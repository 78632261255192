import { clone } from 'app/core/utils';
import { Model } from './model';
import { EventBuilderConfiguration } from 'app/platform/campaign-manager/live-insights/event-builder/event-builder-configuration';
import { EventType } from 'app/platform/campaign-manager/live-insights/event-builder/event-type';
import * as configs from 'app/platform/measurement/live-connect-manager/live-connect-setup/event-configs';

export enum RuleType {
    Event = 'event',
    URL = 'url',
    Retargeting = 'retargeting',
    Standard = 'standard'
}

export class WhitelistingRule extends Model {
    static readonly PLATFORM_WEB = 'web';
    static readonly PLATFORM_APP = 'app';

    advertiserId: number;
    segmentId: string;
    uuid: string;
    previousUuid: string;
    origin: string;
    targets: any[];
    enabled: boolean;
    conversionEnabled: boolean;
    pageRegex: string;
    eventNameRegex: string;
    eventTypes: string[];
    segmentName: string;
    dataProviderId: number;
    platforms: string[];
    isLegacy?: boolean;
    isStandard?: boolean;
    snippet?: string;
    configuration: EventBuilderConfiguration;
    createdAt: number;
    shortHandType: string;

    private _termRegex: string;

    constructor(from?: object) {
        super(from);

        this.configuration = this.computeConfiguration() as any;
    }

    static platformLabel(platform: string) {
        return {
            [WhitelistingRule.PLATFORM_WEB]: 'Web',
            [WhitelistingRule.PLATFORM_APP]: 'In-app'
        }[platform];
    }

    static popularRules() {
        return [
            { value: EventType.AddToCart,       label: 'Add to Cart' },
            { value: EventType.AppInstall,      label: 'App Install' },
            { value: EventType.EmailSignUp,     label: 'Email Sign Up' },
            { value: EventType.FormFill,        label: 'Form Fill' },
            { value: EventType.Lead,            label: 'Lead' },
            { value: EventType.ProductPurchase, label: 'Product Purchase' },
            { value: EventType.Subscription,    label: 'Subscription' },
        ];
    }

    static getConfig(eventType: EventType): EventBuilderConfiguration {
        return {
            [EventType.ProductPurchase]: configs.productPurchase,
            [EventType.EmailSignUp]: configs.emailSignUp,
            [EventType.FormFill]: configs.formFill,
            [EventType.AddToCart]: configs.addToCart,
            [EventType.Lead]: configs.lead,
            [EventType.Subscription]: configs.subscription,
            [EventType.AppInstall]: configs.appInstall,
        }[eventType] || configs.customConversion;
    }

    get termRegex() {
        return this._termRegex || '';
    }

    set termRegex(value) {
        this._termRegex = value;
    }

    get title() {
        return this.configuration.eventName;
    }

    get tagType() {
        if (this.isStandard) {
            return 'Standard Tag';
        } else if (this.isPopular) {
            return 'Popular';
        } else {
            return 'Custom';
        }
    }

    get isPopular() {
        return WhitelistingRule.popularRules().map(popular => WhitelistingRule.getConfig(popular.value)).includes(this.configuration);
    }

    get type() {
        if (this.shortHandType) {
            return this.typeFromShortHand(this.shortHandType);
        }

        if (this.eventNameRegex) {
            return RuleType.Event;
        }
        if (this.pageRegex) {
            return RuleType.URL;
        }
        if (this.isStandard) {
            return RuleType.Standard;
        }
    }

    private typeFromShortHand(shortHand: string) {
        return {
            'dynamic:url': RuleType.URL,
            'dynamic:event': RuleType.Event,
            'dynamic:retargeting': RuleType.Retargeting,
        }[shortHand] || RuleType.Standard;
    }

    get createdAtUnix() {
        return this.createdAt * 1000;
    }

    serialize() {
        const targets = [];

        if (this.platforms && Array.isArray(this.platforms)) {
            if (this.platforms.includes(WhitelistingRule.PLATFORM_APP)) {
                targets.push('mobile');
            }

            if (this.platforms.includes(WhitelistingRule.PLATFORM_WEB)) {
                targets.push('email-hash');
            }
        }

        this.targets = targets;

        delete this.isLegacy;
        delete this.isStandard;
        delete this.configuration;
        delete this.snippet;
        delete this.eventTypes;
        delete this.shortHandType;

        const clone = JSON.parse(JSON.stringify(this));
        clone.termRegex = clone._termRegex;
        return JSON.stringify(clone);
    }

    get forecastable() {
        return [RuleType.Event, RuleType.URL].includes(this.type);
    }

    get entity() {
        return 'Allowing Rule';
    }

    private computeConfiguration() {
        const customConversion = clone(configs.customConversion);
        const match = Object.values(configs).filter(config => config.eventType === this.eventNameRegex)
            .find(config => (config as any).props.find(prop => prop.id === 'name' && prop.value === this.termRegex));

        if (!match) {
            // replace custom conversion name with actual term value
            customConversion.props.forEach(prop => {
                if (prop.id === 'name') {
                    prop.value = this.termRegex;
                }
            });
        }

        return match || customConversion;
    }
}
