import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { InsertionOrder } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
var InsertionOrderRepository = /** @class */ (function (_super) {
    tslib_1.__extends(InsertionOrderRepository, _super);
    function InsertionOrderRepository(http) {
        return _super.call(this, http, '/insertion-order', InsertionOrder) || this;
    }
    return InsertionOrderRepository;
}(BackendRepository));
export { InsertionOrderRepository };
