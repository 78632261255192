import { OnInit } from '@angular/core';
import { IdService } from 'app/core/id.service';
var SelectionSummaryComponent = /** @class */ (function () {
    function SelectionSummaryComponent(id) {
        this.id = id;
        this.hideThreshold = Infinity;
        this.hideTargetingType = null;
        this.isExcluding = false;
        this.showCount = true;
        this.displayAsTable = false;
        this.wide = false;
        this.count = 0;
    }
    SelectionSummaryComponent.prototype.ngOnInit = function () {
        if (Array.isArray(this.selections)) {
            this.count = this.selections.length;
        }
        this.dataVisible = this.count < this.hideThreshold;
    };
    SelectionSummaryComponent.prototype.toggleVisibility = function (event) {
        event.preventDefault();
        this.dataVisible = !this.dataVisible;
    };
    Object.defineProperty(SelectionSummaryComponent.prototype, "_count", {
        get: function () {
            return this.selections.length;
        },
        enumerable: true,
        configurable: true
    });
    return SelectionSummaryComponent;
}());
export { SelectionSummaryComponent };
