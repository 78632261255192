import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { map, tap, mergeMap, take } from 'rxjs/operators';
import { AuthorizationService, Role } from 'app/core/authorization.service';
import { AuthenticationService } from 'app/core/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class CanLoadIsInternalUsers implements CanLoad {
    constructor(
        private authentication: AuthenticationService,
        private authorization: AuthorizationService,
        private router: Router
    ) { }

    canLoad(route: Route, segments: UrlSegment[]) {
        return this.authentication.refreshUser().pipe(mergeMap(() => {
            return forkJoin(
                this.authorization.hasRoleAsync(Role.Admin),
                this.authorization.hasRoleAsync(Role.AdOps),
                this.authorization.hasRoleAsync(Role.AccountManager),
                this.authorization.hasRoleAsync(Role.StandardUser),
                this.authorization.hasRoleAsync(Role.AccountDirector)
            ).pipe(
                map(([isAdmin, isAdOps, isAccountManager, isStandardUser, isAccountDirector]) => {
                    return isAdmin || isAdOps || isAccountManager || isStandardUser || isAccountDirector;
                }),
                tap(isInternalUser => {
                    if (!isInternalUser) {
                        this.router.navigate(['/campaign-manager']);
                    }
                })
            );
        }), take(1));
    }
}
