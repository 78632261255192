import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieSyncPartner } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { shareReplay, mergeMap } from 'rxjs/operators';

@Injectable()
export class CookieSyncPartnerRepository extends BackendRepository<CookieSyncPartner> {
    private cache: Observable<CookieSyncPartner[]>;

    public constructor(http: HttpClient) {
        super(http, '/cookie-sync-partner', CookieSyncPartner);
    }

    getFromCache(id: string) {
        if (!this.cache) {
            this.cache = super.all().pipe(shareReplay());
        }

        return this.cache.pipe(mergeMap(partner => partner.filter(partner => partner.id === id)));
    }
}
