import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LiveConnectTag } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { map } from 'rxjs/operators';
import { NotificationsService } from '../notifications.service';

@Injectable()
export class LiveConnectTagRepository extends BackendRepository<LiveConnectTag> {

    public constructor(
        http: HttpClient,
        private notificationService: NotificationsService
    ) {
        super(http, '/dominique/appInfoByAdvertiserAccount', LiveConnectTag);
    }

    public getOrCreateByAdvertiserIdAndAccountId(id: number, accountId: number) : Observable<LiveConnectTag> {
        return this.http.get(this.url(this.path, id.toString(), accountId.toString()))
            .pipe(
                map(response => this.build(response)),
                catchError(e => {
                    this.notificationService.warning(
                        `Sorry, we encountered an issue retrieving the App-ID for the advertiser with ID: ${id}. Please try again shortly.`,
                        '',
                        0
                    );
                    console.error(`Failed to create appId for ${id}, make sure the advertiser has an accountId.`);
                    return of({} as any);
                })
            );
    }

    public getAppInfoFromCache(id: number, accountId: number): Observable<LiveConnectTag> {
        if (!this._cache.has(id)) {
            const subject = new ReplaySubject<LiveConnectTag>(1);
            this.getOrCreateByAdvertiserIdAndAccountId(id, accountId).subscribe(subject);
            this._cache.set(id, subject);
        }

        return this._cache.get(id);
    }
}
