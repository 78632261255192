import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[validate-password][formControlName],[validate-password][formControl],[validate-password][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => PasswordValidator), multi: true }
    ]
})
export class PasswordValidator implements Validator {

    validate(c: AbstractControl): { [key: string]: any } {
        let password = c.value;
        if (!password || password === '') {
            return null;
        }
        return /(?=.*[0-9 ])(?=.*[!-\/:-@{-~"^_`\[\]\'\\\ ])/.test(password) ? null : { 'password' : { valid: false }};
    }
}
