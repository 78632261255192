import { EventEmitter } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { BulkOperationStatusToastService, IdService, NotificationsService } from 'app/core';
import { CreativeRepository } from 'app/core/repositories';
import { BulkOperationRepository } from 'app/core/repositories/bulk-operation-repository.service';
import { SensitiveCategoryRepository } from 'app/core/repositories/sensitive-category-repository.service';
import { BulkEditLightboxComponent } from 'app/shared/components/bulk-edit-lightbox';
import { BulkOperationRequest, Operation, OperationMethod } from 'app/shared/models/bulk-request';
var CreativeBulkEditComponent = /** @class */ (function () {
    function CreativeBulkEditComponent(creativeRepository, notifications, id, sensitiveCategoryRepo, bulkOperationRepo, bulkOperationStatusToast) {
        this.creativeRepository = creativeRepository;
        this.notifications = notifications;
        this.id = id;
        this.sensitiveCategoryRepo = sensitiveCategoryRepo;
        this.bulkOperationRepo = bulkOperationRepo;
        this.bulkOperationStatusToast = bulkOperationStatusToast;
        this.completed = new EventEmitter();
        this.successItems = [];
        this.failureItems = [];
    }
    CreativeBulkEditComponent.prototype.open = function (creatives, field) {
        var _this = this;
        if (field === void 0) { field = null; }
        this.lightbox.initiate();
        this.lightbox.open(creatives, field, 'load');
        forkJoin(creatives.map(function (creative) { return _this.creativeRepository.get(creative.id).pipe(mergeMap(function (detail) {
            return forkJoin(of(detail), _this.sensitiveCategoryRepo.searchByCreativeId(creative.refId));
        }), map(function (_a) {
            var detail = _a[0], sensitiveCategories = _a[1];
            detail.sensitiveCategories = sensitiveCategories.map(function (category) { return category.id; });
            return detail;
        }), tap(function () { return _this.lightbox.postProgress(); })); })).subscribe(function (details) { return _this.lightbox.open(details, field); }, function () { return _this.handleError(); });
    };
    CreativeBulkEditComponent.prototype.close = function () {
        this.lightbox.close();
    };
    CreativeBulkEditComponent.prototype.submit = function (_a) {
        var _this = this;
        var changed = _a.changed;
        this.lightbox.initiate();
        var LSDRequests = changed.map(function (creative) { return new Operation(OperationMethod.Post, '/creative/' + creative.refId, JSON.parse(creative.serialize()), 'lsd:' + creative.refId.toString()); });
        var MCSRequests = changed.map(function (creative) { return new Operation(OperationMethod.Put, '/mc/creatives/li-' + creative.refId + '/sensitive-category-labels', { attributes: creative.sensitiveCategories || [] }, 'mcs:' + creative.refId.toString()); });
        forkJoin([
            this.bulkOperationRepo.sendRequest(new BulkOperationRequest(LSDRequests.concat(MCSRequests)))
        ]).subscribe(function () { return _this.createBulkOperationStatusToast(); }, function () { return _this.handleSaveError(); });
    };
    CreativeBulkEditComponent.prototype.createBulkOperationStatusToast = function () {
        this.bulkOperationStatusToast.fetchStatusData();
        this.completed.emit();
        this.close();
        return;
    };
    CreativeBulkEditComponent.prototype.handleError = function () {
        this.notifications.error('An error has occurred.');
    };
    CreativeBulkEditComponent.prototype.handleSaveError = function () {
        this.lightbox.resetForm();
        this.handleError();
    };
    return CreativeBulkEditComponent;
}());
export { CreativeBulkEditComponent };
