import { ChangeDetectionStrategy, ContentChildren, Component, Input, QueryList } from '@angular/core';
import { OptionComponent } from '../filter-select';

@Component({
    selector: 'option-group',
    template: '<ng-content></ng-content>',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionGroupComponent {
    @ContentChildren(OptionComponent) optionComponents: QueryList<OptionComponent>;
    @Input() label: string;
    private optionsCache = null;

    get options() {
        if (!this.optionsCache) {
            if (this.optionComponents && this.optionComponents.length > 0) {
                this.optionsCache = this.optionComponents.map((option, key) => {
                    return {
                        key,
                        value: option.value,
                        label: option.html
                    };
                });
            } else {
                this.optionsCache = [];
            }
        }

        return this.optionsCache;
    }
}
