import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Publisher } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { map } from 'rxjs/operators';

@Injectable()
export class PublisherRepository extends BackendRepository<Publisher> {
    constructor(http: HttpClient) {
        super(http, '/publisher', Publisher);
    }

    linkDsps(id: string, params: any): Observable<any> {
        return this.http
            .post(this.url(this.path, 'link-dsps', id), params)
            .pipe(map(response => response['output']));
    }

    confirm(body: any) {
        const params = [this.path, 'confirm'];
        if (body.id) {
            params.push(body.id);
        }

        return this.http.post(this.url(...params), body);
    }

    inventoryUpload(params: any) {
        return this.http
            .post(this.url(this.path, 'inventory-upload', params.id), params)
            .pipe(map(response => response['output']));
    }

    getBlockableByAdvertiser(id: string): Observable<Publisher[]> {
        return this.http.get(this.url('/search/publisher/blockable/advertiser', id))
            .pipe(map(response => response['output'].map(data => this.build(data)) as Publisher[]));
    }

    public linkUserMatchPartners(id: string, body: any): Observable<Response> {
        return this.http
            .post(this.url(this.path + '/link-user-match-partners', id), body)
            .pipe(map(response => response['output'].output));
    }

    /**
     * Trigger an export of the newsletter tags in the MS Excel format:
     *
     *     params: {
     *         id: refid|hash
     *     }
     */
    public downloadTags(params: Array<string>) {
        const req = this.http.request('get', this.url(this.path, 'export-tags', ...params), {
            headers: new HttpHeaders({ 'Accept': 'application/vnd.ms-excel' }),
            responseType: 'blob'
        });

        return req;
    }

    addToBlocklist(params: any) {
        return this.http.post(this.url(this.path, 'targeted-domains-append'), params);
    }

    removeFromBlocklist(params: any) {
        return this.http.post(this.url(this.path, 'targeted-domains-remove'), params);
    }
}
