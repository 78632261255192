import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Category } from 'app/shared/models';
import { BackendRepository, Query } from './backend-repository';
import { publishLast, refCount, map } from 'rxjs/operators';
import { Option } from 'app/shared/elements/dropdown';

@Injectable()
export class CategoryRepository extends BackendRepository<Category> {
    public constructor(http: HttpClient) {
        super(http, '/category', Category);
    }

    // TODO implement based on the query params
    private _allResult: Observable<Category[]> = null;
    private _primaryCategoriesResult: Observable<Category[]> = null;

    public all(query: Query = {}): Observable<Array<Category>> {

        if (this._allResult) {
            return this._allResult;
        }

        this._allResult = super.all(query)
            .pipe(publishLast(), refCount());

        return this._allResult;
    }

    /**
     * Makes a call for all Primary Categories (IAB 1-26)
     */
    public getPrimaryCategories(): Observable<Category[]> {

        const query = { conditions: [{ field: 'parent', value: null }] };

        if (this._primaryCategoriesResult) {
            return this._primaryCategoriesResult;
        }

        this._primaryCategoriesResult = super.search(query)
            .pipe(publishLast(), refCount());

        return this._primaryCategoriesResult;
    }

    public searchAsOptions(query: Query = {}): Observable<Option[]> {
        return this.all(query).pipe(map(categories => {
            return categories.map(
                option => {
                    return {
                        key: option.id,
                        value: option.id,
                        label: [option.iab, option.name].join(': '),
                        data: option
                    };
                });
        }));
    }

    /**
     * Filter out any secondary categories.
     * @param  {any[]} categories
     * @return {any[]}
     */
    public filterOutSecondary(categories) {
        return categories.filter(category => {
            if (category.data && category.data.parent === null) {
                return true;
            } else {
                return category.parent === null;
            }
        });
    }

    /**
     * Converts observable array of categories
     * into an observable array of options
     *
     * @param {Observable<Category[]>} categories
     * @return {Observable<Option[]>} options
     */
    public convertCategoriesToOptions(categories: Observable<Category[]>): Observable<Option[]> {
        return categories.pipe(map(categoryList => {
            return categoryList.map(option => {
                return {
                    key: option.id,
                    value: option.id,
                    label: [option.iab, option.name].join(': '),
                    data: option
                };
            });
        }));
    }
}
