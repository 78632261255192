import { ElementRef, OnInit, EventEmitter } from '@angular/core';
import { generate } from 'c3';
var PieChartComponent = /** @class */ (function () {
    function PieChartComponent() {
        this.sectionSelected = new EventEmitter();
    }
    Object.defineProperty(PieChartComponent.prototype, "columns", {
        get: function () {
            return this._columns;
        },
        set: function (val) {
            this._columns = val;
            if (val) {
                this.total = val.reduce(function (sum, item) { return sum + item[1]; }, 0);
            }
        },
        enumerable: true,
        configurable: true
    });
    PieChartComponent.prototype.clickedSection = function (data) {
        this.sectionSelected.emit(data);
    };
    PieChartComponent.prototype.ngOnInit = function () {
        var _this = this;
        var select = function (data) {
            _this.clickedSection(data);
        };
        this.chart = generate({
            bindto: this.container.nativeElement,
            data: {
                type: 'pie',
                columns: this.columns,
                onclick: function (d, i) { select(d); }
            },
            color: {
                pattern: ['#14abf9', '#fc7709', '#ffa710', '#ba358a',
                    '#bf7eaa', '#c03247', '#ef3849', '#bec4c8', '#f4b526', '#235fac']
            },
            pie: {
                label: {
                    show: false
                }
            },
            legend: {
                show: false
            },
            tooltip: {
                show: true,
                format: {
                    name: function (name, ratio, id, index) {
                        return name.split(' ').map(function (w) { return w.charAt(0).toUpperCase() + w.slice(1); }).join(' ');
                    },
                    value: function (value, ratio, id, index) {
                        return (ratio * 100).toPrecision(3) + "%\n                                " + value.toLocaleString();
                    }
                }
            }
        });
    };
    PieChartComponent.prototype.highlight = function (id) {
        this.chart.focus(id);
    };
    PieChartComponent.prototype.color = function (id) {
        return this.chart ? this.chart.data.colors()[id] : '';
    };
    return PieChartComponent;
}());
export { PieChartComponent };
