import { SelectDropdownComponent } from 'app/shared/elements/select-dropdown';
var BulkEditAdSlotDemandSettingComponent = /** @class */ (function () {
    function BulkEditAdSlotDemandSettingComponent(label) {
        this._adSlots = [];
        this._originals = [];
        this.entityName = '';
        this.label = label;
    }
    BulkEditAdSlotDemandSettingComponent.prototype.master = function (value) {
        this.entities.forEach(function (adSlot) {
            if (adSlot.hasOwnProperty('sspControl')) {
                adSlot.sspControl.exchangeAllow = value;
            }
        });
    };
    BulkEditAdSlotDemandSettingComponent.prototype.reset = function () {
        var _this = this;
        this.masterDropdown.reset();
        this.entities.forEach(function (adSlot, index) {
            if (adSlot.hasOwnProperty('sspControl')) {
                adSlot.sspControl.exchangeAllow = _this.original(index).sspControl.exchangeAllow;
            }
        });
    };
    BulkEditAdSlotDemandSettingComponent.prototype.isDirty = function () {
        var _this = this;
        return this.entities.some(function (adSlot, index) {
            return adSlot.sspControl && adSlot.sspControl.exchangeAllow !== _this.original(index).sspControl.exchangeAllow;
        });
    };
    BulkEditAdSlotDemandSettingComponent.prototype.undo = function (i) {
        this.entities[i].sspControl.exchangeAllow = this.original(i).sspControl.exchangeAllow;
    };
    BulkEditAdSlotDemandSettingComponent.prototype.checkDirty = function (i, value) {
        if (this.original(i).sspControl) {
            return value !== this.original(i).sspControl.exchangeAllow;
        }
    };
    BulkEditAdSlotDemandSettingComponent.prototype.original = function (index) {
        return this._originals[index];
    };
    Object.defineProperty(BulkEditAdSlotDemandSettingComponent.prototype, "entities", {
        get: function () {
            return this._adSlots;
        },
        set: function (adSlots) {
            this._adSlots = adSlots;
            this._originals = adSlots.map(function (adSlot) { return adSlot.clone(); });
        },
        enumerable: true,
        configurable: true
    });
    return BulkEditAdSlotDemandSettingComponent;
}());
export { BulkEditAdSlotDemandSettingComponent };
