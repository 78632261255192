import { Component, OnInit } from '@angular/core';

import { ThemeService, BackgroundStyle } from 'app/shared/helpers/theme.service';

@Component({
    templateUrl: './fatal-error.html'
})
export class FatalErrorComponent implements OnInit {
    constructor(private themeService: ThemeService) { }

    ngOnInit(): void {
        this.themeService.setBackgroundStyle(BackgroundStyle.GreyStyle);
        window.history.replaceState(null, 'Error', '/');
    }

    deleteCookie(name: string|null|undefined): void {
        if (!name) {
            return;
        }

        document.cookie =
            `${name}=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/`;
    }

    deleteAllCookies(): void {
        const cookies = document.cookie.split("; ");

        cookies.forEach((cookie) => {
            if (cookie.includes('=')) {
                const cookieName = cookie.split("=")[0].trim();
                if (cookieName) {
                    this.deleteCookie(cookieName);
                }
            }
        });
    }

    reload(): void {
        // Note: This will not delete the `li_session` cookie since it is set to `HttpOnly`
        this.deleteAllCookies();
        window.localStorage.clear();
        window.location.reload(true);
    }
}
