import { Filter } from './filter';
import { FilterEnum } from './filter-enum/filter-enum';
import { FilterId } from './filter-id/filter-id';
import { FilterNumber } from './filter-number/filter-number';
import { FilterString } from './filter-string/filter-string';

export class FilterFactory {
    /**
     * Create a new filter based on the `field` name.
     * @param {string} field
     * @return {Filter}
     */
    static create(field: string): Filter {
        switch (field) {
            case 'advertiserRefId':
            case 'newsletterRefId':
            case 'publisherRefId':
            case 'mediaGroupRefId':
            case 'campaignId':
            case 'refId':
            case 'id':
                return new FilterId({ field });
            case 'width':
            case 'height':
                return new FilterNumber({ field });
            case 'tagType':
                return new FilterEnum({
                    field,
                    values: ['legacy', 'universal']
                });
            case 'status':
                return new FilterEnum({
                    field,
                    values: [
                        'active', 'inactive', 'paused', 'pending', 'rejected', 'archived', 'deleted'
                    ]
                });
            default:
                return new FilterString({ field });
        }
    }
}
