import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Audience } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import * as configs from 'app/platform/measurement/live-connect-manager/live-connect-setup/event-configs';
import { AudienceSearchResponse } from 'app/shared/models/audience-search-response';
import { map } from 'rxjs/operators';
var AudiencesApiRepository = /** @class */ (function (_super) {
    tslib_1.__extends(AudiencesApiRepository, _super);
    function AudiencesApiRepository(http) {
        return _super.call(this, http, "/audiences-api", AudienceSearchResponse) || this;
    }
    AudiencesApiRepository.prototype.build = function (from) {
        var model = _super.prototype.build.call(this, from);
        if (model.entries && Array.isArray(model.entries)) {
            // Have to convert each entry into an `Audience` object in this way
            // otherwise calling functions or getting properties of the 
            // `Audience` object will not work in runtime
            model.entries = model.entries.map(function (entry) { return new Audience(entry); });
        }
        return model;
    };
    AudiencesApiRepository.prototype.getAudienceByRuleId = function (ruleId, accountId) {
        var _this = this;
        return this.http.post(this.url(this.path, "search/audiences?accountId=" + String(accountId)), {
            'matches': { 'ruleIdMatches': { 'eq': ruleId } }
        }).pipe(map(function (res) { return _this.build(res); }));
    };
    AudiencesApiRepository.prototype.updateTracker = function (ruleId, audienceId, accountId, pixel) {
        var payload = {
            ruleId: ruleId,
            audienceId: audienceId,
            accountId: accountId,
            name: pixel.name,
            pcc: pixel.expiration,
            pvc: pixel.pvcExpiration
        };
        return this.http.patch(this.url(this.path, 'trackers'), payload);
    };
    AudiencesApiRepository.prototype.createEventTracker = function (pixel, accountId, audience, appId, rule) {
        var body = {};
        if (rule.eventNameRegex === configs.addToCart.eventType) {
            body.Matches = {
                path: '$.event',
                value: rule.eventNameRegex
            };
        }
        else {
            body.And = {
                conditions: [
                    {
                        Matches: {
                            path: '$.event',
                            value: rule.eventNameRegex
                        }
                    },
                    {
                        Matches: {
                            path: '$.name',
                            value: rule.termRegex
                        }
                    }
                ]
            };
        }
        return this.http.post(this.url(this.path, 'eventtrackers'), this.getPayload(pixel, accountId, audience, appId, body));
    };
    AudiencesApiRepository.prototype.createUrlTracker = function (pixel, accountId, audience, appId, rule) {
        var body = {};
        body.Matches = {
            path: '$.url',
            value: rule.pageRegex
        };
        return this.http.post(this.url(this.path, 'urltrackers'), this.getPayload(pixel, accountId, audience, appId, body));
    };
    AudiencesApiRepository.prototype.getPayload = function (pixel, accountId, audience, appId, body) {
        var pcc = pixel.expirationInDays * 24 * 60 * 60;
        var pvc = pixel.pvcExpirationInHours * 60 * 60;
        return {
            conversionId: pixel.refId,
            pixelId: pixel.id,
            accountId: accountId,
            appIds: [appId],
            name: audience.name || pixel.name,
            attributionWindow: {
                pcc: pcc,
                pvc: pvc
            },
            audienceTtl: audience.ttl,
            body: body
        };
    };
    return AudiencesApiRepository;
}(BackendRepository));
export { AudiencesApiRepository };
