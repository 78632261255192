/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./blink.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./blink.component";
var styles_BlinkComponent = [i0.styles];
var RenderType_BlinkComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BlinkComponent, data: {} });
export { RenderType_BlinkComponent as RenderType_BlinkComponent };
export function View_BlinkComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { container: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["container", 1]], null, 3, "span", [["class", "blink"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
export function View_BlinkComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "blink", [], null, null, null, View_BlinkComponent_0, RenderType_BlinkComponent)), i1.ɵdid(1, 1097728, null, 0, i2.BlinkComponent, [], null, null)], null, null); }
var BlinkComponentNgFactory = i1.ɵccf("blink", i2.BlinkComponent, View_BlinkComponent_Host_0, {}, {}, ["*"]);
export { BlinkComponentNgFactory as BlinkComponentNgFactory };
