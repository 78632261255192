import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RTBPartner } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { map } from 'rxjs/operators';

@Injectable()
export class RTBPartnerRepository extends BackendRepository<RTBPartner> {
    constructor(http: HttpClient) {
        super(http, '/dsp', RTBPartner);
    }

    searchByMediaGroup(id: string): Observable<RTBPartner[]> {
        return this.http.get(this.url('/search/dsp/media-group', id))
            .pipe(map(response => response['output'].map(data => this.build(data)) as RTBPartner[]));
    }

    searchByPublisher(id: string): Observable<RTBPartner[]> {
        return this.http.get(this.url('/search/dsp/publisher', id))
            .pipe(map(response => response['output'].map(data => this.build(data)) as RTBPartner[]));
    }

    searchByNewsletter(id: string): Observable<RTBPartner[]> {
        return this.http.get(this.url('/search/dsp/newsletter', id))
            .pipe(map(response => response['output'].map(data => this.build(data)) as RTBPartner[]));
    }

    searchByAdSlot(id: string): Observable<RTBPartner[]> {
        return this.http.get(this.url('/search/dsp/ad-slot', id))
            .pipe(map(response => response['output'].map(data => this.build(data)) as RTBPartner[]));
    }
}
