import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    Input,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { generate, ChartAPI, PrimitiveArray } from 'c3';
import { timeFormat } from 'd3';

export type Columns = Array<[string, ...PrimitiveArray]>;

@Component({
    selector: 'app-bar-chart',
    templateUrl: './bar-chart.html',
    styleUrls: ['./bar-chart.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarChartComponent implements OnInit {
    /**
     * Optional zero state template.
     */
    @ContentChild('zeroState', { static: true }) zeroStateTemplate: TemplateRef<any>;

    /**
     * A list of columns, where the first element in each column is the ID and the remaining elements are data.
     */
    @Input() columns: Columns;

    /**
     * Set groups for the data for stacking.
     */
    @Input() groups?: string[][];

    /**
     * Whether or not to include the chart legend.
     */
    @Input('show-legend') showLegend = true;

    /**
     * The Y-Axis label.
     */
    @Input('y-label') yLabel?: string;

    /**
     * A custom date format, because the `load` function does not support it.
     */
    @Input('time-format')
    set timeFormatter(format: string) {
        this.formatter = timeFormat(format);
    }

    /**
     * Current definition of date formatter.
     */
    private formatter: (format: Date) => string = timeFormat('%Y-%m-%d');

    /**
     * A reference to the chart object is made public so the necessary methods may be called on it.
     */
    chart: ChartAPI;

    /**
     * The element container to draw the chart into.
     */
    @ViewChild('container', { static: true }) container: ElementRef;

    constructor(private changeDetection: ChangeDetectorRef) { }

    /**
     * Generate Chart
     */
    ngOnInit() {
        this.chart = generate({
            bindto: this.container.nativeElement,
            data: {
                x: 'Date',
                type: 'bar',
                xFormat: '%Y-%m-%dT%H:%M:%S.%LZ',
                xLocaltime: false,
                columns: this.columns,
                groups: this.groups
            },
            color: {
                pattern: ['#13DDC9', '#ffa710', '#235fac', '#14abf9', '#fc7709', '#ba358a', '#bf7eaa', '#c03247', '#ef3849']
            },
            legend: {
                show: this.showLegend
            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: {
                        format: this.formatter ? (x: Date) => {
                            return this.formatter(x);
                        } : undefined
                    }
                },
                y: {
                    label: this.yLabel ? {
                        text: this.yLabel,
                        position: 'outer-middle'
                    } : undefined,
                    tick: {
                        format: (x) => {
                            // only show integers, but don't wrong cuz that's not accurate
                            return (x % 1 === 0 ? x : '') as string;
                        }
                    }
                },
            },
            grid: {
                y: {
                    show: true
                }
            },
            tooltip: {
                format: {
                    title: (this.formatter ? (x: Date) => {
                        return this.formatter(x);
                    } : undefined) as any
                }
            }
        });
    }

    /**
     * Redraw the chart with new data.
     */
    redraw(columns: Columns) {
        this.changeDetection.markForCheck();
        if (this.chart) {
            this.chart.flush();

            this.chart.load({ columns });
        }
    }

    /**
     * Check if the chart has non-zero data.
     */
    get hasData() {
        // if the chart has not yet rendered, or we have not even
        // supplied columns yet, we should show an empty chart
        if (!this.chart || this.chart.data().length === 0) {
            return false;
        }

        // once we have columns, if they are all empty, we'll show
        // a zero state instead of the graph
        return this.chart.data().some(column => {
            return column.values.some(value => value.value > 0);
        });
    }
}
