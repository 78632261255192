import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { of, zip } from 'rxjs';
import { Advertiser, InsertionOrder, Campaign } from 'app/shared/models';
import { AdvertiserRepository, InsertionOrderRepository, CampaignRepository } from 'app/core/repositories';
import { map, mergeMap } from 'rxjs/operators';
import { Flag, LaunchDarklyService } from '../launch-darkly.service';

@Injectable()
export class CampaignFlowEditResolver
implements Resolve<{ advertiser: Advertiser, insertionOrder: InsertionOrder, campaign: Campaign }> {
    constructor(
        private advertiserRepository: AdvertiserRepository,
        private insertionOrderRepository: InsertionOrderRepository,
        private campaignRepository: CampaignRepository,
        private launchDarklyService: LaunchDarklyService
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        let id = route.paramMap.get('id');

        if (id === null) {
            id = route.parent.paramMap.get('id');
        }

        return this.campaignRepository
            .get(id)
            .pipe(mergeMap(campaign => zip(
                this.advertiserRepository.get(campaign.advertiser),
                this.insertionOrderRepository.get(campaign.insertionOrder),
                this.hasAudienceExtensionFlag(),
                of(campaign)).pipe(
                    mergeMap(([advertiser, insertionOrder, audienceExtensionFlag, campaign]) => {
                    campaign.canAccessAudienceExtension = audienceExtensionFlag;
                    if (campaign.parentCampaign) {
                        return this.campaignRepository.get(campaign.parentCampaign).pipe(map((parentCampaign: Campaign) => {
                            if (parentCampaign) {
                                return { advertiser, insertionOrder, campaign, parentCampaign };
                            }
                            return { advertiser, insertionOrder, campaign };
                        }));
                    } else {
                        return of({ advertiser, insertionOrder, campaign });
                    }
                })
            )));
    }

    private hasAudienceExtensionFlag() {
        return this.launchDarklyService
            .getVariation(Flag.RolloutAudienceExtension);
    }

    /**
     * Checks if campaign is eligible
     * to be an audience extendable campaign
     * @param parentCampaign
     * @param insertionOrder
     * @return boolean
     */
    isAudienceExtensionParentCampaign(parentCampaign: Campaign, insertionOrder: InsertionOrder): boolean {
        return parentCampaign.isDirectSold && parentCampaign.insertionOrder == insertionOrder.id;
    }
}
