import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdSlot } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { UserRepository } from './user-repository.service';
import { LineItem } from 'app/shared/models/line-item';
import { BlueprintRepository } from './blueprint-repository';

@Injectable()
export class AdSlotRepository extends BackendRepository<AdSlot> {
    public searchPath = "/search/ad-slot/shop-by-strategy?params=";

    constructor(
        http: HttpClient,
        public userRepository: UserRepository,
        public blueprintRepository: BlueprintRepository
    ) { super(http, "/ad-slot", AdSlot); }

    linkDsps(id: string, body: any): Observable<AdSlot> {
        return this.http
            .post(this.url("/ad-slot/link-dsps", id), body)
            .pipe(map((response) => this.build(response["output"])));
    }

    getAdSlotDetails(id) {
        return this.get(id)
            .toPromise()
            .then((adSlot) => {
                const userConditions = {
                    conditions: [
                        {
                            field: "hash_id",
                            value: [],
                        },
                    ],
                };

                if (adSlot.createdBy.length === 32) {
                    userConditions.conditions[0].value.push(adSlot.createdBy);
                }

                if (adSlot.modifiedBy.length === 32) {
                    userConditions.conditions[0].value.push(adSlot.modifiedBy);
                }

                return this.userRepository
                    .search(userConditions)
                    .toPromise()
                    .then((users) => {
                        let userLookup = [];
                        users.map((user) => {
                            userLookup[user.hashId] = user;
                        });

                        if (adSlot.createdBy in userLookup) {
                            adSlot.createdByUser = userLookup[adSlot.createdBy];
                        }

                        if (adSlot.modifiedBy in userLookup) {
                            adSlot.modifiedByUser =
                                userLookup[adSlot.modifiedBy];
                        }

                        return adSlot;
                    });
            });
    }

    getBy(searchConditions) {
        const adSlotConditions = {
            conditions: [],
            returnMode: "only",
            return: [
                "id",
                "refId",
                "newsletter",
                "publisher",
                "sizes",
                "type",
                "name",
                "version",
                "created",
                "taboola",
                "coordinationType",
                "tagType",
                "currentTagVersion",
                "previousTagVersion",
                "isHybrid",
                "isDisplay",
                "isNative",
            ],
        };

        adSlotConditions.conditions =
            adSlotConditions.conditions.concat(searchConditions);

        return this.all(adSlotConditions).toPromise();
    }

    getByPublisherId(publisherId) {
        return this.getBy([
            {
                field: 'publisher',
                value: publisherId,
            },
        ]);
    }

    getByNewsletterId(newsletterId) {
        return this.getBy([
            {
                field: 'newsletter',
                value: newsletterId,
            },
        ]);
    }

    activate(id: string, body: any): Observable<AdSlot> {
        return this.http
            .post(this.url("/ad-slot/activate", id), body)
            .pipe(map((response) => this.build(response["output"])));
    }

    getByStrategyId(strategyId: string) {
        return this.http
            .post(this.url("/search/ad-slot/strategy"), { strategyId })
            .pipe(
                map(
                    (response) =>
                        response["output"].map((data) =>
                            this.build(data)
                        ) as AdSlot[]
                )
            );
    }

    searchByLineItem(strategyId: string, params: any) {
        const url = this.url("/search/ad-slot/strategy");
        return this.http
            .post(
                url,
                Object.assign({ strategyId }, this.sanitizeQuery(params))
            )
            .pipe(
                map((data) => ({
                    items: data["output"].map(
                        (item) => this.build(item) as AdSlot
                    ),
                    page: 1,
                    pages: data["pages"],
                    total: data["total"],
                }))
            );
    }

    searchByCoordinatedLineItem(params: any) {
        const url = this.url('/search/ad-slot/coordinated');

        return this.http.post(url, this.sanitizeQuery(params)).pipe(
            map(data => ({
                items: data['output'].map((item) => this.build(item) as AdSlot),
                page: 1,
                pages: data['pages'],
                total: data['total']
            }))
        );
    }

    lineItemSources(lineItem: LineItem, params: any) {
        if (
            (lineItem.campaignObj.isDirectSold ||
                lineItem.campaignObj.isHouse) &&
            lineItem.ads &&
            lineItem.ads.length > 0 &&
            lineItem.ads.some((ad) => !!ad.isNative)
        ) {
            return [
                this.http.post(
                    this.url(this.searchPath + lineItem.id),
                    this.sanitizeQuery(params)
                ),
            ];
        } else {
            return [
                this.http.post(
                    this.url(this.searchPath + lineItem.id),
                    this.sanitizeQuery(params)
                ),
                of({
                    output: [],
                    pages: 0,
                    total: 0,
                }),
            ];
        }
    }

    lineItemShopBy(lineItem: LineItem, params: any): Observable<{ items: AdSlot[]; page: number; pages: any; total: any; }> {
        return forkJoin(
            this.lineItemSources(lineItem, params)
        ).pipe(
            map(([data]) => {
                return {
                    items: data['output'].map((item) => this.build(item)),
                    page: 1,
                    pages: data['pages'],
                    total: data['total'],
                };
            })
        );
    }

    shopByStrategyAll(lineItem: LineItem, params: any): Observable<AdSlot[]> {
        return this.consume((currentPage) => {
            //clone the query so when we update the page
            //we are not mutating the original query
            //since it will be passed by reference
            const q = JSON.parse(JSON.stringify(params));
            q.page = currentPage;
            return forkJoin(
                this.lineItemSources(lineItem, q)
            ).pipe(
                map(([data]) => {
                    return {
                        output: data['output'].map((item) => this.build(item)),
                        page: currentPage,
                        pages: data['pages'],
                        total: data['total'],
                    };
                })
            );
        });
    }

    archive(id: string) {
        return this.delete(id);
    }

    unarchive(adSlot: AdSlot) {
        return this.http
            .post(this.url("/ad-slot/undelete", adSlot.id), {
                version: adSlot.version,
            })
            .pipe(map((response) => this.build(response["output"])));
    }

    tags(adSlotRefId: string) {
        return this.http
            .post(this.url('/ad-slot/tags', adSlotRefId), {})
            .pipe(map((response) => this.build(response['output'])));
    }
}
