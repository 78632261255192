import { EventEmitter, ChangeDetectorRef, Component, Input, Output } from '@angular/core';
import { LocationHelperService } from 'app/core/location-helper.service';
import { Option } from 'app/shared/elements/dropdown';
import postalCountries from './postal-countries.json';

const targets = [
    {
        key: 0,
        value: 'include',
        label: 'Target'
    },
    {
        key: 1,
        value: 'exclude',
        label: 'Exclude'
    }
];

@Component({
    selector: 'location-targeting',
    templateUrl: './location-targeting.html',
    styleUrls: ['./location-targeting.styl']
})
export class LocationTargetingComponent {

    private model = { input: '', postalCodes: '' };
    private _items: Array<any> = [];
    public filteredLocations = [];
    public options = {
        target: targets,
        locations: []
    };

    private static UNITED_STATES_ID = 235;
    private _USOnly: boolean;
    private countries: any[] = [];
    public countryOptions = postalCountries;
    private country: number;
    postalCodePlaceholder: string;

    @Input() showPostal: boolean = true;
    @Input() restrict: Array<string> = [];
    @Input() geos = [];
    @Output() geosChange = new EventEmitter();
    @Input() postalCodes = [];
    @Output() postalCodesChange = new EventEmitter();
    @Input() unitedStatesOnly: boolean = null;
    @Output() unitedStatesOnlyChange = new EventEmitter<boolean>();
    @Input('targeting') _targeting: string = 'include';
    @Output('targetingChange')  _targetingChange = new EventEmitter<string>();

    static validPostalCodes(postalCode) {
        return postalCode.length > 0;
    }

    static uniquePostalCodes(arr) {
        return (postalCode, i) => arr.indexOf(postalCode) === i;
    }

    static findPostalCodes(item) {
        return typeof item.key === 'string';
    }

    static findGeos(item) {
        return typeof item.key === 'number';
    }

    set items(value) {
        this._items = value;
        this.updateLocations();
    }

    get items() {
        return this._items;
    }

    /**
     * Worldwide.
     */

    initWorldwide(): void {
        this.USOnly = this.unitedStatesOnly;
    }

    set USOnly(value) {
        if (value) {
            this.country = LocationTargetingComponent.UNITED_STATES_ID;
            this.postalCodePlaceholder = 'Enter U.S. postal codes in the following formats: 12345 and 12345-6789.';
        }
        else {
            this.country = null;
            this.postalCodePlaceholder = 'Select a country and then add postal codes you want to target.';
        }
        this._USOnly = value;
        this.unitedStatesOnly = value === true;
        this.unitedStatesOnlyChange.emit(this.unitedStatesOnly);
        this.options.locations = this.prepareLocations();
        this.updateLocations();
    }

    get USOnly() {
        return this._USOnly;
    }

    constructor(
        public locationHelper: LocationHelperService,
        public cdr: ChangeDetectorRef
    ) { }

    private isLoading = false;

    ngOnInit() {

        this.isLoading = true;
        this.locationHelper
            .promise
            .then(() => {
                this.countries = this.locationHelper.database
                    .filter(location => location.type === 'country')
                    .map(location => ({
                        key: location.id,
                        value: location.id,
                        label: location.display
                    }));
                this.initWorldwide();
                this.options.locations = this.prepareLocations();
                this.updateItems();

                this.isLoading = false;
            });
    }

    ngDoCheck() {

        // We want to make sure that the locationHelper has all the necessary data before running
        // this function;
        if (this.isLoading) {
            return;
        }

        let postalCodes = this.items.filter(LocationTargetingComponent.findPostalCodes);

        let formattedCodes = [];
        postalCodes.forEach(postalCode => {
            if (!formattedCodes[postalCode.countryId]) {
                formattedCodes[postalCode.countryId] = [];
            }
            formattedCodes[postalCode.countryId].push(postalCode.key);
        });

        this.postalCodes = [];

        Object.keys(formattedCodes).forEach(function (key) {
            this.postalCodes.push({
                countryId: key,
                postalCodes: formattedCodes[key]
            });
        }.bind(this));

        this.postalCodesChange.emit(this.postalCodes);

        let geos = this.items.filter(LocationTargetingComponent.findGeos);
        this.geos = geos.map(item => ({ id: item.key, type: item.value }));
        this.geosChange.emit(this.geos);
    }

    updateItems(): void {
        this.items = this.prepareGeos();
        this.items = this.items.concat(this.preparePostalCodes());
    }

    addPostalCodes() {
        if (typeof this.model.postalCodes !== 'string') { return; }
        let postalCodes = this.model.postalCodes.replace(/\n/g, ',')
            .split(',').map(token => token.trim())
            .filter(LocationTargetingComponent.validPostalCodes);

        if (postalCodes.length < 1) { return; }
        let uniqueFilter = LocationTargetingComponent.uniquePostalCodes(postalCodes);
        postalCodes = postalCodes.filter(uniqueFilter);

        for (let i = 0; i < postalCodes.length; i++) {
            for (let item of this.items) {
                if (postalCodes[i] === item.key && String(this.country) === String(item.countryId)) {
                    postalCodes.splice(i, 1);
                }
            }
        }

        this.changePostalCodes(postalCodes);
        this.model.postalCodes = '';
    }

    changePostalCodes(postalCodes: string[]): void {
        this.postalCodes = this.postalCodes.concat({
            countryId: this.country,
            postalCodes: postalCodes
        });
        this.postalCodesChange.emit(this.postalCodes);
        this.updateItems();
    }

    addGeo(geo) {
        this.geos.push({
            id: geo.value.id,
            type: geo.value.type
        });
        this.geosChange.emit(this.geos);
        this.updateItems();
    }

    removeAll() {
        this.geos.length = 0;
        this.postalCodes.length = 0;
        this.geosChange.emit(this.geos);
        this.postalCodesChange.emit(this.postalCodes);
        this.updateItems();
    }

    select(geo) {
        this.addGeo(geo);
    }

    prepareGeos(): Array<any> {
        let geos = [];
        let includedGeoIds = [];
        if (Array.isArray(this.geos)) {
            for (let i = 0; i < this.geos.length; i++) {
                let geo = this.locationHelper.lookup(this.geos[i].id, this.geos[i].type);
                geos.push({
                    key: geo.id,
                    value: geo.type,
                    label: geo.display
                });
                includedGeoIds.push(geo.id);
            }
        }
        return geos;
    }

    preparePostalCodes(): Array<any> {
        let postalCodes = [];
        if (!Array.isArray(this.postalCodes)) { return postalCodes; }
        this.postalCodes.forEach(postalCode => {
            postalCode.postalCodes.forEach(value => {
                let country = this.countries.filter(country => country.key === Number(postalCode.countryId));
                postalCodes.push({
                    key: value,
                    value: 'Postal Code',
                    label: value + ', ' + country[0].label,
                    countryId: postalCode.countryId
                });
            });
        });
        return postalCodes;
    }

    prepareLocations(): Array<Option> {
        return this.locationHelper.database.map(l =>
            new LocationTargetOption(l)
        ).filter(this.isValidLocationOption.bind(this));
    }

    isValidLocationOption(location: LocationTargetOption) {
        if (this.restrict.indexOf(location.locationType) > -1){ return false; }
        let unitedStatesOnly = this.unitedStatesOnly;
        if (unitedStatesOnly) {
            return (location.value.country && location.value.country.id === LocationTargetingComponent.UNITED_STATES_ID)
                || (!location.value.country && location.value.id === LocationTargetingComponent.UNITED_STATES_ID);
        }
        return true;
    }

    updateLocations() {

        this.filteredLocations = this.options.locations.filter(location => {
            for (let item of this.items) {
                if (item.key === location.key && item.label === location.label) { return false; }
            }
            return true;
        });
        this.cdr.detectChanges();
    }

    set targeting(value) {
        this._targeting = value;
        this._targetingChange.emit(this._targeting);
    }

    get targeting() {
        return this._targeting;
    }
}

class LocationTargetOption implements Option {
    constructor(private location) { }

    get key() {
        return this.location.id;
    }

    get value() {
        return this.location;
    }

    get label() {
        return this.location.display;
    }
    get locationType() {
        return this.location.type;
    }
}
