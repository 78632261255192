import { Model } from './model';

export class BidSwitchAgency extends Model {
    version: number;
    name: string;
    bidswitchAgencyId: string;
    buyerId: number;
    domains: string[];

    serialize() {
        const agency = {
            id: this.id,
            version: this.version,
            name: this.name,
            bidswitchAgencyId: this.bidswitchAgencyId,
            buyerId: this.buyerId,
            domains: this.domains

        }
        return JSON.stringify(agency);
    }

    get entity() {
        return 'BidSwitchAgency';
    }
}
