import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';

@Injectable()
export class ContentTypeInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler) {

        let headers = req.headers;
        if (!headers.has('Content-Type')) {
            headers = req.headers.set('Content-Type', 'application/json');
        }


        if (!headers.has('Accept')) {
            headers = headers.set('Accept', 'application/json');
        }

        const contentTypeReq = req.clone({ headers });
        return next.handle(contentTypeReq);
    }

}
