import { Action, Entity, Role } from 'app/core/authorization.service';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AuthorizationService, IdService, ValidationService } from 'app/core';
import { Creative, LineItem, Native } from 'app/shared/models';
import { AssetField, AssetFieldProperties } from 'app/shared/models/creative';
import { NgForm } from '@angular/forms';
import { AdBuilderNativePreviewComponent } from 'app/shared/components/ad-builder/ad-builder-native-preview';
import validationErrors from 'app/core/errors/validation-errors.json';
import { SearchInputComponent } from 'app/shared/elements/search-input/search-input.component';
import {
    AdvertiserRepository,
    BlueprintRepository,
    LineItemRepository,
    MediaGroupRepository,
    PublisherRepository
} from 'app/core/repositories';
import { map, mergeMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { SearchableSelectSingleComponent } from 'app/shared/elements/searchable-select-single';
import { sortBlueprintOptionsByName } from 'app/shared/helpers/blueprint-helper';

enum Field {
    body,
    adBlueprintId,
}

@Component({
    selector: 'ad-builder-native',
    templateUrl: './ad-builder-native.html',
    providers: [BlueprintRepository]
})
export class AdBuilderNativeComponent implements OnInit {
    @Input() advertiserId: string;
    @Input() campaign: any;
    @Input() lineItem: LineItem;
    @Input() set placementId(value) {
        if (value) {
            this._placementId$.next(value);
            this.hasPlacementTargetingStrategy = true;
        } else {
            this.hasPlacementTargetingStrategy = false;
        }
    }
    @Input() creative = this.createCreative();
    @Input() showThirdPartyTracker = false;
    @Input() showStaqId = false;
    @Input() showExternalId = false;
    @Input() showThirdPartyFields = false;
    @Input() allowBlueprintChange = true;
    @Input() isNativeCreativeEdit = false;

    @ViewChild(NgForm, { static: true }) form: NgForm;
    @ViewChild(SearchInputComponent, { static: true }) searchInput: SearchInputComponent;
    @ViewChild('preview', {static: false}) preview: AdBuilderNativePreviewComponent;
    @ViewChild('previewMobile', {static: false}) previewMobile: AdBuilderNativePreviewComponent;


    readonly update$ = new ReplaySubject<void>(1);
    _placementId$ = new BehaviorSubject<any>('');

    validator = new ValidationService();
    validationErrors = validationErrors;
    blueprintOptions$ = new ReplaySubject(1);

    public search: any;

    _blueprints = [];

    assetField = new AssetField();
    assetFieldProperties = AssetFieldProperties;
    Native = Native;
    action = Action;
    role = Role;
    entity = Entity;
    hasPlacementTargetingCampaign: Boolean = false;
    hasPlacementTargetingStrategy: Boolean = false;

    constructor(
        public auth: AuthorizationService,
        public id: IdService,
        private cdr: ChangeDetectorRef,
        private blueprintRepository: BlueprintRepository,
        private advertiserRepository: AdvertiserRepository,
        private lineItemRepository: LineItemRepository,
        private publisherRepository: PublisherRepository,
        private mediaGroupRepository: MediaGroupRepository,
    ) {
    }

    ngOnInit() {
        for (const prop of this.assetFieldProperties) {
            this.assetField[prop.modelKey] = prop;
        }
        this.getMediaGroup().pipe(mergeMap(mgId => this.blueprintRepository.basicSearch(mgId)
            .pipe(map(blueprints => {
                sortBlueprintOptionsByName(blueprints);
                return blueprints.map(item => {
                    this._blueprints.push(item);
                    return {
                        value: item.id,
                        key: item.id,
                        label: `(ID:${item.id}) ${item.displayName}`,
                    };
                })
            })),
        )).subscribe(blueprints => {
            this.blueprintOptions$.next(blueprints);
            if (this.isNativeCreativeEdit) {
                this.setBlueprintId(this.creative.blueprintId);
            }
        });

        if (this.campaign && this.lineItem) {
            this.lineItemRepository.search({
                conditions: [{
                    field: 'campaign',
                    value: this.campaign.id
                }]
            }).subscribe(lineItems => {
                const lineItemsWPlacementTargeting = lineItems
                    .filter(lineItem => !(lineItem.placementId === '' || (!!this.lineItem.id && this.lineItem.id === lineItem.id)));
                this.hasPlacementTargetingCampaign = lineItemsWPlacementTargeting.length > 0;
            });
        }
    }

    getMediaGroup(): Observable<number> {
        return this.advertiserRepository.get(this.advertiserId)
            .pipe(
                mergeMap(advertiser => {
                    if (advertiser.owner.type === 'Publisher') {
                        return this.publisherRepository.get(advertiser.owner.refId)
                            .pipe(
                                mergeMap(owner => this.mediaGroupRepository.get(owner.mediaGroup))
                            )
                    }

                    return this.mediaGroupRepository.get(advertiser.owner.refId);
                }),
                map(mediaGroup => mediaGroup.refId)
            );
    }

    createCreative() {
        return new Creative({
            type: 'image',
            isNative: true
        });
    }

    bodyFieldMaxCharacter(width: number): number {
        if (!this.creative.thumbnailUrl) {
            return 200;
        }
        switch (true) {
            case width <= 300:
                return 25;
            case width > 300 && width <= 728:
                return 50;
            case width > 728:
                return 90;
        }
    }

    addError(field: string, errorCode?: {}) {
        errorCode = errorCode || {incorrect: true};
        if (field) {
            this.form.form.controls[field].setErrors(errorCode);
        }
    }

    removeError(field: string) {
        if (field) {
            this.form.form.controls[field].setErrors(null);
        }
    }

    updateFieldChar($event, fieldName: string) {
        const charCount = $event ? $event.length : 0;
        let maxChar = this.assetField[fieldName].maxChar;
        if (fieldName === 'body') {
            maxChar = this.bodyFieldMaxCharacter(this.creative.blueprintWidth);
        }

        this.assetField[fieldName].charRemaining = maxChar - charCount;
        this.assetField[fieldName].charCount = charCount;
        this.refresh();
    }

    isCharCountHidden(fieldName) {
        if (fieldName === 'body') {
            return !this.assetField[fieldName].isFocused || !this.isBlueprintWidthSet();
        }

        return !this.assetField[fieldName].isFocused;
    }

    onFieldFocus(fieldName: string) {
        const currentFieldValue = this.form.form.controls[fieldName].value || '';
        if (this.assetField[fieldName]) {
            this.assetField[fieldName].isFocused = true;
            this.updateFieldChar(currentFieldValue, fieldName);
        }
    }

    onFieldBlur(fieldName: string) {
        if (this.assetField[fieldName]) {
            this.assetField[fieldName].isFocused = false;
        }
    }

    exceededMaxChar(fieldName: string) {
        if (this.assetField[fieldName]) {
            if (fieldName === 'body') {
                return !this.isBlueprintNotSelected && this.assetField[fieldName].charRemaining < 0;
            }

            return this.assetField[fieldName].charRemaining < 0;
        }
    }

    setBlueprintId(id: number) {
        if (id) {
            this.creative.blueprintId = id;
            const blueprintObject = this._blueprints.find(blueprint => blueprint.id === this.creative.blueprintId);
            if (blueprintObject && ('width' in blueprintObject)) {
                this.creative.blueprintWidth = blueprintObject.width;
                if (blueprintObject && ('maxHeight' in blueprintObject)) {
                    this.creative.height = blueprintObject.maxHeight;
                    this.creative.width = blueprintObject.width;
                }
            }
            this.creative.blueprintName = this._blueprints.find(blueprint => blueprint.id === this.creative.blueprintId).name;
            this.removeError(Field[Field.adBlueprintId]);
            if (this.hasError(Field[Field.body], 'incorrect')) {
                this.removeError(Field[Field.body]);
            }
        } else {
            this.creative.blueprintId = null;
            this.creative.blueprintWidth = null;
            this.creative.blueprintName = null;
            this.addError(Field[Field.adBlueprintId]);
        }
    }

    isBlueprintWidthSet() {
        if (this.creative.blueprintId && !this.creative.blueprintWidth) {
            if (this._blueprints.length > 0) {
                this.setBlueprintId(this.creative.blueprintId);
            }
        }
        return !!this.creative.blueprintWidth;
    }

    focusIfBlueprintIsDeselected(model: SearchableSelectSingleComponent) {
        if (!model._selected) {
            model.editor.nativeElement.focus();
        }
    }

    get isBlueprintNotSelected() {
        return !this.isBlueprintWidthSet();
    }

    updateIfaasUrl(url: string) {
        const img = new Image();
        img.src = url;

        this.creative.mediaUrl = url;
    }

    reset() {
        this.form.reset();
        this.preview.reset();
        this.previewMobile.reset();
        this.cdr.detectChanges();
        this.creative = this.createCreative();
        this.validateClickUrlProtocol();
    }

    validateClickUrlProtocol() {
        if (this.creative.clickUrl) {
            this.creative.clickUrl = this.creative.clickUrl
                // This is for an edge case where the user deletes the initial ht.*
                .replace(/^h?t?t?p?s?:?\/?\/?$/, 'https://')
                .replace(/^(.*\/\/)?/g, 'https://');
        }
    }

    hasError(field: string, code?: string) {
        const {errors} = this.form.form.controls[field];
        if (errors) {
            if (code) {
                return errors[code];
            }
            return errors.incorrect;
        }
        return errors;
    }

    onUpdateImageUrl() {
        if (this.creative.body) {
            this.updateFieldChar(null, Field[Field.body]);
            if (!(this.assetField['body'].charRemaining < 0) && this.hasError(Field[Field.body], 'invalidLength')) {
                this.removeError(Field[Field.body]);
            }
            return;
        }

        this.refresh();
    }

    refresh() {
        this.preview.refresh();
        this.previewMobile.refresh();
        this.validateClickUrlProtocol();
    }

    setIsModified() {
        this.creative.isModified = true;
    }

    get isBlueprintRequired(){
        return this.campaign && (this.isDirectSoldGuaranteed || this.campaign.isHouse || this.hasPlacementTargetingCampaign
            || this.hasPlacementTargetingStrategy);
    }

    get readOnly() {
        return (this.campaign && !this.auth.canManageMediaGroup && !this.auth.canManagePublisher && !this.auth.isAdOps
            && !this.auth.isAdmin);
    }

    get isDirectSoldGuaranteed() {
        return this.campaign && this.campaign.isDirectSold && this.campaign.isGuaranteed;
    }

    handleOnShowSecondaryTracker(isExpanded: boolean) {
        if (isExpanded === false) {
            this.creative.urlTracking2 = null;
        }
    }
}
