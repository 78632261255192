<div [attr.id]="id.container('overlay-loading-indicator')" class="loading-indicator flex flex-column flex-align-center flex-justify-center fill-vertical">
    <div class="spinner-container">
        <div class="spinner--small">
            <div class="grayDot1"></div>
            <div class="grayDot2"></div>
        </div>
    </div>
    <ng-template [ngTemplateOutlet]="template" *ngIf="template; else default"></ng-template>
    <ng-template #default>
        <h5 class="copy--supporting">Loading...</h5>
    </ng-template>
</div>
