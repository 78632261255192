import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Metro } from 'app/shared/models';
import { BackendRepository } from './backend-repository';

@Injectable()
export class MetroRepository extends BackendRepository<Metro> {
    public constructor(http: HttpClient) {
        super(http, '/metro', Metro);
    }
}
