import {
    Attribute,
    Component,
    QueryList,
    ViewChild,
    ViewChildren
} from "@angular/core";
import {HasDirtyCheck, Resettable} from "app/shared/components/bulk-edit-lightbox";
import {Model} from "app/shared/models";
import {IdService} from "app/core";
import {EspFieldOverride} from "app/shared/models/mixin/esp-field-override";
import {EmailServiceProviderCardComponent} from "app/shared/components/email-service-provider-card";
import {BehaviorSubject} from "rxjs";

@Component({
    selector: 'bulk-edit-esp',
    templateUrl: './bulk-edit-esp.component.html',
    styleUrls: ['./bulk-edit-esp.component.styl']
})
export class BulkEditEspComponent <T extends Model & EspFieldOverride> implements Resettable, HasDirtyCheck {
    @ViewChildren('card') cards: QueryList<EmailServiceProviderCardComponent<T>>;
    @ViewChild('master', { static: false }) masterCard: EmailServiceProviderCardComponent<MasterEspFieldOverride>;

    private _entities: T[] = [];
    private _originals: T[] = [];

    context: string; // Support `publisher` or `newsletter`
    label: string;
    entityName = '';

    espCards: EmailServiceProviderCardComponent<T>[] = [];

    master = new MasterEspFieldOverride();

    dirtyEntitiesByIndexMap$: BehaviorSubject<Array<boolean>> = new BehaviorSubject<Array<boolean>>([]);

    constructor(
        @Attribute('context') context: string,
        @Attribute('label') label: string,
        public id: IdService,
    ) {
        this.context = context;
        this.label = label;
    }

    updateEsp(value: number|string, index: number) {
        this.entities[index].esp = value;
    }

    updateEmailTagReplacement(value: string, index: number) {
        this.entities[index].emailTagReplacement = value;
    }

    updateEmailTagType(value: string, index: number) {
        this.entities[index].emailTagType = value;
    }

    updatePlacementTagReplacement(value: string, index: number) {
        this.entities[index].placementTagReplacement = value;
    }

    updateListTagReplacement(value: string, index: number) {
        this.entities[index].listTagReplacement = value;
    }

    updateLceeKeyReplacement(value: string, index: number) {
        this.entities[index].lceeKeyReplacement = value;
    }

    updateLceeTagReplacement(value: string, index: number) {
        this.entities[index].lceeTagReplacement = value;
    }

    validEspSetting(index) {
        return (this.entities[index].esp !== null);
    }

    processingUpdate = false;

    updateAllEspSettings(master: T) {
        this.entities.forEach((entity, index) => {
            this.updateEsp(master.esp, index);
            this.updateEmailTagReplacement(master.emailTagReplacement, index);
            this.updateEmailTagType(master.emailTagType, index);
            this.updatePlacementTagReplacement(master.placementTagReplacement, index);
            this.updateListTagReplacement(master.listTagReplacement, index);
            this.updateLceeKeyReplacement(master.lceeKeyReplacement, index);
            this.updateLceeTagReplacement(master.lceeTagReplacement, index);

            let card = this.cards.toArray()[index];
            let updatedEntity = this.entities[index];
            card.entity = updatedEntity;

            this.updateDirtyCheck(index, this.checkDirty(index));
        });
    }

    updateEspSettings(entity: T, index) {
        this.updateEsp(entity.esp, index);
        this.updateEmailTagReplacement(entity.emailTagReplacement, index);
        this.updateEmailTagType(entity.emailTagType, index);
        this.updatePlacementTagReplacement(entity.placementTagReplacement, index);
        this.updateListTagReplacement(entity.listTagReplacement, index);
        this.updateLceeKeyReplacement(entity.lceeKeyReplacement, index);
        this.updateLceeTagReplacement(entity.lceeTagReplacement, index);

        this.updateDirtyCheck(index, this.checkDirty(index));
    }

    isDirty(): boolean {
        let hasDirty = false;
        this.entities.forEach((entity, index) => {
            let checkDirty =  this.checkDirty(index);
            if (checkDirty) {
                hasDirty = true;
            }
            this.updateDirtyCheck(index, checkDirty);

        });
        return hasDirty || this.master.isDirty();
    }

    updateDirtyCheck(index: number, value: boolean) {
        const currentMap = {...this.dirtyEntitiesByIndexMap$.value};
        currentMap[index] = value ? value : false;
        this.dirtyEntitiesByIndexMap$.next(currentMap);
    }

    checkDirty(index: number): boolean {
        if (!index && index !== 0) {
            return false;
        }

        let original = this.original(index);
        let entity = this.entities[index];

        return original.esp !== entity.esp
            || original.espName !== entity.espName
            || original.emailTagReplacement !== entity.emailTagReplacement
            || original.emailTagType !== entity.emailTagType
            || original.placementTagReplacement !== entity.placementTagReplacement
            || original.listTagReplacement !== entity.listTagReplacement
            || original.lceeKeyReplacement !== entity.lceeKeyReplacement
            || original.lceeTagReplacement !== entity.lceeTagReplacement
    }

    resetEntity(index: number): void {
        // If the card isn't dirty, then nothing to reset
        if (! this.dirtyEntitiesByIndexMap$.getValue()[index]) {
            return;
        }

        let original = this.original(index);
        let entity = this.entities[index];

        entity.esp = original.esp;
        entity.espName = original.espName;
        entity.emailTagReplacement = original.emailTagReplacement;
        entity.emailTagType = original.emailTagType;
        entity.placementTagReplacement = original.placementTagReplacement;
        entity.listTagReplacement = original.listTagReplacement;
        entity.lceeKeyReplacement = original.lceeKeyReplacement;
        entity.lceeTagReplacement = original.lceeTagReplacement;

        let card = this.cards.toArray()[index];
        card.entity = entity;
        card.loadEspSettings(entity, true);

        this.updateDirtyCheck(index, false);
    }

    reset(): void {
        this.master.reset();
        this.masterCard.entity = this.master;
        this.masterCard.loadEspSettings(this.master, true);
        this.entities.forEach(
            (entity, index) => this.resetEntity(index)
        );
    }

    applyPublisherSettingsToAll(): void {
        this.master.reset();
        this.masterCard.entity = this.master;
        this.masterCard.loadEspSettings(this.master, true);
        this.entities.forEach(
            (entity, index) => this.applyPublisherSetting(index)
        );
    }

    applyPublisherSetting(index) {
        const entity = this.entities[index];
        const card = this.cards.toArray()[index];
        card.entity = entity;
        card.loadEspSettings(entity.publisherObj, true);

        this.updateDirtyCheck(index, true);
    }

    original(index: number): T {
        return this._originals[index];
    }

    set entities(entities: T[]) {
        this._entities = entities;
        this._originals = entities.map(entity => entity.clone());
    }

    get entities(): T[] {
        return this._entities;
    }
}

class MasterEspFieldOverride extends Model implements EspFieldOverride {
    entity: string;

    esp: number | string;
    espName: string;
    emailTagReplacement: string;
    emailTagType: string;
    placementTagReplacement: string;
    listTagReplacement: string;
    lceeKeyReplacement: string;
    lceeTagReplacement: string;

    reset() {
        this.esp = null;
        this.espName = null;
        this.emailTagReplacement = null;
        this.emailTagType = null;
        this.placementTagReplacement = null;
        this.listTagReplacement = null;
        this.lceeKeyReplacement = null;
        this.lceeTagReplacement = null;
    }

    isDirty(): boolean {
        return this.esp !== null
            || this.emailTagReplacement !== null
            || this.emailTagType !== null
            || this.placementTagReplacement !== null
            || this.listTagReplacement !== null
            || this.lceeKeyReplacement !== null
            || this.lceeTagReplacement !== null
    }

    serialize(): string {
        return super.serialize();
    }
}
