import { Component, ElementRef, AfterContentInit, ViewChild } from '@angular/core';

@Component({
    selector: 'blink',
    templateUrl: './blink.component.html',
    styleUrls: ['./blink.component.styl']
})
export class BlinkComponent implements AfterContentInit {
    @ViewChild('container', { static: true }) container: ElementRef;

    ngAfterContentInit() {
        const chars = this.container.nativeElement.innerHTML.trim().split('');
        this.container.nativeElement.innerHTML = chars.map(c => `<span>${c}</span>`).join('');
    }
}
