import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IdService } from 'app/core';
import { SupplyAssetService } from 'app/core/supply-asset.service';
import { IABAssets, SupplyAsset, SupplyAssetProperties, SupplyImageAssetType, SupplyAssetPayload } from 'app/shared/models/native-supply';
import { of } from 'rxjs';

@Component({
    selector: 'blueprint-asset-restrictions',
    templateUrl: './blueprint-asset-restrictions.html',
    styleUrls: ['./blueprint-asset-restrictions.styl'],
    providers: [SupplyAssetService]
})
export class BlueprintAssetRestrictions {
    @ViewChild('assetCharacterForm', { static: true }) public assetCharacterForm: NgForm;
    @ViewChild('assetDescriptionForm', { static: true }) public assetDescriptionForm: NgForm;

    @Input() set blueprint(bp) {
        this._blueprint = bp;
        this.supplyAssetService.initSupplyAssets(this.supplyAssets);
        this.supplyAssetService.setAssets(this.blueprint, this.supplyAssets);

        if (this.supplyAssets && this.supplyAssets.mainImage && this.supplyAssets.mainImage.isExact) {
            this.imageDimensionType = 'exact';
        }
    };
    get blueprint() {
        return this._blueprint;
    }
    @Input() allowExchange;
    @Input() disableInput = false;
    @Input() hideLogo = false;
    @Input() set macros(value: string[]) {
        this.availableMacros = value;
        this.update();
    }

    availableMacros = [];
    supplyAssetProperties = SupplyAssetProperties;
    supplyAssets = new SupplyAsset();
    _blueprint;

    imageDimensionType = "min";
    isInitialized = false;

    constructor(
        public supplyAssetService: SupplyAssetService,
        public id: IdService
    ) {}

    ngOnInit(): void {
        this.supplyAssetService.initSupplyAssets(this.supplyAssets);
        this.supplyAssetService.setAssets(this.blueprint, this.supplyAssets);
        if (this.supplyAssets && this.supplyAssets.mainImage && this.supplyAssets.mainImage.isExact) {
            this.imageDimensionType = 'exact';
        }
        this.isInitialized = true;
    }


    update() {
        if (this.isInitialized) {
            if (this.availableMacros && this.blueprint.isCustom) {
                for (let prop of this.supplyAssetProperties) {
                    if (this.availableMacros.indexOf(prop.macro) < 0) {
                        this.supplyAssets[prop.modelKey].required = false;
                    }
                }
            }
            this.blueprint.assets = this.updateAssets();
        }
    }
    /**
     * Update Assets
     * @returns {any[]}
     */
    updateAssets(): SupplyAssetPayload[] {
        const assets = [];
        for (let prop of this.supplyAssetProperties) {
            if (this.availableMacros && this.blueprint.isCustom) {
                if (this.availableMacros.indexOf(prop.macro) < 0) {
                    continue;
                }
            }

            const supplyAsset = this.supplyAssets[prop.modelKey];
            const key = this.supplyAssets[prop.modelKey].typeDescription;
            if (!key) {
                continue;
            }

            let asset: SupplyAssetPayload = {
                id: supplyAsset.id || null,
                required: supplyAsset.required || false,
                version: supplyAsset.version || null,
                [key]: {} 
            };
            
            switch (supplyAsset.typeDescription) {
                case IABAssets.title:
                    asset[key].len = supplyAsset.model;
                    break;
                case IABAssets.data:
                    asset[key].len = supplyAsset.model;
                    asset[key].type = supplyAsset.typeId;
                    break;
            
                case IABAssets.img:
                    asset[key].type = supplyAsset.typeId;
                    if (this.imageDimensionType === "exact" && prop.type === SupplyImageAssetType.Main) {
                        asset[key].width = supplyAsset.model.width;
                        asset[key].height = supplyAsset.model.height;
                    } else {
                        asset[key].minWidth = supplyAsset.model.width;
                        // [TODO] Needs to be renamed
                        asset[key].minheight = supplyAsset.model.height;
                    }
                    break;
            
                default:
                    break;
            }

            if (supplyAsset.model) {
                assets.push(asset);
            }
        }

        return assets;
    }
    
    public saveAssets() {
        if (this.blueprint.prefix === 'lin') {
            this.blueprint.assets = this.updateAssets();
            return this.supplyAssetService.saveAssets(this.blueprint);
        }
        return of(this.blueprint);

    }

    disableField(macro, modelKey) {
        if (this.availableMacros && this.availableMacros.length === 0) {
            return this.blueprint && this.supplyAssets[modelKey].id === null && this.blueprint.id != null;
        }
        return this.availableMacros && this.availableMacros.length > 0 && this.blueprint && this.blueprint.isCustom !== false && this.availableMacros.indexOf(macro) < 0;
    }

    isValid() {
        return this.blueprint.assets && this.blueprint.assets.some(asset => asset.required);
    }

}
