import { Component, ViewChild, OnDestroy, OnInit, Input, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, fromEvent } from 'rxjs';

import { PreferencesService } from 'app/core/preferences.service';
import { AuthenticationService } from 'app/core/authentication.service';
import { PopoverComponent } from 'app/shared/elements/popover';
import { throttleTime, filter, first } from 'rxjs/operators';
import { IdService } from 'app/core/id.service';
import { GAEventCategory, GAHelper } from 'app/shared/helpers/ga-helper';
import { NavigationService } from 'app/core';

@Component({
    selector: 'profile',
    templateUrl: './profile.html',
    styleUrls: ['./profile.styl']
})
export class ProfileComponent implements OnInit, OnDestroy {
    @ViewChild(PopoverComponent, { static: false }) popover: PopoverComponent = null;
    @Input() target: ElementRef;

    private resize: Subscription;

    constructor(
        public preferences: PreferencesService,
        public auth: AuthenticationService,
        public navigationService: NavigationService,
        private router: Router,
        public id: IdService
    ) { }

    ngOnInit() {
        this.resize = fromEvent(window, 'resize').pipe(
            throttleTime(1000), // Ignore subsequent events for one second.
            filter(() => this.popover.visibility === 'visible')
        ).subscribe(() => this.closeProfilePopover());
    }

    ngOnDestroy() {
        this.resize.unsubscribe();
    }

    viewSelectedAccounts() {
        this.router.navigate(['/accounts']);
        this.closeProfilePopover();
    }

    logout() {
        if (this.auth.isLoggedInAs) {
            this.auth.logoutAs().pipe(first()).subscribe(() => this.router.navigate(['/internal-tools']));
        } else {
            this.navigationService.redirectToLogout();
        }
        GAHelper.trackEvent(GAEventCategory.USER_ACCOUNT_INTERACTION, `Logout`, `Profile : Logout`)
        this.closeProfilePopover();
    }

    private closeProfilePopover() {
        this.popover.hide();
    }
}
