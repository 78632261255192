import { Newsletter } from "app/shared/models";
import { IdService } from "app/core";
import { clone } from "app/core/utils";
import NEWSLETTER_TYPES from "app/shared/models/newsletter-types.json";
import { Flag, LaunchDarklyService } from "app/core/launch-darkly.service";
import { distinctUntilChanged } from "rxjs/operators";
var BulkEditNewsletterUniqueAdsComponent = /** @class */ (function () {
    function BulkEditNewsletterUniqueAdsComponent(label, id, launchDarklyService) {
        this.id = id;
        this.launchDarklyService = launchDarklyService;
        this._newsletters = [];
        this.originals = new Map();
        this.rolloutUamEnhancements = false;
        this._useDifferentSourcesAdvertisersMain = false;
        this._useDifferentSourcesExternalAdvertisersMain = false;
        this.Newsletter = Newsletter;
        this.NEWSLETTER_TYPES = NEWSLETTER_TYPES;
        this.ADVERTISER_NEWSLETTER_TYPES = [
            NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER,
            NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER_DISPLAY,
            NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER_NATIVE
        ];
        this.EXTERNAL_ADVERTISER_NEWSLETTER_TYPES = [
            NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.EXTERNAL_ADVERTISERS,
            NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.EXTERNAL_ADVERTISERS_DISPLAY,
            NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.EXTERNAL_ADVERTISERS_NATIVE
        ];
        this.label = label;
    }
    BulkEditNewsletterUniqueAdsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.launchDarklyService
            .getVariation(Flag.RolloutUAMEnhancements)
            .pipe(distinctUntilChanged())
            .subscribe(function (rolloutUamEnhancements) { return _this.rolloutUamEnhancements = rolloutUamEnhancements; });
    };
    BulkEditNewsletterUniqueAdsComponent.prototype.updateAllNewsletters = function (value) {
        var _this = this;
        this.entities.forEach(function (entity) {
            _this.updateUniqueAds(entity, value);
        });
    };
    BulkEditNewsletterUniqueAdsComponent.prototype.isNewsletterDirty = function (newsletter) {
        var originalNewsletter = this.original(newsletter.refId);
        if ((!!originalNewsletter.sspControl) !== (!!newsletter.sspControl)) {
            return true;
        }
        var originalUniqueAds = originalNewsletter.sspControl ? originalNewsletter.sspControl.uniqueAds : "not_set";
        var newsletterUniqueAds = newsletter.sspControl ? newsletter.sspControl.uniqueAds : "not_set";
        return originalUniqueAds !== newsletterUniqueAds;
    };
    BulkEditNewsletterUniqueAdsComponent.prototype.isDirty = function () {
        var _this = this;
        return this.entities.some(function (newsletter) { return _this.isNewsletterDirty(newsletter); });
    };
    BulkEditNewsletterUniqueAdsComponent.prototype.isUniqueAdsEnabled = function (newsletter) {
        var id = newsletter.refId;
        if (!newsletter.sspControl
            || !newsletter.sspControl.uniqueAds
            || newsletter.sspControl.uniqueAds === NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.OFF
            || newsletter.sspControl.uniqueAds === NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.INHERIT) {
            return false;
        }
        return Object.values(NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS).includes(newsletter.sspControl.uniqueAds);
    };
    BulkEditNewsletterUniqueAdsComponent.prototype.updateUniqueAds = function (newsletter, value) {
        var currentUniqueAdsValue = newsletter.sspControl.uniqueAds || null;
        if (!newsletter.sspControl.uniqueAds) {
            newsletter.sspControl['uniqueAds'] = null;
        }
        if (typeof value === 'string') {
            newsletter.sspControl.uniqueAds = value;
        }
        else {
            newsletter.sspControl.uniqueAds = value
                ? NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER
                : NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.OFF;
        }
    };
    BulkEditNewsletterUniqueAdsComponent.prototype.original = function (refId) {
        return this.originals.get(refId);
    };
    Object.defineProperty(BulkEditNewsletterUniqueAdsComponent.prototype, "entities", {
        get: function () {
            return this._newsletters;
        },
        set: function (entities) {
            var _this = this;
            this._newsletters = entities;
            this.originals.clear();
            entities.forEach(function (newsletter) {
                _this.originals.set(newsletter.refId, newsletter.clone());
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BulkEditNewsletterUniqueAdsComponent.prototype, "useDifferentSourcesMain", {
        get: function () {
            return this._useDifferentSourcesMain;
        },
        set: function (value) {
            this._useDifferentSourcesMain = value;
            this._adSourceMain = value
                ? NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER
                : null;
            this.updateAllNewsletters(this._adSourceMain);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BulkEditNewsletterUniqueAdsComponent.prototype, "adSourceMain", {
        get: function () {
            return this._adSourceMain;
        },
        set: function (value) {
            this._adSourceMain = value;
            this.updateAllNewsletters(this._adSourceMain);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BulkEditNewsletterUniqueAdsComponent.prototype, "useDifferentSourcesAdvertisersMain", {
        get: function () {
            return this.ADVERTISER_NEWSLETTER_TYPES.includes(this._adSourceMain);
        },
        set: function (value) {
            this._useDifferentSourcesAdvertisersMain = value;
            this._useDifferentSourcesExternalAdvertisersMain = null;
            this._adSourceMain = value
                ? NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER
                : null;
            this.updateAllNewsletters(this._adSourceMain);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BulkEditNewsletterUniqueAdsComponent.prototype, "useDifferentSourcesExternalAdvertisersMain", {
        get: function () {
            return this.EXTERNAL_ADVERTISER_NEWSLETTER_TYPES.includes(this._adSourceMain);
        },
        set: function (value) {
            this._useDifferentSourcesExternalAdvertisersMain = value;
            this._useDifferentSourcesAdvertisersMain = null;
            this._adSourceMain = value
                ? NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.EXTERNAL_ADVERTISERS
                : null;
            this.updateAllNewsletters(this._adSourceMain);
        },
        enumerable: true,
        configurable: true
    });
    BulkEditNewsletterUniqueAdsComponent.prototype.revert = function (newsletter) {
        newsletter.sspControl.uniqueAds = clone(this.original(newsletter.refId).sspControl.uniqueAds);
    };
    BulkEditNewsletterUniqueAdsComponent.prototype.revertAll = function () {
        var _this = this;
        this._useDifferentSourcesMain = false;
        this._adSourceMain = null;
        this.entities.forEach(function (newsletter) { return _this.revert(newsletter); });
    };
    BulkEditNewsletterUniqueAdsComponent.prototype.isNewsletterRequiringUniqueAdvertiserSources = function (newsletter) {
        return this.ADVERTISER_NEWSLETTER_TYPES.includes(newsletter.sspControl.uniqueAds);
    };
    BulkEditNewsletterUniqueAdsComponent.prototype.isNewsletterRequiringUniqueExternalAdvertiserSources = function (newsletter) {
        return this.EXTERNAL_ADVERTISER_NEWSLETTER_TYPES.includes(newsletter.sspControl.uniqueAds);
    };
    return BulkEditNewsletterUniqueAdsComponent;
}());
export { BulkEditNewsletterUniqueAdsComponent };
