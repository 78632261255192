import * as tslib_1 from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { BackendRepository } from './backend-repository';
import { HistoryResponse, Model } from 'app/shared/models';
import { HistoryItem } from 'app/shared/models';
import { map } from 'rxjs/operators';
import { SnakeToCamelCasePipe } from 'app/shared/elements/pipes';
export var HistoryEntity;
(function (HistoryEntity) {
    HistoryEntity["AdSlot"] = "ad-slot";
    HistoryEntity["Advertiser"] = "advertiser";
    HistoryEntity["Audience"] = "audience";
    HistoryEntity["Campaign"] = "campaign";
    HistoryEntity["Creative"] = "creative";
    HistoryEntity["LineItem"] = "line-item";
    HistoryEntity["Newsletter"] = "newsletter";
    HistoryEntity["Publisher"] = "publisher";
    HistoryEntity["RTBPartner"] = "rtb-partner";
    HistoryEntity["User"] = "user";
})(HistoryEntity || (HistoryEntity = {}));
var LIES = /** @class */ (function (_super) {
    tslib_1.__extends(LIES, _super);
    function LIES() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LIES.prototype.serialize = function () {
        return null;
    };
    Object.defineProperty(LIES.prototype, "entity", {
        get: function () {
            return 'LIES';
        },
        enumerable: true,
        configurable: true
    });
    return LIES;
}(Model));
var LIESRepository = /** @class */ (function (_super) {
    tslib_1.__extends(LIESRepository, _super);
    function LIESRepository(http, snakeToCamelCasePipe) {
        var _this = _super.call(this, http, '/lies', LIES) || this;
        _this.snakeToCamelCasePipe = snakeToCamelCasePipe;
        return _this;
    }
    LIESRepository.prototype.globalsearch = function (query, from) {
        if (from === void 0) { from = 5; }
        return this.http.get(this.url(this.path, 'globalsearch'), {
            params: { q: query },
        });
    };
    LIESRepository.prototype.eSearch = function (params) {
        return this.asyncSearch(params, '/lies/globalsearch');
    };
    LIESRepository.prototype.history = function (id, entity, page, modified) {
        var _this = this;
        if (page === void 0) { page = 1; }
        return this.http
            .get(this.url(this.path, 'history', entity, id), {
            params: this.createHistoryParams(page, modified),
        })
            .pipe(map(function (response) {
            return new HistoryResponse({
                items: response['output'].map(function (item) { return new HistoryItem(item); }),
                page: page,
                pages: response['pages'],
                total: response['total'],
                next: page < response['pages']
                    ? _this.history(id, entity, page + 1, modified)
                    : null,
            });
        }));
    };
    LIESRepository.prototype.createHistoryParams = function (page, modified) {
        var params = new HttpParams();
        if (page) {
            params = params.set('page', page.toString());
        }
        if (modified) {
            params = params.set('since', modified);
        }
        return params;
    };
    return LIESRepository;
}(BackendRepository));
export { LIESRepository };
