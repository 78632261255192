import { Directive, forwardRef, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import moment from 'moment';

@Directive({
    selector: '[min-time],[max-time]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => TimeValidator),
            multi: true
        }
    ]
})
export class TimeValidator implements Validator {
    @Input('min-time') minTime: string;
    @Input('max-time') maxTime: string;

    validate(control: AbstractControl) {
        if (control.value) {

            const validation = {};

            const time = moment(control.value, 'HH:mm');
            const minTime = moment(this.minTime);
            const maxTime = moment(this.maxTime);

            if (this.minTime && minTime.isAfter(this.cloneWithTime(minTime, time), 'minute')) {
                validation['min-time'] = true;
            }

            if (this.maxTime && maxTime.isBefore(this.cloneWithTime(maxTime, time), 'minute')) {
                validation['max-time'] = true;
            }

            return validation;
        }
    }

    private cloneWithTime(date: moment.Moment, time: moment.Moment) {
        const clone = date.clone();
        clone.set({
            hours: time.hours(),
            minutes: time.minutes()
        });
        return clone;
    }
}
