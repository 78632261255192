import { of, zip } from 'rxjs';
import { AdvertiserRepository, InsertionOrderRepository, CampaignRepository } from 'app/core/repositories';
import { map, mergeMap } from 'rxjs/operators';
import { Flag, LaunchDarklyService } from '../launch-darkly.service';
var CampaignFlowEditResolver = /** @class */ (function () {
    function CampaignFlowEditResolver(advertiserRepository, insertionOrderRepository, campaignRepository, launchDarklyService) {
        this.advertiserRepository = advertiserRepository;
        this.insertionOrderRepository = insertionOrderRepository;
        this.campaignRepository = campaignRepository;
        this.launchDarklyService = launchDarklyService;
    }
    CampaignFlowEditResolver.prototype.resolve = function (route) {
        var _this = this;
        var id = route.paramMap.get('id');
        if (id === null) {
            id = route.parent.paramMap.get('id');
        }
        return this.campaignRepository
            .get(id)
            .pipe(mergeMap(function (campaign) { return zip(_this.advertiserRepository.get(campaign.advertiser), _this.insertionOrderRepository.get(campaign.insertionOrder), _this.hasAudienceExtensionFlag(), of(campaign)).pipe(mergeMap(function (_a) {
            var advertiser = _a[0], insertionOrder = _a[1], audienceExtensionFlag = _a[2], campaign = _a[3];
            campaign.canAccessAudienceExtension = audienceExtensionFlag;
            if (campaign.parentCampaign) {
                return _this.campaignRepository.get(campaign.parentCampaign).pipe(map(function (parentCampaign) {
                    if (parentCampaign) {
                        return { advertiser: advertiser, insertionOrder: insertionOrder, campaign: campaign, parentCampaign: parentCampaign };
                    }
                    return { advertiser: advertiser, insertionOrder: insertionOrder, campaign: campaign };
                }));
            }
            else {
                return of({ advertiser: advertiser, insertionOrder: insertionOrder, campaign: campaign });
            }
        })); }));
    };
    CampaignFlowEditResolver.prototype.hasAudienceExtensionFlag = function () {
        return this.launchDarklyService
            .getVariation(Flag.RolloutAudienceExtension);
    };
    /**
     * Checks if campaign is eligible
     * to be an audience extendable campaign
     * @param parentCampaign
     * @param insertionOrder
     * @return boolean
     */
    CampaignFlowEditResolver.prototype.isAudienceExtensionParentCampaign = function (parentCampaign, insertionOrder) {
        return parentCampaign.isDirectSold && parentCampaign.insertionOrder == insertionOrder.id;
    };
    return CampaignFlowEditResolver;
}());
export { CampaignFlowEditResolver };
