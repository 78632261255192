import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ReportTarget } from '../report-target';
import { IFilterMetaObject } from './what-do-you-want-in-report/other-filters/filter-meta-object.interface';
import { AuthorizationService } from 'app/core/authorization.service';
import reportsFormOptions from './reports-form-options.json';

import {
    AdSlotRepository,
    AdvertiserRepository,
    CampaignRepository,
    CountryRepository,
    CreativeRepository,
    LineItemRepository,
    MetroRepository,
    NewsletterRepository,
    PublisherRepository,
    RegionRepository,
    UserRepository
} from 'app/core/repositories';
import { map, mergeMap } from 'rxjs/operators';
import { decorateAdvertisersWithNames, getManagersExecutivesForAdvertisers } from 'app/shared/helpers/advertiser-helper';

@Injectable()
export class ReportsFormService {
    static searchReturnMode = 'only';
    static advertiserReturnFields = ['id', 'refId', 'name', 'agencyName', 'mediaGroupName', 'publisher'];
    static agencyReturnFields = ['id', 'refId', 'name', 'managerName'];
    static publisherReturnFields = ['id', 'refId', 'name', 'mediaGroupName', 'mediaGroup'];
    static mediaGroupReturnFields = ['id', 'refId', 'name', 'managerName', 'executiveName'];
    static campaignReturnFields = ['id', 'refId', 'name', 'advertiser', 'advertiserName', 'insertionOrder',
        'startDate', 'endDate'];
    static lineItemReturnFields = ['id', 'refId', 'name', 'advertiser', 'advertiserName', 'campaign', 'campaignName',
        'startDate', 'endDate'];
    static creativeReturnFields = ['id', 'refId', 'name', 'advertiser', 'width', 'height', 'created'];
    static templateReturnFields = ['id', 'refId', 'name', 'publisher', 'created'];
    static sectionReturnFields = ['id', 'refId', 'name', 'publisher', 'publisherName', 'newsletter',
        'type', 'status', 'sizes'];
    static reportsFormOptions = reportsFormOptions;

    // These metrics are hidden from the UI and the generated report 
    // but are needed for the postaggs hence still part of the query
    static hiddenAdvertiserMetrics = [
        'UPA',
        'UPA (PCC)',
        'UPA (PVC)',
        'PCC',
        'PVC',
        'Intermediate PCC UPA',
        'Intermediate PVC UPA',
        'UPA PVC Final',
        'Intermediate PCC',
        'Intermediate PVC',
        'PVC Credit',
    ];

    constructor(
        private adSlotRepository: AdSlotRepository,
        private advertiserRepository: AdvertiserRepository,
        private campaignRepository: CampaignRepository,
        private countryRepository: CountryRepository,
        private creativeRepository: CreativeRepository,
        private lineItemRepository: LineItemRepository,
        private metroRepository: MetroRepository,
        private newsletterRepository: NewsletterRepository,
        private publisherRepository: PublisherRepository,
        private regionRepository: RegionRepository,
        private userRepository: UserRepository,
        private authorization: AuthorizationService,
        private router: Router
    ) { }

    /* - Begin of hacky code - */
    /* 'pushData' and 'popData' are used for sharing objects between components that live in different states/routes. */
    private data: any[] = [];

    public pushData(data): void {
        if (this.data.length > 0) {
            throw new Error('Data container already contains something and is not empty.');
        }

        this.data.push(data);
    }

    public popData(): any {
        return this.data.pop();
    }
    /* - End of hacky code - */

    public getReportTarget(): ReportTarget {
        if (this.router.url.indexOf('advertiser') > -1) {
            return ReportTarget.advertiser;
        } else {
            return ReportTarget.publisher;
        }
    }

    static getFilterTargetingOptions() {
        return this.reportsFormOptions.filterTargeting;
    }

    /**
     * Entity Filter Options
     */
    static getEntityFilterOptions(reportTarget: ReportTarget) {
        return this.reportsFormOptions[ReportTarget[reportTarget]].entityFilters;
    }

    /**
     * Selected Entities List
     */
    static buildSelectedListItem(resultData: any) {
        return {
            data: resultData,
            key: resultData.id,
            value: resultData.refId,
            label: resultData.name
        };
    }

    static buildSelectedLocationsListItem(resultData: any, locationType: any) {
        return {
            data: resultData,
            key: resultData.id,
            value: locationType,
            label: resultData.name
        };
    }

    /**
     * Advertiser Filter
     */

    searchAdvertisersByMediaGroupIds(mediaGroupsIds: string[]) {
        return this.searchAdvertisersBy('mediaGroup', mediaGroupsIds);
    }

    searchAdvertisersByRefIds(refIds: any[]) {
        return this.searchAdvertisersBy('refId', refIds);
    }

    searchAdvertisersByMyIds(advertiserHashIds: any[]) {
        return this.searchAdvertisersBy('id', advertiserHashIds);
    }

    private searchAdvertisersBy(field: string, value: any[]) {
        const payload = {
            conditions: [{ field, value }],
            return: ReportsFormService.advertiserReturnFields,
            returnMode: ReportsFormService.searchReturnMode
        };
        return this.advertiserRepository.search(payload).pipe(
            mergeMap(advs => getManagersExecutivesForAdvertisers(advs, this.userRepository)),
            mergeMap(decorateAdvertisersWithNames)
        ).toPromise();
    }

    /**
     * Publisher Filter
     */

    searchPublishersByIds(ids: string[]) {
        const payload = {
            conditions: [{
                field: 'id',
                value: ids
            }],
            return: ReportsFormService.publisherReturnFields,
            returnMode: ReportsFormService.searchReturnMode
        };
        return this.publisherRepository.all(payload).toPromise();
    }

    searchPublishersByRefIds(refIds: any[]) {
        const payload = {
            conditions: [{
                field: 'refId',
                value: refIds
            }],
            return: ReportsFormService.publisherReturnFields,
            returnMode: ReportsFormService.searchReturnMode
        };
        return this.publisherRepository.search(payload).toPromise();
    }

    searchPublishersByMediaGroups(mediaGroupId: any) {
        const payload = {
            conditions: [{
                field: 'mediaGroup',
                value: mediaGroupId
            }],
            return: ReportsFormService.publisherReturnFields,
            returnMode: ReportsFormService.searchReturnMode
        };
        return this.publisherRepository.search(payload).toPromise();
    }

    /**
     * Campaign Filter
     */

    searchAllDirectSoldAndHouseCampaigns() {
        const payload = {
            conditions: [{
                field: 'demandType',
                value: ['direct', 'house']
            }],
            return: ReportsFormService.campaignReturnFields,
            returnMode: ReportsFormService.searchReturnMode
        };
        return this.campaignRepository.all(payload).toPromise();
    }

    searchDirectAndHouseCampaignsByAdvertiserIds(advertiserIds: string[]) {
        const payload = {
            conditions: [
                {
                    field: 'demandType',
                    value: ['direct', 'house']
                },
                {
                    field: 'advertiser',
                    value: advertiserIds
                }
            ],
            return: ReportsFormService.campaignReturnFields,
            returnMode: ReportsFormService.searchReturnMode
        };
        return this.campaignRepository.all(payload).toPromise();
    }

    searchCampaignsByRefIds(refIds: any[]) {
        const payload = {
            conditions: [{
                field: 'refId',
                value: refIds
            }],
            return: ReportsFormService.campaignReturnFields,
            returnMode: ReportsFormService.searchReturnMode
        };
        return this.campaignRepository.search(payload).toPromise();
    }

    /**
     * Line Item Filter
     */

    searchAllLineItems() {
        const payload = {
            return: ReportsFormService.lineItemReturnFields,
            returnMode: ReportsFormService.searchReturnMode
        };
        return this.lineItemRepository.all(payload).toPromise();
    }

    searchLineItemsByAdvertiserIds(advertiserIds: string[]) {
        const payload = {
            conditions: [{
                field: 'advertiser',
                value: advertiserIds
            }],
            return: ReportsFormService.lineItemReturnFields,
            returnMode: ReportsFormService.searchReturnMode
        };
        return this.lineItemRepository.all(payload).toPromise();
    }

    searchLineItemsByRefIds(refIds: any[]) {
        const payload = {
            conditions: [{
                field: 'refId',
                value: refIds
            }],
            return: ReportsFormService.lineItemReturnFields,
            returnMode: ReportsFormService.searchReturnMode
        };
        return this.lineItemRepository.search(payload).toPromise();
    }

    searchIPOWLineItems(searchTerm: any[]) {
        const query = searchTerm.map((li) => '((refId:' + li + ') OR (name: *' + li + '*))').join(' OR ');
        const payload = {
            orderBy: 'name',
            n: 500,
            query: query,
            sort: 'DESC'
        };
        return this.lineItemRepository.asyncSearch(payload, '/lies/search/ipow-line-items').pipe(map(
            (resp) => {
                return resp.items;
            })
        ).pipe().toPromise();
    }

    /**
     * Creative Filter
     */

    searchCreativesByRefIds(refIds: any[]) {
        const payload = {
            conditions: [{
                field: 'refId',
                value: refIds
            }],
            return: ReportsFormService.creativeReturnFields,
            returnMode: ReportsFormService.searchReturnMode
        };
        return this.creativeRepository.search(payload).toPromise();
    }

    /**
     * SSP Report Types
     */
    getReportTypeOptions(reportTarget: ReportTarget): any[] {
        // Only internal users can create lau360 reports and native reports
        return this.authorization.isInternalUser ?
            ReportsFormService.reportsFormOptions[ReportTarget[reportTarget]].reportType :
            ReportsFormService.reportsFormOptions[ReportTarget[reportTarget]].reportType
                .filter(report => report.value !== 'lau360' && report.value !== 'native');
    }

    /**
     * Template Filter
     */

    searchAllTemplates() {
        const payload = {
            return: ReportsFormService.templateReturnFields,
            returnMode: ReportsFormService.searchReturnMode
        };
        return this.newsletterRepository.all(payload).toPromise();
    }

    searchTemplatesByRefIds(refIds: any[]) {
        const payload = {
            conditions: [{
                field: 'refId',
                value: refIds
            }],
            return: ReportsFormService.templateReturnFields,
            returnMode: ReportsFormService.searchReturnMode
        };
        return this.newsletterRepository.search(payload).toPromise();
    }

    searchTemplatesByPublisherIds(publisherHashIds: any[]) {
        const payload = {
            conditions: [{
                field: 'publisher',
                value: publisherHashIds
            }],
            return: ReportsFormService.templateReturnFields,
            returnMode: ReportsFormService.searchReturnMode
        };
        return this.newsletterRepository.search(payload).toPromise();
    }

    /**
     * Section Filter
     */

    searchAllSections() {
        const payload = {
            return: ReportsFormService.sectionReturnFields,
            returnMode: ReportsFormService.searchReturnMode
        };
        return this.adSlotRepository.search(payload).toPromise();
    }

    searchSectionsByRefIds(refIds: any[]) {
        const payload = {
            conditions: [{
                field: 'refId',
                value: refIds
            }],
            return: ReportsFormService.sectionReturnFields,
            returnMode: ReportsFormService.searchReturnMode
        };
        return this.adSlotRepository.search(payload).toPromise();
    }

    searchSectionsByPublisherIds(publisherHashIds: any[]) {
        const payload = {
            conditions: [{
                field: 'publisher',
                value: publisherHashIds
            }],
            return: ReportsFormService.sectionReturnFields,
            returnMode: ReportsFormService.searchReturnMode
        };

        return this.adSlotRepository.search(payload).toPromise();
    }

    /**
     * Location Filter
     */
    getAllCountries() {
        return this.countryRepository.all().toPromise();
    }

    getAllRegions() {
        return this.regionRepository.all().toPromise();
    }

    getAllMetros() {
        return this.metroRepository.all().toPromise();
    }

    /**
     * Filter Dropdown
     */

    static getOtherFilterOptions(reportTarget: ReportTarget): any[] {
        return ReportsFormService.reportsFormOptions[ReportTarget[reportTarget]].otherFilters;
    }

    /**
     * Standard Report Options
     */

    public getStandardReportsOptions(data: any[], reportTarget: ReportTarget) {
        return data
            .filter(report => {
                return report.data.attributes.type === ReportTarget[reportTarget];
            })
            .map(report => {
                return {
                    key: report.data.id,
                    value: report.data.attributes.name,
                    label: report.data.attributes.name
                };
            });
    }

    static getStandardReportQuery(data: any[], selectedReportName: string) {
        return data
            .filter(report => {
                return report.data.attributes.name === selectedReportName;
            })
            .map(report => {
                return report.data.attributes.query;
            })[0];
    }

    static getStandardReportMetaData(data: any[], selectedReportName: string) {
        return data
            .filter(report => {
                return report.data.attributes.name === selectedReportName;
            })
            .map(report => {
                return {
                    splits: report.data.attributes.query.dimensions
                        ? report.data.attributes.query.dimensions.map(dim => {
                            return dim.outputName;
                        }) : '',
                    aggregations: report.data.attributes.query.aggregations
                        ? report.data.attributes.query.aggregations.map(agg => {
                            return agg.name;
                        }) : '',
                    postAggregations: report.data.attributes.query.postAggregations
                        ? report.data.attributes.query.postAggregations.map(agg => {
                            return agg.name;
                        }) : ''
                };
            });
    }

    /**
     * Query Range
     */

    static getIntervalOptions(): any[] {
        return ReportsFormService.reportsFormOptions.interval;
    }

    static getDynamicIntervalLabel(dynamicIntervalValue: any) {
        let dynamicInterval = ReportsFormService.reportsFormOptions.interval
            .filter(option => option.value === dynamicIntervalValue)
            .map(option => {
                return option.label;
            });

        return dynamicInterval[0];
    }

    /**
     * Granularity
     */

    static getLA360GranularityOptions(): any[] {
        return ReportsFormService.reportsFormOptions.granularity
            .filter(option => option.lau360 === true);
    }

    static getGranularityOptions(): any[] {
        return ReportsFormService.reportsFormOptions.granularity;
    }

    /**
     * Splits
     */

    static getLAU360SplitsOptions(): any[] {
        return ReportsFormService.reportsFormOptions['advertiser'].splits
            .filter(option => option.type.indexOf('lau360') > -1);
    }

    static getExactSplitsOptions(reportTarget: ReportTarget): any[] {
        return ReportsFormService.reportsFormOptions[ReportTarget[reportTarget]].splits
            .filter(option => option.type.indexOf('exact') > -1);
    }

    static getInexactSplitsOptions(reportTarget: ReportTarget): any[] {
        return ReportsFormService.reportsFormOptions[ReportTarget[reportTarget]].splits
            .filter(option => option.type.indexOf('inexact') > -1);
    }

    static filterExactSplits(reportTarget: ReportTarget, selectedSplits: string[] = [], reportType): string[] {
        return ReportsFormService.reportsFormOptions[ReportTarget[reportTarget]].splits
            .filter(option => {
                if (reportType === 'lau360') {
                    return option.type.indexOf('lau360') > -1;
                }
                return option.type.indexOf('exact') > -1;
            })
            .filter(option => selectedSplits.indexOf(option.value) > -1)
            .map(option => option.value);
    }

    static filterInexactSplits(reportTarget: ReportTarget, selectedSplits: string[] = []): string[] {
        return ReportsFormService.reportsFormOptions[ReportTarget[reportTarget]].splits
            .filter(option => option.type.indexOf('inexact') > -1)
            .filter(option => selectedSplits.indexOf(option.value) > -1 || selectedSplits.indexOf(option.label) > -1)
            .map(option => option.value);
    }

    getAutoSelectSplits(reportTarget: ReportTarget, selectedSplits: string[]): string[] {
        return Array.from(new Set(ReportsFormService.reportsFormOptions[ReportTarget[reportTarget]].splits
            .filter(option => selectedSplits.indexOf(option.value) > -1)
            .map(option => option.autoSelectSplits)
            .filter(autoSelectSplits => autoSelectSplits !== undefined)
            .flat()));
    }

    /**
     * Creative Size
     */

    static getCreativeSizeOptions(): any[] {
        return ReportsFormService.reportsFormOptions.creativeSize;
    }

    /**
     * Creative Type
     */

    static getCreativeTypeOptions(): any[] {
        return ReportsFormService.reportsFormOptions.creativeType;
    }

    /**
     * Demand Type
     */

    static getAllDemandTypeOptions(): any[] {
        return ReportsFormService.reportsFormOptions.demandType;
    }

    static getNonExchangeDemandTypeOptions(): any[] {
        return ReportsFormService.reportsFormOptions.demandType
            .filter(option => {
                return option.value !== 'default';
            });
    }

    /**
     * Mail Type
     */

    static getMailTypeOptions(): any[] {
        return ReportsFormService.reportsFormOptions.mailType;
    }

    /**
     * Device Type
     */

    static getDeviceTypeOptions(): any[] {
        return ReportsFormService.reportsFormOptions.deviceType;
    }

    /**
     * Device Maker
     */

    static getDeviceMakerOptions(): any[] {
        return ReportsFormService.reportsFormOptions.deviceMaker;
    }

    /**
     * Browser
     */

    static getBrowserOptions(): any[] {
        return ReportsFormService.reportsFormOptions.browser;
    }

    /**
     * Operating System
     */
    static getOperatingSystemOptions(): any[] {
        return ReportsFormService.reportsFormOptions.operatingSystem;
    }

    /**
     * Gender
     */

    static getGenderOptions(): any[] {
        return ReportsFormService.reportsFormOptions.gender;
    }

     /**
     * NCP
     */

     static getNCPOptions(): any[] {
        return ReportsFormService.reportsFormOptions.isNCP;
    }

    /**
     * Age
     */

    static getAgeOptions() {
        return ReportsFormService.reportsFormOptions.age;
    }

    /**
     * 
     * GAM 
     */
    static getAdServingOptions(): any[] {
        return ReportsFormService.reportsFormOptions.adServing;
    }

    /**
     * Metrics
     */

    static getMetricsLeftColumn(reportTarget: ReportTarget, reportType): any[] {
        if (reportType === 'custom' || reportType === 'key_value_pairs') {
            return ReportsFormService.reportsFormOptions[ReportTarget[reportTarget]].metricsLeftCol;
        }

        if (reportType === 'native') {
            return [];
        }

        if (reportType === 'lau360') {
            const allowed = ['Impressions', 'Clicks'];

            return ReportsFormService.reportsFormOptions[ReportTarget[reportTarget]].metricsLeftCol
                .filter(metric => allowed.indexOf(metric.value) > -1);
        }
    }

    static getMetricsRightColumn(reportTarget: ReportTarget, reportType): any[] {
        if (reportType === 'custom' || reportType === 'key_value_pairs') {
            return ReportsFormService.reportsFormOptions[ReportTarget[reportTarget]].metricsRightCol;
        }

        if (reportType === 'native') {
            return [];
        }

        if (reportType === 'lau360') {
            return [];
        }
    }

    /**
     * Filters
     */

    // Filters and returns data set specific to `EntityFiltersComponent`
    public filterEntityFilters(allFiltersMeta: IFilterMetaObject[]): IFilterMetaObject[] {
        let entityFiltersData: IFilterMetaObject[];
        const entityFiltersDimensions = [
            'advertiser_id',
            'publisher_id',
            'campaign_id',
            'line_item_id',
            'creative_id',
            'section_id',
            'template_id'
        ];

        if (!allFiltersMeta) {
            return [];
        }

        entityFiltersData = entityFiltersDimensions
            .map(dimension => allFiltersMeta.find(fltr => fltr.dimension === dimension))
            .filter(fltrData => fltrData);

        if (!entityFiltersData.length) {
            return null;
        }

        return entityFiltersData;
    }

    // Filters and returns data set specific to `OtherFiltersComponent`
    public filterOtherFilters(reportTarget, allFiltersMeta: IFilterMetaObject[]): IFilterMetaObject[] {
        if (!allFiltersMeta) {
            return [];
        }

        let filtersNames: string[] = ReportsFormService.getOtherFilterOptions(reportTarget).map(opt => opt.value);

        filtersNames.splice(filtersNames.indexOf('location'), 1);
        filtersNames = filtersNames.concat(['country', 'region', 'metro']);

        return filtersNames
            .map(fltrName => allFiltersMeta.find(fltr => fltr.dimension === fltrName))
            .filter(fltrData => fltrData);
    }

    /**
     * Schedule
     */

    static getScheduleFrequencyOptions(): any[] {
        return ReportsFormService.reportsFormOptions.frequency;
    }

    static getScheduleDeliveryWeeklyOptions(): any[] {
        return ReportsFormService.reportsFormOptions.daysOfTheWeek;
    }

    static getScheduleDeliveryMonthlyOptions(): any[] {
        return ReportsFormService.reportsFormOptions.monthOptions;
    }

    static getDeliveryTimeOptions(): any[] {
        return ReportsFormService.reportsFormOptions.deliveryTime;
    }
}
