import { Injectable } from '@angular/core';
import { CanActivate , ActivatedRouteSnapshot} from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class AudienceGuard implements CanActivate {
    constructor() { }

    canActivate(route: ActivatedRouteSnapshot) { 
        if (route.url[0].path === 'audiences') {
            window.location.href = environment.portalHost + '/audience-manager';
            return false;
    }
        return true;
}

}
