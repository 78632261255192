import { ChangeDetectorRef } from '@angular/core';
import { UtilRepository } from 'app/core/repositories';
import { Creative } from 'app/shared/models';
import { TableComponent } from 'app/shared/elements/table';
import { TableHelper } from 'app/shared/helpers/table-helper';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
var Columns;
(function (Columns) {
    Columns[Columns["staqId"] = 0] = "staqId";
    Columns[Columns["name"] = 1] = "name";
    Columns[Columns["clickUrl"] = 2] = "clickUrl";
    Columns[Columns["urlTracking1"] = 3] = "urlTracking1";
    Columns[Columns["urlTracking2"] = 4] = "urlTracking2";
})(Columns || (Columns = {}));
var BulkTagUploaderComponent = /** @class */ (function () {
    function BulkTagUploaderComponent(cdr, utilRepository) {
        this.cdr = cdr;
        this.utilRepository = utilRepository;
        this.showError = false;
        this.allChecked = false;
        this.anyChecked = false;
        this.dummyCreative = new Creative();
        this.creativeData$ = new BehaviorSubject([]);
        this.creativeParams$ = new BehaviorSubject({
            page: 1,
            pageSize: 0,
            orderBy: 'staqId',
            filters: [],
            direction: 'ASC',
        });
        this.creativeTableData$ = combineLatest(this.creativeData$, this.creativeParams$)
            .pipe(map(function (_a) {
            var data = _a[0], params = _a[1];
            var filteredData = TableHelper.filter(data, params);
            TableHelper.sort(filteredData, params.orderBy, params.direction);
            return {
                items: filteredData,
                pages: 1,
                total: filteredData.length,
                page: 1
            };
        }));
    }
    BulkTagUploaderComponent.prototype.uploadExcel = function (files) {
        var _this = this;
        this.excelFile = files[0];
        if (!this.excelFile) {
            return;
        }
        this.utilRepository.parseExcel({
            file: this.excelFile.dataURL.split(',')[1]
        }).subscribe(function (rsp) {
            _this.showError = false;
            _this.creativeData$.next(_this.mapToCreatives(rsp));
        }, function () {
            _this.showError = true;
            _this.excelFile = null;
        });
    };
    BulkTagUploaderComponent.prototype.mapToCreatives = function (rsp) {
        rsp.shift();
        var creatives = [];
        for (var _i = 0, rsp_1 = rsp; _i < rsp_1.length; _i++) {
            var row = rsp_1[_i];
            var creative = {};
            this.setProperty(creative, Columns.staqId, row[Columns.staqId]);
            this.setProperty(creative, Columns.name, row[Columns.name]);
            this.setProperty(creative, Columns.clickUrl, this.replaceTimestamp(row[Columns.clickUrl]));
            this.setProperty(creative, Columns.urlTracking1, this.generateSourceUrl(row[Columns.urlTracking1]));
            this.setProperty(creative, Columns.urlTracking2, this.generateSourceUrl(row[Columns.urlTracking2]));
            creative.type = 'image';
            creative.isModified = true;
            creatives.push(creative);
        }
        return creatives;
    };
    BulkTagUploaderComponent.prototype.setProperty = function (creative, index, property) {
        if (property !== undefined && property !== null) {
            creative[Columns[index]] = property;
        }
    };
    BulkTagUploaderComponent.prototype.uploadImage = function (event, creative) {
        var _this = this;
        var file = event.target.files[0];
        var reader = new FileReader();
        var image = new Image();
        var mediaUrl;
        reader.onload = function () {
            mediaUrl = reader.result;
            image.src = mediaUrl;
        };
        image.onload = function () {
            creative.mediaUrl = mediaUrl;
            creative.width = image.width;
            creative.height = image.height;
            creative.fileName = file.name;
            _this.cdr.detectChanges();
        };
        reader.readAsDataURL(file);
    };
    BulkTagUploaderComponent.prototype.removeImage = function (creative) {
        creative.mediaUrl = null;
        creative.width = null;
        creative.height = null;
        creative.fileName = null;
    };
    BulkTagUploaderComponent.prototype.reset = function () {
        this.creativeData$.next([]);
        this.excelFile = null;
    };
    Object.defineProperty(BulkTagUploaderComponent.prototype, "selectedCreatives", {
        get: function () {
            return this.table.selectedItems.map(function (creative) {
                try {
                    var copy = JSON.parse(JSON.stringify(creative));
                    delete copy.selected;
                    delete copy.fileName;
                    return new Creative(copy);
                }
                catch (e) {
                    return null;
                }
            }).filter(function (creative) { return creative !== null; });
        },
        enumerable: true,
        configurable: true
    });
    BulkTagUploaderComponent.prototype.downloadTemplate = function () {
        window.location.href = '../assets/templates/bulk_tag_upload_template.xlsx';
    };
    BulkTagUploaderComponent.prototype.replaceTimestamp = function (url) {
        if (url === undefined || url === null) {
            return;
        }
        return url.replace(/\[timestamp\]/g, '${DECISION_ID}');
    };
    BulkTagUploaderComponent.prototype.thumbnailWidth = function (creative) {
        this.dummyCreative.width = creative.width;
        return this.dummyCreative.thumbnailWidth(false);
    };
    BulkTagUploaderComponent.prototype.thumbnailHeight = function (creative) {
        this.dummyCreative.height = creative.height;
        return this.dummyCreative.thumbnailHeight(false);
    };
    BulkTagUploaderComponent.prototype.generateSourceUrl = function (tag) {
        if (tag === undefined || tag === null) {
            return;
        }
        var match = tag.match(/src="(.*?)"/i);
        return match ? this.replaceTimestamp(match[1]) : null;
    };
    BulkTagUploaderComponent.prototype.query = function (params) {
        this.creativeParams$.next(params);
    };
    return BulkTagUploaderComponent;
}());
export { BulkTagUploaderComponent };
