import { Component, Input, ViewChild,
    ElementRef, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { IdService } from 'app/core';

@Component({
    selector: 'content-editable',
    templateUrl: './content-editable.html',
    styleUrls: ['./content-editable.styl']
})
export class ContentEditableComponent {
    @ViewChild('input', { static: false }) input: ElementRef;
    @ViewChild('textarea', { static: false }) textarea: ElementRef;
    @Input() content: string;
    @Input() isTextarea: boolean = false;
    @Output() inputChanged = new EventEmitter<string>();
    @Output() startEdit = new EventEmitter();

    isEditing = false;
    private originalContent;
    private control: ElementRef;

    constructor (
        private cdr: ChangeDetectorRef,
        public id: IdService
    ) { }

    ngOnInit() {
        this.originalContent = this.content;
    }

    ngAfterViewInit() {
        if (this.isTextarea) {
            this.control = this.textarea;
        } else {
            this.control = this.input;
        }
    }

    edit() {
        this.isEditing = true;
        this.startEdit.emit();
        this.cdr.detectChanges(); // Required to focus otherwise hidden element.
        this.control.nativeElement.focus();
    }

    keydown(event: KeyboardEvent) {
        if (event.code === 'Enter') {
            this.isEditing = false;
            this.originalContent = this.content;
        }
        if (event.code === 'Escape') {
            this.isEditing = false;
            this.content = this.originalContent;
        }
    }

    public reset(val: string) {
        this.isEditing = false;
        this.content = val;
        this.originalContent = val;
    }

    doneEditing (): void {
        this.isEditing = false;
        this.inputChanged.emit(this.content);
    }
}
