import { ChangeDetectorRef, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { DialogboxComponent } from '../dialog-box';

@Component({
    selector: 'status-dialog',
    templateUrl: './status-dialog.component.html',
    styleUrls: ['./status-dialog.component.styl']
})
export class StatusDialogComponent implements OnInit, OnDestroy {
    @ViewChild(DialogboxComponent, { static: true }) dialog: DialogboxComponent;
    action: string = '';
    entityName: string = '';
    progress: number = null;
    total: number = null;

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.dialog.closedState.subscribe(() => this.reset());
    }

    ngOnDestroy() {
        this.dialog.closedState.unsubscribe();
    }

    open(action: string, entityName: string) {
        this.action = action;
        this.entityName = entityName;
        this.dialog.open();
    }

    close() {
        this.dialog.close();
        this.cdr.detectChanges();
    }

    reset() {
        this.progress = null;
        this.total = null;
    }

    initiate(total: number) {
        this.progress = 0;
        this.total = total;
    }

    postProgress() {
        this.progress++;
        this.cdr.detectChanges();
    }
}
