import { Injectable } from '@angular/core';

export enum BackgroundStyle {
    GreyStyle = 1
}

export enum NavigationStyle {
    AdminStyle = 1
}

@Injectable()
export class ThemeService {
    public setBackgroundStyle(backgroundStyle: BackgroundStyle): void {
        this.resetBackgroundStyle();
        document.body.classList.add('background-style');
        document.body.classList.add(ThemeService._getBackgroundStyleClass(backgroundStyle));
    }

    private static _getBackgroundStyleClass(backgroundStyle: BackgroundStyle): string {
        switch (backgroundStyle) {
            case BackgroundStyle.GreyStyle:
                return 'background-style--grey';
        }
        return;
    }

    resetBackgroundStyle(): void {
        document.body.className = document.body.className.split(' ').filter(className => {
            return className.indexOf('background-style-') < 0;
        }).join(' ');
    }

    setNavigationStyle(navigationStyle: NavigationStyle): void {
        this.resetNavigationStyle();
        document.body.classList.add('navigation-style');
        document.body.classList.add(ThemeService._getNavigationStyleClass(navigationStyle));
    }

    private static _getNavigationStyleClass(navigationStyle: NavigationStyle): string {
        switch (navigationStyle) {
            case NavigationStyle.AdminStyle:
                return 'navigation-style--admin';
        }
    }

    resetNavigationStyle(): void {
        document.body.className = document.body.className.split(' ').filter(className => {
            return className.indexOf('navigation-style-') < 0;
        }).join(' ');
    }

    lockPageScroll() {
        document.body.classList.add('modal-open');
    }

    unlockPageScroll() {
        document.body.classList.remove('modal-open');
    }
}
