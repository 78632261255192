import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsComponent } from './tabs.component';
import { TabComponent } from './tab.component';
import { DirectivesModule } from 'src/app/shared/directives';

const TABS = [
    TabsComponent,
    TabComponent
];

@NgModule({
    imports: [ CommonModule, DirectivesModule ],
    declarations: [ ...TABS ],
    exports: [ ...TABS ]
})
export class TabsModule {}
