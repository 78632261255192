import { Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safeStyle' })
export class SafeStyle {
    constructor(private sanitizer: DomSanitizer) {}

    transform(incomingStyle: string) {
        return this.sanitizer.bypassSecurityTrustStyle(incomingStyle);
    }
}
