import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BackendRepository } from './backend-repository';
import { ESP } from 'app/shared/models';
import { mergeMap, shareReplay } from 'rxjs/operators';

@Injectable()
export class ESPRepository extends BackendRepository<ESP> {
    private cache: Observable<ESP[]>;

    public constructor(http: HttpClient) {
        super(http, '/esp', ESP);
    }

    get(id: string) {
        if (!this.cache) {
            this.cache = super.search().pipe(shareReplay());
        }

        return this.cache.pipe(mergeMap(esps => esps.filter(esp => esp.id === id)));
    }

    getFromCache(id: string) {
        return this.get(id);
    }
}
