import { EventEmitter } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { BulkEditLightboxComponent } from 'app/shared/components/bulk-edit-lightbox';
import { AdSlotRepository, NewsletterRepository } from 'app/core/repositories';
import { BulkResponseProcessor, NotificationsService } from 'app/core';
import { sortBy } from 'app/core/array-utils';
var AdSlotBulkEditComponent = /** @class */ (function () {
    function AdSlotBulkEditComponent(adSlotRepository, notifications, newsletterRepository) {
        this.adSlotRepository = adSlotRepository;
        this.notifications = notifications;
        this.newsletterRepository = newsletterRepository;
        this.completed = new EventEmitter();
    }
    AdSlotBulkEditComponent.prototype.open = function (adSlots, field) {
        var _this = this;
        if (field === void 0) { field = null; }
        this.lightbox.initiate();
        this.lightbox.open(adSlots, field, 'load');
        forkJoin(adSlots.map(function (adSlot) { return _this.adSlotRepository.get(adSlot.id).pipe(mergeMap(function (detail) {
            return forkJoin(of(detail), _this.newsletterRepository.get(detail.newsletter));
        }), map(function (_a) {
            var detail = _a[0], newsletter = _a[1];
            detail.newsletterObj = newsletter;
            return detail;
        }), tap(function () { return _this.lightbox.postProgress(); })); })).subscribe(function (details) { return _this.lightbox.open(sortBy(details, 'isUniversalTag').reverse(), field); }, function () { return _this.handleError(); });
    };
    AdSlotBulkEditComponent.prototype.close = function () {
        this.lightbox.close();
    };
    AdSlotBulkEditComponent.prototype.submit = function (_a) {
        var _this = this;
        var changed = _a.changed, originals = _a.originals;
        this.lightbox.initiate();
        var requests = changed.map(function (adSlot) {
            adSlot.sizes.forEach(function (size) {
                delete size.isEnabled;
            });
            return _this.adSlotRepository.save(adSlot)
                .pipe(tap(function () { return _this.lightbox.postProgress(); }), catchError(function (e) {
                _this.lightbox.postProgress();
                return of(e);
            }));
        });
        forkJoin(requests).subscribe(function (responses) { return _this.handleAction(originals, responses); }, function () { return _this.handleError(); });
    };
    AdSlotBulkEditComponent.prototype.handleAction = function (attempted, responses) {
        var _a = (new BulkResponseProcessor()).processSaves(attempted, responses), status = _a.status, failureItems = _a.failureItems, successItems = _a.successItems;
        if (status === BulkResponseProcessor.AllOK) {
            // All OK
            this.notifications.success("Successfully updated " + successItems.length + " ad slot" + (successItems.length === 1 ? '' : 's') + ".");
            this.completed.emit();
            this.close();
            return;
        }
        if (status === BulkResponseProcessor.AllFailed) {
            // All failed
            return this.notifications.error("No ad slots were updated because the following errors have occurred:\n                <ul>" + failureItems.join('') + "</ul>", '', 0);
        }
        // Some succeeded, some failed
        this.notifications.warning("The following ad slots were successfully updated:\n             <ul>" + successItems.join('') + "</ul>\n             <br>\n             However, the following ad slots were not updated:\n             <ul>" + failureItems.join('') + "</ul>", '', 0);
        this.completed.emit();
        this.close();
    };
    AdSlotBulkEditComponent.prototype.handleError = function () {
        this.notifications.error('An error has occurred.');
    };
    return AdSlotBulkEditComponent;
}());
export { AdSlotBulkEditComponent };
