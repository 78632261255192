export class StrategyCardHelper {
    public static isPerformanceMaxConversions(campaign: any, lineItem: any): boolean {
        return (campaign
            && lineItem
            && campaign.pricingModel === 'CPM'
            && (campaign.clearingMethod === '2ndPrice' || campaign.secondPrice === true)
            && campaign.goal === 'CPA'
            && campaign.system === 'dsp'
            && lineItem.dailyBudget === null
        );
    }

    public static isPerformanceMaxConversionsDailyCap(campaign: any, lineItem: any): boolean {
        return (campaign
            && lineItem
            && campaign.pricingModel === 'CPM'
            && (campaign.clearingMethod === '2ndPrice' || campaign.secondPrice === true)
            && campaign.goal === 'CPA'
            && campaign.system === 'dsp'
            && lineItem.dailyBudget === null
            && lineItem.dailyCap > 0
        );
    }
}
