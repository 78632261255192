import * as tslib_1 from "tslib";
import { Filter } from '../filter';
import { Operation } from '../operation';
import moment from 'moment';
var FilterDate = /** @class */ (function (_super) {
    tslib_1.__extends(FilterDate, _super);
    function FilterDate() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.operations = [
            Operation.Equals,
            Operation.DoesNotEqual,
            Operation.GreaterThanOrEqualTo,
            Operation.LessThanOrEqualTo
        ];
        return _this;
    }
    FilterDate.prototype.isValid = function () {
        return _super.prototype.isValid.call(this) && moment(this.query, 'YYYY-MM-DD', true).isValid();
    };
    return FilterDate;
}(Filter));
export { FilterDate };
