import { Component, ViewChild, Input, ContentChild, TemplateRef, EventEmitter, Output, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { of, forkJoin, combineLatest } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthorizationService } from 'app/core/authorization.service';
import { AvailableActionsService } from 'app/shared/helpers/available-actions.service';
import { ConfirmDialogComponent } from 'app/shared/elements/confirm-dialog';
import { ErrorHelper } from 'app/core/errors/error-helper';
import { IdService } from 'app/core/id.service';
import { InsertionOrder } from 'app/shared/models';
import { InsertionOrderRepository } from 'app/core/repositories';
import { NotificationsService} from 'app/core';
import { PaginatorComponent } from 'app/shared/elements/paginator';
import { TableComponent } from 'app/shared/elements/table';
import { TableHelper } from 'app/shared/helpers/table-helper';

@Component({
    selector: 'insertion-orders-table',
    templateUrl: './insertion-orders-table.html',
    styleUrls: ['./insertion-orders-table.styl']
})
export class InsertionOrdersTableComponent implements OnInit {
    @ContentChild('actions', { static: true }) actionsTemplate: TemplateRef<any>;
    @ViewChild(TableComponent, { static: true }) table: TableComponent;
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent;
    @ViewChild('deleteDialog', { static: true }) deleteDialog: ConfirmDialogComponent;
    helper = new TableHelper<InsertionOrder>(params => this.adapter(params));

    private _ids: string[] = null;
    private _failedInsertionOrders: any[] = null;
    public errorHelper: ErrorHelper = null;

    @Input() advertiser: string = null;
    @Output() deleted: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private insertionOrderRepository: InsertionOrderRepository,
        public availActions: AvailableActionsService,
        private router: Router,
        private notifications: NotificationsService,
        public auth: AuthorizationService,
        public id: IdService
    ) { }

    ngOnInit() {
        this.helper.table = this.table;
        this.helper.paginator = this.paginator;
        combineLatest(this.table.query, this.paginator.query).subscribe(args => this.helper.search(args));
    }

    @Input() set advertisers(value: string[]) {
        this._ids = value;
        this.table.reset();
        this.paginator.reset();
    }

    private adapter(params) {
        if (Array.isArray(this._ids)) {
            params.conditions.push({
                field: 'advertiser',
                value: this._ids
            });
        }

        if (typeof this.advertiser === 'string') {
            params.conditions.push({
                field: 'advertiser',
                value: this.advertiser
            });
        }

        return this.insertionOrderRepository.asyncSearch(params);
    }

    availableActionsHandler(action, insertionOrder) {
        if (action === 'edit') {
            this.router.navigate(['/campaign-manager/insertion-orders', insertionOrder.id, 'edit',
                { redirect: btoa(this.router.url)}]);
        }
    }

    deleteIOs(insertionOrders) {
        this._failedInsertionOrders = [];
        this.errorHelper = new ErrorHelper();
        forkJoin(
            insertionOrders.map(insertionOrder => {
                return this.insertionOrderRepository.delete(insertionOrder.id).pipe(catchError((err: HttpErrorResponse) => {
                    this.errorHelper.loadBackEndErrors(err.error.errors);
                    let errorList = this.errorHelper.errors;
                    this._failedInsertionOrders.push({
                        key: insertionOrder.name,
                        value: errorList
                    });
                    return of(null);
                }));
            })
        ).subscribe(res => {
            if (this._failedInsertionOrders.length > 0) {
                this.displayErrorMessage(this._failedInsertionOrders);
            } else {
                this.notifications.success(`Selected insertion orders have been successfully deleted.`);
            }
            this.paginator.reset();
        });

    }

    public displayErrorMessage(failedInsertionOrders): void {
        let entityName = 'insertion order';
        if (failedInsertionOrders.length !== 1) {
            entityName = entityName.concat('s');
        }
        let error = '<strong>Uh Oh!</strong>  The following ' + entityName + ' could not be deleted:';
        failedInsertionOrders.forEach(io => error += '<li>' + io.key + '</li>');
        this.notifications.error(error, '', 0);
    }
}
