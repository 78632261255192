import { EventEmitter } from '@angular/core';
import { KVP } from './KVP';
import reservedKeys from './reserved-keys.json';
import { IdService } from 'app/core/id.service';
var KVPInputComponent = /** @class */ (function () {
    function KVPInputComponent(id) {
        var _this = this;
        this.id = id;
        this.selected = [];
        this.allowMultipleKeyValues = false;
        this.selectedChange = new EventEmitter();
        this.errors = [];
        this.placeholder = 'Enter the KVP parameters each on a separate line with no spaces. '
            + 'Include "=" between the key and value.\n\n'
            + 'ie.\n'
            + 'key=value\n'
            + 'key2=value2\n'
            + 'key3=value3\n';
        this.reservedKeys = reservedKeys;
        this.validate = function (kvp) {
            var isValid = true;
            if (kvp.key === undefined || kvp.value === undefined || kvp.value.length === 0) {
                _this.errors.push('A key value pair must contain "=" between the key and value, with no spaces (i.e. key=value).');
                isValid = false;
            }
            if (!/[a-zA-Z]/.test(kvp.key.charAt(0))) {
                _this.errors.push('Keys must start with a letter.');
                isValid = false;
            }
            if (kvp.key && kvp.value && (kvp.key.includes(' ') || kvp.value.includes(' '))) {
                _this.errors.push('Key value pairs cannot contain spaces.');
                isValid = false;
            }
            var supportedCharactersRegex = new RegExp(/^[a-z0-9_\{\}\[\]\-\s]*$/, 'i');
            if (!supportedCharactersRegex.test(kvp.key) || !supportedCharactersRegex.test(kvp.value)) {
                _this.errors.push('Letters, numbers, hyphens, brackets and underscores are the only' +
                    ' supported characters for key and value names.');
                isValid = false;
            }
            if (_this.selected.some(function (selectedKvp) { return kvp.key === selectedKvp.key; })
                && !_this.allowMultipleKeyValues) {
                _this.errors.push('Keys must be unique.');
                isValid = false;
            }
            if (_this.reservedKeys.indexOf(kvp.key) > -1) {
                _this.errors.push('One or more keys is unavailable.');
                isValid = false;
            }
            return isValid;
        };
    }
    /**
     * Parse raw text into an array of KVPs.
     * @param  {string}  rawList
     * @return {KVP[]}
     */
    KVPInputComponent.prototype.parse = function (rawList) {
        return rawList
            .split(/\n/)
            .map(function (token) { return token.trim(); })
            .filter(function (token) { return token !== ''; })
            .map(function (token) {
            var _a;
            var key, value;
            _a = token.split('='), key = _a[0], value = _a[1];
            return new KVP(key, value);
        });
    };
    KVPInputComponent.prototype.addParemeters = function () {
        this.errors = [];
        this.listInput.parse();
    };
    /**
     * Clear out the componet. This is needed where a form
     * is to be reused rather than freshly instantiated.
     */
    KVPInputComponent.prototype.reset = function () {
        this.inputText = null;
        this.clearAll();
    };
    KVPInputComponent.prototype.clearAll = function () {
        this.update([]);
    };
    KVPInputComponent.prototype.update = function (values) {
        this.selected = values;
        this.selectedChange.emit(this.selected);
    };
    return KVPInputComponent;
}());
export { KVPInputComponent };
