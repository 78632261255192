import * as tslib_1 from "tslib";
import { Model } from 'app/shared/models/model';
import { HistoryChange } from 'app/shared/models/history-change';
var HistoryItem = /** @class */ (function (_super) {
    tslib_1.__extends(HistoryItem, _super);
    function HistoryItem(from) {
        var _this = _super.call(this, from) || this;
        if (from['changes']) {
            _this.changes = from['changes'].map(function (change) { return new HistoryChange(change); });
        }
        return _this;
    }
    Object.defineProperty(HistoryItem.prototype, "hasChanges", {
        get: function () {
            return Array.isArray(this.changes) && this.changes.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HistoryItem.prototype, "action", {
        get: function () {
            return this.created === this.modified ? 'created' : 'updated';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HistoryItem.prototype, "previousModified", {
        get: function () {
            if (this.previous) {
                return this.previous.modified;
            }
        },
        enumerable: true,
        configurable: true
    });
    HistoryItem.prototype.findChange = function (field) {
        return this.changes.find(function (change) { return change.field === field; });
    };
    HistoryItem.prototype.serialize = function () {
        return null;
    };
    Object.defineProperty(HistoryItem.prototype, "entity", {
        get: function () {
            return 'History Item';
        },
        enumerable: true,
        configurable: true
    });
    return HistoryItem;
}(Model));
export { HistoryItem };
