import * as moment from 'moment';

export class ChartHelper{

    public evaluateMetricReduction(data: Array<number>, operation: String) {
        switch (operation) {
            case 'sum':
                return data.reduce((accumlator, current) => accumlator + current, 0);
            case 'mean':
                return data.reduce((accumlator, current) => accumlator + current, 0) / (data.length);
            default: return data.reduce((accumlator, current) => accumlator + current, 0);
        }
    }

    //  Function used for zerofilling data that is meant to be consumed by charts
    zerofill(data: Array<Array<string | number>>, startDate, endDate):  Array<Array<string | number>>{
        let zeroData = [];
        for (let row of data) {
            zeroData.push(row.slice(0, 1));
        }
        let date = moment(startDate).utc();
        const dateRange = moment(endDate).diff(moment(startDate), 'days');
        // create a completely zeroed data set
        for (let i = 0; i <= dateRange; i++) {
            for (let row of zeroData) {
                if (row[0] === 'dates') {
                    row.push(moment(date).format('YYYY-MM-DD'));
                    continue;
                }
                row.push(0);
            }
            date.add(1, 'day');
        }
        let dataIndex = 1;
        for (let i = 1; i < zeroData[0].length && dataIndex < data[0].length; ) {
            if (moment(data[data.length - 1][dataIndex]).isBefore(moment(zeroData[zeroData.length - 1][i]), 'day')) {
                dataIndex++;
            }
            // if the day of data exists then overwrite it in zeroData
            else if (moment(data[data.length - 1][dataIndex]).isSame(moment(zeroData[zeroData.length - 1][i]), 'day')) {
                for (let j = 0; j < data.length - 1; j++) {
                    zeroData[j][i] = data[j][dataIndex];
                }
                i++;
                dataIndex++;
            } else {
                i++;
            }
        }
        return zeroData;
    }
}
