import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bigNumber'
})
export class BigNumberPipe implements PipeTransform {
    private readonly locale = 'en-US';
    private readonly suffixes = ['k', 'M', 'B', 'T'];

    transform(input: any): any {
        if (isNaN(input)) {
            return null;
        }

        if (input < 1000) {
            return input.toFixed();
        }

        const exponent = Math.floor(Math.log(input) / Math.log(1000));
        const shortened = (input / Math.pow(1000, exponent));
        const fixAt = shortened.toFixed().length < 3 ? 1 : 0;

        return shortened.toLocaleString(this.locale, { maximumFractionDigits: fixAt }) + this.suffixes[exponent - 1];
    }
}
