import * as tslib_1 from "tslib";
import { clone } from 'app/core/utils';
import { Model } from './model';
import { EventType } from 'app/platform/campaign-manager/live-insights/event-builder/event-type';
import * as configs from 'app/platform/measurement/live-connect-manager/live-connect-setup/event-configs';
export var RuleType;
(function (RuleType) {
    RuleType["Event"] = "event";
    RuleType["URL"] = "url";
    RuleType["Retargeting"] = "retargeting";
    RuleType["Standard"] = "standard";
})(RuleType || (RuleType = {}));
var WhitelistingRule = /** @class */ (function (_super) {
    tslib_1.__extends(WhitelistingRule, _super);
    function WhitelistingRule(from) {
        var _this = _super.call(this, from) || this;
        _this.configuration = _this.computeConfiguration();
        return _this;
    }
    WhitelistingRule.platformLabel = function (platform) {
        var _a;
        return (_a = {},
            _a[WhitelistingRule.PLATFORM_WEB] = 'Web',
            _a[WhitelistingRule.PLATFORM_APP] = 'In-app',
            _a)[platform];
    };
    WhitelistingRule.popularRules = function () {
        return [
            { value: EventType.AddToCart, label: 'Add to Cart' },
            { value: EventType.AppInstall, label: 'App Install' },
            { value: EventType.EmailSignUp, label: 'Email Sign Up' },
            { value: EventType.FormFill, label: 'Form Fill' },
            { value: EventType.Lead, label: 'Lead' },
            { value: EventType.ProductPurchase, label: 'Product Purchase' },
            { value: EventType.Subscription, label: 'Subscription' },
        ];
    };
    WhitelistingRule.getConfig = function (eventType) {
        var _a;
        return (_a = {},
            _a[EventType.ProductPurchase] = configs.productPurchase,
            _a[EventType.EmailSignUp] = configs.emailSignUp,
            _a[EventType.FormFill] = configs.formFill,
            _a[EventType.AddToCart] = configs.addToCart,
            _a[EventType.Lead] = configs.lead,
            _a[EventType.Subscription] = configs.subscription,
            _a[EventType.AppInstall] = configs.appInstall,
            _a)[eventType] || configs.customConversion;
    };
    Object.defineProperty(WhitelistingRule.prototype, "termRegex", {
        get: function () {
            return this._termRegex || '';
        },
        set: function (value) {
            this._termRegex = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WhitelistingRule.prototype, "title", {
        get: function () {
            return this.configuration.eventName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WhitelistingRule.prototype, "tagType", {
        get: function () {
            if (this.isStandard) {
                return 'Standard Tag';
            }
            else if (this.isPopular) {
                return 'Popular';
            }
            else {
                return 'Custom';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WhitelistingRule.prototype, "isPopular", {
        get: function () {
            return WhitelistingRule.popularRules().map(function (popular) { return WhitelistingRule.getConfig(popular.value); }).includes(this.configuration);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WhitelistingRule.prototype, "type", {
        get: function () {
            if (this.shortHandType) {
                return this.typeFromShortHand(this.shortHandType);
            }
            if (this.eventNameRegex) {
                return RuleType.Event;
            }
            if (this.pageRegex) {
                return RuleType.URL;
            }
            if (this.isStandard) {
                return RuleType.Standard;
            }
        },
        enumerable: true,
        configurable: true
    });
    WhitelistingRule.prototype.typeFromShortHand = function (shortHand) {
        return {
            'dynamic:url': RuleType.URL,
            'dynamic:event': RuleType.Event,
            'dynamic:retargeting': RuleType.Retargeting,
        }[shortHand] || RuleType.Standard;
    };
    Object.defineProperty(WhitelistingRule.prototype, "createdAtUnix", {
        get: function () {
            return this.createdAt * 1000;
        },
        enumerable: true,
        configurable: true
    });
    WhitelistingRule.prototype.serialize = function () {
        var targets = [];
        if (this.platforms && Array.isArray(this.platforms)) {
            if (this.platforms.includes(WhitelistingRule.PLATFORM_APP)) {
                targets.push('mobile');
            }
            if (this.platforms.includes(WhitelistingRule.PLATFORM_WEB)) {
                targets.push('email-hash');
            }
        }
        this.targets = targets;
        delete this.isLegacy;
        delete this.isStandard;
        delete this.configuration;
        delete this.snippet;
        delete this.eventTypes;
        delete this.shortHandType;
        var clone = JSON.parse(JSON.stringify(this));
        clone.termRegex = clone._termRegex;
        return JSON.stringify(clone);
    };
    Object.defineProperty(WhitelistingRule.prototype, "forecastable", {
        get: function () {
            return [RuleType.Event, RuleType.URL].includes(this.type);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WhitelistingRule.prototype, "entity", {
        get: function () {
            return 'Allowing Rule';
        },
        enumerable: true,
        configurable: true
    });
    WhitelistingRule.prototype.computeConfiguration = function () {
        var _this = this;
        var customConversion = clone(configs.customConversion);
        var match = Object.values(configs).filter(function (config) { return config.eventType === _this.eventNameRegex; })
            .find(function (config) { return config.props.find(function (prop) { return prop.id === 'name' && prop.value === _this.termRegex; }); });
        if (!match) {
            // replace custom conversion name with actual term value
            customConversion.props.forEach(function (prop) {
                if (prop.id === 'name') {
                    prop.value = _this.termRegex;
                }
            });
        }
        return match || customConversion;
    };
    WhitelistingRule.PLATFORM_WEB = 'web';
    WhitelistingRule.PLATFORM_APP = 'app';
    return WhitelistingRule;
}(Model));
export { WhitelistingRule };
