import { Routes } from '@angular/router';
import { GlobalSearchComponent } from './global-search';
import { MyAccountsComponent } from './my-accounts';
import { NotificationsComponent } from './notifications';
import { PlatformComponent } from './platform.component';
import { AuthGuard, InternalUserGuard, CanLoadIsInternalUsers } from 'app/core/guards';
import { BulkOperationStatusComponent } from 'app/shared/components';
var ɵ0 = function () { return import("./internal-tools/internal-tools.module.ngfactory").then(function (mod) { return mod.InternalToolsModuleNgFactory; }); }, ɵ1 = function () { return import("./inventory-manager/inventory-manager.module.ngfactory").then(function (mod) { return mod.InventoryModuleNgFactory; }); }, ɵ2 = function () { return import("./campaign-manager/campaign-manager.module.ngfactory").then(function (mod) { return mod.CampaignManagerModuleNgFactory; }); }, ɵ3 = function () { return import("./reporting/reporting.module.ngfactory").then(function (mod) { return mod.ReportingModuleNgFactory; }); }, ɵ4 = function () { return import("./measurement/measurement.module.ngfactory").then(function (mod) { return mod.MeasurementModuleNgFactory; }); }, ɵ5 = function () { return import("./history/history.module.ngfactory").then(function (mod) { return mod.HistoryModuleNgFactory; }); };
export var routes = [
    // Match the root path.
    { path: '', pathMatch: 'full', redirectTo: '/campaign-manager' },
    {
        path: '',
        component: PlatformComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: NotificationsComponent,
                outlet: 'notifications'
            },
            {
                path: 'accounts',
                component: MyAccountsComponent
            },
            {
                path: 'bulk-operation-status',
                component: BulkOperationStatusComponent,
            },
            {
                path: 'internal-tools',
                canLoad: [CanLoadIsInternalUsers],
                loadChildren: ɵ0
            },
            {
                path: 'inventory-manager',
                loadChildren: ɵ1
            },
            {
                path: 'campaign-manager',
                loadChildren: ɵ2
            },
            {
                path: 'reporting',
                loadChildren: ɵ3
            },
            {
                path: 'measurement',
                loadChildren: ɵ4
            },
            {
                path: 'history',
                canActivate: [InternalUserGuard],
                loadChildren: ɵ5
            },
            {
                path: 'search',
                component: GlobalSearchComponent
            }
        ]
    }
];
var PlatformRoutingModule = /** @class */ (function () {
    function PlatformRoutingModule() {
    }
    return PlatformRoutingModule;
}());
export { PlatformRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
