import { Component, Input, ChangeDetectionStrategy, forwardRef, ContentChild, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { IdService } from 'app/core/id.service';
import { Status } from 'app/shared/models';

@Component({
    selector: 'tags-input',
    templateUrl: './tags-input.html',
    styleUrls: ['./tags-input.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TagsInputComponent),
            multi: true
        }
    ]
})
export class TagsInputComponent implements ControlValueAccessor {
    input$ = new BehaviorSubject('');
    items$ = new BehaviorSubject<any[]>([]);
    selectedItems$ = new BehaviorSubject<any[]>([]);
    availableItems$: Observable<any[]>;

    @Input() placeholder;
    @Input() disabled: Boolean = false;
    @Input() listLimit: number = 10;
    @ContentChild('searchTemplate', { static: true }) searchTemplate: TemplateRef<{ item: any, index: number }>;
    @ContentChild('listTemplate', { static: true }) listTemplate: TemplateRef<{ item: any, index: number }>;

    private onChange = (items: any[]) => { };

    constructor(
        public id: IdService
    ) { }

    ngOnInit() {
        this.availableItems$ = combineLatest(this.items$, this.selectedItems$, this.input$).pipe(
            map(([items, selectedItems, input]) => {
                const query = input.toLowerCase();
                const itemList = items.filter(item => selectedItems.findIndex(_item => _item.value === item.value) < 0)
                    .filter(item => input.length < 1 || item.label.toLowerCase().indexOf(query) > -1 || (item.search && item.search.toLowerCase().indexOf(query) > -1));
                return this.listLimit ? itemList.slice(0, this.listLimit) : itemList;
            })
        );
    }

    writeValue(selectedItems: any[]) {
        this.items$.subscribe(items => {
            const nextItems = (Array.isArray(selectedItems) ? selectedItems : [])
                .map(selectedItem => items.find(item => item.value === selectedItem))
                .filter(item => item !== undefined);

            this.selectedItems$.next(nextItems);
        });
    }

    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched() { }

    @Input('options') set items(items: any[]) {
        this.items$.next(Array.isArray(items) ? items : []);
    }

    select(item: any) {
        const selectedItems = this.selectedItems$.getValue().concat(item);
        this.selectedItems$.next(selectedItems);
        this.onChange(selectedItems.map(selectedItem => selectedItem.value));
    }

    remove(index: number) {
        const selectedItems = this.selectedItems$.getValue();
        selectedItems.splice(index, 1);
        this.selectedItems$.next(selectedItems);
        this.onChange(selectedItems.map(selectedItem => selectedItem.value));
    }

    removeAll() {
        const selectedItems = [];
        this.selectedItems$.next(selectedItems);
        this.onChange(selectedItems.map(selectedItem => selectedItem.value));
    }

    inputChange(value: string) {
        this.input$.next(value);
    }

    circleClass(status: Status) {
        switch (status) {
            case Status.ACTIVE:
                return 'circle-success';
            default:
                return 'circle-inactive';
        }
    }

    get showStatus() {
        return Array.isArray(this.items$.getValue()) && this.items$.getValue().some(item => item.status);
    }
}
