import { Directive, Input, Output, EventEmitter, ElementRef } from '@angular/core';

@Directive({
    exportAs: 'listInput',
    selector: '[listInput]'
})
export class ListInputDirective {
    @Input()
    list: any[] = [];

    @Output()
    listChange: EventEmitter<any[]> = new EventEmitter<any[]>();

    @Input()
    validatorFn: (value: string) => any = (value: string) => { return true; }

    @Input()
    parseFn: (rawList: string) => any = this.defaultParseFn;

    public valid: boolean = true;

    constructor(private el: ElementRef) {}

    /**
     * Detect and act upon changes that Angular doesn't catch on its own.
     */
    public ngDoCheck(): void {
        this.valid = this.el.nativeElement.value === '' || this.valid;
        this.el.nativeElement.classList.toggle('ng-invalid', !this.valid);
    }

    /**
     * Select each item inputted by the user as text.
     */
    public parse(): void {
        const invalid = [];
        const selected = [];

        this.parseFn(this.el.nativeElement.value)
            // remove already existing entries
            .filter(item => !this.list.some(existingItem => item === existingItem))
            // remove duplicate entries
            .filter((item, index, items) => items.indexOf(item) === index)
            // add items only if they are valid
            .forEach(item => this.validatorFn(item) ? selected.push(item) : invalid.push(item));

        this.el.nativeElement.value = invalid.join('\n');
        this.valid = invalid.length < 1;

        this.listChange.emit(this.list.concat(selected));
    }

    /**
     * Parse raw text into an array of strings. This function
     * will parse the input if no other `parseFn` is provided.
     * @param  {string}  rawList
     * @return {string[]}
     */
    private defaultParseFn(rawList: string): string[] {
        return rawList
            .replace(/\n/g, ',')
            .split(',')
            .map(token => token.trim())
            .filter(token => token !== '');
    }
}
