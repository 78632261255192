import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { Newsletter } from 'app/shared/models';
import { AdSlotRepository } from 'app/core/repositories/ad-slot-repository.service';
import { CategoryRepository } from 'app/core/repositories/category-repository.service';
import { PublisherRepository } from 'app/core/repositories/publisher-repository.service';
import { ProwlerRepository } from 'app/core/repositories/prowler-repository.service';
import { UserRepository } from 'app/core/repositories/user-repository.service';
import { BackendRepository } from './backend-repository';
var NewsletterRepository = /** @class */ (function (_super) {
    tslib_1.__extends(NewsletterRepository, _super);
    function NewsletterRepository(http, adSlotRepository, categoryHelper, publisherRepository, prowlerRepository, userRepository) {
        var _this = _super.call(this, http, '/newsletter', Newsletter) || this;
        _this.adSlotRepository = adSlotRepository;
        _this.categoryHelper = categoryHelper;
        _this.publisherRepository = publisherRepository;
        _this.prowlerRepository = prowlerRepository;
        _this.userRepository = userRepository;
        return _this;
    }
    /*
       Retrieve the safeRTB tags for the newsletter
       params: {
           id: refid|hash
       }
     */
    NewsletterRepository.prototype.getSafeRTB = function (id) {
        return this.http
            .get(this.url(this.path, 'safe-rtb-tags', id))
            .pipe(map(function (response) { return response['output']; }));
    };
    /**
     * Trigger an export of the newsletter tags in the MS Excel format:
     *
     *     params: {
     *         id: refid|hash
     *     }
     */
    NewsletterRepository.prototype.downloadTags = function (params) {
        var req = this.http.request('get', this.url.apply(this, [this.path, 'export-tags'].concat(params)), {
            headers: new HttpHeaders({ 'Accept': 'application/vnd.ms-excel' }),
            responseType: 'blob'
        });
        return req;
    };
    NewsletterRepository.prototype.linkDsps = function (params) {
        return this.http
            .post(this.url(this.path, 'link-dsps', params.id), params)
            .pipe(map(function (response) { return response['output']; }));
    };
    NewsletterRepository.prototype.validateNewsletter = function (params) {
        return this.http
            .post(this.url(this.path, 'validate'), params)
            .pipe(map(function (response) { return response['output']; }));
    };
    /*
      Transform a single Newsletter details
      - find all ad-slots for each newsletter
      - lookup and set an IAB category details
      - lookup and set users who created/modified the newsletters
    */
    NewsletterRepository.prototype.getNewsletterDetails = function (id) {
        var _this = this;
        var userConditions = {
            conditions: [{
                    field: 'hash_id',
                    value: []
                }]
        };
        var promisedNewsletter = new Promise(function (resolve, reject) {
            _this.get(id)
                .toPromise()
                .then(function (newsletter) {
                var adSlotConditions = [{
                        field: 'newsletter',
                        value: [newsletter.id],
                        operator: 'eq'
                    }];
                if (newsletter.createdBy.length === 32) {
                    userConditions.conditions[0].value.push(newsletter.createdBy);
                }
                if (newsletter.modifiedBy.length === 32) {
                    userConditions.conditions[0].value.push(newsletter.modifiedBy);
                }
                Promise.all([
                    _this.categoryHelper.all().toPromise(),
                    _this.adSlotRepository.getBy(adSlotConditions),
                    _this.userRepository.search(userConditions).toPromise()
                ]).then(function (data) {
                    var categories = data[0];
                    var adSlots = data[1];
                    var users = data[2];
                    var userLookup = [];
                    newsletter.adSlots = adSlots;
                    newsletter.primaryCategory = categories.find(function (cat) {
                        return cat.id === newsletter.category;
                    });
                    users.map(function (user) {
                        userLookup[user.hashId] = user;
                    });
                    if (newsletter.createdBy in userLookup) {
                        newsletter.createdByUser = userLookup[newsletter.createdBy];
                    }
                    if (newsletter.modifiedBy in userLookup) {
                        newsletter.modifiedByUser = userLookup[newsletter.modifiedBy];
                    }
                    return resolve(newsletter);
                });
            });
        });
        return from(promisedNewsletter);
    };
    /*
      The search results from /search/newsletter do not contain the ad slots, detailed user information and
      detailes for the IAB categories.
      Use this function to retrieve the Newsletters for the given publisher. The function will:
      - find all active newsletters for the publisher
      - find all ad-slots for each newsletter
      - lookup and set an IAB category details
      - lookup and set users who created/modified the newsletters
    */
    NewsletterRepository.prototype.searchByPublisherId = function (publisherId) {
        var _this = this;
        var promisedNewsletters = new Promise(function (resolve, reject) {
            var publisherConditions = {
                conditions: [{
                        field: 'publisher',
                        value: publisherId
                    }]
            };
            Promise.all([
                _this.categoryHelper.all().toPromise(),
                _this.search(publisherConditions).toPromise(),
                _this.adSlotRepository.getByPublisherId(publisherId)
            ]).then(function (data) {
                var categories = data[0];
                var newsletters = data[1];
                var adSlots = data[2];
                var userSet = new Set();
                newsletters.forEach(function (newsletter) {
                    newsletter.primaryCategory = categories.find(function (cat) {
                        return cat.id === newsletter.category;
                    });
                    newsletter.adSlots = adSlots.filter(function (slot) { return slot.newsletter === newsletter.id; });
                    if (newsletter.createdBy.length === 32) {
                        userSet.add(newsletter.createdBy);
                    }
                    if (newsletter.modifiedBy.length === 32) {
                        userSet.add(newsletter.modifiedBy);
                    }
                });
                var userConditions = {
                    conditions: [
                        {
                            field: 'hash_id',
                            value: Array.from(userSet)
                        }
                    ],
                };
                _this.userRepository
                    .search(userConditions)
                    .toPromise()
                    .then(function (users) {
                    var userLookup = [];
                    users.map(function (user) {
                        userLookup[user.hashId] = user;
                    });
                    newsletters.forEach(function (letter) {
                        if (letter.createdBy in userLookup) {
                            letter.createdByUser = userLookup[letter.createdBy];
                        }
                        if (letter.modifiedBy in userLookup) {
                            letter.modifiedByUser = userLookup[letter.modifiedBy];
                        }
                    });
                    resolve(newsletters);
                });
                resolve(newsletters);
            });
        });
        return from(promisedNewsletters);
    };
    NewsletterRepository.prototype.shopByStrategy = function (strategyId, params) {
        var _this = this;
        return forkJoin(this.http.post(this.url("/search/newsletter/shop-by-strategy/" + strategyId), this.sanitizeQuery(params))).pipe(map(function (_a) {
            var data = _a[0];
            return {
                items: data['output'].map(function (item) { return _this.build(item); }),
                page: 1,
                pages: data['pages'],
                total: data['total'],
            };
        }));
    };
    return NewsletterRepository;
}(BackendRepository));
export { NewsletterRepository };
