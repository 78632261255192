import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { BackendRepository } from './backend-repository';
import { SupplyImageAsset, SupplyTextAsset } from 'app/shared/models';
import { DemandImageAsset, DemandTextAsset } from 'app/shared/models/native-demand';
import { map } from 'rxjs/operators';
var NativeSupplyImageAssetRepository = /** @class */ (function (_super) {
    tslib_1.__extends(NativeSupplyImageAssetRepository, _super);
    function NativeSupplyImageAssetRepository(http) {
        return _super.call(this, http, '/native-supply-image', SupplyImageAsset) || this;
    }
    NativeSupplyImageAssetRepository.prototype.save = function (supplyImageAsset) {
        var _this = this;
        var params = [this.path];
        if (supplyImageAsset.refId) {
            params.push(supplyImageAsset.refId.toString());
        }
        return this.http.post(this.url.apply(this, params), supplyImageAsset.serialize())
            .pipe(map(function (response) { return _this.build(response['output']); }));
    };
    return NativeSupplyImageAssetRepository;
}(BackendRepository));
export { NativeSupplyImageAssetRepository };
var NativeSupplyTextAssetRepository = /** @class */ (function (_super) {
    tslib_1.__extends(NativeSupplyTextAssetRepository, _super);
    function NativeSupplyTextAssetRepository(http) {
        return _super.call(this, http, '/native-supply-text', SupplyTextAsset) || this;
    }
    NativeSupplyTextAssetRepository.prototype.save = function (supplyTextAsset) {
        var _this = this;
        var params = [this.path];
        if (supplyTextAsset.refId) {
            params.push(supplyTextAsset.refId.toString());
        }
        return this.http.post(this.url.apply(this, params), supplyTextAsset.serialize())
            .pipe(map(function (response) { return _this.build(response['output']); }));
    };
    return NativeSupplyTextAssetRepository;
}(BackendRepository));
export { NativeSupplyTextAssetRepository };
var NativeDemandImageAssetRepository = /** @class */ (function (_super) {
    tslib_1.__extends(NativeDemandImageAssetRepository, _super);
    function NativeDemandImageAssetRepository(http) {
        return _super.call(this, http, '/native-demand-image', DemandImageAsset) || this;
    }
    NativeDemandImageAssetRepository.prototype.save = function (demandImageAsset) {
        var _this = this;
        var params = [this.path];
        if (demandImageAsset.refId) {
            params.push(demandImageAsset.refId.toString());
        }
        return this.http.post(this.url.apply(this, params), demandImageAsset.serialize())
            .pipe(map(function (response) { return _this.build(response['output']); }));
    };
    return NativeDemandImageAssetRepository;
}(BackendRepository));
export { NativeDemandImageAssetRepository };
var NativeDemandTextAssetRepository = /** @class */ (function (_super) {
    tslib_1.__extends(NativeDemandTextAssetRepository, _super);
    function NativeDemandTextAssetRepository(http) {
        return _super.call(this, http, '/native-demand-text', DemandTextAsset) || this;
    }
    NativeDemandTextAssetRepository.prototype.save = function (demandTextAsset) {
        var _this = this;
        var params = [this.path];
        if (demandTextAsset.refId) {
            params.push(demandTextAsset.refId.toString());
        }
        return this.http.post(this.url.apply(this, params), demandTextAsset.serialize())
            .pipe(map(function (response) { return _this.build(response['output']); }));
    };
    return NativeDemandTextAssetRepository;
}(BackendRepository));
export { NativeDemandTextAssetRepository };
