import * as tslib_1 from "tslib";
import { Model } from '../model';
import { DemandImageAssetType } from 'app/shared/models/native-demand/demand-image-asset';
import { DemandTextAssetType } from 'app/shared/models/native-demand/demand-text-asset';
export var DemandAssetTypeId;
(function (DemandAssetTypeId) {
    DemandAssetTypeId[DemandAssetTypeId["Title"] = 20] = "Title";
    DemandAssetTypeId[DemandAssetTypeId["SponsoredBy"] = 21] = "SponsoredBy";
    DemandAssetTypeId[DemandAssetTypeId["Description"] = 22] = "Description";
    DemandAssetTypeId[DemandAssetTypeId["CTA"] = 32] = "CTA";
    DemandAssetTypeId[DemandAssetTypeId["ImageIcon"] = 33] = "ImageIcon";
    DemandAssetTypeId[DemandAssetTypeId["ImageMain"] = 34] = "ImageMain";
})(DemandAssetTypeId || (DemandAssetTypeId = {}));
export var IABAssets;
(function (IABAssets) {
    IABAssets["title"] = "title";
    IABAssets["data"] = "data";
    IABAssets["img"] = "img";
})(IABAssets || (IABAssets = {}));
var DemandAsset = /** @class */ (function (_super) {
    tslib_1.__extends(DemandAsset, _super);
    function DemandAsset() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DemandAsset.prototype.serialize = function () {
        delete this._isClone;
        return JSON.stringify(this);
    };
    Object.defineProperty(DemandAsset.prototype, "entity", {
        get: function () {
            return 'DemandAsset';
        },
        enumerable: true,
        configurable: true
    });
    return DemandAsset;
}(Model));
export { DemandAsset };
export var DemandAssetProperties = [{
        'label': 'Headline',
        'assetLabel': 'Headline',
        'modelKey': 'headline',
        'type': null,
        'assetKey': 'title',
        'multiLine': false
    }, {
        'label': 'Image Url',
        'assetLabel': 'Image',
        'modelKey': 'mainImage',
        'type': DemandImageAssetType.Main,
        'assetKey': 'img',
        'multiLine': false
    }, {
        'label': 'Body',
        'assetLabel': 'Body',
        'modelKey': 'description',
        'type': DemandTextAssetType.Description,
        'assetKey': 'data',
        'multiLine': true
    }, {
        'label': 'Call to Action',
        'assetLabel': 'CTA',
        'modelKey': 'callToAction',
        'type': DemandTextAssetType.CallToAction,
        'assetKey': 'data',
        'multiLine': false
    }, {
        'label': 'Sponsored By',
        'assetLabel': 'Sponsored',
        'modelKey': 'sponsored',
        'type': DemandTextAssetType.SponsoredBy,
        'assetKey': 'data',
        'multiLine': false
    }, {
        'label': 'Logo Url',
        'assetLabel': 'Logo',
        'modelKey': 'logoImage',
        'type': DemandImageAssetType.Icon,
        'assetKey': 'img',
        'multiLine': false
    }];
