import * as tslib_1 from "tslib";
import { ChangeDetectorRef, EventEmitter, OnInit, TemplateRef, ElementRef } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { FilterPipe } from '../pipes/filter.pipe';
import { Navigable } from '../dropdown';
import { DropdownComponent } from '../dropdown';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IdService } from 'app/core/id.service';
var SearchableSelectSingleComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SearchableSelectSingleComponent, _super);
    function SearchableSelectSingleComponent(cdr, id) {
        var _this = _super.call(this, cdr) || this;
        _this.cdr = cdr;
        _this.id = id;
        _this.DELAY = 0;
        _this.itemList = [];
        //Set listLimit to 0 for all options
        _this.listLimit = 5;
        _this.remove = new EventEmitter();
        _this.searchableSelectSingleBlur = new EventEmitter();
        _this.inputChange = new EventEmitter();
        _this.template = null;
        _this.onTouchedCallback = null;
        _this.onChangeCallback = null;
        _this._selected = null;
        _this.input = '';
        _this.filter = new FilterPipe();
        _this._showLoading = false;
        return _this;
    }
    SearchableSelectSingleComponent.prototype.getWidth = function () {
        return this.editor.nativeElement.offsetWidth || '100%';
    };
    Object.defineProperty(SearchableSelectSingleComponent.prototype, "selected", {
        get: function () {
            return this._selected;
        },
        set: function (value) {
            this._selected = value;
        },
        enumerable: true,
        configurable: true
    });
    SearchableSelectSingleComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.refresh(this.input);
        this.dropdown.setWidth(this.getWidth());
        fromEvent(this.editor.nativeElement, 'input').pipe(map(function () { return _this.input; }), debounceTime(this.DELAY), distinctUntilChanged()).subscribe(function (input) { return _this.inputChange.emit(input); });
    };
    SearchableSelectSingleComponent.prototype.writeValue = function (value) {
        this.currentValue = value;
        if (value === null && this.onChangeCallback) {
            this.selected = null;
            this.refresh('');
        }
        else {
            for (var _i = 0, _a = this._items; _i < _a.length; _i++) {
                var item = _a[_i];
                if (item.value === value) {
                    this.selected = item;
                    this.onTouchedCallback();
                    this.refresh('');
                    break;
                }
            }
        }
    };
    SearchableSelectSingleComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    SearchableSelectSingleComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    SearchableSelectSingleComponent.prototype.refresh = function (input) {
        this.input = input;
        if (this.input === '') {
            this.items = this.listLimit === 0 ? this._items
                : this._items.slice(0, this.listLimit);
        }
        else {
            this.items = this.listLimit === 0 ? this.filter.transform(this._items, this.input)
                : this.filter.transform(this._items, this.input).slice(0, this.listLimit);
        }
    };
    SearchableSelectSingleComponent.prototype.select = function (event, index) {
        if (index > -1) {
            if (this.items[index]) {
                this.selected = this.items[index];
                this.onChangeCallback(this._selected ? this._selected.value : null);
            }
            else {
                return;
            }
        }
        this.hideDropdown();
    };
    SearchableSelectSingleComponent.prototype.hideDropdown = function () {
        this.dropdown.hide();
    };
    SearchableSelectSingleComponent.prototype.editorBlur = function () {
        var _this = this;
        window.setTimeout(function () {
            _this.hideDropdown();
            _this.searchableSelectSingleBlur.emit();
            _this.onTouchedCallback();
            _this.editor.nativeElement.blur();
        }, 200);
    };
    SearchableSelectSingleComponent.prototype.editorFocus = function () {
        if (this.showListOnFocus) {
            this.refresh(this.input);
        }
        this.editor.nativeElement.classList.remove('input-field--error');
    };
    SearchableSelectSingleComponent.prototype.deselect = function () {
        if (!this.disabled) {
            this.selected = null;
            this.onChangeCallback(null);
            this.editor.nativeElement.focus();
        }
    };
    SearchableSelectSingleComponent.prototype.resetDropDown = function () {
        this.selected = null;
        this.refresh('');
    };
    SearchableSelectSingleComponent.prototype.search = function (value) {
        this.refresh(value);
    };
    SearchableSelectSingleComponent.prototype.keydown = function (event) {
        if (event.keyCode === 8 && this.input.length < 1) {
            this.remove.emit();
            return;
        }
        _super.prototype.keydown.call(this, event);
    };
    Object.defineProperty(SearchableSelectSingleComponent.prototype, "_items", {
        get: function () {
            return this.itemList;
        },
        set: function (newItems) {
            if (this.itemList && newItems && (this.itemList !== newItems || this.itemList.length !== newItems.length)) {
                this.itemList = newItems;
                this.writeValue(this.currentValue);
                this.showLoading = false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchableSelectSingleComponent.prototype, "showLoading", {
        get: function () {
            return this._showLoading;
        },
        set: function (showLoading) {
            this._showLoading = showLoading;
        },
        enumerable: true,
        configurable: true
    });
    return SearchableSelectSingleComponent;
}(Navigable));
export { SearchableSelectSingleComponent };
