import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { BackendRepository } from './backend-repository';
import { Rule } from 'app/shared/models/rule';
import { map, mergeMap } from 'rxjs/operators';
var RuleRepository = /** @class */ (function (_super) {
    tslib_1.__extends(RuleRepository, _super);
    function RuleRepository(http) {
        return _super.call(this, http, "/ruth/rules", Rule) || this;
    }
    RuleRepository.prototype.getRulesByAppId = function (appId, accountId) {
        var _this = this;
        var params = {
            accountId: accountId.toString(),
            filter: "appId:" + appId,
        };
        return this.http.get(this.url(this.path), { params: params }).pipe(map(function (response) { return response["results"]; }), map(function (results) { return results.map(function (result) { return _this.build(result); }); }));
    };
    RuleRepository.prototype.getRuleByAppIdAndPixelId = function (appId, pixelId, accountId) {
        return this.getRulesByAppId(appId, accountId).pipe(mergeMap(function (rules) {
            if (!rules) {
                throw throwError("There is no rule associated with app id " + appId + " and pixel id " + pixelId);
            }
            var rule = rules.find(function (rule) { return rule.pixelId === pixelId; });
            if (!rule) {
                throw throwError("There is no rule associated with app id " + appId + " and pixel id " + pixelId);
            }
            return of(rule);
        }));
    };
    return RuleRepository;
}(BackendRepository));
export { RuleRepository };
