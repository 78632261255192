import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { BehaviorSubject, of, ReplaySubject } from 'rxjs';
import { AuthorizationService, IdService } from 'app/core';
import { BlueprintRepository } from 'app/core/repositories';
import { Blueprint, NativeBlueprint } from 'app/shared/models';
import { TableHelper } from 'app/shared/helpers/table-helper';
import { LightboxComponent } from 'app/shared/elements/lightbox';
import { AsyncTableComponent, TableQuery } from 'app/shared/elements/async-table';
import { Router } from '@angular/router';
import { StatusId } from 'app/shared/models/status';

interface BlueprintOption {
    label: string;
    value: number;
    key: number;
}

const MAX_LINKABLE_BLUEPRINTS = 10;

@Component({
    selector: 'link-blueprints',
    templateUrl: './link-blueprints.html',
    styleUrls: ['./link-blueprints.styl']
})

export class LinkBlueprintsComponent implements OnInit {
    @Input() adSlot;
    @Input() mediaGroup;
    @Output() blueprintsLinked = new EventEmitter();
    @ViewChild(LightboxComponent, { static: true }) lightbox: LightboxComponent;
    @ViewChild(AsyncTableComponent, { static: true }) table: AsyncTableComponent;

    helper = new TableHelper<Blueprint>(params => this.adapter(params));
    nativeBlueprints$ = new ReplaySubject<NativeBlueprint[]>(1);
    public blueprintOptions$ = new ReplaySubject<BlueprintOption[]>(1);
    private currentParams$ = new BehaviorSubject<any>({});

    private allAvailableBlueprints: Array<Blueprint> = [];

    constructor(
        public blueprintRepository: BlueprintRepository,
        public router: Router,
        public authorizationService: AuthorizationService,
        public id: IdService,
        public location: Location,
    ) {}

    ngOnInit(): void {
        this.nativeBlueprints$.next(this.adSlot.nativeBlueprints);
        this.table.query.subscribe(args => this.helper.search(args));
    }

    private adapter(params) {
        if (!this.adSlot.isNative || (!this.adSlot.nativeBlueprints
                && !this.mediaGroup && !this.adSlot.mediaGroupRefId)) {
            return of({
                items: [],
                page: 1,
                pages: 0,
                total: 0
            })
        }

        const mediaGroupRefId = this.adSlot.mediaGroupRefId || this.mediaGroup.refId;
        if (this.adSlot.nativeBlueprints) {
            params.conditions.push({
                field: 'id',
                value: this.adSlot.nativeBlueprints.map(blueprint => blueprint.blueprintId),
                operator: 'neq'
            });
        }
        if (mediaGroupRefId) {
            params.conditions.push({
                field: 'mediaGroupId',
                value: mediaGroupRefId
            });
        }

        params.conditions.push({
            field: 'status',
            value: StatusId.ACTIVE_ID
        });

        return this.blueprintRepository.asyncSearch(params);
    }

    query(tableQuery: TableQuery) {
        this.currentParams$.next(tableQuery);
    }

    get availableBlueprintsToLink() {
        const existingNativeBlueprints = this.adSlot.nativeBlueprints ? this.adSlot.nativeBlueprints.length : 0;

        const maxLinkableItems = Math.min(MAX_LINKABLE_BLUEPRINTS, this.table.rows.length + existingNativeBlueprints);

        return Math.max(0, maxLinkableItems - existingNativeBlueprints - this.selectedBlueprints.length);
    }

    get selectedBlueprints() {
        if (this.allAvailableBlueprints.length > 0) {
            return this.allAvailableBlueprints;
        } else if (this.table) {
            return this.table.selectedItems;
        }
        return [];
    }

    public confirmLinkBlueprints() {
        const nativeBlueprints = [];
        this.selectedBlueprints.map((blueprint) => {
            nativeBlueprints.push(
                {
                    blueprintId: blueprint.id,
                    floor: 0.0,
                    allowExchange: false,
                    allowRtb: false
                }
            );
        });

        this.blueprintsLinked.emit(nativeBlueprints);
        this.close();
    }

    open() {
        this.table.filters = [];
        this.table.clearSelections();
        this.lightbox.open();
    }

    close() {
        this.lightbox.close();
    }

    redirectToCreateBlueprint() {
        this.close();

        const url = this.router.serializeUrl(
            this.router.createUrlTree(['/inventory-manager/native/native-templates/new'])
        );
        window.open(url, '_blank');
    }

}
