import { AuthenticationService } from './authentication.service';
import { map, take } from 'rxjs/operators';
import { PublisherClientTypes } from 'app/shared/models/publisher';
var NYTIMES_MEDIA_GROUP_HASH = '9e1f7b627b6411e2be2a1231392ea33d';
var NYTIMES_PUBLISHER_HASH = '4daa3a54acc211e088be12313e009a02';
export var Action;
(function (Action) {
    Action[Action["View"] = 0] = "View";
})(Action || (Action = {}));
export var Entity;
(function (Entity) {
    Entity["LiveIntent"] = "LiveIntent";
    Entity["MediaGroup"] = "MediaGroup";
    Entity["Publisher"] = "Publisher";
    Entity["Agency"] = "Agency";
    Entity["Advertiser"] = "Advertiser";
    Entity["Feature"] = "Feature";
})(Entity || (Entity = {}));
export var Role;
(function (Role) {
    Role[Role["Admin"] = 1] = "Admin";
    Role[Role["AdOps"] = 2] = "AdOps";
    Role[Role["AccountDirector"] = 27] = "AccountDirector";
    Role[Role["StandardUser"] = 28] = "StandardUser";
    Role[Role["AccountManager"] = 29] = "AccountManager";
    // External Users: Media Group
    Role[Role["MediaGroupAccess"] = 4] = "MediaGroupAccess";
    Role[Role["MediaGroupManager"] = 5] = "MediaGroupManager";
    Role[Role["MediaGroupDemand"] = 6] = "MediaGroupDemand";
    Role[Role["MediaGroupReports"] = 7] = "MediaGroupReports";
    Role[Role["MediaGroupAudience"] = 8] = "MediaGroupAudience";
    Role[Role["MediaGroupManagePublishers"] = 36] = "MediaGroupManagePublishers";
    // External Users: Publisher
    Role[Role["PublisherAccess"] = 18] = "PublisherAccess";
    Role[Role["PublisherManager"] = 19] = "PublisherManager";
    Role[Role["PublisherDemand"] = 20] = "PublisherDemand";
    Role[Role["PublisherUpload"] = 21] = "PublisherUpload";
    Role[Role["PublisherScrub"] = 22] = "PublisherScrub";
    Role[Role["PublisherReports"] = 23] = "PublisherReports";
    Role[Role["PublisherAudience"] = 24] = "PublisherAudience";
    Role[Role["PublisherUser"] = 26] = "PublisherUser";
    //External User: Agency
    Role[Role["AgencyAccess"] = 9] = "AgencyAccess";
    Role[Role["AgencyManager"] = 10] = "AgencyManager";
    Role[Role["AgencyDemand"] = 11] = "AgencyDemand";
    Role[Role["AgencyReports"] = 12] = "AgencyReports";
    Role[Role["AgencyAudience"] = 13] = "AgencyAudience";
    //External User: Advertiser
    Role[Role["AdvertiserAccess"] = 14] = "AdvertiserAccess";
    Role[Role["AdvertiserManager"] = 15] = "AdvertiserManager";
    Role[Role["AdvertiserReports"] = 16] = "AdvertiserReports";
    Role[Role["AdvertiserAudience"] = 17] = "AdvertiserAudience";
    Role[Role["AdvertiserUser"] = 25] = "AdvertiserUser";
    //Feature
    Role[Role["NativeLike"] = 33] = "NativeLike";
})(Role || (Role = {}));
var AuthorizationService = /** @class */ (function () {
    function AuthorizationService(authenticationService) {
        this.authenticationService = authenticationService;
        this.currentList = this.authenticationService.currentAccessList;
        this.accessList$ = this.authenticationService.accessList$;
    }
    Object.defineProperty(AuthorizationService.prototype, "isAdmin", {
        get: function () {
            return this.hasRole(Role.Admin);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isManager", {
        get: function () {
            return this.isAccountDirector
                || this.isAccountManager
                || this.isStandardUser
                || this.isAdOps;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isInternalUser", {
        get: function () {
            return this.isAdmin || this.isManager;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isAdOps", {
        get: function () {
            return this.hasRole(Role.AdOps);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isAccountManager", {
        get: function () {
            return this.hasRole(Role.AccountManager);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isStandardUser", {
        get: function () {
            return this.hasRole(Role.StandardUser);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isAccountDirector", {
        get: function () {
            return this.hasRole(Role.AccountDirector);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isMediaGroupManager", {
        get: function () {
            return this.hasRole(Role.MediaGroupManager, Entity.MediaGroup);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isMediaGroupDemand", {
        get: function () {
            return this.hasRole(Role.MediaGroupDemand, Entity.MediaGroup);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isMediaGroupAccess", {
        get: function () {
            return this.hasRole(Role.MediaGroupAccess, Entity.MediaGroup);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isMediaGroupReports", {
        get: function () {
            return this.hasRole(Role.MediaGroupReports, Entity.MediaGroup);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isMediaGroupAudience", {
        get: function () {
            return this.hasRole(Role.MediaGroupAudience, Entity.MediaGroup);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isMediaGroupManagePublisher", {
        get: function () {
            return this.hasRole(Role.MediaGroupManagePublishers, Entity.MediaGroup)
                && this.hasRole(Role.MediaGroupManager, Entity.MediaGroup);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isPublisherManager", {
        get: function () {
            return this.hasRole(Role.PublisherManager, Entity.Publisher);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isPublisherDemand", {
        get: function () {
            return this.hasRole(Role.PublisherDemand, Entity.Publisher);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isPublisherAccess", {
        get: function () {
            return this.hasRole(Role.PublisherAccess, Entity.Publisher);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isPublisherUpload", {
        get: function () {
            return this.hasRole(Role.PublisherUpload, Entity.Publisher);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isPublisherScrub", {
        get: function () {
            return this.hasRole(Role.PublisherScrub, Entity.Publisher);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isPublisherReports", {
        get: function () {
            return this.hasRole(Role.PublisherReports, Entity.Publisher);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isPublisherAudience", {
        get: function () {
            return this.hasRole(Role.PublisherAudience, Entity.Publisher);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isPublisherUser", {
        get: function () {
            return this.hasRole(Role.PublisherUser, Entity.Publisher);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isAgencyManager", {
        get: function () {
            return this.hasRole(Role.AgencyManager, Entity.Agency);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isAgencyDemand", {
        get: function () {
            return this.hasRole(Role.AgencyDemand, Entity.Agency);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isAgencyAudience", {
        get: function () {
            return this.hasRole(Role.AgencyAudience, Entity.Agency);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isAgencyAccess", {
        get: function () {
            return this.hasRole(Role.AgencyAccess, Entity.Agency);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isAgencyReports", {
        get: function () {
            return this.hasRole(Role.AgencyReports, Entity.Agency);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isAdvertiserAccess", {
        get: function () {
            return this.hasRole(Role.AdvertiserAccess, Entity.Advertiser);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isAdvertiserAudience", {
        get: function () {
            return this.hasRole(Role.AdvertiserAudience, Entity.Advertiser);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isAdvertiserReports", {
        get: function () {
            return this.hasRole(Role.AdvertiserReports, Entity.Advertiser);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isAdvertiserManager", {
        get: function () {
            return this.hasRole(Role.AdvertiserManager, Entity.Advertiser);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isAdvertiserUser", {
        get: function () {
            return this.hasRole(Role.AdvertiserUser, Entity.Advertiser);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isNativeUser", {
        get: function () {
            return this.canManageMediaGroup || this.canManagePublisher;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isNativeLikeUser", {
        get: function () {
            return this.hasRole(Role.NativeLike, Entity.Feature);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "hasPublisherDetailsAccess", {
        get: function () {
            return this.isInternalUser
                || this.isMediaGroupAccess
                || this.isMediaGroupAudience
                || this.isMediaGroupDemand
                || this.isMediaGroupManager
                || this.isMediaGroupReports
                || this.isPublisherManager
                || this.isPublisherDemand
                || this.isPublisherAccess
                || this.isPublisherUpload
                || this.isPublisherScrub
                || this.isPublisherReports
                || this.isPublisherAudience;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "hasReportingAccess", {
        get: function () {
            return this.hasCampaignReportingAccess || this.hasInventoryReportingAccess;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "hasCampaignReportingAccess", {
        get: function () {
            return this.isInternalUser
                || this.allowedReportingMediaGroupUser
                || this.isAdvertiserReports
                || this.isAdvertiserManager
                || this.isAdvertiserUser
                || this.isAgencyDemand
                || this.isAgencyManager
                || this.isAgencyReports;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "hasInventoryReportingAccess", {
        get: function () {
            return this.isInternalUser
                || this.allowedReportingMediaGroupUser
                || this.isPublisherDemand
                || this.isPublisherManager
                || this.isPublisherReports
                || this.isPublisherUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "allowedReportingMediaGroupUser", {
        get: function () {
            return this.isMediaGroupDemand
                || this.isMediaGroupManager
                || this.isMediaGroupReports;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "isNewYorkTimesUser", {
        get: function () {
            if (this.currentList.getValue()) {
                var mediaGroup = this.currentList.getValue().entities.MediaGroup;
                var publisher = this.currentList.getValue().entities.Publisher;
                if (mediaGroup) {
                    return mediaGroup.some(function (mg) { return mg.id === NYTIMES_MEDIA_GROUP_HASH; });
                }
                if (publisher) {
                    return publisher.some(function (pb) { return pb.id === NYTIMES_PUBLISHER_HASH; });
                }
                return false;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    AuthorizationService.prototype.hasRole = function (role, entity) {
        if (entity === void 0) { entity = Entity.LiveIntent; }
        return this.currentList.getValue().hasAccess(entity, role);
    };
    AuthorizationService.prototype.hasRoleAsync = function (role, entity) {
        if (entity === void 0) { entity = Entity.LiveIntent; }
        return this.accessList$.pipe(take(1), map(function (currentList) { return currentList.hasAccess(entity, role); }));
    };
    Object.defineProperty(AuthorizationService.prototype, "canManageMediaGroup", {
        get: function () {
            return this.isMediaGroupManager || this.isMediaGroupDemand;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "canManagePublisher", {
        get: function () {
            return this.isPublisherManager || this.isPublisherDemand;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "canEditAccountId", {
        get: function () {
            return this.isAdmin || this.isAdOps;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "canViewAccountId", {
        get: function () {
            return this.isInternalUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "canAccessPackages", {
        get: function () {
            return (this.isAdmin
                || this.isManager
                || this.isMediaGroupAccess
                || this.isMediaGroupManager
                || this.isMediaGroupDemand
                || this.isPublisherAccess
                || this.isPublisherManager
                || this.isPublisherDemand);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "canAccessPackageActions", {
        get: function () {
            return (this.isAdmin
                || this.isAdOps
                || this.isMediaGroupManager
                || this.isMediaGroupDemand
                || this.isPublisherManager
                || this.isPublisherDemand);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "canUseIncreasedCreativeFileSizes", {
        get: function () {
            return this.isInternalUser
                || this.isMediaGroupManager
                || this.isMediaGroupDemand
                || this.isPublisherManager
                || this.isPublisherDemand;
        },
        enumerable: true,
        configurable: true
    });
    AuthorizationService.prototype.can = function (action, role, entity) {
        if (role === void 0) { role = null; }
        if (entity === void 0) { entity = Entity.LiveIntent; }
        if (action === Action.View) {
            return this.hasRole(role, entity);
        }
        return false;
    };
    AuthorizationService.prototype.canModifyMonetizationPublisher = function (publisherClientTypes) {
        // Internal
        if (this.isAdmin || this.isInternalUser) {
            return true;
        }
        // External
        if (!this.canManageMediaGroup && !this.canManagePublisher) {
            return false;
        }
        // If client type is null -> monetization
        if (!publisherClientTypes) {
            return true;
        }
        if (typeof publisherClientTypes === 'string') {
            publisherClientTypes = JSON.parse(publisherClientTypes);
        }
        return publisherClientTypes.some(function (type) { return type === PublisherClientTypes.Monetization; });
    };
    return AuthorizationService;
}());
export { AuthorizationService };
