<div *ngIf="isVisible" class="backdrop" [@backdrop]="visibility"></div>
<div *ngIf="isVisible" class="lightbox" [@lightbox]="visibility" (click)="hide()">
    <div class="container relative" (click)="$event.stopPropagation()">
        <overlay-loading-indicator *ngIf="isLoading" class="no-spinner" opacity=".9">
            <ng-template>
                <h4>
                    <span>{{action | titlecase}}</span><blink>...</blink>
                </h4>
                <div class="relative progress-bar-container spacer--large-top spacer--small-bottom">
                    <progress-bar indeterminate></progress-bar>
                </div>
            </ng-template>
        </overlay-loading-indicator>
        <header>
            <div class="row">
                <div class="column--8">
                    <h4>{{header | empty: '&nbsp;'}}</h4>
                </div>
                <div class="column--4">
                    <button type="button" (click)="hide()">
                        <i class="fas fa-times"></i>
                    </button>
                </div>
            </div>
        </header>
        <ng-content select=".body"></ng-content>
        <ng-content select="footer"></ng-content>
    </div>
</div>
