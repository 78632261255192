import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Subject, ReplaySubject, zip, of } from 'rxjs';
import { LineItem } from 'app/shared/models';
import { groupBy } from 'app/core/array-utils';
import { BackendRepository } from './backend-repository';
import { bufferWhen, debounceTime, filter, mergeMap, map } from 'rxjs/operators';
var LineItemRepository = /** @class */ (function (_super) {
    tslib_1.__extends(LineItemRepository, _super);
    function LineItemRepository(http) {
        var _this = _super.call(this, http, '/strategy', LineItem) || this;
        _this.creativeIdToLineItems = new Map();
        _this.adSlotIdToLineItems = new Map();
        _this.creativeIdToSearch$ = new Subject();
        _this.adSlotIdToSearch$ = new Subject();
        _this.creativeIdToSearch$.pipe(bufferWhen(function () { return _this.creativeIdToSearch$.pipe(debounceTime(BackendRepository.FLUSH_INTERVAL)); }), filter(function (ids) { return ids.length > 0; }), mergeMap(function (ids) { return zip(of(ids), _this.searchByCreativeId(ids)); })).subscribe(function (_a) {
            var ids = _a[0], lineItems = _a[1];
            var mapped = groupBy(lineItems, 'creative');
            ids.forEach(function (id) {
                _this.creativeIdToLineItems.get(id).next(mapped[id] || []);
            });
        });
        _this.adSlotIdToSearch$.pipe(bufferWhen(function () { return _this.adSlotIdToSearch$.pipe(debounceTime(BackendRepository.FLUSH_INTERVAL)); }), filter(function (ids) { return ids.length > 0; }), mergeMap(function (ids) { return zip(of(ids), _this.searchByAdSlotId(ids)); })).subscribe(function (_a) {
            var ids = _a[0], lineItems = _a[1];
            var mapped = groupBy(lineItems, 'adSlot');
            ids.forEach(function (id) {
                _this.adSlotIdToLineItems.get(id).next(mapped[id] || []);
            });
        });
        return _this;
    }
    LineItemRepository.prototype.clearCache = function () {
        this.creativeIdToLineItems = new Map();
    };
    LineItemRepository.prototype.searchByAdSlots = function (id) {
        var _this = this;
        var query = {
            conditions: [
                { field: 'adSlot', value: id }
            ]
        };
        return this.http.post(this.url('/search' + this.path, 'ad-slot'), query)
            .pipe(map(function (response) { return response['output']
            .map(function (data) { return _this.build(data); }); }));
    };
    LineItemRepository.prototype.linkCreatives = function (lineItemId, version, creativeIds) {
        var _this = this;
        return this.http.post(this.url(this.path, 'link-creatives', lineItemId), {
            version: version,
            creatives: creativeIds
        }).pipe(map(function (response) { return _this.build(response['output']); }));
    };
    LineItemRepository.prototype.confirmLinkAdSlots = function (lineItemId, version, adSlotIds) {
        return this.http.post(this.url(this.path, 'link-ad-slots-confirm', lineItemId), {
            version: version,
            adSlots: adSlotIds
        });
    };
    LineItemRepository.prototype.linkAdSlots = function (lineItemId, version, adSlotIds) {
        return this.http.post(this.url(this.path, 'link-ad-slots', lineItemId), {
            version: version,
            adSlots: adSlotIds
        });
    };
    LineItemRepository.prototype.searchByCreativeId = function (creativeIds) {
        var _this = this;
        var params = {
            conditions: [
                { field: 'creative', value: creativeIds }
            ]
        };
        return this.http.post(this.url('/search/strategy/creative'), params)
            .pipe(map(function (response) { return response['output']
            .map(function (data) { return _this.build(data); }); }));
    };
    LineItemRepository.prototype.searchByAdSlotId = function (adSlotIds) {
        var _this = this;
        var params = {
            conditions: [
                { field: 'adSlot', value: adSlotIds }
            ]
        };
        return this.http.post(this.url('/search/strategy/ad-slot'), params)
            .pipe(map(function (response) { return response['output'].map(function (data) { return _this.build(data); }); }));
    };
    LineItemRepository.prototype.getByCreative = function (creativeId) {
        if (!this.creativeIdToLineItems.has(creativeId)) {
            this.creativeIdToSearch$.next(creativeId);
            this.creativeIdToLineItems.set(creativeId, new ReplaySubject());
        }
        return this.creativeIdToLineItems.get(creativeId);
    };
    LineItemRepository.prototype.getByAdSlot = function (adSlotId) {
        if (!this.adSlotIdToLineItems.has(adSlotId)) {
            this.adSlotIdToSearch$.next(adSlotId);
            this.adSlotIdToLineItems.set(adSlotId, new ReplaySubject());
        }
        return this.adSlotIdToLineItems.get(adSlotId);
    };
    LineItemRepository.prototype.searchByAudience = function (audienceRefId) {
        var _this = this;
        return this.http.post(this.url('/search/strategy/audience', audienceRefId.toString()), null)
            .pipe(map(function (response) { return response['output']
            .map(function (data) { return _this.build(data); }); }));
    };
    LineItemRepository.prototype.asyncSearchByCreative = function (params) {
        var _this = this;
        return this.http.post(this.url('/search' + this.path, 'creative'), params)
            .pipe(map(function (data) { return ({
            items: data['output'].map(function (item) { return _this.build(item); }),
            page: 1,
            pages: data['pages'],
            total: data['total']
        }); }));
    };
    LineItemRepository.prototype.exchangeStrategySearch = function (params) {
        var _this = this;
        return this.http.post(this.url('/search/exchange-strategy'), params)
            .pipe(map(function (response) { return response['output']
            .map(function (data) { return _this.build(data); }); }));
    };
    LineItemRepository.prototype.asyncSearchByAdSlot = function (params) {
        var _this = this;
        return this.http.post(this.url('/search' + this.path, 'ad-slot'), params)
            .pipe(map(function (data) { return ({
            items: data['output'].map(function (item) { return _this.build(item); }),
            page: 1,
            pages: data['pages'],
            total: data['total']
        }); }));
    };
    LineItemRepository.prototype.getEligibleAdSlots = function (lineItemId, creativeId) {
        return this.http.get(this.url('/strategy-creative/eligible-ad-slots', lineItemId, creativeId))
            .pipe(map(function (response) { return response['output']; }));
    };
    return LineItemRepository;
}(BackendRepository));
export { LineItemRepository };
