import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class UtilRepository {
    public constructor(private http: HttpClient) { }

    public demandSearch(query: string): Observable<any[]> {
        return this.http.get(this.url('/search/demand', query))
            .pipe(map(response => response['output']));
    }

    public supplySearch(query: string): Observable<any[]> {
        return this.http.get(this.url('/search/supply', query))
            .pipe(map(response => response['output']));
    }

    public strategyLaunch(params: {}, strategyId) {
        return this.http.post(this.url('/strategy/launch', strategyId), JSON.stringify(params));
    }

    public parseExcel(params: {}): Observable<any[]> {
        return this.http.post(this.url('/util/parse-excel'), JSON.stringify(params))
            .pipe(map(response => response['output']));
    }

    /**
     * Builds and returns a URL for the backend API given an array of strings
     * `suffix` which are joined using a forward-slash.
     * @param suffix
     */
    url(...suffix: string[]): string {
        return environment.backend.hostname + suffix.join('/');
    }
}
