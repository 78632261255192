import { ChangeDetectorRef, Component, ContentChild, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { LightboxnextComponent } from 'app/shared/elements/lightboxnext';

@Component({
    selector: 'regex-test-lightbox',
    templateUrl: './regex-test-lightbox.html',
    styleUrls: ['./regex-test-lightbox.styl']
})
export class RegexTestLightboxComponent {
    @ViewChild(LightboxnextComponent, { static: true }) private lightbox;
    @ViewChild('textarea', { static: true }) private textarea: ElementRef;
    @ViewChild('backdrop', { static: true }) private backdrop: ElementRef;
    @ContentChild('template', { static: true }) template: TemplateRef<any>;
    regex: string;
    highlightedText: string;

    constructor(private cdr: ChangeDetectorRef) { }

    open(regex: string) {
        this.regex = regex;
        this.lightbox.show();
        this.handleInput(this.textarea.nativeElement.value || '');
        this.cdr.detectChanges();
    }

    close() {
        this.lightbox.hide();
    }

    handleInput(text: string) {
        this.highlightedText = this.applyHighlights(text);
    }

    handleScroll() {
        const top = this.textarea.nativeElement.scrollTop;
        this.backdrop.nativeElement.scrollTop = top;
    }

    private applyHighlights(text: string) {
        return text
            .replace(/\n$/g, '\n\n')
            .replace(new RegExp(this.regex, 'gm'), '<mark>$&</mark>');
    }
}
