import { Campaign, LineItem } from 'app/shared/models';
var GoalMetricCardComponent = /** @class */ (function () {
    function GoalMetricCardComponent() {
        this.lineItem = null;
        this._campaign = null;
    }
    Object.defineProperty(GoalMetricCardComponent.prototype, "campaign", {
        get: function () {
            return this._campaign;
        },
        set: function (campaign) {
            if (campaign !== null) {
                this._campaign = campaign;
                this.campaignGoal = this.campaign.meta;
                this.initSubject();
                this.adjustedCtr = this.getAdjustedCTR();
                this.ctr = this.getCTR();
                this.goaleCPA = this.getGoaleCPA();
                this.goaleCPC = this.getGoaleCPC();
                this.goaleCPM = this.getGoaleCPM();
            }
        },
        enumerable: true,
        configurable: true
    });
    GoalMetricCardComponent.prototype.initSubject = function () {
        if (this.lineItem) {
            this.subject = {
                bidAmount: this.campaign.bidAmount,
                conversions: this.lineItem.conversions,
                clicks: this.lineItem.clicks,
                impressions: this.lineItem.impressions,
                spend: this.lineItem.spend,
                adjustedImpressions: this.lineItem.adjustedImpressions
            };
        }
        else {
            this.subject = {
                bidAmount: this.campaign.bidAmount,
                conversions: this.campaign.conversions,
                clicks: this.campaign.clicks,
                impressions: this.campaign.impressions,
                spend: this.campaign.spend
            };
        }
    };
    GoalMetricCardComponent.prototype.getAdjustedCTR = function () {
        return Number(this.subject.adjustedImpressions)
            ? (this.subject.clicks / (this.subject.adjustedImpressions))
            : 0;
    };
    GoalMetricCardComponent.prototype.getCTR = function () {
        return Number(this.subject.impressions) ? (this.subject.clicks / (this.subject.impressions)) : 0;
    };
    GoalMetricCardComponent.prototype.getCCR = function () {
        return Number(this.subject.conversions) ?
            (this.subject.conversions / this.subject.clicks) : 0;
    };
    GoalMetricCardComponent.prototype.getGoaleCPM = function () {
        return Number(this.subject.impressions) ?
            ((this.subject.spend / this.subject.impressions) * 1000) : 0;
    };
    GoalMetricCardComponent.prototype.getGoaleCPC = function () {
        return Number(this.subject.clicks) ? ((this.subject.spend / this.subject.clicks) * 100) / 100 : 0;
    };
    GoalMetricCardComponent.prototype.getGoaleCPA = function () {
        return Number(this.subject.conversions) ?
            Math.round((this.subject.spend / this.subject.conversions) * 100) / 100 : 0;
    };
    return GoalMetricCardComponent;
}());
export { GoalMetricCardComponent };
