/**
 * Imports
 */
import {
    ViewChild, Component, EventEmitter, Input, Output, OnDestroy, OnInit
} from '@angular/core';

import { OverlayComponent } from 'app/shared/elements/overlay';
import { style, transition, animate, state, trigger } from '@angular/animations';
import { GAEventCategory, GAHelper } from 'app/shared/helpers/ga-helper';

/**
 * ModalState
 *
 * Use these enum values to control the state of the modal.
 */
export enum ModalState {
    Open,
    Closed
}

/**
 * ModalComponent
 */
@Component({
    selector: 'modal',
    templateUrl: './modal.html',
    styleUrls: ['./modal.styl'],

    /**
     * Animations
     *
     * The modal is moved above the viewport when void or closed. when
     * the modal opens it animates to it's fixed position as fullscreen.
     */
    animations: [
        trigger('modal', [
            state('Open', style({ transform: 'translateY(0)' })),
            transition(ModalState[ModalState.Open] + ' <=> ' + ModalState[ModalState.Closed],
            animate(ModalComponent.ModalAnimationTiming))
        ])
    ]
})
export class ModalComponent implements OnDestroy, OnInit {
    /**
     * Animation Control
     */
    private static ModalAnimationTiming: number = 300;
    private static ModalOpenClass = 'modal-open';

    @ViewChild(OverlayComponent, { static: true }) overlay: OverlayComponent;

    /**
     * The `state` property controls the modal animation.
     */
    @Input()
    state: string;

    /**
     * The `header` property is configurable as an Input and is
     * displayed in the template as the modal header.
     */
    @Input()
    header: string = null;

    /**
     * The `modalLabel` property is configurable as Input and being 
     * used in ga as `eventLabel` for `modal` interactions (open/close)
     */
    @Input()
    modalLabel: string = null;

    /**
     * The `opened` property is an EventEmitter that emit a value of
     * AnimationTransitionEvent when the modal is opened.
     */
    @Output() opened = new EventEmitter();

    /**
     * The `closed` property is an EventEmitter that emit a value of
     * AnimationTransitionEvent when the modal is closed.
     */
    @Output() closed = new EventEmitter();

    /**
     * The `signal` property is used to pass a value from the call of the modal
     * close function to the event emitted for when the modal completes its
     * closing animation.
     */
    private signal: any = null;

    /**
     * Checks to make sure state isn't unset or invalid.
     * If modal starts opened apply necessary class.
     */
    public ngOnInit() {
        this.state = ModalState[ModalState[this.state]] || ModalState[ModalState.Closed];
        if (this.state === ModalState[ModalState.Open]) {
            document.body.classList.add(ModalComponent.ModalOpenClass);
        }
    }

    /**
     * Ensure that the modal open class is removed from the body when the modal
     * closes.
     */
    public ngOnDestroy() {
        document.body.classList.remove(ModalComponent.ModalOpenClass);
    }

    /**
     * The `done` function is the callback for whenver the `modal`
     * animation trigger has completed a transition.
     *
     * The function emits either `opened` or `closed` depending on the
     * transition `toState` property.
     */
    done(event) {
        const emitter = ((): EventEmitter<any> => {
            if (event.fromState === 'void' && event.toState === ModalState[ModalState.Closed]) {
                return null;
            }
            switch (event.toState) {
                case ModalState[ModalState.Open]:
                    return this.opened;
                case ModalState[ModalState.Closed]:
                    return this.closed;
            }
            return null;
        })();
        if (emitter === null) {
            return;
        }
        emitter.emit(this.signal);
        // send analytics event
        GAHelper.trackEvent(GAEventCategory.MODAL_INTERACTION, event.toState, this.modalLabel)
        this.signal = null;
    }

    /**
     * The `open` function transitions the modal into the open state.
     */
    public open(doLoading: boolean = false) {
        this.state = ModalState[ModalState.Open];
        document.body.classList.add(ModalComponent.ModalOpenClass);

        if (doLoading) {
            this.showLoading();
        }
    }

    public showLoading() {
        this.overlay.show();
    }

    public hideLoading() {
        this.overlay.hide();
    }

    public isLoading(): boolean {
        return this.overlay.visible;
    }

    /**
     * The `close` function transitions the modal into the closed state.
     */
    public close(signal?: any) {
        // Disallow overwriting an already set signal. The signal will be
        // once it is emitted in an event, additionally prevent signaling
        // 'undefined'.
        if (this.signal === null && signal !== undefined) {
            this.signal = signal;
        }
        this.state = ModalState[ModalState.Closed];
        document.body.classList.remove(ModalComponent.ModalOpenClass);
    }
}
