import { Injectable } from '@angular/core';
import moment from 'moment';

import { Status, Campaign } from 'app/shared/models';

const byStartDate = (a, b) => { // most recent start date at the top
    if (moment(a.startDate).isAfter(b.startDate)) {
        return -1;
    }

    if (moment(a.startDate).isBefore(b.startDate)) {
        return 1;
    }

    return 0;
};

@Injectable()
export class CampaignsSortService {
    private _campaigns: Array<Campaign>;

    private set campaigns(val: Array<Campaign>) {
        this._campaigns = val;
    }

    private get campaigns(): Array<Campaign> {
        return this._campaigns || [];
    }

    public push(campaigns: Array<Campaign>): CampaignsSortService {
        this.campaigns = this.campaigns.concat(campaigns);
        return this;
    }

    public pull(): Array<Campaign> {
        const campaignsClone = this.campaigns.slice(0); // This creates clone of campaigns collection

        this.campaigns = null;
        return campaignsClone;
    }

    public sortByDeliveringLineItems(): CampaignsSortService {
        const today = moment().format('YYYY-MM-DD');
        let delivering;
        let notDelivering;
        let current;
        let past;

        notDelivering = this.campaigns // Doesn't contain delivering LineItems
            .filter(campaign => campaign['lineItems']
            .every(lItem => lItem.platformStatus !== Status.DELIVERING));

        delivering = this.campaigns // Contains 1+ delivering LineItem
            .filter(campaign => campaign['lineItems']
            .some(lItem => lItem.platformStatus === Status.DELIVERING))
            .sort(byStartDate);

        current = notDelivering
            .filter(campaign => moment(campaign.effectiveEndDate).isSameOrAfter(today))
            .sort(byStartDate);

        past = notDelivering
            .filter(campaign => moment(campaign.effectiveEndDate).isBefore(today))
            .sort(byStartDate);

        this.campaigns = delivering.concat(current).concat(past);

        return this;
    }
}
