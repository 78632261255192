import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';
import { ValidationService } from 'app/core/validation-service';

@Directive({
    selector: '[validate-macro][formControlName],[validate-macro][formControl],[validate-macro][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => MacroValidator), multi: true }
    ]
})
export class MacroValidator implements Validator {
    private validationService: ValidationService = new ValidationService();

    validate(c: AbstractControl): ValidationErrors {
        const url = c.value;

        if (!url || url === '') {
            return null;
        }

        return this.validationService.hasAcceptableMacros(url) ? null : { 'macro' : { valid: false }};
    }
}
