import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Advertiser } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { map } from 'rxjs/operators';
var AdvertiserRepository = /** @class */ (function (_super) {
    tslib_1.__extends(AdvertiserRepository, _super);
    function AdvertiserRepository(http) {
        return _super.call(this, http, '/advertiser', Advertiser) || this;
    }
    AdvertiserRepository.prototype.confirm = function (body) {
        var params = [this.path, 'confirm'];
        if (body.id) {
            params.push(body.id);
        }
        return this.http.post(this.url.apply(this, params), body.serialize());
    };
    AdvertiserRepository.prototype.exchangeAdvertiserSearch = function (query) {
        var _this = this;
        return this.http.post(this.url('/search/exchange-advertiser'), query)
            .pipe(map(function (response) { return response['output']
            .map(function (data) { return _this.build(data); }); }));
    };
    return AdvertiserRepository;
}(BackendRepository));
export { AdvertiserRepository };
