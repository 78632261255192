import { Component } from '@angular/core';

@Component({
    selector: 'dialog-confirmation',
    templateUrl: './dialog-confirmation.html',
})
export class DialogConfirmationComponent {
    valid: boolean = true;
    private _confirmed: boolean = false;

    public get confirmed(): boolean {
        return this._confirmed;
    }

    public set confirmed(value) {
        this.valid = this._confirmed = value;
    }

    public check(): boolean {
        return this.valid = this.confirmed;
    }

    public reset(): void {
        this.valid = true;
        this._confirmed = false;
    }
}
