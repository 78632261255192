import {
    Component,
    ViewChild,
    OnInit,
    OnDestroy
} from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { TableComponent } from 'app/shared/elements/table';
import { AuthorizationService, IdService } from 'app/core';
import { ConfirmDialogComponent } from 'app/shared/elements/confirm-dialog';
import { ContentEditableComponent } from 'app/shared/elements/content-editable';
import { DownloadHelper } from 'app/shared/helpers/download-helper';
import { DruidQueryHelper } from 'app/core/query-builder-helper';
import { InventoryTargetingComponent } from 'app/shared/components/inventory-targeting';
import { LightboxComponent } from 'app/shared/elements/lightbox';
import { NotificationsService } from 'app/core/notifications.service';
import { Observable, BehaviorSubject, ReplaySubject, combineLatest, of } from 'rxjs';
import { ThemeService, BackgroundStyle } from 'app/shared/helpers/theme.service';
import { InventoryTargetingOption } from 'app/shared/components/inventory-targeting/inventory-targeting-option.class';
import { ErrorHelper } from 'app/core/errors/error-helper';
import { Router } from '@angular/router';

import {
    AdSlot,
    Category,
    Deal,
    Newsletter,
    Package,
    Publisher
} from 'app/shared/models';

import {
    Newsletter as PackageNewsletter,
    AdSlot as PackageAdSlot
} from 'app/shared/models/package';

import {
    AdSlotRepository,
    CategoryRepository,
    NewsletterRepository,
    PackageRepository,
    PublisherRepository,
    ReportingQueryRepository,
    UserRepository
} from 'app/core/repositories';
import { HttpErrorResponse } from '@angular/common/http';
import { map, mergeMap, take } from 'rxjs/operators';

@Component({
    selector: 'package-details',
    templateUrl: './package-details.html',
    styleUrls: ['./package-details.styl']
})
export class PackageDetailsComponent implements OnInit, OnDestroy {
    @ViewChild(LightboxComponent, { static: true }) addInventoryDialog: LightboxComponent;
    @ViewChild(InventoryTargetingComponent, { static: true }) inventoryTargeting: InventoryTargetingComponent;
    @ViewChild(TableComponent, { static: true }) table: TableComponent;
    @ViewChild(ConfirmDialogComponent, { static: true }) deleteDialog: ConfirmDialogComponent;
    @ViewChild('editName', { static: false }) editName: ContentEditableComponent;

    pkg = new BehaviorSubject<Package>(null);
    deals = new BehaviorSubject<Deal[]>([]);
    createdBy = new BehaviorSubject<any>(null);
    report = new BehaviorSubject<any>({ impressions: 0, adjustedImpressions: 0 });
    inventory = new ReplaySubject<any>(1);
    tableParams = new BehaviorSubject<any>(null);

    private errorId: number;

    private _inventory = null;
    inventoryToAdd: InventoryTargetingOption[] = [];

    // Observable for the table.
    tableData = combineLatest(this.inventory, this.tableParams)
        .pipe(map(([inventory, params]) => this.inventoryAsPage(inventory, params)));

    constructor(
        public authorization: AuthorizationService,
        private route: ActivatedRoute,
        private notifications: NotificationsService,
        private theme: ThemeService,
        private queryBuilder: DruidQueryHelper,
        private reportingQueryRepository: ReportingQueryRepository,
        private adSlotRepository: AdSlotRepository,
        private categoryRepository: CategoryRepository,
        private publisherRepository: PublisherRepository,
        private newsletterRepository: NewsletterRepository,
        private packageRepository: PackageRepository,
        private userRepository: UserRepository,
        public id: IdService,
        private router: Router
    ) { }

    ngOnInit() {
        this.theme.setBackgroundStyle(BackgroundStyle.GreyStyle);
        this.route.data.subscribe((data: { package: Package, deals: Deal[] }) => {
            if(!data.package['status'] || data.package['status'] === 'deleted'){
                this.router.navigate(['/inventory-manager/404']);
            }
            data.package['deals'] = data.deals;
            this.pkg.next(data.package);
            this.deals.next(data.deals);
            this.loadCreatedBy(data.package.createdBy);
            this.loadInventory(data.package);
        });
    }

    ngOnDestroy() {
        this.theme.resetBackgroundStyle();
    }

    private loadCreatedBy(userId) {
        this.userRepository.search({ conditions: [{ field: 'hash_id', value: userId }] }).subscribe(users => {
            if (users.length > 0) {
                this.createdBy.next(users[0]);
            }
        });
    }

    private loadInventory(pkg: Package) {

        this.getInventory(pkg).subscribe(({ publishers, newsletters, adSlots, categories }) => {
            this.loadReport(publishers, newsletters, adSlots, pkg);
            this.inventory.next([].concat(
                this.mapPublishers(publishers, categories),
                this.mapNewsletters(newsletters, publishers, categories),
                this.mapAdSlots(adSlots, publishers, categories)
            ));
        });
    }

    private getInventory(pkg: Package) {
        return combineLatest(
            this.getNewsletters(pkg.newsletters),
            this.getAdSlots(pkg.adSlots),
            this.getCategories()
        ).pipe(mergeMap(([newsletters, adSlots, categories]) => {
            return this.getPublishers(pkg, newsletters, adSlots)
                .pipe(map(publishers => ({ publishers, newsletters, adSlots, categories })));
        }));
    }

    private loadReport(publishers: Publisher[], newsletters: Newsletter[], adSlots: AdSlot[], pkg: Package) {
        const query = {
            type: 'groupBy',
            dataSource: 'custom_aggregates_exact',
            granularity: 'all',
            dimensions: [],
            aggs: ['Impressions', 'Adjusted Impressions'],
            postAggs: [],
            interval: { type: 'dynamic', value: '30' },
            filters: []
        };

        if (pkg.publishers) {
            query.filters.push(
                { dimension: 'Publisher', values: publishers.map(publisher => publisher.refId.toString()) });
        }
        if (pkg.adSlots) {
            query.filters.push(
                { dimension: 'Section', values: adSlots.map(adSlot => adSlot.refId.toString()) }
            );
        }

        if (pkg.newsletters) {
            query.filters.push(
                { dimension: 'Template', values: newsletters.map(newsletter => newsletter.refId.toString()) }
            );
        }

        this.reportingQueryRepository
            .executeAdHocJSON(this.queryBuilder.buildQuery(query), 'publisher').subscribe(rows => {
                if (rows.length > 0) {
                    const event = rows[0].event;
                    this.report.next({
                        impressions: event['Impressions'],
                        adjustedImpressions: event['Adjusted Impressions'],
                    });
                }
            });
    }

    private getNewsletters(newsletters: PackageNewsletter[]): Observable<Newsletter[]> {
        if (newsletters === null) {
            return of([]);
        }
        return this.newsletterRepository.all({
            conditions: [
                {
                    field: 'id',
                    value: newsletters
                }
            ]
        });
    }

    private getAdSlots(adSlots: PackageAdSlot[]): Observable<AdSlot[]>  {
        if (adSlots === null) {
            return of([]);
        }
        return this.adSlotRepository.all({
            conditions: [
                {
                    field: 'id',
                    value: adSlots
                }
            ]
        });
    }

    private getCategories() {
        return this.categoryRepository.all();
    }

    private getPublishers(pkg: Package, newsletters: Newsletter[], adSlots: AdSlot[]) {
        let publishers = pkg.publishers;
        if (!publishers) {
            publishers = [];
        }

        return this.publisherRepository.all({
            conditions: [{
                field: 'id',
                value: [].concat(
                    publishers,
                    newsletters.map(newsletter => newsletter.publisher),
                    adSlots.map(adSlot => adSlot.publisher))
            }]
        });
    }

    private mapPublishers(publishers: Publisher[], categories: Category[]) {
        return this.filterPublishers(publishers).map(publisher => {
            const category = categories.find(category => category.id === publisher.category);
            return {
                id: publisher.id,
                refId: publisher.refId,
                name: publisher.name,
                domain: publisher.domain,
                category: category.name,
                type: 'Publisher'
            };
        });
    }

    private mapNewsletters(newsletters: Newsletter[], publishers: Publisher[], categories: Category[]) {
        return this.filterNewsletters(newsletters, publishers).map(newsletter => {
            const publisher = publishers.find(publisher => publisher.id === newsletter.publisher);
            const category = categories.find(category => category.id === publisher.category);
            return {
                id: newsletter.id,
                refId: newsletter.refId,
                name: newsletter.name,
                domain: publisher.domain,
                category: category['name'],
                type: 'Newsletter'
            };
        });
    }

    private mapAdSlots(adSlots: AdSlot[], publishers: Publisher[], categories: Category[]) {
        return this.filterAdSlots(adSlots, publishers).map(adSlot => {
            const publisher = publishers.find(publisher => publisher.id === adSlot.publisher);
            const category = categories.find(category => category.id === publisher.category);
            return {
                id: adSlot.id,
                refId: adSlot.refId,
                name: adSlot.name,
                domain: publisher.domain,
                category: category.name,
                type: 'Ad Slot'
            };
        });
    }

    private filterPublishers(publishers: Publisher[]) {
        let ids = [];
        const pkg = this.pkg.getValue();
        if (pkg !== null) {
            ids = pkg.publishers;
        }

        if (ids === null) {
            return [];
        } else {
            return publishers.filter(publisher => ids.indexOf(publisher.id) > -1);
        }
    }

    private filterNewsletters(newsletters: Newsletter[], publishers: Publisher[]) {
        let ids = [];
        const pkg = this.pkg.getValue();
        if (pkg !== null) {
            ids = pkg.newsletters;
        }
        return newsletters
            .filter(newsletter => ids.indexOf(newsletter.id) > -1)
            .filter(newsletter => publishers.some(publisher => publisher.id === newsletter.publisher));
    }

    private filterAdSlots(adSlots: AdSlot[], publishers: Publisher[]) {
        let ids = [];
        const pkg = this.pkg.getValue();
        if (pkg !== null) {
            ids = pkg.adSlots;
        }
        return adSlots
            .filter(adSlot => ids.indexOf(adSlot.id) > -1)
            .filter(adSlot => publishers.some(publisher => publisher.id === adSlot.publisher));
    }

    private inventoryAsPage(inventory, params) {
        let items = inventory;

        if (params !== null) {
            if (params.filters.length > 0) {
                const filters = params.filters.map(filter => new RegExp(filter.query, 'i'));
                const filterBy = params.filters.map(filter => filter.field);
                items = items.filter(item => {
                    return filters
                        .every(filter =>
                               filterBy.some(field => filter.test(item[field])));
                });
            }

            items = this.sort(items, params.orderBy, params.direction);
        }

        return {
            items: items,
            page: 1,
            pages: 1,
            total: inventory.length
        };
    }

    query(params) {
        this.tableParams.next(params);
    }

    // This logic could be refactored into a helper.
    private sort(inventory, orderBy, direction) {
        const compare = (a, b) => {
            if (a === undefined) {
                return 1;
            }

            if (b === undefined) {
                return -1;
            }

            if (a > b) {
                return -1;
            }

            if (b > a) {
                return 1;
            }

            if (a === b) {
                return 0;
            }
        };

        inventory.sort((a, b) => compare(a[orderBy], b[orderBy]));

        if (direction === 'DESC') {
            inventory.reverse();
        }

        return inventory;
    }

    inlineUpdate(value: string, key: string) {
        const pkg = this.pkg.getValue();
        if (pkg !== null) {
            if (key === 'name' && value === ''){
                this.editName.reset(pkg.name);
                return;
            }
            if (pkg[key] !== value) {
                pkg[key] = value;
                this.packageRepository.save(pkg).subscribe(pkg => {
                    pkg['deals'] = this.deals.getValue();
                    this.pkg.next(pkg);
                });
            }
        }
    }

    downloadInventoryAsCSV(): void {
        const pkg = this.pkg.getValue();
        if (pkg === null) {
            return;
        }

        this.inventory.pipe(
            map(inventory => {
                return inventory.map(item => ({
                    inventory: item.name.replace(/^[=+\-@]+/, ''),
                    id: item.refId,
                    type: item.type,
                    domain: item.domain,
                    'iab category': item.category
                }));
            }),
            take(1)
        ).subscribe(inventory => {
            let blob = DownloadHelper.convertToCSV(inventory);
            DownloadHelper.downloadFile(blob, pkg.name + `.csv`);
        });
    }

    openAddInventory() {
        this.inventoryTargeting.removeAll();
        this.addInventoryDialog.open();
    }

    removeSingleInventory(inventory) {
        this.removeMultipleInventory([inventory]);
    }

    removeMultipleInventory(inventory) {
        if (!Array.isArray(inventory)) {
            return;
        }

        if (inventory.length < 1) {
            return;
        }
        const pkg = this.pkg.getValue();
        this._inventory = inventory;
        if (pkg['deals'].length > 0 || inventory.length > 1) {
            this.deleteDialog.open();
        } else {
            this.removeInventory();
        }
    }

    removeInventory() {
        if (this._inventory === null) {
            return;
        }
        const inventory = this._inventory;
        this._inventory = null;

        const publishers = inventory.filter(item => item.type === 'Publisher').map(item => item.id);
        const newsletters = inventory.filter(item => item.type === 'Newsletter').map(item => item.id);
        const adSlots = inventory.filter(item => item.type === 'Ad Slot').map(item => item.id);

        const pkg = this.pkg.getValue();

        if (publishers.length > 0) {
            pkg.publishers = pkg.publishers.filter(id => publishers.indexOf(id) < 0);
        }

        if (newsletters.length > 0) {
            pkg.newsletters = pkg.newsletters.filter(id => newsletters.indexOf(id) < 0);
        }

        if (adSlots.length > 0) {
            pkg.adSlots = pkg.adSlots.filter(id => adSlots.indexOf(id) < 0);
        }

        this.packageRepository.save(pkg).subscribe(pkg => {
            this.removeError();
            this.notifications
                .success(`You succesfully removed ${inventory.length} item${inventory.length > 1 ? 's' : ''}.`);
            pkg['deals'] = this.deals.getValue();
            this.pkg.next(pkg);
            this.table.reset();
            this.loadInventory(pkg);
        }, (err: HttpErrorResponse) => {

            this.removeError();

            // @Todo modify notifications service to handle multiple errors
            this.errorId = this.notifications.error(
                ErrorHelper.getMessage(err.error.errors[0].id)
            );
        });
    }

    public addInventory() {

        const inventory = this.inventoryToAdd;
        const pkg = this.pkg.getValue().clone(Package);

        let publishers = inventory
            .filter(item => item.type === 'Publisher')
            .map(item => item.key);

        let newsletters = inventory
            .filter(item => item.type === 'Newsletter')
            .map(item => item.key);

        let adSlots = inventory
            .filter(item => item.type === 'Ad Slot')
            .map(item => item.key);

        if (pkg.publishers) {
            publishers = publishers.filter(id => pkg.publishers.indexOf(id) < 0);
            pkg.publishers.push(...publishers);
        } else if (publishers.length > 0) {
            pkg.publishers = publishers;
        }

        if (pkg.newsletters) {
            newsletters = newsletters.filter(id => pkg.newsletters.indexOf(id) < 0);
            pkg.newsletters.push(...newsletters);
        } else if (newsletters.length > 0) {
            pkg.newsletters = newsletters;
        }

        if (pkg.adSlots) {
            adSlots = adSlots.filter(id => pkg.adSlots.indexOf(id) < 0);
            pkg.adSlots.push(...adSlots);
        } else if (adSlots.length > 0) {
            pkg.adSlots = adSlots;
        }

        const total = this.inventoryToAdd.length;

        this.packageRepository.save(pkg).subscribe(pkg => {

            this.removeError();
            pkg['deals'] = this.deals.getValue();
            this.pkg.next(pkg);
            this.loadInventory(pkg);
            this.addInventoryDialog.close();
            this.notifications.success(`You succesfully added ${total} item${total === 1 ? '' : 's'}.`);

        }, (err: HttpErrorResponse) => {

            this.removeError();

            // @Todo modify notifications service to handle multiple errors
            this.errorId = this.notifications.error(
                ErrorHelper.getMessage(err.error.errors[0].id)
            );
        });
    }

    private removeError() {
        if (typeof this.errorId === 'number') {
            this.notifications.remove(this.errorId);
        }
    }

    public inventoryChange(inventory: any[]) {

        let updatedInventory = [];

        inventory.forEach(data => {
            // If an item was removed from the inventory component remove from this.inventoryToAdd
            if (typeof(data.refId) === 'undefined') {
                updatedInventory.push(this.inventoryToAdd.find(inv => this.inInventory(inv, data)));
            }
        });

        if (updatedInventory.length > 0) {
            this.inventoryToAdd = updatedInventory;
        } else if (inventory.length === 0) {
            this.inventoryToAdd = [];
        }
    }

    private inInventory(inventory, data) {
        return inventory.type === data.type && inventory.id === data.id;
    }
}
