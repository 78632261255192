import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Region } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
var RegionRepository = /** @class */ (function (_super) {
    tslib_1.__extends(RegionRepository, _super);
    function RegionRepository(http) {
        return _super.call(this, http, '/region', Region) || this;
    }
    return RegionRepository;
}(BackendRepository));
export { RegionRepository };
