import { Injectable } from '@angular/core';
import moment from 'moment';
import { of } from 'rxjs';

import { LineItem } from 'app/shared/models';
import { ReportingQueryRepository } from 'app/core/repositories';
import { catchError, shareReplay, map } from 'rxjs/operators';

interface ReportingData {
    event: {
        impressions: number,
        spend: number
    }
}

type System = 'dsp' | 'ssp';

@Injectable()
export class MetricsDataService {
    private lineItemDataCache = new Map<string, any>();

    constructor(private reportingQueryRepository: ReportingQueryRepository) {}

    /**
     * Get the total of all spend for a `lineItem` up until the date `since`.
     */
    getLineItemSpendToDate(lineItem: LineItem, system: System, since: string) {
        return this.fetchLineItemMetrics(lineItem, system, since).pipe(
            map((dataPoints: ReportingData[]) => {
                if (!Array.isArray(dataPoints) || dataPoints.length === 0) {
                    return 0;
                }

                // this query is granularity 'all', so it will only return one data point
                return dataPoints[0].event.spend;
            })
        );
    }

    /**
     * Get the total of all impressions for a `lineItem` up until the date `since`.
     */
    getLineItemImpressionsToDate(lineItem: LineItem, system: System, since: string) {
        return this.fetchLineItemMetrics(lineItem, system, since).pipe(
            map((dataPoints: ReportingData[]) => {
                if (!Array.isArray(dataPoints) || dataPoints.length === 0) {
                    return 0;
                }

                // this query is granularity 'all', so it will only return one data point
                return dataPoints[0].event.impressions;
            })
        );
    }

    /**
     * Fetch metrics data for `lineItem` up until the date `since`.
     */
    private fetchLineItemMetrics(lineItem: LineItem, system: System, since: string) {
        const from = moment(lineItem.effectiveStartDate).toDate();
        const until = moment(since).toDate();
        const cacheKey = `${lineItem.refId}`;

        if (!this.lineItemDataCache.has(cacheKey)) {
            this.lineItemDataCache.set(
                cacheKey,
                this.reportingQueryRepository.getDemandMetrics(lineItem.refId, 'LineItem', system, from, until).pipe(
                    catchError(_ => of(0)),
                    shareReplay(1)
                )
            );
        }

        return this.lineItemDataCache.get(cacheKey);
    }
}
