import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { BackendRepository } from './backend-repository';
import { catchError } from 'rxjs/operators';
import { Audience } from 'app/shared/models';

@Injectable()
export class SamovarAudienceRepository extends BackendRepository<Audience> {

    constructor(http: HttpClient) {
        super(http, "/search/audience/samovar", Audience);
    }

    build(from?: any): Audience {
        from.refId = from.id;
        from.uniqueSegmentId = from.id;
        from.dataProviderId = from.dataProvider.id;
        from.matchCountTotal = from.counts.matchCountTotal.value;

        return new this.model(from);
    }

    protected buildItems(responses): Audience[] {
        return responses.reduce((all, response) => {
            const data = response['results'];
            
            if (!data || !data.length) return all;
            
            const items = data.map(item => this.build(item));
            all.push(...items);

            return all;
        }, [] as Audience[]);
    }

    searchByUniqueSegmentId(params: any): Observable<Audience[]> {
        if (!Array.isArray(params.conditions)) {
            params.conditions = [];
        }

        const refIdCondition = params.conditions.find(condition => condition.field === 'refId')

        if (!refIdCondition) {
            return of([])
        }

        const searchParams = {
            "filter": {
                "id": {
                    "in": refIdCondition.value.map(value => parseInt(value)),
                },
            }
        };
        return this.consume(_ => {
            return this.http.post(this.url(this.path), searchParams).pipe(
                catchError((err) => throwError(err))
            );;
        })
    }

    searchByAccountId(accountId: number): Observable<Audience[]> {
        if(!accountId){
            return of([]);
        }
        return this.consume((page) => {
            var params = {
                "filter": {
                    "account": {
                        "id": {
                            "eq": accountId,
                        },
                    },
                },
                "pagination": {
                    "pageSize": 20000,
                    "page": page,
                },
            };
            return this.http.post(this.url(this.path), params).pipe(
                catchError((err) => throwError(err))
            );
        });
    }
}
