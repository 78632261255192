import { Component, OnInit, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { DatePickerComponent } from 'app/shared/elements/date-picker';
import { DruidQueryHelper } from 'app/core/query-builder-helper';
import { ErrorHelper } from 'app/core/errors/error-helper';
import { ReportingQueryRepository } from 'app/core/repositories';
import moment from 'moment';
import { clone } from 'app/core/utils';

export enum IntervalRange {
    last7Days = 1,
    last14Days = 2,
    last30Days = 3,
    flightDates = 4
}

const INITIAL_DATA = [
    ['Impressions', 0],
    ['Clicks', 0],
    ['PCC', 0],
    ['Advertiser Spend', 0.0],
    ['CTR', 0.0],
    ['eCPA', 0.0],
    ['eCPC', 0.0],
    ['Impressions (a)', 0],
    ['CTR (a)', 0.0]
];

@Component({
    selector: 'metrics-comparison-card',
    templateUrl: './metrics-comparison-card.html',
    styleUrls: ['./metrics-comparison-card.styl']
})
export class MetricsComparisonCardComponent implements OnInit {
    @ViewChild('endDatePicker', { static: true }) endDatePicker: DatePickerComponent;

    /**
     * There will be three states for metric cards:
     * 1. campaign/line item has not yet started
     * 2. campaign/line item is in progress but no data
     * 3. campaign/line item is in progress and has data
     */

    // Acceptable values = { Campaign, Line Item }
    @Input() dimension: String;
    // RefId
    @Input() dimensionId: any;
    @Input() isDirectSold: boolean = false;
    @Input() flightStartDate: any;
    @Input() flightEndDate: any;

    noData: boolean = false;
    errorHelper: ErrorHelper;
    IntervalRange: typeof IntervalRange = IntervalRange;
    currentInterval: IntervalRange;
    private _intervalStart: Date;
    private _intervalEnd: Date;
    // Most recent date that a user can select from the picker
    yesterday = moment().subtract(1, 'days').startOf('day').toDate();
    firstDate = new Date(2010, 0, 1);
    showFilters: boolean = false;
    leftIndex: number = 7;
    rightIndex: number = 8;

    leftColorPattern = ['#14abf9'];
    rightColorPattern = ['#9013fe'];

    private dates: string[] = ['dates'];
    filteredDates: string[] = ['dates'];
    formats = ['Number', 'Number', 'Number', 'Currency', 'Percent', 'Currency', 'Currency', 'Number', 'Percent'];
    private operations = ['sum', 'sum', 'sum', 'sum', 'mean', 'mean',  'mean', 'sum', 'mean'];
    private data: any[] = clone(INITIAL_DATA);
    filteredData: any[] = clone(INITIAL_DATA);

    constructor(
        private reportingQueryRepository: ReportingQueryRepository,
        private druidQueryHelper: DruidQueryHelper,
        private cdr: ChangeDetectorRef) {
        this.errorHelper = new ErrorHelper();
    }

    ngOnInit(): void {
        // 1. Init Intervals
        this.initInterval();

        // 2. Initialize Data
        this.initData(
            this.dates,
            this.data,
            moment(this._intervalStart).format('YYYY-MM-DD'),
            moment(this._intervalEnd).format('YYYY-MM-DD')
        );
        this.initData(
            this.filteredDates,
            this.filteredData,
            moment(this._intervalStart).format('YYYY-MM-DD'),
            moment(this._intervalEnd).format('YYYY-MM-DD')
        );

        // 3. Download aggregates
        this.downloadData();
    }

    set intervalStart(value: Date) {
        this._intervalStart = value;
        this.filterData();
    }

    get intervalStart(): Date {
        return this._intervalStart;
    }

    set intervalEnd(value: Date) {
        this._intervalEnd = value;
        this.filterData();
    }

    get intervalEnd(): Date {
        return this._intervalEnd;
    }

    initInterval() {
        // local cloned copies.
        let flightStartDate = moment(this.flightStartDate).toDate();
        let flightEndDate = moment(this.flightEndDate).toDate();
        const yesterday = moment(this.yesterday).toDate();

        const minDate = this.firstDate;

        if (this.isDateBefore(flightStartDate, minDate)) {
            flightStartDate = minDate;
        }
        if (this.isDateBefore(flightEndDate, minDate)) {
            flightEndDate = minDate;
        }

        /**
         * If campaign/line-item has not yet begun, then display flight dates
         * If campaign/line-item is in progress but not yet completed then show flight start date, last date
         * If campaign/line-item is completed then display flight dates
         */
        this._intervalStart = flightStartDate;
        if (this.isDateBefore(yesterday, flightStartDate)) {
            this._intervalEnd = flightEndDate;
        }
        else if (this.isDateBefore(flightEndDate, yesterday)) {
            this._intervalEnd = flightEndDate;
        } else {
            this._intervalEnd = yesterday;
        }
    }

    initData(dates: string[], data: any[], startDateString: string, endDateString: string) {
        const startDate = moment(startDateString, 'YYYY-MM-DD');
        const endDate = moment(endDateString, 'YYYY-MM-DD');
        const diff = endDate.diff(startDate, 'days');

        // Local cloned copy
        let counter = moment(startDate);
        dates[1] = counter.format('YYYY-MM-DD');
        for (let i = 1; i <= diff; i += 1) {
            counter.add(1, 'days');
            dates.push(counter.format('YYYY-MM-DD'));
            // Impressions
            data[0].push(0);
            // Clicks
            data[1].push(0);
            // Conversions
            data[2].push(0);
            // Advertiser Spend
            data[3].push(0.0);
            // CTR
            data[4].push(0.0);
            // eCPA
            data[5].push(0.0);
            // eCPC
            data[6].push(0.0);
            // Impressions (a)
            data[7].push(0);
            // CTR (a)
            data[8].push(0.0);
        }
    }

    filterData() {
        const filteredDates = ['dates', '2017-01-01'];
        const filteredData = clone(INITIAL_DATA);

        let startDate = moment(this._intervalStart);
        if (startDate.isBefore(this.flightStartDate, 'day')) {
            startDate = moment(this.flightStartDate);
        }

        let endDate = moment(this._intervalEnd);
        if (endDate.isAfter(this.flightEndDate, 'day')) {
            endDate = moment(this.flightEndDate);
        }

        this.initData(
            filteredDates,
            filteredData,
            startDate.format('YYYY-MM-DD'),
            endDate.format('YYYY-MM-DD')
        );

        filteredDates.map((filteredDate, index) => {
            const dataIndex = this.dates.indexOf(filteredDate);

            if (dataIndex > -1) {
                for (let i = 0; i < filteredData.length; i += 1) {
                    filteredData[i][index] = this.data[i][dataIndex];
                }
            }
        });
        this.filteredData = filteredData;
        this.filteredDates = filteredDates;
        this.cdr.markForCheck();
    }

    buildDSPAggregateQueryPayload() {
        let queryBuilder: any = {};
        queryBuilder.dataSource = DruidQueryHelper.getDSPExactDataSource();
        queryBuilder.type = 'timeseries';
        queryBuilder.granularity = 'day';
        queryBuilder.interval = {
            type: 'absolute',
            start: moment(this._intervalStart).format('YYYY-MM-DD'),
            end: moment(this._intervalEnd).format('YYYY-MM-DD'),
        };
        queryBuilder.aggs = ['Impressions', 'Clicks', 'PCC', 'Advertiser Spent', 'Impressions (a)'];
        queryBuilder.postAggs = ['CTR', 'GrossECPC', 'eCPA', 'CTR (a)'];
        if (this.dimension === 'Campaign') {
            queryBuilder.filters = [
                {
                    dimension: 'Campaign ID',
                    values: [JSON.stringify(this.dimensionId)]
                }
            ];
        } else {
            queryBuilder.filters = [
                {
                    dimension: 'Line Item ID',
                    values: [JSON.stringify(this.dimensionId)]
                }
            ];
        }

        // build Druid Query
        let druidQuery = this.druidQueryHelper.buildQuery(queryBuilder);

        this.updateIntervalDateRange(druidQuery);
        this.addFilterForDSPDuplicates(druidQuery);

        return {
            query: druidQuery,
            type: 'advertiser'
        };
    }

    updateIntervalDateRange(druidQuery) {
        const interval = JSON.parse(JSON.stringify(druidQuery.intervals[0]));

        // Druid appends UTC offset 0 timestamp to the event dates
        druidQuery.intervals[0].start = moment.utc(interval.start).startOf('day').toISOString();
        // Adding one day to end to make the user entered end date inclusive in the report.
        druidQuery.intervals[0].end = moment.utc(interval.end).add(1, 'days').endOf('day').toISOString();
    }

    addFilterForDSPDuplicates(druidQuery) {
        /**
         * For DSP reporting, datasource is union of DSP & SSP aggregates.
         * To prevent duplication of DSP data, we need a filter campaign_id != 1118
         * for data in SSP aggregates. Because post bv4 migration the campaign_id for
         * all DSP impressions data in SSP will be marked as 1118 representing LiveIntent DSP.
         */
        const filters = [];
        const duplicateDSPFilter = DruidQueryHelper.getDSPFilterForExchangeDuplicates();
        // Deep cloning druidQuery.filter object using JSON parse & JSON stringify.
        const druidQueryFilter = JSON.parse(JSON.stringify(druidQuery.filter));

        filters.push(duplicateDSPFilter);
        filters.push(druidQueryFilter);
        druidQuery.filter = DruidQueryHelper.createANDFilter(filters);
    }

    downloadData() {
        const { query, type } = this.buildDSPAggregateQueryPayload();

        this.reportingQueryRepository.executeAdHocJSON(query, type).toPromise()
            .then(aggregates => {
                // Load Aggregates Data in data[]
                if (Array.isArray(aggregates)) {
                    this.noData = aggregates.every(aggr =>
                        aggr.result['Advertiser Spend'] === 0 &&
                        aggr.result['CTR'] === 0 &&
                        aggr.result['Clicks'] === 0 &&
                        aggr.result['PCC'] === 0 &&
                        aggr.result['GrossECPA'] === 0 &&
                        aggr.result['eCPA'] === 0 &&
                        aggr.result['Impressions'] === 0 &&
                        aggr.result['Impressions (a)'] === 0 &&
                        aggr.result['CTR (a)'] === 0);

                    aggregates.forEach(druidData => {
                        const date = moment.utc(druidData.timestamp).format('YYYY-MM-DD');
                        const dataIndex = this.dates.indexOf(date);

                        if (dataIndex > -1) {
                            this.data[0][dataIndex] = druidData.result.Impressions;
                            this.data[1][dataIndex] = druidData.result.Clicks;
                            this.data[2][dataIndex] = druidData.result.PCC;
                            this.data[3][dataIndex] = druidData.result['Advertiser Spend'];
                            this.data[4][dataIndex] = druidData.result.CTR;
                            this.data[5][dataIndex] = druidData.result.eCPA;
                            this.data[6][dataIndex] = druidData.result.GrossECPC;
                            this.data[7][dataIndex] = druidData.result['Impressions (a)'];
                            this.data[8][dataIndex] = druidData.result['CTR (a)'];
                        }
                    });
                }

                this.filterData();
                this.cdr.markForCheck();
            }, (aggregatesError) => {
                this.errorHelper.resetAllErrors();
                this.errorHelper.loadReportingError(aggregatesError.data, aggregatesError.status);
            });
    }

    updateInterval(range: IntervalRange) {
        // local cloned copies.
        const flightStartDate = moment(this.flightStartDate).toDate();
        const flightEndDate = moment(this.flightEndDate).toDate();
        const yesterday = moment(this.yesterday).toDate();
        this.currentInterval = range;

        this._intervalEnd = yesterday;

        switch (range) {
            case IntervalRange.last7Days: {
                this._intervalStart = moment(yesterday).subtract(7, 'days').startOf('day').toDate();
                this.filterData();
                break;
            }
            case IntervalRange.last14Days: {
                this._intervalStart = moment(yesterday).subtract(14, 'days').startOf('day').toDate();
                this.filterData();
                break;
            }
            case IntervalRange.last30Days: {
                this._intervalStart = moment(yesterday).subtract(30, 'days').startOf('day').toDate();
                this.filterData();
                break;
            }
            case IntervalRange.flightDates: {
                /**
                 * If campaign/line-item has not yet begun, then display flight dates
                 * If campaign/line-item is in progress but not yet completed then show flight start date, last date
                 * If campaign/line-item is completed then display flight dates
                 */
                this._intervalStart = flightStartDate;
                if (this.isDateBefore(yesterday, flightStartDate)) {
                    this._intervalEnd = flightEndDate;
                }
                else if (this.isDateBefore(flightEndDate, yesterday)) {
                    this._intervalEnd = flightEndDate;
                }
                this.filterData();
                break;
            }
        }
    }

    isDateBefore(firstDate: any, secondDate: any) {
        return moment(firstDate).isBefore(secondDate);
    }

    get leftSelectedData() {
        return this.filteredData[this.leftIndex];
    }

    get rightSelectedData() {
        return this.filteredData[this.rightIndex];
    }

    get leftSelectedName() {
        return this.filteredData[this.leftIndex][0];
    }

    get rightSelectedName() {
        return this.filteredData[this.rightIndex][0];
    }

    get leftSelectedFormat() {
        return this.formats[this.leftIndex];
    }

    get rightSelectedFormat() {
        return this.formats[this.rightIndex];
    }

    get leftSelectedOperation() {
        return this.operations[this.leftIndex];
    }

    get rightSelectedOperation() {
        return this.operations[this.rightIndex];
    }

    leftDropDownIndex(value: number) {
        this.leftIndex = value;
    }
    rightDropDownIndex(value: number) {
        this.rightIndex = value;
    }

    setStartDate(date: Date) {
        this.intervalStart = date;
        this.endDatePicker.calendar.setMonth(moment(date));
        this.endDatePicker.inputElement.nativeElement.focus();
        this.currentInterval = null;
    }

    public get ios15KnowledgeBaseLink() {
        return this.isDirectSold
            ? 'https://support.liveintent.com/hc/en-us/articles/4407063919252'
            : 'https://support.liveintent.com/hc/en-us/articles/4406913619476';
    }
}
