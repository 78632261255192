import { Model } from './model';

export class ReportingSchedule extends Model {
    public serialize(): string {
        const obj = Object.assign({}, this);
        delete obj._isClone;

        return JSON.stringify(obj);
    }

    get entity() {
        return 'Reporting Schedule';
    }
}
