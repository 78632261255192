import { Filter } from 'app/shared/elements/filters';
import { Operation } from 'app/shared/elements/filters';
import { SearchParams, QueryBuilder } from './query-builder';
import { PaginatorQuery } from 'app/shared/elements/paginator';
import { TableQuery } from 'app/shared/elements/async-table';

export class ESQueryBuilder implements QueryBuilder {
    /**
     * Convert a `TableQuery` into `SearchParams`, depending on
     * backend of the search.
     */
    build<T extends TableQuery & PaginatorQuery>(tableQuery: T): ESParams {
        return {
            page: tableQuery.page,
            n: tableQuery.pageSize,
            orderBy: tableQuery.orderBy,
            sort: tableQuery.direction,
            conditions: [],
            query: this.buildQueryString(tableQuery.filters || [])
        };
    }

    /**
     * Convert `filters` into an elasticsearch `query_string`.
     */
    private buildQueryString(filters: Filter[], outerOperation = Operation.And) {
        // strip any filters that are invalid
        const validFilters = filters.filter(filter => filter.isValid());

        // recursively build any nested filters
        const expressionsByGroup = validFilters.map(filter => {
            if (Array.isArray(filter.filters)) {
                return this.buildQueryString(filter.filters, filter.operation);
            }

            return this.buildExpression(filter);
        });

        // apply outer operation between each top level group
        return this[outerOperation](expressionsByGroup);
    }

    /**
     * Flatten a list of expressions into a single expression with `OR` operators
     * between each of the original expressions.
     */
    or(expressions: string[]) {
        return expressions
            .filter(expression => expression && expression !== '')
            .map(expression => this.parenthesize(expression)).join(' OR ');
    }

    /**
     * Flatten a list of expressions into a single expression with `AND` operators
     * between each of the original expressions.
     */
    and(expressions: string[]) {
        return expressions
            .filter(expression => expression && expression !== '')
            .map(expression => this.parenthesize(expression)).join(' AND ');
    }

    /**
     * Negate an expression.
     */
    not(expression: string) {
        return 'NOT ' + expression;
    }

    /**
     * Build an elasticsearch expression based on a `field`, an `input` term, and an `operation`.
     */
    protected buildExpression(filter: Filter) {
        const field = filter.field;
        let query = filter.query;

        query = this.sanitize(query);

        if (Array.isArray(query)) {
            query = query.length > 1 ? this.or(query) : query[0];
        }

        switch (filter.operation) {
            case Operation.Contains:
                return field + ':*' + query + '*';
            case Operation.DoesNotContain:
                return this.not(field + ':*' + query + '*');
            case Operation.Equals:
                return field + ':' + this.quote(query);
            case Operation.DoesNotEqual:
                return this.not(field + ':' + this.quote(query));
            case Operation.GreaterThan:
                return field + ':>' + query;
            case Operation.LessThan:
                return field + ':<' + query;
            default:
                return '';
        }
    }

    /**
     * Add parentheses around an expression.
     */
    protected parenthesize(expression: string) {
        if (!expression || expression === '') {
            return '';
        }

        return '(' + expression + ')';
    }

    /**
     * Add quotes around an expression.
     */
    protected quote(expression: string | number) {
        // an empty expression should not be quoted
        if (!expression || expression === '') {
            return '';
        }

        // don't quote expressions that contain logical operators
        if ([' OR ', ' AND '].some(o => expression.toString().includes(o))) {
            return expression;
        }

        // surround the expression in quotes
        return '"' + expression + '"';
    }

    /**
     * Sanitize the `input` to an acceptable query for elasticsearch.
     */
    protected sanitize(input: any) {
        if (Array.isArray(input)) {
            return input.map(i => this.sanitize(i));
        }

        if (input === null || input === undefined) {
            return '';
        }

        if (typeof input === 'number') {
            return input;
        }

        return this.escapeReserved(input);
    }

    /**
     * Escape or strip reserved characters from the query when applicable.
     */
    private escapeReserved(query: string) {
        const escapable = [
            '\\', '/', '+', '-', '=', '&&', '||', '!', '(', ')', '{', '}', '[', ']', '^', '"', '~', '*', '?', ':'
        ];

        const forbidden = [
            '<', '>'
        ];

        let result = query;

        // escape reserved characters with a leading backslash
        escapable.forEach(keyword => result = result.split(keyword).join('\\' + keyword));

        // strip forbidden characters from the query entirely
        forbidden.forEach(keyword => result = result.split(keyword).join(''));

        return result;
    }
}

export interface ESParams extends SearchParams {
    /**
     * The string to search by. The string should be an elasticsearch `query_string`.
     * See 'https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-query-string-query.html'
     */
    query?: string;

    /**
     * The indices that should be searched. This only makes
     * sense for searches that can span multiple indices.
     */
    indices?: string[];
}
