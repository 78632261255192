import { Component, Input, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { LineItemTargets } from 'app/shared/helpers/line-item-targeting-helper';

import { LineItem } from 'app/shared/models';
import { BudgetHelper } from 'app/shared/helpers';
import { AuthorizationService, IdService } from 'app/core';
import { AdSlotRepository } from 'app/core/repositories';

import FORM_OPTIONS from '../../../platform/campaign-manager/line-items/options.json'

// Age and Gender segments as defined by LSD
// These do not change and can be hardcoded here
// TODO: have central repo for these. See also: data-segment-targeting component
const AGE_SEGMENT_IDS = [1, 2, 3, 4, 5, 6, 7];
const GENDER_SEGMENT_IDS = [8, 9];
const groupsPattern = / (?:AND|OR) (?![a-z0-9])/g;
const audiencePattern = /[a-z0-9.]+/g;

@Component({
    selector: 'line-item-section-card',
    templateUrl:  './line-item-section-card.html',
    styleUrls: ['./line-item-section-card.styl']
})
export class LineItemSectionCardComponent implements OnInit {
    @Input() lineItem: LineItem;
    @Input() metaData: ReplaySubject<LineItemTargets>;
    noOp: Function;
    budgetHelper: BudgetHelper;
    ageSegmentPattern: RegExp;
    genderSegmentPattern: RegExp;

    constructor(
        public adSlotRepository: AdSlotRepository,
        public auth: AuthorizationService,
        public id: IdService
    ) { }

    ngOnInit(): void {
        this.noOp = () => {};

        this.budgetHelper = new BudgetHelper(this.lineItem, this.lineItem.campaignObj);

        const ageSegmentOptions = FORM_OPTIONS.age.map(ele => ele.value);
        const genderSegmentOptions = FORM_OPTIONS.gender.map(ele => ele.value);
        this.ageSegmentPattern = new RegExp(ageSegmentOptions.join('|'), 'g');
        this.genderSegmentPattern = new RegExp(genderSegmentOptions.join('|'), 'g');
    }

    isExcluding(targetType): boolean {
        return targetType === 'exclude';
    }

    commaSeparate(data): string {
        return data.join(', ');
    }

    dayTimeDisplay(dayTimeTargeting): string {
        const days: string = dayTimeTargeting.days.join(', ');
        const hours: string = dayTimeTargeting.hours.join(', ');
        const article: string = (days.length && hours.length) ? ' from ' : '';
        return days + article + hours;
    }

    get genderTargets() {
        let genderTargets = [];
        if (this.lineItem.audienceTargeting) {
            const segmentGroups = this.lineItem.audienceTargeting.split(groupsPattern);
            const genderSegments = segmentGroups.filter(
                segmentGroup => segmentGroup.match(this.genderSegmentPattern)
            );
            if (genderSegments && genderSegments.length) {
                let type = 'include';
                if (genderSegments[0].includes('NOT')) {
                    type = 'exclude';
                }
                const genders = genderSegments[0].match(audiencePattern).map(segment => parseInt(segment));
                genderTargets = genders.map(gender => ({
                    type: type,
                    id: gender
                }));
            }
        }
        return genderTargets;
    }

    get genderTargetingType() {
        const targets = this.genderTargets;
        if (targets.length > 0) {
            return targets[0].type;
        }
        return 'include';
    }

    get ageTargets() {
        let ageTargets = [];
        if (this.lineItem.audienceTargeting) {
            const segmentGroups = this.lineItem.audienceTargeting.split(groupsPattern);
            const ageSegments = segmentGroups.filter(
                segmentGroup => segmentGroup.match(this.ageSegmentPattern)
            );
            if (ageSegments && ageSegments.length) {
                let type = 'include';
                if (ageSegments[0].includes('NOT')) {
                    type = 'exclude';
                }
                const ages = ageSegments[0].match(audiencePattern).map(segment => parseInt(segment));
                ageTargets = ages.map(age => ({
                    type: type,
                    id: age
                }));
            }
        }
        return ageTargets;
    }

    get ageTargetingType() {
        const targets = this.ageTargets;
        if (targets.length > 0) {
            return targets[0].type;
        }
        return 'include';
    }

    get dayTargets () {
        if (!this.lineItem.days) {
            return null;
        }
        return Object.keys(this.lineItem.days);
    }

    get timeTargets () {
        if (!this.lineItem.hours) {
            return null;
        }
        return Object.keys(this.lineItem.hours);
    }

    get liveAudienceIncludes() {
        if (!this.lineItem.audiences) {
            return null;
        }
        return this.lineItem.audiences.filter(audience => audience.type === 'include');
    }

    get liveAudienceExcludes() {
        if (!this.lineItem.audiences) {
            return null;
        }
        return this.lineItem.audiences.filter(audience => audience.type === 'exclude');
    }

    get isExchangeDSPCampaign() {
        return !this.lineItem.campaignObj.isSsp && this.lineItem.campaignObj.isExchange;
    }

    get calculateDailyBudget() {
        return this.budgetHelper.getDailyBudget();
    }
}
