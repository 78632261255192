import { NgModel } from '@angular/forms';
import { AdDetailsMacroComponent } from "app/shared/components/ad-details/ad-details-macro";
import { IdService } from 'app/core/id.service';
var BulkEditCreativeUrlsComponent = /** @class */ (function () {
    function BulkEditCreativeUrlsComponent(label, id) {
        this.id = id;
        this.creatives = [];
        this.originals = new Map();
        this.fields = ['clickUrl', 'urlTracking1', 'urlTracking2'];
        this.label = label;
    }
    BulkEditCreativeUrlsComponent.prototype.original = function (refId) {
        return this.originals.get(refId);
    };
    Object.defineProperty(BulkEditCreativeUrlsComponent.prototype, "entities", {
        get: function () {
            return this.creatives;
        },
        set: function (entities) {
            var _this = this;
            this.creatives = entities;
            this.originals.clear();
            entities.forEach(function (creative) {
                _this.originals.set(creative.refId, creative.clone());
            });
        },
        enumerable: true,
        configurable: true
    });
    BulkEditCreativeUrlsComponent.prototype.revert = function (creative, field) {
        creative[field] = this.original(creative.refId)[field];
    };
    BulkEditCreativeUrlsComponent.prototype.resetClickUrl = function () {
        var _this = this;
        this.clickUrlInput.reset();
        this.creatives.forEach(function (creative) { return _this.revert(creative, 'clickUrl'); });
    };
    BulkEditCreativeUrlsComponent.prototype.resetUrlTracking1 = function () {
        var _this = this;
        this.trackingUrl1Input.reset();
        this.creatives.forEach(function (creative) { return _this.revert(creative, 'urlTracking1'); });
    };
    BulkEditCreativeUrlsComponent.prototype.resetUrlTracking2 = function () {
        var _this = this;
        this.trackingUrl2Input.reset();
        this.creatives.forEach(function (creative) { return _this.revert(creative, 'urlTracking2'); });
    };
    BulkEditCreativeUrlsComponent.prototype.revertFields = function (creative) {
        var _this = this;
        this.fields.forEach(function (field) { return _this.revert(creative, field); });
    };
    BulkEditCreativeUrlsComponent.prototype.isDirty = function () {
        var _this = this;
        return this.entities.some(function (creative) { return _this.isCreativeDirty(creative); });
    };
    BulkEditCreativeUrlsComponent.prototype.isCreativeDirty = function (creative) {
        var original = this.originals.get(creative.refId);
        return original.clickUrl !== creative.clickUrl
            || original.urlTracking1 !== creative.urlTracking1
            || original.urlTracking2 !== creative.urlTracking2;
    };
    BulkEditCreativeUrlsComponent.prototype.updateCreatives = function (value, field) {
        this.creatives.forEach(function (creative) { return creative[field] = value; });
    };
    BulkEditCreativeUrlsComponent.prototype.openValidMacro = function () {
        this.macroInfoBox.open();
    };
    BulkEditCreativeUrlsComponent.prototype.reset = function () {
        this.resetClickUrl();
        this.resetUrlTracking1();
        this.resetUrlTracking2();
    };
    return BulkEditCreativeUrlsComponent;
}());
export { BulkEditCreativeUrlsComponent };
