import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SasNewsletter } from 'app/shared/models';
import { BackendRepository } from './backend-repository';

@Injectable()
export class SasNewsletterRepository extends BackendRepository<SasNewsletter> {
    public constructor(http: HttpClient) {
        super(http, '/sas-newsletter', SasNewsletter);
    }
}
