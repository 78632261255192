import { Directive, forwardRef, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[validate-min][formControlName],[validate-min][formControl],[validate-min][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => MinValidator), multi: true }
    ]
})
export class MinValidator implements Validator {

    @Input('validate-min') min;

    validate(c: AbstractControl): { [key: string]: any } {
        let max = c.value;
        if (max === null || max === undefined || this.min === null || this.min === undefined) {
            return null;
        }
        return this.min <= max ? null : { 'min' : { valid: false }};
    }
}
