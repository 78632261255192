import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CharacterMaxLengthValidator } from './character-max-length-validator.directive';
import { DateRangeValidator } from './date-range-validator.directive';
import { DateValidator } from './date.validator';
import { DomainValidator } from './domain-validator.directive';
import { ImageRatioValidator } from 'app/shared/directives/image-ratio-validator.directive';
import { MacroValidator } from './macro-validator.directive';
import { MaxValidator } from './max-validator.directive';
import { MinValidator } from './min-validator.directive';
import { PasswordValidator } from './password-validator.directive';
import { SecureUrlValidator } from './secure-url-validator.directive';
import { TimeValidator } from './time.validator';
import { UrlValidator } from './url-validator.directive';
import { IdAppendDirective } from './id-append.directive';

const directives = [
    CharacterMaxLengthValidator,
    DateRangeValidator,
    DateValidator,
    DomainValidator,
    ImageRatioValidator,
    MacroValidator,
    MaxValidator,
    MinValidator,
    PasswordValidator,
    SecureUrlValidator,
    TimeValidator,
    UrlValidator,
    IdAppendDirective
];

@NgModule({
    declarations: [...directives],
    exports: [...directives, FormsModule]

})
export class DirectivesModule {}
