import {
    Attribute,
    Component,
    Input,
    ViewChild,
    forwardRef,
    ElementRef,
    OnInit,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    QueryList,
    ContentChildren
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownComponent, Navigable, OutsideClickHelper } from '../dropdown';
import { OptionComponent } from '../filter-select';
import { OptionGroupComponent } from './option-group.component';
import { IdService } from 'app/core/id.service';

@Component({
    selector: 'select-dropdown',
    templateUrl: './select-dropdown.html',
    styleUrls: ['./select-dropdown.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectDropdownComponent),
            multi: true
        }
    ]
})
export class SelectDropdownComponent extends Navigable implements ControlValueAccessor , OnInit {
    @Input() disabled: Boolean = false;
    @Input() dropdownLeftAlign: Boolean = false;
    @Input() fullWidth: Boolean = false;
    @Input() allowCustom: Boolean = false;
    @Input() maxLengthCustomOption: number = 128;
    @Input() set customOptionValue(value: string) {
        if (value) {
            this.newOptionLabel = value;
            this.addCustomOption(this.newOptionLabel);
        }
    }

    @ViewChild(DropdownComponent, { static: true }) dropdown: DropdownComponent;
    @ContentChildren(OptionComponent) tplOptions: QueryList<OptionComponent>;
    @ContentChildren(OptionGroupComponent) groups: QueryList<OptionGroupComponent>;
    isButtonOnly = false;

    selected: number;
    onchange: any = null;
    _outsideClickTarget: OutsideClickHelper = null;

    isEditing: boolean = false;
    newOptionLabel: string = '';
    customOption: { key: number, label: string, value: string } | null = null;

    @Input() set options(value: Array<any>) {
        this._options = value;

        if (this.selected) {
            this._resetValue(this.selectedValue);
        }
    }

    private tplOptionsCache = null;

    get options(): Array<any> {
        if (this.groups && this.groups.length > 0) {
            if (!this.tplOptionsCache) {
                const groupOptions = this.groups.reduce((acc, cur) => {
                    acc = acc.concat(cur.options);
                    return acc;
                }, []);

                this.tplOptionsCache = groupOptions.map((option, key) => {
                    option.key = key;

                    const value = option.value;
                    const label = option.html;
                    return { key, value, label };
                });
            }

            return this.tplOptionsCache;
        }

        if (this.tplOptions && this.tplOptions.length > 0) {
            if (!this.tplOptionsCache) {
                this.tplOptionsCache = this.tplOptions.map((option, key) => {
                    const value = option.value;
                    const label = option.html;
                    return { key, value, label };
                });
            }

            return this.tplOptionsCache;
        }

        return this._options;
    }

    private _options: Array<any> = [];

    /**
     * Used to offer a different way to bind with the component.
     */
    selectedSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        public el: ElementRef,
        private cdr: ChangeDetectorRef,
        public id: IdService,
        @Attribute('placeholder') public placeholder,
        @Attribute('button') private button
    ) {
        super(cdr);
        this._outsideClickTarget = new OutsideClickHelper(el.nativeElement);
        this.isButtonOnly = button !== null;
    }

    ngOnInit(): void {
        this._outsideClickTarget.target = this.dropdown;
    }

    disable(): SelectDropdownComponent {
        this.disabled = true;

        return this;
    }

    enable(): SelectDropdownComponent {
        this.disabled = false;

        return this;
    }

    writeValue(value: any) {
        this._resetValue(value);
        this.cdr.markForCheck();
    }

    private _resetValue(value: any): void {
        delete this.selected;

        if (!this.options) {
            return;
        }
        if (this.customOption && this.customOption.value === value) {
            this.selected = this.customOption.key;
        } else {
            const option: any = this.options.find(option => option.value === value);
            if (option !== undefined) {
                this.selected = option.key;
            }
        }

    }

    dropdownVisChange(opened) {
        this.el.nativeElement.classList[opened ? 'add' : 'remove']('opened');
    }

    registerOnChange(fn): void {
        this.onchange = fn;
    }

    registerOnTouched(fn): void { }

    private propagate(): void {
        if (this.onchange !== null) {
            this.onchange(this.selectedValue);
            this.selectedSubject.next(this.selectedValue);
        }
    }

    private get selectedOption(): any {
        if (this.customOption && this.customOption.key === this.selected) {
            return this.customOption;
        }

        return this.options.find(option => option.key === this.selected);
    }

    private get selectedValue(): any {
        return this.selectedOption ? this.selectedOption.value : undefined;
    }

    get label() {
        if (this.selected !== undefined && this.selectedOption && !this.isButtonOnly) {
            return this.selectedOption.label;
        }
        if (typeof this.placeholder === 'string') {
            return this.placeholder;
        }
        return 'Select';
    }

    reset() {
        this._select(undefined);
    }

    public _select(key: number): void {
        this.selected = key;
        this.propagate();
        this.dropdown.hide();
        this._outsideClickTarget.deactivate();
        this.cdr.markForCheck();
    }

    isString(value: any) {
        return typeof value === 'string';
    }

    get hasCreateClass() {
        return (this.el.nativeElement as HTMLElement).classList.contains('create');
    }

    toggleEditing() {
        if (this.isEditing && this.newOptionLabel.trim()) {
            this.addCustomOption(this.newOptionLabel);
        }
        this.isEditing = !this.isEditing;
    }

    addCustomOption(value) {
        if (value.trim()) {
            this.customOption = {
                key: Number.MAX_SAFE_INTEGER,
                label: value,
                value: value,
            };
            this.isEditing = false;
        } else {
            this.customOption = null;
            this.isEditing = true;
        }

        this.newOptionLabel = '';
    }

    editCustomOption() {
        if (this.customOption) {
            this.newOptionLabel = this.customOption.label;
            this.customOption = null;
            this.isEditing = true;
        }
    }

    handleKeydown(event: KeyboardEvent): void {
        if (event.code === 'Enter') {
            event.preventDefault();
            this.addCustomOption(this.newOptionLabel);
            if (this.customOption) {
                this._select(this.customOption.key);
            }
        }
    }
}
