import { ElementRef, Renderer2, TemplateRef } from '@angular/core';
import { IdService } from 'app/core/id.service';
var OverlayLoadingIndicatorComponent = /** @class */ (function () {
    function OverlayLoadingIndicatorComponent(renderer, el, id) {
        this.renderer = renderer;
        this.el = el;
        this.id = id;
    }
    Object.defineProperty(OverlayLoadingIndicatorComponent.prototype, "opacity", {
        set: function (opacity) {
            this.renderer.setStyle(this.el.nativeElement, 'opacity', opacity || 0.5);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OverlayLoadingIndicatorComponent.prototype, "layer", {
        set: function (layer) {
            this.renderer.setStyle(this.el.nativeElement, 'z-index', layer || 0);
        },
        enumerable: true,
        configurable: true
    });
    return OverlayLoadingIndicatorComponent;
}());
export { OverlayLoadingIndicatorComponent };
