import { ElementRef, OnInit } from '@angular/core';
var PacingPillComponent = /** @class */ (function () {
    function PacingPillComponent(el) {
        this.el = el;
        this.index = null;
    }
    PacingPillComponent.prototype.ngOnInit = function () {
        if (this.index === null) {
            this.top = 25;
            this.position = 'bottom';
            return;
        }
        if (this.index === 0 && this.length === 1) {
            return this.position = 'right';
        }
        else if (this.index === 0) {
            this.top = 25;
            return this.position = 'bottom';
        }
        else {
            this.top = -75;
            return this.position = 'top';
        }
    };
    Object.defineProperty(PacingPillComponent.prototype, "status", {
        get: function () {
            var state = PacingPillComponent.PACING;
            if (this.end !== undefined && PacingPillComponent.NOW
                >= (this.end.getTime() + PacingPillComponent.MS_PER_DAY)) {
                state = PacingPillComponent.PACED;
            }
            else if (!this.delivering) {
                return PacingPillComponent.NOT_DELIVERING;
            }
            return state + ' ' + this.pacing.toString() + '%';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PacingPillComponent.prototype, "isPaced", {
        get: function () {
            return this.end !== undefined && PacingPillComponent.NOW
                >= (this.end.getTime() + PacingPillComponent.MS_PER_DAY);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PacingPillComponent.prototype, "expected", {
        get: function () {
            return 100 / this._total * this._expected;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PacingPillComponent.prototype, "actual", {
        get: function () {
            return 100 / this._total * this._spend;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PacingPillComponent.prototype, "overpacing", {
        get: function () {
            return this.actual > this.expected;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PacingPillComponent.prototype, "pacing", {
        get: function () {
            if (this._actual === 0 || this._total === 0) {
                return 0;
            }
            return Math.round((this._actual / this._total) * 100);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PacingPillComponent.prototype, "total", {
        get: function () {
            return this._total;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PacingPillComponent.prototype, "delivering", {
        get: function () {
            if (this.start === undefined) {
                return false;
            }
            return PacingPillComponent.NOW >= this.start.getTime()
                && PacingPillComponent.NOW < (this.end.getTime() + PacingPillComponent.MS_PER_DAY);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PacingPillComponent.prototype, "budgetTypeText", {
        get: function () {
            switch (this.budgetType) {
                case 'adjusted_impressions':
                    return 'Impressions';
                case 'impressions':
                    return 'Impressions';
                default:
                    return 'Spend';
            }
        },
        enumerable: true,
        configurable: true
    });
    PacingPillComponent.prototype.findMin = function (a, b) {
        return Math.min(a, b);
    };
    PacingPillComponent.prototype.findMax = function (a, b) {
        return Math.max(a, b);
    };
    PacingPillComponent.NOT_DELIVERING = 'Not Delivering';
    PacingPillComponent.PACING = 'Pacing';
    PacingPillComponent.PACED = 'Paced';
    PacingPillComponent.NOW = (new Date()).getTime();
    PacingPillComponent.MS_PER_DAY = 86400000;
    return PacingPillComponent;
}());
export { PacingPillComponent };
