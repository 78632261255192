import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { NewsletterRepository } from 'app/core/repositories';
import { IdService } from 'app/core/id.service';

@Component({
    selector: 'delete-newsletter-dialog',
    templateUrl: './delete-newsletter-dialog.html'
})
export class DeleteNewsletterDialogComponent {

    public constructor(
        public newsletterRepository: NewsletterRepository,
        public id: IdService
        ) { }

    @Output() public deleted: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('dialog', { static: true }) public dialog;

    public newsletter: any = null;

    public open(newsletter): void {
        this.newsletter = newsletter;
        this.dialog.open();
    }

    public delete(): void {
        this.newsletterRepository
            .delete(this.newsletter.id)
            .subscribe(() => this.deleted.emit(this.newsletter));
    }
}
