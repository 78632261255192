import { AfterViewInit, ChangeDetectorRef, ComponentFactoryResolver, ComponentRef, EventEmitter, OnDestroy, QueryList, ViewContainerRef } from '@angular/core';
import { Subject, BehaviorSubject, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FilterExistComponent } from './filter-exist/filter-exist.component';
import { FilterEnumComponent } from './filter-enum/filter-enum.component';
import { FilterDateComponent } from './filter-date/filter-date.component';
import { FilterIdComponent } from './filter-id/filter-id.component';
import { FilterNumberComponent } from './filter-number/filter-number.component';
import { FilterStringComponent } from './filter-string/filter-string.component';
import { FilterFieldDirective } from './filter-field.directive';
import { Filter } from './filter';
import { OperationLabel } from './operation';
import { PopoverComponent } from 'app/shared/elements/popover';
import { IdService } from 'app/core/id.service';
var FilterComponents = [
    FilterExistComponent,
    FilterEnumComponent,
    FilterIdComponent,
    FilterNumberComponent,
    FilterStringComponent,
    FilterDateComponent
];
var FiltersComponent = /** @class */ (function () {
    function FiltersComponent(cdr, componentFactoryResolver, id) {
        this.cdr = cdr;
        this.componentFactoryResolver = componentFactoryResolver;
        this.id = id;
        this.disableMultipleFilters = false;
        this.changed = new EventEmitter();
        this.available = new EventEmitter();
        this.search$ = new BehaviorSubject('');
        this.filterComponents = [];
        this.availableFilters = [];
        this.filters$ = new ReplaySubject(1);
        this.onDestroy = new Subject();
    }
    Object.defineProperty(FiltersComponent.prototype, "appliedFilters", {
        set: function (filters) {
            this.filters$.next(filters);
        },
        enumerable: true,
        configurable: true
    });
    FiltersComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.availableFilters = this.queryResults
            .filter(function (directive) { return directive.component.filter instanceof Filter; })
            .map(function (directive) { return directive.component; });
        this.available.emit(this.availableFilters.map(function (component) { return component.filter; }));
        this.filters$.subscribe(function (filters) { return _this.setFilters(filters); });
        this.items = this.search$.pipe(debounceTime(200), distinctUntilChanged(), map(function (value) { return value.toLowerCase(); }), map(function (value) { return _this.availableFilters.filter(function (item) { return item.filter.label.toLowerCase().includes(value); }); }));
    };
    FiltersComponent.prototype.ngOnDestroy = function () {
        this.onDestroy.next();
        this.onDestroy.complete();
        this.destroyComponent();
    };
    FiltersComponent.prototype.redraw = function (componentToDraw) {
        // destroy the component currently at this spot
        this.destroyComponent();
        // draw the component and place it in this spot
        var factory = this.componentFactoryResolver
            .resolveComponentFactory(componentToDraw.constructor);
        this.component = this.filterOutlet.createComponent(factory);
        // set props on the new component instance
        var component = this.component.instance;
        Object.assign(component, componentToDraw);
        component.filter = new componentToDraw.filter.constructor(JSON.parse(JSON.stringify(componentToDraw.filter)));
    };
    FiltersComponent.prototype.add = function (e) {
        this.currentIndex = -1;
        this.destroyComponent();
        this.popover.target = e.target;
        this.popover.show(e);
    };
    FiltersComponent.prototype.applyFilter = function () {
        var filters = this.filterComponents.concat(this.component.instance).map(function (component) { return component.filter; });
        if (this.currentIndex >= 0) {
            this.filterComponents[this.currentIndex] = this.component.instance;
            filters = this.filterComponents.map(function (component) { return component.filter; });
        }
        this.setFilters(filters);
        this.popover.hide();
        this.emit(filters);
        this.search$.next('');
    };
    FiltersComponent.prototype.remove = function (index) {
        var filters = this.filterComponents.filter(function (f, i) { return i !== index; }).map(function (component) { return component.filter; });
        this.setFilters(filters);
        this.emit(filters);
    };
    FiltersComponent.prototype.select = function (option) {
        if (!option) {
            this.search$.next('');
            return;
        }
        var field = option.filter.field;
        var toDraw = this.availableFilters.find(function (component) { return component.filter.field === field; });
        this.redraw(toDraw);
    };
    FiltersComponent.prototype.editFilter = function (e, component, index) {
        this.currentIndex = index;
        this.redraw(component);
        this.popover.target = e.target;
        this.popover.show(e);
    };
    FiltersComponent.prototype.emit = function (filters) {
        this.changed.emit(filters);
    };
    FiltersComponent.prototype.setFilters = function (filters) {
        var _this = this;
        this.filterComponents = filters.map(function (filter) {
            var componentToCreate = _this.availableFilters.find(function (component) { return component.filter.field === filter.field; });
            if (componentToCreate) {
                var component = new componentToCreate.constructor();
                Object.assign(component, componentToCreate);
                component.filter = filter;
                return component;
            }
        }).filter(function (component) { return !!component; });
        this.cdr.detectChanges();
    };
    FiltersComponent.prototype.destroyComponent = function () {
        if (this.component) {
            this.component.destroy();
        }
    };
    FiltersComponent.prototype.getOperationLabel = function (value) {
        return OperationLabel[value];
    };
    FiltersComponent.prototype.isValid = function () {
        if (this.component) {
            return this.component.instance.filter.isValid();
        }
        return false;
    };
    Object.defineProperty(FiltersComponent.prototype, "showAddFilter", {
        get: function () {
            return this.filterComponents.length === 0 || !this.disableMultipleFilters;
        },
        enumerable: true,
        configurable: true
    });
    return FiltersComponent;
}());
export { FiltersComponent };
