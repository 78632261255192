import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AudienceFile, DeltaCountResponse, HistoricalCountDataPoint, HistoricalCounts } from 'app/shared/models';
import { BackendRepository } from './backend-repository';

@Injectable()
export class UMLAUTRepository extends BackendRepository<AudienceFile> {
    constructor(http: HttpClient) {
        super(http, '/umlaut', AudienceFile);
    }

    get(segmentId: number) {
        const params = {
            conditions: [
                { field: 'segmentId', value: segmentId }
            ]
        };

        return this.http.get(this.url(this.path + '/uploads' + this.stringifyParams(params)))
            .pipe(map(response => response as AudienceFile));
    }

    getLatestCountsBySegment(segmentId: number): Observable<HistoricalCountDataPoint> {
        const params = {
            conditions: [
                { field: 'page', value: 1 },
                { field: 'orderBy', value: 'date' },
                { field: 'order', value: 'desc' }
            ]
        };
        return this.http.get(
            this.url(`${this.path}/segments/${segmentId}/stats${this.stringifyParams(params)}`)
        ).pipe(map(result => {
            return result['data'].length ? result['data'][0] : {} as HistoricalCountDataPoint;
        }));
    }

    getHistoricalCountsBySegment(segmentId: number): Observable<HistoricalCountDataPoint[]> {
        const params = {
            conditions: [
                { field: 'page', value: 1 },
                { field: 'pageSize', value: 100 },
                { field: 'orderBy', value: 'date' },
                { field: 'order', value: 'asc' }
            ]
        };
        return this.http.get(
            this.url(`${this.path}/segments/${segmentId}/stats${this.stringifyParams(params)}`)
        ).pipe(map(result => result['data'] as HistoricalCountDataPoint[]));
    }

    getHistoricalCountsByAdvertiser(advertiserId: number): Observable<HistoricalCountDataPoint[]> {
        const params = {
            conditions: [
                { field: 'page', value: 1 },
                { field: 'pageSize', value: 100 },
                { field: 'orderBy', value: 'date' },
                { field: 'order', value: 'desc' }
            ]
        };
        return this.http.get(
            this.url(`${this.path}/advertisers/${advertiserId}/stats${this.stringifyParams(params)}`)
        ).pipe(map(result => result['data'] as HistoricalCountDataPoint[]));
    }

    deltaCountsByAdvertiserId(advertiserRefId: number): Observable<DeltaCountResponse> {
        return this.http.get(this.url(`${this.path}/advertisers/${advertiserRefId}/stats/grouped`))
            .pipe(map(response => {
                return response as DeltaCountResponse;
            }));
    }

    asyncSearch(params: any): Observable<{ items: AudienceFile[], page: number, pages: number, total: number}> {
        return this.http.get(this.url(this.path + '/uploads' + this.stringifyParams(params)))
            .pipe(map(response => ({
                items: response['data'],
                page: 1,
                pages: response['pages'],
                total: response['total']
            })));
    }

    private stringifyParams(params: any) {
        let str = '?' + params.conditions.map(condition => `${condition.field}=${condition.value}`).join('&');

        params.n ? str += `&pageSize=${params.n}` : '';
        params.page ? str += `&page=${params.page}` : '';
        params.orderBy ? str += `&orderBy=${params.orderBy}` : '';
        params.sort ? str += `&order=${params.sort}` : '';

        return str;
    }

    getRejectedLink(audienceFile: AudienceFile) {
        return this.http.post(this.url('/audience/download-rejected', audienceFile.uniqueSegmentId.toString()), {
            filename: audienceFile.rejectedHashesFile,
            bucketName: audienceFile.rejectedHashesBucket
        }).pipe(map(response => response['output'].presignedURI));
    }
}
