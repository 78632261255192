import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Region } from 'app/shared/models';
import { BackendRepository } from './backend-repository';

@Injectable()
export class RegionRepository extends BackendRepository<Region> {
    public constructor(http: HttpClient) {
        super(http, '/region', Region);
    }
}
