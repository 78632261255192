import { AfterViewInit, DoCheck, Directive, ElementRef, Input } from '@angular/core';

declare var hljs: any;

@Directive({
    selector: 'code[highlight]'
})
export class HighlightDirective implements DoCheck, AfterViewInit {
    @Input() code: any;
    private prev: string;

    constructor(private ref: ElementRef) { }

    ngAfterViewInit() {
        this.highlight(this.ref.nativeElement);
    }

    ngDoCheck() {
        if (!this.code) {
            return;
        }

        const current = this.code.textContent;

        if (this.prev !== current) {
            this.prev = current;
            this.ref.nativeElement.textContent = this.code.textContent;
            this.highlight(this.ref.nativeElement);
        }
    }

    private highlight(ref: ElementRef) {
        if (window.hasOwnProperty('hljs')) {
            hljs.highlightBlock(ref);
        }
    }
}
