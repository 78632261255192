import { Directive, forwardRef, Input} from '@angular/core';
import { NG_VALIDATORS, MaxLengthValidator } from '@angular/forms';

@Directive({
    selector: `
        [validate-max-length][formControlName],
        [validate-max-length][formControl],
        [validate-max-length][ngModel]
    `,
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => CharacterMaxLengthValidator), multi: true }
    ]
})
export class CharacterMaxLengthValidator extends MaxLengthValidator {

    @Input('validate-max-length') maxlength: string;
}
