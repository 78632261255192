import { Component, Input, ViewChild, ContentChild, TemplateRef, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Observable, of, forkJoin, combineLatest } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { DeletePublisherDialogComponent } from 'app/shared/components/delete-publisher-dialog';
import { DownloadHelper } from 'app/shared/helpers/download-helper';
import { HistoryViewComponent } from 'app/shared/components/history-view';
import { IdService } from 'app/core/id.service';
import { InventoryDataService } from 'app/shared/services/inventory-data.service';
import { LiveConnectTag } from 'app/shared/models';
import { LiveConnectTagRepository } from 'app/core/repositories';
import { AuthorizationService, NotificationsService } from 'app/core';
import { PaginatorComponent } from 'app/shared/elements/paginator';
import { Publisher } from 'app/shared/models/publisher';
import { PublisherBulkEditComponent } from 'app/shared/components/publisher-bulk-edit';
import { PublisherRepository } from 'app/core/repositories/publisher-repository.service';
import { SearchParams } from 'app/shared/helpers/query-builder';
import { TableComponent } from 'app/shared/elements/table';
import { TableHelper } from 'app/shared/helpers/table-helper';

enum PublisherAction {
    Delete,
    DownloadLiveConnect,
    Edit,
    EditAdvertiserDomains,
    EditCategory,
    EditCategoryBlacklist,
    EditSensitveCategoryBlocklist,
    EditName,
    ViewHistory,
    EditEsp
}

@Component({
    selector: 'publishers-table',
    templateUrl: './publishers-table.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublishersTableComponent implements OnInit {
    @ContentChild('actions', { static: true }) actionsTemplate: TemplateRef<any>;
    @ViewChild(TableComponent, { static: true }) table: TableComponent;
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent;
    @ViewChild(DeletePublisherDialogComponent, { static: true }) deletePublisherDialog: DeletePublisherDialogComponent;
    @ViewChild(HistoryViewComponent, { static: true }) historyView: HistoryViewComponent;
    @ViewChild(PublisherBulkEditComponent, { static: true }) bulkEditor: PublisherBulkEditComponent;
    helper = new TableHelper<Publisher>(params => this.adapter(params));
    PublisherAction = PublisherAction;
    private mediaGroupId: string;

    @Input('media-group') set setMediaGroup(id: string) {
        this.mediaGroupId = id;
        this.paginator.reset();
    }

    constructor(
        private notifications: NotificationsService,
        private publisherRepository: PublisherRepository,
        private liveConnectTagRepository: LiveConnectTagRepository,
        public inventoryData: InventoryDataService,
        public id: IdService,
        public authorizationService: AuthorizationService
    ) { }

    ngOnInit() {
        this.helper.table = this.table;
        this.helper.paginator = this.paginator;
        combineLatest(this.table.query, this.paginator.query).subscribe(args => this.helper.search(args));
    }

    private adapter(params: SearchParams) {
        if (typeof this.mediaGroupId === 'string') {
            params.conditions.push({ field: 'mediaGroup', value: this.mediaGroupId });
        }

        return this.publisherRepository.asyncSearch(params);
    }

    refresh() {
        this.paginator.reset();
        this.table.clearSelections();
    }

    handle(action: PublisherAction, publishers: Publisher[]) {
        switch (action) {
            case PublisherAction.Edit:
                this.edit(publishers);
                break;
            case PublisherAction.EditName:
                this.bulkEditor.open(publishers, 'name');
                break;
            case PublisherAction.EditCategory:
                this.bulkEditor.open(publishers, 'category');
                break;
            case PublisherAction.EditEsp:
                this.bulkEditor.open(publishers, 'esp');
                break;
            case PublisherAction.EditCategoryBlacklist:
                this.bulkEditor.open(publishers, 'blocklistCategories');
                break;
            case PublisherAction.EditSensitveCategoryBlocklist:
                this.bulkEditor.open(publishers, 'sensitiveCategories');
                break;
            case PublisherAction.EditAdvertiserDomains:
                this.bulkEditor.open(publishers, 'targetedDomains');
                break;
            case PublisherAction.ViewHistory:
                this.viewHistory(publishers);
                break;
            case PublisherAction.DownloadLiveConnect:
                this.downloadLiveConnectTags(publishers);
                break;
            case PublisherAction.Delete:
                this.deletePublishers(publishers);
                break;
        }
    }

    canModify(publishers: Publisher[]): boolean {
        return publishers.every(publisher => this.authorizationService.canModifyMonetizationPublisher(publisher.clientTypes));
    }

    private edit(publishers: Publisher[]) {
        this.bulkEditor.open(publishers);
    }

    private viewHistory(publishers: Publisher[]) {
        if (publishers.length > 1) {
            this.notifications.error('Please select a single publisher to view history for at a time.');
            return;
        }

        this.historyView.viewPublisherHistory(publishers[0]);
    }

    private deletePublishers(publishers: Publisher[]) {
        if (publishers.length > 1) {
            this.notifications.error('Please select a single publisher to delete at a time.');
            return;
        }

        this.deletePublisherDialog.open(publishers[0]);
    }

    private downloadLiveConnectTags(publishers: Publisher[]) {
        forkJoin(publishers.map((publisher: Publisher) => {
            return this.liveConnectTagRepository.getOrCreateByAdvertiserIdAndAccountId(publisher.refId, publisher.accountId);
        })).pipe(catchError(() => {
            this.notifications.error('There was an error downloading the LiveConnect tags.', 'Oh no!');
            return of([]);
        })).subscribe((tags: LiveConnectTag[]) => {
            if (tags) {
                const csv = tags.map(tag => {
                    return {
                        'Publisher ID': tag.publisherId,
                        'Publisher Name': tag.name,
                        'LiveConnect Tag': tag.liveConnectTagJs
                    };
                });

                DownloadHelper.downloadAsCSV(csv, 'liveconnect-tags');
            }
        });
    }
}
