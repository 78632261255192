import { Model } from './model';

interface Grant {
    id: string;
    roles: number[];
}

export class AccessList extends Model {
    public entities: {
        LiveIntent: Grant[],
        MediaGroup: Grant[],
        Agency: Grant[],
        Advertiser: Grant[],
        Publisher: Grant[]
    };

    constructor(from: any = {}) {
        super(null);
        this.entities = from;
    }

    public serialize(): string {
        return null;
    }

    public hasAccess(entityType: string, role: number, entityId?: string): boolean {
        const data = this.entities[entityType];

        // If `entityType` does not exists in the `rbac` object
        // then the role is not there either.
        if (data === undefined) {
            return false;
        }

        // We only accept `data` as an array. It should be an array of objects
        // which have at least a property `roles` and possibly a property `id`
        // of the entity.
        if (!Array.isArray(data)) {
            return false;
        }

        // Initialize `roles` as null and importantly not an array.
        let roles = null;

        // Find the actual roles we are looking for, depending on `entityType` and
        // possibly `entity`.
        if (!entityId) {
            for (let grant of data) {
                if (Array.isArray(grant.roles) && !Array.isArray(roles)) {
                    roles = grant.roles;
                } else if (Array.isArray(grant.roles) && Array.isArray(roles)) {
                    roles = roles.concat(grant.roles);
                }
            }
        } else {
            for (let i = 0; i < data.length; i++) {
                if (data[i].id === entityId) {
                    roles = data[i].roles;
                    break;
                }
            }
        }

        // We only accept `roles` as an array.
        if (!Array.isArray(roles)) {
            return false;
        }

        // If `role` is in `roles` we return true.
        if (roles.indexOf(role) > -1) {
            return true;
        }

        // Otherwise, return false.
        return false;
    }

    get entity() {
        return 'Access List';
    }
}
