import { Model } from './model';

export class DataProvider extends Model {

    static readonly ADOBE_LIVE_AUDIENCE = 69;
    static readonly BLUEKAI_LIVE_AUDIENCE = 70;
    static readonly NEUSTAR_LIVE_AUDIENCE = 73;
    static readonly WEBSITE_AUDIENCE_SEGMENT_PROVIDER = 75;
    static readonly LOTAME_LIVE_AUDIENCE = 78;
    static readonly DET_EXPANDED_SEGMENT_PROVIDER = 81;
    static readonly PRO_EXPANDED_SEGMENT_PROVIDER = 82;
    static readonly REPLACEABLE_AUDIENCE_SEGMENT_PROVIDER = 83;
    static readonly LIVE_AUDIENCE_SEGMENT_PROVIDER = 84;
    static readonly MERKLE_LIVE_AUDIENCE = 85;
    static readonly LIVERAMP_LIVE_AUDIENCE = 86;
    static readonly MEDIAMATH_LIVE_AUDIENCE = 87;
    static readonly DYNAMIC_SEGMENT_ALOGIRTHMIC_RETARGETING = 88;
    static readonly WEBSITE_SEGMENT_URL_PROVIDER = 89;
    static readonly WEBSITE_SEGMENT_EVENT_PROVIDER = 90;

    name?: string;
    category?: string;
    categoryId?: number;
    provider?: string;
    providerId?: number;
    cost?: number;
    system?: string;
    prefix?: string;

    public serialize(): string {
        return JSON.stringify(this);
    }

    get entity() {
        return 'Data Provider';
    }
}
