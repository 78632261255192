<div class="page page--padding">
    <div class="row">
        <div class="column--9 spacer--large-bottom">
            <h1>Global Search</h1>
        </div>
    </div>
</div>
<div class="padding-large">
    <div class="row">
        <div class="column--2 column--rpad">
            <div class="search-advanced">
                <ng-container *ngIf="aggregatedResults$ | async as aggs">
                    <h5>Refine Search</h5>
                    <hr class="space">
                    <div class="spacer--large-bottom">
                        <strong>Demand</strong>
                    </div>
                    <label class="_checkbox" *ngFor="let filter of demandFilters">
                        <ng-container *ngIf="auth.isInternalUser || !filter.internalOnly">
                            <input name="filter" type="checkbox" (change)="filterBy($event.target.checked, filter)">
                            <span>
                                <span>{{filter.label}}</span>
                                <span class="_badge _badge--small">{{count(aggs, filter.index) | number}}</span>
                            </span>
                        </ng-container>
                    </label>
                    <hr class="space">
                    <div class="spacer--large-bottom">
                        <strong>Supply</strong>
                    </div>
                    <label class="_checkbox" *ngFor="let filter of supplyFilters">
                        <ng-container *ngIf="auth.isInternalUser || !filter.internalOnly">
                            <input name="filter" type="checkbox" (change)="filterBy($event.target.checked, filter)">
                            <span>
                                <span>{{filter.label}}</span>
                                <span class="_badge _badge--small">{{count(aggs, filter.index) | number}}</span>
                            </span>
                        </ng-container>
                    </label>
                </ng-container>
            </div>
        </div>
        <div class="column--10">
            <app-table [data]="tableHelper.data | async" filter-by="name" route-key="q" search-on-type showEmptyTable="true">
                <div class="zero align-center">
                    <h3>Your search returned zero results.</h3>
                </div>
                <ng-container appTableColumn="type" appTableColumnName="Type" appTableColumnLocked>
                    <th *appTableHeader class="date">Type</th>
                    <td *appTableCell="let entity">{{ !useNewSearchExperience ? entity.type : formatIndex(entity.index) }}</td>
                </ng-container>
                <ng-container appTableColumn="name" appTableColumnName="Name">
                    <th *appTableHeader class="name">Name</th>
                    <td *appTableCell="let entity">
                        <a class="ellipsis-wrap-white-space" [routerLink]="link(entity)">{{entity.name}}</a>
                        <br>
                        <span class="supporting">ID: {{entity.refId}}</span>
                        <ng-container *ngIf="entity.externalId">
                            <br>
                            <span class="supporting">External ID: {{entity.externalId}}</span>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container appTableColumn="agencyName" appTableColumnName="Agency">
                    <th *appTableHeader class="medium">Agency / Media Group</th>
                    <td *appTableCell="let entity">
                        <ng-container *ngIf="entity.agencyName; else noParent">
                            <span>{{entity.agencyName}}</span>
                            <br>
                            <span class="supporting">{{entity.agencyType === 'Advertiser Agency' ? 'Agency' : 'Media Group'}}</span>
                        </ng-container>
                        <ng-template #noParent>&mdash;</ng-template>
                    </td>
                </ng-container>
                <ng-container [appTableColumn]="useNewSearchExperience? 'created_on' : 'created'" appTableColumnName="Created">
                    <th *appTableHeader appTableSort class="date" class="date">Created</th>
                    <td *appTableCell="let entity">
                        <span>{{!useNewSearchExperience ? entity.created : entity.createdOn | date: 'mediumDate' || '&mdash;'}}</span>
                        <br>
                        <span class="supporting">{{!useNewSearchExperience ? entity.created : entity.createdOn | date: 'shortTime'}}</span>
                    </td>
                </ng-container>
                <ng-container [appTableColumn]="useNewSearchExperience ? 'updated_on' : 'modified'" appTableColumnName="Updated">
                    <th *appTableHeader appTableSort appTableNoDefaultSortColumn class="date">Updated</th>
                    <td *appTableCell="let entity">
                        <span>{{!useNewSearchExperience ? entity.modified : entity.updatedOn | date: 'mediumDate' || '&mdash;'}}</span>
                        <br>
                        <span class="supporting">{{!useNewSearchExperience ? entity.modified : entity.updatedOn | date: 'shortTime'}}</span>
                    </td>
                </ng-container>
            </app-table>
            <paginator [total]="getESTotal(tableHelper.data | async)" noFirstAndLast route-key="q"></paginator>
        </div>
     </div>
</div>
