import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { ReportingSchedule } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { map } from 'rxjs/operators';
var ReportingScheduleRepository = /** @class */ (function (_super) {
    tslib_1.__extends(ReportingScheduleRepository, _super);
    function ReportingScheduleRepository(http) {
        return _super.call(this, http, '/reporting/schedule', ReportingSchedule) || this;
    }
    ReportingScheduleRepository.prototype.getUserScheduledReports = function () {
        return this.http.get(this.url(this.path));
    };
    ReportingScheduleRepository.prototype.getAllScheduledReports = function () {
        return this.http.get(this.url(this.path, 'allUsers'));
    };
    ReportingScheduleRepository.prototype.update = function (params, model) {
        return this.http.put(this.url(this.path, params.id), model.serialize());
    };
    ReportingScheduleRepository.prototype.immediateSend = function (id) {
        return this.http.post(this.url(this.path, id.toString(), 'send'), {});
    };
    ReportingScheduleRepository.prototype.immediateBulkSend = function (ids) {
        return this.http.post(this.url(this.path, 'send'), { 'ids': ids });
    };
    ReportingScheduleRepository.prototype.save = function (instance) {
        var _this = this;
        // Overriding BackendRepository's method because unlike LSD 'output' field doesn't exist in Wolfman response
        var params = [this.path];
        var id = instance.id;
        if (typeof id === 'number') {
            id = id.toString();
        }
        if (typeof id === 'string') {
            params.push(id);
        }
        return this.http.post(this.url.apply(this, params), instance.serialize())
            .pipe(map(function (response) { return _this.build(response); }));
    };
    return ReportingScheduleRepository;
}(BackendRepository));
export { ReportingScheduleRepository };
