import { Model } from './model';
import { BudgetSpendType, ClearingMethod, Goal, PricingModel, System } from './campaign';

export class StrategyCard extends Model {
    static readonly FIRST_PRICE_CPM_MAX_CLICKTHRU : number = 1;
    static readonly PERFORMANCE_MAXIMIZE_CONVERSIONS_DAILY_CAP : number = 105;
    static readonly PERFORMANCE_MAXIMIZE_CONVERSIONS : number = 110;

    name: string;
    demand: string;
    goal: Goal;
    reach: string;
    bidLabel: string;
    pricingModel: PricingModel;
    clearingMethod: ClearingMethod;
    campaignGoal: Goal;
    strategyCardId: number;
    system: System;
    budgetSpendType: BudgetSpendType;
    conversionTracker: boolean;
    maxCpa: boolean;
    dailyCap: boolean;

    serialize(): string {
        return JSON.stringify(this);
    }

    get entity() {
        return 'Strategy Card';
    }
}
