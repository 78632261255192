import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'groupBy'
})
export class GroupByPipe implements PipeTransform {
    transform(collection: any[], groupBy: string) {
        if (!collection) {
            return null;
        }

        return collection.reduce((acc, current) => {
            if (!acc.find(existing => existing[groupBy] === current[groupBy])) {
                acc.push(current);
            }

            return acc;
        }, []);
    }
}
