import * as tslib_1 from "tslib";
import { Filter } from '../filter';
import { Operation } from '../operation';
var FilterExist = /** @class */ (function (_super) {
    tslib_1.__extends(FilterExist, _super);
    function FilterExist() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._query = '';
        _this.operations = [
            Operation.Exists,
            Operation.DoesNotExist
        ];
        return _this;
    }
    Object.defineProperty(FilterExist.prototype, "query", {
        get: function () {
            return this._query;
        },
        enumerable: true,
        configurable: true
    });
    FilterExist.prototype.isValid = function () {
        return this.operations.includes(this.operation);
    };
    return FilterExist;
}(Filter));
export { FilterExist };
