import {
    Directive,
    OnInit,
    Input,
    HostListener,
    HostBinding
} from '@angular/core';

import { AsyncTableComponent } from './async-table.component';

@Directive({
    selector: '[async-sort]'
})
export class AsyncSortDirective implements OnInit {
    @Input('async-sort') sort: string = null;
    @Input() default = false;
    @Input() desc = false;

    constructor(private table: AsyncTableComponent) { }

    ngOnInit() {
        if (this.default !== false) {
            this.table.orderBy = this.sort;
        }

        if (this.desc !== false) {
            this.table.direction = 'DESC';
        }
    }

    @HostBinding('class.active') get active() {
        return this.table.orderBy === this.sort;
    }

    @HostBinding('class.ascending') get ascending() {
        return this.table.direction === 'ASC';
    }

    @HostListener('click') click() {
        if (this.sort !== null) {
            if (this.sort === this.table.orderBy) {
                this.table.direction = this.table.direction === 'ASC' ? 'DESC' : 'ASC';
            } else {
                this.table.orderBy = this.sort;
            }
        }
    }
}
