import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { Publisher } from 'app/shared/models';
import { MediaGroupRepository, PublisherRepository } from 'app/core/repositories';
import { Observable, of, zip } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';

@Injectable()
export class PublisherResolver implements Resolve<Publisher> {
    constructor(
        private mediaGroupRepository: MediaGroupRepository,
        private publisherRepository: PublisherRepository
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        const publisherId = route.params['id'];
        const mediaGroupId = route.params['mediaGroupId'];

        if (publisherId) {
            return this.publisherRepository.get(publisherId).pipe(
                switchMap(publisher => zip(this.mediaGroupRepository.get(publisher.mediaGroup), of(publisher))),
                switchMap(([ mediaGroup, publisher ]) => {
                    publisher.mediaGroupObj = mediaGroup;
                    return of(publisher);
                })
            );
        }

        if (mediaGroupId) {
            return this.newPublisher(mediaGroupId);
        }
        const publisher = new Publisher();
        return of(publisher);
    }

    newPublisher(mediaGroupId): Observable<Publisher> {
        return this.mediaGroupRepository.get(mediaGroupId)
            .pipe(mergeMap(mediaGroup => {

                const publisher = new Publisher();
                publisher.mediaGroup = mediaGroup.id;
                publisher.mediaGroupObj = mediaGroup;

                return of(publisher);
            }));
    }
}
