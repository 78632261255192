import { NgForm } from '@angular/forms';
import { IdService } from 'app/core';
import { SupplyAssetService } from 'app/core/supply-asset.service';
import { IABAssets, SupplyAsset, SupplyAssetProperties, SupplyImageAssetType } from 'app/shared/models/native-supply';
import { of } from 'rxjs';
var BlueprintAssetRestrictions = /** @class */ (function () {
    function BlueprintAssetRestrictions(supplyAssetService, id) {
        this.supplyAssetService = supplyAssetService;
        this.id = id;
        this.disableInput = false;
        this.hideLogo = false;
        this.availableMacros = [];
        this.supplyAssetProperties = SupplyAssetProperties;
        this.supplyAssets = new SupplyAsset();
        this.imageDimensionType = "min";
        this.isInitialized = false;
    }
    Object.defineProperty(BlueprintAssetRestrictions.prototype, "blueprint", {
        get: function () {
            return this._blueprint;
        },
        set: function (bp) {
            this._blueprint = bp;
            this.supplyAssetService.initSupplyAssets(this.supplyAssets);
            this.supplyAssetService.setAssets(this.blueprint, this.supplyAssets);
            if (this.supplyAssets && this.supplyAssets.mainImage && this.supplyAssets.mainImage.isExact) {
                this.imageDimensionType = 'exact';
            }
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(BlueprintAssetRestrictions.prototype, "macros", {
        set: function (value) {
            this.availableMacros = value;
            this.update();
        },
        enumerable: true,
        configurable: true
    });
    BlueprintAssetRestrictions.prototype.ngOnInit = function () {
        this.supplyAssetService.initSupplyAssets(this.supplyAssets);
        this.supplyAssetService.setAssets(this.blueprint, this.supplyAssets);
        if (this.supplyAssets && this.supplyAssets.mainImage && this.supplyAssets.mainImage.isExact) {
            this.imageDimensionType = 'exact';
        }
        this.isInitialized = true;
    };
    BlueprintAssetRestrictions.prototype.update = function () {
        if (this.isInitialized) {
            if (this.availableMacros && this.blueprint.isCustom) {
                for (var _i = 0, _a = this.supplyAssetProperties; _i < _a.length; _i++) {
                    var prop = _a[_i];
                    if (this.availableMacros.indexOf(prop.macro) < 0) {
                        this.supplyAssets[prop.modelKey].required = false;
                    }
                }
            }
            this.blueprint.assets = this.updateAssets();
        }
    };
    /**
     * Update Assets
     * @returns {any[]}
     */
    BlueprintAssetRestrictions.prototype.updateAssets = function () {
        var _a;
        var assets = [];
        for (var _i = 0, _b = this.supplyAssetProperties; _i < _b.length; _i++) {
            var prop = _b[_i];
            if (this.availableMacros && this.blueprint.isCustom) {
                if (this.availableMacros.indexOf(prop.macro) < 0) {
                    continue;
                }
            }
            var supplyAsset = this.supplyAssets[prop.modelKey];
            var key = this.supplyAssets[prop.modelKey].typeDescription;
            if (!key) {
                continue;
            }
            var asset = (_a = {
                    id: supplyAsset.id || null,
                    required: supplyAsset.required || false,
                    version: supplyAsset.version || null
                },
                _a[key] = {},
                _a);
            switch (supplyAsset.typeDescription) {
                case IABAssets.title:
                    asset[key].len = supplyAsset.model;
                    break;
                case IABAssets.data:
                    asset[key].len = supplyAsset.model;
                    asset[key].type = supplyAsset.typeId;
                    break;
                case IABAssets.img:
                    asset[key].type = supplyAsset.typeId;
                    if (this.imageDimensionType === "exact" && prop.type === SupplyImageAssetType.Main) {
                        asset[key].width = supplyAsset.model.width;
                        asset[key].height = supplyAsset.model.height;
                    }
                    else {
                        asset[key].minWidth = supplyAsset.model.width;
                        // [TODO] Needs to be renamed
                        asset[key].minheight = supplyAsset.model.height;
                    }
                    break;
                default:
                    break;
            }
            if (supplyAsset.model) {
                assets.push(asset);
            }
        }
        return assets;
    };
    BlueprintAssetRestrictions.prototype.saveAssets = function () {
        if (this.blueprint.prefix === 'lin') {
            this.blueprint.assets = this.updateAssets();
            return this.supplyAssetService.saveAssets(this.blueprint);
        }
        return of(this.blueprint);
    };
    BlueprintAssetRestrictions.prototype.disableField = function (macro, modelKey) {
        if (this.availableMacros && this.availableMacros.length === 0) {
            return this.blueprint && this.supplyAssets[modelKey].id === null && this.blueprint.id != null;
        }
        return this.availableMacros && this.availableMacros.length > 0 && this.blueprint && this.blueprint.isCustom !== false && this.availableMacros.indexOf(macro) < 0;
    };
    BlueprintAssetRestrictions.prototype.isValid = function () {
        return this.blueprint.assets && this.blueprint.assets.some(function (asset) { return asset.required; });
    };
    return BlueprintAssetRestrictions;
}());
export { BlueprintAssetRestrictions };
