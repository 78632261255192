import {
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    Component,
    OnInit,
    ViewChild,
    ElementRef,
} from '@angular/core';

import { Router, NavigationStart } from '@angular/router';
import { Observable, ReplaySubject, of, fromEvent, combineLatest } from 'rxjs';

import { AuthorizationService } from 'app/core';
import { FilterString, Operation } from 'app/shared/elements/filters';
import { LIESRepository, SorobanRepository } from 'app/core/repositories';
import { PopoverComponent } from 'app/shared/elements/popover';
import { TableMode, TableURLService } from 'app/shared/elements/table';
import {
    debounceTime,
    switchMap,
    tap,
    map,
    distinctUntilChanged,
    take,
    flatMap,
} from 'rxjs/operators';
import { IdService } from 'app/core/id.service';
import { GAEventCategory, GAHelper } from 'app/shared/helpers/ga-helper';
import { getESTotal } from 'app/shared/helpers/es-helper';
import { Flag, LaunchDarklyService } from 'app/core/launch-darkly.service';

@Component({
    selector: 'global-search-input',
    templateUrl: './global-search-input.html',
    styleUrls: ['./global-search-input.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSearchInputComponent implements OnInit {
    @ViewChild('popover', { static: true }) private popover: PopoverComponent;
    @ViewChild('search', { static: true }) private searchElement: ElementRef;

    public useNewSearchExperience = false;

    query$ = new ReplaySubject<string>();

    results$ = combineLatest([
        this.query$.pipe(debounceTime(100)),
        this.launchDarklyService
            .getVariation(Flag.NewSearchExperienceInMaverick)
            .pipe(distinctUntilChanged()),
    ]).pipe(
        switchMap(([query, useNewSearchExperience]) => {
            this.useNewSearchExperience = useNewSearchExperience;
            if (query.length > 1) {
                if (this.useNewSearchExperience) {
                    return this.sorobanRepository.sorobanSearch(query);
                } else {
                    return this.liesRepository.globalsearch(query);
                }
            }

            return of(null);
        })
    );

    getESTotal = getESTotal;

    constructor(
        private tableURLService: TableURLService,
        private auth: AuthorizationService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private liesRepository: LIESRepository,
        private launchDarklyService: LaunchDarklyService,
        public id: IdService,
        private sorobanRepository: SorobanRepository
    ) {}

    ngOnInit() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.searchElement.nativeElement.blur();
            }
        });

        fromEvent(this.searchElement.nativeElement, 'input')
            .pipe(
                tap(() => this.cdr.markForCheck()),
                map(() => this.searchElement.nativeElement.value),
                distinctUntilChanged()
            )
            .subscribe((query) => this.query$.next(query));
    }

    enter() {
        GAHelper.trackEvent(
            GAEventCategory.GLOBAL_SEARCH_INTERACTION,
            `Global Search`,
            `Global Search Interaction : Enter`
        );
        this.router.navigate(['/search'], {
            queryParams: this.generateQueryParams(),
        });
    }

    focus() {
        this.popover.show({
            currentTarget: this.searchElement.nativeElement,
            stopPropagation: () => {},
        });
    }

    blur() {
        this.searchElement.nativeElement.value = '';
        this.popover.hide();
    }

    mousedown(event: MouseEvent) {
        // Allow for `select` to be called.
        event.preventDefault();
    }

    canViewInsertionOrders() {
        return this.auth.isInternalUser;
    }

    generateQueryParams() {
        const filter = new FilterString({
            field: 'name',
            operation: Operation.Contains,
            query: this.searchElement.nativeElement.value,
        });
        this.tableURLService.pushFilters('q', [filter]);
        this.tableURLService.pushMode('q', TableMode.Basic);

        return this.tableURLService.generate('q');
    }
}
