import { OnInit, EventEmitter } from '@angular/core';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import moment from 'moment';
import { switchMap, map, filter, delay } from 'rxjs/operators';
var CalendarComponent = /** @class */ (function () {
    function CalendarComponent() {
        var _this = this;
        this.date$ = new BehaviorSubject(null);
        this.month$ = new BehaviorSubject(moment());
        this.hover$ = new BehaviorSubject(null);
        this.view$ = this.month$.pipe(map(function (date) { return _this.generateView(moment(date).startOf('month').startOf('week')); }));
        this.context$ = combineLatest(this.month$, this.hover$.pipe(switchMap(function (date) { return _this.hoverDelay(date); })));
        this.dateSelected = new EventEmitter();
    }
    Object.defineProperty(CalendarComponent.prototype, "date", {
        get: function () {
            return this._date;
        },
        set: function (date) {
            this._date = date;
            this.snapToView(date);
        },
        enumerable: true,
        configurable: true
    });
    CalendarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.date$.pipe(filter(function (date) { return date !== null; })).subscribe(function (date) { return _this.dateSelected.emit(date); });
    };
    CalendarComponent.prototype.onMousedown = function (event) {
        event.preventDefault();
    };
    CalendarComponent.prototype.previousMonth = function () {
        this.setMonth(this.month$.getValue().clone().subtract(1, 'month'));
    };
    CalendarComponent.prototype.nextMonth = function () {
        this.setMonth(this.month$.getValue().clone().add(1, 'month'));
    };
    CalendarComponent.prototype.setMonth = function (date) {
        if (date && date.isValid()) {
            this.month$.next(date);
        }
    };
    CalendarComponent.prototype.clearHover = function () {
        this.hover$.next(null);
    };
    CalendarComponent.prototype.dateClasses = function (date, _a) {
        var month = _a[0], hover = _a[1];
        var day = date.day();
        var classes = {};
        classes['off-month'] = !month.isSame(date, 'month');
        classes['weekend'] = day === 0 || day === 6;
        if (this.date) {
            var same = date.isSame(this.date, 'day');
            classes['end'] = same;
            classes['end-selected'] = same;
        }
        if (this.from) {
            classes['start'] = date.isSame(this.from, 'day');
            if (this.date) {
                var range = date.isBetween(this.from, this.date, 'day', '[)');
                classes['range'] = range;
                classes['range-selected'] = range;
            }
            if (hover) {
                classes['range'] = date.isBetween(this.from, hover, 'day', '[)');
            }
        }
        if (hover) {
            classes['end'] = date.isSame(hover, 'day');
        }
        classes['disabled'] = this.isDisabled(date);
        return classes;
    };
    CalendarComponent.prototype.selectDate = function (date) {
        if (!this.isDisabled(date)) {
            this.date$.next(date);
        }
    };
    CalendarComponent.prototype.snapToView = function (date) {
        this.setMonth(date);
    };
    CalendarComponent.prototype.isDisabled = function (date) {
        if (this.minDate && date.isBefore(this.minDate)) {
            return true;
        }
        if (this.maxDate && date.isAfter(this.maxDate)) {
            return true;
        }
        return false;
    };
    CalendarComponent.prototype.generateView = function (date) {
        return Array.from(Array(42)).map(function (v, offset) { return date.clone().add(offset, 'days'); });
    };
    CalendarComponent.prototype.hoverDelay = function (date) {
        var date$ = of(date);
        return date ? date$ : date$.pipe(delay(100));
    };
    return CalendarComponent;
}());
export { CalendarComponent };
