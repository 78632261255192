import {
    Component,
    forwardRef,
    Attribute,
    Input,
    TemplateRef,
    ContentChild,
    EventEmitter,
    Output,
    ChangeDetectionStrategy,
    ViewChild,
    ElementRef,
    ChangeDetectorRef
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownComponent } from 'app/shared/elements/dropdown';
import { IdService } from 'app/core/id.service';

@Component({
    selector: 'select-item',
    templateUrl: './select-item.html',
    styleUrls: ['./select-item.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectItemComponent),
            multi: true
        }
    ]
})
export class SelectItemComponent implements ControlValueAccessor {
    @ContentChild(TemplateRef, { static: true }) template: TemplateRef<{ item: any, index: number }>;
    @ViewChild('control', { static: false }) control: ElementRef;
    @ViewChild(DropdownComponent, { static: false }) dropdown: DropdownComponent;
    @Input() items: any[];
    @Input() disabled: false;
    @Output('input-change') onInput = new EventEmitter<string>();
    item: any;
    hover: number;

    private onChange = obj => { };
    private onTouched = () => { };

    constructor(
        @Attribute('placeholder') public placeholder: string,
        private cdr: ChangeDetectorRef,
        public id: IdService
    ) { }

    writeValue(obj: any) {
        this.cdr.markForCheck();
        delete this.item;
        if (typeof obj === 'object') {
            this.item = obj;
        }
    }

    registerOnChange(fn: (obj: any) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }

    select(index: number) {
        this.item = this.items[index];
        this.onChange(this.item);
    }

    deselect() {
        if (!this.disabled) {
            delete this.item;
            this.onChange(null);
            window.setTimeout(() => {
                (<HTMLInputElement>this.control.nativeElement).focus();
            }, 0);
        }
    }

    input(value: string) {
        this.onInput.emit(value);
    }

    focus() {
        this.hover = -1;
    }

    blur(event: FocusEvent) {
        this.onTouched();
        this.dropdown.hide();
        delete this.hover;
    }

    enter() {
        if (typeof this.hover === 'number' && this.hover > -1) {
            this.select(this.hover);
        }
    }

    arrowup() {
        this.hover--;

        if (this.hover < 0) {
            this.hover = this.items.length - 1;
        }
    }

    arrowdown() {
        this.hover++;

        if (this.hover === this.items.length) {
            this.hover = 0;
        }
    }

    mouseenter(index: number) {
        this.hover = index;
    }

    mousedown(event: MouseEvent) {
        // Allow for `select` to be called.
        event.preventDefault();
    }
}
