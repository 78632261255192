export enum EventType {
    AddToCart,
    AppInstall,
    EmailSignUp,
    FormFill,
    Lead,
    ProductPurchase,
    Subscription,
    Custom
}
