import { Attribute, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment';

import { PopoverComponent } from 'app/shared/elements/popover';
import { DateTimeInterval } from './date-time-interval';

const URL_PARAM_DELIMETER = '|';

enum Preset {
    Today,
    Yesterday,
    LastSevenDays,
    LastThirtyDays
}

@Component({
    selector: 'date-interval-picker',
    templateUrl: './date-interval-picker.component.html',
    styleUrls: ['./date-interval-picker.component.styl']
})
export class DateIntervalPickerComponent implements OnInit, OnDestroy {
    @ViewChild(PopoverComponent, { static: true }) popover: PopoverComponent;
    @Output() confirmed = new EventEmitter<DateTimeInterval>();
    @Input('min-date') minDate: moment.Moment;
    @Input('max-date') maxDate: moment.Moment;

    start: moment.Moment;
    end: moment.Moment;
    error$ = new Subject<string>();
    readonly Preset = Preset;

    @Input('start') private initialStart: moment.Moment;
    @Input('end') private initialEnd: moment.Moment;
    private routeKey: string;
    private readonly readableFormat = 'MM/DD/YYYY';
    private readonly onDestroy$ = new Subject<void>();

    constructor(
        @Attribute('route-key') routeKey: string,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private location: Location,
        private route: ActivatedRoute
    ) {
        this.routeKey = routeKey;
    }

    ngOnInit() {
        this.route.queryParamMap.pipe(takeUntil(this.onDestroy$)).subscribe(paramMap => {
            if (this.routeKey && paramMap.has(this.routeKey)) {
                const [start, end] = paramMap
                    .get(this.routeKey)
                    .split(URL_PARAM_DELIMETER)
                    .map(date => moment(decodeURIComponent(date)));

                if (start.isValid() && end.isValid()) {
                    this.start = start;
                    this.end = end;
                    this.apply();
                }
            }
        })
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    get summary() {
        const format = 'MMM Do, YYYY';

        if (this.initialStart.isSame(moment().startOf('day')) && this.initialEnd.isSame(moment().endOf('day'))) {
            return 'Today';
        }

        if (this.initialStart.isSame(moment().subtract(1, 'day').startOf('day')) && this.initialEnd.isSame(moment().subtract(1, 'day').endOf('day'))) {
            return 'Yesterday';
        }

        if (this.initialStart.isSame(moment().subtract(7, 'day').startOf('day')) && this.initialEnd.isSame(moment().endOf('day'))) {
            return 'Last 7 Days';
        }

        if (this.initialStart.isSame(moment().subtract(30, 'day').startOf('day')) && this.initialEnd.isSame(moment().endOf('day'))) {
            return 'Last 30 Days';
        }

        if (this.initialStart.isSame(this.initialEnd, 'day')) {
            return this.initialStart.format(format);
        }

        return ''.concat(this.initialStart.format(format), ' - ', this.initialEnd.format(format));
    }

    setStart(str: string) {
        const date = this.toMoment(str);
        this.start = date ? date.startOf('day') : null;
    }

    setEnd(str: string) {
        const date = this.toMoment(str);
        this.end = date ? date.endOf('day') : null;
    }

    format(date: moment.Moment) {
        if (date) {
            return date.format(this.readableFormat);
        }
    }

    snapTo(preset: Preset) {
        const date = this.getDateRange(preset);
        this.start = date.start;
        this.end = date.end;
    }

    display(preset: Preset) {
        const date = this.getDateRange(preset);

        return this.minDate.isSameOrBefore(date.start)
            && this.maxDate.isSameOrAfter(date.end);
    }

    getDateRange(preset: Preset): DateTimeInterval {
        let start: moment.Moment;
        let end: moment.Moment;

        switch (preset) {
            case Preset.Today:
                start = moment().startOf('day');
                end = moment().endOf('day');
                break;
            case Preset.Yesterday:
                start = moment().subtract(1, 'day').startOf('day');
                end = moment().subtract(1, 'day').endOf('day');
                break;
            case Preset.LastSevenDays:
                start = moment().subtract(7, 'days').startOf('day');
                end = moment().endOf('day');
                break;
            case Preset.LastThirtyDays:
                start = moment().subtract(30, 'days').startOf('day');
                end = moment().endOf('day');
                break;
        }

        return {start, end};
    }

    cancel() {
        this.start = this.initialStart;
        this.end = this.initialEnd;
        this.reset();
    }

    apply() {
        if (!this.validate()) {
            return;
        }

        this.initialStart = this.start;
        this.initialEnd = this.end;
        this.confirmed.emit({ start: this.start, end: this.end });
        this.pushToURL(this.initialStart, this.initialEnd);
        this.reset();
    }

    private pushToURL(start: moment.Moment, end: moment.Moment) {
        if (!this.routeKey) {
            return;
        }

        const state = this.router.createUrlTree([], {
            relativeTo: this.route,
            queryParams: {
                [this.routeKey]: encodeURIComponent(start.toISOString()) + URL_PARAM_DELIMETER + encodeURIComponent(end.toISOString())
            },
            queryParamsHandling: 'merge'
        }).toString();

        this.location.replaceState(state);
    }

    private reset() {
        this.error$.next(null);
        this.popover.hide();
    }

    private validate() {
        if (!this.start || !this.end) {
            this.error$.next('Please include both start and end dates.');
            return false;
        }

        if (this.start.isAfter(this.end)) {
            this.error$.next('The start date must be before the end date.');
            return false;
        }

        if (this.minDate && this.start.isBefore(this.minDate)) {
            this.error$.next(`The start date must not be before ${this.minDate.format(this.readableFormat)}.`);
            return false;
        }

        if (this.maxDate && this.end.isAfter(this.maxDate)) {
            this.error$.next(`The end date must not be after ${this.maxDate.format(this.readableFormat)}.`);
            return false;
        }

        this.error$.next(null);
        return true;
    }

    private toMoment(dateString: string) {
        const date = moment(dateString, this.readableFormat);
        return date.isValid() ? date : null;
    }
}
