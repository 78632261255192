import { LightboxComponent } from 'app/shared/elements/lightbox';
import { AuthorizationService } from 'app/core';
import macros from 'app/core/valid-macros.json';
var AdDetailsMacroComponent = /** @class */ (function () {
    function AdDetailsMacroComponent(auth) {
        this.auth = auth;
    }
    AdDetailsMacroComponent.prototype.open = function () {
        this.macroInfoBox.open();
    };
    Object.defineProperty(AdDetailsMacroComponent.prototype, "validMacros", {
        get: function () {
            if (this.auth.isInternalUser) {
                return macros.allowedMacros;
            }
            else {
                return macros.externalUserMacros;
            }
        },
        enumerable: true,
        configurable: true
    });
    return AdDetailsMacroComponent;
}());
export { AdDetailsMacroComponent };
