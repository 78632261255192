import * as tslib_1 from "tslib";
import { Model } from './model';
var Bundle = /** @class */ (function (_super) {
    tslib_1.__extends(Bundle, _super);
    function Bundle() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Bundle.prototype.serialize = function () {
        return JSON.stringify(this);
    };
    Object.defineProperty(Bundle.prototype, "entity", {
        get: function () {
            return 'Bundle';
        },
        enumerable: true,
        configurable: true
    });
    return Bundle;
}(Model));
export { Bundle };
