import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { BackendRepository } from './backend-repository';
import { Catalog } from 'app/shared/models';
import { map } from 'rxjs/operators';
var CatalogRepository = /** @class */ (function (_super) {
    tslib_1.__extends(CatalogRepository, _super);
    function CatalogRepository(http) {
        return _super.call(this, http, '/dcs/catalog-setting', Catalog) || this;
    }
    CatalogRepository.prototype.get = function (id) {
        var _this = this;
        return this.http.get(this.url(this.path, id))
            .pipe(map(function (response) { return _this.build(response); }));
    };
    CatalogRepository.prototype.save = function (catalog) {
        var _this = this;
        var params = [this.path];
        if (catalog.id) {
            params.push(catalog.advertiserId);
        }
        return this.http.post(this.url.apply(this, params), catalog.serialize())
            .pipe(map(function (response) { return _this.build(response); }));
    };
    return CatalogRepository;
}(BackendRepository));
export { CatalogRepository };
