/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./global-navigation.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./global-navigation.component";
var styles_GlobalNavigationComponent = [i0.styles];
var RenderType_GlobalNavigationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GlobalNavigationComponent, data: {} });
export { RenderType_GlobalNavigationComponent as RenderType_GlobalNavigationComponent };
export function View_GlobalNavigationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "nav", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
export function View_GlobalNavigationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "global-navigation", [], null, null, null, View_GlobalNavigationComponent_0, RenderType_GlobalNavigationComponent)), i1.ɵdid(1, 49152, null, 0, i2.GlobalNavigationComponent, [], null, null)], null, null); }
var GlobalNavigationComponentNgFactory = i1.ɵccf("global-navigation", i2.GlobalNavigationComponent, View_GlobalNavigationComponent_Host_0, {}, {}, ["*"]);
export { GlobalNavigationComponentNgFactory as GlobalNavigationComponentNgFactory };
