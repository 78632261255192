import { Attribute, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { AdSlot } from 'app/shared/models';
import { HasDirtyCheck, Resettable } from 'app/shared/components/bulk-edit-lightbox';

@Component({
    selector: 'bulk-edit-ad-slot-exchange-floor',
    templateUrl: './bulk-edit-ad-slot-exchange-floor.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkEditAdSlotExchangeFloorComponent implements HasDirtyCheck, Resettable {
    @ViewChild('input', { static: true }) input: NgModel;
    private _adSlots: AdSlot[] = [];
    private _originals: AdSlot[] = [];
    label: string;
    entityName = '';
    field: string;

    constructor(@Attribute('label') label: string) {
        this.label = label;
    }

    master(value: number) {
        this.entities.forEach(adSlot => {
            if (adSlot.hasOwnProperty('sspControl')) {
                adSlot.sspControl.exchangeFloor = value;
            }
        });
    }

    original(index: number) {
        return this._originals[index];
    }

    reset() {
        this.input.reset();
        this.entities.forEach((adSlot, index) => {
            if (adSlot.hasOwnProperty('sspControl')) {
                adSlot.sspControl.exchangeFloor = this.original(index).sspControl.exchangeFloor;
            }
        });
    }

    isDirty() {
        return this.entities.some((adSlot, index) => {
            return adSlot.sspControl && adSlot.sspControl.exchangeFloor !== this.original(index).sspControl.exchangeFloor;
        });
    }

    set entities(adSlots: AdSlot[]) {
        this._adSlots = adSlots;
        this._originals = adSlots.map(adSlot => adSlot.clone());
    }

    get entities() {
        return this._adSlots;
    }
}
