import { OnInit, QueryList } from '@angular/core';
import { CategoryRepository } from "app/core/repositories";
import { SearchableSelectSingleComponent } from "app/shared/elements/searchable-select-single";
import { TagsInputComponent } from "app/shared/elements/tags-input";
var BulkEditIABCategoriesComponent = /** @class */ (function () {
    function BulkEditIABCategoriesComponent(label, multiple, categoryRepository) {
        this.categoryRepository = categoryRepository;
        this._entities = [];
        this._originals = [];
        this.entityName = '';
        this.categories = [];
        this.multiple = multiple !== null;
        this.label = label;
    }
    BulkEditIABCategoriesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.categoryRepository.searchAsOptions().subscribe(function (categories) { return _this.categories = categories; });
    };
    BulkEditIABCategoriesComponent.prototype.master = function (value) {
        var _this = this;
        this.entities.forEach(function (entity) { return entity[_this.field] = value; });
    };
    BulkEditIABCategoriesComponent.prototype.original = function (index) {
        return this._originals[index];
    };
    Object.defineProperty(BulkEditIABCategoriesComponent.prototype, "entities", {
        get: function () {
            return this._entities;
        },
        set: function (entities) {
            this._entities = entities;
            this._originals = entities.map(function (entity) { return entity.clone(); });
        },
        enumerable: true,
        configurable: true
    });
    BulkEditIABCategoriesComponent.prototype.reset = function () {
        var _this = this;
        if (this.isSingleSelectOption()) {
            this.singleSelect.resetDropDown();
        }
        if (this.isMultiSelectOption()) {
            this.multiSelect.removeAll();
        }
        this.entities.forEach(function (entity, index) { return entity[_this.field] = _this.original(index)[_this.field]; });
    };
    BulkEditIABCategoriesComponent.prototype.isSingleSelectOption = function () {
        return this.singleSelect !== undefined && !this.multiple;
    };
    BulkEditIABCategoriesComponent.prototype.isMultiSelectOption = function () {
        return this.multiSelect !== undefined && this.multiple;
    };
    return BulkEditIABCategoriesComponent;
}());
export { BulkEditIABCategoriesComponent };
