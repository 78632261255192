import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Pixel } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { catchError, map } from 'rxjs/operators';
import { empty } from 'rxjs';
var PixelRepository = /** @class */ (function (_super) {
    tslib_1.__extends(PixelRepository, _super);
    function PixelRepository(http) {
        return _super.call(this, http, '/pixel', Pixel) || this;
    }
    PixelRepository.prototype.searchAsOptions = function (query) {
        if (query === void 0) { query = {}; }
        return this.all(query).pipe(map(function (pixels) {
            return pixels.map(function (option) {
                return {
                    key: option.id,
                    value: option.id,
                    label: "(" + option.refId + "): " + option.name,
                    data: option
                };
            });
        }));
    };
    PixelRepository.prototype.basicSearchAsOptions = function (advertiser, tagType) {
        var _this = this;
        return this.consume(function (page) {
            var params = "p/" + page + "/n/1000/status/active/advertiser/" + advertiser;
            if (tagType) {
                params += '/tagType/' + tagType;
            }
            return _this.http.get(_this.url("/search/pixel?params=" + params));
        }).pipe(map(function (pixels) {
            return pixels.map(function (option) {
                return {
                    key: option.id,
                    value: option.id,
                    label: "(" + option.refId + "): " + option.name,
                    data: option
                };
            });
        }));
    };
    PixelRepository.prototype.eSearch = function (params) {
        return this.asyncSearch(params, '/lies/search/conversion-pixels')
            .pipe(catchError(function () { return empty(); }));
    };
    return PixelRepository;
}(BackendRepository));
export { PixelRepository };
