import { OnInit } from '@angular/core';
import { AsyncTableComponent } from './async-table.component';
var AsyncSortDirective = /** @class */ (function () {
    function AsyncSortDirective(table) {
        this.table = table;
        this.sort = null;
        this.default = false;
        this.desc = false;
    }
    AsyncSortDirective.prototype.ngOnInit = function () {
        if (this.default !== false) {
            this.table.orderBy = this.sort;
        }
        if (this.desc !== false) {
            this.table.direction = 'DESC';
        }
    };
    Object.defineProperty(AsyncSortDirective.prototype, "active", {
        get: function () {
            return this.table.orderBy === this.sort;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AsyncSortDirective.prototype, "ascending", {
        get: function () {
            return this.table.direction === 'ASC';
        },
        enumerable: true,
        configurable: true
    });
    AsyncSortDirective.prototype.click = function () {
        if (this.sort !== null) {
            if (this.sort === this.table.orderBy) {
                this.table.direction = this.table.direction === 'ASC' ? 'DESC' : 'ASC';
            }
            else {
                this.table.orderBy = this.sort;
            }
        }
    };
    return AsyncSortDirective;
}());
export { AsyncSortDirective };
