<lightboxnext header="Modify columns" class="abs small">

        <div class="body">
            <div class="padding">
                <div class="row">
                    <div class="column--6 column--rpad">
                        <h4 class="strong">Available Columns</h4>
                        <hr class="space">
                        <app-draggable-list
                            icon="fa fa-plus ng-star-inserted fa-fw action"
                            [(ngModel)]="available" (onRemove)="addToActive($event)">
                        </app-draggable-list>
                    </div>
                    <div class="column--6 column--lpad">
                        <div class="row">
                            <div class="column--4">
                                <h4 class="strong"> <a>{{activeCount}}</a> Columns Selected  </h4>
                            </div>
                            <div class="column--5">
                                <span> Drag and drop to reorder</span>
                            </div>
                        </div>
                        <hr class="space">
                        <app-draggable-list
                            section="lock"
                            drag="lock"
                            icon="fa fa-lock ng-star-inserted fa-fw lock"
                            [(ngModel)]="lockedColumns">
                        </app-draggable-list >
                        <app-draggable-list
                            drag="dragable"
                            icon="fa fa-times ng-star-inserted fa-fw action"
                            [(ngModel)]="active" (onRemove)="removeFromActive($event)">
                        </app-draggable-list >
                    </div>
                </div>
            </div>
        </div>
        <footer>
            <div class="align-right">
                <button type="button" (click)="selectColumns(active)">Done</button>
            </div>
        </footer>

</lightboxnext>
