import { ChangeDetectorRef, EventEmitter, OnInit, QueryList } from '@angular/core';
import { BulkEditGroupComponent } from './bulk-edit-group';
import { LightboxComponent } from 'app/shared/elements/lightbox';
import { IdService } from 'app/core/id.service';
var BulkEditLightboxComponent = /** @class */ (function () {
    function BulkEditLightboxComponent(entity, cdr, id) {
        this.cdr = cdr;
        this.id = id;
        this._submit = new EventEmitter();
        this.entities = [];
        this.progress = null;
        this.action = 'save';
        this.customLoadingMessage = null;
        this.originals = [];
        this.entityName = entity;
    }
    BulkEditLightboxComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.lightbox.closedState.subscribe(function () {
            _this.progress = null;
            _this.children.forEach(function (child) { return child.reset(); });
        });
    };
    BulkEditLightboxComponent.prototype.open = function (entities, field, action) {
        if (field === void 0) { field = null; }
        if (action === void 0) { action = 'save'; }
        this.action = action;
        var toActivate = this.children[0];
        if (field !== null && Array.isArray(entities)) {
            toActivate = this.children.find(function (child) { return child.field === field; });
        }
        this.originals = entities;
        this.entities = entities.map(function (entity) { return entity.clone(); });
        this.activate(toActivate, true);
        this.lightbox.open();
    };
    BulkEditLightboxComponent.prototype.close = function () {
        this.lightbox.close();
    };
    BulkEditLightboxComponent.prototype.activate = function (child, doReset) {
        var _this = this;
        if (doReset === void 0) { doReset = false; }
        var index = this.children.indexOf(child);
        this.children.forEach(function (c, i) {
            if (doReset) {
                c.reset();
            }
            if (index === i) {
                c.activate(_this.entities, _this.entityName);
                _this.label = c.label;
            }
            else {
                c.deactivate();
            }
        });
    };
    BulkEditLightboxComponent.prototype.submit = function () {
        this._submit.emit({
            changed: this.entities,
            originals: this.originals,
            label: this.label
        });
    };
    BulkEditLightboxComponent.prototype.initiate = function () {
        this.progress = 0;
    };
    BulkEditLightboxComponent.prototype.postProgress = function () {
        this.progress++;
        this.cdr.markForCheck();
    };
    Object.defineProperty(BulkEditLightboxComponent.prototype, "isLoading", {
        get: function () {
            return Array.isArray(this.entities) && this.progress !== null
                && this.entities.length !== this.progress;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BulkEditLightboxComponent.prototype, "activeChild", {
        get: function () {
            return this.children.filter(function (child) { return child.isActive; })[0];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BulkEditLightboxComponent.prototype, "children", {
        get: function () {
            return this.groups.reduce(function (acc, cur) {
                acc = acc.concat(cur.children.toArray());
                return acc;
            }, []);
        },
        enumerable: true,
        configurable: true
    });
    BulkEditLightboxComponent.prototype.resetForm = function () {
        var _this = this;
        this.progress = null;
        this.children.forEach(function (child) {
            child.reset();
            child.resetOriginals(_this.originals);
        });
    };
    return BulkEditLightboxComponent;
}());
export { BulkEditLightboxComponent };
