import { Routes } from '@angular/router';
import { FatalErrorComponent } from 'app/shared/components';
var ɵ0 = function () { return import("./login/login.module.ngfactory").then(function (mod) { return mod.LoginModuleNgFactory; }); };
var routes = [
    {
        path: 'fatal-error',
        component: FatalErrorComponent
    },
    {
        path: '',
        loadChildren: ɵ0
    },
    {
        path: '**',
        redirectTo: '/campaign-manager'
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0 };
