import { ElementRef, Directive } from '@angular/core';

@Directive({
    selector: 'textarea[autosize]'
})
export class AutosizeTextareaDirective {
    private previousValue;

    constructor (public element: ElementRef) {
    }

    ngOnInit (): void {
        this.element.nativeElement.rows = 1;
    }

    ngDoCheck(): void {
        if (this.previousValue !== this.element.nativeElement.value) {
            this.adjust();
            this.previousValue = this.element.nativeElement.value;
        }
    }

    adjust (): void {
        this.element.nativeElement.style.overflow = 'hidden';
        this.element.nativeElement.style.height = 'auto';
        this.element.nativeElement.style.height = this.element.nativeElement.scrollHeight + 'px';
    }
}
