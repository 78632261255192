import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Renderer2
} from '@angular/core';
import { ButtonComponent } from '../button';

@Component({
    selector: 'dropdown',
    templateUrl: './dropdown.html',
    styleUrls: ['./dropdown.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent implements OnInit {
    private listeners: Array<Function> = [];

    public shown = false;
    @Input() public trigger: Element;
    @Output() visibility = new EventEmitter<boolean>();

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private cdr: ChangeDetectorRef
    ) {}

    private appendToBody(el): void {
        el.style.position = 'absolute';
        el.parentElement.removeChild(el);
        document.body.appendChild(el);
    }

    private positionElem(el, btn): void {
        // document.body.scrollTop - Chrome; document.documentElement.scrollTop - Firefox
        const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        const triggerOffsets = btn.getBoundingClientRect();
        const triggerHeight = btn.clientHeight;

        el.style.top = scrollTop + triggerOffsets.top + triggerHeight + 'px';
        el.style.left = triggerOffsets.left + 'px';
    }

    private isInsideDatatableOrAdvertisers(el) {
        if (!el.parentElement) {
            return false;
        }

        const tagName = el.parentElement.tagName.toLocaleLowerCase();

        if (tagName === 'data-table' || tagName === 'advertisers') {
            return true;
        }

        return this.isInsideDatatableOrAdvertisers(el.parentElement);
    }

    private getScrollYParentElems(el): Array<HTMLElement> {
        let scrollYElems: Array<HTMLElement> = [];

        function getAllElems(el) {
            if (!el.parentElement) {
                return;
            }

            const styles = getComputedStyle(el.parentElement);

            if (styles.overflowY === 'hidden' || styles.overflowY === 'scroll') {
                scrollYElems.push(el.parentElement);
            }

            getAllElems(el.parentElement);
        }

        getAllElems(el);
        return scrollYElems;
    }

    private parentsScrollHandler(): void {
        this.hide();
    }

    private bindScrollHandlers() {
        const parentsScrollHandler = this.parentsScrollHandler.bind(this);

        this.getScrollYParentElems(this.trigger)
            .forEach(elem => this.listeners.push(this.renderer.listen(elem, 'scroll', parentsScrollHandler)));
    }

    private unbindScrollHandlers() {
        this.listeners.forEach(func => func());
    }

    public setWidth(width: number): void {
        this.el.nativeElement.querySelector('.dropdown').style.width = `${width}px`;
    }

    ngOnDestroy() {
        this.el.nativeElement.remove();
    }

    ngOnInit() {
        let trigger: any = this.trigger;

        if (trigger instanceof ButtonComponent) {
            trigger = trigger.el.nativeElement;
            trigger.addEventListener('click', _ => this.toggle());
        } else if (trigger instanceof HTMLInputElement) {
            trigger.addEventListener('focus', _ => this.show());
        }

        this.trigger = trigger;

        if (this.trigger && this.isInsideDatatableOrAdvertisers(trigger)) {
            this.appendToBody(this.el.nativeElement);
        }
    }

    toggle() {
        return (this.shown ? this.hide : this.show).call(this);
    }

    show() {
        const nativeEl = this.el.nativeElement;
        const button = this.trigger;

        if (button && this.isInsideDatatableOrAdvertisers(this.trigger)) {
            this.bindScrollHandlers();
            this.positionElem(nativeEl, button);
        }

        this.visibility.emit(true);
        this.shown = true;
        this.cdr.markForCheck();
    }

    hide() {
        if (this.isInsideDatatableOrAdvertisers(this.trigger)) {
            this.unbindScrollHandlers();
        }

        this.visibility.emit(false);
        this.shown = false;
        this.cdr.markForCheck();
    }
}
