import { Component, Input, OnInit } from '@angular/core';
import { Campaign, LineItem } from 'app/shared/models';
import moment from 'moment';
import { IdService } from 'app/core/id.service';

@Component({
    selector: 'pacing-card',
    templateUrl: './pacing-card.html'
})
export class PacingCardComponent implements OnInit {
    @Input() lineItem: LineItem;
    @Input() index: number;
    private _campaign: Campaign = null;

    startDate: string;
    endDate: string;

    ngOnInit() {
        this.startDate = this.lineItem.startDate || this.lineItem.effectiveStartDate;
        this.endDate = this.lineItem.endDate || this.lineItem.effectiveEndDate;
    }

    @Input() set campaign(campaign: Campaign) {
        if (campaign !== null) {
            this._campaign = campaign;
        }
    }

    constructor(
        public id: IdService
    ) { }

    get campaign() {
        return this._campaign;
    }

    get live() {
        const now = moment();
        const start = moment(this.startDate);
        return now.diff(start, 'days');
    }

    get total() {
        const start = moment(this.startDate);
        const end = moment(this.endDate);
        let total = end.diff(start, 'days') - 1;
        if (total === 0) {
            total = 1;
        }

        return total;
    }

    get aggregate() {
        const now = moment();
        const start = moment(this.startDate);
        const end = moment(this.endDate);
        // Unsure on why we have to add an extra day of total time.
        let spend = this.lineItem.spend;
        if (this.lineItem.hasAdjustedImpressionsBudget) {
            spend = this.lineItem.adjustedImpressions;
        } else if (this.lineItem.hasImpressionsBudget) {
            spend = this.lineItem.impressions;
        }
        return end.isAfter(now) &&
            start.isBefore(now, 'day') ? (spend / this.live) * (this.total + 1) : this.spend;
    }

    get expected() {
        const now = moment();
        const start = moment(this.startDate);
        const end = moment(this.endDate);

        if (now.isAfter(end) || now.isSameOrBefore(start)) {
            return this.lineItem.budget;
        }

        return (this.budget / this.total) * this.live;
    }

    get budget() {
        return this.lineItem.budget;
    }

    get threshold() {
        return this.campaign.goalThreshold;
    }

    get spend() {
        if (this.lineItem.hasImpressionsBudget) {
            return this.lineItem.impressions;
        }
        if (this.lineItem.hasAdjustedImpressionsBudget) {
            return this.lineItem.adjustedImpressions;
        }
        return this.lineItem.spend;
    }

    get start() {
        return new Date(this.startDate);
    }

    get end() {
        return new Date(this.endDate);
    }
}
