import { OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, of, combineLatest } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AdSlotBulkEditComponent } from 'app/shared/components/ad-slot-bulk-edit';
import { AdSlotRepository, LineItemRepository } from 'app/core/repositories';
import { AuthorizationService, BulkResponseProcessor, NotificationsService } from 'app/core';
import { ConfirmDialogComponent } from 'app/shared/elements/confirm-dialog';
import { HistoryViewComponent } from 'app/shared/components/history-view';
import { IdService } from 'app/core/id.service';
import { InventoryDataService } from 'app/shared/services/inventory-data.service';
import { Newsletter } from 'app/shared/models/newsletter';
import { NewsletterFormComponent } from 'app/shared/components/newsletter-form';
import { NewsletterTagsComponent } from 'app/shared/components/newsletter-tags';
import { PaginatorComponent } from 'app/shared/elements/paginator';
import { TableComponent } from 'app/shared/elements/table';
import { TableHelper } from 'app/shared/helpers/table-helper';
import warnings from 'app/core/warnings.json';
var AdSlotAction;
(function (AdSlotAction) {
    AdSlotAction[AdSlotAction["Edit"] = 0] = "Edit";
    AdSlotAction[AdSlotAction["ViewCode"] = 1] = "ViewCode";
    AdSlotAction[AdSlotAction["EditName"] = 2] = "EditName";
    AdSlotAction[AdSlotAction["EditSize"] = 3] = "EditSize";
    AdSlotAction[AdSlotAction["Archive"] = 4] = "Archive";
    AdSlotAction[AdSlotAction["Unarchive"] = 5] = "Unarchive";
    AdSlotAction[AdSlotAction["ViewHistory"] = 6] = "ViewHistory";
    AdSlotAction[AdSlotAction["EditDemandSetting"] = 7] = "EditDemandSetting";
    AdSlotAction[AdSlotAction["EditExchangeFloor"] = 8] = "EditExchangeFloor";
})(AdSlotAction || (AdSlotAction = {}));
var AdSlotsTableComponent = /** @class */ (function () {
    function AdSlotsTableComponent(adSlotRepository, inventoryData, notifications, lineItemRepository, router, id, authorizationService) {
        var _this = this;
        this.adSlotRepository = adSlotRepository;
        this.inventoryData = inventoryData;
        this.notifications = notifications;
        this.lineItemRepository = lineItemRepository;
        this.router = router;
        this.id = id;
        this.authorizationService = authorizationService;
        this.AdSlotAction = AdSlotAction;
        this.helper = new TableHelper(function (params) { return _this.adapter(params); });
        this.warnings = warnings;
        this.defaultColumns = [
            'name',
            'status',
            'type',
            'linkedLineItems',
            'linkedBlueprints',
            'size',
            'collapsible',
            'displayStatus',
            'nativeStatus',
            'exchange',
            'exchangeFloorEffective',
            'rtb',
            'impressions',
            'ecpm',
            'revenue'
        ];
    }
    AdSlotsTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.helper.table = this.table;
        this.helper.paginator = this.paginator;
        combineLatest(this.table.query, this.paginator.query).subscribe(function (args) { return _this.helper.search(args); });
    };
    Object.defineProperty(AdSlotsTableComponent.prototype, "setMediaGroup", {
        set: function (id) {
            this.mediaGroupId = id;
            this.refresh();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdSlotsTableComponent.prototype, "setNewsletter", {
        set: function (id) {
            this.newsletterId = id;
            this.refresh();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdSlotsTableComponent.prototype, "setDefaultColumns", {
        set: function (columns) {
            this.defaultColumns = columns;
            this.refresh();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdSlotsTableComponent.prototype, "setLineItem", {
        set: function (id) {
            this.lineItemId = id;
            this.refresh();
        },
        enumerable: true,
        configurable: true
    });
    AdSlotsTableComponent.prototype.refresh = function () {
        this.paginator.reset();
        this.table.clearSelections();
        this.lineItemRepository.clearCache();
    };
    AdSlotsTableComponent.prototype.adapter = function (params) {
        if (typeof this.mediaGroupId === 'string') {
            params.conditions.push({ field: 'mediaGroup', value: this.mediaGroupId });
        }
        if (typeof this.newsletterId === 'string') {
            params.conditions.push({ field: 'newsletter', value: this.newsletterId });
        }
        if (typeof this.lineItemId === 'string') {
            return this.adSlotRepository.searchByLineItem(this.lineItemId, params);
        }
        else {
            params.return = ['newsletterName', 'newsletterRefId', 'publisherRefId', 'sizes',
                'isDisplay', 'isNative', 'isHybrid', 'currentTagVersion', 'nativeBlueprints', 'status'];
            params.returnMode = 'appended';
            return this.adSlotRepository.asyncSearch(params);
        }
    };
    AdSlotsTableComponent.prototype.handle = function (action, adSlots) {
        switch (action) {
            case AdSlotAction.Edit:
                this.edit(adSlots);
                break;
            case AdSlotAction.ViewCode:
                this.viewCode(adSlots);
                break;
            case AdSlotAction.EditName:
                this.bulkEditor.open(adSlots, 'name');
                break;
            case AdSlotAction.EditSize:
                this.bulkEditor.open(adSlots, 'sizes');
                break;
            case AdSlotAction.ViewHistory:
                this.viewHistory(adSlots);
                break;
            case AdSlotAction.Archive:
                this.confirmArchive();
                break;
            case AdSlotAction.Unarchive:
                this.confirmUnarchive();
                break;
            case AdSlotAction.EditDemandSetting:
                this.bulkEditor.open(adSlots, 'sspControl.exchangeAllow');
                break;
            case AdSlotAction.EditExchangeFloor:
                this.bulkEditor.open(adSlots, 'sspControl.exchangeFloor');
                break;
        }
    };
    AdSlotsTableComponent.prototype.archive = function (adSlots) {
        var _this = this;
        this.archiveDialog.initiate(adSlots.length);
        var requests = adSlots.map(function (adSlot) {
            return _this.adSlotRepository.archive(adSlot.id)
                .pipe(tap(function () { return _this.archiveDialog.postProgress(); }), catchError(function (e) { return of(e); }));
        });
        forkJoin(requests).subscribe(function (responses) { return _this.handleArchived(adSlots, responses); }, function () { return _this.handleGenericError(); });
    };
    AdSlotsTableComponent.prototype.unarchive = function (adSlots) {
        var _this = this;
        this.unarchiveDialog.initiate(adSlots.length);
        var requests = adSlots.map(function (adSlot) {
            return _this.adSlotRepository.unarchive(adSlot)
                .pipe(tap(function () { return _this.unarchiveDialog.postProgress(); }), catchError(function (e) { return of(e); }));
        });
        forkJoin(requests).subscribe(function (responses) { return _this.handleUnarchived(adSlots, responses); }, function () { return _this.handleGenericError(); });
    };
    AdSlotsTableComponent.prototype.edit = function (adSlots) {
        if (adSlots.length > 1) {
            this.notifications.error('Please select a single ad slot to edit at a time.');
            return;
        }
        this.router.navigate(['inventory-manager/ad-slots/' + adSlots[0].refId + '/edit', {
                redirect: btoa(this.router.url)
            }]);
    };
    AdSlotsTableComponent.prototype.viewCode = function (adSlots) {
        if (adSlots.length > 1) {
            this.notifications.error('Please select a single ad slot to view code for at a time.');
            return;
        }
        var newsletter = new Newsletter({ id: adSlots[0].newsletter });
        this.newsletterTags.openModal(newsletter, adSlots[0].id);
    };
    AdSlotsTableComponent.prototype.viewHistory = function (adSlots) {
        if (adSlots.length > 1) {
            this.notifications.error('Please select a single ad slot to view history for at a time.');
            return;
        }
        this.historyView.viewAdSlotHistory(adSlots[0]);
    };
    AdSlotsTableComponent.prototype.confirmArchive = function () {
        this.archiveDialog.open('Archive');
    };
    AdSlotsTableComponent.prototype.confirmUnarchive = function () {
        this.unarchiveDialog.open('Unarchive');
    };
    AdSlotsTableComponent.prototype.handleArchived = function (attempted, responses) {
        var _a = (new BulkResponseProcessor()).processDeletes(attempted, responses), status = _a.status, failureItems = _a.failureItems, successItems = _a.successItems;
        if (status === BulkResponseProcessor.AllOK) {
            this.notifications.success(successItems.length + " ad slot" + (successItems.length === 1 ? ' was' : 's were') + " successfully archived.");
            this.archiveDialog.close();
            this.refresh();
            return;
        }
        if (status === BulkResponseProcessor.AllFailed) {
            this.notifications.error(failureItems.length + " ad slot" + (failureItems.length === 1 ? ' was' : 's were') + " not archived due to the following errors:\n                <ul>" + failureItems.join('') + "</ul>", '', 0);
            this.archiveDialog.close();
            return;
        }
        this.notifications.warning("The following ad slots were successfully archived:\n             <ul>" + successItems.join('') + "</ul>\n             <br>\n             However, the following ad slots were not archived:\n            <ul>" + failureItems.join('') + "</ul>", '', 0);
        this.archiveDialog.close();
        this.refresh();
    };
    AdSlotsTableComponent.prototype.handleUnarchived = function (attempted, responses) {
        var _a = (new BulkResponseProcessor()).processDeletes(attempted, responses), status = _a.status, failureItems = _a.failureItems, successItems = _a.successItems;
        if (status === BulkResponseProcessor.AllOK) {
            this.notifications.success(successItems.length + " ad slot" + (successItems.length === 1 ? ' was' : 's were') + " successfully unarchived.");
            this.unarchiveDialog.close();
            this.refresh();
            return;
        }
        if (status === BulkResponseProcessor.AllFailed) {
            this.notifications.error(failureItems.length + " ad slot" + (failureItems.length === 1 ? ' was' : 's were') + " not unarchived due to the following errors:\n                <ul>" + failureItems.join('') + "</ul>", '', 0);
            this.unarchiveDialog.close();
            return;
        }
        this.notifications.warning("The following ad slots were successfully unarchived:\n             <ul>" + successItems.join('') + "</ul>\n             <br>\n             However, the following ad slots were not unarchived:\n            <ul>" + failureItems.join('') + "</ul>", '', 0);
        this.unarchiveDialog.close();
        this.refresh();
    };
    AdSlotsTableComponent.prototype.handleGenericError = function () {
        this.notifications.error('An error has occurred.');
    };
    AdSlotsTableComponent.prototype.getLinkedLineItems = function (id) {
        return this.lineItemRepository.getByAdSlot(id);
    };
    AdSlotsTableComponent.prototype.canModify = function (adSlots) {
        var _this = this;
        return adSlots.every(function (adSlot) { return _this.authorizationService.canModifyMonetizationPublisher(adSlot.publisherClientTypes); });
    };
    return AdSlotsTableComponent;
}());
export { AdSlotsTableComponent };
