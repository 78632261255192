import { Injectable } from '@angular/core';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Audience, HistoryResponse, LineItem } from 'app/shared/models';
import { LineItemRepository, LIESRepository, UMLAUTRepository, HistoryEntity } from 'app/core/repositories';

@Injectable()
export class AudienceService {
    private readonly linkedLineItemsCache = new Map<number, Subject<LineItem[]>>();

    constructor(
        private liesRepository: LIESRepository,
        private umlautRepository: UMLAUTRepository,
        private lineItemRepository: LineItemRepository
    ) { }

    /**
     * Get the line items that are linked to the audience.
     */
    getLinkedLineItems(refId: number) {
        let subject = this.linkedLineItemsCache.get(refId);

        if (!subject) {
            subject = new ReplaySubject(1);
            this.lineItemRepository
                .searchByAudience(refId)
                .subscribe(lineItems => subject.next(lineItems));
            this.linkedLineItemsCache.set(refId, subject);
        }

        return subject;
    }

    /**
     * Fetch all the files that comprise the audience.
     */
    fetchAllFiles(audience: Audience) {
        return this.umlautRepository.asyncSearch({
            conditions: [
                { field: 'segmentId', value: audience.refId }
            ]
        });
    }

    /**
     * Get all the previously used replacement ids, sorted by date used desc, for an audience.
     */
    previousReplacementIds(audience: Audience): Observable<number[]> {
        return this.liesRepository.history(audience.id, HistoryEntity.Audience).pipe(
            map(historyResponse => {
                return (historyResponse as HistoryResponse).items.map(revision => revision.findChange('parentId'))
                    .map(change => change ? change.value : null)
                    .filter(value => !!value);
            })
        );
    }
}
