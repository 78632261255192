import { ChangeDetectorRef, EventEmitter, OnInit, QueryList } from '@angular/core';
import { DialogComponent } from '../dialog';
import { DialogConfirmationComponent } from './dialog-confirmation.component';
import { IdService } from 'app/core/id.service';
var ConfirmDialogComponent = /** @class */ (function () {
    function ConfirmDialogComponent(cdr, id) {
        this.cdr = cdr;
        this.id = id;
        this.dialog = null;
        this.header = null;
        this.confirmText = 'No, Cancel';
        this.cancelText = 'Yes, Confirm';
        this.confirmed = new EventEmitter();
        this.canceled = new EventEmitter();
        this.action = null;
        this.progress = null;
        this.total = null;
        this.valid = true;
    }
    ConfirmDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dialog.closed.subscribe(function () { return _this.reset(); });
    };
    ConfirmDialogComponent.prototype.open = function (action) {
        this.action = action;
        this.dialog.open();
    };
    ConfirmDialogComponent.prototype.close = function () {
        this.dialog.close();
        this.progress = null;
        this.total = null;
        this.canceled.emit(true);
    };
    ConfirmDialogComponent.prototype.confirm = function () {
        if (this.isConfirmed) {
            if (!this.action) {
                this.dialog.close();
            }
            this.confirmed.emit(true);
        }
    };
    ConfirmDialogComponent.prototype.initiate = function (total) {
        this.progress = 0;
        this.total = total;
    };
    ConfirmDialogComponent.prototype.postProgress = function () {
        this.progress++;
        this.cdr.detectChanges();
    };
    Object.defineProperty(ConfirmDialogComponent.prototype, "isLoading", {
        get: function () {
            return this.total !== null && this.progress !== null
                && this.total !== this.progress;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfirmDialogComponent.prototype, "isConfirmed", {
        get: function () {
            this.confirmations.forEach(function (confirmation) { return confirmation.check(); });
            return this.valid = this.confirmations.toArray().every(function (confirmation) { return confirmation.confirmed; });
        },
        enumerable: true,
        configurable: true
    });
    ConfirmDialogComponent.prototype.reset = function () {
        this.valid = true;
        this.confirmations.forEach(function (confirmation) { return confirmation.reset(); });
    };
    return ConfirmDialogComponent;
}());
export { ConfirmDialogComponent };
