import backendErrors from './back-end-errors.json';
import reportingErrors from './reporting-errors.json';
import { HttpErrorResponse } from '@angular/common/http';

const GENERIC_MESSAGE = 'An error has occurred.';

export class ErrorHelper {

    //key value pair
    validations: any[] = [];
    _exclamation: string;
    apiErrors: any[] = [];

    public static getMessage(code?: string) {
        return backendErrors[code] || GENERIC_MESSAGE;
    }

    public get errors() {
        let errorList: any[] = [];

        for (let key in this.validations) {
            if (Array.isArray(this.validations[key]) === true) {
                let fields = this.getError(key);
                fields = fields.filter(function (item, pos) {
                    return fields.indexOf(item) === pos;
                });
                const the = key == 'incompatible' ? '' : 'the ';
                if (fields.length === 1) {
                    // Special rule for 'incompatible' error since each error always contains two entities in one field
                    const predicate = key == 'incompatible' ? ' are ' : ' is ';
                    errorList.push(the + fields.join() + predicate + key + '.');
                } else {
                    let x = fields.join(', ');
                    let pos = x.lastIndexOf(', ');
                    x = x.substring(0, pos) + ' and ' + x.substring(pos + 1);
                    errorList.push(the + x + ' are ' + key + '.');
                }
            } else {
                errorList.push(this.validations[key]);
            }
        }
        return errorList;
    }

    public get size(): number {
        return Object.keys(this.validations).length;
    }

    public get hasErrors(): boolean {
        return this.size > 0;
    }

    public setError(key, value): void {
        this.validations[key] = value;
    }

    public getError(key): any {
        if (this.validations[key] !== undefined) {
            return this.validations[key];
        }
    }

    public resetError(key): void {
        if (this.validations[key] !== undefined) {
            delete this.validations[key];
        }
    }

    public resetAllErrors() {
        for (let key in this.validations) {
            delete this.validations[key];
        }
    }

    public didError(key): boolean {
        return this.validations[key] !== undefined;
    }

    get exclamation(): string {
        return this._exclamation || 'Uh oh!';
    }

    set exclamation(str: string) {
        this._exclamation = str;
    }

    public loadBackEndErrors(errors) {
        if (errors instanceof HttpErrorResponse) {
            errors = errors.error.errors;
        }

        if (!Array.isArray(errors)) {
            this.setError('genericError', GENERIC_MESSAGE);
            return;
        }
        let invalidFields = this.getInvalidFields(errors);
        if (invalidFields.length > 0) {
            let error: any[] = this.getError('invalid') ? this.getError('invalid') : [];
            this.setError('invalid', invalidFields.concat(error));
        }

        let requiredFields = this.getRequiredFields(errors);
        if (requiredFields.length > 0) {
            let error: any[] = this.getError('required') ? this.getError('required') : [];
            this.setError('required', requiredFields.concat(error));
        }

        let incompatibleFields = this.getIncompatibleFields(errors);
        if (incompatibleFields.length > 0) {
            let error: any[] = this.getError('incompatible') ? this.getError('incompatible') : [];
            this.setError('incompatible', incompatibleFields.concat(error));
        }

        let customErrors = this.getCustomErrors(errors);
        customErrors.forEach(error => {
            this.setError(error.id, backendErrors[error.id]);
        });

        let missingErrors = errors.filter(error => backendErrors[error.id] === undefined);
        if (missingErrors.length > 0 && this.size === 0) {
            this.setError('genericError', GENERIC_MESSAGE);
        }
    }

    /**
    * Some LSD error responses do not fit the pattern that is expected by the methods in this helper
    * Therefore, it is sometimes necessary to set a generic error to not break the template
    **/
    public setGenericError() {
        this.setError('genericError', GENERIC_MESSAGE);
    }

    /**
     * Expected Reporting Error Object Format
     * This is Wolfman's JOI Error Format
     * {
            "statusCode": ,
            "error": "",
            "message": "",
            "validation": {
                "source": "",
                "keys": [
                ""
                ]
            }
        }
     */
    public loadReportingError(error, status) {
        if (status === -1) {
            /**
             * In case if wolfman service is down or it takes more than 60 seconds to process a query
             * The ELB for merlin times out request after 60 seconds. In that case there is no response from the server
             * But ELB returns a 504 Gateway error and angular in this case returns status -1
             */
            this.setError('Generic Error', reportingErrors['Generic Error']);
        }
        /**
        * TODO: Once Wolfman starts sending standard error response object for 500K rows error
        * This code needs to be changed to:
        * remove this else if block and replace as follows
        * if reportingErrors[error.error] exists then setError(error.error, reportingErrors[error.error])
        */
        else if (error && error.responsePayload !== undefined) {
            if (error.responsePayload.error === 'Resource limit exceeded') {
                this.setError('Resource Limit Exceeded', reportingErrors['Resource Limit Exceeded']);
            } else {
                this.setError('Generic Error', reportingErrors['Generic Error']);
            }
        } else if (error && error.message) {
            if (error.message.includes('destination')) {
                this.setError('Destination error', reportingErrors['Destination Error']);
            } else {
                this.setError('Generic Error', reportingErrors['Generic Error']);
            }
        } else {
            this.setError('Generic Error', reportingErrors['Generic Error']);
        }
    }

    public getInvalidFields(errors): any[] {
        return errors.filter(error => error.code === 'INV' && error.id === 'E1000')
            .map(error => error.details.split(/(?=[A-Z])/).join(' ').toLowerCase());
    }

    public getRequiredFields(errors) {
        return errors.filter(error => error.code === 'REQ' && error.id === 'E1001')
            .map(error => error.details.split(/(?=[A-Z])/).join(' ').toLowerCase());
    }

    public getIncompatibleFields(errors) {
        return errors.filter(error => error.code === 'INC')
            .map(error => error.details.split(/(?=[A-Z])/).join(' ').toLowerCase());
    }

    public getCustomErrors(errors): any[] {
        return errors.filter(error => {
            // If LSD sent extra context for the error we take that as the value of the error instead of what's in
            // backendErrors for that error id. When trouble shooting confirm the error id exists in backendErrors
            // and then check LSD to see if for that error id it's also setting the extra context property for the error
            if (backendErrors[error.id] && error.extra) {
                backendErrors[error.id] = error.extra;
                return true;
            }

            return backendErrors[error.id] !== undefined;
        });
    }

    static isRateLimitError(err: HttpErrorResponse) {
        return err.status === 403 && Object.keys(err.error).length === 0;
    }
}
