import { OnDestroy, OnInit, QueryList, Renderer2, } from '@angular/core';
import { AsyncTableComponent } from 'app/shared/elements/async-table';
import { FrontendTableHelper } from 'app/shared/helpers/frontend-table-helper';
import { combineLatest, forkJoin, of, ReplaySubject } from 'rxjs';
import { AdSlot, Newsletter } from 'app/shared/models';
import { mergeMap } from 'rxjs/operators';
import { AdSlotRepository, NewsletterRepository } from 'app/core/repositories';
import { IdService } from 'app/core';
import { RowDirective } from 'app/shared/elements/table';
import { CodeSnippetComponent } from 'app/shared/elements/code-snippet';
import { debounceTime } from 'rxjs/internal/operators';
var WIDTH_MACRO = '{INSERT TEMPLATE MAX WIDTH HERE AS INTEGER}';
var MOBILE_WIDTH_MACRO = '{INSERT TEMPLATE MAX MOBILE WIDTH HERE AS INTEGER}';
var RESPONSIVE_WIDTH_MACRO = '{INSERT TEMPLATE MOBILE RESPONSIVE WIDTH HERE AS INTEGER}';
var NewsletterTagsTableComponent = /** @class */ (function () {
    function NewsletterTagsTableComponent(adSlotRepository, newsletterRepository, id, renderer) {
        var _this = this;
        this.adSlotRepository = adSlotRepository;
        this.newsletterRepository = newsletterRepository;
        this.id = id;
        this.renderer = renderer;
        this.tableHelper = new FrontendTableHelper(function () { return _this.adapter(); });
        this.newsletter$ = new ReplaySubject(1);
        this.adSlotId$ = new ReplaySubject(1);
        this.maxWidth$ = new ReplaySubject(1);
        this.maxMobileWidth$ = new ReplaySubject(1);
    }
    Object.defineProperty(NewsletterTagsTableComponent.prototype, "newsletter", {
        set: function (newsletter) {
            this.newsletter$.next(newsletter);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewsletterTagsTableComponent.prototype, "adSlotId", {
        set: function (adSlotId) {
            this.adSlotId$.next(adSlotId);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewsletterTagsTableComponent.prototype, "maxWidth", {
        set: function (maxWidth) {
            this.maxWidth$.next(maxWidth);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewsletterTagsTableComponent.prototype, "maxMobileWidth", {
        set: function (maxMobileWidth) {
            this.maxMobileWidth$.next(maxMobileWidth);
        },
        enumerable: true,
        configurable: true
    });
    NewsletterTagsTableComponent.prototype.adapter = function () {
        var _this = this;
        return this.newsletter$.pipe(mergeMap(function (newsletter) { return _this.getAllNewsletterAdSlots(newsletter); }), mergeMap(function (_a) {
            var adSlots = _a[0], newsletter = _a[1];
            return _this.getAllAdSlotTags(adSlots, newsletter);
        }), mergeMap(function (_a) {
            var adSlots = _a[0], newsletter = _a[1];
            return _this.combineNewsletterSafeRtbWithAdSlots(adSlots, newsletter);
        }));
    };
    NewsletterTagsTableComponent.prototype.getAllNewsletterAdSlots = function (newsletter) {
        if (!newsletter) {
            return of([]);
        }
        // Grab all adSlots for a newsletter and join with the newsletter
        return forkJoin(this.adSlotRepository.getByNewsletterId(newsletter.id), of(newsletter));
    };
    NewsletterTagsTableComponent.prototype.getAllAdSlotTags = function (adSlots, newsletter) {
        var _this = this;
        if (!adSlots || !newsletter) {
            return of([]);
        }
        // If the newsletter doesn't have any ad slots return the newsletter so we can return safeRtb
        if (adSlots.length === 0) {
            return of([adSlots, newsletter]);
        }
        // Map adSlots to adSlot tags and join with newsletter
        return forkJoin(forkJoin(adSlots.map(function (adSlot) { return _this.adSlotRepository.tags(adSlot.refId.toString()); })), of(newsletter));
    };
    NewsletterTagsTableComponent.prototype.combineNewsletterSafeRtbWithAdSlots = function (adSlots, newsletter) {
        if (!adSlots || !newsletter) {
            return of([]);
        }
        // Create an adSlot from the newsletter safeRTB
        var newsletterSafeRtb = new AdSlot({
            created: newsletter.created,
            fullTag: newsletter.safeRTB.fullTag,
            name: 'SafeRTB',
            refId: '',
            sizes: '',
            status: '',
            type: 'safertb'
        });
        // If an ad slot was clicked to view code then we expand the row
        if (this.adSlot.refId) {
            var pos = adSlots.map(function (adSlot) { return adSlot.refId; }).indexOf(this.adSlot.refId);
            this.tableRow.toggleExpansion(adSlots[pos]);
        }
        // Combine Newsletter SafeRtb with AdSlots
        return of([].concat(adSlots, newsletterSafeRtb));
    };
    NewsletterTagsTableComponent.prototype.copyCodeSnippetHtmlIntoDataAttribute = function (codeSnippet) {
        var dataAttribute = codeSnippet.nativeElement.getAttribute('data');
        // Prevent overwriting default code snippets
        if (!dataAttribute) {
            var innerHtml = codeSnippet.nativeElement.querySelector('#code-input').innerHTML;
            this.renderer.setAttribute(codeSnippet.nativeElement, 'data', innerHtml);
        }
    };
    NewsletterTagsTableComponent.prototype.replaceMacroWithMaxWidths = function (codeSnippet, maxWidth, maxMobileWidth) {
        // Default codesnippet is stored in data attribute of codesnippet element
        var copyInnerText = codeSnippet.nativeElement.getAttribute('data');
        var widthMacroRegex = new RegExp(WIDTH_MACRO, 'g');
        var mobileWidthMacroRegex = new RegExp(MOBILE_WIDTH_MACRO, 'g');
        var responsiveWidthMacroRegex = new RegExp(RESPONSIVE_WIDTH_MACRO, 'g');
        if (maxWidth) {
            copyInnerText = copyInnerText.replace(widthMacroRegex, maxWidth);
        }
        if (maxMobileWidth) {
            copyInnerText = copyInnerText.replace(responsiveWidthMacroRegex, maxMobileWidth);
            copyInnerText = copyInnerText.replace(mobileWidthMacroRegex, maxMobileWidth);
        }
        return copyInnerText;
    };
    NewsletterTagsTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.adSlotId$.pipe(mergeMap(function (adSlotId) {
            if (!adSlotId) {
                return of([]);
            }
            return _this.adSlotRepository.get(adSlotId);
        })).subscribe(function (adSlot) {
            if (adSlot !== []) {
                _this.adSlot = new AdSlot(adSlot);
            }
        });
        combineLatest(this.maxWidth$, this.maxMobileWidth$).pipe(debounceTime(300), mergeMap(function (_a) {
            var maxWidth = _a[0], maxMobileWidth = _a[1];
            if (_this.codeSnippets && _this.codeSnippets.length > 0) {
                _this.codeSnippets.forEach(function (codeSnippet) { return _this.copyCodeSnippetHtmlIntoDataAttribute(codeSnippet); });
            }
            return of([maxWidth, maxMobileWidth]);
        })).subscribe(function (_a) {
            var maxWidth = _a[0], maxMobileWidth = _a[1];
            if (_this.codeSnippets && _this.codeSnippets.length > 0) {
                _this.codeSnippets.forEach(function (codeSnippet) {
                    var codeInputElement = codeSnippet.nativeElement.querySelector('#code-input');
                    var codeElement = codeSnippet.nativeElement.getElementsByTagName('code')[0];
                    var replacementCode = _this.replaceMacroWithMaxWidths(codeSnippet, maxWidth, maxMobileWidth);
                    _this.renderer.setProperty(codeInputElement, 'innerHTML', replacementCode);
                    _this.renderer.setProperty(codeElement, 'innerHTML', replacementCode);
                });
            }
        });
    };
    NewsletterTagsTableComponent.prototype.ngOnDestroy = function () {
        this.newsletter$.unsubscribe();
        this.adSlotId$.unsubscribe();
        this.maxWidth$.unsubscribe();
        this.maxMobileWidth$.unsubscribe();
    };
    NewsletterTagsTableComponent.prototype.query = function (tableQuery) {
        this.tableHelper.search([tableQuery]);
    };
    NewsletterTagsTableComponent.prototype.reset = function () {
        this.newsletter$.next(null);
        this.tableRow.getExpansions().clear();
    };
    return NewsletterTagsTableComponent;
}());
export { NewsletterTagsTableComponent };
