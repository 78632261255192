<div class="app-table">
    <ng-template #zero>
        <zero-state [class.minimal]="isZeroStateMinimal">
            <ng-content select=".zero"></ng-content>
        </zero-state>
    </ng-template>
    <ng-container *ngIf="!zerostate; else zero">
        <div class="table">
            <div class="controls">
                <div class="row">
                    <div class="column--10">
                        <div class="row fill-horizontal" *ngIf="filterable">
                            <div *ngIf="!showAdvanced">
                                <div class="column--5 placeholder-icon spacer--large-right">
                                    <input [id-append]="['search']" type="text" #search placeholder="Search" (keyup.enter)="submitInput(search.value); search.value = '';" (ngModelChange)="handleInput($event)" ngModel>
                                    <i class="fas fa-search fa-lg"></i>
                                </div>
                                <div *ngIf="advanced.children.length > 0">
                                    <button type="button" class="link readonly-text" (click)="toggleAdvanced(true)">Advanced</button>
                                </div>
                            </div>
                            <div [hidden]="!showAdvanced">
                                <div class="flex">
                                    <div class="search-icon">
                                        <button type="button" class="link" (click)="toggleAdvanced(false)">
                                            <i class="fas fa-search fa-lg"></i>
                                        </button>
                                    </div>
                                    <div #advanced>
                                        <ng-content select="filters"></ng-content>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column--2 pull-right flex flex-align-center flex-end">
                        <div #downloadButtonContainer [class.spacer--xlarge-right]="downloadButtonContainer.children.length > 0">
                            <ng-content select="app-table-download-button"></ng-content>
                        </div>
                        <button type="button" (click)="openConfigureColumns()">
                            <i class="fas fa-columns"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="filters" *ngIf="filters.length > 0 && !showAdvanced">
                <ng-container  *ngFor="let filter of filters">
                    <button type="button" class="button--small regular" (click)="removeFilter(filter)" *ngIf="!filter.isBuilding">
                        <span>{{filter.simpleLabel}}</span>
                        <i class="fas fa-times"></i>
                    </button>
                </ng-container>
            </div>
            <div class="data">
                <ng-template #empty>
                    <td class="empty align-center" [attr.colspan]="activeColumns.length">
                        <h3 [id-append]="['no-search-results']" *ngIf="!initializing">There are no results for the selected filters.</h3>
                    </td>
                </ng-template>
                <div class="actions" *ngIf="selectedCount > 0 && showSelectedCount">
                    <span>{{selectedCount | number}} {{'item' | pluralize : selectedCount}} selected</span>
                    <span *ngIf="selectedOutOfView.length > 0" class="italic">({{selectedOutOfView.length}} {{'item' | pluralize: selectedOutOfView.length}} out of view)</span>
                    <ng-content select=".bulk-actions"></ng-content>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th *ngIf="hasActions" class="table-checkbox">
                                <label [id-append]="['header-checkbox-label']" class="_checkbox">
                                    <input type="checkbox" [(ngModel)]="master" [disabled]="disableMaster">
                                    <span></span>
                                </label>
                            </th>
                            <ng-container *ngFor="let column of activeColumns">
                                <ng-container *ngTemplateOutlet="column.header.template"></ng-container>
                            </ng-container>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="rows.length > 0; else empty">
                            <tr *ngFor="let row of rows; let index = index" [class.is-funnel]="row.id === funnel" [ngStyle]="{'background-color': getRowBackgroundColor(row)}">
                                <td *ngIf="hasActions">
                                    <label [id-append]="['checkbox-label', index]" class="_checkbox">
                                        <input type="checkbox" [ngModel]="isSelected(row)" (ngModelChange)="select($event, row)" [disabled]="table.loading || isDisabled(row)">
                                        <span></span>
                                    </label>
                                </td>
                                <ng-container *ngFor="let column of activeColumns">
                                    <ng-container *ngTemplateOutlet="column.cell.template; context: { $implicit: row, index: index }"></ng-container>
                                </ng-container>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <overlay-loading-indicator *ngIf="loading" [class.no-spinner]="!initializing" [style.top.px]="overlayOffset" class="local white" [opacity]="rows.length > 0 ? .5 : 1"></overlay-loading-indicator>
            </div>
        </div>
    </ng-container>
</div>
<app-configure-columns (configured)="setActiveColumns($event)"></app-configure-columns>
