import { Model } from './model';

export class Category extends Model {
    id: any;
    name: string;
    iab: string;
    parent: number;

    serialize(): string {
        return JSON.stringify(this);
    }

    get entity() {
        return 'Category';
    }
}
