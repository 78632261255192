<div class="page page--padding">
    <div class="row">
        <div class="column--9 spacer--large-bottom">
            <h1>Selected Accounts</h1>
        </div>
        <div class="column--3 align-right">
            <button type="button" class="button--primary" (click)="openChooseAccounts()">Select More Accounts</button>
        </div>
    </div>
    <app-table [data]="helper.data | async" (query)="query($event)" filter-by="name,refId,entity,managedBy,managerName,executiveName">
        <div class="zero align-center">
            <h3>No Accounts Selected</h3>
            <p>Select accounts to see alerts when they need attention or are ending soon.</p>
        </div>
        <ng-container appTableColumn="name" appTableColumnName="Account Name" appTableColumnLocked>
            <th *appTableHeader appTableSort appTableDefaultColumn appTableSortDescending>Account Name</th>
            <td *appTableCell="let account">
                <div class="ellipsis">
                    <a [routerLink]="getPath(account)">{{account.name}}</a>
                </div>
                <span class="supporting">ID: {{account.refId}}</span>
                <row-actions class="show-on-hover" (clickedAction)="actionHandler($event, account)" [options]="[{ label: 'Remove', key: 0, value: 'remove' }]"></row-actions>
            </td>
        </ng-container>
        <ng-container appTableColumn="entity" appTableColumnName="Account Type">
            <th *appTableHeader appTableSort>Account Type</th>
            <td *appTableCell="let account">
                <span>{{account.entity}}</span>
            </td>
        </ng-container>
        <ng-container appTableColumn="managedBy" appTableColumnName="managedBy">
            <th *appTableHeader appTableSort>Managed By</th>
            <td *appTableCell="let account">
                <div class="ellipsis">{{account.managedBy}}</div>
            </td>
        </ng-container>
        <ng-container appTableColumn="managerName" appTableColumnName="Account Manager">
            <th *appTableHeader appTableSort>Account Manager</th>
            <td *appTableCell="let account">
                <div class="ellipsis">{{account.managerName || '&mdash;'}}</div>
            </td>
        </ng-container>
        <ng-container appTableColumn="executiveName" appTableColumnName="Account Executive">
            <th *appTableHeader appTableSort>Account Executive</th>
            <td *appTableCell="let account">
                <div class="ellipsis">{{account.executiveName || '&mdash;'}}</div>
            </td>
        </ng-container>
        <ng-container appTableColumn="created" appTableColumnName="Created">
            <th *appTableHeader appTableSort>Created</th>
            <td *appTableCell="let account">
                <span>{{account.created | date: 'mediumDate' || '&mdash;'}}</span>
                <br>
                <span class="supporting">{{account.created | date: 'shortTime'}}</span>
            </td>
        </ng-container>
    </app-table>
</div>
<choose-accounts></choose-accounts>
