import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Account } from 'app/shared/models/account';
import { map } from 'rxjs/operators';
var AccountRepository = /** @class */ (function () {
    function AccountRepository(http) {
        this.http = http;
        this.path = '/accounts';
    }
    AccountRepository.prototype.all = function () {
        return this.http.get(this.url(this.path));
    };
    AccountRepository.prototype.save = function (instance) {
        var params = [this.path];
        if (instance.id) {
            params.push(instance.id);
        }
        return this.http.post(this.url.apply(this, params), instance.serialize())
            .pipe(map(function (response) { return new Account(response); }));
    };
    AccountRepository.prototype.addProvision = function (accountId, provisionId) {
        return this.http.put(this.url('/accounts', accountId, 'provisions', provisionId), null, {
            responseType: 'text'
        });
    };
    AccountRepository.prototype.allProvisions = function () {
        return this.http.get(this.url('/provisions'));
    };
    /**
     * Builds and returns a URL for the backend API given an array of strings
     * `suffix` which are joined using a forward-slash.
     */
    AccountRepository.prototype.url = function () {
        var suffix = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            suffix[_i] = arguments[_i];
        }
        return environment.backend.heimdall.hostname + suffix.join('/');
    };
    return AccountRepository;
}());
export { AccountRepository };
