import { OnInit } from '@angular/core';
import { Campaign, LineItem } from 'app/shared/models';
import moment from 'moment';
import { IdService } from 'app/core/id.service';
var PacingCardComponent = /** @class */ (function () {
    function PacingCardComponent(id) {
        this.id = id;
        this._campaign = null;
    }
    PacingCardComponent.prototype.ngOnInit = function () {
        this.startDate = this.lineItem.startDate || this.lineItem.effectiveStartDate;
        this.endDate = this.lineItem.endDate || this.lineItem.effectiveEndDate;
    };
    Object.defineProperty(PacingCardComponent.prototype, "campaign", {
        get: function () {
            return this._campaign;
        },
        set: function (campaign) {
            if (campaign !== null) {
                this._campaign = campaign;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PacingCardComponent.prototype, "live", {
        get: function () {
            var now = moment();
            var start = moment(this.startDate);
            return now.diff(start, 'days');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PacingCardComponent.prototype, "total", {
        get: function () {
            var start = moment(this.startDate);
            var end = moment(this.endDate);
            var total = end.diff(start, 'days') - 1;
            if (total === 0) {
                total = 1;
            }
            return total;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PacingCardComponent.prototype, "aggregate", {
        get: function () {
            var now = moment();
            var start = moment(this.startDate);
            var end = moment(this.endDate);
            // Unsure on why we have to add an extra day of total time.
            var spend = this.lineItem.spend;
            if (this.lineItem.hasAdjustedImpressionsBudget) {
                spend = this.lineItem.adjustedImpressions;
            }
            else if (this.lineItem.hasImpressionsBudget) {
                spend = this.lineItem.impressions;
            }
            return end.isAfter(now) &&
                start.isBefore(now, 'day') ? (spend / this.live) * (this.total + 1) : this.spend;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PacingCardComponent.prototype, "expected", {
        get: function () {
            var now = moment();
            var start = moment(this.startDate);
            var end = moment(this.endDate);
            if (now.isAfter(end) || now.isSameOrBefore(start)) {
                return this.lineItem.budget;
            }
            return (this.budget / this.total) * this.live;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PacingCardComponent.prototype, "budget", {
        get: function () {
            return this.lineItem.budget;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PacingCardComponent.prototype, "threshold", {
        get: function () {
            return this.campaign.goalThreshold;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PacingCardComponent.prototype, "spend", {
        get: function () {
            if (this.lineItem.hasImpressionsBudget) {
                return this.lineItem.impressions;
            }
            if (this.lineItem.hasAdjustedImpressionsBudget) {
                return this.lineItem.adjustedImpressions;
            }
            return this.lineItem.spend;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PacingCardComponent.prototype, "start", {
        get: function () {
            return new Date(this.startDate);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PacingCardComponent.prototype, "end", {
        get: function () {
            return new Date(this.endDate);
        },
        enumerable: true,
        configurable: true
    });
    return PacingCardComponent;
}());
export { PacingCardComponent };
