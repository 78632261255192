import { Attribute, Component, QueryList, ContentChildren } from '@angular/core';
import { StepComponent } from './step';

@Component({
    selector: 'wizard',
    templateUrl: './wizard.html',
    styleUrls: ['./wizard.styl']
})
export class WizardComponent {

    @ContentChildren(StepComponent) steps: QueryList<StepComponent>;
    headerColumns: number;
    bodyColumns: number;

    constructor(
        @Attribute('header-columns') headerColumns: number,
        @Attribute('body-columns') bodyColumns: number
    ) {
        this.headerColumns = headerColumns || 6;
        this.bodyColumns = bodyColumns || 8;

    }

    get headerColumnClassList() {
        return `column--${this.headerColumns} offset--${(12 - this.headerColumns) / 2}`;
    }

    get bodyColumnClassList() {
        return `column--${this.bodyColumns} offset--${(12 - this.bodyColumns) / 2}`;
    }

    nextStep() {
        const currentStep = this.activeStepIndex;
        const steps = this.steps.toArray();

        if (currentStep >= steps.length) {
            return;
        }

        this.setActiveStep(currentStep + 1);
    }

    previousStep() {
        const currentStep = this.activeStepIndex;

        if (currentStep < 1) {
            return;
        }

        this.setActiveStep(currentStep - 1);
    }

    setActiveStep(stepIndex: number) {
        this.steps.forEach((step, i) => {
            step.isActive = stepIndex === i;
        });
    }

    get activeStep() {
        return this.steps.find(step => step.isActive);
    }

    get activeStepIndex() {
        return this.steps.toArray().findIndex(step => step.isActive);
    }

}
