import { AfterViewInit, Attribute, Component, Directive, ViewContainerRef, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Model } from 'app/shared/models';
import { isEqual } from 'app/core/utils';

@Directive({
    selector: '[bulk-edit]'
})
export class BulkEditDirective implements AfterViewInit {
    label: string;
    field: string;
    component: Component;
    private originals: Model[];

    constructor(
        @Attribute('label') label: string,
        @Attribute('field') field: string,
        private viewContainer: ViewContainerRef,
        private cdr: ChangeDetectorRef,
        private el: ElementRef
    ) {
        this.label = label;
        this.field = field;
    }

    ngAfterViewInit() {
        this.component = this.viewContainer['_data'].componentView.component;
        this.component['field'] = this.field;
    }

    activate(entities: Model[], entityName: string = '') {
        if (!this.originals) {
            this.originals = entities.map(entity => entity.clone());
        }

        this.component['entities'] = entities;
        this.component['entityName'] = entityName;
        this.el.nativeElement.hidden = false;
        this.cdr.detectChanges();
    }

    resetOriginals(entities: Model[]) {
        if (!this.originals) {
            this.originals = entities.map(entity => entity.clone());
        }
        this.component['entities'] = entities;
    }

    deactivate() {
        this.el.nativeElement.hidden = true;
    }

    get isActive() {
        return this.el.nativeElement.hidden === false;
    }

    get isDirty() {
        if (!this.component || !Array.isArray(this.component['entities']) || !Array.isArray(this.originals)) {
            return false;
        }

        if (typeof this.component['isDirty'] === 'function') {
            return this.component['isDirty']();
        }

        return this.component['entities'].some((entity: Model, index: number) => {
            return !isEqual(entity[this.field], this.originals[index][this.field]);
        });
    }

    isPreventingSave() {
        return this.component && typeof this.component['isPreventingSave'] === 'function'
            ? this.component['isPreventingSave']()
            : false;
    }

    reset() {
        delete this.originals;

        if (typeof this.component['reset'] === 'function') {
            this.component['reset']();
        }
    }
}
