<div class="list">
    <ul>
        <li [ngClass]="disabled ? 'disabled' : 'none'" (click)="remove(i)" *ngFor="let item of shownItems; let i = index" title="{{item.value}} {{item.label}}">
            <div [ngSwitch]="itemtpl">
                <div class="row" *ngSwitchCase="null">
                    <div class="column--3"><span [id-append]="[item.value]" class="list--value overflow">{{item.value}}</span></div>
                    <div class="column--8"><span [id-append]="[item.label]" class="overflow">{{item.label}}</span></div>
                    <div class="column--1 align-right">
                        <i class="fas fa-times"></i>
                    </div>
                </div>
                <ng-template *ngSwitchDefault
                    [ngTemplateOutlet]="itemtpl"
                    [ngTemplateOutletContext]="{ $implicit: item, index: i }"></ng-template>
            </div>
        </li>
        <li *ngIf="items.length > LIST_LIMIT">Only showing first {{LIST_LIMIT}} items</li>
    </ul>
</div>
