import { Model } from './model';
import { AdomainIfaas } from './adomain-ifaas-urls';

export class BuyerSeat extends Model {
    name: string;
    dspId: string;
    buyerSeat: string;
    description: string;
    adomainIfaasUrls: AdomainIfaas[];

    serialize(): string {

        const buyerSeat = this.clone(BuyerSeat);

        if (typeof buyerSeat.description !== 'string' || buyerSeat.description.length === 0) {
            buyerSeat.description = null;
        }
        if (buyerSeat.adomainIfaasUrls) {
            buyerSeat.adomainIfaasUrls.forEach(adomainIfaas => {
                if (typeof adomainIfaas.ifaasUrlTwo !== 'string' || adomainIfaas.ifaasUrlTwo.length === 0) {
                    adomainIfaas.ifaasUrlTwo = null;
                }

                delete adomainIfaas._isClone;
            });
        }

        return JSON.stringify(buyerSeat);
    }

    get entity() {
        return 'Buyer Seat';
    }
}
