import { ChangeDetectorRef, EventEmitter, TemplateRef } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { IdService } from 'app/core/id.service';
var ListComponent = /** @class */ (function () {
    function ListComponent(cdr, id) {
        this.cdr = cdr;
        this.id = id;
        this.items = [];
        this.LIST_LIMIT = 1000;
        this.disabled = false;
        this.itemtpl = null;
        this.onRemove = new EventEmitter();
        this.onchange = function (_) { };
    }
    ListComponent.prototype.writeValue = function (value) {
        if (Array.isArray(value)) {
            this.items = value;
            this.cdr.markForCheck();
        }
    };
    ListComponent.prototype.registerOnChange = function (fn) {
        this.onchange = fn;
    };
    ListComponent.prototype.registerOnTouched = function () { };
    ListComponent.prototype.remove = function (i) {
        if (!this.disabled) {
            var items = this.items.slice();
            var removedItem = items.splice(i, 1);
            this.items = items;
            this.onRemove.emit(removedItem);
            this.onchange(this.items);
        }
    };
    ListComponent.prototype.reset = function () {
        if (!this.disabled) {
            var removedItems = this.items.splice(0, this.items.length);
            this.onRemove.emit(removedItems);
        }
    };
    Object.defineProperty(ListComponent.prototype, "shownItems", {
        get: function () {
            return this.items.slice(0, this.LIST_LIMIT);
        },
        enumerable: true,
        configurable: true
    });
    return ListComponent;
}());
export { ListComponent };
