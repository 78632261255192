import * as tslib_1 from "tslib";
import { ESQueryBuilder } from './es-query-builder';
var ESSimpleQueryBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(ESSimpleQueryBuilder, _super);
    function ESSimpleQueryBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Convert a `TableQuery` into `SearchParams`, depending on
     * backend of the search.
     */
    ESSimpleQueryBuilder.prototype.build = function (tableQuery) {
        return {
            page: tableQuery.page,
            n: tableQuery.pageSize,
            orderBy: tableQuery.orderBy,
            sort: tableQuery.direction,
            query: this.buildSimpleQueryString(tableQuery.filters || [])
        };
    };
    /**
     * Convert `filters` into an elasticsearch `query_string`.
     */
    ESSimpleQueryBuilder.prototype.buildSimpleQueryString = function (filters) {
        var _this = this;
        // validate filters
        var validFilters = filters.filter(function (filter) { return filter.isValid(); });
        // recursively build any nested filters
        var expressions = validFilters.map(function (filter) {
            if (Array.isArray(filter.filters)) {
                return _this.buildSimpleQueryString(filter.filters);
            }
            return _this.buildExpression(filter);
        });
        // apply AND between different expressions
        return this.and(expressions);
    };
    /**
     * Flatten a list of expressions into a single expression with `AND` operators
     * between each of the original expressions.
     */
    ESSimpleQueryBuilder.prototype.and = function (expressions) {
        var _this = this;
        return expressions.map(function (expression) { return _this.parenthesize(expression); }).join(' + ');
    };
    /**
     * Build an elasticsearch expression based on a `Filter`.
     */
    ESSimpleQueryBuilder.prototype.buildExpression = function (filter) {
        return this.sanitize(filter.query);
    };
    return ESSimpleQueryBuilder;
}(ESQueryBuilder));
export { ESSimpleQueryBuilder };
