import { ControlValueAccessor } from '@angular/forms';
import { DownloadHelper } from 'app/shared/helpers/download-helper';
import { IdService } from 'app/core';
var PlacementIdTargetingComponent = /** @class */ (function () {
    function PlacementIdTargetingComponent(id) {
        this.id = id;
        this.data = '';
        this.items = [];
        this.fileName = 'placement_ids';
        this.onchange = null;
    }
    PlacementIdTargetingComponent.prototype.writeValue = function (placementIds) {
        if (placementIds) {
            this.addPlacementIds(placementIds);
        }
    };
    PlacementIdTargetingComponent.prototype.registerOnChange = function (fn) {
        this.onchange = fn;
    };
    PlacementIdTargetingComponent.prototype.registerOnTouched = function (fn) { };
    PlacementIdTargetingComponent.prototype.emitChanges = function () {
        if (this.onchange !== null) {
            var selected = null;
            if (this.items.length > 0) {
                selected = this.items.map(function (item) {
                    return item.value;
                }).join(',');
            }
            this.onchange(selected);
        }
    };
    PlacementIdTargetingComponent.prototype.addPlacementIds = function (data) {
        var _this = this;
        if (typeof data !== 'string') {
            return;
        }
        var placementIds = data.replace(/\n/g, ',').split(',').map(function (str) { return str.trim(); }).filter(function (str) { return str.length > 0; });
        if (placementIds.length < 1) {
            return;
        }
        // Filter list so that it is unique.
        placementIds = placementIds.filter(function (placementId, i, arr) { return arr.indexOf(placementId) === i; });
        // Filter list so that it does not contain any placement ids already added.
        placementIds = placementIds.filter(function (placementId) {
            return _this.items.findIndex(function (item) { return item.value === placementId; }) < 0;
        });
        var items = this.items.slice();
        placementIds.forEach(function (placementId) {
            items.push({
                key: placementId,
                value: placementId,
                label: "" + placementId.toString()
            });
        });
        this.items = items;
        this.emitChanges();
        // Reset.
        this.data = '';
    };
    PlacementIdTargetingComponent.prototype.itemsUpdate = function (items) {
        this.items = items;
        this.emitChanges();
    };
    Object.defineProperty(PlacementIdTargetingComponent.prototype, "hasItems", {
        get: function () {
            return this.items.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    PlacementIdTargetingComponent.prototype.clearItems = function () {
        this.items = [];
        this.emitChanges();
    };
    PlacementIdTargetingComponent.prototype.download = function () {
        var data = this.items.map(function (item) { return ({
            'placement id': item.value
        }); });
        DownloadHelper.downloadAsCSV(data, this.fileName);
    };
    return PlacementIdTargetingComponent;
}());
export { PlacementIdTargetingComponent };
