import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { ValidationService } from 'app/core/validation-service';

@Directive({
    selector: '[validate-domain][formControlName],[validate-domain][formControl],[validate-domain][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => DomainValidator), multi: true }
    ]
})
export class DomainValidator implements Validator {

    validate(c: AbstractControl): { [key: string]: any } {
        const domain = c.value;
        if (!domain || domain === '') {
            return null;
        }
        return ValidationService.isValidDomain(domain) ? null : { 'domain' : { valid: false }};
    }
}
