import { ChangeDetectorRef, EventEmitter, OnInit } from '@angular/core';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { LocationHelperService, IdService } from 'app/core';
import { ControlValueAccessor } from '@angular/forms';
import { SearchInputComponent } from 'app/shared/elements/search-input/search-input.component';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
var GeoTargetingComponent = /** @class */ (function () {
    function GeoTargetingComponent(cdr, locationHelper, id) {
        this.cdr = cdr;
        this.locationHelper = locationHelper;
        this.id = id;
        this.targetingOptions = [
            { key: 0, value: 'include', label: 'Target' },
            { key: 1, value: 'exclude', label: 'Exclude' }
        ];
        this.targeting = 'include';
        this.targetingChange = new EventEmitter();
        this.searchValue = new BehaviorSubject('');
        this.unitedStatesOnly = new ReplaySubject(1);
        this.selectedLocations = new BehaviorSubject([]);
        this.searchableLocations = [];
        this.onChange = function (selectedLocations) { };
    }
    GeoTargetingComponent.isUnitedStatesLocation = function (location) {
        return location.country && location.country.countryId === GeoTargetingComponent.UnitedStates
            || location.id === GeoTargetingComponent.UnitedStates;
    };
    GeoTargetingComponent.isSameLocation = function (a, b) {
        return a.id === b.id && a.type === b.type;
    };
    GeoTargetingComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.searchValue.pipe(distinctUntilChanged(), debounceTime(100)), this.selectedLocations, this.unitedStatesOnly).subscribe(function (_a) {
            var value = _a[0], selectedLocations = _a[1], unitedStatesOnly = _a[2];
            return _this.updateSearchableLocations(value, selectedLocations, unitedStatesOnly);
        });
        this.searchInput.inputChange.subscribe(function (value) { return _this.searchValue.next(value); });
        this.selectedLocations.subscribe(function (selectedLocations) {
            _this.onChange(selectedLocations.map(function (selectedLocation) { return ({
                id: selectedLocation.id,
                type: selectedLocation.type
            }); }));
        });
    };
    GeoTargetingComponent.prototype.writeValue = function (selectedLocations) {
        var _this = this;
        if (Array.isArray(selectedLocations)) {
            this.locationHelper.promise.then(function () {
                _this.selectedLocations.next(selectedLocations.map(function (selectedLocation) {
                    return _this.locationHelper.database.find(function (location) {
                        return GeoTargetingComponent.isSameLocation(location, selectedLocation);
                    });
                }));
            });
        }
    };
    GeoTargetingComponent.prototype.registerOnChange = function (onChange) {
        this.onChange = onChange;
    };
    GeoTargetingComponent.prototype.registerOnTouched = function () { };
    GeoTargetingComponent.prototype.updateSearchableLocations = function (value, selectedLocations, unitedStatesOnly) {
        var _this = this;
        this.locationHelper.promise.then(function () {
            var pattern = new RegExp(value, 'i');
            _this.searchableLocations = _this.locationHelper.database
                .filter(function (location) { return !unitedStatesOnly || GeoTargetingComponent.isUnitedStatesLocation(location); })
                .filter(function (location) { return !selectedLocations.some(function (selectedLocation) {
                return GeoTargetingComponent.isSameLocation(location, selectedLocation);
            }); })
                .filter(function (location) { return pattern.test(location.display); })
                .slice(0, 10);
            _this.cdr.markForCheck();
        });
    };
    GeoTargetingComponent.prototype.selectLocation = function (location) {
        this.selectedLocations.next(this.selectedLocations.getValue().concat(location));
    };
    GeoTargetingComponent.prototype.updateSelectedLocations = function (selectedLocations) {
        this.selectedLocations.next(selectedLocations);
    };
    Object.defineProperty(GeoTargetingComponent.prototype, "usonly", {
        set: function (value) {
            this.unitedStatesOnly.next(value);
        },
        enumerable: true,
        configurable: true
    });
    GeoTargetingComponent.prototype.updateTargeting = function (targeting) {
        this.targetingChange.emit(targeting);
    };
    GeoTargetingComponent.prototype.clearSelectedLocations = function () {
        this.selectedLocations.next([]);
    };
    GeoTargetingComponent.UnitedStates = 235;
    return GeoTargetingComponent;
}());
export { GeoTargetingComponent };
