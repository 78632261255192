import * as tslib_1 from "tslib";
import { Model } from '../model';
var ProwlerError = /** @class */ (function (_super) {
    tslib_1.__extends(ProwlerError, _super);
    function ProwlerError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ProwlerError.prototype, "entity", {
        get: function () {
            return 'Error';
        },
        enumerable: true,
        configurable: true
    });
    return ProwlerError;
}(Model));
export { ProwlerError };
