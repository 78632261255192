
import { Directive, Input, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[templateClick]',
})
export class BlueprintClickDirective {

    @Input() pCoord: string;
    @Output() templateClick = new EventEmitter<FocusedTarget>();

    constructor(private elementRef: ElementRef) { }

    @HostListener('document:click', ['$event'])
    public onClick(e) {
        let targetEl = e.target;
        let pCoord = this.pCoord;
        let insideTemplate = this.elementRef.nativeElement.contains(targetEl);

        if(insideTemplate){
            // check <span> via generateHTML()
            if(targetEl instanceof HTMLSpanElement){
                this.templateClick.emit({pCoord, insideTemplate, targetProperty: targetEl.parentElement.id})
            } else {
                if(e.target.id) this.templateClick.emit({pCoord, insideTemplate, targetProperty: targetEl.id});
                if(!e.target.id) this.templateClick.emit({pCoord, insideTemplate, targetProperty: 'wrapper'}); //default wrapper
            }
        }
    }
}

export interface FocusedTarget{
    pCoord: string;
    insideTemplate: boolean;
    targetProperty: string;
}

