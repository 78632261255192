import { Filter } from '../filter';
import { Operation } from '../operation';

export class FilterString extends Filter {
    readonly operations: Operation[] = [
        Operation.Contains,
        Operation.DoesNotContain,
        Operation.Equals,
        Operation.DoesNotEqual
    ];

    constructor(data = {}) {
        super(data);

        if (this.field === 'newsletter' && typeof this.query === 'string') {
            this.query = this.query.slice(0, 48);
        }
    }

}
