import { Attribute, Component, ViewChild } from '@angular/core';
import { AdSlot } from 'app/shared/models';
import { SelectDropdownComponent } from 'app/shared/elements/select-dropdown';
import { HasDirtyCheck, Resettable } from 'app/shared/components/bulk-edit-lightbox';

@Component({
  selector: 'bulk-edit-ad-slot-demand-setting',
  templateUrl: './bulk-edit-ad-slot-demand-setting.component.html',
  styleUrls: ['./bulk-edit-ad-slot-demand-setting.component.styl']
})
export class BulkEditAdSlotDemandSettingComponent implements HasDirtyCheck, Resettable {

    @ViewChild('masterDropdown', { static: true }) masterDropdown: SelectDropdownComponent;
    private _adSlots: AdSlot[] = [];
    private _originals: AdSlot[] = [];
    label: string;
    entityName = '';
    field: string;

    constructor(@Attribute('label') label: string) {
        this.label = label;
    }

    master(value: boolean) {
        this.entities.forEach(adSlot => {
            if (adSlot.hasOwnProperty('sspControl')) {
                adSlot.sspControl.exchangeAllow = value;
            }
        });
    }

    reset() {
        this.masterDropdown.reset();
        this.entities.forEach((adSlot, index) => {
            if (adSlot.hasOwnProperty('sspControl')) {
                adSlot.sspControl.exchangeAllow = this.original(index).sspControl.exchangeAllow;
            }
        });
    }

    isDirty() {
        return this.entities.some((adSlot, index) => {
            return adSlot.sspControl && adSlot.sspControl.exchangeAllow !== this.original(index).sspControl.exchangeAllow;
        });
    }

    undo(i: number) {
        this.entities[i].sspControl.exchangeAllow = this.original(i).sspControl.exchangeAllow;
    }

    checkDirty(i: number, value: boolean) {
        if (this.original(i).sspControl) {
            return value !== this.original(i).sspControl.exchangeAllow;
        }
    }

    original(index: number) {
        return this._originals[index];
    }

    set entities(adSlots: AdSlot[]) {
        this._adSlots = adSlots;
        this._originals = adSlots.map(adSlot => adSlot.clone());
    }

    get entities() {
        return this._adSlots;
    }
}
