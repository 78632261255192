/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../directives/id-append.directive";
import * as i3 from "../button/button.component.ngfactory";
import * as i4 from "../button/button.component";
import * as i5 from "@angular/common";
import * as i6 from "./alert.component";
import * as i7 from "../../../core/id.service";
var styles_AlertnextComponent = [i0.styles];
var RenderType_AlertnextComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertnextComponent, data: {} });
export { RenderType_AlertnextComponent as RenderType_AlertnextComponent };
function View_AlertnextComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "strong", [["class", "title"]], [[1, "id", 0]], null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.IdAppendDirective, [i1.ElementRef], { id: [0, "id"] }, null), i1.ɵpad(2, 1), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_1 = _ck(_v, 2, 0, "alert-title"); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).attrId; _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.title; _ck(_v, 3, 0, currVal_2); }); }
function View_AlertnextComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], [[8, "innerHTML", 1], [1, "id", 0]], null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.IdAppendDirective, [i1.ElementRef], { id: [0, "id"] }, null), i1.ɵpad(2, 1)], function (_ck, _v) { var currVal_2 = _ck(_v, 2, 0, "alert-message"); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; var currVal_1 = i1.ɵnov(_v, 1).attrId; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_AlertnextComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "alert relative"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 6, "button", [["class", "close button--close pull-right"], ["type", "button"]], [[1, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i1.ɵdid(3, 16384, null, 0, i2.IdAppendDirective, [i1.ElementRef], { id: [0, "id"] }, null), i1.ɵpad(4, 1), i1.ɵdid(5, 49152, null, 0, i4.ButtonComponent, [[8, null], i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["\n        "])), (_l()(), i1.ɵeld(7, 0, null, 0, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertnextComponent_1)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertnextComponent_2)), i1.ɵdid(14, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 4, 0, "close-button"); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.hasTitle; _ck(_v, 11, 0, currVal_2); var currVal_3 = _co.hasMessage; _ck(_v, 14, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).attrId; _ck(_v, 2, 0, currVal_0); }); }
export function View_AlertnextComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "alertnext", [], null, null, null, View_AlertnextComponent_0, RenderType_AlertnextComponent)), i1.ɵdid(1, 180224, null, 0, i6.AlertnextComponent, [i7.IdService], null, null)], null, null); }
var AlertnextComponentNgFactory = i1.ɵccf("alertnext", i6.AlertnextComponent, View_AlertnextComponent_Host_0, { title: "title", message: "message" }, { dismissed: "dismissed" }, ["*"]);
export { AlertnextComponentNgFactory as AlertnextComponentNgFactory };
