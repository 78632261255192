import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { Newsletter } from 'app/shared/models';
import { NewsletterRepository } from 'app/core/repositories';
import { of } from 'rxjs';

@Injectable()
export class NewsletterResolver implements Resolve<Newsletter> {
    constructor(private newsletterRepository: NewsletterRepository) { }

    resolve(route: ActivatedRouteSnapshot) {
        if (route.params['id']) {
            return this.newsletterRepository.getNewsletterDetails(route.params['id']);
        }
        if (route.params['publisherId']) {
            return of(new Newsletter({
                publisher: route.params['publisherId']
            }));
        }
        const newsletter = new Newsletter();
        return of(newsletter);
    }
}
