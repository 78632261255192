import { Model } from './model';

type Dsp = {
    id: string,
    allow: boolean,
    floor: number
};

export class MediaGroup extends Model {
    accountId?: number;
    createdBy: string;
    description: string;
    executive: string;
    executiveName: string;
    isApiPartner: boolean;
    manager: string;
    managerName: string;
    modified: string;
    name: string;
    publishers: any[];
    refId: number;
    resultType: string;
    dealSspFee: number;
    rtbPartners: Dsp[];
    salesforceId: string;
    status: string;
    type: string;
    agencyType: string;
    agreedUserMatching: boolean;
    contractType: string;
    monthlyMinimum: number;
    dspFee: number;
    liPaymentTerms: string;
    feeStructure: string;
    agreedExclusivity: boolean;
    includedCampaigns: string;
    agreedRepurposing: boolean;
    autoActivateAdSlots: boolean;
    flatFee: number;
    marginalFee: number;
    selfServe: boolean;
    requireMfa: boolean;
    sspFee: number;
    tagsUrlPrefix: string;
    blocklists: number[];
    gdprCompliance: string;
    sspControl: {
        exchangeAllow: boolean,
        exchangeFloor: number,
        rtbAllow: any,
        rtbFloor: number
    };
    consentControls: {
        id: number,
        allow: boolean
    }[];
    sellerId: number;
    axtCpm: number;
    // returned by LSD but deleted by us
    contact?: any;
    advertisers?: any;

    public serialize(): string {
        const mediaGroup = this.clone(MediaGroup);

        mediaGroup.agreedUserMatching = !! mediaGroup.agreedUserMatching;

        if (!mediaGroup.dealSspFee) {
            delete mediaGroup.dealSspFee;
        }

        delete mediaGroup.advertisers;
        delete mediaGroup.contact;
        delete mediaGroup.flatFee;
        delete mediaGroup.marginalFee;

        if (!this.salesforceId){
            delete mediaGroup.salesforceId;
        }

        if (mediaGroup.type === 'Media Group') {
            delete mediaGroup.selfServe;
        } else {
            delete mediaGroup.executive;
            delete mediaGroup.sspFee;
            delete mediaGroup.contractType;
            delete mediaGroup.blocklists;
            delete mediaGroup.monthlyMinimum;
            delete mediaGroup.tagsUrlPrefix;
        }

        delete mediaGroup.agreedExclusivity;
        delete mediaGroup.includedCampaigns;
        delete mediaGroup.agreedRepurposing;

        return JSON.stringify(mediaGroup);
    }

    get managedBy() {
        return this.name;
    }

    get entity() {
        return 'Media Group';
    }
}
