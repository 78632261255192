import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdSlot } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { UserRepository } from './user-repository.service';
import { BlueprintRepository } from './blueprint-repository';
var AdSlotRepository = /** @class */ (function (_super) {
    tslib_1.__extends(AdSlotRepository, _super);
    function AdSlotRepository(http, userRepository, blueprintRepository) {
        var _this = _super.call(this, http, "/ad-slot", AdSlot) || this;
        _this.userRepository = userRepository;
        _this.blueprintRepository = blueprintRepository;
        _this.searchPath = "/search/ad-slot/shop-by-strategy?params=";
        return _this;
    }
    AdSlotRepository.prototype.linkDsps = function (id, body) {
        var _this = this;
        return this.http
            .post(this.url("/ad-slot/link-dsps", id), body)
            .pipe(map(function (response) { return _this.build(response["output"]); }));
    };
    AdSlotRepository.prototype.getAdSlotDetails = function (id) {
        var _this = this;
        return this.get(id)
            .toPromise()
            .then(function (adSlot) {
            var userConditions = {
                conditions: [
                    {
                        field: "hash_id",
                        value: [],
                    },
                ],
            };
            if (adSlot.createdBy.length === 32) {
                userConditions.conditions[0].value.push(adSlot.createdBy);
            }
            if (adSlot.modifiedBy.length === 32) {
                userConditions.conditions[0].value.push(adSlot.modifiedBy);
            }
            return _this.userRepository
                .search(userConditions)
                .toPromise()
                .then(function (users) {
                var userLookup = [];
                users.map(function (user) {
                    userLookup[user.hashId] = user;
                });
                if (adSlot.createdBy in userLookup) {
                    adSlot.createdByUser = userLookup[adSlot.createdBy];
                }
                if (adSlot.modifiedBy in userLookup) {
                    adSlot.modifiedByUser =
                        userLookup[adSlot.modifiedBy];
                }
                return adSlot;
            });
        });
    };
    AdSlotRepository.prototype.getBy = function (searchConditions) {
        var adSlotConditions = {
            conditions: [],
            returnMode: "only",
            return: [
                "id",
                "refId",
                "newsletter",
                "publisher",
                "sizes",
                "type",
                "name",
                "version",
                "created",
                "taboola",
                "coordinationType",
                "tagType",
                "currentTagVersion",
                "previousTagVersion",
                "isHybrid",
                "isDisplay",
                "isNative",
            ],
        };
        adSlotConditions.conditions =
            adSlotConditions.conditions.concat(searchConditions);
        return this.all(adSlotConditions).toPromise();
    };
    AdSlotRepository.prototype.getByPublisherId = function (publisherId) {
        return this.getBy([
            {
                field: 'publisher',
                value: publisherId,
            },
        ]);
    };
    AdSlotRepository.prototype.getByNewsletterId = function (newsletterId) {
        return this.getBy([
            {
                field: 'newsletter',
                value: newsletterId,
            },
        ]);
    };
    AdSlotRepository.prototype.activate = function (id, body) {
        var _this = this;
        return this.http
            .post(this.url("/ad-slot/activate", id), body)
            .pipe(map(function (response) { return _this.build(response["output"]); }));
    };
    AdSlotRepository.prototype.getByStrategyId = function (strategyId) {
        var _this = this;
        return this.http
            .post(this.url("/search/ad-slot/strategy"), { strategyId: strategyId })
            .pipe(map(function (response) {
            return response["output"].map(function (data) {
                return _this.build(data);
            });
        }));
    };
    AdSlotRepository.prototype.searchByLineItem = function (strategyId, params) {
        var _this = this;
        var url = this.url("/search/ad-slot/strategy");
        return this.http
            .post(url, Object.assign({ strategyId: strategyId }, this.sanitizeQuery(params)))
            .pipe(map(function (data) { return ({
            items: data["output"].map(function (item) { return _this.build(item); }),
            page: 1,
            pages: data["pages"],
            total: data["total"],
        }); }));
    };
    AdSlotRepository.prototype.searchByCoordinatedLineItem = function (params) {
        var _this = this;
        var url = this.url('/search/ad-slot/coordinated');
        return this.http.post(url, this.sanitizeQuery(params)).pipe(map(function (data) { return ({
            items: data['output'].map(function (item) { return _this.build(item); }),
            page: 1,
            pages: data['pages'],
            total: data['total']
        }); }));
    };
    AdSlotRepository.prototype.lineItemSources = function (lineItem, params) {
        if ((lineItem.campaignObj.isDirectSold ||
            lineItem.campaignObj.isHouse) &&
            lineItem.ads &&
            lineItem.ads.length > 0 &&
            lineItem.ads.some(function (ad) { return !!ad.isNative; })) {
            return [
                this.http.post(this.url(this.searchPath + lineItem.id), this.sanitizeQuery(params)),
            ];
        }
        else {
            return [
                this.http.post(this.url(this.searchPath + lineItem.id), this.sanitizeQuery(params)),
                of({
                    output: [],
                    pages: 0,
                    total: 0,
                }),
            ];
        }
    };
    AdSlotRepository.prototype.lineItemShopBy = function (lineItem, params) {
        var _this = this;
        return forkJoin(this.lineItemSources(lineItem, params)).pipe(map(function (_a) {
            var data = _a[0];
            return {
                items: data['output'].map(function (item) { return _this.build(item); }),
                page: 1,
                pages: data['pages'],
                total: data['total'],
            };
        }));
    };
    AdSlotRepository.prototype.shopByStrategyAll = function (lineItem, params) {
        var _this = this;
        return this.consume(function (currentPage) {
            //clone the query so when we update the page
            //we are not mutating the original query
            //since it will be passed by reference
            var q = JSON.parse(JSON.stringify(params));
            q.page = currentPage;
            return forkJoin(_this.lineItemSources(lineItem, q)).pipe(map(function (_a) {
                var data = _a[0];
                return {
                    output: data['output'].map(function (item) { return _this.build(item); }),
                    page: currentPage,
                    pages: data['pages'],
                    total: data['total'],
                };
            }));
        });
    };
    AdSlotRepository.prototype.archive = function (id) {
        return this.delete(id);
    };
    AdSlotRepository.prototype.unarchive = function (adSlot) {
        var _this = this;
        return this.http
            .post(this.url("/ad-slot/undelete", adSlot.id), {
            version: adSlot.version,
        })
            .pipe(map(function (response) { return _this.build(response["output"]); }));
    };
    AdSlotRepository.prototype.tags = function (adSlotRefId) {
        var _this = this;
        return this.http
            .post(this.url('/ad-slot/tags', adSlotRefId), {})
            .pipe(map(function (response) { return _this.build(response['output']); }));
    };
    return AdSlotRepository;
}(BackendRepository));
export { AdSlotRepository };
