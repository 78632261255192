import * as tslib_1 from "tslib";
import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnDestroy, OnInit, QueryList } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Campaign, Creative, DemandAsset, LineItem, Native, SupplyAssetProperties } from 'app/shared/models';
import { DemandAssetProperties, DemandAssetTypeId, IABAssets } from 'app/shared/models/native-demand';
import { AdvertiserRepository, BlueprintRepository, CreativeRepository, LineItemRepository, MediaGroupRepository, PublisherRepository } from 'app/core/repositories';
import { BehaviorSubject, ReplaySubject, Subject, Subscription } from 'rxjs/index';
import { debounceTime, distinctUntilChanged, map, mergeMap, takeUntil } from 'rxjs/internal/operators';
import { AuthorizationService, IdService, NotificationsService, ValidationService } from 'app/core';
import { DemandAssetService } from 'app/core/demand-asset.service';
import { AdBuilderNativePreviewComponent } from 'app/shared/components/ad-builder/ad-builder-native-preview';
import validationErrors from 'app/core/errors/validation-errors.json';
import { Flag, LaunchDarklyService } from 'app/core/launch-darkly.service';
import { sortBlueprintOptionsByName } from 'app/shared/helpers/blueprint-helper';
import { IMAGE_TYPE_GIF } from 'app/shared/helpers/ad-builder-helper';
import { GifReader } from 'omggif';
import { HttpBackend, HttpHeaders, HttpClient } from '@angular/common/http';
import { IfaasRepository } from 'app/core/repositories';
var MAX_NATIVE_FILE_SIZE = 1200; // in KB
var MAX_LOGO_FILE_SIZE = 250; // in KB
var MAX_GIF_NUM_FRAMES = 90;
var MAX_IFAAS_FILE_SIZE = 4; // in MB
var GIF_FILE_EXTENSION = '.gif';
var AdBuilderNativeExchangeComponent = /** @class */ (function () {
    function AdBuilderNativeExchangeComponent(cdr, creativeRepository, id, notificationService, lineItemRepository, blueprintRepository, advertiserRepository, mediaGroupRepository, publisherRepository, demandAssetService, launchDarklyService, auth, httpBackend, ifaasRepository) {
        this.cdr = cdr;
        this.creativeRepository = creativeRepository;
        this.id = id;
        this.notificationService = notificationService;
        this.lineItemRepository = lineItemRepository;
        this.blueprintRepository = blueprintRepository;
        this.advertiserRepository = advertiserRepository;
        this.mediaGroupRepository = mediaGroupRepository;
        this.publisherRepository = publisherRepository;
        this.demandAssetService = demandAssetService;
        this.launchDarklyService = launchDarklyService;
        this.auth = auth;
        this.ifaasRepository = ifaasRepository;
        this.creative = this.createCreative();
        this.showThirdPartyTracker = false;
        this.showStaqId = false;
        this.showExternalId = false;
        this.allowBlueprintChange = false;
        this.isNativeCreativeEdit = false;
        this.errorHelper = null;
        this.selectedCampaigns = [];
        this.isCreativeSizeValid = new EventEmitter();
        this.update$ = new ReplaySubject(1);
        this.creative$ = new ReplaySubject(1);
        this.blueprint$ = new ReplaySubject(0);
        this.blueprintOptions$ = new ReplaySubject(1);
        this.demandAssetProperties = DemandAssetProperties;
        this.Native = Native;
        this.demandAssets = new DemandAsset();
        this.iabAssets = IABAssets;
        this._secondary = false;
        this._blueprints = [];
        this._placementId$ = new BehaviorSubject('');
        this.hasPlacementTargetingStrategy = false;
        this.hasPlacementTargetingCampaign = false;
        this.assetRestrictions = {};
        this.errorMessages = [];
        this.onDestroy$ = new Subject();
        this.debounceTime = 1000;
        this.validationService = null;
        this.validationErrors = validationErrors;
        this.showBlueprintAlert = false;
        this.ifaasImage$ = new Subscription();
        this.ifaasImageFetchPendingMap = new Map(); // CreativeId, notificationId
        this.rolloutNativeCreativeTrafficking$ = new BehaviorSubject(false);
        this.rolloutNativeCreativeTrafficking = false;
        this.http = new HttpClient(httpBackend);
    }
    Object.defineProperty(AdBuilderNativeExchangeComponent.prototype, "placementId", {
        set: function (value) {
            if (value) {
                this._placementId$.next(value);
                this.hasPlacementTargetingStrategy = true;
            }
            else {
                this.hasPlacementTargetingStrategy = false;
            }
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Init
     */
    AdBuilderNativeExchangeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.validationService = new ValidationService();
        this.demandAssets = this.demandAssetService.initDemandAssets(this.demandAssets);
        this.getMediaGroup().pipe(mergeMap(function (mgId) { return _this.blueprintRepository.basicSearch(mgId)
            .pipe(map(function (blueprints) {
            sortBlueprintOptionsByName(blueprints);
            return blueprints.map(function (item) {
                _this._blueprints.push(item);
                return {
                    value: item.id,
                    key: item.id,
                    label: "(ID:" + item.id + ") " + item.displayName,
                };
            });
        })); })).subscribe(function (blueprints) {
            _this.blueprintOptions$.next(blueprints);
            if (_this.creative) {
                if (_this.creative.urlTracking2) {
                    _this._secondary = true;
                }
                if (_this.creative.refId) {
                    _this.creativeRepository
                        .get(_this.creative.refId)
                        .subscribe(function (creative) {
                        if (creative.native) {
                            _this.setBlueprintId(_this.creative.blueprintId);
                            _this.demandAssets = _this.demandAssetService.setAssets(_this.demandAssets, creative.native.assets, _this.update$);
                        }
                    });
                }
                else if (_this.creative.native) {
                    _this.setBlueprintId(_this.creative.blueprintId);
                    if (!_this.isNativeDirectSoldCreative) {
                        delete _this.creative.blueprintId;
                        _this.setIsModified();
                        _this.setAllAssetsRequiredForNCT();
                    }
                    _this.demandAssets = _this.demandAssetService.setAssets(_this.demandAssets, _this.creative.native.assets, _this.update$);
                    if (!_this.creative.id) {
                        _this.demandAssets = _this.demandAssetService.unlinkDemandAssets(_this.demandAssets);
                    }
                }
            }
            if (_this.isNativeCreativeEdit) {
                _this.setBlueprintId(_this.creative.blueprintId);
            }
        });
        if (this.campaign && this.lineItem) {
            this.lineItemRepository.search({
                conditions: [{
                        field: 'campaign',
                        value: this.campaign.id
                    }]
            }).subscribe(function (lineItems) {
                var lineItemsWPlacementTargeting = lineItems
                    .filter(function (lineItem) { return !(lineItem.placementId === '' || (!!_this.lineItem.id && _this.lineItem.id === lineItem.id)); });
                _this.hasPlacementTargetingCampaign = lineItemsWPlacementTargeting.length > 0;
            });
        }
        this.creative$.pipe(takeUntil(this.onDestroy$)).subscribe(function (creative) {
            if (typeof creative.native === 'string') {
                creative.native = JSON.parse(creative.native);
            }
            _this.creative = creative;
            if (creative.isNative && creative.native && creative.native.assets) {
                _this.demandAssets = _this.demandAssetService.initDemandAssets(_this.demandAssets);
                _this.demandAssets = _this.demandAssetService.setAssets(_this.demandAssets, creative.native.assets, _this.update$);
                _this.setBlueprintId(creative.blueprintId);
                _this.refreshPreviews();
            }
        });
        this.update$.pipe(debounceTime(this.debounceTime), takeUntil(this.onDestroy$)).subscribe(function () {
            _this.refreshPreviews();
            _this.creative.native = {
                link: _this.creative.clickUrl,
                assets: _this.updateAssets()
            };
            _this.cdr.markForCheck();
        });
        this.blueprint$.pipe(takeUntil(this.onDestroy$)).subscribe(function (blueprintId) {
            if (blueprintId) {
                _this.creative.blueprintId = blueprintId;
                var blueprintObject = _this._blueprints.find(function (blueprint) { return blueprint.id === _this.creative.blueprintId; });
                if (blueprintObject && ('width' in blueprintObject)) {
                    _this.creative.blueprintWidth = blueprintObject.width;
                    if (blueprintObject && ('maxHeight' in blueprintObject)) {
                        _this.creative.height = blueprintObject.maxHeight;
                        _this.creative.width = blueprintObject.width;
                    }
                }
                _this.creative.blueprintName = _this._blueprints.find(function (blueprint) { return blueprint.id === _this.creative.blueprintId; }).name;
                _this.setSupplyAssets(blueprintId);
                _this.creative.isDirectSoldTrafficking = false;
            }
            else {
                if (_this.isDirectSoldCampaign || _this.creative.isDirectSold) {
                    _this.creative.isDirectSoldTrafficking = true;
                }
                _this.creative.assetRestrictions = null;
                _this.setAllAssetsRequiredForNCT();
            }
        });
        this.launchDarklyService
            .getVariation(Flag.RolloutNativeCreativeTrafficking)
            .pipe(distinctUntilChanged())
            .subscribe(function (rolloutNativeCreativeTrafficking) {
            _this.rolloutNativeCreativeTrafficking$.next(rolloutNativeCreativeTrafficking);
        });
        this.rolloutNativeCreativeTrafficking$.subscribe(function (value) {
            _this.rolloutNativeCreativeTrafficking = value;
        });
    };
    AdBuilderNativeExchangeComponent.prototype.ngAfterViewInit = function () {
        this.cdr.detectChanges();
    };
    /**
     * Destroy
     */
    AdBuilderNativeExchangeComponent.prototype.ngOnDestroy = function () {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    };
    AdBuilderNativeExchangeComponent.prototype.getMediaGroup = function () {
        var _this = this;
        return this.advertiserRepository.get(this.advertiserId)
            .pipe(mergeMap(function (advertiser) {
            if (advertiser.owner.type === 'Publisher') {
                return _this.publisherRepository.get(advertiser.owner.refId)
                    .pipe(mergeMap(function (owner) { return _this.mediaGroupRepository.get(owner.mediaGroup); }));
            }
            return _this.mediaGroupRepository.get(advertiser.owner.refId);
        }), map(function (mediaGroup) { return mediaGroup.refId; }));
    };
    /**
     * Update Assets
     * @returns {any[]}
     */
    AdBuilderNativeExchangeComponent.prototype.updateAssets = function () {
        var _this = this;
        var assets = [];
        var _loop_1 = function (prop) {
            var asset = {
                id: null,
                required: null,
                version: null
            };
            var demandAsset = this_1.demandAssets[prop.modelKey];
            var key = this_1.demandAssets[prop.modelKey].typeDescription;
            if (!key) {
                return "continue";
            }
            asset[key] = {};
            asset.id = demandAsset.id || null;
            asset.required = demandAsset.requirement || false;
            asset.version = demandAsset.version || null;
            if (demandAsset.typeDescription === this_1.iabAssets.title
                || demandAsset.typeDescription === this_1.iabAssets.data) {
                asset[key].text = demandAsset.model;
                asset[key].len = this_1.setTextAssetLen(demandAsset);
            }
            if (demandAsset.typeId) {
                asset[key].type = demandAsset.typeId;
            }
            if (demandAsset.typeDescription === this_1.iabAssets.img && demandAsset.model) {
                asset[key].url = demandAsset.model;
                this_1.demandAssetService.fetchImageDimensions(demandAsset.model).subscribe(function (_a) {
                    var width = _a[0], height = _a[1];
                    asset[key].height = height;
                    asset[key].width = width;
                    if (height === 0 || width === 0) {
                        _this.nativeForm.controls[prop.modelKey].setErrors({
                            valid: false
                        });
                    }
                });
            }
            if (demandAsset.model && !this_1.disableField(prop.modelKey)) {
                assets.push(asset);
            }
        };
        var this_1 = this;
        for (var _i = 0, _a = this.demandAssetProperties; _i < _a.length; _i++) {
            var prop = _a[_i];
            _loop_1(prop);
        }
        return assets;
    };
    AdBuilderNativeExchangeComponent.prototype.isValidUrl = function (url) {
        if (!url || url === '') {
            return true;
        }
        return ValidationService.isValidUrl(url);
    };
    AdBuilderNativeExchangeComponent.prototype.isValidImage = function (type) {
        this.cdr.markForCheck();
        if (this.creative && this.creative.native && this.creative.native.assets) {
            for (var _i = 0, _a = this.creative.native.assets; _i < _a.length; _i++) {
                var asset = _a[_i];
                if ('img' in asset) {
                    if (type === asset['img']['type']) {
                        var height = asset['img']['height'];
                        var width = asset['img']['width'];
                        if (height === 0 || width === 0) {
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    };
    /**
     * Set Text Asset Len
     * @param demandAsset
     * @returns {any}
     */
    AdBuilderNativeExchangeComponent.prototype.setTextAssetLen = function (demandAsset) {
        if (!demandAsset.model) {
            return;
        }
        return demandAsset.model.length;
    };
    AdBuilderNativeExchangeComponent.prototype.calculateDisplayLength = function (demandAsset, multiLine) {
        if (!demandAsset || !demandAsset.model) {
            return 0;
        }
        if (multiLine) {
            var newlines = (demandAsset.model.match(/\n/g) || []).length;
            // Multiline fields will have newlines converted to <br>,
            // so we add 3 additional characters to the count for each one
            return demandAsset.model.length + (newlines * 3);
        }
        return demandAsset.model.length;
    };
    AdBuilderNativeExchangeComponent.prototype.clearAsset = function (assetrestrictions) {
        for (var _i = 0, _a = this.demandAssetProperties; _i < _a.length; _i++) {
            var prop = _a[_i];
            if (assetrestrictions && !assetrestrictions[prop.modelKey]) {
                this.demandAssets[prop.modelKey] = {
                    requirement: false,
                    model: null,
                    typeId: null,
                    typeDescription: null,
                    id: null
                };
            }
        }
    };
    /**
     * Refresh Preview
     * @param $event
     * @param prop
     */
    AdBuilderNativeExchangeComponent.prototype.refreshPreview = function ($event, prop) {
        var _this = this;
        if (prop.assetKey === 'img') {
            if (this.demandAssets[prop.modelKey].model) {
                var image_1 = new Image();
                image_1.src = this.demandAssets[prop.modelKey].model;
                image_1.onload = function () {
                    _this.demandAssets[prop.modelKey].width = image_1.width;
                    _this.demandAssets[prop.modelKey].height = image_1.height;
                };
            }
            else {
                this.demandAssets[prop.modelKey].width = null;
                this.demandAssets[prop.modelKey].height = null;
            }
        }
        this.demandAssets[prop.modelKey].typeDescription = prop.assetKey;
        this.demandAssets[prop.modelKey].typeId = prop.type;
        this.setIsModified();
        this.update$.next();
    };
    /**
     * Update Ifaas Url
     * @param {string} url
     */
    AdBuilderNativeExchangeComponent.prototype.updateIfaasUrl = function (url) {
        if (url === this.creative.mediaUrl) {
            var id = this.creative.id ? this.creative.id : this.creative.created;
            this.isCreativeSizeValid.next({ id: id, isValid: true });
            return;
        }
        this.validateIfaasImageSize(url);
        this.creative.mediaUrl = url;
    };
    AdBuilderNativeExchangeComponent.prototype.validateIfaasImageSize = function (url) {
        var _this = this;
        var id = this.creative.id ? this.creative.id : this.creative.created;
        // Cancel any previous calls before making a new one for the same creative
        if (this.ifaasImageFetchPendingMap.has(id)) {
            this.ifaasImage$.unsubscribe();
            // Remove pending message
            this.notificationService.remove(this.ifaasImageFetchPendingMap.get(id));
        }
        // Set validation to false initially
        this.isCreativeSizeValid.next({ id: id, isValid: false });
        var warningId = this.hasAGifFile(url)
            ? this.notificationService.warning('Validating the creative size, please wait. This could take up to a minute.', '', 60)
            : null;
        // Add creative ID and pending message ID to pending fetch list
        this.ifaasImageFetchPendingMap.set(id, warningId);
        // Add a cache buster param to ifaas url to avoid getting a cors error
        this.ifaasImage$ = this.http.get(this.addCacheBusterParam(url), {
            headers: new HttpHeaders({ Accept: 'image/jpeg, image/png, image/gif' }),
            responseType: 'blob',
        }).pipe(map(function (resp) { return resp.size / 1000000; })).subscribe(function (fileSize) {
            if (warningId !== null) {
                _this.notificationService.remove(warningId);
            }
            if (fileSize > MAX_IFAAS_FILE_SIZE) {
                _this.notificationService.error('The resulting creative exceeds the maximum size of 4MB. Please use a different file.');
                _this.isCreativeSizeValid.next({ id: id, isValid: false });
            }
            else {
                _this.isCreativeSizeValid.next({ id: id, isValid: true });
            }
            // Remove creative ID from pending fetch list once completed
            _this.ifaasImageFetchPendingMap.delete(id);
        }, function () {
            // Skip vaidation when ifaas does not respond
            _this.isCreativeSizeValid.next({ id: id, isValid: true });
            // Remove creative ID from pending fetch list once completed
            _this.ifaasImageFetchPendingMap.delete(id);
        });
    };
    AdBuilderNativeExchangeComponent.prototype.hasAGifFile = function (inputUrl) {
        var url = new URL(inputUrl);
        var iUrl = url.searchParams.get('iUrl');
        var lUrl = url.searchParams.get('lUrl');
        return iUrl.slice(-4) === GIF_FILE_EXTENSION || lUrl.slice(-4) === GIF_FILE_EXTENSION;
    };
    AdBuilderNativeExchangeComponent.prototype.addCacheBusterParam = function (inputUrl) {
        var currentTime = Date.now().toString();
        var url = new URL(inputUrl);
        url.searchParams.set('updatedAt', currentTime);
        return url.toJSON();
    };
    /**
     * Create Creative
     * @returns {Creative}
     */
    AdBuilderNativeExchangeComponent.prototype.createCreative = function () {
        return new Creative({
            type: 'image',
            isNative: true,
            assetRestrictions: [],
            isDirectSoldTrafficking: false,
            created: Date.now()
        });
    };
    /**
     * Refresh On Click
     * @param {Creative} creative
     */
    AdBuilderNativeExchangeComponent.prototype.refresh = function (creative) {
        this.creative$.next(creative);
    };
    /**
     * Reset
     */
    AdBuilderNativeExchangeComponent.prototype.reset = function () {
        this.nativeForm.reset();
        this.cdr.detectChanges();
        this.creative = this.createCreative();
    };
    /**
     * Creative has assets
     * @returns {boolean}
     */
    AdBuilderNativeExchangeComponent.prototype.hasAssets = function () {
        return this.creative.native.assets.length > 0 && this.hasRequiredAsset();
    };
    AdBuilderNativeExchangeComponent.prototype.setAllAssetsRequiredForNCT = function () {
        if (this.isNCTCreative) {
            for (var _i = 0, _a = this.demandAssetProperties; _i < _a.length; _i++) {
                var prop = _a[_i];
                this.demandAssets[prop.modelKey].requirement = true;
            }
            this.creative.blueprintId = null;
            this.creative.isDirectSoldTrafficking = true;
        }
    };
    /**
     * Creative has required asset
     * @returns {boolean}
     */
    AdBuilderNativeExchangeComponent.prototype.hasRequiredAsset = function () {
        return this.creative.native && this.creative.native.assets.filter(function (asset) { return asset.required; }).length > 0;
    };
    Object.defineProperty(AdBuilderNativeExchangeComponent.prototype, "isNativeDirectSoldCreative", {
        /**
         * Is creative native direct sold
         */
        get: function () {
            // To support both ad library ad builder form and line item ad builder form
            // On ad library it checks if the creative has a blueprint id or not to determine if it's direct sold or not
            // isDirectSold is only being used in the frontend for copying a direct sold creative which is being set by the AdsCopyResolver
            return (this.creative && !!this.creative.blueprintId) || this.creative.isDirectSold;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdBuilderNativeExchangeComponent.prototype, "isNCTCreative", {
        /**
         * Is creative NCT
         */
        get: function () {
            return this.creative && ((this.isDirectSoldCampaign && !this.creative.blueprintId) || this.creative.isDirectSoldTrafficking);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdBuilderNativeExchangeComponent.prototype, "isBlueprintRequired", {
        get: function () {
            return (this.isDirectSoldCampaign || this.isNativeDirectSoldCreative || this.allowBlueprintChange) && !this.rolloutNativeCreativeTrafficking;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdBuilderNativeExchangeComponent.prototype, "isDirectSoldCampaign", {
        get: function () {
            // If there is a list of line items:
            if (this.selectedCampaigns && this.selectedCampaigns.length > 0) {
                return !this.findFirstNonDirectSoldCampaign(this.selectedCampaigns);
            }
            return (this.campaign && this.isNotExchangeCampaign(this.campaign) &&
                (this.campaign.isDirectSold || this.campaign.isHouse || this.hasAnyPlacementTargets() || this.isImpressionBasedCampaign(this.campaign)));
        },
        enumerable: true,
        configurable: true
    });
    AdBuilderNativeExchangeComponent.prototype.isImpressionBasedCampaign = function (campaign) {
        return campaign && campaign.isDirectSold && campaign.isImpressionBased;
    };
    AdBuilderNativeExchangeComponent.prototype.hasAnyPlacementTargets = function () {
        return (this.hasPlacementTargetingCampaign || this.hasPlacementTargetingStrategy);
    };
    AdBuilderNativeExchangeComponent.prototype.isNotExchangeCampaign = function (campaign) {
        return campaign && !campaign.isExchange;
    };
    AdBuilderNativeExchangeComponent.prototype.findFirstNonDirectSoldCampaign = function (campaigns) {
        var _this = this;
        return campaigns.find(function (campaign) { return ((campaign && !_this.isNotExchangeCampaign(campaign)) &&
            !(campaign.isDirectSold || campaign.isHouse || _this.hasAnyPlacementTargets() || _this.isImpressionBasedCampaign(campaign))); }) != null;
    };
    AdBuilderNativeExchangeComponent.prototype.handleImageUploadError = function (prop, message) {
        this.notificationService.error(message);
        // we get a reference to both file input directives
        var _a = this.fileInputComponents.toArray(), mainImageFileInput = _a[0], logoImageFileInput = _a[1];
        if (prop.modelKey === 'mainImage') {
            mainImageFileInput.removeFile(0);
        }
        else if (prop.modelKey === 'logoImage') {
            logoImageFileInput.removeFile(0);
        }
    };
    /**
     * Grabs the upload event, and does some safety
     * checks prior to actually loading update method
     * @param files
     * @param prop
     */
    AdBuilderNativeExchangeComponent.prototype.upload = function (files, prop) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var file, size, maxFileSize, type, arrayBuffer, intArray, reader, numFrames, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        file = null;
                        if (files && files.length > 0) {
                            file = files[0];
                        }
                        if (!file) {
                            this.demandAssets[prop.modelKey] = {
                                requirement: false,
                                model: null,
                                typeId: null,
                                typeDescription: null,
                                id: null
                            };
                            this.refreshPreview(files, prop);
                            return [2 /*return*/];
                        }
                        size = file.file.size / 1024;
                        maxFileSize = prop.modelKey === 'mainImage' ? MAX_NATIVE_FILE_SIZE : MAX_LOGO_FILE_SIZE;
                        if (size > maxFileSize) {
                            this.handleImageUploadError(prop, "The uploaded file exceeds the maximum file size of " + maxFileSize + " kb. Please try uploading another file.");
                            return [2 /*return*/];
                        }
                        type = file.file.type;
                        if (!(type === IMAGE_TYPE_GIF)) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, file.file.arrayBuffer()];
                    case 2:
                        arrayBuffer = _a.sent();
                        intArray = new Uint8Array(arrayBuffer);
                        reader = new GifReader(intArray);
                        numFrames = reader.numFrames();
                        if (numFrames > MAX_GIF_NUM_FRAMES) {
                            this.handleImageUploadError(prop, "The uploaded GIF exceeds the maximum number of " + MAX_GIF_NUM_FRAMES + " frames. Please try uploading another file.");
                            return [2 /*return*/];
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        this.handleImageUploadError(prop, 'Something went wrong. Please try uploading another file.');
                        return [2 /*return*/];
                    case 4:
                        this.load(file, prop);
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Passes file on to the demand asset service, and saves it
     * @param file
     * @param prop
     */
    AdBuilderNativeExchangeComponent.prototype.load = function (file, prop) {
        var _this = this;
        this.demandAssetService.saveImageAssetForPreview(file, prop, this.isAssetRequired(prop.modelKey)).subscribe(function (assetDetails) {
            _this.demandAssetService.setAssets(_this.demandAssets, [assetDetails], _this.update$);
            _this.cdr.markForCheck();
        });
    };
    AdBuilderNativeExchangeComponent.prototype.refreshPreviews = function () {
        this.preview.refresh();
        this.previewMobile.refresh();
    };
    Object.defineProperty(AdBuilderNativeExchangeComponent.prototype, "readOnly", {
        get: function () {
            return (!this.auth.isAdmin && !this.auth.isNativeLikeUser && !(this.isNativeDirectSoldCreative || this.isDirectSoldCampaign)) ||
                (this.campaign && !this.auth.canManageMediaGroup && !this.auth.canManagePublisher && !this.auth.isAdOps
                    && !this.auth.isAdmin && (this.isNativeDirectSoldCreative || this.isDirectSoldCampaign));
        },
        enumerable: true,
        configurable: true
    });
    AdBuilderNativeExchangeComponent.prototype.setBlueprintId = function (id) {
        if (id) {
            this.blueprint$.next(id);
        }
        else {
            this.blueprint$.next(null);
            // When you remove the blueprint remove the alert if there was one
            if (this.showBlueprintAlert) {
                this.showBlueprintAlert = false;
            }
        }
        this.update$.next();
    };
    AdBuilderNativeExchangeComponent.prototype.focusIfBlueprintIsDeselected = function (model) {
        if (!model._selected) {
            model.editor.nativeElement.focus();
        }
    };
    AdBuilderNativeExchangeComponent.prototype.setIsModified = function () {
        this.creative.isModified = true;
    };
    AdBuilderNativeExchangeComponent.prototype.setSupplyAssets = function (blueprintId) {
        var _this = this;
        if (this.blueprintSubscription) {
            this.blueprintSubscription.unsubscribe();
        }
        this.blueprintSubscription = this.blueprintRepository.get(blueprintId).pipe(map(function (blueprint) {
            _this.assetRestrictions = [];
            blueprint.assets.forEach(function (asset) {
                if (asset.title) {
                    _this.assetRestrictions.headline = asset;
                }
                if (asset.img) {
                    switch (asset.img.type) {
                        case 1:
                            _this.assetRestrictions.logoImage = asset;
                            break;
                        case 3:
                            _this.assetRestrictions.mainImage = asset;
                            break;
                    }
                }
                if (asset.data) {
                    switch (asset.data.type) {
                        case 1:
                            _this.assetRestrictions.sponsored = asset;
                            break;
                        case 2:
                            _this.assetRestrictions.description = asset;
                            break;
                        case 12:
                            _this.assetRestrictions.callToAction = asset;
                            break;
                    }
                }
            });
        })).subscribe(function () {
            _this.creative.assetRestrictions = _this.assetRestrictions;
            if (_this.creative.assetRestrictions && _this.isNativeDirectSoldCreative) {
                Object.keys(_this.creative.assetRestrictions)
                    .filter(function (asset) { return _this.creative.assetRestrictions[asset].required; })
                    .map(function (assetName) {
                    _this.demandAssets[assetName].requirement = _this.creative.assetRestrictions[assetName].required;
                });
            }
            _this.clearAsset(_this.assetRestrictions);
            _this.setIsModified();
            _this.update$.next();
        }, function () {
            _this.showBlueprintAlert = true;
        });
    };
    AdBuilderNativeExchangeComponent.prototype.isAssetRequired = function (asset) {
        if (this.creative.assetRestrictions && this.creative.assetRestrictions[asset]) {
            return this.creative.assetRestrictions[asset].required;
        }
        return false;
    };
    AdBuilderNativeExchangeComponent.prototype.setAssetLength = function (asset) {
        if (this.creative.assetRestrictions && this.creative.assetRestrictions[asset]) {
            if (this.creative.assetRestrictions[asset].title) {
                return this.creative.assetRestrictions[asset].title.len;
            }
            return this.creative.assetRestrictions[asset].data.len;
        }
        else if (this.isDirectSoldCampaign || this.isNCTCreative) {
            return SupplyAssetProperties.find(function (supplyAsset) { return supplyAsset.modelKey === asset; }).max;
        }
        return false;
    };
    AdBuilderNativeExchangeComponent.prototype.setAssetSize = function (asset) {
        if (this.creative.assetRestrictions && this.creative.assetRestrictions[asset]) {
            if (this.creative.assetRestrictions[asset].img.w || this.creative.assetRestrictions[asset].img.h) {
                return 'Image asset must be ' + this.creative.assetRestrictions[asset].img.w + 'x' + this.creative.assetRestrictions[asset].img.h;
            }
            if (this.creative.assetRestrictions[asset].img.wmin || this.creative.assetRestrictions[asset].img.hmin) {
                return 'Image asset should be at least ' + this.creative.assetRestrictions[asset].img.wmin + 'x' + this.creative.assetRestrictions[asset].img.hmin;
            }
        }
        else if (this.isDirectSoldCampaign || this.isNCTCreative) {
            if (asset === 'mainImage') {
                return 'Image asset should be at least 50x50';
            }
            if (asset === 'logoImage') {
                return 'Image asset should be at least 1x1';
            }
        }
        return false;
    };
    AdBuilderNativeExchangeComponent.prototype.disableField = function (asset) {
        // Should only be used for direct sold creatives - creatives that have a blueprint id selected only
        // If the blueprint doesn't support a specific asset, then that asset is disabled in the form from being added
        // Ex. A logo only blueprint would only allow a user to select a logo asset
        return this.isNativeDirectSoldCreative && this.creative.assetRestrictions && !this.creative.assetRestrictions[asset];
    };
    AdBuilderNativeExchangeComponent.prototype.getInputErrorMessage = function (errorHelper, inputType) {
        if (errorHelper && errorHelper.apiErrors) {
            this.setErrorInputs(errorHelper);
        }
        switch (inputType) {
            case ('headline'):
                return this.errorMessages[DemandAssetTypeId.Title] || '';
            case ('sponsored'):
                return this.errorMessages[DemandAssetTypeId.SponsoredBy] || '';
            case ('description'):
                return this.errorMessages[DemandAssetTypeId.Description] || '';
            case ('callToAction'):
                return this.errorMessages[DemandAssetTypeId.CTA] || '';
            case ('logoImage'):
                return this.errorMessages[DemandAssetTypeId.ImageIcon] || '';
            case ('mainImage'):
                return this.errorMessages[DemandAssetTypeId.ImageMain] || '';
            default:
                return '';
        }
    };
    AdBuilderNativeExchangeComponent.prototype.setErrorInputs = function (errorHelper) {
        var _this = this;
        if (errorHelper && errorHelper.apiErrors) {
            errorHelper.apiErrors.forEach(function (error) {
                var typeId = error.details.split(' - ')[0].split(':')[1];
                _this.errorMessages[typeId] = error.details.split(' - ')[1];
            });
        }
    };
    AdBuilderNativeExchangeComponent.prototype.getMaxAssetLength = function (asset) {
        if (this.isNativeDirectSoldCreative) {
            if (this.creative.assetRestrictions) {
                if (!this.creative.assetRestrictions[asset]) {
                    if (asset === 'description') {
                        return 2000;
                    }
                    return 255;
                }
                if (this.creative.assetRestrictions[asset].title) {
                    return Number(this.creative.assetRestrictions[asset].title.len);
                }
                return Number(this.creative.assetRestrictions[asset].data.len);
            }
        }
        else if (this.isDirectSoldCampaign || this.isNCTCreative) {
            return SupplyAssetProperties.find(function (supplyAsset) { return supplyAsset.modelKey === asset; }).max;
        }
        return null;
    };
    AdBuilderNativeExchangeComponent.prototype.hasSizeRestrictionError = function (asset) {
        var demandAsset = this.demandAssets[asset];
        if (!demandAsset.model) {
            return false;
        }
        if (this.isNativeDirectSoldCreative) {
            if (this.creative.assetRestrictions && this.creative.assetRestrictions[asset] && demandAsset.model) {
                var supplyAsset = this.creative.assetRestrictions[asset];
                if ('img' in supplyAsset) {
                    if (supplyAsset.img.wmin && supplyAsset.img.hmin) {
                        if (demandAsset.width < supplyAsset.img.wmin || demandAsset.height < supplyAsset.img.hmin) {
                            return true;
                        }
                    }
                    if (supplyAsset.img.w && supplyAsset.img.h) {
                        if (demandAsset.width !== supplyAsset.img.w || demandAsset.height !== supplyAsset.img.h) {
                            return true;
                        }
                    }
                }
            }
        }
        else if (this.isDirectSoldCampaign || this.isNCTCreative) {
            if (asset === 'mainImage') {
                if (demandAsset.width < 50 || demandAsset.height < 50) {
                    return true;
                }
            }
            else if (asset === 'logoImage') {
                if (demandAsset.width < 1 || demandAsset.height < 1) {
                    return true;
                }
            }
        }
        return false;
    };
    AdBuilderNativeExchangeComponent.prototype.hasAssetErrors = function () {
        var _this = this;
        return DemandAssetProperties.some(function (prop) { return _this.hasSizeRestrictionError(prop.modelKey); });
    };
    Object.defineProperty(AdBuilderNativeExchangeComponent.prototype, "enableBlueprintEdit", {
        get: function () {
            return this.enableCreativeBlueprintEdit || this.enableNctCreativeBlueprintEdit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdBuilderNativeExchangeComponent.prototype, "enableCreativeBlueprintEdit", {
        /**
         * Enable editing blueprint for non NCT creatives.
         */
        get: function () {
            if (this.selectedCampaigns.length > 0) {
                return !this.readOnly && this.isDirectSoldCampaign && this.allowBlueprintChange;
            }
            return !this.readOnly && (!this.creative.id || this.allowBlueprintChange);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdBuilderNativeExchangeComponent.prototype, "enableNctCreativeBlueprintEdit", {
        /**
         * Enable editing blueprint for NCT creatives.
         */
        get: function () {
            return this.isDirectSoldCampaign && this.rolloutNativeCreativeTrafficking;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdBuilderNativeExchangeComponent.prototype, "displayBlueprintField", {
        get: function () {
            // If there is an exchange campaign
            if (this.selectedCampaigns.length > 0 && !this.isDirectSoldCampaign) {
                this.creative.blueprintId = null;
                return false;
            }
            return this.isNativeDirectSoldCreative || this.isDirectSoldCampaign || this.isNCTCreative || this.allowBlueprintChange;
        },
        enumerable: true,
        configurable: true
    });
    AdBuilderNativeExchangeComponent.prototype.handleOnShowSecondaryTracker = function (isExpanded) {
        if (isExpanded === false) {
            this.creative.urlTracking2 = null;
        }
    };
    return AdBuilderNativeExchangeComponent;
}());
export { AdBuilderNativeExchangeComponent };
