import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Pixel } from 'app/shared/models';
import { ESParams } from 'app/shared/helpers/es-query-builder';
import { BackendRepository, Query } from './backend-repository';
import { catchError, map } from 'rxjs/operators';
import { empty } from 'rxjs';

@Injectable()
export class PixelRepository extends BackendRepository<Pixel> {
    public constructor(http: HttpClient) {
        super(http, '/pixel', Pixel);
    }

    public searchAsOptions(query: Query = {}) {
        return this.all(query).pipe(map(pixels => {
            return pixels.map(
                    option => {
                        return {
                            key: option.id,
                            value: option.id,
                            label: `(${option.refId}): ${option.name}`,
                            data: option
                        };
                    });
        }));
    }

    basicSearchAsOptions(advertiser: string, tagType: string) {
        return this.consume(page => {
            let params = `p/${page}/n/1000/status/active/advertiser/${advertiser}`;
            if (tagType) {
                params += '/tagType/' + tagType;
            }
            return this.http.get(this.url(`/search/pixel?params=${params}`));
        }).pipe(map(pixels => {
            return pixels.map(
                option => {
                    return {
                        key: option.id,
                        value: option.id,
                        label: `(${option.refId}): ${option.name}`,
                        data: option
                    };
                });
        }));
    }

    eSearch(params: ESParams) {
        return this.asyncSearch(params, '/lies/search/conversion-pixels')
            .pipe(catchError(() => empty()));
    }
}
