import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pluralize'
})
export class PluralizePipe implements PipeTransform {
    transform(noun: string, n?: number) {

        if (n !== undefined && n > 0 && n < 2) {
            return noun;
        }

        if (noun[noun.length - 1] === 'y') {
            return noun.slice(0, -1) + 'ies';
        }

        return noun + 's';
    }
}
