 <div
    cdkDropList
    #activeList="cdkDropList"
    [cdkDropListData]="items"
    [cdkDropListConnectedTo]="[activeList]"

    (cdkDropListDropped)="drop($event)"
    >
    <div class="draggable-list draggable-list-box" *ngFor="let item of items; let i = index" cdkDrag [class.lock]="isLocked" [cdkDragDisabled]="isLocked">
        <div class="{{drag}}">
            <i class="fa fa-bars fa-fw" aria-hidden="true"  *ngIf="!isLocked"></i>
            {{item.displayName}}
        </div>
        <i (click)="remove(i)" class="{{icon}}" aria-hidden="true"></i>
    </div>
 </div>
