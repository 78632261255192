import { Model } from '../model';

export enum SupplyImageAssetType {
    Icon = 1,
    Main = 3
}

export const SupplyImageAssets = [{
    id: SupplyImageAssetType.Icon,
    key: 'logoImage'
}, {
    id: SupplyImageAssetType.Main,
    key: 'mainImage'
}];

export class SupplyImageAsset extends Model {
    type: SupplyImageAssetType;
    minWidth: number;
    minHeight: number;
    width: number;
    height: number;
    aspectRatio?: string;

    constructor(data: any = {}) {
        super(data);
    }

    serialize(): string {
        const imageAsset = this.clone(SupplyImageAsset);
        delete imageAsset._isClone;
        if (!imageAsset.version) {
            delete imageAsset.version;
        }
        if (!imageAsset.refId) {
            delete imageAsset.refId;
        }

        if (!imageAsset.height) {
            delete imageAsset.height;
        }

        if (!imageAsset.width) {
            delete imageAsset.width;
        }

        if (!imageAsset.minWidth) {
            delete imageAsset.minWidth;
        }

        if (!imageAsset.minHeight) {
            delete imageAsset.minHeight;
        }

        return JSON.parse(JSON.stringify(imageAsset));
    }

    get entity(): string {
        return 'Native Supply Image Asset';
    }
}
