import * as tslib_1 from "tslib";
import { Model } from './model';
var Newsletter = /** @class */ (function (_super) {
    tslib_1.__extends(Newsletter, _super);
    function Newsletter(newsletter) {
        if (newsletter === void 0) { newsletter = {}; }
        var _this = _super.call(this) || this;
        Object.assign(_this, newsletter, {
            esp: newsletter.esp !== null ? newsletter.esp : 'other'
        });
        return _this;
    }
    Newsletter.prototype.serialize = function (asJson) {
        if (asJson === void 0) { asJson = false; }
        var newsletter = this.clone(Newsletter);
        delete newsletter.primaryCategory;
        delete newsletter.adSlots;
        delete newsletter.createdByUser;
        delete newsletter.modifiedByUser;
        delete newsletter.publisherCategory;
        delete newsletter.publisherObj;
        if (newsletter.esp === 'other') {
            newsletter.esp = null;
        }
        if (newsletter.emailTagReplacement === '') {
            newsletter.emailTagReplacement = null;
        }
        if (newsletter.placementTagReplacement === '') {
            newsletter.placementTagReplacement = null;
        }
        if (newsletter.listTagReplacement === '') {
            newsletter.listTagReplacement = null;
        }
        if (newsletter.placementTagReplacement === '') {
            newsletter.placementTagReplacement = null;
        }
        if (newsletter.lceeKeyReplacement === '') {
            newsletter.lceeKeyReplacement = null;
        }
        if (newsletter.lceeTagReplacement === '') {
            newsletter.lceeTagReplacement = null;
        }
        if (asJson) {
            return newsletter;
        }
        return JSON.stringify(newsletter);
    };
    Object.defineProperty(Newsletter.prototype, "entity", {
        get: function () {
            return 'Newsletter';
        },
        enumerable: true,
        configurable: true
    });
    return Newsletter;
}(Model));
export { Newsletter };
