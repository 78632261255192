import * as tslib_1 from "tslib";
import { Model } from './model';
var MediaGroup = /** @class */ (function (_super) {
    tslib_1.__extends(MediaGroup, _super);
    function MediaGroup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MediaGroup.prototype.serialize = function () {
        var mediaGroup = this.clone(MediaGroup);
        mediaGroup.agreedUserMatching = !!mediaGroup.agreedUserMatching;
        if (!mediaGroup.dealSspFee) {
            delete mediaGroup.dealSspFee;
        }
        delete mediaGroup.advertisers;
        delete mediaGroup.contact;
        delete mediaGroup.flatFee;
        delete mediaGroup.marginalFee;
        if (!this.salesforceId) {
            delete mediaGroup.salesforceId;
        }
        if (mediaGroup.type === 'Media Group') {
            delete mediaGroup.selfServe;
        }
        else {
            delete mediaGroup.executive;
            delete mediaGroup.sspFee;
            delete mediaGroup.contractType;
            delete mediaGroup.blocklists;
            delete mediaGroup.monthlyMinimum;
            delete mediaGroup.tagsUrlPrefix;
        }
        delete mediaGroup.agreedExclusivity;
        delete mediaGroup.includedCampaigns;
        delete mediaGroup.agreedRepurposing;
        return JSON.stringify(mediaGroup);
    };
    Object.defineProperty(MediaGroup.prototype, "managedBy", {
        get: function () {
            return this.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MediaGroup.prototype, "entity", {
        get: function () {
            return 'Media Group';
        },
        enumerable: true,
        configurable: true
    });
    return MediaGroup;
}(Model));
export { MediaGroup };
