import { EventEmitter, QueryList } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { NgForm, NgModel } from '@angular/forms';
import { CategoryRepository, NewsletterRepository, PublisherRepository, MediaGroupRepository } from 'app/core/repositories';
import { Newsletter } from 'app/shared/models';
import { IdService } from 'app/core/id.service';
import { AuthorizationService } from 'app/core';
import { AdSlotCoordinatedLineItemsDialogComponent } from 'app/shared/components/ad-slot-coordinated-line-items-dialog';
import { Flag, LaunchDarklyService } from 'app/core/launch-darkly.service';
import { distinctUntilChanged } from 'rxjs/operators';
import NEWSLETTER_TYPES from "app/shared/models/newsletter-types.json";
var NewsletterSingleComponent = /** @class */ (function () {
    function NewsletterSingleComponent(auth, categoryRepository, newsletterRepository, publisherRepository, mediaGroupRepository, id, launchDarklyService) {
        this.auth = auth;
        this.categoryRepository = categoryRepository;
        this.newsletterRepository = newsletterRepository;
        this.publisherRepository = publisherRepository;
        this.mediaGroupRepository = mediaGroupRepository;
        this.id = id;
        this.launchDarklyService = launchDarklyService;
        this.onSaved = new EventEmitter();
        this.onCreated = new EventEmitter();
        this.onError = new EventEmitter();
        this.onLoaded = new EventEmitter();
        this.onOpenPopUp = new EventEmitter();
        this.errors = [];
        this.showAdvanced = false;
        this._useDifferentSources = false;
        this._useDifferentSourcesAdvertisers = false;
        this._useDifferentSourcesExternalAdvertisers = false;
        this.canSetCoordination$ = new BehaviorSubject(false);
        this.newsletter$ = new BehaviorSubject(null);
        this.rolloutUamEnhancements = false;
        this.rolloutAdBlocking = false;
        this.Newsletter = Newsletter;
        this.NEWSLETTER_TYPES = NEWSLETTER_TYPES;
        this.ADVERTISER_UNIQUE_MODES = [
            NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER,
            NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER_DISPLAY,
            NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER_NATIVE
        ];
        this.EXTERNAL_ADVERTISER_UNIQUE_MODES = [
            NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.EXTERNAL_ADVERTISERS,
            NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.EXTERNAL_ADVERTISERS_DISPLAY,
            NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.EXTERNAL_ADVERTISERS_NATIVE
        ];
        this.newsletter = this.newNewsletter();
        this.initCategories();
    }
    Object.defineProperty(NewsletterSingleComponent.prototype, "privateDeal", {
        get: function () {
            return this.newsletter.privateDeals;
        },
        set: function (value) {
            this.newsletter.privateDeals = value;
            if (value) {
                this.newsletter.sspControl.exchangeAllow = false;
            }
        },
        enumerable: true,
        configurable: true
    });
    NewsletterSingleComponent.prototype.initForCreate = function (publisher) {
        this.isEdit = false;
        this.categoryInitValue = null;
        this.errors = [];
        this.newsletter = new Newsletter({
            publisher: publisher.id,
            sspControl: {
                exchangeAllow: true
            }
        });
        this.publisher = publisher;
        this.newsletter.publisherObj = this.publisher;
        this.newsletter.domainTargeting = {
            type: 'inherit',
            values: []
        };
        this.newsletter.sensitiveCategoryTargeting = {
            type: 'inherit',
            values: []
        };
        this.newsletter$.next(this.newsletter);
        this.initLaunchDarklyFlags();
    };
    NewsletterSingleComponent.prototype.initForEdit = function (newsletter) {
        var _this = this;
        this.isEdit = true;
        this.errors = [];
        forkJoin([
            this.newsletterRepository.get(newsletter.id),
            this.publisherRepository.get(newsletter.publisher)
        ]).subscribe(function (_a) {
            var newsletter = _a[0], publisher = _a[1];
            _this.newsletter = newsletter;
            _this.publisher = publisher;
            _this.newsletter.publisherObj = _this.publisher;
            _this.newsletter$.next(_this.newsletter);
            _this.categoryInitValue = newsletter.category;
            if (_this.newsletter.sspControl.uniqueAds !== NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.INHERIT &&
                _this.newsletter.sspControl.uniqueAds !== NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.OFF) {
                _this._useDifferentSources = true;
            }
            _this.onLoaded.emit(true);
        }, (function (rejection) { return _this.showErrors(rejection); }));
        this.initLaunchDarklyFlags();
    };
    NewsletterSingleComponent.prototype.initCategories = function () {
        var _this = this;
        this.categoryRepository.getPrimaryCategories()
            .pipe(function (categories) { return _this.categoryRepository.convertCategoriesToOptions(categories); })
            .subscribe(function (categories) { return _this.primaryCategories = categories; });
    };
    NewsletterSingleComponent.prototype.newNewsletter = function () {
        return {
            sspControl: {}
        };
    };
    NewsletterSingleComponent.prototype.resetForm = function () {
        var _this = this;
        this.newsletter = this.newNewsletter();
        this.categoryInitValue = null;
        window.setTimeout(function () { return _this.form.reset(); }, 10);
    };
    Object.defineProperty(NewsletterSingleComponent.prototype, "useDifferentSources", {
        get: function () {
            return this._useDifferentSources;
        },
        set: function (value) {
            this.newsletter.sspControl.uniqueAds = value ? null : NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.OFF;
            this._useDifferentSources = value;
            if (this.newsletter.sspControl.uniqueAds === NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.INHERIT) {
                this._useDifferentSources = false;
            }
            if (!this.newsletter.sspControl.uniqueAds) {
                this.newsletter.sspControl.uniqueAds = NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER;
                ; // Default if no previous selection
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewsletterSingleComponent.prototype, "useDifferentSourcesAdvertisers", {
        get: function () {
            return this.ADVERTISER_UNIQUE_MODES.includes(this.newsletter.sspControl.uniqueAds);
        },
        set: function (value) {
            this._useDifferentSourcesAdvertisers = value;
            this._useDifferentSourcesExternalAdvertisers = null;
            if (value) {
                // Preserve the user's choice if it's already set
                if (!this.newsletter.sspControl.uniqueAds ||
                    !this.ADVERTISER_UNIQUE_MODES.includes(this.newsletter.sspControl.uniqueAds)) {
                    this.newsletter.sspControl.uniqueAds = NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER; // Default if no previous selection
                }
            }
            else {
                this.newsletter.sspControl.uniqueAds = NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.OFF;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewsletterSingleComponent.prototype, "useDifferentSourcesExternalAdvertisers", {
        get: function () {
            return this.EXTERNAL_ADVERTISER_UNIQUE_MODES.includes(this.newsletter.sspControl.uniqueAds);
        },
        set: function (value) {
            this._useDifferentSourcesExternalAdvertisers = value;
            this._useDifferentSourcesAdvertisers = null;
            if (value) {
                // Preserve the user's choice if it's already set
                if (!this.newsletter.sspControl.uniqueAds ||
                    !this.EXTERNAL_ADVERTISER_UNIQUE_MODES.includes(this.newsletter.sspControl.uniqueAds)) {
                    this.newsletter.sspControl.uniqueAds = NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.EXTERNAL_ADVERTISERS; // Default if no previous selection
                }
            }
            else {
                this.newsletter.sspControl.uniqueAds = NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.OFF;
            }
        },
        enumerable: true,
        configurable: true
    });
    NewsletterSingleComponent.prototype.inheritUniqueAdMode = function () {
        this.newsletter.sspControl.uniqueAds = NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.INHERIT;
        this._useDifferentSources = false;
        this._useDifferentSourcesAdvertisers = false;
        this._useDifferentSourcesExternalAdvertisers = false;
    };
    NewsletterSingleComponent.prototype.checkCoordinatedSponsorship = function () {
        return this.coordinatedLineItemsDialogComponent.showCoordination(this.newsletter);
    };
    NewsletterSingleComponent.prototype.turnOffCoordinatedSponsorship = function ($event) {
        this.onOpenPopUp.emit($event);
    };
    NewsletterSingleComponent.prototype.submit = function () {
        var _this = this;
        var newsletterPromise = Promise.resolve(this.newsletter);
        if (this.dirty) {
            newsletterPromise = this.newsletterRepository.save(this.newsletter).toPromise();
        }
        newsletterPromise
            .then(function (saveNewsletter) {
            _this.newsletter = saveNewsletter;
            _this.isEdit ? _this.onSaved.emit(_this.newsletter) : _this.onCreated.emit(_this.newsletter);
        })
            .catch(function (rejection) { return _this.showErrors(rejection); });
    };
    NewsletterSingleComponent.prototype.showErrors = function (err) {
        var _this = this;
        this.onError.emit();
        this.errors = [];
        var payload = err.error;
        if (payload.errors) {
            payload.errors.forEach(function (error) {
                _this.errors.push({
                    error: "There was an error with the " + error.details + " field."
                });
            });
        }
        else if (!err.ok) {
            this.errors.push({
                error: "Please, let us know! (" + err.toString() + ")."
            });
        }
    };
    NewsletterSingleComponent.prototype.initLaunchDarklyFlags = function () {
        var _this = this;
        if (this.launchDarklySubscription$) {
            this.launchDarklySubscription$.unsubscribe();
        }
        this.launchDarklySubscription$ = this.launchDarklyService
            .getVariation(Flag.AllowCoordination)
            .pipe(distinctUntilChanged())
            .subscribe(function (canSetCoordination) {
            _this.canSetCoordination$.next(canSetCoordination);
        });
        this.canSetCoordination$.subscribe(function (value) {
            _this.canSetCoordination = value;
        });
        this.launchDarklyService
            .getVariation(Flag.RolloutUAMEnhancements)
            .pipe(distinctUntilChanged())
            .subscribe(function (rolloutUamEnhancements) { return _this.rolloutUamEnhancements = rolloutUamEnhancements; });
        this.launchDarklyService
            .getVariation(Flag.RolloutAdBlocking)
            .pipe(distinctUntilChanged())
            .subscribe(function (rolloutAdBlocking) { return _this.rolloutAdBlocking = rolloutAdBlocking; });
    };
    Object.defineProperty(NewsletterSingleComponent.prototype, "dirty", {
        get: function () {
            var _this = this;
            return this.controls.some(function (control) {
                if (control.name === 'newsletterCategory') {
                    return _this.categoryInitValue !== control.value;
                }
                return control.dirty;
            }) || this.validEspSetting();
        },
        enumerable: true,
        configurable: true
    });
    NewsletterSingleComponent.prototype.getParentEsp = function () {
        if (this.publisher.esp === 'other') {
            return 'Other ESP';
        }
        return this.publisher.espName;
    };
    NewsletterSingleComponent.prototype.validEspSetting = function () {
        return (this.newsletter.esp !== null);
    };
    NewsletterSingleComponent.prototype.updateNewsletterEspSettings = function (newsletter) {
        this.newsletter.esp = newsletter.esp;
        this.newsletter.emailTagReplacement = newsletter.emailTagReplacement;
        this.newsletter.emailTagType = newsletter.emailTagType;
        this.newsletter.placementTagReplacement = newsletter.placementTagReplacement;
        this.newsletter.listTagReplacement = newsletter.listTagReplacement;
        this.newsletter.lceeKeyReplacement = newsletter.lceeKeyReplacement;
        this.newsletter.lceeTagReplacement = newsletter.lceeTagReplacement;
    };
    NewsletterSingleComponent.prototype.updateDemandBlockingSettings = function (newsletter) {
        if (!this.newsletter.domainTargeting) {
            this.newsletter.domainTargeting = {};
        }
        this.newsletter.domainTargeting = newsletter.domainTargeting;
        this.transformTargetingType(this.newsletter.domainTargeting.type);
        if (!this.newsletter.sensitiveCategoryTargeting) {
            this.newsletter.sensitiveCategoryTargeting = {};
        }
        this.newsletter.sensitiveCategoryTargeting = newsletter.sensitiveCategoryTargeting;
    };
    NewsletterSingleComponent.prototype.transformTargetingType = function (type) {
        switch (type) {
            case 'exclude':
                this.newsletter.domainTargeting.type = 'block';
                break;
            case 'include':
                this.newsletter.domainTargeting.type = 'allow';
                break;
        }
    };
    return NewsletterSingleComponent;
}());
export { NewsletterSingleComponent };
