import { Component, Input, Attribute } from '@angular/core';

@Component({
    selector: 'step',
    template: '<ng-container *ngIf="isActive"><ng-content></ng-content></ng-container>'
})
export class StepComponent {

    @Input('header') header: string;
    isActive = false;

    constructor(
        @Attribute('active') public activeAttr: string
    ) {
        this.isActive = activeAttr !== null;
    }

}
