import * as tslib_1 from "tslib";
import { Filter } from '../filter';
import { Operation } from '../operation';
var FilterEnum = /** @class */ (function (_super) {
    tslib_1.__extends(FilterEnum, _super);
    function FilterEnum(data) {
        if (data === void 0) { data = {}; }
        var _this = _super.call(this, data) || this;
        _this.operations = [
            Operation.Equals,
            Operation.DoesNotEqual
        ];
        _this.values = _this.values || [];
        return _this;
    }
    FilterEnum.prototype.isValid = function () {
        var _this = this;
        return _super.prototype.isValid.call(this)
            && this.query.length > 0
            && Array.isArray(this.query)
            ? this.query.every(function (query) { return _this.values.flat().includes(query); })
            : this.values.includes(this.query);
    };
    Object.defineProperty(FilterEnum.prototype, "query", {
        get: function () {
            return this._query;
        },
        set: function (value) {
            if (typeof value === 'string') {
                value = value.split(',');
            }
            this._query = value;
        },
        enumerable: true,
        configurable: true
    });
    return FilterEnum;
}(Filter));
export { FilterEnum };
