import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { ValidationService } from 'app/core/validation-service';

@Directive({
    selector: '[validate-url][formControlName],[validate-url][formControl],[validate-url][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => UrlValidator), multi: true }
    ]
})
export class UrlValidator implements Validator {

    validate(c: AbstractControl): { [key: string]: any } {
        let url = c.value;
        if (!url || url === '') {
            return null;
        }
        return ValidationService.isValidUrl(url) ? null : { 'url' : { valid: false }};
    }
}
