import { Component, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { LightboxnextComponent } from '../../lightboxnext';
import { TableColumnDirective } from '../table-column.directive';

@Component({
    selector: 'app-configure-columns',
    templateUrl: './configure-columns.component.html',
    styleUrls: ['configure-columns.component.styl'],
})
export class ConfigureColumnsComponent {

    available = [];
    active = [];
    lockedColumns = [];
    funnel = [];

    get activeCount() {
        return this.active.length;
    }

    @Output('configured') onConfigured = new EventEmitter<TableColumnDirective[]>();
    @ViewChild(LightboxnextComponent, { static: true }) lightbox: LightboxnextComponent;

    constructor() { }

    addToActive(columns: TableColumnDirective[]) {
        this.active = this.active.concat(columns);
    }

    removeFromActive(columns: TableColumnDirective[]) {
        this.available = this.available.concat(columns);
    }

    open(availableColumns: TableColumnDirective[], activeColumns: TableColumnDirective[]) {
        this.reset();
        this.active = activeColumns.filter(column => {
            if (column.locked) {
                this.lockedColumns.push(column);
                return false;
            }
            if (column.name === 'funnel') {
                this.funnel.push(column);
                return false;
            }
            return true;
        });
        this.available = availableColumns.filter(column => column.name !== 'funnel')
            .filter(column => activeColumns.findIndex(activeColumn => activeColumn.name === column.name) < 0);
        this.lightbox.show();
    }

    selectColumns(columns: TableColumnDirective[]) {
        columns.unshift(...this.lockedColumns);
        columns.unshift(...this.funnel);
        this.onConfigured.emit(columns);
        this.lightbox.hide();
    }

    private reset() {
        this.lockedColumns = [];
        this.funnel = [];
    }
}
