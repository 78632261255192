import { Component, forwardRef, OnInit, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Option } from 'app/shared/elements/dropdown';
import { AccountRepository } from 'app/core/repositories';
import { Account } from 'app/shared/models';

@Component({
    selector: 'account-id-input',
    templateUrl: './account-id-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AccountIdInputComponent),
            multi: true
        }
    ]
})
export class AccountIdInputComponent implements ControlValueAccessor, OnInit {
    @Input() disabled = false;
    account: Account | null;
    options: Option[] = [];
    private accountId: number;
    private onChange = (id: number) => {};

    constructor(
        private accountRepository: AccountRepository
    ) {}

    ngOnInit() {
        this.accountRepository.all().subscribe((accounts) => {
            this.options = (accounts as Account[]).map((account, index) => ({
                key: index,
                value: account,
                label: `(ID: ${account.id}) ${account.name}`
            }));

            this.writeValue(this.accountId);
        });
    }

    writeValue(accountId: number) {
        this.accountId = accountId;

        const accountOption = this.options.find(option => option.value.id === accountId);
        this.account = accountOption ? accountOption.value : null;
    }

    registerOnChange(fn: (obj: any) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void) {
        // implementation stub
    }

    handleChange(account: Account) {
        this.onChange(account ? parseInt(account.id.toString(), 10) : null);
    }
}
