import { BackendRepository } from 'app/core/repositories/backend-repository';
import { PublisherIdentifier } from 'app/shared/models/publisher-identifier';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class PublisherIdentifierRepository extends BackendRepository<any> {
    public constructor(http: HttpClient) {
        super(http, '/publisher-identifier', PublisherIdentifier);
    }
}
