import { Model } from './model';

export class ReportingSspforecasting extends Model {
    public serialize(): string {
        return JSON.stringify(this);
    }

    get entity() {
        return 'Reporting Sspforecasting';
    }
}
