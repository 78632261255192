import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { forkJoin, of, ReplaySubject, Subject } from 'rxjs';
import { mergeMap, map, takeUntil } from 'rxjs/operators';
import { Action } from 'app/shared/helpers/available-actions.service';
import { LiveConnectService } from 'app/core';
import { LiveConnectOverviewService } from './live-connect-overview.service';
var LiveConnectOverviewComponent = /** @class */ (function () {
    function LiveConnectOverviewComponent(title, route, router, liveConnectService, liveConnectOverviewService) {
        this.title = title;
        this.route = route;
        this.router = router;
        this.liveConnectService = liveConnectService;
        this.liveConnectOverviewService = liveConnectOverviewService;
        this.tag$ = new ReplaySubject(1);
        this.advertiser$ = this.liveConnectOverviewService.advertiser$;
        this.onDestroy$ = new Subject();
        this.actions = [
            { value: Action.ViewTags, label: 'View Tags', key: 0 },
            { value: Action.CreateConversionTracker, label: 'Create Conversion Tracker', key: 1 },
        ];
    }
    LiveConnectOverviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.pipe(takeUntil(this.onDestroy$), map(function (data) { return data.advertiser; }), mergeMap(function (advertiser) { return forkJoin(of(advertiser), _this.liveConnectService.getTagByAdvertiserId(advertiser.refId, advertiser.accountId)); })).subscribe(function (_a) {
            var advertiser = _a[0], tag = _a[1];
            _this.tag$.next(tag);
            _this.advertiser$.next(advertiser);
            _this.title.setTitle(advertiser.refId + " - LiveConnect Manager");
        });
    };
    LiveConnectOverviewComponent.prototype.ngOnDestroy = function () {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    };
    LiveConnectOverviewComponent.prototype.tagStatus = function (advertiserRefId) {
        return this.liveConnectService.tagStatus(advertiserRefId);
    };
    LiveConnectOverviewComponent.prototype.handle = function (action, advertiser) {
        switch (action) {
            case Action.CreateConversionTracker:
                this.router.navigate(['/campaign-manager/conversion-trackers/new']);
                break;
            case Action.ViewTags:
                this.router.navigate(['/measurement/live-connect-manager', advertiser.refId, 'setup']);
                break;
        }
    };
    return LiveConnectOverviewComponent;
}());
export { LiveConnectOverviewComponent };
