import * as tslib_1 from "tslib";
import { Model } from './model';
export var IdPrefix;
(function (IdPrefix) {
    IdPrefix["LIN"] = "lin";
    IdPrefix["LIN_DSP"] = "lin_dsp";
})(IdPrefix || (IdPrefix = {}));
var DeviceType;
(function (DeviceType) {
    DeviceType["DESKTOP"] = "Desktop";
    DeviceType["MOBILE"] = "Mobile";
})(DeviceType || (DeviceType = {}));
var Blueprint = /** @class */ (function (_super) {
    tslib_1.__extends(Blueprint, _super);
    function Blueprint() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Blueprint.prototype.thumbnailFactor = function (large) {
        return large ? Blueprint.largeThumbnailFactor : Blueprint.smallThumbnailFactor;
    };
    Blueprint.prototype.thumbnailWidth = function (large) {
        return Math.floor(this.width * this.thumbnailFactor(large));
    };
    Blueprint.prototype.thumbnailHeight = function (large) {
        return Math.floor(this.maxHeight * this.thumbnailFactor(large));
    };
    Blueprint.prototype.previewWidth = function (display) {
        if (display === 'mobile')
            return Math.floor(this.mobileWidth * Blueprint.previewFactor);
        return Math.floor(this.width * Blueprint.previewFactor);
    };
    Blueprint.prototype.previewHeight = function (display) {
        if (display === 'mobile')
            return Math.floor(this.mobileMaxHeight * Blueprint.previewFactor);
        return Math.floor(this.maxHeight * Blueprint.previewFactor);
    };
    Blueprint.prototype.appendPrefix = function (ifaasId) {
        var re = /^lin_/gi;
        var replaceRe = /\s/g;
        if (!re.test(ifaasId)) {
            return ''.concat('lin_', ifaasId).toLowerCase().replace(replaceRe, '_');
        }
        return ifaasId.toLowerCase().replace(replaceRe, '_');
    };
    Blueprint.prototype.serialize = function () {
        delete this._isClone;
        return JSON.stringify(this);
    };
    Object.defineProperty(Blueprint.prototype, "entity", {
        get: function () {
            return 'Blueprint';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Blueprint.prototype, "aspectRatio", {
        get: function () {
            if (this.desktopAspectRatio && this.mobileAspectRatio && this.desktopAspectRatio !== this.mobileAspectRatio) {
                return this.desktopAspectRatio + " (" + DeviceType.DESKTOP + ")\n" + this.mobileAspectRatio + " (" + DeviceType.MOBILE + ")";
            }
            if (this.desktopAspectRatio) {
                return this.desktopAspectRatio;
            }
            if (this.mobileAspectRatio) {
                return this.mobileAspectRatio + " (" + DeviceType.MOBILE + " Only)";
            }
            return 'N/A';
        },
        enumerable: true,
        configurable: true
    });
    Blueprint.smallThumbnailFactor = 0.08;
    Blueprint.largeThumbnailFactor = 0.6;
    Blueprint.previewFactor = 0.5;
    Blueprint.nativeTypes = ['text_ad', 'in_feed', 'product_listing', 'recommendation_widget'];
    return Blueprint;
}(Model));
export { Blueprint };
var BlueprintSettings = /** @class */ (function (_super) {
    tslib_1.__extends(BlueprintSettings, _super);
    function BlueprintSettings() {
        var _this = _super.call(this) || this;
        for (var _i = 0, BlueprintCoords_1 = BlueprintCoords; _i < BlueprintCoords_1.length; _i++) {
            var device = BlueprintCoords_1[_i];
            if (!_this[device.modelKey]) {
                _this[device.modelKey] = {
                    sponsoredBy: null,
                    description: null,
                    headline: null,
                    callToAction: null,
                    wrapper: null,
                    image: null,
                    isFixed: null,
                    isSynced: true,
                    hasBorder: false,
                };
            }
            for (var _a = 0, _b = Object.keys(BlueprintProperties); _a < _b.length; _a++) {
                var prop = _b[_a];
                var defaultHtml = BlueprintProperties[prop].html;
                var defaultIfaasHTML = BlueprintProperties[prop].macro;
                if (!_this[device.modelKey][prop]) {
                    _this[device.modelKey][prop] = {
                        css: new BlueprintCssOptions(),
                        defaultCss: new BlueprintCssOptions(),
                        internalHtml: null,
                        ifaasHtml: null,
                        internalStyle: null,
                        ifaasStyle: null,
                    };
                }
                _this[device.modelKey][prop].internalHtml = _this[device.modelKey][prop].internalHtml || defaultHtml;
                _this[device.modelKey][prop].ifaasHtml = _this[device.modelKey][prop].ifaasHtml || defaultIfaasHTML;
            }
        }
        return _this;
    }
    BlueprintSettings.prototype.serialize = function () {
        delete this._isClone;
        return JSON.stringify(this);
    };
    Object.defineProperty(BlueprintSettings.prototype, "entity", {
        get: function () {
            return 'BlueprintSettings';
        },
        enumerable: true,
        configurable: true
    });
    return BlueprintSettings;
}(Model));
export { BlueprintSettings };
export var BlueprintCoords = [{
        label: 'Desktop',
        modelKey: 'desktop'
    }, {
        label: 'Mobile',
        modelKey: 'mobile'
    }];
export var BlueprintProperties = {
    sponsoredBy: {
        html: 'Sponsored By: Advertisers Name Is Here',
        label: 'Sponsored By',
        modelKey: 'sponsoredBy',
        class: 'sponsoredby',
        macro: ' <#if sb??> Sponsored by ${sb!} <#else> Sponsored Content </#if>'
    },
    headline: {
        html: 'An Advertisers Headline Will Go Here To Promote A Product When You Open To Exchange Demand',
        label: 'Headline',
        modelKey: 'headline',
        class: 'title',
        macro: '${t!}'
    },
    description: {
        html: 'Description For An Ad Will Go Here. Be Mindful Of Advertisers Abiding By IAB Recommended Character Counts When Accepting Exchange Demand, Typically 200 Characters Including Spaces For The Body Of Ad.',
        label: 'Description',
        modelKey: 'description',
        class: 'description',
        macro: '${d!}'
    },
    callToAction: {
        html: 'Call To Action Shown',
        label: 'Call to action',
        modelKey: 'callToAction',
        class: 'cta',
        macro: '${cta!}'
    },
    image: {
        html: null,
        label: 'Image',
        modelKey: 'image',
        class: 'image',
        macro: '<div class="image" style="background-image: url(${i!});" />'
    },
    wrapper: {
        html: null,
        label: 'Wrapper',
        modelKey: 'wrapper',
        class: 'wrapper',
        macro: null
    },
    global: {
        html: null,
        label: 'Global',
        modelKey: 'global',
        class: 'global',
        macro: null
    }
};
export var BlueprintLayouts = {
    left: {
        ifaasDesktopId: 'lin_master_wysiwyg_d1',
        ifaasMobileId: 'lin_master_wysiwyg_m1',
        direction: 'left',
        class: 'layout-left',
        image: '/assets/images/img_left.svg',
        imageSelected: '/assets/images/img_left_selected.svg'
    },
    right: {
        ifaasDesktopId: 'lin_master_wysiwyg_d2',
        ifaasMobileId: 'lin_master_wysiwyg_m2',
        direction: 'right',
        class: 'layout-right',
        image: '/assets/images/img_right.svg',
        imageSelected: '/assets/images/img_right_selected.svg'
    },
    top: {
        ifaasDesktopId: 'lin_master_wysiwyg_d3',
        ifaasMobileId: 'lin_master_wysiwyg_m3',
        direction: 'top',
        class: 'layout-top',
        image: '/assets/images/img_top.svg',
        imageSelected: '/assets/images/img_top_selected.svg'
    },
    text: {
        ifaasDesktopId: 'lin_master_wysiwyg_d4',
        ifaasMobileId: 'lin_master_wysiwyg_m4',
        direction: 'text',
        class: 'layout-text',
        image: '/assets/images/img_none.svg',
        imageSelected: '/assets/images/img_none_selected.svg'
    }
};
var BlueprintCssOptions = /** @class */ (function () {
    function BlueprintCssOptions() {
        this['font-family'] = null;
        this['font-weight'] = null; // bold | normal
        this['font-style'] = null; // italic | normal
        this['font-size'] = null;
        this['color'] = null;
        this['text-transform'] = null; // capitalize | uppercase | lowercase
        this['text-decoration'] = null; // underline | line-through | underline line-through
        this['line-height'] = null;
        this['text-align'] = null; // left, center, right, justify
        this['margin'] = null; // top right bottom left | top right,left bottom | (top bottom), (right left)
        this['margin-left'] = null;
        this['margin-right'] = null;
        this['margin-top'] = null;
        this['margin-bottom'] = null;
        this['padding'] = null; // top right bottom left | top right,left bottom | (top bottom), (right left)
        this['padding-left'] = null;
        this['padding-right'] = null;
        this['padding-top'] = null;
        this['padding-bottom'] = null;
        this['border'] = null; // border-width border-style border-color
        this['border-style'] = null;
        this['border-width'] = null;
        this['border-radius'] = null;
        this['border-color'] = null;
        this['background-color'] = null;
        this['height'] = null;
        this['width'] = null;
        this['max-height'] = null;
        this['min-height'] = null;
        this.strike = false;
        this.underline = false;
        this.bold = false;
        this.italic = false;
        this.cssState = {
            font: {
                capitalize: false,
                uppercase: false,
                lowercase: false,
            },
            alignment: {
                left: false,
                center: false,
                right: false,
                justify: false
            }
        };
    }
    return BlueprintCssOptions;
}());
export { BlueprintCssOptions };
