import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Creative, DemandAsset, Native } from 'app/shared/models';
import { BlueprintRepository } from 'app/core/repositories';
import { IdService } from 'app/core';
import { Blueprint } from 'app/shared/models/blueprint';
import { DemandAssetService } from 'app/core/demand-asset.service';
import { DemandAssetProperties, DemandImageAssetType } from 'app/shared/models/native-demand';
import { CopyableDirective } from 'app/shared/elements/copyable';
import { TypeOfCreative } from 'app/shared/models/creative';
import { fadeIn } from 'app/shared/animations';
import warnings from 'app/core/warnings.json';

const BASE_URL = environment.ifaas;
const DEFAULT_PREVIEW_BLUEPRINT_DESKTOP = 'creative/lin_masterblueprint_left_desktop';
const DEFAULT_PREVIEW_BLUEPRINT_MOBILE = 'creative/lin_masterblueprint_left_mobile';

enum MessageType {
    Pending,
    Success,
    Error
}

@Component({
    selector: 'app-ad-builder-native-preview',
    templateUrl: './ad-builder-native-preview.html',
    styleUrls: ['./ad-builder-native-preview.styl'],
    animations: [fadeIn]
})
export class AdBuilderNativePreviewComponent implements OnDestroy, OnInit, AfterViewInit  {
    @Output() ifaasUrl = new EventEmitter<string>();
    @Input() creative: Creative = new Creative();
    @Input() display = Native.DESKTOP_DISPLAY;
    @Input() hidePreviewTitle = false;
    @Input() hidePreviewDetails = true;
    @Input() demandAssets: DemandAsset = null;
    @Input() blueprintId: number = null;
    @Input() modalPopup = false;
    @Input() isComingFromCreativePreview = false;
    // minimum 350px to ensure the COPY MEDIA URL button is rendered in a single line
    @Input() creativeRenderWidth: number = 350;
    @Input() disablePreview: boolean = false;
    @ViewChild(CopyableDirective, { static: false }) copyable: CopyableDirective;
    previewTitle: string;
    Native = Native
    blueprint: Blueprint;
    warnings = warnings;

    readonly imageUrl$ = new ReplaySubject<string>(1);
    readonly update$ = new ReplaySubject<void>(1);
    private readonly onDestroy$ = new Subject<void>();
    private readonly debounceTime = 1000;
    private readonly timeout = 3000;
    message$: Observable<MessageType> = new Subject<MessageType>();
    readonly Message = MessageType;
    private previewFactor = 0.75;

    private urlComponents = {
        headline: null,
        mainImage: null,
        description: null,
        callToAction: null,
        sponsored: null,
        logoImage: null
    };

    constructor(
        private blueprintRepository: BlueprintRepository,
        private demandAssetService: DemandAssetService,
        public id: IdService) {
    }

    ngAfterViewInit(): void {
        if (this.creative.typeOfCreative === TypeOfCreative.NativeExchange || this.blueprintId) {
            this.refresh();
        }
    }

    ngOnInit() {
        this.previewTitle = this.capitalizeFirstLetter(this.display);
        this.update$.pipe(
            debounceTime(this.debounceTime),
            takeUntil(this.onDestroy$),
            mergeMap(() => {
                // We want to render the ad in all scenarios except if isComingFromCreativePreview - In that scenario we only render if blueprintId is passed explicitly as an input.
                // During Ad create/edit a user may toggle b/w blueprints, we need to update reset the blueprintId everytime that happens.
                if (this.creative.blueprintId && !this.isComingFromCreativePreview && (!this.blueprintId || this.blueprintId != this.creative.blueprintId)) {
                    this.blueprintId = this.creative.blueprintId;
                }
                if (this.blueprintId) {
                    return this.blueprintRepository.search({ id: this.blueprintId });
                }
                return of([]);
            }),
            tap(blueprints => {
                // We can remove this map and shift when we have a Blueprint Details Command
                this.blueprint = blueprints.map(blueprint => blueprint).shift();
            }),
            tap(() => this.generateUrlComponents()),
            map(() => this.imageUrl)
        ).subscribe((imageUrl) => {
            this.message$ = of(MessageType.Pending)
            this.imageUrl$.next(imageUrl);
            this.updateImageUrl(imageUrl);
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
        this.update$.complete();
    }

    updateImageUrl(url: string) {
        this.ifaasUrl.emit(url);
    }

    // Build one object to house creative details
    generateUrlComponents() {
        if (this.creative.isNativeCreative) {
            Object.keys(this.urlComponents).forEach(key => {
                if (this.demandAssets && this.demandAssets[key]) {
                    this.urlComponents[key] = this.demandAssets[key].model;
                    for (const prop of DemandAssetProperties) {
                        if (prop.modelKey === key && prop.multiLine && this.demandAssets[key].model) {
                            // if this prop supports multiline, replace \n with <br> before rendering
                            this.urlComponents[key] = this.demandAssets[key].model.replaceAll("\n", '<br>');
                        }
                    }
                }
            });
        } else {
            this.urlComponents.mainImage = this.creative.thumbnailUrl;
            this.urlComponents.headline = this.creative.headline;
            this.urlComponents.callToAction = this.creative.callToAction;
            this.urlComponents.description = this.creative.body;
            this.urlComponents.sponsored = this.creative.sponsoredBy;
            this.urlComponents.logoImage = this.creative.logoUrl;
        }
    }

    get imageUrl() {
        const url = new URL(BASE_URL);
        url.pathname = this.pathName;
        url.searchParams.set('iUrl', this.sanitize(this.urlComponents.mainImage));
        url.searchParams.set('t', this.sanitize(this.urlComponents.headline));
        url.searchParams.set('cta', this.sanitize(this.urlComponents.callToAction));
        url.searchParams.set('d', this.sanitize(this.urlComponents.description));
        url.searchParams.set('sb', this.sanitize(this.urlComponents.sponsored));
        url.searchParams.set('lUrl', this.sanitize(this.urlComponents.logoImage));
        return url.toJSON().toString();
    }

    get showPreview() {
        return this.urlComponents.mainImage
            || this.urlComponents.headline
            || this.urlComponents.callToAction
            || this.urlComponents.description
            || this.urlComponents.sponsored
            || this.urlComponents.logoImage;
    }

    reset() {
        this.imageUrl$.next('');
    }

    refresh() {
        this.generateUrlComponents();
        this.update$.next();
    }

    copy(cp: CopyableDirective) {
        cp.copy();
        cp.copied.subscribe((copied: boolean) => {
            const messageType = copied ? MessageType.Success : MessageType.Error;
            this.setMessage(messageType);
        });
    }

    /**
     * Reset imageUrl if an image is not loaded to show preview unavailable
     */
    handleImageError() {
        this.reset();
    }

    private setMessage(messageType: MessageType) {
        this.message$ = of(messageType);

        setTimeout(() => {
            this.message$ = of(MessageType.Pending); // Clear the message after a timeout
        }, this.timeout);
    }

    private sanitize(text: string) {
        text = this.demandAssetService.encodeUtf8(text) || text;
        return text || '';
    }

    private capitalizeFirstLetter(title: string) {
        return title.replace(/^\w/, c => c.toUpperCase());
    }

    get pathName() {
        // Including NCT to ensure all pages where we only have one preview for desktop/mobile we use Master Blueprint
        if (this.isNativeExchangeOrDirectSoldTrafficking || !this.blueprint) {
            if (this.display === Native.DESKTOP_DISPLAY) {
                return DEFAULT_PREVIEW_BLUEPRINT_DESKTOP;
            }

            return DEFAULT_PREVIEW_BLUEPRINT_MOBILE;
        }
        return 'creative/' + this.blueprint.name + '_' + this.display;
    }

    // Should be removed once AdView is deprecated
    get isNativeExchangeOrDirectSoldTrafficking(): boolean {
        return this.creative.typeOfCreative === TypeOfCreative.NativeExchange ||
          (this.creative.typeOfCreative === TypeOfCreative.NativeDirectSoldTrafficking && !this.isComingFromCreativePreview);
    }

    get creativeRenderWidthAdjust(): string {
        let renderWidth = this.creativeRenderWidth;

        if (this.modalPopup) {
            renderWidth *= this.previewFactor;
        }
        if (this.display === Native.MOBILE_DISPLAY) {
            renderWidth *= this.previewFactor;
        }
        return `${Math.floor(renderWidth)}px`;
    }

    get aspectRatio(): string {
        if (this.display === Native.MOBILE_DISPLAY && this.blueprint.mobileAspectRatio) {
            return this.blueprint.mobileAspectRatio;
        }
        else if (this.display === Native.DESKTOP_DISPLAY && this.blueprint.desktopAspectRatio) {
            return this.blueprint.desktopAspectRatio;
        }
        return 'N/A';
    }

    get displayAspectRatioMismatchWarning(): boolean {
        if (!this.shouldCheckAspectRatio()) {
            return false;
        }

        const creativeAspectRatio = this.getCreativeMainImageAspectRatio();

        return creativeAspectRatio !== null && creativeAspectRatio !== this.aspectRatio;
    }

    private shouldCheckAspectRatio(): boolean {
        return this.creative.isNative && this.aspectRatio !== 'N/A' && this.aspectRatio !== undefined;
    }

    private getCreativeMainImageAspectRatio(): string {
        if (this.creative && this.creative.native && this.creative.assets) {
            const creativeMainImageAsset = this.creative.native.assets.find(asset =>
                this.isDemandImageAsset(asset) && asset.hasOwnProperty('aspectRatio')
            );

            return creativeMainImageAsset ? creativeMainImageAsset.aspectRatio : null;
        }
        return null;
    }

    private isDemandImageAsset(asset): boolean {
        return asset && asset.hasOwnProperty('img') && asset.img.hasOwnProperty('type') && asset.img.type === DemandImageAssetType.Main
    }
}
