import { Attribute, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Model } from 'app/shared/models';
import { Resettable } from 'app/shared/components/bulk-edit-lightbox';
import { IdService } from 'app/core/id.service';
import {getDotNotationField, setDotNotationField} from "app/core/utils";

@Component({
    selector: 'bulk-edit-checkbox',
    templateUrl: './bulk-edit-checkbox.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkEditCheckboxComponent implements Resettable {
    @ViewChild('input', { static: true }) input: NgModel;
    private _entities: Model[] = [];
    private _originals: Model[] = [];

    setToLabelOverride: string;
    label: string;
    entityName: string = '';
    field: string;
    description: string = '';

    constructor(
        @Attribute('set-to-label') setToLabelOverride: string = null,
        @Attribute('label') label: string,
        @Attribute('description') description: string,
        public id: IdService)
        {
            this.label = label;
            this.setToLabelOverride = setToLabelOverride;
            this.description = description;
        }

    master(value: string) {
        this.entities.forEach(entity => {
            setDotNotationField(entity, this.field, value)
        });
    }

    original(index: number) {
        return this._originals[index];
    }

    resetEntity(entity: Model) {
        let originalValue = getDotNotationField(this.original(this.entities.indexOf(entity)), this.field);
        setDotNotationField(entity, this.field, originalValue);
    }

    reset() {
        this.input.reset();
        this.entities.forEach((entity, index) => {
            let originalValue = getDotNotationField(this.original(index), this.field);
            setDotNotationField(entity, this.field, originalValue);
        });
    }

    set entities(entities: Model[]) {
        this._entities = entities;
        this._originals = entities.map(entity => entity.clone());
    }

    get entities() {
        return this._entities;
    }

    getInputField(entity: Model) {
        if (!entity) {
            return null;
        }
        return getDotNotationField(entity, this.field);
    }

    inputFieldChanged(event: boolean, entity: Model) {
        setDotNotationField(entity, this.field, event);
    }

    isDirty(): boolean {
        return this.entities.some((entity, index) => {
            let originalValue = getDotNotationField(this.original(index), this.field);
            return this.getInputField(entity) !== originalValue;
        });
    }
}
