import {
    Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef, ChangeDetectorRef
} from '@angular/core';
import { ButtonComponent } from '../button';
import { Closable } from './closable';
import { state, style, trigger, animate, transition } from '@angular/animations';

@Component({
    selector: 'popover',
    templateUrl: './popover.html',
    styleUrls: ['./popover.styl'],
    animations: [
        trigger('visibility', [
            state('visible', style({
                opacity: 1,
                transform: 'scale(1)'
            })),
            state('hidden', style({
                opacity: .5,
                transform: 'scale(.95)'
            })),
            transition('hidden => visible', animate('50ms ease-in'))
        ])
    ]
})
export class PopoverComponent extends Closable implements OnInit {
    @Input() target: EventTarget;
    @Input() event: string = 'mouseenter';
    @Input() maxWidth: number = 1000;
    @Input() margin: boolean = true;
    @Output() onHide = new EventEmitter();
    @ViewChild('popover', { static: true }) popover: ElementRef;

    position: string = 'bottom';
    visibility = 'hidden';
    _delay: any;
    left: number = null;
    top: number = null;
    width: number = null;
    nubbinLeft: number = null;
    containerLeft: number = null;
    centered: boolean = true;

    constructor (
        private cdr: ChangeDetectorRef,
        public el: ElementRef
    ) {
        super(el.nativeElement);
    }

    ngOnInit () {
        let end: string;
        let target: any = this.target;

        /**
         * If the target is a Component-like type then we will resolve the native
         * element. This means that any of our components that wish to be
         * compatible popover targets must publicly expose an `ElementRef` as its
         * member `el`.
         */

        super.ngOnInit();

        if (!target) {
            return;
        }

        if (target instanceof ButtonComponent) {
            target = target.el.nativeElement;
        }

        if (this.event === undefined) {
            if (target instanceof HTMLButtonElement) {
                this.event = 'mouseenter';
            } else if (target instanceof HTMLInputElement) {
                this.event = 'focus';
            }
        }

        if (this.event === 'mouseenter') {
            end = 'mouseleave';
        } else if (this.event === 'focus') {
            end = 'blur';
        }

        target.addEventListener(this.event, this.show.bind(this));
        target.addEventListener(end, this.hide.bind(this));
        target.style.display = 'inline-block';

        this.target = target;
    }

    update () {
        if (!this.target) {
            return;
        }

        this.cdr.markForCheck();
        this.position = 'bottom';
        this.top = null;

        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        const NUBBIN_WIDTH = 22;
        const target = <HTMLElement>this.target;

        const targetWidth: number = target.clientWidth;
        const targetOffsetLeft = target.offsetLeft;
        const el = <HTMLElement>this.popover.nativeElement;
        const elWidth = el.clientWidth;

        let elLeft: number = targetOffsetLeft - (elWidth - targetWidth) / 2;

        if (viewportWidth < elLeft + el.clientWidth) {
            elLeft = targetOffsetLeft + target.clientWidth - el.clientWidth;
            this.nubbinLeft = el.clientWidth - NUBBIN_WIDTH - (target.clientWidth - NUBBIN_WIDTH) / 2;
            this.centered = false;
        }

        this.left = elLeft;
        this.width = elWidth;

        this.top = target.offsetTop + target.clientHeight + 4;

        // 'Flip' the popover up when it is out of the viewport
        const bounds = el.getBoundingClientRect();
        if (bounds.bottom > viewportHeight) {
            this.position = 'top';
            this.top  = target.offsetTop - el.clientHeight - 4;
        }

        const menuColumn = <HTMLElement>document.getElementsByClassName('column--static')[0];
        const elRect = menuColumn ? menuColumn.getBoundingClientRect() : { left: 0, width: 0};

        if (el.getBoundingClientRect().left < elRect.left + elRect.width) {
            this.containerLeft = -(el.getBoundingClientRect().left - (elRect.left + elRect.width));
        }
    }

    show (e): void {
        e.stopPropagation();

        if (this.event === 'click' && this.visibility === 'visible') {
            this.hide();
        } else {
            super.show(e);
            this._show();
        }
    }

    _show () {
        this.visibility = 'visible';
        this.popover.nativeElement.style.display = 'block';
        this.update();
    }

    hide () {
        this.visibility = 'hidden';
        this.popover.nativeElement.style.display = 'none';
        this.left = null;
        this.top = null;
        this.onHide.emit();
    }
}
