export class OutsideClickHelper {
    public target: any;
    private onclick: (mouseEvent: MouseEvent) => void;

    constructor(private component, private onDismiss: Function = null) {
        this.onclick = (mouseEvent: MouseEvent) => {
            if (!this.target) {
                this.deactivate();
                return;
            }

            if (!this.component.contains(mouseEvent.target)
                && !this.target.el.nativeElement.contains(mouseEvent.target)) {
                this.target.hide();
                this.onDismiss && this.onDismiss()
                this.deactivate();
            }
        };
    }

    activate() {
        document.addEventListener('click', this.onclick);
    }

    deactivate() {
        document.removeEventListener('click', this.onclick);
    }
}
