import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { Logger, ConsoleLogger, Preferences, Store } from 'app/services';

import { AppRoutingModule } from './app-routing.module';

import { CoreModule } from './core';
import { PlatformModule } from './platform';
import { httpInterceptorProviders } from './http-interceptors';
import { AppComponent } from './app.component';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserAnimationsModule,
        HttpClientModule,
        CoreModule,
        PlatformModule,
        AppRoutingModule
    ],
    providers: [
        Store,
        Preferences,
        httpInterceptorProviders,
        { provide: Logger, useClass: ConsoleLogger }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
