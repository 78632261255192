import * as tslib_1 from "tslib";
import { Model } from './model';
var BidSwitchAgency = /** @class */ (function (_super) {
    tslib_1.__extends(BidSwitchAgency, _super);
    function BidSwitchAgency() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BidSwitchAgency.prototype.serialize = function () {
        var agency = {
            id: this.id,
            version: this.version,
            name: this.name,
            bidswitchAgencyId: this.bidswitchAgencyId,
            buyerId: this.buyerId,
            domains: this.domains
        };
        return JSON.stringify(agency);
    };
    Object.defineProperty(BidSwitchAgency.prototype, "entity", {
        get: function () {
            return 'BidSwitchAgency';
        },
        enumerable: true,
        configurable: true
    });
    return BidSwitchAgency;
}(Model));
export { BidSwitchAgency };
