import { InjectionToken } from '@angular/core';
import * as i0 from "@angular/core";
export var ID_PREFIX = new InjectionToken('IdPrefix');
var IdService = /** @class */ (function () {
    function IdService(prefix) {
        if (prefix === void 0) { prefix = null; }
        this.prefix = prefix;
    }
    IdService.prototype.container = function () {
        var path = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            path[_i] = arguments[_i];
        }
        return this.generate.apply(this, path.concat('container'));
    };
    IdService.prototype.control = function () {
        var path = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            path[_i] = arguments[_i];
        }
        return this.generate.apply(this, path.concat('control'));
    };
    IdService.prototype.message = function () {
        var path = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            path[_i] = arguments[_i];
        }
        return this.generate.apply(this, path.concat('message'));
    };
    IdService.prototype.header = function () {
        var path = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            path[_i] = arguments[_i];
        }
        return this.generate.apply(this, path.concat('header'));
    };
    IdService.prototype.form = function () {
        var path = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            path[_i] = arguments[_i];
        }
        return this.generate.apply(this, path.concat('form'));
    };
    IdService.prototype.text = function () {
        var path = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            path[_i] = arguments[_i];
        }
        return this.generate.apply(this, path.concat('text'));
    };
    IdService.prototype.generate = function () {
        var path = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            path[_i] = arguments[_i];
        }
        if (this.prefix) {
            path.unshift(this.prefix);
        }
        return path.join('.');
    };
    IdService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IdService_Factory() { return new IdService(i0.ɵɵinject(ID_PREFIX, 8)); }, token: IdService, providedIn: "root" });
    return IdService;
}());
export { IdService };
