import { ApplicationRef, ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AppErrorHandler extends ErrorHandler {
    constructor(private injector: Injector) {
        super();
    }

    /**
     * Intercept all uncaught application errors, and
     * handle them appropriately.
     */
    handleError(error: any) {
        if (document.location.host.includes('liveintent.com')) {
            this.recover(error);
        }

        super.handleError(error);
    }

    /**
     * Recover from the error.
     *
     * If the application is still stable, the next `tick`
     * will succeed and nothing further will be required.
     * Otherwise, the application is in an unrecoverable
     * state and must be completely rebooted.
     *
     * @param {error} any
     */
    private recover(error: any) {
        const app = this.injector.get(ApplicationRef);
        const url = this.injector.get(Router).url;

        try {
            app.tick();
        } catch (e) {
            this.log(error, url).then(() => window.location.href = '/fatal-error');
        }
    }

    /**
     * Log the error to Google Analytics.
     *
     * @param {error} any
     */
    private log(error: any, url: string) {
        const ga = window['ga'];

        return new Promise(resolve => {
            ga('send', {
                hitType: 'event',
                eventCategory: 'errors',
                eventAction: 'Page: ' + url,
                eventLabel: 'crash',
                hitCallback: resolve
            });
        });
    }
}
