import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, empty } from 'rxjs';
import { TokenService } from 'src/app/core/token.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { catchError, finalize } from 'rxjs/operators';
import { StoreService } from 'app/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    static tokenless = [
        '/auth/login',
        '/auth/refresh',
        '/auth/password/reset',
        '/auth/password/forgot'
    ];

    constructor(
        private navigation: NavigationService,
        private tokenService: TokenService,
        private storeService: StoreService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const parser = document.createElement('a');
        parser.href = req.url;

        const reqWithAuth = req.clone({
            headers: this.tokenService.accessToken ? req.headers.set('Authorization', 'Bearer ' + this.tokenService.accessToken) : undefined,
            withCredentials: !this.tokenService.isLoggedInAs
        });

        let authFailed = false;

        return next.handle(reqWithAuth).pipe(
            catchError(err => {
                authFailed = err.status === 401;
                console.debug("Request failed on URL " + reqWithAuth.url + " with error " + err.status + " " + err.statusText);
                return this.handleUnauthorized(err);
            }),
            finalize(() => {
                if (!authFailed && reqWithAuth.url.includes('auth/me')) {
                    // The token is valid, so we can reset the error count
                    this.handleAuthSuccessful();
                }
            })
        );
    }

    private handleAuthSuccessful() {
        this.storeService.set('errorCount', 0);
    }

    private handleUnauthorized(err: Response): Observable<HttpEvent<any>> {
        if (err.status === 401) {
            const errorCount = this.storeService.get('errorCount');
            if (errorCount > 30) {
                this.navigation.redirectToFatalError();
                return empty();
            }

            this.storeService.set('errorCount', errorCount + 1);

            this.tokenService.clear();
            this.navigation.redirectToLogin();
            return empty();
        }

        throw err;
    }
}
