import {Attribute, Component, ContentChild, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import { fadeIn } from 'app/shared/animations';
import { IdService } from 'app/core/id.service';

@Component({
    selector: 'tab',
    template: `<div *ngIf="active" @fadeIn>
                   <div [ngSwitch]="template">
                       <div *ngSwitchCase="null"><ng-content></ng-content></div>
                       <ng-template *ngSwitchDefault [ngTemplateOutlet]="template"></ng-template>
                   </div>
              </div>`,
    animations: [fadeIn]
})
export class TabComponent {
    @ContentChild('body', { static: true }) template: TemplateRef<any> = null;
    @Input() badge: number = null;
    @Input() state: string;
    @Input() header: string;
    @Input() superScript: string;
    @Input() status: string;
    @Input() hidden = false;
    @Input() disabled: boolean = null;
    @Input() value: any = null;
    @Input() icon: string = null;
    @Input() empty: boolean = false;
    public active: boolean;

    constructor(
        @Attribute('active') active: boolean,
        public id: IdService
    ) {
        this.active = active !== null;
    }
}
