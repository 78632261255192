import { Component, Output, ViewChild, EventEmitter } from '@angular/core';
import { NewsletterUploaderComponent } from './newsletter-uploader';
import { NewsletterSingleComponent } from './newsletter-single';
import { ModalComponent } from 'app/shared/elements/modal';
import { ButtonComponent } from 'app/shared/elements/button';
import { NotificationsService } from 'app/core/notifications.service';
import { TabComponent } from 'app/shared/elements/tabs';
import { AuthorizationService } from 'app/core/authorization.service';
import { IdService } from 'app/core/id.service';

@Component({
    selector: 'newsletter-form',
    templateUrl: './newsletter-form.html'
})

export class NewsletterFormComponent {

    @ViewChild('modal', { static: true }) public modal: ModalComponent;
    @ViewChild('singleTab', { static: true }) public singleTab: TabComponent;
    @ViewChild('uploadTab', { static: true }) public uploadTab: TabComponent;
    @ViewChild('bulkUpload', { static: true }) public uploaderTool: NewsletterUploaderComponent;
    @ViewChild('singleUpload', { static: true }) public newsletterSingle: NewsletterSingleComponent;
    @ViewChild('saveBtn', { static: false }) public saveButton: ButtonComponent;
    @Output() onCanceled: EventEmitter<any> = new EventEmitter<any>();
    @Output() onCreated: EventEmitter<any> = new EventEmitter<any>();
    @Output() onUploaded: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSaved: EventEmitter<any> = new EventEmitter<any>();
    @Output() onOpenPopup: EventEmitter<any> = new EventEmitter<any>();

    public isEdit: boolean;

    constructor(
        public notifications: NotificationsService,
        public auth: AuthorizationService,
        public id: IdService
    ) {}

    public openForCreate(publisher): void {
        this.isEdit = false;
        this.singleTab.active = true;
        this.newsletterSingle.initForCreate(publisher);
        this.uploaderTool.init(publisher);
        this.modal.open();
    }

    public openForEdit(newsletter): void {
        this.isEdit = true;
        this.singleTab.active = true;
        this.newsletterSingle.initForEdit(newsletter);
        this.modal.open(true);
    }

    public handleFormLoaded($event): void {
        this.modal.hideLoading();
    }

    public handleCreated($event): void {
        this.notifications.success('You\'ve successfully created a newsletter!');
        this.modal.hideLoading();
        this.saveButton.reset();
        this.onCreated.emit($event);
        this.close();
    }

    public handleSaved($event): void {
        this.notifications.success('Your changes have been saved.');
        this.modal.hideLoading();
        this.saveButton.reset();
        this.onSaved.emit($event);
        this.close();
    }

    public handleUploaded(): void {
        this.notifications.success('Newsletters successfully uploaded.');
        this.modal.hideLoading();
        this.onUploaded.emit();
        this.close();
    }

    public triggerNewsLetterUpload(): void {
        this.uploaderTool.uploadFile()
    }

    public handleCoordinatedSponsorshipPopUp($confirmEvent) {
        if (!$confirmEvent) {
            this.newsletterSingle.newsletter.allowCoordination = true;
        }

        this.handleSingleUpload();
    }

    public handleCoordinatedSponsorship() {
        this.newsletterSingle.checkCoordinatedSponsorship()
            .subscribe(open => {
                if (open) {
                    this.newsletterSingle.coordinatedLineItemsDialogComponent.open();
                } else {
                    this.handleSingleUpload();
                }
            });
    }

    public handleSingleUpload(): void {
        this.modal.showLoading();
        this.saveButton.load();
        this.newsletterSingle.submit();
    }

    public handleSingleError($event): void {
        this.modal.hideLoading();
        this.saveButton.reset();
    }

    public close(): void {
        this.singleTab.active = true;
        this.uploadTab.active = false;
        this.uploaderTool.reset();
        this.modal.close();
        this.reset();
    }

    public reset(): void {
        this.newsletterSingle.resetForm();
    }
}
