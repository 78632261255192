export const sortBlueprintOptionsByName = (blueprints) => {
    return blueprints.sort((a, b) => {
        const capitalizeFirstLetter = (word) => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        };

        const blueprintA = capitalizeFirstLetter(a.displayName);
        const blueprintB = capitalizeFirstLetter(b.displayName);

        if (blueprintA > blueprintB) {
            return 1;
        }

        if (blueprintA < blueprintB) {
            return -1;
        }

        return 0;
    });
};
