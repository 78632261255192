import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { TimeseriesChartComponent } from 'app/shared/elements/charts/timeseries-chart/timeseries-chart.component';

@Component({
    selector: 'metric-comparison',
    templateUrl: './metric-comparison.html',
    styles: ['./metric-comparison.styl']
})
export class MetricComparisonComponent implements OnInit {
    @ViewChild(TimeseriesChartComponent, { static: true }) timeseries: TimeseriesChartComponent;

    @Input() formats: string[];
    @Input() dates: string[];
    @Input() showFilters: boolean;

    @Input('data')
    set data(value) {
        this._data = value;
        this.reloadGraph();
    }
    private _dataCache = [];
    get data() {
        this._dataCache.length = 0;
        this._dataCache.unshift(this._data[this._left], this._data[this._right]);
        return this._dataCache;
    }

    @Output() rightIndex = new EventEmitter<number>();
    @Output() leftIndex = new EventEmitter<number>();

    options: any[];
    private _left: number = 7;
    private _right: number = 8;
    filterBy: number = 1;
    private _data: any[];

    ngOnInit() {
        this.createOptions();
    }

    createOptions() {
        if (this.data && this.data[0].length > 1) {
            this.options = this._data.map( (data, index) => {
                return { key: index, value: index, label: data[0] };
            });
        }
    }

    set left(data) {
        this._left = data;
        this.leftIndex.emit(data);
        this.reloadGraph();
    }

    get left() {
        return this._left;
    }

    set right(data) {
        this._right = data;
        this.rightIndex.emit(data);
        this.reloadGraph();
    }

    get right() {
        return this._right;
    }

    setData(data: any[], suffix) {
        const label = data[0];
        return [label + suffix].concat(data.slice(1, data.length));
    }

    reloadGraph() {
        if (this.timeseries.chart === null) {
            return;
        }
        if (this.timeseries.called) {
            const leftData = this.setData(this.data[0], '');
            const rightData = this.setData(this.data[1], ' ');
            this.timeseries.chart.load({
                columns: [this.dates, leftData, rightData],
                x: 'dates',
                axes: {
                    [leftData[0]]: 'y',
                    [rightData[0]]: 'y2'
                },
                colors: {
                    [leftData[0]]: '#14abf9',
                    [rightData[0]]: '#9013fe'
                },
                unload: true,
                done: () => this.timeseries.rebuildLegend()
            });
        }
    }
}
