import { forkJoin, of } from "rxjs";
export var getManagersExecutivesForMediaGroups = function (mediaGroups, userRepo) {
    var managerIds = mediaGroups.map(function (mediaGroup) { return mediaGroup.manager; });
    var executiveIds = mediaGroups.map(function (mediaGroup) { return mediaGroup.executive; });
    var userIds = managerIds.concat(executiveIds).filter(function (userId) { return userId; });
    // Get rid of duplicates
    userIds = Array.from(new Set(userIds));
    if (userIds && userIds.length) {
        return forkJoin([of(mediaGroups), userRepo.search({
                conditions: [{ field: 'hash_id', value: userIds }]
            })]);
    }
    return forkJoin([of(mediaGroups), of(new Array())]);
};
export var decorateMediaGroupsWithNames = function (_a) {
    var mediaGroups = _a[0], users = _a[1];
    var idToName = new Map();
    users.forEach(function (user) { return idToName.set(user.hashId, user.name); });
    mediaGroups.forEach(function (mediaGroup) {
        if (idToName.has(mediaGroup.manager)) {
            mediaGroup.managerName = idToName.get(mediaGroup.manager);
        }
        if (idToName.has(mediaGroup.executive)) {
            mediaGroup.executiveName = idToName.get(mediaGroup.executive);
        }
    });
    return of(mediaGroups);
};
