import { AfterViewInit, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { of, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Creative, DemandAsset, Native } from 'app/shared/models';
import { BlueprintRepository } from 'app/core/repositories';
import { IdService } from 'app/core';
import { DemandAssetService } from 'app/core/demand-asset.service';
import { DemandAssetProperties, DemandImageAssetType } from 'app/shared/models/native-demand';
import { CopyableDirective } from 'app/shared/elements/copyable';
import { TypeOfCreative } from 'app/shared/models/creative';
import warnings from 'app/core/warnings.json';
var BASE_URL = environment.ifaas;
var DEFAULT_PREVIEW_BLUEPRINT_DESKTOP = 'creative/lin_masterblueprint_left_desktop';
var DEFAULT_PREVIEW_BLUEPRINT_MOBILE = 'creative/lin_masterblueprint_left_mobile';
var MessageType;
(function (MessageType) {
    MessageType[MessageType["Pending"] = 0] = "Pending";
    MessageType[MessageType["Success"] = 1] = "Success";
    MessageType[MessageType["Error"] = 2] = "Error";
})(MessageType || (MessageType = {}));
var AdBuilderNativePreviewComponent = /** @class */ (function () {
    function AdBuilderNativePreviewComponent(blueprintRepository, demandAssetService, id) {
        this.blueprintRepository = blueprintRepository;
        this.demandAssetService = demandAssetService;
        this.id = id;
        this.ifaasUrl = new EventEmitter();
        this.creative = new Creative();
        this.display = Native.DESKTOP_DISPLAY;
        this.hidePreviewTitle = false;
        this.hidePreviewDetails = true;
        this.demandAssets = null;
        this.blueprintId = null;
        this.modalPopup = false;
        this.isComingFromCreativePreview = false;
        // minimum 350px to ensure the COPY MEDIA URL button is rendered in a single line
        this.creativeRenderWidth = 350;
        this.disablePreview = false;
        this.Native = Native;
        this.warnings = warnings;
        this.imageUrl$ = new ReplaySubject(1);
        this.update$ = new ReplaySubject(1);
        this.onDestroy$ = new Subject();
        this.debounceTime = 1000;
        this.timeout = 3000;
        this.message$ = new Subject();
        this.Message = MessageType;
        this.previewFactor = 0.75;
        this.urlComponents = {
            headline: null,
            mainImage: null,
            description: null,
            callToAction: null,
            sponsored: null,
            logoImage: null
        };
    }
    AdBuilderNativePreviewComponent.prototype.ngAfterViewInit = function () {
        if (this.creative.typeOfCreative === TypeOfCreative.NativeExchange || this.blueprintId) {
            this.refresh();
        }
    };
    AdBuilderNativePreviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.previewTitle = this.capitalizeFirstLetter(this.display);
        this.update$.pipe(debounceTime(this.debounceTime), takeUntil(this.onDestroy$), mergeMap(function () {
            // We want to render the ad in all scenarios except if isComingFromCreativePreview - In that scenario we only render if blueprintId is passed explicitly as an input.
            // During Ad create/edit a user may toggle b/w blueprints, we need to update reset the blueprintId everytime that happens.
            if (_this.creative.blueprintId && !_this.isComingFromCreativePreview && (!_this.blueprintId || _this.blueprintId != _this.creative.blueprintId)) {
                _this.blueprintId = _this.creative.blueprintId;
            }
            if (_this.blueprintId) {
                return _this.blueprintRepository.search({ id: _this.blueprintId });
            }
            return of([]);
        }), tap(function (blueprints) {
            // We can remove this map and shift when we have a Blueprint Details Command
            _this.blueprint = blueprints.map(function (blueprint) { return blueprint; }).shift();
        }), tap(function () { return _this.generateUrlComponents(); }), map(function () { return _this.imageUrl; })).subscribe(function (imageUrl) {
            _this.message$ = of(MessageType.Pending);
            _this.imageUrl$.next(imageUrl);
            _this.updateImageUrl(imageUrl);
        });
    };
    AdBuilderNativePreviewComponent.prototype.ngOnDestroy = function () {
        this.onDestroy$.next();
        this.onDestroy$.complete();
        this.update$.complete();
    };
    AdBuilderNativePreviewComponent.prototype.updateImageUrl = function (url) {
        this.ifaasUrl.emit(url);
    };
    // Build one object to house creative details
    AdBuilderNativePreviewComponent.prototype.generateUrlComponents = function () {
        var _this = this;
        if (this.creative.isNativeCreative) {
            Object.keys(this.urlComponents).forEach(function (key) {
                if (_this.demandAssets && _this.demandAssets[key]) {
                    _this.urlComponents[key] = _this.demandAssets[key].model;
                    for (var _i = 0, DemandAssetProperties_1 = DemandAssetProperties; _i < DemandAssetProperties_1.length; _i++) {
                        var prop = DemandAssetProperties_1[_i];
                        if (prop.modelKey === key && prop.multiLine && _this.demandAssets[key].model) {
                            // if this prop supports multiline, replace \n with <br> before rendering
                            _this.urlComponents[key] = _this.demandAssets[key].model.replaceAll("\n", '<br>');
                        }
                    }
                }
            });
        }
        else {
            this.urlComponents.mainImage = this.creative.thumbnailUrl;
            this.urlComponents.headline = this.creative.headline;
            this.urlComponents.callToAction = this.creative.callToAction;
            this.urlComponents.description = this.creative.body;
            this.urlComponents.sponsored = this.creative.sponsoredBy;
            this.urlComponents.logoImage = this.creative.logoUrl;
        }
    };
    Object.defineProperty(AdBuilderNativePreviewComponent.prototype, "imageUrl", {
        get: function () {
            var url = new URL(BASE_URL);
            url.pathname = this.pathName;
            url.searchParams.set('iUrl', this.sanitize(this.urlComponents.mainImage));
            url.searchParams.set('t', this.sanitize(this.urlComponents.headline));
            url.searchParams.set('cta', this.sanitize(this.urlComponents.callToAction));
            url.searchParams.set('d', this.sanitize(this.urlComponents.description));
            url.searchParams.set('sb', this.sanitize(this.urlComponents.sponsored));
            url.searchParams.set('lUrl', this.sanitize(this.urlComponents.logoImage));
            return url.toJSON().toString();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdBuilderNativePreviewComponent.prototype, "showPreview", {
        get: function () {
            return this.urlComponents.mainImage
                || this.urlComponents.headline
                || this.urlComponents.callToAction
                || this.urlComponents.description
                || this.urlComponents.sponsored
                || this.urlComponents.logoImage;
        },
        enumerable: true,
        configurable: true
    });
    AdBuilderNativePreviewComponent.prototype.reset = function () {
        this.imageUrl$.next('');
    };
    AdBuilderNativePreviewComponent.prototype.refresh = function () {
        this.generateUrlComponents();
        this.update$.next();
    };
    AdBuilderNativePreviewComponent.prototype.copy = function (cp) {
        var _this = this;
        cp.copy();
        cp.copied.subscribe(function (copied) {
            var messageType = copied ? MessageType.Success : MessageType.Error;
            _this.setMessage(messageType);
        });
    };
    /**
     * Reset imageUrl if an image is not loaded to show preview unavailable
     */
    AdBuilderNativePreviewComponent.prototype.handleImageError = function () {
        this.reset();
    };
    AdBuilderNativePreviewComponent.prototype.setMessage = function (messageType) {
        var _this = this;
        this.message$ = of(messageType);
        setTimeout(function () {
            _this.message$ = of(MessageType.Pending); // Clear the message after a timeout
        }, this.timeout);
    };
    AdBuilderNativePreviewComponent.prototype.sanitize = function (text) {
        text = this.demandAssetService.encodeUtf8(text) || text;
        return text || '';
    };
    AdBuilderNativePreviewComponent.prototype.capitalizeFirstLetter = function (title) {
        return title.replace(/^\w/, function (c) { return c.toUpperCase(); });
    };
    Object.defineProperty(AdBuilderNativePreviewComponent.prototype, "pathName", {
        get: function () {
            // Including NCT to ensure all pages where we only have one preview for desktop/mobile we use Master Blueprint
            if (this.isNativeExchangeOrDirectSoldTrafficking || !this.blueprint) {
                if (this.display === Native.DESKTOP_DISPLAY) {
                    return DEFAULT_PREVIEW_BLUEPRINT_DESKTOP;
                }
                return DEFAULT_PREVIEW_BLUEPRINT_MOBILE;
            }
            return 'creative/' + this.blueprint.name + '_' + this.display;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdBuilderNativePreviewComponent.prototype, "isNativeExchangeOrDirectSoldTrafficking", {
        // Should be removed once AdView is deprecated
        get: function () {
            return this.creative.typeOfCreative === TypeOfCreative.NativeExchange ||
                (this.creative.typeOfCreative === TypeOfCreative.NativeDirectSoldTrafficking && !this.isComingFromCreativePreview);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdBuilderNativePreviewComponent.prototype, "creativeRenderWidthAdjust", {
        get: function () {
            var renderWidth = this.creativeRenderWidth;
            if (this.modalPopup) {
                renderWidth *= this.previewFactor;
            }
            if (this.display === Native.MOBILE_DISPLAY) {
                renderWidth *= this.previewFactor;
            }
            return Math.floor(renderWidth) + "px";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdBuilderNativePreviewComponent.prototype, "aspectRatio", {
        get: function () {
            if (this.display === Native.MOBILE_DISPLAY && this.blueprint.mobileAspectRatio) {
                return this.blueprint.mobileAspectRatio;
            }
            else if (this.display === Native.DESKTOP_DISPLAY && this.blueprint.desktopAspectRatio) {
                return this.blueprint.desktopAspectRatio;
            }
            return 'N/A';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdBuilderNativePreviewComponent.prototype, "displayAspectRatioMismatchWarning", {
        get: function () {
            if (!this.shouldCheckAspectRatio()) {
                return false;
            }
            var creativeAspectRatio = this.getCreativeMainImageAspectRatio();
            return creativeAspectRatio !== null && creativeAspectRatio !== this.aspectRatio;
        },
        enumerable: true,
        configurable: true
    });
    AdBuilderNativePreviewComponent.prototype.shouldCheckAspectRatio = function () {
        return this.creative.isNative && this.aspectRatio !== 'N/A' && this.aspectRatio !== undefined;
    };
    AdBuilderNativePreviewComponent.prototype.getCreativeMainImageAspectRatio = function () {
        var _this = this;
        if (this.creative && this.creative.native && this.creative.assets) {
            var creativeMainImageAsset = this.creative.native.assets.find(function (asset) {
                return _this.isDemandImageAsset(asset) && asset.hasOwnProperty('aspectRatio');
            });
            return creativeMainImageAsset ? creativeMainImageAsset.aspectRatio : null;
        }
        return null;
    };
    AdBuilderNativePreviewComponent.prototype.isDemandImageAsset = function (asset) {
        return asset && asset.hasOwnProperty('img') && asset.img.hasOwnProperty('type') && asset.img.type === DemandImageAssetType.Main;
    };
    return AdBuilderNativePreviewComponent;
}());
export { AdBuilderNativePreviewComponent };
