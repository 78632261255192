import * as tslib_1 from "tslib";
import { Model } from './model';
var ReportingEmail = /** @class */ (function (_super) {
    tslib_1.__extends(ReportingEmail, _super);
    function ReportingEmail() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ReportingEmail.prototype.serialize = function () {
        return JSON.stringify(this);
    };
    Object.defineProperty(ReportingEmail.prototype, "entity", {
        get: function () {
            return 'Reporting Email';
        },
        enumerable: true,
        configurable: true
    });
    return ReportingEmail;
}(Model));
export { ReportingEmail };
