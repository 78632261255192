import { Component } from '@angular/core';

import { AuthenticationService, AuthorizationService } from 'app/core';
import { IdService, ID_PREFIX } from 'app/core/id.service';

@Component({
    templateUrl: './platform.html',
    styleUrls: ['./platform.styl'],
    providers: [{ provide: ID_PREFIX, useValue: 'platform' }, IdService ]
})
export class PlatformComponent {

    constructor(
        public auth: AuthorizationService,
        public authentication: AuthenticationService,
        public id: IdService
    ) { }

    getLogoSrc(): string {
        // Check if it is June
        if (new Date().getMonth() == 5) {
            return '/assets/images/liveintent-logo-pride-month.svg';
        }
        return '/assets/images/liveintent-logo.svg';
    }
    
}
