import { Model } from './model';
import { AdSlot } from './ad-slot';
import { Category } from './category';
import { User } from './user';
import { Publisher } from './publisher';
import { EspFieldOverride } from "./mixin/esp-field-override";

export class Newsletter extends Model implements EspFieldOverride {
    createdBy: string;
    modifiedBy: string;
    category: number;
    mediaGroup: string;
    publisher: string;
    refId: number;
    id: string;
    name: string;
    publisherName: string;
    publisherRefId: number;
    lastSeen: string;
    lastSeenUrl: string;
    privateDeals: boolean;
    allowCoordination: boolean;
    publisherClientTypes: string[];

    domainTargeting?: {
        type: string,
        values?: string[]
    };

    sensitiveCategoryTargeting?: {
        type: string,
        values?: number[]
    }

    // ESPFieldOverride
    public esp: number | string;
    public espName: string;
    public emailTagReplacement: string;
    public emailTagType: string;
    public placementTagReplacement: string;
    public listTagReplacement: string;
    public lceeTagReplacement: string;
    public lceeKeyReplacement: string;

    // Non-LSD fields
    primaryCategory?: Category;
    adSlots?: AdSlot[];
    createdByUser?: User;
    modifiedByUser?: User;
    publisherCategory?: Category;
    publisherObj?: Publisher;

    public sspControl: {
        exchangeAllow: boolean,
        exchangeFloor: number,
        rtbFloor: number,
        rtbAllow: boolean,
        uniqueAds: string|null|undefined,
    };

    public constructor(newsletter: any = {}) {
        super();

        Object.assign(this, newsletter, {
            esp: newsletter.esp !== null ? newsletter.esp : 'other'
        });
    }

    serialize(asJson = false) {

        const newsletter = this.clone(Newsletter);
        delete newsletter.primaryCategory;
        delete newsletter.adSlots;
        delete newsletter.createdByUser;
        delete newsletter.modifiedByUser;
        delete newsletter.publisherCategory;
        delete newsletter.publisherObj;

        if (newsletter.esp === 'other') {
            newsletter.esp = null;
        }

        if (newsletter.emailTagReplacement === '') {
            newsletter.emailTagReplacement = null;
        }
        if (newsletter.placementTagReplacement === '') {
            newsletter.placementTagReplacement = null;
        }

        if (newsletter.listTagReplacement === '') {
            newsletter.listTagReplacement = null;
        }

        if (newsletter.placementTagReplacement === '') {
            newsletter.placementTagReplacement = null;
        }

        if (newsletter.lceeKeyReplacement === '') {
            newsletter.lceeKeyReplacement = null;
        }

        if (newsletter.lceeTagReplacement === '') {
            newsletter.lceeTagReplacement = null;
        }

        if (asJson) {
            return newsletter;
        }

        return JSON.stringify(newsletter);
    }

    get entity(): string {
        return 'Newsletter';
    }
}
