import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Advertiser, InsertionOrder } from 'app/shared/models';
import { AdvertiserRepository, InsertionOrderRepository } from 'app/core/repositories';
import { mergeMap, map } from 'rxjs/operators';

type InsertionOrderDetails = {
    advertiser: Advertiser,
    insertionOrder: InsertionOrder
};

@Injectable()
export class InsertionOrderDetailsResolver implements Resolve<InsertionOrderDetails> {
    constructor(
        private advertiserRepository: AdvertiserRepository,
        private insertionOrderRepository: InsertionOrderRepository
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.insertionOrderRepository
            .get(route.params['id']).pipe(mergeMap(insertionOrder => {
                return this.advertiserRepository
                    .get(insertionOrder.advertiser)
                    .pipe(map(advertiser => ({ advertiser, insertionOrder })));
            }));
    }
}
