import { Observable } from 'rxjs';
import { HistoryItem } from 'app/shared/models/history-item';

export class HistoryResponse {
    items: HistoryItem[];
    page: number;
    pages: number;
    total: number;
    next: Observable<HistoryResponse>;

    constructor(from?: object) {
        Object.assign(this, from);
    }
}
