import * as tslib_1 from "tslib";
import { Model } from './model';
var GlobalBlocklist = /** @class */ (function (_super) {
    tslib_1.__extends(GlobalBlocklist, _super);
    function GlobalBlocklist() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GlobalBlocklist.prototype.serialize = function () {
        delete this._isClone;
        return JSON.stringify(this);
    };
    Object.defineProperty(GlobalBlocklist.prototype, "entity", {
        get: function () {
            return 'Global Blocklist';
        },
        enumerable: true,
        configurable: true
    });
    return GlobalBlocklist;
}(Model));
export { GlobalBlocklist };
