import { Model } from './model';

export class AudienceFile extends Model {
    action: 'add' | 'remove' | 'replace';
    advertiserID: string;
    errorFileName: string;
    fileName: string;
    lastUpdate: string;
    matchCount: number;
    originalFileName: string;
    rejectedHashesBucket: string;
    rejectedHashesFile: string;
    segmentCount: number;
    segmentId: number;
    status: string;
    uniqueSegmentId: number;
    updated: string;
    uploadedHashesCount: number;

    serialize(): string {
        return JSON.stringify(this);
    }

    get entity() {
        return 'Audience File';
    }
}
