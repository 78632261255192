import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safeHtml' })
export class SafeHtml {
    constructor(private sanitizer: DomSanitizer) {
    }

    transform(incomingHtml: string) {
        return this.sanitizer.bypassSecurityTrustHtml(incomingHtml);
    }
}
