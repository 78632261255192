import {Pipe} from '@angular/core';

@Pipe({
    name: 'filterBy',
    pure: false // Not ideal, need to change in the future - maybe an "Async" pipe
})

export class FilterByPipe {
    transform(options, inputValue) {
        if (inputValue && inputValue.length === 0) {
            return options;
        }

        if (Array.isArray(options) && options.length > 0) {
            return options.filter(option => {
                return (option.selected === false
                    && option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
            });
        }

        return [];
    }
}
