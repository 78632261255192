import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IdService } from 'app/core/id.service';

@Component({
    selector: 'progressive-link',
    templateUrl: './progressive-link.html',
    styleUrls: ['./progressive-link.styl']
})
export class ProgressiveLinkComponent {
    @Input() text: string;
    @Input() expanded: boolean = false;
    @Input() disabled: boolean = false;
    @Output() expandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        public id: IdService
    ) {}

    open() {
        if (!this.disabled) {
            this.expanded = true;
            this.expandedChange.emit(this.expanded);
        }
    }

    close() {
        if (!this.disabled) {
            this.expanded = false;
            this.expandedChange.emit(this.expanded);
        }
    }
}
