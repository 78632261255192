import { EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LightboxComponent } from 'app/shared/elements/lightbox';
import { NewsletterRepository, PublisherRepository } from 'app/core/repositories';
import { DownloadHelper } from 'app/shared/helpers/download-helper';
import { IdService } from 'app/core';
var Type;
(function (Type) {
    Type[Type["Publisher"] = 0] = "Publisher";
    Type[Type["Newsletter"] = 1] = "Newsletter";
})(Type || (Type = {}));
var DownloadTagsComponent = /** @class */ (function () {
    function DownloadTagsComponent(newsletterRepository, publisherRepository, id) {
        this.newsletterRepository = newsletterRepository;
        this.publisherRepository = publisherRepository;
        this.id = id;
        this.saved = new EventEmitter();
        this.maxWidth = null;
        this.maxMobileWidth = null;
    }
    DownloadTagsComponent.prototype.open = function (hash, refId, type) {
        this.hash = hash;
        this.refId = refId;
        this.type = type;
        this.lightbox.open();
        this.form.controls['maxWidth'].markAllAsTouched();
    };
    DownloadTagsComponent.prototype.onClose = function () {
        this.form.reset();
    };
    Object.defineProperty(DownloadTagsComponent.prototype, "repository", {
        get: function () {
            switch (this.type) {
                case Type[Type.Newsletter]:
                    return this.newsletterRepository;
                case Type[Type.Publisher]:
                    return this.publisherRepository;
                default:
                    throw 'Invalid Repository Type';
            }
        },
        enumerable: true,
        configurable: true
    });
    DownloadTagsComponent.prototype.disabled = function () {
        return !this.form.valid;
    };
    DownloadTagsComponent.prototype.downloadTags = function () {
        var _this = this;
        var args = [this.hash];
        if (this.maxWidth !== null) {
            args.push(this.maxWidth.toString());
        }
        if (this.maxWidth !== null && this.maxMobileWidth !== null) {
            args.push(this.maxMobileWidth.toString());
        }
        this.repository.downloadTags(args)
            .subscribe(function (data) {
            DownloadHelper.downloadFile(data, _this.type + "Tags-" + _this.refId + ".xls");
        });
        this.lightbox.close();
    };
    return DownloadTagsComponent;
}());
export { DownloadTagsComponent };
