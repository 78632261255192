import * as tslib_1 from "tslib";
import { Model } from './model';
export var AccountProvisions;
(function (AccountProvisions) {
    AccountProvisions["SELF_SERVICE"] = "Self Service (Platform)";
    AccountProvisions["AUDIENCE_MANAGER"] = "Audience Manager";
})(AccountProvisions || (AccountProvisions = {}));
var Account = /** @class */ (function (_super) {
    tslib_1.__extends(Account, _super);
    function Account() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Account.prototype.serialize = function () {
        return JSON.stringify(this);
    };
    Object.defineProperty(Account.prototype, "entity", {
        get: function () {
            return 'Account';
        },
        enumerable: true,
        configurable: true
    });
    return Account;
}(Model));
export { Account };
