import { EventEmitter, ChangeDetectorRef } from '@angular/core';
import { LocationHelperService } from 'app/core/location-helper.service';
import postalCountries from './postal-countries.json';
var targets = [
    {
        key: 0,
        value: 'include',
        label: 'Target'
    },
    {
        key: 1,
        value: 'exclude',
        label: 'Exclude'
    }
];
var LocationTargetingComponent = /** @class */ (function () {
    function LocationTargetingComponent(locationHelper, cdr) {
        this.locationHelper = locationHelper;
        this.cdr = cdr;
        this.model = { input: '', postalCodes: '' };
        this._items = [];
        this.filteredLocations = [];
        this.options = {
            target: targets,
            locations: []
        };
        this.countries = [];
        this.countryOptions = postalCountries;
        this.showPostal = true;
        this.restrict = [];
        this.geos = [];
        this.geosChange = new EventEmitter();
        this.postalCodes = [];
        this.postalCodesChange = new EventEmitter();
        this.unitedStatesOnly = null;
        this.unitedStatesOnlyChange = new EventEmitter();
        this._targeting = 'include';
        this._targetingChange = new EventEmitter();
        this.isLoading = false;
    }
    LocationTargetingComponent.validPostalCodes = function (postalCode) {
        return postalCode.length > 0;
    };
    LocationTargetingComponent.uniquePostalCodes = function (arr) {
        return function (postalCode, i) { return arr.indexOf(postalCode) === i; };
    };
    LocationTargetingComponent.findPostalCodes = function (item) {
        return typeof item.key === 'string';
    };
    LocationTargetingComponent.findGeos = function (item) {
        return typeof item.key === 'number';
    };
    Object.defineProperty(LocationTargetingComponent.prototype, "items", {
        get: function () {
            return this._items;
        },
        set: function (value) {
            this._items = value;
            this.updateLocations();
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Worldwide.
     */
    LocationTargetingComponent.prototype.initWorldwide = function () {
        this.USOnly = this.unitedStatesOnly;
    };
    Object.defineProperty(LocationTargetingComponent.prototype, "USOnly", {
        get: function () {
            return this._USOnly;
        },
        set: function (value) {
            if (value) {
                this.country = LocationTargetingComponent.UNITED_STATES_ID;
                this.postalCodePlaceholder = 'Enter U.S. postal codes in the following formats: 12345 and 12345-6789.';
            }
            else {
                this.country = null;
                this.postalCodePlaceholder = 'Select a country and then add postal codes you want to target.';
            }
            this._USOnly = value;
            this.unitedStatesOnly = value === true;
            this.unitedStatesOnlyChange.emit(this.unitedStatesOnly);
            this.options.locations = this.prepareLocations();
            this.updateLocations();
        },
        enumerable: true,
        configurable: true
    });
    LocationTargetingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isLoading = true;
        this.locationHelper
            .promise
            .then(function () {
            _this.countries = _this.locationHelper.database
                .filter(function (location) { return location.type === 'country'; })
                .map(function (location) { return ({
                key: location.id,
                value: location.id,
                label: location.display
            }); });
            _this.initWorldwide();
            _this.options.locations = _this.prepareLocations();
            _this.updateItems();
            _this.isLoading = false;
        });
    };
    LocationTargetingComponent.prototype.ngDoCheck = function () {
        // We want to make sure that the locationHelper has all the necessary data before running
        // this function;
        if (this.isLoading) {
            return;
        }
        var postalCodes = this.items.filter(LocationTargetingComponent.findPostalCodes);
        var formattedCodes = [];
        postalCodes.forEach(function (postalCode) {
            if (!formattedCodes[postalCode.countryId]) {
                formattedCodes[postalCode.countryId] = [];
            }
            formattedCodes[postalCode.countryId].push(postalCode.key);
        });
        this.postalCodes = [];
        Object.keys(formattedCodes).forEach(function (key) {
            this.postalCodes.push({
                countryId: key,
                postalCodes: formattedCodes[key]
            });
        }.bind(this));
        this.postalCodesChange.emit(this.postalCodes);
        var geos = this.items.filter(LocationTargetingComponent.findGeos);
        this.geos = geos.map(function (item) { return ({ id: item.key, type: item.value }); });
        this.geosChange.emit(this.geos);
    };
    LocationTargetingComponent.prototype.updateItems = function () {
        this.items = this.prepareGeos();
        this.items = this.items.concat(this.preparePostalCodes());
    };
    LocationTargetingComponent.prototype.addPostalCodes = function () {
        if (typeof this.model.postalCodes !== 'string') {
            return;
        }
        var postalCodes = this.model.postalCodes.replace(/\n/g, ',')
            .split(',').map(function (token) { return token.trim(); })
            .filter(LocationTargetingComponent.validPostalCodes);
        if (postalCodes.length < 1) {
            return;
        }
        var uniqueFilter = LocationTargetingComponent.uniquePostalCodes(postalCodes);
        postalCodes = postalCodes.filter(uniqueFilter);
        for (var i = 0; i < postalCodes.length; i++) {
            for (var _i = 0, _a = this.items; _i < _a.length; _i++) {
                var item = _a[_i];
                if (postalCodes[i] === item.key && String(this.country) === String(item.countryId)) {
                    postalCodes.splice(i, 1);
                }
            }
        }
        this.changePostalCodes(postalCodes);
        this.model.postalCodes = '';
    };
    LocationTargetingComponent.prototype.changePostalCodes = function (postalCodes) {
        this.postalCodes = this.postalCodes.concat({
            countryId: this.country,
            postalCodes: postalCodes
        });
        this.postalCodesChange.emit(this.postalCodes);
        this.updateItems();
    };
    LocationTargetingComponent.prototype.addGeo = function (geo) {
        this.geos.push({
            id: geo.value.id,
            type: geo.value.type
        });
        this.geosChange.emit(this.geos);
        this.updateItems();
    };
    LocationTargetingComponent.prototype.removeAll = function () {
        this.geos.length = 0;
        this.postalCodes.length = 0;
        this.geosChange.emit(this.geos);
        this.postalCodesChange.emit(this.postalCodes);
        this.updateItems();
    };
    LocationTargetingComponent.prototype.select = function (geo) {
        this.addGeo(geo);
    };
    LocationTargetingComponent.prototype.prepareGeos = function () {
        var geos = [];
        var includedGeoIds = [];
        if (Array.isArray(this.geos)) {
            for (var i = 0; i < this.geos.length; i++) {
                var geo = this.locationHelper.lookup(this.geos[i].id, this.geos[i].type);
                geos.push({
                    key: geo.id,
                    value: geo.type,
                    label: geo.display
                });
                includedGeoIds.push(geo.id);
            }
        }
        return geos;
    };
    LocationTargetingComponent.prototype.preparePostalCodes = function () {
        var _this = this;
        var postalCodes = [];
        if (!Array.isArray(this.postalCodes)) {
            return postalCodes;
        }
        this.postalCodes.forEach(function (postalCode) {
            postalCode.postalCodes.forEach(function (value) {
                var country = _this.countries.filter(function (country) { return country.key === Number(postalCode.countryId); });
                postalCodes.push({
                    key: value,
                    value: 'Postal Code',
                    label: value + ', ' + country[0].label,
                    countryId: postalCode.countryId
                });
            });
        });
        return postalCodes;
    };
    LocationTargetingComponent.prototype.prepareLocations = function () {
        return this.locationHelper.database.map(function (l) {
            return new LocationTargetOption(l);
        }).filter(this.isValidLocationOption.bind(this));
    };
    LocationTargetingComponent.prototype.isValidLocationOption = function (location) {
        if (this.restrict.indexOf(location.locationType) > -1) {
            return false;
        }
        var unitedStatesOnly = this.unitedStatesOnly;
        if (unitedStatesOnly) {
            return (location.value.country && location.value.country.id === LocationTargetingComponent.UNITED_STATES_ID)
                || (!location.value.country && location.value.id === LocationTargetingComponent.UNITED_STATES_ID);
        }
        return true;
    };
    LocationTargetingComponent.prototype.updateLocations = function () {
        var _this = this;
        this.filteredLocations = this.options.locations.filter(function (location) {
            for (var _i = 0, _a = _this.items; _i < _a.length; _i++) {
                var item = _a[_i];
                if (item.key === location.key && item.label === location.label) {
                    return false;
                }
            }
            return true;
        });
        this.cdr.detectChanges();
    };
    Object.defineProperty(LocationTargetingComponent.prototype, "targeting", {
        get: function () {
            return this._targeting;
        },
        set: function (value) {
            this._targeting = value;
            this._targetingChange.emit(this._targeting);
        },
        enumerable: true,
        configurable: true
    });
    LocationTargetingComponent.UNITED_STATES_ID = 235;
    return LocationTargetingComponent;
}());
export { LocationTargetingComponent };
var LocationTargetOption = /** @class */ (function () {
    function LocationTargetOption(location) {
        this.location = location;
    }
    Object.defineProperty(LocationTargetOption.prototype, "key", {
        get: function () {
            return this.location.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LocationTargetOption.prototype, "value", {
        get: function () {
            return this.location;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LocationTargetOption.prototype, "label", {
        get: function () {
            return this.location.display;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LocationTargetOption.prototype, "locationType", {
        get: function () {
            return this.location.type;
        },
        enumerable: true,
        configurable: true
    });
    return LocationTargetOption;
}());
