import { Injectable } from '@angular/core';

import { AuthenticationService } from './authentication.service';
import { map, take } from 'rxjs/operators';
import { PublisherClientTypes } from 'app/shared/models/publisher';

const NYTIMES_MEDIA_GROUP_HASH = '9e1f7b627b6411e2be2a1231392ea33d';
const NYTIMES_PUBLISHER_HASH = '4daa3a54acc211e088be12313e009a02';

export enum Action {
    View
}

export enum Entity {
    LiveIntent = 'LiveIntent',
    MediaGroup = 'MediaGroup',
    Publisher = 'Publisher',
    Agency = 'Agency',
    Advertiser = 'Advertiser',
    Feature = 'Feature'
}

export enum Role {
    Admin = 1,
    AdOps = 2,
    AccountDirector = 27,
    StandardUser = 28,
    AccountManager = 29,

    // External Users: Media Group
    MediaGroupAccess = 4,
    MediaGroupManager = 5,
    MediaGroupDemand = 6,
    MediaGroupReports = 7,
    MediaGroupAudience = 8,
    MediaGroupManagePublishers = 36,

    // External Users: Publisher
    PublisherAccess = 18,
    PublisherManager = 19,
    PublisherDemand = 20,
    PublisherUpload = 21,
    PublisherScrub = 22,
    PublisherReports = 23,
    PublisherAudience = 24,
    PublisherUser = 26,

    //External User: Agency
    AgencyAccess = 9,
    AgencyManager = 10,
    AgencyDemand = 11,
    AgencyReports = 12,
    AgencyAudience = 13,

    //External User: Advertiser
    AdvertiserAccess = 14,
    AdvertiserManager = 15,
    AdvertiserReports = 16,
    AdvertiserAudience = 17,
    AdvertiserUser = 25,

    //Feature
    NativeLike = 33
}

@Injectable()
export class AuthorizationService {
    private currentList = this.authenticationService.currentAccessList;
    private accessList$ = this.authenticationService.accessList$;

    constructor(private authenticationService: AuthenticationService) { }

    get isAdmin() {
        return this.hasRole(Role.Admin);
    }

    get isManager() {
        return this.isAccountDirector
            || this.isAccountManager
            || this.isStandardUser
            || this.isAdOps;
    }

    get isInternalUser() {
        return this.isAdmin || this.isManager;
    }

    get isAdOps() {
        return this.hasRole(Role.AdOps);
    }

    get isAccountManager() {
        return this.hasRole(Role.AccountManager);
    }

    get isStandardUser() {
        return this.hasRole(Role.StandardUser);
    }

    get isAccountDirector() {
        return this.hasRole(Role.AccountDirector);
    }

    get isMediaGroupManager() {
        return this.hasRole(Role.MediaGroupManager, Entity.MediaGroup);
    }

    get isMediaGroupDemand() {
        return this.hasRole(Role.MediaGroupDemand, Entity.MediaGroup);
    }

    get isMediaGroupAccess() {
        return this.hasRole(Role.MediaGroupAccess, Entity.MediaGroup);
    }

    get isMediaGroupReports() {
        return this.hasRole(Role.MediaGroupReports, Entity.MediaGroup);
    }

    get isMediaGroupAudience() {
        return this.hasRole(Role.MediaGroupAudience, Entity.MediaGroup);
    }

    get isMediaGroupManagePublisher() {
        return this.hasRole(Role.MediaGroupManagePublishers, Entity.MediaGroup)
            && this.hasRole(Role.MediaGroupManager, Entity.MediaGroup);
    }

    get isPublisherManager() {
        return this.hasRole(Role.PublisherManager, Entity.Publisher);
    }

    get isPublisherDemand() {
        return this.hasRole(Role.PublisherDemand, Entity.Publisher);
    }

    get isPublisherAccess() {
        return this.hasRole(Role.PublisherAccess, Entity.Publisher);
    }

    get isPublisherUpload() {
        return this.hasRole(Role.PublisherUpload, Entity.Publisher);
    }

    get isPublisherScrub() {
        return this.hasRole(Role.PublisherScrub, Entity.Publisher);
    }

    get isPublisherReports() {
        return this.hasRole(Role.PublisherReports, Entity.Publisher);
    }

    get isPublisherAudience() {
        return this.hasRole(Role.PublisherAudience, Entity.Publisher);
    }

    get isPublisherUser() {
        return this.hasRole(Role.PublisherUser, Entity.Publisher);
    }

    get isAgencyManager() {
        return this.hasRole(Role.AgencyManager, Entity.Agency);
    }

    get isAgencyDemand() {
        return this.hasRole(Role.AgencyDemand, Entity.Agency);
    }

    get isAgencyAudience() {
        return this.hasRole(Role.AgencyAudience, Entity.Agency);
    }

    get isAgencyAccess() {
        return this.hasRole(Role.AgencyAccess, Entity.Agency);
    }

    get isAgencyReports() {
        return this.hasRole(Role.AgencyReports, Entity.Agency);
    }

    get isAdvertiserAccess() {
        return this.hasRole(Role.AdvertiserAccess, Entity.Advertiser);
    }

    get isAdvertiserAudience() {
        return this.hasRole(Role.AdvertiserAudience, Entity.Advertiser);
    }

    get isAdvertiserReports() {
        return this.hasRole(Role.AdvertiserReports, Entity.Advertiser);
    }

    get isAdvertiserManager() {
        return this.hasRole(Role.AdvertiserManager, Entity.Advertiser);
    }

    get isAdvertiserUser() {
        return this.hasRole(Role.AdvertiserUser, Entity.Advertiser);
    }

    get isNativeUser() {
        return this.canManageMediaGroup || this.canManagePublisher;
    }

    get isNativeLikeUser() {
        return this.hasRole(Role.NativeLike, Entity.Feature);
    }

    get hasPublisherDetailsAccess() {
        return this.isInternalUser
        || this.isMediaGroupAccess
        || this.isMediaGroupAudience
        || this.isMediaGroupDemand
        || this.isMediaGroupManager
        || this.isMediaGroupReports
        || this.isPublisherManager
        || this.isPublisherDemand
        || this.isPublisherAccess
        || this.isPublisherUpload
        || this.isPublisherScrub
        || this.isPublisherReports
        || this.isPublisherAudience;
    }

    get hasReportingAccess() {
        return this.hasCampaignReportingAccess || this.hasInventoryReportingAccess;
    }

    get hasCampaignReportingAccess() {
        return this.isInternalUser
        || this.allowedReportingMediaGroupUser
        || this.isAdvertiserReports
        || this.isAdvertiserManager
        || this.isAdvertiserUser
        || this.isAgencyDemand
        || this.isAgencyManager
        || this.isAgencyReports;
    }

    get hasInventoryReportingAccess() {
        return this.isInternalUser
        || this.allowedReportingMediaGroupUser
        || this.isPublisherDemand
        || this.isPublisherManager
        || this.isPublisherReports
        || this.isPublisherUser;
    }

    get allowedReportingMediaGroupUser() {
        return this.isMediaGroupDemand
        || this.isMediaGroupManager
        || this.isMediaGroupReports;
    }

    get isNewYorkTimesUser(): boolean {
        if (this.currentList.getValue()) {
            const mediaGroup = this.currentList.getValue().entities.MediaGroup;
            const publisher = this.currentList.getValue().entities.Publisher;

            if (mediaGroup) {
                return mediaGroup.some(mg => mg.id === NYTIMES_MEDIA_GROUP_HASH);
            }

            if (publisher) {
                return publisher.some(pb => pb.id === NYTIMES_PUBLISHER_HASH);
            }

            return false;
        }

        return false;
    }

    private hasRole(role: Role, entity = Entity.LiveIntent): boolean {
        return this.currentList.getValue().hasAccess(entity, role);
    }

    hasRoleAsync(role: Role, entity = Entity.LiveIntent) {
        return this.accessList$.pipe(take(1), map(currentList => currentList.hasAccess(entity, role)));
    }

    get canManageMediaGroup() {
        return this.isMediaGroupManager || this.isMediaGroupDemand;
    }

    get canManagePublisher() {
        return this.isPublisherManager || this.isPublisherDemand;
    }

    get canEditAccountId() {
        return this.isAdmin || this.isAdOps;
    }

    get canViewAccountId() {
        return this.isInternalUser;
    }

    get canAccessPackages() {
        return (this.isAdmin
        || this.isManager
        || this.isMediaGroupAccess
        || this.isMediaGroupManager
        || this.isMediaGroupDemand
        || this.isPublisherAccess
        || this.isPublisherManager
        || this.isPublisherDemand);
    }

    get canAccessPackageActions() {
        return (this.isAdmin
        || this.isAdOps
        || this.isMediaGroupManager
        || this.isMediaGroupDemand
        || this.isPublisherManager
        || this.isPublisherDemand);
    }

    get canUseIncreasedCreativeFileSizes() {
        return this.isInternalUser
        || this.isMediaGroupManager
        || this.isMediaGroupDemand
        || this.isPublisherManager
        || this.isPublisherDemand;
    }

    can(action: Action, role: Role = null, entity = Entity.LiveIntent): boolean {
        if (action === Action.View) {
            return this.hasRole(role, entity);
        }

        return false;
    }

    canModifyMonetizationPublisher(publisherClientTypes: string[]): boolean {
        // Internal
        if (this.isAdmin || this.isInternalUser) {
            return true;
        }

        // External
        if (!this.canManageMediaGroup && !this.canManagePublisher) {
            return false;
        }

        // If client type is null -> monetization
        if (!publisherClientTypes) {
            return true;
        }

        if (typeof publisherClientTypes === 'string') {
            publisherClientTypes = JSON.parse(publisherClientTypes);
        }

        return publisherClientTypes.some(type => type === PublisherClientTypes.Monetization);
    }
}
