import { Injectable } from '@angular/core';
import { HeimdallRepository } from 'app/core/repositories/heimdall-repository';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Blueprint } from 'app/shared/models';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/index';
import { Url } from '../url';

@Injectable()
export class IfaasRepository extends HeimdallRepository<Blueprint> {
    public constructor(http: HttpClient) {
        super(http, '/ifaas/templates', Blueprint);
    }
}
