/**
 * Group a collection of items by a specific key.
 */
export function groupBy(arr, key) {
    return arr.reduce(function (acc, cur) {
        (acc[cur[key]] = acc[cur[key]] || []).push(cur);
        return acc;
    }, {});
}
/**
 * Sort a collection of items in place by a specific key.
 * Return the collection for convenience.
 */
export function sortBy(arr, key) {
    arr.sort(function (a, b) {
        if (a[key] > b[key]) {
            return 1;
        }
        if (a[key] < b[key]) {
            return -1;
        }
        return 0;
    });
    return arr;
}
/**
 * Flatten an array of arrays to an array of all items.
 */
export function flatten(arr) {
    return arr.reduce(function (acc, cur) {
        acc = acc.concat(cur);
        return acc;
    }, []);
}
/**
 * Flattens a list of objects by extracting the values of a specified key.
 *
 * @param list - The list of objects to flatten.
 * @param key - The key whose values need to be extracted.
 * @returns A flat list of values corresponding to the specified key.
 */
export function flattenListByKey(list, key) {
    return list.filter(function (item) { return key in item; }).map(function (item) { return item[key]; });
}
/**
 * Remove duplicates from an array. If `keys` is present,
 * use the value at each `key` to determine equality.
 *
 * For example, given:
 *
 *     let foo = { a: 1, b: 2, c: 3 }
 *     let bar = { a: 1, b: 2, c: 4 }
 *
 * calling `dedupe([foo, bar])` would return `[foo, bar]`
 * calling `dedupe([foo, bar], 'a')` would return `[foo]`
 * calling `dedupe([foo, bar], 'a', 'c')` would return `[foo, bar]`
 */
export function dedupe(arr) {
    var keys = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        keys[_i - 1] = arguments[_i];
    }
    if (keys.length === 0) {
        return Array.from(new Set(arr));
    }
    var map = new Map();
    arr.forEach(function (element) {
        var compositeKey = keys.map(function (key) { return element[key]; }).join('');
        if (!map.has(compositeKey)) {
            map.set(compositeKey, element);
        }
    });
    return Array.from(map.values());
}
/**
 * Sum the values of each element in `arr` at `key`.
 */
export function sum(arr, key) {
    return arr.map(function (element) { return element[key]; })
        .reduce(function (acc, cur) {
        acc += cur;
        return acc;
    }, 0);
}
