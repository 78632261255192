import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Package } from 'app/shared/models';
import { ModalComponent } from 'app/shared/elements/modal';
import { ButtonComponent } from 'app/shared/elements/button';
import { OverlayComponent } from 'app/shared/elements/overlay';
import { InventoryTargetingComponent } from 'app/shared/components/inventory-targeting';
import { ErrorHelper } from 'app/core/errors/error-helper';
import { PackageRepository, MediaGroupRepository } from 'app/core/repositories';
import { AuthorizationService } from 'app/core/authorization.service';
import validationErrors from 'app/core/errors/validation-errors.json';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { distinctUntilChanged, debounceTime, switchMap, filter } from 'rxjs/operators';
import { IdService } from 'app/core';

@Component({
    selector: 'package-form',
    templateUrl: './package-form.html',
    styleUrls: ['./package-form.styl']
})
export class PackageFormComponent {
    @ViewChild(NgForm, { static: true }) packageForm: NgForm;
    @ViewChild(ModalComponent, { static: true }) modal: ModalComponent;
    @ViewChild(OverlayComponent, { static: true }) overlay: OverlayComponent;
    @ViewChild(InventoryTargetingComponent, { static: true }) inventoryTargeting: InventoryTargetingComponent;
    @ViewChild('saveButton', { static: true }) saveButton: ButtonComponent;
    @Output() saved: EventEmitter<any> = new EventEmitter();

    header: string;
    package: any = {};
    mediaGroup: any = null;
    inventory: any[] = [];
    search$ = new BehaviorSubject('');
    userMediaGroups$ = new BehaviorSubject([]);
    errorHelper: ErrorHelper = new ErrorHelper();
    validationErrors = validationErrors;
    intialSearchMGResultCount: number;
    selectedMediaGroup: any = null;

    options = {
        demandTypeOptions: [
            { key: 0, value: 'direct', label: 'Direct' },
            { key: 1, value: 'exchange', label: 'Exchange' }
        ]
    };

    constructor(
        private packageRepository: PackageRepository,
        private mediaGroupRepository: MediaGroupRepository,
        public auth: AuthorizationService,
        public id: IdService
    ) { }

    ngOnInit() {
        this.search$.pipe(
            distinctUntilChanged(),
            debounceTime(200),
            switchMap(search => {
                const conditions = [];

                if (search.length > 0) {
                    conditions.push({
                        field: 'name',
                        value: search,
                        operator: 'like'
                    });

                    const refId = parseInt(search);
                    if (!isNaN(refId) && refId.toString() === search) {
                        conditions.push({
                            field: 'refId',
                            value: refId,
                            mode: 'or'
                        });
                    }
                }

                return this.mediaGroupRepository.search({ conditions });
            })
        ).subscribe(mediaGroups => {
            if (typeof this.intialSearchMGResultCount !== 'number')  {
                this.intialSearchMGResultCount = mediaGroups.length;
                if (this.intialSearchMGResultCount === 1) {
                    this.mediaGroup = mediaGroups[0];
                    this.selectedMediaGroup = mediaGroups[0];
                }
            }
            return this.userMediaGroups$.next(mediaGroups)
        });
        this.search$.next('');
        this.modal.closed.subscribe(() => this.reset());
        this.modal.closed.pipe(filter(pkg => pkg !== null)).subscribe(this.saved);
    }

    inputChange(search: string) {
        this.search$.next(search);
    }

    createPackage(): void {
        this.inventory = [];
        this.header = 'Create Package';
        this.package = new Package();
        this.package.demandType = 'direct';
        this.mediaGroup = this.selectedMediaGroup;
        this.modal.open();
    }

    saveAndExit() {
        this.overlay.show();
        this.saveButton.load();
        this.errorHelper.resetAllErrors();
        this.package.publishers = [];
        this.package.newsletters = [];
        this.package.adSlots = [];
        this.inventory.forEach(item => {
            if (item.type === 'Publisher') {
                this.package.publishers.push(item.id);
            }
            else if (item.type === 'Newsletter') {
                this.package.newsletters.push(item.id);
            }
            else if (item.type === 'Ad Slot') {
                this.package.adSlots.push(item.id);
            }
        });

        if (!this.auth.isInternalUser) {
            this.package.demandType = 'direct';
        }
        if (this.mediaGroup
            && this.package.demandType === 'direct') {
            this.package.mediaGroup = this.mediaGroup.id;
        } else {
            this.package.mediaGroup = null;
        }

        this.packageRepository.save(this.package).subscribe(
            pkg => {
                this.modal.close(pkg);
                this.overlay.hide();
                this.saveButton.reset();
            },
            (err: HttpErrorResponse) => {
                this.overlay.hide();
                this.saveButton.reset();
                this.errorHelper.loadBackEndErrors(err.error.errors);
            });
    }

    reset() {
        this.errorHelper.resetAllErrors();
        this.packageForm.reset();
        this.inventoryTargeting.removeAll();
    }
}