import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReportingSlicer } from 'app/shared/models';
import { BackendRepository } from './backend-repository';

@Injectable()
export class ReportingSlicerRepository extends BackendRepository<ReportingSlicer> {
    public constructor(http: HttpClient) {
        super(http, '/reporting/slicer', ReportingSlicer);
    }

    public querySlicer(slicerQuery): Observable<ReportingSlicer> {
        return this.http.post(this.url(this.path), slicerQuery) as Observable<ReportingSlicer>;
    }
}
