var DialogConfirmationComponent = /** @class */ (function () {
    function DialogConfirmationComponent() {
        this.valid = true;
        this._confirmed = false;
    }
    Object.defineProperty(DialogConfirmationComponent.prototype, "confirmed", {
        get: function () {
            return this._confirmed;
        },
        set: function (value) {
            this.valid = this._confirmed = value;
        },
        enumerable: true,
        configurable: true
    });
    DialogConfirmationComponent.prototype.check = function () {
        return this.valid = this.confirmed;
    };
    DialogConfirmationComponent.prototype.reset = function () {
        this.valid = true;
        this._confirmed = false;
    };
    return DialogConfirmationComponent;
}());
export { DialogConfirmationComponent };
