
import { Model, TargetingType } from './model';
import { DisplayUtils } from 'app/shared/helpers/display-utils';

export type OwnerType = 'Agency' | 'Media Group' | 'Publisher';

export enum AudiencePriceTier {
    Tier_1 = 1,
    Tier_2,
    Tier_3,
    Tier_4
}

export enum AdvertiserTier {
    Major = 'major_account',
    Mid_Market = 'mid_market_account',
    Other = 'other_account'
}

export class Advertiser extends Model {
    refId: number;
    name: string;
    agency: string;
    mediaGroup: string;
    externalId: string;
    description: string;
    category: number;
    categories: Array<number>;
    contract: any;
    owner: {
        refId: number,
        name: string,
        type: OwnerType
        id: string
    };
    domain: string;
    ownerName: string;
    resultType: string;
    agencyName: string;
    mediaGroupName: string;
    managerFirstName: string;
    managerLastName: string;
    executiveId: string;
    executiveFirstName: string;
    executiveLastName: string;
    admin: string;
    executive: string;
    dmps: any[];
    targetedPublishers: string[];
    targetedDomains: string[];
    targetingType: TargetingType;
    publisher?: string;
    modified?: string;
    suppressCompetitive: boolean;
    audiencePriceTier: AudiencePriceTier;
    salesforceId: string;
    accountId: number;
    advertiserTier: AdvertiserTier;

    constructor(attributes: any = {}) {
        super();

        Object.assign(this, {
            owner: {}
        }, attributes);
    }

    public serialize(): string {
        const advertiser = this.clone(Advertiser);

        delete advertiser.owner.refId;

        // LSD requires a description but doesn't use it.
        advertiser.description = 'Default description, courtesy of Maverick.';

        if (typeof advertiser.externalId === 'string') {
            if (advertiser.externalId.length < 1) {
                advertiser.externalId = null;
            }
        }

        if (!advertiser.salesforceId) {
            advertiser.salesforceId = null;
        }

        return JSON.stringify(advertiser);
    }

    get managedBy() {
        if (typeof this.agencyName === 'string') {
            return this.agencyName;
        }
        return this.mediaGroupName;
    }

    get managerName() {
        return DisplayUtils.displayName(this.managerFirstName, this.managerLastName);
    }

    get executiveName() {
        return DisplayUtils.displayName(this.executiveFirstName, this.executiveLastName);
    }

    get entity() {
        return 'Advertiser';
    }
}
