/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./notifications.component";
import * as i2 from "../../core/notifications.service";
import * as i3 from "../../core/bulk-operation-status-toast.service";
var styles_NotificationsComponent = [];
var RenderType_NotificationsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotificationsComponent, data: {} });
export { RenderType_NotificationsComponent as RenderType_NotificationsComponent };
export function View_NotificationsComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_NotificationsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "ng-component", [], null, null, null, View_NotificationsComponent_0, RenderType_NotificationsComponent)), i0.ɵdid(1, 4374528, null, 0, i1.NotificationsComponent, [i0.ComponentFactoryResolver, i0.ViewContainerRef, i0.Renderer2, i2.NotificationsService, i3.BulkOperationStatusToastService], null, null)], null, null); }
var NotificationsComponentNgFactory = i0.ɵccf("ng-component", i1.NotificationsComponent, View_NotificationsComponent_Host_0, {}, {}, []);
export { NotificationsComponentNgFactory as NotificationsComponentNgFactory };
