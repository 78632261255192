import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Agency } from 'app/shared/models';
import { BackendRepository, Query } from './backend-repository';
import { publishLast, refCount, map } from 'rxjs/operators';
import { Option } from 'app/shared/elements/dropdown';

@Injectable()
export class AgencyRepository extends BackendRepository<Agency> {
    public constructor(http: HttpClient) {
        super(http, '/agency', Agency);
    }
    private _allResult: Observable<Agency[]> = null;

    public activate(agency: Agency): Observable<Agency> {
        return this.http.post(this.url(this.path, 'activate', agency.id), agency)
            .pipe(map(response => this.build(response['output'])));
    }

    public deactivate(agency: Agency): Observable<Agency> {
        return this.http.post(this.url(this.path, 'deactivate', agency.id), agency)
            .pipe(map(response => this.build(response['output'])));
    }

    public all(query: Query = {}): Observable<Array<Agency>> {

        if (this._allResult) {
            return this._allResult;
        }

        this._allResult = super.all(query)
            .pipe(publishLast(), refCount());

        return this._allResult;
    }

    public searchAsOptions(query: Query = {}): Observable<Option[]> {
        return this.all(query).pipe(map(agencies => {
            return agencies.map(
                option => {
                    return {
                        key: option.refId,
                        value: option.refId,
                        label: "<span class='supporting-color'>(" + option.refId + ") </span><span class='bold'>" + option.name + "</span>",
                        taglabel: "(" + option.refId + ") " + option.name,
                        data: option
                    };
                });
        }));
    }

    public convertAgencyToOptions(agencies: Observable<Agency[]>): Observable<Option[]> {
        return agencies.pipe(map(agency => {
            return agency.map(option => {
                return {
                    key: option.refId,
                    value: option.refId,
                    label:option.name,
                    data: option
                };
            });
        }));
    }
}
