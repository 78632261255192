import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { forkJoin, of, ReplaySubject, Subject } from 'rxjs';
import { mergeMap, map, takeUntil } from 'rxjs/operators';

import { Action } from 'app/shared/helpers/available-actions.service';
import { Advertiser, LiveConnectTag } from 'app/shared/models';
import { LiveConnectService } from 'app/core';
import { LiveConnectOverviewService } from './live-connect-overview.service';

@Component({
    templateUrl:  './live-connect-overview.component.html',
    providers:  [LiveConnectOverviewService]
})
export class LiveConnectOverviewComponent implements OnInit, OnDestroy {
    readonly tag$ = new ReplaySubject<LiveConnectTag>(1);
    readonly advertiser$ = this.liveConnectOverviewService.advertiser$;

    private readonly onDestroy$ = new Subject<void>();

    readonly actions = [
        { value: Action.ViewTags, label: 'View Tags', key: 0 },
        { value: Action.CreateConversionTracker, label: 'Create Conversion Tracker', key: 1 },
    ];

    constructor(
        private title: Title,
        private route: ActivatedRoute,
        private router: Router,
        private liveConnectService: LiveConnectService,
        private liveConnectOverviewService: LiveConnectOverviewService
    ) {}

    ngOnInit() {
        this.route.data.pipe(
            takeUntil(this.onDestroy$),
            map(data => data.advertiser),
            mergeMap(advertiser => forkJoin(
                of(advertiser),
                this.liveConnectService.getTagByAdvertiserId(advertiser.refId, advertiser.accountId)
            ))
        ).subscribe(([advertiser, tag]) => {
            this.tag$.next(tag as LiveConnectTag);
            this.advertiser$.next(advertiser);
            this.title.setTitle(`${advertiser.refId} - LiveConnect Manager`);
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    tagStatus(advertiserRefId: number) {
        return this.liveConnectService.tagStatus(advertiserRefId);
    }

    handle(action: Action, advertiser: Advertiser) {
        switch (action) {
            case Action.CreateConversionTracker:
                this.router.navigate(['/campaign-manager/conversion-trackers/new']);
                break;
            case Action.ViewTags:
                this.router.navigate(['/measurement/live-connect-manager', advertiser.refId, 'setup']);
                break;
        }
    }
}
