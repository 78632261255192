import { Model } from "../model";
import { OperationInnerResponse } from "./operation-inner-response";

export enum OperationStatus {
    Completed = 'completed',
    Failed = 'failed',
    Cancelled = 'cancelled',
    Pending = 'pending',
}

export class OperationResponse extends Model {

    public method: string;
    public operation_map_id: string;
    public status: OperationStatus;
    public status_code: number;
    public uri: string;
    public response: OperationInnerResponse;

    get entity(): string {
        return 'Operation Response';
    }
}
