export const DataSourceRegistry = {
    'aggregations': {
        'Count': {
            'type': 'count',
            'name': 'Count'
        },
        'Impressions': {
            'fieldName': 'impressions',
            'type': 'longSum',
            'name': 'Impressions'
        },
        'Page Views': {
            'fieldName': 'page_views',
            'type': 'longSum',
            'name': 'Page Views'
        },
        'Clicks': {
            'fieldName': 'clicks',
            'type': 'longSum',
            'name': 'Clicks'
        },
        'PCC': {
            'fieldName': 'conversions',
            'type': 'longSum',
            'name': 'PCC'
        },
        'Avg Won Bid CPM': {
            'fieldName': 'avg_won_bid_cpm',
            'type': 'doubleSum',
            'name': 'Avg Won Bid CPM'
        },
        'SSP Fee Due': {
            'fieldName': 'ssp_fee_due',
            'type': 'doubleSum',
            'name': 'SSP Fee Due'
        },
        'PVC': {
            'fieldName': 'pvc',
            'type': 'doubleSum',
            'name': 'PVC'
        },
        'Expected Conversions': {
            'fieldName': 'expected_conversions',
            'type': 'doubleSum',
            'name': 'Expected Conversions'
        },
        'Advertiser Spent': {
            'fieldName': 'advertiser_spent',
            'type': 'doubleSum',
            'name': 'Advertiser Spend'
        },
        'Advertiser Spend': {
            'fieldName': 'advertiser_spent',
            'type': 'doubleSum',
            'name': 'Advertiser Spend'
        },
        'First Impressions': {
            'fieldName': 'decisions',
            'type': 'longSum',
            'name': 'First Impressions'
        },
        'Expected Payout': {
            'fieldName': 'expected_payout',
            'type': 'doubleSum',
            'name': 'Expected Payout'
        },
        'Programmatic Fee Due': {
            'fieldName': 'programmatic_fee_due',
            'type': 'doubleSum',
            'name': 'Programmatic Fee Due'
        },
        'Net Revenue': {
            'type': 'javascript',
            'fieldNames': [
                'publisher_revenue',
                'id_sync_fee',
                'ssp_fee'
            ],
            'fnAggregate': 'function(current, p, i, s){ return current + (p - (i * p / 100) - (s * p / 100)); }',
            'fnCombine': 'function(partialA, partialB){ return partialA + partialB; }',
            'fnReset': 'function(){ return 0; }',
            'name': 'Net Revenue'
        },
        'Publisher Revenue': {
            'fieldName': 'publisher_revenue',
            'type': 'doubleSum',
            'name': 'Publisher Revenue'
        },
        'Expected Clicks': {
            'fieldName': 'expected_clicks',
            'type': 'doubleSum',
            'name': 'Expected Clicks'
        },
        'Anamoly Decisions Unfiltered': {
            'fieldName': 'anomaly_decisions_unfiltered',
            'type': 'longSum',
            'name': 'Anamoly Decisions Unfiltered'
        },
        'Anomaly Click Filtered': {
            'fieldName': 'anomaly_clicks_filtered',
            'type': 'longSum',
            'name': 'Anomaly Click Filtered'
        },
        'XML Price': {
            'fieldName': 'xml_price',
            'type': 'doubleSum',
            'name': 'XML Price'
        },
        'Bids': {
            'fieldName': 'bids',
            'type': 'longSum',
            'name': 'Bids'
        },
        'Campaign Bids': {
            'fieldName': 'bids_cp',
            'type': 'longSum',
            'name': 'Campaign Bids'
        },
        'No Bids': {
            'fieldName': 'nobids',
            'type': 'longSum',
            'name': 'No Bids'
        },
        'Wins': {
            'fieldName': 'wins',
            'type': 'longSum',
            'name': 'Wins'
        },
        'Campaign Wins': {
            'fieldName': 'wins_cp',
            'type': 'longSum',
            'name': 'Campaign Wins'
        },
        'Reopens': {
            'fieldName': 'expected_clicks',
            'type': 'longSum',
            'name': 'Reopens'
        },
        'Post Click Conversions': {
            'fieldName': 'pc_convs',
            'type': 'doubleSum',
            'name': 'Post Click Conversions'
        },
        'Advertiser Payout': {
            'fieldName': 'advertiser_payout',
            'type': 'doubleSum',
            'name': 'Advertiser Payout'
        },
        'Bid Attempt': {
            'fieldName': 'bid_attempt',
            'type': 'doubleSum',
            'name': 'Bid Attempt'
        },
        'Winner Price': {
            'fieldName': 'winner_price',
            'type': 'doubleSum',
            'name': 'Winner Price'
        },
        'Bid Won': {
            'fieldName': 'bid_won',
            'type': 'doubleSum',
            'name': 'Bid Won'
        },
        'Anomoly Impressions Filtered': {
            'fieldName': 'anomaly_imps_filtered',
            'type': 'longSum',
            'name': 'Anomoly Impressions Filtered'
        },
        'Fraud Impressions': {
            'fieldName': 'fraud_imps',
            'type': 'longSum',
            'name': 'Fraud Impressions'
        },
        'Fraud Clicks': {
            'fieldName': 'fraud_clicks',
            'type': 'longSum',
            'name': 'Fraud Clicks'
        },
        'Bids Variance': {
            'fieldName': 'bids_variance',
            'type': 'doubleSum',
            'name': 'Bids Variance'
        },
        'No Bids Variance': {
            'fieldName': 'nobids_variance',
            'type': 'doubleSum',
            'name': 'No Bids Variance'
        },
        'Impressions Variance': {
            'fieldName': 'imps_variance',
            'type': 'doubleSum',
            'name': 'Impressions Variance'
        },
        'Clicks Variance': {
            'fieldName': 'clicks_variance',
            'type': 'doubleSum',
            'name': 'Clicks Variance'
        },
        'Reopens Variance': {
            'fieldName': 'reopens_variance',
            'type': 'doubleSum',
            'name': 'Reopens Variance'
        },
        'Post-View Conversions Variance': {
            'fieldName': 'pv_convs_variance',
            'type': 'doubleSum',
            'name': 'Post-View Conversions Variance'
        },
        'Post-Click Conversions Variance': {
            'fieldName': 'pc_convs_variance',
            'type': 'doubleSum',
            'name': 'Post-Click Conversions Variance'
        },
        'Advertiser Payout Variance': {
            'fieldName': 'advertiser_payout_variance',
            'type': 'doubleSum',
            'name': 'Advertiser Payout Variance'
        },
        'Bid Attempt Variance': {
            'fieldName': 'bid_attempt_variance',
            'type': 'doubleSum',
            'name': 'Bid Attempt Variance'
        },
        'Winner Price Variance': {
            'fieldName': 'winner_price_variance',
            'type': 'doubleSum',
            'name': 'Winner Price Variance'
        },
        'Bid Won Variance': {
            'fieldName': 'bid_won_variance',
            'type': 'doubleSum',
            'name': 'Bid Won Variance'
        },
        'DSP Amount Variance': {
            'fieldName': 'dsp_amount_variance',
            'type': 'doubleSum',
            'name': 'DSP Amount Variance'
        },
        'Anomaly Clicks Filtered Variance': {
            'fieldName': 'anomaly_clicks_filtered_variance',
            'type': 'doubleSum',
            'name': 'Anomaly Clicks Filtered Variance'
        },
        'Anomaly Impressions Filtered Variance': {
            'fieldName': 'anomaly_imps_filtered_variance',
            'type': 'doubleSum',
            'name': 'Anomaly Impressions Filtered Variance'
        },
        'Fraud Impressionss Variance': {
            'fieldName': 'fraud_imps_variance',
            'type': 'doubleSum',
            'name': 'Fraud Impressionss Variance'
        },
        'Fraud Clicks Variance': {
            'fieldName': 'fraud_clicks_variance',
            'type': 'doubleSum',
            'name': 'Fraud Clicks Variance'
        },
        'PVC Credit': {
            'fieldName': 'pvc_credit',
            'type': 'longSum',
            'name': 'PVC Credit'
        },
        'Noads': {
            'fieldName': 'noads',
            'type': 'doubleSum',
            'name': 'Noads'
        },
        'SecondPrice': {
            'fieldName': 'second_price',
            'type': 'longSum',
            'name': 'SecondPrice'
        },
        'UPA': {
            'fieldName': 'upa',
            'type': 'doubleSum',
            'name': 'UPA (PCC)'
        },
        'UPA (PCC)': {
            'fieldName': 'upa',
            'type': 'doubleSum',
            'name': 'UPA (PCC)'
        },
        'Intermediate PCC UPA': {
            'fieldName': 'upa_pcc_intermediate',
            'type': 'doubleSum',
            'name': 'Intermediate PCC UPA'
        },
        'Intermediate PVC UPA': {
            'fieldName': 'upa_pvc_intermediate',
            'type': 'doubleSum',
            'name': 'Intermediate PVC UPA'
        },
        'UPA PVC Final': {
            'fieldName': 'upa_pvc_final',
            'type': 'doubleSum',
            'name': 'UPA (PVC)'
        },
        'UPA (PVC)': {
            'fieldName': 'upa_pvc_final',
            'type': 'doubleSum',
            'name': 'UPA (PVC)'
        },
        'Intermediate PCC': {
            'fieldName': 'conversions_intermediate',
            'type': 'doubleSum',
            'name': 'Intermediate PCC'
        },
        'Intermediate PVC': {
            'fieldName': 'pvc_intermediate',
            'type': 'doubleSum',
            'name': 'Intermediate PVC'
        },
        'NoAds': {
            'fieldName': 'noads',
            'type': 'doubleSum',
            'name': 'NoAds'
        },
        'Adjusted Impressions': {
            'fieldName': 'adjusted_impressions',
            'type': 'doubleSum',
            'name': 'Adjusted Impressions'
        },
        'Ad Serving Cost': {
            'fieldName': 'ad_serving_cost',
            'type': 'doubleSum',
            'name': 'Ad Serving Cost'
        },
        'LiveAudience Data Fee': {
            'fieldName': 'liveaudience_data_fee',
            'type': 'doubleSum',
            'name': 'LiveAudience Data Fee'
        },
        'Impressions (a)': {
            'fieldName': 'adjusted_impressions',
            'type': 'doubleSum',
            'name': 'Impressions (a)'
        },
        'Media Cost': {
            'fieldName': 'media_cost',
            'type': 'doubleSum',
            'name': 'Media Cost'
        }
    },
    'postAggregations': {
        'Reopens': {
            'type': 'javascript',
            'name': 'Reopens',
            'fieldNames': [
                'Impressions',
                'First Impressions'
            ],
            'function':
                'function(impressions, decisions) { return impressions >= decisions ? (impressions-decisions) : 0;};'
        },
        'CTR': {
            'type': 'javascript',
            'name': 'CTR',
            'fieldNames': [
                'Clicks',
                'Impressions'
            ],
            'function': 'function(clicks, impressions) { return impressions > 0 ? (clicks/impressions) : 0; };'
        },
        'CCR': {
            'type': 'javascript',
            'name': 'CCR',
            'fieldNames': [
                'PCC',
                'Clicks'
            ],
            'function': 'function(pcc, clicks) { return clicks > 0 ? (pcc/clicks) : 0; };'
        },
        'NetECPM': {
            'type': 'javascript',
            'name': 'NetECPM',
            'fieldNames': [
                'Net Revenue',
                'Impressions'
            ],
            'function':
                `function(net_revenue, impressions) {
                    return impressions ? (net_revenue/impressions) * 1000 : 0;
                };`
        },
        'GrossECPM': {
            'type': 'javascript',
            'name': 'GrossECPM',
            'fieldNames': [
                'Advertiser Spend',
                'Impressions'
            ],
            'function':
                `function(advertiser_spent, impressions) {
                    return impressions ? (advertiser_spent/impressions) * 1000 : 0;
                };`
        },
        'eCPA (PCC)': {
            'type': 'javascript',
            'name': 'eCPA (PCC)',
            'fieldNames': [
                'PCC',
                'Advertiser Spend'
            ],
            'function':
                'function(pcc, advertiser_spent) { return pcc ? advertiser_spent / pcc : 0; };'
        },
        'GrossECPC': {
            'type': 'javascript',
            'name': 'GrossECPC',
            'fieldNames': [
                'Advertiser Spend',
                'Clicks'
            ],
            'function': 'function(advertiser_spent, clicks) { return clicks ? (advertiser_spent/clicks) : 0; };'
        },
        'NetECPA': {
            'type': 'javascript',
            'name': 'NetECPA',
            'fieldNames': [
                'Net Revenue',
                'PCC'
            ],
            'function':
                `function(net_revenue, pcc) {
                    return pcc ? (net_revenue/pcc) : 0;
                };`
        },
        'NetECPC': {
            'type': 'javascript',
            'name': 'NetECPC',
            'fieldNames': [
                'Net Revenue',
                'Clicks'
            ],
            'function': 'function(net_revenue, clicks) { return clicks ? (net_revenue/clicks) : 0; };'
        },
        'CPA': {
            'type': 'javascript',
            'name': 'CPA',
            'fieldNames': [
                'Advertiser Spend',
                'PCC'
            ],
            'function':
                'function(advertiser_spent, pcc) {return pcc ? (advertiser_spent/pcc) : 0;};'
        },
        'CPC': {
            'type': 'javascript',
            'name': 'CPC',
            'fieldNames': [
                'Advertiser Spend',
                'Clicks'
            ],
            'function': 'function(advertiser_spent, clicks) {return clicks ? (advertiser_spent/clicks) : 0;};'
        },
        'CampaignWinRate': {
            'type': 'javascript',
            'name': 'CampaignWinRate',
            'fieldNames': [
                'Campaign Wins',
                'Campaign Bids'
            ],
            'function': 'function(wins_cp, bids_cp) {return bids_cp > 0 ? (wins_cp/bids_cp) : 0;};'
        },
        'WinRate': {
            'type': 'javascript',
            'name': 'WinRate',
            'fieldNames': [
                'Wins',
                'Bids'
            ],
            'function': 'function(wins, bids) {return bids > 0 ? (wins/bids) : 0;};'
        },
        'Fill Rate': {
            'type': 'javascript',
            'name': 'Fill Rate',
            'fieldNames': [
                'First Impressions',
                'Noads'
            ],
            'function':
                'function(decisions, noads) { return (decisions + noads) ? (decisions/(decisions + noads)) : 0; };'
        },
        'BookedCPM': {
            'type': 'javascript',
            'name': 'BookedCPM',
            'fieldNames': [
                'Advertiser Spend',
                'First Impressions'
            ],
            'function':
                'function(advertiser_spent, decisions) { return decisions ? (advertiser_spent/decisions) * 1000 : 0; };'
        },
        'ROAS (PCC)': {
            'type': 'javascript',
            'name': 'ROAS (PCC)',
            'fieldNames': [
                'UPA (PCC)',
                'Advertiser Spend'
            ],
            'function': 'function(upa, advertiser_spent) { return advertiser_spent ? upa / advertiser_spent : 0; };'
        },
        'Adjusted CTR': {
            'type': 'javascript',
            'name': 'Adjusted CTR',
            'fieldNames': [
                'Adjusted Impressions',
                'Clicks'
            ],
            'function': 'function(adjusted_impressions, clicks) { return adjusted_impressions > 0 ? (clicks/adjusted_impressions) : 0; };'
        },
        'Adjusted NetECPM': {
            'type': 'javascript',
            'name': 'Adjusted NetECPM',
            'fieldNames': [
                'Net Revenue',
                'Adjusted Impressions'
            ],
            'function':
                `function(net_revenue, adjusted_impressions) {
                    return adjusted_impressions ? (net_revenue/adjusted_impressions) * 1000 : 0;
                };`
        },
        'CTR (a)': {
            'type': 'javascript',
            'name': 'CTR (a)',
            'fieldNames': [
                'Impressions (a)',
                'Clicks'
            ],
            'function': 'function(adjusted_impressions, clicks) { return adjusted_impressions > 0 ? (clicks/adjusted_impressions) : 0; };'
        },
        'NetECPM (a)': {
            'type': 'javascript',
            'name': 'NetECPM (a)',
            'fieldNames': [
                'Net Revenue',
                'Impressions (a)'
            ],
            'function':
                `function(net_revenue, adjusted_impressions) {
                    return adjusted_impressions ? (net_revenue/adjusted_impressions) * 1000 : 0;
                };`
        },
        'Ad Serving Fee': {
            'type': 'javascript',
            'name': 'Ad Serving Fee',
            'fieldNames': [
                'Demand Type',
                'Ad Serving Cost',
                'Impressions (a)'
            ],
            'function':
                `function(demand_type, ad_serving_cost, adjusted_impressions) {
                    return (adjusted_impressions > 0 && (demand_type === 'direct' || demand_type === 'house')) ? (ad_serving_cost/adjusted_impressions) * 1000 : 0;
                };`
        },
        'First Impressions CTR': {
            'type': 'javascript',
            'name': 'First Impressions CTR',
            'fieldNames': [
                'Clicks',
                'First Impressions'
            ],
            'function': 'function(clicks, decisions) { return decisions > 0 ? (clicks/decisions) : 0; };'
        },

        "Conversions": {
            "type": "javascript",
            "name": "Conversions",
            "fieldNames": [
                "PCC",
                "PVC",
                "PVC Credit",
                "First Impressions"
            ],
            "function": "function(pcc, pvc, pvc_credit, decisions) { return decisions ? pcc + ( pvc * pvc_credit / decisions / 100 ) : pcc; };"
        },
        "Intermediate Conversions": {
            "type": "javascript",
            "name": "Intermediate Conversions",
            "fieldNames": [
                "Intermediate PCC",
                "Intermediate PVC",
                "PVC Credit",
                "First Impressions"
            ],
            "function": "function(conversions_intermediate, pvc_intermediate, pvc_credit, decisions) { return decisions ? conversions_intermediate + ( pvc_intermediate * pvc_credit / decisions / 100 ) : conversions_intermediate; };"
        },
        "User Purchase Amount": {
            "type": "javascript",
            "name": "User Purchase Amount",
            "fieldNames": [
                "UPA (PCC)",
                "UPA (PVC)",
                "PVC Credit",
                "First Impressions"
            ],
            "function": "function(pcc_upa, upa_pvc_final, pvc_credit, decisions) { return decisions ? pcc_upa + ( upa_pvc_final * pvc_credit / decisions / 100 ) : pcc_upa; };"
        },
        "Intermediate UPA": {
            "type": "javascript",
            "name": "Intermediate UPA",
            "fieldNames": [
                "Intermediate PCC UPA",
                "Intermediate PVC UPA",
                "PVC Credit",
                "First Impressions"
            ],
            "function": "function(upa_pcc_intermediate, upa_pvc_intermediate, pvc_credit, decisions) { return decisions ? upa_pcc_intermediate + ( upa_pvc_intermediate * pvc_credit / decisions / 100 ) : upa_pcc_intermediate; };"
        },
        "eCPA": {
            "type": "javascript",
            "name": "eCPA",
            "fieldNames": [
                "Advertiser Spend",
                "PCC",
                "PVC",
                "PVC Credit",
                "First Impressions"
            ],
            "function": "function(advertiser_spent, pcc, pvc, pvc_credit, decisions) { return (pcc * decisions + pvc * pvc_credit) ? advertiser_spent / ( pcc + ( pvc * pvc_credit / decisions / 100 ) ) : 0; };"
        },
        "Intermediate eCPA": {
            "type": "javascript",
            "name": "Intermediate eCPA",
            "fieldNames": [
                "Advertiser Spend",
                "Intermediate PCC",
                "Intermediate PVC",
                "PVC Credit",
                "First Impressions"
            ],
            "function": "function(advertiser_spent, pcc, pvc, pvc_credit, decisions) { return (pcc * decisions + pvc * pvc_credit) ? advertiser_spent / ( pcc + ( pvc * pvc_credit / decisions / 100 ) ) : 0; };"
        },
        "Intermediate ROAS": {
            "type": "javascript",
            "name": "Intermediate ROAS",
            "fieldNames": [
                "Intermediate PCC UPA",
                "Intermediate PVC UPA",
                "PVC Credit",
                "First Impressions",
                "Advertiser Spend",
            ],
            "function": "function(upa_pcc_final, upa_pvc_final, pvc_credit, decisions, advertiser_spent) { return (decisions * advertiser_spent) ? ( upa_pcc_final + ( upa_pvc_final * pvc_credit / decisions / 100 ) ) / advertiser_spent : 0; };"
        },
        "ROAS": {
            "type": "javascript",
            "name": "ROAS",
            "fieldNames": [
                "UPA (PCC)",
                "UPA (PVC)",
                "PVC Credit",
                "First Impressions",
                "Advertiser Spend",
            ],
            "function": "function(upa_pcc_final, upa_pvc_final, pvc_credit, decisions, advertiser_spent) { return (decisions * advertiser_spent) ? ( upa_pcc_final + ( upa_pvc_final * pvc_credit / decisions / 100 ) ) / advertiser_spent : 0; };"
        },
        "Conversion Rate": {
            "type": "javascript",
            "name": "Conversion Rate",
            "fieldNames": [
                "PCC",
                "PVC",
                "PVC Credit",
                "First Impressions"
            ],
            "function": "function(pcc, pvc, pvc_credit, decisions) { return decisions ? ( pcc + ( pvc * pvc_credit / decisions / 100 ) ) / decisions : 0; };"
        },

        "Total Conversions": {
            "type": "javascript",
            "name": "Total Conversions",
            "fieldNames": [
                "PCC",
                "PVC"
            ],
            "function": "function(conversions, pvc) { return conversions + pvc; };"
        },
        "Intermediate UPA (Total Conversions)": {
            "type": "javascript",
            "name": "Intermediate UPA (Total Conversions)",
            "fieldNames": [
                "Intermediate PCC UPA",
                "Intermediate PVC UPA"
            ],
            "function": "function(intermediate_pcc_upa, intermediate_pvc_upa) { return intermediate_pcc_upa + intermediate_pvc_upa; };"
        },
        "User Purchase Amount (Total Conversions)": {
            "type": "javascript",
            "name": "User Purchase Amount (Total Conversions)",
            "fieldNames": [
                "UPA (PCC)",
                "UPA (PVC)"
            ],
            "function": "function(upa, upa_pvc_final) { return upa + upa_pvc_final; };"
        },
        "eCPA (Total Conversions)": {
            "type": "javascript",
            "name": "eCPA (Total Conversions)",
            "fieldNames": [
                "PCC",
                "PVC",
                "Advertiser Spend",
            ],
            "function": "function(conversions, pvc, advertiser_spent) { return (pvc + conversions) ? advertiser_spent / (conversions + pvc) : 0; };"
        },
        "eCPA (Total Intermediate Conversions)": {
            "type": "javascript",
            "name": "eCPA (Total Intermediate Conversions)",
            "fieldNames": [
                "Advertiser Spend",
                "Intermediate PVC",
                "Intermediate PCC"
            ],
            "function": "function(advertiser_spent, pvc_intermediate, conversions_intermediate) { return (pvc_intermediate + conversions_intermediate) ? advertiser_spent / (pvc_intermediate + conversions_intermediate) : 0; };"
        },
        "Total Intermediate Conversions": {
            "type": "javascript",
            "name": "Total Intermediate Conversions",
            "fieldNames": [
                "Intermediate PCC",
                "Intermediate PVC"
            ],
            "function": "function(conversions_intermediate, pvc_intermediate) { return conversions_intermediate + pvc_intermediate };"
        },
        "ROAS (Total Conversions)": {
             "type": "javascript",
            "name": "ROAS (Total Conversions)",
            "fieldNames": [
                "Advertiser Spend",
                "UPA (PCC)",
                "UPA (PVC)"
            ],
            "function": "function(advertiser_spent, upa, upa_pvc_final) { return advertiser_spent ? ((upa + upa_pvc_final)/advertiser_spent) : 0; };"
        },
        "ROAS (Total Intermediate Conversions)": {
            "type": "javascript",
            "name": "ROAS (Total Intermediate Conversions)",
            "fieldNames": [
                "Advertiser Spend",
                "Intermediate PCC UPA",
                "Intermediate PVC UPA"
            ],
            "function": "function(advertiser_spent, upa_pcc_intermediate, upa_pvc_intermediate) { return advertiser_spent ? ((upa_pcc_intermediate + upa_pvc_intermediate)/advertiser_spent) : 0; };"
        },
        "Conversion Rate (Total Conversions)": {
            "type": "javascript",
            "name": "Conversion Rate (Total Conversions)",
            "fieldNames": [
                "PCC",
                "PVC",
                "First Impressions",
            ],
            "function": "function(pcc, pvc, decisions) { return decisions ? ( pcc + pvc ) / decisions : 0; };"
        }
    },
    'dimensions': {
        'Publisher ID': {
            'type': 'default',
            'dimension': 'publisher_id',
            'outputName': 'Publisher ID'
        },
        'Publisher': {
            'type': 'default',
            'dimension': 'publisher_id',
            'outputName': 'Publisher'
        },
        'Publisher Domain': {
            'type': 'default',
            'dimension': 'publisher_id',
            'outputName': 'Publisher Domain'
        },
        'Taboola ID': {
            'type': 'default',
            'dimension': 'taboola_id',
            'outputName': 'Taboola ID'
        },
        'Partner ID:': {
            'type': 'default',
            'dimension': 'partner_id',
            'outputName': 'Partner ID'
        },
        'Partner Name:': {
            'type': 'default',
            'dimension': 'partner_name',
            'outputName': 'Partner Name'
        },
        'Demand Type': {
            'type': 'default',
            'dimension': 'demand_type',
            'outputName': 'Demand Type'
        },
        'Bid Bucket': {
            'type': 'default',
            'dimension': 'bid_bucket',
            'outputName': 'Bid Bucket'
        },
        'Mail Type': {
            'type': 'default',
            'dimension': 'mail_type',
            'outputName': 'Mail Type'
        },
        'Advertiser ID': {
            'type': 'default',
            'dimension': 'advertiser_id',
            'outputName': 'Advertiser ID'
        },
        'Advertiser': {
            'type': 'default',
            'dimension': 'advertiser_id',
            'outputName': 'Advertiser'
        },
        'Page Type': {
            'type': 'default',
            'dimension': 'page_type',
            'outputName': 'Page Type'
        },
        'Template ID': {
            'type': 'default',
            'dimension': 'template_id',
            'outputName': 'Template ID'
        },
        'Template': {
            'type': 'default',
            'dimension': 'template_id',
            'outputName': 'Template'
        },
        'Section ID': {
            'type': 'default',
            'dimension': 'section_id',
            'outputName': 'Section ID'
        },
        'Section': {
            'type': 'default',
            'dimension': 'section_id',
            'outputName': 'Section'
        },
        'Creative Size': {
            'type': 'default',
            'dimension': 'size',
            'outputName': 'Creative Size'
        },
        'Campaign ID': {
            'type': 'default',
            'dimension': 'campaign_id',
            'outputName': 'Campaign ID'
        },
        'Campaign': {
            'type': 'default',
            'dimension': 'campaign_id',
            'outputName': 'Campaign'
        },
        'Line Item ID': {
            'type': 'default',
            'dimension': 'line_item_id',
            'outputName': 'Line Item ID'
        },
        'Line Item': {
            'type': 'default',
            'dimension': 'line_item_id',
            'outputName': 'Line Item'
        },
        'Creative ID': {
            'type': 'default',
            'dimension': 'creative_id',
            'outputName': 'Creative ID'
        },
        'Creative': {
            'type': 'default',
            'dimension': 'creative_id',
            'outputName': 'Creative'
        },
        'Age': {
            'type': 'default',
            'dimension': 'age',
            'outputName': 'Age'
        },
        'Gender': {
            'type': 'default',
            'dimension': 'gender',
            'outputName': 'Gender'
        },
        'Pricing Type': {
            'type': 'default',
            'dimension': 'pricing_type',
            'outputName': 'Pricing Type'
        },
        'Goal Type': {
            'type': 'default',
            'dimension': 'goal_type',
            'outputName': 'Goal Type'
        },
        'Region': {
            'type': 'default',
            'dimension': 'region',
            'outputName': 'Region'
        },
        'Zip': {
            'type': 'default',
            'dimension': 'zip',
            'outputName': 'Zip'
        },
        'List': {
            'type': 'default',
            'dimension': 'list_id',
            'outputName': 'List'
        },
        'Browser': {
            'type': 'default',
            'dimension': 'browser',
            'outputName': 'Browser'
        },
        'Agency ID': {
            'type': 'default',
            'dimension': 'agency_id',
            'outputName': 'Agency ID'
        },
        'RTB Domain': {
            'type': 'default',
            'dimension': 'rtb_domain',
            'outputName': 'Advertiser Domain'
        },
        'Domain': {
            'type': 'default',
            'dimension': 'domain',
            'outputName': 'Email Domain'
        },
        'Country': {
            'type': 'default',
            'dimension': 'country',
            'outputName': 'Country'
        },
        'Device Type': {
            'type': 'default',
            'dimension': 'device_type',
            'outputName': 'Device Type'
        },
        'Metro': {
            'type': 'default',
            'dimension': 'metro',
            'outputName': 'Metro'
        },
        'Device Maker': {
            'type': 'default',
            'dimension': 'device_maker',
            'outputName': 'Device Maker'
        },
        'OS': {
            'type': 'default',
            'dimension': 'os',
            'outputName': 'OS'
        },
        'ISP': {
            'type': 'default',
            'dimension': 'isp',
            'outputName': 'ISP'
        },
        'Key Value Pairs': {
            'type': 'default',
            'dimension': 'key_value_pairs',
            'outputName': 'Key Value Pairs'
        },
        'Gross ECPM Bucket': {
            'type': 'default',
            'dimension': 'gross_ecpm_bucket',
            'outputName': 'Gross ECPM Bucket'
        },
        'NET ECPM Bucket': {
            'type': 'default',
            'dimension': 'net_ecpm_bucket',
            'outputName': 'NET ECPM Bucket'
        },
        'Bidder ID': {
            'type': 'default',
            'dimension': 'bidder_id',
            'outputName': 'Bidder ID'
        },
        'Bidder Seat': {
            'type': 'default',
            'dimension': 'bidder_seat',
            'outputName': 'Bidder Seat'
        },
        'Bidder Campaign ID': {
            'type': 'default',
            'dimension': 'bidder_campaign_id',
            'outputName': 'Bidder Campaign ID'
        },
        'Exchange': {
            'type': 'default',
            'dimension': 'exchange',
            'outputName': 'Exchange'
        },
        'Slot Visibility': {
            'type': 'default',
            'dimension': 'slot_visibility',
            'outputName': 'Slot Visibility'
        },
        'Event Date': {
            'type': 'default',
            'dimension': 'event_date',
            'outputName': 'Event Date'
        },
        'Site Category': {
            'type': 'default',
            'dimension': 'site_cat',
            'outputName': 'Site Category'
        },
        'Advertiser Payout': {
            'type': 'default',
            'dimension': 'adv_payout',
            'outputName': 'Advertiser Payout'
        },
        'City': {
            'type': 'default',
            'dimension': 'city',
            'outputName': 'City'
        },
        'Language': {
            'type': 'default',
            'dimension': 'language',
            'outputName': 'Language'
        },
        'Timezone Offset': {
            'type': 'default',
            'dimension': 'timezone_offset',
            'outputName': 'Timezone Offset'
        },
        'Fraud Type': {
            'type': 'default',
            'dimension': 'fraud_type',
            'outputName': 'Fraud Type'
        },
        'Anomaly Segment': {
            'type': 'default',
            'dimension': 'anomaly_segment',
            'outputName': 'Anomaly Segment'
        },
        'Segments': {
            'type': 'default',
            'dimension': 'segments',
            'outputName': 'Segments'
        },
        'Currency': {
            'type': 'default',
            'dimension': 'currency',
            'outputName': 'Currency'
        },
        'Deal': {
            'type': 'default',
            'dimension': 'deal_id',
            'outputName': 'Deal'
        },
        'Creative URL': {
            'type': 'default',
            'dimension': 'creative_id',
            'outputName': 'Creative URL'
        },
        'ClickThrough URL': {
            'type': 'default',
            'dimension': 'creative_id',
            'outputName': 'ClickThrough URL'
        },
        'Campaign Start Date': {
            'type': 'default',
            'dimension': 'campaign_id',
            'outputName': 'Campaign Start Date'
        },
        'Campaign End Date': {
            'type': 'default',
            'dimension': 'campaign_id',
            'outputName': 'Campaign End Date'
        },
        'Line Item Start Date': {
            'type': 'default',
            'dimension': 'line_item_id',
            'outputName': 'Line Item Start Date'
        },
        'Line Item End Date': {
            'type': 'default',
            'dimension': 'line_item_id',
            'outputName': 'Line Item End Date'
        },
        'Placement ID': {
            'type': 'default',
            'dimension': 'placement_id',
            'outputName': 'Placement ID'
        },
        'Status': {
            'type': 'default',
            'dimension': 'line_item_id',
            'outputName': 'Status'
        },
        'Budget Type': {
            'type': 'default',
            'dimension': 'line_item_id',
            'outputName': 'Budget Type'
        },
        'Pacing': {
            'type': 'default',
            'dimension': 'line_item_id',
            'outputName': 'Pacing'
        },
        'Line Item Bid Amount': {
            'type': 'default',
            'dimension': 'line_item_id',
            'outputName': 'Line Item Bid Amount'
        },
        'Campaign Bid Amount': {
            'type': 'default',
            'dimension': 'campaign_id',
            'outputName': 'Campaign Bid Amount'
        },
        'Line Item Budget': {
            'type': 'default',
            'dimension': 'line_item_id',
            'outputName': 'Line Item Budget'
        },
        'Campaign Budget': {
            'type': 'default',
            'dimension': 'campaign_id',
            'outputName': 'Campaign Budget'
        },
        'Guaranteed': {
            'type': 'default',
            'dimension': 'campaign_id',
            'outputName': 'Guaranteed'
        },
        'Advertiser Domain': {
            'type': 'default',
            'dimension': 'advertiser_domain',
            'outputName': 'Advertiser Domain'
        },
        'Creative Advertiser Domain': {
            'type': 'default',
            'dimension': 'adomain',
            'outputName': 'Creative Advertiser Domain'
        },
        'Click Url': {
            'type': 'default',
            'dimension': 'click_url',
            'outputName': 'Click Url'
        },
        'Image Url': {
            'type': 'default',
            'dimension': 'image_url',
            'outputName': 'Image Url'
        },
        'Line Item Label': {
            'type': 'default',
            'dimension': 'line_item_id',
            'outputName': 'Line Item Label'
        },
        'Campaign Label': {
            'type': 'default',
            'dimension': 'campaign_id',
            'outputName': 'Campaign Label'
        },
        'Advertiser Label': {
            'type': 'default',
            'dimension': 'advertiser_id',
            'outputName': 'Advertiser Label'
        },
        'Creative Label': {
            'type': 'default',
            'dimension': 'creative_id',
            'outputName': 'Creative Label'
        },
        'Publisher Label': {
            'type': 'default',
            'dimension': 'publisher_id',
            'outputName': 'Publisher Label'
        },
        'Template Label': {
            'type': 'default',
            'dimension': 'template_id',
            'outputName': 'Template Label'
        },
        'Section Label': {
            'type': 'default',
            'dimension': 'section_id',
            'outputName': 'Section Label'
        },
        'Creative Type': {
            'type': 'default',
            'dimension': 'creative_type',
            'outputName': 'Creative Type'
        },
        'GAM Line Item ID': {
            'type': 'default',
            'dimension': 'google_line_item_id',
            'outputName': 'GAM Line Item ID'
        },
        'GAM Creative ID': {
            'type': 'default',
            'dimension': 'google_creative_id',
            'outputName': 'GAM Creative ID'
        },
        'Blueprint ID': {
            'type': 'default',
            'dimension': 'blueprint_id',
            'outputName': 'Blueprint ID'
        },
        'Is NCP': {
            'type': 'default',
            'dimension': 'is_ncp',
            'outputName': 'Is NCP'
        },
        'Blueprint': {
            'type': 'lookup',
            'dimension': 'blueprint_id',
            'outputName': 'Blueprint',
            'name': 'blueprint_name'
        },
        'Parent Campaign ID': {
            'type': 'default',
            'dimension': 'parent_campaign_id',
            'outputName': 'Parent Campaign ID',
        }
    },
    'lookups':  {
        "Publisher": {
            "type": "lookup",
            "dimension": "publisher_id",
            "outputName": "Publisher",
            "name": "publisher_name_v2"
        },
        "Publisher Domain": {
            "type": "lookup",
            "dimension": "publisher_id",
            "outputName": "Publisher Domain",
            "name": "publisher_domain_v2"
        },
        "Advertiser": {
            "type": "lookup",
            "dimension": "advertiser_id",
            "outputName": "Advertiser",
            "name": "advertiser_name_v2"
        },
        "Template": {
            "type": "lookup",
            "dimension": "template_id",
            "outputName": "Template",
            "name": "list_name_v2"
        },
        "Section": {
            "type": "lookup",
            "dimension": "section_id",
            "outputName": "Section",
            "name": "section_name_v2"
        },
        "Campaign": {
            "type": "lookup",
            "dimension": "campaign_id",
            "outputName": "Campaign",
            "name": "campaign_name_v2"
        },
        "Creative": {
            "type": "lookup",
            "dimension": "creative_id",
            "outputName": "Creative",
            "name": "creative_name_v2"
        },
        "Metro": {
            "type": "lookup",
            "dimension": "metro",
            "outputName": "Metro",
            "name": "region_name_v2"
        },
        "Line Item": {
            "type": "lookup",
            "dimension": "line_item_id",
            "outputName": "Line Item",
            "name": "strategy_name_v2"
        },
        "Campaign Start Date": {
            "type": "lookup",
            "dimension": "campaign_id",
            "outputName": "Campaign Start Date",
            "name": "campaign_start_date_v2"
        },
        "Creative URL": {
            "type": "lookup",
            "dimension": "creative_id",
            "outputName": "Creative URL",
            "name": "creative_image_url_v2"
        },
        "Campaign End Date": {
            "type": "lookup",
            "dimension": "campaign_id",
            "outputName": "Campaign End Date",
            "name": "campaign_end_date_v2"
        },
        "Line Item Start Date": {
            "type": "lookup",
            "dimension": "line_item_id",
            "outputName": "Line Item Start Date",
            "name": "strategy_start_date_v2"
        },
        "Line Item End Date": {
            "type": "lookup",
            "dimension": "line_item_id",
            "outputName": "Line Item End Date",
            "name": "strategy_end_date_v2"
        },
        "ClickThrough URL": {
            "type": "lookup",
            "dimension": "creative_id",
            "outputName": "ClickThrough URL",
            "name": "creative_click_url_v2"
        },
        "Status": {
            "type": "lookup",
            "dimension": "line_item_id",
            "outputName": "Status",
            "name": "strategy_status_v2"
        },
        "Budget Type": {
            "type": "lookup",
            "dimension": "line_item_id",
            "outputName": "Budget Type",
            "name": "strategy_budget_type_v2"
        },
        "Pacing": {
            "type": "lookup",
            "dimension": "line_item_id",
            "outputName": "Pacing",
            "name": "strategy_pacing_v2"
        },
        "Line Item Bid Amount": {
            "type": "lookup",
            "dimension": "line_item_id",
            "outputName": "Line Item Bid Amount",
            "name": "strategy_bid_amount_v2"
        },
        "Campaign Bid Amount": {
            "type": "lookup",
            "dimension": "campaign_id",
            "outputName": "Campaign Bid Amount",
            "name": "campaign_bid_amount_v2"
        },
        "Line Item Budget": {
            "type": "lookup",
            "dimension": "line_item_id",
            "outputName": "Line Item Budget",
            "name": "strategy_budget_v2"
        },
        "Campaign Budget": {
            "type": "lookup",
            "dimension": "campaign_id",
            "outputName": "Campaign Budget",
            "name": "campaign_budget_v2"
        },
        "Guaranteed": {
            "type": "lookup",
            "dimension": "campaign_id",
            "outputName": "Guaranteed",
            "name": "campaign_guaranteed_v2"
        },
        "Campaign Label": {
            "type": "lookup",
            "dimension": "campaign_id",
            "outputName": "Campaign Label",
            "name": "campaign_external_id_v2"
        },
        "Advertiser Label": {
            "type": "lookup",
            "dimension": "advertiser_id",
            "outputName": "Advertiser Label",
            "name": "pubvertiser_external_id_v2"
        },
        "Creative Label": {
            "type": "lookup",
            "dimension": "creative_id",
            "outputName": "Creative Label",
            "name": "creative_external_id_v2"
        },
        "Line Item Label": {
            "type": "lookup",
            "dimension": "line_item_id",
            "outputName": "Line Item Label",
            "name": "strategy_external_id_v2"
        },
        "Template Label": {
            "type": "lookup",
            "dimension": "template_id",
            "outputName": "Template Label",
            "name": "list_external_id_v2"
        },
        "Publisher Label": {
            "type": "lookup",
            "dimension": "publisher_id",
            "outputName": "Publisher Label",
            "name": "publisher_external_id_v2"
        },
        "Section Label": {
            "type": "lookup",
            "dimension": "section_id",
            "outputName": "Section Label",
            "name": "section_external_id_v2"
        },
        'Parent Campaign ID': {
            'type': 'lookup',
            'dimension': 'parent_campaign_id',
            'outputName': 'Parent Campaign',
            'name': 'campaign_parent_campaign_id'
        }
    }
};
