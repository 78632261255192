import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    ViewChild,
    Output
} from '@angular/core';
import { FilterComponent } from '../filter.component';
import { FilterNumber } from './filter-number';

@Component({
    selector: 'filter-number',
    templateUrl: './filter-number.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterNumberComponent implements AfterViewInit, FilterComponent {
    @ViewChild('content', { static: true }) content: ElementRef;
    @Output() emitter = new EventEmitter<FilterNumber>();
    filter: FilterNumber = new FilterNumber();

    ngAfterViewInit() {
        this.filter.label = this.content.nativeElement.innerHTML;

        if (!this.filter.operation) {
            this.filter.operation = this.filter.operations[0];
        }
    }

    emit() {
        this.emitter.emit(this.filter);
    }
}
