import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { CreativeRepository } from 'app/core/repositories';
import { NotificationsService } from 'app/core';

@Component({
    selector: 'delete-ad-dialog',
    templateUrl: './delete-ad-dialog.html'
})
export class DeleteAdDialogComponent {
    @Output() private deleted: EventEmitter<any> = new EventEmitter<any>();
    @Output() private deletedError: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('dialog', { static: true }) private dialog;

    ad: any = null;

    constructor(
        private creativeRepository: CreativeRepository,
        private notifications: NotificationsService
    ) { }

    public open(ad): void {
        this.ad = ad;
        this.dialog.open();
    }

    delete(): void {
        this.creativeRepository.delete(this.ad.id)
            .subscribe(() => this.handleSuccess(),
                () => this.handleError());
    }

    handleSuccess() {
        this.deleted.emit();
        this.notifications.success(this.successMessage(), 'Woo Hoo!');
    }

    handleError() {
        this.deletedError.emit();
        this.notifications.error(this.errorMessage(), 'Oh No!');
    }

    successMessage(): string {
        return `The ad <strong>${this.ad.name}</strong> has successfully been deleted.`;
    }

    errorMessage(): string {
        return `<strong>${this.ad.name}</strong> was not able to be deleted.`
            + `Please edit the ad to continue troubleshooting.`;
    }
}
