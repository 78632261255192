import * as tslib_1 from "tslib";
import { Logger } from 'app/services/logger';
import moment from 'moment';
import * as i0 from "@angular/core";
var timestampStyle = 'color: #2688fe';
var messageStyle = 'color: #2c3039';
function noop() {
}
var ConsoleLogger = /** @class */ (function (_super) {
    tslib_1.__extends(ConsoleLogger, _super);
    function ConsoleLogger() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ConsoleLogger.prototype, "notice", {
        get: function () {
            return this.bind(console.info);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConsoleLogger.prototype, "warning", {
        get: function () {
            return this.bind(console.warn);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConsoleLogger.prototype, "error", {
        get: function () {
            return this.bind(console.error);
        },
        enumerable: true,
        configurable: true
    });
    ConsoleLogger.prototype.bind = function (fn) {
        if (!document.location.host.includes('liveintent.com')) {
            var now = moment().utc();
            return fn.bind(console, "%c" + now.format() + " %c%s", timestampStyle, messageStyle);
        }
        return noop;
    };
    ConsoleLogger.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConsoleLogger_Factory() { return new ConsoleLogger(); }, token: ConsoleLogger, providedIn: "root" });
    return ConsoleLogger;
}(Logger));
export { ConsoleLogger };
