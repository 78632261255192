import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { SupplyAssetProperties } from 'app/shared/models/native-supply';

@Directive({
    selector: '[imageRatioValidator]',
    providers: [{provide: NG_VALIDATORS, useExisting: ImageRatioValidator, multi: true}]
})

export class ImageRatioValidator implements Validator {
    @Input('isNativeDirectSold') isNativeDirectSold: boolean = false;

    validate(control: AbstractControl): ValidationErrors {
        const ratioValidation = {};

        for (const prop of SupplyAssetProperties) {
            const dimensions = {
                width: control.get(prop.modelKey.concat('Width')),
                height: control.get(prop.modelKey.concat('Height'))
            };

            const exactDimensions = {
                width: control.get(prop.modelKey.concat('ExactWidth')),
                height: control.get(prop.modelKey.concat('ExactHeight'))
            };

            const dimensionType = control.get('imageDimensionType');
            const isExact = dimensionType && dimensionType.value === 'exact';

            if (verifyDataPresent(isExact ? exactDimensions : dimensions)) {
                const validationResult = validate(prop.modelKey, dimensions, this.isNativeDirectSold);

                if (validationResult.length > 0) {
                    ratioValidation[prop.modelKey] = validationResult;
                }
            }
        }
        return ratioValidation;
    }
}

const validate = (asset, dimensions, isNativeDirectSold) => {
    const { width, height } = { width: dimensions.width.value, height: dimensions.height.value };
    const ratio = parseFloat((width / height).toFixed(2));
    const errors = [];
    switch (asset) {
        case 'mainImage':
            if (isNativeDirectSold) {
                if (width < 50) {
                    errors.push({
                        valid: false,
                        message: 'Width must be at least 50'
                    });
                }
                if (height < 50) {
                    errors.push({
                        valid: false,
                        message: 'Height must be at least 50'
                    });
                }
            } else {
                if (height < 50) {
                    errors.push({
                        valid: false,
                        message: 'Height must be at least 50'
                    });
                }
                switch (ratio) {
                    case 1:
                        if (width < 50) {
                            errors.push({
                                valid: false,
                                message: 'Width must be at least 50'
                            });
                        }
                        break;
                    case parseFloat((4 / 3).toFixed(2)):
                        if (width < 67) {
                            errors.push({
                                valid: false,
                                message: 'Width must be at least 67'
                            });
                        }
                        break;
                    case 1.91:
                        if (width < 96) {
                            errors.push({
                                valid: false,
                                message: 'Width must be at least 96'
                            });
                        }
                        break;
                    default:
                        errors.push({
                            valid: false,
                            message: 'Aspect ratio of 1:1, 4:3, or 1.91:1 is required'
                        });
                }
            }
            break;
        case 'logoImage':
            if (!isNativeDirectSold) {
                switch (ratio) {
                    case 1:
                        for (const dimension of Object.keys(dimensions)) {
                            if (dimensions[dimension].value > 50) {
                                errors.push({
                                    valid: false,
                                    message: `${capitalize(dimension)} must be less than 50.`
                                });
                            }
                        }
                        break;
                    default:
                        errors.push({
                            valid: false,
                            message: 'Aspect ratio of 1:1 is required'
                        });
                }
            }
            break;
    }

    return errors;
};

const verifyDataPresent = ({ width, height }) => {
    return width && height &&
        (
            (width.value && height.value) ||
            (
                (width.touched || height.touched) &&
                (width.dirty || height.dirty)
            )
        );
};

const capitalize = (input) => {
    return input.charAt(0).toUpperCase() + input.slice(1);
};
