import { Model } from './model';

export class Agency extends Model {
    refId: number;
    name: string;
    createdBy: string;
    status: string;
    description: string;
    isApiPartner: boolean;
    contract: {
        selfServe: boolean;
        dspFee: number;
    };
    salesforceId: string;

    public serialize(): string {
        this.isApiPartner = false;

        return JSON.stringify(this);
    }

    get entity() {
        return 'Agency';
    }
}
