<div class="select--dropdown">
    <button #trigger
        type="button"
        class="dropdown"
        [id-append]="['button']"
        [class.regular]="!hasCreateClass"
        [class.button--create]="hasCreateClass"
        [class.dropdown--no-label]="isString(placeholder) && placeholder.length === 0"
        [class.full-width]="fullWidth"
        [disabled]="disabled"
        (click)="_outsideClickTarget.activate()"
        (keydown)="keydown($event)">
        <span [id-append]="['span', label]" [class.pull-left]="fullWidth" [class.button--placeholder-label]="selected === undefined">{{label}}</span>
        <i class="fas fa-angle-down" [class.pull-right]="fullWidth"></i>
    </button>
    <dropdown (visibility)="dropdownVisChange($event)" [trigger]="trigger" [class.left-align]="dropdownLeftAlign" [class.full-width]="fullWidth">
        <ng-container *ngIf="groups.length > 0; else singleGroup">
            <div *ngFor="let group of groups; let g = index">
                <ng-container *ngIf="group.options.length > 0">
                    <div class="supporting padding">{{ group.label }}</div>
                    <ul>
                        <li *ngFor="let option of group.options"
                            [class.dropdown--item---index]="key === option.key"
                            (mouseenter)="mouseenter($event, option.key)"
                            (click)="_select(option.key)">
                            <a>
                                <span [attr.id]="id.control('option', option.label)" class="spacer--large-left" [innerHTML]="option.label"></span>
                            </a>
                        </li>
                    </ul>
                    <hr *ngIf="g !== groups.length - 1" class="no-margin">
                </ng-container>
            </div>
        </ng-container>
        <ng-template #singleGroup>
            <ul>
                <li [id-append]="[option.label]" *ngFor="let option of options; let i = index"
                    [class.dropdown--item---index]="key === option.key"
                    (mouseenter)="mouseenter($event, option.key)"
                    (click)="_select(option.key)">
                    <a [id-append]="['hyperlink']">
                        <span [attr.id]="id.control('option', option.label)" [textContent]="option.label"></span>
                    </a>
                </li>

                <ng-container *ngIf="allowCustom">
                    <li class="dropdown--item custom-option" *ngIf="customOption; else inputField"
                        [id-append]="[customOption.label]"
                        [class.dropdown--item---index]="key === customOption.key"
                        (mouseenter)="mouseenter($event, customOption.key)"
                        (click)="_select(customOption.key)">
                        <div class="custom-option-container">
                            <span>
                                <a [id-append]="['hyperlink']" class="padding-xsmall">
                                    {{ customOption.label }}
                                </a>
                            </span>
                            <i class="fas fa-pen" (click)="editCustomOption(); $event.stopPropagation()"></i>
                        </div>
                    </li>

                    <ng-template #inputField>
                        <li class="dropdown--item custom-option">
                            <div class="custom-option-container">
                                <input type="text" [(ngModel)]="newOptionLabel" placeholder="Add custom option" (keydown)="handleKeydown($event)" [maxlength]="maxLengthCustomOption"/>
                                <i class="fas fa-check" (click)="addCustomOption(newOptionLabel); $event.stopPropagation()"></i>
                            </div>
                        </li>
                    </ng-template>
                </ng-container>

            </ul>
        </ng-template>
    </dropdown>
</div>
