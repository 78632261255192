import { Filter } from '../filter';
import { Operation } from '../operation';
import moment from 'moment';

export class FilterDate extends Filter {
    readonly operations: Operation[] = [
        Operation.Equals,
        Operation.DoesNotEqual,
        Operation.GreaterThanOrEqualTo,
        Operation.LessThanOrEqualTo
    ];

    isValid() {
        return super.isValid() && moment(this.query, 'YYYY-MM-DD', true).isValid();
    }
}
