import * as tslib_1 from "tslib";
import { Model } from './model';
var Country = /** @class */ (function (_super) {
    tslib_1.__extends(Country, _super);
    function Country() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Country.prototype.serialize = function () {
        return JSON.stringify(this);
    };
    Object.defineProperty(Country.prototype, "countryId", {
        get: function () {
            if (typeof this.id === 'number') {
                return this.id;
            }
            return parseInt(this.id.toString());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Country.prototype, "entity", {
        get: function () {
            return 'Country';
        },
        enumerable: true,
        configurable: true
    });
    return Country;
}(Model));
export { Country };
