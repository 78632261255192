import { EventEmitter, ChangeDetectorRef } from '@angular/core';
import { of, forkJoin } from 'rxjs';
import { AdSlotRepository, BundleRepository, NewsletterRepository, PublisherRepository } from 'app/core/repositories';
import { InventoryHelperService } from 'app/core/inventory-helper.service';
import { DownloadHelper } from 'app/shared/helpers/download-helper';
var Publisher = 'Publisher';
var Newsletter = 'Newsletter';
var AdSlot = 'Ad Slot';
var Bundle = 'Bundle';
import options from './options.json';
import { InventoryTargetingToOptions } from './inventory-targeting-to-options';
import { IdService } from 'app/core/id.service';
/**
 * This file is a work in progress. The 'edit' functionality is not back yet,
 * and there is also some refactoring that is needed to make this more
 * coherent, so feel free.
 */
var InventoryTargetingComponent = /** @class */ (function () {
    function InventoryTargetingComponent(adSlotRepository, bundleRepository, cdr, inventoryHelper, newsletterRepository, publisherRepository, id) {
        this.adSlotRepository = adSlotRepository;
        this.bundleRepository = bundleRepository;
        this.cdr = cdr;
        this.inventoryHelper = inventoryHelper;
        this.newsletterRepository = newsletterRepository;
        this.publisherRepository = publisherRepository;
        this.id = id;
        this.fileName = 'targeted_inventory';
        this.inventory = [];
        this.whiteListOnly = false;
        this.placeholder = 'Search Publishers and Templates';
        this._targeting = 'include';
        this.inventoryChange = new EventEmitter();
        this.targetingChange = new EventEmitter();
        this._bulkAddOptions = [Newsletter, Publisher, AdSlot, Bundle];
        this.items = [];
        this.modes = [Publisher, Newsletter, AdSlot, Bundle];
        this.isLoading = false;
        this._options = [];
        this.text = '';
        this.idsError = false;
        this.modeOptions = options['modeOptions'];
        this.targetingOptions = options['targetingOptions'];
        this.targetingDropDownVisibile = true;
    }
    InventoryTargetingComponent.prototype.ngOnInit = function () {
        if (Array.isArray(this.inventory)) {
            this.updateItems();
        }
        else {
            this.inventory = [];
        }
        this.setTargeting(this.whiteListOnly);
    };
    Object.defineProperty(InventoryTargetingComponent.prototype, "bulkAddOptions", {
        get: function () {
            return this._bulkAddOptions;
        },
        set: function (value) {
            var _this = this;
            this._bulkAddOptions = value;
            this.modes = [];
            this._bulkAddOptions.forEach(function (mode) {
                _this.modes.push(_this.modeOptions[mode]);
            });
            this.mode = this.modes[0] ? this.modes[0].value : 'Mode not set';
        },
        enumerable: true,
        configurable: true
    });
    InventoryTargetingComponent.prototype.updateItems = function () {
        var _this = this;
        this.inventoryHelper
            .lookupInventory(this.inventory, 'id')
            .then(function (inventory) {
            _this.items = inventory[0].map(InventoryTargetingToOptions.preparePublisherOption)
                .concat(inventory[1].map(InventoryTargetingToOptions.prepareNewsletterOption), inventory[2].map(InventoryTargetingToOptions.prepareAdSlotOption), inventory[3].map(InventoryTargetingToOptions.prepareBundleOption));
            _this.isLoading = false;
            _this.inventoryChange.emit(_this.inventory);
            _this.cdr.detectChanges();
        }).catch(function () { });
    };
    InventoryTargetingComponent.prototype.setTargeting = function (whitelistonly) {
        if (whitelistonly) {
            this.targetingOptions = [{
                    key: 0,
                    value: 'include',
                    label: 'Allow List'
                }];
        }
    };
    Object.defineProperty(InventoryTargetingComponent.prototype, "searchItems", {
        get: function () {
            var _this = this;
            return this._options.filter(function (option) {
                for (var _i = 0, _a = _this.inventory; _i < _a.length; _i++) {
                    var item = _a[_i];
                    if (item.key === option.value) {
                        return false;
                    }
                }
                return true;
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryTargetingComponent.prototype, "targeting", {
        get: function () {
            return this._targeting;
        },
        set: function (value) {
            this._targeting = value;
            this.targetingChange.emit(this._targeting);
        },
        enumerable: true,
        configurable: true
    });
    InventoryTargetingComponent.prototype.parseIds = function (mode) {
        var _this = this;
        this.idsError = false;
        var invalid = [];
        // split on newline and commas
        var ids = this.text.replace(/\n/g, ',')
            .split(',')
            .map(function (token) { return token.trim(); })
            .filter(function (refId) { return !_this.isRefIdAdded(parseInt(refId), mode); })
            .filter(function (refId) {
            if (!_this.isIdValid(refId)) {
                invalid.push(refId);
                return false;
            }
            return true;
        });
        if (invalid.length > 0) {
            this.idsError = true;
        }
        this.text = invalid.join('\n');
        return ids;
    };
    InventoryTargetingComponent.prototype.addIDs = function () {
        var _this = this;
        var ids = this.parseIds(this.mode);
        var query = {
            conditions: [
                {
                    field: 'refId',
                    value: ids
                }
            ],
            return: [
                'id',
                'refId',
                'name'
            ],
            returnMode: 'only'
        };
        var repository;
        switch (this.mode) {
            default:
            case 'Publisher':
                repository = this.publisherRepository;
                break;
            case 'Newsletter':
                repository = this.newsletterRepository;
                break;
            case 'Ad Slot':
                repository = this.adSlotRepository;
                break;
            case 'Bundle':
                repository = this.bundleRepository;
                break;
        }
        repository.all(query).subscribe(function (results) {
            var _a;
            var returnedIds = results.map(function (res) { return res.refId; });
            var missingIds = ids.filter(function (id) { return !returnedIds.includes(Number(id)); });
            if (missingIds.length > 0) {
                _this.idsError = true;
                _this.text += _this.text.length > 0 ? '\n' + missingIds.join('\n') : missingIds.join('\n');
            }
            (_a = _this.inventory).push.apply(_a, results.map(function (item) {
                return {
                    key: item.id,
                    value: item.id,
                    id: item.id,
                    refId: item.refId,
                    label: item.refId + ': ' + item.name,
                    type: _this.mode
                };
            }));
            _this.updateItems();
            if (results.length === 0) {
                _this.idsError = true;
            }
        });
    };
    /**
     * Check if a refId has already been added for a mode.
     * @param {number} refId
     * @param {string} mode
     */
    InventoryTargetingComponent.prototype.isRefIdAdded = function (refId, mode) {
        return this.inventory.findIndex(function (added) {
            return added.refId === refId && added.type === mode;
        }) > -1;
    };
    InventoryTargetingComponent.prototype.isIdAdded = function (id) {
        return this.inventory.findIndex(function (added) {
            return added.id === id;
        }) > -1;
    };
    InventoryTargetingComponent.prototype.addTarget = function (option) {
        if (this.isIdAdded(option.key)) {
            return;
        }
        this.inventory.push({
            key: option.key,
            id: option.value,
            refId: option.refId,
            type: option.type
        });
        this.updateItems();
    };
    InventoryTargetingComponent.prototype.removeAll = function () {
        this.inventory.length = 0;
        this.updateItems();
    };
    InventoryTargetingComponent.prototype.inputChange = function (searchTerm) {
        var _this = this;
        if (searchTerm.length < 1) {
            return;
        }
        var query = {
            conditions: [
                {
                    field: 'name',
                    value: searchTerm,
                    operator: 'like'
                }
            ],
            return: [
                'id',
                'refId',
                'name'
            ],
            returnMode: 'only'
        };
        this.isLoading = true;
        var requests = [];
        if (this.modes.findIndex(function (mode) { return mode.value === Publisher; }) > -1) {
            requests.push(this.publisherRepository.search(query));
        }
        else {
            requests.push(of([]));
        }
        if (this.modes.findIndex(function (mode) { return mode.value === Newsletter; }) > -1) {
            requests.push(this.newsletterRepository.search(query));
        }
        else {
            requests.push(of([]));
        }
        if (this.modes.findIndex(function (mode) { return mode.value === AdSlot; }) > -1) {
            requests.push(this.adSlotRepository.search(query));
        }
        else {
            requests.push(of([]));
        }
        if (this.modes.findIndex(function (mode) { return mode.value === Bundle; }) > -1) {
            requests.push(this.bundleRepository.search(query));
        }
        else {
            requests.push(of([]));
        }
        forkJoin.apply(void 0, requests).subscribe(function (results) {
            var flattend = [].concat.apply([], results);
            _this._options = flattend.map(function (item) {
                return {
                    key: item.id,
                    value: item.id,
                    refId: item.refId,
                    label: item.refId + ': ' + item.name,
                    type: item.entity
                };
            });
            _this.updateItems();
        });
    };
    InventoryTargetingComponent.prototype.isIdValid = function (value) {
        var regex = new RegExp('[0-9]+');
        return regex.test(value);
    };
    InventoryTargetingComponent.prototype.updateInventory = function (newInventory) {
        this.inventory = newInventory.map(function (option) { return ({ type: option.type, id: option.data.id }); });
        this.updateItems();
    };
    InventoryTargetingComponent.prototype.download = function () {
        var _this = this;
        var targetingType = this.targetingOptions.find(function (current) { return _this.targeting === current.value; }).label;
        var data = this.items.map(function (item) { return ({
            'targeting type': targetingType,
            'targeting entity type': item.type,
            id: item.data.refId,
            name: item.data.name
        }); });
        DownloadHelper.downloadAsCSV(data, this.fileName);
    };
    return InventoryTargetingComponent;
}());
export { InventoryTargetingComponent };
