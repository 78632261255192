import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { ReportingSspforecasting } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
var ReportingSspforecastingRepository = /** @class */ (function (_super) {
    tslib_1.__extends(ReportingSspforecastingRepository, _super);
    function ReportingSspforecastingRepository(http) {
        return _super.call(this, http, '/reporting/sspforecasting', ReportingSspforecasting) || this;
    }
    ReportingSspforecastingRepository.prototype.getReport = function (body) {
        return this.http.post(this.url(this.path, 'report'), body);
    };
    return ReportingSspforecastingRepository;
}(BackendRepository));
export { ReportingSspforecastingRepository };
