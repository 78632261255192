import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
var MailRepository = /** @class */ (function () {
    function MailRepository(http) {
        this.http = http;
    }
    MailRepository.prototype.sendLiveConnectSetup = function (mail) {
        return this.http.post(this.url('/mail/live-connect-setup'), JSON.stringify(mail));
    };
    /**
     * Builds and returns a URL for the backend API given an array of strings
     * `suffix` which are joined using a forward-slash.
     * @param suffix
     */
    MailRepository.prototype.url = function () {
        var suffix = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            suffix[_i] = arguments[_i];
        }
        return environment.backend.hostname + suffix.join('/');
    };
    return MailRepository;
}());
export { MailRepository };
