import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BackendRepository } from './backend-repository';
import { Catalog } from 'app/shared/models';
import { map } from 'rxjs/operators';

@Injectable()
export class CatalogRepository extends BackendRepository<Catalog> {

    public constructor(http: HttpClient) {
        super(http, '/dcs/catalog-setting', Catalog);
    }

    public get(id: string) {
        return this.http.get(this.url(this.path, id))
            .pipe(map(response => this.build(response)));
    }

    save(catalog: Catalog): Observable<Catalog> {
        const params: any[] = [this.path];
        if (catalog.id) {
            params.push(catalog.advertiserId);
        }
        return this.http.post(this.url(...params), catalog.serialize())
            .pipe(map(response => this.build(response)));
    }
}
