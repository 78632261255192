import { zip, of } from 'rxjs';
import { InsertionOrder, Campaign } from 'app/shared/models';
import { AdvertiserRepository, CampaignRepository, InsertionOrderRepository } from 'app/core/repositories';
import { map, mergeMap } from 'rxjs/operators';
import { Flag, LaunchDarklyService } from '../launch-darkly.service';
import { CAMPAIGN_CONST } from 'app/shared/models/campaign';
var CampaignFlowResolver = /** @class */ (function () {
    function CampaignFlowResolver(advertiserRepository, insertionOrderRepository, campaignRepository, launchDarklyService) {
        this.advertiserRepository = advertiserRepository;
        this.insertionOrderRepository = insertionOrderRepository;
        this.campaignRepository = campaignRepository;
        this.launchDarklyService = launchDarklyService;
    }
    CampaignFlowResolver.prototype.resolve = function (route) {
        var _this = this;
        var id = route.paramMap.get('io') || (route.firstChild ? route.firstChild.paramMap.get('io') : null);
        var parentCampaignId = route.paramMap.get('parentCampaign') || (route.firstChild ? route.firstChild.paramMap.get('parentCampaign') : null);
        if (id) {
            return this.insertionOrderRepository
                .get(id)
                .pipe(mergeMap(function (insertionOrder) { return zip(_this.advertiserRepository.get(insertionOrder.advertiser), of(insertionOrder), of(new Campaign()), _this.hasAudienceExtensionFlag()).pipe(mergeMap(function (_a) {
                var advertiser = _a[0], insertionOrder = _a[1], campaign = _a[2], audienceExtensionFlag = _a[3];
                campaign.canAccessAudienceExtension = audienceExtensionFlag;
                if (parentCampaignId) {
                    return _this.campaignRepository.get(parentCampaignId).pipe(map(function (parentCampaign) {
                        if (parentCampaign && _this.isAudienceExtensionParentCampaign(parentCampaign, insertionOrder)) {
                            campaign.parentCampaign = parentCampaign.id;
                            campaign.demandType = CAMPAIGN_CONST.EXCHANGE;
                            campaign.isAudienceExtension = true;
                            return { advertiser: advertiser, insertionOrder: insertionOrder, campaign: campaign, parentCampaign: parentCampaign };
                        }
                        return { advertiser: advertiser, insertionOrder: insertionOrder, campaign: campaign };
                    }));
                }
                else {
                    return of({ advertiser: advertiser, insertionOrder: insertionOrder, campaign: campaign });
                }
            })); }));
        }
        else {
            /**
             * Self Service campaigns do not have an insertion order on create
             * so we use the parent advertiser to set up the campaign
             **/
            id = route.paramMap.get('advertiser');
            if (route.firstChild && route.firstChild.paramMap.has('advertiser')) {
                id = route.firstChild.paramMap.get('advertiser');
            }
            return this.advertiserRepository.get(id)
                .pipe(mergeMap(function (advertiser) { return zip(of(advertiser), of(new InsertionOrder({
                isUnlimitedBudget: true
            })), of(new Campaign()), _this.hasAudienceExtensionFlag()).pipe(mergeMap(function (_a) {
                var advertiser = _a[0], insertionOrder = _a[1], campaign = _a[2], audienceExtensionFlag = _a[3];
                campaign.canAccessAudienceExtension = audienceExtensionFlag;
                if (parentCampaignId) {
                    return _this.campaignRepository.get(parentCampaignId).pipe(map(function (parentCampaign) {
                        if (parentCampaign && _this.isAudienceExtensionParentCampaign(parentCampaign, insertionOrder)) {
                            campaign.parentCampaign = parentCampaign.id;
                            campaign.demandType = CAMPAIGN_CONST.EXCHANGE;
                            campaign.isAudienceExtension = true;
                            return { advertiser: advertiser, insertionOrder: insertionOrder, campaign: campaign, parentCampaign: parentCampaign };
                        }
                    }));
                }
                else {
                    return of({ advertiser: advertiser, insertionOrder: insertionOrder, campaign: campaign });
                }
            })); }));
        }
    };
    CampaignFlowResolver.prototype.hasAudienceExtensionFlag = function () {
        return this.launchDarklyService
            .getVariation(Flag.RolloutAudienceExtension);
    };
    /**
     * Checks if campaign is eligible
     * to be an audience extendable campaign
     * @param parentCampaign
     * @param insertionOrder
     * @return boolean
     */
    CampaignFlowResolver.prototype.isAudienceExtensionParentCampaign = function (parentCampaign, insertionOrder) {
        return parentCampaign.isDirectSold && parentCampaign.insertionOrder == insertionOrder.id;
    };
    return CampaignFlowResolver;
}());
export { CampaignFlowResolver };
