import { Injectable } from '@angular/core';
import { Logger } from 'app/services/logger';
import { environment } from 'src/environments/environment';
import moment from 'moment';

const timestampStyle = 'color: #2688fe';
const messageStyle = 'color: #2c3039';

function noop() {

}

@Injectable({
    providedIn: 'root'
})
export class ConsoleLogger extends Logger {

    get notice() {
        return this.bind(console.info);
    }

    get warning() {
        return this.bind(console.warn);
    }

    get error() {
        return this.bind(console.error);
    }

    private bind(fn: any) {
        if (!document.location.host.includes('liveintent.com')) {
            const now = moment().utc();
            return fn.bind(console, `%c${now.format()} %c%s`, timestampStyle, messageStyle);
        }

        return noop;
    }

}
