import * as tslib_1 from "tslib";
import { HeimdallRepository } from 'app/core/repositories/heimdall-repository';
import { HttpClient } from '@angular/common/http';
import { Blueprint } from 'app/shared/models';
var IfaasRepository = /** @class */ (function (_super) {
    tslib_1.__extends(IfaasRepository, _super);
    function IfaasRepository(http) {
        return _super.call(this, http, '/ifaas/templates', Blueprint) || this;
    }
    return IfaasRepository;
}(HeimdallRepository));
export { IfaasRepository };
