import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Email } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
var ProwlerRepository = /** @class */ (function (_super) {
    tslib_1.__extends(ProwlerRepository, _super);
    function ProwlerRepository(http) {
        return _super.call(this, http, '/prowler/api', Email) || this;
    }
    ProwlerRepository.prototype.buildItems = function (responses) {
        var _this = this;
        return responses.reduce(function (all, response) {
            var data = response['data'];
            var items = data.map(function (item) { return _this.build(item); });
            all.push.apply(all, items);
            return all;
        }, []);
    };
    ProwlerRepository.prototype.filter = function (params, resource) {
        return this.http.get(this.url(this.path, resource, this.stringifyParams(params))).pipe(map(function (result) {
            return result;
        }));
    };
    ProwlerRepository.prototype.stringifyParams = function (params) {
        var p = Object.assign({}, { page: {} }, params);
        var str = '?' + params.conditions.map(function (condition) { return "filter[" + condition.field + "]=" + condition.value; }).join('&');
        p.page.number ? str += "&page[number]=" + params.page.number : '';
        p.page.size ? str += "&page[size]=" + params.page.size : '';
        p.sort ? str += "&sort=" + params.sort : '';
        return encodeURI(str);
    };
    ProwlerRepository.prototype.findKeywords = function (params) {
        return this.http.get(this.url(this.path, 'keywords', this.stringifyParams(params))).pipe(map(function (result) {
            return result['data'];
        }));
    };
    ProwlerRepository.prototype.findMatchingKeywords = function (wildcardKeywords) {
        if (wildcardKeywords.length === 0) {
            return of([]);
        }
        return this.findKeywords({ conditions: [{ field: 'value', value: wildcardKeywords }] })
            .pipe(map(function (result) {
            return result.map(function (res) { return res.value; });
        }));
    };
    ProwlerRepository.prototype.generateKeywordReport = function (email, keywords, targetingType) {
        var _a;
        var filter = targetingType === 'include' ? 'keywords' : 'lackingKeywords';
        return this.http.post(this.url(this.path, 'keyword-reports'), (_a = {},
            _a[filter] = keywords,
            _a.email = email,
            _a));
    };
    ProwlerRepository.prototype.newsletters = function (refIds) {
        var filter = encodeURI("filter[id]=" + refIds.join(','));
        return this.http.get(this.url(this.path, "newsletters?" + filter))
            .pipe(map(function (result) { return result['data']; }));
    };
    return ProwlerRepository;
}(BackendRepository));
export { ProwlerRepository };
