import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IdService } from 'app/core/id.service';

@Component({
    selector: 'alert',
    templateUrl: './alert.html',
    styleUrls: ['./alert.styl']
})
export class AlertComponent {
    @Input() title: string = null;
    @Input() dismissible: boolean = false;
    @Input() overlay: boolean = false;
    @Input() timeout: number = null;
    @Input() switch: boolean;
    @Output() switchChange = new EventEmitter();

    private timer = null;

    constructor(
        public id: IdService
    ){}

    ngOnInit(): void {
        this.initTimeout();
    }

    ngOnDestroy() {
        clearInterval(this.timer);
    }

    close(): void {
        clearInterval(this.timer);
        this.switchChange.emit();
    }

    private initTimeout(): void {
        if (this.timeout !== undefined) {
            this.timer = setInterval(() => {
                this.timeout -= 1;
                if (this.timeout < 1) {
                    this.close();
                }
            }, 1000);
        }
    }
}
