import {
    AfterViewInit,
    Attribute,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    OnDestroy,
    ViewChild,
    ContentChildren,
    QueryList
} from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { Filter, FilterFactory, Operation } from 'app/shared/elements/filters';
import { RowDirective } from './row.directive';
import { TableData } from './table-data';
import { TableMode } from './table-mode';
import { TableQuery } from './table-query';
import { TableURLService } from './table-url.service';
import { TableColumnDirective } from './table-column.directive';
import { TableService } from './table.service';
import { ConfigureColumnsComponent } from './configure-columns/configure-columns.component';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { IdService } from 'app/core/id.service';

import colors from 'src/data/colors/palette.json';

export type TableData = { page: number, column: string, direction: string, size: number };

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TableService]
})
export class TableComponent implements AfterViewInit, OnDestroy {
    @Input() defaultColumns: string[] = [];
    @Input('id') tableId: string;
    @Input() customButtonName: string;
    @Input() customButtonCondition: boolean;
    @Input() showEmptyTable: boolean;
    @Input() showSelectedCount: boolean = true;

    @ContentChildren(TableColumnDirective)
    availableColumns: QueryList<TableColumnDirective>;

    activeColumns: TableColumnDirective[] = [];

    @ViewChild(ConfigureColumnsComponent, { static: false }) configureColumns: ConfigureColumnsComponent;
    @ViewChild('pageInfo', { static: false }) pageInfoElement: ElementRef = null;
    @ViewChild('table', { static: false }) tableElement: ElementRef = null;
    @ContentChild(RowDirective, { static: true }) rowDirective: RowDirective;
    @ContentChild('actions', { static: false }) actions: ElementRef = null;

    @Output() action = new EventEmitter();
    @Output() query = new EventEmitter<TableQuery>();
    @Output('reset') _reset = new EventEmitter<void>();
    @Output() selection = new EventEmitter<any[]>();

    funnel: string | number = null;

    private _data: TableData = { items: [] };
    private _initializing = true;
    private _loading = true;
    private _direction: 'ASC' | 'DESC' = 'ASC';
    private _orderBy: string;
    private _availableFilters: Filter[] = [];
    private _debounceTime: number;
    private _query$ = new Subject<TableQuery>();
    private _routeKey: string;
    private _mode: TableMode;
    private _onDestroy = new Subject<void>();
    private _searchOnType: boolean;
    public _searchOnInput: boolean;
    private _selectionMap = new Map<string | number, any>();
    private _disabledMap = new Map<string | number, any>();
    private readonly filter = new Filter({
        filters: [],
        operation: Operation.And
    });

    private dataUpdate: Subscription;

    constructor(
        private table: TableService,
        private element: ElementRef,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private urlService: TableURLService,
        public id: IdService,
        @Attribute('route-key') routeKey: string,
        @Attribute('search-on-type') searchOnType: boolean,
        @Attribute('search-on-input') searchOnInput: boolean
    ) {
        this._searchOnType = searchOnType !== null;
        this._debounceTime = this._searchOnType ? 0 : 300;

        this._searchOnInput = searchOnInput === false;
        this._routeKey = routeKey;

        this.dataUpdate = this.table.data$.subscribe(data => {
            this.orderBy = data.column;
            this.direction = data.direction as 'ASC' | 'DESC';
        });
    }

    ngAfterViewInit() {
        this.loadActiveColumns();

        this._query$.pipe(
            debounceTime(this._debounceTime),
            takeUntil(this._onDestroy)
        ).subscribe(query => this.query.emit(query));

        this.route.queryParamMap
            .pipe(takeUntil(this._onDestroy))
            .subscribe(paramMap => {
                const useAdvanced = this.urlService
                    .parseModeFromParamMap(this._routeKey, paramMap) === TableMode.Advanced;

                this._mode = useAdvanced ? TableMode.Advanced : TableMode.Basic;

                this.filter.filters = this.urlService
                    .parseFiltersFromParamMap(this._routeKey, paramMap, this.availableFilters);
            });
    }

    ngOnDestroy() {
        this.dataUpdate.unsubscribe();
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    get hasActions() {
        return !!this.actions;
    }

    openConfigureColumns() {
        this.configureColumns.open(this.availableColumns.toArray(), this.activeColumns);
    }

    setActiveColumns(columns: TableColumnDirective[]) {
        this.activeColumns = columns;

        if (this.tableId) {
            this.table.setActiveColumns(this.tableId, this.activeColumns.map(column => column.name));
        }
    }

    loadActiveColumns() {
        let activeColumns: TableColumnDirective[] = [];

        if (this.tableId) {
            const columnNames = this.table.getActiveColumns(this.tableId);

            if (columnNames) {
                activeColumns = columnNames.map(columnName => this.availableColumns.find(column => column.name === columnName))
                    .filter(column => !!column);
            }
        }

        if (activeColumns.length < 1) {
            if (!Array.isArray(this.defaultColumns) || this.defaultColumns.length < 1) {
                activeColumns = this.availableColumns.toArray();
            } else {
                activeColumns = this.defaultColumns
                    .map(columnName =>this.availableColumns.find(column => column.name === columnName))
                    .filter(column => !!column);
            }
        }

        this.activeColumns = activeColumns;

    }

    @Input() set data(value: TableData) {
        if (value) {
            this._initializing = false;
            this.stopLoading();
            this._data = value;
        }
    }

    load() {
        this._loading = true;
        this.cdr.markForCheck();
    }

    stopLoading() {
        this._loading = false;
    }

    @Input('filter-by') set filterBy(value: string | Filter[]) {
        // filter-by can be a commma separated string for simple filtering
        if (typeof value === 'string') {
            this._availableFilters = value.split(',')
                .map(field => FilterFactory.create(field.trim()));
        }

        // filter-by can be passed as a Filter[] for more advanced filtering
        if (Array.isArray(value)) {
            this._availableFilters = value;
        }
    }

    get availableFilters() {
        return this._availableFilters;
    }

    select(value: boolean, item: any, emit = true) {
        if (value && item.id) {
            this._selectionMap.set(item.id, item);
        } else {
            this._selectionMap.delete(item.id);
        }

        if (emit) {
            this.emitSelection();
        }
    }

    isSelected(item: any) {
        return item.id && this._selectionMap.has(item.id);
    }

    get selectedOutOfView() {
        return this.selectedItems.filter(selected => !this.rows.some(item => item.id === selected.id));
    }

    get master() {
        return this.rows.every(item => this.isSelected(item) || this.isDisabled(item));
    }

    set master(value: boolean) {
        if (this.master) {
            this._selectionMap.clear();
        } else {
            this.rows.forEach(item => {
                if (!this._disabledMap.has(item.id)) {
                    this.select(value, item, false);
                }
            });
        }
        this.cdr.markForCheck();
        this.emitSelection();
    }

    get selectedCount() {
        return this._selectionMap.size;
    }

    get selectedItems() {
        return Array.from(this._selectionMap.values());
    }

    emitSelection() {
        // We emit those rows which are selected.
        this.selection.emit(this.selectedItems);
    }

    clearSelections() {
        this.master = false;
        this._selectionMap.clear();
    }

    handleInput(term: string) {
        if (this._searchOnType) {
            this.filterSimple(term);
        }
    }

    submitInput(term: string) {
        if (term) {
            this._searchOnInput = true;
        }
        this.filterSimple(term);

        this.filters.forEach(filter => filter['isBuilding'] = false);
    }

    filterSimple(term: string) {
        // try to use the search `term` with every filter available to the table
        if (term) {
            const filters = this.availableFilters
                .filter(filter => !filter.isAdvancedOnly)
                .map(filter => {
                    return new (filter as any).constructor({
                        field: filter.field,
                        query: term,
                        label: term,
                        operation: filter.operations[0],
                        values: filter.values,
                        isBuilding: true
                    });
                });

            this.filters = this.filters.filter(filter => !filter['isBuilding'])
                .concat(new Filter({ filters, operation: Operation.Or, isBuilding: true }))
                .filter(filter => filter.isValid());
        }
    }

    emit() {
        this.load();
        this._query$.next({
            filters: this.filters,
            orderBy: this.orderBy,
            direction: this.direction
        });
    }

    reset() {
        this.emit();
        this._reset.emit();
    }

    set orderBy(value: string) {
        this._orderBy = value;
        this.reset();
    }

    get orderBy() {
        return this._orderBy;
    }

    set direction(value: 'ASC' | 'DESC') {
        this._direction = value;
        this.reset();
    }

    get direction() {
        return this._direction;
    }

    addFilter(filter: Filter) {
        this.filters = this.filters.concat(filter);
    }

    removeFilter(toRemove: Filter) {
        this.filters = this.filters.filter(filter => filter !== toRemove);
    }

    get filters() {
        return this.filter.filters;
    }

    set filters(filters: Filter[]) {
        this.filter.filters = filters;
        this.urlService.pushFilters(this._routeKey, this.filter.filters);
        this.reset();
    }

    get filterable() {
        return Array.isArray(this.availableFilters) && this.availableFilters.length > 0;
    }

    get rows() {
        return this._data.items;
    }

    get initializing() {
        return this._initializing;
    }

    get zerostate() {
        return this.filters.length < 1 && !this.loading && !this.showEmptyTable && this.rows.length < 1;
    }

    get isZeroStateMinimal() {
        return this.element.nativeElement.classList.contains('light');
    }

    get loading() {
        return this._loading;
    }

    get overlayOffset() {
        // Adjust for the borders?
        let offset = 2;

        if (this.pageInfoElement instanceof ElementRef) {
            offset += this.pageInfoElement.nativeElement.clientHeight;
        }

        if (this.tableElement instanceof ElementRef) {
            offset += this.tableElement.nativeElement.tHead.clientHeight;
        }

        return offset;
    }

    get mode() {
        return this._mode;
    }

    get showAdvanced() {
        return this.mode === 'advanced';
    }

    get disableMaster(): boolean {
        return this.rows.length === this._disabledMap.size;
    }

    toggleAdvanced(useAdvanced: boolean) {
        this._mode = useAdvanced ? TableMode.Advanced : TableMode.Basic;
        this.urlService.pushMode(this._routeKey, this._mode);

        this.filters = useAdvanced
            ? []
            : this.filters.filter(filter => filter.isValid());
    }

    disable(value: boolean, item: any): void {
        if (value && item.id) {
            this._disabledMap.set(item.id, item);
        } else {
            this._disabledMap.delete(item.id);
        }
    }

    isDisabled(item: any) {
        return item.id && this._disabledMap.has(item.id);
    }

    getRowBackgroundColor(item: any) {
        if (this._disabledMap.has(item.id)) {
            return colors['athens-gray-darker'];
        }
    }
}
