import { Attribute, ChangeDetectionStrategy, Component, ChangeDetectorRef } from '@angular/core';
import { Campaign, LineItem } from 'app/shared/models';
import { CampaignManagerDataService } from 'app/platform/campaign-manager/campaign-manager-data.service';
import { AuthorizationService, MetricsDataService } from 'app/core';
import { HasDirtyCheck, Resettable } from 'app/shared/components/bulk-edit-lightbox';
import { sum } from 'app/core/array-utils';
import { isEqual } from 'app/core/utils';
import moment from 'moment';
import { take } from 'rxjs/operators';

@Component({
    selector: 'bulk-edit-line-item-budget',
    templateUrl: './bulk-edit-line-item-budget.component.html',
    styleUrls: ['./bulk-edit-line-item-budget.component.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkEditLineItemBudgetComponent implements HasDirtyCheck, Resettable {
    private _lineItems: LineItem[] = [];
    private originals = new Map<number, LineItem>();
    private campaignToLineItemsMap = new Map<string, LineItem[]>();
    private campaignMap = new Map<string, Campaign>();
    label: string;
    field: string;
    budgetIncrement: string = 'lifetime';
    spendAsOfDate = moment().subtract(1, 'days').endOf('day');

    constructor(
        @Attribute('label') label: string,
        private auth: AuthorizationService,
        private cdr: ChangeDetectorRef,
        private dataService: CampaignManagerDataService,
        private metricsDataService: MetricsDataService
    ) {
        this.label = label;
    }

    original(refId: number) {
        return this.originals.get(refId);
    }

    set lineItems(lineItems: LineItem[]) {
        this._lineItems = lineItems;
        this.originals.clear();
        this.campaignMap.clear();
        this.campaignToLineItemsMap.clear();

        lineItems.forEach(lineItem => {
            const clone = lineItem.clone();
            delete clone.campaignObj;

            this.originals.set(lineItem.refId, clone);

            if (!this.campaignToLineItemsMap.has(lineItem.campaign)) {
                this.campaignToLineItemsMap.set(lineItem.campaign, []);
            }

            this.campaignToLineItemsMap.get(lineItem.campaign).push(lineItem);

            this.dataService.fetchCampaign(lineItem.campaign)
                .pipe(take(1))
                .subscribe(campaign => {
                    this.campaignMap.set(campaign.id, campaign);
                    this.cdr.markForCheck();
                });
        });
    }

    get lineItems() {
        return this._lineItems;
    }

    get campaigns() {
        return Array.from(this.campaignMap.values());
    }

    set entities(entities: LineItem[]) {
        this.lineItems = entities;
    }

    get entities() {
        return this.lineItems;
    }

    getCampaignLineItems(campaign: Campaign) {
        return this.campaignToLineItemsMap.get(campaign.id);
    }

    getSpendToDate(lineItem: LineItem, date: string) {
        const system = this.campaignMap.get(lineItem.campaign).system;
        return this.metricsDataService.getLineItemSpendToDate(lineItem, system, date);
    }

    getImpressionsToDate(lineItem: LineItem, date: string) {
        const system = this.campaignMap.get(lineItem.campaign).system;
        return this.metricsDataService.getLineItemImpressionsToDate(lineItem, system, date);
    }

    budgetIncrementDisabled(lineItem: LineItem) {
        // TODO: disable this toggle and force user to use line item form
        // until we have this logic worked out
        return true;
    }

    availableBudget(lineItem: LineItem) {
        const campaign = this.campaignMap.get(lineItem.campaign);

        // sum of budgets of all line items under the campaign, calculated by LSD
        const budgetOfAllLineItemsUnderCampaign = campaign.budgetAllocated;

        // list of line items currently visible in the form
        const selectedLineItemsUnderCampaign = this.getCampaignLineItems(campaign);

        // sum of budgets of currently visible line items, BEFORE they were modified
        const originalSumOfBudgetsOfSelectedLineItems = sum(selectedLineItemsUnderCampaign.map(lineItem => this.original(lineItem.refId)), 'budget');

        // sum of budgets of currently visible line items, AFTER they were modified
        const currentSumOfBudgetsOfSelectedLineItems = selectedLineItemsUnderCampaign.reduce((acc: number, cur: LineItem) => {
            acc += cur.budgetIncrement === 'lifetime' ? cur.budget : cur.dailyBudget * cur.remainingNumberOfDaysInFlight;
            return acc;
        }, 0);

        return campaign.budget - budgetOfAllLineItemsUnderCampaign + originalSumOfBudgetsOfSelectedLineItems - currentSumOfBudgetsOfSelectedLineItems;
    }

    useRemainingBudget(lineItem: LineItem) {
        if (lineItem.budgetIncrement === 'daily') {
            lineItem.dailyBudget += this.availableBudget(lineItem) / lineItem.remainingNumberOfDaysInFlight;
        } else {
            lineItem.budget += this.availableBudget(lineItem);
        }
    }

    inheritDates(lineItem: LineItem) {
        lineItem.startDate = null;
        lineItem.endDate = null;
    }

    disinheritDates(lineItem: LineItem) {
        const campaign = this.campaignMap.get(lineItem.campaign);
        lineItem.startDate = campaign.effectiveStartDate;
        lineItem.endDate = campaign.effectiveEndDate;
    }

    isLineItemDirty(lineItem: LineItem) {
        const clone = lineItem.clone();
        delete clone.campaignObj;

        return !isEqual(clone, this.original(lineItem.refId));
    }

    isDirty() {
        return this.lineItems.some(lineItem => this.isLineItemDirty(lineItem));
    }

    revert(lineItem: LineItem) {
        Object.assign(lineItem, this.original(lineItem.refId));
    }

    reset() {
        this.dataService.reset();
        this.lineItems.forEach(entity => {
            entity['budget'] = this.original(entity.refId)['budget'];
            entity['dailyBudget'] = this.original(entity.refId)['dailyBudget'];
        });
    }
}
