import {
    Component,
    Input,
    Output,
    EventEmitter,
    forwardRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export const CHECKBOX_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxComponent),
    multi: true
};

export class CheckboxChange {
    source: CheckboxComponent;
    checked: boolean;
}

@Component({
    selector: 'checkbox',
    templateUrl: './checkbox.component.html',
    providers: [CHECKBOX_CONTROL_VALUE_ACCESSOR]
})
export class CheckboxComponent implements ControlValueAccessor{
    @Output() change: EventEmitter<CheckboxChange> = new EventEmitter<CheckboxChange>();

    @Input() id: string = '';
    @Input() required: boolean;
    @Input() disabled: boolean = false;
    @Input() tabindex: number = 0;
    @Input() name: string = null;

    @Input() label: string;
    @Input() error: boolean;

    onTouched = () => {};

    private _checked: boolean = false;
    private _indeterminate: boolean = false;
    private _controlValueAccessorChangeFn: (value: any) => void = (value) => {};

    hasFocus: boolean = false;

    @Input() get checked() {
        return this._checked;
    }

    set checked(checked: boolean) {
        if (checked !== this.checked) {
            this._checked = checked;
        }
    }

    @Input() get indeterminate() {
        return this._indeterminate;
    }

    set indeterminate(indeterminate: boolean) {
        this._indeterminate = indeterminate;
    }

    writeValue(value: any){
        this.checked = !!value;
    }

    registerOnChange(fn: (value: any) => void) {
        this._controlValueAccessorChangeFn = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    private _emitChangeEvent() {
        let event = new CheckboxChange();
        event.source = this;
        event.checked = this.checked;

        this._controlValueAccessorChangeFn(this.checked);
        this.change.emit(event);
    }

    _onFocus() {
        this.hasFocus = true;
    }

    _onBlur() {
        this.hasFocus = false;
        this.onTouched();
    }

    _onChange(event: Event) {
        event.stopPropagation();
        if (!this.disabled) {
            this.toggle();
            this._emitChangeEvent();
        }
    }

    _onClick(event: Event) {
        event.stopPropagation();
    }

    toggle() {
        this.checked = !this.checked;
    }
}
