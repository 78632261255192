import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { UtilRepository } from 'app/core/repositories';
import { Creative } from 'app/shared/models';
import { TableComponent, TableQuery } from 'app/shared/elements/table';
import { PaginatorQuery } from 'app/shared/elements/paginator';
import { TableHelper } from 'app/shared/helpers/table-helper';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

enum Columns {
    staqId,
    name,
    clickUrl,
    urlTracking1,
    urlTracking2
}

@Component({
    selector: 'bulk-tag-uploader',
    templateUrl: './bulk-tag-uploader.html',
    styleUrls: [ './bulk-tag-uploader.styl' ]
})

export class BulkTagUploaderComponent {
    @ViewChild(TableComponent, { static: true })
    table: TableComponent;

    showError: boolean = false;
    excelFile;
    allChecked: boolean = false;
    anyChecked: boolean = false;
    dummyCreative = new Creative();
    creativeData$ = new BehaviorSubject<any[]>([]);
    creativeParams$ = new BehaviorSubject<TableQuery & PaginatorQuery>({
        page: 1,
        pageSize: 0,
        orderBy: 'staqId',
        filters: [],
        direction: 'ASC',
    });

    creativeTableData$ = combineLatest(this.creativeData$, this.creativeParams$)
        .pipe(map(([data, params]) => {
            let filteredData = TableHelper.filter(data, params as any);
            TableHelper.sort(filteredData, params.orderBy, params.direction);

            return {
                items: filteredData,
                pages: 1,
                total: filteredData.length,
                page: 1
            };
        }));

    constructor (
        private cdr: ChangeDetectorRef,
        private utilRepository: UtilRepository
    ) {}

    uploadExcel(files): void {
        this.excelFile = files[0];
        if (!this.excelFile) {
            return;
        }
        this.utilRepository.parseExcel({
            file: this.excelFile.dataURL.split(',')[1]
        }).subscribe(rsp => {
            this.showError = false;
            this.creativeData$.next(this.mapToCreatives(rsp));
        }, () => {
            this.showError = true;
            this.excelFile = null;
        });
    }

    mapToCreatives(rsp: Array<Array<string>>): Array<any> {
        rsp.shift();
        let creatives = [];

        for (let row of rsp) {
            let creative: any = {};
            this.setProperty(creative, Columns.staqId, row[Columns.staqId]);
            this.setProperty(creative, Columns.name, row[Columns.name]);
            this.setProperty(creative, Columns.clickUrl, this.replaceTimestamp(row[Columns.clickUrl]));
            this.setProperty(creative, Columns.urlTracking1, this.generateSourceUrl(row[Columns.urlTracking1]));
            this.setProperty(creative, Columns.urlTracking2, this.generateSourceUrl(row[Columns.urlTracking2]));
            creative.type = 'image';
            creative.isModified = true;
            creatives.push(creative);
        }
        return creatives;
    }

    setProperty(creative: any, index: Columns, property: string): void {
        if (property !== undefined && property !== null) {
            creative[Columns[index]] = property;
        }
    }

    uploadImage(event, creative: Creative) {
        const file = event.target.files[0];

        const reader = new FileReader();
        const image = new Image();
        let mediaUrl;

        reader.onload = () => {
            mediaUrl = reader.result;
            image.src = mediaUrl;
        };

        image.onload = () => {
            creative.mediaUrl = mediaUrl;
            creative.width = image.width;
            creative.height = image.height;
            creative.fileName = file.name;
            this.cdr.detectChanges();
        };

        reader.readAsDataURL(file);
    }

    removeImage(creative): void {
        creative.mediaUrl = null;
        creative.width = null;
        creative.height = null;
        creative.fileName = null;
    }

    reset() {
        this.creativeData$.next([]);
        this.excelFile = null;
    }

    public get selectedCreatives(): Array<Creative> {
        return this.table.selectedItems.map(creative => {
            try {
                let copy = JSON.parse(JSON.stringify(creative));
                delete copy.selected;
                delete copy.fileName;
                return new Creative(copy);
            } catch (e) {
                return null;
            }
        }).filter(creative => creative !== null);
    }

    downloadTemplate(): void {
        window.location.href = '../assets/templates/bulk_tag_upload_template.xlsx';
    }

    replaceTimestamp(url: string): string {
        if (url === undefined || url === null) {
            return;
        }
        return url.replace(/\[timestamp\]/g, '${DECISION_ID}');
    }

    thumbnailWidth(creative: any) {
        this.dummyCreative.width = creative.width;
        return this.dummyCreative.thumbnailWidth(false);
    }

    thumbnailHeight(creative: any) {
        this.dummyCreative.height = creative.height;
        return this.dummyCreative.thumbnailHeight(false);
    }

    generateSourceUrl(tag: string): string {
        if (tag === undefined || tag === null) {
            return;
        }
        let match = tag.match(/src="(.*?)"/i);

        return match ? this.replaceTimestamp(match[1]) : null;
    }

    query(params: TableQuery) {
        this.creativeParams$.next(params);
    }
}
