import * as tslib_1 from "tslib";
import { Model } from './model';
import { daysToSeconds, secondsToDays, hoursToSeconds, secondsToHours } from 'app/core/utils';
var Pixel = /** @class */ (function (_super) {
    tslib_1.__extends(Pixel, _super);
    function Pixel(from) {
        return _super.call(this, Object.assign({ expiration: Pixel.DEFAULT_EXPIRATION_SECONDS }, from)) || this;
    }
    Object.defineProperty(Pixel.prototype, "isLiveConnect", {
        get: function () {
            return this.tagType === 'LiveConnect';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Pixel.prototype, "formattedType", {
        get: function () {
            return this.isLiveConnect ? 'LiveConnect' : 'Image Pixel';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Pixel.prototype, "expirationInDays", {
        get: function () {
            return secondsToDays(this.expiration);
        },
        set: function (days) {
            this.expiration = daysToSeconds(days);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Pixel.prototype, "pvcExpirationInHours", {
        get: function () {
            return secondsToHours(this.pvcExpiration);
        },
        set: function (days) {
            this.pvcExpiration = hoursToSeconds(days);
        },
        enumerable: true,
        configurable: true
    });
    Pixel.prototype.serialize = function () {
        var clone = this.clone();
        if (typeof clone.advertiser === 'object') {
            clone.advertiser = clone.advertiser.id;
        }
        return JSON.stringify(clone);
    };
    Object.defineProperty(Pixel.prototype, "entity", {
        get: function () {
            return 'Pixel';
        },
        enumerable: true,
        configurable: true
    });
    Pixel.DEFAULT_EXPIRATION_SECONDS = daysToSeconds(30);
    Pixel.DEFAULT_PVC_EXPIRATION_IN_HOURS = 72;
    Pixel.DEFAULT_EXPIRATION_IN_DAYS = 30;
    return Pixel;
}(Model));
export { Pixel };
