import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { wipeDown } from 'app/shared/animations';
import { AsyncTableComponent, TableQuery } from 'app/shared/elements/async-table';
import { FrontendTableHelper } from 'app/shared/helpers/frontend-table-helper';
import { combineLatest, forkJoin, of, ReplaySubject } from 'rxjs';
import { AdSlot, Newsletter } from 'app/shared/models';
import { mergeMap } from 'rxjs/operators';
import { AdSlotRepository, NewsletterRepository } from 'app/core/repositories';
import { IdService } from 'app/core';
import { RowDirective } from 'app/shared/elements/table';
import { CodeSnippetComponent } from 'app/shared/elements/code-snippet';
import { debounceTime } from 'rxjs/internal/operators';

const WIDTH_MACRO = '{INSERT TEMPLATE MAX WIDTH HERE AS INTEGER}';
const MOBILE_WIDTH_MACRO = '{INSERT TEMPLATE MAX MOBILE WIDTH HERE AS INTEGER}';
const RESPONSIVE_WIDTH_MACRO = '{INSERT TEMPLATE MOBILE RESPONSIVE WIDTH HERE AS INTEGER}';

@Component({
    selector: 'newsletter-tags-table',
    templateUrl: './newsletter-tags-table.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [wipeDown]
})
export class NewsletterTagsTableComponent implements OnInit, OnDestroy {
    @Input('newsletter') set newsletter(newsletter: Newsletter) {
        this.newsletter$.next(newsletter);
    }

    @Input('adSlotId') set adSlotId(adSlotId: string) {
        this.adSlotId$.next(adSlotId);
    }

    @Input('maxWidth') set maxWidth(maxWidth: number) {
        this.maxWidth$.next(maxWidth);
    }

    @Input('maxMobileWidth') set maxMobileWidth(maxMobileWidth: number) {
        this.maxMobileWidth$.next(maxMobileWidth);
    }

    constructor(
        public adSlotRepository: AdSlotRepository,
        public newsletterRepository: NewsletterRepository,
        public id: IdService,
        private renderer: Renderer2
    ) {}

    @ViewChild(AsyncTableComponent, { static: true }) table: AsyncTableComponent;
    @ViewChild('row', {static: false}) tableRow: RowDirective;
    @ViewChildren(CodeSnippetComponent, { read: ElementRef }) codeSnippets: QueryList<CodeSnippetComponent>;
    tableHelper = new FrontendTableHelper<any>(() => this.adapter());
    newsletter$ = new ReplaySubject<Newsletter>(1);
    adSlotId$ = new ReplaySubject<string>(1);
    maxWidth$ = new ReplaySubject<number>(1);
    maxMobileWidth$ = new ReplaySubject<number>(1);
    adSlot: AdSlot;

    private adapter() {
        return this.newsletter$.pipe(
            mergeMap(newsletter => this.getAllNewsletterAdSlots(newsletter)),
            mergeMap(([adSlots, newsletter]) => this.getAllAdSlotTags(adSlots, newsletter)),
            mergeMap(([adSlots, newsletter]) => this.combineNewsletterSafeRtbWithAdSlots(adSlots, newsletter))
        );
    }

    private getAllNewsletterAdSlots(newsletter) {
        if (!newsletter) {
            return of([]);
        }

        // Grab all adSlots for a newsletter and join with the newsletter
        return forkJoin(this.adSlotRepository.getByNewsletterId(newsletter.id), of(newsletter));
    }

    private getAllAdSlotTags(adSlots, newsletter) {
        if (!adSlots || !newsletter) {
            return of([]);
        }

        // If the newsletter doesn't have any ad slots return the newsletter so we can return safeRtb
        if (adSlots.length === 0) {
            return of([adSlots, newsletter]);
        }

        // Map adSlots to adSlot tags and join with newsletter
        return forkJoin(forkJoin(adSlots.map(adSlot => this.adSlotRepository.tags(adSlot.refId.toString()))), of(newsletter));
    }

    private combineNewsletterSafeRtbWithAdSlots(adSlots, newsletter) {
        if (!adSlots || !newsletter) {
            return of([]);
        }

        // Create an adSlot from the newsletter safeRTB
        const newsletterSafeRtb = new AdSlot({
            created: newsletter.created,
            fullTag: newsletter.safeRTB.fullTag,
            name: 'SafeRTB',
            refId: '',
            sizes: '',
            status: '',
            type: 'safertb'
        });

        // If an ad slot was clicked to view code then we expand the row
        if (this.adSlot.refId) {
            const pos = adSlots.map(adSlot => adSlot.refId).indexOf(this.adSlot.refId);
            this.tableRow.toggleExpansion(adSlots[pos]);
        }

        // Combine Newsletter SafeRtb with AdSlots
        return of([].concat(adSlots, newsletterSafeRtb));
    }

    private copyCodeSnippetHtmlIntoDataAttribute(codeSnippet: CodeSnippetComponent) {
        const dataAttribute = codeSnippet.nativeElement.getAttribute('data');

        // Prevent overwriting default code snippets
        if (!dataAttribute) {
            const innerHtml = codeSnippet.nativeElement.querySelector('#code-input').innerHTML;

            this.renderer.setAttribute(codeSnippet.nativeElement, 'data', innerHtml);
        }
    }

    private replaceMacroWithMaxWidths(codeSnippet: CodeSnippetComponent, maxWidth, maxMobileWidth) {
        // Default codesnippet is stored in data attribute of codesnippet element
        let copyInnerText = codeSnippet.nativeElement.getAttribute('data');

        const widthMacroRegex = new RegExp(WIDTH_MACRO, 'g');
        const mobileWidthMacroRegex = new RegExp(MOBILE_WIDTH_MACRO, 'g');
        const responsiveWidthMacroRegex = new RegExp(RESPONSIVE_WIDTH_MACRO, 'g');

        if (maxWidth) {
            copyInnerText = copyInnerText.replace(widthMacroRegex, maxWidth);
        }

        if (maxMobileWidth) {
            copyInnerText = copyInnerText.replace(responsiveWidthMacroRegex, maxMobileWidth);
            copyInnerText = copyInnerText.replace(mobileWidthMacroRegex, maxMobileWidth);
        }

        return copyInnerText;
    }

    ngOnInit() {
        this.adSlotId$.pipe(
            mergeMap(adSlotId => {
                if (!adSlotId) {
                    return of([]);
                }

                return this.adSlotRepository.get(adSlotId);
            })
        ).subscribe(adSlot => {
            if (adSlot !== []) {
                this.adSlot = new AdSlot(adSlot);
            }
        });

        combineLatest(this.maxWidth$, this.maxMobileWidth$).pipe(
            debounceTime(300),
            mergeMap(([maxWidth, maxMobileWidth]) => {
                if (this.codeSnippets && this.codeSnippets.length > 0) {
                    this.codeSnippets.forEach(codeSnippet => this.copyCodeSnippetHtmlIntoDataAttribute(codeSnippet));
                }

                return of([maxWidth, maxMobileWidth]);
            })
        ).subscribe(([maxWidth, maxMobileWidth]) => {
            if (this.codeSnippets && this.codeSnippets.length > 0) {
                this.codeSnippets.forEach(codeSnippet => {
                    const codeInputElement = codeSnippet.nativeElement.querySelector('#code-input');

                    const codeElement = codeSnippet.nativeElement.getElementsByTagName('code')[0];

                    const replacementCode = this.replaceMacroWithMaxWidths(codeSnippet, maxWidth, maxMobileWidth);
                    
                    this.renderer.setProperty(codeInputElement, 'innerHTML', replacementCode);
                    this.renderer.setProperty(codeElement, 'innerHTML', replacementCode);
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.newsletter$.unsubscribe();
        this.adSlotId$.unsubscribe();
        this.maxWidth$.unsubscribe();
        this.maxMobileWidth$.unsubscribe();
    }

    query(tableQuery: TableQuery) {
        this.tableHelper.search([tableQuery]);
    }

    reset() {
        this.newsletter$.next(null);
        this.tableRow.getExpansions().clear();
    }
}
