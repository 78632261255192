import { Injectable } from '@angular/core';

@Injectable()
export class RedirectService {
    redirectUrl(url: string) {
        let ret: string[];

        ret = atob(url).split(';');
        return ret.shift();
    }

    redirectParams(url: string) {
        let ret: string[];
        let params: any = {};

        ret = atob(url).split(';');
        ret.shift();

        for (let i of ret) {
            let [key, value] = i.split('=');

            params[key] = value;
        }

        return params;
    }
}
