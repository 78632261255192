import { Component, ViewChild } from '@angular/core';

import { ChooseAccountsComponent } from '../choose-accounts';
import { PreferencesService } from 'app/core';
import { TableHelper } from 'app/shared/helpers/table-helper';
import { TableComponent, TableQuery } from 'app/shared/elements/table';
import { NotificationsService } from 'app/core';
import { Advertiser } from 'app/shared/models';
import { map } from 'rxjs/operators';

@Component({
    templateUrl: './my-accounts.html'
})
export class MyAccountsComponent {
    helper = new TableHelper<any[]>(params => this.adapter(params));
    @ViewChild(TableComponent, { static: true }) table: TableComponent;
    @ViewChild(ChooseAccountsComponent, { static: true }) chooseAccounts: ChooseAccountsComponent;

    constructor(
        private preferences: PreferencesService,
        private notifications: NotificationsService
    ) { }

    query(tableQuery: TableQuery) {
        this.helper.search([tableQuery]);
    }

    private adapter(params) {
        return this.preferences.accounts.pipe(
            map(accounts => TableHelper.filter(accounts, params)),
            map(accounts => this.accountsAsPage(accounts, params))
        );
    }

    private accountsAsPage(accounts, params) {
        const items = TableHelper.sort(accounts, params.orderBy, params.sort);

        return {
            items,
            pages: items.length > 0 ? 1 : 0,
            total: accounts.length
        };
    }

    actionHandler(action, account) {
        switch (action) {
            case 'remove':
                this.preferences.removeAccount(account).subscribe(data => {
                    this.notifications.success('The account was removed.');
                });
                break;
        }
    }

    openChooseAccounts() {
        this.chooseAccounts.open();
    }

    getPath(account: any) {
        return account instanceof Advertiser
            ? ['/campaign-manager/advertisers', account.refId]
            : ['/inventory-manager/media-groups', account.refId];
    }
}
