import { Directive, ContentChild, Input } from '@angular/core';
import { TableHeaderDirective } from './table-header.directive';
import { TableCellDirective } from './table-cell.directive';

@Directive({
    selector: '[appTableColumn]'
})
export class TableColumnDirective {

    @Input('appTableColumn') name: string;
    @Input('appTableColumnName') displayName: string;
    @Input('appTableColumnLocked') lock = null;

    get locked() {
        return this.lock !== null;
    }

    @ContentChild(TableHeaderDirective, { static: true }) header: TableHeaderDirective;
    @ContentChild(TableCellDirective, { static: true }) cell: TableCellDirective;

}
