import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { GlobalSearchComponent } from 'app/platform/global-search';
import { HistoryComponent } from 'app/platform/history/history.component';
import { LiveConnectOverviewComponent } from 'app/platform/measurement/live-connect-manager/live-connect-overview';

export class PlatformRouteReuseStrategy implements RouteReuseStrategy {
    routesToCache: string[] = [];
    storedRouteHandles = new Map<string, DetachedRouteHandle>();

    /**
     * Decides if the route should be cached.
     */
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return this.routesToCache.indexOf(route.routeConfig.path) > -1;
    }

    /**
     * Store the information for the route being destructed.
     */
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle) {
        this.storedRouteHandles.set(route.routeConfig.path, handle);
    }

    /**
     * Check if there is a stored route object for the next route.
     */
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return this.storedRouteHandles.has(route.routeConfig.path);
    }

    /**
     * Retrieve the stored data for the route.
     */
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        return this.storedRouteHandles.get(route.routeConfig.path);
    }

    /**
     * Determine if we should reuse the current route.
     */
    shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
        let hasChildren = future.children.length > 0;

        // Angular's default is to reuse components when the future component is the same as
        // the current component. Not all of our code is written in a way that can handle
        // this, so we have turned it off, except for the following components which
        // can properly support being reused by Angular.
        const reusableComponents = [
            GlobalSearchComponent,
            HistoryComponent,
            LiveConnectOverviewComponent
        ];

        if (current.component === future.component && reusableComponents.findIndex(c => c === future.component) > -1) {
            return true;
        }

        return (future.routeConfig === current.routeConfig && hasChildren) || future.outlet === 'notifications';
    }
}
