import { Component, Input, OnInit } from '@angular/core';
import { IdService } from 'app/core';
import { Blueprint, SupplyAsset, SupplyAssetProperties } from 'app/shared/models';
import { SupplyAssetService } from 'app/core/supply-asset.service';

@Component({
    selector: 'blueprint-details',
    templateUrl: './blueprint-details.html',
    styleUrls: ['./blueprint-details.styl'],
    providers: [ SupplyAssetService ]
})

export class BlueprintDetailsComponent implements OnInit {
    @Input() public blueprint: Blueprint;

    supplyAssets = new SupplyAsset();
    supplyAssetProperties = SupplyAssetProperties;
    cacheBuster = 10000;

    // Update preview with cachebuster query param, if longer than time:
    get ifaasUrl() {
        const currentTime = Date.now();
        const blueprintModified = Date.parse(this.blueprint.modified);

        if (blueprintModified + this.cacheBuster < currentTime) {
            return this.blueprint.ifaasUrl + '&updatedAt=' + blueprintModified;
        }

        return this.blueprint.ifaasUrl;
    }

    constructor(
        public id: IdService,
        public supplyAssetService: SupplyAssetService
    ) {}

    public ngOnInit(): void {
        this.supplyAssetService.initSupplyAssets(this.supplyAssets);
        this.supplyAssetService.setAssets(this.blueprint, this.supplyAssets);
    }

    mobileIfaasUrl() {
        if (this.ifaasUrl) {
            return this.ifaasUrl.replace('_desktop', '_mobile');
        }
        return null;
    }
}