import { OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'app/shared/elements/confirm-dialog';
import { PackageRepository, PublisherRepository, ReportingQueryRepository, NewsletterRepository, AdSlotRepository } from 'app/core/repositories';
import { NotificationsService } from 'app/core/notifications.service';
import { PackageFormComponent } from '../package-form/package-form.component';
import { TableHelper } from 'app/shared/helpers/table-helper';
import { TableComponent } from 'app/shared/elements/table';
import { PaginatorComponent } from 'app/shared/elements/paginator';
import { ThemeService, BackgroundStyle } from 'app/shared/helpers/theme.service';
import { IdService } from 'app/core';
import { DruidQueryHelper } from 'app/core/query-builder-helper';
import { AuthorizationService } from 'app/core/authorization.service';
var PackageManagerComponent = /** @class */ (function () {
    function PackageManagerComponent(router, theme, notifications, packageRepository, publisherRepository, newsletterRepository, adSlotRepository, reportingQueryRepository, queryBuilder, id, auth) {
        var _this = this;
        this.router = router;
        this.theme = theme;
        this.notifications = notifications;
        this.packageRepository = packageRepository;
        this.publisherRepository = publisherRepository;
        this.newsletterRepository = newsletterRepository;
        this.adSlotRepository = adSlotRepository;
        this.reportingQueryRepository = reportingQueryRepository;
        this.queryBuilder = queryBuilder;
        this.id = id;
        this.auth = auth;
        this.helper = new TableHelper(function (params) { return _this.adapter(params); });
    }
    PackageManagerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.theme.setBackgroundStyle(BackgroundStyle.GreyStyle);
        this.publisherRepository.search({ n: 5, page: 1 }).pipe(map(function (publishers) { return publishers.length > 0; }))
            .subscribe(function (hasPublishers) {
            if (!hasPublishers) {
                _this.notifications.warning('At least one publisher is required to use the Package Manager.', 'Notice!', 0);
            }
        });
        this.helper.table = this.table;
        this.helper.paginator = this.paginator;
        combineLatest(this.table.query, this.paginator.query).subscribe(function (args) { return _this.helper.search(args); });
    };
    PackageManagerComponent.prototype.ngAfterViewInit = function () {
        this.paginator.reset();
    };
    PackageManagerComponent.prototype.ngOnDestroy = function () {
        this.theme.resetBackgroundStyle();
    };
    PackageManagerComponent.prototype.getPublishers = function (pubIds) {
        return this.publisherRepository.searchByIds(pubIds || []);
    };
    PackageManagerComponent.prototype.getNewsletters = function (newsletterIds) {
        return this.newsletterRepository.searchByIds(newsletterIds || []);
    };
    PackageManagerComponent.prototype.getAdSlots = function (adSlotIds) {
        return this.adSlotRepository.searchByIds(adSlotIds || []);
    };
    PackageManagerComponent.prototype.adapter = function (params) {
        var _this = this;
        params.conditions.push({
            'field': 'demandType',
            'value': 'direct'
        });
        var query = {
            type: 'groupBy',
            dataSource: 'custom_aggregates_exact',
            granularity: 'all',
            dimensions: [],
            aggs: ['Adjusted Impressions'],
            postAggs: [],
            interval: { type: 'dynamic', value: '30' }
        };
        // Fetch all packages
        return this.packageRepository.asyncSearch(params).pipe(map(function (response) {
            response.items.forEach(function (pkg) {
                // For each package fetch the inventory
                _this.packageRepository.get(pkg.id).subscribe(function (pkgDetails) {
                    forkJoin([_this.getPublishers(pkgDetails.publishers),
                        _this.getAdSlots(pkgDetails.adSlots),
                        _this.getNewsletters(pkgDetails.newsletters)
                    ]).subscribe(function (_a) {
                        var publishers = _a[0], adSlots = _a[1], newsletters = _a[2];
                        var filters = [];
                        if (publishers.length) {
                            filters.push({ dimension: 'Publisher', values: publishers.map(function (pub) { return pub.refId.toString(); }) });
                        }
                        if (adSlots.length) {
                            filters.push({ dimension: 'Section', values: adSlots.map(function (adSlot) { return adSlot.refId.toString(); }) });
                        }
                        if (newsletters.length) {
                            filters.push({ dimension: 'Template', values: newsletters.map(function (newsletter) { return newsletter.refId.toString(); }) });
                        }
                        // For each package make a reporting call to fetch impressions using inventory above for filters
                        _this.reportingQueryRepository
                            .executeAdHocJSON(_this.queryBuilder.buildQuery(Object.assign({}, query, { filters: filters })), 'publisher')
                            .subscribe(function (rows) {
                            var adjustedImpressions = 0;
                            if (Array.isArray(rows) && rows.length > 0) {
                                adjustedImpressions = rows[0].event['Adjusted Impressions'];
                            }
                            pkg['adjusted_impressions'] = adjustedImpressions;
                        });
                    });
                });
            });
            return {
                items: response.items,
                pages: response.pages,
                total: response.total,
                page: params.page
            };
        }));
    };
    ;
    PackageManagerComponent.prototype.openPackageModal = function () {
        this.packageForm.createPackage();
    };
    PackageManagerComponent.prototype.rowOptions = function (pkg) {
        return PackageManagerComponent.optionsAll;
    };
    PackageManagerComponent.prototype.rowAction = function (value, pkg) {
        switch (value) {
            case 'edit':
                if (this.isInventoryManager()) {
                    this.router.navigate(['/inventory-manager/packages', pkg.id]);
                }
                else {
                    this.router.navigate(['/internal-tools/packages', pkg.id]);
                }
                break;
            case 'delete':
                this.selectedPackage = pkg;
                this.deleteDialog.open();
                break;
        }
    };
    PackageManagerComponent.prototype.deletePackage = function () {
        var _this = this;
        this.packageRepository.delete(this.selectedPackage.id).subscribe(function () {
            _this.notifications.success("You have successfully deleted " + _this.selectedPackage.name + ".", 'Woo hoo!');
            _this.paginator.reset();
        });
    };
    PackageManagerComponent.prototype.saved = function (pkg) {
        this.paginator.reset();
        this.notifications.success("You have successfully created <strong>" + pkg.name + "</strong>.", 'Woo Hoo!');
    };
    PackageManagerComponent.prototype.isInventoryManager = function () {
        return this.router.url.indexOf('inventory-manager') > -1;
    };
    PackageManagerComponent.optionsAll = [
        {
            key: 0, value: 'edit', label: 'Edit'
        },
        {
            key: 1, value: 'delete', label: 'Delete'
        }
    ];
    return PackageManagerComponent;
}());
export { PackageManagerComponent };
