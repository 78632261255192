import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LiveConnectTag } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { map } from 'rxjs/operators';
import { NotificationsService } from '../notifications.service';
var LiveConnectTagRepository = /** @class */ (function (_super) {
    tslib_1.__extends(LiveConnectTagRepository, _super);
    function LiveConnectTagRepository(http, notificationService) {
        var _this = _super.call(this, http, '/dominique/appInfoByAdvertiserAccount', LiveConnectTag) || this;
        _this.notificationService = notificationService;
        return _this;
    }
    LiveConnectTagRepository.prototype.getOrCreateByAdvertiserIdAndAccountId = function (id, accountId) {
        var _this = this;
        return this.http.get(this.url(this.path, id.toString(), accountId.toString()))
            .pipe(map(function (response) { return _this.build(response); }), catchError(function (e) {
            _this.notificationService.warning("Sorry, we encountered an issue retrieving the App-ID for the advertiser with ID: " + id + ". Please try again shortly.", '', 0);
            console.error("Failed to create appId for " + id + ", make sure the advertiser has an accountId.");
            return of({});
        }));
    };
    LiveConnectTagRepository.prototype.getAppInfoFromCache = function (id, accountId) {
        if (!this._cache.has(id)) {
            var subject = new ReplaySubject(1);
            this.getOrCreateByAdvertiserIdAndAccountId(id, accountId).subscribe(subject);
            this._cache.set(id, subject);
        }
        return this._cache.get(id);
    };
    return LiveConnectTagRepository;
}(BackendRepository));
export { LiveConnectTagRepository };
