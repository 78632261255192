import { Model } from './model';

export class Role extends Model {
    public rid: number;
    public name: string;
    public commands: string[];

    constructor(from?: any) {
        super(from);
        delete this.id;
        this.rid = parseInt(from.id);
    }

    public serialize(): string {
        return JSON.stringify(this);
    }

    get entity() {
        return 'Role';
    }
}
