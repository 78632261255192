import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    ViewChild,
    Output
} from '@angular/core';
import { FilterComponent } from '../filter.component';
import { FilterExist } from './filter-exist';

@Component({
    selector: 'filter-exist',
    templateUrl: './filter-exist.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterExistComponent implements AfterViewInit, FilterComponent {
    @ViewChild('content', { static: true }) content: ElementRef;
    @Output() emitter = new EventEmitter<FilterExist>();
    filter: FilterExist = new FilterExist();

    ngAfterViewInit() {
        this.filter.label = this.content.nativeElement.innerHTML;
        this.filter.simpleLabel = null;

        if (!this.filter.operation) {
            this.filter.operation = this.filter.operations[0];
        }
    }

    emit() {
        this.emitter.emit(this.filter);
    }
}
