import { RegexGroupOperator, RegexRuleOperator } from 'app/shared/models';
import { RegexRule } from 'app/shared/models/regex-rule';
import { Filter, Operation } from 'app/shared/elements/filters';

export class RegexBuilder {
    /**
     * Generate a regex string from a `RegexRule` recursively handling nested rules.
     */
    generate(rule: RegexRule) {
        if (!rule || !rule.isValid()) {
            return '';
        }

        if (!Array.isArray(rule.rules)) {
            return this.generateFromRule(rule);
        }

        const operation = rule.operator === RegexGroupOperator.And ? this.and : this.or;
        return operation(
            ...rule.rules
                .filter(r => r.isValid())
                .map(r => this.generate(r))
        );
    }

    /**
     * Generate a regex string from a `URLRule`.
     */
    generateFromRule(rule: RegexRule) {
        switch (rule.operator) {
            case RegexRuleOperator.Contains:
                return this.contains(rule.term);
            case RegexRuleOperator.DoesNotContain:
                return this.notContains(rule.term);
            case RegexRuleOperator.Equals:
                return this.equals(rule.term);
            default:
                throw new Error(`Unsupported regex operation ${rule.operator} for ${rule.term}.`);
        }
    }

    /**
     * Escape reserved characters for use in a regex.
     */
    escape(term: string) {
        return term.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    }

    /**
     * Generate a regex to check if a `term` is present in a string.
     */
    contains(term: string) {
        return `(?=.*${term})`;
    }

    /**
     * Generate a regex to check if a `term` is not present in a string.
     */
    notContains(term: string) {
        return `(?!.*${term})`;
    }

    /**
     * Generate a regex to check if a `term` is equals to string.
     */
    equals(term: string) {
        return `(^${term}$)`;
    }

    /**
     * Generate a regex to check if all of the `terms` are present in a string.
     */
    and(...terms: string[]) {
        return '^' +  terms.map(term => `(?=${term})`).join('') + '.*';
    }

    /**
     * Generate a regex to check if any of the `terms` are present in a string.
     */
    or(...terms: string[]) {
        return '^' + terms.join('|') + '.*';
    }

    regexToFilter(rule: RegexRule, dimension: string) {
        return new Filter({
            operation: this.mapOperation(rule.operator),
            operations: [this.mapOperation(rule.operator)],
            query: rule.term,
            field: dimension,
            filters: Array.isArray(rule.rules) && rule.rules.length > 0
                ? rule.rules.map(r => this.regexToFilter(r, dimension))
                : undefined
        });
    }

    private mapOperation(ruleOperation: RegexRuleOperator | RegexGroupOperator) {
        return {
            [RegexGroupOperator.And]: Operation.And,
            [RegexGroupOperator.Or]: Operation.Or,
            [RegexRuleOperator.Contains]: Operation.Contains,
            [RegexRuleOperator.DoesNotContain]: Operation.DoesNotContain,
            [RegexRuleOperator.Equals]: Operation.Equals
        }[ruleOperation];
    }
}
