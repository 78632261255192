import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReportingSspforecasting } from 'app/shared/models';
import { BackendRepository } from './backend-repository';

@Injectable()
export class ReportingSspforecastingRepository extends BackendRepository<ReportingSspforecasting> {
    public constructor(http: HttpClient) {
        super(http, '/reporting/sspforecasting', ReportingSspforecasting);
    }

    public getReport(body): Observable<ReportingSspforecasting> {
        return this.http.post(this.url(this.path, 'report'), body) as Observable<ReportingSspforecasting>;
    }
}
