import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Creative } from 'app/shared/models';
import { CreativeRepository } from 'app/core/repositories';

@Injectable()
export class AdsCopyResolver implements Resolve<Creative[]> {
    constructor(
        private creativeRepository: CreativeRepository,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot): Observable<Creative[]> {
        const creativeId = route.params['id'];
        return this.creativeRepository.get(creativeId).pipe(
            map((creative: Creative) => {
                if (creative.externalId) {
                    this.router.navigate(['/campaign-manager/404']);
                }
                creative.name += ' - COPY';

                if (route.params['isDirectSoldCreative']) {
                    creative.isDirectSold = route.params['isDirectSoldCreative'].toLowerCase() === 'true';
                } else {
                    creative.isDirectSold = false;
                }

                // LineItems will be copied if lineItem param is set, will be set if comes from lineItem view
                if (route.params['lineItem']) {
                    creative['copyLineItems'] = true;
                    creative['lineItem'] = route.params['lineItem'];
                } else {
                    delete creative.id;
                    delete creative.refId;
                }
                return [creative];
            })
        );
    }
}
