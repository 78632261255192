import { Operation, OperationLabel } from './operation';
var Filter = /** @class */ (function () {
    function Filter(data) {
        if (data === void 0) { data = {}; }
        this.operations = [];
        Object.assign(this, data);
    }
    Object.defineProperty(Filter.prototype, "operationOptions", {
        get: function () {
            if (!this._operationOptions) {
                this._operationOptions = this.operations.map(function (operation, index) { return ({
                    key: index,
                    value: operation,
                    label: OperationLabel[operation]
                }); });
            }
            return this._operationOptions;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Filter.prototype, "simpleLabel", {
        get: function () {
            if (!this._simpleLabel) {
                return (Array.isArray(this.filters)
                    ? this.filters.filter(function (f) { return f.isValid(); })[0].query
                    : this.query).toString();
            }
            return this._simpleLabel;
        },
        set: function (label) {
            this._simpleLabel = label;
        },
        enumerable: true,
        configurable: true
    });
    Filter.prototype.isValid = function () {
        // or and and must contain child filters
        if ([Operation.And, Operation.Or].includes(this.operation) && (!Array.isArray(this.filters) || this.filters.length < 1)) {
            return false;
        }
        // we'll defer this to the children, because we will exclude the
        // invalid filters, rather than throw an error
        if (Array.isArray(this.filters)) {
            return true;
        }
        return this.field
            && (this.query || this.query === 0)
            && (typeof this.query === 'string' ? this.query.trim().length > 0 : true)
            && this.operations.includes(this.operation);
    };
    return Filter;
}());
export { Filter };
