import { Component, Input } from '@angular/core';
import { Campaign, LineItem } from 'app/shared/models';

@Component({
    selector: 'goal-metric-card',
    templateUrl: './goal-metric-card.html',
    styleUrls: ['./goal-metric-card.styl']
})
export class GoalMetricCardComponent {
    @Input() lineItem: LineItem = null;
    private _campaign: Campaign = null;

    adjustedCtr: number;
    campaignGoal;
    ctr: number;
    goaleCPA: number;
    goaleCPC: number;
    goaleCPM: number;

    subject: {
        bidAmount: number;
        conversions: number;
        clicks: number;
        impressions: number;
        spend: number;
        adjustedImpressions?: number;
    };

    @Input() set campaign(campaign: Campaign) {
        if (campaign !== null) {
            this._campaign = campaign;
            this.campaignGoal = this.campaign.meta;
            this.initSubject();

            this.adjustedCtr = this.getAdjustedCTR();
            this.ctr = this.getCTR();
            this.goaleCPA = this.getGoaleCPA();
            this.goaleCPC = this.getGoaleCPC();
            this.goaleCPM = this.getGoaleCPM();
        }
    }

    get campaign() {
        return this._campaign;
    }

    initSubject () {
        if (this.lineItem) {
            this.subject = {
                bidAmount: this.campaign.bidAmount,
                conversions: this.lineItem.conversions,
                clicks: this.lineItem.clicks,
                impressions: this.lineItem.impressions,
                spend: this.lineItem.spend,
                adjustedImpressions: this.lineItem.adjustedImpressions
            };
        } else {
            this.subject = {
                bidAmount: this.campaign.bidAmount,
                conversions:  this.campaign.conversions,
                clicks:  this.campaign.clicks,
                impressions:  this.campaign.impressions,
                spend:  this.campaign.spend
            };
        }
    }

    getAdjustedCTR() {
        return Number(this.subject.adjustedImpressions)
            ? (this.subject.clicks / (this.subject.adjustedImpressions))
            : 0;
    }

    getCTR() {
        return Number(this.subject.impressions) ? (this.subject.clicks / (this.subject.impressions)) : 0;
    }

    getCCR() {
        return Number(this.subject.conversions) ?
            (this.subject.conversions / this.subject.clicks) : 0;
    }

    getGoaleCPM() {
        return Number(this.subject.impressions) ?
            ((this.subject.spend / this.subject.impressions) * 1000) : 0;
    }

    getGoaleCPC() {
        return Number(this.subject.clicks) ? ((this.subject.spend / this.subject.clicks) * 100) / 100 : 0;
    }

    getGoaleCPA() {
        return Number(this.subject.conversions) ?
            Math.round((this.subject.spend / this.subject.conversions) * 100) / 100 : 0;
    }
}
