import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { map, tap, mergeMap, take } from 'rxjs/operators';
import { AuthorizationService, Role } from 'app/core/authorization.service';
import { AuthenticationService } from 'app/core/authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "../authentication.service";
import * as i2 from "../authorization.service";
import * as i3 from "@angular/router";
var CanLoadIsInternalUsers = /** @class */ (function () {
    function CanLoadIsInternalUsers(authentication, authorization, router) {
        this.authentication = authentication;
        this.authorization = authorization;
        this.router = router;
    }
    CanLoadIsInternalUsers.prototype.canLoad = function (route, segments) {
        var _this = this;
        return this.authentication.refreshUser().pipe(mergeMap(function () {
            return forkJoin(_this.authorization.hasRoleAsync(Role.Admin), _this.authorization.hasRoleAsync(Role.AdOps), _this.authorization.hasRoleAsync(Role.AccountManager), _this.authorization.hasRoleAsync(Role.StandardUser), _this.authorization.hasRoleAsync(Role.AccountDirector)).pipe(map(function (_a) {
                var isAdmin = _a[0], isAdOps = _a[1], isAccountManager = _a[2], isStandardUser = _a[3], isAccountDirector = _a[4];
                return isAdmin || isAdOps || isAccountManager || isStandardUser || isAccountDirector;
            }), tap(function (isInternalUser) {
                if (!isInternalUser) {
                    _this.router.navigate(['/campaign-manager']);
                }
            }));
        }), take(1));
    };
    CanLoadIsInternalUsers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CanLoadIsInternalUsers_Factory() { return new CanLoadIsInternalUsers(i0.ɵɵinject(i1.AuthenticationService), i0.ɵɵinject(i2.AuthorizationService), i0.ɵɵinject(i3.Router)); }, token: CanLoadIsInternalUsers, providedIn: "root" });
    return CanLoadIsInternalUsers;
}());
export { CanLoadIsInternalUsers };
