import { Component, Input, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { IdService } from 'app/core/index.js';
import { RTBPartnerRepository } from 'app/core/repositories';
import { SspControlHelper } from 'app/shared/helpers/ssp-control-helper';
import { AdSlot, MediaGroup, Newsletter, Publisher, RTBPartner } from 'app/shared/models';
import { AdSlotType } from 'app/shared/models/ad-slot';

import options from './options.json';

export enum RtbOptions {
    DEFAULT,
    ALLOW,
    BLOCK,
}

@Component({
    selector: 'rtb-settings',
    templateUrl: './rtb-settings.html',
    styleUrls: ['./rtb-settings.styl']
})
export class RTBSettingsComponent {
    @ViewChild('demand', { static: false }) demand: NgModel;
    @ViewChild('floor', { static: false }) floor: NgModel;
    @ViewChild('inheritFloor', { static: false }) inheritFloor: NgModel;

    @Input() onlyNativeDSP = false;
    @Input() isExchange = true;

    @Input() set adSlotType(value: AdSlotType) {
        this._adSlotType = value;

        this.overrideRtbOptions();
        this.resetSectionRtbSetting();
    }

    get adSlotType(): AdSlotType {
        return this._adSlotType;
    }

    rtbPartners: RTBPartner[] = [];
    effectiveAllow: boolean;
    effectiveFloor: number;
    sspControlHelper: SspControlHelper;
    mediaGroup: MediaGroup;
    publisher: Publisher;
    newsletter: Newsletter;
    adSlot: AdSlot;
    entityType: 'AdSlot' | 'Newsletter' | 'Publisher' | 'Media Group' = 'Publisher';
    rtbAllowOptions = [];
    rtbPartnerOptions = {};
    rtbPartnersWithFloor = new BehaviorSubject<any>({});
    _inheritiedExchangeFloor;
    effectiveExchangeFloor = null;
    _adSlotType: AdSlotType = null;
    rtbFirstTrigger = false;

    constructor(
        public rtbPartnerRepository: RTBPartnerRepository,
        public id: IdService
    ) {
        // Remove 2 criteoIDs that have floors from searches
        // because we do not use their floors (2.5) anymore per MT-4951
        const params = {
            conditions: [
                {
                    field: 'floor',
                    value: 0,
                    operator: 'gt'
                },
                {
                    field: 'id',
                    value: ['43800dd0785411e789f2126374df4a7a', '7268c40e859411e68c1522000a974651'],
                    operator: 'neq'
                }
            ]
        };
        rtbPartnerRepository.search(params).pipe(map(partners => {
            return partners.reduce((map, partner) => {
                map[partner.id] = partner;
                return map;
            }, {});
        })).subscribe(this.rtbPartnersWithFloor);

    }

    get inheritedExchangeFloor() {
        return this._inheritiedExchangeFloor;
    }

    set inheritedExchangeFloor(value) {
        this._inheritiedExchangeFloor = value;
    }

    get exchangeFloor() {
        return this.effectiveExchangeFloor;
    }

    set exchangeFloor(value) {
        switch (this.entityType) {
            case 'Media Group': {
                this.mediaGroup.sspControl.exchangeFloor = value;
                break;
            } case 'Publisher': {
                this.publisher.sspControl.exchangeFloor = value;
                break;
            } case 'Newsletter': {
                this.newsletter.sspControl.exchangeFloor = value;
                break;
            } default: {
                this.adSlot.sspControl.exchangeFloor = value;
                break;
            }
        }
    }

    get rtbAllow(): number {
        if ((this.sspControlHelper.rtbAllow
            || this.sspControlHelper.rtbAllowNative
            || this.sspControlHelper.rtbAllowDisplay) && !this.isLT2Native()) {
            return RtbOptions.ALLOW;
        } else if (this.sspControlHelper.rtbAllow === false && !this.isLT2Native()) {
            return RtbOptions.BLOCK;
        } else if (this.isLT2Native() && !this.atleastOneBlueprintHasRtbEnabled()) {
            this.rtbFirstTrigger = false;
            return RtbOptions.BLOCK;
        } else if (this.isLT2Native() && this.atleastOneBlueprintHasRtbEnabled() && !this.rtbFirstTrigger) {
            this.rtbFirstTrigger = true;
            this.rtbPartners = this.getAllowKeys(this.rtbPartners);
            return RtbOptions.ALLOW;
        }

        return this.setRtbAllowDefault();
    }

    set rtbAllow(value: number) {
        if (this.sspControlHelper) {
            this.setRtbAllows(value);
        }
    }

    /**
     * Initalize
     */
    public initSettings(sspControl, linkedRtbs, type, mediaGroup, publisher?, newsletter?, adSlot?): void {
        this.sspControlHelper = sspControl;
        this.entityType = type;
        this.mediaGroup = mediaGroup;
        this.publisher = publisher;
        this.newsletter = newsletter;
        this.adSlot = adSlot;
        this.initEffectiveSetting('Allow');
        this.initEffectiveSetting('Floor');
        this.rtbPartners = this.getAllowKeys(linkedRtbs);
        this.setRtbAllows(this.rtbAllow);
        this.initEffectiveExchangeFloor();
    }

    /**
     * Covert the booleans `allow`, `allowNative`, and `allowDisplay` values into integers
     */
    public getAllowKeys(rtbs) {
        rtbs.forEach(rtb => {
            rtb.allowKey = this.setRtbAllowDefault();

            if (rtb.allow === false || (rtb.allowNative === false && rtb.allowDisplay === false)) {
                rtb.allowKey = RtbOptions.BLOCK;
            } else if (rtb.allow || rtb.allowNative || rtb.allowDisplay) {
                rtb.allowKey = RtbOptions.ALLOW;
            }

            if (this.isLT2Native() && !this.atleastOneBlueprintHasRtbEnabled()) {
                rtb.allowKey = RtbOptions.BLOCK;
            }

            if (this.isLT2Native() && this.atleastOneBlueprintHasRtbEnabled() && rtb.allowKey === RtbOptions.DEFAULT) {
                rtb.allowKey = RtbOptions.ALLOW;
            }
        });

        return rtbs;
    }

    setRtbAllowDefault() {
        // If being inherited from the database then take the effective value
        if (this.isAdSlotComponent()) {
            return this.effectiveAllow ? RtbOptions.ALLOW : RtbOptions.BLOCK;
        }

        return RtbOptions.DEFAULT;
    }

    setRtbAllows(rtbAllow: number): void {
        switch (rtbAllow as number) {
            case RtbOptions.DEFAULT: {
                /**
                 * Set rtbAllow, rtbAllowNative, and rtbAllowDisplay for AdSlots.
                 * Only set rtbAllow for Media Groups, Publishers, and Newsletters.
                 */
                if (this.adSlot) {
                    this.setRtbAllowsHelper(null, null, null);
                } else {
                    this.setRtbAllowHelper(null);
                }

                break;
            } case RtbOptions.ALLOW: {
                /**
                 * Set rtbAllow, rtbAllowNative, and rtbAllowDisplay for AdSlots.
                 * Only set rtbAllow for Media Groups, Publishers, and Newsletters.
                 */
                if (this.adSlot) {
                    // Allow Native and Block Display for LT2 Native AdSlots
                    // Block Native and Allow Display for LT2 Fixed Ratio and Taboola AdSlots
                    this.setRtbAllowsHelper(true, this.adSlot.isNative, !this.adSlot.isNative);
                } else {
                    this.setRtbAllowHelper(true);
                }

                break;
            } case RtbOptions.BLOCK: {
                /**
                 * Set rtbAllow, rtbAllowNative, and rtbAllowDisplay for AdSlots.
                 * Only set rtbAllow for Media Groups, Publishers, and Newsletters.
                 */
                if (this.adSlot) {
                    // Block Native and Display
                    this.setRtbAllowsHelper(false, false, false);
                } else {
                    this.setRtbAllowHelper(false);
                }

                break;
            }
        }

        this.overrideRtbOptions();

        for (const rtb of this.rtbPartners) {
            if (rtb.isNative3PDSP) {
                const found = this.rtbPartnerOptions[rtb.id].find(option => (
                    option.value === rtb.allowKey || option.value === RtbOptions.DEFAULT
                ));

                if (found === undefined) {
                    if (rtbAllow === RtbOptions.DEFAULT) {
                        const option =  this.rtbPartnerOptions[rtb.id].slice(-1);
                        rtb.allowKey = option.key;
                    } else {
                        rtb.allowKey = rtbAllow;
                    }
                }
           }
        }
    }

    /**
     * Set rtbAllow.
     */
    setRtbAllowHelper(rtbAllow: boolean): void {
        this.sspControlHelper.rtbAllow = rtbAllow;
    }

    /**
     * Set rtbAllow, rtbAllowNative, and rtbAllowDisplay
     */
    setRtbAllowsHelper(rtbAllow: boolean, rtbAllowNative: boolean, rtbAllowDisplay: boolean): void {
        this.sspControlHelper.rtbAllow = rtbAllow;
        this.sspControlHelper.rtbAllowNative = rtbAllowNative;
        this.sspControlHelper.rtbAllowDisplay = rtbAllowDisplay;
    }

    public updateMediaGroup(mediaGroup) {
        this.mediaGroup = mediaGroup;
        this.rtbPartners = this.remapRtbPartners(mediaGroup);
        this.initEffectiveSetting('Allow');
        this.initEffectiveSetting('Floor');
        this.overrideRtbOptions();
    }

    initEffectiveExchangeFloor() {
        let values;
        if (this.entityType === 'Media Group') {
            this.effectiveExchangeFloor = this.mediaGroup.sspControl.exchangeFloor === null ? 0.5
                : this.mediaGroup.sspControl.exchangeFloor;
            return;
        } else if (this.entityType === 'Publisher') {
            values = [this.publisher.sspControl.exchangeFloor, this.mediaGroup.sspControl.exchangeFloor];
        } else if (this.entityType === 'Newsletter') {
            values = [this.newsletter.sspControl.exchangeFloor,
                    this.publisher.sspControl.exchangeFloor,
                    this.mediaGroup.sspControl.exchangeFloor];
        } else {
            values = [this.adSlot.sspControl.exchangeFloor,
                    this.newsletter.sspControl.exchangeFloor,
                    this.publisher.sspControl.exchangeFloor,
                    this.mediaGroup.sspControl.exchangeFloor];
        }
        if (values[0] !== null && values[0] !== undefined) {
            this.effectiveExchangeFloor = values[0];
        }
        for (let i = 1; i < values.length; i++) {
            if (values[i] !== null && values[i] !== undefined) {
                this.inheritedExchangeFloor = values[i];
                break;
            }
        }
    }

    initEffectiveSetting(field: 'Allow' | 'Floor') {
        let effectiveSetting;
        const sspField = field === 'Floor' ?  'exchange' + field :  'rtb' + field;
        if (this.mediaGroup.sspControl.hasOwnProperty(sspField) && this.mediaGroup.sspControl[sspField] !== null) {
            effectiveSetting = this.mediaGroup.sspControl[sspField];
        }
        if (
            this.publisher &&
            this.publisher.sspControl.hasOwnProperty(sspField) &&
            this.publisher.sspControl[sspField] !== null
        ) {
            effectiveSetting = this.publisher.sspControl[sspField];
        }
        if (
            this.entityType === 'AdSlot' &&
            this.newsletter.sspControl.hasOwnProperty(sspField) &&
            this.newsletter.sspControl[sspField] !== null
        ) {
            effectiveSetting = this.newsletter.sspControl[sspField];
        }
        this['effective' + field] = effectiveSetting;
    }

    public getRtbAllowOptionsWithoutDefault() {
        const optionsCopy = JSON.parse(JSON.stringify(options.rtbAllow));
        optionsCopy.shift();

        return optionsCopy;
    }

    public getRtbAllowOptions() {
        // If component is ad slot form - don't do any inheritance
        if (this.isAdSlotComponent()) {
            return this.getRtbAllowOptionsWithoutDefault();
        }

        // Media Group, Publisher, and Newsletter should still have inheritance
        return this.getRtbAllowOptionsInheritance();
    }

    public getRtbAllowOptionsInheritance() {
        const label = this.effectiveAllow ? 'Allow' : 'Block';
        const optionsCopy = JSON.parse(JSON.stringify(options.rtbAllow));

        const allowDefault = optionsCopy[0];
        allowDefault.label = 'Default (' + label + ')';

        return optionsCopy;
    }

    public getRtbPartnerOptions(override = false, overrideInheritAllow = null) {
        // If component is ad slot form - don't do any inheritance
        if (this.isAdSlotComponent()) {
            return this.getRtbAllowOptionsWithoutDefault();
        }

        // Media Group, Publisher, and Newsletter should still have inheritance
        return this.getRtbPartnerOptionsInheritance(override, overrideInheritAllow);
    }

    /**
     * Changes the default label for allow options.
     * If override is true, we check the inherited allow for that
     * rtbPartner and if that is not set then the allow value
     * for the current entity of the form. For example, if this is the
     * newsletter form we check the newsletter for its allow
     * value to change the default label for the individual rtb partners.
     */
    getRtbPartnerOptionsInheritance(override = false, overrideInheritAllow = null) {
        const labelCheck = () => {
            if (this.onlyNativeDSP) {
                return true;
            }

            if (override && this.sspControlHelper.rtbAllow !== null) {
                return this.sspControlHelper.rtbAllow;
            }

            if (override && overrideInheritAllow !== null) {
                return overrideInheritAllow;
            }

            return this.effectiveAllow;
        };

        const optionsCopy = JSON.parse(JSON.stringify(options.rtbAllow));

        const label = labelCheck() ? 'Allow' : 'Block';
        const allowDefault = optionsCopy[0];

        allowDefault.label = 'Default (' + label + ')';

        return optionsCopy;
    }

    overrideRtbOptions(): void {
        // rtbAllow
        this.rtbAllowOptions = this.getRtbAllowOptions();

        // Rtb Partners
        for (const rtb of this.rtbPartners) {
            this.rtbPartnerOptions[rtb.id] = this.getRtbPartnerOptions(true, rtb.inheritedAllow);
        }
    }

    /**
     * Returns an array of all Rtb
     * Partners that have overrides
     */
    getLinkedRtbs() {
        if (this.isRtbOff) {
            return [];
        } else {
            return this.rtbPartners.filter(rtb => (
                rtb.allowKey !== 0 ||
                rtb.floor !== null
            )).map(rtb => {
                    const rtbSettings = {
                        id: rtb.id,
                        floor: rtb.floor,
                        allow: rtb.allow,
                        allowNative: rtb.allowNative === undefined ? null : rtb.allowNative,
                        allowDisplay: rtb.allowDisplay === undefined ? null : rtb.allowDisplay
                    };

                    switch (rtb.allowKey) {
                        case RtbOptions.DEFAULT: {
                            // Reset the allow and floor to NULL when we want to inherit
                            // This will ensure we remove the DSP from zf_section_rtb_partners
                            rtbSettings.allow = null;
                            rtbSettings.floor = null;
                            break;
                        } case RtbOptions.ALLOW: {
                            if (this.adSlot) {
                                // Allow Native and Block Display for LT2 Native AdSlots
                                // Block Native and Allow Display for LT2 Fixed Ratio and Taboola AdSlots
                                rtbSettings.allowNative = this.adSlot.isNative;
                                rtbSettings.allowDisplay = !this.adSlot.isNative;
                            } else {
                                delete rtbSettings.allowNative;
                                delete rtbSettings.allowDisplay;
                            }

                            rtbSettings.allow = true;
                            break;
                        } case RtbOptions.BLOCK: {
                            if (this.adSlot) {
                                // Block Native and Display
                                rtbSettings.allowNative = false;
                                rtbSettings.allowDisplay = false;
                            } else {
                                delete rtbSettings.allowNative;
                                delete rtbSettings.allowDisplay;
                            }

                            rtbSettings.allow = false;
                            break;
                        }
                    }

                    return rtbSettings;
                }
            );
        }
    }

    /**
     * Clear section Rtb setting to default
     */
    resetSectionRtbSetting(): void {
        this.rtbAllow = RtbOptions.DEFAULT;
    }

    resetAllowKeys(): void {
        this.rtbPartners.forEach(rtb => {
            rtb.allowKey = RtbOptions.BLOCK;
        });
    }

    get sspControlsDirty(): boolean {
        if (this.inheritFloor) {
            return this.demand.dirty ||
                this.floor.dirty ||
                this.inheritFloor.dirty;
        }

        return this.demand.dirty || (this.floor && this.floor.dirty);
    }

    get isRtbOff(): boolean {
        return this.sspControlHelper.rtbAllow === false
            || (this.sspControlHelper.rtbAllow === null && this.effectiveAllow === false);
    }

    remapRtbPartners(mediaGroup) {
        const mgPartners = mediaGroup.rtbPartners || [];
        return this.rtbPartners.map(partner => {
            partner.inheritedAllow = mediaGroup.sspControl.rtbAllow;
            partner.inheritedFloor = mediaGroup.sspControl.rtbFloor;

            const mgPartner = mgPartners.find(aMgPartner => aMgPartner.id === partner.id);
            if (mgPartner && mgPartner.allow !== null) {
                partner.inheritedAllow = mgPartner.allow;
            }
            if (mgPartner && mgPartner.floor !== null) {
                partner.inheritedFloor = mgPartner.floor;
            }
            return partner;
        });
    }

    validFloors() {
        const partners = this.rtbPartnersWithFloor.getValue();
        return this.rtbPartners.every(partner => {
            return !(partners[partner.id] &&
                (partner.floor || partner.floor === 0) && partner.floor < partners[partner.id].floor);
        });
    }

    resetFloors() {
        this.effectiveExchangeFloor = null;
        this.inheritedExchangeFloor  = null;
    }

    // this will filter out our internal native dsp when we show all dsps
    isExchangeAdSlotAndNativeDSP(partner): boolean {
        return !this.onlyNativeDSP && this.adSlot && this.isExchange && partner.isLiNativeDSP;
    }

    isAdSlotNativeDSP(partner): boolean {
        return this.onlyNativeDSP && !partner.isNative3PDSP;
    }

    public isPartnerDisabled(partner): boolean {
        return this.isAdSlotNativeDSP(partner) || this.isExchangeAdSlotAndNativeDSP(partner);
    }

    isLT2Native(): boolean {
        return this.adSlot && this.adSlot.isNative || (this.adSlotType === AdSlotType.ImageNative);
    }

    atleastOneBlueprintHasRtbEnabled(): boolean {
        return this.adSlot && this.adSlot.nativeBlueprints
            && this.adSlot.nativeBlueprints.some(b => b.allowRtb === true);
    }

    isAdSlotComponent() {
        return this.adSlot !== undefined;
    }
}
