import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WhitelistingRule, RuleType } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { map } from 'rxjs/operators';

@Injectable()
export class WhitelistingRuleRepository extends BackendRepository<WhitelistingRule> {
    constructor(http: HttpClient) {
        super(http, '/lcidc/rule', WhitelistingRule);
    }

    get(id: string) {
        return this.http.get(this.url(this.path + 's', id))
            .pipe(map(response => this.build(response)));
    }

    delete(id: string){
        return this.http.delete(this.url(this.path + 's', id));
    }

    getByAdvertiserId(advertiserId: number) {
        return this.http.get(this.url('/lcidc/rules?advertiserId=' + advertiserId))
            .pipe(
                map(response => response as object[]),
                map(output => output.map(rule => this.build(rule)) as WhitelistingRule[])
            );
    }

    /**
     * Returns an observable that saves `WhitelistingRule` in the backend.
     */
    save(instance: WhitelistingRule): Observable<WhitelistingRule> {
        return this.saveEventsByExternalId(instance.type, instance);
    }

    /**
     * Returns an observable that saves `WhitelistingRule` in the backend.
     */
    saveEventsByExternalId(ruleType: RuleType, instance: WhitelistingRule): Observable<WhitelistingRule> {
        const params = [this.path + '/' + ruleType];

        if (instance.segmentId) {
            params.push(instance.segmentId);
        }

        return this.http.post(this.url(...params), instance.serialize())
            .pipe(map(response => this.build(response)));
    }
}
