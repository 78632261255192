import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Deal } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { map } from 'rxjs/operators';
var DealRepository = /** @class */ (function (_super) {
    tslib_1.__extends(DealRepository, _super);
    function DealRepository(http) {
        return _super.call(this, http, '/deal', Deal) || this;
    }
    DealRepository.prototype.save = function (deal) {
        var _this = this;
        var params = [this.path];
        if (deal.id) {
            params.push(deal.id.toString());
        }
        return this.http.post(this.url.apply(this, params), deal.serialize())
            .pipe(map(function (response) { return _this.build(response['output']); }));
    };
    return DealRepository;
}(BackendRepository));
export { DealRepository };
