import { Model } from './model';
import { clone } from 'app/core/utils';
import { EventBuilderConfiguration } from 'app/platform/campaign-manager/live-insights/event-builder/event-builder-configuration';
import * as configs from 'app/platform/measurement/live-connect-manager/live-connect-setup/event-configs';

export class Rule extends Model {
    public eventType: string;
    public ruleId: number;
    public name: string;
    public accountId: number;
    public pixelId: string;
    public appsId: string[];
    public body: object;
    configuration: EventBuilderConfiguration;
    public isStandard?: boolean;
    private _eventNameRegex: string;
    private _termRegex: string;

    constructor(from?: Object) {
        super(from);
        this.configuration = this.computeConfiguration();
    }

    public serialize(): string {
        return JSON.stringify(this);
    }

    get entity() {
        return 'Rule';
    }

    get isUrlBased(): boolean {
        return !! this.findValueByPath('$.url');
    }

    get isEventBased(): boolean {
        return !this.isUrlBased;
    }

    get eventNameRegex(): string | undefined {
        return this._eventNameRegex || this.findValueByPath('$.event') || '';
    }

    set eventNameRegex(value: string) {
        this._eventNameRegex = value;
    }

    get pageRegex(): string | undefined {
        return this.findValueByPath('$.url');
    }

    get termRegex(): string {
        return this._termRegex || this.findValueByPath('$.name') || '';
    }

    set termRegex(value: string) {
        this._termRegex = value;
    }

    get title() {
        return this.configuration.eventName;
    }

    /**
     * This is written based on the research done here:
     * https://liveintent.atlassian.net/browse/MT-5182?focusedCommentId=145373
     */
    private findValueByPath(targetPath: string): string | undefined {
        const data = this.body;
        if (!data) return undefined;

        if (data['And'] && Array.isArray(data['And'].conditions)) {
            for (const condition of data['And'].conditions) {
                // Because there can only ever be one Match path with the `targetPath`, we can return early
                if (condition.Matches && condition.Matches.path === targetPath) {
                    return condition.Matches.value;
                }
            }
        } else if (data['Matches'] && data['Matches'].path === targetPath) {
            return data['Matches'].value;
        }

        // Return undefined if no matching path is found
        return undefined;
    }

    private computeConfiguration() {
        const customConversion = clone(configs.customConversion);
        const matchedConfig = Object.values(configs)
            .filter(config => config.eventType === this.eventNameRegex)
            .find((config: any) => config.props.find(
                (prop: any) => prop.id === 'name' && prop.value === this.termRegex
            ));

        if (!matchedConfig) {
            // replace custom conversion name with actual term value
            customConversion.props.forEach((prop: any) => {
                if (prop.id === 'name') {
                    prop.value = this.termRegex;
                }
            });
        }

        return matchedConfig || customConversion;
    }
}
