import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { User, Session, AccessList, Role } from 'app/shared/models';
import { Id, Query } from './backend-repository';
import { BackendResourceRepository } from './backend-resource-repository';
import { map } from 'rxjs/operators';

@Injectable()
export class UserRepository extends BackendResourceRepository<User> {
    public constructor(http: HttpClient) {
        super(http, '/user', User);
    }

    get(id: Id): Observable<User> {
        if (typeof id === 'number') {
            id = id.toString();
        }

        if (typeof id !== 'string') {
            return throwError(new Error('Invalid parameter. Parameter must be a string or a number.'));
        }

        if (id.length < 1) {
            return throwError(new Error('Invalid parameter. Parameter must have length at least 1.'));
        }

        return this.http.get(this.url('/users', id)).pipe(
            map(response => this.build(response['data']))
        );
    }

    getFromLSD(id: Id): Observable<User> {
        if (typeof id === 'number') {
            id = id.toString();
        }

        if (typeof id !== 'string') {
            return throwError(new Error('Invalid parameter. Parameter must be a string or a number.'));
        }

        if (id.length < 1) {
            return throwError(new Error('Invalid parameter. Parameter must have length at least 1.'));
        }

        return this.http.get(this.url('/user', id)).pipe(
            map(response => this.build(response['output']))
        );
    }

    save(instance: User): Observable<User> {
        if (instance.hashId) {
            instance.id = instance.hashId;
        }
        return super.save(instance);
    }

    protected rawSearch(query?: {}, uri?: string) {
        return super.rawSearch(query, '/users/search');
    }

    all(query: Query = {}) {
        console.error('Not yet implemented');

        return of([]);
    }

    searchByIds(ids: Id[]) {
        if (Array.isArray(ids) && ids.length === 0) {
            return of([]);
        }

        return this.search({
            conditions: [
                { field: 'hash_id', value: ids }
            ]
        });
    }

    syncToTessellate(emailOrId: string): Observable<any> {
        return this.http.post(this.url('/users', emailOrId, 'sync'), {})
            .pipe(map(response => response['data']));
    }

    syncDeleteToTessellate(emailOrId: string): Observable<any> {
        return this.http.delete(this.url('/users', emailOrId), {}).pipe();
    }

    syncRestoreToTessellate(emailOrId: string): Observable<any> {
        return this.http.put(this.url('/users', emailOrId, 'restore'), {})
            .pipe(map(response => response['data']));
    }

    resetMultiFactorAuth(emailOrId: string): Observable<User> {
        return this.http.delete(this.url('/users', emailOrId, 'two-factor-authentication'), {})
            .pipe(map(response => this.build(response['data'])));
    }

    public getMeta(id: string): Observable<any> {
        return this.http.get(this.url('/user', id))
            .pipe(map(response => response['output'].meta));
    }

    public saveMeta(id: string, meta: any): Observable<User> {
        return this.http.post(this.url('/user/save-meta', id), meta)
            .pipe(map(response => response['output']));
    }

    undelete(user: User): Observable<User> {
        return this.http
            .post(this.url("/user/undelete", user.hashId), {
                version: user.version,
            })
            .pipe(map((response) => this.build(response["output"])));
    }

    public loginAs(id: string | number): Observable<Session> {
        const data = { userId: id };
        return this.http.post(this.url('/auth/login-as'), data)
            .pipe(map(response => new Session(response)));
    }

    public getAccessList(id: string | number): Observable<AccessList> {
        return this.http.get(this.url('/rbac/user', id.toString()))
            .pipe(map(response => UserRepository.unwrapAccessList(response)));
    }

    private static unwrapAccessList(response): AccessList {
        const value = response.output;
        return new AccessList(Array.isArray(value) ? {} : value);
    }

    public saveUserRoles(id: string, roles: any) {
        return this.http.post(this.url('/rbac/user/save', id), roles);
    }

    public getRoles(): Observable<Role[]> {
        return this.http.get(this.url('/rbac/role'))
            .pipe(map(response => response['output'].map(data => new Role(data)) as Role[]));
    }

    public getAdmins(): Observable<User[]> {
        return this.http.get(this.url('/rbac/user/admins'))
            .pipe(map(response => response['output']));
    }

    public getManagers(): Observable<User[]> {
        return this.http.get(this.url('/rbac/user/managers'))
            .pipe(map(response => response['output']));
    }

    public getFromCache(id: Id): Observable<User> {
        if (id === '1') {
            return of({
                get name() {
                    return 'LiveIntent System';
                }
            } as any);
        }

        return super.getFromCache(id);
    }

    protected getCacheKey(model: User): Id {
        return model.hashId;
    }
}
