import { ReplaySubject } from 'rxjs';
var BaseTableHelper = /** @class */ (function () {
    function BaseTableHelper() {
        /**
         * We set a debounce so we don't fire off too many queries at once. For example if
         * a user clicks the `Next` button repeatedly, we'll wait until the clicks stop
         * coming before finally emitting the desired page of the user.
         */
        this.debounceTime = 150;
        /**
         * The data subject holds the current the data for the table.
         */
        this.data = new ReplaySubject(1);
        /**
         * The query made by the table to fill itself with data.
         */
        this.query = new ReplaySubject(1);
    }
    /**
     * Initiate a new table query.
     */
    BaseTableHelper.prototype.search = function (args) {
        this.query.next(Object.assign.apply(Object, [{}].concat(args)));
    };
    Object.defineProperty(BaseTableHelper.prototype, "table", {
        /**
         * Get the table.
         */
        get: function () {
            return this._table;
        },
        /**
         * Set the table paginator and register subscribptions.
         */
        set: function (table) {
            var _this = this;
            if (this.table)
                this.table.query.unsubscribe();
            this._table = table;
            this.table.query.subscribe(function () { if (_this.paginator)
                _this.paginator.reset(); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseTableHelper.prototype, "paginator", {
        /**
         * Get the table's paginator.
         */
        get: function () {
            return this._paginator;
        },
        /**
         * Set the table's paginator and register subscribptions.
         */
        set: function (paginator) {
            var _this = this;
            if (this.paginator)
                this.paginator.query.unsubscribe();
            this._paginator = paginator;
            this.paginator.query.subscribe(function () { if (_this.table)
                _this.table.load(); });
        },
        enumerable: true,
        configurable: true
    });
    return BaseTableHelper;
}());
export { BaseTableHelper };
