import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'disabled'
})
export class DisabledPipe implements PipeTransform {
    transform(value: any) {
        return value !== null && value !== true ? null : true;
    }
}
