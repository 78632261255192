import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { Deal } from 'app/shared/models';
import { DealRepository } from 'app/core/repositories';

@Injectable()
export class PackageDealsResolver implements Resolve<Deal[]> {
    constructor(private dealRepository: DealRepository) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.dealRepository.all({
            conditions: [
                {
                    field: 'packageId',
                    value: route.params['id']
                }
            ]
        });
    }
}
