import { Model } from 'app/shared/models/model';

export class HistoryChange extends Model {
    field: string;
    value: any;
    old: any;

    serialize() {
        return null;
    }

    get entity() {
        return 'History Change';
    }
}
