var InventoryTargetingOption = /** @class */ (function () {
    function InventoryTargetingOption(data) {
        this.data = data;
    }
    Object.defineProperty(InventoryTargetingOption.prototype, "key", {
        get: function () {
            return this.data.refId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryTargetingOption.prototype, "value", {
        get: function () {
            return this.data.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryTargetingOption.prototype, "label", {
        get: function () {
            if (this.data.label) {
                return this.data.label;
            }
            return [this.data.refId + ':', this.data.name].join(' ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryTargetingOption.prototype, "type", {
        get: function () {
            return this.data.type;
        },
        enumerable: true,
        configurable: true
    });
    return InventoryTargetingOption;
}());
export { InventoryTargetingOption };
