import { ValidationService } from './validation-service';

export class Url {
    /**
     * The URL to parse.
     *
     * @type {string}
     */
    private url: string;

    /**
     * Create a new Url object from a valid URL string.
     *
     * @param {string} url
     */
    constructor(url: string) {
        if (!ValidationService.isValidDomain(url) && !ValidationService.isValidUrl(url)) {
            console.warn('Invalid Url: ' + url);
        }

        this.url = url || '';
    }

    /**
     * Get the protocol of the url.
     *
     * @return {string}
     */
    public get protocol(): string {
        return this.url.includes('://') ? this.url.substring(0, this.url.indexOf('://') + 3) : null;
    }

    /**
     * Get the hostname of the url.
     *
     * @return {string}
     */
    public get hostname(): string {
        return this.url.includes('://') ? this.url.substring(this.url.indexOf('://') + 3) : this.url;
    }
}
