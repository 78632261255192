import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { ButtonComponent } from '../button';

@Component({
    selector: 'lightbox',
    templateUrl: './lightbox.html',
    styleUrls: ['./lightbox.styl'],
    animations: [
        trigger('backdrop', [
            state('*', style({ display: 'none', opacity: '.5' })),
            state('open', style({ display: 'block', opacity: '.7' })),
            transition('closed => open', animate('200ms ease-in')),
            transition('open => closed', animate('150ms 50ms ease-in')),
        ]),
        trigger('lightbox', [
            state('*', style({ display: 'none', transform: 'scale(.5)', opacity: '0' })),
            state('open', style({ display: 'block', transform: 'scale(1)', opacity: '1' })),
            transition('closed => open', animate('150ms 50ms ease-in')),
            transition('open => closed', animate('200ms ease-in')),
        ])
    ]
})

export class LightboxComponent implements OnInit {
    @Input() header: string;
    @Input() size: string;
    @Input() closeButtonHoverText: string;
    @Input() trigger: Element;
    @Input() state: string = 'closed';
    @Input('close-disabled') closeDisabled: boolean = false;
    @Output() closedState = new EventEmitter<string>();
    context: any = null;

    constructor(
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        let trigger: any = this.trigger;

        if (trigger instanceof ButtonComponent) {
            trigger = trigger.el.nativeElement;
            trigger.addEventListener('click', _ => this.open());
        }

        if (this.state === 'auto') {
            this.state = 'closed';
            window.setTimeout(() => {
                this.state = 'open';
                this.cdr.detectChanges();
            }, 0);
        }
    }

    open (context?: any): void {
        this.context = context;
        this.state = 'open';
        this.cdr.markForCheck();
    }

    close(): void {
        this.state = 'closed';
        this.cdr.markForCheck();
        this.closedState.emit('closed');
    }

    @HostListener('window:keydown.esc') closeUsingEsc(): void {
        this.close();
    }
}
