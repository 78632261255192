import moment from 'moment';
import { Status } from './status';
var LiveConnectStatus = /** @class */ (function () {
    /**
     * Create a new instance.
     */
    function LiveConnectStatus(activity) {
        this.activity = activity;
    }
    Object.defineProperty(LiveConnectStatus.prototype, "lastSeen", {
        /**
         * Get the last seen time.
         */
        get: function () {
            if (!this.hasActivity) {
                return null;
            }
            return moment.utc(this.activity.events[0].__time);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LiveConnectStatus.prototype, "lastSeenInterval", {
        /**
         * Get the last time it was seen as an estimated interval.
         */
        get: function () {
            if (!this.lastSeen) {
                return null;
            }
            return this.lastSeen.fromNow();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LiveConnectStatus.prototype, "title", {
        /**
         * A textual representation of the status.
         */
        get: function () {
            switch (this.status) {
                case Status.ACTIVE:
                case Status.WARNING:
                    return 'Active';
                case Status.INACTIVE:
                    return 'Inactive';
                default:
                    return 'Unknown';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LiveConnectStatus.prototype, "circleClass", {
        /**
         * The css class used for showing the status as a circle icon.
         */
        get: function () {
            switch (this.status) {
                case Status.ACTIVE:
                    return 'circle-success';
                case Status.WARNING:
                    return 'circle-warning';
                default:
                    return 'circle-inactive';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LiveConnectStatus.prototype, "status", {
        /**
         * The current status.
         */
        get: function () {
            if (!this.lastSeen) {
                return Status.UNKNOWN;
            }
            var dayDiff = moment().diff(this.lastSeen, 'days');
            if (dayDiff <= 1) {
                return Status.ACTIVE;
            }
            else if (dayDiff <= 7) {
                return Status.WARNING;
            }
            else {
                return Status.INACTIVE;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LiveConnectStatus.prototype, "hasActivity", {
        /**
         * Check if the pixel has activity.
         */
        get: function () {
            return this.activity && Array.isArray(this.activity.events) && this.activity.events.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    return LiveConnectStatus;
}());
export { LiveConnectStatus };
