<lightboxnext class="large abs" header="Choose Accounts" (hidden)="reset()">
    <div class="body">
        <div class="row">
            <div class="column--8 left">
                <div class="padding">
                    <input type="text" placeholder="Search Accounts" #input (keyup)="change($event.target.value)">
                    <br>
                    <br>
                    <app-table class="light" [data]="tableData | async" (query)="query($event)">
                        <div class="zero">
                            <p [attr.id]="id.text('choose-accounts', 'search-text')">Search by Advertiser, Agency, Media Group, Account Manager or Account Executive.</p>
                        </div>
                        <ng-container appTableColumn="name" appTableColumnName="Account Name" appTableColumnLocked>
                            <th [attr.id]="id.control('choose-accounts', 'account-name-column')" *appTableHeader appTableSort appTableDefaultColumn class="name-column">Account Name</th>
                            <td *appTableCell="let account" class="name-column" (click)="select(account)">
                                <div [attr.id]="id.text('choose-accounts', 'account-name')" class="overflow">{{account.name}}</div>
                                <span [attr.id]="id.text('choose-accounts', 'account-id')" class="supporting">ID: {{account.refId}}</span>
                            </td>
                        </ng-container>
                        <ng-container appTableColumn="entity" appTableColumnName="Account Type">
                            <th [attr.id]="id.control('choose-accounts', 'account-type-column')" *appTableHeader appTableSort>Account Type</th>
                            <td [attr.id]="id.text('choose-accounts', 'account-type')" *appTableCell="let account" class="align-center" (click)="select(account)">{{account.entity}}</td>
                        </ng-container>
                        <ng-container appTableColumn="managedBy" appTableColumnName="Managed By">
                            <th [attr.id]="id.control('choose-accounts', 'managed-by-column')" *appTableHeader appTableSort>Managed By</th>
                            <td *appTableCell="let account" (click)="select(account)">
                                <div [attr.id]="id.text('choose-accounts', 'managed-by')" class="ellipsis align-center">{{account.managedBy}}</div>
                            </td>
                        </ng-container>
                        <ng-container appTableColumn="managerName" appTableColumnName="Account Manager">
                            <th [attr.id]="id.control('choose-accounts', 'account-manager-column')" *appTableHeader appTableSort>Account Manager</th>
                            <td *appTableCell="let account" (click)="select(account)">
                                <div [attr.id]="id.text('choose-accounts', 'manager-name')" class="ellipsis align-center">{{account.managerName || '&mdash;'}}</div>
                            </td>
                        </ng-container>
                        <ng-container appTableColumn="executiveName" appTableColumnName="Account Executive">
                            <th [attr.id]="id.control('choose-accounts', 'account-executive-column')" *appTableHeader appTableSort >Account Executive</th>
                            <td *appTableCell="let account" (click)="select(account)">
                                <div [attr.id]="id.text('choose-accounts', 'executive-name')" class="ellipsis align-center">{{account.executiveName || '&mdash;'}}</div>
                            </td>
                        </ng-container>
                    </app-table>
                </div>
            </div>
            <div class="column--4 right">
                <div class="padding">
                    <a [attr.id]="id.control('choose-accounts', 'clear-all')" class="pull-right" *ngIf="(selected | async)?.length > 0" (click)="clearAll()">Clear All</a>
                    <h5>
                        <span [attr.id]="id.text('choose-accounts', 'selected')"> Selected</span>
                        <span [attr.id]="id.text('choose-accounts', 'selected-length')" class="_badge">{{(selected | async)?.length}}</span>
                    </h5>
                </div>
                <list class="no-border" [ngModel]="selected | async" (ngModelChange)="update($event)">
                    <ng-template #template let-account>
                        <div class="row">
                            <div class="column--3">
                                <span class="supporting">ID: {{account.refId}}</span>
                            </div>
                            <div class="column--8">
                                <div class="ellipsis">{{account.name}}</div>
                            </div>
                            <div class="column--1 align-right">
                                <i class="fas fa-times"></i>
                            </div>
                        </div>
                    </ng-template>
                </list>
            </div>
        </div>
    </div>
    <footer>
        <div class="pull-right">
            <a [attr.id]="id.control('choose-accounts', 'cancel')" class="button" (click)="cancel()">Cancel</a>
            <button [attr.id]="id.control('choose-accounts', 'add-accounts')" type="button" class="button--primary" (click)="addAccounts()" [disabled]="!canAddAccounts">Add Accounts</button>
        </div>
    </footer>
</lightboxnext>
