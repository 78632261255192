import { Component, HostListener, ContentChildren, QueryList, ElementRef, Renderer2, Input } from '@angular/core';

@Component({
    selector: 'content-slider',
    templateUrl: './content-slider.html',
    styleUrls: ['./content-slider.styl'],
})

// To use the filter component, make sure in the parent component that your
// individual slides are templated with #slide and class tag slide

export class ContentSliderComponent {
    @ContentChildren('slide') slides: QueryList<ElementRef>;
    @Input() instructionMsg: string;
    activeIndex: number = 0;
    constructor(private renderer: Renderer2) {}

    ngAfterViewInit() {
        this.getDotsArray();
        this.getSlidesArray();
        this.setActiveIndex(0);
    }

    next(): void {
        if (this.slides.length === 0 || this.activeIndex === this.slides.length) {
            return;
        }
        this.setActiveIndex((this.activeIndex + 1) % this.slides.length);
    }

    prev(): void {
        if (this.slides.length === 0) {
            return;
        }
        this.setActiveIndex((this.activeIndex - 1 + this.slides.length) % this.slides.length);
    }

    setActiveIndex(index: number): void {
        if (this.slides.length > 0) {
            const previousElement = this.slides.toArray()[this.activeIndex].nativeElement;
            this.renderer.addClass(previousElement, 'slide');

            // Set active slide
            this.activeIndex = index;
            const currentElement = this.slides.toArray()[this.activeIndex].nativeElement;
            this.renderer.removeClass(currentElement, 'slide');
        }
    }

    getDotsArray(): number[] {
        const length = !(this.slides) ? 0 : this.slides.length;
        return Array.from({ length: Math.max(1, length) }, (_, i) => i);
    }

    getSlidesArray(): number[] {
        const length = !(this.slides) ? 0 : this.slides.length;
        return Array.from({ length: length }, (_, i) => i);
    }

    // Used to cycle through carousel with keyboard arrows
    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.key === 'ArrowLeft') {
            this.prev();
        } else if (event.key === 'ArrowRight') {
            this.next();
        }
    }
}
