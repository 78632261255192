import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableComponent } from 'app/shared/elements/table';
import { AuthorizationService, IdService } from 'app/core';
import { ConfirmDialogComponent } from 'app/shared/elements/confirm-dialog';
import { ContentEditableComponent } from 'app/shared/elements/content-editable';
import { DownloadHelper } from 'app/shared/helpers/download-helper';
import { DruidQueryHelper } from 'app/core/query-builder-helper';
import { InventoryTargetingComponent } from 'app/shared/components/inventory-targeting';
import { LightboxComponent } from 'app/shared/elements/lightbox';
import { NotificationsService } from 'app/core/notifications.service';
import { BehaviorSubject, ReplaySubject, combineLatest, of } from 'rxjs';
import { ThemeService, BackgroundStyle } from 'app/shared/helpers/theme.service';
import { ErrorHelper } from 'app/core/errors/error-helper';
import { Router } from '@angular/router';
import { Package } from 'app/shared/models';
import { AdSlotRepository, CategoryRepository, NewsletterRepository, PackageRepository, PublisherRepository, ReportingQueryRepository, UserRepository } from 'app/core/repositories';
import { map, mergeMap, take } from 'rxjs/operators';
var PackageDetailsComponent = /** @class */ (function () {
    function PackageDetailsComponent(authorization, route, notifications, theme, queryBuilder, reportingQueryRepository, adSlotRepository, categoryRepository, publisherRepository, newsletterRepository, packageRepository, userRepository, id, router) {
        var _this = this;
        this.authorization = authorization;
        this.route = route;
        this.notifications = notifications;
        this.theme = theme;
        this.queryBuilder = queryBuilder;
        this.reportingQueryRepository = reportingQueryRepository;
        this.adSlotRepository = adSlotRepository;
        this.categoryRepository = categoryRepository;
        this.publisherRepository = publisherRepository;
        this.newsletterRepository = newsletterRepository;
        this.packageRepository = packageRepository;
        this.userRepository = userRepository;
        this.id = id;
        this.router = router;
        this.pkg = new BehaviorSubject(null);
        this.deals = new BehaviorSubject([]);
        this.createdBy = new BehaviorSubject(null);
        this.report = new BehaviorSubject({ impressions: 0, adjustedImpressions: 0 });
        this.inventory = new ReplaySubject(1);
        this.tableParams = new BehaviorSubject(null);
        this._inventory = null;
        this.inventoryToAdd = [];
        // Observable for the table.
        this.tableData = combineLatest(this.inventory, this.tableParams)
            .pipe(map(function (_a) {
            var inventory = _a[0], params = _a[1];
            return _this.inventoryAsPage(inventory, params);
        }));
    }
    PackageDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.theme.setBackgroundStyle(BackgroundStyle.GreyStyle);
        this.route.data.subscribe(function (data) {
            if (!data.package['status'] || data.package['status'] === 'deleted') {
                _this.router.navigate(['/inventory-manager/404']);
            }
            data.package['deals'] = data.deals;
            _this.pkg.next(data.package);
            _this.deals.next(data.deals);
            _this.loadCreatedBy(data.package.createdBy);
            _this.loadInventory(data.package);
        });
    };
    PackageDetailsComponent.prototype.ngOnDestroy = function () {
        this.theme.resetBackgroundStyle();
    };
    PackageDetailsComponent.prototype.loadCreatedBy = function (userId) {
        var _this = this;
        this.userRepository.search({ conditions: [{ field: 'hash_id', value: userId }] }).subscribe(function (users) {
            if (users.length > 0) {
                _this.createdBy.next(users[0]);
            }
        });
    };
    PackageDetailsComponent.prototype.loadInventory = function (pkg) {
        var _this = this;
        this.getInventory(pkg).subscribe(function (_a) {
            var publishers = _a.publishers, newsletters = _a.newsletters, adSlots = _a.adSlots, categories = _a.categories;
            _this.loadReport(publishers, newsletters, adSlots, pkg);
            _this.inventory.next([].concat(_this.mapPublishers(publishers, categories), _this.mapNewsletters(newsletters, publishers, categories), _this.mapAdSlots(adSlots, publishers, categories)));
        });
    };
    PackageDetailsComponent.prototype.getInventory = function (pkg) {
        var _this = this;
        return combineLatest(this.getNewsletters(pkg.newsletters), this.getAdSlots(pkg.adSlots), this.getCategories()).pipe(mergeMap(function (_a) {
            var newsletters = _a[0], adSlots = _a[1], categories = _a[2];
            return _this.getPublishers(pkg, newsletters, adSlots)
                .pipe(map(function (publishers) { return ({ publishers: publishers, newsletters: newsletters, adSlots: adSlots, categories: categories }); }));
        }));
    };
    PackageDetailsComponent.prototype.loadReport = function (publishers, newsletters, adSlots, pkg) {
        var _this = this;
        var query = {
            type: 'groupBy',
            dataSource: 'custom_aggregates_exact',
            granularity: 'all',
            dimensions: [],
            aggs: ['Impressions', 'Adjusted Impressions'],
            postAggs: [],
            interval: { type: 'dynamic', value: '30' },
            filters: []
        };
        if (pkg.publishers) {
            query.filters.push({ dimension: 'Publisher', values: publishers.map(function (publisher) { return publisher.refId.toString(); }) });
        }
        if (pkg.adSlots) {
            query.filters.push({ dimension: 'Section', values: adSlots.map(function (adSlot) { return adSlot.refId.toString(); }) });
        }
        if (pkg.newsletters) {
            query.filters.push({ dimension: 'Template', values: newsletters.map(function (newsletter) { return newsletter.refId.toString(); }) });
        }
        this.reportingQueryRepository
            .executeAdHocJSON(this.queryBuilder.buildQuery(query), 'publisher').subscribe(function (rows) {
            if (rows.length > 0) {
                var event_1 = rows[0].event;
                _this.report.next({
                    impressions: event_1['Impressions'],
                    adjustedImpressions: event_1['Adjusted Impressions'],
                });
            }
        });
    };
    PackageDetailsComponent.prototype.getNewsletters = function (newsletters) {
        if (newsletters === null) {
            return of([]);
        }
        return this.newsletterRepository.all({
            conditions: [
                {
                    field: 'id',
                    value: newsletters
                }
            ]
        });
    };
    PackageDetailsComponent.prototype.getAdSlots = function (adSlots) {
        if (adSlots === null) {
            return of([]);
        }
        return this.adSlotRepository.all({
            conditions: [
                {
                    field: 'id',
                    value: adSlots
                }
            ]
        });
    };
    PackageDetailsComponent.prototype.getCategories = function () {
        return this.categoryRepository.all();
    };
    PackageDetailsComponent.prototype.getPublishers = function (pkg, newsletters, adSlots) {
        var publishers = pkg.publishers;
        if (!publishers) {
            publishers = [];
        }
        return this.publisherRepository.all({
            conditions: [{
                    field: 'id',
                    value: [].concat(publishers, newsletters.map(function (newsletter) { return newsletter.publisher; }), adSlots.map(function (adSlot) { return adSlot.publisher; }))
                }]
        });
    };
    PackageDetailsComponent.prototype.mapPublishers = function (publishers, categories) {
        return this.filterPublishers(publishers).map(function (publisher) {
            var category = categories.find(function (category) { return category.id === publisher.category; });
            return {
                id: publisher.id,
                refId: publisher.refId,
                name: publisher.name,
                domain: publisher.domain,
                category: category.name,
                type: 'Publisher'
            };
        });
    };
    PackageDetailsComponent.prototype.mapNewsletters = function (newsletters, publishers, categories) {
        return this.filterNewsletters(newsletters, publishers).map(function (newsletter) {
            var publisher = publishers.find(function (publisher) { return publisher.id === newsletter.publisher; });
            var category = categories.find(function (category) { return category.id === publisher.category; });
            return {
                id: newsletter.id,
                refId: newsletter.refId,
                name: newsletter.name,
                domain: publisher.domain,
                category: category['name'],
                type: 'Newsletter'
            };
        });
    };
    PackageDetailsComponent.prototype.mapAdSlots = function (adSlots, publishers, categories) {
        return this.filterAdSlots(adSlots, publishers).map(function (adSlot) {
            var publisher = publishers.find(function (publisher) { return publisher.id === adSlot.publisher; });
            var category = categories.find(function (category) { return category.id === publisher.category; });
            return {
                id: adSlot.id,
                refId: adSlot.refId,
                name: adSlot.name,
                domain: publisher.domain,
                category: category.name,
                type: 'Ad Slot'
            };
        });
    };
    PackageDetailsComponent.prototype.filterPublishers = function (publishers) {
        var ids = [];
        var pkg = this.pkg.getValue();
        if (pkg !== null) {
            ids = pkg.publishers;
        }
        if (ids === null) {
            return [];
        }
        else {
            return publishers.filter(function (publisher) { return ids.indexOf(publisher.id) > -1; });
        }
    };
    PackageDetailsComponent.prototype.filterNewsletters = function (newsletters, publishers) {
        var ids = [];
        var pkg = this.pkg.getValue();
        if (pkg !== null) {
            ids = pkg.newsletters;
        }
        return newsletters
            .filter(function (newsletter) { return ids.indexOf(newsletter.id) > -1; })
            .filter(function (newsletter) { return publishers.some(function (publisher) { return publisher.id === newsletter.publisher; }); });
    };
    PackageDetailsComponent.prototype.filterAdSlots = function (adSlots, publishers) {
        var ids = [];
        var pkg = this.pkg.getValue();
        if (pkg !== null) {
            ids = pkg.adSlots;
        }
        return adSlots
            .filter(function (adSlot) { return ids.indexOf(adSlot.id) > -1; })
            .filter(function (adSlot) { return publishers.some(function (publisher) { return publisher.id === adSlot.publisher; }); });
    };
    PackageDetailsComponent.prototype.inventoryAsPage = function (inventory, params) {
        var items = inventory;
        if (params !== null) {
            if (params.filters.length > 0) {
                var filters_1 = params.filters.map(function (filter) { return new RegExp(filter.query, 'i'); });
                var filterBy_1 = params.filters.map(function (filter) { return filter.field; });
                items = items.filter(function (item) {
                    return filters_1
                        .every(function (filter) {
                        return filterBy_1.some(function (field) { return filter.test(item[field]); });
                    });
                });
            }
            items = this.sort(items, params.orderBy, params.direction);
        }
        return {
            items: items,
            page: 1,
            pages: 1,
            total: inventory.length
        };
    };
    PackageDetailsComponent.prototype.query = function (params) {
        this.tableParams.next(params);
    };
    // This logic could be refactored into a helper.
    PackageDetailsComponent.prototype.sort = function (inventory, orderBy, direction) {
        var compare = function (a, b) {
            if (a === undefined) {
                return 1;
            }
            if (b === undefined) {
                return -1;
            }
            if (a > b) {
                return -1;
            }
            if (b > a) {
                return 1;
            }
            if (a === b) {
                return 0;
            }
        };
        inventory.sort(function (a, b) { return compare(a[orderBy], b[orderBy]); });
        if (direction === 'DESC') {
            inventory.reverse();
        }
        return inventory;
    };
    PackageDetailsComponent.prototype.inlineUpdate = function (value, key) {
        var _this = this;
        var pkg = this.pkg.getValue();
        if (pkg !== null) {
            if (key === 'name' && value === '') {
                this.editName.reset(pkg.name);
                return;
            }
            if (pkg[key] !== value) {
                pkg[key] = value;
                this.packageRepository.save(pkg).subscribe(function (pkg) {
                    pkg['deals'] = _this.deals.getValue();
                    _this.pkg.next(pkg);
                });
            }
        }
    };
    PackageDetailsComponent.prototype.downloadInventoryAsCSV = function () {
        var pkg = this.pkg.getValue();
        if (pkg === null) {
            return;
        }
        this.inventory.pipe(map(function (inventory) {
            return inventory.map(function (item) { return ({
                inventory: item.name.replace(/^[=+\-@]+/, ''),
                id: item.refId,
                type: item.type,
                domain: item.domain,
                'iab category': item.category
            }); });
        }), take(1)).subscribe(function (inventory) {
            var blob = DownloadHelper.convertToCSV(inventory);
            DownloadHelper.downloadFile(blob, pkg.name + ".csv");
        });
    };
    PackageDetailsComponent.prototype.openAddInventory = function () {
        this.inventoryTargeting.removeAll();
        this.addInventoryDialog.open();
    };
    PackageDetailsComponent.prototype.removeSingleInventory = function (inventory) {
        this.removeMultipleInventory([inventory]);
    };
    PackageDetailsComponent.prototype.removeMultipleInventory = function (inventory) {
        if (!Array.isArray(inventory)) {
            return;
        }
        if (inventory.length < 1) {
            return;
        }
        var pkg = this.pkg.getValue();
        this._inventory = inventory;
        if (pkg['deals'].length > 0 || inventory.length > 1) {
            this.deleteDialog.open();
        }
        else {
            this.removeInventory();
        }
    };
    PackageDetailsComponent.prototype.removeInventory = function () {
        var _this = this;
        if (this._inventory === null) {
            return;
        }
        var inventory = this._inventory;
        this._inventory = null;
        var publishers = inventory.filter(function (item) { return item.type === 'Publisher'; }).map(function (item) { return item.id; });
        var newsletters = inventory.filter(function (item) { return item.type === 'Newsletter'; }).map(function (item) { return item.id; });
        var adSlots = inventory.filter(function (item) { return item.type === 'Ad Slot'; }).map(function (item) { return item.id; });
        var pkg = this.pkg.getValue();
        if (publishers.length > 0) {
            pkg.publishers = pkg.publishers.filter(function (id) { return publishers.indexOf(id) < 0; });
        }
        if (newsletters.length > 0) {
            pkg.newsletters = pkg.newsletters.filter(function (id) { return newsletters.indexOf(id) < 0; });
        }
        if (adSlots.length > 0) {
            pkg.adSlots = pkg.adSlots.filter(function (id) { return adSlots.indexOf(id) < 0; });
        }
        this.packageRepository.save(pkg).subscribe(function (pkg) {
            _this.removeError();
            _this.notifications
                .success("You succesfully removed " + inventory.length + " item" + (inventory.length > 1 ? 's' : '') + ".");
            pkg['deals'] = _this.deals.getValue();
            _this.pkg.next(pkg);
            _this.table.reset();
            _this.loadInventory(pkg);
        }, function (err) {
            _this.removeError();
            // @Todo modify notifications service to handle multiple errors
            _this.errorId = _this.notifications.error(ErrorHelper.getMessage(err.error.errors[0].id));
        });
    };
    PackageDetailsComponent.prototype.addInventory = function () {
        var _a, _b, _c;
        var _this = this;
        var inventory = this.inventoryToAdd;
        var pkg = this.pkg.getValue().clone(Package);
        var publishers = inventory
            .filter(function (item) { return item.type === 'Publisher'; })
            .map(function (item) { return item.key; });
        var newsletters = inventory
            .filter(function (item) { return item.type === 'Newsletter'; })
            .map(function (item) { return item.key; });
        var adSlots = inventory
            .filter(function (item) { return item.type === 'Ad Slot'; })
            .map(function (item) { return item.key; });
        if (pkg.publishers) {
            publishers = publishers.filter(function (id) { return pkg.publishers.indexOf(id) < 0; });
            (_a = pkg.publishers).push.apply(_a, publishers);
        }
        else if (publishers.length > 0) {
            pkg.publishers = publishers;
        }
        if (pkg.newsletters) {
            newsletters = newsletters.filter(function (id) { return pkg.newsletters.indexOf(id) < 0; });
            (_b = pkg.newsletters).push.apply(_b, newsletters);
        }
        else if (newsletters.length > 0) {
            pkg.newsletters = newsletters;
        }
        if (pkg.adSlots) {
            adSlots = adSlots.filter(function (id) { return pkg.adSlots.indexOf(id) < 0; });
            (_c = pkg.adSlots).push.apply(_c, adSlots);
        }
        else if (adSlots.length > 0) {
            pkg.adSlots = adSlots;
        }
        var total = this.inventoryToAdd.length;
        this.packageRepository.save(pkg).subscribe(function (pkg) {
            _this.removeError();
            pkg['deals'] = _this.deals.getValue();
            _this.pkg.next(pkg);
            _this.loadInventory(pkg);
            _this.addInventoryDialog.close();
            _this.notifications.success("You succesfully added " + total + " item" + (total === 1 ? '' : 's') + ".");
        }, function (err) {
            _this.removeError();
            // @Todo modify notifications service to handle multiple errors
            _this.errorId = _this.notifications.error(ErrorHelper.getMessage(err.error.errors[0].id));
        });
    };
    PackageDetailsComponent.prototype.removeError = function () {
        if (typeof this.errorId === 'number') {
            this.notifications.remove(this.errorId);
        }
    };
    PackageDetailsComponent.prototype.inventoryChange = function (inventory) {
        var _this = this;
        var updatedInventory = [];
        inventory.forEach(function (data) {
            // If an item was removed from the inventory component remove from this.inventoryToAdd
            if (typeof (data.refId) === 'undefined') {
                updatedInventory.push(_this.inventoryToAdd.find(function (inv) { return _this.inInventory(inv, data); }));
            }
        });
        if (updatedInventory.length > 0) {
            this.inventoryToAdd = updatedInventory;
        }
        else if (inventory.length === 0) {
            this.inventoryToAdd = [];
        }
    };
    PackageDetailsComponent.prototype.inInventory = function (inventory, data) {
        return inventory.type === data.type && inventory.id === data.id;
    };
    return PackageDetailsComponent;
}());
export { PackageDetailsComponent };
