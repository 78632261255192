import { ElementRef } from '@angular/core';
import { Subject, BehaviorSubject, combineLatest } from 'rxjs';
import { Advertiser, MediaGroup } from 'app/shared/models';
import { AdvertiserRepository, MediaGroupRepository, UserRepository } from 'app/core/repositories';
import { TableComponent } from 'app/shared/elements/table';
import { LightboxnextComponent } from 'app/shared/elements/lightboxnext';
import { NotificationsService, PreferencesService, IdService } from 'app/core';
import { map, debounceTime, switchMap, retry, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { decorateAdvertisersWithNames, getManagersExecutivesForAdvertisers } from 'app/shared/helpers/advertiser-helper';
import { decorateMediaGroupsWithNames, getManagersExecutivesForMediaGroups } from 'app/shared/helpers/media-group-helper';
var ChooseAccountsComponent = /** @class */ (function () {
    function ChooseAccountsComponent(preferences, notifications, advertiserRepository, mediaGroupRepository, userRepository, id) {
        var _this = this;
        this.preferences = preferences;
        this.notifications = notifications;
        this.advertiserRepository = advertiserRepository;
        this.mediaGroupRepository = mediaGroupRepository;
        this.userRepository = userRepository;
        this.id = id;
        this.search = new Subject();
        this.selected = new BehaviorSubject([]);
        this.all = new BehaviorSubject([]);
        this.tableData = new BehaviorSubject({
            items: [],
            total: 0,
            page: 0,
            pages: 0
        });
        this.setDefaultAccounts();
        combineLatest(this.preferences.accounts, this.selected).pipe(map(function (_a) {
            var active = _a[0], selected = _a[1];
            return [].concat(active, selected);
        })).subscribe(this.all);
        combineLatest(this.search.pipe(debounceTime(300), switchMap(function (query) { return _this.findAccounts(query); }), distinctUntilChanged(), retry()), this.all).pipe(map(function (_a) {
            var accounts = _a[0], selected = _a[1];
            var items = accounts.filter(function (account) {
                return selected.findIndex(function (selected) { return selected.refId === account.refId; }) < 0;
            });
            return {
                items: items,
                total: accounts.length,
                page: 1,
                pages: items.length > 0 ? 1 : 0
            };
        })).subscribe(function (tableData) { return _this.tableData.next(tableData); });
    }
    ChooseAccountsComponent.prototype.setDefaultAccounts = function () {
        var _this = this;
        combineLatest(this.findAccounts(''), this.all).pipe(map(function (_a) {
            var accounts = _a[0], selected = _a[1];
            var items = accounts.filter(function (account) {
                return selected.findIndex(function (selected) { return selected.refId === account.refId; }) < 0;
            });
            return {
                items: items,
                total: accounts.length,
                page: 1,
                pages: items.length > 0 ? 1 : 0
            };
        })).subscribe(function (tableData) { return _this.tableData.next(tableData); });
    };
    ChooseAccountsComponent.prototype.query = function (_a) {
        var orderBy = _a.orderBy, direction = _a.direction;
        var tableData = this.tableData.getValue();
        if (tableData.items.length > 0) {
            tableData = {
                items: this.sort(tableData.items, orderBy, direction),
                total: tableData.items.length,
                page: 1,
                pages: tableData.items.length > 0 ? 1 : 0
            };
            this.tableData.next(tableData);
        }
    };
    ChooseAccountsComponent.prototype.sort = function (items, orderBy, direction) {
        var compare = function (a, b) {
            if (a === undefined) {
                return 1;
            }
            if (b === undefined) {
                return -1;
            }
            if (a > b) {
                return -1;
            }
            if (b > a) {
                return 1;
            }
            if (a === b) {
                return 0;
            }
        };
        items.sort(function (a, b) { return compare(a[orderBy], b[orderBy]); });
        if (direction === 'DESC') {
            items.reverse();
        }
        return items;
    };
    ChooseAccountsComponent.prototype.open = function () {
        this.lightbox.show();
    };
    ChooseAccountsComponent.prototype.close = function () {
        this.lightbox.hide();
    };
    ChooseAccountsComponent.prototype.cancel = function () {
        this.lightbox.hide();
    };
    ChooseAccountsComponent.prototype.reset = function () {
        this.input.nativeElement.value = '';
        this.search.next(this.input.nativeElement.value);
        this.clearAll();
    };
    ChooseAccountsComponent.prototype.change = function (query) {
        this.search.next(query);
    };
    ChooseAccountsComponent.prototype.select = function (account) {
        this.selected.next(this.selected.getValue().concat(account));
    };
    ChooseAccountsComponent.prototype.update = function (accounts) {
        this.selected.next(accounts);
    };
    ChooseAccountsComponent.prototype.addAccounts = function () {
        var _this = this;
        var accounts = this.all.getValue();
        var advertisers = accounts.filter(function (account) { return account instanceof Advertiser; });
        var mediaGroups = accounts.filter(function (account) { return account instanceof MediaGroup; });
        this.preferences.setAccounts(advertisers, mediaGroups).subscribe(function (data) {
            _this.notifications.success(_this.selected.getValue().length + " account(s) have been added.");
            _this.close();
        });
    };
    ChooseAccountsComponent.prototype.clearAll = function () {
        this.selected.next([]);
    };
    Object.defineProperty(ChooseAccountsComponent.prototype, "canAddAccounts", {
        get: function () {
            return this.selected.getValue().length > 0;
        },
        enumerable: true,
        configurable: true
    });
    ChooseAccountsComponent.prototype.findAccounts = function (query) {
        return combineLatest(this.findAdvertisers(query), this.findMediaGroups(query)).pipe(map(function (_a) {
            var advertisers = _a[0], mediaGroups = _a[1];
            return [].concat(advertisers, mediaGroups);
        }), map(function (accounts) { return accounts.sort(function (a, b) { return a.name > b.name ? 1 : b.name > a.name ? -1 : 0; }); }));
    };
    ChooseAccountsComponent.prototype.findAdvertisers = function (query) {
        var _this = this;
        var conditions;
        if (query) {
            conditions = [
                {
                    field: 'name',
                    value: query,
                    operator: 'like'
                },
                {
                    mode: 'or',
                    field: 'agencyName',
                    value: query,
                    operator: 'like'
                },
                {
                    mode: 'or',
                    field: 'managerFirstName',
                    value: query,
                    operator: 'like'
                },
                {
                    mode: 'or',
                    field: 'managerLastName',
                    value: query,
                    operator: 'like'
                },
                {
                    mode: 'or',
                    field: 'executiveFirstName',
                    value: query,
                    operator: 'like'
                },
                {
                    mode: 'or',
                    field: 'executiveLastName',
                    value: query,
                    operator: 'like'
                }
            ];
            if (/^\d+$/.test(query)) {
                conditions.push({
                    mode: 'or',
                    field: 'refId',
                    value: query,
                    operator: 'like'
                });
            }
        }
        return this.advertiserRepository.search({ number: 50, conditions: conditions }).pipe(mergeMap(function (advertisers) { return getManagersExecutivesForAdvertisers(advertisers, _this.userRepository); }), mergeMap(decorateAdvertisersWithNames));
    };
    ChooseAccountsComponent.prototype.findMediaGroups = function (query) {
        var _this = this;
        var conditions;
        if (query) {
            conditions = [
                {
                    field: 'name',
                    value: query,
                    operator: 'like',
                    mode: 'or'
                },
                {
                    field: 'managerName',
                    value: query,
                    operator: 'like',
                    mode: 'or'
                }, {
                    field: 'executiveName',
                    value: query,
                    operator: 'like',
                    mode: 'or'
                }
            ];
            if (/^\d+$/.test(query)) {
                conditions.push({
                    field: 'refId',
                    value: query,
                    operator: 'like',
                    mode: 'or'
                });
            }
        }
        return this.mediaGroupRepository.search({ number: 50, conditions: conditions }).pipe(mergeMap(function (mgs) { return getManagersExecutivesForMediaGroups(mgs, _this.userRepository); }), mergeMap(decorateMediaGroupsWithNames));
    };
    return ChooseAccountsComponent;
}());
export { ChooseAccountsComponent };
