import {
    Component,
    Input,
    OnInit,
    AfterViewInit
} from '@angular/core';

import { Chart } from '../chart';

@Component({
    selector: 'timeseries-chart',
    styleUrls: ['./timeseries-chart.styl'],
    template: `<div #chart class="timeseries-chart">
    <div class="legend">
        <div class="chart-legend-item">
            <div class="chart-legend-select">
                <ng-content select=".left"></ng-content>
            </div>
        </div>
        <div class="chart-legend-item">
            <div class="chart-legend-select">
                <ng-content select=".right"></ng-content>
            </div>
        </div>
    </div>
</div>`
})
export class TimeseriesChartComponent extends Chart implements OnInit, AfterViewInit {
    @Input() aggregate: (set: number[]) => number = null;
    @Input() dates: string[];
    @Input() height: number = 280;
    @Input() c3Config: any = {};

    public called = false;

    private metricMapping: any = {
        Clicks: 'Integer',
        Impressions: 'Integer',
        'Impressions (a)': 'Integer',
        PCC: 'Integer',
        Spend: 'Currency',
        'Advertiser Spend': 'Currency',
        CTR: 'Percent',
        'CTR (a)': 'Percent',
        CPC: 'Currency',
        CPA: 'Currency',
        eCPA: 'Currency',
        eCPC: 'Currency',
        'Win Rate': 'Percent'
    };

    private formatMapping: any = {
        Currency: '$,.2f',
        Number: ',.2r',
        Percent: ',.2%',
        Integer: ','
    };

    ngOnInit(): void {
        this.drawGraph();
    }

    drawGraph() {
        if (this.data && this.data[0].length > 1) {
            this.called = true;
            const y = this.data[0][0];
            const y2 = this.data[1][0];
            this.data.unshift(this.dates);
            this.chart = this.generate(Object.assign({
                size: {
                    height: this.height
                },
                point: {
                    show: true,
                    r: 0,
                    focus: {
                        expand: {
                            enabled: true,
                            r: 4
                        }
                    }
                },
                axis: {
                    x: {
                        type: 'timeseries',
                        tick: {
                            format: '%b %e'
                        }
                    },
                    y: {
                        show: true,
                        tick: {
                            format: (value) => {
                                const yMetric = this.data[0][0].toString().trim();
                                return this.d3.format(this.formatMapping[this.metricMapping[yMetric]])(value);
                            },
                            outer: false
                        }
                    },
                    y2: {
                        show: true,
                        tick: {
                            format: (value) => {
                                const y2Metric = this.data[1][0].toString().trim();
                                return this.d3.format(this.formatMapping[this.metricMapping[y2Metric]])(value);
                            },
                            outer: false
                        }
                    }
                },
                grid: {
                    y: {
                        show: true
                    }
                },
                legend: {
                    show: false
                },
                tooltip: {
                    format: {
                        title: this.d3.timeFormat('%b %e, %Y'),
                        value: (value, ratio, id, index) => {
                            const metric = id.toString().trim();

                            if (this.metricMapping[metric]) {
                                return this.d3.format(this.formatMapping[this.metricMapping[metric]])(value);
                            } else {
                                return this.d3.format(this.formatMapping.Number)(value);
                            }
                        }
                    }
                }
            }, this.c3Config), {
                x: 'dates',
                axes: {
                    dates: 'x',
                    [y]: 'y',
                    [y2]: 'y2'
                },
                colors: {
                    [y]: '#14abf9',
                    [y2]: '#9013fe'
                }
            });
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.buildLegend();
    }

    private buildLegend(): void {
        const select = this.d3.select;
        const color = this.chart.color;
        const chart = this.chart;
        const items = select(this.element.nativeElement)
            .selectAll('.chart-legend-item')
            .data(this.chart.data());
        items.insert('div', '.chart-legend-select').attr('class', 'chart-legend-circle')
            .each(function (id) { select(this).style('border-color', color(id)); })
            .on('mouseover', function (data) {
                chart.focus(data.id);
                select(this).style('box-shadow', '0 0 0 1px ' + color(data.id));
            })
            .on('mouseout', function () {
                chart.revert();
                select(this).style('box-shadow', null);
            });
    }

    rebuildLegend() {
        this.d3.select(this.element.nativeElement)
            .selectAll('.chart-legend-circle').remove();
        this.buildLegend();
    }
}
