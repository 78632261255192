import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of, Observable, from, forkJoin } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { AdSlot, Newsletter } from 'app/shared/models';
import { AdSlotRepository } from 'app/core/repositories/ad-slot-repository.service';
import { CategoryRepository } from 'app/core/repositories/category-repository.service';
import { PublisherRepository } from 'app/core/repositories/publisher-repository.service';
import { ProwlerRepository } from 'app/core/repositories/prowler-repository.service';
import { UserRepository } from 'app/core/repositories/user-repository.service';
import { BackendRepository } from './backend-repository';
import moment from 'moment';

@Injectable()
export class NewsletterRepository extends BackendRepository<Newsletter> {

    public constructor(
        http: HttpClient,
        private adSlotRepository: AdSlotRepository,
        private categoryHelper: CategoryRepository,
        private publisherRepository: PublisherRepository,
        private prowlerRepository: ProwlerRepository,
        private userRepository: UserRepository
    ) {
        super(http, '/newsletter', Newsletter);
    }

    /*
       Retrieve the safeRTB tags for the newsletter
       params: {
           id: refid|hash
       }
     */
    public getSafeRTB(id: string): Observable<any> {
        return this.http
            .get(this.url(this.path, 'safe-rtb-tags', id))
            .pipe(map(response => response['output']));
    }

    /**
     * Trigger an export of the newsletter tags in the MS Excel format:
     *
     *     params: {
     *         id: refid|hash
     *     }
     */
    public downloadTags(params: Array<string>) {
        const req = this.http.request('get', this.url(this.path, 'export-tags', ...params), {
            headers: new HttpHeaders({ 'Accept': 'application/vnd.ms-excel' }),
            responseType: 'blob'
        });

        return req;
    }

    public linkDsps(params: any): Observable<any> {
        return this.http
            .post(this.url(this.path, 'link-dsps', params.id), params)
            .pipe(map(response => response['output']));
    }

    public validateNewsletter(params: any): Observable<any> {
        return this.http
            .post(this.url(this.path, 'validate'), params)
            .pipe(map(response => response['output']));
    }

    /*
      Transform a single Newsletter details
      - find all ad-slots for each newsletter
      - lookup and set an IAB category details
      - lookup and set users who created/modified the newsletters
    */
    public getNewsletterDetails(id): Observable<any> {

        const userConditions = {
            conditions: [{
                field: 'hash_id',
                value: []
            }]
        };

        const promisedNewsletter = new Promise( (resolve, reject) => {
            this.get(id)
                .toPromise()
                .then(newsletter => {

                    const adSlotConditions = [{
                        field: 'newsletter',
                        value: [newsletter.id],
                        operator: 'eq'
                    }];

                    if (newsletter.createdBy.length === 32) {
                        userConditions.conditions[0].value.push(newsletter.createdBy);
                    }

                    if (newsletter.modifiedBy.length === 32) {
                        userConditions.conditions[0].value.push(newsletter.modifiedBy);
                    }

                    Promise.all([
                        this.categoryHelper.all().toPromise(),
                        this.adSlotRepository.getBy(adSlotConditions),
                        this.userRepository.search(userConditions).toPromise()
                    ]).then(data => {
                        const categories = data[0];
                        const adSlots    = data[1];
                        const users      = data[2];
                        let userLookup = [];

                        newsletter.adSlots = adSlots;
                        newsletter.primaryCategory = categories.find(cat => {
                            return cat.id === newsletter.category;
                        });

                        users.map(user => {
                            userLookup[user.hashId] = user;
                        });

                        if (newsletter.createdBy in userLookup) {
                            newsletter.createdByUser = userLookup[newsletter.createdBy];
                        }

                        if (newsletter.modifiedBy in userLookup) {
                            newsletter.modifiedByUser = userLookup[newsletter.modifiedBy];
                        }

                        return resolve(newsletter);
                    });
                });
        });

        return from(promisedNewsletter);
    }

    /*
      The search results from /search/newsletter do not contain the ad slots, detailed user information and
      detailes for the IAB categories.
      Use this function to retrieve the Newsletters for the given publisher. The function will:
      - find all active newsletters for the publisher
      - find all ad-slots for each newsletter
      - lookup and set an IAB category details
      - lookup and set users who created/modified the newsletters
    */
    public searchByPublisherId(publisherId): Observable<any> {

        const promisedNewsletters = new Promise( (resolve, reject) => {

            const publisherConditions = {
                conditions: [{
                    field: 'publisher',
                    value: publisherId
                }]
            };

            Promise.all([
                this.categoryHelper.all().toPromise(),
                this.search(publisherConditions).toPromise(),
                this.adSlotRepository.getByPublisherId(publisherId)
            ]).then(data => {

                const categories  = data[0];
                const newsletters = data[1];
                const adSlots     = data[2];
                const userSet = new Set();

                newsletters.forEach(newsletter => {
                        
                    newsletter.primaryCategory = categories.find(cat => {
                        return cat.id === newsletter.category;
                    });

                    newsletter.adSlots = adSlots.filter(slot => slot.newsletter === newsletter.id);

                    if (newsletter.createdBy.length === 32) {
                        userSet.add(newsletter.createdBy);
                    }
                    if (newsletter.modifiedBy.length === 32) {
                        userSet.add(newsletter.modifiedBy);
                    }
                });

                const userConditions = {
                    conditions: [
                        {
                            field: 'hash_id',
                            value: Array.from(userSet)
                        }
                    ],
                };

                this.userRepository
                    .search(userConditions)
                    .toPromise()
                    .then(users => {

                        let userLookup = [];
                        users.map(user => {
                            userLookup[user.hashId] = user;
                        });

                        newsletters.forEach (letter => {

                            if (letter.createdBy in userLookup) {
                                letter.createdByUser = userLookup[letter.createdBy];
                            }

                            if (letter.modifiedBy in userLookup) {
                                letter.modifiedByUser = userLookup[letter.modifiedBy];
                            }
                        });

                        resolve(newsletters);
                    });

                resolve(newsletters);
            });
        });

        return from(promisedNewsletters);
    }

    shopByStrategy(strategyId: string, params: any): Observable<{ items: Newsletter[]; page: number; pages: any; total: any; }> {
        return forkJoin(
            this.http.post(this.url("/search/newsletter/shop-by-strategy/" + strategyId), this.sanitizeQuery(params))
        ).pipe(
            map(([data]) => {
                return {
                    items: data['output'].map((item) => this.build(item)),
                    page: 1,
                    pages: data['pages'],
                    total: data['total'],
                };
            })
        );
    }
}
