import { EMPTY, Subject, of, concat } from 'rxjs';
import { pairwise, delay, concatMap, map, repeat, distinctUntilChanged, startWith } from 'rxjs/operators';
import { AuthenticationService } from 'app/core/authentication.service';
import { BulkOperationRepository } from 'app/core/repositories/bulk-operation-repository.service';
import { Status } from 'app/shared/models/bulk-request';
var BulkOperationStatusToastService = /** @class */ (function () {
    function BulkOperationStatusToastService(authentication, bulkOperationRepo) {
        var _this = this;
        this.authentication = authentication;
        this.bulkOperationRepo = bulkOperationRepo;
        this.show$ = new Subject();
        this.remove$ = new Subject();
        this.data$ = new Subject();
        this.user = null;
        this.delayed$ = EMPTY.pipe(delay(5000));
        this.authentication.currentUser.subscribe(function (user) { return _this.user = user; });
        this.getStatusData().subscribe(function (status) { return _this.createStatusToast(status); });
        this.fetchStatusData();
    }
    BulkOperationStatusToastService.prototype.getStatus = function (_a) {
        var prevData = _a[0], currData = _a[1];
        if (prevData.length <= 0 || currData.length <= 0) {
            return Status.Pending;
        }
        var previousDataCount = prevData.reduce(function (acc, batch) {
            acc.failedOperationCount += batch.completed_with_failures;
            acc.failedBatchCount += batch.status === Status.Failed ? 1 : 0;
            acc.successBatchCount += batch.status === Status.Success ? 1 : 0;
            return acc;
        }, {
            failedOperationCount: 0,
            failedBatchCount: 0,
            successBatchCount: 0,
        });
        var currentDataCount = currData.reduce(function (acc, batch) {
            acc.failedOperationCount += batch.completed_with_failures;
            acc.failedBatchCount += batch.status === Status.Failed ? 1 : 0;
            acc.successBatchCount += batch.status === Status.Success ? 1 : 0;
            acc.pendingBatchCount += batch.status === Status.Pending ? 1 : 0;
            return acc;
        }, {
            failedOperationCount: 0,
            failedBatchCount: 0,
            successBatchCount: 0,
            pendingBatchCount: 0,
        });
        if ((currentDataCount.failedOperationCount > previousDataCount.failedOperationCount) || (currentDataCount.failedBatchCount > previousDataCount.failedBatchCount)) {
            return Status.Failed;
        }
        if (!currentDataCount.pendingBatchCount || (currentDataCount.successBatchCount > previousDataCount.successBatchCount)) {
            return Status.Success;
        }
        return Status.Pending;
    };
    BulkOperationStatusToastService.prototype.createStatusToast = function (data) {
        this.show$.next(data);
    };
    BulkOperationStatusToastService.prototype.removeStatusToast = function () {
        this.remove$.next();
    };
    BulkOperationStatusToastService.prototype.fetchStatusData = function () {
        var _this = this;
        if (this.fetchData === undefined || this.fetchData.closed) {
            var twoHoursAgo_1 = new Date(Date.now() - 1000 * 60 * 60 * 2).toISOString();
            var params = {
                conditions: [{
                        field: 'user',
                        value: this.user.id
                    }]
            };
            this.fetchData = this.bulkOperationRepo.getStatusByUser(params).pipe(map(function (response) { return response.items.filter(function (bulkOperation) { return bulkOperation.created_at > twoHoursAgo_1; }); }), map(function (bulkOperations) { return bulkOperations.map(function (bulkOperation) { return ({
                status: bulkOperation.status,
                completed_successfully: bulkOperation.completed_successfully,
                completed_with_failures: bulkOperation.completed_with_failures
            }); }); }), concatMap(function (v) { return concat(of(v), _this.delayed$); }), repeat(), startWith([]), pairwise(), distinctUntilChanged(function (prev, curr) { return JSON.stringify(prev) === JSON.stringify(curr); })).subscribe(function (_a) {
                var prevData = _a[0], currData = _a[1];
                var shouldContinue = currData.some(function (bulkOperation) { return bulkOperation.status === Status.Pending; });
                if (!shouldContinue) {
                    _this.stop();
                    if (prevData.length > 0) {
                        _this.data$.next([prevData, currData]);
                    }
                }
                else {
                    _this.data$.next([prevData, currData]);
                }
            });
        }
    };
    BulkOperationStatusToastService.prototype.getStatusData = function () {
        var _this = this;
        return this.data$.pipe(map(function (data) { return _this.getStatus([data[0], data[1]]); }), distinctUntilChanged(function (prev, curr) { return prev === curr && curr === Status.Pending; }));
    };
    BulkOperationStatusToastService.prototype.stop = function () {
        this.fetchData.unsubscribe();
    };
    return BulkOperationStatusToastService;
}());
export { BulkOperationStatusToastService };
