import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { User, Session, AccessList, Role } from 'app/shared/models';
import { BackendResourceRepository } from './backend-resource-repository';
import { map } from 'rxjs/operators';
var UserRepository = /** @class */ (function (_super) {
    tslib_1.__extends(UserRepository, _super);
    function UserRepository(http) {
        return _super.call(this, http, '/user', User) || this;
    }
    UserRepository.prototype.get = function (id) {
        var _this = this;
        if (typeof id === 'number') {
            id = id.toString();
        }
        if (typeof id !== 'string') {
            return throwError(new Error('Invalid parameter. Parameter must be a string or a number.'));
        }
        if (id.length < 1) {
            return throwError(new Error('Invalid parameter. Parameter must have length at least 1.'));
        }
        return this.http.get(this.url('/users', id)).pipe(map(function (response) { return _this.build(response['data']); }));
    };
    UserRepository.prototype.getFromLSD = function (id) {
        var _this = this;
        if (typeof id === 'number') {
            id = id.toString();
        }
        if (typeof id !== 'string') {
            return throwError(new Error('Invalid parameter. Parameter must be a string or a number.'));
        }
        if (id.length < 1) {
            return throwError(new Error('Invalid parameter. Parameter must have length at least 1.'));
        }
        return this.http.get(this.url('/user', id)).pipe(map(function (response) { return _this.build(response['output']); }));
    };
    UserRepository.prototype.save = function (instance) {
        if (instance.hashId) {
            instance.id = instance.hashId;
        }
        return _super.prototype.save.call(this, instance);
    };
    UserRepository.prototype.rawSearch = function (query, uri) {
        return _super.prototype.rawSearch.call(this, query, '/users/search');
    };
    UserRepository.prototype.all = function (query) {
        if (query === void 0) { query = {}; }
        console.error('Not yet implemented');
        return of([]);
    };
    UserRepository.prototype.searchByIds = function (ids) {
        if (Array.isArray(ids) && ids.length === 0) {
            return of([]);
        }
        return this.search({
            conditions: [
                { field: 'hash_id', value: ids }
            ]
        });
    };
    UserRepository.prototype.syncToTessellate = function (emailOrId) {
        return this.http.post(this.url('/users', emailOrId, 'sync'), {})
            .pipe(map(function (response) { return response['data']; }));
    };
    UserRepository.prototype.syncDeleteToTessellate = function (emailOrId) {
        return this.http.delete(this.url('/users', emailOrId), {}).pipe();
    };
    UserRepository.prototype.syncRestoreToTessellate = function (emailOrId) {
        return this.http.put(this.url('/users', emailOrId, 'restore'), {})
            .pipe(map(function (response) { return response['data']; }));
    };
    UserRepository.prototype.resetMultiFactorAuth = function (emailOrId) {
        var _this = this;
        return this.http.delete(this.url('/users', emailOrId, 'two-factor-authentication'), {})
            .pipe(map(function (response) { return _this.build(response['data']); }));
    };
    UserRepository.prototype.getMeta = function (id) {
        return this.http.get(this.url('/user', id))
            .pipe(map(function (response) { return response['output'].meta; }));
    };
    UserRepository.prototype.saveMeta = function (id, meta) {
        return this.http.post(this.url('/user/save-meta', id), meta)
            .pipe(map(function (response) { return response['output']; }));
    };
    UserRepository.prototype.undelete = function (user) {
        var _this = this;
        return this.http
            .post(this.url("/user/undelete", user.hashId), {
            version: user.version,
        })
            .pipe(map(function (response) { return _this.build(response["output"]); }));
    };
    UserRepository.prototype.loginAs = function (id) {
        var data = { userId: id };
        return this.http.post(this.url('/auth/login-as'), data)
            .pipe(map(function (response) { return new Session(response); }));
    };
    UserRepository.prototype.getAccessList = function (id) {
        return this.http.get(this.url('/rbac/user', id.toString()))
            .pipe(map(function (response) { return UserRepository.unwrapAccessList(response); }));
    };
    UserRepository.unwrapAccessList = function (response) {
        var value = response.output;
        return new AccessList(Array.isArray(value) ? {} : value);
    };
    UserRepository.prototype.saveUserRoles = function (id, roles) {
        return this.http.post(this.url('/rbac/user/save', id), roles);
    };
    UserRepository.prototype.getRoles = function () {
        return this.http.get(this.url('/rbac/role'))
            .pipe(map(function (response) { return response['output'].map(function (data) { return new Role(data); }); }));
    };
    UserRepository.prototype.getAdmins = function () {
        return this.http.get(this.url('/rbac/user/admins'))
            .pipe(map(function (response) { return response['output']; }));
    };
    UserRepository.prototype.getManagers = function () {
        return this.http.get(this.url('/rbac/user/managers'))
            .pipe(map(function (response) { return response['output']; }));
    };
    UserRepository.prototype.getFromCache = function (id) {
        if (id === '1') {
            return of({
                get name() {
                    return 'LiveIntent System';
                }
            });
        }
        return _super.prototype.getFromCache.call(this, id);
    };
    UserRepository.prototype.getCacheKey = function (model) {
        return model.hashId;
    };
    return UserRepository;
}(BackendResourceRepository));
export { UserRepository };
