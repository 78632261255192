import {
    Component,
    ViewChild,
    ElementRef,
    OnInit,
    Input,
    EventEmitter,
    Output
} from '@angular/core';
import { generate } from 'c3';

@Component({
    selector: 'pie-chart',
    templateUrl: './pie-chart.html'
})
export class PieChartComponent implements OnInit {
    @ViewChild('container', { static: true }) container: ElementRef;

    @Output() sectionSelected: EventEmitter<any> = new EventEmitter<any>();

    chart;
    _columns;
    total;

    @Input() set columns(val) {
        this._columns = val;
        if (val) {
            this.total = val.reduce((sum, item) => sum + item[1], 0);
        }
    }

    get columns(): [string, number][] {
        return this._columns;
    }

    clickedSection(data) {
        this.sectionSelected.emit(data);
    }

    ngOnInit(): void {
        const select = (data) => {
            this.clickedSection(data);
        };
        this.chart = generate({
            bindto: this.container.nativeElement,
            data: {
                type: 'pie',
                columns: this.columns,
                onclick: function(d, i) { select(d); }
            },
            color: {
                pattern: [ '#14abf9', '#fc7709', '#ffa710', '#ba358a',
                    '#bf7eaa', '#c03247', '#ef3849', '#bec4c8', '#f4b526', '#235fac']
            },
            pie: {
                label: {
                    show: false
                }
            },
            legend: {
                show: false
            },
            tooltip: {
                show: true,
                format: {
                    name: function(name, ratio, id, index) {
                        return name.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ');
                    },
                    value: function (value, ratio, id, index) {
                        return `${(ratio * 100).toPrecision(3)}%
                                ${value.toLocaleString()}`;
                    }
                }
            }
        });
    }

    highlight(id: string) {
        this.chart.focus(id);
    }

    color(id: string) {
        return this.chart ? this.chart.data.colors()[id] : '';
    }
}
