import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthorizationService } from 'app/core/authorization.service';
import { ConfirmDialogComponent } from 'app/shared/elements/confirm-dialog';
import { LightboxComponent } from 'app/shared/elements/lightbox';
import { ModalComponent } from 'app/shared/elements/modal';
import { Publisher } from 'app/shared/models';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { ErrorHelper } from 'app/core/errors/error-helper';
import { IdService } from 'app/core/id.service';
import { NotificationsService } from 'app/core/notifications.service';
import { AdvertiserRepository, SamovarAudienceRepository, CategoryRepository, CookieSyncPartnerRepository, ESPRepository, GlobalBlocklistRepository, MediaGroupRepository, PublisherIdentifierRepository, PublisherRepository, UserRepository } from 'app/core/repositories';
import options from './options.json';
import { ActivatedRoute, Router } from '@angular/router';
import validationErrors from 'app/core/errors/validation-errors.json';
import { YieldManagement } from 'app/shared/services/yield-management.service';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { PublisherClientTypes } from 'app/shared/models/publisher';
import { Flag, LaunchDarklyService } from 'app/core/launch-darkly.service';
import PUBLISHER_TYPES from "app/shared/models/publisher-types.json";
var LIVERAMP_USER_PARTNER = 'ca7d5f52aadf61794896c87f1d1e9d9c';
var NO_NAME_USER_PARTNER = '93d2344a67c111e18dd712313e003071';
var AUDIENCE_ID_TO_PREFIX = new Map();
var PublisherFormComponent = /** @class */ (function () {
    function PublisherFormComponent(yieldManagement, userRepository, samovarAudienceRepository, id, route, router, auth, advertiserRepository, cookieSyncPartnerRepository, espRepository, mediaGroupRepository, publisherRepository, publisherIdentifierRepository, categoryRepository, globalBlocklistRepository, launchDarklyService, notifications) {
        this.yieldManagement = yieldManagement;
        this.userRepository = userRepository;
        this.samovarAudienceRepository = samovarAudienceRepository;
        this.id = id;
        this.route = route;
        this.router = router;
        this.auth = auth;
        this.advertiserRepository = advertiserRepository;
        this.cookieSyncPartnerRepository = cookieSyncPartnerRepository;
        this.espRepository = espRepository;
        this.mediaGroupRepository = mediaGroupRepository;
        this.publisherRepository = publisherRepository;
        this.publisherIdentifierRepository = publisherIdentifierRepository;
        this.categoryRepository = categoryRepository;
        this.globalBlocklistRepository = globalBlocklistRepository;
        this.launchDarklyService = launchDarklyService;
        this.notifications = notifications;
        this.modal = null;
        this.form = null;
        this.submitButton = null;
        this.adBuilder = null;
        this.publisherConfirmation = null;
        this.saved = new EventEmitter();
        this.validationErrors = validationErrors;
        this.isEdit = false;
        this.categories = [];
        this.blocklists = [];
        this.requiredErrorText = 'Oops! This is a required field.';
        this.invalidDomainText = 'Please enter a valid domain, eg. domain.com.';
        this.invalidUrlText = 'Please enter a valid URL.';
        this.mediaGroups$ = new BehaviorSubject([]);
        this.mediaGroup$ = new BehaviorSubject('');
        this.searchMediaGroup$ = new BehaviorSubject('');
        this.errorDetails = options.errorDetails;
        this.espOptions = new ReplaySubject(1);
        this.espLabel = new ReplaySubject(1);
        this.mediaGroup = null;
        this.showKVPs = false;
        this.showPublisherLabel = false;
        this.showAdvanced = false;
        this.showPrivacy = false;
        this.cname = '';
        this.protocol = 'https://';
        this.protocolOptions = options.protocolOptions;
        this._useDifferentSources = false;
        this._useDifferentSourcesAdvertisers = false;
        this._useDifferentSourcesExternalAdvertisers = false;
        this.userMatchPartners = [];
        this.targetedUserMatchPartners = [];
        this.cookieSyncPartners = [];
        this.gdprComplianceOptions = JSON.parse(JSON.stringify(options.gdprCompliance));
        this.showGlobalBlocklist = true;
        this.globalBlocklist = [];
        this.publisherIdentifierOptions = new BehaviorSubject([]);
        //AM and AE
        this.accountMgrName = '';
        this.accountExecName = '';
        this.errorHelper = null;
        this.confirmationErrorHelper = null;
        this.errors = [];
        this.genericError = false;
        this.publisher$ = new BehaviorSubject(null);
        this.userOptions = [];
        this.audienceOptions$ = new ReplaySubject(1);
        this.matchingPublishers = [];
        this.isMouseHover = false;
        this.esp$ = new BehaviorSubject('');
        this.emailTagReplacement$ = new BehaviorSubject('');
        this.emailTagType$ = new BehaviorSubject('');
        this.placementTagReplacement$ = new BehaviorSubject('');
        this.listTagReplacement$ = new BehaviorSubject('');
        this.lceeTagReplacement$ = new BehaviorSubject('');
        this.lceeKeyReplacement$ = new BehaviorSubject('');
        this.rolloutUamEnhancements = false;
        // Client Types
        this.clientTypes = [
            { label: 'Monetization', index: PublisherClientTypes.Monetization },
            { label: 'HIRO', index: PublisherClientTypes.Hiro },
            { label: 'Identity', index: PublisherClientTypes.Identity },
            { label: 'Email Intelligence', index: PublisherClientTypes.EmailIntelligence },
        ];
        this.PUBLISHER_TYPES = PUBLISHER_TYPES;
        this.ADVERTISER_UNIQUE_MODES = [
            PUBLISHER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER,
            PUBLISHER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER_DISPLAY,
            PUBLISHER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER_NATIVE
        ];
        this.EXTERNAL_ADVERTISER_UNIQUE_MODES = [
            PUBLISHER_TYPES.SSP_CONTROL.UNIQUE_ADS.EXTERNAL_ADVERTISERS,
            PUBLISHER_TYPES.SSP_CONTROL.UNIQUE_ADS.EXTERNAL_ADVERTISERS_DISPLAY,
            PUBLISHER_TYPES.SSP_CONTROL.UNIQUE_ADS.EXTERNAL_ADVERTISERS_NATIVE
        ];
        this.errorHelper = new ErrorHelper();
        this.confirmationErrorHelper = new ErrorHelper();
    }
    PublisherFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.modal.open();
        this.route.paramMap.subscribe(function (params) {
            if (params.has('redirect')) {
                _this.redirect = atob(params.get('redirect'));
            }
        });
        this.route.data.subscribe(function (data) {
            _this.publisher = data.publisher;
            _this.publisher$.next(_this.publisher);
            if (_this.publisher.id) {
                _this.isEdit = true;
            }
            if (_this.publisher.mediaGroupObj) {
                _this.mediaGroup = _this.publisher.mediaGroupObj;
                _this.changeMediaGroup(_this.mediaGroup);
            }
            if (!_this.publisher.clientTypes) {
                _this.publisher.clientTypes = [
                    PublisherClientTypes.Monetization
                ];
            }
            _this.open();
        });
        this.mediaGroup$.subscribe(function (mediaGroup) {
            if (mediaGroup) {
                _this.loadPublisherIdentifiers();
                _this.populatePublisherDetails(mediaGroup);
                _this.updateGdprCompliance(mediaGroup);
            }
        });
        this.modal.closed.subscribe(function () { return _this.reset(); });
        this.launchDarklyService
            .getVariation(Flag.RolloutUAMEnhancements)
            .pipe(distinctUntilChanged())
            .subscribe(function (rolloutUamEnhancements) { return _this.rolloutUamEnhancements = rolloutUamEnhancements; });
    };
    PublisherFormComponent.prototype.ngOnDestroy = function () {
        this.yieldManagement.destroy();
    };
    PublisherFormComponent.prototype.onPublisherNameChange = function (value) {
        var _this = this;
        var publisherName = value ? value.trim() : '';
        if (publisherName) {
            var query = {
                conditions: [
                    {
                        mode: 'and',
                        operator: 'eq',
                        field: 'name',
                        value: publisherName
                    }
                ],
                orderBy: 'created',
                sort: 'DESC'
            };
            this.publisherRepository.search(query).subscribe(function (publishers) {
                _this.matchingPublishers = publishers;
            });
        }
        else {
            this.matchingPublishers = [];
        }
    };
    /**
     * Initialize this component by loading any necessary data
     * and register any subscribers with their events.
     */
    PublisherFormComponent.prototype.init = function () {
        var _this = this;
        this.loadIABCategories();
        this.loadEsps();
        this.loadCookieSyncPartners();
        this.loadBlocklists();
        this.showPublisherFields();
        if (this.auth.isInternalUser) {
            combineLatest(this.userRepository.getAdmins(), this.userRepository.getManagers()).pipe(map(function (_a) {
                var admins = _a[0], managers = _a[1];
                var users = admins.concat(managers);
                _this.userOptions = users.map(function (user) { return _this.convertUserToOption(user); });
            })).subscribe();
        }
        if (!this.publisher.id) {
            // ESP field settings
            this.publisher.esp = null;
            this.publisher.emailTagReplacement = null;
            this.publisher.emailTagType = null;
            this.publisher.placementTagReplacement = null;
            this.publisher.listTagReplacement = null;
            this.publisher.lceeKeyReplacement = null;
            this.publisher.lceeTagReplacement = null;
        }
        this.esp$.next(this.publisher.esp);
        this.emailTagReplacement$.next(this.publisher.emailTagReplacement);
        this.emailTagType$.next(this.publisher.emailTagType);
        this.placementTagReplacement$.next(this.publisher.placementTagReplacement);
        this.listTagReplacement$.next(this.publisher.listTagReplacement);
        this.lceeKeyReplacement$.next(this.publisher.lceeKeyReplacement);
        this.lceeTagReplacement$.next(this.publisher.lceeTagReplacement);
    };
    /**
     * Open the form modal for create or edit.
     * @param {any} publisher
     */
    PublisherFormComponent.prototype.open = function (publisher) {
        var _this = this;
        if (publisher === void 0) { publisher = {}; }
        this.init();
        var sspControl = {
            rtbAllow: null,
            exchangeAllow: true,
            exchangeFloor: null,
        };
        if (!this.isEdit) {
            this.initMediaGroup();
            publisher.domainTargetingType = 'exclude';
            publisher.inheritConsentControls = true;
            this.publisher = new Publisher(JSON.parse(JSON.stringify(publisher)));
            this.publisher.sspControl = sspControl;
        }
        else {
            this.protocol = this.publisher.getTagsProtocol();
            this.cname = this.publisher.getCname();
            if (!this.auth.isInternalUser) {
                this.espOptions.subscribe(function (esps) {
                    var publisherEsp = esps.find(function (esp) { return _this.publisher.esp === esp.value; });
                    if (publisherEsp) {
                        _this.espLabel.next(publisherEsp.label);
                    }
                });
                this.userRepository.search({
                    conditions: [
                        {
                            field: 'hash_id',
                            value: [this.publisher.executive, this.publisher.admin]
                        }
                    ]
                }).subscribe(function (users) {
                    users.forEach(function (user) {
                        if (user.hashId === _this.publisher.executive) {
                            _this.accountExecName = user.name;
                        }
                        else {
                            _this.accountMgrName = user.name;
                        }
                    });
                });
            }
            if (this.publisher.sspControl.uniqueAds !== 'off'
                && this.publisher.sspControl.uniqueAds !== 'inherit') {
                this._useDifferentSources = true;
            }
            if (Array.isArray(this.publisher.userMatchPartners)) {
                this.targetedUserMatchPartners =
                    this.cookieSyncPartners
                        .filter(function (userMatchPartner) {
                        return _this.publisher.userMatchPartners.indexOf(userMatchPartner.id) > -1;
                    });
            }
        }
    };
    PublisherFormComponent.prototype.inputChangeMediaGroup = function (search) {
        this.searchMediaGroup$.next(search);
    };
    PublisherFormComponent.prototype.changeMediaGroup = function (mediaGroup) {
        var _this = this;
        if (mediaGroup) {
            this.mediaGroupRepository.get(mediaGroup.id).subscribe(function (mediaGroupObj) {
                _this.mediaGroup = mediaGroupObj;
                _this.publisher.mediaGroup = mediaGroupObj.id;
                _this.mediaGroup$.next(_this.mediaGroup);
            });
        }
        else {
            this.mediaGroup$.next(null);
            this.mediaGroup = null;
        }
    };
    PublisherFormComponent.prototype.updateGdprCompliance = function (mediaGroup) {
        var label = 'Inherit (';
        var value = this.gdprComplianceOptions.find(function (option) { return option.value === mediaGroup.gdprCompliance; });
        this.gdprComplianceOptions[3].label = label + value.label + ')';
    };
    /**
     * Show all fields that are not null on the publisher
     */
    PublisherFormComponent.prototype.showPublisherFields = function () {
        this.showPublisherLabel = typeof this.publisher.externalId === 'string';
        this.showKVPs = this.publisher.keyValues.length > 0;
    };
    /**
     * Load the list of primary IAB categories.
     */
    PublisherFormComponent.prototype.loadIABCategories = function () {
        var _this = this;
        this.categoryRepository.getPrimaryCategories()
            .pipe(function (categories) { return _this.categoryRepository.convertCategoriesToOptions(categories); })
            .subscribe(function (categories) { return _this.categories = categories; });
    };
    Object.defineProperty(PublisherFormComponent.prototype, "categoryLabel", {
        get: function () {
            var _this = this;
            var categoryOption = this.categories.find(function (option) { return _this.publisher.category === option.value; });
            if (categoryOption) {
                return categoryOption.label;
            }
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Populate the AM and AE from the mediaGroup for a new publisher
     * @param mediaGroup
     */
    PublisherFormComponent.prototype.populatePublisherDetails = function (mediaGroup) {
        this.publisher.admin = mediaGroup.manager;
        this.publisher.executive = mediaGroup.executive;
        this.accountExecName = mediaGroup.executiveName;
        this.accountMgrName = mediaGroup.managerName;
    };
    /**
     * Load all supported Esp's for the dropdown.
     */
    PublisherFormComponent.prototype.loadEsps = function () {
        var _this = this;
        this.espSubscription = this.espRepository.all()
            .pipe(map(function (esps) { return esps.map(function (esp) { return ({
            key: esp.id,
            value: esp.id,
            label: esp.name
        }); }); })).subscribe(function (esps) {
            esps.push({ key: 'other', value: 'other', label: 'Other ESP' });
            _this.espOptions.next(esps);
        });
    };
    /**
     * Load all supported publisher identifiers
     */
    PublisherFormComponent.prototype.loadPublisherIdentifiers = function () {
        var _this = this;
        this.publisherIdentifierRepository.all()
            .pipe(map(function (pubId) { return pubId.map(function (pi) {
            var option = {
                key: pi.refId,
                label: pi.name,
                value: false
            };
            if (_this.publisher.inheritConsentControls && _this.mediaGroup.consentControls) {
                var mgConsent = _this.mediaGroup.consentControls.find(function (cc) { return cc.id === option.key; });
                if (mgConsent) {
                    option.value = mgConsent.allow;
                }
            }
            else if (_this.publisher.consentControls) {
                var pubConsent = _this.publisher.consentControls.find(function (cc) { return cc.id === option.key; });
                if (pubConsent) {
                    option.value = pubConsent.allow;
                }
            }
            return option;
        }); })).subscribe(function (options) {
            _this.publisherIdentifierOptions.next(options);
        });
    };
    /**
     * Load blocklists
     */
    PublisherFormComponent.prototype.loadBlocklists = function () {
        var _this = this;
        this.globalBlocklistRepository.all()
            .pipe(function (blocklists) { return _this.globalBlocklistRepository.convertGlobalBlocklistToOptions(blocklists); })
            .subscribe(function (blocklists) { return _this.blocklists = blocklists; });
    };
    /**
     * Load Cookie Synch Partners
     */
    PublisherFormComponent.prototype.loadCookieSyncPartners = function () {
        var _this = this;
        this.cookieSyncPartnerRepository.search().toPromise()
            .then(function (cookieSyncPartners) {
            _this.cookieSyncPartners = cookieSyncPartners;
            _this.userMatchPartners = cookieSyncPartners
                .filter(function (cookieSyncPartner) { return cookieSyncPartner.cookieSyncType === 'user_matching'; })
                .map(function (cookieSyncPartner) {
                return {
                    key: cookieSyncPartner.id,
                    value: cookieSyncPartner.id,
                    label: cookieSyncPartner.name
                };
            });
        });
    };
    /**
     * Respond to a progressive link being expanded or collapsed.
     * @param {string}  inputName
     * @param {boolean} expanded
     */
    PublisherFormComponent.prototype.handleExpandedChange = function (inputName, expanded) {
        if (expanded === false) {
            if (inputName === 'publisherLabel') {
                this.publisher.externalId = null;
            }
            this.publisher[inputName] = [];
        }
    };
    /**
     * Save the publisher to the api.
     */
    PublisherFormComponent.prototype.confirmPublisher = function () {
        var _this = this;
        if (typeof this.noteId === 'number') {
            this.notifications.remove(this.noteId);
        }
        this.errorHelper.resetAllErrors();
        this.confirmationErrorHelper.resetAllErrors();
        this.publisher.mediaGroup = this.mediaGroup.id;
        this.publisher.tagsUrlPrefix = this.protocol + this.cname;
        var publisher = this.publisher.serialize();
        this.submitButton.load();
        this.publisherRepository.confirm(publisher)
            .toPromise()
            .then(function () {
            _this.submitButton.reset();
            _this.savePublisher();
        })
            .catch(function (err) {
            if (_this.auth.isInternalUser || _this.auth.isMediaGroupManagePublisher) {
                _this.confirmationErrorHelper.loadBackEndErrors(err.error.errors);
                _this.publisherConfirmation.open();
            }
            else {
                _this.errorHelper.loadBackEndErrors(err.error.errors);
                var errorList = _this.errorHelper.errors;
                _this.displayErrorMessage(errorList);
            }
            _this.submitButton.reset();
        });
    };
    PublisherFormComponent.prototype.savePublisher = function () {
        var _this = this;
        this.modal.showLoading();
        this.submitButton.load();
        this.publisherRepository.save(this.publisher)
            .toPromise()
            .then(function (publisher) {
            _this.publisher = publisher;
        })
            .then(function () {
            _this.modal.hideLoading();
            if (_this.isEdit) {
                _this.notifications.success("<strong>Woo hoo!</strong> You have successfully updated details for " + _this.publisher.name + ".");
            }
            else {
                _this.notifications.success("The publisher <strong>" + _this.publisher.name + "</strong> was created successfully.");
            }
            _this.modal.close(_this.publisher.clone(Publisher));
            _this.submitButton.reset();
        })
            .catch(function (errResponse) {
            _this.submitButton.reset();
            _this.handleErrors(errResponse);
        });
    };
    /**
     * Save User Match Partners
     * @param {string} publisherId
     * @return Promise
     */
    PublisherFormComponent.prototype.saveUserMatchPartners = function (publisherId) {
        return this.publisherRepository.linkUserMatchPartners(publisherId, {
            version: this.publisher.version,
            // Hard coded user match partner will be replaced with user inputs later
            partners: [LIVERAMP_USER_PARTNER, NO_NAME_USER_PARTNER]
        }).toPromise();
    };
    Object.defineProperty(PublisherFormComponent.prototype, "useDifferentSources", {
        get: function () {
            return this._useDifferentSources;
        },
        set: function (value) {
            this.publisher.sspControl.uniqueAds = value ? null : PUBLISHER_TYPES.SSP_CONTROL.UNIQUE_ADS.OFF;
            this._useDifferentSources = value;
            if (!this.publisher.sspControl.uniqueAds) {
                this.publisher.sspControl.uniqueAds = PUBLISHER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER; // Default if no previous selection
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PublisherFormComponent.prototype, "useDifferentSourcesAdvertisers", {
        get: function () {
            return this.ADVERTISER_UNIQUE_MODES.includes(this.publisher.sspControl.uniqueAds);
        },
        set: function (value) {
            this._useDifferentSourcesAdvertisers = value;
            this._useDifferentSourcesExternalAdvertisers = null;
            if (value) {
                // Preserve the user's choice if it's already set
                if (!this.publisher.sspControl.uniqueAds ||
                    !this.ADVERTISER_UNIQUE_MODES.includes(this.publisher.sspControl.uniqueAds)) {
                    this.publisher.sspControl.uniqueAds = PUBLISHER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER; // Default if no previous selection
                }
            }
            else {
                this.publisher.sspControl.uniqueAds = PUBLISHER_TYPES.SSP_CONTROL.UNIQUE_ADS.OFF;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PublisherFormComponent.prototype, "useDifferentSourcesExternalAdvertisers", {
        get: function () {
            return this.EXTERNAL_ADVERTISER_UNIQUE_MODES.includes(this.publisher.sspControl.uniqueAds);
        },
        set: function (value) {
            this._useDifferentSourcesExternalAdvertisers = value;
            this._useDifferentSourcesAdvertisers = null;
            if (value) {
                // Preserve the user's choice if it's already set
                if (!this.publisher.sspControl.uniqueAds ||
                    !this.EXTERNAL_ADVERTISER_UNIQUE_MODES.includes(this.publisher.sspControl.uniqueAds)) {
                    this.publisher.sspControl.uniqueAds = PUBLISHER_TYPES.SSP_CONTROL.UNIQUE_ADS.EXTERNAL_ADVERTISERS; // Default if no previous selection
                }
            }
            else {
                this.publisher.sspControl.uniqueAds = PUBLISHER_TYPES.SSP_CONTROL.UNIQUE_ADS.OFF;
            }
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Handle parsing and displaying errors received
     * from the api.
     * @param {any} response
     */
    PublisherFormComponent.prototype.handleErrors = function (err) {
        var _this = this;
        this.modal.hideLoading();
        var response = err.error;
        response.errors.forEach(function (error) {
            /**
             * If error message is in errorDetails array, then use the more descriptive
             * message from errorObj instead of the one returned from the Api and add it to the
             * errors array which will be sent to errorHelper
             */
            if (_this.errorDetails[error.details]) {
                var errorObj = _this.errorDetails[error.details];
                var index = response.errors.findIndex(function (err) {
                    return err.details === error.details;
                });
                if (index > -1) {
                    response.errors[index].details = errorObj.details;
                }
            }
        });
        this.errorHelper.loadBackEndErrors(response.errors);
        var errorList = this.errorHelper.errors;
        this.displayErrorMessage(errorList);
    };
    /**
     * Clear out and reset the form.
     */
    PublisherFormComponent.prototype.reset = function () {
        this.errorHelper.resetAllErrors();
        this.form.resetForm();
    };
    PublisherFormComponent.prototype.convertUserToOption = function (user) {
        return {
            key: user.hashId,
            value: user.hashId,
            label: user.name
        };
    };
    PublisherFormComponent.prototype.displayErrorMessage = function (errorList) {
        if (errorList.length === 1) {
            this.noteId = this.notifications.error('<strong>Uh Oh!</strong> The publisher could not be saved because '
                + errorList.join(' ') + ' Please check the publisher and try again.', '', 0);
        }
        else if (errorList.length > 1) {
            this.noteId = this.notifications.error('<strong>Uh Oh!</strong>  The publisher could not be saved for'
                + ' the following reasons: <li>' + errorList.join('</li><li>') + '</li>', '', 0);
        }
    };
    Object.defineProperty(PublisherFormComponent.prototype, "audienceId", {
        /**
         * We are only returning the first item because
         * the api returns an array of audiences. We want
         * to limit users to only select 1 audience through
         * the frontend.
         */
        get: function () {
            if (Array.isArray(this.publisher.audiences) && this.publisher.audiences[0]) {
                // This will be prefixed, so don't return the prefix, only return the audience id
                var audienceIdStr = this.publisher.audiences[0].split('.')[1];
                // And convert to number to match one of the dropdown options
                return Number(audienceIdStr);
            }
            return null;
        },
        set: function (audienceId) {
            this.addPrefixToThisPublishersAudience(audienceId);
        },
        enumerable: true,
        configurable: true
    });
    PublisherFormComponent.prototype.addPrefixToThisPublishersAudience = function (audienceId) {
        if (audienceId) {
            var prefix = AUDIENCE_ID_TO_PREFIX.get(audienceId);
            this.publisher.audiences = [prefix + "." + audienceId];
        }
        else {
            this.publisher.audiences = null;
        }
    };
    PublisherFormComponent.prototype.onPubConsentControlChange = function (publisherIdentifier) {
        this.publisher.inheritConsentControls = false;
        this.publisher.consentControls = publisherIdentifier.getValue().map(function (pi) { return ({ id: pi.key, allow: pi.value }); });
    };
    PublisherFormComponent.prototype.updateDemandBlockingSettings = function (publisher) {
        this.publisher.targetedDomains = publisher.targetedDomains;
        this.publisher.domainTargetingType = publisher.domainTargetingType;
        this.publisher.blocklistCategories = publisher.blocklistCategories;
        this.publisher.sensitiveCategories = publisher.sensitiveCategories;
        this.publisher.audiences = publisher.audiences;
    };
    PublisherFormComponent.prototype.updatePublisherEspSettings = function (publisher) {
        this.publisher.esp = publisher.esp;
        this.publisher.emailTagReplacement = publisher.emailTagReplacement;
        this.publisher.emailTagType = publisher.emailTagType;
        this.publisher.placementTagReplacement = publisher.placementTagReplacement;
        this.publisher.listTagReplacement = publisher.listTagReplacement;
        this.publisher.lceeKeyReplacement = publisher.lceeKeyReplacement;
        this.publisher.lceeTagReplacement = publisher.lceeTagReplacement;
    };
    PublisherFormComponent.prototype.updatePublisherEsp = function (esp) {
        this.publisher.esp = esp;
    };
    PublisherFormComponent.prototype.updatePublisherEmailTagReplacement = function (value) {
        this.publisher.emailTagReplacement = value;
    };
    PublisherFormComponent.prototype.updatePublisherEmailTagType = function (value) {
        this.publisher.emailTagType = value;
    };
    PublisherFormComponent.prototype.updatePublisherPlacementTagReplacement = function (value) {
        this.publisher.placementTagReplacement = value;
    };
    PublisherFormComponent.prototype.updatePublisherListTagReplacement = function (value) {
        this.publisher.listTagReplacement = value;
    };
    PublisherFormComponent.prototype.updatePublisherLceeKeyReplacement = function (value) {
        this.publisher.lceeKeyReplacement = value;
    };
    PublisherFormComponent.prototype.updatePublisherLceeTagReplacement = function (value) {
        this.publisher.lceeTagReplacement = value;
    };
    PublisherFormComponent.prototype.validEspSetting = function () {
        return (this.publisher.esp !== null);
    };
    PublisherFormComponent.prototype.initMediaGroup = function () {
        var _this = this;
        this.searchMediaGroup$.pipe(distinctUntilChanged(), debounceTime(300), switchMap(function (search) {
            var conditions = [];
            if (search.length > 0) {
                conditions.push({
                    field: 'name',
                    value: search,
                    operator: 'like'
                });
                var refId = parseInt(search);
                if (!isNaN(refId) && refId.toString() === search) {
                    conditions.push({
                        field: 'refId',
                        value: refId,
                        mode: 'or'
                    });
                }
            }
            else {
                return _this.mediaGroupRepository.search();
            }
            return _this.mediaGroupRepository.search({ conditions: conditions });
        })).subscribe(function (mediaGroups) {
            if (_this.mediaGroups === undefined) {
                _this.mediaGroups = mediaGroups;
            }
            _this.mediaGroups$.next(mediaGroups);
        });
    };
    PublisherFormComponent.prototype.isClientTypeSelected = function (publisherClientTypes, clientType) {
        if (publisherClientTypes) {
            return publisherClientTypes.includes(clientType);
        }
        return clientType === PublisherClientTypes.Monetization;
    };
    PublisherFormComponent.prototype.onClientTypeChange = function (isSelected, clientType) {
        if (isSelected) {
            this.publisher.clientTypes.push(clientType);
        }
        else {
            this.publisher.clientTypes = this.publisher.clientTypes.filter(function (publisherClientType) { return publisherClientType !== clientType; });
        }
    };
    PublisherFormComponent.prototype.isLastSelected = function (currentClientType) {
        if (!this.publisher.clientTypes) {
            this.publisher.clientTypes = [
                PublisherClientTypes.Monetization
            ];
        }
        return this.publisher.clientTypes.length === 1 && this.publisher.clientTypes.find(function (clientType) { return clientType === currentClientType; });
    };
    PublisherFormComponent.prototype.close = function () {
        this.modal.close();
        if (this.redirect) {
            this.router.navigateByUrl(this.redirect);
        }
        else if (this.publisher.refId) {
            this.router.navigate(['/inventory-manager/publishers', this.publisher.refId]);
        }
        else if (this.mediaGroup.refId) {
            this.router.navigate(['/inventory-manager/media-groups', this.mediaGroup.refId]);
        }
        else {
            this.router.navigate(['/inventory-manager']);
        }
    };
    return PublisherFormComponent;
}());
export { PublisherFormComponent };
