import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { BackendRepository } from './backend-repository';
import { ESP } from 'app/shared/models';
import { mergeMap, shareReplay } from 'rxjs/operators';
var ESPRepository = /** @class */ (function (_super) {
    tslib_1.__extends(ESPRepository, _super);
    function ESPRepository(http) {
        return _super.call(this, http, '/esp', ESP) || this;
    }
    ESPRepository.prototype.get = function (id) {
        if (!this.cache) {
            this.cache = _super.prototype.search.call(this).pipe(shareReplay());
        }
        return this.cache.pipe(mergeMap(function (esps) { return esps.filter(function (esp) { return esp.id === id; }); }));
    };
    ESPRepository.prototype.getFromCache = function (id) {
        return this.get(id);
    };
    return ESPRepository;
}(BackendRepository));
export { ESPRepository };
