import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import moment from 'moment';
import { GroupByDataPoint, MidasActivity, ReportingQuery, Rule, TimeSeriesDataPoint, TopNDataPoint } from 'app/shared/models';
import { BackendRepository } from 'app/core/repositories/backend-repository';
export var Granularity;
(function (Granularity) {
    Granularity["All"] = "all";
    Granularity["Day"] = "day";
    Granularity["Hour"] = "hour";
})(Granularity || (Granularity = {}));
var LGK = 8;
var ReportingQueryRepository = /** @class */ (function (_super) {
    tslib_1.__extends(ReportingQueryRepository, _super);
    function ReportingQueryRepository(http) {
        return _super.call(this, http, '/reporting/query', ReportingQuery) || this;
    }
    ReportingQueryRepository.prototype.executeSavedQueryCSV = function (params) {
        return this.http.request('post', this.url(this.path, params.id, 'execute'), {
            headers: new HttpHeaders({ Accept: 'text/csv' })
        }).pipe(catchError(function (err) {
            if (!(err.error instanceof ErrorEvent)) {
                if (typeof err.error === 'object') {
                    return throwError(err.error);
                }
                return of(JSON.parse(err.error));
            }
            return throwError('An error occurred executing the query.');
        }));
    };
    ReportingQueryRepository.prototype.executeAdHocQueryCSV = function (params) {
        return this.http.request('post', this.url(this.path, 'execute'), {
            headers: new HttpHeaders({ Accept: 'text/csv' }),
            body: params.serialize()
        }).pipe(catchError(function (err) {
            if (!(err.error instanceof ErrorEvent)) {
                if (typeof err.error === 'object') {
                    return throwError(err.error);
                }
                return of(JSON.parse(err.error));
            }
            return throwError('An error occurred executing the query.');
        }));
    };
    ReportingQueryRepository.prototype.executeAdHocJSON = function (query, type) {
        return this.http.post(this.url(this.path, 'execute'), { query: query, type: type });
    };
    ReportingQueryRepository.prototype.getUserQueries = function () {
        return this.http.get(this.url(this.path));
    };
    ReportingQueryRepository.prototype.getAllQueries = function () {
        return this.http.get(this.url(this.path, 'allUsers'));
    };
    ReportingQueryRepository.prototype.fetchRtbDomains = function (params) {
        // @ts-ignore
        params.type = 'rtb';
        return this.http.post(this.url(this.path, 'fetch-domains'), params);
    };
    ReportingQueryRepository.prototype.fetchExchangeDomains = function (params) {
        // @ts-ignore
        params.type = 'exchange';
        return this.http.post(this.url(this.path, 'fetch-domains'), params);
    };
    ReportingQueryRepository.prototype.fetchRtbCreatives = function (params) {
        // @ts-ignore
        params.type = 'rtb';
        return this.http.post(this.url(this.path, 'fetch-creatives'), params);
    };
    ReportingQueryRepository.prototype.fetchExchangeCreatives = function (params) {
        // @ts-ignore
        params.type = 'exchange';
        return this.http.post(this.url(this.path, 'fetch-creatives'), params);
    };
    ReportingQueryRepository.prototype.update = function (params, model) {
        return this.http.put(this.url(this.path, params.id), model.serialize());
    };
    ReportingQueryRepository.prototype.save = function (instance) {
        // Overriding BackendRepository's method because unlike LSD 'output' field doesn't exist in Wolfman response
        var params = [this.path];
        var id = instance.id;
        if (typeof id === 'number') {
            id = id.toString();
        }
        if (typeof id === 'string') {
            params.push(id);
        }
        return this.http.post(this.url.apply(this, params), instance.serialize());
    };
    ReportingQueryRepository.prototype.executeCannedReport = function (data) {
        return this.http.post(this.url(this.path, 'execute-canned'), data);
    };
    /**
     * Determine midas aggregated data source based on interval's start date.
     * midas_events_intermediate has hourly aggregates only for last 7 days
     * midas_events_intermediate_filtered has daily aggregates for 30 days
     * midas_events_intermediate_filtered doesn't include today's data
     */
    ReportingQueryRepository.prototype.getMidasDataSource = function (start) {
        return moment(start).isBefore(moment().subtract(7, 'days').startOf('day'))
            ? 'midas_events_intermediate_filtered' : 'midas_events_intermediate';
    };
    ReportingQueryRepository.prototype.getInventoryStats = function (publisherIds) {
        var query = {
            dataSource: 'custom_aggregates_exact',
            timeZone: 'America/New_York',
            queryType: 'groupBy',
            intervals: [{
                    type: 'dynamic',
                    value: '30'
                }],
            granularity: 'all',
            postAggregations: [{
                    type: 'javascript',
                    name: 'effectiveCPM',
                    fieldNames: [
                        'revenue',
                        'adjusted_impressions'
                    ],
                    function: 'function(r, i) { return i ? (r / i) * 1000 : 0; };'
                }],
            aggregations: [{
                    fieldName: 'publisher_revenue',
                    type: 'doubleSum',
                    name: 'revenue'
                }, {
                    fieldName: 'adjusted_impressions',
                    type: 'longSum',
                    name: 'adjusted_impressions'
                }],
            dimensions: [{
                    type: 'default',
                    dimension: 'publisher_id',
                    outputName: 'publisherId'
                }, {
                    type: 'default',
                    dimension: 'template_id',
                    outputName: 'newsletterId'
                }, {
                    type: 'default',
                    dimension: 'section_id',
                    outputName: 'adSlotId'
                }],
            filter: {
                type: 'and',
                fields: [{
                        type: 'in',
                        dimension: 'publisher_id',
                        values: publisherIds.map(function (id) { return id.toString(); })
                    }, {
                        type: 'selector',
                        dimension: 'demand_type',
                        value: 'default'
                    }]
            }
        };
        return this.executeAdHocJSON(query, 'publisher');
    };
    ReportingQueryRepository.prototype.getMostRecentTagActivity = function (advertiserRefId) {
        return this.mostRecentActivity([
            { dimension: 'advertiser_id', value: advertiserRefId.toString() }
        ]);
    };
    ReportingQueryRepository.prototype.getMostRecentPixelActivity = function (pixelId) {
        return this.mostRecentActivity([
            { dimension: 'conversion_tracker_id', value: pixelId.toString() }
        ]);
    };
    ReportingQueryRepository.prototype.getMostRecentRuleActivity = function (rule, advertiserRefId) {
        return this.mostRecentActivity([
            { dimension: 'soft_validation_result_event', value: rule.eventNameRegex },
            { dimension: 'soft_validation_result_name', value: rule.termRegex.toLowerCase() },
            { dimension: 'advertiser_id', value: advertiserRefId.toString() },
        ]);
    };
    ReportingQueryRepository.prototype.getMostRecentEventActivity = function (advertiserId, eventName) {
        // empty event name is considered as pageView
        var event = eventName === 'pageView' ? '' : eventName;
        return this.mostRecentActivity([
            { dimension: 'soft_validation_result_event', value: event },
            { dimension: 'advertiser_id', value: advertiserId.toString() },
        ]);
    };
    ReportingQueryRepository.prototype.liveConnectOverviewMetrics = function (advertiserRefId, start, end, eventType) {
        var filters = [];
        if (eventType === 'url') {
            filters = [{
                    type: 'not',
                    field: {
                        type: 'selector',
                        dimension: 'web_page_url_path',
                        value: null
                    }
                }];
        }
        return this.fetchLiveConnectOverviewMetrics(advertiserRefId, start, end, filters);
    };
    ReportingQueryRepository.prototype.fetchLiveConnectOverviewMetrics = function (advertiserRefId, start, end, filters) {
        if (filters === void 0) { filters = []; }
        var dataSource = this.getMidasDataSource(start);
        var query = {
            dataSource: dataSource,
            timeZone: 'America/New_York',
            queryType: 'groupBy',
            granularity: 'all',
            intervals: [
                {
                    type: 'absolute',
                    start: start.toISOString(),
                    end: end.toISOString()
                }
            ],
            aggregations: [
                {
                    type: 'HLLSketchMerge',
                    name: 'site_visits',
                    fieldName: 'event_id_sketch',
                },
                {
                    type: 'HLLSketchMerge',
                    name: 'known_visitors',
                    fieldName: 'all_hashes_sketch',
                },
                {
                    type: 'HLLSketchMerge',
                    name: 'matched_visitors',
                    fieldName: 'auction_hashes_sketch',
                },
                {
                    type: 'HLLSketchMerge',
                    name: 'unique_visitors',
                    fieldName: 'domain_user_id_sketch',
                }
            ],
            filter: {
                type: 'and',
                fields: [
                    {
                        type: 'selector',
                        dimension: 'advertiser_id',
                        value: advertiserRefId.toString()
                    }
                ].concat(filters)
            }
        };
        return this.executeAdHocJSON(query, 'advertiser').pipe(
        // pluck the first since we use granularity all
        map(function (dataPoints) { return Array.isArray(dataPoints) && dataPoints.length !== 0 ? dataPoints[0] : { event: {} }; }));
    };
    ReportingQueryRepository.prototype.topUrls = function (advertiserRefId, days, limit) {
        if (days === void 0) { days = 7; }
        if (limit === void 0) { limit = 100; }
        var query = {
            dataSource: 'midas_events_intermediate_filtered',
            queryType: 'topN',
            granularity: 'all',
            dimension: 'web_page_url_path',
            metric: 'matched_visitors',
            timeZone: 'America/New_York',
            threshold: limit,
            intervals: [{
                    type: 'dynamic',
                    value: days.toString()
                }],
            aggregations: [
                {
                    type: 'HLLSketchMerge',
                    name: 'matched_visitors',
                    fieldName: 'auction_hashes_sketch',
                    lgK: LGK
                }
            ],
            filter: {
                type: 'and',
                fields: [
                    {
                        type: 'selector',
                        dimension: 'advertiser_id',
                        value: advertiserRefId.toString()
                    }
                ]
            }
        };
        return this.executeAdHocJSON(query, 'advertiser');
    };
    ReportingQueryRepository.prototype.topEventTypes = function (advertiserRefId, days, limit) {
        if (days === void 0) { days = 7; }
        if (limit === void 0) { limit = 100; }
        var query = {
            dataSource: 'midas_events_intermediate_filtered',
            queryType: 'topN',
            granularity: 'all',
            dimension: 'soft_validation_result_event',
            metric: 'matched_visitors',
            timeZone: 'America/New_York',
            threshold: limit,
            intervals: [{
                    type: 'dynamic',
                    value: days.toString()
                }],
            aggregations: [
                {
                    type: 'HLLSketchMerge',
                    name: 'matched_visitors',
                    fieldName: 'auction_hashes_sketch',
                    lgK: LGK
                }
            ],
            filter: {
                type: 'and',
                fields: [
                    {
                        type: 'selector',
                        dimension: 'advertiser_id',
                        value: advertiserRefId.toString()
                    }
                ]
            }
        };
        return this.executeAdHocJSON(query, 'advertiser');
    };
    ReportingQueryRepository.prototype.topEventNames = function (eventTypes, advertiserRefId, days, limit) {
        if (days === void 0) { days = 7; }
        if (limit === void 0) { limit = 100; }
        var query = {
            dataSource: 'midas_events_intermediate_filtered',
            queryType: 'topN',
            granularity: 'all',
            dimension: 'soft_validation_result_name',
            metric: 'matched_visitors',
            timeZone: 'America/New_York',
            threshold: limit,
            intervals: [{
                    type: 'dynamic',
                    value: days.toString()
                }],
            aggregations: [
                {
                    type: 'HLLSketchMerge',
                    name: 'matched_visitors',
                    fieldName: 'auction_hashes_sketch',
                    lgK: LGK
                }
            ],
            filter: {
                type: 'and',
                fields: [
                    {
                        type: 'selector',
                        dimension: 'advertiser_id',
                        value: advertiserRefId.toString()
                    }
                ]
            }
        };
        if (eventTypes.length > 0) {
            query.filter.fields.push({
                type: 'or',
                fields: eventTypes.map(function (eventType) { return ({
                    type: 'selector',
                    dimension: 'soft_validation_result_event',
                    value: eventType
                }); })
            });
        }
        return this.executeAdHocJSON(query, 'advertiser');
    };
    ReportingQueryRepository.prototype.getPotentialCountsByAdvertiser = function (advertiserRefId, filters, days) {
        if (filters === void 0) { filters = []; }
        if (days === void 0) { days = 7; }
        var query = {
            dataSource: 'midas_events_intermediate_filtered',
            queryType: 'topN',
            granularity: 'all',
            dimension: 'advertiser_id',
            metric: 'unique_visitors',
            timeZone: 'America/New_York',
            threshold: '10',
            intervals: [{
                    type: 'dynamic',
                    value: days.toString()
                }],
            aggregations: [
                {
                    type: 'HLLSketchMerge',
                    name: 'unique_visitors',
                    fieldName: 'all_hashes_sketch',
                    lgK: LGK
                },
                {
                    type: 'HLLSketchMerge',
                    name: 'matched_visitors',
                    fieldName: 'auction_hashes_sketch',
                    lgK: LGK
                }
            ],
            filter: {
                type: 'and',
                fields: [
                    {
                        type: 'selector',
                        dimension: 'advertiser_id',
                        value: advertiserRefId.toString()
                    }
                ].concat(filters)
            }
        };
        return this.executeAdHocJSON(query, 'advertiser');
    };
    ReportingQueryRepository.prototype.getLiveConnectEvents = function (advertiserRefId, start, end, granularity, filters) {
        if (filters === void 0) { filters = []; }
        var period = granularity === Granularity.Day ? 'P1D' : 'PT1H';
        var query = {
            queryType: 'timeseries',
            granularity: {
                type: 'period',
                period: period,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            timeZone: 'America/New_York',
            dataSource: 'midas',
            intervals: [{
                    type: 'absolute',
                    start: start.toISOString(),
                    end: end.toISOString(),
                }],
            aggregations: [
                {
                    type: 'count',
                    name: 'count'
                }
            ],
            filter: {
                type: 'and',
                fields: filters.concat({
                    type: 'selector',
                    dimension: 'advertiser_id',
                    value: advertiserRefId.toString()
                })
            }
        };
        return this.executeAdHocJSON(query, 'advertiser').pipe(map(function (dataPoints) {
            var unitOfTime = granularity === Granularity.Day ? 'day' : 'hour';
            return dataPoints.map(function (dataPoint) {
                dataPoint.timestamp = moment(dataPoint.timestamp).startOf(unitOfTime).toISOString();
                return dataPoint;
            });
        }));
    };
    ReportingQueryRepository.prototype.getEventData = function (advertiserRefId, start, end) {
        var dataSource = this.getMidasDataSource(start);
        var query = {
            dataSource: dataSource,
            timeZone: 'America/New_York',
            queryType: 'groupBy',
            filter: {
                type: 'and',
                fields: [
                    {
                        type: 'selector',
                        dimension: 'advertiser_id',
                        value: advertiserRefId.toString()
                    }
                ]
            },
            intervals: [
                {
                    type: 'absolute',
                    start: start.toISOString(),
                    end: end.toISOString()
                }
            ],
            granularity: {
                type: 'period',
                period: 'P1D',
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            aggregations: [
                {
                    type: 'HLLSketchMerge',
                    name: 'page_visits',
                    fieldName: 'event_id_sketch'
                },
                {
                    type: 'HLLSketchMerge',
                    name: 'known_visitors',
                    fieldName: 'domain_user_id_sketch'
                }
            ],
            // Druid group by query remove null aggregation from response.
            // We are replacing null (empty) with 'pageView' event.
            // Empty events get generated from base LC tag
            // Empty event name is considered as 'pageView'
            dimensions: [
                {
                    type: 'lookup',
                    dimension: 'soft_validation_result_event',
                    outputName: 'soft_validation_result_event',
                    lookup: {
                        type: 'map',
                        map: { '': 'pageView' }
                    },
                    retainMissingValue: true,
                    injective: false
                }
            ]
        };
        return this.executeAdHocJSON(query, 'advertiser').pipe(map(function (dataPoints) {
            return dataPoints.map(function (dataPoint) {
                dataPoint.timestamp = moment(dataPoint.timestamp).startOf('day').toISOString();
                return dataPoint;
            });
        }));
    };
    ReportingQueryRepository.prototype.getUrlData = function (advertiserRefId, start, end) {
        var dataSource = this.getMidasDataSource(start);
        var query = {
            dataSource: dataSource,
            timeZone: 'America/New_York',
            queryType: 'groupBy',
            granularity: {
                type: 'period',
                period: 'P1D',
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            intervals: [
                {
                    type: 'absolute',
                    start: start.toISOString(),
                    end: end.toISOString()
                }
            ],
            aggregations: [
                {
                    type: 'HLLSketchMerge',
                    name: 'count',
                    fieldName: 'event_id_sketch'
                }
            ],
            filter: {
                type: 'and',
                fields: [
                    {
                        type: 'selector',
                        dimension: 'advertiser_id',
                        value: advertiserRefId.toString()
                    },
                    {
                        type: 'not',
                        field: {
                            type: 'selector',
                            dimension: 'web_page_url_path',
                            value: null
                        }
                    }
                ]
            }
        };
        return this.executeAdHocJSON(query, 'advertiser').pipe(map(function (dataPoints) {
            return dataPoints.map(function (dataPoint) {
                dataPoint.timestamp = moment(dataPoint.timestamp).startOf('day').toISOString();
                return dataPoint;
            });
        }));
    };
    ReportingQueryRepository.prototype.getLiveConnectAggregatedEvents = function (advertiserRefId, start, end) {
        var dataSource = this.getMidasDataSource(start);
        var query = {
            dataSource: dataSource,
            timeZone: 'America/New_York',
            queryType: 'groupBy',
            filter: {
                type: 'and',
                fields: [
                    {
                        type: 'selector',
                        dimension: 'advertiser_id',
                        value: advertiserRefId.toString()
                    }
                ]
            },
            intervals: [
                {
                    type: 'absolute',
                    start: start.toISOString(),
                    end: end.toISOString()
                }
            ],
            granularity: 'all',
            aggregations: [
                {
                    type: 'HLLSketchMerge',
                    name: 'page_visits',
                    fieldName: 'event_id_sketch'
                }
            ],
            dimensions: [
                'event_platform',
                {
                    type: 'lookup',
                    dimension: 'soft_validation_result_event',
                    outputName: 'soft_validation_result_event',
                    lookup: {
                        type: 'map',
                        map: { '': 'pageView' }
                    },
                    retainMissingValue: true,
                    injective: false
                }
            ]
        };
        return this.executeAdHocJSON(query, 'advertiser');
    };
    ReportingQueryRepository.prototype.getLiveConnectUrls = function (advertiserRefId, start, end) {
        var dataSource = this.getMidasDataSource(start);
        var query = {
            dataSource: dataSource,
            timeZone: 'America/New_York',
            queryType: 'topN',
            metric: 'page_visits',
            filter: {
                type: 'and',
                fields: [
                    {
                        type: 'selector',
                        dimension: 'advertiser_id',
                        value: advertiserRefId.toString()
                    },
                    {
                        type: 'not',
                        field: {
                            type: 'selector',
                            dimension: 'web_page_url_path',
                            value: null
                        }
                    }
                ]
            },
            intervals: [
                {
                    type: 'absolute',
                    start: start.toISOString(),
                    end: end.toISOString()
                }
            ],
            granularity: 'all',
            aggregations: [
                {
                    type: 'HLLSketchMerge',
                    name: 'page_visits',
                    fieldName: 'event_id_sketch'
                },
                {
                    type: 'HLLSketchMerge',
                    name: 'known_visitors',
                    fieldName: 'domain_user_id_sketch'
                }
            ],
            threshold: 1000,
            dimension: 'web_page_url_path'
        };
        return this.executeAdHocJSON(query, 'advertiser').pipe(
        // pluck the first since we use granularity all
        map(function (dataPoints) { return Array.isArray(dataPoints) && dataPoints.length !== 0 ? dataPoints[0] : { result: [] }; }));
    };
    ReportingQueryRepository.prototype.mostRecentActivity = function (filters, maxLookBack) {
        if (maxLookBack === void 0) { maxLookBack = 30; }
        var start = moment().subtract(maxLookBack, 'days').format('YYYY-MM-DD');
        var end = moment().add(1, 'day').format('YYYY-MM-DD');
        var query = {
            queryType: 'scan',
            dataSource: 'midas',
            limit: 1,
            order: 'descending',
            columns: [
                'app_id',
                'advertiser_id',
                'conversion_tracker_id',
                '__time'
            ],
            timeZone: 'America/New_York',
            intervals: [{
                    type: 'absolute',
                    start: start,
                    end: end
                }],
            filter: {
                type: 'and',
                fields: filters.map(function (filter) { return ({
                    type: 'selector',
                    dimension: filter.dimension,
                    value: filter.value
                }); })
            }
        };
        return this.executeAdHocJSON(query, 'advertiser').pipe(map(function (dataPoints) { return Array.isArray(dataPoints) && dataPoints.length !== 0 ? dataPoints[0] : {}; }), catchError(function (e) {
            console.log('Got error', e);
            return of({});
        }));
    };
    ReportingQueryRepository.prototype.getInsightMetrics = function (advertiserId) {
        var query = {
            dataSource: 'advertiser_insights',
            timeZone: 'America/New_York',
            queryType: 'groupBy',
            intervals: [{
                    type: 'dynamic',
                    value: '30'
                }],
            granularity: 'day',
            aggregations: [],
            dimensions: [{
                    type: 'default',
                    dimension: 'date',
                    outputName: 'date'
                }, {
                    type: 'default',
                    dimension: 'openers_count',
                    outputName: 'openersCount'
                }, {
                    type: 'default',
                    dimension: 'website_visitors_openers_count',
                    outputName: 'websiteVisitorsOpenersCount'
                }, {
                    type: 'default',
                    dimension: 'website_prospects_count',
                    outputName: 'websiteProspectsCount'
                }, {
                    type: 'default',
                    dimension: 'website_visitors_count',
                    outputName: 'websiteVisitorsCount'
                }],
            filter: {
                type: 'selector',
                dimension: 'advertiser_id',
                value: advertiserId.toString()
            }
        };
        return this.executeAdHocJSON(query, 'advertiser');
    };
    ReportingQueryRepository.prototype.getAdSlotImpressionsForDeviceAndSize = function (refId, idType, since) {
        var query = {
            timeZone: 'America/New_York',
            queryType: 'groupBy',
            dataSource: 'custom_aggregates_inexact_v2',
            granularity: 'all',
            dimensions: [
                'size',
                'device_type'
            ],
            filter: {
                type: 'and',
                fields: []
            },
            aggregations: [
                {
                    type: 'longSum',
                    name: 'impressions',
                    fieldName: 'impressions'
                }
            ],
            intervals: [
                {
                    type: 'absolute',
                    start: since.toISOString(),
                    end: new Date().toISOString()
                }
            ]
        };
        query.filter.fields.push({
            type: 'selector',
            dimension: idType === 'AdSlot' ? 'section_id' : 'publisher_id',
            value: refId.toString()
        });
        return this.executeAdHocJSON(query, 'publisher');
    };
    ReportingQueryRepository.prototype.getAdSlotImpressionsWithBidBucketsBySize = function (refId, idType, since) {
        var query = {
            timeZone: 'America/New_York',
            queryType: 'groupBy',
            dataSource: 'imp_bid_buckets',
            granularity: 'all',
            dimensions: [
                'size',
                'bid_price_bucket'
            ],
            filter: {
                type: 'and',
                fields: []
            },
            aggregations: [
                {
                    type: 'longSum',
                    name: 'impressions',
                    fieldName: 'impressions'
                }
            ],
            intervals: [
                {
                    type: 'absolute',
                    start: since.toISOString(),
                    end: new Date().toISOString()
                }
            ]
        };
        query.filter.fields.push({
            type: 'selector',
            dimension: idType === 'AdSlot' ? 'section_id' : 'publisher_id',
            value: refId.toString()
        });
        return this.executeAdHocJSON(query, 'publisher');
    };
    ReportingQueryRepository.prototype.getIndexes = function (advertiserId) {
        var query = {
            dataSource: 'indexes_insights',
            timeZone: 'America/New_York',
            queryType: 'groupBy',
            granularity: 'day',
            intervals: [{
                    type: 'dynamic',
                    value: '1'
                }],
            dimensions: [
                'date',
                'days_behind',
                'goal',
                'campaign_id',
                'lc_ctr_rank',
                'non_lc_ctr_rank',
                'avg_ctr',
                'lc_ccr_rank',
                'non_lc_ccr_rank',
                'avg_ccr',
                'lc_ecpm_rank',
                'non_lc_ecpm_rank',
                'avg_ecpm',
                'clicks',
                'conversions',
                'category',
                'demand_type',
                'advertiser_spent'
            ],
            aggregations: [],
            filter: {
                type: 'selector',
                dimension: 'advertiser_id',
                value: advertiserId
            }
        };
        return this.executeAdHocJSON(query, 'advertiser');
    };
    ReportingQueryRepository.prototype.getAllAdvertisersByIndex = function (index) {
        var query = {
            dataSource: 'indexes_insights',
            timeZone: 'America/New_York',
            queryType: 'groupBy',
            granularity: 'day',
            intervals: [
                {
                    type: 'dynamic',
                    value: '1'
                }
            ],
            dimensions: [
                index
            ],
            aggregations: []
        };
        return this.executeAdHocJSON(query, 'advertiser');
    };
    ReportingQueryRepository.prototype.getDemandMetrics = function (refId, entityType, system, from, until) {
        var dataSource = system === 'dsp' ? 'custom_exact_dsp' : 'custom_aggregates_exact';
        var dimension;
        if (entityType === 'LineItem') {
            dimension = 'line_item_id';
        }
        var query = {
            timeZone: 'America/New_York',
            queryType: 'groupBy',
            dataSource: dataSource,
            granularity: 'all',
            dimensions: [
                'line_item_id'
            ],
            filter: {
                type: 'and',
                fields: []
            },
            aggregations: [
                {
                    type: 'longSum',
                    name: 'impressions',
                    fieldName: 'impressions'
                },
                {
                    type: 'longSum',
                    name: 'spend',
                    fieldName: 'advertiser_spent'
                }
            ],
            intervals: [
                {
                    type: 'absolute',
                    start: from.toISOString(),
                    end: until.toISOString()
                }
            ]
        };
        query.filter.fields.push({
            type: 'selector',
            dimension: dimension,
            value: refId.toString()
        });
        return this.executeAdHocJSON(query, 'advertiser');
    };
    return ReportingQueryRepository;
}(BackendRepository));
export { ReportingQueryRepository };
