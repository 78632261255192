import { OnInit } from '@angular/core';
import { IdService } from 'app/core';
import { Blueprint, SupplyAsset, SupplyAssetProperties } from 'app/shared/models';
import { SupplyAssetService } from 'app/core/supply-asset.service';
var BlueprintDetailsComponent = /** @class */ (function () {
    function BlueprintDetailsComponent(id, supplyAssetService) {
        this.id = id;
        this.supplyAssetService = supplyAssetService;
        this.supplyAssets = new SupplyAsset();
        this.supplyAssetProperties = SupplyAssetProperties;
        this.cacheBuster = 10000;
    }
    Object.defineProperty(BlueprintDetailsComponent.prototype, "ifaasUrl", {
        // Update preview with cachebuster query param, if longer than time:
        get: function () {
            var currentTime = Date.now();
            var blueprintModified = Date.parse(this.blueprint.modified);
            if (blueprintModified + this.cacheBuster < currentTime) {
                return this.blueprint.ifaasUrl + '&updatedAt=' + blueprintModified;
            }
            return this.blueprint.ifaasUrl;
        },
        enumerable: true,
        configurable: true
    });
    BlueprintDetailsComponent.prototype.ngOnInit = function () {
        this.supplyAssetService.initSupplyAssets(this.supplyAssets);
        this.supplyAssetService.setAssets(this.blueprint, this.supplyAssets);
    };
    BlueprintDetailsComponent.prototype.mobileIfaasUrl = function () {
        if (this.ifaasUrl) {
            return this.ifaasUrl.replace('_desktop', '_mobile');
        }
        return null;
    };
    return BlueprintDetailsComponent;
}());
export { BlueprintDetailsComponent };
