import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AudienceFile, DeltaCountResponse, HistoricalCountDataPoint } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
var UMLAUTRepository = /** @class */ (function (_super) {
    tslib_1.__extends(UMLAUTRepository, _super);
    function UMLAUTRepository(http) {
        return _super.call(this, http, '/umlaut', AudienceFile) || this;
    }
    UMLAUTRepository.prototype.get = function (segmentId) {
        var params = {
            conditions: [
                { field: 'segmentId', value: segmentId }
            ]
        };
        return this.http.get(this.url(this.path + '/uploads' + this.stringifyParams(params)))
            .pipe(map(function (response) { return response; }));
    };
    UMLAUTRepository.prototype.getLatestCountsBySegment = function (segmentId) {
        var params = {
            conditions: [
                { field: 'page', value: 1 },
                { field: 'orderBy', value: 'date' },
                { field: 'order', value: 'desc' }
            ]
        };
        return this.http.get(this.url(this.path + "/segments/" + segmentId + "/stats" + this.stringifyParams(params))).pipe(map(function (result) {
            return result['data'].length ? result['data'][0] : {};
        }));
    };
    UMLAUTRepository.prototype.getHistoricalCountsBySegment = function (segmentId) {
        var params = {
            conditions: [
                { field: 'page', value: 1 },
                { field: 'pageSize', value: 100 },
                { field: 'orderBy', value: 'date' },
                { field: 'order', value: 'asc' }
            ]
        };
        return this.http.get(this.url(this.path + "/segments/" + segmentId + "/stats" + this.stringifyParams(params))).pipe(map(function (result) { return result['data']; }));
    };
    UMLAUTRepository.prototype.getHistoricalCountsByAdvertiser = function (advertiserId) {
        var params = {
            conditions: [
                { field: 'page', value: 1 },
                { field: 'pageSize', value: 100 },
                { field: 'orderBy', value: 'date' },
                { field: 'order', value: 'desc' }
            ]
        };
        return this.http.get(this.url(this.path + "/advertisers/" + advertiserId + "/stats" + this.stringifyParams(params))).pipe(map(function (result) { return result['data']; }));
    };
    UMLAUTRepository.prototype.deltaCountsByAdvertiserId = function (advertiserRefId) {
        return this.http.get(this.url(this.path + "/advertisers/" + advertiserRefId + "/stats/grouped"))
            .pipe(map(function (response) {
            return response;
        }));
    };
    UMLAUTRepository.prototype.asyncSearch = function (params) {
        return this.http.get(this.url(this.path + '/uploads' + this.stringifyParams(params)))
            .pipe(map(function (response) { return ({
            items: response['data'],
            page: 1,
            pages: response['pages'],
            total: response['total']
        }); }));
    };
    UMLAUTRepository.prototype.stringifyParams = function (params) {
        var str = '?' + params.conditions.map(function (condition) { return condition.field + "=" + condition.value; }).join('&');
        params.n ? str += "&pageSize=" + params.n : '';
        params.page ? str += "&page=" + params.page : '';
        params.orderBy ? str += "&orderBy=" + params.orderBy : '';
        params.sort ? str += "&order=" + params.sort : '';
        return str;
    };
    UMLAUTRepository.prototype.getRejectedLink = function (audienceFile) {
        return this.http.post(this.url('/audience/download-rejected', audienceFile.uniqueSegmentId.toString()), {
            filename: audienceFile.rejectedHashesFile,
            bucketName: audienceFile.rejectedHashesBucket
        }).pipe(map(function (response) { return response['output'].presignedURI; }));
    };
    return UMLAUTRepository;
}(BackendRepository));
export { UMLAUTRepository };
