import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { StrategyCard } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { map } from 'rxjs/operators';
var StrategyCardRepository = /** @class */ (function (_super) {
    tslib_1.__extends(StrategyCardRepository, _super);
    function StrategyCardRepository(http) {
        return _super.call(this, http, '/strategyCard', StrategyCard) || this;
    }
    StrategyCardRepository.prototype.cards = function () {
        return this.http.get(this.url('/strategy-card/cards')).pipe(map(function (response) { return response['output']; }));
    };
    return StrategyCardRepository;
}(BackendRepository));
export { StrategyCardRepository };
