import { Attribute, Component, OnInit, ViewChild } from '@angular/core';
import { Observable, merge } from 'rxjs';
import { map, mapTo, delay } from 'rxjs/operators';
import { CopyableDirective } from '../copyable';
import { fadeInOut } from 'app/shared/animations';

enum MessageType {
    Success,
    Error
}

@Component({
    selector: 'code-snippet',
    templateUrl: './code-snippet.component.html',
    styleUrls: ['./code-snippet.component.styl'],
    animations: [fadeInOut]
})
export class CodeSnippetComponent implements OnInit {
    @ViewChild(CopyableDirective, { static: true }) copyable: CopyableDirective;
    language: string;
    message$: Observable<MessageType>;
    readonly Message = MessageType;
    private readonly timeout = 3000;
    nativeElement: Element;

    constructor(@Attribute('language') langauge: string) {
        this.language = langauge;
    }

    ngOnInit() {
        this.message$ = merge(
            this.copyable.copied.pipe(map(result => result ? MessageType.Success : MessageType.Error)),
            this.copyable.copied.pipe(mapTo(null), delay(this.timeout))
        );
    }
}
