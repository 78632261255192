import { OnInit, OnDestroy, } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, BehaviorSubject, combineLatest, of } from 'rxjs';
import { LIESRepository, SorobanRepository } from 'app/core/repositories';
import { ESSimpleQueryBuilder } from 'app/shared/helpers/es-simple-query-builder';
import { PaginatorComponent } from 'app/shared/elements/paginator';
import { TableComponent } from 'app/shared/elements/table';
import { TableHelper } from 'app/shared/helpers/table-helper';
import { AuthorizationService } from 'app/core';
import { debounceTime, distinctUntilChanged, takeUntil, switchMap, } from 'rxjs/operators';
import { getESTotal } from 'app/shared/helpers/es-helper';
import { CamelToSnakeCasePipe, SnakeToCamelCasePipe } from 'app/shared/elements/pipes';
import { getLinkFromEntity, formatIndex, } from 'app/shared/helpers/search-helper';
import { Flag, LaunchDarklyService } from 'app/core/launch-darkly.service';
var GlobalSearchComponent = /** @class */ (function () {
    function GlobalSearchComponent(route, auth, liesRepository, camelToSnakeCasePipe, snakeToCamelCasePipe, sorobanRepository, launchDarklyService) {
        var _this = this;
        this.route = route;
        this.auth = auth;
        this.liesRepository = liesRepository;
        this.camelToSnakeCasePipe = camelToSnakeCasePipe;
        this.snakeToCamelCasePipe = snakeToCamelCasePipe;
        this.sorobanRepository = sorobanRepository;
        this.launchDarklyService = launchDarklyService;
        this.queryBuilder = new ESSimpleQueryBuilder();
        this.tableHelper = new TableHelper(function (params) { return _this.adapter(params); }, this.queryBuilder);
        this.getESTotal = getESTotal;
        this.filters = new BehaviorSubject({});
        this.onDestroy = new Subject();
        this.demandFilters = [
            { label: 'Agencies', index: 'agencies' },
            { label: 'Advertisers', index: 'advertisers' },
            {
                label: 'Insertion Orders',
                index: 'insertionOrders',
                internalOnly: true,
            },
            { label: 'Campaigns', index: 'campaigns' },
            { label: 'Line Items', index: 'lineItems' },
            { label: 'Creatives', index: 'creatives' },
        ];
        this.supplyFilters = [
            { label: 'Media Groups', index: 'mediaGroups' },
            { label: 'Publishers', index: 'publishers' },
            { label: 'Newsletters', index: 'newsletters' },
            { label: 'Ad Slots', index: 'adSlots' },
        ];
        this.useNewSearchExperience = false;
    }
    GlobalSearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.aggregatedResults$ = combineLatest([
            this.table.query.pipe(debounceTime(300), takeUntil(this.onDestroy)),
            this.launchDarklyService
                .getVariation(Flag.NewSearchExperienceInMaverick)
                .pipe(distinctUntilChanged()),
        ]).pipe(switchMap(function (_a) {
            var rawParams = _a[0], useNewSearchExperience = _a[1];
            _this.useNewSearchExperience = useNewSearchExperience;
            var params = _this.queryBuilder.build(rawParams);
            if (params.query.length > 1) {
                if (_this.useNewSearchExperience) {
                    return _this.sorobanRepository.sorobanSearch(params.query);
                }
                else {
                    return _this.liesRepository.globalsearch(params.query);
                }
            }
            return of(null);
        }));
        this.tableHelper.table = this.table;
        this.tableHelper.paginator = this.paginator;
        combineLatest(this.table.query, this.paginator.query).subscribe(function (args) { return _this.tableHelper.search(args); });
    };
    GlobalSearchComponent.prototype.ngOnDestroy = function () {
        this.onDestroy.next();
        this.onDestroy.complete();
    };
    GlobalSearchComponent.prototype.adapter = function (params) {
        var _this = this;
        return combineLatest([
            this.filters,
            this.launchDarklyService
                .getVariation(Flag.NewSearchExperienceInMaverick)
                .pipe(distinctUntilChanged()),
        ]).pipe(switchMap(function (_a) {
            var filters = _a[0], useNewSearchExperience = _a[1];
            _this.useNewSearchExperience = useNewSearchExperience;
            params.indices = _this.filterIndices(Object.keys(filters));
            var _params = _this.route.queryParams['value'].q;
            var splitQueryParams = _params.split(/[+:]/);
            if (splitQueryParams.find(function (arr) { return arr[0] !== ''; })) {
                var parsed = splitQueryParams.slice(2, 3);
                var searchQuery = parsed[0];
                params.query = _this.table._searchOnInput
                    ? params.query
                    : '(' + searchQuery + ')';
            }
            if (_this.useNewSearchExperience) {
                return _this.sorobanRepository.eSorobanSearch(params);
            }
            else {
                return _this.liesRepository.eSearch(params);
            }
        }));
    };
    GlobalSearchComponent.prototype.filterIndices = function (filters) {
        var _this = this;
        if (filters.length > 0) {
            return filters;
        }
        return this.demandFilters
            .concat(this.supplyFilters)
            .filter(function (filter) { return _this.auth.isInternalUser || !filter.internalOnly; })
            .map(function (filter) {
            if (_this.useNewSearchExperience) {
                filter.index = _this.camelToSnakeCasePipe.transform(filter.index);
                return filter.index;
            }
            else {
                return filter.index;
            }
        });
    };
    GlobalSearchComponent.prototype.filterBy = function (shouldFilter, filter) {
        var filters = this.filters.getValue();
        var key = filter.index;
        filters[key] = shouldFilter;
        if (!filters[key]) {
            delete filters[key];
        }
        this.filters.next(filters);
    };
    GlobalSearchComponent.prototype.count = function (results, key) {
        if (this.useNewSearchExperience) {
            key = this.snakeToCamelCasePipe.transform(key);
        }
        var type = results[key];
        return getESTotal(type);
    };
    GlobalSearchComponent.prototype.link = function (entity) {
        return getLinkFromEntity(entity);
    };
    GlobalSearchComponent.prototype.formatIndex = function (index) {
        return formatIndex(index);
    };
    return GlobalSearchComponent;
}());
export { GlobalSearchComponent };
