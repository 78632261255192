import { AfterViewInit, ChangeDetectorRef, Component, Input, Output, EventEmitter, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Campaign, Creative, DemandAsset, LineItem, Native, SupplyAssetProperties } from 'app/shared/models';
import { CreativeSizeValidation } from 'app/shared/models/creative';
import { DemandAssetProperties, DemandAssetTypeId, IABAssets } from 'app/shared/models/native-demand';
import { AdvertiserRepository, BlueprintRepository, CreativeRepository, LineItemRepository, MediaGroupRepository, PublisherRepository } from 'app/core/repositories';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription } from 'rxjs/index';
import { debounceTime, distinctUntilChanged, map, mergeMap, takeUntil } from 'rxjs/internal/operators';
import { AuthorizationService, IdService, NotificationsService, ValidationService } from 'app/core';
import { DemandAssetService } from 'app/core/demand-asset.service';
import { FileInputComponent, UploadedFile } from 'app/shared/elements/file-input';
import { AdBuilderNativePreviewComponent } from 'app/shared/components/ad-builder/ad-builder-native-preview';
import { SearchableSelectSingleComponent } from 'app/shared/elements/searchable-select-single';
import { ErrorHelper } from 'app/core/errors';
import validationErrors from 'app/core/errors/validation-errors.json';
import { Flag, LaunchDarklyService } from 'app/core/launch-darkly.service';
import { sortBlueprintOptionsByName } from 'app/shared/helpers/blueprint-helper';
import { IMAGE_TYPE_GIF } from 'app/shared/helpers/ad-builder-helper';
import { GifReader } from 'omggif';
import { HttpBackend, HttpHeaders, HttpClient } from '@angular/common/http';
import { IfaasRepository } from 'app/core/repositories';

const MAX_NATIVE_FILE_SIZE = 1200; // in KB
const MAX_LOGO_FILE_SIZE = 250; // in KB
const MAX_GIF_NUM_FRAMES = 90;
const MAX_IFAAS_FILE_SIZE = 4; // in MB
const GIF_FILE_EXTENSION = '.gif';

@Component({
    selector: 'ad-builder-native-exchange',
    templateUrl: './ad-builder-native-exchange.html',
    styleUrls: ['./ad-builder-native-exchange.styl']
})
export class AdBuilderNativeExchangeComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('nativeForm', { static: true }) public nativeForm: NgForm;
    @Input() advertiserId: string;
    @Input() creative = this.createCreative();
    @Input() campaign: Campaign;
    @Input() lineItem: LineItem;
    @Input() showThirdPartyTracker = false;
    @Input() showStaqId = false;
    @Input() showExternalId = false;
    @Input() allowBlueprintChange = false;
    @Input() isNativeCreativeEdit = false;
    @Input() set placementId(value) {
        if (value) {
            this._placementId$.next(value);
            this.hasPlacementTargetingStrategy = true;
        } else {
            this.hasPlacementTargetingStrategy = false;
        }
    }
    @Input() errorHelper = null;
    @Input() selectedCampaigns: Campaign[] = [];

    @ViewChildren('fileInputComponent') public fileInputComponents: QueryList<FileInputComponent>;
    @ViewChild('preview', {static: false}) preview: AdBuilderNativePreviewComponent;
    @ViewChild('previewMobile', {static: false}) previewMobile: AdBuilderNativePreviewComponent;
    @Output() isCreativeSizeValid = new EventEmitter<CreativeSizeValidation>();

    readonly update$ = new ReplaySubject<void>(1);
    readonly creative$ = new ReplaySubject<Creative>(1);
    readonly blueprint$ = new ReplaySubject<number>(0);
    blueprintOptions$ = new ReplaySubject(1);
    demandAssetProperties = DemandAssetProperties;
    Native = Native;
    demandAssets = new DemandAsset();
    iabAssets = IABAssets;
    _secondary = false;
    _blueprints = [];
    _placementId$ = new BehaviorSubject<any>('');
    hasPlacementTargetingStrategy: boolean = false;
    hasPlacementTargetingCampaign: boolean = false;
    assetRestrictions: any = {};
    errorMessages = [];
    private readonly onDestroy$ = new Subject<void>();
    private readonly debounceTime = 1000;
    private blueprintSubscription: Subscription;
    private validationService: ValidationService = null;
    validationErrors = validationErrors;
    showBlueprintAlert = false;
    private ifaasImage$ = new Subscription();
    private ifaasImageFetchPendingMap = new Map<string, number>(); // CreativeId, notificationId
    private rolloutNativeCreativeTrafficking$ = new BehaviorSubject(false);
    public rolloutNativeCreativeTrafficking: boolean = false;
    private http: HttpClient;

    constructor(
        private cdr: ChangeDetectorRef,
        private creativeRepository: CreativeRepository,
        public id: IdService,
        private notificationService: NotificationsService,
        private lineItemRepository: LineItemRepository,
        private blueprintRepository: BlueprintRepository,
        private advertiserRepository: AdvertiserRepository,
        private mediaGroupRepository: MediaGroupRepository,
        private publisherRepository: PublisherRepository,
        public demandAssetService: DemandAssetService,
        private launchDarklyService: LaunchDarklyService,
        public auth: AuthorizationService,
        httpBackend: HttpBackend,
        public ifaasRepository: IfaasRepository,

    ) {
        this.http = new HttpClient(httpBackend);
    }

    /**
     * Init
     */
    ngOnInit() {
        this.validationService = new ValidationService();
        this.demandAssets = this.demandAssetService.initDemandAssets(this.demandAssets);

        this.getMediaGroup().pipe(mergeMap(mgId => this.blueprintRepository.basicSearch(mgId)
            .pipe(map(blueprints => {
                sortBlueprintOptionsByName(blueprints);
                return blueprints.map(item => {
                    this._blueprints.push(item);
                    return {
                        value: item.id,
                        key: item.id,
                        label: `(ID:${item.id}) ${item.displayName}`,
                    };
                })
            })),
        )).subscribe(blueprints => {
            this.blueprintOptions$.next(blueprints);
            if (this.creative) {
                if (this.creative.urlTracking2) {
                    this._secondary = true;
                }

                if (this.creative.refId) {
                    this.creativeRepository
                        .get(this.creative.refId)
                        .subscribe(creative => {
                            if (creative.native) {
                                this.setBlueprintId(this.creative.blueprintId);
                                this.demandAssets = this.demandAssetService.setAssets(this.demandAssets, creative.native.assets, this.update$);
                            }
                        });
                } else if (this.creative.native) {
                    this.setBlueprintId(this.creative.blueprintId);
                    if (!this.isNativeDirectSoldCreative) {
                        delete this.creative.blueprintId;
                        this.setIsModified();
                        this.setAllAssetsRequiredForNCT();
                    }

                    this.demandAssets = this.demandAssetService.setAssets(this.demandAssets, this.creative.native.assets, this.update$);

                    if (!this.creative.id) {
                        this.demandAssets = this.demandAssetService.unlinkDemandAssets(this.demandAssets);
                    }
                }
            }
            if (this.isNativeCreativeEdit) {
                this.setBlueprintId(this.creative.blueprintId);
            }
        });

        if (this.campaign && this.lineItem) {
            this.lineItemRepository.search({
                conditions: [{
                    field: 'campaign',
                    value: this.campaign.id
                }]
            }).subscribe(lineItems => {
                const lineItemsWPlacementTargeting = lineItems
                    .filter(lineItem => !(lineItem.placementId === '' || (!!this.lineItem.id && this.lineItem.id === lineItem.id)));
                this.hasPlacementTargetingCampaign = lineItemsWPlacementTargeting.length > 0;
            });
        }

        this.creative$.pipe(
            takeUntil(this.onDestroy$)
        ).subscribe((creative: Creative) => {
            if (typeof creative.native === 'string') {
                creative.native = JSON.parse(creative.native);
            }

            this.creative = creative;

            if (creative.isNative && creative.native && creative.native.assets) {
                this.demandAssets = this.demandAssetService.initDemandAssets(this.demandAssets);
                this.demandAssets = this.demandAssetService.setAssets(this.demandAssets, creative.native.assets, this.update$);

                this.setBlueprintId(creative.blueprintId);

                this.refreshPreviews();
            }
        });

        this.update$.pipe(
            debounceTime(this.debounceTime),
            takeUntil(this.onDestroy$),
        ).subscribe(() => {
            this.refreshPreviews();
            this.creative.native = {
                link: this.creative.clickUrl,
                assets: this.updateAssets()
            };
            this.cdr.markForCheck();
        });

        this.blueprint$.pipe(
            takeUntil(this.onDestroy$)
        ).subscribe((blueprintId) => {
            if (blueprintId) {
                this.creative.blueprintId = blueprintId;
                const blueprintObject = this._blueprints.find(blueprint => blueprint.id === this.creative.blueprintId);
                if (blueprintObject && ('width' in blueprintObject)) {
                    this.creative.blueprintWidth = blueprintObject.width;
                    if (blueprintObject && ('maxHeight' in blueprintObject)) {
                        this.creative.height = blueprintObject.maxHeight;
                        this.creative.width = blueprintObject.width;
                    }
                }
                this.creative.blueprintName = this._blueprints.find(blueprint => blueprint.id === this.creative.blueprintId).name;
                this.setSupplyAssets(blueprintId);
                this.creative.isDirectSoldTrafficking = false;
            } else {
                if (this.isDirectSoldCampaign || this.creative.isDirectSold) {
                    this.creative.isDirectSoldTrafficking = true;
                }

                this.creative.assetRestrictions = null;
                this.setAllAssetsRequiredForNCT();
            }
        });

        this.launchDarklyService
            .getVariation(Flag.RolloutNativeCreativeTrafficking)
            .pipe(distinctUntilChanged())
            .subscribe(rolloutNativeCreativeTrafficking => {
                this.rolloutNativeCreativeTrafficking$.next(rolloutNativeCreativeTrafficking);
            });

        this.rolloutNativeCreativeTrafficking$.subscribe(value => {
            this.rolloutNativeCreativeTrafficking = value;
        });
    }

    ngAfterViewInit() {
        this.cdr.detectChanges();
    }

    /**
     * Destroy
     */
    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    getMediaGroup(): Observable<number> {
        return this.advertiserRepository.get(this.advertiserId)
            .pipe(
                mergeMap(advertiser => {
                    if (advertiser.owner.type === 'Publisher') {
                        return this.publisherRepository.get(advertiser.owner.refId)
                            .pipe(
                                mergeMap(owner => this.mediaGroupRepository.get(owner.mediaGroup))
                            );
                    }

                    return this.mediaGroupRepository.get(advertiser.owner.refId);
                }),
                map(mediaGroup => mediaGroup.refId)
            );
    }

    /**
     * Update Assets
     * @returns {any[]}
     */
    updateAssets() {
        const assets = [];
        for (let prop of this.demandAssetProperties) {
            const asset = {
                id: null,
                required: null,
                version: null
            };
            const demandAsset = this.demandAssets[prop.modelKey];
            const key = this.demandAssets[prop.modelKey].typeDescription;
            if (!key) {
                continue;
            }

            asset[key] = {};
            asset.id = demandAsset.id || null;
            asset.required = demandAsset.requirement || false;
            asset.version = demandAsset.version || null;

            if (demandAsset.typeDescription === this.iabAssets.title
                || demandAsset.typeDescription === this.iabAssets.data) {
                asset[key].text = demandAsset.model;
                asset[key].len = this.setTextAssetLen(demandAsset);
            }

            if (demandAsset.typeId) {
                asset[key].type = demandAsset.typeId;
            }

            if (demandAsset.typeDescription === this.iabAssets.img && demandAsset.model) {
                asset[key].url = demandAsset.model;
                this.demandAssetService.fetchImageDimensions(demandAsset.model).subscribe(
                    ([width, height]) => {
                        asset[key].height = height;
                        asset[key].width = width;

                        if (height === 0 || width === 0) {
                            this.nativeForm.controls[prop.modelKey].setErrors({
                                valid: false
                            });
                        }
                    }
                );
            }

            if (demandAsset.model && !this.disableField(prop.modelKey)) {
                assets.push(asset);
            }
        }

        return assets;
    }

    isValidUrl(url: string): boolean {
        if (!url || url === '') {
            return true;
        }

        return ValidationService.isValidUrl(url);
    }

    isValidImage(type: number): boolean {
        this.cdr.markForCheck();
        if (this.creative && this.creative.native && this.creative.native.assets) {
            for (let asset of this.creative.native.assets) {
                if ('img' in asset) {
                    if (type === asset['img']['type']) {
                        const height = asset['img']['height'];
                        const width = asset['img']['width'];

                        if (height === 0 || width === 0) {
                            return false;
                        }
                    }
                }
            }
        }

        return true;
    }

    /**
     * Set Text Asset Len
     * @param demandAsset
     * @returns {any}
     */
    setTextAssetLen(demandAsset) {
        if (!demandAsset.model) {
            return;
        }

        return demandAsset.model.length;
    }

    calculateDisplayLength(demandAsset, multiLine) {
        if (!demandAsset || !demandAsset.model) {
            return 0;
        }
        if (multiLine) {
            var newlines = (demandAsset.model.match(/\n/g) || []).length;

            // Multiline fields will have newlines converted to <br>,
            // so we add 3 additional characters to the count for each one
            return demandAsset.model.length + (newlines * 3);
        }
        return demandAsset.model.length;
    }

    clearAsset(assetrestrictions : {}) {
        for (let prop of this.demandAssetProperties) {
            if (assetrestrictions && !assetrestrictions[prop.modelKey]) {
                this.demandAssets[prop.modelKey] = {
                    requirement: false,
                    model: null,
                    typeId: null,
                    typeDescription: null,
                    id: null
                };
            }
        }
    }

    /**
     * Refresh Preview
     * @param $event
     * @param prop
     */
    refreshPreview($event, prop) {
        if (prop.assetKey === 'img') {
            if (this.demandAssets[prop.modelKey].model) {
                const image = new Image();
                image.src = this.demandAssets[prop.modelKey].model;
                image.onload = () => {
                    this.demandAssets[prop.modelKey].width = image.width;
                    this.demandAssets[prop.modelKey].height = image.height;
                };
            } else {
                this.demandAssets[prop.modelKey].width = null;
                this.demandAssets[prop.modelKey].height = null;
            }
        }

        this.demandAssets[prop.modelKey].typeDescription = prop.assetKey;
        this.demandAssets[prop.modelKey].typeId = prop.type;

        this.setIsModified();
        this.update$.next();
    }

    /**
     * Update Ifaas Url
     * @param {string} url
     */
    updateIfaasUrl(url: string): void {
        if (url === this.creative.mediaUrl) {
            const id = this.creative.id ? this.creative.id : this.creative.created;
            this.isCreativeSizeValid.next({ id, isValid: true});
            return;
        }

        this.validateIfaasImageSize(url);
        this.creative.mediaUrl = url;
    }

    validateIfaasImageSize(url: string): void {
        const id = this.creative.id ? this.creative.id : this.creative.created;

        // Cancel any previous calls before making a new one for the same creative
        if (this.ifaasImageFetchPendingMap.has(id)) {
            this.ifaasImage$.unsubscribe();
            // Remove pending message
            this.notificationService.remove(this.ifaasImageFetchPendingMap.get(id));
        }

        // Set validation to false initially
        this.isCreativeSizeValid.next({ id, isValid: false});

        const warningId = this.hasAGifFile(url)
            ? this.notificationService.warning('Validating the creative size, please wait. This could take up to a minute.', '' , 60)
            : null;

        // Add creative ID and pending message ID to pending fetch list
        this.ifaasImageFetchPendingMap.set(id, warningId);

        // Add a cache buster param to ifaas url to avoid getting a cors error
        this.ifaasImage$ = this.http.get(this.addCacheBusterParam(url), {
            headers: new HttpHeaders({ Accept: 'image/jpeg, image/png, image/gif' }),
            responseType: 'blob',
        }).pipe(
            map((resp: Blob) => resp.size / 1000000)
        ).subscribe(
            fileSize => {
            if (warningId !== null) {
                this.notificationService.remove(warningId);
            }

            if (fileSize > MAX_IFAAS_FILE_SIZE) {
                this.notificationService.error('The resulting creative exceeds the maximum size of 4MB. Please use a different file.');
                this.isCreativeSizeValid.next({ id, isValid: false});
            } else {
                this.isCreativeSizeValid.next({ id, isValid: true});
            }

            // Remove creative ID from pending fetch list once completed
            this.ifaasImageFetchPendingMap.delete(id);
        }, () => {
            // Skip vaidation when ifaas does not respond
            this.isCreativeSizeValid.next({ id, isValid: true});
            // Remove creative ID from pending fetch list once completed
            this.ifaasImageFetchPendingMap.delete(id);
        });
    }

    hasAGifFile(inputUrl: string): boolean {
        const url = new URL(inputUrl);
        const iUrl = url.searchParams.get('iUrl');
        const lUrl = url.searchParams.get('lUrl');

        return iUrl.slice(-4) === GIF_FILE_EXTENSION || lUrl.slice(-4) === GIF_FILE_EXTENSION;
    }

    addCacheBusterParam(inputUrl: string): string {
        const currentTime = Date.now().toString();
        const url = new URL(inputUrl);

        url.searchParams.set('updatedAt', currentTime);

        return url.toJSON();
    }

    /**
     * Create Creative
     * @returns {Creative}
     */
    createCreative() {
        return new Creative({
            type: 'image',
            isNative: true,
            assetRestrictions: [],
            isDirectSoldTrafficking: false,
            created: Date.now()
        });
    }

    /**
     * Refresh On Click
     * @param {Creative} creative
     */
    refresh(creative: Creative) {
        this.creative$.next(creative);
    }

    /**
     * Reset
     */
    reset() {
        this.nativeForm.reset();
        this.cdr.detectChanges();
        this.creative = this.createCreative();
    }

    /**
     * Creative has assets
     * @returns {boolean}
     */
    hasAssets() {
        return this.creative.native.assets.length > 0 && this.hasRequiredAsset();
    }

    setAllAssetsRequiredForNCT() {
        if (this.isNCTCreative){
            for (let prop of this.demandAssetProperties) {
                this.demandAssets[prop.modelKey].requirement = true;
            }
            this.creative.blueprintId = null;
            this.creative.isDirectSoldTrafficking = true;
        }
    }

    /**
     * Creative has required asset
     * @returns {boolean}
     */
    hasRequiredAsset() {
        return this.creative.native && this.creative.native.assets.filter(asset => asset.required).length > 0;
    }

    /**
     * Is creative native direct sold
     */
    get isNativeDirectSoldCreative(): boolean {
        // To support both ad library ad builder form and line item ad builder form
        // On ad library it checks if the creative has a blueprint id or not to determine if it's direct sold or not
        // isDirectSold is only being used in the frontend for copying a direct sold creative which is being set by the AdsCopyResolver
        return (this.creative && !!this.creative.blueprintId) || this.creative.isDirectSold;
    }

    /**
     * Is creative NCT
     */
    get isNCTCreative(): boolean {
        return this.creative && ((this.isDirectSoldCampaign && !this.creative.blueprintId) || this.creative.isDirectSoldTrafficking);
    }

    get isBlueprintRequired(): boolean {
        return (this.isDirectSoldCampaign || this.isNativeDirectSoldCreative || this.allowBlueprintChange) && !this.rolloutNativeCreativeTrafficking;
    }

    get isDirectSoldCampaign(): boolean {
        // If there is a list of line items:
        if (this.selectedCampaigns && this.selectedCampaigns.length > 0) {
            return !this.findFirstNonDirectSoldCampaign(this.selectedCampaigns);
        }

        return (
            this.campaign && this.isNotExchangeCampaign(this.campaign) &&
            (this.campaign.isDirectSold || this.campaign.isHouse || this.hasAnyPlacementTargets() || this.isImpressionBasedCampaign(this.campaign))
        );
    }

    isImpressionBasedCampaign(campaign: Campaign): boolean {
        return campaign && campaign.isDirectSold && campaign.isImpressionBased;
    }

    hasAnyPlacementTargets(): boolean {
        return (this.hasPlacementTargetingCampaign || this.hasPlacementTargetingStrategy);
    }

    isNotExchangeCampaign(campaign: Campaign): boolean {
        return campaign && !campaign.isExchange;
    }

    findFirstNonDirectSoldCampaign(campaigns: Campaign[]): boolean {
        return campaigns.find((campaign: Campaign) => (
            (campaign && !this.isNotExchangeCampaign(campaign)) &&
            !(campaign.isDirectSold || campaign.isHouse || this.hasAnyPlacementTargets() || this.isImpressionBasedCampaign(campaign))
        )) != null;
    }

    handleImageUploadError(prop, message: string) {
        this.notificationService.error(message);

        // we get a reference to both file input directives
        const [mainImageFileInput, logoImageFileInput] = this.fileInputComponents.toArray();

        if (prop.modelKey === 'mainImage') {
            mainImageFileInput.removeFile(0);
        } else if (prop.modelKey === 'logoImage') {
            logoImageFileInput.removeFile(0);
        }
    }

    /**
     * Grabs the upload event, and does some safety
     * checks prior to actually loading update method
     * @param files
     * @param prop
     */
    async upload(files, prop) {
        let file: UploadedFile = null;
        if (files && files.length > 0) {
            file = files[0];
        }

        if (!file) {
            this.demandAssets[prop.modelKey] = {
                requirement: false,
                model: null,
                typeId: null,
                typeDescription: null,
                id: null
            };
            this.refreshPreview(files, prop);
            return;
        }

        // size validation
        const size = file.file.size / 1024; // in KB

        const maxFileSize = prop.modelKey === 'mainImage' ? MAX_NATIVE_FILE_SIZE : MAX_LOGO_FILE_SIZE;

        if (size > maxFileSize) {
            this.handleImageUploadError(prop, `The uploaded file exceeds the maximum file size of ${ maxFileSize } kb. Please try uploading another file.`);

            return;
        }

        // GIF validation
        const type = file.file.type;

        if (type === IMAGE_TYPE_GIF) {
            try {
                // https://stackoverflow.com/questions/60889795/property-arraybuffer-does-not-exist-on-type-file
                // @ts-ignore
                const arrayBuffer = await file.file.arrayBuffer();

                const intArray = new Uint8Array(arrayBuffer);

                const reader = new GifReader(intArray);

                const numFrames = reader.numFrames();

                if (numFrames > MAX_GIF_NUM_FRAMES) {
                    this.handleImageUploadError(prop, `The uploaded GIF exceeds the maximum number of ${MAX_GIF_NUM_FRAMES} frames. Please try uploading another file.`);

                    return;
                }
            } catch (e) {
                this.handleImageUploadError(prop, 'Something went wrong. Please try uploading another file.');

                return;
            }

        }

        this.load(file, prop);
    }

    /**
     * Passes file on to the demand asset service, and saves it
     * @param file
     * @param prop
     */
    load(file, prop) {
        this.demandAssetService.saveImageAssetForPreview(file, prop, this.isAssetRequired(prop.modelKey)).subscribe(assetDetails => {
            this.demandAssetService.setAssets(this.demandAssets, [assetDetails], this.update$);
            this.cdr.markForCheck();
        });
    }

    refreshPreviews() {
        this.preview.refresh();
        this.previewMobile.refresh();
    }

    get readOnly() {
        return (!this.auth.isAdmin && !this.auth.isNativeLikeUser && !(this.isNativeDirectSoldCreative || this.isDirectSoldCampaign)) ||
            (this.campaign && !this.auth.canManageMediaGroup && !this.auth.canManagePublisher && !this.auth.isAdOps
                && !this.auth.isAdmin && (this.isNativeDirectSoldCreative || this.isDirectSoldCampaign));
    }

    setBlueprintId(id: number) {
        if (id) {
            this.blueprint$.next(id);
        } else {
            this.blueprint$.next(null);

            // When you remove the blueprint remove the alert if there was one
            if (this.showBlueprintAlert) {
                this.showBlueprintAlert = false;
            }
        }
        this.update$.next();
    }

    focusIfBlueprintIsDeselected(model: SearchableSelectSingleComponent) {
        if (!model._selected) {
            model.editor.nativeElement.focus();
        }
    }

    setIsModified() {
        this.creative.isModified = true;
    }

    setSupplyAssets(blueprintId) {
        if (this.blueprintSubscription) {
            this.blueprintSubscription.unsubscribe();
        }

        this.blueprintSubscription = this.blueprintRepository.get(blueprintId).pipe(
            map((blueprint) => {
                this.assetRestrictions = [];
                blueprint.assets.forEach(asset => {
                    if (asset.title) {
                        this.assetRestrictions.headline = asset;
                    }
                    if (asset.img) {
                        switch (asset.img.type) {
                            case 1:
                                this.assetRestrictions.logoImage = asset;
                                break;
                            case 3:
                                this.assetRestrictions.mainImage = asset;
                                break;
                        }
                    }
                    if (asset.data) {
                        switch (asset.data.type) {
                            case 1:
                                this.assetRestrictions.sponsored = asset;
                                break;
                            case 2:
                                this.assetRestrictions.description = asset;
                                break;
                            case 12:
                                this.assetRestrictions.callToAction = asset;
                                break;
                        }
                    }
                });
            })
        ).subscribe(() => {
            this.creative.assetRestrictions = this.assetRestrictions;
            if (this.creative.assetRestrictions && this.isNativeDirectSoldCreative) {
                Object.keys(this.creative.assetRestrictions)
                    .filter(asset => this.creative.assetRestrictions[asset].required)
                    .map(assetName => {
                        this.demandAssets[assetName].requirement = this.creative.assetRestrictions[assetName].required;
                    }
                );
            }
            this.clearAsset(this.assetRestrictions);
            this.setIsModified();
            this.update$.next();
        }, () => {
            this.showBlueprintAlert = true;
        });
    }

    isAssetRequired(asset: string) {
        if (this.creative.assetRestrictions && this.creative.assetRestrictions[asset]) {
            return this.creative.assetRestrictions[asset].required;
        }
        return false;
    }

    setAssetLength(asset: string) {
        if (this.creative.assetRestrictions && this.creative.assetRestrictions[asset]) {
            if (this.creative.assetRestrictions[asset].title) {
                return this.creative.assetRestrictions[asset].title.len;
            }

            return this.creative.assetRestrictions[asset].data.len;
        } else if (this.isDirectSoldCampaign || this.isNCTCreative) {
            return SupplyAssetProperties.find(supplyAsset => supplyAsset.modelKey === asset).max;
        }

        return false;
    }

    setAssetSize(asset: string) {
        if (this.creative.assetRestrictions && this.creative.assetRestrictions[asset]) {
            if (this.creative.assetRestrictions[asset].img.w || this.creative.assetRestrictions[asset].img.h) {
                return 'Image asset must be ' + this.creative.assetRestrictions[asset].img.w + 'x' + this.creative.assetRestrictions[asset].img.h;
            }

            if (this.creative.assetRestrictions[asset].img.wmin || this.creative.assetRestrictions[asset].img.hmin) {
                return 'Image asset should be at least ' + this.creative.assetRestrictions[asset].img.wmin + 'x' + this.creative.assetRestrictions[asset].img.hmin;
            }
        } else if (this.isDirectSoldCampaign || this.isNCTCreative) {
            if (asset === 'mainImage') {
                return 'Image asset should be at least 50x50';
            }

            if (asset === 'logoImage') {
                return 'Image asset should be at least 1x1';
            }
        }

        return false;
    }

    disableField(asset) {
        // Should only be used for direct sold creatives - creatives that have a blueprint id selected only
        // If the blueprint doesn't support a specific asset, then that asset is disabled in the form from being added
        // Ex. A logo only blueprint would only allow a user to select a logo asset
        return this.isNativeDirectSoldCreative && this.creative.assetRestrictions && !this.creative.assetRestrictions[asset];
    }

    getInputErrorMessage(errorHelper: ErrorHelper, inputType: string) {
        if (errorHelper && errorHelper.apiErrors) {
            this.setErrorInputs(errorHelper);
        }

        switch (inputType) {
            case ('headline'):
                return this.errorMessages[DemandAssetTypeId.Title] || '';

            case ('sponsored'):
                return this.errorMessages[DemandAssetTypeId.SponsoredBy] || '';

            case ('description'):
                return this.errorMessages[DemandAssetTypeId.Description] || '';

            case ('callToAction'):
                return this.errorMessages[DemandAssetTypeId.CTA] || '';

            case ('logoImage'):
                return this.errorMessages[DemandAssetTypeId.ImageIcon] || '';

            case ('mainImage'):
                return this.errorMessages[DemandAssetTypeId.ImageMain] || '';

            default:
                return '';
        }
    }

    setErrorInputs(errorHelper: ErrorHelper) {
        if (errorHelper && errorHelper.apiErrors) {
            errorHelper.apiErrors.forEach(error => {
                const typeId = error.details.split(' - ')[0].split(':')[1];
                this.errorMessages[typeId] = error.details.split(' - ')[1];
            });
        }
    }

    getMaxAssetLength(asset: string) {
        if (this.isNativeDirectSoldCreative) {
            if (this.creative.assetRestrictions) {
                if (!this.creative.assetRestrictions[asset]) {
                    if (asset === 'description') {
                        return 2000;
                    }
                    return 255;
                }

                if (this.creative.assetRestrictions[asset].title) {
                    return Number(this.creative.assetRestrictions[asset].title.len);
                }

                return Number(this.creative.assetRestrictions[asset].data.len);
            }
        } else if (this.isDirectSoldCampaign || this.isNCTCreative) {
            return SupplyAssetProperties.find(supplyAsset => supplyAsset.modelKey === asset).max;
        }
        return null;
    }

    hasSizeRestrictionError(asset: string) {
        const demandAsset = this.demandAssets[asset];
        if (!demandAsset.model) {
            return false;
        }
        if (this.isNativeDirectSoldCreative) {
            if (this.creative.assetRestrictions && this.creative.assetRestrictions[asset] && demandAsset.model) {
                const supplyAsset = this.creative.assetRestrictions[asset];

                if ('img' in supplyAsset) {
                    if (supplyAsset.img.wmin && supplyAsset.img.hmin) {
                        if (demandAsset.width < supplyAsset.img.wmin || demandAsset.height < supplyAsset.img.hmin) {
                            return true;
                        }
                    }
                    if (supplyAsset.img.w && supplyAsset.img.h) {
                        if (demandAsset.width !== supplyAsset.img.w || demandAsset.height !== supplyAsset.img.h) {
                            return true;
                        }
                    }
                }
            }
        } else if (this.isDirectSoldCampaign || this.isNCTCreative) {
            if (asset === 'mainImage') {
                if (demandAsset.width < 50 || demandAsset.height < 50) {
                    return true;
                }
            } else if (asset === 'logoImage') {
                if (demandAsset.width < 1 || demandAsset.height < 1) {
                    return true;
                }
            }
        }
        return false;
    }

    hasAssetErrors() {
        return DemandAssetProperties.some((prop) => this.hasSizeRestrictionError(prop.modelKey));
    }

    get enableBlueprintEdit(): boolean {
        return this.enableCreativeBlueprintEdit || this.enableNctCreativeBlueprintEdit;
    }

    /**
     * Enable editing blueprint for non NCT creatives.
     */
    get enableCreativeBlueprintEdit(): boolean {
        if (this.selectedCampaigns.length > 0) {
            return !this.readOnly && this.isDirectSoldCampaign && this.allowBlueprintChange;
        }

        return !this.readOnly && (!this.creative.id || this.allowBlueprintChange);
    }

    /**
     * Enable editing blueprint for NCT creatives.
     */
    get enableNctCreativeBlueprintEdit(): boolean {
        return this.isDirectSoldCampaign && this.rolloutNativeCreativeTrafficking;
    }

    get displayBlueprintField(): boolean {
        // If there is an exchange campaign
        if (this.selectedCampaigns.length > 0 && !this.isDirectSoldCampaign) {
            this.creative.blueprintId = null;
            return false;
        }

        return this.isNativeDirectSoldCreative || this.isDirectSoldCampaign || this.isNCTCreative || this.allowBlueprintChange;
    }

    handleOnShowSecondaryTracker(isExpanded: boolean) {
        if (isExpanded === false) {
            this.creative.urlTracking2 = null;
        }
    }
}
