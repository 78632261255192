import { ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
var SwitchComponent = /** @class */ (function () {
    function SwitchComponent(cdr) {
        this.cdr = cdr;
        this.on = 'On';
        this.off = 'Off';
        this.disabled = false;
        this.onchange = function (_) { };
        this.ontouched = function () { };
        this._checked = false;
    }
    SwitchComponent.prototype.writeValue = function (checked) {
        if (typeof checked === 'boolean') {
            this.cdr.markForCheck();
            this._checked = checked;
        }
    };
    SwitchComponent.prototype.registerOnChange = function (fn) {
        this.onchange = fn;
    };
    SwitchComponent.prototype.registerOnTouched = function (fn) {
        this.ontouched = fn;
    };
    Object.defineProperty(SwitchComponent.prototype, "checked", {
        get: function () {
            return this._checked;
        },
        set: function (checked) {
            this._checked = checked;
            this.onchange(this._checked);
            this.ontouched();
        },
        enumerable: true,
        configurable: true
    });
    return SwitchComponent;
}());
export { SwitchComponent };
