export const PageSizeOptions = [
    {
        key: 0,
        value: 5,
        label: '5'
    },
    {
        key: 1,
        value: 10,
        label: '10'
    },
    {
        key: 2,
        value: 25,
        label: '25'
    },
    {
        key: 3,
        value: 100,
        label: '100'
    }
];
