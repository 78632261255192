import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MediaGroup } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { map } from 'rxjs/operators';

@Injectable()
export class MediaGroupRepository extends BackendRepository<MediaGroup> {
    public constructor(http: HttpClient) {
        super(http, '/media-group', MediaGroup);
    }

    public confirm(body: MediaGroup) {
        const params = [this.path, 'confirm'];
        if (body.id) {
            params.push(body.id);
        }

        return this.http.post(this.url(...params), body.serialize());
    }

    public activate(mediaGroup: MediaGroup): Observable<MediaGroup> {
        return this.http.post(this.url(this.path, 'activate', mediaGroup.id), {
            version: mediaGroup.version
        }).pipe(map(response => this.build(response['output'])));
    }

    public deactivate(mediaGroup: MediaGroup): Observable<MediaGroup> {
        return this.http.post(this.url(this.path, 'deactivate', mediaGroup.id), {
            version: mediaGroup.version
        }).pipe(map(response => this.build(response['output'])));
    }
}
