var ProgressBarComponent = /** @class */ (function () {
    function ProgressBarComponent(indeterminate) {
        this.indeterminate = indeterminate !== null;
    }
    Object.defineProperty(ProgressBarComponent.prototype, "percentDone", {
        get: function () {
            if (this.indeterminate) {
                return 100;
            }
            return this.total > 0 ? this.current / this.total * 100 : 0;
        },
        enumerable: true,
        configurable: true
    });
    return ProgressBarComponent;
}());
export { ProgressBarComponent };
