import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { PublisherRepository } from 'app/core/repositories';
import { IdService } from 'app/core';

@Component({
    selector: 'delete-publisher-dialog',
    templateUrl: './delete-publisher-dialog.html'
})

export class DeletePublisherDialogComponent {

    @Output()
    public deleted: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('dialog', { static: true })
    public dialog;

    public publisher: any = null;

    public constructor (
        public publisherRepository: PublisherRepository,
        public id: IdService
    ) {}

    public open(publisher): void {
        this.publisher = publisher;
        this.dialog.open();
    }

    public delete(): void {
        this.publisherRepository.delete(this.publisher.id)
            .subscribe(() => this.deleted.emit(this.publisher));
    }
}
