import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AudienceFile } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { NotificationsService } from 'app/core/notifications.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class LAURepository extends BackendRepository<AudienceFile> {
    constructor(
        http: HttpClient, private notifications: NotificationsService
    ) {
        super(http, '/lau', AudienceFile);
    }

    asyncSearch(params: any): Observable<{ items: AudienceFile[], page: number, pages: number, total: number}> {
        return this.http.get(this.url(this.path, 'status', this.stringifyParams(params)))
            .pipe(map(data => ({
                items: data['output'].map(item => this.build(item)),
                page: 1,
                pages: data['pages'],
                total: data['total']
            })));
    }

    private stringifyParams(params: any) {
        let str = '?' + params.conditions.map(condition => `${condition.field}=${condition.value}`).join('&');

        params.n ? str += `&number=${params.n}` : '';
        params.page ? str += `&page=${params.page}` : '';
        params.orderBy ? str += `&orderBy=${params.orderBy}` : '';
        params.sort ? str += `&sort=${params.sort}` : '';

        return str;
    }

    private getCredentials(): Observable<any> {
        return this.http.get(this.url(this.path, 'credentials'));
    }

    doS3Action(action) {
        return this.getCredentials().toPromise().then(function (creds) {
            let s3 = new AWS.S3({
                accessKeyId: creds.accessKeyId,
                secretAccessKey: creds.secretAccessKey,
                sessionToken: creds.sessionToken,
                region: 'us-east-1'
            });

            let bucketName = creds.bucketName;
            if (bucketName === undefined) {
                bucketName = environment.lau.bucket.name;
            }

            return action(s3, bucketName);
        });
    }

    downloadFile(fileName: string, key: string) {
        const download = (s3, bucketName) => {
            let params = {
                Bucket: bucketName,
                Key: `${key}/${fileName}`,
                Expires: 600
            };
            let metaParams = {
                Bucket: bucketName,
                Key: `${key}/${fileName}`,
            };

            s3.headObject(metaParams, (err) => {
                if (err && err.code === 'NotFound') {
                    this.notifications.error('Oops! The file you are trying to download cannot be found!');
                } else {
                    let url = s3.getSignedUrl('getObject', params);
                    let link = document.createElement('a');
                    link.href = url;
                    link.click();
                }
            });
        };

        return this.doS3Action(download);
    }
}
