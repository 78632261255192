import moment from 'moment';
import { clone } from 'app/core/utils';

export type TargetingType = 'include' | 'exclude' | 'allow' | 'block' | 'inherit';

export abstract class Model {
    id: string;
    refId?: number;
    name?: string;
    version: number;
    _isClone: boolean = false;

    constructor(from?: Object) {
        // If `from` is given then initialize `this` model with `from`.
        if (typeof from === 'object') {
            Object.assign(this, from);
        }
    }

    clone<T extends Model>(model?: { new (from?: {}): T; }) {
        if (model) {
            return new model(clone(this));
        }

        return new (this as any).constructor(clone(this));
    }

    shallowClone<T extends Model>() {
        return new (this as any).constructor(Object.assign({}, this));
    }

    abstract get entity(): string;

    isNew(): boolean {
        return !this.id;
    }

    isClone(): boolean {
        return this._isClone;
    }

    serialize(): string {
        return JSON.parse(JSON.stringify(this));
    }

    getTime(date: string) {
        return moment(date).format('HH:mm');
    }

    createDatetime(date: string, time: string) {
        const datetime = moment(date);
        const timestamp = moment(time, 'HH:mm:ss');
        datetime.hours(timestamp.hours());
        datetime.minutes(timestamp.minutes());
        datetime.seconds(timestamp.seconds());
        return datetime.format('YYYY-MM-DD HH:mm:ss');
    }
}
