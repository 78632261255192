import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, ReplaySubject, forkJoin, throwError } from 'rxjs';
import { tap, mergeMap, throwIfEmpty } from 'rxjs/operators';
import { AccessList } from 'app/shared/models';
import { environment } from 'src/environments/environment';
import { TokenService } from './token.service';
import { UserRepository } from './repositories';
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(http, tokenService, userRepository) {
        this.http = http;
        this.tokenService = tokenService;
        this.userRepository = userRepository;
        this.currentUser = new ReplaySubject(1);
        this.currentAccessList = new BehaviorSubject(new AccessList());
        this.accessList$ = new ReplaySubject(1);
    }
    /**
     * Refresh the current user's details and access.
     */
    AuthenticationService.prototype.refreshUser = function () {
        var _this = this;
        return this.me().pipe(mergeMap(function (me) {
            if (me && me.id) {
                return forkJoin(_this.userRepository.get(me.id), _this.userRepository.getAccessList(me.id));
            }
            else {
                return throwError(new Error());
            }
        }), tap(function (_a) {
            var user = _a[0], accessList = _a[1];
            _this.currentUser.next(user);
            _this.currentAccessList.next(accessList);
            _this.accessList$.next(accessList);
        }));
    };
    Object.defineProperty(AuthenticationService.prototype, "isLoggedInAs", {
        /**
         * Check if the user is currently "logged in as" another user.
         */
        get: function () {
            return this.tokenService.isLoggedInAs;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Login as another user.
     *
     * @param  {string} userId
     */
    AuthenticationService.prototype.loginAs = function (userId) {
        var _this = this;
        return this.userRepository.loginAs(userId).pipe(tap(function (session) { return _this.tokenService.setLoggedInAsTokens(session); }), mergeMap(function () { return _this.refreshUser(); }));
    };
    /**
     * Log out of the "logged in as" user and return to the
     * authenticated user's session.
     */
    AuthenticationService.prototype.logoutAs = function () {
        this.tokenService.clearLoggedInAsTokens();
        return this.refreshUser();
    };
    /**
     * Create a new password.
     *
     * @param  {string} email
     * @param  {string} code
     * @param  {string} newPassword
     * @param  {string} confirmPassword
     */
    AuthenticationService.prototype.createNewPassword = function (email, code, newPassword, confirmPassword) {
        var params = { email: email, code: code, password: newPassword, confirmPassword: confirmPassword };
        return this.http.post(this.url('/auth/password/reset'), params);
    };
    /**
     * Request an email be sent to the user with password reset
     * instructions.
     *
     * @param  {string} email
     */
    AuthenticationService.prototype.requestPasswordReset = function (email) {
        return this.http.put(this.url('/auth/password/forgot'), { email: email });
    };
    /**
     * Fetch current user info.
     */
    AuthenticationService.prototype.me = function () {
        return this.http.get(environment.backend.hostname + "/auth/me")
            .pipe(throwIfEmpty(function () { return new Error(); }));
    };
    /**
     * Builds and returns a URL for the backend API given an array of strings
     * `suffix` which are joined using a forward-slash.
     * @param suffix
     */
    AuthenticationService.prototype.url = function () {
        var suffix = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            suffix[_i] = arguments[_i];
        }
        return environment.backend.hostname + suffix.join('/');
    };
    return AuthenticationService;
}());
export { AuthenticationService };
