import * as tslib_1 from "tslib";
import { Model } from './model';
var AccessList = /** @class */ (function (_super) {
    tslib_1.__extends(AccessList, _super);
    function AccessList(from) {
        if (from === void 0) { from = {}; }
        var _this = _super.call(this, null) || this;
        _this.entities = from;
        return _this;
    }
    AccessList.prototype.serialize = function () {
        return null;
    };
    AccessList.prototype.hasAccess = function (entityType, role, entityId) {
        var data = this.entities[entityType];
        // If `entityType` does not exists in the `rbac` object
        // then the role is not there either.
        if (data === undefined) {
            return false;
        }
        // We only accept `data` as an array. It should be an array of objects
        // which have at least a property `roles` and possibly a property `id`
        // of the entity.
        if (!Array.isArray(data)) {
            return false;
        }
        // Initialize `roles` as null and importantly not an array.
        var roles = null;
        // Find the actual roles we are looking for, depending on `entityType` and
        // possibly `entity`.
        if (!entityId) {
            for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
                var grant = data_1[_i];
                if (Array.isArray(grant.roles) && !Array.isArray(roles)) {
                    roles = grant.roles;
                }
                else if (Array.isArray(grant.roles) && Array.isArray(roles)) {
                    roles = roles.concat(grant.roles);
                }
            }
        }
        else {
            for (var i = 0; i < data.length; i++) {
                if (data[i].id === entityId) {
                    roles = data[i].roles;
                    break;
                }
            }
        }
        // We only accept `roles` as an array.
        if (!Array.isArray(roles)) {
            return false;
        }
        // If `role` is in `roles` we return true.
        if (roles.indexOf(role) > -1) {
            return true;
        }
        // Otherwise, return false.
        return false;
    };
    Object.defineProperty(AccessList.prototype, "entity", {
        get: function () {
            return 'Access List';
        },
        enumerable: true,
        configurable: true
    });
    return AccessList;
}(Model));
export { AccessList };
