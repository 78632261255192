import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';

import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { LocationHelperService, IdService } from 'app/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { SearchInputComponent } from 'app/shared/elements/search-input/search-input.component';
import { Location, TargetedLocation } from 'app/shared/models';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';

@Component({
    selector: 'geo-targeting',
    templateUrl: './geo-targeting.html',
    styleUrls: ['./geo-targeting.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GeoTargetingComponent),
            multi: true
        }
    ]
})
export class GeoTargetingComponent implements OnInit, ControlValueAccessor {
    private static UnitedStates = 235;

    private static isUnitedStatesLocation(location: Location) {
        return location.country && location.country.countryId === GeoTargetingComponent.UnitedStates
            || location.id === GeoTargetingComponent.UnitedStates;
    }

    private static isSameLocation(a: Location, b: Location | TargetedLocation) {
        return a.id === b.id && a.type === b.type;
    }

    targetingOptions = [
        { key: 0, value: 'include', label: 'Target' },
        { key: 1, value: 'exclude', label: 'Exclude' }
    ];

    @ViewChild(SearchInputComponent, { static: true }) searchInput: SearchInputComponent;
    @Input() targeting = 'include';
    @Output() targetingChange = new EventEmitter<string>();
    searchValue = new BehaviorSubject<string>('');
    unitedStatesOnly = new ReplaySubject<boolean>(1);
    selectedLocations = new BehaviorSubject<Location[]>([]);
    searchableLocations: Location[] = [];

    private onChange = (selectedLocations: TargetedLocation[]) => { };

    constructor(
        private cdr: ChangeDetectorRef,
        private locationHelper: LocationHelperService,
        public id: IdService
    ) { }

    ngOnInit() {
        combineLatest(
            this.searchValue.pipe(
                distinctUntilChanged(),
                debounceTime(100)
            ),
            this.selectedLocations,
            this.unitedStatesOnly
        ).subscribe(([value, selectedLocations, unitedStatesOnly]) =>
            this.updateSearchableLocations(value, selectedLocations, unitedStatesOnly));

        this.searchInput.inputChange.subscribe(value => this.searchValue.next(value));

        this.selectedLocations.subscribe(selectedLocations => {
            this.onChange(selectedLocations.map(selectedLocation => ({
                id: selectedLocation.id,
                type: selectedLocation.type
            })));
        });
    }

    writeValue(selectedLocations: TargetedLocation[]) {
        if (Array.isArray(selectedLocations)) {
            this.locationHelper.promise.then(() => {
                this.selectedLocations.next(selectedLocations.map(selectedLocation => {
                    return this.locationHelper.database.find(location =>
                        GeoTargetingComponent.isSameLocation(location, selectedLocation));
                }));
            });
        }
    }

    registerOnChange(onChange: (selectedLocations: TargetedLocation[]) => { }) {
        this.onChange = onChange;
    }

    registerOnTouched() { }

    updateSearchableLocations(value: string, selectedLocations: Location[], unitedStatesOnly: boolean) {
        this.locationHelper.promise.then(() => {
            const pattern = new RegExp(value, 'i');
            this.searchableLocations = this.locationHelper.database
                .filter(location => !unitedStatesOnly || GeoTargetingComponent.isUnitedStatesLocation(location))
                .filter(location => !selectedLocations.some(selectedLocation =>
                    GeoTargetingComponent.isSameLocation(location, selectedLocation)))
                .filter(location => pattern.test(location.display))
                .slice(0, 10);
            this.cdr.markForCheck();
        });
    }

    selectLocation(location: Location) {
        this.selectedLocations.next(this.selectedLocations.getValue().concat(location));
    }

    updateSelectedLocations(selectedLocations: Location[]) {
        this.selectedLocations.next(selectedLocations);
    }

    @Input() set usonly(value: boolean) {
        this.unitedStatesOnly.next(value);
    }

    updateTargeting(targeting: string) {
        this.targetingChange.emit(targeting);
    }

    clearSelectedLocations() {
        this.selectedLocations.next([]);
    }
}
