import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LightboxComponent } from 'app/shared/elements/lightbox';
import { NewsletterRepository, PublisherRepository } from 'app/core/repositories';
import { DownloadHelper } from 'app/shared/helpers/download-helper';
import { IdService } from 'app/core';

enum Type {
    Publisher,
    Newsletter
}

@Component({
    selector: 'download-tags',
    templateUrl: './download-tags.html'
})
export class DownloadTagsComponent {
    @ViewChild(LightboxComponent, { static: true }) lightbox: LightboxComponent;
    @ViewChild(NgForm, { static: true }) form: NgForm;
    @Input() advertiser: any;
    @Output() saved = new EventEmitter<void>();

    private type: 'Newsletter' | 'Publisher';
    private hash: string;
    private refId: number;
    public maxWidth: number = null;
    public maxMobileWidth: number = null;

    constructor(
        private newsletterRepository: NewsletterRepository,
        private publisherRepository: PublisherRepository,
        public id: IdService
    ) {}

    public open(hash, refId, type: 'Publisher' | 'Newsletter'): void {
        this.hash = hash;
        this.refId = refId;
        this.type = type;
        this.lightbox.open();
        this.form.controls['maxWidth'].markAllAsTouched();
    }

    public onClose(): void {
        this.form.reset();
    }

    get repository(): NewsletterRepository | PublisherRepository {
        switch (this.type) {
            case Type[Type.Newsletter]:
                return this.newsletterRepository;
            case Type[Type.Publisher]:
                return this.publisherRepository;
            default:
                throw 'Invalid Repository Type';
        }
    }

    disabled(): boolean {
        return !this.form.valid;
    }

    public downloadTags(): void {
        let args = [this.hash];

        if (this.maxWidth !== null) {
            args.push(this.maxWidth.toString());
        }
        if (this.maxWidth !== null && this.maxMobileWidth !== null) {
            args.push(this.maxMobileWidth.toString());
        }

        this.repository.downloadTags(args)
            .subscribe(data => {
                DownloadHelper.downloadFile(data, `${this.type}Tags-${this.refId}.xls`);
            });

        this.lightbox.close();
    }
}
