import { Component, ContentChild, HostBinding, ElementRef, Input, Renderer2, TemplateRef } from '@angular/core';
import { overlayLoadingIndicatorAnimation } from 'app/shared/animations';
import { IdService } from 'app/core/id.service';

@Component({
    selector: 'overlay-loading-indicator',
    templateUrl: './overlay-loading-indicator.html',
    styleUrls: ['./overlay-loading-indicator.styl'],
    animations: [overlayLoadingIndicatorAnimation]
})
export class OverlayLoadingIndicatorComponent {
    @ContentChild(TemplateRef, { static: true }) template: TemplateRef<any>;
    @HostBinding('@overlayLoadingIndicatorAnimation') overlayLoadingIndicatorAnimation;

    @Input() set opacity(opacity: number) {
        this.renderer.setStyle(this.el.nativeElement, 'opacity', opacity || 0.5)
    }

    @Input() set layer(layer: number) {
        this.renderer.setStyle(this.el.nativeElement, 'z-index', layer || 0)
    }

    constructor(
        private renderer: Renderer2, 
        private el: ElementRef, 
        public id: IdService
    ) {}
}
