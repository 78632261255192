/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./option.component";
var styles_OptionComponent = [];
var RenderType_OptionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OptionComponent, data: {} });
export { RenderType_OptionComponent as RenderType_OptionComponent };
export function View_OptionComponent_0(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0)], null, null); }
export function View_OptionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "option", [], null, null, null, View_OptionComponent_0, RenderType_OptionComponent)), i0.ɵdid(1, 49152, null, 0, i1.OptionComponent, [i0.ElementRef], null, null)], null, null); }
var OptionComponentNgFactory = i0.ɵccf("option", i1.OptionComponent, View_OptionComponent_Host_0, { value: "value", disabled: "disabled" }, {}, ["*"]);
export { OptionComponentNgFactory as OptionComponentNgFactory };
