import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Buyer } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
var BuyerRepository = /** @class */ (function (_super) {
    tslib_1.__extends(BuyerRepository, _super);
    function BuyerRepository(http) {
        return _super.call(this, http, '/buyer', Buyer) || this;
    }
    return BuyerRepository;
}(BackendRepository));
export { BuyerRepository };
