import { Model } from './model';

export class Preferences extends Model {
    advertiserIds: string[] = [];
    mediaGroupIds: string[] = [];
    watchedLineItemIds: string[] = [];

    constructor(data: any = {}) {
        super();

        Object.assign(this, data);
    }

    serialize(): string {
        return JSON.stringify(this);
    }

    get entity() {
        return 'Preferences';
    }
}
