import {
    Component,
    forwardRef,
    ContentChildren,
    QueryList,
    AfterViewInit
} from '@angular/core';

import {
    NG_VALUE_ACCESSOR,
    ControlValueAccessor
} from '@angular/forms';

import { OptionComponent } from './option.component';

@Component({
    selector: 'filter-select',
    templateUrl: './filter-select.html',
    styleUrls: ['./filter-select.styl'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FilterSelectComponent),
            multi: true
        }
    ]
})
export class FilterSelectComponent implements ControlValueAccessor, AfterViewInit {
    @ContentChildren(OptionComponent)
    options: QueryList<OptionComponent> = null;

    private _active: number = null;

    public writeValue(value: any): void {
        if (value !== null) {
            this._active = this.options.toArray().findIndex(element => element.value === value);
        }
    }

    public registerOnChange(fn: any): void {

    }

    public registerOnTouched(fn: any): void {

    }

    public ngAfterViewInit(): void {

    }

    active(index: number): boolean {
        return this._active === index;
    }
}
