import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { WhitelistingRule } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { map } from 'rxjs/operators';
var WhitelistingRuleRepository = /** @class */ (function (_super) {
    tslib_1.__extends(WhitelistingRuleRepository, _super);
    function WhitelistingRuleRepository(http) {
        return _super.call(this, http, '/lcidc/rule', WhitelistingRule) || this;
    }
    WhitelistingRuleRepository.prototype.get = function (id) {
        var _this = this;
        return this.http.get(this.url(this.path + 's', id))
            .pipe(map(function (response) { return _this.build(response); }));
    };
    WhitelistingRuleRepository.prototype.delete = function (id) {
        return this.http.delete(this.url(this.path + 's', id));
    };
    WhitelistingRuleRepository.prototype.getByAdvertiserId = function (advertiserId) {
        var _this = this;
        return this.http.get(this.url('/lcidc/rules?advertiserId=' + advertiserId))
            .pipe(map(function (response) { return response; }), map(function (output) { return output.map(function (rule) { return _this.build(rule); }); }));
    };
    /**
     * Returns an observable that saves `WhitelistingRule` in the backend.
     */
    WhitelistingRuleRepository.prototype.save = function (instance) {
        return this.saveEventsByExternalId(instance.type, instance);
    };
    /**
     * Returns an observable that saves `WhitelistingRule` in the backend.
     */
    WhitelistingRuleRepository.prototype.saveEventsByExternalId = function (ruleType, instance) {
        var _this = this;
        var params = [this.path + '/' + ruleType];
        if (instance.segmentId) {
            params.push(instance.segmentId);
        }
        return this.http.post(this.url.apply(this, params), instance.serialize())
            .pipe(map(function (response) { return _this.build(response); }));
    };
    return WhitelistingRuleRepository;
}(BackendRepository));
export { WhitelistingRuleRepository };
