import { ElementRef, OnInit, Renderer2, } from "@angular/core";
import { ControlValueAccessor } from "@angular/forms";
var NumberInputDirective = /** @class */ (function () {
    function NumberInputDirective(el, renderer) {
        this.el = el;
        this.renderer = renderer;
        this.value = null;
        this.propagate = null;
        this.onTouchedCallback = null;
    }
    NumberInputDirective.prototype.ngOnInit = function () {
        this.el.nativeElement.placeholder = this.placeholder;
    };
    NumberInputDirective.prototype.writeValue = function (value) {
        if (value === null || value === undefined) {
            this.renderer.setStyle(this.el.nativeElement, "text-align", "left");
            this.reset();
            return;
        }
        this.renderer.setStyle(this.el.nativeElement, "text-align", "right");
        var input = this.el.nativeElement;
        this.value = value;
        input.value = this.formatNumber(this.value.toString());
    };
    NumberInputDirective.prototype.reset = function () {
        this.el.nativeElement.value = null;
        this.el.nativeElement.placeholder = this.placeholder;
        this.renderer.setStyle(this.el.nativeElement, "text-align", "left");
        this.value = null;
    };
    Object.defineProperty(NumberInputDirective.prototype, "placeholder", {
        get: function () {
            return this._placeholder ? this._placeholder : "";
        },
        set: function (placeholder) {
            this._placeholder = placeholder;
            this.el.nativeElement.placeholder = this.placeholder;
        },
        enumerable: true,
        configurable: true
    });
    NumberInputDirective.prototype.registerOnChange = function (fn) {
        this.propagate = fn;
    };
    NumberInputDirective.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    NumberInputDirective.prototype.onFocus = function () {
        var input = this.el.nativeElement;
        input.value = this.value;
        input.placeholder = "";
        this.renderer.setStyle(this.el.nativeElement, "text-align", "right");
    };
    NumberInputDirective.prototype.onBlur = function () {
        var input = this.el.nativeElement;
        if (input.value.length > 0) {
            var numericValueAsString = input.value.replace(/[^0-9\.]+/g, "");
            if (numericValueAsString.length < 1) {
                this.reset();
            }
            else {
                input.value = this.formatNumber(numericValueAsString);
                this.value = parseFloat(input.value.replace(/[,]/g, ""));
            }
        }
        else {
            this.reset();
        }
        this.propagate(this.value);
        this.onTouchedCallback();
    };
    NumberInputDirective.prototype.formatNumber = function (value) {
        if (!value)
            return "0";
        var parts = parseFloat(value.replace(/,/g, "")).toString().split(".");
        // Add commas to integer part
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    };
    Object.defineProperty(NumberInputDirective.prototype, "disabled", {
        set: function (isDisabled) {
            this.el.nativeElement.disabled = isDisabled;
        },
        enumerable: true,
        configurable: true
    });
    return NumberInputDirective;
}());
export { NumberInputDirective };
