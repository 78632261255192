import { Component, Input, ViewChild, ContentChild, TemplateRef, Attribute, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, forkJoin, of, combineLatest } from 'rxjs';
import { catchError, tap, switchMap, mergeMap, map } from 'rxjs/operators';

import {
    AdvertiserRepository,
    CampaignRepository,
    LineItemRepository
} from 'app/core/repositories';

import { BudgetHelper } from 'app/shared/helpers';
import { AvailableActionsService } from 'app/shared/helpers/available-actions.service';
import { BulkResponseProcessor, NotificationsService, PreferencesService } from 'app/core';
import { CampaignManagerDataService } from 'app/platform/campaign-manager/campaign-manager-data.service';
import { ConfirmDialogComponent } from 'app/shared/elements/confirm-dialog';
import { GoingLiveNotificationComponent } from '../going-live-notification';
import { HistoryViewComponent } from 'app/shared/components/history-view';
import { IdService } from 'app/core/id.service';
import { LineItem, Campaign } from 'app/shared/models';
import { LineItemBulkEditComponent } from 'app/shared/components/line-item-bulk-edit';
import { PaginatorComponent } from 'app/shared/elements/paginator';
import { SearchParams } from 'app/shared/helpers/query-builder';
import { Status } from 'app/shared/models/status';
import { StatusDialogComponent } from 'app/shared/elements/status-dialog';
import { TableComponent } from 'app/shared/elements/table';
import { TableHelper } from 'app/shared/helpers/table-helper';

enum LineItemAction {
    Activate,
    Pause,
    Delete,
    Watch,
    Unwatch,
    Notify,
    ViewHistory,
    Copy,
    EditName,
    EditBudget
}

@Component({
    selector: 'line-items-table',
    templateUrl: './line-items-table.html',
    styleUrls: ['./line-items-table.styl']
})
export class LineItemsTableComponent implements OnInit {
    @ContentChild('actions', { static: true }) actionsTemplate: TemplateRef<any>;
    @ViewChild('deleteDialog', { static: true }) deleteDialog: ConfirmDialogComponent;
    @ViewChild('statusDialog', { static: true }) statusDialog: StatusDialogComponent;
    @ViewChild('goingLiveNotification', { static: true }) goingLiveNotification: GoingLiveNotificationComponent;
    @ViewChild(TableComponent, { static: true }) table: TableComponent;
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent;
    @ViewChild(HistoryViewComponent, { static: true }) private historyView: HistoryViewComponent;
    @ViewChild(LineItemBulkEditComponent, { static: true }) bulkEditor: LineItemBulkEditComponent;
    @Input('is-selectable') isSelectable: boolean = false;
    @Input('show-actions-menu') showActionsMenu: boolean = true;
    @Input() advertiser: string = null;
    disableDateSort: boolean;
    helper = new TableHelper<LineItem>(params => this.adapter(params));
    LineItemAction = LineItemAction;

    private _ids: string[] = null;
    private _lineItemIds: string[] = null;
    private _creative: string;

    defaultColumns: string[] = [
        'funnel',
        'name',
        'campaignName',
        'status',
        'budget',
        'dailySpend',
        'spend',
        'pacing',
        'effectiveStartDate',
        'effectiveEndDate'
    ];

    constructor(
        public advertiserRepository: AdvertiserRepository,
        public availableActionsService: AvailableActionsService,
        public campaignRepository: CampaignRepository,
        public campaignStatus: CampaignManagerDataService,
        public lineItemRepository: LineItemRepository,
        public router: Router,
        public preferencesService: PreferencesService,
        private notifications: NotificationsService,
        public id: IdService,
        @Attribute('disable-date-sort') disableDateSort: string
    ) {
        this.disableDateSort = disableDateSort === 'true';
    }

    ngOnInit() {
        this.helper.table = this.table;
        this.helper.paginator = this.paginator;
        combineLatest(this.table.query, this.paginator.query).subscribe(args => this.helper.search(args));
    }

    @Input() set campaigns(value: string[]) {
        this._ids = value;
        this.paginator.reset();
    }

    @Input('line-items') set lineItems(value: string[]) {
        this._lineItemIds = value;
        this.paginator.reset();
    }

    @Input() set creative(id: string) {
        this._creative = id;
    }

    refresh() {
        this.paginator.reset();
        this.table.clearSelections();
    }

    private buildParams(params: SearchParams) {
        if (!this._creative) {
            if (Array.isArray(this._ids)) {
                params.conditions.push({
                    field: 'campaign',
                    value: this._ids
                });
            }

            if (Array.isArray(this._lineItemIds)) {
                params.conditions.push({
                    field: 'id',
                    value: this._lineItemIds
                });
            }

            if (typeof this.advertiser === 'string') {
                params.conditions.push({
                    field: 'advertiser',
                    value: this.advertiser
                });
            }
        }

        params.returnMode = 'appended';
        params.return = [
            'budget',
            'dailyCap',
            'spend',
            'bidAmount',
            'impressions',
            'clicks',
            'conversions',
            'adjustedImpressions',
            'platformStatus'
        ];

        if (this._creative) {
            params['creative'] = this._creative;
        }

        return params;
    }

    private adapter(rawParams: SearchParams) {
        const params = this.buildParams(rawParams);

        return of(true).pipe(
            switchMap(() => {
                if (this._creative) {
                    if (!params.conditions.length) delete params.conditions;
                    return this.lineItemRepository.asyncSearchByCreative(params);
                } else {
                    return this.lineItemRepository.asyncSearch(params);
                }
            }),
            mergeMap(response => {
                if (response.items.length > 0) {
                    return forkJoin(response.items.map(lineItem => {
                        if (lineItem.campaignDemandType !== 'exchange') {
                            return forkJoin(
                                this.campaignStatus.fetchAdSlots(lineItem.id),
                                this.campaignStatus.fetchBundles(lineItem.id),
                                this.campaignStatus.fetchPackages(lineItem.id)
                            ).pipe(map(([adSlots, bundles, packages]) => {
                                lineItem.adSlots = adSlots.map(adSlot => adSlot.id);
                                lineItem.bundles = bundles.map(bundle => bundle.id);
                                lineItem.packages = packages;
                                return lineItem;
                            }));
                        } else {
                            return of(lineItem);
                        }
                    })).pipe(
                        map(lineItems => ({
                            items: lineItems,
                            pages: response.pages,
                            total: response.total,
                            page: params.page
                        }))
                    );
                } else {
                    return of({
                        items: [],
                        pages: 0,
                        total: 0,
                        page: params.page
                    });
                }
            })
        );
    }

    getLineItemCampaign(lineItem: LineItem) {
        return this.campaignStatus.fetchCampaign(lineItem.campaign);
    }

    lineItemPlatformStatus(lineItem: LineItem): Status {
        return lineItem.getPlatformStatus();
    }

    showPacingPill(lineItem: LineItem): boolean {
        return this.lineItemPlatformStatus(lineItem) === Status.COMPLETED || this.lineItemPlatformStatus(lineItem) === Status.DELIVERING;
    }

    private preventBulkAction(lineItems: LineItem[], action: string) {
        if (lineItems.length > 1) {
            this.notifications.error('Please select a single line item to ' + action + ' at a time.');
            return true;
        }

        return false;
    }

    clickedAction(event: string, lineItem: LineItem) {
        switch (event) {
        case 'edit':
            this.router.navigate(['/campaign-manager/line-items', lineItem.refId, 'edit', {
                redirect: btoa(this.router.url)
            }]);
            break;
        }
    }

    handle(action: LineItemAction, lineItems: LineItem[]) {
        switch (action) {
            case LineItemAction.Activate:
                this.changeStatus(lineItems, LineItemAction.Activate);
                break;
            case LineItemAction.Pause:
                this.changeStatus(lineItems, LineItemAction.Pause);
                break;
            case LineItemAction.EditName:
                this.bulkEditor.open(lineItems, 'name');
                break;
            case LineItemAction.EditBudget:
                this.bulkEditor.open(lineItems, 'budget');
                break;
            case LineItemAction.Copy:
                if (!this.preventBulkAction(lineItems, 'copy')) {
                    this.router.navigate(['/campaign-manager/line-items/new', {
                        cloneFrom: lineItems[0].id,
                        redirect: btoa(this.router.url)
                    }]);
                }
                break;
            case LineItemAction.Notify:
                if (!this.preventBulkAction(lineItems, 'send a notification for')) {
                    this.goingLiveNotification.init(lineItems[0]);
                }
                break;
            case LineItemAction.Watch:
                lineItems.forEach(lineItem => this.preferencesService.watchLineItem(lineItem));
                break;
            case LineItemAction.Unwatch:
                lineItems.forEach(lineItem => this.preferencesService.unwatchLineItem(lineItem));
                break;
            case LineItemAction.Delete:
                this.deleteDialog.open('Delete');
                break;
            case LineItemAction.ViewHistory:
                if (!this.preventBulkAction(lineItems, 'view history for')) {
                    this.historyView.viewLineItemHistory(lineItems[0]);
                }
                break;
        }
    }

    private changeStatus(lineItems: LineItem[], action: LineItemAction) {
        this.statusDialog.open(action === LineItemAction.Pause ? 'pause' : 'activate', 'line item');
        this.statusDialog.initiate(lineItems.length);

        const requests = lineItems.map(lineItem => {
            return this.lineItemRepository.get(lineItem.id).pipe(
                mergeMap(li => {
                    li.status = action === LineItemAction.Pause ? 'paused' : 'active';
                    return this.lineItemRepository.save(li);
                }),
                tap(() => this.statusDialog.postProgress()),
                catchError(e => {
                    this.statusDialog.postProgress();
                    return of(e);
                })
            )
        });

        forkJoin(requests).subscribe(
            responses => this.handleStatusChange(lineItems, responses, action),
            (e) => this.handleGenericError(e)
        );
    }

    deleteLineItems(lineItems: LineItem[]) {
        this.deleteDialog.initiate(lineItems.length);

        const requests = lineItems.map(lineItem => {
            return this.lineItemRepository.delete(lineItem.id)
                .pipe(
                    tap(() => this.deleteDialog.postProgress()),
                    catchError(e => {
                        this.deleteDialog.postProgress();
                        return of(e);
                    })
                )
        });

        forkJoin(requests).subscribe(
            responses => this.handleDeleted(lineItems, responses),
            (e) => this.handleGenericError(e)
        );
    }

    handleDeleted(attempted: LineItem[], responses: any[]) {
        const { status, failureItems, successItems } = (new BulkResponseProcessor()).processDeletes(attempted, responses);

        if (status === BulkResponseProcessor.AllOK) {
            this.notifications.success(
                `${successItems.length} line item${successItems.length === 1 ? ' was' : 's were'} successfully deleted.`
            );
            this.deleteDialog.close();
            this.refresh();
            return;
        }

        if (status === BulkResponseProcessor.AllFailed) {
            this.notifications.error(
                `${failureItems.length} line item${failureItems.length === 1 ? ' was' : 's were'} not deleted due to the following errors:
                <ul>${failureItems.join('')}</ul>`, '', 0
            );
            this.deleteDialog.close();
            return;
        }

        this.notifications.warning(
            `The following line items were successfully deleted:
             <ul>${successItems.join('')}</ul>
             <br>
             However, the following line items were not deleted:
            <ul>${failureItems.join('')}</ul>`, '', 0
        );
        this.deleteDialog.close();
        this.refresh();
    }

    handleStatusChange(attempted: LineItem[], responses: any[], action: LineItemAction) {
        const { status, failureItems, successItems } = (new BulkResponseProcessor()).processSaves(attempted, responses);
        const actionName = action === LineItemAction.Pause ? 'paused' : 'activated';

        if (status === BulkResponseProcessor.AllOK) {
            this.notifications.success(
                `${successItems.length} line item${successItems.length === 1 ? ' was' : 's were'} successfully ${actionName}.`
            );
            this.statusDialog.close();
            this.refresh();
            return;
        }

        if (status === BulkResponseProcessor.AllFailed) {
            this.notifications.error(
                `${failureItems.length} line item${failureItems.length === 1 ? ' was' : 's were'} not ${actionName} due to the following errors:
                <ul>${failureItems.join('')}</ul>`, '', 0
            );
            this.statusDialog.close();
            return;
        }

        this.notifications.warning(
            `The following line items were successfully ${actionName}:
             <ul>${successItems.join('')}</ul>
             <br>
             However, the following line items were not ${actionName}:
            <ul>${failureItems.join('')}</ul>`, '', 0
        );
        this.statusDialog.close();
        this.refresh();
    }

    private handleGenericError(e) {
        this.notifications.error('An error has occurred.');
    }

    get startDateSortField() {
        return this.disableDateSort ? null : 'effectiveStartDate';
    }

    get endDateSortField() {
        return this.disableDateSort ? null : 'effectiveEndDate';
    }

    showExtraStatus(lineItem: LineItem, platformStatus) {
        if (lineItem.status.toLowerCase() === platformStatus.toLowerCase()) {
            return false;
        }

        return lineItem.status !== 'active' && platformStatus !== Status.COMPLETED;
    }

    getRateLabel(strategyCardId: string, campaign: Campaign) {
        if (campaign.system === 'dsp') {
            if (['2', '5', '103'].indexOf(strategyCardId) > -1) {
                return 'Max bid';
            } else if (['1', '4'].indexOf(strategyCardId) > -1) {
                return 'CPM';
            }
        } else {
            const meta = campaign.meta;
            if (meta.goal === 'Performance') {
                if (meta.reach === 'clicks' || meta.reach === 'conversions' || meta.reach === 'reach') {
                    return 'Max Bid';
                } else if (meta.reach === 'cpc') {
                    return 'CPC';
                } else if (meta.reach === 'cpa') {
                    return 'CPA';
                }
            } else if (meta.goal === 'Branding') {
                if (meta.reach === 'clicks' || meta.reach === 'conversions' || meta.reach === 'conversions' || meta.reach === 'impressions') {
                    return 'CPM';
                }
            }
        }
    }

    isExchangeDSPCampaign(lineItem) {
        return lineItem.campaignDemandType === 'exchange' && lineItem.campaignSystem === 'dsp';
    }

    calculateDailyBudget(lineItem) {
        const budgetHelper = new BudgetHelper(lineItem, lineItem.campaignObj);
        return budgetHelper.getDailyBudget();

    }

    hasDailyCap(lineItem) {
        return !!Number(lineItem.dailyCap);
    }
}
