import { Component, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from 'app/core/authentication.service';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.styl'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent {
    private startTime: number;
    private destinationUrl: string;

    constructor (
        public router: Router,
        private authenticationService: AuthenticationService
    ) {
        if (window['ga'] && environment.ga && environment.ga.clientId) {
            window['ga']('create', environment.ga.clientId, { 'siteSpeedSampleRate': 100 });
        }

        // see: Google Analytics > Admin > Custom Definitions > Custom dimensions
        // dimension1: user_domain, scope: user
        this.authenticationService.currentUser.subscribe(user => {
            if (window['ga'] && user.email) {
                const userDomain = user.email.substring(user.email.indexOf('@') + 1).toLowerCase();

                // Google Tag Manager dataLayer
                window['dataLayer'] = window['dataLayer'] || [];
                window['dataLayer'].push({
                    'userDomain': userDomain,
                    'userId': user.id
                });
            }
        });

        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.startTime = Date.now();
                this.destinationUrl = event.url;
            }
            if (event instanceof NavigationEnd && this.destinationUrl === event.url && window['ga']) {
                window['ga']('send', 'pageview', event.url);
                window['ga']('send', {
                    hitType: 'timing',
                    timingCategory: event.url,
                    timingVar: 'load',
                    timingValue: Date.now() - this.startTime
                });
            }
        });
    }
}
