import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, ReplaySubject, Observable, forkJoin, throwError } from 'rxjs';
import { tap, mergeMap, throwIfEmpty } from 'rxjs/operators';
import { AccessList, User } from 'app/shared/models';
import { environment } from 'src/environments/environment';
import { TokenService } from './token.service';
import { UserRepository } from './repositories';

@Injectable()
export class AuthenticationService {
    currentUser = new ReplaySubject<User>(1);
    currentAccessList = new BehaviorSubject<AccessList>(new AccessList());
    accessList$ = new ReplaySubject<AccessList>(1);

    constructor(
        private http: HttpClient,
        private tokenService: TokenService,
        private userRepository: UserRepository
    ) {}

    /**
     * Refresh the current user's details and access.
     */
    refreshUser() {
        return this.me().pipe(
            mergeMap(me => {
                if (me && me.id) {
                    return forkJoin(
                        this.userRepository.get(me.id),
                        this.userRepository.getAccessList(me.id)
                    );
                } else {
                    return throwError(new Error());
                }
            }),
            tap(([user, accessList]) => {
                this.currentUser.next(user);
                this.currentAccessList.next(accessList);
                this.accessList$.next(accessList);
            }));
    }

    /**
     * Check if the user is currently "logged in as" another user.
     */
    get isLoggedInAs() {
        return this.tokenService.isLoggedInAs;
    }

    /**
     * Login as another user.
     *
     * @param  {string} userId
     */
    loginAs(userId: string | number): Observable<any> {
        return this.userRepository.loginAs(userId).pipe(
            tap(session => this.tokenService.setLoggedInAsTokens(session)),
            mergeMap(() => this.refreshUser()));
    }

    /**
     * Log out of the "logged in as" user and return to the
     * authenticated user's session.
     */
    logoutAs(): Observable<any> {
        this.tokenService.clearLoggedInAsTokens();
        return this.refreshUser();
    }

    /**
     * Create a new password.
     *
     * @param  {string} email
     * @param  {string} code
     * @param  {string} newPassword
     * @param  {string} confirmPassword
     */
    createNewPassword(email, code, newPassword, confirmPassword) {
        const params = { email, code, password: newPassword, confirmPassword };

        return this.http.post(this.url('/auth/password/reset'), params);
    }

    /**
     * Request an email be sent to the user with password reset
     * instructions.
     *
     * @param  {string} email
     */
    requestPasswordReset(email: string) {
        return this.http.put(this.url('/auth/password/forgot'), { email });
    }

    /**
     * Fetch current user info.
     */
    private me(): Observable<any> {
        return this.http.get(`${environment.backend.hostname}/auth/me`)
            .pipe(throwIfEmpty(() => new Error()));
    }

    /**
     * Builds and returns a URL for the backend API given an array of strings
     * `suffix` which are joined using a forward-slash.
     * @param suffix
     */
    url(...suffix: string[]): string {
        return environment.backend.hostname + suffix.join('/');
    }
}
