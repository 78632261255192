import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

type NotificationType = 'success' | 'error' | 'warning';

export interface NotificationData {
    id: number;
    type: NotificationType;
    title: string;
    message: string;
    timeout?: number;
}

@Injectable()
export class NotificationsService {
    show$ = new Subject<NotificationData>();
    remove$ = new Subject<number>();
    private index = 0;

    success(message: string, title?: string, timeout?: number) {
        return this.dispatch('success', message, title, timeout);
    }

    error(message: string, title?: string, timeout?: number) {
        return this.dispatch('error', message, title, timeout);
    }

    warning(message: string, title?: string, timeout?: number) {
        return this.dispatch('warning', message, title, timeout);
    }

    remove(id: number) {
        this.remove$.next(id);
    }

    private dispatch(type: NotificationType, message: string, title?: string, timeout?: number) {
        this.show$.next({ id: ++this.index, type, message, title, timeout });
        return this.index;
    }
}
