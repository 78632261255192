import { Model } from './model';

export class ReportingSlicer extends Model {
    rows: any[];
    total: any;
    status: string;

    public serialize(): string {
        return JSON.stringify(this);
    }

    get entity() {
        return 'Reporting Slicer';
    }
}
