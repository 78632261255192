import { Model } from './model';
enum native3PDsps {
   BIDSWITCH_NATIVE_ID = 'd1faedca409211e786aa22000a974651',
   APPNEXUS_NATIVE_ID = 'ed987af49ef211e789f2126374df4a7a',
   TRADE_DESK_NATIVE_ID = 'c41b2a04992011ea9377129c48bca6c4'
}

const LIVEINTENT_NATIVE_DSP_ID = '4363607d53e411ea8716129c48bca6c4';

export class RTBPartner extends Model {

    static readonly native3PDsps = native3PDsps;
    public refId: number;
    public name: string;
    public status: string;
    public browserTargets: number[];
    public budget: number;
    public deviceTypeTargets: number[];
    public deviceManufacturerTargets: number[];
    public osTargets: number[];
    public programmaticFee: number;
    public matchUrl: string;
    public creativeSizeTargets: number[];
    public datacenters: any[];
    public parentId: string;
    public matchedUsersOnly: boolean;
    public md5AsUuid: boolean;
    public dailyCap: number;
    public protocol: string;
    public openRtbVersion: string;
    public extraOfferMax: number;
    public sendDebug: boolean;
    public sendSensitiveDetails: boolean;
    public requestTimeout: number;
    public ifaasUrlOne: string;
    public ifaasUrlTwo: string;
    public reduceBid: boolean;
    public allow: boolean;
    public allowNative: boolean;
    public allowDisplay: boolean;
    public allowKey: number;
    public transparency: boolean;
    public floor: number;
    public lossNotificationUrl: string;
    public extSsp: string;
    public auctionType: string;
    public inheritedAllow: boolean;
    public inheritedFloor: number;
    public allowCompression: boolean;
    public allowRedirects: boolean;
    public maidType: number;
    public sendLiid: boolean;

    public serialize(): string {

        const rtbPartner = this.clone(RTBPartner);

        if (typeof rtbPartner.budget !== 'number' && rtbPartner.budget !== null) {
            delete rtbPartner.budget;
        }
        if (typeof rtbPartner.programmaticFee !== 'number') {
            delete rtbPartner.programmaticFee;
        }
        if (typeof rtbPartner.matchUrl !== 'string') {
            delete rtbPartner.matchUrl;
        }
        if (Array.isArray(rtbPartner.datacenters)) {
            rtbPartner.datacenters.forEach (datacenter => {
                if (!datacenter['geoTargets'] || datacenter['geoTargets'].length === 0) {
                    delete datacenter['geoTargets'];
                    datacenter['geoTargetingType'] = '';
                }
            });
        }
        if (Array.isArray(rtbPartner.creativeSizeTargets) &&
                rtbPartner.creativeSizeTargets.length < 1) {

            delete rtbPartner.creativeSizeTargets;
        }
        if (typeof rtbPartner.lossNotificationUrl === 'string'
                && rtbPartner.lossNotificationUrl.trim().length === 0) {
            rtbPartner.lossNotificationUrl = null;
        }
        if (!rtbPartner.ifaasUrlOne) {
            rtbPartner.ifaasUrlOne = null;
        }
        if (!rtbPartner.ifaasUrlTwo) {
            rtbPartner.ifaasUrlTwo = null;
        }
        if (!rtbPartner.requestTimeout) {
            delete rtbPartner.requestTimeout;
        }

        return JSON.stringify(rtbPartner);
    }

    get entity() {
        return 'RTB Partner';
    }

    get isNative3PDSP() {
        return Object.values(native3PDsps).includes(this.id);
    }

    get isLiNativeDSP() {
        return LIVEINTENT_NATIVE_DSP_ID === this.id;
    }
}
