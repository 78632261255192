import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    Attribute,
    ContentChildren,
    QueryList,
    AfterContentInit
} from '@angular/core';
import { DropdownComponent, Navigable, OutsideClickHelper } from '../dropdown';
import { OptionComponent } from '../filter-select';
import { IdService } from 'app/core/id.service';

@Component({
    selector: 'row-actions',
    templateUrl: './row-actions.html',
    styleUrls: ['./row-actions.styl']
})
export class RowActionsComponent extends Navigable implements OnInit, AfterContentInit {
    @ContentChildren(OptionComponent) contentOptions: QueryList<OptionComponent> = null;

    @Input()
    set maxRowActions(num: number) {
        if (num) {
            this._maxRowActions = num;
        }
    }

    get maxRowActions() {
        return this._maxRowActions;
    }

    @Input()
    set options(opts: Array<any>) {        
        opts.forEach(option => option.disabled = option.disabled || false);

        if (JSON.stringify(opts) !== JSON.stringify(this._options)) {
            this._options = opts;
            
            if (this.options.length <= this.maxRowActions) {
                this.linkOptions = this.options;
            }
            if (this.options.length > this.maxRowActions) {
                this.linkOptions = this.options.slice(0, this.maxRowActions);
                this.dropdownOptions = this.options.slice(this.maxRowActions);
            }
        }
    }

    get options(): Array<any> {
        return this._options;
    }
    _options: Array<any> = null;
    _maxRowActions = 2;

    @ViewChild(DropdownComponent, { static: true })
    dropdown: DropdownComponent;

    @Output()
    clickedAction: EventEmitter<string> = new EventEmitter<string>();

    _outsideClickTarget: OutsideClickHelper = null;
    linkOptions: any[];
    dropdownOptions: any[];
    dropdownPos = { top: 0, left: 0 };

    constructor(
        public el: ElementRef,
        private cdr: ChangeDetectorRef,
        public id: IdService,
        @Attribute('max-row-actions') maxRowActions
    ) {
        super(cdr);
        if (typeof maxRowActions === 'string') {
            this.maxRowActions = parseInt(maxRowActions);
        }
        this._outsideClickTarget = new OutsideClickHelper(el.nativeElement);
    }

    private findOptionByAction(action) {
        return this._options.find(opt => opt.action === action);
    }

    public ngOnInit(): void {
        this._outsideClickTarget.target = this.dropdown;
    }

    ngAfterContentInit() {
        if (this.contentOptions.length > 0) {
            this.options = this.contentOptions.map((option, key) => ({
                key,
                value: option.value,
                label: option.html
            }));
        }
    }

    dropdownVisChange(opened) {
        this.el.nativeElement.classList[opened ? 'add' : 'remove']('opened');
    }

    clickAction(event, action) {
        if (this.options.find(opt => opt.value === action).disabled) {
            return;
        }
        this.clickedAction.emit(action);
        event.stopPropagation();
        this._outsideClickTarget.deactivate();
        this.cdr.detectChanges();
    }

    clickDropdownAction(event, action) {
        this.clickedAction.emit(action);
        event.stopPropagation();
        this.dropdown.toggle();
        this._outsideClickTarget.deactivate();
        this.cdr.detectChanges();
    }

    clickedMore({ pageY, pageX}: MouseEvent) {
        this.dropdownPos = { top: pageY, left: pageX };
        this.dropdown.toggle();
        this._outsideClickTarget.activate();
    }

    setOptionLabel(action, label): void {
        this.findOptionByAction(action).label = label;
    }

    disableOption(action): void {
        this.findOptionByAction(action).disabled = true;
    }

    enableOption(action): void {
        this.findOptionByAction(action).disabled = false;
    }
}
