import { TemplateRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, of, combineLatest } from 'rxjs';
import { catchError, tap, switchMap, mergeMap, map } from 'rxjs/operators';
import { AdvertiserRepository, CampaignRepository, LineItemRepository } from 'app/core/repositories';
import { BudgetHelper } from 'app/shared/helpers';
import { AvailableActionsService } from 'app/shared/helpers/available-actions.service';
import { BulkResponseProcessor, NotificationsService, PreferencesService } from 'app/core';
import { CampaignManagerDataService } from 'app/platform/campaign-manager/campaign-manager-data.service';
import { ConfirmDialogComponent } from 'app/shared/elements/confirm-dialog';
import { GoingLiveNotificationComponent } from '../going-live-notification';
import { HistoryViewComponent } from 'app/shared/components/history-view';
import { IdService } from 'app/core/id.service';
import { LineItemBulkEditComponent } from 'app/shared/components/line-item-bulk-edit';
import { PaginatorComponent } from 'app/shared/elements/paginator';
import { Status } from 'app/shared/models/status';
import { StatusDialogComponent } from 'app/shared/elements/status-dialog';
import { TableComponent } from 'app/shared/elements/table';
import { TableHelper } from 'app/shared/helpers/table-helper';
var LineItemAction;
(function (LineItemAction) {
    LineItemAction[LineItemAction["Activate"] = 0] = "Activate";
    LineItemAction[LineItemAction["Pause"] = 1] = "Pause";
    LineItemAction[LineItemAction["Delete"] = 2] = "Delete";
    LineItemAction[LineItemAction["Watch"] = 3] = "Watch";
    LineItemAction[LineItemAction["Unwatch"] = 4] = "Unwatch";
    LineItemAction[LineItemAction["Notify"] = 5] = "Notify";
    LineItemAction[LineItemAction["ViewHistory"] = 6] = "ViewHistory";
    LineItemAction[LineItemAction["Copy"] = 7] = "Copy";
    LineItemAction[LineItemAction["EditName"] = 8] = "EditName";
    LineItemAction[LineItemAction["EditBudget"] = 9] = "EditBudget";
})(LineItemAction || (LineItemAction = {}));
var LineItemsTableComponent = /** @class */ (function () {
    function LineItemsTableComponent(advertiserRepository, availableActionsService, campaignRepository, campaignStatus, lineItemRepository, router, preferencesService, notifications, id, disableDateSort) {
        var _this = this;
        this.advertiserRepository = advertiserRepository;
        this.availableActionsService = availableActionsService;
        this.campaignRepository = campaignRepository;
        this.campaignStatus = campaignStatus;
        this.lineItemRepository = lineItemRepository;
        this.router = router;
        this.preferencesService = preferencesService;
        this.notifications = notifications;
        this.id = id;
        this.isSelectable = false;
        this.showActionsMenu = true;
        this.advertiser = null;
        this.helper = new TableHelper(function (params) { return _this.adapter(params); });
        this.LineItemAction = LineItemAction;
        this._ids = null;
        this._lineItemIds = null;
        this.defaultColumns = [
            'funnel',
            'name',
            'campaignName',
            'status',
            'budget',
            'dailySpend',
            'spend',
            'pacing',
            'effectiveStartDate',
            'effectiveEndDate'
        ];
        this.disableDateSort = disableDateSort === 'true';
    }
    LineItemsTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.helper.table = this.table;
        this.helper.paginator = this.paginator;
        combineLatest(this.table.query, this.paginator.query).subscribe(function (args) { return _this.helper.search(args); });
    };
    Object.defineProperty(LineItemsTableComponent.prototype, "campaigns", {
        set: function (value) {
            this._ids = value;
            this.paginator.reset();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LineItemsTableComponent.prototype, "lineItems", {
        set: function (value) {
            this._lineItemIds = value;
            this.paginator.reset();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LineItemsTableComponent.prototype, "creative", {
        set: function (id) {
            this._creative = id;
        },
        enumerable: true,
        configurable: true
    });
    LineItemsTableComponent.prototype.refresh = function () {
        this.paginator.reset();
        this.table.clearSelections();
    };
    LineItemsTableComponent.prototype.buildParams = function (params) {
        if (!this._creative) {
            if (Array.isArray(this._ids)) {
                params.conditions.push({
                    field: 'campaign',
                    value: this._ids
                });
            }
            if (Array.isArray(this._lineItemIds)) {
                params.conditions.push({
                    field: 'id',
                    value: this._lineItemIds
                });
            }
            if (typeof this.advertiser === 'string') {
                params.conditions.push({
                    field: 'advertiser',
                    value: this.advertiser
                });
            }
        }
        params.returnMode = 'appended';
        params.return = [
            'budget',
            'dailyCap',
            'spend',
            'bidAmount',
            'impressions',
            'clicks',
            'conversions',
            'adjustedImpressions',
            'platformStatus'
        ];
        if (this._creative) {
            params['creative'] = this._creative;
        }
        return params;
    };
    LineItemsTableComponent.prototype.adapter = function (rawParams) {
        var _this = this;
        var params = this.buildParams(rawParams);
        return of(true).pipe(switchMap(function () {
            if (_this._creative) {
                if (!params.conditions.length)
                    delete params.conditions;
                return _this.lineItemRepository.asyncSearchByCreative(params);
            }
            else {
                return _this.lineItemRepository.asyncSearch(params);
            }
        }), mergeMap(function (response) {
            if (response.items.length > 0) {
                return forkJoin(response.items.map(function (lineItem) {
                    if (lineItem.campaignDemandType !== 'exchange') {
                        return forkJoin(_this.campaignStatus.fetchAdSlots(lineItem.id), _this.campaignStatus.fetchBundles(lineItem.id), _this.campaignStatus.fetchPackages(lineItem.id)).pipe(map(function (_a) {
                            var adSlots = _a[0], bundles = _a[1], packages = _a[2];
                            lineItem.adSlots = adSlots.map(function (adSlot) { return adSlot.id; });
                            lineItem.bundles = bundles.map(function (bundle) { return bundle.id; });
                            lineItem.packages = packages;
                            return lineItem;
                        }));
                    }
                    else {
                        return of(lineItem);
                    }
                })).pipe(map(function (lineItems) { return ({
                    items: lineItems,
                    pages: response.pages,
                    total: response.total,
                    page: params.page
                }); }));
            }
            else {
                return of({
                    items: [],
                    pages: 0,
                    total: 0,
                    page: params.page
                });
            }
        }));
    };
    LineItemsTableComponent.prototype.getLineItemCampaign = function (lineItem) {
        return this.campaignStatus.fetchCampaign(lineItem.campaign);
    };
    LineItemsTableComponent.prototype.lineItemPlatformStatus = function (lineItem) {
        return lineItem.getPlatformStatus();
    };
    LineItemsTableComponent.prototype.showPacingPill = function (lineItem) {
        return this.lineItemPlatformStatus(lineItem) === Status.COMPLETED || this.lineItemPlatformStatus(lineItem) === Status.DELIVERING;
    };
    LineItemsTableComponent.prototype.preventBulkAction = function (lineItems, action) {
        if (lineItems.length > 1) {
            this.notifications.error('Please select a single line item to ' + action + ' at a time.');
            return true;
        }
        return false;
    };
    LineItemsTableComponent.prototype.clickedAction = function (event, lineItem) {
        switch (event) {
            case 'edit':
                this.router.navigate(['/campaign-manager/line-items', lineItem.refId, 'edit', {
                        redirect: btoa(this.router.url)
                    }]);
                break;
        }
    };
    LineItemsTableComponent.prototype.handle = function (action, lineItems) {
        var _this = this;
        switch (action) {
            case LineItemAction.Activate:
                this.changeStatus(lineItems, LineItemAction.Activate);
                break;
            case LineItemAction.Pause:
                this.changeStatus(lineItems, LineItemAction.Pause);
                break;
            case LineItemAction.EditName:
                this.bulkEditor.open(lineItems, 'name');
                break;
            case LineItemAction.EditBudget:
                this.bulkEditor.open(lineItems, 'budget');
                break;
            case LineItemAction.Copy:
                if (!this.preventBulkAction(lineItems, 'copy')) {
                    this.router.navigate(['/campaign-manager/line-items/new', {
                            cloneFrom: lineItems[0].id,
                            redirect: btoa(this.router.url)
                        }]);
                }
                break;
            case LineItemAction.Notify:
                if (!this.preventBulkAction(lineItems, 'send a notification for')) {
                    this.goingLiveNotification.init(lineItems[0]);
                }
                break;
            case LineItemAction.Watch:
                lineItems.forEach(function (lineItem) { return _this.preferencesService.watchLineItem(lineItem); });
                break;
            case LineItemAction.Unwatch:
                lineItems.forEach(function (lineItem) { return _this.preferencesService.unwatchLineItem(lineItem); });
                break;
            case LineItemAction.Delete:
                this.deleteDialog.open('Delete');
                break;
            case LineItemAction.ViewHistory:
                if (!this.preventBulkAction(lineItems, 'view history for')) {
                    this.historyView.viewLineItemHistory(lineItems[0]);
                }
                break;
        }
    };
    LineItemsTableComponent.prototype.changeStatus = function (lineItems, action) {
        var _this = this;
        this.statusDialog.open(action === LineItemAction.Pause ? 'pause' : 'activate', 'line item');
        this.statusDialog.initiate(lineItems.length);
        var requests = lineItems.map(function (lineItem) {
            return _this.lineItemRepository.get(lineItem.id).pipe(mergeMap(function (li) {
                li.status = action === LineItemAction.Pause ? 'paused' : 'active';
                return _this.lineItemRepository.save(li);
            }), tap(function () { return _this.statusDialog.postProgress(); }), catchError(function (e) {
                _this.statusDialog.postProgress();
                return of(e);
            }));
        });
        forkJoin(requests).subscribe(function (responses) { return _this.handleStatusChange(lineItems, responses, action); }, function (e) { return _this.handleGenericError(e); });
    };
    LineItemsTableComponent.prototype.deleteLineItems = function (lineItems) {
        var _this = this;
        this.deleteDialog.initiate(lineItems.length);
        var requests = lineItems.map(function (lineItem) {
            return _this.lineItemRepository.delete(lineItem.id)
                .pipe(tap(function () { return _this.deleteDialog.postProgress(); }), catchError(function (e) {
                _this.deleteDialog.postProgress();
                return of(e);
            }));
        });
        forkJoin(requests).subscribe(function (responses) { return _this.handleDeleted(lineItems, responses); }, function (e) { return _this.handleGenericError(e); });
    };
    LineItemsTableComponent.prototype.handleDeleted = function (attempted, responses) {
        var _a = (new BulkResponseProcessor()).processDeletes(attempted, responses), status = _a.status, failureItems = _a.failureItems, successItems = _a.successItems;
        if (status === BulkResponseProcessor.AllOK) {
            this.notifications.success(successItems.length + " line item" + (successItems.length === 1 ? ' was' : 's were') + " successfully deleted.");
            this.deleteDialog.close();
            this.refresh();
            return;
        }
        if (status === BulkResponseProcessor.AllFailed) {
            this.notifications.error(failureItems.length + " line item" + (failureItems.length === 1 ? ' was' : 's were') + " not deleted due to the following errors:\n                <ul>" + failureItems.join('') + "</ul>", '', 0);
            this.deleteDialog.close();
            return;
        }
        this.notifications.warning("The following line items were successfully deleted:\n             <ul>" + successItems.join('') + "</ul>\n             <br>\n             However, the following line items were not deleted:\n            <ul>" + failureItems.join('') + "</ul>", '', 0);
        this.deleteDialog.close();
        this.refresh();
    };
    LineItemsTableComponent.prototype.handleStatusChange = function (attempted, responses, action) {
        var _a = (new BulkResponseProcessor()).processSaves(attempted, responses), status = _a.status, failureItems = _a.failureItems, successItems = _a.successItems;
        var actionName = action === LineItemAction.Pause ? 'paused' : 'activated';
        if (status === BulkResponseProcessor.AllOK) {
            this.notifications.success(successItems.length + " line item" + (successItems.length === 1 ? ' was' : 's were') + " successfully " + actionName + ".");
            this.statusDialog.close();
            this.refresh();
            return;
        }
        if (status === BulkResponseProcessor.AllFailed) {
            this.notifications.error(failureItems.length + " line item" + (failureItems.length === 1 ? ' was' : 's were') + " not " + actionName + " due to the following errors:\n                <ul>" + failureItems.join('') + "</ul>", '', 0);
            this.statusDialog.close();
            return;
        }
        this.notifications.warning("The following line items were successfully " + actionName + ":\n             <ul>" + successItems.join('') + "</ul>\n             <br>\n             However, the following line items were not " + actionName + ":\n            <ul>" + failureItems.join('') + "</ul>", '', 0);
        this.statusDialog.close();
        this.refresh();
    };
    LineItemsTableComponent.prototype.handleGenericError = function (e) {
        this.notifications.error('An error has occurred.');
    };
    Object.defineProperty(LineItemsTableComponent.prototype, "startDateSortField", {
        get: function () {
            return this.disableDateSort ? null : 'effectiveStartDate';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LineItemsTableComponent.prototype, "endDateSortField", {
        get: function () {
            return this.disableDateSort ? null : 'effectiveEndDate';
        },
        enumerable: true,
        configurable: true
    });
    LineItemsTableComponent.prototype.showExtraStatus = function (lineItem, platformStatus) {
        if (lineItem.status.toLowerCase() === platformStatus.toLowerCase()) {
            return false;
        }
        return lineItem.status !== 'active' && platformStatus !== Status.COMPLETED;
    };
    LineItemsTableComponent.prototype.getRateLabel = function (strategyCardId, campaign) {
        if (campaign.system === 'dsp') {
            if (['2', '5', '103'].indexOf(strategyCardId) > -1) {
                return 'Max bid';
            }
            else if (['1', '4'].indexOf(strategyCardId) > -1) {
                return 'CPM';
            }
        }
        else {
            var meta = campaign.meta;
            if (meta.goal === 'Performance') {
                if (meta.reach === 'clicks' || meta.reach === 'conversions' || meta.reach === 'reach') {
                    return 'Max Bid';
                }
                else if (meta.reach === 'cpc') {
                    return 'CPC';
                }
                else if (meta.reach === 'cpa') {
                    return 'CPA';
                }
            }
            else if (meta.goal === 'Branding') {
                if (meta.reach === 'clicks' || meta.reach === 'conversions' || meta.reach === 'conversions' || meta.reach === 'impressions') {
                    return 'CPM';
                }
            }
        }
    };
    LineItemsTableComponent.prototype.isExchangeDSPCampaign = function (lineItem) {
        return lineItem.campaignDemandType === 'exchange' && lineItem.campaignSystem === 'dsp';
    };
    LineItemsTableComponent.prototype.calculateDailyBudget = function (lineItem) {
        var budgetHelper = new BudgetHelper(lineItem, lineItem.campaignObj);
        return budgetHelper.getDailyBudget();
    };
    LineItemsTableComponent.prototype.hasDailyCap = function (lineItem) {
        return !!Number(lineItem.dailyCap);
    };
    return LineItemsTableComponent;
}());
export { LineItemsTableComponent };
