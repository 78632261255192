import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { SensitiveCategory } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { map } from 'rxjs/operators';
var SensitiveCategoryRepository = /** @class */ (function (_super) {
    tslib_1.__extends(SensitiveCategoryRepository, _super);
    function SensitiveCategoryRepository(http) {
        return _super.call(this, http, '/mc/sensitive-category-labels', SensitiveCategory) || this;
    }
    SensitiveCategoryRepository.prototype.all = function () {
        var _this = this;
        return this.http.get(this.url(this.path)).pipe(map(function (response) { return response['data'].map(function (data) { return _this.build(data); }); }));
    };
    SensitiveCategoryRepository.prototype.linkSensitiveCategories = function (creativeId, sensitiveCategories) {
        var _this = this;
        var params = {
            attributes: sensitiveCategories
        };
        var url = this.url('/mc/creatives/li-' + creativeId + '/sensitive-category-labels');
        return this.http.put(url, params).pipe(map(function (response) { return _this.build(response['output']); }));
    };
    SensitiveCategoryRepository.prototype.searchByCreativeId = function (id) {
        var _this = this;
        var params = {
            filters: [
                { field: 'creatives.id', value: 'li-' + id }
            ]
        };
        var url = this.url(this.path + '/search');
        return this.http.post(url, params)
            .pipe(map(function (response) { return response['data'].map(function (data) { return _this.build(data); }); }));
    };
    /**
     * Converts observable array of sensitive categories
     * into an observable array of options
     *
     * @param {Observable<SensitiveCategory[]>} sensitiveCategories
     * @return {Observable<Option[]>} options
     */
    SensitiveCategoryRepository.prototype.convertCategoriesToOptions = function (sensitiveCategories) {
        return sensitiveCategories.pipe(map(function (sensitiveCategoryList) {
            return sensitiveCategoryList.map(function (option) {
                return {
                    key: option.id,
                    value: option.id,
                    label: option.name,
                    data: option
                };
            });
        }));
    };
    return SensitiveCategoryRepository;
}(BackendRepository));
export { SensitiveCategoryRepository };
