import { Observable } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';

import { BaseTableHelper } from './base-table-helper';
import { Condition } from 'app/core/repositories';
import { LSDQueryBuilder } from './lsd-query-builder';
import { Query } from 'app/shared/elements/types';
import { QueryBuilder, SearchParams } from './query-builder';

export interface TableData<T> {
    items: T[];
    cursor?: string;
    total?: number;
    pages?: number;
}

export class TableHelper<T> extends BaseTableHelper<T> {
    /**
     * Create a new table helper.
     */
    constructor(
        private adapter?: (params: SearchParams) => Observable<TableData<T>>,
        private queryBuilder: QueryBuilder = new LSDQueryBuilder()
    ) {
        super();

        this.query.pipe(
            debounceTime(this.debounceTime),
            switchMap(rawParams => this.adapt(rawParams))
        ).subscribe(this.data);
    }

    /**
     * Adapt a query into table data.
     */
    adapt(rawParams: Query) {
        const params = this.queryBuilder.build(rawParams);

        return this.adapter(params).pipe(
            map(response => ({
                items: response.items,
                page: params.page,
                cursor: response.cursor,
                pages: response.pages,
                total: response.total
            }))
        );
    }

    /**
     * Sorts an array of items by a given field name `orderBy` and direction `direction`.
     * Sorting happens in place. This is meant to be used for async-tables that sort client side.
     */
    static sort(data: any[], orderBy: string, direction: string) {
        const compare = (a, b) => {
            if (a === undefined || a === null) {
                return 1;
            }

            if (b === undefined || b === null) {
                return -1;
            }

            if (typeof a === 'string') {
                a = a.toLowerCase();
                b = b.toLowerCase();
            }

            if (a > b) {
                return -1;
            }

            if (b > a) {
                return 1;
            }

            if (a === b) {
                return 0;
            }
        };

        data.sort((a, b) => compare(a[orderBy], b[orderBy]));

        if (direction.toUpperCase() === 'DESC') {
            data.reverse();
        }

        return data;
    }

    /**
     * Filters an array of items.
     * This is meant to be used for async-tables that filter client side.
     */
    public static filter(items: any[], params: SearchParams) {
        if (!Array.isArray(params.conditions) || params.conditions.length < 1) {
            return items;
        }

        return items.filter(item => TableHelper.testAgainstConditions(item, params.conditions));
    }

    /**
     * Test whether an item meets a set of conditions.
     */
    private static testAgainstConditions(item, conditions: Condition[]) {
        if (!Array.isArray(conditions) || conditions.length < 1) {
            return true;
        }

        // for simplicity, let's assume the conditions in a group all share the same
        // operator until we have to support anything further
        if (conditions[0].mode === 'or') {
            return conditions.some(condition => TableHelper.testAgainstCondition(item, condition));
        } else {
            return conditions.every(condition => TableHelper.testAgainstCondition(item, condition));
        }
    }

    /**
     * Test whether an item meet a of condition.
     */
    private static testAgainstCondition(item, condition: Condition) {
        if (Array.isArray(condition.conditions)) {
            return TableHelper.testAgainstConditions(item, condition.conditions);
        }

        if (condition.operator === 'like') {
            return (new RegExp(condition.value, 'i')).test(item[condition.field]);
        }

        return condition.value === item[condition.field];
    }
}
