import * as LDClient from "launchdarkly-js-client-sdk";
import { AuthenticationService } from "./authentication.service";
import { AuthorizationService } from "./authorization.service";
import { distinctUntilChanged, map, mergeMap, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { forkJoin, of, ReplaySubject } from "rxjs";
import { AdvertiserRepository, AgencyRepository, MediaGroupRepository, PublisherRepository, UserRepository } from "./repositories";
import { NgZone } from "@angular/core";
// Flag naming suggestions recommendations https://docs.launchdarkly.com/guides/flags/flag-naming#flag-kind-examples
export var Flag;
(function (Flag) {
    Flag["AllowCoordination"] = "allow-ad-slot-coordination";
    Flag["NewSearchExperienceInMaverick"] = "new-search-experience-in-maverick";
    Flag["Rollout_BookOnAdjustedImpressions"] = "rollout-book-on-adjusted-impressions";
    Flag["Rollout_PubaAccess"] = "rollout-puba-access";
    Flag["RolloutNativeCreativeTrafficking"] = "rollout-native-creative-trafficking";
    Flag["RolloutTraffickingUX"] = "rollout-trafficking-ux";
    Flag["RolloutCreativeMapping"] = "rollout-creative-mapping";
    Flag["RolloutAudienceExtension"] = "rollout-audience-extension";
    Flag["RolloutUAMEnhancements"] = "rollout-uam-enhancements";
    Flag["RolloutAdBlocking"] = "rollout-ad-blocking";
    Flag["RolloutLineItemBlackout"] = "rollout-line-item-blackout";
})(Flag || (Flag = {}));
var LaunchDarklyService = /** @class */ (function () {
    function LaunchDarklyService(userRepository, mediaGroupRepository, publisherRepository, agencyRepository, advertiserRepository, auth, authenticationService, ngZone) {
        var _this = this;
        this.userRepository = userRepository;
        this.mediaGroupRepository = mediaGroupRepository;
        this.publisherRepository = publisherRepository;
        this.agencyRepository = agencyRepository;
        this.advertiserRepository = advertiserRepository;
        this.auth = auth;
        this.authenticationService = authenticationService;
        this.ngZone = ngZone;
        this.client = null;
        this.readySubject = new ReplaySubject(1);
        this.ngZone.runOutsideAngular(function () {
            _this.authenticationService.currentUser.pipe(distinctUntilChanged(), mergeMap(function (user) {
                return forkJoin([of(user), _this.userRepository.getAccessList(user.id)]);
            }), tap(function (_a) {
                var user = _a[0], accessList = _a[1];
                forkJoin([
                    of(user),
                    _this.getPublishers(accessList),
                    _this.getAdvertisers(accessList),
                    _this.getMediaGroups(accessList),
                    _this.getAgencies(accessList)
                ]).subscribe(function (_a) {
                    var user = _a[0], publishers = _a[1], advertisers = _a[2], mediaGroups = _a[3], agencies = _a[4];
                    var context = {
                        kind: 'multi',
                        user: {
                            key: user.hashId,
                            name: user.name,
                            email: user.email,
                            isAdmin: _this.auth.isAdmin,
                            isManager: _this.auth.isManager,
                            isInternalUser: _this.auth.isInternalUser,
                            isClient: !_this.auth.isInternalUser,
                        },
                        accounts: {
                            key: 'account-' + user.hashId,
                            publishers: publishers.map(function (p) { return 'MG(' + p.mediaGroupRefId + '):PUB(' + p.refId + ') ' + p.name; }),
                            advertisers: advertisers.map(function (a) { return 'AG(' + a.agencyRefId + '):ADV(' + a.refId + ') ' + a.name; }),
                            mediaGroups: mediaGroups.map(function (a) { return 'MG(' + a.refId + ') ' + a.name; }),
                            agencies: agencies.map(function (a) { return 'AG(' + a.refId + ') ' + a.name; }),
                        }
                    };
                    _this.client = LDClient.initialize(environment.ld.clientId, context, {
                        useReport: true,
                        streaming: true,
                    });
                    _this.client.identify(context, null, function () {
                        // do nothing
                    });
                    _this.client.on("ready", function () {
                        _this.ngZone.run(function () {
                            _this.readySubject.next(true);
                        });
                    });
                    _this.client.on("change", function () {
                        _this.ngZone.run(function () {
                            _this.readySubject.next(true);
                        });
                    });
                });
            })).subscribe();
        });
    }
    LaunchDarklyService.prototype.getPublishers = function (accessList) {
        var ids = [];
        if (accessList.entities.Publisher) {
            ids.push.apply(ids, accessList.entities.Publisher.map(function (e) { return e.id; }) || []);
        }
        return this.publisherRepository.searchByIds(ids);
    };
    LaunchDarklyService.prototype.getAdvertisers = function (accessList) {
        var ids = [];
        if (accessList.entities.Advertiser) {
            ids.push.apply(ids, accessList.entities.Advertiser.map(function (e) { return e.id; }) || []);
        }
        return this.advertiserRepository.searchByIds(ids);
    };
    LaunchDarklyService.prototype.getMediaGroups = function (accessList) {
        var ids = [];
        if (accessList.entities.MediaGroup) {
            ids.push.apply(ids, accessList.entities.MediaGroup.map(function (e) { return e.id; }) || []);
        }
        return this.mediaGroupRepository.searchByIds(ids);
    };
    LaunchDarklyService.prototype.getAgencies = function (accessList) {
        var ids = [];
        if (accessList.entities.Agency) {
            ids.push.apply(ids, accessList.entities.Agency.map(function (e) { return e.id; }) || []);
        }
        return this.agencyRepository.searchByIds(ids);
    };
    /**
     * Get flag variation for current user
     * @param flag string
     * @param defaultValue any
     *
     * @returns Observable<any>
     */
    LaunchDarklyService.prototype.getVariation = function (flag, defaultValue) {
        var _this = this;
        return this.readySubject.pipe(map(function () {
            if (typeof defaultValue !== 'undefined') {
                return _this.client.variation(flag, defaultValue);
            }
            return _this.client.variation(flag);
        }));
    };
    return LaunchDarklyService;
}());
export { LaunchDarklyService };
