import { TableHeaderDirective } from './table-header.directive';
import { TableCellDirective } from './table-cell.directive';
var TableColumnDirective = /** @class */ (function () {
    function TableColumnDirective() {
        this.lock = null;
    }
    Object.defineProperty(TableColumnDirective.prototype, "locked", {
        get: function () {
            return this.lock !== null;
        },
        enumerable: true,
        configurable: true
    });
    return TableColumnDirective;
}());
export { TableColumnDirective };
