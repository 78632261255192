import {
    Component,
    forwardRef,
    Input
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DownloadHelper } from 'app/shared/helpers/download-helper';
import { IdService } from 'app/core';

@Component({
    selector: 'placement-id-targeting',
    templateUrl: './placement-id-targeting.html',
    styleUrls: ['./placement-id-targeting.styl'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => PlacementIdTargetingComponent),
        multi: true
    }]
})
export class PlacementIdTargetingComponent implements ControlValueAccessor {
    data = '';
    items = [];

    @Input() fileName: string = 'placement_ids';
    @Input() placeholder: string;

    private onchange = null;

    constructor(
        public id: IdService
    ) { }

    writeValue(placementIds: string) {
        if (placementIds) {
            this.addPlacementIds(placementIds);
        }
    }

    registerOnChange(fn: any) {
        this.onchange = fn;
    }

    registerOnTouched(fn: any) { }

    private emitChanges() {
        if (this.onchange !== null) {
            let selected = null;
            if (this.items.length > 0) {
                selected = this.items.map(item => {
                    return item.value;
                }).join(',');
            }
            this.onchange(selected);
        }
    }

    addPlacementIds(data: string) {
        if (typeof data !== 'string') {
            return;
        }

        let placementIds = data.replace(/\n/g, ',').split(',').map(str => str.trim()).filter(str => str.length > 0);

        if (placementIds.length < 1) {
            return;
        }

        // Filter list so that it is unique.
        placementIds = placementIds.filter((placementId, i, arr) => arr.indexOf(placementId) === i);

        // Filter list so that it does not contain any placement ids already added.
        placementIds = placementIds.filter(placementId =>
            this.items.findIndex(item => item.value === placementId) < 0
        );

        const items = this.items.slice();

        placementIds.forEach(placementId => {
            items.push({
                key: placementId,
                value: placementId,
                label: `${placementId.toString()}`
            });
        });

        this.items = items;

        this.emitChanges();

        // Reset.
        this.data = '';
    }

    itemsUpdate(items) {
        this.items = items;
        this.emitChanges();
    }

    get hasItems() {
        return this.items.length > 0;
    }

    clearItems() {
        this.items = [];
        this.emitChanges();
    }

    download() {
        const data = this.items.map(item => ({
            'placement id': item.value
        }));

        DownloadHelper.downloadAsCSV(data, this.fileName);
    }
}
