import * as tslib_1 from "tslib";
import { Model } from './model';
var Role = /** @class */ (function (_super) {
    tslib_1.__extends(Role, _super);
    function Role(from) {
        var _this = _super.call(this, from) || this;
        delete _this.id;
        _this.rid = parseInt(from.id);
        return _this;
    }
    Role.prototype.serialize = function () {
        return JSON.stringify(this);
    };
    Object.defineProperty(Role.prototype, "entity", {
        get: function () {
            return 'Role';
        },
        enumerable: true,
        configurable: true
    });
    return Role;
}(Model));
export { Role };
