import { Model } from '../model';

export enum DemandTextAssetType {
    SponsoredBy = 1,
    Description = 2,
    CallToAction = 12
}

export const DemandTextAssets = [{
    id: DemandTextAssetType.SponsoredBy,
    key: 'sponsored'
}, {
    id: DemandTextAssetType.CallToAction,
    key: 'callToAction'
}, {
    id: DemandTextAssetType.Description,
    key: 'description'
}];

export class DemandTextAsset extends Model {
    type?: DemandTextAssetType;
    len: number;
    text: string;

    constructor(data: any = {}) {
        super(data);
    }

    get entity(): string {
        return 'Native Demand Text Asset';
    }
}