import { Component, ChangeDetectionStrategy, EventEmitter, Output, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BulkOperationStatusToastService, NotificationsService } from 'app/core';
import { PublisherRepository } from 'app/core/repositories';
import { BulkOperationRepository } from 'app/core/repositories/bulk-operation-repository.service';
import { BulkEditLightboxComponent } from 'app/shared/components/bulk-edit-lightbox';
import { Publisher } from 'app/shared/models';
import { BulkOperationRequest } from 'app/shared/models/bulk-request/bulk-operation-request';
import { Operation, OperationMethod } from 'app/shared/models/bulk-request/operation';

@Component({
    selector: 'publisher-bulk-edit',
    templateUrl: './publisher-bulk-edit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublisherBulkEditComponent {
    @ViewChild(BulkEditLightboxComponent, { static: true }) lightbox: BulkEditLightboxComponent;
    @Output() completed = new EventEmitter();

    constructor(
        private publisherRepository: PublisherRepository,
        private notifications: NotificationsService,
        private bulkOperationRepo: BulkOperationRepository,
        private bulkOperationStatusToast: BulkOperationStatusToastService,
    ) {}

    open(publishers: Publisher[], field: string = null) {
        this.lightbox.initiate();
        this.lightbox.open(publishers, field, 'load');

        forkJoin(
            publishers.map(publisher => this.publisherRepository.get(publisher.id).pipe(
                tap(() => this.lightbox.postProgress())
            ))
        ).subscribe(
            details => this.lightbox.open(details, field),
            () => this.handleError()
        )
    }

    close() {
        this.lightbox.close();
    }

    submit({ changed }: { changed: Publisher[] }): void {
        this.lightbox.initiate();

        const operations = changed.map(publisher => new Operation(
            OperationMethod.Post,
            '/publisher/' + publisher.refId,
            publisher.serialize(),
            publisher.refId.toString()
        ));

        this.bulkOperationRepo.sendRequest(new BulkOperationRequest(operations)).subscribe(
            () => this.createBulkOperationStatusToast(),
            () => this.handleSaveError()
        );
    }

    private createBulkOperationStatusToast(): void {
        this.bulkOperationStatusToast.fetchStatusData();
        this.completed.emit();
        this.close();

        return;
    }

    private handleError() {
        this.notifications.error('An error has occurred.');
    }

    private handleSaveError(): void {
        this.lightbox.resetForm();
        this.handleError();
    }
}
