import { StoreService } from './store.service';
import { JwtHelperService } from '@auth0/angular-jwt';
var TokenService = /** @class */ (function () {
    function TokenService(store) {
        this.store = store;
        this.jwtHelper = new JwtHelperService();
        this.expiration = new Date().getTime() + 1000 * 60 * 60 * 24 * 365;
    }
    Object.defineProperty(TokenService.prototype, "isLoggedInAs", {
        /**
         * Check if the user has a currently valid "logged in as" token stored.
         */
        get: function () {
            return !!this.store.get(TokenService.LoggedInAsTokens);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TokenService.prototype, "accessToken", {
        /**
         * Get the current access token.
         */
        get: function () {
            var token = this.tokens ? this.tokens.token : null;
            return !this.isTokenExpired(token) ? token : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TokenService.prototype, "tokens", {
        /**
         * Get the currently effective tokens.
         */
        get: function () {
            return this.isLoggedInAs
                ? this.store.get(TokenService.LoggedInAsTokens)
                : this.store.get(TokenService.Tokens);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Check if an access token is expired. We
     * add a buffer time of 60 seconds.
     * @param {string} token
     */
    TokenService.prototype.isTokenExpired = function (token) {
        return this.jwtHelper.isTokenExpired(token, 600);
    };
    /**
     * Set tokens for a "logged in as" user.
     * @param {Session} session
     */
    TokenService.prototype.setLoggedInAsTokens = function (session) {
        this.store.set(TokenService.LoggedInAsTokens, session, this.expiration);
    };
    /**
     * Clear the tokens for a "logged in as" user and restore
     * the original user.
     */
    TokenService.prototype.clearLoggedInAsTokens = function () {
        this.store.remove(TokenService.LoggedInAsTokens);
    };
    /**
     * Clear all the tokens from the store.
     */
    TokenService.prototype.clear = function () {
        this.store.remove(TokenService.Tokens);
        this.store.remove(TokenService.LoggedInAsTokens);
    };
    TokenService.Tokens = 'mtokens';
    TokenService.LoggedInAsTokens = 'mlitokens';
    return TokenService;
}());
export { TokenService };
