import { NgModel } from '@angular/forms';
import macros from './valid-macros.json';
import domainExtensions from './valid-domain-extensions.json';
import moment from 'moment';
import validationErrors from 'app/core/errors/validation-errors.json';

export class ValidationService {
    public static isValidDomain(domain: string): boolean {
        if (domain.includes(' ')) {
            return false;
        }
        const regEx = /^[a-z0-9][-a-z0-9.]*[a-z0-9]\.[a-z]{2,}$/i;
        return regEx.test(domain) && ValidationService.hasValidDomainExtension(domain);
    }

    public static hasValidDomainExtension(domain: string) {
        const split = domain.trim().split('.');

        if (!(Array.isArray(split) && (split.length > 1))) {
            return false;
        }
        const extension = split.pop();

        return domainExtensions.indexOf(extension) > -1;
    }

    public static isValidUrl(url) {
        const regEx = /^http(s)?:\/\/[a-z0-9]([-a-z0-9.]?)*([a-z0-9])*\.[a-z]{2,}[-\|\[\]_0-9a-z?,;:.\/()%&=#+*!{}~$ ]*$/i;
        return regEx.test(url);
    }

    //Allow alphanumeric and hypen characters only for keywords
    public static isValidKeyword(keyword: string) {
        const validCharacters = /^[a-zA-Z0-9-\*]+$/i;
        const containsLetters = /[a-zA-Z0-9]/i;
        return validCharacters.test(keyword) && containsLetters.test(keyword) && keyword.length > 2;
    }

    hasValidMacros(url: string): boolean {
        const oldMacros = url.match(/\[=(.*?)=\]/ig);
        const newMacros = url.match(/\$\{(.*?)\}/ig);

        if (Array.isArray(newMacros)) {
            if (newMacros.filter(macro => macros.allowedMacros.indexOf(macro) === -1).length > 0) {
                return false;
            }
        }

        if (Array.isArray(oldMacros) && oldMacros.length > 0) {
            return false;
        }

        return true;
    }

    hasInvalidMacros(url: string): string[] | boolean {
        if (!url) {
            return false;
        }

        const allMacros = this.parseAllMacros(url, macros.allowedMacros);

        return allMacros.invalidMacros.length > 0 ? allMacros.invalidMacros : false;
    }

    hasCustomMacros(url: string): string[] | boolean  {
        if (!url) {
            return false;
        }

        const allMacros = this.parseAllMacros(url, macros.allowedMacros);

        return allMacros.otherValidMacros.length > 0 ? allMacros.otherValidMacros : false;
    }

    hasDeprecatedMacros(url: string): string[] | boolean  {
        if (!url) {
            return false;
        }

        const deprecatedMacros = (url.match(/\[=(.*?)=\]/ig) || []).filter(macro => macros.allowedMacros.indexOf(macro) === -1);

        return deprecatedMacros.length > 0 ? deprecatedMacros : false;
    }

    hasAcceptableMacros(url: string): boolean {
        if (!url) {
            return true;
        } else if (this.hasInvalidMacros(url) || this.hasDeprecatedMacros(url)) {
            return false;
        }

        return this.hasValidMacros(url) || !!this.hasCustomMacros(url);
    }

    isValidSecureUrl(url: string): boolean {
        return ValidationService.isValidUrl(url) && this.isSecureUrl(url);
    }

    isAcceptableUrl(url: string): boolean {
        return this.isValidSecureUrl(url) && this.hasAcceptableMacros(url);
    }

    private parseAllMacros(url: string, allowedMacros: string[]): { [key: string]: string[] } {
        const validMacros = [];
        const invalidMacros = [];
        const otherValidMacros = [];

        if (!url) {
            return {
                validMacros,
                invalidMacros,
                otherValidMacros
            };
        }

        const macros = [];
        let macro = [];

        for (let i = 0; i < url.length; i++) {
            const char = url[i];
            const prevChar = i > 0 ? url[i - 1] : '';
            const nextChar = i < url.length - 1 ? url[i + 1] : '';

            if (char === '$' && nextChar === '{') {
                i++;

                if (macro.length > 0) {
                    macros.push(macro.join(''));
                    macro = [];
                }

                macro = [];
                macro.push('${');
            } else if (char === '{') {
                if (macro.length > 0 && prevChar !== '$') {
                    macros.push(macro.join(''));
                    macro = [];
                }

                macro.push(char);
            } else if (char === '$' && nextChar!== '{') {
                macro.push(char);
                macros.push(macro.join(''));
                macro = [];
            } else if (char === '[') {
                if (macro.length > 0) {
                    macros.push(macro.join(''));
                    macro = [];
                }

                macro.push(char);
            } else if (char == '}' || char == ']') {
                macro.push(char);
                macros.push(macro.join(''));
                macro = [];
            } else if (macro.length > 0) {
                macro.push(char);
            }

            if (i === url.length - 1 && macro.length > 0) {
                macros.push(macro.join(''));
            }
        }

        for (let i = 0; i < macros.length; i++) {
            const macro = macros[i];
            const matchesMacroPattern = macro.match(/\$\{(.*?)\}/ig);
            const matchesAllowed = allowedMacros.includes(macro);

            if (matchesMacroPattern && matchesAllowed) {
                validMacros.push(macro);
            } else if (matchesMacroPattern && macro.trim().length > 3){
            // Valid macros must have a length longer than 3, including `${}`.
                otherValidMacros.push(macro);
            } else {
                invalidMacros.push(macro);
            }
        }

        return {
            validMacros,
            invalidMacros,
            otherValidMacros
        };
    }

    message(model: NgModel): string {
        const errors = model.errors;

        if (model.errors.url) {
            return validationErrors.ERROR_INVALID_URL;
        }

        if (model.errors.secure) {
            return validationErrors.ERROR_UNSECURE_URLS;
        }

        if (model.errors.required) {
            return validationErrors.ERROR_REQUIRED_FIELD;
        }

        if (model.errors.macro) {
            if (this.hasDeprecatedMacros(model.model)) {
                return validationErrors.ERROR_DEPRECATED_MACRO;
            } else if (this.hasInvalidMacros(model.model)) {
                return 'Invalid Macro Format: ' + this.hasInvalidMacros(model.model);
            }
        }

        const messages = Object.values(errors)
            .filter(error => error.message)
            .map(error => error.message);

        // TODO figure out what to do if there is more than one error at a time.
        return messages[0] || 'This field is invalid.';
    }

    creativesHaveRequiredFields(creatives) {
        for (let i = 0; i < creatives.length; i++) {
            let creative = creatives[i];
            if (creative.mediaUrl && creative.clickUrl && creative.name) {
                continue;
            }
            else {
                return false;
            }
        }
        return true;
    }

    creativesHaveValidUrl(creatives, field) {
        if (!Array.isArray(creatives)) {
            return false;
        }

        for (let i = 0; i < creatives.length; i++) {
            let creative = creatives[i];

            if (!creative[field]) {
                continue;
            } else if (ValidationService.isValidUrl(creative[field]) && this.hasAcceptableMacros(creative[field])) {
                if (field === 'clickUrl' || this.isSecureUrl(creative[field])) {
                    continue;
                }

                return false;
            } else if (field === 'mediaUrl' && !creative.isThirdPartyTag()) {
                continue;
            }

            return false;
        }

        return true;
    }

    lineItemHasRequiredFields(lineItem, displayDates) {
        function hasBudget (budget) {
            if (typeof budget === 'string') {
                return budget.length > 0;
            }
            return budget !== undefined && budget !== null && typeof budget === 'number';
        }

        return !!(lineItem.name
            && (hasBudget(lineItem.budget) || lineItem.campaignObj.unlimitedBudget)
            && displayDates.startDate
            && displayDates.endDate);
    }

    missesCreatives(lineItem): boolean {
        const requiredSizes = ['300x250', '728x90', '300x600'];
        const containsReqSizeCreative = (reqSize) => lineItem.ads
            .findIndex(creative => reqSize === `${creative.width}x${creative.height}`) > -1;

        if (lineItem.status === 'paused' || lineItem.status === 'inactive') {
            return false;
        }

        return !requiredSizes.every(containsReqSizeCreative);
    }

    validateDateRange(date, startDate, endDate) {
        if (!date) {
            return true;
        }

        return moment(date).isSameOrAfter(startDate)
            && moment(date).isSameOrBefore(endDate);
    }

    validateStartDatePast(date) {
        if (!date) {
            return true;
        }

        return moment().isSameOrBefore(date, 'day');
    }

    isValidTrackingUrl(trackingUrl1: string, trackingUrl2: string) {
        return !(trackingUrl1 && trackingUrl2
            && trackingUrl1.trim().length > 0 && trackingUrl2.trim().length > 0
            && trackingUrl1.trim() === trackingUrl2.trim());
    }

    isSecureUrl(url: string): boolean {
        if (!url) {
            return true;
        }

        return typeof url === 'string'
            && url.trim().length > 0
            && url.trim().indexOf('https://') === 0;
    }
}
