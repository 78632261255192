import { Component, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
    selector: 'task',
    templateUrl: './task.html',
    styleUrls: ['./task.styl']
})
export class TaskComponent {
    @Input() complete = true;
    @ViewChild('content', { static: true }) content: ElementRef<HTMLElement>;
    _currentTask = false;

    get right() {
        const el = this.content.nativeElement;
        return (el.scrollWidth / 2) - 15;
    }

    get currentTask() {
        return this._currentTask;
    }
}
