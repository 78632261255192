import * as tslib_1 from "tslib";
import { Model } from './model';
var Catalog = /** @class */ (function (_super) {
    tslib_1.__extends(Catalog, _super);
    function Catalog() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Catalog.prototype.serialize = function () {
        delete this._isClone;
        return JSON.stringify(this);
    };
    Object.defineProperty(Catalog.prototype, "entity", {
        get: function () {
            return 'Catalog';
        },
        enumerable: true,
        configurable: true
    });
    return Catalog;
}(Model));
export { Catalog };
