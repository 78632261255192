import { Component, ViewChild } from '@angular/core';
import { NativeBlueprint, AdSlot } from 'app/shared/models';
import { LightboxComponent } from 'app/shared/elements/lightbox';
import { LinkedBlueprintsComponent } from 'app/shared/components/linked-blueprints';
import { IdService } from 'app/core/id.service';

@Component({
    selector: 'linked-blueprints-lightbox',
    templateUrl: './linked-blueprints-lightbox.html',
    styleUrls: ['./linked-blueprints-lightbox.styl']
})
export class LinkedBlueprintsLightboxComponent {
    @ViewChild(LinkedBlueprintsComponent, { static: true }) table: LinkedBlueprintsComponent;
    @ViewChild(LightboxComponent, { static: true }) lightbox: LightboxComponent;
    nativeBlueprints: NativeBlueprint[];
    adSlot: AdSlot;

    constructor(
        public id: IdService
    ) {}

    open(adSlot: AdSlot, nativeBlueprints: NativeBlueprint[]) {
        this.adSlot = adSlot;
        this.nativeBlueprints = nativeBlueprints;
        this.lightbox.open();
    }

    close() {
        this.lightbox.close();
    }
}
