import { Pipe, PipeTransform } from '@angular/core';
import { Option } from '../dropdown';

@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
    private static test (s) {
        if (typeof s !== 'string' || s.length < 1) {
            return _ => false;
        }
        return e => e.label.toLowerCase().indexOf(s.toLowerCase()) > -1;
    }

    transform (a: Array<Option>, s: string): Array<Option> {
        return a.filter(FilterPipe.test(s));
    }
}
