import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { ReportingQueryRepository } from './repositories';
import { LiveConnectStatus, Rule } from 'app/shared/models';

@Injectable()
export class RuleService {
    static readonly FLUSH_INTERVAL = 1000;
    private statusCache = new Map<string, Observable<LiveConnectStatus>>();

    constructor(
        private reporting: ReportingQueryRepository    ) {
    }

    /**
     * Get recent activity for a rule.
     */
    private activity(rule: Rule, advertiserRefId: number) {
        return this.reporting.getMostRecentRuleActivity(rule, advertiserRefId);
    }

    /**
     * Compute the status of the rule.
     */
    status(rule: Rule, advertiserRefId: number) {
        let cacheKey: string;
        if (rule.ruleId) {
            cacheKey = advertiserRefId.toString().concat('.', rule.ruleId.toString());
        } else {
            cacheKey = advertiserRefId.toString().concat('.', rule.eventNameRegex, rule.configuration.eventName);
        }

        if (!this.statusCache.has(cacheKey)) {
            this.statusCache.set(cacheKey, this.computeStatus(rule, advertiserRefId).pipe(shareReplay(1)));
        }

        return this.statusCache.get(cacheKey);
    }

    /**
     * Compute the status of a rule.
     */
    private computeStatus(rule: Rule, advertiserRefId: number) {
        return this.activity(rule, advertiserRefId).pipe(
            map(activity => new LiveConnectStatus(activity))
        );
    }
}
