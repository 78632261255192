import store from 'store';
import expirePlugin from 'store/plugins/expire';
import * as i0 from "@angular/core";
store.addPlugin(expirePlugin);
var Store = /** @class */ (function () {
    function Store() {
    }
    Store.prototype.set = function (key, value, expiration) {
        store.set(key, btoa(JSON.stringify(value)), expiration);
    };
    Store.prototype.get = function (key) {
        try {
            return JSON.parse(atob(store.get(key)));
        }
        catch (_a) {
            return;
        }
    };
    Store.prototype.remove = function (key) {
        store.remove(key);
    };
    Store.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Store_Factory() { return new Store(); }, token: Store, providedIn: "root" });
    return Store;
}());
export { Store };
