import { Injectable } from '@angular/core';
import { AdvertiserRepository } from 'app/core/repositories';

export enum EmailOptions {
    'action',
    'approve',
    'reject'
}

@Injectable()
export class CreativeApprovalEmailService {

    private emailInfo: any = {
        template: 'test/creative-approval',
        from: 'product@liveintent.com',
        locals: {}
    };

    constructor(
        private advertiserRepository: AdvertiserRepository,
    ) { }

    public createEmail(creative: any, type: EmailOptions) {
        this.getAdvertiserInfo(creative.advertiser)
            .then(advertiser => {
                this.emailInfo.locals.type = EmailOptions[type];
                this.emailInfo.locals.advertiserRefId = advertiser.refId;
                this.emailInfo.locals.advertiserName = advertiser.name;
                this.emailInfo.locals.creative = creative;
                switch (type) {
                    case EmailOptions.action:
                        this.emailInfo.subject =
                            `${advertiser.name} (ID #${advertiser.refId} Creative Approval Required)`;
                        break;
                    case EmailOptions.approve:
                        this.emailInfo.subject = 'Your Creative Was Approved';
                        break;
                    case EmailOptions.reject:
                        this.emailInfo.subject = 'Your Creative Was Rejected';
                        break;
                }
            });
    }

    private getAdvertiserInfo(advertiserId): Promise<any> {
        return this.advertiserRepository.get(advertiserId).toPromise();
    }
}
