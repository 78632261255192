import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BackendRepository } from './backend-repository';
import { BulkOperationStatusResponse } from 'app/shared/models/bulk-request';
var BulkOperationRepository = /** @class */ (function (_super) {
    tslib_1.__extends(BulkOperationRepository, _super);
    function BulkOperationRepository(http) {
        return _super.call(this, http, '/bulk/operation-batches', BulkOperationStatusResponse) || this;
    }
    BulkOperationRepository.prototype.sendRequest = function (request) {
        return this.http
            .post(this.url(this.path), request.serialize())
            .pipe(map(function (result) { return result; }));
    };
    BulkOperationRepository.prototype.getStatus = function (batchId) {
        var includesPath = '?response_output=all';
        return this.http.get(this.url(this.path, batchId) + includesPath, {
            headers: new HttpHeaders({ 'Content-Type': 'text/html' })
        }).pipe(map(function (result) { return result; }));
    };
    BulkOperationRepository.prototype.getStatusByUser = function (params) {
        var _this = this;
        return this.http.get(this.url(this.path) + this.stringifyParams(params))
            .pipe(map(function (response) { return ({
            items: response['data'].map(function (item) { return _this.build(item); }),
            page: 1,
            pages: response['meta']['last_page'],
            total: response['meta']['total']
        }); }));
    };
    BulkOperationRepository.prototype.stringifyParams = function (params) {
        var str = '?' + params.conditions.map(function (condition) { return condition.field + "=" + condition.value; }).join('&');
        params.page ? str += "&page=" + params.page : '';
        params.n ? str += "&pageSize=" + params.n : '';
        return str;
    };
    return BulkOperationRepository;
}(BackendRepository));
export { BulkOperationRepository };
