import {
    Component,
    Input,
    Attribute,
    forwardRef,
    ViewChild,
    ElementRef,
    Output, 
    EventEmitter
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownComponent, OutsideClickHelper } from '../dropdown';

import moment from 'moment';
import { IdService } from 'app/core';

enum Type {
    Simple,
    Range,
    Hours,
    Days
}

@Component({
    selector: 'multi-select',
    templateUrl: './multi-select.component.html',
    styleUrls: ['./multi-select.styl'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiSelectComponent),
            multi: true
        }
    ]
})
export class MultiSelectComponent implements ControlValueAccessor {
    @ViewChild(DropdownComponent, { static: true }) dropdown: DropdownComponent;
    @Input() disabled: boolean = false;
    @Output() dismissedEvent = new EventEmitter<boolean>();

    static weekends = [0, 6];
    static weekdays = [1, 2, 3, 4, 5];

    private _options: any[] = [];
    private change: (any) => void;
    private model: any = { values: {} };
    private type: Type = Type.Simple;

    _outsideClickTarget: OutsideClickHelper;

    get options() {
        return this._options;
    }

    @Input()
    set options(options) {
        this._options = options;
        this._outsideClickTarget.target = this.dropdown;

        this.model = { values: {} };
        options.forEach(opt => {
            const property = opt.value.toString();

            if (typeof opt.disabled === 'undefined') {
                opt.disabled = false;
            }
            Object.defineProperty(this.model, property, {
                configurable: true,
                set: value => {
                    this.model.values[property] = value;
                    this.change(this.selected);
                },
                get: () => {
                    return this.model.values[property];
                }
            });
        });
    }

    constructor(
        @Attribute('placeholder')
        private placeholder: string,

        @Attribute('type') type: string,
        private el: ElementRef,
        public id: IdService
    ) {
        if (type === 'range') {
            this.type = Type.Range;
        } else if (type === 'hours') {
            this.type = Type.Hours;
        } else if (type === 'days') {
            this.type = Type.Days;
        }
        this._outsideClickTarget = new OutsideClickHelper(this.el.nativeElement , ()=>{
            this.dismissedEvent.emit(true)
        });
    }

    writeValue(value) {
        if (!Array.isArray(value)) {
            value = [];
        }
        for (let i = 0; i < this._options.length; i++) {
            let property = this._options[i].value;
            this.model.values[property.toString()] = value.indexOf(property) > -1;
        }
    }

    registerOnChange(fn) {
        this.change = fn;
    }

    registerOnTouched(fn) { }

    get label(): string {
        if (this.selected.length < 1) {
            return this.placeholder;
        }
        if (this.type === Type.Days) {
            return this.days();
        }
        if (this.type === Type.Hours) {
            return this.hours();
        }
        if (this.type === Type.Range) {
            return this.range();
        }
        return this.getValueLabel();
    }

    optionLabel(label): string {
        if (this.type === Type.Range) {
            if (label.length > 1) {
                return label.join('-');
            } else {
                return label + '+';
            }
        }
        if (this.type === Type.Hours) {
            return moment.unix(label[0]).utc().format('hA');
        }
        return label;
    }

    getValueLabel(): string {
        return this.selected.map(value => {
            return this.option(value).label;
        }).join(', ');
    }

    days(): string {
        if (this.selected.length === 7) {
            return 'Everyday';
        } else {
            switch (this.selected.toString()) {
                case MultiSelectComponent.weekdays.toString():
                    return 'Weekdays';
                case MultiSelectComponent.weekends.toString():
                    return 'Weekends';
                default: return this.getValueLabel();
            }
        }
    }

    hours(): string {
        const ranges = this.selected.map(key => this.option(key).label);
        const options = this.options.map(option => option.label);
        const groups = this.findRangeGroups(options, ranges);

        return groups.map(function (group) {
            return group.reduce(function (previous, current, i, group) {
                let a = current[0];
                let b = current[1];
                if (b === undefined) {
                    a += '+';
                }
                if (i === 0) {
                    previous.push(a);
                }
                if (i === group.length - 1) {
                    if (b !== undefined) {
                        previous.push(b);
                    } else if (group.length > 1) {
                        previous.push(a);
                    }
                }
                return previous;
            }, []).map(seconds => moment.unix(seconds).utc().format('hA')).join('-');
        }).join(', ');
    }

    range(): string {
        const ranges = this.selected.map(key => this.option(key).label);
        if (ranges && ranges[0] && Array.isArray(ranges[0]) && ranges[0][0]) {
            // Example: [[65], [18,20]]
            // After sorting: [[18,20], [65]];
            ranges.sort((a, b) => a[0] - b[0]);
        }
        const options = this.options.map(option => option.label);
        const groups = this.findRangeGroups(options, ranges);
        return groups.map(function (group) {
            return group.reduce(function (previous, current, i, group) {
                let a = current[0];
                let b = current[1];
                if (b === undefined) {
                    a += '+';
                }
                if (i === 0) {
                    previous.push(a);
                }
                if (i === group.length - 1) {
                    if (b !== undefined) {
                        previous.push(b);
                    } else if (group.length > 1) {
                        previous.push(a);
                    }
                }
                return previous;
            }, []).join('-');
        }).join(', ');
    }

    findRangeGroups(options, ranges) {
        let local;
        let groups = [];
        for (let i = 0; i < ranges.length; i++) {
            let range = ranges[i];
            if (local === undefined) {
                local = [range];
                continue;
            }
            let tail = local[local.length - 1];
            let j = options.indexOf(tail);
            let k = options.indexOf(range);
            if (j + 1 === k) {
                local.push(range);
            } else {
                groups.push(local.slice());
                local = [range];
            }
        }
        if (local !== undefined) {
            groups.push(local);
        }
        return groups;
    }

    get selected(): any[] {
        return Object.keys(this.model.values)
            .filter(item => this.model.values[item] === true).map(item => {
                let val: any = item;

                if (item.length === 32 || !this.isNumeric(val)) {
                    return item;
                }

                return parseInt(val);
            });
    }

    option(value: any): { disabled: boolean, key: any; label: any, value: any } {
        return this.options.find(opt => opt.value === value) || null;
    }

    // from https://github.com/jquery/jquery/blob/master/src/core.js
    isNumeric(value: any): boolean {
        return !isNaN( value - parseFloat( value ) );
    }
}
