import { TemplateRef } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { IdService } from 'app/core/id.service';
import { Status } from 'app/shared/models';
var TagsInputComponent = /** @class */ (function () {
    function TagsInputComponent(id) {
        this.id = id;
        this.input$ = new BehaviorSubject('');
        this.items$ = new BehaviorSubject([]);
        this.selectedItems$ = new BehaviorSubject([]);
        this.disabled = false;
        this.listLimit = 10;
        this.onChange = function (items) { };
    }
    TagsInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.availableItems$ = combineLatest(this.items$, this.selectedItems$, this.input$).pipe(map(function (_a) {
            var items = _a[0], selectedItems = _a[1], input = _a[2];
            var query = input.toLowerCase();
            var itemList = items.filter(function (item) { return selectedItems.findIndex(function (_item) { return _item.value === item.value; }) < 0; })
                .filter(function (item) { return input.length < 1 || item.label.toLowerCase().indexOf(query) > -1 || (item.search && item.search.toLowerCase().indexOf(query) > -1); });
            return _this.listLimit ? itemList.slice(0, _this.listLimit) : itemList;
        }));
    };
    TagsInputComponent.prototype.writeValue = function (selectedItems) {
        var _this = this;
        this.items$.subscribe(function (items) {
            var nextItems = (Array.isArray(selectedItems) ? selectedItems : [])
                .map(function (selectedItem) { return items.find(function (item) { return item.value === selectedItem; }); })
                .filter(function (item) { return item !== undefined; });
            _this.selectedItems$.next(nextItems);
        });
    };
    TagsInputComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    TagsInputComponent.prototype.registerOnTouched = function () { };
    Object.defineProperty(TagsInputComponent.prototype, "items", {
        set: function (items) {
            this.items$.next(Array.isArray(items) ? items : []);
        },
        enumerable: true,
        configurable: true
    });
    TagsInputComponent.prototype.select = function (item) {
        var selectedItems = this.selectedItems$.getValue().concat(item);
        this.selectedItems$.next(selectedItems);
        this.onChange(selectedItems.map(function (selectedItem) { return selectedItem.value; }));
    };
    TagsInputComponent.prototype.remove = function (index) {
        var selectedItems = this.selectedItems$.getValue();
        selectedItems.splice(index, 1);
        this.selectedItems$.next(selectedItems);
        this.onChange(selectedItems.map(function (selectedItem) { return selectedItem.value; }));
    };
    TagsInputComponent.prototype.removeAll = function () {
        var selectedItems = [];
        this.selectedItems$.next(selectedItems);
        this.onChange(selectedItems.map(function (selectedItem) { return selectedItem.value; }));
    };
    TagsInputComponent.prototype.inputChange = function (value) {
        this.input$.next(value);
    };
    TagsInputComponent.prototype.circleClass = function (status) {
        switch (status) {
            case Status.ACTIVE:
                return 'circle-success';
            default:
                return 'circle-inactive';
        }
    };
    Object.defineProperty(TagsInputComponent.prototype, "showStatus", {
        get: function () {
            return Array.isArray(this.items$.getValue()) && this.items$.getValue().some(function (item) { return item.status; });
        },
        enumerable: true,
        configurable: true
    });
    return TagsInputComponent;
}());
export { TagsInputComponent };
