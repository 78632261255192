import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { zip, of } from 'rxjs';
import { Advertiser, InsertionOrder, Campaign } from 'app/shared/models';
import { AdvertiserRepository, CampaignRepository, InsertionOrderRepository } from 'app/core/repositories';
import { map, mergeMap } from 'rxjs/operators';
import { Flag, LaunchDarklyService } from '../launch-darkly.service';
import { CAMPAIGN_CONST } from 'app/shared/models/campaign';

@Injectable()
export class CampaignFlowResolver
implements Resolve<{ advertiser: Advertiser, insertionOrder: InsertionOrder, campaign: Campaign }> {
    constructor(
        private advertiserRepository: AdvertiserRepository,
        private insertionOrderRepository: InsertionOrderRepository,
        private campaignRepository: CampaignRepository,
        private launchDarklyService: LaunchDarklyService,
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        let id = route.paramMap.get('io') || (route.firstChild ? route.firstChild.paramMap.get('io') : null);
        const parentCampaignId = route.paramMap.get('parentCampaign') || (route.firstChild ? route.firstChild.paramMap.get('parentCampaign') : null);

        if (id) {
            return this.insertionOrderRepository
            .get(id)
            .pipe(mergeMap(insertionOrder => zip(
                this.advertiserRepository.get(insertionOrder.advertiser),
                of(insertionOrder),
                of(new Campaign()),
                this.hasAudienceExtensionFlag()).pipe(
                    mergeMap(([advertiser, insertionOrder, campaign, audienceExtensionFlag]) => {
                        campaign.canAccessAudienceExtension = audienceExtensionFlag;
                        if (parentCampaignId) {
                            return this.campaignRepository.get(parentCampaignId).pipe(map((parentCampaign: Campaign) => {
                                if (parentCampaign && this.isAudienceExtensionParentCampaign(parentCampaign, insertionOrder)) {
                                    campaign.parentCampaign = parentCampaign.id;
                                    campaign.demandType = CAMPAIGN_CONST.EXCHANGE;
                                    campaign.isAudienceExtension = true;
                                    return { advertiser, insertionOrder, campaign, parentCampaign };
                                }
                                return { advertiser, insertionOrder, campaign };
                            }));
                        } else {
                            return of({ advertiser, insertionOrder, campaign });
                        }
                    })
                )
            ));
        } else {
            /**
             * Self Service campaigns do not have an insertion order on create
             * so we use the parent advertiser to set up the campaign
             **/
            id = route.paramMap.get('advertiser');

            if (route.firstChild && route.firstChild.paramMap.has('advertiser')) {
                id = route.firstChild.paramMap.get('advertiser');
            }

            return this.advertiserRepository.get(id)
                .pipe(mergeMap(advertiser => zip(
                    of(advertiser),
                    of(new InsertionOrder({
                        isUnlimitedBudget: true
                    })),
                    of(new Campaign()),
                    this.hasAudienceExtensionFlag()).pipe(
                        mergeMap(([advertiser, insertionOrder, campaign, audienceExtensionFlag]) => {
                            campaign.canAccessAudienceExtension = audienceExtensionFlag;
                            if (parentCampaignId) {
                                return this.campaignRepository.get(parentCampaignId).pipe(map((parentCampaign: Campaign) => {
                                    if (parentCampaign && this.isAudienceExtensionParentCampaign(parentCampaign, insertionOrder)) {
                                        campaign.parentCampaign = parentCampaign.id;
                                        campaign.demandType = CAMPAIGN_CONST.EXCHANGE;
                                        campaign.isAudienceExtension = true;
                                        return { advertiser, insertionOrder, campaign, parentCampaign };
                                    }
                                }));
                            } else {
                                return of({ advertiser, insertionOrder, campaign });
                            }
                        })
                    )
                ));
        }
    }

    private hasAudienceExtensionFlag() {
        return this.launchDarklyService
            .getVariation(Flag.RolloutAudienceExtension);
    }

    /**
     * Checks if campaign is eligible
     * to be an audience extendable campaign
     * @param parentCampaign
     * @param insertionOrder
     * @return boolean
     */
    isAudienceExtensionParentCampaign(parentCampaign: Campaign, insertionOrder: InsertionOrder): boolean {
        return parentCampaign.isDirectSold && parentCampaign.insertionOrder == insertionOrder.id;
    }
}
