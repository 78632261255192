import { Component, ElementRef, Attribute } from '@angular/core';

@Component({
    selector: 'button',
    templateUrl: './button.html',
    styleUrls: ['./button.styl']
})
export class ButtonComponent {
    static loading: string = 'Loading...';

    constructor(
        @Attribute('loading') private loading: string,
        public el: ElementRef) {
        this.el.nativeElement.disabled = false;
        if (this.loading === null) {
            this.loading = ButtonComponent.loading;
        }
    }

    private _loading: string = null;

    // This function will set the button to a loading state and if given a promise
    // as its first parameter it will reset when the promise resolves.
    // If the promise is not given or does not resolve, the button will have to be
    // reset using the `.reset()` method.
    public load(promise?: Promise<any>) {
        // We disallow calling `load()` when the button is disabled.
        if (this.el.nativeElement.disabled === true) {
            return;
        }
        this._load(this.loading, true);
        if (promise === undefined || typeof promise.then !== 'function') {
            return;
        }
        // Always reset when the promise is resolved. Catch any potential error,
        // ignore and perform the reset.
        promise.catch().then(() => this.reset());
        return promise;
    }

    // This is an internal function used to control the load state of the button.
    private _load(html, disabled): void {
        if (!this._loading) {
            this._loading = this.el.nativeElement.innerHTML;
        }
        this.el.nativeElement.innerHTML = html;
        this.el.nativeElement.disabled = disabled;
    }

    // This function resets the button to its original state.
    public reset(): void {
        this._load(this._loading, false);
    }
}
