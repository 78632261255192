import { Component, Input } from '@angular/core';

@Component({
    selector: 'disabled-link',
    templateUrl: './disabled-link.html',
    styleUrls: ['./disabled-link.styl']
})
export class DisabledLinkComponent {
    @Input() disabled: boolean = false;
}
