import { OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { forkJoin, combineLatest, of } from 'rxjs';
import moment from 'moment';
import { AdSlotRepository, AdvertiserRepository, SamovarAudienceRepository, BundleRepository, CampaignRepository, CategoryRepository, CreativeRepository, InsertionOrderRepository, ISPRepository, PixelRepository, UtilRepository } from 'app/core/repositories';
import { DateHelperService } from 'app/shared/elements/date-picker/date-helper';
import { LightboxComponent } from 'app/shared/elements/elements';
import { StatusCardComponent } from 'app/shared/elements/status-card/status-card.component';
import { NotificationsService, IdService } from 'app/core';
import { environment } from 'src/environments/environment';
import { map, mergeMap } from 'rxjs/operators';
var GoingLiveNotificationComponent = /** @class */ (function () {
    function GoingLiveNotificationComponent(adSlotRepository, advertiserRepository, samovarAudienceRepository, bundleRepository, campaignRepository, categoryRepository, creativeRepository, dateHelper, insertionOrderRepository, ispRepository, pixelRepository, utilRepository, notifications, id) {
        this.adSlotRepository = adSlotRepository;
        this.advertiserRepository = advertiserRepository;
        this.samovarAudienceRepository = samovarAudienceRepository;
        this.bundleRepository = bundleRepository;
        this.campaignRepository = campaignRepository;
        this.categoryRepository = categoryRepository;
        this.creativeRepository = creativeRepository;
        this.dateHelper = dateHelper;
        this.insertionOrderRepository = insertionOrderRepository;
        this.ispRepository = ispRepository;
        this.pixelRepository = pixelRepository;
        this.utilRepository = utilRepository;
        this.notifications = notifications;
        this.id = id;
        this.isps = [];
        this.bundles = [];
        this.liveAudience = [];
        this.adSlots = [];
        this.emails = '';
        this.subscriptions = [];
    }
    GoingLiveNotificationComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) {
            subscription.unsubscribe();
        });
    };
    GoingLiveNotificationComponent.prototype.fetchData = function () {
        var _this = this;
        return forkJoin(this.categoryRepository.all(), this.advertiserRepository.get(this.lineItem.advertiser), this.campaignRepository.get(this.lineItem.campaign), this.creativeRepository.searchByLineItem(this.lineItem.id), this.fetchAdSlots(this.lineItem.adSlots || [])).pipe(mergeMap(function (_a) {
            var categories = _a[0], advertiser = _a[1], campaign = _a[2], creatives = _a[3], adSlots = _a[4];
            var pixel = campaign.hasConversionTracker() ?
                _this.pixelRepository.get(campaign.conversionPixel) : of(null);
            return combineLatest(_this.insertionOrderRepository.get(campaign.insertionOrder), pixel).pipe(map(function (_a) {
                var insertionOrder = _a[0], pixel = _a[1];
                return [categories, advertiser, insertionOrder, campaign, creatives, pixel, adSlots];
            }));
        }));
    };
    GoingLiveNotificationComponent.prototype.fetchAdSlots = function (adSlotIds) {
        if (adSlotIds.length === 0) {
            return of([]);
        }
        return this.adSlotRepository.search({
            conditions: [{
                    field: 'id',
                    value: adSlotIds
                }]
        });
    };
    GoingLiveNotificationComponent.prototype.init = function (lineItem) {
        var _this = this;
        if (this.lineItem && this.lineItem.id === lineItem.id) {
            this.lightbox.open();
            this.findMappingInfo();
            return;
        }
        this.lineItem = lineItem;
        var subscription = this.fetchData()
            .subscribe(function (_a) {
            var categories = _a[0], advertiser = _a[1], insertionOrder = _a[2], campaign = _a[3], creatives = _a[4], pixel = _a[5], adSlots = _a[6];
            _this.categories = categories;
            _this.advertiser = advertiser;
            _this.insertionOrder = insertionOrder;
            _this.campaign = campaign;
            _this.creatives = creatives;
            _this.pixel = pixel;
            _this.adSlots = adSlots;
            _this.advertiser['categoryIAB'] = _this.categories.find(function (category) {
                return parseInt(category.id) === advertiser.category;
            });
            _this.campaign['categoryIAB'] = _this.categories.find(function (category) {
                return parseInt(category.id) === campaign.category;
            });
            _this.lightbox.open();
            _this.findMappingInfo();
        });
        this.subscriptions.push(subscription);
    };
    GoingLiveNotificationComponent.prototype.findMappingInfo = function () {
        var _this = this;
        if (this.lineItem.audienceTargeting) {
            this.samovarAudienceRepository.searchByAccountId(this.lineItem.advertiserObj.accountId)
                .subscribe(function (audiences) {
                _this.liveAudience = audiences.map(function (audience, i) {
                    return {
                        key: i,
                        value: audience.uniqueSegmentId,
                        label: '(ID: ' + audience.uniqueSegmentId + ') ' + audience.name,
                        data: audience
                    };
                });
            });
        }
        if (this.lineItem.categories) {
            this.categoryRepository.all().subscribe(function (options) {
                _this.categories = options.map(function (option) {
                    return {
                        key: option.id,
                        value: option.id,
                        label: [option.iab, option.name].join(': '),
                        data: option
                    };
                });
            });
        }
        if (this.lineItem.bundles) {
            this.bundleRepository.all().subscribe(function (bundles) {
                _this.bundles = bundles.map(function (option) {
                    return {
                        key: option.id,
                        value: option.id,
                        label: option['name']
                    };
                });
            });
        }
        if (this.lineItem.isps) {
            this.ispRepository.all().subscribe(function (isps) {
                _this.isps = isps.map(function (option) {
                    return {
                        key: option.id,
                        value: option.id,
                        label: option['name']
                    };
                });
            });
        }
    };
    GoingLiveNotificationComponent.prototype.send = function () {
        var _this = this;
        var emailArr = [];
        if (environment.emailGroup && environment.emailGroup.goingLive) {
            emailArr = environment.emailGroup.goingLive;
        }
        if (this.emails.length > 0) {
            emailArr = emailArr.concat(this.emails.split(','));
            emailArr = emailArr.map(function (email) { return email.trim(); });
        }
        if (emailArr.length < 1) {
            this.lightbox.close();
            return;
        }
        var datesToUse = this.dateHelper.determineDatesToUse(this.lineItem, this.insertionOrder);
        var datePipe = new DatePipe('en-US');
        var statusCard = new StatusCardComponent();
        statusCard.status = this.lineItem.getPlatformStatus();
        statusCard.startDate = datesToUse.startDate;
        statusCard.endDate = datesToUse.endDate;
        statusCard.compareDate = moment();
        statusCard.ngOnInit();
        var params = {
            emails: emailArr,
            status: statusCard.status,
            statusText: statusCard.statusText ?
                statusCard.statusText + ' ' + datePipe.transform(statusCard.dateToUse, 'shortDate') : null,
            goalDescription: this.campaign.goalDescription
        };
        this.utilRepository.strategyLaunch(params, this.lineItem.refId)
            .subscribe(function () {
            _this.lightbox.close();
            _this.notifications.success('Line item launch notification sent');
        }, function () {
            _this.notifications.error('There was a problem sending the notification');
        });
    };
    return GoingLiveNotificationComponent;
}());
export { GoingLiveNotificationComponent };
