import { NgModel } from '@angular/forms';
import { IdService } from 'app/core/id.service';
import { clone } from "app/core/utils";
var BulkEditTextComponent = /** @class */ (function () {
    function BulkEditTextComponent(label, id) {
        this.id = id;
        this._entities = [];
        this._originals = [];
        this.entityName = '';
        this.label = label;
    }
    BulkEditTextComponent.prototype.master = function (value) {
        var _this = this;
        this.entities.forEach(function (entity) { return entity[_this.field] = clone(value); });
    };
    BulkEditTextComponent.prototype.original = function (index) {
        return this._originals[index];
    };
    BulkEditTextComponent.prototype.reset = function () {
        var _this = this;
        this.input.reset();
        this.entities.forEach(function (entity, index) { return entity[_this.field] = clone(_this.original(index)[_this.field]); });
    };
    BulkEditTextComponent.prototype.resetEntity = function (entity, index) {
        if (index === void 0) { index = null; }
        var i = index;
        if (!i) {
            i = this.entities.indexOf(entity);
        }
        entity[this.field] = clone(this.original(i)[this.field]);
    };
    BulkEditTextComponent.prototype.isDirty = function () {
        var _this = this;
        return this.entities.some(function (entity, index) { return entity[_this.field] !== _this.original(index)[_this.field]; });
    };
    Object.defineProperty(BulkEditTextComponent.prototype, "entities", {
        get: function () {
            return this._entities;
        },
        set: function (entities) {
            this._entities = entities;
            this._originals = entities.map(function (entity) { return entity.clone(); });
        },
        enumerable: true,
        configurable: true
    });
    return BulkEditTextComponent;
}());
export { BulkEditTextComponent };
