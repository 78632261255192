import { Directive, Input, HostBinding, ElementRef } from '@angular/core';

@Directive({
    selector: '[id-append]'
})
export class IdAppendDirective {
    constructor(private elementRef: ElementRef<HTMLElement>) { }

    @Input('id-append') id: string[] = null;

    @HostBinding('attr.id') get attrId() {
        return [...this.getNearestId(this.elementRef.nativeElement.parentElement), ...this.id].join('.')
    }

    getNearestId(element: HTMLElement): string[] {
        if (element.id) {
            return [element.id];
        }
        if (element.parentElement) {
            return this.getNearestId(element.parentElement)
        }
        return []
    }
}
