import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IABSize } from 'app/shared/models';
import { BackendRepository } from './backend-repository';

@Injectable()
export class IABSizeRepository extends BackendRepository<IABSize> {
    public constructor(http: HttpClient) {
        super(http, '/iab-size', IABSize);
    }
}
