import { Model } from './model';

export class Seller extends Model {
    name: string;
    domain: string;
    mediaGroups: [{
        name: string,
        refId: number
    }]

    serialize(): string {
        return JSON.stringify(this);
    }

    get entity() {
        return 'Seller';
    }
}
