import {
    Component,
    ChangeDetectionStrategy,
    forwardRef,
    ElementRef,
    ViewChild,
    OnInit,
    Attribute,
    OnDestroy,
    ChangeDetectorRef,
    Input
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReplaySubject, Subscription } from 'rxjs';
import moment from 'moment';
import { filter } from 'rxjs/operators';
import { IdService } from 'app/core/id.service';

enum Meridiem {
    AM,
    PM
}

@Component({
    selector: 'time-picker',
    templateUrl: './time-picker.html',
    styleUrls: ['./time-picker.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimePickerComponent),
            multi: true
        }
    ]
})
export class TimePickerComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() disabled: boolean = false;
    @ViewChild('input', { static: true }) inputElement: ElementRef;
    time$ = new ReplaySubject<moment.Moment>(1);
    meridiem = Meridiem.AM;

    meridiemOptions = [
        {
            key: 0,
            value: Meridiem.AM,
            label: 'AM'
        },
        {
            key: 1,
            value: Meridiem.PM,
            label: 'PM'
        }
    ];

    private onChange: Subscription;
    private onUpdate: Subscription;

    constructor(
        @Attribute('placeholder') public placeholder,
        private cdr: ChangeDetectorRef,
        public id: IdService
    ) { }

    ngOnInit() {
        this.onUpdate = this.time$.pipe(filter(time => time !== null)).subscribe(time => this.update(time));
    }

    ngOnDestroy() {
        this.onChange.unsubscribe();
        this.onUpdate.unsubscribe();
    }

    writeValue(value) {
        this.update(moment(value || null, 'HH:mm'));
    }

    registerOnChange(onChange) {
        this.onChange = this.time$.subscribe(time => onChange(time.isValid() ? time.format('HH:mm') : null));
    }

    registerOnTouched(onTouched) {
        //
    }

    inputChange(value: string) {
        this.time$.next(moment(value, 'hh:mm'));
    }

    setMeridiem(meridiem: Meridiem) {
        this.meridiem = meridiem;

        const time = moment(this.inputElement.nativeElement.value, 'HH:mm');
        const hour = time.hour();

        if (meridiem === Meridiem.PM && hour < 12) {
            time.add('hours', 12);
        } else if (meridiem === Meridiem.AM && hour > 11) {
            time.subtract('hours', 12);
        }

        this.time$.next(time);
    }

    reset() {
        this.inputElement.nativeElement.value = null;
    }

    private update(time: moment.Moment) {
        if (time.isValid()) {
            this.inputElement.nativeElement.value = time.format('hh:mm');
            this.meridiem = time.hours() >= 12 ? Meridiem.PM : Meridiem.AM;
            this.cdr.markForCheck();
        } else {
            delete this.inputElement.nativeElement.value;
        }
    }
}
