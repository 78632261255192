import * as tslib_1 from "tslib";
import { Model } from './model';
var liveConnectTagTemplate = '' +
    "<!-- LiveConnectTag for advertisers -->\n  <script type=\"text/javascript\" src=\"//b-code.liadm.com/{appId}.min.js\" async=\"true\" charset=\"utf-8\"></script>\n<!-- LiveConnectTag for advertisers -->";
var LiveConnectTag = /** @class */ (function (_super) {
    tslib_1.__extends(LiveConnectTag, _super);
    function LiveConnectTag() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(LiveConnectTag.prototype, "liveConnectTagJs", {
        get: function () {
            return liveConnectTagTemplate.replace('{appId}', this.appId);
        },
        enumerable: true,
        configurable: true
    });
    LiveConnectTag.prototype.serialize = function () {
        return JSON.stringify(this);
    };
    Object.defineProperty(LiveConnectTag.prototype, "entity", {
        get: function () {
            return 'LiveConnect Tag';
        },
        enumerable: true,
        configurable: true
    });
    return LiveConnectTag;
}(Model));
export { LiveConnectTag };
