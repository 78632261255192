import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PublisherKeyValue } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { map } from 'rxjs/operators';

@Injectable()
export class PublisherKeyValueRepository extends BackendRepository<PublisherKeyValue> {

    public constructor(http: HttpClient) {
        super(http, '/key-value/publisher', PublisherKeyValue);
    }

    searchByPublisher(id: string): Observable<PublisherKeyValue[]> {
        return this.http.get(this.url('/search/key-value/publisher', id))
            .pipe(map(response => response['output'].map(data => this.build(data)) as PublisherKeyValue[]));
    }

}
