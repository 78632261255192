import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from "@angular/core";
import { IdService } from 'app/core/id.service';
import { AvailableActionsService } from "app/shared/helpers/available-actions.service";
import { BlueprintRepository, CreativeRepository, LineItemRepository } from "app/core/repositories";
import { ReplaySubject } from "rxjs";
import { mergeMap, tap } from "rxjs/operators";
import { FrontendTableHelper } from "app/shared/helpers/frontend-table-helper";
import { ConfirmDialogComponent } from "app/shared/elements/confirm-dialog";
import { DownloadHelper } from "app/shared/helpers/download-helper";
var LinkedBlueprintsComponent = /** @class */ (function () {
    function LinkedBlueprintsComponent(blueprintRepository, lineItemRepository, creativeRepository, id, availActions) {
        var _this = this;
        this.blueprintRepository = blueprintRepository;
        this.lineItemRepository = lineItemRepository;
        this.creativeRepository = creativeRepository;
        this.id = id;
        this.availActions = availActions;
        this.detailsOnly = false;
        this.unlinkBlueprint = new EventEmitter();
        this.blueprintUpdated = new EventEmitter();
        this.tableHelper = new FrontendTableHelper(function () { return _this.adapter(); });
        this.nativeBlueprints$ = new ReplaySubject(1);
        this.lineItems = [];
        this.blueprintIds = [];
        this.mismatchingLineItems = [];
    }
    Object.defineProperty(LinkedBlueprintsComponent.prototype, "nativeBlueprints", {
        set: function (value) {
            if (value) {
                this.blueprintIds = value.map(function (bp) { return bp.blueprintId; });
            }
            this.nativeBlueprints$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    ;
    LinkedBlueprintsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.lineItemRepository.asyncSearchByAdSlot({
            "conditions": [
                {
                    "field": "adSlot",
                    "value": this.adSlot.id,
                    "operator": "eq"
                }
            ]
        }).pipe(tap(function (res) {
            _this.lineItems = res.items.map(function (lineItem) {
                return { lineItem: lineItem, creatives: [] };
            });
        }), 
        // Flatten array to observable array
        mergeMap(function (res) { return res.items; }), 
        // Fetch creatives
        mergeMap(function (lineItem) { return _this.creativeRepository.searchByLineItem(lineItem.id); }))
            .subscribe(function (creatives) {
            if (creatives.length) {
                var lineItem = _this.lineItems.find(function (lineItemObject) { return lineItemObject.lineItem.id === creatives[0].strategyId; });
                var lineItemIndex = _this.lineItems.indexOf(lineItem);
                lineItem['creatives'] = creatives;
                _this.lineItems[lineItemIndex] = tslib_1.__assign({}, lineItem);
            }
        });
    };
    LinkedBlueprintsComponent.prototype.adapter = function () {
        var _this = this;
        return this.nativeBlueprints$.pipe(mergeMap(function (nativeBlueprints) {
            var blueprintIds = [];
            if (nativeBlueprints != null) {
                nativeBlueprints.forEach(function (nb) { return blueprintIds.push(nb.blueprintId); });
            }
            var payload = {
                conditions: [{
                        field: 'id',
                        value: blueprintIds,
                        operator: 'eq'
                    }]
            };
            return _this.blueprintRepository.search(payload);
        }));
    };
    LinkedBlueprintsComponent.prototype.attachBlueprintAssets = function (nativeBlueprints, blueprints) {
        var attachedBlueprintAssets = [];
        var nativeBlueprintMap = (nativeBlueprints || []).reduce(function (obj, val) { obj[val.blueprintId] = val; return obj; }, {});
        blueprints.map(function (blueprint) {
            blueprint.assets = nativeBlueprintMap[blueprint.id].native.assets || [];
            attachedBlueprintAssets.push(blueprint);
        });
        return attachedBlueprintAssets;
    };
    LinkedBlueprintsComponent.prototype.getBlueprintAllowExchange = function (id) {
        if (this.adSlot && this.adSlot.nativeBlueprints) {
            var blueprint = this.adSlot.nativeBlueprints.find(function (blueprint) { return blueprint.blueprintId === id; });
            return blueprint ? this.booleanize(blueprint.allowExchange) : false;
        }
        return false;
    };
    LinkedBlueprintsComponent.prototype.getBlueprintAllowRtb = function (id) {
        if (this.adSlot && this.adSlot.nativeBlueprints) {
            var blueprint = this.adSlot.nativeBlueprints.find(function (blueprint) { return blueprint.blueprintId === id; });
            return blueprint ? this.booleanize(blueprint.allowRtb) : false;
        }
        return false;
    };
    LinkedBlueprintsComponent.prototype.updateBlueprint = function (blueprintId, property, value) {
        var blueprintData = {
            blueprintId: blueprintId
        };
        blueprintData[property] = value;
        this.blueprintUpdated.next(blueprintData);
    };
    LinkedBlueprintsComponent.prototype.disconnect = function (blueprintId) {
        var _this = this;
        this.mismatchingLineItems = [];
        this.lineItems.filter(function (lineItemObj) { return !lineItemObj.creatives.some(
        // Does the creative match any of the remaining blueprints?
        function (creative) { return _this.blueprintIds.filter(function (bp) { return bp !== blueprintId; }).indexOf(creative.blueprintId) !== -1; }); }).map(function (lineItemObject) { return lineItemObject.creatives.map(function (creative) {
            // Did creative match the removed blueprint id? - Was the creative serving?
            if (creative.blueprintId == blueprintId) {
                _this.mismatchingLineItems.push({
                    "Line Item name": lineItemObject.lineItem.name,
                    "Line Item id": lineItemObject.lineItem.refId,
                    "Start date": lineItemObject.lineItem.startDate,
                    "End date": lineItemObject.lineItem.endDate,
                    "Creative id": creative.refId,
                    "Creative name": creative.name,
                });
            }
        }); });
        if (this.mismatchingLineItems.length) {
            this.unlinkedBlueprintDialog.confirmed.subscribe(function () {
                _this.unlinkBlueprint.next(blueprintId);
                _this.unlinkedBlueprintDialog.close();
            });
            this.unlinkedBlueprintDialog.open('disconnect-blueprint');
        }
        else {
            this.unlinkBlueprint.next(blueprintId);
        }
    };
    LinkedBlueprintsComponent.prototype.query = function (tableQuery) {
        this.tableHelper.search([tableQuery]);
    };
    LinkedBlueprintsComponent.prototype.downloadFile = function () {
        DownloadHelper.downloadAsCSV(this.mismatchingLineItems, "report");
    };
    LinkedBlueprintsComponent.prototype.booleanize = function (value) {
        if (value === 'true') {
            return true;
        }
        return value;
    };
    return LinkedBlueprintsComponent;
}());
export { LinkedBlueprintsComponent };
