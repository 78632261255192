import { EventEmitter, QueryList } from '@angular/core';
import { OptionComponent } from '../filter-select';
import { IdService } from 'app/core/id.service';
var BubbleCardComponent = /** @class */ (function () {
    function BubbleCardComponent(id) {
        this.id = id;
        this.onSelect = new EventEmitter();
    }
    Object.defineProperty(BubbleCardComponent.prototype, "options", {
        get: function () {
            if (this.tplOptions && this.tplOptions.length > 0) {
                return this.tplOptions.map(function (option, key) {
                    var param = option.value;
                    var text = option.html;
                    return { key: key, param: param, text: text };
                });
            }
            return this.inputOptions;
        },
        enumerable: true,
        configurable: true
    });
    BubbleCardComponent.prototype.select = function (option) {
        this.onSelect.emit(option);
    };
    Object.defineProperty(BubbleCardComponent.prototype, "iconClass", {
        get: function () {
            return "fa-" + this.iconName;
        },
        enumerable: true,
        configurable: true
    });
    return BubbleCardComponent;
}());
export { BubbleCardComponent };
