import { Attribute, Component, ViewChild } from '@angular/core';

import { Model, TargetingType } from 'app/shared/models';
import { WizardComponent } from 'app/shared/elements/wizard';
import { DomainTargetingComponent } from 'app/shared/components/domain-targeting';
import { HasDirtyCheck, PreventsSave, Resettable } from 'app/shared/components/bulk-edit-lightbox';
import { dedupe } from 'app/core/array-utils';
import { isEqual } from 'app/core/utils';

type Action = 'add' | 'remove' | 'replace' | 'delete';

@Component({
    selector: 'bulk-edit-domain-targeting',
    templateUrl: './bulk-edit-domain-targeting.component.html'
})
export class BulkEditDomainTargetingComponent implements Resettable, HasDirtyCheck, PreventsSave {
    @ViewChild(WizardComponent, { static: true }) wizard: WizardComponent;
    @ViewChild(DomainTargetingComponent, { static: false }) domainTargetingComponent: DomainTargetingComponent;
    entityName: string = '';
    label: string = '';
    field: string;
    action: Action = 'add';

    private _entities: Model[] = [];
    private _originals: Model[] = [];
    private _domains: string[] = [];
    private _targetingType: TargetingType;
    private targetingTypeField = 'domainTargetingType';
    private selectionMap = new Map<number, boolean>();

    constructor(@Attribute('label') label: string) {
        this.label = label;
    }

    get entities() {
        return this._entities;
    }

    set entities(entities: Model[]) {
        this._entities = entities;
        this._originals = entities.map(entity => entity.clone());
        this.master = true;
    }

    get domains() {
        return this._domains;
    }

    set domains(domains: string[]) {
        this._domains = domains;
        this.applyChangeToDomains();
    }

    get targetingType() {
        return this._targetingType;
    }

    set targetingType(targetingType: TargetingType) {
        this._targetingType = targetingType;

        if (this.wizard.activeStepIndex > 0) {
            this.selected.forEach(entity => entity[this.targetingTypeField] = targetingType);
        }
    }

    isMinority(index: number) {
        const numIncluded = this._originals.filter(entity => entity[this.targetingTypeField] === 'include').length;
        const numExcluded = this._originals.length - numIncluded;

        if (numIncluded > numExcluded) {
            return this.original(index)[this.targetingTypeField] === 'exclude';
        }

        if (numIncluded < numExcluded) {
            return this.original(index)[this.targetingTypeField] === 'include';
        }

        return false;
    }

    isSelected(index: number) {
        return this.selectionMap.get(index);
    }

    toggleSelection(value: boolean, index: number) {
        this.selectionMap.set(index, value);
    }

    get selected() {
        return this.entities.filter((_, index) => this.selectionMap.get(index));
    }

    get master() {
        return this.entities.every((_, index) => this.selectionMap.get(index));
    }

    set master(value: boolean) {
        this.entities.forEach((_, index) => this.selectionMap.set(index, value))
    }

    get multipleTargetingTypesSelected() {
        return this.originalsOfSelected()
            .some((entity, _, arr) => entity[this.targetingTypeField] !== arr[0][this.targetingTypeField]);
    }

    private originalsOfSelected() {
        return this._originals.filter((_, i) => this.isSelected(i));
    }

    original(index: number) {
        return this._originals[index];
    }

    next() {
        this.handleActionChange(this.action);
        this.wizard.nextStep();
    }

    previous() {
        this.wizard.previousStep();
        this.entities = this._originals;
    }

    handleActionChange(action: Action) {
        if (action !== 'replace') {
            // reset the targeting type back to what it was if not replacing
            this.targetingType = this.originalTargetingType;
        }
        this.applyChangeToDomains();
    }

    get originalTargetingType() {
        if (this.originalsOfSelected().length === 0) {
            return 'exclude';
        }

        // once this function is called, we should assume all
        // selected entities share the same targeting type
        // since you cannot get past step 1 with multiple types selected
        return this.originalsOfSelected()[0][this.targetingTypeField];
    }

    private applyChangeToDomains() {
        switch (this.action) {
            case 'delete':
                this._domains = [];
                this.selected.forEach(entity => entity[this.field] = []);
                break;
            case 'replace':
                this.selected.forEach(entity => entity[this.field] = this.domains);
                break;
            case 'add':
                this.selected.forEach((entity, index) => {
                    entity[this.field] = dedupe(this.originalsOfSelected()[index][this.field].concat(this.domains));
                });
                break;
            case 'remove':
                this.selected.forEach((entity, index) => {
                    entity[this.field] = this.originalsOfSelected()[index][this.field].filter((existing: string) => !this.domains.includes(existing));
                });
                break;
        }
    }

    isDirty() {
        // check if targeting type has been changed
        if (this.targetingType && this.targetingType !== this.originalTargetingType) {
            return true;
        }

        // check if the main domains field has been changed
        if (this.entities.every((entity, index) => !isEqual(entity[this.field], this.original(index)[this.field]))) {
            return true;
        }

        return false;
    }

    isPreventingSave() {
        return this.action !== 'delete' && this.domains.length === 0;
    }

    reset() {
        this._originals = [];
        this.entities = [];

        if (this.domainTargetingComponent) {
            this.domainTargetingComponent.reset();
        }

        while (this.wizard.activeStepIndex !== 0) {
            this.wizard.previousStep();
        }
    }
}
