import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { LineItem } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
var ExchangeStrategyRepository = /** @class */ (function (_super) {
    tslib_1.__extends(ExchangeStrategyRepository, _super);
    function ExchangeStrategyRepository(http) {
        return _super.call(this, http, '/exchange-strategy', LineItem) || this;
    }
    return ExchangeStrategyRepository;
}(BackendRepository));
export { ExchangeStrategyRepository };
