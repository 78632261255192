import * as tslib_1 from "tslib";
import { Model } from "../model";
export var Status;
(function (Status) {
    Status["Pending"] = "pending";
    Status["Success"] = "success";
    Status["Failed"] = "failed";
    Status["Cancelled"] = "cancelled";
    Status["Completed"] = "completed";
    Status["CompletedWithFailures"] = "completed (with failures)";
})(Status || (Status = {}));
var BulkOperationStatusResponse = /** @class */ (function (_super) {
    tslib_1.__extends(BulkOperationStatusResponse, _super);
    function BulkOperationStatusResponse() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(BulkOperationStatusResponse.prototype, "entity", {
        get: function () {
            return 'Bulk Operation Status Response';
        },
        enumerable: true,
        configurable: true
    });
    return BulkOperationStatusResponse;
}(Model));
export { BulkOperationStatusResponse };
