import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { Action, AuthorizationService, Entity, Role } from 'app/core/authorization.service';
import { AdBuilderNativeComponent } from './ad-builder-native';
import { AdBuilderNativeExchangeComponent } from './ad-builder-native-exchange';
import { BulkTagUploaderComponent } from './bulk-tag-uploader';
import { Campaign, Creative, LineItem } from 'app/shared/models';
import { CreativeRepository, NativeDemandImageAssetRepository, NativeDemandTextAssetRepository } from 'app/core/repositories';
import { PaginatorComponent } from 'app/shared/elements/paginator';
import { CreativeSizeValidation } from 'app/shared/models/creative';
import { SearchParams } from 'app/shared/helpers/query-builder';
import { TableComponent } from 'app/shared/elements/table';
import { TableHelper } from 'app/shared/helpers/table-helper';
import { TabsComponent } from 'app/shared/elements/tabs';
import { IdService } from 'app/core/id.service';
import { ValidationService } from 'app/core/validation-service';
import validationErrors from 'app/core/errors/validation-errors.json';
import { DemandAssetService } from 'app/core/demand-asset.service';
import { isOverMaxFileSize, getMaxFileSizeByType } from 'app/shared/helpers/ad-builder-helper';
import { lodashDeepClone, truncateString } from 'app/core/utils';
import { Flag, LaunchDarklyService } from 'app/core/launch-darkly.service';
import { CreativeStatus, creativeStatusColors } from 'app/shared/models/creative';

@Component({
    selector: 'ad-builder',
    templateUrl: './ad-builder.html',
    styleUrls: [ './ad-builder.styl' ],
    providers: [ DemandAssetService, NativeDemandImageAssetRepository, NativeDemandTextAssetRepository ]
})
export class AdBuilderComponent implements OnInit {
    private validationService: ValidationService = null;
    validationErrors = validationErrors;
    search: string = '';
    creativeRows: any[] = [];
    tags: any[] = [];
    state: string = 'closed';
    allChecked: boolean = false;
    anyChecked: boolean = false;
    checkedRows: number = 0;
    fileError: string;
    isCreativeSizeValid: boolean = false;

    helper = new TableHelper<Creative>(params => this.adapter(params));
    files;
    truncateString = truncateString;

    private rolloutNativeCreativeTrafficking$ = new BehaviorSubject(false);
    public rolloutNativeCreativeTrafficking;

    action = Action;
    role = Role;
    entity = Entity;

    @ViewChild(TabsComponent, { static: true }) tabs: TabsComponent;
    @ViewChild(AdBuilderNativeComponent, { static: false }) nativeAdForm: AdBuilderNativeComponent;
    @ViewChild(AdBuilderNativeExchangeComponent, { static: false }) nativeExchangeAdForm: AdBuilderNativeExchangeComponent;
    @ViewChild(BulkTagUploaderComponent, { static: true }) bulkTagUploader: BulkTagUploaderComponent;
    @ViewChild(TableComponent, { static: true }) table: TableComponent;
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent;

    @Input() creatives: any[] = [];
    @Input() ads: any[] = [];
    @Input() campaign: Campaign;
    @Input() lineItem: LineItem;
    @Input() set placementId(value) {
        if (value) {
            this._placementId$.next(value);
        } else {
            this._placementId$.next(null);
        }
    }

    @Output() adsChange: EventEmitter<Creative[]> = new EventEmitter<Creative[]>();
    @Output() onClosed: EventEmitter<any> = new EventEmitter<any>();
    _placementId$ = new BehaviorSubject<any>('');

    constructor(
        public auth: AuthorizationService,
        private creativeRepository: CreativeRepository,
        public id: IdService,
        private launchDarklyService: LaunchDarklyService
    ) { }

    ngOnInit() {
        this.validationService = new ValidationService();
        this.addThirdPartyTag();
        this.helper.table = this.table;
        this.helper.paginator = this.paginator;
        combineLatest(this.table.query, this.paginator.query).subscribe(args => this.helper.search(args));

        this.launchDarklyService
            .getVariation(Flag.RolloutNativeCreativeTrafficking)
            .pipe(distinctUntilChanged())
            .subscribe(rolloutNativeCreativeTrafficking => {
                this.rolloutNativeCreativeTrafficking$.next(rolloutNativeCreativeTrafficking);
            });

        this.rolloutNativeCreativeTrafficking$.subscribe(value => {
            this.rolloutNativeCreativeTrafficking = value;
        });
    }

    sourceUrlBlur(tag) {
        let img = new Image();
        img.src = tag.mediaUrl;

        img.onload = () => {
            tag.height = img.height;
            tag.width = img.width;
        };
    }

    addThirdPartyTag(): void {
        this.tags.push(new Creative({
            name: '',
            type: 'image',
            mediaUrl: '',
            clickUrl: '',
            urlTracking1: null,
            thirdPartyTag: true,
            _secondary: false,
            isModified: true
        }));
    }

    remove(index): void {
        this.tags.splice(index, 1);
    }

    displayUploadError() {
        this.fileError = 'The file type you uploaded is not supported. ' +
            'Please upload a JPG, PNG, or GIF file.';
    }

    upload(files) {
        const component = this;

        const ads = [];
        for (let file of files) {
            const fileType = file.type;

            if (isOverMaxFileSize(file.file.size, fileType, !this.auth.canUseIncreasedCreativeFileSizes)) {
                return this.fileError = 'The uploaded file exceeds the maximum file size of '
                    + getMaxFileSizeByType(fileType, !this.auth.canUseIncreasedCreativeFileSizes) + 'kb. Please try uploading another file.';
            }

            load(file);
        }

        function load(file) {
            let image = new Image();
            image.src = file.dataURL;

            image.onload = function () {
                ads.push(new Creative({
                    type: 'image',
                    name: file.name,
                    mediaUrl: file.dataURL,
                    clickUrl: '',
                    width: image.width,
                    height: image.height,
                    thirdPartyTag: false,
                    isModified: true,
                    created: Date.now()
                }));

                if (ads.length === files.length) {
                    component.addToLineItem(ads);
                }
            };
        }
    }

    addToLineItem(advertisements?: any): void {

        let ads = advertisements || [];

        if (this.tabs.activeTab.header === 'Add Third Party Tags') {
            ads = this.tags;
        }
        if (this.tabs.activeTab.header === 'Use Existing Ads') {
            ads = this.table.selectedItems;
        }
        if (this.tabs.activeTab.header === 'Native Ads') {
            if (this.nativeAdForm) {
                ads = [this.nativeAdForm.creative.clone()];
            } else if (this.nativeExchangeAdForm) {
                ads = [this.nativeExchangeAdForm.creative.clone()];
            }
        }
        if (this.tabs.activeTab.header === 'Bulk Tag Uploader') {
            ads = this.bulkTagUploader.selectedCreatives;
        }

        if (ads.length > 0) {
            this.ads = this.ads.concat(ads);
            this.ads.forEach(ad => {
                ad.advertiser = this.campaign.advertiser;
            });
            this.adsChange.emit(this.ads);
        }

        this.files = null;
        this.state = 'closed';
        this.table.clearSelections();
    }

    open() {
        this.state = 'open';
        this.fileError = null;

        if (this.nativeAdForm) {
            this.nativeAdForm.reset();
        }

        if (this.nativeExchangeAdForm) {
            this.nativeExchangeAdForm.reset();
        }

        this.paginator.reset();
    }

    closeModal() {
        this.files = null;
        this.onClosed.emit();
        this.bulkTagUploader.reset();
        this.state = 'closed';
    }

    isValid() {
        if (this.tabs && this.tabs.activeTab && this.tabs.activeTab.header === 'Native Ads') {
            return this.areAllRequiredFieldsValid && this.isCreativeSizeValid;
        } else if (this.tabs && this.tabs.activeTab && this.tabs.activeTab.header === 'Bulk Tag Uploader') {
            return this.bulkTagUploader.selectedCreatives.length > 0;
        }

        return true;
    }

    private sortCreatives(params: SearchParams, creatives: Creative[]){
        // Sorting by string
        if (params.orderBy === 'name'){
            if (params.sort.toLowerCase() === 'asc'){
                return creatives.sort((a,b) => a[params.orderBy].localeCompare(b[params.orderBy]));
            }

            return creatives.sort((a,b) => b[params.orderBy].localeCompare(a[params.orderBy]));
        }

        if (params.sort.toLowerCase() === 'asc'){
            return creatives.sort((a,b) => (a[params.orderBy] > b[params.orderBy]) ? 1 : ((b[params.orderBy] > a[params.orderBy]) ? -1 : 0));
        }

        return creatives.sort((a,b) => (a[params.orderBy] < b[params.orderBy]) ? 1 : ((b[params.orderBy] < a[params.orderBy]) ? -1 : 0));
    }

    get areAllRequiredFieldsValid() {
        if (this.nativeAdForm) {
            return this.nativeAdForm.form.valid && !this.nativeAdForm.isBlueprintNotSelected;
        } else if (this.nativeExchangeAdForm) {
            return this.nativeExchangeAdForm.nativeForm.valid && this.nativeExchangeAdForm.hasAssets() && !this.nativeExchangeAdForm.hasAssetErrors();
        }
    }

    setIsCreativeSizeValid({ isValid }: CreativeSizeValidation): void {
        // The `id` field is used only in ad-details and can be ignored here.
        this.isCreativeSizeValid = isValid;
    }

    private adapter(params: SearchParams) {
        const conditions = [];
        if (this.ads.length > 0) {
            conditions.push({
                field: 'id',
                value: this.ads
                    .filter(ad => !!ad.id)
                    .map(ad => ad.id),
                operator: 'neq'
            });
        }

        if (this.campaign) {
            conditions.push({
                field: 'advertiser',
                value: this.campaign.advertiser,
                operator: 'eq'
            });

            if (this.campaign.demandType !== 'direct') {
                conditions.push({
                    field: 'type',
                    value: Creative.nativeTypes,
                    operator: 'neq'
                });
            }

            if (this.campaign.demandType !== 'exchange') {
                conditions.push({
                    field: 'isNativeLike',
                    value: ['0'],
                    operator: 'eq'
                });
            }
        }

        params.conditions.push(...conditions);

        if (this.campaign && this.campaign.demandType === 'exchange') {
            return this.creativeRepository.asyncSearch(params)
                .pipe(map(response => ({
                    items: response.items,
                    pages: response.pages,
                    total: response.total,
                    page: params.page
                })));
        }

        // Build native conditions off creative conditions w/ caveats
        const nativeParams = JSON.parse(JSON.stringify(params));

        if (this.campaign && this.campaign.demandType === 'direct') {
            nativeParams.conditions.push({
                field: 'blueprintId',
                value: null,
                operator: 'neq'
            });
        }

        nativeParams.conditions = nativeParams.conditions.filter(condition => !(condition.field == 'isNativeLike'));
        nativeParams.conditions.push({ field: 'externalId', value: null });

        if (params.conditions.length > 0 && nativeParams.conditions.length > 0) {
            if (this.rolloutNativeCreativeTrafficking === true && this.campaign.demandType !== 'exchange') {
                const nativeCreativeTraffickingParams = lodashDeepClone(nativeParams);
                nativeCreativeTraffickingParams.conditions = nativeCreativeTraffickingParams.conditions.filter(condition => condition.field !== 'blueprintId');
                nativeCreativeTraffickingParams.conditions.push({
                    field: 'isDirectSoldTrafficking',
                    value: true,
                    operator: 'eq'
                });

                return this.creativeRepository.searchAllCreatives(params, nativeParams, nativeCreativeTraffickingParams).pipe(map(creatives => {
                    const items = [];
                    const creativeTotal = creatives.length;
                    creatives = this.sortCreatives(params, creatives);

                    // Creating pages
                    while (creatives.length > 0)
                        items.push(creatives.splice(0, params.n));

                    return {
                        items: items.length > 0 ?  items[params.page-1] : items,
                        page: params.page,
                        pages: Math.ceil(creativeTotal/params.n),
                        total: creativeTotal
                    }
                }));
            } else {
                return forkJoin(
                    this.creativeRepository.asyncSearch(params),
                    this.creativeRepository.asyncSearch(nativeParams, '/search/creative/native')
                ).pipe(
                    map(([nonNative, native]) => {
                        return {
                            items: [...nonNative.items, ...native.items],
                            page: params.page,
                            pages: nonNative.pages + native.pages,
                            total: nonNative.total + native.total
                        };
                    })
                );
            }
        }

        return of({
            items: [],
            page: 1,
            pages: 1,
            total: 0
        })
    }

    handleOnShowSecondaryTracker(index: number, isExpanded: boolean) {
        if (isExpanded === false) {
            this.tags[index].urlTracking2 = null;
        }
    }

    getCreativeStatusColor(status: CreativeStatus): string {
        return creativeStatusColors[status.toLowerCase()];
    }
}
