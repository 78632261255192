import { Model } from "../model";
import { OperationResponse } from "./operation-response";

export enum Status {
    Pending = 'pending',
    Success = 'success',
    Failed = 'failed',
    Cancelled = 'cancelled',
    Completed = 'completed',
    CompletedWithFailures = 'completed (with failures)'
}

export class BulkOperationStatusResponse extends Model {
    public finished: boolean;
    public progress: number;
    public status: Status;
    public total_jobs: number;
    public pending_jobs: number;
    public failed_jobs: number;
    public processed_jobs: number;
    public created_at;
    public operations: OperationResponse[];
    public operation_batch_name: string;
    public completed_successfully: number;
    public completed_with_failures: number;

    get entity(): string {
        return 'Bulk Operation Status Response';
    }
}
