import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { Action, AuthorizationService, Entity, Role } from 'app/core/authorization.service';

@Injectable()
export class NativeGuard implements CanActivate {
    action = Action;
    role = Role;
    entity = Entity;

    constructor(
        private router: Router,
        private authorization: AuthorizationService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot) {
        if ((route.url[0].path === 'native' && this.hasNativeAccess()) ||
            (route.url[1].path === 'jurassicpark' && this.authorization.isAdmin)) {
            return of(true);
        }

        this.router.navigate(['/inventory-manager']);
        return of(false);
    }

    /**
     * MT-870 - Remove Beta Flag
     * Checking for the following:
     * Publisher Access/Manage/Demand
     * Media Group Access/Manage/Demand
     */
    hasNativeAccess() {
        return this.authorization.isPublisherAccess || this.authorization.canManagePublisher || this.authorization.isMediaGroupAccess || this.authorization.canManageMediaGroup || this.authorization.isAdOps;
    }

}
