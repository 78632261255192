import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
    transform(text: string, match: string) {
        return typeof match === 'string' && match.length < 1
            ? text : text.replace(new RegExp(escapeRegularExpression(match), 'ig'), '<span class="strong">$&</span>');
    }
}

function escapeRegularExpression(pattern: string) {
    return pattern.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
}
