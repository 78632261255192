import { AfterViewInit, Attribute, Component, ContentChildren, EventEmitter, Output, QueryList } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil, map, distinctUntilChanged } from 'rxjs/operators';
import { TabComponent } from './tab.component';
import { IdService } from 'app/core/id.service';
import { GAEventCategory, GAHelper } from 'app/shared/helpers/ga-helper';

@Component({
    selector: 'tabs',
    templateUrl: './tabs.html',
    styleUrls: ['./tabs.styl']
})
export class TabsComponent implements AfterViewInit {
    /**
     * A list of all tabs.
     */
    @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

    /**
     * Emit when a tab is selected.
     */
    @Output('tab-selected') onTabSelected = new EventEmitter<TabComponent>();

    /**
     * Emit a value of our choice upon click
     */
    @Output('clicked') clicked = new EventEmitter<any>();

    /**
     * Expose the currently active tab.
     */
    activeTab: TabComponent;

    /**
     * The key to use when saving/reading the active tab from the URL.
     */
    private routeKey?: string;

    /**
     * On destroy subject.
     */
    private onDestroy$ = new Subject<void>();

    /**
     * Create a new tabs component.
     */
    constructor(
        @Attribute('route-key') routeKey: string,
        private router: Router,
        private location: Location,
        private route: ActivatedRoute,
        public id: IdService
    ) {
        this.routeKey = routeKey;
    }

    /**
     * Initialize.
     */
    ngAfterViewInit() {
        this.route.queryParamMap.pipe(
            takeUntil(this.onDestroy$),
            map(map => map.get(this.routeKey)),
            distinctUntilChanged()
        ).subscribe(header => {
            const activeTab = this.tabs.toArray().find(tab => tab.header === header);

            // Activate the tab identified in the route if present
            if (this.routeKey && activeTab) {
                setTimeout(() => this.select(activeTab, true));
                return;
            }
        });

        if (!this.routeKey) {
            // fallback to the default active tab
            for (const t of this.tabs.toArray()) {
                if (t.active) {
                    this.select(t);
                    break;
                }
            }
        }
    }

    /**
     * Select a tab and make it active.
     */
    select(tab: TabComponent, replaceUrl = false) {
        if (!tab.empty) {
            this.activeTab = tab;
            this.tabs.forEach(_tab => _tab.active = tab === _tab);
            window.setTimeout(() => {
                this.onTabSelected.emit(tab);
            }, 0);

            if (this.routeKey) {
                GAHelper.trackEvent(GAEventCategory.TABS_INTERACTION, `Tab Selected : ${tab.header}, Query: ${this.routeKey}`, `Tab Navigation Page : ${this.router.url}`)
                this.pushToURL(tab, replaceUrl);
            }else{
                GAHelper.trackEvent(GAEventCategory.TABS_INTERACTION, `Tab Selected : ${tab.header}`, `Tab Navigation : Tab Selection`)
            }
            this.clicked.emit(this.activeTab.value);
        } else {
            this.clicked.emit();
        }
    }

    /**
     * Select a tab by its title.
     */
    selectTabByTitle(title: string) {
        for (const tab of this.tabs.toArray()) {
            if (tab.header === title) {
                this.select(tab);
                break;
            }
        }
    }

    /**
     * Push the currently selected tab to the URL.
     */
    private pushToURL(tab: TabComponent, replaceUrl = false) {
        this.router.navigate([], {
            queryParams: {
                [this.routeKey]: tab.header
            },
            queryParamsHandling: 'merge',
            replaceUrl
        })
    }
}
