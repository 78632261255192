import { RegexGroupOperator, RegexRuleOperator } from 'app/shared/models';
import { Filter, Operation } from 'app/shared/elements/filters';
var RegexBuilder = /** @class */ (function () {
    function RegexBuilder() {
    }
    /**
     * Generate a regex string from a `RegexRule` recursively handling nested rules.
     */
    RegexBuilder.prototype.generate = function (rule) {
        var _this = this;
        if (!rule || !rule.isValid()) {
            return '';
        }
        if (!Array.isArray(rule.rules)) {
            return this.generateFromRule(rule);
        }
        var operation = rule.operator === RegexGroupOperator.And ? this.and : this.or;
        return operation.apply(void 0, rule.rules
            .filter(function (r) { return r.isValid(); })
            .map(function (r) { return _this.generate(r); }));
    };
    /**
     * Generate a regex string from a `URLRule`.
     */
    RegexBuilder.prototype.generateFromRule = function (rule) {
        switch (rule.operator) {
            case RegexRuleOperator.Contains:
                return this.contains(rule.term);
            case RegexRuleOperator.DoesNotContain:
                return this.notContains(rule.term);
            case RegexRuleOperator.Equals:
                return this.equals(rule.term);
            default:
                throw new Error("Unsupported regex operation " + rule.operator + " for " + rule.term + ".");
        }
    };
    /**
     * Escape reserved characters for use in a regex.
     */
    RegexBuilder.prototype.escape = function (term) {
        return term.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    };
    /**
     * Generate a regex to check if a `term` is present in a string.
     */
    RegexBuilder.prototype.contains = function (term) {
        return "(?=.*" + term + ")";
    };
    /**
     * Generate a regex to check if a `term` is not present in a string.
     */
    RegexBuilder.prototype.notContains = function (term) {
        return "(?!.*" + term + ")";
    };
    /**
     * Generate a regex to check if a `term` is equals to string.
     */
    RegexBuilder.prototype.equals = function (term) {
        return "(^" + term + "$)";
    };
    /**
     * Generate a regex to check if all of the `terms` are present in a string.
     */
    RegexBuilder.prototype.and = function () {
        var terms = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            terms[_i] = arguments[_i];
        }
        return '^' + terms.map(function (term) { return "(?=" + term + ")"; }).join('') + '.*';
    };
    /**
     * Generate a regex to check if any of the `terms` are present in a string.
     */
    RegexBuilder.prototype.or = function () {
        var terms = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            terms[_i] = arguments[_i];
        }
        return '^' + terms.join('|') + '.*';
    };
    RegexBuilder.prototype.regexToFilter = function (rule, dimension) {
        var _this = this;
        return new Filter({
            operation: this.mapOperation(rule.operator),
            operations: [this.mapOperation(rule.operator)],
            query: rule.term,
            field: dimension,
            filters: Array.isArray(rule.rules) && rule.rules.length > 0
                ? rule.rules.map(function (r) { return _this.regexToFilter(r, dimension); })
                : undefined
        });
    };
    RegexBuilder.prototype.mapOperation = function (ruleOperation) {
        var _a;
        return (_a = {},
            _a[RegexGroupOperator.And] = Operation.And,
            _a[RegexGroupOperator.Or] = Operation.Or,
            _a[RegexRuleOperator.Contains] = Operation.Contains,
            _a[RegexRuleOperator.DoesNotContain] = Operation.DoesNotContain,
            _a[RegexRuleOperator.Equals] = Operation.Equals,
            _a)[ruleOperation];
    };
    return RegexBuilder;
}());
export { RegexBuilder };
