import { OnInit } from '@angular/core';
import { SensitiveCategoryRepository } from 'app/core/repositories/sensitive-category-repository.service';
import { SearchableSelectSingleComponent } from "app/shared/elements/searchable-select-single";
import { TagsInputComponent } from "app/shared/elements/tags-input";
var BulkEditSensitiveCategoriesComponent = /** @class */ (function () {
    function BulkEditSensitiveCategoriesComponent(label, multiple, sensitiveCategoryRepo) {
        this.sensitiveCategoryRepo = sensitiveCategoryRepo;
        this._entities = [];
        this._originals = [];
        this.entityName = '';
        this.sensitiveCategories = [];
        this.multiple = multiple !== null;
        this.label = label;
    }
    BulkEditSensitiveCategoriesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sensitiveCategoryRepo.all()
            .pipe(function (sensitiveCategories) { return _this.sensitiveCategoryRepo.convertCategoriesToOptions(sensitiveCategories); })
            .subscribe(function (sensitiveCategories) { return _this.sensitiveCategories = sensitiveCategories; });
    };
    BulkEditSensitiveCategoriesComponent.prototype.master = function (value) {
        var _this = this;
        this.entities.forEach(function (entity) { return entity[_this.field] = value; });
    };
    BulkEditSensitiveCategoriesComponent.prototype.original = function (index) {
        return this._originals[index];
    };
    Object.defineProperty(BulkEditSensitiveCategoriesComponent.prototype, "entities", {
        get: function () {
            return this._entities;
        },
        set: function (entities) {
            this._entities = entities;
            this._originals = entities.map(function (entity) { return entity.clone(); });
        },
        enumerable: true,
        configurable: true
    });
    BulkEditSensitiveCategoriesComponent.prototype.reset = function () {
        var _this = this;
        if (this.singleSelect !== undefined && !this.multiple) {
            this.singleSelect.resetDropDown();
        }
        if (this.multiSelect !== undefined && this.multiple) {
            this.multiSelect.removeAll();
        }
        this.entities.forEach(function (entity, index) { return entity[_this.field] = _this.original(index)[_this.field]; });
    };
    return BulkEditSensitiveCategoriesComponent;
}());
export { BulkEditSensitiveCategoriesComponent };
