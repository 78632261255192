import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ElementsModule } from 'app/shared/elements';

import { AccountIdInputComponent } from 'app/shared/components/account-id-input';
import { AdBuilderComponent } from 'app/shared/components/ad-builder';
import { AdBuilderNativeComponent } from 'app/shared/components/ad-builder/ad-builder-native';
import { AdBuilderNativeExchangeComponent } from 'app/shared/components/ad-builder/ad-builder-native-exchange';
import { AdBuilderNativePreviewComponent } from 'app/shared/components/ad-builder/ad-builder-native-preview';
import { AdDetailsComponent } from 'app/shared/components/ad-details';
import { AdDetailsLightboxComponent } from 'app/shared/components/ad-details-lightbox';
import { AdDetailsMacroComponent } from 'app/shared/components/ad-details/ad-details-macro';
import { AdSlotBulkEditComponent } from 'app/shared/components/ad-slot-bulk-edit';
import { AdSlotsTableComponent } from 'app/shared/components/ad-slots-table';
import { AdSlotCoordinatedLineItemsDialogComponent } from 'app/shared/components/ad-slot-coordinated-line-items-dialog';
import { AdvertiserModalComponent } from 'app/shared/components/advertiser-modal';
import { AdvertisersTableComponent } from 'app/shared/components/advertisers-table';
import { BlueprintAssetRestrictions } from './blueprint-asset-restrictions/blueprint-asset-restrictions.component';
import { BlueprintDetailsComponent } from 'app/shared/components/blueprint-details';
import { BulkEditAdSlotSizeComponent } from 'app/shared/components/bulk-edit-ad-slot-size';
import { BulkEditCheckboxComponent } from "app/shared/components/bulk-edit-checkbox";
import { BulkEditCreativeUrlsComponent } from 'app/shared/components/bulk-edit-creative-urls';
import { BulkEditDomainTargetingComponent } from 'app/shared/components/bulk-edit-domain-targeting';
import { BulkEditEspComponent } from "app/shared/components/bulk-edit-esp";
import { BulkEditIABCategoriesComponent } from 'app/shared/components/bulk-edit-iab-categories';
import { BulkEditLightboxComponent, BulkEditGroupComponent, BulkEditDirective } from 'app/shared/components/bulk-edit-lightbox';
import { BulkEditLineItemBudgetComponent } from 'app/shared/components/bulk-edit-line-item-budget';
import { BulkEditNewsletterUniqueAdsComponent } from 'app/shared/components/bulk-edit-newsletter-unique-ads';
import { BulkEditSelectDropdownComponent } from "app/shared/components/bulk-edit-select-dropdown";
import { BulkEditSensitiveCategoriesComponent } from 'app/shared/components/bulk-edit-sensitive-categories';
import { BulkEditTextComponent } from 'app/shared/components/bulk-edit-text';
import { BulkOperationStatusComponent } from 'app/shared/components/bulk-operation-status';
import { BulkOperationStatusModalComponent } from 'app/shared/components/bulk-operation-status-modal';
import { BulkTagUploaderComponent } from 'app/shared/components/ad-builder/bulk-tag-uploader';
import { CampaignPauseDialogComponent } from 'app/shared/components/campaign-pause-dialog';
import { CampaignsTableComponent } from 'app/shared/components/campaigns-table';
import { CreativeBulkEditComponent } from 'app/shared/components/creative-bulk-edit';
import { DeleteAdDialogComponent } from 'app/shared/components/delete-ad-dialog';
import { DeleteAdSlotDialogComponent } from 'app/shared/components/delete-ad-slot-dialog';
import { DeleteLineItemDialogComponent } from 'app/shared/components/delete-line-item-dialog';
import { DeleteMediaGroupDialogComponent } from 'app/shared/components/delete-media-group-dialog';
import { DeleteNewsletterDialogComponent } from 'app/shared/components/delete-newsletter-dialog';
import { DeletePublisherDialogComponent } from 'app/shared/components/delete-publisher-dialog';
import { DemandBlockingComponent } from 'app/shared/components/demand-blocking';
import { DirectivesModule } from 'app/shared/directives';
import { DomainTargetingComponent } from 'app/shared/components/domain-targeting';
import { DownloadTagsComponent } from 'app/shared/components/download-tags';
import { EmailServiceProviderCardComponent } from 'app/shared/components/email-service-provider-card';
import { FatalErrorComponent } from 'app/shared/components/fatal-error';
import { GeoTargetingComponent } from 'app/shared/components/geo-targeting';
import { GoalMetricCardComponent } from 'app/shared/components/goal-metric-card';
import { GoingLiveNotificationComponent } from 'app/shared/components/going-live-notification';
import { HistoryViewComponent } from 'app/shared/components/history-view';
import { InsertionOrdersTableComponent } from 'app/shared/components/insertion-orders-table';
import { InventoryTargetingComponent } from 'app/shared/components/inventory-targeting';
import { KeywordTargetingComponent } from 'app/shared/components/keyword-targeting';
import { KVPInputComponent } from 'app/shared/components/kvp-input';
import { LineItemBulkEditComponent } from 'app/shared/components/line-item-bulk-edit';
import { LinkBlueprintsComponent } from 'app/shared/components/link-blueprints';
import { LinkedBlueprintsComponent } from 'app/shared/components/linked-blueprints';
import { LinkedBlueprintsLightboxComponent } from 'app/shared/components/linked-blueprints-lightbox';
import { LineItemsTableComponent } from 'app/shared/components/line-items-table';
import { LinkedLineItemsLightboxComponent } from 'app/shared/components/linked-line-items-lightbox';
import { LocationTargetingComponent } from 'app/shared/components/location-targeting';
import { MappedToDetailsTableComponent } from './mapped-to-details-table/mapped-to-details-table.component';
import { MetricsComparisonCardComponent } from 'app/shared/components/metrics-comparison-card';
import { NewsletterTagsComponent } from 'app/shared/components/newsletter-tags';
import { NewsletterTagsTableComponent } from 'app/shared/components/newsletter-tags/newsletter-tags-table';
import { NewslettersTableComponent } from 'app/shared/components/newsletters-table';
import { NewsletterBulkEditComponent} from "app/shared/components/newsletter-bulk-edit";
import { PacingCardComponent } from 'app/shared/components/pacing-card';
import { PageNotFoundComponent } from 'app/shared/components/page-not-found';
import { PixelSharingComponent } from 'app/shared/components/pixel-sharing';
import { PlacementIdTargetingComponent } from 'app/shared/components/placement-id-targeting';
import { PublisherBulkEditComponent } from 'app/shared/components/publisher-bulk-edit';
import { PublisherFormComponent } from 'app/shared/components/publisher-form';
import { PublishersTableComponent } from 'app/shared/components/publishers-table';
import { RateLimitExceededComponent } from 'app/shared/components/rate-limit-exceeded';
import { RTBSettingsComponent } from 'app/shared/components/rtb-settings';
import { RegexTestLightboxComponent } from 'app/shared/components/regex-test-lightbox';
import { SelectAdvertisersLightboxComponent } from 'app/shared/components/select-advertisers-lightbox';
import { SelectPublishersLightboxComponent } from 'app/shared/components/select-publishers-lightbox';
import { ThirdPartyDemandSettingsComponent } from 'app/shared/components/third-party-demand-settings';
import { ZipTargetingComponent } from 'app/shared/components/zip-targeting';
import { PackageFormComponent, PackageManagerComponent, PackageDetailsComponent } from 'app/platform/inventory-manager/packages';

import {
    NewsletterFormComponent,
    NewsletterSingleComponent,
    NewsletterUploaderComponent
} from 'app/shared/components/newsletter-form';

import { BulkEditAdSlotDemandSettingComponent } from 'app/shared/components/bulk-edit-ad-slot-demand-setting';
import { BulkEditAdSlotExchangeFloorComponent } from 'app/shared/components/bulk-edit-ad-slot-exchange-floor';
import { ChooseAccountsComponent } from 'app/platform/choose-accounts';

import {
    LeftMasterBlueprintComponent ,
    RightMasterBlueprintComponent,
    TopMasterBlueprintComponent,
    TextMasterBlueprintComponent,
    LeftMasterBlueprintMobileComponent,
    RightMasterBlueprintMobileComponent,
    TopMasterBlueprintMobileComponent,
    TextMasterBlueprintMobileComponent,
    BlueprintClickDirective
} from 'app/shared/components/blueprints';
import { PipesModules } from 'app/shared/pipes';

const components = [
    AccountIdInputComponent,
    AdBuilderComponent,
    AdBuilderNativeComponent,
    AdBuilderNativeExchangeComponent,
    AdBuilderNativePreviewComponent,
    AdDetailsComponent,
    AdDetailsLightboxComponent,
    AdDetailsMacroComponent,
    AdSlotBulkEditComponent,
    AdSlotCoordinatedLineItemsDialogComponent,
    AdSlotsTableComponent,
    AdvertiserModalComponent,
    AdvertisersTableComponent,
    BlueprintAssetRestrictions,
    BlueprintDetailsComponent,
    BulkEditAdSlotDemandSettingComponent,
    BulkEditAdSlotExchangeFloorComponent,
    BulkEditAdSlotSizeComponent,
    BulkEditCheckboxComponent,
    BulkEditCreativeUrlsComponent,
    BulkEditDirective,
    BulkEditDomainTargetingComponent,
    BulkEditEspComponent,
    BulkEditGroupComponent,
    BulkEditIABCategoriesComponent,
    BulkEditLightboxComponent,
    BulkEditLineItemBudgetComponent,
    BulkEditNewsletterUniqueAdsComponent,
    BulkEditSelectDropdownComponent,
    BulkEditSensitiveCategoriesComponent,
    BulkEditTextComponent,
    BulkOperationStatusComponent,
    BulkOperationStatusModalComponent,
    BulkTagUploaderComponent,
    CampaignPauseDialogComponent,
    CampaignsTableComponent,
    ChooseAccountsComponent,
    CreativeBulkEditComponent,
    DeleteAdDialogComponent,
    DeleteAdDialogComponent,
    DeleteAdSlotDialogComponent,
    DeleteLineItemDialogComponent,
    DeleteMediaGroupDialogComponent,
    DeleteNewsletterDialogComponent,
    DeletePublisherDialogComponent,
    DemandBlockingComponent,
    DomainTargetingComponent,
    DownloadTagsComponent,
    EmailServiceProviderCardComponent,
    FatalErrorComponent,
    GeoTargetingComponent,
    GoalMetricCardComponent,
    GoingLiveNotificationComponent,
    HistoryViewComponent,
    InsertionOrdersTableComponent,
    InventoryTargetingComponent,
    KeywordTargetingComponent,
    KVPInputComponent,
    LeftMasterBlueprintComponent,
    LeftMasterBlueprintMobileComponent,
    LineItemBulkEditComponent,
    LineItemsTableComponent,
    LinkBlueprintsComponent,
    LinkedBlueprintsComponent,
    LinkedBlueprintsLightboxComponent,
    LinkedLineItemsLightboxComponent,
    LocationTargetingComponent,
    MappedToDetailsTableComponent,
    MetricsComparisonCardComponent,
    NewsletterFormComponent,
    NewsletterSingleComponent,
    NewsletterTagsComponent,
    NewsletterTagsTableComponent,
    NewsletterUploaderComponent,
    NewslettersTableComponent,
    NewsletterBulkEditComponent,
    PacingCardComponent,
    PackageFormComponent,
    PackageManagerComponent,
    PackageDetailsComponent,
    PageNotFoundComponent,
    PixelSharingComponent,
    PlacementIdTargetingComponent,
    PublisherBulkEditComponent,
    PublisherFormComponent,
    PublishersTableComponent,
    RightMasterBlueprintComponent,
    RightMasterBlueprintMobileComponent,
    RTBSettingsComponent,
    RateLimitExceededComponent,
    RegexTestLightboxComponent,
    SelectAdvertisersLightboxComponent,
    SelectPublishersLightboxComponent,
    ThirdPartyDemandSettingsComponent,
    TextMasterBlueprintComponent,
    TextMasterBlueprintMobileComponent,
    TopMasterBlueprintComponent,
    TopMasterBlueprintMobileComponent,
    ZipTargetingComponent,
    BlueprintClickDirective
];

@NgModule({
    imports: [
        DirectivesModule,
        ElementsModule,
        RouterModule,
        PipesModules
    ],
    declarations: [
        ...components,
        BulkEditEspComponent
    ],
    exports: [
        ...components
    ]
})
export class ComponentsModule { }
