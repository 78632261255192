import {
    Attribute,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, ReplaySubject, Subject, combineLatest, fromEvent } from 'rxjs';
import { map, startWith, take, takeUntil, tap } from 'rxjs/operators';

import { DropdownComponent } from 'app/shared/elements/dropdown';
import { dedupe } from 'app/core/array-utils';
import { IdService } from 'app/core/id.service';

export interface Suggestion {
    label: string;
    supporting?: string;
    value: string;
}

@Component({
    selector: 'tags-input-text',
    templateUrl: './tags-input-text.component.html',
    styleUrls: ['./tags-input-text.styl'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TagsInputTextComponent),
            multi: true
        }
    ]
})
export class TagsInputTextComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @ViewChild(DropdownComponent, { static: false }) dropdown: DropdownComponent;
    @Input() placeholder = '';
    @Input() header?: string;
    @Input() multiple = true;
    @Output() onblur = new EventEmitter<void>();

    @Input()
    set suggestions(suggestions: Suggestion[]) {
        this.suggestions$.next(suggestions);
    }

    hover: number;
    model = '';
    focused = false;
    onchange: (selected: string[]) => {} = null;
    selected$ = new BehaviorSubject<string[]>([]);
    suggestions$ = new BehaviorSubject<Suggestion[]>([]);
    searchableOptions$ = new ReplaySubject<Suggestion[]>(1);

    private input$ = new ReplaySubject<string>(1);
    private onDestroy$ = new Subject<void>();
    private splitOn: string[];

    constructor(
        public id: IdService,
        private cdr: ChangeDetectorRef,
        @Attribute('split-on') splitOn: string
    ) {
        const defaultSplitOn = ['\n', ','];
        this.splitOn = splitOn !== null ? splitOn.split(',') : defaultSplitOn;
    }

    ngOnInit() {
        combineLatest(
            this.suggestions$.pipe(startWith([])),
            this.selected$.pipe(startWith([])),
            this.input$.pipe(startWith('')),
        ).pipe(
            tap(() => this.cdr.markForCheck()),
            takeUntil(this.onDestroy$),
            map(([suggestions, selected, input]) => {
                if (!Array.isArray(suggestions)) {
                    return;
                }

                const pattern = new RegExp(input, 'ig');

                return suggestions
                    // exclude already selected items
                    .filter(option => selected.findIndex(s => s === option.value) < 0)
                    // filter based on user input
                    .filter(option => input.length < 1 || pattern.test(option.label));
            })
        ).subscribe(this.searchableOptions$);
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    writeValue(value: any) {
        this.reset();

        if (Array.isArray(value)) {
            this.selected$.next(value);
        }

        this.cdr.detectChanges();
    }

    registerOnChange(fn: (items: string[]) => {}) {
        this.onchange = fn;
    }

    registerOnTouched(_: () => {}) { }

    change(input: string) {
        if (!this.canSelect) {
            this.resetModel();
            return;
        }

        this.input$.next(input);

        const allJoinedAsString = this.selected$.getValue().join(this.splitOn[0]).concat(this.splitOn[0], input);

        // Split on any one of the `splitOn` values and turn the results
        // into selections. The last term is special, because that is
        // the one the user is still typing
        const splitters = this.splitOn.map(splitter => `${splitter}+`).join('|');
        const terms = allJoinedAsString.split(new RegExp(splitters));
        const lastTerm = terms.splice(-1, 1)[0];

        this.selected$.next(dedupe(terms).filter(n => n.trim() !== ''));

        this.model = lastTerm;
        this.input$.next(lastTerm);

        this.propagate();
        this.onblur.emit();
    }

    focus() {
        this.focused = true;
        this.hover = -1;
    }

    blur(event: FocusEvent, value: string) {
        this.focused = false;

        if (this.dropdown) {
            this.dropdown.hide();
        }

        (event.target as HTMLInputElement).value = null;
        this.push(value);

        this.onblur.emit();
    }

    mouseenter(index: number) {
        this.hover = index;
    }

    mousedown(event: MouseEvent) {
        // Allow for `select` to be called.
        event.preventDefault();
    }

    arrowup(event: KeyboardEvent) {
        event.preventDefault();

        this.hover--;

        if (this.hover < 0 && Array.isArray(this.suggestions$.getValue())) {
            this.hover = this.suggestions$.getValue().length - 1;
        }
    }

    trapInput(event: KeyboardEvent) {
        if (!this.canSelect) {
            event.preventDefault();
            return;
        }
    }

    get canSelect() {
        return this.multiple || this.selected$.getValue().length === 0;
    }

    arrowdown(event: KeyboardEvent) {
        event.preventDefault();

        this.hover++;

        if (Array.isArray(this.suggestions$.getValue()) && this.hover === this.suggestions$.getValue().length) {
            this.hover = 0;
        }
    }

    enter(event: Event) {
        // allow user to press enter in case we are splitting on newlines
        event.preventDefault();

        if (typeof this.hover === 'number' && this.hover > -1) {
            this.select(this.hover);
        }

        this.model = this.model ? this.model + '\n' : '';
        this.change(this.model);
    }

    select(index: number) {
        this.searchableOptions$.pipe(
            take(1),
            map(suggestions => suggestions.length > index ? suggestions[index].value : '')
        ).subscribe(option => this.push(option));

        this.hover = -1;
    }

    deselect(index: number) {
        this.selected$.getValue().splice(index, 1);
        this.selected$.next(this.selected$.getValue());

        this.resetModel();
        this.cdr.detectChanges();
        this.propagate();
        this.onblur.emit();
    }

    private propagate() {
        if (this.onchange !== null) {
            this.onchange(this.selected$.getValue());
        }
    }

    private push(value: string) {
        if (value !== '' && this.canSelect) {
            this.change(value + this.splitOn[0]);
        }
    }

    private reset() {
        this.selected$.next([]);
        this.resetModel();
    }

    private resetModel() {
        this.model = null;
    }
}
