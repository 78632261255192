import { Model } from './model';

const liveConnectTagTemplate = '' +
`<!-- LiveConnectTag for advertisers -->
  <script type="text/javascript" src="//b-code.liadm.com/{appId}.min.js" async="true" charset="utf-8"></script>
<!-- LiveConnectTag for advertisers -->`;

export class LiveConnectTag extends Model {
    name: string;
    advertiserId: number;
    publisherId: number;
    appId: string;
    accountId: number;

    get liveConnectTagJs(): string {
        return liveConnectTagTemplate.replace('{appId}', this.appId);
    }

    public serialize(): string {
        return JSON.stringify(this);
    }

    get entity() {
        return 'LiveConnect Tag';
    }
}
