import { Model } from './model';

export class Catalog extends Model {
    /**
     * Adveritser Ref Id
     */
    advertiserId: string;
    catalogFormat: string;
    catalogType: string;
    disabled: boolean;
    groupIdAsId: boolean;
    id: string;
    password: string;
    source: string;
    url: string;
    username: string;

    public serialize(): string {

        delete this._isClone;

        return JSON.stringify(this);
    }

    get entity() {
        return 'Catalog';
    }
}
