/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dropdown.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./dropdown.component";
var styles_DropdownComponent = [i0.styles];
var RenderType_DropdownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DropdownComponent, data: {} });
export { RenderType_DropdownComponent as RenderType_DropdownComponent };
function View_DropdownComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "dropdown--container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n    "]))], null, null); }
export function View_DropdownComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "dropdown"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shown; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_DropdownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dropdown", [], null, null, null, View_DropdownComponent_0, RenderType_DropdownComponent)), i1.ɵdid(1, 245760, null, 0, i3.DropdownComponent, [i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DropdownComponentNgFactory = i1.ɵccf("dropdown", i3.DropdownComponent, View_DropdownComponent_Host_0, { trigger: "trigger" }, { visibility: "visibility" }, ["*"]);
export { DropdownComponentNgFactory as DropdownComponentNgFactory };
