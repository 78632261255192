import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Publisher } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { map } from 'rxjs/operators';
var PublisherRepository = /** @class */ (function (_super) {
    tslib_1.__extends(PublisherRepository, _super);
    function PublisherRepository(http) {
        return _super.call(this, http, '/publisher', Publisher) || this;
    }
    PublisherRepository.prototype.linkDsps = function (id, params) {
        return this.http
            .post(this.url(this.path, 'link-dsps', id), params)
            .pipe(map(function (response) { return response['output']; }));
    };
    PublisherRepository.prototype.confirm = function (body) {
        var params = [this.path, 'confirm'];
        if (body.id) {
            params.push(body.id);
        }
        return this.http.post(this.url.apply(this, params), body);
    };
    PublisherRepository.prototype.inventoryUpload = function (params) {
        return this.http
            .post(this.url(this.path, 'inventory-upload', params.id), params)
            .pipe(map(function (response) { return response['output']; }));
    };
    PublisherRepository.prototype.getBlockableByAdvertiser = function (id) {
        var _this = this;
        return this.http.get(this.url('/search/publisher/blockable/advertiser', id))
            .pipe(map(function (response) { return response['output'].map(function (data) { return _this.build(data); }); }));
    };
    PublisherRepository.prototype.linkUserMatchPartners = function (id, body) {
        return this.http
            .post(this.url(this.path + '/link-user-match-partners', id), body)
            .pipe(map(function (response) { return response['output'].output; }));
    };
    /**
     * Trigger an export of the newsletter tags in the MS Excel format:
     *
     *     params: {
     *         id: refid|hash
     *     }
     */
    PublisherRepository.prototype.downloadTags = function (params) {
        var req = this.http.request('get', this.url.apply(this, [this.path, 'export-tags'].concat(params)), {
            headers: new HttpHeaders({ 'Accept': 'application/vnd.ms-excel' }),
            responseType: 'blob'
        });
        return req;
    };
    PublisherRepository.prototype.addToBlocklist = function (params) {
        return this.http.post(this.url(this.path, 'targeted-domains-append'), params);
    };
    PublisherRepository.prototype.removeFromBlocklist = function (params) {
        return this.http.post(this.url(this.path, 'targeted-domains-remove'), params);
    };
    return PublisherRepository;
}(BackendRepository));
export { PublisherRepository };
