import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { ReportingSlicer } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
var ReportingSlicerRepository = /** @class */ (function (_super) {
    tslib_1.__extends(ReportingSlicerRepository, _super);
    function ReportingSlicerRepository(http) {
        return _super.call(this, http, '/reporting/slicer', ReportingSlicer) || this;
    }
    ReportingSlicerRepository.prototype.querySlicer = function (slicerQuery) {
        return this.http.post(this.url(this.path), slicerQuery);
    };
    return ReportingSlicerRepository;
}(BackendRepository));
export { ReportingSlicerRepository };
