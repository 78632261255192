import * as tslib_1 from "tslib";
import { Model } from './model';
var BuyerSeat = /** @class */ (function (_super) {
    tslib_1.__extends(BuyerSeat, _super);
    function BuyerSeat() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BuyerSeat.prototype.serialize = function () {
        var buyerSeat = this.clone(BuyerSeat);
        if (typeof buyerSeat.description !== 'string' || buyerSeat.description.length === 0) {
            buyerSeat.description = null;
        }
        if (buyerSeat.adomainIfaasUrls) {
            buyerSeat.adomainIfaasUrls.forEach(function (adomainIfaas) {
                if (typeof adomainIfaas.ifaasUrlTwo !== 'string' || adomainIfaas.ifaasUrlTwo.length === 0) {
                    adomainIfaas.ifaasUrlTwo = null;
                }
                delete adomainIfaas._isClone;
            });
        }
        return JSON.stringify(buyerSeat);
    };
    Object.defineProperty(BuyerSeat.prototype, "entity", {
        get: function () {
            return 'Buyer Seat';
        },
        enumerable: true,
        configurable: true
    });
    return BuyerSeat;
}(Model));
export { BuyerSeat };
