import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Creative } from 'app/shared/models/creative';
import { switchMap } from 'rxjs/operators';
import { CreativeRepository } from '../repositories';
import { Observable, of } from 'rxjs';

@Injectable()
export class AdResolver implements Resolve<Creative> {
    constructor(
        private router: Router,
        private creativeRepository: CreativeRepository,
    ) { }

    resolve(route: ActivatedRouteSnapshot): Observable<Creative | null> {
        const adId = route.paramMap.get('id');
        const advertiserId = route.paramMap.get('advertiser');
    
        return this.creativeRepository.get(adId).pipe(
            switchMap(ad => {
                if (!ad || (ad.advertiserRefId.toString() !== advertiserId && !(route.url[2] && route.url[2].path === 'details'))) {
                    this.router.navigate(['/campaign-manager/ad-library']);
                    return of(null);
                }
                return of(ad);
            })
        );
    }
}
