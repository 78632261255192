import * as tslib_1 from "tslib";
import { Model } from './model';
export var RegexGroupOperator;
(function (RegexGroupOperator) {
    RegexGroupOperator["And"] = "and";
    RegexGroupOperator["Or"] = "or";
})(RegexGroupOperator || (RegexGroupOperator = {}));
export var RegexRuleOperator;
(function (RegexRuleOperator) {
    RegexRuleOperator["Contains"] = "contains";
    RegexRuleOperator["DoesNotContain"] = "doesNotContain";
    RegexRuleOperator["Equals"] = "Equals";
})(RegexRuleOperator || (RegexRuleOperator = {}));
var RegexRule = /** @class */ (function (_super) {
    tslib_1.__extends(RegexRule, _super);
    function RegexRule(from) {
        return _super.call(this, Object.assign({ operator: RegexRuleOperator.Contains }, from)) || this;
    }
    RegexRule.prototype.isValid = function () {
        if (Array.isArray(this.rules)) {
            return Object.values(RegexGroupOperator).includes(this.operator)
                && this.rules.length > 0
                && this.rules.every(function (rule) { return rule.isValid(); });
        }
        return Object.values(RegexRuleOperator).includes(this.operator)
            && typeof this.term === 'string' && this.term.length > 0;
    };
    RegexRule.prototype.serialize = function () {
        return null;
    };
    Object.defineProperty(RegexRule.prototype, "entity", {
        get: function () {
            return 'Regex Rule';
        },
        enumerable: true,
        configurable: true
    });
    return RegexRule;
}(Model));
export { RegexRule };
