import { Component, ContentChildren, QueryList, AfterContentChecked } from '@angular/core';
import { TaskComponent } from './task.component';

@Component({
    selector: 'task-progress',
    templateUrl: './task-progress.html',
    styleUrls: ['./task-progress.styl']
})
export class TaskProgressComponent implements AfterContentChecked {
    @ContentChildren(TaskComponent) tasks: QueryList<TaskComponent>;

    get complete(): number {
        let complete = 0;

        this.tasks.forEach((task) => {
            if (task.complete) {
                complete++;
            }
        });
        return this.tasks.length > 1 ? (complete - 1) / (this.tasks.length - 1) * 100 : 0;
    }

    ngAfterContentChecked() {
        let currentTask = false;
        this.tasks.forEach((task) => {
            if (!task.complete && !currentTask) {
                task._currentTask = currentTask = true;
            } else {
                task._currentTask = false;
            }
        });
    }
}
