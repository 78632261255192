import { Directive, ElementRef, Renderer2, HostListener, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { PercentPipe } from '@angular/common';

@Directive({
    selector: '[percent-input]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PercentageInputDirective),
            multi: true
        }
    ]
})
export class PercentageInputDirective implements OnInit {
    private static PERCENT_SYMBOL = '%';
    private percentPipe: PercentPipe = null;
    private value: number = null;

    constructor(private el: ElementRef, private renderer: Renderer2) {
        this.percentPipe = new PercentPipe('en-US');
        this.renderer.setStyle(this.el.nativeElement, 'text-align', 'right');
    }

    private propagate = null;
    private propagateTouched = null;

    writeValue(value) {
        if (value === null || value === undefined) {
            this.reset();
            return;
        }
        let input = this.el.nativeElement;
        this.value = value;
        input.value = this.transformInput((this.value / 100));
    }

    registerOnChange(fn) {
        this.propagate = fn;
    }

    registerOnTouched(fn) {
        this.propagateTouched = fn;
    }

    @HostListener('focus')
    activate() {
        let input = this.el.nativeElement;
        input.value = this.value;
        input.placeholder = '';
    }

    @HostListener('blur')
    deactivate() {
        let input = this.el.nativeElement;

        if (input.value.length > 0) {
            const numericValueAsString = input.value.replace(/[^0-9\.]+/g, '');
            if (numericValueAsString.length < 1) {
                this.reset();
            } else {
                input.value = this.formatAsPercentage(numericValueAsString);
            }
        } else {
            this.reset();
        }

        this.propagate(this.value);
        this.propagateTouched();
    }

    reset(): void {
        this.el.nativeElement.value = null;
        this.el.nativeElement.placeholder = PercentageInputDirective.PERCENT_SYMBOL;
        this.value = null;
    }

    formatAsPercentage(valueAsString: string): string {
        this.value = parseInt(valueAsString);
        return this.transformInput(this.value / 100);
    }

    ngOnInit() {
        this.el.nativeElement.placeholder = PercentageInputDirective.PERCENT_SYMBOL;
    }

    transformInput(value: number): string {
        return this.percentPipe.transform(value, '.0');
    }
}
