import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { MediaGroup } from 'app/shared/models';
import { MediaGroupRepository } from 'app/core/repositories';

@Injectable()
export class MediaGroupResolver implements Resolve<MediaGroup> {
    constructor(private mediaGroupRepository: MediaGroupRepository) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.mediaGroupRepository.get(route.params['id']);
    }
}
