import {
    Component,
    Input,
    ViewChild
} from '@angular/core';

import { NgForm } from '@angular/forms';

import {
    AdvertiserRepository,
    AgencyRepository,
    CategoryRepository,
    MediaGroupRepository,
    PublisherRepository,
    UserRepository
} from 'app/core/repositories';

import {
    Advertiser,
    Agency,
    MediaGroup,
    TargetingType
} from 'app/shared/models';

import { AdvertiserTier, OwnerType } from 'app/shared/models/advertiser';

import { AuthorizationService, NotificationsService } from 'app/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, combineLatest, forkJoin } from 'rxjs';
import { ModalComponent } from 'app/shared/elements/modal/modal.component';
import { ButtonComponent } from 'app/shared/elements/button';
import { OverlayComponent } from 'app/shared/elements/overlay';
import { ErrorHelper } from 'app/core/errors/error-helper';
import { ConfirmDialogComponent } from 'app/shared/elements/confirm-dialog';
import { Option } from 'app/shared/elements/dropdown/option.interface';
import { InventoryTargetingComponent } from '../inventory-targeting';
import { DomainTargetingComponent } from '../domain-targeting';
import { HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { IdService } from 'app/core/id.service';
import { removeCommonDelimiters } from "app/core/utils";

const TYPE_MEDIA_GROUP = 'Media Group';

@Component({
    selector: 'advertiser-modal',
    templateUrl: './advertiser-modal.html',
    styleUrls: ['./advertiser-modal.styl']
})
export class AdvertiserModalComponent {

    private _showAdvertiserLabel: boolean = false;
    private _showDomainBlocklist: boolean = false;
    private _showWhiteBlackList: boolean = false;
    private noteId: number;

    accountsOptions: Option[] = [];
    userOptions: any[] = [];
    inventory = [];
    categories: Observable<Option[]>;
    requiredErrorText: string = 'Oops! This is a required field.';
    isCreating: boolean = true;
    redirectUrl: string;
    advertiser: Advertiser;
    matchingAdvertisers: Advertiser[] = [];
    account: MediaGroup | Agency;
    owner: any;
    errorHelper = new ErrorHelper();
    cfmErrorHelper = new ErrorHelper();
    isDemandFeeRequired: boolean = false;

    @Input('header') headerText: string;
    @Input() redirect: string;

    @ViewChild('whiteBlackListComponent', { static: false })
    whiteBlackListComponent: InventoryTargetingComponent;

    @ViewChild('domainTargetingComponent', { static: true })
    domainTargeting: DomainTargetingComponent;

    @ViewChild('modal', { static: true })
    modal: ModalComponent;

    @ViewChild('saveBtn', { static: true })
    saveButton: ButtonComponent;

    @ViewChild('overlay', { static: true })
    overlay: OverlayComponent;

    @ViewChild(ConfirmDialogComponent, { static: true })
    confirmDialog: ConfirmDialogComponent;

    @ViewChild('advertiserForm', { static: true })
    advertiserForm: NgForm;

    advertiserTierOptions = [];

    removeCommonDelimiters = removeCommonDelimiters;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private advertiserRepository: AdvertiserRepository,
        private mediaGroupRepository: MediaGroupRepository,
        private agencyRepository: AgencyRepository,
        private publisherRepository: PublisherRepository,
        private notifications: NotificationsService,
        public authorization: AuthorizationService,
        private categoryRepository: CategoryRepository,
        private userRepository: UserRepository,
        public id: IdService
    ) {
        // Default accounts for selection of media group or publisher
        this.getAccounts();

        this.categories = this.categoryRepository.getPrimaryCategories()
            .pipe(categories => this.categoryRepository.convertCategoriesToOptions(categories));
    }

    ngOnInit() {
        this.activatedRoute.params.subscribe(params => {
            if (params.redirect) {
                this.redirectUrl = atob(params.redirect);
            } else {
                this.redirectUrl = this.redirect;
            }
        });
        this.advertiser = new Advertiser();
        this.advertiser.owner = {
            refId: null,
            name: null,
            type: null,
            id: null
        };

        this.advertiser.contract = {
            dspFee: null
        };

        this.activatedRoute.data.subscribe((resp) => {
            if (resp.advertiser) {
                this.advertiser = new Advertiser(resp.advertiser);
            }
        });
    }

    onAdvertiserNameChange (value) {
        const advertiserName = value ? value.trim() : ''
        if (advertiserName) {
            const query = {
                conditions: [
                    {
                        mode: 'and',
                        operator: 'eq',
                        field: 'name',
                        value: advertiserName
                    }
                ],
                orderBy: 'created',
                sort: 'DESC'
            };

            this.advertiserRepository.search(query).subscribe(advertisers => {
                this.matchingAdvertisers = advertisers
            })
        }else{
            this.matchingAdvertisers = []
        }
    }

    submit() {
        if (typeof this.noteId === 'number') {
            this.notifications.remove(this.noteId);
        }

        // Remove dspFee if null so that LSD returns clean responses
        if (this.advertiser.contract.dspFee == null) {
            delete this.advertiser.contract.dspFee;
        }

        this.overlay.show();
        this.saveButton.load();

        this.advertiserRepository.confirm(this.advertiser).subscribe(
            advertiser => this.saveAdvertiser(),
            (err: HttpErrorResponse) => {
                if (this.authorization.isInternalUser) {
                    this.cfmErrorHelper.loadBackEndErrors(err.error.errors);
                    this.confirmDialog.open();
                }
                else {
                    this.errorHelper.loadBackEndErrors(err.error.errors);
                    let errorList = this.errorHelper.errors;
                    this.displayErrorMessage(errorList);
                }
                this.overlay.hide();
                this.saveButton.reset();
            });
    }

    saveAdvertiser() {
        this.saveButton.load();
        this.overlay.show();

        this.advertiserRepository.save(this.advertiser).subscribe(
            advertiser => {
                this.saveButton.reset();
                this.overlay.hide();
                this.didSaveAdvertiser(advertiser);
            },
            (err: HttpErrorResponse) => {
                this.saveButton.reset();
                this.overlay.hide();
                this.handleSaveErrors(err.error.errors);
            }
        );
    }

    close() {
        this.reset();

        if (this.redirectUrl) {
            this.router.navigate([this.redirectUrl]);
        }
    }

    didSaveAdvertiser(advertiser) {
        const action = this.isCreating ? 'created' : 'updated';
        if (this.isCreating) {
            this.router.navigate(['/campaign-manager/advertisers', advertiser.refId]);
        } else if (this.redirectUrl) {
            this.router.navigate([this.redirectUrl]);
        } else {
            this.modal.close();
        }
        this.notifications.success(`You've successfully ${action} <strong>${advertiser.name}</strong>.`, `Woo hoo`);
        this.overlay.hide();
        this.saveButton.reset();
    }

    handleSaveErrors(errors) {
        this.overlay.hide();
        this.saveButton.reset();
        this.errorHelper.resetAllErrors();
        this.errorHelper.loadBackEndErrors(errors);
        let errorList = this.errorHelper.errors;
        this.displayErrorMessage(errorList);
    }

    createNewAdvertiser(account?) {
        this.isCreating = true;
        this.account = account;
        this.advertiser = new Advertiser();
        if (account) {
            this.advertiser.owner.id = account.id;
        }
        this.advertiser.advertiserTier = AdvertiserTier.Other;
        this.init();
        this.initAdvertiser();
        this.initAdvertiserTiers();
        this.modal.open();
    }

    editAdvertiser(advertiser) {
        this.isCreating = false;
        this.advertiser = advertiser;
        this.owner = advertiser.owner;
        this.account = this.owner;
        // If advertiser had a dspFee already, lock dspFee from being empty
        if (this.advertiser.contract && this.advertiser.contract.dspFee !== null) {
            this.isDemandFeeRequired = true;
        }
        this.prepareEdit();
        this.init();
        this.initAdvertiserTiers();
        this.modal.open();
    }

    init() {
        // Don't run a search for account admins and managers if logged in as an external user
        if (this.authorization.isInternalUser) {
            combineLatest(this.userRepository.getAdmins(), this.userRepository.getManagers())
                .pipe(map(([admins, managers]) => {
                    let users = admins.concat(managers);
                    this.userOptions = users.map(user => this.convertUserToOption(user));
                })).subscribe();
        }

        if (Array.isArray(this.advertiser.targetedPublishers) && this.advertiser.targetedPublishers.length > 0) {
            this.inventory = this.advertiser.targetedPublishers.map(publisher => {
                return {
                    id: publisher,
                    type: 'Publisher'
                };
            });
        }
    }

    private initAdvertiserTiers() {
        this.advertiserTierOptions = Object.entries(AdvertiserTier).map(([key, value]) => ({
            key: key,
            value: value,
            label: `${this.removeCommonDelimiters(key)} Account`
        }));
    }

    private initAdvertiser() {
        this.advertiser.category = null;
        this.advertiser.domain = '';
        this.advertiser.name = '';
        this.advertiser.owner = { 'type': TYPE_MEDIA_GROUP, 'id': '', 'refId': null, 'name': '' };
        this.advertiser.targetingType = 'include';
        this.advertiser.targetedPublishers = this.inventory;
        this.advertiser.targetedDomains = [];
        this.isDemandFeeRequired = true;
        this.advertiser.contract = {
            dspFee: 25
        };
        if (this.account) {
            this.advertiser.owner.id = this.account.id;
            this.advertiser.owner.type = this.account.entity as OwnerType;
        }
    }

    private prepareEdit() {
        if (typeof this.advertiser.externalId === 'string') {
            this.showAdvertiserLabel = true;
        }

        if (Array.isArray(this.advertiser.targetedDomains) && this.advertiser.targetedDomains.length > 0) {
            this.showDomainBlocklist = true;
        }

        if (Array.isArray(this.advertiser.targetedPublishers) && this.advertiser.targetedPublishers.length > 0) {
            this.showWhiteBlackList = true;
        }

        if (!this.advertiser.targetedPublishers) {
            this.advertiser.targetingType = 'include';
        }
    }

    get advertiserOwner() {
        return this.advertiser.owner.id;
    }

    set advertiserOwner(value) {
        if (value) {
            this.advertiser.owner.id = value;
            const account = this.accountsOptions.find(option => option.data.id === value);
            this.advertiser.owner.type = account.data.entity;
        }
    }

    set selectedCategories(value) {
        this.advertiser.categories = value;
    }

    get selectedCategories() {
        return this.advertiser.categories;
    }

    advertiserOwnerInputChange(searchTerm: string) {
        if (searchTerm.length < 1) {
            return;
        }

        const query = this.constructQuery(searchTerm);
        this.getAccounts(query);
    }

    private getAccounts(query?) {
        let hasAgencyAccess: boolean = this.authorization.isAgencyDemand || this.authorization.isAgencyManager;
        let hasMediaGroupAccess: boolean = this.authorization.isMediaGroupDemand || this.authorization.isMediaGroupManager;

        if (this.authorization.isInternalUser || (hasAgencyAccess && hasMediaGroupAccess)) {
            forkJoin(
                this.agencyRepository.search(query),
                this.publisherRepository.search(query),
                this.mediaGroupRepository.search(query)
            ).subscribe(([agencies, publishers, mediaGroups]) => {
                const agenciesOptions = agencies.map(this.convertAccountToOption);
                const mediaGroupOptions = mediaGroups.map(this.convertAccountToOption);
                const publisherOptions = publishers.map(this.convertAccountToOption);
                this.accountsOptions = [...agenciesOptions, ...mediaGroupOptions, ...publisherOptions];
            });
        } else {
            if (hasAgencyAccess) {
                this.agencyRepository.search(query).subscribe((agencies) => {
                    this.accountsOptions = agencies.map(this.convertAccountToOption);
                });
            } else {
                forkJoin(
                    this.publisherRepository.search(query),
                    this.mediaGroupRepository.search(query)
                ).subscribe(([publishers, mediaGroups]) => {
                    const mediaGroupOptions = mediaGroups.map(this.convertAccountToOption);
                    const publisherOptions = publishers.map(this.convertAccountToOption);
                    this.accountsOptions = [...mediaGroupOptions, ...publisherOptions];
                });
            }
        }
    }

    private convertCategoryToOption(category: any): Option {
        return {
            key: category.id,
            value: category.id,
            label: category.iab + ': ' + category.name
        };
    }

    private convertAccountToOption(account: any): Option {
        return {
            key: account.refId,
            value: account.id,
            label: '(ID: ' + account.refId + ') ' + account.name,
            data: account
        };
    }

    private convertUserToOption(user: any): Option {
        return {
            key: user.hashId,
            value: user.hashId,
            label: user.name
        };
    }

    get isAccountEditable(): boolean {
        // Editable only if creating new and has no account parameter
        return this.isCreating && !this.account;
    }

    get showAdvertiserLabel(): boolean {
        return this._showAdvertiserLabel;
    }

    set showAdvertiserLabel(value) {
        this._showAdvertiserLabel = value;
        if (!value) {
            this.advertiser.externalId = null;
        }
    }

    get showDomainBlocklist(): boolean {
        return this._showDomainBlocklist;
    }

    set showDomainBlocklist(value) {
        if (value) {
            this.advertiser.targetedDomains = this.advertiser.targetedDomains || [];
        }
        this._showDomainBlocklist = value;
    }

    get showWhiteBlackList(): boolean {
        return this._showWhiteBlackList;
    }

    set showWhiteBlackList(value) {
        this._showWhiteBlackList = value;
        if (!value) {
            this.advertiser.targetedPublishers = null;
        }
    }

    public inventoryChange(inventory: any[]) {
        this.advertiser.targetedPublishers = inventory.map(item => item.id);
    }

    public targetingChange(targeting: TargetingType) {
        this.advertiser.targetingType = targeting;
    }

    public reset() {
        this.showAdvertiserLabel = false;
        this.showDomainBlocklist = false;
        this.showWhiteBlackList = false;
        this.errorHelper.resetAllErrors();
        this.advertiserForm.form.reset();
    }

    private constructQuery(param: string) {
        let request = {
            conditions: [
                {
                    mode: 'or',
                    field: 'name',
                    value: param,
                    operator: 'like'
                }
            ]
        };

        if (/^\d+$/.test(param)) {
            request.conditions.push({
                mode: 'or',
                field: 'refId',
                value: param,
                operator: 'like'
            });
        }

        return request;
    }

    public displayErrorMessage(errorList): void {
        if (errorList.length === 1) {
            this.noteId = this.notifications.error('<strong>Uh Oh!</strong> The advertiser could not be saved because '
                + errorList.join(' ') + ' Please check the advertiser and try again.', '', 0);
        } else if (errorList.length > 1) {
            this.noteId = this.notifications.error('<strong>Uh Oh!</strong>  The advertiser could not be saved for'
                + ' the following reasons: <li>' + errorList.join('</li><li>') + '</li>', '', 0);
        }
    }
}
