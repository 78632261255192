import * as tslib_1 from "tslib";
import { Model } from './model';
var OperatingSystem = /** @class */ (function (_super) {
    tslib_1.__extends(OperatingSystem, _super);
    function OperatingSystem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OperatingSystem.prototype.serialize = function () {
        return JSON.stringify(this);
    };
    Object.defineProperty(OperatingSystem.prototype, "entity", {
        get: function () {
            return 'Operating System';
        },
        enumerable: true,
        configurable: true
    });
    return OperatingSystem;
}(Model));
export { OperatingSystem };
