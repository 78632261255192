import { Pipe, PipeTransform } from '@angular/core';
import { Blueprint } from 'app/shared/models';

@Pipe({name: 'blueprintDimensions'})

export class BlueprintDimensions implements PipeTransform {
    desktop: {
        isDynamic: boolean
        minHeight: number
        maxHeight: number
        width: number
    };

    mobile: {
        isDynamic: boolean
        minHeight: number
        maxHeight: number
        width: number
    };

    transform(blueprint: Blueprint, device: string) {
        this.desktop = {
            isDynamic: blueprint.isDesktopDynamic,
            minHeight: blueprint.minHeight,
            maxHeight: blueprint.maxHeight,
            width: blueprint.width
        };

        this.mobile = {
            isDynamic: blueprint.isMobileDynamic,
            minHeight: blueprint.mobileMinHeight,
            maxHeight: blueprint.mobileMaxHeight,
            width: blueprint.mobileWidth
        };

        return this.getDeviceDisplayText(device);
    }


    // Spits out
    // width x maxHeight || width x minHeight - maxHeight
    getDeviceDisplayText(device: string): string {
        return `${this[device].width / 2} x ${!this[device].isDynamic ? this[device].maxHeight / 2 : `${this[device].minHeight / 2} - ${this[device].maxHeight / 2}`}`;
    }
}
