import { ElementRef } from '@angular/core';
var IdAppendDirective = /** @class */ (function () {
    function IdAppendDirective(elementRef) {
        this.elementRef = elementRef;
        this.id = null;
    }
    Object.defineProperty(IdAppendDirective.prototype, "attrId", {
        get: function () {
            return this.getNearestId(this.elementRef.nativeElement.parentElement).concat(this.id).join('.');
        },
        enumerable: true,
        configurable: true
    });
    IdAppendDirective.prototype.getNearestId = function (element) {
        if (element.id) {
            return [element.id];
        }
        if (element.parentElement) {
            return this.getNearestId(element.parentElement);
        }
        return [];
    };
    return IdAppendDirective;
}());
export { IdAppendDirective };
