import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AudienceFile } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { NotificationsService } from 'app/core/notifications.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
var LAURepository = /** @class */ (function (_super) {
    tslib_1.__extends(LAURepository, _super);
    function LAURepository(http, notifications) {
        var _this = _super.call(this, http, '/lau', AudienceFile) || this;
        _this.notifications = notifications;
        return _this;
    }
    LAURepository.prototype.asyncSearch = function (params) {
        var _this = this;
        return this.http.get(this.url(this.path, 'status', this.stringifyParams(params)))
            .pipe(map(function (data) { return ({
            items: data['output'].map(function (item) { return _this.build(item); }),
            page: 1,
            pages: data['pages'],
            total: data['total']
        }); }));
    };
    LAURepository.prototype.stringifyParams = function (params) {
        var str = '?' + params.conditions.map(function (condition) { return condition.field + "=" + condition.value; }).join('&');
        params.n ? str += "&number=" + params.n : '';
        params.page ? str += "&page=" + params.page : '';
        params.orderBy ? str += "&orderBy=" + params.orderBy : '';
        params.sort ? str += "&sort=" + params.sort : '';
        return str;
    };
    LAURepository.prototype.getCredentials = function () {
        return this.http.get(this.url(this.path, 'credentials'));
    };
    LAURepository.prototype.doS3Action = function (action) {
        return this.getCredentials().toPromise().then(function (creds) {
            var s3 = new AWS.S3({
                accessKeyId: creds.accessKeyId,
                secretAccessKey: creds.secretAccessKey,
                sessionToken: creds.sessionToken,
                region: 'us-east-1'
            });
            var bucketName = creds.bucketName;
            if (bucketName === undefined) {
                bucketName = environment.lau.bucket.name;
            }
            return action(s3, bucketName);
        });
    };
    LAURepository.prototype.downloadFile = function (fileName, key) {
        var _this = this;
        var download = function (s3, bucketName) {
            var params = {
                Bucket: bucketName,
                Key: key + "/" + fileName,
                Expires: 600
            };
            var metaParams = {
                Bucket: bucketName,
                Key: key + "/" + fileName,
            };
            s3.headObject(metaParams, function (err) {
                if (err && err.code === 'NotFound') {
                    _this.notifications.error('Oops! The file you are trying to download cannot be found!');
                }
                else {
                    var url = s3.getSignedUrl('getObject', params);
                    var link = document.createElement('a');
                    link.href = url;
                    link.click();
                }
            });
        };
        return this.doS3Action(download);
    };
    return LAURepository;
}(BackendRepository));
export { LAURepository };
