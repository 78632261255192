import { Model } from './model';

export class Session extends Model {
    token: string;
    refreshToken: string;

    public serialize(): string {
        return JSON.stringify(this);
    }

    get entity() {
        return 'Session';
    }
}
