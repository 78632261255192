import * as tslib_1 from "tslib";
import { Model } from './model';
import { Url } from 'app/core/url';
import { KVP } from 'app/shared/components/kvp-input/KVP';
export var PublisherClientTypes;
(function (PublisherClientTypes) {
    PublisherClientTypes["Monetization"] = "monetization";
    PublisherClientTypes["Hiro"] = "hiro";
    PublisherClientTypes["Identity"] = "identity";
    PublisherClientTypes["EmailIntelligence"] = "email_intelligence";
})(PublisherClientTypes || (PublisherClientTypes = {}));
var Publisher = /** @class */ (function (_super) {
    tslib_1.__extends(Publisher, _super);
    function Publisher(publisher) {
        if (publisher === void 0) { publisher = {}; }
        var _this = _super.call(this) || this;
        Object.assign(_this, publisher, {
            esp: publisher.esp !== null ? publisher.esp : 'other',
            targetedDomains: publisher.targetedDomains || [],
            blocklistCategories: publisher.blocklistCategories || [],
            sensitiveCategories: publisher.sensitiveCategories || [],
            blocklists: publisher.blocklists || [],
            keyValues: publisher.keyValues ? _this.transformIncomingKvps(publisher.keyValues) : [],
            sspControl: publisher.sspControl || {}
        });
        return _this;
    }
    /**
     * Transform JSON kvp's into an array of KVP objects.
     * The frontend always limits one value per key,
     * so we can just pluck the first value.
     */
    Publisher.prototype.transformIncomingKvps = function (json) {
        if (Array.isArray(json)) {
            return json;
        }
        return Object.keys(json).map(function (key) { return new KVP(key, json[key][0]); });
    };
    /**
     * Get the protocol from the tags URL.
     */
    Publisher.prototype.getTagsProtocol = function () {
        return new Url(this.tagsUrlPrefix).protocol;
    };
    /**
     * Get the CNAME from the tags URL.
     */
    Publisher.prototype.getCname = function () {
        return new Url(this.tagsUrlPrefix).hostname;
    };
    Publisher.prototype.serialize = function () {
        var publisher = JSON.parse(JSON.stringify(this));
        delete publisher.mediaGroupObj;
        publisher.keyValues = publisher.keyValues.reduce(function (json, kvp) {
            // the api accepts an array of values, but on the
            // frontend we always limit one value per key
            json[kvp.key] = [kvp.value];
            return json;
        }, {});
        if (publisher.esp === 'other') {
            publisher.esp = null;
        }
        if (this.isEmptyArray(publisher.targetedDomains)) {
            publisher.targetedDomains = null;
            delete publisher.domainTargetingType;
        }
        if (publisher.externalId === '') {
            publisher.externalId = null;
        }
        if (!publisher.salesforceId) {
            publisher.salesforceId = null;
        }
        if (publisher.emailTagReplacement === '') {
            publisher.emailTagReplacement = null;
        }
        if (publisher.placementTagReplacement === '') {
            publisher.placementTagReplacement = null;
        }
        if (publisher.listTagReplacement === '') {
            publisher.listTagReplacement = null;
        }
        if (publisher.placementTagReplacement === '') {
            publisher.placementTagReplacement = null;
        }
        if (publisher.lceeKeyReplacement === '') {
            publisher.lceeKeyReplacement = null;
        }
        if (publisher.lceeTagReplacement === '') {
            publisher.lceeTagReplacement = null;
        }
        this.setDefaultsForLSD(publisher);
        return publisher;
    };
    Publisher.prototype.setDefaultsForLSD = function (publisher) {
        // LSD requires a description but doesn't use it.
        publisher.description = 'Default description, courtesy of Maverick.';
    };
    Publisher.prototype.isEmptyArray = function (prop) {
        return Array.isArray(prop) && prop.length === 0;
    };
    Object.defineProperty(Publisher.prototype, "entity", {
        get: function () {
            return 'Publisher';
        },
        enumerable: true,
        configurable: true
    });
    return Publisher;
}(Model));
export { Publisher };
