import * as tslib_1 from "tslib";
import { switchMap, map } from 'rxjs/operators';
import { BaseTableHelper } from './base-table-helper';
import { LSDQueryBuilder } from './lsd-query-builder';
var FrontendTableHelper = /** @class */ (function (_super) {
    tslib_1.__extends(FrontendTableHelper, _super);
    /**
     * Create a new table helper.
     */
    function FrontendTableHelper(adapter, queryBuilder) {
        if (queryBuilder === void 0) { queryBuilder = new LSDQueryBuilder(); }
        var _this = _super.call(this) || this;
        _this.adapter = adapter;
        _this.queryBuilder = queryBuilder;
        _this.query.pipe(switchMap(function (rawParams) { return _this.adapt(rawParams); }))
            .subscribe(_this.data);
        return _this;
    }
    /**
     * Adapt a query into table data.
     */
    FrontendTableHelper.prototype.adapt = function (rawParams) {
        var _this = this;
        var params = this.queryBuilder.build(rawParams);
        return this.adapter().pipe(map(function (all) { return ({
            items: _this.itemsAtCurrentPage(all, params),
            page: params.page,
            pages: _this.pages(all, params),
            total: _this.filter(all, params).length
        }); }));
    };
    /**
     * Fetch the sorted, filtered, and paginated items to display at the current page.
     */
    FrontendTableHelper.prototype.itemsAtCurrentPage = function (items, params) {
        return this.paginate(this.sort(this.filter(items, params), params), params);
    };
    /**
     * Paginate the results according to the supplied params.
     */
    FrontendTableHelper.prototype.paginate = function (items, params) {
        if (!params.n) {
            return items;
        }
        var offset = (params.page - 1) * params.n;
        return items.slice(offset, Math.min(offset + params.n, items.length));
    };
    /**
     * The total number of available pages.
     */
    FrontendTableHelper.prototype.pages = function (items, params) {
        return Math.ceil(items.length / params.n);
    };
    /**
     * Sorts an array of items in place by a given field name `params.orderBy` and direction `params.sort`.
     */
    FrontendTableHelper.prototype.sort = function (data, params) {
        var orderBy = params.orderBy;
        var direction = params.sort;
        var compare = function (a, b) {
            if (a === undefined || a === null) {
                return 1;
            }
            if (b === undefined || b === null) {
                return -1;
            }
            if (typeof a === 'string') {
                a = a.toLowerCase();
                b = b.toLowerCase();
            }
            if (a > b) {
                return -1;
            }
            if (b > a) {
                return 1;
            }
            if (a === b) {
                return 0;
            }
        };
        data.sort(function (a, b) { return compare(a[orderBy], b[orderBy]); });
        if (direction.toUpperCase() === 'ASC') {
            data.reverse();
        }
        return data;
    };
    /**
     * Filters an array of items.
     * This is meant to be used for async-tables that filter client side.
     */
    FrontendTableHelper.prototype.filter = function (items, params) {
        var _this = this;
        if (!Array.isArray(params.conditions) || params.conditions.length < 1) {
            return items;
        }
        return items.filter(function (item) { return _this.testAgainstConditions(item, params.conditions); });
    };
    /**
     * Test whether an item meets a set of conditions.
     */
    FrontendTableHelper.prototype.testAgainstConditions = function (item, conditions) {
        var _this = this;
        if (!Array.isArray(conditions) || conditions.length < 1) {
            return true;
        }
        // for simplicity, let's assume the conditions in a group all share the same
        // operator until we have to support anything further
        if (conditions[0].mode === 'or') {
            return conditions.some(function (condition) { return _this.testAgainstCondition(item, condition); });
        }
        else {
            return conditions.every(function (condition) { return _this.testAgainstCondition(item, condition); });
        }
    };
    /**
     * Test whether an item meet a of condition.
     */
    FrontendTableHelper.prototype.testAgainstCondition = function (item, condition) {
        if (Array.isArray(condition.conditions)) {
            return this.testAgainstConditions(item, condition.conditions);
        }
        if (condition.operator === 'like') {
            return (new RegExp(condition.value, 'i')).test(item[condition.field]);
        }
        return condition.value === item[condition.field];
    };
    return FrontendTableHelper;
}(BaseTableHelper));
export { FrontendTableHelper };
