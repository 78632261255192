import { Option } from 'app/shared/elements/dropdown';

export class InventoryTargetingOption implements Option {
    constructor (public data: any) {}

    get key () {
        return this.data.refId;
    }

    get value () {
        return this.data.id;
    }

    get label () {
        if (this.data.label) {
            return this.data.label;
        }
        return [this.data.refId + ':', this.data.name].join(' ');
    }

    get type () {
        return this.data.type;
    }
}
