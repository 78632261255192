import { Model } from './model';

export class Package extends Model {
    name: string;
    demandType: string;
    createdBy: string;
    publishers: Publisher[];
    newsletters: Newsletter[];
    adSlots: AdSlot[];

    serialize(): string {
        return JSON.stringify(this);
    }

    get entity() {
        return 'Package';
    }
}

export interface Publisher {
    name: string,
    publisherId: number
}

export interface Newsletter {
    name: string,
    newsletterId: number
}

export interface AdSlot {
    name: string,
    adSlotId: number
}
