import { ReplaySubject, combineLatest } from 'rxjs';
import { mergeMap, shareReplay, map } from 'rxjs/operators';
import { Logger } from 'app/services/logger';
import { Store } from 'app/services/store.service';
import { AdvertiserRepository } from 'app/core/repositories/advertiser-repository.service';
import { MediaGroupRepository } from 'app/core/repositories';
import * as i0 from "@angular/core";
import * as i1 from "./logger";
import * as i2 from "./store.service";
import * as i3 from "../core/repositories/advertiser-repository.service";
import * as i4 from "../core/repositories/media-group-repository.service";
var AccountsPreferences = 'preferences:accounts';
var Preferences = /** @class */ (function () {
    function Preferences(logger, store, advertisers, mediaGroups) {
        var _this = this;
        this.logger = logger;
        this.store = store;
        this.advertisers = advertisers;
        this.mediaGroups = mediaGroups;
        this.accountIds$ = new ReplaySubject(1);
        this.advertisers$ = this.accountIds$.pipe(mergeMap(function (accountIds) { return _this.advertisers.searchByIds(accountIds); }), shareReplay(1));
        this.mediaGroups$ = this.accountIds$.pipe(mergeMap(function (accountIds) { return _this.mediaGroups.searchByIds(accountIds); }), shareReplay(1));
        this.accounts$ = combineLatest(this.advertisers$, this.mediaGroups$).pipe(map(function (_a) {
            var advertisers = _a[0], mediaGroups = _a[1];
            return advertisers.concat(mediaGroups);
        }));
        // Initialize the `accountIds` subject.
        var accountIds = this.store.get(AccountsPreferences) || [];
        this.accountIds$.next(accountIds);
        this.accountIds$.subscribe(function (accountIds) { return _this.store.set(AccountsPreferences, accountIds); });
    }
    Preferences.prototype.setAccountIds = function (accountIds) {
        this.accountIds$.next(accountIds);
        this.logger.notice("Updated account preferences (" + accountIds.length + " accounts)");
    };
    Preferences.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Preferences_Factory() { return new Preferences(i0.ɵɵinject(i1.Logger), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.AdvertiserRepository), i0.ɵɵinject(i4.MediaGroupRepository)); }, token: Preferences, providedIn: "root" });
    return Preferences;
}());
export { Preferences };
