import * as tslib_1 from "tslib";
import { Model } from './model';
var Metro = /** @class */ (function (_super) {
    tslib_1.__extends(Metro, _super);
    function Metro() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Metro.prototype.serialize = function () {
        return JSON.stringify(this);
    };
    Object.defineProperty(Metro.prototype, "entity", {
        get: function () {
            return 'Metro';
        },
        enumerable: true,
        configurable: true
    });
    return Metro;
}(Model));
export { Metro };
