import { ButtonComponent } from '../button';

import {
    Component, Input, OnInit, Output, EventEmitter
} from '@angular/core';
import { trigger, state, transition, animate, style } from '@angular/animations';

@Component({
    selector: 'dialog-box',
    templateUrl: './dialog-box.html',
    styleUrls: ['./dialog-box.styl'],
    animations: [
        trigger('backdrop', [
            state('closed', style({ display: 'none', opacity: '.5' })),
            state('open', style({ display: 'block', opacity: '.7' })),
            transition('closed => open', animate('200ms ease-in')),
            transition('open => closed', animate('150ms 50ms ease-in')),
        ]),
        trigger('dialogBox', [
            state('closed', style({ transform: 'scale(.5)', display: 'none', opacity: '0'})),
            state('open', style({ transform: 'scale(1)', opacity: '1'})),
            transition('closed => open', animate('150ms 50ms ease-in')),
            transition('open => closed', animate('200ms ease-in')),
        ])
    ]
})

export class DialogboxComponent implements OnInit {
    @Input() header: string;
    @Input() size: string;
    @Input() trigger: Element;
    @Input() state: string = 'closed';
    @Output() closedState = new EventEmitter<string>();

    ngOnInit () {
        let trigger: any = this.trigger;

        if (trigger instanceof ButtonComponent) {
            trigger = trigger.el.nativeElement;
        }

        if (trigger !== undefined) {
            trigger.addEventListener('click', _ => this.open());
        }

        if (this.state === 'auto') {
            this.state = 'closed';
            window.setTimeout(() => this.state = 'open', 0);
        }
    }

    open () {
        this.state = 'open';
    }

    close () {
        this.state = 'closed';
        this.closedState.emit('closed');
    }
}
