import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { Status } from 'app/shared/models';
import moment from 'moment';

@Component({
    selector: 'status-card',
    templateUrl: './status-card.html',
    styleUrls: ['./status-card.styl']
})
export class StatusCardComponent implements OnInit {
    @ContentChild('template', { static: true }) template: TemplateRef<any>;
    @Input() status: Status = Status.INACTIVE;
    @Input() startDate: string;
    @Input() endDate: string;
    @Input() compareDate: string;

    private styleGood: string = 'status-card--good';
    private styleBad: string = 'status-card--bad';
    private styleWarning: string = 'status-card--warning';
    private styleInactive: string = 'status-card--inactive';

    private _startDate: number;
    private _compareDate: number;
    private _compareDateMoment: any;
    private _endDateMoment: any;

    ngOnInit() {
        this._startDate = this.convertStringTimeToMilliseconds(this.startDate);
        this._compareDate = this.convertStringTimeToMilliseconds(this.compareDate);
        this._compareDateMoment = moment(this.compareDate).startOf('day');
        this._endDateMoment = moment(this.endDate);
    }

    convertStringTimeToMilliseconds(dateTypeString: string): number {
        const dateTypeDate = new Date(dateTypeString);
        return dateTypeDate.getTime();
    }

    get styleClass(): string {
        if (!this.status) {
            return this.styleInactive;
        }
        switch (this.status.toUpperCase()) {
            case Status.ACTIVE.toUpperCase(): return this.styleGood;
            case Status.READY.toUpperCase(): return this.styleGood;
            case Status.NOT_READY.toUpperCase(): return this.styleBad;
            case Status.DELIVERING.toUpperCase(): return this.styleGood;
            case Status.NOT_DELIVERING.toUpperCase(): return this.styleBad;
            case Status.PAUSED.toUpperCase(): return this.styleWarning;
            case Status.PAUSED_BLACKOUT.toUpperCase(): return this.styleWarning;
            case Status.COMPLETED.toUpperCase(): return this.styleGood;
            case Status.INACTIVE.toUpperCase(): return this.styleInactive;
            case Status.NEEDS_ACTIVATION.toUpperCase(): return this.styleWarning;
            case Status.WARNING.toUpperCase(): return this.styleWarning;
            case Status.READY_LIMITED.toUpperCase(): return this.styleGood;
            case Status.INELIGIBLE.toUpperCase(): return this.styleBad;
            case Status.EXPIRING_SOON.toUpperCase(): return this.styleWarning;
            case Status.REJECTED.toUpperCase(): return this.styleBad;
            default: return this.styleInactive;
        }
    }

    get willStart(): boolean {
        return this._compareDate < this._startDate;
    }

    get hasEnded(): boolean {
        return this._compareDateMoment.isAfter(this._endDateMoment);
    }

    get dateToUse(): string {
        if (this.willStart) {
            return this.startDate;
        }
        return this.endDate;
    }

    get statusText(): string {
        if (this.hasEnded) {
            return 'Ended';
        }
        return this.willStart ? 'Starts' : 'Ends';
    }
}
