import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Buyer } from 'app/shared/models';
import { BackendRepository } from './backend-repository';

@Injectable()
export class BuyerRepository extends BackendRepository<Buyer> {
    public constructor(http: HttpClient) {
        super(http, '/buyer', Buyer);
    }
}
