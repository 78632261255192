import * as tslib_1 from "tslib";
import { Model } from './model';
var CookieSyncPartner = /** @class */ (function (_super) {
    tslib_1.__extends(CookieSyncPartner, _super);
    function CookieSyncPartner() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CookieSyncPartner.prototype.serialize = function () {
        return JSON.stringify(this);
    };
    Object.defineProperty(CookieSyncPartner.prototype, "entity", {
        get: function () {
            return 'Cookie Sync Partner';
        },
        enumerable: true,
        configurable: true
    });
    return CookieSyncPartner;
}(Model));
export { CookieSyncPartner };
