import moment from 'moment';
import { Model } from './model';

export class InsertionOrder extends Model {
    isUnlimitedBudget: boolean;
    advertiser: string;
    executive: string;
    admin: string;
    trafficker: string;
    externalId: string;
    referenceNumber: string;
    name: string;
    spend: number;
    budget: number;
    budgetAllocated: number;
    adServer: string;
    isLiBilling: boolean;
    modified: string;
    startDate: string;
    endDate: string;
    isSelfService: boolean;

    constructor(from?: any) {
        super(from);

        if (typeof this.isUnlimitedBudget !== 'boolean') {
            this.isUnlimitedBudget = false;
        }
    }

    serialize(): string {
        const insertionOrder = this.clone(InsertionOrder);

        if (insertionOrder.admin && insertionOrder.admin['hash']) {
            insertionOrder.admin = insertionOrder.admin['hash'];
        }

        if (insertionOrder.executive && insertionOrder.executive['hash']) {
            insertionOrder.executive = insertionOrder.executive['hash'];
        }

        if (insertionOrder.trafficker && insertionOrder.trafficker['hash']) {
            insertionOrder.trafficker = insertionOrder.trafficker['hash'];
        }

        insertionOrder.startDate = moment(insertionOrder.startDate).format('YYYY-MM-DD') + ' 00:00:00';
        insertionOrder.endDate = moment(insertionOrder.endDate).format('YYYY-MM-DD') + ' 23:59:59';

        return JSON.stringify(insertionOrder);
    }

    get entity() {
        return 'Insertion Order';
    }
}
