import { forkJoin, of } from "rxjs";
export var getManagersExecutivesForAdvertisers = function (advertisers, userRepo) {
    var managerIds = advertisers.map(function (advertiser) { return advertiser.admin; });
    var executiveIds = advertisers.map(function (advertiser) { return advertiser.executiveId; });
    var userIds = managerIds.concat(executiveIds).filter(function (userId) { return userId; });
    // Get rid of duplicates
    userIds = Array.from(new Set(userIds));
    if (userIds && userIds.length) {
        return forkJoin([of(advertisers), userRepo.search({
                conditions: [{ field: 'hash_id', value: userIds }]
            })]);
    }
    return forkJoin([of(advertisers), of(new Array())]);
};
export var decorateAdvertisersWithNames = function (_a) {
    var advertisers = _a[0], users = _a[1];
    var idToName = new Map();
    users.forEach(function (user) { return idToName.set(user.hashId, [user.firstName, user.lastName]); });
    advertisers.forEach(function (advertiser) {
        if (idToName.has(advertiser.admin)) {
            advertiser.managerFirstName = idToName.get(advertiser.admin)[0];
            advertiser.managerLastName = idToName.get(advertiser.admin)[1];
        }
        if (idToName.has(advertiser.executiveId)) {
            advertiser.executiveFirstName = idToName.get(advertiser.executiveId)[0];
            advertiser.executiveLastName = idToName.get(advertiser.executiveId)[1];
        }
    });
    return of(advertisers);
};
export var getManagersExecutivesForTableData = function (data, userRepo) {
    var managerIds = data.items.map(function (advertiser) { return advertiser.admin; });
    var executiveIds = data.items.map(function (advertiser) { return advertiser.executiveId; });
    var userIds = managerIds.concat(executiveIds).filter(function (userId) { return userId; });
    // Get rid of duplicates
    userIds = Array.from(new Set(userIds));
    if (userIds && userIds.length) {
        return forkJoin([of(data), userRepo.search({
                conditions: [{ field: 'hash_id', value: userIds }]
            })]);
    }
    return forkJoin([of(data), of(new Array())]);
};
export var decorateTableDataWithNames = function (_a) {
    var data = _a[0], users = _a[1];
    var idToName = new Map();
    users.forEach(function (user) { return idToName.set(user.hashId, [user.firstName, user.lastName]); });
    data.items.forEach(function (advertiser) {
        if (idToName.has(advertiser.admin)) {
            advertiser.managerFirstName = idToName.get(advertiser.admin)[0];
            advertiser.managerLastName = idToName.get(advertiser.admin)[1];
        }
        if (idToName.has(advertiser.executiveId)) {
            advertiser.executiveFirstName = idToName.get(advertiser.executiveId)[0];
            advertiser.executiveLastName = idToName.get(advertiser.executiveId)[1];
        }
    });
    return of(data);
};
export var isDomainOnAllPublishers = function (domain, publishers) {
    return publishers.every(function (publisher) {
        return publisher.targetedDomains.includes(domain);
    });
};
