import { ChangeDetectorRef, ChangeDetectionStrategy, Input, Component } from '@angular/core';

@Component({
    selector: 'overlay',
    templateUrl: './overlay.html',
    styleUrls: ['./overlay.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverlayComponent {
    @Input()
    visible: boolean = false;

    constructor(
        private cdr: ChangeDetectorRef
    ) {}

    show() {
        this.visible = true;
        this.cdr.markForCheck();
    }

    hide() {
        this.visible = false;
        this.cdr.markForCheck();
    }
}
