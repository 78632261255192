import * as tslib_1 from "tslib";
import moment from 'moment';
import { Model } from './model';
import { Status } from './status';
import targetingFields from './line-item-targeting-fields.json';
export var LineItemPacing;
(function (LineItemPacing) {
    LineItemPacing["Asap"] = "asap";
    LineItemPacing["Even"] = "even";
    LineItemPacing["Unlimited"] = "unlimited";
})(LineItemPacing || (LineItemPacing = {}));
var LineItem = /** @class */ (function (_super) {
    tslib_1.__extends(LineItem, _super);
    function LineItem(from) {
        var _this = _super.call(this, from) || this;
        _this.startTime = _this.getTime(_this.startDate || _this.effectiveStartDate);
        _this.endTime = _this.getTime(_this.endDate || _this.effectiveEndDate);
        _this.budgetIncrement = _this.dailyBudget !== null && _this.dailyBudget !== undefined ? 'daily' : 'lifetime';
        return _this;
    }
    /*
      Clear out NON LSD fields
      - remove non-lsd objects
      - remove empty arrays and make them into nulls
    */
    LineItem.prototype.serialize = function () {
        var lineItem = this.clone(LineItem);
        if (lineItem.creativeMapping) {
            lineItem.creativeMapping = lineItem.creativeMapping.map(function (_a) {
                var creative = _a.creative, adSlots = _a.adSlots;
                return ({
                    creative: { id: creative.id },
                    adSlots: adSlots.map(function (_a) {
                        var id = _a.id, mappedBlueprints = _a.mappedBlueprints;
                        if (!Array.isArray(mappedBlueprints)) {
                            return { id: id };
                        }
                        // Needs to normalize mappedBlueprints for the line item form to remove all fields except for id
                        var sanitizedMappedBlueprints = mappedBlueprints.map(function (item) {
                            return (typeof item === 'object' && item !== null) ? item.id : item;
                        });
                        return {
                            id: id,
                            mappedBlueprints: sanitizedMappedBlueprints
                        };
                    })
                });
            });
        }
        if (lineItem.campaignObj && lineItem.campaignObj.demandType === 'exchange') {
            delete lineItem.keyValuesOperator;
        }
        if (lineItem.keyValues === null) {
            delete lineItem.keyValues;
            delete lineItem.keyValuesTargetingType;
        }
        if (lineItem.pacing === undefined) {
            lineItem.pacing = 'even';
        }
        if (lineItem.externalId !== null && lineItem.externalId !== undefined) {
            if (lineItem.externalId.length < 1) {
                lineItem.externalId = null;
            }
        }
        if (typeof lineItem.placementId === 'string' && lineItem.placementId.trim().length < 1) {
            lineItem.placementId = null;
        }
        if (typeof lineItem.dailyCap !== 'number') {
            lineItem.dailyCap = 0.0;
        }
        if (typeof lineItem.budget !== 'number') {
            lineItem.budget = parseFloat(lineItem.budget);
        }
        lineItem.endDate = moment(lineItem.endDate).endOf('minute').format('YYYY-MM-DD HH:mm:ss');
        delete lineItem.budgetIncrement;
        delete lineItem.startTime;
        delete lineItem.endTime;
        if (lineItem.isNew()) {
            lineItem = this.sanitizeForCreate(lineItem);
        }
        else {
            lineItem = this.sanitizeForEdit(lineItem);
        }
        if (lineItem.audienceTargeting === '') {
            lineItem.audienceTargeting = null;
        }
        if (lineItem.blackoutPeriods && Array.isArray(lineItem.blackoutPeriods)) {
            lineItem.blackoutPeriods = lineItem.blackoutPeriods.filter(function (period) { return period.endDate !== ''; });
        }
        delete lineItem.ads;
        delete lineItem.sections;
        delete lineItem.campaignObj;
        delete lineItem.advertiserObj;
        delete lineItem.inheritFlightDates;
        delete lineItem.insertionOrderObj;
        delete lineItem.userHasNCTAccess;
        delete lineItem.eligibleAdSlots;
        delete lineItem.eligibleCreatives;
        if (lineItem.pacing !== LineItemPacing.Asap) {
            lineItem.blackoutPeriods = [];
        }
        lineItem = lineItem.removeUnsetProperties(lineItem);
        return JSON.stringify(lineItem);
    };
    Object.defineProperty(LineItem.prototype, "isActive", {
        get: function () {
            return this.status === 'active';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LineItem.prototype, "hasImpressionsBudget", {
        get: function () {
            return this.budgetType === 'impressions';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LineItem.prototype, "hasAdjustedImpressionsBudget", {
        get: function () {
            return this.budgetType === 'adjusted_impressions';
        },
        enumerable: true,
        configurable: true
    });
    LineItem.prototype.getStartDate = function () {
        return moment(this.startDate).toDate();
    };
    LineItem.prototype.getEndDate = function () {
        return moment(this.endDate).toDate();
    };
    LineItem.prototype.calculatePacing = function () {
        var ms = 3600000 * 24;
        var now = new Date();
        var start = new Date(this.effectiveStartDate);
        var end = new Date(this.effectiveEndDate);
        if (now > end) {
            return (this.spend / this.budget) * 100;
        }
        else if (now < start) {
            return 100;
        }
        var days = Math.floor((end.getTime() - start.getTime()) / ms) + 1;
        var progressed = Math.floor((now.getTime() - start.getTime()) / ms);
        return Math.round(((this.spend / progressed) * days) / this.budget * 100);
    };
    LineItem.prototype.isUnderPerforming = function (campaign) {
        var ctype = campaign.meta;
        var flag = false;
        if (ctype.goal === 'Branding' && ctype.title === 'Clicks') {
            var ctr = this.clicks / (this.impressions === 0 ? 1 : this.impressions);
            if (ctr < 0.002) {
                flag = true;
            }
        }
        else if (ctype.goal === 'Performance' && ctype.title === 'Maximize Reach') {
            var eCPM = (this.spend / this.impressions);
            if (eCPM > campaign.bidAmount) {
                flag = true;
            }
        }
        else if (ctype.goal === 'Performance' && ctype.title === 'Maximize Clicks') {
            var eCPC = (this.spend / this.clicks);
            if (eCPC > campaign.bidAmount) {
                flag = true;
            }
        }
        else if (ctype.goal === 'Performance' && ctype.title === 'Maximize Conversions') {
            var eCPA = (this.spend / this.conversions);
            if (eCPA > campaign.bidAmount) {
                flag = true;
            }
        }
        return flag;
    };
    LineItem.prototype.hasPixelProblem = function (campaign) {
        var eightdays = 1000 * 60 * 60 * 24 * 8;
        var start = moment(this.effectiveStartDate);
        var now = moment();
        var campaignType = campaign.meta;
        if (campaignType.reach === 'cpa' || campaignType.reach === 'conversions') {
            if (campaign.status === 'pending' && (start.diff(now) < eightdays)
                && start > now.startOf('day')) {
                return true;
            }
        }
        return false;
    };
    LineItem.prototype.hasRemainingBudget = function () {
        return this.budget > 0 || this.budget > this.spend;
    };
    LineItem.prototype.getPlatformStatus = function () {
        return Status[this.platformStatus];
    };
    LineItem.prototype.getDetailedDescription = function (campaign, pixel, creatives) {
        var errors = [];
        var preFlightDetailed = {
            no_active_ads: 'The line item is not ready to deliver because it does not contain any active ads.'
                + ' Please link at least one active ad before the campaign begins.',
            no_budget: 'The line item is not ready to deliver because it doesn’t have a budget.'
                + ' Please increase the budget before the flight begins.',
            no_active_tracker: 'The line item is not ready to deliver because the conversion tracker on the'
                + ' parent campaign is inactive. Please activate the conversion tracker before the campaign begins.',
            misses_creatives: 'This line item does not have all the required ad sizes to start delivering.'
                + ' Please include the ad sizes 300x250, 728x90, and 300x600.',
            no_ad_slots: 'The line item is not ready to deliver because it is not linked to any ad slots.'
                + ' Please link at least one ad slot to the line item.'
        };
        var inFlightDetailed = {
            no_active_ads: 'The line item is not ready to deliver because it does not contain any active ads.'
                + ' Please link at least one active ad to the line item.',
            no_budget: 'The line item is not delivering because there is no remaining budget.',
            no_active_tracker: 'The line item is not delivering because the conversion tracker on the parent'
                + ' campaign is inactive. Please activate the conversion tracker.',
            misses_creatives: 'This line item does not have all the required ad sizes to start delivering.'
                + ' Please include the ad sizes 300x250, 728x90, and 300x600.',
            no_ad_slots: 'The line item is not ready to deliver because it is not linked to any ad slots.'
                + ' Please link at least one ad slot to the line item.'
        };
        var short = {
            no_active_ads: 'It does not contain any active ads.',
            no_budget: 'It does not have a budget.',
            no_active_tracker: 'The parent campaign does not have an active conversion tracker.',
            misses_creatives: 'It does not have all of the required ad sizes.',
            no_ad_slots: 'It does not contain any ad slots.'
        };
        var val = {
            title: '',
            errors: [],
            display: false
        };
        /*
        * 1. Check creative status only for exchange campaigns. Direct sold or in-house creatives
        * don't require approval and can't be rejected.
        * 2. Pending creatives were created from LFM for Direct sold or in-house campaigns.
        * This was causing Maverick to display incorrect strategy status.
        * */
        if (campaign.demandType === 'exchange' && !this.hasActiveAd(creatives)) {
            errors.push('no_active_ads');
        }
        if (campaign.demandType !== 'exchange' && !this.hasAdSlots()) {
            errors.push('no_ad_slots');
        }
        if (!this.hasRemainingBudget() && !campaign.unlimitedBudget) {
            errors.push('no_budget');
        }
        this.hasConversionTracker(campaign) &&
            !this.hasActiveConversionTracker(campaign, pixel) && errors.push('no_active_tracker');
        val.display = errors.length > 0;
        if (this.isPreFlight()) {
            if (errors.length === 1) {
                val.title = preFlightDetailed[errors[0]];
                val.errors = [];
            }
            if (errors.length > 1) {
                val.title = 'The line item is not ready to deliver because of the following reasons:';
                val.errors = errors.map(function (err) { return short[err]; });
            }
        }
        else if (this.isInFlight()) {
            if (errors.length === 1) {
                val.title = inFlightDetailed[errors[0]];
                val.errors = [];
            }
            if (errors.length > 1) {
                val.title = 'The line item is not delivering because of the following reasons:';
                val.errors = errors.map(function (err) { return short[err]; });
            }
        }
        else {
            val.display = false;
        }
        return val;
    };
    LineItem.prototype.isPreFlight = function () {
        var startDate = this.effectiveStartDate || this.startDate;
        return moment().isBefore(startDate);
    };
    LineItem.prototype.isInFlight = function () {
        var startDate = this.effectiveStartDate || this.startDate;
        var endDate = this.effectiveEndDate || this.endDate;
        return moment().isBetween(startDate, endDate, 'days', '[]');
    };
    LineItem.prototype.hasCompletedFlight = function () {
        var endDate = this.effectiveEndDate || this.endDate;
        return moment().isAfter(endDate);
    };
    LineItem.prototype.hasProblem = function (campaign, pixel, creatives) {
        return !this.hasActiveCampaign(campaign)
            || !(this.hasRemainingBudget() || campaign.isDynamicBudget || campaign.unlimitedBudget)
            || (this.hasConversionTracker(campaign) && !this.hasActiveConversionTracker(campaign, pixel))
            || !this.hasAd(creatives)
            || (campaign.demandType !== 'exchange' && !(this.hasAdSlots() || this.hasBundles() || this.hasPackages()))
            || (this.hasAd(creatives) && campaign.demandType === 'exchange' && !this.hasActiveAd(creatives));
    };
    LineItem.prototype.hasConversionTracker = function (campaign) {
        return Boolean(campaign.conversionPixel);
    };
    LineItem.prototype.hasActiveConversionTracker = function (campaign, pixel) {
        return this.hasConversionTracker(campaign) && pixel.status === 'active';
    };
    LineItem.prototype.hasActiveCampaign = function (campaign) {
        return campaign.status === 'active';
    };
    LineItem.prototype.hasAd = function (creatives) {
        return creatives[0] !== null && creatives[0] !== undefined;
    };
    LineItem.prototype.hasActiveAd = function (creatives) {
        return creatives.some(function (creative) { return creative.status === 'active'; });
    };
    LineItem.prototype.hasAdSlots = function () {
        return Array.isArray(this.adSlots) && this.adSlots.length > 0;
    };
    LineItem.prototype.hasBundles = function () {
        return Array.isArray(this.bundles) && this.bundles.length > 0;
    };
    LineItem.prototype.hasPackages = function () {
        return Array.isArray(this.packages) && this.packages.length > 0;
    };
    LineItem.prototype.determineDatesWithCampaign = function (campaign) {
        var startDate, endDate;
        if (this.startDate && this.endDate) {
            startDate = this.startDate;
            endDate = this.endDate;
        }
        else if (campaign.effectiveStartDate && campaign.effectiveEndDate) {
            startDate = campaign.effectiveStartDate;
            endDate = campaign.effectiveEndDate;
        }
        else {
            startDate = campaign.insertionOrderStartDate;
            endDate = campaign.insertionOrderEndDate;
        }
        return { startDate: startDate, endDate: endDate };
    };
    /**
     * Removes unset Line Item properties to prevent
     * errors when trying to save the resource.
     *
     * @param  {LineItem}  strategy
     * @return {LineItem}  strategy
     */
    LineItem.prototype.removeUnsetProperties = function (strategy) {
        this.removeIfUnset(strategy, 'browsers', 'browserTargetingType');
        this.removeIfUnset(strategy, 'deviceMakers', 'deviceMakerTargetingType');
        this.removeIfUnset(strategy, 'deviceTypes', 'deviceTypeTargetingType');
        this.removeIfUnset(strategy, 'inventoryTargeting', 'inventoryTargetingType');
        this.removeIfUnset(strategy, 'isps', 'ispTargetingType');
        this.removeIfUnset(strategy, 'operatingSystems', 'operatingSystemTargetingType');
        this.removeIfUnset(strategy, 'categories', 'categoryTargetingType');
        // The intent is to allow for postal codes to be unset if the value is
        // `null` or that of an empty array. Otherwise we discard the property
        // to avoid sending invalid data.
        if (Array.isArray(strategy.postalCodes)) {
            if (strategy.postalCodes.length < 1) {
                strategy.postalCodes = null;
            }
        }
        else if (strategy.postalCodes !== null) {
            delete strategy.postalCodes;
        }
        if (this.isUnset(strategy, 'postalCodes') && this.isUnset(strategy, 'geos')) {
            delete strategy.geoTargetingType;
        }
        return strategy;
    };
    /**
     * Deletes properties of a resource if a
     * specified property is unset.
     *
     * @param  {Object}  resource
     * @param  {string}  property
     * @param  {string|array}  propertiesToRemove
     */
    LineItem.prototype.removeIfUnset = function (resource, property, propertiesToRemove) {
        if (this.isUnset(resource, property)) {
            removeProperties(resource, propertiesToRemove);
        }
        function removeProperties(resource, propertiesToRemove) {
            if (Array.isArray(propertiesToRemove)) {
                propertiesToRemove.forEach(function (propertyToRemove) {
                    delete resource[propertyToRemove];
                });
            }
            else {
                delete resource[propertiesToRemove];
            }
        }
    };
    /**
      * Checks if a resource's property is either
      * null, undefined, or length < 1.
      *
      * @param  {Object}  resource
      * @param  {string}  property
      * @return {Boolean}
      */
    LineItem.prototype.isUnset = function (resource, property) {
        return resource[property] !== false
            && (resource[property] === undefined
                || resource[property] === null
                || !Object.keys(resource[property]).length);
    };
    /**
     * Sets the property of a resource to
     * null if it was previously unset.
     *
     * @param  {Object}  resource
     * @param  {string}  property
     */
    LineItem.prototype.nullifyIfUnset = function (resource, property) {
        if (this.isUnset(resource, property)) {
            resource[property] = null;
        }
    };
    /**
     * Sets a property of a resource to null and
     * a property of the resource to 'exclude'
     * if the former was previously unset.
     *
     * @param  {Object}  resource
     * @param  {string}  property
     */
    LineItem.prototype.nullifyAndExcludeIfUnset = function (resource, property, propertyToExclude) {
        if (this.isUnset(resource, property)) {
            resource[property] = null;
            resource[propertyToExclude] = 'exclude';
        }
    };
    /**
     * Sanitizes a new Strategy before being saved
     * for the first time.
     *
     * @param  {Strategy}  strategy
     * @return {Strategy}
     */
    LineItem.prototype.sanitizeForCreate = function (strategy) {
        if (Array.isArray(strategy.geos) && strategy.geos.length < 1) {
            delete strategy.geos;
        }
        if (Array.isArray(strategy.postalCodes) && strategy.postalCodes.length < 1) {
            delete strategy.postalCodes;
        }
        if (!strategy.domains || (Array.isArray(strategy.domains) && strategy.domains.length === 0)) {
            delete strategy.domains;
            delete strategy.domainTargetingType;
        }
        if (Array.isArray(strategy.dataProviderSegments)) {
            strategy.dataProviderSegments = strategy.dataProviderSegments.map(function (s) { return ({
                type: s.type,
                id: s.id
            }); });
        }
        this.removeIfUnset(strategy, 'deviceTypes', ['deviceTypes', 'deviceTypeTargetingType']);
        this.removeIfUnset(strategy, 'browsers', ['browsers', 'browserTargetingType']);
        this.removeIfUnset(strategy, 'categories', ['categories', 'categoryTargetingType']);
        this.removeIfUnset(strategy, 'isps', ['isps', 'ispTargetingType']);
        this.removeIfUnset(strategy, 'operatingSystems', ['operatingSystems', 'operatingSystemTargetingType']);
        this.removeIfUnset(strategy, 'postalCodes', 'postalCodes');
        this.removeIfUnset(strategy, 'deviceMakers', ['deviceMakers', 'deviceMakerTargetingType']);
        this.removeIfUnset(strategy, 'inventoryTargeting', ['inventoryTargeting', 'inventoryTargetingType']);
        this.removeIfUnset(strategy, 'audiences', 'audiences');
        this.removeIfUnset(strategy, 'dataProviderSegments', 'dataProviderSegments');
        this.removeIfUnset(strategy, 'keyValues', ['keyValues', 'keyValuesTargetingType', 'keyValuesOperator']);
        if (this.isUnset(strategy, 'postalCodes') && this.isUnset(strategy, 'geos')) {
            delete strategy.geoTargetingType;
        }
        if (this.isUnset(strategy, 'days') && this.isUnset(strategy, 'hours')) {
            delete strategy.dayTargetingType;
        }
        return strategy;
    };
    /**
     * Sanitizes an existing strategy before being
     * saved.
     *
     * @param  {Strategy}  strategy
     * @return {Strategy}
     */
    LineItem.prototype.sanitizeForEdit = function (strategy) {
        if (!strategy.domains || (Array.isArray(strategy.domains) && strategy.domains.length === 0)) {
            strategy.domains = null;
        }
        if (Array.isArray(strategy.dataProviderSegments)) {
            strategy.dataProviderSegments = strategy.dataProviderSegments.map(function (s) { return ({
                type: s.type,
                id: s.id
            }); });
        }
        this.nullifyIfUnset(strategy, 'audiences');
        this.nullifyIfUnset(strategy, 'bundles');
        this.nullifyIfUnset(strategy, 'dataProviderSegments');
        this.nullifyIfUnset(strategy, 'geos');
        this.nullifyIfUnset(strategy, 'postalCodes');
        this.nullifyIfUnset(strategy, 'days');
        this.nullifyIfUnset(strategy, 'hours');
        this.nullifyAndExcludeIfUnset(strategy, 'categories', 'categoryTargetingType');
        this.nullifyAndExcludeIfUnset(strategy, 'browsers', 'browserTargetingType');
        this.nullifyAndExcludeIfUnset(strategy, 'deviceMakers', 'deviceMakerTargetingType');
        this.nullifyAndExcludeIfUnset(strategy, 'deviceTypes', 'deviceTypeTargetingType');
        this.nullifyAndExcludeIfUnset(strategy, 'isps', 'ispTargetingType');
        this.nullifyAndExcludeIfUnset(strategy, 'inventoryTargeting', 'inventoryTargetingType');
        this.nullifyAndExcludeIfUnset(strategy, 'operatingSystems', 'operatingSystemTargetingType');
        if (this.isUnset(strategy, 'postalCodes') && this.isUnset(strategy, 'geos')) {
            strategy.geoTargetingType = 'exclude';
        }
        if (this.isUnset(strategy, 'days') && this.isUnset(strategy, 'hours')) {
            strategy.dayTargetingType = 'exclude';
        }
        if (this.isUnset(strategy, 'keyValues')) {
            strategy.keyValuesOperator = 'any';
        }
        return strategy;
    };
    Object.defineProperty(LineItem.prototype, "budgetField", {
        get: function () {
            if (this.budgetIncrement === 'lifetime') {
                return 'budget';
            }
            return 'dailyBudget';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LineItem, "targetingFields", {
        get: function () {
            return targetingFields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LineItem.prototype, "entity", {
        get: function () {
            return 'Line Item';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LineItem.prototype, "remainingNumberOfDaysInFlight", {
        get: function () {
            var start = moment(this.startDate || this.effectiveStartDate, 'YYYY-MM-DD');
            var end = moment(this.endDate || this.effectiveEndDate, 'YYYY-MM-DD');
            var today = moment().startOf('day');
            var daysLeft;
            if (start.isBefore(today)) {
                if (end.isBefore(today)) {
                    daysLeft = 0;
                }
                else {
                    daysLeft = end.diff(today, 'days') + 1;
                }
            }
            else {
                daysLeft = end.diff(start, 'days') + 1;
            }
            return daysLeft;
        },
        enumerable: true,
        configurable: true
    });
    return LineItem;
}(Model));
export { LineItem };
