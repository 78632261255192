import { Observable, ReplaySubject } from 'rxjs';

import { PaginatorComponent } from 'app/shared/elements/paginator';
import { Query } from 'app/shared/elements/types';
import { TableComponent } from 'app/shared/elements/table';
import { TableData } from './table-helper';
import { AsyncTableComponent } from '../elements/async-table';

export abstract class BaseTableHelper<T> {
    /**
     * We set a debounce so we don't fire off too many queries at once. For example if
     * a user clicks the `Next` button repeatedly, we'll wait until the clicks stop
     * coming before finally emitting the desired page of the user.
     */
    protected readonly debounceTime = 150;

    /**
     * The data subject holds the current the data for the table.
     */
    data = new ReplaySubject<TableData<T>>(1);

    /**
     * The query made by the table to fill itself with data.
     */
    readonly query = new ReplaySubject<Query>(1);

    /**
     * The table.
     */
    private _table: TableComponent|AsyncTableComponent;

    /**
     * The table's paginator.
     */
    private _paginator: PaginatorComponent;

    /**
     * Adapt a query into table data.
     */
    abstract adapt(query?: Query): Observable<TableData<T>>;

    /**
     * Initiate a new table query.
     */
    search(args: Query[]) {
        this.query.next(Object.assign({}, ...args));
    }

    /**
     * Set the table paginator and register subscribptions.
     */
    set table(table: TableComponent|AsyncTableComponent) {
        if (this.table) this.table.query.unsubscribe();

        this._table = table;

        this.table.query.subscribe(() => { if (this.paginator) this.paginator.reset() });
    }

    /**
     * Get the table.
     */
    get table() {
        return this._table;
    }

    /**
     * Set the table's paginator and register subscribptions.
     */
    set paginator(paginator: PaginatorComponent) {
        if (this.paginator) this.paginator.query.unsubscribe();

        this._paginator = paginator;

        this.paginator.query.subscribe(() => { if (this.table) this.table.load() });
    }

    /**
     * Get the table's paginator.
     */
    get paginator() {
        return this._paginator;
    }
}
