import { Component, Output, EventEmitter, ViewChild } from '@angular/core';

import { IdService } from 'app/core';
import { MediaGroupRepository } from 'app/core/repositories';
import { ConfirmDialogComponent } from 'app/shared/elements/confirm-dialog';
import { MediaGroup } from 'app/shared/models';

@Component({
    selector: 'delete-media-group-dialog',
    templateUrl: './delete-media-group-dialog.html'
})
export class DeleteMediaGroupDialogComponent {
    @Output() deleted: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('dialog', { static: true }) dialog: ConfirmDialogComponent;

    public mediaGroup: MediaGroup = null;

    public constructor (
        public mediaGroupRepository: MediaGroupRepository,
        public id: IdService
    ) {}

    public open(mediaGroup: MediaGroup): void {
        this.mediaGroup = mediaGroup;
        this.dialog.open();
    }

    public delete(): void {
        this.mediaGroupRepository.delete(this.mediaGroup.id)
            .subscribe(() => this.deleted.emit(this.mediaGroup));
    }
}
