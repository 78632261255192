import { StrategyCard } from 'app/shared/models';
var BudgetHelper = /** @class */ (function () {
    function BudgetHelper(lineItem, campaign) {
        this.campaign = {};
        this.lineItem = lineItem;
        this.campaign = campaign || this.campaign;
    }
    BudgetHelper.prototype.getLifetimeBudget = function () {
        var actual = (this.lineItem.spend || 0) + this.lineItem[this.dailyField] * this.remainingNumberOfDaysInFlight;
        return this.ensurePositive(this.round(actual));
    };
    BudgetHelper.prototype.getDailyBudget = function () {
        if (this.lineItem.dailyBudget) {
            return this.lineItem.dailyBudget;
        }
        var actual = ((this.lineItem.budget || 0) - (this.lineItem.spend || 0)) / this.remainingNumberOfDaysInFlight;
        return this.ensurePositive(this.round(actual));
    };
    BudgetHelper.prototype.round = function (n) {
        if (isNaN(n)) {
            return null;
        }
        return Math.round(n * 100) / 100;
    };
    BudgetHelper.prototype.ensurePositive = function (value) {
        // Budget or Daily budget may not be less than $1. In a case of line item overspend,
        // the daily budget would be caclulated as a negative number. Resaving
        // the line item would readjust and increase the overall budget.
        return Math.max(value, 1);
    };
    Object.defineProperty(BudgetHelper.prototype, "remainingNumberOfDaysInFlight", {
        get: function () {
            if (this.lineItem.isNew() || !this.lineItem.effectiveStartDate || !this.lineItem.effectiveEndDate) {
                this.lineItem.effectiveStartDate = this.campaign.effectiveStartDate;
                this.lineItem.effectiveEndDate = this.campaign.effectiveEndDate;
            }
            return Math.max(this.lineItem.remainingNumberOfDaysInFlight, 1);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BudgetHelper.prototype, "hasUnlimitedBudget", {
        get: function () {
            return this.campaign.isDirectSold && this.campaign.isGuaranteed && this.campaign.isUnlimitedBudget;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BudgetHelper.prototype, "hasUnlimitedCampaignBudget", {
        get: function () {
            return this.campaign.isUnlimitedBudget;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BudgetHelper.prototype, "usesImpressionsBudget", {
        get: function () {
            return this.campaign.budgetType === 'impressions' || this.campaign.budgetType === 'adjusted_impressions';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BudgetHelper.prototype, "usesCalculatedDailyBudget", {
        get: function () {
            return this.usesDailyBudget || this.usesDailyCap;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BudgetHelper.prototype, "usesOptionalDailyCap", {
        get: function () {
            return this.campaign.supportsDailyCap && !this.isDailyCapRequired && !this.hasUnlimitedBudget;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BudgetHelper.prototype, "isAboveDailyAverage", {
        get: function () {
            return this.lineItem[this.dailyField] >= 10000;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BudgetHelper.prototype, "usesDailyCap", {
        get: function () {
            return this.isDailyCapRequired;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BudgetHelper.prototype, "usesDailyBudget", {
        get: function () {
            return this.campaign.isDsp && this.campaign.isExchange && !this.isDailyCapRequired;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BudgetHelper.prototype, "isDailyCapRequired", {
        get: function () {
            return this.campaign.buyingStrategyId === StrategyCard.PERFORMANCE_MAXIMIZE_CONVERSIONS_DAILY_CAP;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BudgetHelper.prototype, "dailyField", {
        get: function () {
            return this.usesDailyCap ? 'dailyCap' : 'dailyBudget';
        },
        enumerable: true,
        configurable: true
    });
    return BudgetHelper;
}());
export { BudgetHelper };
