import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (window['_limavrun'] === true) {
    if (document.location.host.includes('liveintent.com')) {
        enableProdMode();
    }
    
    platformBrowserDynamic()
        .bootstrapModule(AppModule, { preserveWhitespaces: true })
        .catch(err => console.error(err));
}
