import * as tslib_1 from "tslib";
import { Model } from './model';
var PublisherKeyValue = /** @class */ (function (_super) {
    tslib_1.__extends(PublisherKeyValue, _super);
    function PublisherKeyValue() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PublisherKeyValue.prototype.serialize = function () {
        return JSON.stringify(this.clone(PublisherKeyValue));
    };
    Object.defineProperty(PublisherKeyValue.prototype, "entity", {
        get: function () {
            return 'Publisher Key Value';
        },
        enumerable: true,
        configurable: true
    });
    return PublisherKeyValue;
}(Model));
export { PublisherKeyValue };
