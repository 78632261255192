import { Action, Entity, Role } from 'app/core/authorization.service';
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { AuthorizationService, IdService, ValidationService } from 'app/core';
import { Creative, LineItem, Native } from 'app/shared/models';
import { AssetField, AssetFieldProperties } from 'app/shared/models/creative';
import { NgForm } from '@angular/forms';
import { AdBuilderNativePreviewComponent } from 'app/shared/components/ad-builder/ad-builder-native-preview';
import validationErrors from 'app/core/errors/validation-errors.json';
import { SearchInputComponent } from 'app/shared/elements/search-input/search-input.component';
import { AdvertiserRepository, BlueprintRepository, LineItemRepository, MediaGroupRepository, PublisherRepository } from 'app/core/repositories';
import { map, mergeMap } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { sortBlueprintOptionsByName } from 'app/shared/helpers/blueprint-helper';
var Field;
(function (Field) {
    Field[Field["body"] = 0] = "body";
    Field[Field["adBlueprintId"] = 1] = "adBlueprintId";
})(Field || (Field = {}));
var AdBuilderNativeComponent = /** @class */ (function () {
    function AdBuilderNativeComponent(auth, id, cdr, blueprintRepository, advertiserRepository, lineItemRepository, publisherRepository, mediaGroupRepository) {
        this.auth = auth;
        this.id = id;
        this.cdr = cdr;
        this.blueprintRepository = blueprintRepository;
        this.advertiserRepository = advertiserRepository;
        this.lineItemRepository = lineItemRepository;
        this.publisherRepository = publisherRepository;
        this.mediaGroupRepository = mediaGroupRepository;
        this.creative = this.createCreative();
        this.showThirdPartyTracker = false;
        this.showStaqId = false;
        this.showExternalId = false;
        this.showThirdPartyFields = false;
        this.allowBlueprintChange = true;
        this.isNativeCreativeEdit = false;
        this.update$ = new ReplaySubject(1);
        this._placementId$ = new BehaviorSubject('');
        this.validator = new ValidationService();
        this.validationErrors = validationErrors;
        this.blueprintOptions$ = new ReplaySubject(1);
        this._blueprints = [];
        this.assetField = new AssetField();
        this.assetFieldProperties = AssetFieldProperties;
        this.Native = Native;
        this.action = Action;
        this.role = Role;
        this.entity = Entity;
        this.hasPlacementTargetingCampaign = false;
        this.hasPlacementTargetingStrategy = false;
    }
    Object.defineProperty(AdBuilderNativeComponent.prototype, "placementId", {
        set: function (value) {
            if (value) {
                this._placementId$.next(value);
                this.hasPlacementTargetingStrategy = true;
            }
            else {
                this.hasPlacementTargetingStrategy = false;
            }
        },
        enumerable: true,
        configurable: true
    });
    AdBuilderNativeComponent.prototype.ngOnInit = function () {
        var _this = this;
        for (var _i = 0, _a = this.assetFieldProperties; _i < _a.length; _i++) {
            var prop = _a[_i];
            this.assetField[prop.modelKey] = prop;
        }
        this.getMediaGroup().pipe(mergeMap(function (mgId) { return _this.blueprintRepository.basicSearch(mgId)
            .pipe(map(function (blueprints) {
            sortBlueprintOptionsByName(blueprints);
            return blueprints.map(function (item) {
                _this._blueprints.push(item);
                return {
                    value: item.id,
                    key: item.id,
                    label: "(ID:" + item.id + ") " + item.displayName,
                };
            });
        })); })).subscribe(function (blueprints) {
            _this.blueprintOptions$.next(blueprints);
            if (_this.isNativeCreativeEdit) {
                _this.setBlueprintId(_this.creative.blueprintId);
            }
        });
        if (this.campaign && this.lineItem) {
            this.lineItemRepository.search({
                conditions: [{
                        field: 'campaign',
                        value: this.campaign.id
                    }]
            }).subscribe(function (lineItems) {
                var lineItemsWPlacementTargeting = lineItems
                    .filter(function (lineItem) { return !(lineItem.placementId === '' || (!!_this.lineItem.id && _this.lineItem.id === lineItem.id)); });
                _this.hasPlacementTargetingCampaign = lineItemsWPlacementTargeting.length > 0;
            });
        }
    };
    AdBuilderNativeComponent.prototype.getMediaGroup = function () {
        var _this = this;
        return this.advertiserRepository.get(this.advertiserId)
            .pipe(mergeMap(function (advertiser) {
            if (advertiser.owner.type === 'Publisher') {
                return _this.publisherRepository.get(advertiser.owner.refId)
                    .pipe(mergeMap(function (owner) { return _this.mediaGroupRepository.get(owner.mediaGroup); }));
            }
            return _this.mediaGroupRepository.get(advertiser.owner.refId);
        }), map(function (mediaGroup) { return mediaGroup.refId; }));
    };
    AdBuilderNativeComponent.prototype.createCreative = function () {
        return new Creative({
            type: 'image',
            isNative: true
        });
    };
    AdBuilderNativeComponent.prototype.bodyFieldMaxCharacter = function (width) {
        if (!this.creative.thumbnailUrl) {
            return 200;
        }
        switch (true) {
            case width <= 300:
                return 25;
            case width > 300 && width <= 728:
                return 50;
            case width > 728:
                return 90;
        }
    };
    AdBuilderNativeComponent.prototype.addError = function (field, errorCode) {
        errorCode = errorCode || { incorrect: true };
        if (field) {
            this.form.form.controls[field].setErrors(errorCode);
        }
    };
    AdBuilderNativeComponent.prototype.removeError = function (field) {
        if (field) {
            this.form.form.controls[field].setErrors(null);
        }
    };
    AdBuilderNativeComponent.prototype.updateFieldChar = function ($event, fieldName) {
        var charCount = $event ? $event.length : 0;
        var maxChar = this.assetField[fieldName].maxChar;
        if (fieldName === 'body') {
            maxChar = this.bodyFieldMaxCharacter(this.creative.blueprintWidth);
        }
        this.assetField[fieldName].charRemaining = maxChar - charCount;
        this.assetField[fieldName].charCount = charCount;
        this.refresh();
    };
    AdBuilderNativeComponent.prototype.isCharCountHidden = function (fieldName) {
        if (fieldName === 'body') {
            return !this.assetField[fieldName].isFocused || !this.isBlueprintWidthSet();
        }
        return !this.assetField[fieldName].isFocused;
    };
    AdBuilderNativeComponent.prototype.onFieldFocus = function (fieldName) {
        var currentFieldValue = this.form.form.controls[fieldName].value || '';
        if (this.assetField[fieldName]) {
            this.assetField[fieldName].isFocused = true;
            this.updateFieldChar(currentFieldValue, fieldName);
        }
    };
    AdBuilderNativeComponent.prototype.onFieldBlur = function (fieldName) {
        if (this.assetField[fieldName]) {
            this.assetField[fieldName].isFocused = false;
        }
    };
    AdBuilderNativeComponent.prototype.exceededMaxChar = function (fieldName) {
        if (this.assetField[fieldName]) {
            if (fieldName === 'body') {
                return !this.isBlueprintNotSelected && this.assetField[fieldName].charRemaining < 0;
            }
            return this.assetField[fieldName].charRemaining < 0;
        }
    };
    AdBuilderNativeComponent.prototype.setBlueprintId = function (id) {
        var _this = this;
        if (id) {
            this.creative.blueprintId = id;
            var blueprintObject = this._blueprints.find(function (blueprint) { return blueprint.id === _this.creative.blueprintId; });
            if (blueprintObject && ('width' in blueprintObject)) {
                this.creative.blueprintWidth = blueprintObject.width;
                if (blueprintObject && ('maxHeight' in blueprintObject)) {
                    this.creative.height = blueprintObject.maxHeight;
                    this.creative.width = blueprintObject.width;
                }
            }
            this.creative.blueprintName = this._blueprints.find(function (blueprint) { return blueprint.id === _this.creative.blueprintId; }).name;
            this.removeError(Field[Field.adBlueprintId]);
            if (this.hasError(Field[Field.body], 'incorrect')) {
                this.removeError(Field[Field.body]);
            }
        }
        else {
            this.creative.blueprintId = null;
            this.creative.blueprintWidth = null;
            this.creative.blueprintName = null;
            this.addError(Field[Field.adBlueprintId]);
        }
    };
    AdBuilderNativeComponent.prototype.isBlueprintWidthSet = function () {
        if (this.creative.blueprintId && !this.creative.blueprintWidth) {
            if (this._blueprints.length > 0) {
                this.setBlueprintId(this.creative.blueprintId);
            }
        }
        return !!this.creative.blueprintWidth;
    };
    AdBuilderNativeComponent.prototype.focusIfBlueprintIsDeselected = function (model) {
        if (!model._selected) {
            model.editor.nativeElement.focus();
        }
    };
    Object.defineProperty(AdBuilderNativeComponent.prototype, "isBlueprintNotSelected", {
        get: function () {
            return !this.isBlueprintWidthSet();
        },
        enumerable: true,
        configurable: true
    });
    AdBuilderNativeComponent.prototype.updateIfaasUrl = function (url) {
        var img = new Image();
        img.src = url;
        this.creative.mediaUrl = url;
    };
    AdBuilderNativeComponent.prototype.reset = function () {
        this.form.reset();
        this.preview.reset();
        this.previewMobile.reset();
        this.cdr.detectChanges();
        this.creative = this.createCreative();
        this.validateClickUrlProtocol();
    };
    AdBuilderNativeComponent.prototype.validateClickUrlProtocol = function () {
        if (this.creative.clickUrl) {
            this.creative.clickUrl = this.creative.clickUrl
                // This is for an edge case where the user deletes the initial ht.*
                .replace(/^h?t?t?p?s?:?\/?\/?$/, 'https://')
                .replace(/^(.*\/\/)?/g, 'https://');
        }
    };
    AdBuilderNativeComponent.prototype.hasError = function (field, code) {
        var errors = this.form.form.controls[field].errors;
        if (errors) {
            if (code) {
                return errors[code];
            }
            return errors.incorrect;
        }
        return errors;
    };
    AdBuilderNativeComponent.prototype.onUpdateImageUrl = function () {
        if (this.creative.body) {
            this.updateFieldChar(null, Field[Field.body]);
            if (!(this.assetField['body'].charRemaining < 0) && this.hasError(Field[Field.body], 'invalidLength')) {
                this.removeError(Field[Field.body]);
            }
            return;
        }
        this.refresh();
    };
    AdBuilderNativeComponent.prototype.refresh = function () {
        this.preview.refresh();
        this.previewMobile.refresh();
        this.validateClickUrlProtocol();
    };
    AdBuilderNativeComponent.prototype.setIsModified = function () {
        this.creative.isModified = true;
    };
    Object.defineProperty(AdBuilderNativeComponent.prototype, "isBlueprintRequired", {
        get: function () {
            return this.campaign && (this.isDirectSoldGuaranteed || this.campaign.isHouse || this.hasPlacementTargetingCampaign
                || this.hasPlacementTargetingStrategy);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdBuilderNativeComponent.prototype, "readOnly", {
        get: function () {
            return (this.campaign && !this.auth.canManageMediaGroup && !this.auth.canManagePublisher && !this.auth.isAdOps
                && !this.auth.isAdmin);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdBuilderNativeComponent.prototype, "isDirectSoldGuaranteed", {
        get: function () {
            return this.campaign && this.campaign.isDirectSold && this.campaign.isGuaranteed;
        },
        enumerable: true,
        configurable: true
    });
    AdBuilderNativeComponent.prototype.handleOnShowSecondaryTracker = function (isExpanded) {
        if (isExpanded === false) {
            this.creative.urlTracking2 = null;
        }
    };
    return AdBuilderNativeComponent;
}());
export { AdBuilderNativeComponent };
