import {
    ChangeDetectorRef,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild
} from '@angular/core';
import { DialogComponent } from '../dialog';
import { DialogConfirmationComponent } from './dialog-confirmation.component';
import { IdService } from 'app/core/id.service';

@Component({
    selector: 'confirm-dialog',
    templateUrl: './confirm-dialog.html',
    styleUrls: ['./confirm-dialog.styl']
})
export class ConfirmDialogComponent implements OnInit {
    @ViewChild(DialogComponent, { static: true }) dialog: DialogComponent = null;
    @ContentChildren(DialogConfirmationComponent) confirmations: QueryList<DialogConfirmationComponent>;
    @Input() header: string = null;
    @Input() confirmText: string = 'No, Cancel';
    @Input() cancelText: string = 'Yes, Confirm';
    @Output() confirmed: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() canceled: EventEmitter<boolean> = new EventEmitter<boolean>();
    action: string = null;
    progress: number = null;
    total: number = null;
    valid: boolean = true;

    constructor(
        private cdr: ChangeDetectorRef,
        public id: IdService
    ) {}

    ngOnInit() {
        this.dialog.closed.subscribe(() => this.reset());
    }

    open(action?: string) {
        this.action = action;
        this.dialog.open();
    }

    close() {
        this.dialog.close();
        this.progress = null;
        this.total = null;
        this.canceled.emit(true);
    }

    confirm() {
        if (this.isConfirmed) {
            if (!this.action) {
                this.dialog.close();
            }
            this.confirmed.emit(true);
        }
    }

    initiate(total: number) {
        this.progress = 0;
        this.total = total;
    }

    postProgress() {
        this.progress++;
        this.cdr.detectChanges();
    }

    get isLoading() {
        return this.total !== null && this.progress !== null
            && this.total !== this.progress;
    }

    private get isConfirmed() {
        this.confirmations.forEach(confirmation => confirmation.check());
        return this.valid = this.confirmations.toArray().every(confirmation => confirmation.confirmed);
    }

    private reset() {
        this.valid = true;
        this.confirmations.forEach(confirmation => confirmation.reset());
    }
}
