import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, zip } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { LineItem } from 'app/shared/models';

import {
    AdSlotRepository,
    CampaignRepository,
    CreativeRepository,
    LineItemRepository
} from 'app/core/repositories';
import { Flag, LaunchDarklyService } from '../launch-darkly.service';
import { Id } from '../repositories/backend-repository';
import { dedupeObjectArray } from "app/core/utils";

@Injectable()
export class LineItemResolver implements Resolve<LineItem> {
    constructor(private lineItemRepository: LineItemRepository,
        private creativeRepository: CreativeRepository,
        private adSlotRepository: AdSlotRepository,
        private campaignRepository: CampaignRepository,
        private launchDarklyService: LaunchDarklyService,
    ) { }

    dedupeObjectArray = dedupeObjectArray;

    /*
      This resolver is used for details and line item forms.
      It either:
      - id parameter (refId), ie line-items/123/edit, line-items/123
      - cloneFrom (hash), line-items/news;cloneFrom=XXXXXXXXXXXXXXXXXXXXXXXX
      - campaign (hash), to create a new line item for campaign XXXX, line-items/new;campaign=XXXXXXXXXXXXXXXXX
     */
    resolve(route: ActivatedRouteSnapshot): Observable<LineItem> {

        const cloneId = route.params['cloneFrom'];
        const campaignId = route.params['campaign'];
        let lineItemId = route.params['id'];
        const advertiserId = route.params['advertiser'];

        if (campaignId) {

            return this.campaignRepository.get(campaignId)
                .pipe(mergeMap(campaign => {

                    let newLineItem = new LineItem();
                    newLineItem.campaign = campaignId;
                    newLineItem.campaignObj = campaign;
                    newLineItem.ads = [];
                    newLineItem.targetUsOnly = true;

                    return of(newLineItem);
                }));
        }

        if (advertiserId) {
            return of(new LineItem({
                ads: [],
                advertiser: advertiserId,
                targetUsOnly: true
            }));
        }

        if (cloneId) {
            lineItemId = cloneId;
            return this.resolveLineItem(lineItemId, true);
        }

        return this.resolveLineItem(lineItemId);
    }

    resolveLineItem(lineItemId: Id, isClone: boolean = false) {
        return this.lineItemRepository.get(lineItemId).pipe(
            mergeMap(lineItem => zip(
                this.campaignRepository.get(lineItem.campaign),
                this.creativeRepository.searchByLineItem(lineItem.id),
                this.adSlotRepository.search({conditions: [{field: 'strategyId', value: lineItem.refId}]}),
                this.adSlotRepository.search({conditions: [{field: 'strategyId', value: lineItem.refId}]
                    ,return: ['onlyPackageLinkedAdSlots']}),
                of(lineItem),
                this.hasNCTFlag(),
                this.hasTraffickingUXFlag(),
                (campaign, creatives, sections, packageSections, lineItem, hasNCTFlag, hasTraffickingUXFlag) => {
                    campaign.startDate = campaign.startDate || campaign.insertionOrderStartDate;
                    campaign.endDate = campaign.endDate || campaign.insertionOrderEndDate;
                    let newLineItem = lineItem;
                    if (isClone) {
                        newLineItem = this.copy(lineItem);
                    } else {
                        newLineItem.startDate = newLineItem.startDate || campaign.startDate;
                        newLineItem.endDate = newLineItem.endDate || campaign.endDate;

                        // TODO: Address this once LSD does proper calculations
                        newLineItem.effectiveStartDate = newLineItem.startDate;
                        newLineItem.effectiveEndDate = newLineItem.endDate;
                    }

                    newLineItem.campaignObj = campaign;
                    newLineItem.ads = creatives;
                    newLineItem.userHasNCTAccess = hasNCTFlag;
                    newLineItem.userHasTraffickingUXFlag = hasTraffickingUXFlag;

                    newLineItem.packageAdSlots = packageSections;
                    newLineItem.sections = dedupeObjectArray([...sections, ...packageSections], 'id');

                    return newLineItem;
                }
            ))
        );
    }

    private hasNCTFlag() {
        return this.launchDarklyService
            .getVariation(Flag.RolloutNativeCreativeTrafficking);
    }

    private hasTraffickingUXFlag() {
        return this.launchDarklyService
            .getVariation(Flag.RolloutTraffickingUX);
    }

    private copy(lineItem) {
        const newLineItem = lineItem.clone(LineItem);
        /**
         * Remove all fields that are not
         * part of the Line Item's targeting
         */
        for (const key of Object.keys(newLineItem)) {
            if (LineItem.targetingFields.indexOf(key) > -1) {
                continue;
            }
            delete newLineItem[key];
        }

        newLineItem.name += ' - COPY';
        newLineItem._isClone = true;
        return newLineItem;
    }
}
