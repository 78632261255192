import * as tslib_1 from "tslib";
import { Model } from './model';
import colors from 'src/data/colors/palette.json';
import { DemandImageAssetType } from './native-demand';
export var AdSlotTagType;
(function (AdSlotTagType) {
    AdSlotTagType["Universal"] = "universal";
    AdSlotTagType["Legacy"] = "legacy";
})(AdSlotTagType || (AdSlotTagType = {}));
export var AdSlotTagVersion;
(function (AdSlotTagVersion) {
    AdSlotTagVersion["Hybrid"] = "3.0.0";
})(AdSlotTagVersion || (AdSlotTagVersion = {}));
export var AdSlotType;
(function (AdSlotType) {
    AdSlotType["FixedRatio"] = "image";
    AdSlotType["TaboolaNative"] = "native";
    AdSlotType["ImageNative"] = "imageNative";
    AdSlotType["Hybrid"] = "hybrid";
})(AdSlotType || (AdSlotType = {}));
export var AdSlotTypeLabel;
(function (AdSlotTypeLabel) {
    AdSlotTypeLabel["FixedRatio"] = "Display";
    AdSlotTypeLabel["TaboolaNative"] = "Taboola";
    AdSlotTypeLabel["ImageNative"] = "Native";
    AdSlotTypeLabel["Hybrid"] = "Display & Native";
})(AdSlotTypeLabel || (AdSlotTypeLabel = {}));
export var AdIndicatorType;
(function (AdIndicatorType) {
    AdIndicatorType[AdIndicatorType["Advertisement"] = 1] = "Advertisement";
    AdIndicatorType[AdIndicatorType["Advertising"] = 2] = "Advertising";
    AdIndicatorType[AdIndicatorType["AdvertiserContent"] = 3] = "AdvertiserContent";
    AdIndicatorType[AdIndicatorType["Sponsored"] = 4] = "Sponsored";
    AdIndicatorType[AdIndicatorType["SponsoredAd"] = 5] = "SponsoredAd";
    AdIndicatorType[AdIndicatorType["SponsoredBy"] = 6] = "SponsoredBy";
    AdIndicatorType[AdIndicatorType["Blank"] = 7] = "Blank";
})(AdIndicatorType || (AdIndicatorType = {}));
export var AdSlotStatus;
(function (AdSlotStatus) {
    AdSlotStatus["Active"] = "active";
    AdSlotStatus["Pending"] = "pending";
})(AdSlotStatus || (AdSlotStatus = {}));
var AdSlot = /** @class */ (function (_super) {
    tslib_1.__extends(AdSlot, _super);
    function AdSlot(data) {
        if (data === void 0) { data = {}; }
        var _this = _super.call(this, data) || this;
        _this._status = data.status;
        return _this;
    }
    Object.defineProperty(AdSlot.prototype, "status", {
        get: function () {
            if (this._status === 'deleted') {
                return 'archived';
            }
            return this._status;
        },
        set: function (value) {
            this._status = value;
        },
        enumerable: true,
        configurable: true
    });
    AdSlot.prototype.isPending = function () {
        return this.status === AdSlotStatus.Pending;
    };
    AdSlot.prototype.getAdType = function () {
        var type = this.type;
        if (this.isHybrid) {
            type = AdSlotTypeLabel.Hybrid;
        }
        else if (this.isNative) {
            type = AdSlotTypeLabel.ImageNative;
        }
        else if (type === AdSlotType.TaboolaNative) {
            type = AdSlotTypeLabel.TaboolaNative;
        }
        else if (type === AdSlotType.FixedRatio) {
            type = AdSlotTypeLabel.FixedRatio;
        }
        return type;
    };
    AdSlot.prototype.getAdIndicatorType = function () {
        if (this.adIndicatorId) {
            return AdIndicatorType[this.adIndicatorId];
        }
        return 'Blank';
    };
    AdSlot.prototype.getCoordinationLabel = function () {
        if (this.tagType === AdSlotTagType.Universal || this.getTagType() === AdSlotTagType.Universal) {
            if (this.coordinationType) {
                return 'Enabled';
            }
            return 'Disabled';
        }
        return 'N/A';
    };
    AdSlot.prototype.getCoordinationLabelForLineItem = function (lineItem) {
        if (lineItem.isCoordinated) {
            return this.coordinationStatus;
        }
        return this.getCoordinationLabel();
    };
    /*
    * Tag Type is universal if adslot has display enabled with multiple sizes or single size and collapse enabled
    * If adslot is native only then it is considered a Universal Tag by default
    */
    AdSlot.prototype.getTagType = function () {
        if ((this.isUniversalTag && this.sizes && this.sizes.length > 1) ||
            (this.isUniversalTag && this.sizes && this.sizes.length === 1 && this.collapse) ||
            (this.isUniversalTag && this.isNative && this.isDisplay != true)) {
            return AdSlotTagType.Universal;
        }
        return AdSlotTagType.Legacy;
    };
    // [TODO] Use collapse field instead of tagtype https://liveintent.atlassian.net/browse/MT-5222
    AdSlot.prototype.getCollapsibleStatus = function () {
        if (this.tagType === AdSlotTagType.Universal || this.getTagType() === AdSlotTagType.Universal) {
            return 'Yes';
        }
        return 'No';
    };
    AdSlot.prototype.serialize = function () {
        var adSlot = JSON.parse(JSON.stringify(this));
        delete adSlot.newsletterObj;
        delete adSlot.eligibleCreatives;
        if (adSlot.type !== 'native' && adSlot.taboola) {
            delete adSlot.taboola;
        }
        if ('sspControl' in adSlot) {
            delete adSlot.sspControl.uniqueAds;
        }
        if ('nativeBlueprints' in adSlot && adSlot.nativeBlueprints) {
            adSlot.nativeBlueprints.forEach(function (nativeBlueprint) {
                delete nativeBlueprint.blueprintName;
                delete nativeBlueprint.native;
                delete nativeBlueprint.maxHeight;
                delete nativeBlueprint.minHeight;
                delete nativeBlueprint.width;
                delete nativeBlueprint.mobileMaxHeight;
                delete nativeBlueprint.mobileMinHeight;
                delete nativeBlueprint.mobileWidth;
            });
        }
        delete adSlot.native;
        if (!adSlot.externalUrl && adSlot.externalUrlEnabled) {
            delete adSlot.externalUrl;
        }
        if (!adSlot.sizes) {
            delete adSlot.sizes;
        }
        return adSlot;
    };
    Object.defineProperty(AdSlot.prototype, "entity", {
        get: function () {
            return 'Ad Slot';
        },
        enumerable: true,
        configurable: true
    });
    AdSlot.prototype.isPrimary = function () {
        return this.type == 'image';
    };
    AdSlot.prototype.isTaboola = function () {
        return this.type === AdSlotType.TaboolaNative;
    };
    AdSlot.prototype.nativeEnabledStatus = function () {
        if (this.isTaboola()) {
            return 'N/A';
        }
        return this.isNative ? 'Yes' : 'No';
    };
    AdSlot.prototype.displayEnabledStatus = function () {
        if (this.isTaboola()) {
            return 'N/A';
        }
        if (this.isDisplay) {
            return 'Yes';
        }
        return 'No';
    };
    Object.defineProperty(AdSlot.prototype, "isNativeUnsupported", {
        /**
         * Check if the hybrid adslot is using an LT3 tag to support serving native.
         * Newly created adslots will have currentTagVersion set as null so the new adslots need to be excluded.
         */
        get: function () {
            return this.isHybrid && this.currentTagVersion !== AdSlotTagVersion.Hybrid && this.currentTagVersion !== null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdSlot.prototype, "showNativeUnsupportedWarning", {
        /**
         * Display the warning only in some locations when native is enabled.
         */
        get: function () {
            return this.isNativeUnsupported && this.isNative;
        },
        enumerable: true,
        configurable: true
    });
    AdSlot.prototype.showNoBlueprintsInfo = function () {
        return this.isNative && this.nativeBlueprints && this.nativeBlueprints.length < 1;
    };
    Object.defineProperty(AdSlot.prototype, "adSlotStatusColor", {
        get: function () {
            return this.status === AdSlotStatus.Active ? colors['sushi'] : colors['storm-gray'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdSlot.prototype, "displaySizes", {
        get: function () {
            if (!this.isDisplay && this.isNative) {
                return 'N/A';
            }
            return this.sizes;
        },
        enumerable: true,
        configurable: true
    });
    AdSlot.prototype.getBlueprintAspectRatio = function (blueprint) {
        if (blueprint.desktopAspectRatio && blueprint.mobileAspectRatio && blueprint.desktopAspectRatio !== blueprint.mobileAspectRatio) {
            return blueprint.desktopAspectRatio + " (Desktop)\n" + blueprint.mobileAspectRatio + " (Mobile)";
        }
        if (blueprint.desktopAspectRatio) {
            return blueprint.desktopAspectRatio;
        }
        if (blueprint.mobileAspectRatio) {
            return blueprint.mobileAspectRatio + " (Mobile)";
        }
        return 'N/A';
    };
    AdSlot.prototype.getEligibleBlueprintAspectRatios = function (eligibleBlueprintIds) {
        if (this.nativeBlueprints.length === 0 || !eligibleBlueprintIds) {
            return 'N/A';
        }
        var allAspectRatios = new Set();
        var desktopOnlyAspectRatios = new Set();
        var mobileOnlyAspectRatios = new Set();
        var hasNAAspectRatio = false;
        // Populate aspect ratios into respective sets
        this.nativeBlueprints
            .filter(function (blueprint) { return eligibleBlueprintIds.includes(blueprint.blueprintId); })
            .forEach(function (blueprint) {
            var desktopAspectRatio = blueprint.desktopAspectRatio, mobileAspectRatio = blueprint.mobileAspectRatio;
            if (desktopAspectRatio && mobileAspectRatio && desktopAspectRatio === mobileAspectRatio) {
                // Add to combined set if both ratios are the same
                allAspectRatios.add(blueprint.desktopAspectRatio);
            }
            else {
                if (desktopAspectRatio && !allAspectRatios.has(desktopAspectRatio)) {
                    desktopOnlyAspectRatios.add(desktopAspectRatio + ' (Desktop)');
                }
                if (mobileAspectRatio && !allAspectRatios.has(mobileAspectRatio)) {
                    mobileOnlyAspectRatios.add(mobileAspectRatio + ' (Mobile)');
                }
                if (!desktopAspectRatio && !mobileAspectRatio) {
                    hasNAAspectRatio = true;
                }
            }
        });
        // Aspect ratios for both desktop and mobile will be listed first, followed by desktop-only and mobile-only ratios
        var combinedAspectRatios = Array.from(allAspectRatios).concat(Array.from(desktopOnlyAspectRatios).filter(function (ratio) { return !allAspectRatios.has(ratio.replace(' (Desktop)', '')); }), Array.from(mobileOnlyAspectRatios).filter(function (ratio) { return !allAspectRatios.has(ratio.replace(' (Mobile)', '')); }));
        // Append "N/A" at the end if necessary
        if (hasNAAspectRatio || !combinedAspectRatios.length) {
            combinedAspectRatios.push('N/A');
        }
        return combinedAspectRatios.join(', ');
    };
    Object.defineProperty(AdSlot.prototype, "primaryPackageNames", {
        get: function () {
            if (!this.primaryPackageInfo) {
                return [];
            }
            return this.primaryPackageInfo.map(function (packageInfo) { return packageInfo.packageName; });
        },
        enumerable: true,
        configurable: true
    });
    AdSlot.prototype.displayAspectRatioMismatchWarning = function (blueprint, creative) {
        if (!this.shouldCheckAspectRatio(blueprint, creative)) {
            return false;
        }
        var creativeAspectRatio = this.getCreativeMainImageAspectRatio(creative);
        return creativeAspectRatio !== null && creativeAspectRatio !== this.getBlueprintAspectRatio(blueprint);
    };
    AdSlot.prototype.shouldCheckAspectRatio = function (blueprint, creative) {
        var blueprintAspectRatio = this.getBlueprintAspectRatio(blueprint);
        return creative.isNative && blueprintAspectRatio !== 'N/A' && blueprintAspectRatio !== undefined;
    };
    AdSlot.prototype.getCreativeMainImageAspectRatio = function (creative) {
        var _this = this;
        var creativeMainImageAsset = creative.native.assets.find(function (asset) {
            return _this.isDemandImageAsset(asset) && asset.hasOwnProperty('aspectRatio');
        });
        return creativeMainImageAsset ? creativeMainImageAsset.aspectRatio : null;
    };
    AdSlot.prototype.isDemandImageAsset = function (asset) {
        return asset && asset.hasOwnProperty('img') && asset.img.hasOwnProperty('type') && asset.img.type === DemandImageAssetType.Main;
    };
    AdSlot.prototype.newsletterHasCoordinatedAdSlots = function () {
        if (this.newsletterObj && this.newsletterObj.allowCoordination && this.newsletterObj.adSlots) {
            return this.newsletterObj.adSlots.filter(function (adSlot) { return adSlot.coordinationType === 'companion'; }).length > 0;
        }
        return false;
    };
    return AdSlot;
}(Model));
export { AdSlot };
