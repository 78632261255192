import { Injectable } from "@angular/core";
import * as LDClient from "launchdarkly-js-client-sdk";

import { AuthenticationService } from "./authentication.service";
import { AuthorizationService } from "./authorization.service";
import { distinctUntilChanged, map, mergeMap, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { forkJoin, Observable, of, ReplaySubject } from "rxjs";
import { AdvertiserRepository, AgencyRepository, MediaGroupRepository, PublisherRepository, UserRepository } from "./repositories";
import { NgZone } from "@angular/core";

// Flag naming suggestions recommendations https://docs.launchdarkly.com/guides/flags/flag-naming#flag-kind-examples
export enum Flag {
    AllowCoordination = 'allow-ad-slot-coordination',
    NewSearchExperienceInMaverick = 'new-search-experience-in-maverick',
    Rollout_BookOnAdjustedImpressions = 'rollout-book-on-adjusted-impressions',
    Rollout_PubaAccess = 'rollout-puba-access',
    RolloutNativeCreativeTrafficking = 'rollout-native-creative-trafficking',
    RolloutTraffickingUX = 'rollout-trafficking-ux',
    RolloutCreativeMapping = 'rollout-creative-mapping',
    RolloutAudienceExtension = 'rollout-audience-extension',
    RolloutUAMEnhancements = 'rollout-uam-enhancements',
    RolloutAdBlocking = 'rollout-ad-blocking',
    RolloutLineItemBlackout = 'rollout-line-item-blackout',
}

@Injectable()
export class LaunchDarklyService {
    private client: LDClient.LDClient = null;
    private readySubject = new ReplaySubject(1);

    constructor(
        private userRepository: UserRepository,
        private mediaGroupRepository: MediaGroupRepository,
        private publisherRepository: PublisherRepository,
        private agencyRepository: AgencyRepository,
        private advertiserRepository: AdvertiserRepository,
        private auth: AuthorizationService,
        private authenticationService: AuthenticationService,
        private ngZone: NgZone
    ) {

        this.ngZone.runOutsideAngular(() => {
            this.authenticationService.currentUser.pipe(
                distinctUntilChanged(),
                mergeMap(user => {
                    return forkJoin([of(user), this.userRepository.getAccessList(user.id)]);
                }),
                tap(([user, accessList]) => {
                    forkJoin([
                        of(user),
                        this.getPublishers(accessList),
                        this.getAdvertisers(accessList),
                        this.getMediaGroups(accessList),
                        this.getAgencies(accessList)
                    ]).subscribe(([ user, publishers, advertisers, mediaGroups,  agencies ]) => {
                        const context = {
                            kind: 'multi',
                            user: {
                                key: user.hashId,
                                name: user.name,
                                email: user.email,
                                isAdmin: this.auth.isAdmin,
                                isManager: this.auth.isManager,
                                isInternalUser: this.auth.isInternalUser,
                                isClient: !this.auth.isInternalUser,
                            },
                            accounts: {
                                key: 'account-' + user.hashId,
                                publishers: publishers.map(p => 'MG(' + p.mediaGroupRefId + '):PUB(' + p.refId + ') ' + p.name),
                                advertisers: advertisers.map(a => 'AG(' + a.agencyRefId + '):ADV(' + a.refId + ') ' + a.name ),
                                mediaGroups: mediaGroups.map(a => 'MG(' + a.refId + ') ' + a.name),
                                agencies: agencies.map(a => 'AG(' + a.refId + ') ' + a.name),
                            }
                        };

                        this.client = LDClient.initialize(environment.ld.clientId, context, {
                            useReport: true,
                            streaming: true,
                        });

                        this.client.identify(context, null, function() {
                            // do nothing
                        });

                        this.client.on("ready", () => {
                            this.ngZone.run(() => {
                                this.readySubject.next(true);
                            });

                        });

                        this.client.on("change", () => {
                            this.ngZone.run(() => {
                                this.readySubject.next(true);
                            });
                        });

                    });
                })
            ).subscribe()
        });

    }

    private getPublishers(accessList) {
        const ids = [];
        if (accessList.entities.Publisher) {
            ids.push(...accessList.entities.Publisher.map(e => e.id) || [])
        }
        return this.publisherRepository.searchByIds(ids);
    }

    private getAdvertisers(accessList) {
        const ids = [];
        if (accessList.entities.Advertiser) {
            ids.push(...accessList.entities.Advertiser.map(e => e.id) || [])
        }
        return this.advertiserRepository.searchByIds(ids);
    }

    private getMediaGroups(accessList) {
        const ids = [];
        if (accessList.entities.MediaGroup) {
            ids.push(...accessList.entities.MediaGroup.map(e => e.id) || [])
        }

        return this.mediaGroupRepository.searchByIds(ids);
    }

    private getAgencies(accessList) {
        const ids = [];
        if (accessList.entities.Agency) {
            ids.push(...accessList.entities.Agency.map(e => e.id) || [])
        }

        return this.agencyRepository.searchByIds(ids);
    }

    /**
     * Get flag variation for current user
     * @param flag string
     * @param defaultValue any
     *
     * @returns Observable<any>
     */
    getVariation(flag: string, defaultValue?: any): Observable<any> {
        return this.readySubject.pipe(
            map(() => {
                if (typeof defaultValue !== 'undefined') {
                    return this.client.variation(flag, defaultValue);
                }

                return this.client.variation(flag);
            })
        );
    }
}
