import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    ViewChild,
    Output,
    Input
} from '@angular/core';
import { FilterComponent } from '../filter.component';
import { FilterId } from './filter-id';

@Component({
    selector: 'filter-id',
    templateUrl: './filter-id.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterIdComponent implements AfterViewInit, FilterComponent {
    @ViewChild('content', { static: true }) content: ElementRef;
    @Output() emitter = new EventEmitter<FilterId>();
    filter: FilterId = new FilterId();
    @Input() suggestedItems: Map<number, string>;

    ngAfterViewInit() {
        this.filter.label = this.content.nativeElement.innerHTML;

        if (!this.filter.operation) {
            this.filter.operation = this.filter.operations[0];
        }
    }

    emit() {
        this.emitter.emit(this.filter);
    }
}
