import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { BackendRepository } from './backend-repository';
import { catchError } from 'rxjs/operators';
import { Audience } from 'app/shared/models';
var SamovarAudienceRepository = /** @class */ (function (_super) {
    tslib_1.__extends(SamovarAudienceRepository, _super);
    function SamovarAudienceRepository(http) {
        return _super.call(this, http, "/search/audience/samovar", Audience) || this;
    }
    SamovarAudienceRepository.prototype.build = function (from) {
        from.refId = from.id;
        from.uniqueSegmentId = from.id;
        from.dataProviderId = from.dataProvider.id;
        from.matchCountTotal = from.counts.matchCountTotal.value;
        return new this.model(from);
    };
    SamovarAudienceRepository.prototype.buildItems = function (responses) {
        var _this = this;
        return responses.reduce(function (all, response) {
            var data = response['results'];
            if (!data || !data.length)
                return all;
            var items = data.map(function (item) { return _this.build(item); });
            all.push.apply(all, items);
            return all;
        }, []);
    };
    SamovarAudienceRepository.prototype.searchByUniqueSegmentId = function (params) {
        var _this = this;
        if (!Array.isArray(params.conditions)) {
            params.conditions = [];
        }
        var refIdCondition = params.conditions.find(function (condition) { return condition.field === 'refId'; });
        if (!refIdCondition) {
            return of([]);
        }
        var searchParams = {
            "filter": {
                "id": {
                    "in": refIdCondition.value.map(function (value) { return parseInt(value); }),
                },
            }
        };
        return this.consume(function (_) {
            return _this.http.post(_this.url(_this.path), searchParams).pipe(catchError(function (err) { return throwError(err); }));
            ;
        });
    };
    SamovarAudienceRepository.prototype.searchByAccountId = function (accountId) {
        var _this = this;
        if (!accountId) {
            return of([]);
        }
        return this.consume(function (page) {
            var params = {
                "filter": {
                    "account": {
                        "id": {
                            "eq": accountId,
                        },
                    },
                },
                "pagination": {
                    "pageSize": 20000,
                    "page": page,
                },
            };
            return _this.http.post(_this.url(_this.path), params).pipe(catchError(function (err) { return throwError(err); }));
        });
    };
    return SamovarAudienceRepository;
}(BackendRepository));
export { SamovarAudienceRepository };
