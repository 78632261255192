import moment from 'moment';
import { cloneDeep, cloneDeepWith } from 'lodash';

const SECONDS_IN_AN_HOUR = 60 * 60;
const SECONDS_IN_A_DAY = SECONDS_IN_AN_HOUR * 24;

export const CONVERSION_REPORTING_RELEASE_DATE = new Date('April 1 2025 EST');

/**
 * Check if two things are logically equivalent.
 */
export function isEqual(a: any, b: any) {
    if (typeof a === 'object' && typeof b === 'object') {
        return JSON.stringify(a) === JSON.stringify(b);
    }

    return a === b;
}

/**
 * Check if a string is a number.
 */
export function isNumber(str: string) {
    return /^\d+$/.test(str);
}

/**
 * Convert days to seconds.
 */
export function daysToSeconds(days: number) {
    return days * SECONDS_IN_A_DAY;
}

/**
 * Convert seconds to days.
 */
export function secondsToDays(seconds: number) {
    return Math.round(seconds / SECONDS_IN_A_DAY);
}

/**
 * Convert days to hours.
 */
export function hoursToSeconds(hours: number) {
    return hours * SECONDS_IN_AN_HOUR;
}

/**
 * Convert seconds to days.
 */
export function secondsToHours(seconds: number) {
    return Math.round(seconds / SECONDS_IN_AN_HOUR);
}

/**
 * Deep clone an object. There are faster clone methods, but we don't really care.
 */
export function clone(obj: any) {
    return JSON.parse(JSON.stringify(obj));
}

/**
 * Deep clone an object using lodash. Better supported than JSON stringify clone method above.
 */
export function lodashDeepClone(obj: any) {
    return cloneDeep(obj);
}

/**
 * Deep clone an object using lodash with methods
 */
export function lodashDeepCloneWith(obj: any) {
    return cloneDeepWith(obj, (value) => {
        if (typeof value === 'function') {
          // If value is a function, return it as is to include methods
          return value;
        }
        // Otherwise, return undefined to use default cloning behavior
      });
}

/**
 * Zero fill an array of dataPoints so that each period within the interval specified contains a data point.
 *
 * It's usually used for graphs.
 */
export function zeroFill(start: moment.Moment, end: moment.Moment, granularity: moment.unitOfTime.Diff, dataPoints: Array<{ timestamp: string }>, fillWith?: object) {
    const map = new Map<string, object>();
    dataPoints.forEach(dataPoint => map.set(dataPoint.timestamp, dataPoint));

    const result = [];
    const currentTimestamp = start.clone();
    const endTimestamp = end.clone().startOf(granularity);

    do {
        const toFill = map.get(currentTimestamp.toISOString()) || Object.assign({
            timestamp: currentTimestamp.toISOString()
        }, fillWith);

        result.push(toFill);

        currentTimestamp.add(1, granularity);
    } while (currentTimestamp.diff(endTimestamp, granularity) <= 0);

    return result;
}

/**
 * Build a key based on the parameters, useful for caching.
 */
export function buildKey(...args: any[]) {
    return args.join('');
}

/**
 * Truncates string by the set length (not including the ending) and ending
 * if string is longer than the truncated target.
 */
export function truncateString(originalString: string, len: number = 128, ending: string = '...'): string {
    return !originalString || (originalString.length <= len) ? originalString : originalString.substring(0, len) + ending;
}

/**
 * Remove common delimiters with space on default
 */
export function removeCommonDelimiters(string: string, replaceDeliminator: string = ' '): string {
    if (!string) {
        return;
    }
    return string.replace(/[,_.\-]/g, replaceDeliminator);
}

/**
 * Return last path segment from path string
 */
export function getLastPathSegment(path: string): string {
    const urlPaths = path.split('/');
    return urlPaths[urlPaths.length - 1];
}

/**
 * Dedupe array of objects
 */
export function dedupeObjectArray(arr: any[], identifier: string) {
    return arr.reduce((unique, o) => {
        if(!unique.some(obj => obj[identifier] === o[identifier])) {
          unique.push(o);
        }
        return unique;
    },[]);
}

/**
 * (1) Get the value of a field in an object using dot notation
 *
 * Example:
 *    let obj = { a: { b: { c: 1 } } };
 *    getDotNotationField(obj, 'a.b.c') // => 1
 *
 *
 * (2) This also works for non-nested objects.
 *
 * Example:
 *    let obj = { a: 1 };
 *    getDotNotationField(obj, 'a') // => 1
 */
export function getDotNotationField(obj: any, field: string) {
    if (!obj || !field) {
        return null;
    }
    let fields = field.split(".");
    let objRef = obj;
    for (let i = 0; i < fields.length; i++) {
        if (!objRef[fields[i]]) {
            return null;
        }
        objRef = objRef[fields[i]];
    }
    return objRef;
}

/**
 * (1) Set the value of a field in an object using dot notation.
 *
 * Example:
 *    let obj = { a: { b: { c: 1 } } };
 *    setDotNotationField(obj, 'a.b.c', 2); // obj => { a: { b: { c: 2 } } }
 *
 *
 * (2) This also works for non-nested objects.
 *
 * Example:
 *    let obj = { a: 1 };
 *    setDotNotationField(obj, 'a', 2); // obj => { a: 2 }
 */
export function setDotNotationField(obj: any, field: string, value: any) {
    // If the object or field is not provided, return early
    if (!obj || !field) {
        return;
    }

    // Split the field into an array of fields
    let fields = field.split(".");
    let objRef = obj;

    // Traverse the object to the second to last field
    for (let i = 0; i < fields.length - 1; i++) {
        if (!objRef[fields[i]]) {
            // If the field in the chain does not exist, return early
            return;
        }
        objRef = objRef[fields[i]];
    }

    // Set the value of the last field
    objRef[fields[fields.length - 1]] = value;
}
