import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthorizationService } from 'app/core/authorization.service';
import { ConfirmDialogComponent } from 'app/shared/elements/confirm-dialog';
import { Option } from 'app/shared/elements/dropdown';
import { LightboxComponent } from 'app/shared/elements/lightbox';
import { ModalComponent } from 'app/shared/elements/modal';
import { Publisher } from 'app/shared/models';
import { BehaviorSubject, ReplaySubject, Subscription, combineLatest } from 'rxjs';

import { ErrorHelper } from 'app/core/errors/error-helper';
import { IdService } from 'app/core/id.service';
import { NotificationsService } from 'app/core/notifications.service';
import { SspControlHelper } from 'app/shared/helpers/ssp-control-helper';

import {
    AdvertiserRepository,
    SamovarAudienceRepository,
    CategoryRepository,
    CookieSyncPartnerRepository,
    ESPRepository,
    GlobalBlocklistRepository,
    MediaGroupRepository,
    PublisherIdentifierRepository,
    PublisherRepository,
    UserRepository
} from 'app/core/repositories';
import options from './options.json';

import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import validationErrors from 'app/core/errors/validation-errors.json';
import { YieldManagement } from 'app/shared/services/yield-management.service';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { PublisherClientTypes } from 'app/shared/models/publisher';
import { Flag, LaunchDarklyService } from 'app/core/launch-darkly.service';

import PUBLISHER_TYPES from "app/shared/models/publisher-types.json";

const LIVERAMP_USER_PARTNER = 'ca7d5f52aadf61794896c87f1d1e9d9c';
const NO_NAME_USER_PARTNER = '93d2344a67c111e18dd712313e003071';
const AUDIENCE_ID_TO_PREFIX = new Map();

@Component({
    selector: 'publisher-form',
    templateUrl: './publisher-form.html',
    styleUrls: ['./publisher-form.styl'],
    providers: [YieldManagement]
})
export class PublisherFormComponent implements OnInit, OnDestroy {
    @ViewChild(ModalComponent, { static: true })
    public modal: ModalComponent = null;

    @ViewChild(NgForm, { static: true })
    private form: NgForm = null;

    @ViewChild('submitButton', { static: true })
    public submitButton = null;

    @ViewChild(LightboxComponent, { static: false })
    public adBuilder: LightboxComponent = null;

    @ViewChild('publisherConfirmation', { static: true })
    public publisherConfirmation: ConfirmDialogComponent = null;

    @Output()
    public saved: EventEmitter<any> = new EventEmitter();

    public validationErrors = validationErrors;
    public isEdit: boolean = false;
    public categories: Option[] = [];
    public blocklists: Option[] = [];
    public requiredErrorText: string = 'Oops! This is a required field.';
    public invalidDomainText: string = 'Please enter a valid domain, eg. domain.com.';
    public invalidUrlText: string = 'Please enter a valid URL.';
    public redirect: string;

    public mediaGroups$ = new BehaviorSubject([]);
    public mediaGroup$ = new BehaviorSubject('');
    public mediaGroups: any;
    public searchMediaGroup$ = new BehaviorSubject('');

    public errorDetails = options.errorDetails;

    // Publisher Details
    public publisher: any;
    public espOptions = new ReplaySubject<Option[]>(1);
    public espLabel = new ReplaySubject<string>(1);
    public mediaGroup: any = null;
    public showKVPs: boolean = false;
    public showPublisherLabel: boolean = false;
    public showAdvanced: boolean = false;
    public showPrivacy: boolean = false;
    public cname: string = '';
    public protocol: string = 'https://';
    public protocolOptions = options.protocolOptions;
    public _useDifferentSources: boolean = false;
    public _useDifferentSourcesAdvertisers: boolean = false;
    public _useDifferentSourcesExternalAdvertisers: boolean = false;
    public userMatchPartners: any[] = [];
    public targetedUserMatchPartners: any[] = [];
    public cookieSyncPartners: any[] = [];
    public gdprComplianceOptions = JSON.parse(JSON.stringify(options.gdprCompliance));
    public showGlobalBlocklist: boolean = true;

    // RTB Settings
    public sspControlHelper: SspControlHelper;
    public globalBlocklist: any[] = [];

    public publisherIdentifierSubscription: Subscription;
    public publisherIdentifierOptions = new BehaviorSubject<Option[]>([]);
    //AM and AE
    public accountMgrName: string = '';
    public accountExecName: string = '';
    public errorHelper: ErrorHelper = null;
    public confirmationErrorHelper: ErrorHelper = null;
    public errors: any[] = [];
    public genericError: boolean = false;
    public publisher$ = new BehaviorSubject<Publisher>(null);
    espSubscription: Subscription;
    userOptions: any[] = [];
    audienceOptions$: ReplaySubject<Option[]> = new ReplaySubject<Option[]>(1);
    matchingPublishers: Publisher[] = [];
    accountId: number
    isMouseHover: boolean = false;

    esp$ = new BehaviorSubject('');
    emailTagReplacement$ = new BehaviorSubject('');
    emailTagType$ = new BehaviorSubject('');
    placementTagReplacement$ = new BehaviorSubject('');
    listTagReplacement$ = new BehaviorSubject('');
    lceeTagReplacement$ = new BehaviorSubject('');
    lceeKeyReplacement$ = new BehaviorSubject('');

    public rolloutUamEnhancements = false;

    private noteId: number;

    // Client Types
    public clientTypes: any[] = [
        { label: 'Monetization', index: PublisherClientTypes.Monetization },
        { label: 'HIRO', index: PublisherClientTypes.Hiro },
        { label: 'Identity', index: PublisherClientTypes.Identity },
        { label: 'Email Intelligence', index: PublisherClientTypes.EmailIntelligence },
    ];

    protected readonly PUBLISHER_TYPES = PUBLISHER_TYPES;
    readonly ADVERTISER_UNIQUE_MODES = [
        PUBLISHER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER,
        PUBLISHER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER_DISPLAY,
        PUBLISHER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER_NATIVE
    ];

    readonly EXTERNAL_ADVERTISER_UNIQUE_MODES = [
        PUBLISHER_TYPES.SSP_CONTROL.UNIQUE_ADS.EXTERNAL_ADVERTISERS,
        PUBLISHER_TYPES.SSP_CONTROL.UNIQUE_ADS.EXTERNAL_ADVERTISERS_DISPLAY,
        PUBLISHER_TYPES.SSP_CONTROL.UNIQUE_ADS.EXTERNAL_ADVERTISERS_NATIVE
    ];

    constructor(
        private yieldManagement: YieldManagement,
        private userRepository: UserRepository,
        private samovarAudienceRepository: SamovarAudienceRepository,
        public id: IdService,
        public route: ActivatedRoute,
        public router: Router,
        public auth: AuthorizationService,
        public advertiserRepository: AdvertiserRepository,
        public cookieSyncPartnerRepository: CookieSyncPartnerRepository,
        public espRepository: ESPRepository,
        public mediaGroupRepository: MediaGroupRepository,
        public publisherRepository: PublisherRepository,
        public publisherIdentifierRepository: PublisherIdentifierRepository,
        public categoryRepository: CategoryRepository,
        public globalBlocklistRepository: GlobalBlocklistRepository,
        private launchDarklyService: LaunchDarklyService,
        public notifications: NotificationsService,
    ) {
        this.errorHelper = new ErrorHelper();
        this.confirmationErrorHelper = new ErrorHelper();
    }

    ngOnInit() {
        this.modal.open();

        this.route.paramMap.subscribe(params => {
            if (params.has('redirect')) {
                this.redirect = atob(params.get('redirect'));
            }
        });

        this.route.data.subscribe(data => {
            this.publisher = data.publisher;
            this.publisher$.next(this.publisher);

            if (this.publisher.id) {
                this.isEdit = true;
            }

            if (this.publisher.mediaGroupObj) {
                this.mediaGroup = this.publisher.mediaGroupObj;
                this.changeMediaGroup(this.mediaGroup);
            }

            if (!this.publisher.clientTypes) {
                this.publisher.clientTypes = [
                    PublisherClientTypes.Monetization
                ];
            }
            this.open();
        });

        this.mediaGroup$.subscribe(mediaGroup => {
            if (mediaGroup) {
                this.loadPublisherIdentifiers();
                this.populatePublisherDetails(mediaGroup);
                this.updateGdprCompliance(mediaGroup);
            }
        });
        this.modal.closed.subscribe(() => this.reset());

        this.launchDarklyService
            .getVariation(Flag.RolloutUAMEnhancements)
            .pipe(distinctUntilChanged())
            .subscribe(rolloutUamEnhancements => this.rolloutUamEnhancements = rolloutUamEnhancements);
    }

    ngOnDestroy() {
        this.yieldManagement.destroy();
    }

    onPublisherNameChange (value) {
        const publisherName = value ? value.trim() : ''
        if (publisherName) {
            const query = {
                conditions: [
                    {
                        mode: 'and',
                        operator: 'eq',
                        field: 'name',
                        value: publisherName
                    }
                ],
                orderBy: 'created',
                sort: 'DESC'
            };

            this.publisherRepository.search(query).subscribe(publishers => {
                this.matchingPublishers = publishers
            })
        }else{
            this.matchingPublishers = []
        }
    }
    /**
     * Initialize this component by loading any necessary data
     * and register any subscribers with their events.
     */
    public init(): void {
        this.loadIABCategories();
        this.loadEsps();
        this.loadCookieSyncPartners();
        this.loadBlocklists();
        this.showPublisherFields();
        if (this.auth.isInternalUser) {
            combineLatest(
                this.userRepository.getAdmins(),
                this.userRepository.getManagers()
            ).pipe(map(([admins, managers]) => {
                const users = admins.concat(managers);
                this.userOptions = users.map(user => this.convertUserToOption(user));
            })).subscribe();
        }

        if (!this.publisher.id) {
            // ESP field settings
            this.publisher.esp = null;
            this.publisher.emailTagReplacement = null;
            this.publisher.emailTagType = null;
            this.publisher.placementTagReplacement = null;
            this.publisher.listTagReplacement = null;
            this.publisher.lceeKeyReplacement = null;
            this.publisher.lceeTagReplacement = null;
        }

        this.esp$.next(this.publisher.esp);
        this.emailTagReplacement$.next(this.publisher.emailTagReplacement);
        this.emailTagType$.next(this.publisher.emailTagType);
        this.placementTagReplacement$.next(this.publisher.placementTagReplacement);
        this.listTagReplacement$.next(this.publisher.listTagReplacement);
        this.lceeKeyReplacement$.next(this.publisher.lceeKeyReplacement);
        this.lceeTagReplacement$.next(this.publisher.lceeTagReplacement);
    }

    /**
     * Open the form modal for create or edit.
     * @param {any} publisher
     */
    public open(publisher: any = {}): void {
        this.init();
        const sspControl = {
            rtbAllow: null,
            exchangeAllow: true,
            exchangeFloor: null,
        };

        if (!this.isEdit) {
            this.initMediaGroup();
            publisher.domainTargetingType = 'exclude';
            publisher.inheritConsentControls = true;

            this.publisher = new Publisher(JSON.parse(JSON.stringify(publisher)));
            this.publisher.sspControl = sspControl;

        } else {
            this.protocol = this.publisher.getTagsProtocol();
            this.cname = this.publisher.getCname();

            if (!this.auth.isInternalUser) {
                this.espOptions.subscribe(esps => {
                    const publisherEsp = esps.find(esp => this.publisher.esp === esp.value);
                    if (publisherEsp) {
                        this.espLabel.next(publisherEsp.label);
                    }
                });

                this.userRepository.search({
                    conditions: [
                        {
                            field: 'hash_id',
                            value: [this.publisher.executive, this.publisher.admin]
                        }
                    ]
                }).subscribe(users => {
                    users.forEach(user => {
                        if (user.hashId === this.publisher.executive) {
                            this.accountExecName = user.name;
                        } else {
                            this.accountMgrName = user.name;
                        }
                    });
                });
            }

            if (this.publisher.sspControl.uniqueAds !== 'off'
                && this.publisher.sspControl.uniqueAds !== 'inherit') {
                this._useDifferentSources = true;
            }

            if (Array.isArray(this.publisher.userMatchPartners)) {
                this.targetedUserMatchPartners =
                    this.cookieSyncPartners
                        .filter(userMatchPartner => {
                            return this.publisher.userMatchPartners.indexOf(userMatchPartner.id) > -1;
                        });
            }
        }
    }

    public inputChangeMediaGroup(search: string) {
        this.searchMediaGroup$.next(search);
    }

    public changeMediaGroup(mediaGroup) {
        if (mediaGroup) {
            this.mediaGroupRepository.get(mediaGroup.id).subscribe(mediaGroupObj => {
                this.mediaGroup = mediaGroupObj;
                this.publisher.mediaGroup = mediaGroupObj.id;
                this.mediaGroup$.next(this.mediaGroup);
            })
        } else {
            this.mediaGroup$.next(null);
            this.mediaGroup = null;
        }
    }

    updateGdprCompliance(mediaGroup) {
        let label = 'Inherit (';
        const value = this.gdprComplianceOptions.find( option => option.value === mediaGroup.gdprCompliance );
        this.gdprComplianceOptions[3].label = label + value.label + ')';
    }

    /**
     * Show all fields that are not null on the publisher
     */
    public showPublisherFields(): void {
        this.showPublisherLabel = typeof this.publisher.externalId === 'string';
        this.showKVPs = this.publisher.keyValues.length > 0;
    }

    /**
     * Load the list of primary IAB categories.
     */
    public loadIABCategories(): void {
        this.categoryRepository.getPrimaryCategories()
            .pipe(categories => this.categoryRepository.convertCategoriesToOptions(categories))
            .subscribe(categories => this.categories = categories);
    }

    public get categoryLabel() {
        const categoryOption = this.categories.find(option => this.publisher.category === option.value);

        if (categoryOption) {
            return categoryOption.label;
        }
    }

    /**
     * Populate the AM and AE from the mediaGroup for a new publisher
     * @param mediaGroup
     */
    public populatePublisherDetails(mediaGroup) {
        this.publisher.admin = mediaGroup.manager;
        this.publisher.executive = mediaGroup.executive;
        this.accountExecName = mediaGroup.executiveName;
        this.accountMgrName = mediaGroup.managerName;
    }

    /**
     * Load all supported Esp's for the dropdown.
     */
    public loadEsps(): void {

        this.espSubscription = this.espRepository.all()
            .pipe(map(esps => esps.map(esp => ({
                key: esp.id,
                value: esp.id,
                label: esp.name
            })))).subscribe(esps => {
                esps.push({ key: 'other', value: 'other', label: 'Other ESP' });
                this.espOptions.next(esps);
            });
    }

    /**
     * Load all supported publisher identifiers
     */
    public loadPublisherIdentifiers() {
        this.publisherIdentifierRepository.all()
            .pipe(
                map(pubId => pubId.map(pi => {
                    const option: any = {
                        key: pi.refId,
                        label: pi.name,
                        value: false
                    };
                    if (this.publisher.inheritConsentControls && this.mediaGroup.consentControls) {
                        const mgConsent = this.mediaGroup.consentControls.find(cc => cc.id === option.key);
                        if (mgConsent) {
                            option.value = mgConsent.allow;
                        }
                    } else if (this.publisher.consentControls) {
                        const pubConsent = this.publisher.consentControls.find(cc => cc.id === option.key);
                        if (pubConsent) {
                            option.value = pubConsent.allow;
                        }
                    }
                    return option;
                }))
            ).subscribe(options => {
                this.publisherIdentifierOptions.next(options);
            })
    }

    /**
     * Load blocklists
     */
    public loadBlocklists(): void {
        this.globalBlocklistRepository.all()
            .pipe(blocklists => this.globalBlocklistRepository.convertGlobalBlocklistToOptions(blocklists))
            .subscribe(blocklists => this.blocklists = blocklists);
    }

    /**
     * Load Cookie Synch Partners
     */
    public loadCookieSyncPartners(): void {
        this.cookieSyncPartnerRepository.search().toPromise()
            .then(cookieSyncPartners => {
                this.cookieSyncPartners = cookieSyncPartners;
                this.userMatchPartners = cookieSyncPartners
                    .filter(cookieSyncPartner => cookieSyncPartner.cookieSyncType === 'user_matching')
                    .map(cookieSyncPartner => {
                        return {
                            key: cookieSyncPartner.id,
                            value: cookieSyncPartner.id,
                            label: cookieSyncPartner.name
                        };
                    });
            });
    }

    /**
     * Respond to a progressive link being expanded or collapsed.
     * @param {string}  inputName
     * @param {boolean} expanded
     */
    public handleExpandedChange(inputName: string, expanded: boolean): void {
        if (expanded === false) {
            if (inputName === 'publisherLabel') {
                this.publisher.externalId = null;
            }
            this.publisher[inputName] = [];
        }
    }

    /**
     * Save the publisher to the api.
     */
    public confirmPublisher(): void {
        if (typeof this.noteId === 'number') {
            this.notifications.remove(this.noteId);
        }

        this.errorHelper.resetAllErrors();
        this.confirmationErrorHelper.resetAllErrors();
        this.publisher.mediaGroup = this.mediaGroup.id;
        this.publisher.tagsUrlPrefix = this.protocol + this.cname;

        let publisher = this.publisher.serialize();
        this.submitButton.load();
        this.publisherRepository.confirm(publisher)
            .toPromise()
            .then(() => {
                this.submitButton.reset();
                this.savePublisher();
            })
            .catch((err: HttpErrorResponse) => {
                if (this.auth.isInternalUser || this.auth.isMediaGroupManagePublisher) {
                    this.confirmationErrorHelper.loadBackEndErrors(err.error.errors);
                    this.publisherConfirmation.open();
                } else {
                    this.errorHelper.loadBackEndErrors(err.error.errors);
                    let errorList = this.errorHelper.errors;
                    this.displayErrorMessage(errorList);
                }
                this.submitButton.reset();
            });
    }

    public savePublisher() {
        this.modal.showLoading();
        this.submitButton.load();

        this.publisherRepository.save(this.publisher)
            .toPromise()
            .then(publisher => {
                this.publisher = publisher;
            })
            .then(() => {
                this.modal.hideLoading();
                if (this.isEdit) {
                    this.notifications.success(`<strong>Woo hoo!</strong> You have successfully updated details for ${this.publisher.name}.`);
                } else {
                    this.notifications.success(`The publisher <strong>${this.publisher.name}</strong> was created successfully.`);
                }
                this.modal.close(this.publisher.clone(Publisher));
                this.submitButton.reset();
            })
            .catch(errResponse => {
                this.submitButton.reset();
                this.handleErrors(errResponse);
            });
    }

    /**
     * Save User Match Partners
     * @param {string} publisherId
     * @return Promise
     */
    public saveUserMatchPartners(publisherId: string): Promise<any> {
        return this.publisherRepository.linkUserMatchPartners(publisherId, {
            version: this.publisher.version,
            // Hard coded user match partner will be replaced with user inputs later
            partners: [LIVERAMP_USER_PARTNER, NO_NAME_USER_PARTNER]
        }).toPromise();
    }

    public get useDifferentSources(): boolean {
        return this._useDifferentSources;
    }

    public set useDifferentSources(value: boolean) {
        this.publisher.sspControl.uniqueAds = value ? null : PUBLISHER_TYPES.SSP_CONTROL.UNIQUE_ADS.OFF;
        this._useDifferentSources = value;

        if (!this.publisher.sspControl.uniqueAds) {
            this.publisher.sspControl.uniqueAds = PUBLISHER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER; // Default if no previous selection
        }
    }

    public get useDifferentSourcesAdvertisers(): boolean {
        return this.ADVERTISER_UNIQUE_MODES.includes(this.publisher.sspControl.uniqueAds);
    }
    
    public get useDifferentSourcesExternalAdvertisers(): boolean {
        return this.EXTERNAL_ADVERTISER_UNIQUE_MODES.includes(this.publisher.sspControl.uniqueAds);
    }

    public set useDifferentSourcesAdvertisers(value: boolean) {
        this._useDifferentSourcesAdvertisers = value;
        this._useDifferentSourcesExternalAdvertisers = null;
        
       if (value) {
            // Preserve the user's choice if it's already set
            if (!this.publisher.sspControl.uniqueAds || 
                !this.ADVERTISER_UNIQUE_MODES.includes(this.publisher.sspControl.uniqueAds)) {
                this.publisher.sspControl.uniqueAds = PUBLISHER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER; // Default if no previous selection
            }
        } else {
            this.publisher.sspControl.uniqueAds = PUBLISHER_TYPES.SSP_CONTROL.UNIQUE_ADS.OFF;
        }
    }
    
    public set useDifferentSourcesExternalAdvertisers(value: boolean) {
        this._useDifferentSourcesExternalAdvertisers = value;
        this._useDifferentSourcesAdvertisers = null;
        
        if (value) {
            // Preserve the user's choice if it's already set
            if (!this.publisher.sspControl.uniqueAds || 
                !this.EXTERNAL_ADVERTISER_UNIQUE_MODES.includes(this.publisher.sspControl.uniqueAds)) {
                this.publisher.sspControl.uniqueAds = PUBLISHER_TYPES.SSP_CONTROL.UNIQUE_ADS.EXTERNAL_ADVERTISERS; // Default if no previous selection
            }
        } else {
            this.publisher.sspControl.uniqueAds = PUBLISHER_TYPES.SSP_CONTROL.UNIQUE_ADS.OFF;
        }
    }

    /**
     * Handle parsing and displaying errors received
     * from the api.
     * @param {any} response
     */
    public handleErrors(err: HttpErrorResponse): void {
        this.modal.hideLoading();

        const response = err.error;

        response.errors.forEach(error => {

            /**
             * If error message is in errorDetails array, then use the more descriptive
             * message from errorObj instead of the one returned from the Api and add it to the
             * errors array which will be sent to errorHelper
             */
            if (this.errorDetails[error.details]) {
                let errorObj = this.errorDetails[error.details];

                let index = response.errors.findIndex((err) => {
                    return err.details === error.details;
                });

                if (index > -1) {
                    response.errors[index].details = errorObj.details;
                }
            }
        });
        this.errorHelper.loadBackEndErrors(response.errors);
        let errorList = this.errorHelper.errors;
        this.displayErrorMessage(errorList);
    }

    /**
     * Clear out and reset the form.
     */
    public reset(): void {
        this.errorHelper.resetAllErrors();
        this.form.resetForm();
    }
    private convertUserToOption(user: any): Option {
        return {
            key: user.hashId,
            value: user.hashId,
            label: user.name
        };
    }

    public displayErrorMessage(errorList): void {
        if (errorList.length === 1) {
            this.noteId = this.notifications.error('<strong>Uh Oh!</strong> The publisher could not be saved because '
                + errorList.join(' ') + ' Please check the publisher and try again.', '', 0);
        } else if (errorList.length > 1) {
            this.noteId = this.notifications.error('<strong>Uh Oh!</strong>  The publisher could not be saved for'
                + ' the following reasons: <li>' + errorList.join('</li><li>') + '</li>', '', 0);
        }
    }

    /**
     * We are only returning the first item because
     * the api returns an array of audiences. We want
     * to limit users to only select 1 audience through
     * the frontend.
     */
    get audienceId(): number|null {
        if (Array.isArray(this.publisher.audiences) && this.publisher.audiences[0]) {
            // This will be prefixed, so don't return the prefix, only return the audience id
            const audienceIdStr = this.publisher.audiences[0].split('.')[1];
            // And convert to number to match one of the dropdown options
            return Number(audienceIdStr);
        }
        return null;
    }

    set audienceId(audienceId: number|null) {
        this.addPrefixToThisPublishersAudience(audienceId);
    }

    private addPrefixToThisPublishersAudience(audienceId: number) {
        if (audienceId) {
            const prefix = AUDIENCE_ID_TO_PREFIX.get(audienceId);
            this.publisher.audiences = [`${prefix}.${audienceId}`];
        } else {
            this.publisher.audiences = null;
        }
    }

    onPubConsentControlChange(publisherIdentifier) {
        this.publisher.inheritConsentControls = false;
        this.publisher.consentControls = publisherIdentifier.getValue().map(pi => ({ id: pi.key, allow: pi.value }));
    }

    updateDemandBlockingSettings(publisher) {
        this.publisher.targetedDomains = publisher.targetedDomains;
        this.publisher.domainTargetingType = publisher.domainTargetingType;
        this.publisher.blocklistCategories = publisher.blocklistCategories;
        this.publisher.sensitiveCategories = publisher.sensitiveCategories;
        this.publisher.audiences = publisher.audiences;
    }

    updatePublisherEspSettings(publisher) {
        this.publisher.esp = publisher.esp;
        this.publisher.emailTagReplacement = publisher.emailTagReplacement;
        this.publisher.emailTagType = publisher.emailTagType;
        this.publisher.placementTagReplacement = publisher.placementTagReplacement;
        this.publisher.listTagReplacement = publisher.listTagReplacement;
        this.publisher.lceeKeyReplacement = publisher.lceeKeyReplacement;
        this.publisher.lceeTagReplacement = publisher.lceeTagReplacement;
    }

    updatePublisherEsp(esp) {
        this.publisher.esp = esp;
    }

    updatePublisherEmailTagReplacement(value) {
        this.publisher.emailTagReplacement = value;
    }

    updatePublisherEmailTagType(value) {
        this.publisher.emailTagType = value;
    }

    updatePublisherPlacementTagReplacement(value) {
        this.publisher.placementTagReplacement = value;
    }

    updatePublisherListTagReplacement(value) {
        this.publisher.listTagReplacement = value;
    }

    updatePublisherLceeKeyReplacement(value) {
        this.publisher.lceeKeyReplacement = value;
    }

    updatePublisherLceeTagReplacement(value) {
        this.publisher.lceeTagReplacement = value;
    }

    validEspSetting() {
        return (this.publisher.esp !== null);
    }

    private initMediaGroup() {
        this.searchMediaGroup$.pipe(
            distinctUntilChanged(),
            debounceTime(300),
            switchMap(search => {
                const conditions = [];

                if (search.length > 0) {
                    conditions.push({
                        field: 'name',
                        value: search,
                        operator: 'like'
                    });

                    const refId = parseInt(search);

                    if (!isNaN(refId) && refId.toString() === search) {
                        conditions.push({
                            field: 'refId',
                            value: refId,
                            mode: 'or'
                        });
                    }
                } else {
                    return this.mediaGroupRepository.search();
                }

                return this.mediaGroupRepository.search({conditions});
            })
        ).subscribe((mediaGroups) => {
            if (this.mediaGroups === undefined) {
                this.mediaGroups = mediaGroups;
            }
            this.mediaGroups$.next(mediaGroups);
        });
    }

    isClientTypeSelected(publisherClientTypes: PublisherClientTypes[], clientType: PublisherClientTypes): boolean {
        if (publisherClientTypes) {
            return publisherClientTypes.includes(clientType);
        }

        return clientType === PublisherClientTypes.Monetization;
    }

    onClientTypeChange(isSelected: boolean, clientType: PublisherClientTypes): void {
        if (isSelected) {
            this.publisher.clientTypes.push(clientType);
        } else {
            this.publisher.clientTypes = this.publisher.clientTypes.filter(publisherClientType => publisherClientType !== clientType);
        }
    }

    isLastSelected(currentClientType: PublisherClientTypes): boolean {
        if (!this.publisher.clientTypes) {
            this.publisher.clientTypes = [
                PublisherClientTypes.Monetization
            ];
        }
        return this.publisher.clientTypes.length === 1 && this.publisher.clientTypes.find((clientType: PublisherClientTypes) => clientType === currentClientType);
    }

    close(): void {
        this.modal.close();
        if (this.redirect) {
            this.router.navigateByUrl(this.redirect);
        } else if (this.publisher.refId) {
            this.router.navigate(['/inventory-manager/publishers', this.publisher.refId]);
        } else if (this.mediaGroup.refId) {
            this.router.navigate(['/inventory-manager/media-groups', this.mediaGroup.refId]);
        } else {
            this.router.navigate(['/inventory-manager']);
        }
    }
}
