import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { fadeAnimation } from 'app/shared/fade.animation';

@Component({
    selector: 'x-dropdown',
    templateUrl: './x-dropdown.html',
    styleUrls: ['./x-dropdown.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeAnimation]
})
export class XDropdownComponent {
    private visibility = 'hidden';

    constructor(private changeDetector: ChangeDetectorRef) { }

    show() {
        this.visible = true;
    }

    hide() {
        this.visible = false;
    }

    set visible(visible: boolean) {
        this.visibility = visible ? 'visible' : 'hidden';
        this.changeDetector.markForCheck();
    }

    get isVisible() {
        return this.visibility === 'visible';
    }
}
