import { Model } from './model';

export class IABSize extends Model {
    public serialize(): string {
        return JSON.stringify(this);
    }

    get entity() {
        return 'IAB Size';
    }
}
