import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Advertiser } from 'app/shared/models/advertiser';
import { AdvertiserRepository } from 'app/core/repositories/advertiser-repository.service';

@Injectable()
export class AdvertiserRefResolver implements Resolve<Advertiser> {
    constructor(private advertiserRepository: AdvertiserRepository) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.advertiserRepository.get(route.paramMap.get('advertiser'));
    }
}
