import { Model } from './model';

export class Deal extends Model {
    packageId: number;
    name: string;
    startDate: string;
    endDate: string;
    buyerId: string;
    buyerSeatId: string;
    floor: number;
    dataFee: number;
    dataFeePercent: number;
    demandType: string;
    dealSspFee: number;
    dspName: string;
    audiences: any[];
    buyers: any[];
    modified: string;
    created: string;
    preferred: boolean;
    maidType: string;
    domains: string[];
    domainTargetingType: string;
    dataProviderSegments: any[];
    deviceTypes: any[];
    adSlotSizes: any[];
    bswAgencies: { bidderSeats: any[] } | number[];
    keywords: string[];
    keywordTargetingType: string;

    constructor(from?: any) {
        super(from);

        if (this.preferred === null) {
            this.preferred = false;
        }
    }

    serialize(): string {
        const deal = JSON.parse(JSON.stringify(this));

        if (this.isEmptyArray(deal.domains)) {
            deal.domains = null;
            delete deal.domainTargetingType;
        }

        if (this.isEmptyArray(deal.keywords)) {
            deal.keywords = null;
            delete deal.keywordTargetingType;
        }

        if (deal.preferred === null) {
            deal.preferred = false;
        }

        return deal;
    }

    private isEmptyArray(prop) {
        return Array.isArray(prop) && prop.length === 0;
    }

    get entity() {
        return 'Deal';
    }
}
