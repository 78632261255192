import { Title } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import {PortalModule} from '@angular/cdk/portal';

import { AlertComponent } from './alert';
import { AlertnextComponent } from './alertnext';
import { AsyncTableComponent, AsyncSortDirective } from './async-table';
import { AutosizeTextareaDirective } from './textarea';
import { BlinkComponent } from './blink';
import { BreadcrumbComponent } from './breadcrumb';
import { BubbleCardComponent } from './bubble-card';
import { ButtonComponent } from './button';
import { CampaignSectionCardComponent } from './campaign-section-card';
import { CarouselComponent } from './carousel';
import { ChartsModule } from './charts';
import { CheckboxComponent } from './checkbox';
import { ConfirmDialogComponent } from './confirm-dialog';
import { ContentEditableComponent } from './content-editable';
import { ContentSliderComponent } from './content-slider';
import { CodeSnippetComponent } from './code-snippet';
import { CopyableDirective } from './copyable';
import { CurrencyInputDirective } from './currency-input';
import { DataTableComponent, VirtualRowsDirective, SortDirective } from './data-table';
import { CalendarComponent } from './calendar';
import { DateIntervalPickerComponent } from './date-interval-picker';
import { DatePickerComponent } from './date-picker';
import { DescriptionDisplayComponent } from './description-display';
import { DialogComponent } from './dialog';
import { DialogboxComponent } from './dialog-box';
import { DialogConfirmationComponent } from './confirm-dialog';
import { DialogErrorComponent } from './confirm-dialog';
import { DialogMessageComponent } from './confirm-dialog';
import { DisabledLinkComponent } from './disabled-link';
import { DropdownComponent } from './dropdown';
import { EditableLabelComponent } from './editable-label';
import { ExpandableTextComponent } from './expandable-text';
import { HighlightDirective } from './highlight';
import { ErrorDisplayComponent } from './error-display';
import { FileInputComponent } from './file-input';
import { FilterSelectComponent, OptionComponent } from './filter-select';

import {
    FilterExistComponent,
    FilterEnumComponent,
    FilterDateComponent,
    FilterFieldDirective,
    FilterIdComponent,
    FilterNumberComponent,
    FilterStringComponent,
    FiltersComponent
} from './filters';

import { GlobalNavigationComponent } from './global-navigation';
import { InsertionOrderSectionCardComponent } from './insertion-order-section-card';
import { LightboxComponent } from './lightbox';
import { LightboxnextComponent } from './lightboxnext';
import { LineItemSectionCardComponent } from './line-item-section-card';
import { ListComponent } from './list';
import { ListInputDirective } from './list-input';
import { LocalNavigationComponent } from './local-navigation';
import { LoadingComponent } from './loading';
import { MetricCardComponent } from './metric-card';
import { MetricComparisonComponent } from './metric-comparison';
import { ModalComponent } from './modal';
import { MultiSelectComponent } from './multi-select';
import { NumberInputDirective } from './number-input';
import { OpenBookComponent } from './open-book';
import { OverlayComponent } from './overlay';
import { OverlayLoadingIndicatorComponent } from './overlay-loading-indicator';
import { PaginatorComponent } from './paginator';
import { PacingPillComponent } from './pacing-pill';
import { PercentageInputDirective } from './percentage-input';
import { PopoverComponent } from './popover';
import { ProgressBarComponent } from './progress-bar';
import { ProgressiveLinkComponent } from './progressive-link';
import { RowActionsComponent } from './row-actions';
import { SearchableSelectSingleComponent } from './searchable-select-single';
import { SearchComponent } from './search';
import { SearchInputComponent } from './search-input';
import { SectionCardComponent } from './section-card';
import { SelectControlComponent } from './select-control';
import { SelectDropdownComponent, OptionGroupComponent } from './select-dropdown';
import { SelectionSummaryComponent } from './selection-summary';
import { SelectItemComponent } from './select-item';
import { StackComponent } from './stack';
import { StatusCardComponent } from './status-card';
import { StatusDialogComponent } from './status-dialog';
import { SwitchComponent } from './switch';
import { TableComponent, RowDirective, TableURLService } from './table';
import { TabsModule } from './tabs';
import { TagsInputComponent } from './tags-input';
import { TagsInputTextComponent } from './tags-input-text';
import { TaskComponent, TaskProgressComponent } from './task-progress';
import { TimePickerComponent } from './time-picker';
import { UrlValidatorDirective } from './url-validator';
import { ZebraListComponent } from './zebra-list';
import { ZeroStateComponent } from './zero-state';

import { XDropdownComponent } from './x-dropdown';
import { WizardComponent, StepComponent } from './wizard';

import { DragDropModule } from '@angular/cdk/drag-drop';

const ELEMENTS = [
    AlertComponent,
    AlertnextComponent,
    AsyncSortDirective,
    AsyncTableComponent,
    AutosizeTextareaDirective,
    BlinkComponent,
    BreadcrumbComponent,
    BubbleCardComponent,
    ButtonComponent,
    CalendarComponent,
    CampaignSectionCardComponent,
    CarouselComponent,
    CheckboxComponent,
    ConfirmDialogComponent,
    ContentEditableComponent,
    ContentSliderComponent,
    CodeSnippetComponent,
    CopyableDirective,
    CurrencyInputDirective,
    DateIntervalPickerComponent,
    DataTableComponent,
    DatePickerComponent,
    DescriptionDisplayComponent,
    DialogComponent,
    DialogConfirmationComponent,
    DialogErrorComponent,
    DialogMessageComponent,
    DialogboxComponent,
    DisabledLinkComponent,
    DropdownComponent,
    EditableLabelComponent,
    ErrorDisplayComponent,
    ExpandableTextComponent,
    FileInputComponent,
    FilterExistComponent,
    FilterEnumComponent,
    FilterDateComponent,
    FilterFieldDirective,
    FilterIdComponent,
    FilterNumberComponent,
    FilterSelectComponent,
    FilterStringComponent,
    FiltersComponent,
    GlobalNavigationComponent,
    HighlightDirective,
    InsertionOrderSectionCardComponent,
    LightboxComponent,
    LightboxnextComponent,
    LineItemSectionCardComponent,
    ListComponent,
    ListInputDirective,
    LocalNavigationComponent,
    LoadingComponent,
    MetricCardComponent,
    MetricComparisonComponent,
    ModalComponent,
    MultiSelectComponent,
    NumberInputDirective,
    OpenBookComponent,
    OptionComponent,
    OptionGroupComponent,
    OverlayComponent,
    OverlayLoadingIndicatorComponent,
    PaginatorComponent,
    PacingPillComponent,
    PercentageInputDirective,
    PopoverComponent,
    ProgressBarComponent,
    ProgressiveLinkComponent,
    RowActionsComponent,
    RowDirective,
    SearchComponent,
    SearchInputComponent,
    SearchableSelectSingleComponent,
    SectionCardComponent,
    SelectControlComponent,
    SelectDropdownComponent,
    SelectItemComponent,
    SelectionSummaryComponent,
    SortDirective,
    StackComponent,
    StatusCardComponent,
    StatusDialogComponent,
    StepComponent,
    SwitchComponent,
    TableComponent,
    TagsInputComponent,
    TagsInputTextComponent,
    TaskComponent,
    TaskProgressComponent,
    TimePickerComponent,
    UrlValidatorDirective,
    VirtualRowsDirective,
    WizardComponent,
    XDropdownComponent,
    ZebraListComponent,
    ZeroStateComponent,
    TableCellDirective,
    TableColumnDirective,
    TableDownloadButtonComponent,
    TableHeaderDirective,
    TableSortDirective,
    ConfigureColumnsComponent
];

import { ConfigureColumnsComponent } from './table/configure-columns/configure-columns.component';
import { TableCellDirective } from './table/table-cell.directive';
import { TableColumnDirective } from './table/table-column.directive';
import { TableDownloadButtonComponent } from './table';
import { TableHeaderDirective } from './table/table-header.directive';
import { TableSortDirective } from './table/table-sort.directive';

import {
    AdSlotSizePipe,
    BigNumberPipe,
    BudgetPipe,
    CapitalizeFirstLetterPipe,
    DashToSpacePipe,
    DisabledPipe,
    EmptyPipe,
    FilterByPipe,
    FilterPipe,
    GerundPipe,
    GroupByPipe,
    HighlightPipe,
    NumberToPercentagePipe,
    OnOffPipe,
    PluralizePipe,
    SortPipe,
    WordspacePipe,
    YesNoPipe,
    YieldManagementRankPipe,
    YieldManagementRankDescriptionPipe,
    SnakeToCamelCasePipe,
    CamelToSnakeCasePipe
} from './pipes';
import { PipesModules } from 'app/shared/pipes';
import { DraggableListComponent } from 'app/shared/elements/draggable-list/draggable-list.component';
import { DirectivesModule } from '../directives';
import { PortalComponent } from 'app/shared/components/portal'

const PIPES = [
    AdSlotSizePipe,
    BigNumberPipe,
    BudgetPipe,
    CapitalizeFirstLetterPipe,
    DashToSpacePipe,
    DisabledPipe,
    EmptyPipe,
    FilterByPipe,
    FilterPipe,
    GerundPipe,
    GroupByPipe,
    HighlightPipe,
    NumberToPercentagePipe,
    OnOffPipe,
    PluralizePipe,
    SortPipe,
    WordspacePipe,
    YesNoPipe,
    YieldManagementRankPipe,
    YieldManagementRankDescriptionPipe,
    SnakeToCamelCasePipe,
    CamelToSnakeCasePipe
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TabsModule,
        ChartsModule,
        DragDropModule,
        DirectivesModule,
        PortalModule,
        ReactiveFormsModule,
        PipesModules
    ],
    declarations: [...ELEMENTS, ...PIPES, DraggableListComponent, PortalComponent],
    providers: [Title, TableURLService, SnakeToCamelCasePipe, CamelToSnakeCasePipe],
    exports: [...ELEMENTS, ...PIPES, FormsModule, CommonModule, TabsModule, ChartsModule, ReactiveFormsModule, DraggableListComponent],
    entryComponents: [AlertnextComponent]
})
export class ElementsModule { }
