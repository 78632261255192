import { Model } from './model';
import { DemandAssetLinkType, DemandAssetType } from 'app/shared/models/native-demand/demand-asset';
import { Blueprint } from './blueprint';
import { LineItem } from './line-item';
import userInterfaceColors from 'src/data/colors/user-interface.json';

const dataPattern =
/^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;

export class Native {
    static WIDTH = 975;
    static HEIGHT = 250;
    static MOBILE_DISPLAY = 'mobile';
    static DESKTOP_DISPLAY = 'desktop';
    static BLUEPRINT_ID = 1;
}

export enum TypeOfCreative {
    Native,
    NativeDirectSold,
    NativeDirectSoldTrafficking,
    NativeExchange
}

export type CreativeStatus = 'active' | 'rejected' | 'pending';
export const creativeStatusColors: Record<CreativeStatus | 'default', string> = {
    active: userInterfaceColors['brand-green'],
    rejected: userInterfaceColors['brand-red'],
    default: userInterfaceColors['status-default'],
    pending: userInterfaceColors['status-default'],
};

export const CREATIVE_MAPPING_ERROR_ID = 'E1628';

export class Creative extends Model {

    advertiser: string;
    advertiserName: string;
    advertiserRefId: number;
    clickUrl: string;
    created: string;
    dataUrl: string;
    externalId: string;
    file: string;
    fileName: string;
    height: number;
    mediaUrl: string;
    urlCachedCopy: string;
    name: string;
    refId: number;
    staqId: string;
    status: CreativeStatus;
    strategyId: string;
    thirdPartyTag: string;
    type: string;
    urlTracking1: string;
    urlTracking2: string;
    width: number;
    modified: string;
    isModified: boolean;
    headline: string;
    body: string;
    callToAction: string;
    sponsoredBy: string;
    thumbnailUrl: string;
    isNative: boolean;
    isDirectSoldTrafficking: boolean;
    blueprintId: number;
    blueprintWidth: number;
    blueprintName: string;
    blueprint: Blueprint;
    logoUrl: string;
    native: DemandAssetType;
    isDirectSold: boolean; // This is only being used in the frontend for copying a direct sold creative.
    assets: {
        title: DemandAssetLinkType[],
        image: DemandAssetLinkType[],
        text: DemandAssetLinkType[]
    };
    assetRestrictions: any;
    sensitiveCategories: number[];

    // Non-LSD fields
    lineItemsObj?: LineItem[];
    availableActions?: any;
    size?: any;
    aspectRatio?: any;
    eligibleAdSlots?: any;
    eligibleBlueprints?: any;
    previewBlueprint?: any;

    desktopMainImageWidth?: number;
    desktopMainImageHeight?: number;

    creativeMappingStrategies?: string[];
    updateCreativeMapping?: boolean;
    nativeAssets?: any;

    thumbnailClass?:string;

    private static smallThumbnailFactor = 0.08;
    private static largeThumbnailFactor = 0.6;
    private static maxSmallThumbnailSize = 90;
    private static maxLargeThumbnailSize = 300;
    private static previewFactor = 0.25;
    private static scalingExponent = 0.1;

    public static readonly nativeTypes = ['text_ad', 'in_feed', 'product_listing', 'recommendation_widget'];

    private getMaxSize(large: boolean): number {
        return large ? Creative.maxLargeThumbnailSize : Creative.maxSmallThumbnailSize;
    }

    private applyScaling(size: number, large: boolean): number {
        const maxSize = this.getMaxSize(large);

        if (size > maxSize) {
            return Math.floor(maxSize * Math.pow(size / maxSize, Creative.scalingExponent));
        }
        return size;
    }

    private thumbnailFactor(large: boolean): number {
        return large ? Creative.largeThumbnailFactor : Creative.smallThumbnailFactor;
    }

    thumbnailWidth(large: boolean, customWidth?: number): number {
        const width = customWidth ? customWidth : this.width;
        let scaledWidth = Math.floor(width * this.thumbnailFactor(large));

        return this.applyScaling(scaledWidth, large);
    }

    thumbnailHeight(large: boolean, customHeight?: number): number {
        const height = customHeight ? customHeight : this.height;
        let scaledHeight = Math.floor(height * this.thumbnailFactor(large));

        return this.applyScaling(scaledHeight, large);
    }

    get cachedMediaUrl(): string {
        if (this.isNative) {
            return this.urlCachedCopy;
        }
        else return this.mediaUrl;
    }

    previewWidth(display: string = '', blueprint: Blueprint = this.blueprint): number {
        // default preview is 25% of original image size
        const defaultPreviewFactor = 0.25;
        // if image is not proportional like logo, preview is 70% of original image size
        const logoPreviewFactor = 0.7;

        // Reset previewFactor if not yet reset
        if (Creative.previewFactor !== defaultPreviewFactor) {
          Creative.previewFactor = defaultPreviewFactor;
        }

        if (blueprint) {
          const { width, mobileWidth, maxHeight, mobileMaxHeight } = blueprint;

          // Determine if it's suitable for resizing based on height/width ratio
          const isSuitableForResizing = (height: number, width: number) => height / width > 0.1;

          // Adjust previewFactor based on suitability for resizing
          const adjustPreviewFactor = (height: number, width: number) => {
            if (isSuitableForResizing(height, width)) {
              Creative.previewFactor = defaultPreviewFactor;
            } else {
              Creative.previewFactor = logoPreviewFactor;
            }
          };

          if (display === 'mobile' && mobileWidth && mobileMaxHeight) {
            adjustPreviewFactor(mobileMaxHeight, mobileWidth);
            return Math.floor(mobileWidth * Creative.previewFactor);
          }

          if (maxHeight && width) {
            adjustPreviewFactor(maxHeight, width);
            return Math.floor(width * Creative.previewFactor);
          }
        }

        // Return default width if no blueprint provided
        return this.width;
    }

    previewHeight(display: string = '', blueprint: Blueprint = this.blueprint): number {
        // default preview is 25% of original image size
        const defaultPreviewFactor = 0.25;
        // if image is not proportional like logo, preview is 70% of original image size
        const logoPreviewFactor = 0.7;

        // Reset previewFactor if not yet reset. This ensures if previewFactor set to Logo its reset back afterwards
        if (Creative.previewFactor !== defaultPreviewFactor) {
          Creative.previewFactor = defaultPreviewFactor;
        }

        if (blueprint) {
          const { width, mobileWidth, maxHeight, mobileMaxHeight } = blueprint;

          // Determine if it's suitable for resizing based on height/width ratio
          const isSuitableForResizing = (height: number, width: number) => height / width > 0.1;

          // Adjust previewFactor based on suitability for resizing
          const adjustPreviewFactor = (height: number, width: number) => {
            if (isSuitableForResizing(height, width)) {
              Creative.previewFactor = defaultPreviewFactor;
            } else {
              Creative.previewFactor = logoPreviewFactor;
            }
          };

          if (display === 'mobile' && mobileWidth && mobileMaxHeight) {
            adjustPreviewFactor(mobileMaxHeight, mobileWidth);
            return Math.floor(mobileMaxHeight * Creative.previewFactor);
          }

          if (maxHeight && width) {
            adjustPreviewFactor(maxHeight, width);
            return Math.floor(maxHeight * Creative.previewFactor);
          }
        }

        // Return default height if no blueprint provided
        return this.height;
    }

    get mediaUrlScrubMacros(): string {
        const sub = /\$\{.*\}/;
        return this.mediaUrl ? this.mediaUrl.replace(sub, 'liveintentdefaultvalue') : null;
    }

    isThirdPartyTag(): boolean {
        if (typeof this.mediaUrl === 'string' && this.mediaUrl.length > 0) {
            if (this.mediaUrl.match(dataPattern)
                || ['liveintenteng', 'c.licasd.com'].indexOf(this.mediaUrl) > -1
            ) {
                return false;
            }
        }

        return true;
    }

    isTrueNative(): boolean {
        return this.isNative && !this.blueprintId;
    }

    serialize(): string {
        const creative = this.clone(Creative);

        delete creative.thirdPartyTag;
        delete creative.dataUrl;
        delete creative.native;
        delete creative._isClone;
        delete creative.isModified;
        delete creative.isValid;
        delete creative.assetRestrictions;

        if (typeof creative.externalId === 'string' && creative.externalId.length === 0) {
            creative.externalId = null;
        }

        if (!creative.urlTracking1) {
            creative.urlTracking1 = null;
        }

        if (!creative.urlTracking2) {
            creative.urlTracking2 = null;
        }

        if (typeof creative.staqId === 'string' && creative.staqId.length === 0) {
            creative.staqId = null;
        }

        if (!creative.isNative) {
            delete creative.blueprintId;
            delete creative.blueprintName;
        }

        if (creative.isNative) {
            for (const asset of ['headline', 'body', 'callToAction', 'sponsoredBy', 'thumbnailUrl', 'logoUrl']) {
                if (!creative[asset]) {
                    creative[asset] = null;
                }
            }
        }

        const creativeUrl = creative.mediaUrl.split(',');
        creative.file = creativeUrl.length > 1 ? creativeUrl[1] : creative.mediaUrl;

        delete creative.mediaUrl;
        delete creative.eligibleAdSlots;

        return JSON.stringify(creative);
    }

    get entity() {
        return 'Creative';
    }

    getImageTypeFromSrc(): string {
        // Extract the file extension from the URL
        const parts = this.mediaUrl.split('.');
        const extension = parts.length > 0 ? parts[parts.length - 1].toLowerCase() : '';

        // Map common image file extensions to their types
        const imageTypes: { [key: string]: string } = {
            'jpg': 'JPEG',
            'jpeg': 'JPEG',
            'png': 'PNG',
            'gif': 'GIF',
            'bmp': 'BMP',
            'webp': 'WEBP',
            'svg': 'SVG',
        };

        // Lookup the type based on the file extension
        if (extension && imageTypes.hasOwnProperty(extension)) {
            return imageTypes[extension];
        } else {
            return 'Unknown';
        }
    }

    get typeOfCreative() {
        if (this.isNative && this.blueprintId && this.native) {
            return TypeOfCreative.NativeDirectSold;
        } else if (this.isNative && this.native && this.isDirectSoldTrafficking) {
            return TypeOfCreative.NativeDirectSoldTrafficking;
        } else if (this.isNative && this.native) {
            return TypeOfCreative.NativeExchange;
        } else if (this.isNative && this.blueprintId) {
            return TypeOfCreative.Native;
        }
    }

    get isNativeCreative(): boolean {
        return [
          TypeOfCreative.NativeExchange,
          TypeOfCreative.NativeDirectSold,
          TypeOfCreative.NativeDirectSoldTrafficking
        ].includes(this.typeOfCreative);
    }
}

export class AssetField extends Model {
    sponsoredBy: AssetFieldModel;
    headline: AssetFieldModel;
    description: AssetFieldModel;
    callToAction: AssetFieldModel;
    body: AssetFieldModel;

    public serialize(): string {

        delete this._isClone;

        return JSON.stringify(this);
    }

    get entity() {
        return 'AssetFields';
    }
}

interface AssetFieldModel {
    charRemaining: number;
    charCount: number;
    isFocused: boolean;
}

export const AssetFieldProperties = [{
    label: 'Sponsored By',
    modelKey: 'sponsoredBy',
    placeholder: 'Sponsored By',
    isFocused: false,
    charRemaining: 0,
    charCount: 0,
    errorMessage: 'Characters are over recommended limit',
    inputId: 'ad-sponsored-by-input',
    maxChar: 30,
    macro: 'sb'
}, {
    label: 'Body',
    modelKey: 'body',
    placeholder: 'Text body',
    isFocused: false,
    charRemaining: 0,
    charCount: 0,
    errorMessage: 'Characters are over recommended limit',
    inputId: 'ad-body-input',
    maxChar: 90,
    macro: 'd'
}, {
    label: 'Headline',
    modelKey: 'headline',
    placeholder: 'Type your headline',
    isFocused: false,
    charRemaining: 0,
    charCount: 0,
    errorMessage: 'Characters are over recommended limit',
    inputId: 'ad-headline-input',
    maxChar: 30,
    macro: 't'
}, {
    label: 'Call to Action',
    modelKey: 'callToAction',
    placeholder: 'Click me',
    isFocused: false,
    charRemaining: 0,
    charCount: 0,
    errorMessage: 'Characters are over recommended limit',
    inputId: 'ad-click-me-input',
    maxChar: 15,
    macro: 'cta'
}];


export class CreativeMeta extends Model {
    id: string;
    version: number;
    creative_external_id: number;
    content_hash: string;
    bidder_id: string;
    image_url: string;
    click_url: string;
    attributes: object;
    created_by: string;
    updated_by: string;
    created_at: Date;
    updated_at: Date;

    public serialize(): string {

        delete this._isClone;

        return JSON.stringify(this);
    }

    get entity() {
        return 'CreativeMeta';
    }
}

export interface CreativeSizeValidation {
    id: string;
    isValid: boolean;
}
