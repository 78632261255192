import { LineItem, Campaign, StrategyCard } from 'app/shared/models';

export class BudgetHelper {
    private lineItem: LineItem;
    private campaign: Campaign = {} as any;

    constructor(lineItem: LineItem, campaign: Campaign) {
        this.lineItem = lineItem;
        this.campaign = campaign || this.campaign;
    }

    getLifetimeBudget() {
        const actual = (this.lineItem.spend || 0) + this.lineItem[this.dailyField] * this.remainingNumberOfDaysInFlight;
        return this.ensurePositive(this.round(actual));
    }

    getDailyBudget() {
        if (this.lineItem.dailyBudget) {
            return this.lineItem.dailyBudget;
        }

        const actual = ((this.lineItem.budget || 0) - (this.lineItem.spend || 0)) / this.remainingNumberOfDaysInFlight;
        return this.ensurePositive(this.round(actual));
    }

    round(n: number) {
        if (isNaN(n)) {
            return null;
        }

        return Math.round(n * 100) / 100;
    }

    private ensurePositive(value: number) {
        // Budget or Daily budget may not be less than $1. In a case of line item overspend,
        // the daily budget would be caclulated as a negative number. Resaving
        // the line item would readjust and increase the overall budget.
        return Math.max(value, 1);
    }

    get remainingNumberOfDaysInFlight() {
        if (this.lineItem.isNew() || !this.lineItem.effectiveStartDate || !this.lineItem.effectiveEndDate) {
            this.lineItem.effectiveStartDate = this.campaign.effectiveStartDate;
            this.lineItem.effectiveEndDate = this.campaign.effectiveEndDate;
        }

        return Math.max(this.lineItem.remainingNumberOfDaysInFlight, 1);
    }

    get hasUnlimitedBudget() {
        return this.campaign.isDirectSold && this.campaign.isGuaranteed && this.campaign.isUnlimitedBudget;
    }

    get hasUnlimitedCampaignBudget() {
        return this.campaign.isUnlimitedBudget;
    }

    get usesImpressionsBudget() {
        return this.campaign.budgetType === 'impressions' || this.campaign.budgetType === 'adjusted_impressions';
    }

    get usesCalculatedDailyBudget() {
        return this.usesDailyBudget || this.usesDailyCap;
    }

    get usesOptionalDailyCap() {
        return this.campaign.supportsDailyCap && !this.isDailyCapRequired && !this.hasUnlimitedBudget;
    }

    get isAboveDailyAverage() {
        return this.lineItem[this.dailyField] >= 10000;
    }

    get usesDailyCap() {
        return this.isDailyCapRequired;
    }

    get usesDailyBudget() {
        return this.campaign.isDsp && this.campaign.isExchange && !this.isDailyCapRequired;
    }

    get isDailyCapRequired() {
        return this.campaign.buyingStrategyId === StrategyCard.PERFORMANCE_MAXIMIZE_CONVERSIONS_DAILY_CAP;
    }

    get dailyField() {
        return this.usesDailyCap ? 'dailyCap' : 'dailyBudget';
    }
}
