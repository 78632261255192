import * as tslib_1 from "tslib";
import { Model } from './model';
import { clone } from 'app/core/utils';
import * as configs from 'app/platform/measurement/live-connect-manager/live-connect-setup/event-configs';
var Rule = /** @class */ (function (_super) {
    tslib_1.__extends(Rule, _super);
    function Rule(from) {
        var _this = _super.call(this, from) || this;
        _this.configuration = _this.computeConfiguration();
        return _this;
    }
    Rule.prototype.serialize = function () {
        return JSON.stringify(this);
    };
    Object.defineProperty(Rule.prototype, "entity", {
        get: function () {
            return 'Rule';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Rule.prototype, "isUrlBased", {
        get: function () {
            return !!this.findValueByPath('$.url');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Rule.prototype, "isEventBased", {
        get: function () {
            return !this.isUrlBased;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Rule.prototype, "eventNameRegex", {
        get: function () {
            return this._eventNameRegex || this.findValueByPath('$.event') || '';
        },
        set: function (value) {
            this._eventNameRegex = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Rule.prototype, "pageRegex", {
        get: function () {
            return this.findValueByPath('$.url');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Rule.prototype, "termRegex", {
        get: function () {
            return this._termRegex || this.findValueByPath('$.name') || '';
        },
        set: function (value) {
            this._termRegex = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Rule.prototype, "title", {
        get: function () {
            return this.configuration.eventName;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * This is written based on the research done here:
     * https://liveintent.atlassian.net/browse/MT-5182?focusedCommentId=145373
     */
    Rule.prototype.findValueByPath = function (targetPath) {
        var data = this.body;
        if (!data)
            return undefined;
        if (data['And'] && Array.isArray(data['And'].conditions)) {
            for (var _i = 0, _a = data['And'].conditions; _i < _a.length; _i++) {
                var condition = _a[_i];
                // Because there can only ever be one Match path with the `targetPath`, we can return early
                if (condition.Matches && condition.Matches.path === targetPath) {
                    return condition.Matches.value;
                }
            }
        }
        else if (data['Matches'] && data['Matches'].path === targetPath) {
            return data['Matches'].value;
        }
        // Return undefined if no matching path is found
        return undefined;
    };
    Rule.prototype.computeConfiguration = function () {
        var _this = this;
        var customConversion = clone(configs.customConversion);
        var matchedConfig = Object.values(configs)
            .filter(function (config) { return config.eventType === _this.eventNameRegex; })
            .find(function (config) { return config.props.find(function (prop) { return prop.id === 'name' && prop.value === _this.termRegex; }); });
        if (!matchedConfig) {
            // replace custom conversion name with actual term value
            customConversion.props.forEach(function (prop) {
                if (prop.id === 'name') {
                    prop.value = _this.termRegex;
                }
            });
        }
        return matchedConfig || customConversion;
    };
    return Rule;
}(Model));
export { Rule };
