import {
    Attribute,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnInit,
    Optional,
    Output,
    QueryList,
    ViewChild
} from '@angular/core';

import { BulkEditDirective } from './bulk-edit.directive';
import { BulkEditGroupComponent } from './bulk-edit-group';
import { LightboxComponent } from 'app/shared/elements/lightbox';
import { Model } from 'app/shared/models';
import { IdService } from 'app/core/id.service';

@Component({
    selector: 'bulk-edit-lightbox',
    templateUrl: './bulk-edit-lightbox.component.html'
})
export class BulkEditLightboxComponent implements OnInit {
    @ContentChildren(BulkEditGroupComponent) groups: QueryList<BulkEditGroupComponent>;
    @ViewChild(LightboxComponent, { static: true }) lightbox: LightboxComponent;
    @Output('submit') _submit = new EventEmitter<{ changed: Model[], originals: Model[], label: string }>();
    entityName: string;
    entities: Model[] = [];
    label: string;
    progress: number = null;
    action: string = 'save';
    customLoadingMessage: string = null;
    private originals: Model[] = [];

    constructor(
        @Attribute('entity') entity: string,
        private cdr: ChangeDetectorRef,
        public id: IdService
    )
    {
        this.entityName = entity;
    }

    ngOnInit() {
        this.lightbox.closedState.subscribe(() => {
            this.progress = null;
            this.children.forEach(child => child.reset());
        });
    }

    open(entities: Model[], field: string = null, action: string = 'save') {
        this.action = action;

        let toActivate: BulkEditDirective = this.children[0];

        if (field !== null && Array.isArray(entities)) {
            toActivate = this.children.find(child => child.field === field);
        }

        this.originals = entities;
        this.entities = entities.map(entity => entity.clone());
        this.activate(toActivate, true);

        this.lightbox.open();
    }

    close() {
        this.lightbox.close();
    }

    activate(child: BulkEditDirective, doReset = false) {
        const index = this.children.indexOf(child);

        this.children.forEach((c, i) => {
            if (doReset) {
                c.reset();
            }

            if (index === i) {
                c.activate(this.entities, this.entityName);
                this.label = c.label;
            } else {
                c.deactivate();
            }
        });
    }

    submit() {
        this._submit.emit({
            changed: this.entities,
            originals: this.originals,
            label: this.label
        });
    }

    initiate() {
        this.progress = 0;
    }

    postProgress() {
        this.progress++;
        this.cdr.markForCheck();
    }

    get isLoading() {
        return Array.isArray(this.entities) && this.progress !== null
            && this.entities.length !== this.progress;
    }

    get activeChild() {
        return this.children.filter(child => child.isActive)[0];
    }

    private get children(): BulkEditDirective[] {
        return this.groups.reduce((acc, cur) => {
            acc = acc.concat(cur.children.toArray());
            return acc;
        }, []);
    }

    resetForm() {
        this.progress = null;
        this.children.forEach(child => {
            child.reset();
            child.resetOriginals(this.originals);
        });
    }
}
