import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Package } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
var PackageRepository = /** @class */ (function (_super) {
    tslib_1.__extends(PackageRepository, _super);
    function PackageRepository(http) {
        return _super.call(this, http, '/package', Package) || this;
    }
    PackageRepository.prototype.save = function (pkg) {
        var clone = pkg.clone(Package);
        if (pkg.id) {
            clone.id = pkg.id.toString();
        }
        return _super.prototype.save.call(this, clone);
    };
    return PackageRepository;
}(BackendRepository));
export { PackageRepository };
