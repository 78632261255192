import { Directive, forwardRef, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[validate-max][formControlName],[validate-max][formControl],[validate-max][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => MaxValidator), multi: true }
    ]
})
export class MaxValidator implements Validator {
    @Input('validate-max') max: number;

    validate(c: AbstractControl): { [key: string]: any } {
        const myValue = c.value;

        if (myValue === null || myValue === undefined || this.max === null || this.max === undefined) {
            return null;
        }

        return this.max >= myValue ? null : { 'max' : {
            valid: false,
            message: `The value entered must not exceed ${this.max}.`
        }};
    }
}
