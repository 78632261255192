import { NgForm } from '@angular/forms';
import { AdvertiserRepository, AgencyRepository, CategoryRepository, MediaGroupRepository, PublisherRepository, UserRepository } from 'app/core/repositories';
import { Advertiser } from 'app/shared/models';
import { AdvertiserTier } from 'app/shared/models/advertiser';
import { AuthorizationService, NotificationsService } from 'app/core';
import { Router, ActivatedRoute } from '@angular/router';
import { combineLatest, forkJoin } from 'rxjs';
import { ModalComponent } from 'app/shared/elements/modal/modal.component';
import { ButtonComponent } from 'app/shared/elements/button';
import { OverlayComponent } from 'app/shared/elements/overlay';
import { ErrorHelper } from 'app/core/errors/error-helper';
import { ConfirmDialogComponent } from 'app/shared/elements/confirm-dialog';
import { InventoryTargetingComponent } from '../inventory-targeting';
import { DomainTargetingComponent } from '../domain-targeting';
import { map } from 'rxjs/operators';
import { IdService } from 'app/core/id.service';
import { removeCommonDelimiters } from "app/core/utils";
var TYPE_MEDIA_GROUP = 'Media Group';
var AdvertiserModalComponent = /** @class */ (function () {
    function AdvertiserModalComponent(router, activatedRoute, advertiserRepository, mediaGroupRepository, agencyRepository, publisherRepository, notifications, authorization, categoryRepository, userRepository, id) {
        var _this = this;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.advertiserRepository = advertiserRepository;
        this.mediaGroupRepository = mediaGroupRepository;
        this.agencyRepository = agencyRepository;
        this.publisherRepository = publisherRepository;
        this.notifications = notifications;
        this.authorization = authorization;
        this.categoryRepository = categoryRepository;
        this.userRepository = userRepository;
        this.id = id;
        this._showAdvertiserLabel = false;
        this._showDomainBlocklist = false;
        this._showWhiteBlackList = false;
        this.accountsOptions = [];
        this.userOptions = [];
        this.inventory = [];
        this.requiredErrorText = 'Oops! This is a required field.';
        this.isCreating = true;
        this.matchingAdvertisers = [];
        this.errorHelper = new ErrorHelper();
        this.cfmErrorHelper = new ErrorHelper();
        this.isDemandFeeRequired = false;
        this.advertiserTierOptions = [];
        this.removeCommonDelimiters = removeCommonDelimiters;
        // Default accounts for selection of media group or publisher
        this.getAccounts();
        this.categories = this.categoryRepository.getPrimaryCategories()
            .pipe(function (categories) { return _this.categoryRepository.convertCategoriesToOptions(categories); });
    }
    AdvertiserModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.params.subscribe(function (params) {
            if (params.redirect) {
                _this.redirectUrl = atob(params.redirect);
            }
            else {
                _this.redirectUrl = _this.redirect;
            }
        });
        this.advertiser = new Advertiser();
        this.advertiser.owner = {
            refId: null,
            name: null,
            type: null,
            id: null
        };
        this.advertiser.contract = {
            dspFee: null
        };
        this.activatedRoute.data.subscribe(function (resp) {
            if (resp.advertiser) {
                _this.advertiser = new Advertiser(resp.advertiser);
            }
        });
    };
    AdvertiserModalComponent.prototype.onAdvertiserNameChange = function (value) {
        var _this = this;
        var advertiserName = value ? value.trim() : '';
        if (advertiserName) {
            var query = {
                conditions: [
                    {
                        mode: 'and',
                        operator: 'eq',
                        field: 'name',
                        value: advertiserName
                    }
                ],
                orderBy: 'created',
                sort: 'DESC'
            };
            this.advertiserRepository.search(query).subscribe(function (advertisers) {
                _this.matchingAdvertisers = advertisers;
            });
        }
        else {
            this.matchingAdvertisers = [];
        }
    };
    AdvertiserModalComponent.prototype.submit = function () {
        var _this = this;
        if (typeof this.noteId === 'number') {
            this.notifications.remove(this.noteId);
        }
        // Remove dspFee if null so that LSD returns clean responses
        if (this.advertiser.contract.dspFee == null) {
            delete this.advertiser.contract.dspFee;
        }
        this.overlay.show();
        this.saveButton.load();
        this.advertiserRepository.confirm(this.advertiser).subscribe(function (advertiser) { return _this.saveAdvertiser(); }, function (err) {
            if (_this.authorization.isInternalUser) {
                _this.cfmErrorHelper.loadBackEndErrors(err.error.errors);
                _this.confirmDialog.open();
            }
            else {
                _this.errorHelper.loadBackEndErrors(err.error.errors);
                var errorList = _this.errorHelper.errors;
                _this.displayErrorMessage(errorList);
            }
            _this.overlay.hide();
            _this.saveButton.reset();
        });
    };
    AdvertiserModalComponent.prototype.saveAdvertiser = function () {
        var _this = this;
        this.saveButton.load();
        this.overlay.show();
        this.advertiserRepository.save(this.advertiser).subscribe(function (advertiser) {
            _this.saveButton.reset();
            _this.overlay.hide();
            _this.didSaveAdvertiser(advertiser);
        }, function (err) {
            _this.saveButton.reset();
            _this.overlay.hide();
            _this.handleSaveErrors(err.error.errors);
        });
    };
    AdvertiserModalComponent.prototype.close = function () {
        this.reset();
        if (this.redirectUrl) {
            this.router.navigate([this.redirectUrl]);
        }
    };
    AdvertiserModalComponent.prototype.didSaveAdvertiser = function (advertiser) {
        var action = this.isCreating ? 'created' : 'updated';
        if (this.isCreating) {
            this.router.navigate(['/campaign-manager/advertisers', advertiser.refId]);
        }
        else if (this.redirectUrl) {
            this.router.navigate([this.redirectUrl]);
        }
        else {
            this.modal.close();
        }
        this.notifications.success("You've successfully " + action + " <strong>" + advertiser.name + "</strong>.", "Woo hoo");
        this.overlay.hide();
        this.saveButton.reset();
    };
    AdvertiserModalComponent.prototype.handleSaveErrors = function (errors) {
        this.overlay.hide();
        this.saveButton.reset();
        this.errorHelper.resetAllErrors();
        this.errorHelper.loadBackEndErrors(errors);
        var errorList = this.errorHelper.errors;
        this.displayErrorMessage(errorList);
    };
    AdvertiserModalComponent.prototype.createNewAdvertiser = function (account) {
        this.isCreating = true;
        this.account = account;
        this.advertiser = new Advertiser();
        if (account) {
            this.advertiser.owner.id = account.id;
        }
        this.advertiser.advertiserTier = AdvertiserTier.Other;
        this.init();
        this.initAdvertiser();
        this.initAdvertiserTiers();
        this.modal.open();
    };
    AdvertiserModalComponent.prototype.editAdvertiser = function (advertiser) {
        this.isCreating = false;
        this.advertiser = advertiser;
        this.owner = advertiser.owner;
        this.account = this.owner;
        // If advertiser had a dspFee already, lock dspFee from being empty
        if (this.advertiser.contract && this.advertiser.contract.dspFee !== null) {
            this.isDemandFeeRequired = true;
        }
        this.prepareEdit();
        this.init();
        this.initAdvertiserTiers();
        this.modal.open();
    };
    AdvertiserModalComponent.prototype.init = function () {
        var _this = this;
        // Don't run a search for account admins and managers if logged in as an external user
        if (this.authorization.isInternalUser) {
            combineLatest(this.userRepository.getAdmins(), this.userRepository.getManagers())
                .pipe(map(function (_a) {
                var admins = _a[0], managers = _a[1];
                var users = admins.concat(managers);
                _this.userOptions = users.map(function (user) { return _this.convertUserToOption(user); });
            })).subscribe();
        }
        if (Array.isArray(this.advertiser.targetedPublishers) && this.advertiser.targetedPublishers.length > 0) {
            this.inventory = this.advertiser.targetedPublishers.map(function (publisher) {
                return {
                    id: publisher,
                    type: 'Publisher'
                };
            });
        }
    };
    AdvertiserModalComponent.prototype.initAdvertiserTiers = function () {
        var _this = this;
        this.advertiserTierOptions = Object.entries(AdvertiserTier).map(function (_a) {
            var key = _a[0], value = _a[1];
            return ({
                key: key,
                value: value,
                label: _this.removeCommonDelimiters(key) + " Account"
            });
        });
    };
    AdvertiserModalComponent.prototype.initAdvertiser = function () {
        this.advertiser.category = null;
        this.advertiser.domain = '';
        this.advertiser.name = '';
        this.advertiser.owner = { 'type': TYPE_MEDIA_GROUP, 'id': '', 'refId': null, 'name': '' };
        this.advertiser.targetingType = 'include';
        this.advertiser.targetedPublishers = this.inventory;
        this.advertiser.targetedDomains = [];
        this.isDemandFeeRequired = true;
        this.advertiser.contract = {
            dspFee: 25
        };
        if (this.account) {
            this.advertiser.owner.id = this.account.id;
            this.advertiser.owner.type = this.account.entity;
        }
    };
    AdvertiserModalComponent.prototype.prepareEdit = function () {
        if (typeof this.advertiser.externalId === 'string') {
            this.showAdvertiserLabel = true;
        }
        if (Array.isArray(this.advertiser.targetedDomains) && this.advertiser.targetedDomains.length > 0) {
            this.showDomainBlocklist = true;
        }
        if (Array.isArray(this.advertiser.targetedPublishers) && this.advertiser.targetedPublishers.length > 0) {
            this.showWhiteBlackList = true;
        }
        if (!this.advertiser.targetedPublishers) {
            this.advertiser.targetingType = 'include';
        }
    };
    Object.defineProperty(AdvertiserModalComponent.prototype, "advertiserOwner", {
        get: function () {
            return this.advertiser.owner.id;
        },
        set: function (value) {
            if (value) {
                this.advertiser.owner.id = value;
                var account = this.accountsOptions.find(function (option) { return option.data.id === value; });
                this.advertiser.owner.type = account.data.entity;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdvertiserModalComponent.prototype, "selectedCategories", {
        get: function () {
            return this.advertiser.categories;
        },
        set: function (value) {
            this.advertiser.categories = value;
        },
        enumerable: true,
        configurable: true
    });
    AdvertiserModalComponent.prototype.advertiserOwnerInputChange = function (searchTerm) {
        if (searchTerm.length < 1) {
            return;
        }
        var query = this.constructQuery(searchTerm);
        this.getAccounts(query);
    };
    AdvertiserModalComponent.prototype.getAccounts = function (query) {
        var _this = this;
        var hasAgencyAccess = this.authorization.isAgencyDemand || this.authorization.isAgencyManager;
        var hasMediaGroupAccess = this.authorization.isMediaGroupDemand || this.authorization.isMediaGroupManager;
        if (this.authorization.isInternalUser || (hasAgencyAccess && hasMediaGroupAccess)) {
            forkJoin(this.agencyRepository.search(query), this.publisherRepository.search(query), this.mediaGroupRepository.search(query)).subscribe(function (_a) {
                var agencies = _a[0], publishers = _a[1], mediaGroups = _a[2];
                var agenciesOptions = agencies.map(_this.convertAccountToOption);
                var mediaGroupOptions = mediaGroups.map(_this.convertAccountToOption);
                var publisherOptions = publishers.map(_this.convertAccountToOption);
                _this.accountsOptions = agenciesOptions.concat(mediaGroupOptions, publisherOptions);
            });
        }
        else {
            if (hasAgencyAccess) {
                this.agencyRepository.search(query).subscribe(function (agencies) {
                    _this.accountsOptions = agencies.map(_this.convertAccountToOption);
                });
            }
            else {
                forkJoin(this.publisherRepository.search(query), this.mediaGroupRepository.search(query)).subscribe(function (_a) {
                    var publishers = _a[0], mediaGroups = _a[1];
                    var mediaGroupOptions = mediaGroups.map(_this.convertAccountToOption);
                    var publisherOptions = publishers.map(_this.convertAccountToOption);
                    _this.accountsOptions = mediaGroupOptions.concat(publisherOptions);
                });
            }
        }
    };
    AdvertiserModalComponent.prototype.convertCategoryToOption = function (category) {
        return {
            key: category.id,
            value: category.id,
            label: category.iab + ': ' + category.name
        };
    };
    AdvertiserModalComponent.prototype.convertAccountToOption = function (account) {
        return {
            key: account.refId,
            value: account.id,
            label: '(ID: ' + account.refId + ') ' + account.name,
            data: account
        };
    };
    AdvertiserModalComponent.prototype.convertUserToOption = function (user) {
        return {
            key: user.hashId,
            value: user.hashId,
            label: user.name
        };
    };
    Object.defineProperty(AdvertiserModalComponent.prototype, "isAccountEditable", {
        get: function () {
            // Editable only if creating new and has no account parameter
            return this.isCreating && !this.account;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdvertiserModalComponent.prototype, "showAdvertiserLabel", {
        get: function () {
            return this._showAdvertiserLabel;
        },
        set: function (value) {
            this._showAdvertiserLabel = value;
            if (!value) {
                this.advertiser.externalId = null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdvertiserModalComponent.prototype, "showDomainBlocklist", {
        get: function () {
            return this._showDomainBlocklist;
        },
        set: function (value) {
            if (value) {
                this.advertiser.targetedDomains = this.advertiser.targetedDomains || [];
            }
            this._showDomainBlocklist = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdvertiserModalComponent.prototype, "showWhiteBlackList", {
        get: function () {
            return this._showWhiteBlackList;
        },
        set: function (value) {
            this._showWhiteBlackList = value;
            if (!value) {
                this.advertiser.targetedPublishers = null;
            }
        },
        enumerable: true,
        configurable: true
    });
    AdvertiserModalComponent.prototype.inventoryChange = function (inventory) {
        this.advertiser.targetedPublishers = inventory.map(function (item) { return item.id; });
    };
    AdvertiserModalComponent.prototype.targetingChange = function (targeting) {
        this.advertiser.targetingType = targeting;
    };
    AdvertiserModalComponent.prototype.reset = function () {
        this.showAdvertiserLabel = false;
        this.showDomainBlocklist = false;
        this.showWhiteBlackList = false;
        this.errorHelper.resetAllErrors();
        this.advertiserForm.form.reset();
    };
    AdvertiserModalComponent.prototype.constructQuery = function (param) {
        var request = {
            conditions: [
                {
                    mode: 'or',
                    field: 'name',
                    value: param,
                    operator: 'like'
                }
            ]
        };
        if (/^\d+$/.test(param)) {
            request.conditions.push({
                mode: 'or',
                field: 'refId',
                value: param,
                operator: 'like'
            });
        }
        return request;
    };
    AdvertiserModalComponent.prototype.displayErrorMessage = function (errorList) {
        if (errorList.length === 1) {
            this.noteId = this.notifications.error('<strong>Uh Oh!</strong> The advertiser could not be saved because '
                + errorList.join(' ') + ' Please check the advertiser and try again.', '', 0);
        }
        else if (errorList.length > 1) {
            this.noteId = this.notifications.error('<strong>Uh Oh!</strong>  The advertiser could not be saved for'
                + ' the following reasons: <li>' + errorList.join('</li><li>') + '</li>', '', 0);
        }
    };
    return AdvertiserModalComponent;
}());
export { AdvertiserModalComponent };
