import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'switch',
    templateUrl: './switch.html',
    styleUrls: ['./switch.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SwitchComponent),
        multi: true
    }]
})
export class SwitchComponent implements ControlValueAccessor {
    @Input() on = 'On';
    @Input() off = 'Off';
    @Input() disabled = false;

    private onchange = (_: boolean) => { };
    private ontouched = () => { };
    private _checked = false;

    constructor(private cdr: ChangeDetectorRef) { }

    writeValue(checked: boolean) {
        if (typeof checked === 'boolean') {
            this.cdr.markForCheck();
            this._checked = checked;
        }
    }

    registerOnChange(fn: (_: boolean) => {}) {
        this.onchange = fn;
    }

    registerOnTouched(fn: () => {}) {
        this.ontouched = fn;
    }

    set checked(checked: boolean) {
        this._checked = checked;
        this.onchange(this._checked);
        this.ontouched();
    }

    get checked() {
        return this._checked;
    }
}
