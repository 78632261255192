import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { BidSwitchAgency } from 'app/shared/models/bidswitch-agency';
import { BackendRepository } from './backend-repository';
var BidSwitchAgencyRepository = /** @class */ (function (_super) {
    tslib_1.__extends(BidSwitchAgencyRepository, _super);
    function BidSwitchAgencyRepository(http) {
        return _super.call(this, http, '/bidswitch-agency', BidSwitchAgency) || this;
    }
    return BidSwitchAgencyRepository;
}(BackendRepository));
export { BidSwitchAgencyRepository };
