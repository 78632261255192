import * as tslib_1 from "tslib";
import { Model } from './model';
import userInterfaceColors from 'src/data/colors/user-interface.json';
var dataPattern = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
var Native = /** @class */ (function () {
    function Native() {
    }
    Native.WIDTH = 975;
    Native.HEIGHT = 250;
    Native.MOBILE_DISPLAY = 'mobile';
    Native.DESKTOP_DISPLAY = 'desktop';
    Native.BLUEPRINT_ID = 1;
    return Native;
}());
export { Native };
export var TypeOfCreative;
(function (TypeOfCreative) {
    TypeOfCreative[TypeOfCreative["Native"] = 0] = "Native";
    TypeOfCreative[TypeOfCreative["NativeDirectSold"] = 1] = "NativeDirectSold";
    TypeOfCreative[TypeOfCreative["NativeDirectSoldTrafficking"] = 2] = "NativeDirectSoldTrafficking";
    TypeOfCreative[TypeOfCreative["NativeExchange"] = 3] = "NativeExchange";
})(TypeOfCreative || (TypeOfCreative = {}));
export var creativeStatusColors = {
    active: userInterfaceColors['brand-green'],
    rejected: userInterfaceColors['brand-red'],
    default: userInterfaceColors['status-default'],
    pending: userInterfaceColors['status-default'],
};
export var CREATIVE_MAPPING_ERROR_ID = 'E1628';
var Creative = /** @class */ (function (_super) {
    tslib_1.__extends(Creative, _super);
    function Creative() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Creative.prototype.getMaxSize = function (large) {
        return large ? Creative.maxLargeThumbnailSize : Creative.maxSmallThumbnailSize;
    };
    Creative.prototype.applyScaling = function (size, large) {
        var maxSize = this.getMaxSize(large);
        if (size > maxSize) {
            return Math.floor(maxSize * Math.pow(size / maxSize, Creative.scalingExponent));
        }
        return size;
    };
    Creative.prototype.thumbnailFactor = function (large) {
        return large ? Creative.largeThumbnailFactor : Creative.smallThumbnailFactor;
    };
    Creative.prototype.thumbnailWidth = function (large, customWidth) {
        var width = customWidth ? customWidth : this.width;
        var scaledWidth = Math.floor(width * this.thumbnailFactor(large));
        return this.applyScaling(scaledWidth, large);
    };
    Creative.prototype.thumbnailHeight = function (large, customHeight) {
        var height = customHeight ? customHeight : this.height;
        var scaledHeight = Math.floor(height * this.thumbnailFactor(large));
        return this.applyScaling(scaledHeight, large);
    };
    Object.defineProperty(Creative.prototype, "cachedMediaUrl", {
        get: function () {
            if (this.isNative) {
                return this.urlCachedCopy;
            }
            else
                return this.mediaUrl;
        },
        enumerable: true,
        configurable: true
    });
    Creative.prototype.previewWidth = function (display, blueprint) {
        if (display === void 0) { display = ''; }
        if (blueprint === void 0) { blueprint = this.blueprint; }
        // default preview is 25% of original image size
        var defaultPreviewFactor = 0.25;
        // if image is not proportional like logo, preview is 70% of original image size
        var logoPreviewFactor = 0.7;
        // Reset previewFactor if not yet reset
        if (Creative.previewFactor !== defaultPreviewFactor) {
            Creative.previewFactor = defaultPreviewFactor;
        }
        if (blueprint) {
            var width = blueprint.width, mobileWidth = blueprint.mobileWidth, maxHeight = blueprint.maxHeight, mobileMaxHeight = blueprint.mobileMaxHeight;
            // Determine if it's suitable for resizing based on height/width ratio
            var isSuitableForResizing_1 = function (height, width) { return height / width > 0.1; };
            // Adjust previewFactor based on suitability for resizing
            var adjustPreviewFactor = function (height, width) {
                if (isSuitableForResizing_1(height, width)) {
                    Creative.previewFactor = defaultPreviewFactor;
                }
                else {
                    Creative.previewFactor = logoPreviewFactor;
                }
            };
            if (display === 'mobile' && mobileWidth && mobileMaxHeight) {
                adjustPreviewFactor(mobileMaxHeight, mobileWidth);
                return Math.floor(mobileWidth * Creative.previewFactor);
            }
            if (maxHeight && width) {
                adjustPreviewFactor(maxHeight, width);
                return Math.floor(width * Creative.previewFactor);
            }
        }
        // Return default width if no blueprint provided
        return this.width;
    };
    Creative.prototype.previewHeight = function (display, blueprint) {
        if (display === void 0) { display = ''; }
        if (blueprint === void 0) { blueprint = this.blueprint; }
        // default preview is 25% of original image size
        var defaultPreviewFactor = 0.25;
        // if image is not proportional like logo, preview is 70% of original image size
        var logoPreviewFactor = 0.7;
        // Reset previewFactor if not yet reset. This ensures if previewFactor set to Logo its reset back afterwards
        if (Creative.previewFactor !== defaultPreviewFactor) {
            Creative.previewFactor = defaultPreviewFactor;
        }
        if (blueprint) {
            var width = blueprint.width, mobileWidth = blueprint.mobileWidth, maxHeight = blueprint.maxHeight, mobileMaxHeight = blueprint.mobileMaxHeight;
            // Determine if it's suitable for resizing based on height/width ratio
            var isSuitableForResizing_2 = function (height, width) { return height / width > 0.1; };
            // Adjust previewFactor based on suitability for resizing
            var adjustPreviewFactor = function (height, width) {
                if (isSuitableForResizing_2(height, width)) {
                    Creative.previewFactor = defaultPreviewFactor;
                }
                else {
                    Creative.previewFactor = logoPreviewFactor;
                }
            };
            if (display === 'mobile' && mobileWidth && mobileMaxHeight) {
                adjustPreviewFactor(mobileMaxHeight, mobileWidth);
                return Math.floor(mobileMaxHeight * Creative.previewFactor);
            }
            if (maxHeight && width) {
                adjustPreviewFactor(maxHeight, width);
                return Math.floor(maxHeight * Creative.previewFactor);
            }
        }
        // Return default height if no blueprint provided
        return this.height;
    };
    Object.defineProperty(Creative.prototype, "mediaUrlScrubMacros", {
        get: function () {
            var sub = /\$\{.*\}/;
            return this.mediaUrl ? this.mediaUrl.replace(sub, 'liveintentdefaultvalue') : null;
        },
        enumerable: true,
        configurable: true
    });
    Creative.prototype.isThirdPartyTag = function () {
        if (typeof this.mediaUrl === 'string' && this.mediaUrl.length > 0) {
            if (this.mediaUrl.match(dataPattern)
                || ['liveintenteng', 'c.licasd.com'].indexOf(this.mediaUrl) > -1) {
                return false;
            }
        }
        return true;
    };
    Creative.prototype.isTrueNative = function () {
        return this.isNative && !this.blueprintId;
    };
    Creative.prototype.serialize = function () {
        var creative = this.clone(Creative);
        delete creative.thirdPartyTag;
        delete creative.dataUrl;
        delete creative.native;
        delete creative._isClone;
        delete creative.isModified;
        delete creative.isValid;
        delete creative.assetRestrictions;
        if (typeof creative.externalId === 'string' && creative.externalId.length === 0) {
            creative.externalId = null;
        }
        if (!creative.urlTracking1) {
            creative.urlTracking1 = null;
        }
        if (!creative.urlTracking2) {
            creative.urlTracking2 = null;
        }
        if (typeof creative.staqId === 'string' && creative.staqId.length === 0) {
            creative.staqId = null;
        }
        if (!creative.isNative) {
            delete creative.blueprintId;
            delete creative.blueprintName;
        }
        if (creative.isNative) {
            for (var _i = 0, _a = ['headline', 'body', 'callToAction', 'sponsoredBy', 'thumbnailUrl', 'logoUrl']; _i < _a.length; _i++) {
                var asset = _a[_i];
                if (!creative[asset]) {
                    creative[asset] = null;
                }
            }
        }
        var creativeUrl = creative.mediaUrl.split(',');
        creative.file = creativeUrl.length > 1 ? creativeUrl[1] : creative.mediaUrl;
        delete creative.mediaUrl;
        delete creative.eligibleAdSlots;
        return JSON.stringify(creative);
    };
    Object.defineProperty(Creative.prototype, "entity", {
        get: function () {
            return 'Creative';
        },
        enumerable: true,
        configurable: true
    });
    Creative.prototype.getImageTypeFromSrc = function () {
        // Extract the file extension from the URL
        var parts = this.mediaUrl.split('.');
        var extension = parts.length > 0 ? parts[parts.length - 1].toLowerCase() : '';
        // Map common image file extensions to their types
        var imageTypes = {
            'jpg': 'JPEG',
            'jpeg': 'JPEG',
            'png': 'PNG',
            'gif': 'GIF',
            'bmp': 'BMP',
            'webp': 'WEBP',
            'svg': 'SVG',
        };
        // Lookup the type based on the file extension
        if (extension && imageTypes.hasOwnProperty(extension)) {
            return imageTypes[extension];
        }
        else {
            return 'Unknown';
        }
    };
    Object.defineProperty(Creative.prototype, "typeOfCreative", {
        get: function () {
            if (this.isNative && this.blueprintId && this.native) {
                return TypeOfCreative.NativeDirectSold;
            }
            else if (this.isNative && this.native && this.isDirectSoldTrafficking) {
                return TypeOfCreative.NativeDirectSoldTrafficking;
            }
            else if (this.isNative && this.native) {
                return TypeOfCreative.NativeExchange;
            }
            else if (this.isNative && this.blueprintId) {
                return TypeOfCreative.Native;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Creative.prototype, "isNativeCreative", {
        get: function () {
            return [
                TypeOfCreative.NativeExchange,
                TypeOfCreative.NativeDirectSold,
                TypeOfCreative.NativeDirectSoldTrafficking
            ].includes(this.typeOfCreative);
        },
        enumerable: true,
        configurable: true
    });
    Creative.smallThumbnailFactor = 0.08;
    Creative.largeThumbnailFactor = 0.6;
    Creative.maxSmallThumbnailSize = 90;
    Creative.maxLargeThumbnailSize = 300;
    Creative.previewFactor = 0.25;
    Creative.scalingExponent = 0.1;
    Creative.nativeTypes = ['text_ad', 'in_feed', 'product_listing', 'recommendation_widget'];
    return Creative;
}(Model));
export { Creative };
var AssetField = /** @class */ (function (_super) {
    tslib_1.__extends(AssetField, _super);
    function AssetField() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AssetField.prototype.serialize = function () {
        delete this._isClone;
        return JSON.stringify(this);
    };
    Object.defineProperty(AssetField.prototype, "entity", {
        get: function () {
            return 'AssetFields';
        },
        enumerable: true,
        configurable: true
    });
    return AssetField;
}(Model));
export { AssetField };
export var AssetFieldProperties = [{
        label: 'Sponsored By',
        modelKey: 'sponsoredBy',
        placeholder: 'Sponsored By',
        isFocused: false,
        charRemaining: 0,
        charCount: 0,
        errorMessage: 'Characters are over recommended limit',
        inputId: 'ad-sponsored-by-input',
        maxChar: 30,
        macro: 'sb'
    }, {
        label: 'Body',
        modelKey: 'body',
        placeholder: 'Text body',
        isFocused: false,
        charRemaining: 0,
        charCount: 0,
        errorMessage: 'Characters are over recommended limit',
        inputId: 'ad-body-input',
        maxChar: 90,
        macro: 'd'
    }, {
        label: 'Headline',
        modelKey: 'headline',
        placeholder: 'Type your headline',
        isFocused: false,
        charRemaining: 0,
        charCount: 0,
        errorMessage: 'Characters are over recommended limit',
        inputId: 'ad-headline-input',
        maxChar: 30,
        macro: 't'
    }, {
        label: 'Call to Action',
        modelKey: 'callToAction',
        placeholder: 'Click me',
        isFocused: false,
        charRemaining: 0,
        charCount: 0,
        errorMessage: 'Characters are over recommended limit',
        inputId: 'ad-click-me-input',
        maxChar: 15,
        macro: 'cta'
    }];
var CreativeMeta = /** @class */ (function (_super) {
    tslib_1.__extends(CreativeMeta, _super);
    function CreativeMeta() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CreativeMeta.prototype.serialize = function () {
        delete this._isClone;
        return JSON.stringify(this);
    };
    Object.defineProperty(CreativeMeta.prototype, "entity", {
        get: function () {
            return 'CreativeMeta';
        },
        enumerable: true,
        configurable: true
    });
    return CreativeMeta;
}(Model));
export { CreativeMeta };
