import {
    Component,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    ViewContainerRef,
    Renderer2,
    AfterViewInit,
    OnDestroy
} from '@angular/core';
import { Subscription } from 'rxjs';

import {
    BulkOperationStatusToastService,
    NotificationsService,
    NotificationData
} from 'app/core';
import { AlertnextComponent } from 'app/shared/elements/alertnext';
import { Status } from 'app/shared/models/bulk-request';

@Component({
    template: ''
})
export class NotificationsComponent implements AfterViewInit, OnDestroy {
    private alertComponentFactory: ComponentFactory<AlertnextComponent>;
    private showAlert: Subscription;
    private removeAlert: Subscription;
    private showBulkOperationToast: Subscription;
    private removeBulkOperationToast: Subscription;
    private alerts = new Map<number, ComponentRef<AlertnextComponent>>();
    private bulkOperationStatusToast: ComponentRef<AlertnextComponent>;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainer: ViewContainerRef,
        private renderer: Renderer2,
        private notifications: NotificationsService,
        private bulkOperationStatusToastService: BulkOperationStatusToastService
    ) {
        this.alertComponentFactory = this.componentFactoryResolver.resolveComponentFactory(AlertnextComponent);
    }

    ngAfterViewInit() {
        this.showAlert = this.notifications.show$.subscribe(data => this.showNotification(data));
        this.removeAlert = this.notifications.remove$.subscribe(id => this.removeNotification(id));

        this.showBulkOperationToast = this.bulkOperationStatusToastService.show$.subscribe(data => this.showBulkOperationStatusToast(data));
        this.removeBulkOperationToast = this.bulkOperationStatusToastService.remove$.subscribe(() => this.removeBulkOperationStatusToasts());
    }

    ngOnDestroy() {
        this.showAlert.unsubscribe();
        this.removeAlert.unsubscribe();
        this.showBulkOperationToast.unsubscribe();
        this.removeBulkOperationToast.unsubscribe();
    }

    private showNotification(notification: NotificationData) {
        const alert = this.viewContainer.createComponent(this.alertComponentFactory, 0);
        this.alerts.set(notification.id, alert);
        this.renderer.addClass(alert.location.nativeElement, notification.type);

        // Set various properties.
        alert.instance.title = notification.title;
        alert.instance.message = notification.message;

        if (notification.timeout !== 0) {
            alert.instance.timeout(notification.timeout || 5);
        }

        // When the alert is dismissed, make sure we destroy it.
        alert.instance.dismissed.subscribe(() => alert.destroy());
    }

    private removeNotification(id: number) {
        const alert = this.alerts.get(id);
        if (alert) {
            this.alerts.delete(id);
            alert.destroy();
        }
    }

    private showBulkOperationStatusToast(data: Status): void {
        this.removeBulkOperationStatusToasts();

        const toast = this.viewContainer.createComponent(this.alertComponentFactory, 0);

        toast.instance.message = this.getMessage(data);
        this.updateStyle(toast, data);
        this.bulkOperationStatusToast = toast;

        toast.instance.dismissed.subscribe(() => toast.destroy());
    }

    private getMessage(data: Status): string {
        let message = '';

        switch (data) {
            case Status.Pending:
                message += 'There are pending batch operations in progress. Your recent changes will not be reflected until they complete. ';
                break;
            case Status.Success:
                message += 'Your recent batch operations have completed.';
                break;
            case Status.Failed:
                message += 'There were failures in a recent batch operation.';
                break;
        }

        message += ' <a href="/bulk-operation-status">Click here to view them.</a>';

        return message;
    }

    private updateStyle(toast: ComponentRef<AlertnextComponent>, data: Status): void {
        switch (data) {
            case Status.Pending:
                this.renderer.addClass(toast.location.nativeElement, 'announcement');
                break;
            case Status.Success:
                this.renderer.addClass(toast.location.nativeElement, 'success');
                break;
            case Status.Failed:
                this.renderer.addClass(toast.location.nativeElement, 'error');
                break;
        }
    }

    private removeBulkOperationStatusToasts(): void {
        if (this.bulkOperationStatusToast) {
            this.bulkOperationStatusToast.destroy();
        }
    }
}
