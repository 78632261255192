import { NgModule } from '@angular/core';

import { AudienceService } from 'app/core/audience.service';
import { AuthenticationService } from 'app/core/authentication.service';
import { AuthorizationService } from 'app/core/authorization.service';
import { BulkOperationStatusToastService } from 'app/core/bulk-operation-status-toast.service';
import { DruidQueryHelper } from 'app/core/query-builder-helper';
import { IfaasPreviewService } from 'app/core/ifaas-preview.service';
import { InventoryHelperService } from 'app/core/inventory-helper.service';
import { LaunchDarklyService } from "./launch-darkly.service";
import { LiveConnectService } from 'app/core/live-connect.service';
import { LocationHelperService } from 'app/core/location-helper.service';
import { MetricsDataService } from 'app/core/metrics-data.service';
import { NavigationService } from 'app/core/navigation.service';
import { NotificationsService } from 'app/core/notifications.service';
import { PixelService } from './pixel.service';
import { PreferencesService } from 'app/core/preferences.service';
import { ProwlerService } from 'app/core/prowler.service';
import { RedirectService } from 'app/core/redirect.service';
import { RuleService } from 'app/core/rule.service';
import { StoreService } from 'app/core/store.service';
import { TokenService } from 'app/core/token.service';
import { YieldManagementAdSlotService } from 'app/core/yield-management-ad-slot.service';

// TODO: this does not belong here
import { CampaignFlowService } from 'app/platform/campaign-manager/campaigns/campaign-form/campaign-flow.service';
import { ReportsFormService } from 'app/platform/reporting/reports-form/reports-form-service';

import { AvailableActionsService } from 'app/shared/helpers/available-actions.service';
import { CampaignsSortService } from 'app/shared/helpers/campaigns-sort.service';
import { CreativeApprovalEmailService } from 'app/shared/helpers/creative-approval-email.service';
import { LineItemsSortService } from 'app/shared/helpers/line-items-sort.service';
import { ThemeService } from 'app/shared/helpers/theme.service';

import {
    AccountRepository,
    AdSlotRepository,
    AdvertiserRepository,
    AgencyRepository,
    AudiencesApiRepository,
    BidSwitchAgencyRepository,
    BlueprintRepository,
    BrowserRepository,
    BulkOperationRepository,
    BundleRepository,
    BuyerRepository,
    BuyerSeatRepository,
    CampaignRepository,
    CatalogRepository,
    CategoryRepository,
    CityRepository,
    CookieSyncPartnerRepository,
    CountryRepository,
    CreativeRepository,
    DealRepository,
    DeviceManufacturerRepository,
    DeviceTypeRepository,
    ESPRepository,
    ExchangeStrategyRepository,
    IABSizeRepository,
    IfaasRepository,
    InsertionOrderRepository,
    ISPRepository,
    LAURepository,
    SamovarAudienceRepository,
    LIESRepository,
    LineItemRepository,
    LiveConnectTagRepository,
    MailRepository,
    MediaGroupRepository,
    MetroRepository,
    NativeSupplyImageAssetRepository,
    NativeSupplyTextAssetRepository,
    NewsletterRepository,
    OperatingSystemRepository,
    PackageRepository,
    PixelRepository,
    ProwlerRepository,
    PublisherKeyValueRepository,
    PublisherRepository,
    PublisherIdentifierRepository,
    RegionRepository,
    ReportingEmailRepository,
    ReportingQueryRepository,
    ReportingScheduleRepository,
    ReportingSlicerRepository,
    ReportingSspforecastingRepository,
    RTBPartnerRepository,
    RuleRepository,
    SasNewsletterRepository,
    SorobanRepository,
    SellerRepository,
    SensitiveCategoryRepository,
    StrategyCardRepository,
    GlobalBlocklistRepository,
    UMLAUTRepository,
    UserRepository,
    UtilRepository,
    WhitelistingRuleRepository,
} from 'app/core/repositories';
import {
    ActiveAdvertisersResolver,
    AdResolver,
    AdsCopyResolver,
    AdSlotResolver,
    AdsResolver,
    AdvertiserRefResolver,
    AdvertiserResolver,
    AgencyResolver,
    BlueprintResolver,
    CampaignDetailsResolver,
    CampaignFlowEditResolver,
    CampaignFlowResolver,
    ConversionTrackerResolver,
    InsertionOrderDetailsResolver,
    InsertionOrderResolver,
    LineItemResolver,
    MediaGroupResolver,
    NewsletterResolver,
    PackageDealsResolver,
    PackageResolver,
    PublisherCreativeApprovalResolver,
    PublisherResolver,
} from 'app/core/resolvers';
import {
    AuthGuard,
    CreativeApprovalGuard,
    InsertionOrderFormGuard,
    InternalUserGuard,
    MockupToolGuard,
    NativeGuard,
    ReportingGuard,
    ResetPasswordGuard,
    PackagesGuard,
    ReportingInternalUserGuard,
} from 'app/core/guards';
import { AudienceGuard } from './guards/audience-guard-service';
import { EligibleAdSlotsResolver } from './resolvers/eligible-ad-slot.resolver';

const repositories = [
    AccountRepository,
    AdSlotRepository,
    AdvertiserRepository,
    AgencyRepository,
    AudiencesApiRepository,
    BidSwitchAgencyRepository,
    BlueprintRepository,
    BrowserRepository,
    BulkOperationRepository,
    BundleRepository,
    BuyerRepository,
    BuyerSeatRepository,
    CampaignRepository,
    CatalogRepository,
    CategoryRepository,
    CityRepository,
    CookieSyncPartnerRepository,
    CountryRepository,
    CreativeRepository,
    DealRepository,
    DeviceManufacturerRepository,
    DeviceTypeRepository,
    ESPRepository,
    ExchangeStrategyRepository,
    GlobalBlocklistRepository,
    IABSizeRepository,
    IfaasRepository,
    InsertionOrderRepository,
    ISPRepository,
    LAURepository,
    SamovarAudienceRepository,
    LIESRepository,
    LineItemRepository,
    LiveConnectTagRepository,
    MailRepository,
    MediaGroupRepository,
    MetroRepository,
    NativeSupplyImageAssetRepository,
    NativeSupplyTextAssetRepository,
    NewsletterRepository,
    OperatingSystemRepository,
    PackageRepository,
    PixelRepository,
    ProwlerRepository,
    PublisherKeyValueRepository,
    PublisherRepository,
    PublisherIdentifierRepository,
    RegionRepository,
    ReportingEmailRepository,
    ReportingQueryRepository,
    ReportingScheduleRepository,
    ReportingSlicerRepository,
    ReportingSspforecastingRepository,
    RTBPartnerRepository,
    RTBPartnerRepository,
    RTBPartnerRepository,
    RuleRepository,
    SasNewsletterRepository,
    SorobanRepository,
    SellerRepository,
    SensitiveCategoryRepository,
    StrategyCardRepository,
    UMLAUTRepository,
    UserRepository,
    UtilRepository,
    WhitelistingRuleRepository,
    SensitiveCategoryRepository,
];

const resolvers = [
    ActiveAdvertisersResolver,
    AdResolver,
    AdsCopyResolver,
    AdSlotResolver,
    AdsResolver,
    AdvertiserRefResolver,
    AdvertiserResolver,
    AgencyResolver,
    BlueprintResolver,
    CampaignDetailsResolver,
    CampaignFlowEditResolver,
    CampaignFlowResolver,
    ConversionTrackerResolver,
    EligibleAdSlotsResolver,
    InsertionOrderDetailsResolver,
    InsertionOrderResolver,
    LineItemResolver,
    MediaGroupResolver,
    NewsletterResolver,
    PackageDealsResolver,
    PackageResolver,
    PublisherCreativeApprovalResolver,
    PublisherResolver,
];

const guards = [
    AuthGuard,
    CreativeApprovalGuard,
    InsertionOrderFormGuard,
    InternalUserGuard,
    MockupToolGuard,
    NativeGuard,
    ReportingGuard,
    ResetPasswordGuard,
    PackagesGuard,
    AudienceGuard,
    ReportingInternalUserGuard,
];

@NgModule({
    providers: [
        // Various `Core` providers
        AudienceService,
        AuthenticationService,
        AuthorizationService,
        AvailableActionsService,
        BulkOperationStatusToastService,
        CampaignFlowService,
        CampaignsSortService,
        CreativeApprovalEmailService,
        DruidQueryHelper,
        IfaasPreviewService,
        InventoryHelperService,
        LaunchDarklyService,
        LineItemsSortService,
        LiveConnectService,
        LocationHelperService,
        MetricsDataService,
        NavigationService,
        NotificationsService,
        PixelService,
        PreferencesService,
        ProwlerService,
        RedirectService,
        ReportsFormService,
        RuleService,
        StoreService,
        ThemeService,
        TokenService,
        YieldManagementAdSlotService,

        // Service groups
        ...guards,
        ...repositories,
        ...resolvers,
    ],
})
export class CoreModule { }
