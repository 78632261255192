import * as tslib_1 from "tslib";
import { Model } from '../model';
export var SupplyTextAssetType;
(function (SupplyTextAssetType) {
    SupplyTextAssetType[SupplyTextAssetType["SponsoredBy"] = 1] = "SponsoredBy";
    SupplyTextAssetType[SupplyTextAssetType["Description"] = 2] = "Description";
    SupplyTextAssetType[SupplyTextAssetType["CallToAction"] = 12] = "CallToAction";
})(SupplyTextAssetType || (SupplyTextAssetType = {}));
var ɵ0 = SupplyTextAssetType.SponsoredBy, ɵ1 = SupplyTextAssetType.CallToAction, ɵ2 = SupplyTextAssetType.Description;
export var SupplyTextAssets = [{
        id: ɵ0,
        key: 'sponsored'
    }, {
        id: ɵ1,
        key: 'callToAction'
    }, {
        id: ɵ2,
        key: 'description'
    }];
var SupplyTextAsset = /** @class */ (function (_super) {
    tslib_1.__extends(SupplyTextAsset, _super);
    function SupplyTextAsset(data) {
        if (data === void 0) { data = {}; }
        return _super.call(this, data) || this;
    }
    SupplyTextAsset.prototype.serialize = function () {
        var textAsset = this.clone(SupplyTextAsset);
        delete textAsset._isClone;
        if (!textAsset.version) {
            delete textAsset.version;
        }
        if (!textAsset.refId) {
            delete textAsset.refId;
        }
        return JSON.parse(JSON.stringify(textAsset));
    };
    Object.defineProperty(SupplyTextAsset.prototype, "entity", {
        get: function () {
            return 'Native Supply Text Asset';
        },
        enumerable: true,
        configurable: true
    });
    return SupplyTextAsset;
}(Model));
export { SupplyTextAsset };
export { ɵ0, ɵ1, ɵ2 };
