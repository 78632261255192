import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Package } from 'app/shared/models';
import { BackendRepository } from './backend-repository';

@Injectable()
export class PackageRepository extends BackendRepository<Package> {
    public constructor(http: HttpClient) {
        super(http, '/package', Package);
    }

    save(pkg: Package) {
        const clone = pkg.clone(Package);

        if (pkg.id) {
            clone.id = pkg.id.toString();
        }

        return super.save(clone);
    }
}
