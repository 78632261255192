import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Output, HostListener, Input } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import moment from 'moment';
import { switchMap, map, filter, delay } from 'rxjs/operators';

@Component({
    selector: 'calendar',
    templateUrl: './calendar.html',
    styleUrls: ['./calendar.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarComponent implements OnInit {
    date$ = new BehaviorSubject<moment.Moment>(null);
    month$ = new BehaviorSubject<moment.Moment>(moment());
    hover$ = new BehaviorSubject<moment.Moment>(null);
    view$ = this.month$.pipe(map(date => this.generateView(moment(date).startOf('month').startOf('week'))));
    context$ = combineLatest(this.month$, this.hover$.pipe(switchMap(date => this.hoverDelay(date))));
    @Input() from;
    @Input('min-date') minDate: Date;
    @Input('max-date') maxDate: Date;
    @Output('date-selected') dateSelected = new EventEmitter<moment.Moment>();
    private _date: moment.Moment;

    @Input() set date(date: moment.Moment) {
        this._date = date;
        this.snapToView(date);
    }

    get date() {
        return this._date;
    }

    ngOnInit() {
        this.date$.pipe(filter(date => date !== null)).subscribe(date => this.dateSelected.emit(date));
    }

    @HostListener('mousedown', ['$event']) onMousedown(event: MouseEvent) {
        event.preventDefault();
    }

    previousMonth() {
        this.setMonth(this.month$.getValue().clone().subtract(1, 'month'));
    }

    nextMonth() {
        this.setMonth(this.month$.getValue().clone().add(1, 'month'));
    }

    setMonth(date: moment.Moment) {
        if (date && date.isValid()) {
            this.month$.next(date);
        }
    }

    clearHover() {
        this.hover$.next(null);
    }

    dateClasses(date: moment.Moment, [month, hover]) {
        const day = date.day();

        const classes = {};

        classes['off-month'] = !month.isSame(date, 'month');
        classes['weekend'] = day === 0 || day === 6;

        if (this.date) {
            const same = date.isSame(this.date, 'day');
            classes['end'] = same;
            classes['end-selected'] = same;
        }

        if (this.from) {
            classes['start'] = date.isSame(this.from, 'day');

            if (this.date) {
                const range = date.isBetween(this.from, this.date, 'day', '[)');
                classes['range'] = range;
                classes['range-selected'] = range;
            }

            if (hover) {
                classes['range'] = date.isBetween(this.from, hover, 'day', '[)');
            }
        }

        if (hover) {
            classes['end'] = date.isSame(hover, 'day');
        }

        classes['disabled'] = this.isDisabled(date);

        return classes;
    }

    selectDate(date: moment.Moment) {
        if (!this.isDisabled(date)) {
            this.date$.next(date);
        }
    }

    snapToView(date: moment.Moment) {
        this.setMonth(date);
    }

    private isDisabled(date: moment.Moment) {
        if (this.minDate && date.isBefore(this.minDate)) {
            return true;
        }

        if (this.maxDate && date.isAfter(this.maxDate)) {
            return true;
        }

        return false;
    }

    private generateView(date: moment.Moment) {
        return Array.from(Array(42)).map((v, offset) => date.clone().add(offset, 'days'));
    }

    private hoverDelay(date: moment.Moment) {
        const date$ = of(date);
        return date ? date$ : date$.pipe(delay(100));
    }
}
