import { Component, EventEmitter, Output, ViewChild, Input, TemplateRef, ContentChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { of, forkJoin, combineLatest } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { AvailableActionsService } from 'app/shared/helpers/available-actions.service';
import { Campaign } from 'app/shared/models';
import { CampaignRepository } from 'app/core/repositories';
import { ErrorHelper } from 'app/core/errors/error-helper';
import { HistoryViewComponent } from 'app/shared/components/history-view';
import { IdService } from 'app/core/id.service';
import { NotificationsService } from 'app/core';
import { PaginatorComponent } from 'app/shared/elements/paginator';
import { SearchParams } from 'app/shared/helpers/query-builder';
import { TableComponent } from 'app/shared/elements/table';
import { TableHelper } from 'app/shared/helpers/table-helper';

@Component({
    selector: 'campaigns-table',
    templateUrl: './campaigns-table.html',
    styleUrls: ['./campaigns-table.styl']
})
export class CampaignsTableComponent implements OnInit {
    @ContentChild('zeroState', { static: true }) zeroStateTpl: TemplateRef<any>;
    @ContentChild('actions', { static: true }) actionsTemplate: TemplateRef<any>;
    @ViewChild(TableComponent, { static: true }) table: TableComponent;
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent;
    @ViewChild('campaignPauseModal', { static: true }) campaignPauseModal;
    @ViewChild(HistoryViewComponent, { static: true }) historyView: HistoryViewComponent;
    @Output() paused = new EventEmitter<any[]>();
    @Output() deleted = new EventEmitter<any[]>();
    helper = new TableHelper<Campaign>(params => this.adapter(params));

    private _ids: string[] = null;
    private _conversionPixelId: string = null;
    private _failedCampaigns: any[] = null;
    private errorHelper: ErrorHelper = null;

    @Input() advertiser: string = null;

    constructor(
        private campaignRepository: CampaignRepository,
        public availActions: AvailableActionsService,
        private notifications: NotificationsService,
        private router: Router,
        public id: IdService
    ) { }

    ngOnInit() {
        this.helper.table = this.table;
        this.helper.paginator = this.paginator;
        combineLatest(this.table.query, this.paginator.query).subscribe(args => this.helper.search(args));
    }

    @Input('insertion-orders') set insertionOrders(value: string[]) {
        this._ids = value;
        this.paginator.reset();
    }

    @Input('conversion-pixel') set conversionPixelId(id: string) {
        this._conversionPixelId = id;
        this.paginator.reset();
    }

    private adapter(params: SearchParams) {
        if (Array.isArray(this._ids)) {
            params.conditions.push({
                field: 'insertionOrder',
                value: this._ids
            });
        }

        if (typeof this.advertiser === 'string') {
            params.conditions.push({
                field: 'advertiser',
                value: this.advertiser
            });
        }

        if (typeof this._conversionPixelId === 'string') {
            params.conditions.push({
                field: 'conversionPixel',
                value: this._conversionPixelId
            });
        }

        return this.campaignRepository.asyncSearch(params);
    }

    actionHandler(action, campaign) {
        if (action === 'edit') {
            this.router.navigate(['/campaign-manager/campaigns', campaign.refId, 'edit',
                { redirect: btoa(this.router.url)}]);
        }
        else if (action === 'pause') {
            this.campaignPauseModal.open(campaign);
        }
        else if (action === 'history') {
            this.historyView.viewCampaignHistory(campaign);
        }
    }

    handlePaused() {
        this.paused.emit();
        this.notifications.success(`The campaign has successfully been paused.`);
    }

    deleteCampaigns(campaigns) {
        this._failedCampaigns = [];
        this.errorHelper = new ErrorHelper();
        forkJoin(
            campaigns.map(campaign => {
                return this.campaignRepository.delete(campaign.id).pipe(catchError((err: HttpErrorResponse) => {
                    this.errorHelper.loadBackEndErrors(err.error.errors);
                    let errorList = this.errorHelper.errors;
                    this._failedCampaigns.push({
                        key: campaign.name,
                        value: errorList
                    });
                    return of(null);
                }));
            })
        ).subscribe(res => {
            if (this._failedCampaigns.length > 0) {
                this.displayErrorMessage(this._failedCampaigns);
            } else {
                this.notifications.success(`Selected campaigns have been successfully deleted.`);
                this.deleted.emit();
                this.table.clearSelections();
            }
            this.paginator.reset();
        });
    }

    public displayErrorMessage(campaigns): void {
        let entityName = 'campaign';
        if (campaigns.length !== 1) {
            entityName = entityName.concat('s');
        }
        let error = '<strong>Uh Oh!</strong>  The following ' + entityName + ' could not be deleted:';
        campaigns.forEach(c => error += '<li>' + c.key + ' ' + c.value + '</li>');
        this.notifications.error(error, '', 0);
    }
}
