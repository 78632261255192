import * as tslib_1 from "tslib";
import { EventEmitter, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ButtonComponent } from '../button';
import { Closable } from './closable';
var PopoverComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PopoverComponent, _super);
    function PopoverComponent(cdr, el) {
        var _this = _super.call(this, el.nativeElement) || this;
        _this.cdr = cdr;
        _this.el = el;
        _this.event = 'mouseenter';
        _this.maxWidth = 1000;
        _this.margin = true;
        _this.onHide = new EventEmitter();
        _this.position = 'bottom';
        _this.visibility = 'hidden';
        _this.left = null;
        _this.top = null;
        _this.width = null;
        _this.nubbinLeft = null;
        _this.containerLeft = null;
        _this.centered = true;
        return _this;
    }
    PopoverComponent.prototype.ngOnInit = function () {
        var end;
        var target = this.target;
        /**
         * If the target is a Component-like type then we will resolve the native
         * element. This means that any of our components that wish to be
         * compatible popover targets must publicly expose an `ElementRef` as its
         * member `el`.
         */
        _super.prototype.ngOnInit.call(this);
        if (!target) {
            return;
        }
        if (target instanceof ButtonComponent) {
            target = target.el.nativeElement;
        }
        if (this.event === undefined) {
            if (target instanceof HTMLButtonElement) {
                this.event = 'mouseenter';
            }
            else if (target instanceof HTMLInputElement) {
                this.event = 'focus';
            }
        }
        if (this.event === 'mouseenter') {
            end = 'mouseleave';
        }
        else if (this.event === 'focus') {
            end = 'blur';
        }
        target.addEventListener(this.event, this.show.bind(this));
        target.addEventListener(end, this.hide.bind(this));
        target.style.display = 'inline-block';
        this.target = target;
    };
    PopoverComponent.prototype.update = function () {
        if (!this.target) {
            return;
        }
        this.cdr.markForCheck();
        this.position = 'bottom';
        this.top = null;
        var viewportWidth = window.innerWidth;
        var viewportHeight = window.innerHeight;
        var NUBBIN_WIDTH = 22;
        var target = this.target;
        var targetWidth = target.clientWidth;
        var targetOffsetLeft = target.offsetLeft;
        var el = this.popover.nativeElement;
        var elWidth = el.clientWidth;
        var elLeft = targetOffsetLeft - (elWidth - targetWidth) / 2;
        if (viewportWidth < elLeft + el.clientWidth) {
            elLeft = targetOffsetLeft + target.clientWidth - el.clientWidth;
            this.nubbinLeft = el.clientWidth - NUBBIN_WIDTH - (target.clientWidth - NUBBIN_WIDTH) / 2;
            this.centered = false;
        }
        this.left = elLeft;
        this.width = elWidth;
        this.top = target.offsetTop + target.clientHeight + 4;
        // 'Flip' the popover up when it is out of the viewport
        var bounds = el.getBoundingClientRect();
        if (bounds.bottom > viewportHeight) {
            this.position = 'top';
            this.top = target.offsetTop - el.clientHeight - 4;
        }
        var menuColumn = document.getElementsByClassName('column--static')[0];
        var elRect = menuColumn ? menuColumn.getBoundingClientRect() : { left: 0, width: 0 };
        if (el.getBoundingClientRect().left < elRect.left + elRect.width) {
            this.containerLeft = -(el.getBoundingClientRect().left - (elRect.left + elRect.width));
        }
    };
    PopoverComponent.prototype.show = function (e) {
        e.stopPropagation();
        if (this.event === 'click' && this.visibility === 'visible') {
            this.hide();
        }
        else {
            _super.prototype.show.call(this, e);
            this._show();
        }
    };
    PopoverComponent.prototype._show = function () {
        this.visibility = 'visible';
        this.popover.nativeElement.style.display = 'block';
        this.update();
    };
    PopoverComponent.prototype.hide = function () {
        this.visibility = 'hidden';
        this.popover.nativeElement.style.display = 'none';
        this.left = null;
        this.top = null;
        this.onHide.emit();
    };
    return PopoverComponent;
}(Closable));
export { PopoverComponent };
