import * as tslib_1 from "tslib";
import { Model } from './model';
var ReportingSlicer = /** @class */ (function (_super) {
    tslib_1.__extends(ReportingSlicer, _super);
    function ReportingSlicer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ReportingSlicer.prototype.serialize = function () {
        return JSON.stringify(this);
    };
    Object.defineProperty(ReportingSlicer.prototype, "entity", {
        get: function () {
            return 'Reporting Slicer';
        },
        enumerable: true,
        configurable: true
    });
    return ReportingSlicer;
}(Model));
export { ReportingSlicer };
