import { WizardComponent } from 'app/shared/elements/wizard';
import { DomainTargetingComponent } from 'app/shared/components/domain-targeting';
import { dedupe } from 'app/core/array-utils';
import { isEqual } from 'app/core/utils';
var BulkEditDomainTargetingComponent = /** @class */ (function () {
    function BulkEditDomainTargetingComponent(label) {
        this.entityName = '';
        this.label = '';
        this.action = 'add';
        this._entities = [];
        this._originals = [];
        this._domains = [];
        this.targetingTypeField = 'domainTargetingType';
        this.selectionMap = new Map();
        this.label = label;
    }
    Object.defineProperty(BulkEditDomainTargetingComponent.prototype, "entities", {
        get: function () {
            return this._entities;
        },
        set: function (entities) {
            this._entities = entities;
            this._originals = entities.map(function (entity) { return entity.clone(); });
            this.master = true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BulkEditDomainTargetingComponent.prototype, "domains", {
        get: function () {
            return this._domains;
        },
        set: function (domains) {
            this._domains = domains;
            this.applyChangeToDomains();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BulkEditDomainTargetingComponent.prototype, "targetingType", {
        get: function () {
            return this._targetingType;
        },
        set: function (targetingType) {
            var _this = this;
            this._targetingType = targetingType;
            if (this.wizard.activeStepIndex > 0) {
                this.selected.forEach(function (entity) { return entity[_this.targetingTypeField] = targetingType; });
            }
        },
        enumerable: true,
        configurable: true
    });
    BulkEditDomainTargetingComponent.prototype.isMinority = function (index) {
        var _this = this;
        var numIncluded = this._originals.filter(function (entity) { return entity[_this.targetingTypeField] === 'include'; }).length;
        var numExcluded = this._originals.length - numIncluded;
        if (numIncluded > numExcluded) {
            return this.original(index)[this.targetingTypeField] === 'exclude';
        }
        if (numIncluded < numExcluded) {
            return this.original(index)[this.targetingTypeField] === 'include';
        }
        return false;
    };
    BulkEditDomainTargetingComponent.prototype.isSelected = function (index) {
        return this.selectionMap.get(index);
    };
    BulkEditDomainTargetingComponent.prototype.toggleSelection = function (value, index) {
        this.selectionMap.set(index, value);
    };
    Object.defineProperty(BulkEditDomainTargetingComponent.prototype, "selected", {
        get: function () {
            var _this = this;
            return this.entities.filter(function (_, index) { return _this.selectionMap.get(index); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BulkEditDomainTargetingComponent.prototype, "master", {
        get: function () {
            var _this = this;
            return this.entities.every(function (_, index) { return _this.selectionMap.get(index); });
        },
        set: function (value) {
            var _this = this;
            this.entities.forEach(function (_, index) { return _this.selectionMap.set(index, value); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BulkEditDomainTargetingComponent.prototype, "multipleTargetingTypesSelected", {
        get: function () {
            var _this = this;
            return this.originalsOfSelected()
                .some(function (entity, _, arr) { return entity[_this.targetingTypeField] !== arr[0][_this.targetingTypeField]; });
        },
        enumerable: true,
        configurable: true
    });
    BulkEditDomainTargetingComponent.prototype.originalsOfSelected = function () {
        var _this = this;
        return this._originals.filter(function (_, i) { return _this.isSelected(i); });
    };
    BulkEditDomainTargetingComponent.prototype.original = function (index) {
        return this._originals[index];
    };
    BulkEditDomainTargetingComponent.prototype.next = function () {
        this.handleActionChange(this.action);
        this.wizard.nextStep();
    };
    BulkEditDomainTargetingComponent.prototype.previous = function () {
        this.wizard.previousStep();
        this.entities = this._originals;
    };
    BulkEditDomainTargetingComponent.prototype.handleActionChange = function (action) {
        if (action !== 'replace') {
            // reset the targeting type back to what it was if not replacing
            this.targetingType = this.originalTargetingType;
        }
        this.applyChangeToDomains();
    };
    Object.defineProperty(BulkEditDomainTargetingComponent.prototype, "originalTargetingType", {
        get: function () {
            if (this.originalsOfSelected().length === 0) {
                return 'exclude';
            }
            // once this function is called, we should assume all
            // selected entities share the same targeting type
            // since you cannot get past step 1 with multiple types selected
            return this.originalsOfSelected()[0][this.targetingTypeField];
        },
        enumerable: true,
        configurable: true
    });
    BulkEditDomainTargetingComponent.prototype.applyChangeToDomains = function () {
        var _this = this;
        switch (this.action) {
            case 'delete':
                this._domains = [];
                this.selected.forEach(function (entity) { return entity[_this.field] = []; });
                break;
            case 'replace':
                this.selected.forEach(function (entity) { return entity[_this.field] = _this.domains; });
                break;
            case 'add':
                this.selected.forEach(function (entity, index) {
                    entity[_this.field] = dedupe(_this.originalsOfSelected()[index][_this.field].concat(_this.domains));
                });
                break;
            case 'remove':
                this.selected.forEach(function (entity, index) {
                    entity[_this.field] = _this.originalsOfSelected()[index][_this.field].filter(function (existing) { return !_this.domains.includes(existing); });
                });
                break;
        }
    };
    BulkEditDomainTargetingComponent.prototype.isDirty = function () {
        var _this = this;
        // check if targeting type has been changed
        if (this.targetingType && this.targetingType !== this.originalTargetingType) {
            return true;
        }
        // check if the main domains field has been changed
        if (this.entities.every(function (entity, index) { return !isEqual(entity[_this.field], _this.original(index)[_this.field]); })) {
            return true;
        }
        return false;
    };
    BulkEditDomainTargetingComponent.prototype.isPreventingSave = function () {
        return this.action !== 'delete' && this.domains.length === 0;
    };
    BulkEditDomainTargetingComponent.prototype.reset = function () {
        this._originals = [];
        this.entities = [];
        if (this.domainTargetingComponent) {
            this.domainTargetingComponent.reset();
        }
        while (this.wizard.activeStepIndex !== 0) {
            this.wizard.previousStep();
        }
    };
    return BulkEditDomainTargetingComponent;
}());
export { BulkEditDomainTargetingComponent };
