import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Seller } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
var SellerRepository = /** @class */ (function (_super) {
    tslib_1.__extends(SellerRepository, _super);
    function SellerRepository(http) {
        return _super.call(this, http, '/seller', Seller) || this;
    }
    return SellerRepository;
}(BackendRepository));
export { SellerRepository };
