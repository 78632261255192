import { Model } from './model';

export class Buyer extends Model {
    dspId: number;
    dspName: string;
    buyerSeat: string;
    name: string;
    isBidswitchRequired: boolean;

    serialize(): string {
        return JSON.stringify(this);
    }

    get entity() {
        return 'Buyer';
    }
}
