import * as tslib_1 from "tslib";
import { Filter } from '../filter';
import { Operation } from '../operation';
var FilterId = /** @class */ (function (_super) {
    tslib_1.__extends(FilterId, _super);
    function FilterId() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.operations = [
            Operation.Equals,
            Operation.DoesNotEqual
        ];
        return _this;
    }
    FilterId.prototype.isValid = function () {
        return _super.prototype.isValid.call(this) && this.query > 0;
    };
    Object.defineProperty(FilterId.prototype, "query", {
        get: function () {
            return this._query;
        },
        set: function (value) {
            var parsed = parseInt(value);
            if (!isNaN(parsed)) {
                this._query = parsed;
            }
        },
        enumerable: true,
        configurable: true
    });
    return FilterId;
}(Filter));
export { FilterId };
