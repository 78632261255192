import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, BehaviorSubject, combineLatest, of } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { NotificationsService } from './notifications.service';
import { AdvertiserRepository, MediaGroupRepository, LineItemRepository, UserRepository } from './repositories';
import { Advertiser, MediaGroup, LineItem, Account, Preferences } from 'app/shared/models';
import { map, filter, bufferWhen, debounceTime, mergeMap, take, tap } from 'rxjs/operators';
import { decorateAdvertisersWithNames, getManagersExecutivesForAdvertisers } from 'app/shared/helpers/advertiser-helper';
import { decorateMediaGroupsWithNames, getManagersExecutivesForMediaGroups } from 'app/shared/helpers/media-group-helper';

@Injectable()
export class PreferencesService {
    user = this.authenticationService.currentUser;

    meta = new BehaviorSubject<Preferences>(new Preferences());
    private metaUpdates$ = new ReplaySubject<Preferences>();
    accounts = new BehaviorSubject<Account[]>([]);
    advertisers = new ReplaySubject<Advertiser[]>(1);
    mediaGroups = new ReplaySubject<MediaGroup[]>(1);
    watchedLineItems = new ReplaySubject<LineItem[]>(1);
    isLoading = false;

    private userHashId: string;
    private userVersion: number;

    constructor(
        private authenticationService: AuthenticationService,
        private advertiserRepository: AdvertiserRepository,
        private mediaGroupRepository: MediaGroupRepository,
        private lineItemRepository: LineItemRepository,
        private userRepository: UserRepository,
        private notifications: NotificationsService
    ) {
        combineLatest(this.advertisers, this.mediaGroups).pipe(
            map(([advertisers, mediaGroups]) => [].concat(advertisers, mediaGroups))
        ).subscribe(accounts => this.accounts.next(accounts));

        this.user.pipe(
            mergeMap(user => this.userRepository.getFromLSD(user.hashId))
        ).subscribe(user => {
            this.meta.next(Object.assign(this.meta.getValue(), user.meta));
            
            this.userHashId = user.hashId;
            this.userVersion = user.version;
        });

        this.meta.pipe(filter(meta => meta !== null)).subscribe(meta => {
            this.findAdvertisers(meta.advertiserIds).subscribe(advertisers => this.advertisers.next(advertisers));
            this.findMediaGroups(meta.mediaGroupIds).subscribe(mediaGroups => this.mediaGroups.next(mediaGroups));
            this.findLineItems(meta.watchedLineItemIds).subscribe(lineItems => this.watchedLineItems.next(lineItems));
        });

        this.metaUpdates$.pipe(
            tap(() => this.isLoading = true),
            bufferWhen(() => this.metaUpdates$.pipe(debounceTime(5000))),
            filter(updates => updates.length > 0), // because it was buffered up
            mergeMap(updates => this.updateMeta(updates[updates.length - 1]).pipe(take(1))), // latest change wins
        ).subscribe(
            () => {
                this.isLoading = false;

                return this.notifications.success('Your preferences have successfully been saved.')
            },
            () => {
                this.isLoading = false;

                return this.notifications.error('There was an error updating your preferences.')
            }
        );
    }

    watchLineItem(lineItem: LineItem) {
        const meta = this.meta.getValue();
        meta.watchedLineItemIds.push(lineItem.id);
        this.metaUpdates$.next(meta);
    }

    unwatchLineItem(lineItem: LineItem) {
        const meta = this.meta.getValue();
        meta.watchedLineItemIds = meta.watchedLineItemIds.filter(existingId => existingId !== lineItem.id);
        this.metaUpdates$.next(meta);
    }

    isWatchedLineItem(lineItem: LineItem): Observable<boolean> {
        return this.watchedLineItems.pipe(
            mergeMap(lineItems => of(lineItems.map(lineItem => lineItem.id))),
            map(existingIds => existingIds.indexOf(lineItem.id) > -1));
    }

    setAccounts(advertisers: Advertiser[], mediaGroups: MediaGroup[]) {
        const advertiserIds = advertisers.map(advertiser => advertiser.id);
        const mediaGroupIds = mediaGroups.map(mediaGroup => mediaGroup.id);
        return this.updateMeta({ advertiserIds, mediaGroupIds });
    }

    removeAccount(account) {
        const [advertisers, mediaGroups] = this.filterAccounts(this.accounts.getValue(), account);
        return this.setAccounts(advertisers, mediaGroups);
    }

    private partitionAccounts(accounts) {
        const advertisers = [];
        const mediaGroups = [];

        accounts.forEach(account => {
            if (account instanceof Advertiser) {
                advertisers.push(account);
            } else if (account instanceof MediaGroup) {
                mediaGroups.push(account);
            }
        });

        return [advertisers, mediaGroups];
    }

    private filterAccounts(accounts: Account[], account: Account) {
        let [advertisers, mediaGroups] = this.partitionAccounts(accounts);

        if (account instanceof Advertiser) {
            advertisers = advertisers.filter(advertiser => advertiser.refId !== account.refId);
        } else if (account instanceof MediaGroup) {
            mediaGroups = mediaGroups.filter(mediaGroup => mediaGroup.refId !== account.refId);
        }

        return [advertisers, mediaGroups];
    }

    updateMeta(data) {
        const meta = this.meta.getValue() || {};
        Object.keys(data).forEach(property => meta[property] = data[property]);

        return this.userRepository.saveMeta(this.userHashId, {
            version: this.userVersion,
            meta: meta
        }).pipe(tap(user => {
            this.meta.next(user.meta);
            this.userVersion = user.version;
        }));
    }

    private findAdvertisers(ids: string[]): Observable<Advertiser[]> {
        if (ids.length === 0) {
            return of([]);
        }

        return this.advertiserRepository.search({
            conditions: [{ field: 'id', value: ids }]
        }).pipe(
            mergeMap(advs => getManagersExecutivesForAdvertisers(advs, this.userRepository)),
            mergeMap(decorateAdvertisersWithNames)
        );
    }

    private findMediaGroups(ids: string[]): Observable<MediaGroup[]> {
        if (ids.length === 0) {
            return of([]);
        }

        return this.mediaGroupRepository.search({
            conditions: [{ field: 'id', value: ids }]
        }).pipe(
            mergeMap(mgs => getManagersExecutivesForMediaGroups(mgs, this.userRepository)),
            mergeMap(decorateMediaGroupsWithNames)
        );
    }

    private findLineItems(ids: string[]): Observable<LineItem[]> {
        if (!ids || ids.length === 0) {
            return of([]);
        }

        return this.lineItemRepository.search({
            conditions: [
                {
                    field: 'id',
                    value: ids
                }
            ],
            returnMode: 'appended',
            return: ['budget', 'spend', 'bidAmount', 'impressions', 'clicks', 'conversions', 'endDate', 'adjustedImpressions']
        });
    }
}
