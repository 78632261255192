import { Filter } from '../filter';
import { Operation } from '../operation';

export class FilterExist extends Filter {
    private _query = '';

    readonly operations: Operation[] = [
        Operation.Exists,
        Operation.DoesNotExist
    ];

    get query() {
        return this._query;
    }

    isValid() {
        return this.operations.includes(this.operation);
    }
}
