import * as tslib_1 from "tslib";
import { Model } from './model';
var Seller = /** @class */ (function (_super) {
    tslib_1.__extends(Seller, _super);
    function Seller() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Seller.prototype.serialize = function () {
        return JSON.stringify(this);
    };
    Object.defineProperty(Seller.prototype, "entity", {
        get: function () {
            return 'Seller';
        },
        enumerable: true,
        configurable: true
    });
    return Seller;
}(Model));
export { Seller };
