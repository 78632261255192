import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { AdvertiserRepository, UserRepository } from 'app/core/repositories';
import { AvailableActionsService } from 'app/shared/helpers/available-actions.service';
import { AuthorizationService } from 'app/core/';
import { HistoryViewComponent } from 'app/shared/components/history-view';
import { PaginatorComponent } from 'app/shared/elements/paginator';
import { IdService } from 'app/core/id.service';
import { TableComponent } from 'app/shared/elements/table';
import { TableHelper } from 'app/shared/helpers/table-helper';
import { mergeMap } from 'rxjs/operators';
import { getManagersExecutivesForTableData, decorateTableDataWithNames } from 'app/shared/helpers/advertiser-helper';
var AdvertisersTableComponent = /** @class */ (function () {
    function AdvertisersTableComponent(availActions, advertiserRepository, userRepository, authorization, router, id) {
        var _this = this;
        this.availActions = availActions;
        this.advertiserRepository = advertiserRepository;
        this.userRepository = userRepository;
        this.authorization = authorization;
        this.router = router;
        this.id = id;
        this.helper = new TableHelper(function (params) { return _this.adapter(params); });
    }
    AdvertisersTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.helper.table = this.table;
        this.helper.paginator = this.paginator;
        combineLatest(this.table.query, this.paginator.query).subscribe(function (args) { return _this.helper.search(args); });
    };
    Object.defineProperty(AdvertisersTableComponent.prototype, "advertisers", {
        set: function (value) {
            this._ids = value;
            this.paginator.reset();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdvertisersTableComponent.prototype, "setMediaGroup", {
        set: function (id) {
            this.mediaGroup = id;
            this.paginator.reset();
        },
        enumerable: true,
        configurable: true
    });
    AdvertisersTableComponent.prototype.adapter = function (params) {
        var _this = this;
        if (typeof this.mediaGroup === 'string') {
            params.conditions.push({ field: 'mediaGroup', value: this.mediaGroup });
        }
        if (Array.isArray(this._ids)) {
            params.conditions.push({
                field: 'id',
                value: this._ids
            });
        }
        return this.advertiserRepository.asyncSearch(params).pipe(mergeMap(function (data) { return getManagersExecutivesForTableData(data, _this.userRepository); }), mergeMap(decorateTableDataWithNames));
        ;
    };
    AdvertisersTableComponent.prototype.availableActionHandler = function (action, advertiser) {
        if (action === 'edit') {
            this.router.navigate(['/campaign-manager/advertisers', advertiser.refId, 'edit',
                { redirect: btoa(this.router.url) }]);
        }
        else if (action === 'history') {
            this.historyView.viewAdvertiserHistory(advertiser);
        }
    };
    Object.defineProperty(AdvertisersTableComponent.prototype, "canCreateAdvertiser", {
        get: function () {
            return this.authorization.isInternalUser
                || this.authorization.isMediaGroupDemand
                || this.authorization.isMediaGroupManager
                || this.authorization.isPublisherDemand
                || this.authorization.isPublisherManager
                || this.authorization.isAgencyDemand
                || this.authorization.isAgencyManager;
        },
        enumerable: true,
        configurable: true
    });
    return AdvertisersTableComponent;
}());
export { AdvertisersTableComponent };
