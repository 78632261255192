import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BackendRepository } from './backend-repository';
import { Blueprint } from 'app/shared/models/blueprint';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SupplyAssetPayload } from 'app/shared/models/native-supply/supply-asset';
import { StatusId } from 'app/shared/models/status';

@Injectable()
export class BlueprintRepository extends BackendRepository<Blueprint> {
    public constructor(http: HttpClient) {
        super(http, '/blueprint', Blueprint);
    }

    basicSearch(mediaGroupId: number): Observable<Blueprint[]> {
        return this.consume(page => {
            return this.http.post(
                this.url(`/search/blueprint`),
                { 'mediaGroupId' : mediaGroupId, 'status' : StatusId.ACTIVE_ID, 'page': page}
            );
        });
    }

    public confirm(body: Blueprint) {
        const params = [this.path, 'confirm'];
        if (body.id) {
            params.push(body.id);
        }
        return this.http.post(this.url(...params), body.serialize());
    }

    linkAdSlot(id: string, body: any) {
        return this.http
            .post(this.url('/blueprint/link-ad-slots', id), body)
            .pipe(map(response => response['output']));
    }

    unlinkAdSlot(id: string, body: any) {
        return this.http
            .post(this.url('/blueprint/unlink-ad-slots', id), body)
            .pipe(map(response => response['output']));
    }

    confirmAssets(id: string, body: SupplyAssetPayload[], creatives?: Number[]): Observable<any> {
        const payload = {"assets": body.map(({ id, version, ...rest }) => rest)};
        if (creatives) {
            payload['creatives'] = creatives;
        }
        return this.http.post(this.url('/blueprint/confirm-assets', id), payload);
    }
}
