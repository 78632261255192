import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
var UtilRepository = /** @class */ (function () {
    function UtilRepository(http) {
        this.http = http;
    }
    UtilRepository.prototype.demandSearch = function (query) {
        return this.http.get(this.url('/search/demand', query))
            .pipe(map(function (response) { return response['output']; }));
    };
    UtilRepository.prototype.supplySearch = function (query) {
        return this.http.get(this.url('/search/supply', query))
            .pipe(map(function (response) { return response['output']; }));
    };
    UtilRepository.prototype.strategyLaunch = function (params, strategyId) {
        return this.http.post(this.url('/strategy/launch', strategyId), JSON.stringify(params));
    };
    UtilRepository.prototype.parseExcel = function (params) {
        return this.http.post(this.url('/util/parse-excel'), JSON.stringify(params))
            .pipe(map(function (response) { return response['output']; }));
    };
    /**
     * Builds and returns a URL for the backend API given an array of strings
     * `suffix` which are joined using a forward-slash.
     * @param suffix
     */
    UtilRepository.prototype.url = function () {
        var suffix = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            suffix[_i] = arguments[_i];
        }
        return environment.backend.hostname + suffix.join('/');
    };
    return UtilRepository;
}());
export { UtilRepository };
