import { QueryList } from '@angular/core';
import { OptionComponent } from '../filter-select';
var OptionGroupComponent = /** @class */ (function () {
    function OptionGroupComponent() {
        this.optionsCache = null;
    }
    Object.defineProperty(OptionGroupComponent.prototype, "options", {
        get: function () {
            if (!this.optionsCache) {
                if (this.optionComponents && this.optionComponents.length > 0) {
                    this.optionsCache = this.optionComponents.map(function (option, key) {
                        return {
                            key: key,
                            value: option.value,
                            label: option.html
                        };
                    });
                }
                else {
                    this.optionsCache = [];
                }
            }
            return this.optionsCache;
        },
        enumerable: true,
        configurable: true
    });
    return OptionGroupComponent;
}());
export { OptionGroupComponent };
