import { Component, Output, EventEmitter, ViewChild } from '@angular/core';

import { LineItemRepository } from 'app/core/repositories';

@Component({
    selector: 'delete-line-item-dialog',
    templateUrl: './delete-line-item-dialog.html'
})
export class DeleteLineItemDialogComponent {
    @Output() private deleted: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('dialog', { static: true }) private dialog;

    lineItem: any = null;

    constructor(private lineItemRepository: LineItemRepository) {}

    open(lineItem): void {
        this.lineItem = lineItem;
        this.dialog.open();
    }

    delete(): void {
        this.lineItemRepository
            .delete(this.lineItem.id)
            .subscribe(() => this.deleted.emit(this.lineItem));
    }
}
