import { NgModule } from '@angular/core';

import { ComponentsModule } from './components';
import { DirectivesModule } from './directives';
import { ElementsModule } from './elements';
import { PipesModules } from './pipes';

@NgModule({
    imports: [
        ComponentsModule,
        DirectivesModule,
        ElementsModule,
        PipesModules
    ],
    exports: [
        ComponentsModule,
        DirectivesModule,
        ElementsModule,
        PipesModules
    ]
})
export class SharedModule { }
