import {Attribute, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';

import { Model } from "app/shared/models";
import { CategoryRepository } from "app/core/repositories";
import { Resettable } from "app/shared/components/bulk-edit-lightbox";
import { SearchableSelectSingleComponent } from "app/shared/elements/searchable-select-single";
import { TagsInputComponent } from "app/shared/elements/tags-input";
import {clone} from "app/core/utils";

@Component({
    selector: 'bulk-edit-iab-categories',
    templateUrl: './bulk-edit-iab-categories.component.html'
})
export class BulkEditIABCategoriesComponent implements OnInit, Resettable {
    private _entities: Model[] = [];
    private _originals: Model[] = [];
    label: string;
    multiple: boolean;
    entityName: string = '';
    field: string;
    categories = [];
    @ViewChild("singleSelect", { static: false }) singleSelect: SearchableSelectSingleComponent;
    @ViewChild("multiSelect", { static: false }) multiSelect: TagsInputComponent;
    @ViewChildren("singleSelectEntity") singleSelectEntities: QueryList<SearchableSelectSingleComponent>
    @ViewChildren("multiSelectEntity") multiSelectEntities: QueryList<TagsInputComponent>

    constructor(
        @Attribute('label') label: string,
        @Attribute('multiple') multiple: boolean,
        private categoryRepository: CategoryRepository
    ) {
        this.multiple = multiple !== null;
        this.label = label;
    }

    ngOnInit() {
        this.categoryRepository.searchAsOptions().subscribe(categories => this.categories = categories);
    }

    master(value: string) {
        this.entities.forEach(entity => entity[this.field] = value);
    }

    original(index: number) {
        return this._originals[index];
    }

    set entities(entities: Model[]) {
        this._entities = entities;
        this._originals = entities.map(entity => entity.clone());
    }

    get entities() {
        return this._entities;
    }

    reset() {
        if (this.isSingleSelectOption()) {
            this.singleSelect.resetDropDown();
        }
        if (this.isMultiSelectOption()) {
            this.multiSelect.removeAll();
        }
        this.entities.forEach(
            (entity, index) => entity[this.field] = this.original(index)[this.field]
        );
    }

    isSingleSelectOption() {
        return this.singleSelect !== undefined && !this.multiple;
    }

    isMultiSelectOption() {
        return this.multiSelect !== undefined && this.multiple;
    }
}
