import { Email } from 'app/shared/models';
import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ProwlerRepository } from 'app/core/repositories';
import { ProwlerError, ProwlerNewsletter } from 'app/shared/models/prowler';
import moment from 'moment';

@Injectable()
export class ProwlerService {
    private lastSeenCache = new Map<number, Observable<Email>>();

    constructor(
        private prowlerRepository: ProwlerRepository
    ) { }

    getNewsletterLastSeen(id: number) {
        if (!this.lastSeenCache.has(id)) {
            const prowlerResponse = this.prowlerRepository
                .filter({conditions: [{field: 'id', value: id, operator: 'eq'}]}, 'newsletters')
                .pipe(shareReplay(1))
                .pipe(map((n) => {
                    const newsletter: ProwlerNewsletter = n.data[0];
                    if (newsletter) {
                        return new Email({
                            fileURL : newsletter.lastFileURL,
                            updatedAt : newsletter.lastSeenAt
                        });
                    }
                }));

            this.lastSeenCache.set(id, prowlerResponse);
        }
        return this.lastSeenCache.get(id);
    }

    getEmailLastSeen(newsletterRefId: number): Observable<Email> {
        const params = {
            conditions: [{ field: 'newsletterId', value: newsletterRefId, operator: 'eq' }],
            page: { size: 1 },
            sort: "-seenAt"
        };
        return this.prowlerRepository
            .filter(params, 'emails')
            .pipe(map(res => res.data))
            .pipe(map((data) => {
                if (data.length) {
                    return new Email({
                        refId: data[0].id,
                        newsletterId: data[0].newsletterId,
                        publisherId: data[0].publisherId,
                        placementId: data[0].placementId,
                        listId: data[0].listId, 
                    });
                }
                return new Email();
            }));
    }

    getErrors(emailRefId: number, adSlotRefId: number): Observable<ProwlerError[]> {
        const conditions = [];
        if (emailRefId) {
            conditions.push({ field: 'emailId', value: emailRefId, operator: 'eq' });
        }
        if (adSlotRefId) {
            conditions.push({ field: 'adSlotId', value: adSlotRefId, operator: 'eq' });
        }
        return this.prowlerRepository
            .filter({ conditions }, 'errors')
            .pipe(map(res => res.data))
            .pipe(map((data) => {
                if (data.length) {
                    return data.map((i: any) => new ProwlerError({
                        message: i.message,
                        rule: {
                            displayName: i.rule.display_name,
                            preventsActivation: i.rule.prevents_activation
                        },
                        createdAt: i.created_at
                    }));
                }
                return [];
            }));
    }
}
