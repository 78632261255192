import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, forkJoin, of } from 'rxjs';
import { Creative, CreativeMeta } from 'app/shared/models/creative';
import { BackendRepository, Query } from './backend-repository';
import { ESParams } from 'app/shared/helpers/es-query-builder';
import { map, mergeMap } from 'rxjs/operators';

@Injectable()
export class CreativeRepository extends BackendRepository<Creative> {
    public constructor(http: HttpClient) {
        super(http, '/creative', Creative);
    }

    public setSearchReturnFields(params) {
        this._searchReturnFields = params;
    }

    asyncLineItemSearch(query?: {}): Observable<{ items: Creative[], page: number, pages: number, total: number}> {
        return this.rawLineItemSearch(query)
            .pipe(map(data => ({
                items: data['output'].map(item => this.build(item) as Creative),
                page: 1,
                pages: data['pages'],
                total: data['total']
            })));
    }
    asyncCreativeSearch(query?: {}): Observable<{ items: CreativeMeta[]}> {
        return this.http.post(this.url('/mc/creatives', 'search'), query)
            .pipe(map(data => ({
                items: data['data'].map(item => this.build(item) as unknown as CreativeMeta),
                page: 1,
                total: data['meta']['total']
            })));
    }
    public confirm(creative: Creative) {
        const params = [this.path, 'confirm'];
        if (creative.id) {
            params.push(creative.id);
        }

        return this.http.post(this.url(...params), creative.serialize());
    }

    public lineItemSearch(query?: {}): Observable<Creative[]> {
        return this.http.post(this.url('/search' + this.path, 'strategy'), query)
            .pipe(map(response => response['output']
                .map(data => this.build(data)) as Creative[]));
    }

    public searchNativeCreatives(query?: {}): Observable<Creative[]> {
        return this.http.post(this.url('/search' + this.path, 'native'), query)
            .pipe(map(response => response['output']
                .map(data => this.build(data)) as Creative[]));
    }

    // Takes in 3 different creative searches and combines them all
    // - display creatives
    // - native creatives (native endpoint where blueprints attached to creatives)
    // - native creative trafficking creatives (native endpoint where there are no blueprints attached to the creatives)
    public searchAllCreatives(params: any, nativeParams: any, nativeCreativeTraffickingParams: any): Observable<Creative[]> {
        return this.consume(page => {
            // Clone both params and try to send
            // requests as few as possible
            const q = JSON.parse(JSON.stringify(params));
            q.page = page;
            q.n = 500;

            const nq = JSON.parse(JSON.stringify(nativeParams));
            nq.page = page;
            nq.n = 500;

            const nctq = JSON.parse(JSON.stringify(nativeCreativeTraffickingParams));
            nctq.page = page;
            nctq.n = 500;

            return forkJoin(
                this.asyncSearch(q),
                this.asyncSearch(nq, '/search/creative/native'),
                this.asyncSearch(nctq, '/search/creative/native')
            ).pipe(
                map(([nonNative, native, nativeCreativeTrafficking]) => {
                    return {
                        output: [].concat(...nonNative['items'], ...native['items'], ...nativeCreativeTrafficking['items']),
                        page: params.page,
                        pages: nonNative['pages'] + native['pages'] + nativeCreativeTrafficking['pages'],
                        total: nonNative['total'] + native['total'] + nativeCreativeTrafficking['total']
                    };
                })
            );
        });
    }

    private rawLineItemSearch(query?: {}) {
        return this.http.post(this.url('/search' + this.path, 'strategy'), query);
    }

    public searchByLineItem(id: string | string[]): Observable<Creative[]> {
        return this.lineItemSearch({
            conditions: [
                {
                    field: 'strategyId',
                    value: id
                }
            ]
        });
    }

    public searchByLineItemAll(id: string | string[]): Observable<Creative[]> {
        if (typeof id !== 'string' && !Array.isArray(id)) {
            return throwError(new Error('Invalid parameter. Parameter must be string or array of strings.'));
        }

        const query: Query = {
            conditions: [
                {
                    field: 'strategyId',
                    value: id
                }
            ]
        };

        return this.consume(page => {
            const q = JSON.parse(JSON.stringify(query));
            q.page = page;
            return this.rawLineItemSearch(q);
        });
    }

    public searchExchangeCreativeByStrategy(query: {}): Observable<Creative[]> {
        return this.http.post(this.url('/search/exchange-creative/strategy'), query)
            .pipe(map(response => response['output']
                .map(data => this.build(data)) as Creative[]));
    }

    public approve(id: string, key: string, version: number): Observable<Creative> {
        return this.http.post(this.url(this.path, 'approve', id, key), { version: version })
            .pipe(map(response => this.build(response['output'])));
    }

    public reject(id: string, key: string, version: number): Observable<Creative> {
        return this.http.post(this.url(this.path, 'reject', id, key), { version: version })
            .pipe(map(response => this.build(response['output'])));
    }

    public clone(creative: any): any {
        creative.name += ' - COPY';
        delete creative.id;
        delete creative.refId;

        return creative;
    }

    eSearch(params: ESParams) {
        return this.asyncSearch(params, '/lies/search/creatives').pipe(
            mergeMap(creatives => {
                if (!creatives.items || creatives.items.length === 0) {
                    return forkJoin([of(creatives), of({ items: [] })]);
                }

                return forkJoin([of(creatives), this.asyncCreativeSearch({
                    filters: [{
                        field: 'id',
                        value: creatives.items.map(creative => String('li-' + creative.refId)),
                        type: 'or',
                        operator: 'in'
                    }]
                })]);
            }),
            map(([creatives, attributes]) => {
                creatives.items.map(creative => {
                    if (attributes.items.find(label => String(label.creative_external_id) === String(creative.refId))) {
                        creative.categoryLabel = attributes.items
                            .find(label => String(label.creative_external_id) === String(creative.refId))
                            .attributes;
                        const result = creative.categoryLabel.map(label => label.name);
                        creative.categoryLabel = result.join('; ');

                    } else {
                        creative.categoryLabel = '—';
                    }
                });
                return creatives;
            })
        );
    }
}
