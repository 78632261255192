import { Attribute, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Model } from 'app/shared/models';
import { Resettable } from 'app/shared/components/bulk-edit-lightbox';
import { IdService } from 'app/core/id.service';
import {clone} from "app/core/utils";

@Component({
    selector: 'bulk-edit-text',
    templateUrl: './bulk-edit-text.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkEditTextComponent implements Resettable {
    @ViewChild('input', { static: true }) input: NgModel;
    private _entities: Model[] = [];
    private _originals: Model[] = [];
    label: string;
    entityName: string = '';
    field: string;

    constructor(
        @Attribute('label') label: string,
        public id: IdService)
        {
            this.label = label;
        }

    master(value: string) {
        this.entities.forEach(entity => entity[this.field] = clone(value));
    }

    original(index: number) {
        return this._originals[index];
    }

    reset() {
        this.input.reset();
        this.entities.forEach((entity, index) => entity[this.field] = clone(this.original(index)[this.field]));
    }

    resetEntity(entity: Model, index = null) {
        let i = index;
        if (!i) {
            i = this.entities.indexOf(entity);
        }
        entity[this.field] = clone(this.original(i)[this.field]);
    }

    isDirty(): boolean {
        return this.entities.some((entity, index) => entity[this.field] !== this.original(index)[this.field]);
    }

    set entities(entities: Model[]) {
        this._entities = entities;
        this._originals = entities.map(entity => entity.clone());
    }

    get entities() {
        return this._entities;
    }
}
