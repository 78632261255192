import { Component, OnDestroy, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subscription, forkJoin, combineLatest, of } from 'rxjs';
import moment from 'moment';

import {
    AdSlotRepository,
    AdvertiserRepository,
    SamovarAudienceRepository,
    BundleRepository,
    CampaignRepository,
    CategoryRepository,
    CreativeRepository,
    InsertionOrderRepository,
    ISPRepository,
    PixelRepository,
    UtilRepository
} from 'app/core/repositories';

import { AdSlot } from 'app/shared/models';
import { DateHelperService } from 'app/shared/elements/date-picker/date-helper';
import { LightboxComponent } from 'app/shared/elements/elements';
import { Advertiser, Campaign, Creative, InsertionOrder, LineItem, Pixel } from 'app/shared/models';
import { StatusCardComponent } from 'app/shared/elements/status-card/status-card.component';
import { NotificationsService, IdService } from 'app/core';
import { environment } from 'src/environments/environment';
import { map, mergeMap } from 'rxjs/operators';

@Component({
    selector: 'going-live-notification',
    templateUrl: './going-live-notification.html',
    providers: [DateHelperService]
})
export class GoingLiveNotificationComponent implements OnDestroy {

    @ViewChild(LightboxComponent, { static: true }) lightbox: LightboxComponent;

    advertiser: Advertiser;
    campaign: Campaign;
    categories;
    creatives: Creative[];
    insertionOrder: InsertionOrder;
    lineItem: LineItem;
    pixel: Pixel;

    isps: any[] = [];
    bundles: any[] = [];
    liveAudience: any[] = [];
    adSlots: AdSlot[] = [];
    emails = '';

    subscriptions: Subscription[] = [];

    constructor(
        private adSlotRepository: AdSlotRepository,
        private advertiserRepository: AdvertiserRepository,
        private samovarAudienceRepository: SamovarAudienceRepository,
        private bundleRepository: BundleRepository,
        private campaignRepository: CampaignRepository,
        private categoryRepository: CategoryRepository,
        private creativeRepository: CreativeRepository,
        private dateHelper: DateHelperService,
        private insertionOrderRepository: InsertionOrderRepository,
        private ispRepository: ISPRepository,
        private pixelRepository: PixelRepository,
        private utilRepository: UtilRepository,
        private notifications: NotificationsService,
        public id: IdService
    ) { }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }

    fetchData() {
        return forkJoin(
            this.categoryRepository.all(),
            this.advertiserRepository.get(this.lineItem.advertiser),
            this.campaignRepository.get(this.lineItem.campaign),
            this.creativeRepository.searchByLineItem(this.lineItem.id),
            this.fetchAdSlots(this.lineItem.adSlots || [])
        ).pipe(mergeMap(([categories, advertiser, campaign, creatives, adSlots]) => {
            const pixel = campaign.hasConversionTracker() ?
                this.pixelRepository.get(campaign.conversionPixel) : of(null);

            return combineLatest(this.insertionOrderRepository.get(campaign.insertionOrder), pixel).pipe(
                map(([insertionOrder, pixel]) => {
                    return [categories, advertiser, insertionOrder, campaign, creatives, pixel, adSlots];
                })
            );
        }));
    }

    private fetchAdSlots(adSlotIds: string[]) {
        if (adSlotIds.length === 0) {
            return of([]);
        }

        return this.adSlotRepository.search({
            conditions: [{
                field: 'id',
                value: adSlotIds
            }]
        });
    }

    init(lineItem: LineItem): void {

        if (this.lineItem && this.lineItem.id === lineItem.id) {
            this.lightbox.open();
            this.findMappingInfo();
            return;
        }

        this.lineItem = lineItem;
        const subscription = this.fetchData()
            .subscribe(([categories, advertiser, insertionOrder, campaign, creatives, pixel, adSlots]) => {
                this.categories = categories;
                this.advertiser = advertiser;
                this.insertionOrder = insertionOrder;
                this.campaign = campaign;
                this.creatives = creatives;
                this.pixel = pixel;
                this.adSlots = adSlots;

                this.advertiser['categoryIAB'] = this.categories.find(category => {
                    return parseInt(category.id) === advertiser.category;
                });
                this.campaign['categoryIAB'] = this.categories.find(category => {
                    return parseInt(category.id) === campaign.category;
                });

                this.lightbox.open();
                this.findMappingInfo();
            });

        this.subscriptions.push(subscription);
    }

    findMappingInfo() {
        if (this.lineItem.audienceTargeting) {
            this.samovarAudienceRepository.searchByAccountId(this.lineItem.advertiserObj.accountId)
                .subscribe(audiences => {
                    this.liveAudience = audiences.map(function (audience, i) {
                        return {
                            key: i,
                            value: audience.uniqueSegmentId,
                            label: '(ID: ' + audience.uniqueSegmentId + ') ' + audience.name,
                            data: audience
                        };
                    });
                });
        }

        if (this.lineItem.categories) {
            this.categoryRepository.all().subscribe(options => {
                this.categories = options.map(option => {
                    return {
                        key: option.id,
                        value: option.id,
                        label: [option.iab, option.name].join(': '),
                        data: option
                    };
                });
            });
        }

        if (this.lineItem.bundles) {
            this.bundleRepository.all().subscribe(bundles => {
                this.bundles = bundles.map(option => {
                    return {
                        key: option.id,
                        value: option.id,
                        label: option['name']
                    };
                });
            });
        }

        if (this.lineItem.isps) {
            this.ispRepository.all().subscribe(isps => {
                this.isps = isps.map(option => {
                    return {
                        key: option.id,
                        value: option.id,
                        label: option['name']
                    };
                });
            });
        }

    }

    send() {
        let emailArr: string[] = [];
        if (environment.emailGroup && environment.emailGroup.goingLive) {
            emailArr = environment.emailGroup.goingLive;
        }
        if (this.emails.length > 0) {
            emailArr = emailArr.concat(this.emails.split(','));
            emailArr = emailArr.map(email => email.trim());
        }

        if (emailArr.length < 1) {
            this.lightbox.close();
            return;
        }

        const datesToUse = this.dateHelper.determineDatesToUse(this.lineItem, this.insertionOrder);
        const datePipe = new DatePipe('en-US');

        let statusCard = new StatusCardComponent();
        statusCard.status = this.lineItem.getPlatformStatus();
        statusCard.startDate = datesToUse.startDate;
        statusCard.endDate = datesToUse.endDate;
        statusCard.compareDate = moment() as any;
        statusCard.ngOnInit();

        let params = {
            emails: emailArr,
            status: statusCard.status,
            statusText: statusCard.statusText ?
                statusCard.statusText + ' ' + datePipe.transform(statusCard.dateToUse, 'shortDate') : null,
            goalDescription: this.campaign.goalDescription
        };

        this.utilRepository.strategyLaunch(params, this.lineItem.refId)
            .subscribe(() => {
                this.lightbox.close();
                this.notifications.success('Line item launch notification sent');
            }, () => {
                this.notifications.error('There was a problem sending the notification');
            });
    }
}
