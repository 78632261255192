import { Component, Input, ElementRef, OnInit } from '@angular/core';

@Component({
    selector: 'pacing-pill',
    templateUrl: './pacing-pill.html',
    styleUrls: ['./pacing-pill.styl']
})
export class PacingPillComponent implements OnInit {
    @Input('actual') _actual: number;
    @Input('expected') _expected: number;
    @Input('total') _total: number;
    @Input('spend') _spend: number;
    @Input() threshold: number;
    @Input() start: Date;
    @Input() end: Date;
    @Input() index: number = null;
    @Input() length: number;
    @Input() budgetType: string;

    position: string;
    top: number;

    private static NOT_DELIVERING = 'Not Delivering';
    private static PACING = 'Pacing';
    private static PACED = 'Paced';
    private static NOW = (new Date()).getTime();
    private static MS_PER_DAY = 86400000;
    constructor(public el: ElementRef) { }

    ngOnInit() {
        if (this.index === null) {
            this.top = 25;
            this.position = 'bottom';
            return;
        }
        if (this.index === 0 && this.length === 1) {
            return this.position = 'right';
        } else if (this.index === 0) {
            this.top = 25;
            return this.position = 'bottom';
        } else {
            this.top = -75;
            return this.position = 'top';
        }
    }
    get status() {
        let state = PacingPillComponent.PACING;
        if (this.end !== undefined && PacingPillComponent.NOW
            >= (this.end.getTime() + PacingPillComponent.MS_PER_DAY)) {
            state = PacingPillComponent.PACED;
        }
        else if (!this.delivering) {
            return PacingPillComponent.NOT_DELIVERING;
        }
        return state + ' ' + this.pacing.toString() + '%';
    }

    get isPaced() {
        return this.end !== undefined && PacingPillComponent.NOW
            >= (this.end.getTime() + PacingPillComponent.MS_PER_DAY);
    }

    get expected() {
        return 100 / this._total * this._expected;
    }

    get actual() {
        return 100 / this._total * this._spend;
    }

    get overpacing() {
        return this.actual > this.expected;
    }

    get pacing() {
        if (this._actual === 0 || this._total === 0) {
            return 0;
        }
        return Math.round((this._actual / this._total) * 100);
    }

    get total() {
        return this._total;
    }

    get delivering() {
        if (this.start === undefined) {
            return false;
        }
        return PacingPillComponent.NOW >= this.start.getTime()
            && PacingPillComponent.NOW < (this.end.getTime() + PacingPillComponent.MS_PER_DAY);
    }

    get budgetTypeText(): string {
        switch (this.budgetType) {
            case 'adjusted_impressions':
                return 'Impressions';
            case 'impressions':
                return 'Impressions';
            default:
                return 'Spend';
        }
    }

    findMin(a: number, b: number) {
        return Math.min(a, b);
    }

    findMax(a: number, b: number) {
        return Math.max(a, b);
    }
}
