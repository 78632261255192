import { TemplateRef, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import moment from 'moment';
import { AuthorizationService } from 'app/core';
import { DeleteNewsletterDialogComponent } from 'app/shared/components/delete-newsletter-dialog';
import { IdService } from 'app/core/id.service';
import { InventoryDataService } from 'app/shared/services/inventory-data.service';
import { NewsletterFormComponent } from 'app/shared/components/newsletter-form';
import { NewsletterRepository } from 'app/core/repositories/newsletter-repository.service';
import { NewsletterTagsComponent } from 'app/shared/components/newsletter-tags';
import { PaginatorComponent } from 'app/shared/elements/paginator';
import { TableHelper } from 'app/shared/helpers/table-helper';
import { TableComponent } from 'app/shared/elements/table';
import { CategoryRepository } from 'app/core/repositories';
import { NewsletterBulkEditComponent } from "app/shared/components/newsletter-bulk-edit";
import { Flag, LaunchDarklyService } from "app/core/launch-darkly.service";
var NewslettersTableRowAction;
(function (NewslettersTableRowAction) {
    NewslettersTableRowAction[NewslettersTableRowAction["Edit"] = 0] = "Edit";
    NewslettersTableRowAction[NewslettersTableRowAction["ViewCode"] = 1] = "ViewCode";
    NewslettersTableRowAction[NewslettersTableRowAction["Delete"] = 2] = "Delete";
    NewslettersTableRowAction[NewslettersTableRowAction["BulkEdit"] = 3] = "BulkEdit";
    NewslettersTableRowAction[NewslettersTableRowAction["BulkEditName"] = 4] = "BulkEditName";
    NewslettersTableRowAction[NewslettersTableRowAction["BulkEditCategory"] = 5] = "BulkEditCategory";
    NewslettersTableRowAction[NewslettersTableRowAction["BulkEditESP"] = 6] = "BulkEditESP";
    NewslettersTableRowAction[NewslettersTableRowAction["BulkEditMisc"] = 7] = "BulkEditMisc";
    NewslettersTableRowAction[NewslettersTableRowAction["BulkEditPrivateDeals"] = 8] = "BulkEditPrivateDeals";
    NewslettersTableRowAction[NewslettersTableRowAction["BulkEditAllowCoordination"] = 9] = "BulkEditAllowCoordination";
    NewslettersTableRowAction[NewslettersTableRowAction["BulkEditRequireUniqueAds"] = 10] = "BulkEditRequireUniqueAds";
})(NewslettersTableRowAction || (NewslettersTableRowAction = {}));
var newslettersTableRowActions = [
    {
        key: 0,
        value: NewslettersTableRowAction.Edit,
        label: 'Edit'
    },
    {
        key: 1,
        value: NewslettersTableRowAction.ViewCode,
        label: 'View Code'
    },
    {
        key: 2,
        value: NewslettersTableRowAction.Delete,
        label: 'Delete'
    }
];
var NewslettersTableComponent = /** @class */ (function () {
    function NewslettersTableComponent(newsletterRepository, inventoryData, id, categoryRepository, launchDarklyService, authorizationService) {
        var _this = this;
        this.newsletterRepository = newsletterRepository;
        this.inventoryData = inventoryData;
        this.id = id;
        this.categoryRepository = categoryRepository;
        this.launchDarklyService = launchDarklyService;
        this.authorizationService = authorizationService;
        this.helper = new TableHelper(function (params) { return _this.adapter(params); });
        this.NewsletterAction = NewslettersTableRowAction;
        this.rowActions = newslettersTableRowActions;
        this.maxDate = moment();
        this.canSetCoordination$ = new BehaviorSubject(false);
        this.canSetCoordination = false;
        this.NewslettersTableRowAction = NewslettersTableRowAction;
    }
    NewslettersTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.helper.table = this.table;
        this.helper.paginator = this.paginator;
        combineLatest(this.table.query, this.paginator.query).subscribe(function (args) { return _this.helper.search(args); });
        this.primaryIABCategories$ = this.primaryCategories();
        this.launchDarklyService
            .getVariation(Flag.AllowCoordination)
            .pipe(distinctUntilChanged())
            .subscribe(function (canSetCoordination) {
            _this.canSetCoordination$.next(canSetCoordination);
        });
        this.canSetCoordination$.subscribe(function (value) {
            _this.canSetCoordination = value;
        });
    };
    NewslettersTableComponent.prototype.adapter = function (params) {
        if (typeof this.mediaGroup === 'string') {
            params.conditions.push({ field: 'mediaGroup', value: this.mediaGroup });
        }
        params.return = ['publisherRefId', 'lastSeen', 'categoryName'];
        params.returnMode = 'appended';
        return this.newsletterRepository.asyncSearch(params);
    };
    Object.defineProperty(NewslettersTableComponent.prototype, "setMediaGroup", {
        set: function (id) {
            this.mediaGroup = id;
            this.paginator.reset();
        },
        enumerable: true,
        configurable: true
    });
    NewslettersTableComponent.prototype.actionHandler = function (action, newsletters) {
        switch (action) {
            case NewslettersTableRowAction.Delete:
                this.deleteNewsletterDialog.open(newsletters[0]);
                break;
            case NewslettersTableRowAction.ViewCode:
                this.viewCode.openModal(newsletters[0]);
                break;
            case NewslettersTableRowAction.Edit:
                this.newsletterForm.openForEdit(newsletters[0]);
                break;
            case NewslettersTableRowAction.BulkEdit:
                this.bulkEditor.open(newsletters);
                break;
            case NewslettersTableRowAction.BulkEditName:
                this.bulkEditor.open(newsletters, 'name');
                break;
            case NewslettersTableRowAction.BulkEditCategory:
                this.bulkEditor.open(newsletters, 'category');
                break;
            case NewslettersTableRowAction.BulkEditESP:
                this.bulkEditor.open(newsletters, 'esp');
                break;
            case NewslettersTableRowAction.BulkEditPrivateDeals:
                this.bulkEditor.open(newsletters, 'privateDeals');
                break;
            case NewslettersTableRowAction.BulkEditAllowCoordination:
                this.bulkEditor.open(newsletters, 'allowCoordination');
                break;
            case NewslettersTableRowAction.BulkEditRequireUniqueAds:
                this.bulkEditor.open(newsletters, 'sspControl.uniqueAds');
                break;
        }
    };
    NewslettersTableComponent.prototype.buildPrimaryCategories = function (categories) {
        return categories.reduce(function (primaryCategory, currentCategory) {
            primaryCategory[currentCategory] = currentCategory;
            return primaryCategory;
        }, {});
    };
    NewslettersTableComponent.prototype.primaryCategories = function () {
        var _this = this;
        return this.categoryRepository.search().pipe(map(function (categories) { return _this.categoryRepository.filterOutSecondary(categories).map(function (category) { return category.name; }); }), map(function (categories) { return _this.buildPrimaryCategories(categories); }));
    };
    NewslettersTableComponent.prototype.isGreaterThan = function (date, days) {
        return moment().diff(date, 'days') > days;
    };
    NewslettersTableComponent.prototype.refresh = function () {
        this.paginator.reset();
        this.table.clearSelections();
    };
    NewslettersTableComponent.prototype.canModify = function (newsletters) {
        var _this = this;
        return newsletters.every(function (newsletter) { return _this.authorizationService.canModifyMonetizationPublisher(newsletter.publisherClientTypes); });
    };
    NewslettersTableComponent.prototype.getRowActions = function (newsletter) {
        if (this.authorizationService.canModifyMonetizationPublisher(newsletter.publisherClientTypes)) {
            return this.rowActions;
        }
        return this.rowActions.filter(function (action) { return action.value === NewslettersTableRowAction.ViewCode; });
    };
    return NewslettersTableComponent;
}());
export { NewslettersTableComponent };
