import { EventEmitter } from '@angular/core';
import { LightboxnextComponent } from '../../lightboxnext';
var ConfigureColumnsComponent = /** @class */ (function () {
    function ConfigureColumnsComponent() {
        this.available = [];
        this.active = [];
        this.lockedColumns = [];
        this.funnel = [];
        this.onConfigured = new EventEmitter();
    }
    Object.defineProperty(ConfigureColumnsComponent.prototype, "activeCount", {
        get: function () {
            return this.active.length;
        },
        enumerable: true,
        configurable: true
    });
    ConfigureColumnsComponent.prototype.addToActive = function (columns) {
        this.active = this.active.concat(columns);
    };
    ConfigureColumnsComponent.prototype.removeFromActive = function (columns) {
        this.available = this.available.concat(columns);
    };
    ConfigureColumnsComponent.prototype.open = function (availableColumns, activeColumns) {
        var _this = this;
        this.reset();
        this.active = activeColumns.filter(function (column) {
            if (column.locked) {
                _this.lockedColumns.push(column);
                return false;
            }
            if (column.name === 'funnel') {
                _this.funnel.push(column);
                return false;
            }
            return true;
        });
        this.available = availableColumns.filter(function (column) { return column.name !== 'funnel'; })
            .filter(function (column) { return activeColumns.findIndex(function (activeColumn) { return activeColumn.name === column.name; }) < 0; });
        this.lightbox.show();
    };
    ConfigureColumnsComponent.prototype.selectColumns = function (columns) {
        columns.unshift.apply(columns, this.lockedColumns);
        columns.unshift.apply(columns, this.funnel);
        this.onConfigured.emit(columns);
        this.lightbox.hide();
    };
    ConfigureColumnsComponent.prototype.reset = function () {
        this.lockedColumns = [];
        this.funnel = [];
    };
    return ConfigureColumnsComponent;
}());
export { ConfigureColumnsComponent };
