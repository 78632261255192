import { OnInit, TemplateRef } from '@angular/core';
import { Status } from 'app/shared/models';
import moment from 'moment';
var StatusCardComponent = /** @class */ (function () {
    function StatusCardComponent() {
        this.status = Status.INACTIVE;
        this.styleGood = 'status-card--good';
        this.styleBad = 'status-card--bad';
        this.styleWarning = 'status-card--warning';
        this.styleInactive = 'status-card--inactive';
    }
    StatusCardComponent.prototype.ngOnInit = function () {
        this._startDate = this.convertStringTimeToMilliseconds(this.startDate);
        this._compareDate = this.convertStringTimeToMilliseconds(this.compareDate);
        this._compareDateMoment = moment(this.compareDate).startOf('day');
        this._endDateMoment = moment(this.endDate);
    };
    StatusCardComponent.prototype.convertStringTimeToMilliseconds = function (dateTypeString) {
        var dateTypeDate = new Date(dateTypeString);
        return dateTypeDate.getTime();
    };
    Object.defineProperty(StatusCardComponent.prototype, "styleClass", {
        get: function () {
            if (!this.status) {
                return this.styleInactive;
            }
            switch (this.status.toUpperCase()) {
                case Status.ACTIVE.toUpperCase(): return this.styleGood;
                case Status.READY.toUpperCase(): return this.styleGood;
                case Status.NOT_READY.toUpperCase(): return this.styleBad;
                case Status.DELIVERING.toUpperCase(): return this.styleGood;
                case Status.NOT_DELIVERING.toUpperCase(): return this.styleBad;
                case Status.PAUSED.toUpperCase(): return this.styleWarning;
                case Status.PAUSED_BLACKOUT.toUpperCase(): return this.styleWarning;
                case Status.COMPLETED.toUpperCase(): return this.styleGood;
                case Status.INACTIVE.toUpperCase(): return this.styleInactive;
                case Status.NEEDS_ACTIVATION.toUpperCase(): return this.styleWarning;
                case Status.WARNING.toUpperCase(): return this.styleWarning;
                case Status.READY_LIMITED.toUpperCase(): return this.styleGood;
                case Status.INELIGIBLE.toUpperCase(): return this.styleBad;
                case Status.EXPIRING_SOON.toUpperCase(): return this.styleWarning;
                case Status.REJECTED.toUpperCase(): return this.styleBad;
                default: return this.styleInactive;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StatusCardComponent.prototype, "willStart", {
        get: function () {
            return this._compareDate < this._startDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StatusCardComponent.prototype, "hasEnded", {
        get: function () {
            return this._compareDateMoment.isAfter(this._endDateMoment);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StatusCardComponent.prototype, "dateToUse", {
        get: function () {
            if (this.willStart) {
                return this.startDate;
            }
            return this.endDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StatusCardComponent.prototype, "statusText", {
        get: function () {
            if (this.hasEnded) {
                return 'Ended';
            }
            return this.willStart ? 'Starts' : 'Ends';
        },
        enumerable: true,
        configurable: true
    });
    return StatusCardComponent;
}());
export { StatusCardComponent };
