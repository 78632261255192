import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Country } from 'app/shared/models';
import { BackendRepository } from './backend-repository';

@Injectable()
export class CountryRepository extends BackendRepository<Country> {
    public constructor(http: HttpClient) {
        super(http, '/country', Country);
    }
}
