import {
    ChangeDetectorRef,
    Component,
    ContentChild,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    forwardRef,
    ElementRef
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, fromEvent } from 'rxjs';
import { FilterPipe } from '../pipes/filter.pipe';
import { Navigable } from '../dropdown';
import { DropdownComponent } from '../dropdown';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IdService } from 'app/core/id.service';

@Component({
    selector: 'searchable-select-single',
    templateUrl: './searchable-select-single.html',
    styleUrls: ['./searchable-select-single.styl'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchableSelectSingleComponent),
            multi: true
        }
    ]
})

export class SearchableSelectSingleComponent extends Navigable implements OnInit, ControlValueAccessor {
    @Input('debounce') DELAY = 0;
    itemList: any[] = [];
    @Input() placeholder: string;
    //Set listLimit to 0 for all options
    @Input() listLimit: number = 5;
    @Input() disabled: false;
    @Input() showListOnFocus: false;

    @Output() remove = new EventEmitter<Object>();
    @Output() searchableSelectSingleBlur: EventEmitter<Object> = new EventEmitter<Object>();
    @Output() inputChange: EventEmitter<Object> = new EventEmitter<Object>();

    @ViewChild(DropdownComponent, { static: true }) dropdown: DropdownComponent;
    @ViewChild('searchInput', { static: true }) editor: ElementRef;
    @ContentChild('template', { static: true }) template: TemplateRef<any> = null;

    onTouchedCallback: () => void = null;
    onChangeCallback: (_: any) => void = null;
    _selected = null;
    input = '';
    filter = new FilterPipe();
    currentValue;
    _showLoading = false;

    private getWidth(): number {
        return this.editor.nativeElement.offsetWidth || '100%';
    }

    set selected(value) {
        this._selected = value;
    }

    get selected() {
        return this._selected;
    }

    constructor(
        public cdr: ChangeDetectorRef,
        public id: IdService
    ) {
        super(cdr);
    }

    ngOnInit() {
        this.refresh(this.input);

        this.dropdown.setWidth(this.getWidth());

        fromEvent(this.editor.nativeElement, 'input').pipe(
            map(() => this.input),
            debounceTime(this.DELAY),
            distinctUntilChanged()
        ).subscribe(input => this.inputChange.emit(input));
    }

    writeValue(value) {
        this.currentValue = value;
        if (value === null && this.onChangeCallback) {
            this.selected = null;
            this.refresh('');
        } else {
            for (let item of this._items) {
                if (item.value === value) {
                    this.selected = item;
                    this.onTouchedCallback();
                    this.refresh('');
                    break;
                }
            }
        }
    }

    registerOnChange(fn) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn) {
        this.onTouchedCallback = fn;
    }

    refresh(input) {
        this.input = input;
        if (this.input === '') {
            this.items = this.listLimit === 0 ? this._items
                : this._items.slice(0, this.listLimit);
        } else {
            this.items = this.listLimit === 0 ? this.filter.transform(this._items, this.input)
            : this.filter.transform(this._items, this.input).slice(0, this.listLimit);
        }
    }

    select(event: Event, index: number) {
        if (index > -1) {
            if (this.items[index]) {
                this.selected = this.items[index];
                this.onChangeCallback(this._selected ? this._selected.value : null);
            }
            else {
                return;
            }
        }
        this.hideDropdown();
    }

    hideDropdown() {
        this.dropdown.hide();
    }

    editorBlur() {
        window.setTimeout(() => {
            this.hideDropdown();
            this.searchableSelectSingleBlur.emit();
            this.onTouchedCallback();
            this.editor.nativeElement.blur();
        }, 200);
    }

    editorFocus() {
        if (this.showListOnFocus) {
            this.refresh(this.input);
        }
        this.editor.nativeElement.classList.remove('input-field--error');
    }

    deselect() {
        if (!this.disabled) {
            this.selected = null;
            this.onChangeCallback(null);
            this.editor.nativeElement.focus();
        }
    }

    resetDropDown() {
        this.selected = null;
        this.refresh('');
    }

    search(value) {
        this.refresh(value);
    }

    keydown(event: KeyboardEvent) {
        if (event.keyCode === 8 && this.input.length < 1) {
            this.remove.emit();
            return;
        }
        super.keydown(event);
    }

    @Input('items')
    set _items(newItems) {
        if (this.itemList && newItems && (this.itemList !== newItems || this.itemList.length !== newItems.length)) {
            this.itemList = newItems;
            this.writeValue(this.currentValue);
            this.showLoading = false;
        }
    }

    get _items() {
        return this.itemList;
    }

    @Input('showLoading')
    set showLoading(showLoading) {
        this._showLoading = showLoading;
    }

    get showLoading() {
        return this._showLoading;
    }
}
