import { ValidationService } from './validation-service';
var Url = /** @class */ (function () {
    /**
     * Create a new Url object from a valid URL string.
     *
     * @param {string} url
     */
    function Url(url) {
        if (!ValidationService.isValidDomain(url) && !ValidationService.isValidUrl(url)) {
            console.warn('Invalid Url: ' + url);
        }
        this.url = url || '';
    }
    Object.defineProperty(Url.prototype, "protocol", {
        /**
         * Get the protocol of the url.
         *
         * @return {string}
         */
        get: function () {
            return this.url.includes('://') ? this.url.substring(0, this.url.indexOf('://') + 3) : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Url.prototype, "hostname", {
        /**
         * Get the hostname of the url.
         *
         * @return {string}
         */
        get: function () {
            return this.url.includes('://') ? this.url.substring(this.url.indexOf('://') + 3) : this.url;
        },
        enumerable: true,
        configurable: true
    });
    return Url;
}());
export { Url };
