import { Model } from './model';

export class Metro extends Model {
    code: string;

    public serialize(): string {
        return JSON.stringify(this);
    }

    get entity() {
        return 'Metro';
    }
}
