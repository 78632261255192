import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { backdropAnimation, lightboxAnimation } from 'app/shared/animations';
import { ThemeService } from 'app/shared/helpers/theme.service';

@Component({
    selector: 'lightboxnext',
    templateUrl: './lightbox.html',
    styleUrls: ['./lightbox.styl'],
    animations: [backdropAnimation, lightboxAnimation]
})
export class LightboxnextComponent {
    @Input() header: string;
    @Output() shown = new EventEmitter();
    @Output() hidden = new EventEmitter();
    visibility = 'hidden';
    isLoading = false;
    action: string;

    constructor(private theme: ThemeService) { }

    show() {
        this.visible = true;
        this.theme.lockPageScroll();
    }

    load(action = 'loading') {
        this.action = action;
        this.isLoading = true;
    }

    stop() {
        this.isLoading = false;
    }

    @HostListener('window:keydown.esc') hide() {
        if (this.isLoading) {
            return;
        }

        this.visible = false;
        this.hidden.emit();
        this.theme.unlockPageScroll();
    }

    set visible(visible: boolean) {
        this.visibility = visible ? 'visible' : 'hidden';
    }

    get isVisible() {
        return this.visibility === 'visible';
    }

    done(event) {
        const emitter = (() => {
            switch (event.toState) {
                case 'visible':
                    return this.shown;
                case 'void':
                    return this.hidden;
            }
            return null;
        })();
        if (emitter === null) {
            return;
        }
        emitter.emit(event);
    }
}
