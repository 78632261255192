import * as tslib_1 from "tslib";
import { Model } from './model';
var DeviceType = /** @class */ (function (_super) {
    tslib_1.__extends(DeviceType, _super);
    function DeviceType() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DeviceType.prototype.serialize = function () {
        return JSON.stringify(this);
    };
    Object.defineProperty(DeviceType.prototype, "entity", {
        get: function () {
            return 'Device Type';
        },
        enumerable: true,
        configurable: true
    });
    return DeviceType;
}(Model));
export { DeviceType };
