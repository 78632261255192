import { AuthorizationService } from 'app/core';
import { IdService } from 'app/core/id.service';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { BlueprintCoords, BlueprintProperties, BlueprintSettings } from 'app/shared/models/blueprint';
import { DomSanitizer } from '@angular/platform-browser';
import { NativeBlueprintService } from 'app/core/native-blueprint.service';
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { FocusedTarget } from '../../blueprint-click.directive';


@Component({
    selector: 'top-master-blueprint',
    templateUrl: 'top-master-blueprint.html',
    styleUrls: ['top-master-blueprint.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class TopMasterBlueprintComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('sponsoredBy', { static: false }) sponsoredBy: ElementRef;
    @ViewChild('image', { static: false }) image: ElementRef;
    @ViewChild('headline', { static: false }) headline: ElementRef;
    @ViewChild('description', { static: false }) description: ElementRef;
    @ViewChild('callToAction', { static: false }) callToAction: ElementRef;

    coord: string;
    targetProperty: string;
    blueprintSettings: BlueprintSettings;
    blueprintProperties = BlueprintProperties;
    blueprintCoords = BlueprintCoords;
    wrapperStyle;

    private _onDestroy = new Subject<void>();

    state = {
        sponsoredBy: {
            value: false,
            modelKey: 'sponsoredBy',
            ref: null
        },
        headline: {
            value: false,
            modelKey: 'headline',
            ref: null
        },
        description: {
            value: false,
            modelKey: 'description',
            ref: null
        },
        callToAction: {
            value: false,
            modelKey: 'callToAction',
            ref: null
        },
        image: {
            value: false,
            modelKey: 'image',
            ref: null
        },
        wrapper: {
            value: false,
            modelKey: 'wrapper',
            ref: null
        }
    };

    constructor(
        private sanitizer: DomSanitizer,
        private cdr: ChangeDetectorRef,
        public authorization: AuthorizationService,
        public id: IdService,
        public blueprintService: NativeBlueprintService
    ) { }

    ngOnInit() {
        this.blueprintService.coord$.pipe(
            takeUntil(this._onDestroy)
        ).subscribe(coord => {
            this.coord = coord;
        });

        this.blueprintService.blueprintSettings$.pipe(
            takeUntil(this._onDestroy)
        ).subscribe(blueprintSettings => {
            this.blueprintSettings = blueprintSettings;
            this.wrapperStyle = this.blueprintService.setWrapperStyling(this.blueprintSettings, 'desktop');
            this.cdr.markForCheck();
        });

        this.blueprintService.targetProperty$.pipe(
            takeUntil(this._onDestroy)
        ).subscribe(targetProperty => {
            this.targetProperty = targetProperty;
            this.cdr.markForCheck();
            Object.keys(this.state).forEach(asset => {
                this.state[asset].value = (asset === targetProperty);
            });
        });
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    ngAfterViewInit() {
        this.addStateRefs();
    }

    setTargetProperty(event: FocusedTarget){
        if(event.pCoord === this.coord && event.insideTemplate){
            this.blueprintService.dispatchTargetProperty(event.targetProperty);
        }
    }

    setState(selectedAsset) {
        Object.keys(this.state).forEach(asset => {
            this.state[asset].value = (asset === selectedAsset);
            if (this.state[asset].value) {
                this.blueprintService.dispatchTargetProperty(this.state[asset].modelKey);
            }
        });
    }

    getState(selectedAsset) {
        return this.state[selectedAsset].value;
    }

    addStateRefs() {
        Object.keys(this.state).forEach(key => {
            this.state[key].ref = this[key];
        });
    }
}
