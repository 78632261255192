import { Component, ElementRef, Input } from '@angular/core';

@Component({
    selector: 'section-card',
    templateUrl: './section-card.html',
    styleUrls: ['./section-card.styl']
})
export class SectionCardComponent {
    @Input() dismissible: boolean = false;
    public state: string;
    public dirty: boolean = true;

    constructor (private el: ElementRef) {}

    close (): void {
        this.el.nativeElement.parentNode.removeChild(this.el.nativeElement);
    }
}
