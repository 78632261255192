import {
    Attribute,
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    EventEmitter,
    Output,
    TemplateRef,
    forwardRef,
    Input
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { IdService } from 'app/core/id.service';

enum State {
    Drag,
    DragError,
    Loaded,
    Empty
}

export type UploadedFile = {
    name: string;
    type: string;
    body: string;
    dataURL: string;
    file: File;
};

@Component({
    selector: 'file-input',
    templateUrl: './file-input.html',
    styleUrls: ['./file-input.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileInputComponent),
            multi: true
        }
    ]
})
export class FileInputComponent implements ControlValueAccessor {
    @ContentChild('loaded', { static: true }) loadedTemplate: TemplateRef<any>;
    @ContentChild('drag', { static: true }) dragTemplate: TemplateRef<any>;
    @ContentChild('dragError', { static: true }) dragErrorTemplate: TemplateRef<any>;
    @ContentChild('empty', { static: true }) emptyTemplate: TemplateRef<any>;
    @Output() private error = new EventEmitter();
    @Input() private showName: boolean = true;

    State = State;
    state = new BehaviorSubject<State>(State.Empty);
    private files: UploadedFile[];

    private onChange = files => { };

    constructor(
        @Attribute('accept') public accept,
        @Attribute('multiple') public multiple,
        public id: IdService
    ) { }

    writeValue(files: UploadedFile[]) {
        this.files = files || [];

        const state = Array.isArray(files) && files.length > 0 ? State.Loaded : State.Empty;
        this.state.next(state);
    }

    registerOnChange(fn: (files: UploadedFile[]) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void) {
        //
    }

    dragenter(event) {
        event.stopPropagation();
        event.preventDefault();

        this.state.next(State.Drag);

        event.dataTransfer.dropEffect = 'copy';
        if (!this.acceptable(Array.from(event.dataTransfer.items))) {
            this.state.next(State.DragError);
        }
    }

    dragleave(event) {
        event.stopPropagation();
        event.preventDefault();

        this.state.next(State.Empty);
    }

    drop(event) {
        event.stopPropagation();
        event.preventDefault();

        this.upload(Array.from(event.dataTransfer.files));
    }

    change(event) {
        event.stopPropagation();
        event.preventDefault();

        this.upload(Array.from(event.target.files));
    }

    private upload(files: File[]) {
        if (!this.acceptable(files)) {
            return this.error.emit();
        }

        const read$ = files.map(file => this.readFile(file));
        forkJoin(read$).subscribe(files => {
            this.state.next(State.Loaded);
            this.files = files;
            this.onChange(files);
        });
    }

    removeFile(index: number) {
        this.files.splice(index, 1);
        this.files = this.files.slice();
        this.onChange(this.files);
        this.state.next(State.Empty);
    }

    private readFile(file: File): Observable<UploadedFile> {
        return new Observable(obs => {

            const reader = new FileReader();
            reader.onerror = err => obs.error(err);
            reader.onabort = err => obs.error(err);
            reader.onload = err => obs.next(this.formatFile(file, reader.result.toString()));
            reader.onloadend = err => obs.complete();

            return reader.readAsDataURL(file);
        });
    }

    private formatFile(file: File, dataUrl: string) {
        return {
            name: file.name,
            type: file.type,
            dataURL: dataUrl,
            body: this.convertToBlob(dataUrl),
            file: file
        };
    }

    private convertToBlob(dataURL) {
        if (dataURL.length < 6) {
            return '';
        }
        const parts = dataURL.split(',');
        if (parts.length < 2) {
            return '';
        }
        const base = parts[1];
        return window.atob(base);
    }

    private acceptable(files: File[]) {
        if (!this.accept) {
            return true;
        }

        return files.every(file => this.accept.includes(file.type));
    }

    get getStateClass() {
        return this.state.pipe(map(state => {
            switch (state) {
                case State.Loaded:
                    return 'loaded';
                case State.Drag:
                    return 'drag';
                case State.DragError:
                    return 'error';
                default:
                    return 'empty';
            }
        }));
    }
}
