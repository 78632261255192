import { Directive, HostListener, Attribute, HostBinding } from '@angular/core';
import { TableService } from './table.service';
import { TableColumnDirective } from './table-column.directive';

@Directive({
    selector: '[appTableSort]'
})
export class TableSortDirective {

    constructor(
        private tableService: TableService,
        private column: TableColumnDirective,
        @Attribute('appTableDefaultColumn') defaultColumn: string,
        @Attribute('appTableSortAscending') sortAscending: string,
        @Attribute('appTableSortDescending') sortDescending: string,
        @Attribute('appTableNoDefaultSortColumn') noDefaultSortColumn: string
    ) {
        if (sortDescending !== null) {
            this.tableService.setSortDirection('desc');
        }
        if (sortAscending !== null) {
            this.tableService.setSortDirection('asc');
        }
        if (defaultColumn !== null) {
            this.tableService.setColumn(this.column.name);
        } else if (noDefaultSortColumn != null) {
            this.tableService.setColumn(undefined);
            this.tableService.setSortDirection(undefined);
        }
    }

    @HostListener('click') toggleSort() {
        this.tableService.sortByColumn(this.column.name);
    }

    @HostBinding('class.desc') get classDesc() {
        return this.tableService.getColumn() === this.column.name && this.tableService.getSortDirection() === 'desc';
    }

    @HostBinding('class.asc') get classAsc() {
        return this.tableService.getColumn() === this.column.name && this.tableService.getSortDirection() === 'asc';
    }
}
