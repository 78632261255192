import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BackendRepository, PaginatedResponse } from './backend-repository';
import { HistoryResponse, Model } from 'app/shared/models';
import { HistoryItem } from 'app/shared/models';
import { ESParams } from 'app/shared/helpers/es-query-builder';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SnakeToCamelCasePipe } from 'app/shared/elements/pipes';
import { Observable } from 'rxjs';

export enum HistoryEntity {
    AdSlot = 'ad-slot',
    Advertiser = 'advertiser',
    Audience = 'audience',
    Campaign = 'campaign',
    Creative = 'creative',
    LineItem = 'line-item',
    Newsletter = 'newsletter',
    Publisher = 'publisher',
    RTBPartner = 'rtb-partner',
    User = 'user',
}

class LIES extends Model {
    serialize() {
        return null;
    }

    get entity() {
        return 'LIES';
    }
}

@Injectable()
export class LIESRepository extends BackendRepository<LIES> {
    constructor(
        http: HttpClient,
        private snakeToCamelCasePipe: SnakeToCamelCasePipe
    ) {
        super(http, '/lies', LIES);
    }

    globalsearch(query: string, from = 5) {
        return this.http.get(this.url(this.path, 'globalsearch'), {
            params: { q: query },
        });
    }

    eSearch(params: ESParams) {
        return this.asyncSearch(params, '/lies/globalsearch');
    }

    history(id: string, entity: HistoryEntity, page = 1, modified?: string) {
        return this.http
            .get(this.url(this.path, 'history', entity, id), {
                params: this.createHistoryParams(page, modified),
            })
            .pipe(
                map(
                    (response) =>
                        new HistoryResponse({
                            items: response['output'].map(
                                (item) => new HistoryItem(item)
                            ),
                            page,
                            pages: response['pages'],
                            total: response['total'],
                            next:
                                page < response['pages']
                                    ? this.history(
                                          id,
                                          entity,
                                          page + 1,
                                          modified
                                      )
                                    : null,
                        })
                )
            );
    }

    private createHistoryParams(page: number, modified?: string) {
        let params = new HttpParams();

        if (page) {
            params = params.set('page', page.toString());
        }

        if (modified) {
            params = params.set('since', modified);
        }

        return params;
    }
}
