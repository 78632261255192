import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    ViewChild,
    Output,
    Input
} from '@angular/core';
import { FilterComponent } from '../filter.component';
import { FilterString } from './filter-string';

@Component({
    selector: 'filter-string',
    templateUrl: './filter-string.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterStringComponent implements AfterViewInit, FilterComponent {
    @ViewChild('content', { static: true }) content: ElementRef;
    @Output() emitter = new EventEmitter<FilterString>();
    filter: FilterString = new FilterString();
    @Input('suggested-items') suggestedItems: Set<string>;

    ngAfterViewInit() {
        this.filter.label = this.content.nativeElement.innerHTML;

        if (!this.filter.operation) {
            this.filter.operation = this.filter.operations[0];
        }
    }

    emit() {
        this.emitter.emit(this.filter);
    }
}
