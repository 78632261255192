import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { zip, of } from 'rxjs';
import { Advertiser } from 'app/shared/models';
import { AdvertiserRepository, CategoryRepository, UserRepository } from 'app/core/repositories';
import { mergeMap } from 'rxjs/operators';
import { decorateAdvertisersWithNames, getManagersExecutivesForAdvertisers } from 'app/shared/helpers/advertiser-helper';

@Injectable()
export class AdvertiserResolver implements Resolve<Advertiser> {
    constructor(private advertiserRepository: AdvertiserRepository,
                private categoryRepository: CategoryRepository,
                private userRepository: UserRepository ) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.advertiserRepository
            .get(route.params['id'])
            .pipe(
                mergeMap(advertiser => zip(
                    this.categoryRepository.all(),
                    this.advertiserRepository.search({
                        conditions: [{
                            field: 'refId',
                            value: route.params['id']
                        }]
                    }).pipe(
                        mergeMap(advertisers => getManagersExecutivesForAdvertisers(advertisers, this.userRepository)),
                        mergeMap(decorateAdvertisersWithNames)
                    ),
                    of(advertiser),
                    (categories, advertiserSearch, advertiser) => {
                        advertiser = Object.assign({}, advertiser, advertiserSearch[0]);
                        advertiser['categories'] = advertiser['categories'] || [];
                        advertiser['targetedDomains'] = advertiser['targetedDomains'] || [];
                        advertiser.targetedPublishers = advertiser.targetedPublishers || [];
                        advertiser['categoriesCSV'] = advertiser['categories'].map(function (id) {
                            let category = categories.find(function (cat) {
                                return cat.id === id;
                            });
                            return `${category.iab} ${category.name}`;
                        }).join(', ');
                        advertiser['categoryIAB'] = categories.find(function (cat) {
                            return advertiser.category === parseInt(cat.id);
                        })['iab'];
                        return advertiser;
                    }
                ))
            );
    }
}
