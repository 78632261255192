import moment from 'moment';

import { Campaign } from 'app/shared/models/campaign';
import { Creative } from 'app/shared/models/creative';
import { Advertiser } from 'app/shared/models/advertiser';
import { Model, TargetingType } from './model';
import { Pixel } from 'app/shared/models/pixel';
import { Status } from './status';
import targetingFields from './line-item-targeting-fields.json';
import { InsertionOrder } from './insertion-order';
import { AdSlot } from './ad-slot';

export enum LineItemPacing {
    Asap = 'asap',
    Even = 'even',
    Unlimited = 'unlimited'
}

export class LineItem extends Model {

    adjustedImpressions: number;
    adSlots: string[];
    advertiser: string;
    audiences: any[];
    browserTargetingType: TargetingType;
    browsers: string[];
    budget: number;
    budgetType: string;
    budgetIncrement: 'daily' | 'lifetime';
    bundles: string[];
    bidAmount: number;
    campaign: string;
    campaignName: string;
    campaignDemandType: string;
    campaignSystem: string;
    categories: string[];
    categoryTargetingType: TargetingType;
    clicks: number;
    conversions: number;
    createdBy: string;
    creativeMapping: CreativeMapping[];
    creatives: string[];
    dailyCap: number;
    dataProviderSegments: Array<any>;
    dayTargetingType: TargetingType;
    days: {};
    dailyBudget: number;
    deviceMakerTargetingType: TargetingType;
    deviceMakers: string[];
    deviceTypeTargetingType: TargetingType;
    deviceTypes: number[];
    displayStatus: Status;
    domainTargetingType: TargetingType;
    domains: string[];
    effectiveEndDate: string;
    effectiveStartDate: string;
    endDate: string;
    externalId: string;
    geoTargetingType: TargetingType;
    geos: { id: string, type: 'country' | 'region' | 'metro' | 'city' }[];
    hours: {};
    impressions: number;
    insertionOrder: string;
    inventoryTargeting: { id: string, type: 'Publisher' | 'Newsletter' }[];
    inventoryTargetingType: TargetingType;
    isCoordinated: boolean;
    ispTargetingType: TargetingType;
    isps: string[];
    isMaxRoas: boolean;
    keyValues: any;
    keyValuesOperator: 'any' | 'all';
    keyValuesTargetingType: TargetingType;
    learningBudget: number;
    learningConversionLimit: string;
    learningCpm: string;
    listId: string[];
    modified: string;
    name: string;
    nativeLike: boolean;
    notEligibleSizedAdSlots: any[];
    operatingSystemTargetingType: TargetingType;
    operatingSystems: string[];
    pacing: LineItemPacing.Asap | LineItemPacing.Even | LineItemPacing.Unlimited;
    packages: number[];
    placementId: string;
    postalCodes: { countryId: string, postalCodes: string[] }[];
    directSoldPriority: number;
    refId: number;
    spend: number;
    startDate: string;
    status: string;
    strategyCardId: number;
    targetUsOnly: boolean;
    trackingUrl1: string;
    trackingUrl2: string;
    audienceTargeting: string;
    maxCpa: string;
    platformStatus: Status;

    // Non-LSD fields
    campaignObj?: Campaign;
    advertiserObj?: Advertiser;
    inheritFlightDates: boolean;
    ads?: Creative[];
    sections?: AdSlot[];
    insertionOrderObj?: InsertionOrder;
    userHasNCTAccess?: boolean;
    userHasTraffickingUXFlag?: boolean;
    eligibleAdSlots?: any;
    eligibleCreatives?: any;
    packageAdSlots?: AdSlot[];
    coordinatedAdSlots?: string[];
    blackoutPeriods?: BlackoutPeriod[];

    startTime: string;
    endTime: string;

    constructor(from?) {
        super(from);
        this.startTime = this.getTime(this.startDate || this.effectiveStartDate);
        this.endTime = this.getTime(this.endDate || this.effectiveEndDate);

        this.budgetIncrement = this.dailyBudget !== null && this.dailyBudget !== undefined ? 'daily' : 'lifetime';
    }

    /*
      Clear out NON LSD fields
      - remove non-lsd objects
      - remove empty arrays and make them into nulls
    */
    serialize(): string {
        let lineItem = this.clone(LineItem);

        if (lineItem.creativeMapping) {
            lineItem.creativeMapping = lineItem.creativeMapping.map(({ creative, adSlots }) => ({
                creative: { id: creative.id },
                adSlots: adSlots.map(({ id, mappedBlueprints }) => {
                    if (!Array.isArray(mappedBlueprints)) {
                        return { id };
                    }

                    // Needs to normalize mappedBlueprints for the line item form to remove all fields except for id
                    const sanitizedMappedBlueprints = mappedBlueprints.map(item => {
                        return (typeof item === 'object' && item !== null) ? item.id : item
                    });

                    return {
                        id,
                        mappedBlueprints: sanitizedMappedBlueprints
                    };
                })
            }));
        }

        if (lineItem.campaignObj && lineItem.campaignObj.demandType === 'exchange') {
            delete lineItem.keyValuesOperator;
        }

        if (lineItem.keyValues === null) {
            delete lineItem.keyValues;
            delete lineItem.keyValuesTargetingType;
        }

        if (lineItem.pacing === undefined) {
            lineItem.pacing = 'even';
        }

        if (lineItem.externalId !== null && lineItem.externalId !== undefined) {
            if (lineItem.externalId.length < 1) {
                lineItem.externalId = null;
            }
        }

        if (typeof lineItem.placementId === 'string' && lineItem.placementId.trim().length < 1) {
            lineItem.placementId = null;
        }

        if (typeof lineItem.dailyCap !== 'number') {
            lineItem.dailyCap = 0.0;
        }

        if (typeof lineItem.budget !== 'number') {
            lineItem.budget = parseFloat(lineItem.budget);
        }

        lineItem.endDate = moment(lineItem.endDate).endOf('minute').format('YYYY-MM-DD HH:mm:ss');

        delete lineItem.budgetIncrement;
        delete lineItem.startTime;
        delete lineItem.endTime;

        if (lineItem.isNew()) {
            lineItem = this.sanitizeForCreate(lineItem);
        } else {
            lineItem = this.sanitizeForEdit(lineItem);
        }

        if (lineItem.audienceTargeting === '') {
            lineItem.audienceTargeting = null;
        }

        if (lineItem.blackoutPeriods && Array.isArray(lineItem.blackoutPeriods)) {
            lineItem.blackoutPeriods = lineItem.blackoutPeriods.filter(period => period.endDate !== '');
        }

        delete lineItem.ads;
        delete lineItem.sections;
        delete lineItem.campaignObj;
        delete lineItem.advertiserObj;
        delete lineItem.inheritFlightDates;
        delete lineItem.insertionOrderObj;
        delete lineItem.userHasNCTAccess;
        delete lineItem.eligibleAdSlots;
        delete lineItem.eligibleCreatives;

        if (lineItem.pacing !== LineItemPacing.Asap) {
            lineItem.blackoutPeriods = [];
        }

        lineItem = lineItem.removeUnsetProperties(lineItem);

        return JSON.stringify(lineItem);
    }

    get isActive(): boolean {
        return this.status === 'active';
    }

    get hasImpressionsBudget(): boolean {
        return this.budgetType === 'impressions';
    }

    get hasAdjustedImpressionsBudget(): boolean {
        return this.budgetType === 'adjusted_impressions';
    }

    getStartDate(): Date {
        return moment(this.startDate).toDate();
    }

    getEndDate(): Date {
        return moment(this.endDate).toDate();
    }

    calculatePacing() {
        const ms = 3600000 * 24;
        const now = new Date();
        const start = new Date(this.effectiveStartDate);
        const end = new Date(this.effectiveEndDate);

        if (now > end) {
            return (this.spend / this.budget) * 100;
        } else if (now < start) {
            return 100;
        }

        const days = Math.floor((end.getTime() - start.getTime()) / ms) + 1;
        const progressed = Math.floor((now.getTime() - start.getTime()) / ms);

        return Math.round(((this.spend / progressed) * days) / this.budget * 100);
    }

    isUnderPerforming(campaign: Campaign) {
        const ctype = campaign.meta;
        let flag = false;

        if (ctype.goal === 'Branding' && ctype.title === 'Clicks') {
            const ctr = this.clicks / (this.impressions === 0 ? 1 : this.impressions);
            if (ctr < 0.002) {
                flag = true;
            }
        } else if (ctype.goal === 'Performance' && ctype.title === 'Maximize Reach') {
            const eCPM = (this.spend / this.impressions);
            if (eCPM > campaign.bidAmount) {
                flag = true;
            }
        } else if (ctype.goal === 'Performance' && ctype.title === 'Maximize Clicks') {
            const eCPC = (this.spend / this.clicks);
            if (eCPC > campaign.bidAmount) {
                flag = true;
            }
        } else if (ctype.goal === 'Performance' && ctype.title === 'Maximize Conversions') {
            const eCPA = (this.spend / this.conversions);
            if (eCPA > campaign.bidAmount) {
                flag = true;
            }
        }

        return flag;
    }

    hasPixelProblem(campaign: Campaign) {
        const eightdays = 1000 * 60 * 60 * 24 * 8;

        const start = moment(this.effectiveStartDate);
        const now = moment();

        const campaignType = campaign.meta;

        if (campaignType.reach === 'cpa' || campaignType.reach === 'conversions') {
            if (campaign.status === 'pending' && (start.diff(now) < eightdays)
                && start > now.startOf('day')
               ) {
                return true;
            }
        }
        return false;
    }

    hasRemainingBudget(): boolean {
        return this.budget > 0 || this.budget > this.spend;
    }

    getPlatformStatus(): Status {
        return Status[this.platformStatus];
    }

    getDetailedDescription(
        campaign: Campaign, pixel: Pixel, creatives: Creative[]
    ): { title: string, errors: any[], display: boolean } {
        const errors = [];
        const preFlightDetailed = {
            no_active_ads: 'The line item is not ready to deliver because it does not contain any active ads.'
                + ' Please link at least one active ad before the campaign begins.',
            no_budget: 'The line item is not ready to deliver because it doesn’t have a budget.'
                + ' Please increase the budget before the flight begins.',
            no_active_tracker: 'The line item is not ready to deliver because the conversion tracker on the'
                + ' parent campaign is inactive. Please activate the conversion tracker before the campaign begins.',
            misses_creatives: 'This line item does not have all the required ad sizes to start delivering.'
                + ' Please include the ad sizes 300x250, 728x90, and 300x600.',
            no_ad_slots: 'The line item is not ready to deliver because it is not linked to any ad slots.'
                + ' Please link at least one ad slot to the line item.'
        };
        const inFlightDetailed = {
            no_active_ads: 'The line item is not ready to deliver because it does not contain any active ads.'
                + ' Please link at least one active ad to the line item.',
            no_budget: 'The line item is not delivering because there is no remaining budget.',
            no_active_tracker: 'The line item is not delivering because the conversion tracker on the parent'
                + ' campaign is inactive. Please activate the conversion tracker.',
            misses_creatives: 'This line item does not have all the required ad sizes to start delivering.'
                + ' Please include the ad sizes 300x250, 728x90, and 300x600.',
            no_ad_slots: 'The line item is not ready to deliver because it is not linked to any ad slots.'
                + ' Please link at least one ad slot to the line item.'
        };
        const short = {
            no_active_ads: 'It does not contain any active ads.',
            no_budget: 'It does not have a budget.',
            no_active_tracker: 'The parent campaign does not have an active conversion tracker.',
            misses_creatives: 'It does not have all of the required ad sizes.',
            no_ad_slots: 'It does not contain any ad slots.'
        };
        const val = {
            title: '',
            errors: [],
            display: false
        };

        /*
        * 1. Check creative status only for exchange campaigns. Direct sold or in-house creatives
        * don't require approval and can't be rejected.
        * 2. Pending creatives were created from LFM for Direct sold or in-house campaigns.
        * This was causing Maverick to display incorrect strategy status.
        * */
        if (campaign.demandType === 'exchange' && !this.hasActiveAd(creatives)) {
            errors.push('no_active_ads');
        }
        if (campaign.demandType !== 'exchange' && !this.hasAdSlots()) {
            errors.push('no_ad_slots');
        }
        if (!this.hasRemainingBudget() && !campaign.unlimitedBudget) {
            errors.push('no_budget');
        }

        this.hasConversionTracker(campaign) &&
            !this.hasActiveConversionTracker(campaign, pixel) && errors.push('no_active_tracker');

        val.display = errors.length > 0;

        if (this.isPreFlight()) {
            if (errors.length === 1) {
                val.title = preFlightDetailed[errors[0]];
                val.errors = [];
            }

            if (errors.length > 1) {
                val.title = 'The line item is not ready to deliver because of the following reasons:';
                val.errors = errors.map(err => short[err]);
            }
        } else if (this.isInFlight()) {
            if (errors.length === 1) {
                val.title = inFlightDetailed[errors[0]];
                val.errors = [];
            }

            if (errors.length > 1) {
                val.title = 'The line item is not delivering because of the following reasons:';
                val.errors = errors.map(err => short[err]);
            }
        } else {
            val.display = false;
        }

        return val;
    }

    isPreFlight()  {
        const startDate = this.effectiveStartDate || this.startDate;
        return moment().isBefore(startDate);
    }

    isInFlight() {
        const startDate = this.effectiveStartDate || this.startDate;
        const endDate = this.effectiveEndDate || this.endDate;
        return moment().isBetween(startDate, endDate, 'days', '[]');
    }

    hasCompletedFlight() {
        const endDate = this.effectiveEndDate || this.endDate;
        return moment().isAfter(endDate);
    }

    private hasProblem(campaign: Campaign, pixel: Pixel, creatives: Creative[]): boolean {
        return !this.hasActiveCampaign(campaign)
            || !(this.hasRemainingBudget() || campaign.isDynamicBudget || campaign.unlimitedBudget)
            || (this.hasConversionTracker(campaign) && !this.hasActiveConversionTracker(campaign, pixel))
            || !this.hasAd(creatives)
            || (campaign.demandType !== 'exchange' && !(this.hasAdSlots() || this.hasBundles() || this.hasPackages()))
            || (this.hasAd(creatives) && campaign.demandType === 'exchange'  && !this.hasActiveAd(creatives));
    }

    private hasConversionTracker(campaign: Campaign) {
        return Boolean(campaign.conversionPixel);
    }

    private hasActiveConversionTracker(campaign: Campaign, pixel: Pixel) {
        return this.hasConversionTracker(campaign) && pixel.status === 'active';
    }

    private hasActiveCampaign(campaign: Campaign) {
        return campaign.status === 'active';
    }

    private hasAd(creatives: Creative[]) {
        return creatives[0] !== null && creatives[0] !== undefined;
    }

    private hasActiveAd(creatives: Creative[]) {
        return creatives.some(creative => creative.status === 'active');
    }

    private hasAdSlots() {
        return Array.isArray(this.adSlots) && this.adSlots.length > 0;
    }

    private hasBundles() {
        return Array.isArray(this.bundles) && this.bundles.length > 0;
    }

    private hasPackages() {
        return Array.isArray(this.packages) && this.packages.length > 0;
    }

    determineDatesWithCampaign(campaign: Campaign) {
        let startDate, endDate;

        if (this.startDate && this.endDate) {
            startDate = this.startDate;
            endDate = this.endDate;
        } else if (campaign.effectiveStartDate && campaign.effectiveEndDate) {
            startDate = campaign.effectiveStartDate;
            endDate = campaign.effectiveEndDate;
        } else {
            startDate = campaign.insertionOrderStartDate;
            endDate = campaign.insertionOrderEndDate;
        }

        return { startDate, endDate };
    }

    /**
     * Removes unset Line Item properties to prevent
     * errors when trying to save the resource.
     *
     * @param  {LineItem}  strategy
     * @return {LineItem}  strategy
     */
    public removeUnsetProperties(strategy) {
        this.removeIfUnset(strategy, 'browsers', 'browserTargetingType');
        this.removeIfUnset(strategy, 'deviceMakers', 'deviceMakerTargetingType');
        this.removeIfUnset(strategy, 'deviceTypes', 'deviceTypeTargetingType');
        this.removeIfUnset(strategy, 'inventoryTargeting', 'inventoryTargetingType');
        this.removeIfUnset(strategy, 'isps', 'ispTargetingType');
        this.removeIfUnset(strategy, 'operatingSystems', 'operatingSystemTargetingType');
        this.removeIfUnset(strategy, 'categories', 'categoryTargetingType');

        // The intent is to allow for postal codes to be unset if the value is
        // `null` or that of an empty array. Otherwise we discard the property
        // to avoid sending invalid data.
        if (Array.isArray(strategy.postalCodes)) {
            if (strategy.postalCodes.length < 1) {
                strategy.postalCodes = null;
            }
        } else if (strategy.postalCodes !== null) {
            delete strategy.postalCodes;
        }

        if (this.isUnset(strategy, 'postalCodes') && this.isUnset(strategy, 'geos')) {
            delete strategy.geoTargetingType;
        }

        return strategy;
    }

    /**
     * Deletes properties of a resource if a
     * specified property is unset.
     *
     * @param  {Object}  resource
     * @param  {string}  property
     * @param  {string|array}  propertiesToRemove
     */
    public removeIfUnset (resource, property, propertiesToRemove) {
        if (this.isUnset(resource, property)) {
            removeProperties(resource, propertiesToRemove);
        }

        function removeProperties (resource, propertiesToRemove) {
            if (Array.isArray(propertiesToRemove)) {
                propertiesToRemove.forEach(function (propertyToRemove) {
                    delete resource[propertyToRemove];
                });
            } else {
                delete resource[propertiesToRemove];
            }
        }
    }

   /**
     * Checks if a resource's property is either
     * null, undefined, or length < 1.
     *
     * @param  {Object}  resource
     * @param  {string}  property
     * @return {Boolean}
     */
    public isUnset (resource, property) {
        return resource[property] !== false
            && (resource[property] === undefined
                || resource[property] === null
                || !Object.keys(resource[property]).length);
    }

    /**
     * Sets the property of a resource to
     * null if it was previously unset.
     *
     * @param  {Object}  resource
     * @param  {string}  property
     */
    public nullifyIfUnset(resource, property) {
        if (this.isUnset(resource, property)) {
            resource[property] = null;
        }
    }

    /**
     * Sets a property of a resource to null and
     * a property of the resource to 'exclude'
     * if the former was previously unset.
     *
     * @param  {Object}  resource
     * @param  {string}  property
     */
    public nullifyAndExcludeIfUnset(resource, property, propertyToExclude) {
        if (this.isUnset(resource, property)) {
            resource[property] = null;
            resource[propertyToExclude] = 'exclude';
        }
    }

    /**
     * Sanitizes a new Strategy before being saved
     * for the first time.
     *
     * @param  {Strategy}  strategy
     * @return {Strategy}
     */
    private sanitizeForCreate(strategy: LineItem): LineItem {
        if (Array.isArray(strategy.geos) && strategy.geos.length < 1) {
            delete strategy.geos;
        }
        if (Array.isArray(strategy.postalCodes) && strategy.postalCodes.length < 1) {
            delete strategy.postalCodes;
        }

        if (!strategy.domains || (Array.isArray(strategy.domains) && strategy.domains.length === 0)) {
            delete strategy.domains;
            delete strategy.domainTargetingType;
        }

        if (Array.isArray(strategy.dataProviderSegments)) {
            strategy.dataProviderSegments = strategy.dataProviderSegments.map(s => ({
                type: s.type,
                id: s.id
            }));
        }

        this.removeIfUnset(strategy, 'deviceTypes', ['deviceTypes', 'deviceTypeTargetingType']);
        this.removeIfUnset(strategy, 'browsers', ['browsers', 'browserTargetingType']);
        this.removeIfUnset(strategy, 'categories', ['categories', 'categoryTargetingType']);
        this.removeIfUnset(strategy, 'isps', ['isps', 'ispTargetingType']);
        this.removeIfUnset(strategy, 'operatingSystems', ['operatingSystems', 'operatingSystemTargetingType']);
        this.removeIfUnset(strategy, 'postalCodes', 'postalCodes');
        this.removeIfUnset(strategy, 'deviceMakers', ['deviceMakers', 'deviceMakerTargetingType']);
        this.removeIfUnset(strategy, 'inventoryTargeting', ['inventoryTargeting', 'inventoryTargetingType']);
        this.removeIfUnset(strategy, 'audiences', 'audiences');
        this.removeIfUnset(strategy, 'dataProviderSegments', 'dataProviderSegments');
        this.removeIfUnset(strategy, 'keyValues', ['keyValues', 'keyValuesTargetingType', 'keyValuesOperator']);

        if (this.isUnset(strategy, 'postalCodes') && this.isUnset(strategy, 'geos')) {
            delete strategy.geoTargetingType;
        }

        if (this.isUnset(strategy, 'days') && this.isUnset(strategy, 'hours')) {
            delete strategy.dayTargetingType;
        }

        return strategy;
    }

    /**
     * Sanitizes an existing strategy before being
     * saved.
     *
     * @param  {Strategy}  strategy
     * @return {Strategy}
     */
    private sanitizeForEdit(strategy: LineItem): LineItem {

        if (!strategy.domains || (Array.isArray(strategy.domains) && strategy.domains.length === 0)) {
            strategy.domains = null;
        }

        if (Array.isArray(strategy.dataProviderSegments)) {
            strategy.dataProviderSegments = strategy.dataProviderSegments.map(s => ({
                type: s.type,
                id: s.id
            }));
        }

        this.nullifyIfUnset(strategy, 'audiences');
        this.nullifyIfUnset(strategy, 'bundles');
        this.nullifyIfUnset(strategy, 'dataProviderSegments');
        this.nullifyIfUnset(strategy, 'geos');
        this.nullifyIfUnset(strategy, 'postalCodes');
        this.nullifyIfUnset(strategy, 'days');
        this.nullifyIfUnset(strategy, 'hours');
        this.nullifyAndExcludeIfUnset(strategy, 'categories', 'categoryTargetingType');
        this.nullifyAndExcludeIfUnset(strategy, 'browsers', 'browserTargetingType');
        this.nullifyAndExcludeIfUnset(strategy, 'deviceMakers', 'deviceMakerTargetingType');
        this.nullifyAndExcludeIfUnset(strategy, 'deviceTypes', 'deviceTypeTargetingType');
        this.nullifyAndExcludeIfUnset(strategy, 'isps', 'ispTargetingType');
        this.nullifyAndExcludeIfUnset(strategy, 'inventoryTargeting', 'inventoryTargetingType');
        this.nullifyAndExcludeIfUnset(strategy, 'operatingSystems', 'operatingSystemTargetingType');

        if (this.isUnset(strategy, 'postalCodes') && this.isUnset(strategy, 'geos')) {
            strategy.geoTargetingType = 'exclude';
        }

        if (this.isUnset(strategy, 'days') && this.isUnset(strategy, 'hours')) {
            strategy.dayTargetingType = 'exclude';
        }

        if (this.isUnset(strategy, 'keyValues')) {
            strategy.keyValuesOperator = 'any';
        }

        return strategy;
    }

    get budgetField() {
        if (this.budgetIncrement === 'lifetime') {
            return 'budget';
        }

        return 'dailyBudget';
    }

    static get targetingFields(): Array<string> {
        return targetingFields;
    }

    get entity() {
        return 'Line Item';
    }

    get remainingNumberOfDaysInFlight() {
        const start = moment(this.startDate || this.effectiveStartDate, 'YYYY-MM-DD');
        const end = moment(this.endDate || this.effectiveEndDate, 'YYYY-MM-DD');
        const today = moment().startOf('day');
        let daysLeft: number;

        if (start.isBefore(today)) {
            if (end.isBefore(today)) {
                daysLeft = 0;
            } else {
                daysLeft = end.diff(today, 'days') + 1;
            }
        } else {
            daysLeft = end.diff(start, 'days') + 1;
        }

        return daysLeft;
    }
}

export interface NativeCreatives {
    [creativeRefId: string]: {
        [adSlotRefId: string]: number[];
    };
}

export interface IneligibleNativeCreatives {
    [creativeRefId: string]: {
        [blueprintId: string]: string[];
    };
}

export interface DisplayCreatives {
    [creativeRefId: string]: {
        [adSlotRefId: string]: string[];
    };
}

export interface EligibleAdSlots {
    nativeCreatives: NativeCreatives;
    displayCreatives: DisplayCreatives;
    ineligibleNativeCreatives?: IneligibleNativeCreatives;
}

export interface CreativeMapping {
    creative: CreativeMappingCreative;
    adSlots: CreativeMappingAdSlot[];
}

export interface CreativeMappingCreative {
    id: string;
    name?: string;
    isNative?: boolean;
}

export interface CreativeMappingAdSlot {
    id: string;
    refId?: number;
    name?: string;
    isNative?: boolean;
    mappedBlueprints?: CreativeMappingAdSlotBlueprint[];
    linkedBlueprints?: CreativeMappingAdSlotBlueprint[];
}

export interface CreativeMappingAdSlotBlueprint {
    id: number;
    name?: string;
    desktopAspectRatio?: string;
    mobileAspectRatio?: string;
}

export interface BlackoutPeriod {
    startDate: string;
    endDate: string;
}
