import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';

@Directive({
    exportAs: 'copyable',
    selector: '[copyable]'
})
export class CopyableDirective {
    @Output() copied = new EventEmitter<boolean>();

    constructor(private el: ElementRef) {}

    copy() {
        const value = this.el.nativeElement.value ? this.el.nativeElement.value : this.el.nativeElement.textContent;
    
        navigator.clipboard.writeText(value)
            .then(() => {
                this.copied.emit(true);
            })
            .catch(error => {
                this.copied.emit(false);
            });
    }
}
