import { AbstractControl, ValidationErrors, Validator } from '@angular/forms';
import { SupplyAssetProperties } from 'app/shared/models/native-supply';
var ImageRatioValidator = /** @class */ (function () {
    function ImageRatioValidator() {
        this.isNativeDirectSold = false;
    }
    ImageRatioValidator.prototype.validate = function (control) {
        var ratioValidation = {};
        for (var _i = 0, SupplyAssetProperties_1 = SupplyAssetProperties; _i < SupplyAssetProperties_1.length; _i++) {
            var prop = SupplyAssetProperties_1[_i];
            var dimensions = {
                width: control.get(prop.modelKey.concat('Width')),
                height: control.get(prop.modelKey.concat('Height'))
            };
            var exactDimensions = {
                width: control.get(prop.modelKey.concat('ExactWidth')),
                height: control.get(prop.modelKey.concat('ExactHeight'))
            };
            var dimensionType = control.get('imageDimensionType');
            var isExact = dimensionType && dimensionType.value === 'exact';
            if (verifyDataPresent(isExact ? exactDimensions : dimensions)) {
                var validationResult = validate(prop.modelKey, dimensions, this.isNativeDirectSold);
                if (validationResult.length > 0) {
                    ratioValidation[prop.modelKey] = validationResult;
                }
            }
        }
        return ratioValidation;
    };
    return ImageRatioValidator;
}());
export { ImageRatioValidator };
var validate = function (asset, dimensions, isNativeDirectSold) {
    var _a = { width: dimensions.width.value, height: dimensions.height.value }, width = _a.width, height = _a.height;
    var ratio = parseFloat((width / height).toFixed(2));
    var errors = [];
    switch (asset) {
        case 'mainImage':
            if (isNativeDirectSold) {
                if (width < 50) {
                    errors.push({
                        valid: false,
                        message: 'Width must be at least 50'
                    });
                }
                if (height < 50) {
                    errors.push({
                        valid: false,
                        message: 'Height must be at least 50'
                    });
                }
            }
            else {
                if (height < 50) {
                    errors.push({
                        valid: false,
                        message: 'Height must be at least 50'
                    });
                }
                switch (ratio) {
                    case 1:
                        if (width < 50) {
                            errors.push({
                                valid: false,
                                message: 'Width must be at least 50'
                            });
                        }
                        break;
                    case parseFloat((4 / 3).toFixed(2)):
                        if (width < 67) {
                            errors.push({
                                valid: false,
                                message: 'Width must be at least 67'
                            });
                        }
                        break;
                    case 1.91:
                        if (width < 96) {
                            errors.push({
                                valid: false,
                                message: 'Width must be at least 96'
                            });
                        }
                        break;
                    default:
                        errors.push({
                            valid: false,
                            message: 'Aspect ratio of 1:1, 4:3, or 1.91:1 is required'
                        });
                }
            }
            break;
        case 'logoImage':
            if (!isNativeDirectSold) {
                switch (ratio) {
                    case 1:
                        for (var _i = 0, _b = Object.keys(dimensions); _i < _b.length; _i++) {
                            var dimension = _b[_i];
                            if (dimensions[dimension].value > 50) {
                                errors.push({
                                    valid: false,
                                    message: capitalize(dimension) + " must be less than 50."
                                });
                            }
                        }
                        break;
                    default:
                        errors.push({
                            valid: false,
                            message: 'Aspect ratio of 1:1 is required'
                        });
                }
            }
            break;
    }
    return errors;
};
var ɵ0 = validate;
var verifyDataPresent = function (_a) {
    var width = _a.width, height = _a.height;
    return width && height &&
        ((width.value && height.value) ||
            ((width.touched || height.touched) &&
                (width.dirty || height.dirty)));
};
var ɵ1 = verifyDataPresent;
var capitalize = function (input) {
    return input.charAt(0).toUpperCase() + input.slice(1);
};
var ɵ2 = capitalize;
export { ɵ0, ɵ1, ɵ2 };
