import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Bundle } from 'app/shared/models';
import { BackendRepository, Query } from './backend-repository';
import { publishLast, refCount, map } from 'rxjs/operators';

@Injectable()
export class BundleRepository extends BackendRepository<Bundle> {
    public constructor(http: HttpClient) {
        super(http, '/bundle', Bundle);
    }

    // TODO implement based on the query params
    private _allResult: Observable<Bundle[]> = null;
    public all(query: Query = {}) {

        if (this._allResult) {
            return this._allResult;
        }

        this._allResult = super.all(query)
            .pipe(
                publishLast(),
                refCount()
            );

        return this._allResult;
    }

    public searchAsOptions(query: Query = {}): Observable<any[]> {
        return this.all(query).pipe(map(bundles => {
            return bundles.map(
                option => {
                    return {
                        key: option.id,
                        value: option.id,
                        label: option.name
                    };
                });
        }));
    }

    searchByLineItem(strategy: string, params?: any) {
        const url = this.url('/search/bundle/strategy');
        return this.http.post(url, Object.assign({ strategy }, params))
            .pipe(map(data => data['output'].map(item => this.build(item) as Bundle)));
    }
}
