import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ContentChildren,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewContainerRef
} from '@angular/core';
import { Subject, BehaviorSubject, ReplaySubject } from 'rxjs';
import {debounceTime, distinctUntilChanged, map, tap} from 'rxjs/operators';
import { FilterExistComponent } from './filter-exist/filter-exist.component';
import { FilterEnumComponent } from './filter-enum/filter-enum.component';
import { FilterDateComponent } from './filter-date/filter-date.component';
import { FilterIdComponent } from './filter-id/filter-id.component';
import { FilterNumberComponent } from './filter-number/filter-number.component';
import { FilterStringComponent } from './filter-string/filter-string.component';
import { FilterFieldDirective } from './filter-field.directive';
import { Filter } from './filter';
import { FilterComponent } from './filter.component';
import { OperationLabel } from './operation';
import { PopoverComponent } from 'app/shared/elements/popover';
import { IdService } from 'app/core/id.service';

const FilterComponents = [
    FilterExistComponent,
    FilterEnumComponent,
    FilterIdComponent,
    FilterNumberComponent,
    FilterStringComponent,
    FilterDateComponent
];

@Component({
    selector: 'filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    entryComponents: FilterComponents
})
export class FiltersComponent implements AfterViewInit, OnDestroy {
    @ViewChild('popover' , { static: true }) popover: PopoverComponent;
    @ViewChild('filterOutlet', { read: ViewContainerRef, static: true }) filterOutlet: ViewContainerRef;
    @ContentChildren(FilterFieldDirective) queryResults: QueryList<FilterFieldDirective>;
    @Input() disableMultipleFilters: boolean = false;
    @Output('filtersChange') changed = new EventEmitter<Filter[]>();
    @Output() available = new EventEmitter<Filter[]>();
    search$ = new BehaviorSubject('');
    items;
    filterComponents: FilterComponent[] = [];
    availableFilters: FilterComponent[] = [];
    currentIndex: number;

    private component: ComponentRef<FilterComponent>;
    private filters$ = new ReplaySubject<Filter[]>(1);
    private onDestroy = new Subject<void>();

    @Input('filters') set appliedFilters(filters: Filter[]) {
        this.filters$.next(filters);
    }

    constructor(
        private cdr: ChangeDetectorRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        public id: IdService
    ) { }

    ngAfterViewInit() {
        this.availableFilters = this.queryResults
            .filter(directive => directive.component.filter instanceof Filter)
            .map(directive => directive.component);

        this.available.emit(this.availableFilters.map(component => component.filter));

        this.filters$.subscribe(filters => this.setFilters(filters));

        this.items = this.search$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(value => value.toLowerCase()),
            map(value => this.availableFilters.filter(item => item.filter.label.toLowerCase().includes(value)))
        );
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
        this.destroyComponent();
    }

    redraw(componentToDraw: FilterComponent) {
        // destroy the component currently at this spot
        this.destroyComponent();

        // draw the component and place it in this spot
        const factory = this.componentFactoryResolver
            .resolveComponentFactory((componentToDraw.constructor as any));

        this.component = this.filterOutlet.createComponent(factory) as any;

        // set props on the new component instance
        const component = this.component.instance as FilterComponent;

        Object.assign(component, componentToDraw);

        component.filter = new (componentToDraw.filter as any).constructor(
            JSON.parse(JSON.stringify(componentToDraw.filter))
        );
    }

    add(e: MouseEvent) {
        this.currentIndex = -1;
        this.destroyComponent();
        this.popover.target = e.target;
        this.popover.show(e);
    }

    applyFilter() {
        let filters = this.filterComponents.concat(this.component.instance).map(component => component.filter);
        if (this.currentIndex >= 0) {
            this.filterComponents[this.currentIndex] = this.component.instance;
            filters = this.filterComponents.map(component => component.filter);
        }
        this.setFilters(filters);
        this.popover.hide();
        this.emit(filters);
        this.search$.next('');
    }

    remove(index: number) {
        const filters = this.filterComponents.filter((f, i) => i !== index).map(component => component.filter);
        this.setFilters(filters);
        this.emit(filters);
    }

    select(option: any) {
        if (!option) {
            this.search$.next('');
            return;
        }
        const field = option.filter.field;
        const toDraw = this.availableFilters.find(component => component.filter.field === field);
        this.redraw(toDraw);
    }

    editFilter(e: MouseEvent, component, index) {
        this.currentIndex = index;
        this.redraw(component);
        this.popover.target = e.target;
        this.popover.show(e);
    }

    private emit(filters) {
        this.changed.emit(filters);
    }

    private setFilters(filters: Filter[]) {
        this.filterComponents = filters.map(filter => {
            const componentToCreate = this.availableFilters.find(component => component.filter.field === filter.field);

            if (componentToCreate) {
                const component = new (componentToCreate as any).constructor();
                Object.assign(component, componentToCreate);
                component.filter = filter;

                return component;
            }
        }).filter(component => !!component);

        this.cdr.detectChanges();
    }

    destroyComponent() {
        if (this.component) {
            this.component.destroy();
        }
    }

    getOperationLabel(value) {
        return OperationLabel[value];
    }

    isValid() {
        if (this.component) {
            return this.component.instance.filter.isValid();
        }

        return false;
    }

    get showAddFilter(): boolean {
        return this.filterComponents.length === 0  || !this.disableMultipleFilters;
    }
}
