import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { Blueprint } from 'app/shared/models';
import { BlueprintRepository } from 'app/core/repositories';
import { of } from 'rxjs/index';

@Injectable()
export class BlueprintResolver implements Resolve<Blueprint> {
    constructor(
        private blueprintRepository: BlueprintRepository
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        if (route.params['id']) {
            return this.blueprintRepository.get(route.params['id']);
        }
        const blueprint = new Blueprint();
        return of(blueprint);
    }
}
