import { ElementRef, Renderer2, OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
var CurrencyInputDirective = /** @class */ (function () {
    function CurrencyInputDirective(el, renderer) {
        this.el = el;
        this.renderer = renderer;
        this.numberFormat = '1.2-2';
        this.usdPipe = null;
        this.value = null;
        this.propagate = null;
        this.onTouchedCallback = null;
        this.usdPipe = new CurrencyPipe('en-US');
    }
    CurrencyInputDirective.prototype.ngOnInit = function () {
        this.el.nativeElement.placeholder = this.placeholder;
    };
    CurrencyInputDirective.prototype.writeValue = function (value) {
        if (value === null || value === undefined) {
            this.renderer.setStyle(this.el.nativeElement, 'text-align', 'left');
            this.reset();
            return;
        }
        this.renderer.setStyle(this.el.nativeElement, 'text-align', 'right');
        var input = this.el.nativeElement;
        this.value = value;
        input.value = this.transformInput(this.value.toString());
    };
    CurrencyInputDirective.prototype.registerOnChange = function (fn) {
        this.propagate = fn;
    };
    CurrencyInputDirective.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    CurrencyInputDirective.prototype.activate = function () {
        var input = this.el.nativeElement;
        input.value = this.value;
        input.placeholder = '';
        this.renderer.setStyle(this.el.nativeElement, 'text-align', 'right');
    };
    CurrencyInputDirective.prototype.deactivate = function () {
        var input = this.el.nativeElement;
        if (input.value.length > 0) {
            var numericValueAsString = input.value.replace(/[^0-9\.]+/g, '');
            if (numericValueAsString.length < 1) {
                this.reset();
            }
            else {
                input.value = this.transformInput(numericValueAsString);
                this.value = parseFloat(input.value.replace(/[,$]/g, ''));
            }
        }
        else {
            this.reset();
        }
        this.propagate(this.value);
        this.onTouchedCallback();
    };
    CurrencyInputDirective.prototype.reset = function () {
        this.el.nativeElement.value = null;
        this.el.nativeElement.placeholder = this.placeholder;
        this.renderer.setStyle(this.el.nativeElement, 'text-align', 'left');
        this.value = null;
    };
    CurrencyInputDirective.prototype.transformInput = function (value) {
        if (value.length < 1) {
            return '';
        }
        return this.usdPipe.transform(value, CurrencyInputDirective.CURRENCY, 'symbol', this.numberFormat);
    };
    Object.defineProperty(CurrencyInputDirective.prototype, "placeholder", {
        get: function () {
            return this._placeholder ? this._placeholder : CurrencyInputDirective.CURRENCY_SYMBOL;
        },
        set: function (placeholder) {
            this._placeholder = placeholder;
            this.el.nativeElement.placeholder = this.placeholder;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrencyInputDirective.prototype, "disabled", {
        set: function (isDisabled) {
            this.el.nativeElement.disabled = isDisabled;
        },
        enumerable: true,
        configurable: true
    });
    CurrencyInputDirective.CURRENCY = 'USD';
    CurrencyInputDirective.CURRENCY_SYMBOL = '$';
    return CurrencyInputDirective;
}());
export { CurrencyInputDirective };
