import { Component, Input } from '@angular/core';

@Component({
    selector: 'open-book',
    templateUrl: './open-book.html',
    styleUrls: ['./open-book.styl']
})
export class OpenBookComponent {
    @Input() hideInput: boolean = false;
}
