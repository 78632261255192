<lightboxnext header="Batch Operation Status" class="abs small">
    <div class="body">
        <div class="padding">
            <div *ngIf="failedOperations.length" class="row">
                <div class="column--12 padding">
                    <h4 [attr.id]="id.header('bulk-operation-status-modal', 'failed-operation', 'header')" class="strong red">Failed operations</h4>
                    <hr class="space space--small">
                    <ul *ngFor="let failedOperation of failedOperations; let i = index">
                        <li [attr.id]="id.text('bulk-operation-status-modal', 'failed-operation', i)">
                            <strong>{{ failedOperation.name | dashToSpace | titlecase }} Update</strong> ID: {{ failedOperation.id }} — {{ failedOperation.errorMessage }}
                        </li>
                    </ul>
                </div>
            </div>
            <div *ngIf="pendingOperations.length" class="row">
                <div class="column--12 padding">
                    <h4 [attr.id]="id.header('bulk-operation-status-modal', 'pending-operation', 'header')" class="strong">Pending operations</h4>
                    <hr class="space space--small">
                    <ul *ngFor="let pendingOperation of pendingOperations; let i = index">
                        <li [attr.id]="id.text('bulk-operation-status-modal', 'pending-operation', i)">
                            <strong>{{ pendingOperation.name | dashToSpace | titlecase }} Update</strong> ID: {{ pendingOperation.id }}
                        </li>
                    </ul>
                </div>
            </div>
            <div *ngIf="successfulOperations.length" class="row">
                <div class="column--12 padding">
                    <h4 [attr.id]="id.header('bulk-operation-status-modal', 'completed-operation', 'header')" class="strong green">Successful operations</h4>
                    <hr class="space space--small">
                    <ul *ngFor="let completedOperation of successfulOperations; let i = index">
                        <li [attr.id]="id.text('bulk-operation-status-modal', 'completed-operation', i)">
                            <strong>{{ completedOperation.name | dashToSpace | titlecase }} Update</strong> ID: {{ completedOperation.id }}
                        </li>
                    </ul>
                </div>
            </div>
            <div *ngIf="cancelledOperations.length" class="row">
                <div class="column--12 padding">
                    <h4 [attr.id]="id.header('bulk-operation-status-modal', 'cancelled-operation', 'header')" class="strong">Cancelled operations</h4>
                    <hr class="space space--small">
                    <ul *ngFor="let cancelledOperation of cancelledOperations; let i = index">
                        <li [attr.id]="id.text('bulk-operation-status-modal', 'cancelled-operation', i)">
                            <strong>{{ cancelledOperation.name | dashToSpace | titlecase }} Update</strong> ID: {{ cancelledOperation.id }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <overlay-loading-indicator *ngIf="loading"></overlay-loading-indicator>
    </div>
    <footer>
        <div class="align-right">
            <button (click)="close()" type="button">Close</button>
        </div>
    </footer>
</lightboxnext>
