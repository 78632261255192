import { Component, Input } from '@angular/core';
import { IdService } from 'app/core/id.service';

@Component({
    selector: 'error-display',
    templateUrl: './error-display.html',
    styleUrls: ['./error-display.styl']
})
export class ErrorDisplayComponent {
    @Input('errorHelper') errorHelper;
    @Input('entity') entity;

    constructor(
        public id: IdService
    ){}

    toLowerCase(description) {
        return description.charAt(0).toLowerCase() + description.slice(1);
    }
}
