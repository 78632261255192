import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Model } from 'app/shared/models/model';

export interface Query extends Condition {
    n?: number;
    page?: number;
    returnMode?: string;
    return?: string[];
    orderBy?: string;
    sort?: 'ASC' | 'DESC';
}

export interface Condition {
    field?: string;
    value?: any;
    mode?: string;
    operator?: string;
    conditions?: Condition[];
}

export type Id = string | number;

/**
 * The `HeimdallRepository` is an abstract class which when extended takes a
 * generic model type and allows for the creation of instances of the model as
 * promises typically using data fetched from the backend API through the
 * `BackendHttp` service.
 */
export abstract class HeimdallRepository<T extends Model> {

    constructor(
        public http: HttpClient,
        public path: string,
        public model: { new (from?: {}): T; }
    ) {}

    /**
     * Returns an observable that fetches `T` with `id` from the backend.
     * @param id
     */
    get(id: Id): Observable<string> {
        if (typeof id === 'number') {
            id = id.toString();
        }

        if (typeof id !== 'string') {
            return throwError(new Error('Invalid parameter. Parameter must be a string or a number.'));
        }

        if (id.length < 1) {
            return throwError(new Error('Invalid parameter. Parameter must have length at least 1.'));
        }

        return this.http.get(this.url(this.path, id), {
            headers: new HttpHeaders({
                'Accept': 'text/plain',
                'Content-Type': 'text/plain'
            }),
            responseType: 'text'
        }).pipe(
            map(response => response)
        );
    }

    /**
     * Returns an observable that saves `T` in the backend.
     * @param model
     */
    save(instance: T): Observable<T> {
        const params = [this.path];
        if (instance.id) {
            params.push(instance.id);
        }

        return this.http.post(this.url(...params), instance.serialize())
            .pipe(map(response => this.build(response['output'])));
    }

    /**
     * This function returns a new instance of the model of type `T`
     * instantiated with `from`.
     * @param from
     */
    build(from?: {}): T {
        return new this.model(from);
    }

    /**
     * Builds and returns a URL for the backend API given an array of strings
     * `suffix` which are joined using a forward-slash.
     * @param suffix
     */
    url(...suffix: string[]): string {
        return environment.backend.heimdall.hostname + suffix.join('/');
    }
}
