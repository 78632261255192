import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'yieldrankdesc'
})
export class YieldManagementRankDescriptionPipe implements PipeTransform {
    transform(rank: number) {
        switch (rank) {
            case 1:
                return '≤ 100k';
            case 2:
                return '100k < 1mm';
            case 3:
                return '> 1mm';
        }
    }
}
