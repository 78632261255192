import { Component, AfterViewInit, Input, ViewChild } from '@angular/core';
import { SparkChartComponent } from 'app/shared/elements/charts/spark-chart/spark-chart.component';
import { ChartHelper } from 'app/shared/helpers/chart-helper';

@Component({
    selector: 'metric-card',
    templateUrl: './metric-card.html',
    styleUrls: ['./metric-card.styl']
})
export class MetricCardComponent implements AfterViewInit {
    @ViewChild(SparkChartComponent, { static: false }) sparkChart: SparkChartComponent;

    @Input() metric: String = '';
    @Input() operation: String = '';

    @Input()
    set sparkYAxis(value) {
        this._sparkYAxis = value;
        this.reloadGraph();
    }

    @Input()
    set sparkXAxis(value) {
        this._sparkXAxis = value;
        this.reloadGraph();
    }

    @Input() height: number = 150;
    @Input() width: number = 50;
    @Input() format: string = '';
    @Input() colorPattern: Array<string> = [];

    ngAfterViewInit() {
        this.reloadGraph();
    }

    undefinedTrend: boolean = false;

    private trendValue: number = 0;
    trendNegative: boolean = false;
    private _sparkYAxis: any[] = [];
    private _sparkXAxis: any[] = [];
    private chartHelper = new ChartHelper();

    get sparkYAxis() {
        return this._sparkYAxis;
    }

    get sparkXAxis() {
        return this._sparkXAxis;
    }

    get cardMetric() {
        if (this._sparkYAxis[0] === 'eCPA' || this._sparkYAxis[0] === 'eCPC' || this._sparkYAxis[0] === 'CTR') {
            return null;
        }

        const value = this.chartHelper.evaluateMetricReduction(
            this._sparkYAxis.slice(1, this._sparkYAxis.length),
            this.operation
        );

        switch (this.format) {
            case 'Percent':
                return value.toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 1});
            case 'Number':
                return value.toLocaleString('en-US');
            case 'Currency':
                return value.toLocaleString('en-US', { style: 'currency', currency: 'USD'});
            default: return value.toLocaleString('en-US');
        }
    }

    dataTrend(YData) {
        const data: number[] = YData.slice(1, YData.length);
        let index = -1;
        for (let i = 0; i < data.length; i++) {
            if (data[i] > 0) {
                index = i;
                break;
            }
        }
        if (index > -1) {
            this.trendValue = (data[data.length - 1] - data[index]) / data[index];
            this.undefinedTrend = false;
        } else {
            this.undefinedTrend = true;
        }
        this.trendNegative = (this.trendValue < 0);
    }

    get cardTrend() {
        return Math.abs(this.trendValue).toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 1});
    }

    reloadGraph() {
        this.dataTrend(this.sparkYAxis);
        if (this.sparkChart === undefined) {
            return;
        }
        if (this.sparkChart.called && this.sparkChart.chart) {
            this.sparkChart.chart.load({
                columns: [this.sparkYAxis, this.sparkXAxis],
                unload: true,
            });
        }
    }
}
