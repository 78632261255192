import { of, forkJoin, ReplaySubject } from 'rxjs';
import { DownloadHelper } from 'app/shared/helpers/download-helper';
import { AdSlotRepository, NewsletterRepository } from 'app/core/repositories';
import { LightboxComponent } from 'app/shared/elements/lightbox';
import { mergeMap } from 'rxjs/operators';
import { IdService } from 'app/core/id.service';
import { NgForm } from '@angular/forms';
var WIDTH_MACRO = '{INSERT TEMPLATE MAX WIDTH HERE AS INTEGER}';
var MOBILE_WIDTH_MACRO = '{INSERT TEMPLATE MAX MOBILE WIDTH HERE AS INTEGER}';
var RESPONSIVE_WIDTH_MACRO = '{INSERT TEMPLATE MOBILE RESPONSIVE WIDTH HERE AS INTEGER}';
var DEFAULT_TAG_OPTIONS = [
    { value: 'all', label: 'All', key: 0 },
    { value: 'safertb', label: 'SafeRTB', key: 1 },
];
var NewsletterTagsComponent = /** @class */ (function () {
    function NewsletterTagsComponent(adSlotRepository, newsletterRepository, id) {
        this.adSlotRepository = adSlotRepository;
        this.newsletterRepository = newsletterRepository;
        this.id = id;
        this.tagOptions = DEFAULT_TAG_OPTIONS.slice();
        this.state = 'closed';
        this.newsletter$ = new ReplaySubject(1);
        this.adSlotId$ = new ReplaySubject(1);
    }
    /**
     * If adSlotId is not specified, show the code for all of the ad slots on the newsletter
     * If the adSlotId is passed in, focus on displaying that ad slot, while others should be available as options.
     * Depending on where the method is invoked, newsletter might only have an id, in which case we would need to
     * do a get request
     * @param newsletter
     * @param adSlotId
     */
    NewsletterTagsComponent.prototype.openModal = function (newsletter, adSlotId) {
        var _this = this;
        this.newsletterRepository.get(newsletter.id)
            .pipe(mergeMap(function (n) { return forkJoin(_this.newsletterRepository.getSafeRTB(newsletter.id), of(n)); }))
            .subscribe(function (_a) {
            var newsletterSafeRTB = _a[0], n = _a[1];
            _this.newsletter = n;
            _this.newsletter.safeRTB = newsletterSafeRTB;
            _this.newsletter$.next(_this.newsletter);
            if (adSlotId) {
                _this.adSlotId$.next(adSlotId);
            }
            else {
                _this.adSlotId$.next(null);
            }
        });
        this.dialog.open();
    };
    NewsletterTagsComponent.prototype.closeModal = function () {
        this.form.reset();
        this.newsletter = null;
        this.adSlots = [];
        this.tagOptions = DEFAULT_TAG_OPTIONS.slice();
        this.newsletterTagsTable.reset();
    };
    NewsletterTagsComponent.prototype.downloadTags = function () {
        var _this = this;
        var args = [this.newsletter.id];
        if (this.width !== null && this.width !== undefined) {
            args.push(this.width.toString());
        }
        if (this.mobileWidth !== null && this.mobileWidth !== undefined) {
            args.push(this.mobileWidth.toString());
        }
        this.newsletterRepository
            .downloadTags(args)
            .subscribe(function (data) {
            DownloadHelper.downloadFile(data, "NewsletterTags-" + _this.newsletter.refId + ".xls");
        });
    };
    Object.defineProperty(NewsletterTagsComponent.prototype, "width", {
        get: function () {
            return this._width;
        },
        set: function (width) {
            this._width = width;
            for (var _i = 0, _a = this.tagOptions; _i < _a.length; _i++) {
                var slot = _a[_i];
                if (!slot.adSlot) {
                    continue;
                }
                slot.fullTag = this.replaceWidths(slot);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewsletterTagsComponent.prototype, "mobileWidth", {
        get: function () {
            return this._mobileWidth;
        },
        set: function (mobileWidth) {
            this._mobileWidth = mobileWidth;
            for (var _i = 0, _a = this.tagOptions; _i < _a.length; _i++) {
                var slot = _a[_i];
                if (!slot.adSlot) {
                    continue;
                }
                slot.fullTag = this.replaceWidths(slot);
            }
        },
        enumerable: true,
        configurable: true
    });
    NewsletterTagsComponent.prototype.replaceWidths = function (slot) {
        var fullTag = slot.adSlot.fullTag;
        var desktopRegex = new RegExp(WIDTH_MACRO, 'g');
        var mobileRegex = new RegExp(MOBILE_WIDTH_MACRO, 'g');
        var responsiveRegex = new RegExp(RESPONSIVE_WIDTH_MACRO, 'g');
        if (this._width !== null && this._width !== undefined) {
            fullTag = fullTag.replace(desktopRegex, this._width);
        }
        if (this._mobileWidth !== null && this._mobileWidth !== undefined) {
            fullTag = fullTag.replace(mobileRegex, this._mobileWidth);
            fullTag = fullTag.replace(responsiveRegex, this._mobileWidth);
        }
        return fullTag;
    };
    NewsletterTagsComponent.prototype.disabled = function () {
        return !this.form.valid;
    };
    return NewsletterTagsComponent;
}());
export { NewsletterTagsComponent };
