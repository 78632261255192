import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { PublisherKeyValue } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { map } from 'rxjs/operators';
var PublisherKeyValueRepository = /** @class */ (function (_super) {
    tslib_1.__extends(PublisherKeyValueRepository, _super);
    function PublisherKeyValueRepository(http) {
        return _super.call(this, http, '/key-value/publisher', PublisherKeyValue) || this;
    }
    PublisherKeyValueRepository.prototype.searchByPublisher = function (id) {
        var _this = this;
        return this.http.get(this.url('/search/key-value/publisher', id))
            .pipe(map(function (response) { return response['output'].map(function (data) { return _this.build(data); }); }));
    };
    return PublisherKeyValueRepository;
}(BackendRepository));
export { PublisherKeyValueRepository };
