import { Audience } from './audience';
import { Model } from './model';

export class AudienceSearchResponse extends Model {

    entries: Audience[];
    page: number;
    pageSize: number;
    pages: number;
    totalCount: number;

    serialize(): string {
        return JSON.stringify(this);
    }

    get entity() {
        return 'Audience Search Response';
    }
}
