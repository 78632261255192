import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { take, map, filter, tap, shareReplay } from 'rxjs/operators';
import { Logger, Store } from 'app/services';
import { TableData } from './table.component';

const TableColumns = 'table:columns';

@Injectable()
export class TableService {
    private column$ = new BehaviorSubject<string>(null);
    private direction$ = new BehaviorSubject('asc');
    private page$ = new BehaviorSubject(1);
    private size$ = new BehaviorSubject(10);

    data$ = combineLatest(this.column$, this.direction$, this.page$, this.size$).pipe(
        filter(([column]) => column !== null),
        map(data => this.packTableData(data)),
        shareReplay(1)
    );

    constructor(
        private logger: Logger,
        private store: Store
    ) { }

    setActiveColumns(tableId: string, activeColumns) {
        this.store.set(this.getTableColumnsKey(tableId), activeColumns);
    }

    getActiveColumns(tableId: string): string[] {
        return this.store.get(this.getTableColumnsKey(tableId));
    }

    private getTableColumnsKey(tableId: string) {
        const key = parseInt(tableId.toLowerCase().replace(/-/g, ''), 36);
        return `${TableColumns}:${key}`;
    }

    setColumn(column: string) {
        this.column$.next(column);
    }

    setPageSize(size: number) {
        this.size$.next(size);
    }

    setSortDirection(direction: string) {
        this.direction$.next(direction);
    }

    sortByColumn(column: string) {
        this.column$.pipe(take(1)).subscribe(currentColumn => {
            if (currentColumn === column) {
                const newDirection = this.direction$.getValue() === 'desc' ? 'asc' : 'desc';
                this.direction$.next(newDirection);
            } else {
                this.column$.next(column);
                this.direction$.next('desc');
            }
        });
    }

    gotoPage(page: number) {
        this.page$.next(page);
    }

    getCurrentPage() {
        return this.page$.getValue();
    }

    getSortDirection() {
        return this.direction$.getValue();
    }

    getColumn() {
        return this.column$.getValue();
    }

    private packTableData([column, direction, page, size]): TableData {
        return { column, direction, page, size };
    };

}
