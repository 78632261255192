import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { of } from 'rxjs';

import { AuthorizationService } from 'app/core/authorization.service';

@Injectable()
export class PackagesGuard implements CanActivate {
    constructor(
        private router: Router,
        private authorization: AuthorizationService,
    ) { }

    canActivate() {
        if (this.authorization.canAccessPackages) {
            return of(true);
        }

        this.router.navigate(['/inventory-manager']);
        return of(false);
    }
}
