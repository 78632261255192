import { Injectable } from '@angular/core';
import { Option } from 'app/shared/elements/dropdown';
import { InventoryTargetingOption } from './inventory-targeting-option.class';

@Injectable()
export class InventoryTargetingToOptions {
    static preparePublisherOption (item: any): Option {
        return new InventoryTargetingOption({
            type: 'Publisher',
            id: item.id,
            refId: item.refId,
            name: item.name || item.publisherName
        });
    }

    static prepareNewsletterOption (item: any): Option {
        return new InventoryTargetingOption({
            type: 'Newsletter',
            id: item.id,
            refId: item.refId,
            name: item.name
        });
    }

    static prepareAdSlotOption (item: any): Option {
        return new InventoryTargetingOption({
            type: 'Ad Slot',
            id: item.id,
            refId: item.refId,
            name: item.name,
            coordinationType: item.coordinationType
        });
    }

    static prepareBundleOption (item: any): Option {
        return new InventoryTargetingOption({
            type: 'Bundle',
            id: item.id,
            refId: item.refId,
            name: item.name
        });
    }

    static preparePackageOption (item: any): Option {
        return new InventoryTargetingOption({
            type: 'Package',
            id: item.id,
            refId: item.id,
            name: item.name
        });
    }

}
