import * as tslib_1 from "tslib";
import { Model } from './model';
var ReportingSchedule = /** @class */ (function (_super) {
    tslib_1.__extends(ReportingSchedule, _super);
    function ReportingSchedule() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ReportingSchedule.prototype.serialize = function () {
        var obj = Object.assign({}, this);
        delete obj._isClone;
        return JSON.stringify(obj);
    };
    Object.defineProperty(ReportingSchedule.prototype, "entity", {
        get: function () {
            return 'Reporting Schedule';
        },
        enumerable: true,
        configurable: true
    });
    return ReportingSchedule;
}(Model));
export { ReportingSchedule };
