import { Attribute, Component, Input } from '@angular/core';

@Component({
    selector: 'progress-bar',
    templateUrl: './progress-bar.html',
    styleUrls: ['./progress-bar.styl']
})
export class ProgressBarComponent {
    @Input('current') current: number;
    @Input('total') total: number;
    indeterminate: boolean;

    constructor(@Attribute('indeterminate') indeterminate: boolean) {
        this.indeterminate = indeterminate !== null;
    }

    get percentDone() {
        if (this.indeterminate) {
            return 100;
        }

        return this.total > 0 ? this.current / this.total * 100 : 0;
    }
}
