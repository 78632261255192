// snake-to-camel-case.pipe.ts

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'snakeToCamelCase' })
export class SnakeToCamelCasePipe implements PipeTransform {
    transform(value: string): string {
        if (!value) return value;
        return value.replace(/_([a-z])/g, (match, letter) =>
            letter.toUpperCase()
        );
    }
}
