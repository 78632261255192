import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Category } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { publishLast, refCount, map } from 'rxjs/operators';
var CategoryRepository = /** @class */ (function (_super) {
    tslib_1.__extends(CategoryRepository, _super);
    function CategoryRepository(http) {
        var _this = _super.call(this, http, '/category', Category) || this;
        // TODO implement based on the query params
        _this._allResult = null;
        _this._primaryCategoriesResult = null;
        return _this;
    }
    CategoryRepository.prototype.all = function (query) {
        if (query === void 0) { query = {}; }
        if (this._allResult) {
            return this._allResult;
        }
        this._allResult = _super.prototype.all.call(this, query)
            .pipe(publishLast(), refCount());
        return this._allResult;
    };
    /**
     * Makes a call for all Primary Categories (IAB 1-26)
     */
    CategoryRepository.prototype.getPrimaryCategories = function () {
        var query = { conditions: [{ field: 'parent', value: null }] };
        if (this._primaryCategoriesResult) {
            return this._primaryCategoriesResult;
        }
        this._primaryCategoriesResult = _super.prototype.search.call(this, query)
            .pipe(publishLast(), refCount());
        return this._primaryCategoriesResult;
    };
    CategoryRepository.prototype.searchAsOptions = function (query) {
        if (query === void 0) { query = {}; }
        return this.all(query).pipe(map(function (categories) {
            return categories.map(function (option) {
                return {
                    key: option.id,
                    value: option.id,
                    label: [option.iab, option.name].join(': '),
                    data: option
                };
            });
        }));
    };
    /**
     * Filter out any secondary categories.
     * @param  {any[]} categories
     * @return {any[]}
     */
    CategoryRepository.prototype.filterOutSecondary = function (categories) {
        return categories.filter(function (category) {
            if (category.data && category.data.parent === null) {
                return true;
            }
            else {
                return category.parent === null;
            }
        });
    };
    /**
     * Converts observable array of categories
     * into an observable array of options
     *
     * @param {Observable<Category[]>} categories
     * @return {Observable<Option[]>} options
     */
    CategoryRepository.prototype.convertCategoriesToOptions = function (categories) {
        return categories.pipe(map(function (categoryList) {
            return categoryList.map(function (option) {
                return {
                    key: option.id,
                    value: option.id,
                    label: [option.iab, option.name].join(': '),
                    data: option
                };
            });
        }));
    };
    return CategoryRepository;
}(BackendRepository));
export { CategoryRepository };
