import { Component, ViewChild, ElementRef } from '@angular/core';
import { Subject, BehaviorSubject, combineLatest } from 'rxjs';

import { Advertiser, MediaGroup } from 'app/shared/models';
import { AdvertiserRepository, MediaGroupRepository, UserRepository } from 'app/core/repositories';
import { TableComponent, TableData, TableQuery } from 'app/shared/elements/table';
import { LightboxnextComponent } from 'app/shared/elements/lightboxnext';
import { NotificationsService, PreferencesService, IdService } from 'app/core';
import { map, debounceTime, switchMap, retry, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { decorateAdvertisersWithNames, getManagersExecutivesForAdvertisers } from 'app/shared/helpers/advertiser-helper';
import { decorateMediaGroupsWithNames, getManagersExecutivesForMediaGroups } from 'app/shared/helpers/media-group-helper';

@Component({
    selector: 'choose-accounts',
    templateUrl: './choose-accounts.html',
    styleUrls: ['./choose-accounts.styl'],
})
export class ChooseAccountsComponent {
    @ViewChild('input', { static: true }) input: ElementRef;
    @ViewChild(TableComponent, { static: true }) table: TableComponent;
    @ViewChild(LightboxnextComponent, { static: true }) lightbox: LightboxnextComponent;

    search = new Subject<any>();
    selected = new BehaviorSubject<any>([]);
    all = new BehaviorSubject<any>([]);

    tableData = new BehaviorSubject<TableData>({
        items: [],
        total: 0,
        page: 0,
        pages: 0
    });

    constructor(
        private preferences: PreferencesService,
        private notifications: NotificationsService,
        private advertiserRepository: AdvertiserRepository,
        private mediaGroupRepository: MediaGroupRepository,
        private userRepository: UserRepository,
        public id: IdService
    ) {
        this.setDefaultAccounts();

        combineLatest(this.preferences.accounts, this.selected).pipe(
            map(([active, selected]) => [].concat(active, selected))
        ).subscribe(this.all);

        combineLatest(
            this.search.pipe(
                debounceTime(300),
                switchMap(query => this.findAccounts(query)),
                distinctUntilChanged(),
                retry()
            ),
            this.all
        ).pipe(map(([accounts, selected]) => {
            const items = accounts.filter(account => {
                return selected.findIndex(selected => selected.refId === account.refId) < 0;
            });

            return {
                items,
                total: accounts.length,
                page: 1,
                pages: items.length > 0 ? 1 : 0
            };
        })).subscribe(tableData => this.tableData.next(tableData));
    }

    setDefaultAccounts() {
        combineLatest(this.findAccounts(''), this.all).pipe(
            map(([accounts, selected]) => {
                const items = accounts.filter(account => {
                    return selected.findIndex(selected => selected.refId === account.refId) < 0;
                });
                return {
                    items,
                    total: accounts.length,
                    page: 1,
                    pages: items.length > 0 ? 1 : 0
                };
            })
        ).subscribe(tableData => this.tableData.next(tableData));

    }

    query({ orderBy, direction }: TableQuery) {
        let tableData = this.tableData.getValue();

        if (tableData.items.length > 0) {
            tableData  = {
                items: this.sort(tableData.items, orderBy, direction),
                total: tableData.items.length,
                page: 1,
                pages: tableData.items.length > 0 ? 1 : 0
            };

            this.tableData.next(tableData);
        }
    }

    private sort(items, orderBy, direction) {
        const compare = (a, b) => {
            if (a === undefined) {
                return 1;
            }

            if (b === undefined) {
                return -1;
            }

            if (a > b) {
                return -1;
            }

            if (b > a) {
                return 1;
            }

            if (a === b) {
                return 0;
            }
        };

        items.sort((a, b) => compare(a[orderBy], b[orderBy]));

        if (direction === 'DESC') {
            items.reverse();
        }

        return items;
    }

    open() {
        this.lightbox.show();
    }

    close() {
        this.lightbox.hide();
    }

    cancel() {
        this.lightbox.hide();
    }

    reset() {
        this.input.nativeElement.value = '';
        this.search.next(this.input.nativeElement.value);
        this.clearAll();
    }

    change(query) {
        this.search.next(query);
    }

    select(account) {
        this.selected.next(this.selected.getValue().concat(account));
    }

    update(accounts) {
        this.selected.next(accounts);
    }

    addAccounts() {
        const accounts = this.all.getValue();
        const advertisers = accounts.filter(account => account instanceof Advertiser);
        const mediaGroups = accounts.filter(account => account instanceof MediaGroup);
        this.preferences.setAccounts(advertisers, mediaGroups).subscribe(data => {
            this.notifications.success(`${this.selected.getValue().length} account(s) have been added.`);
            this.close();
        });
    }

    clearAll() {
        this.selected.next([]);
    }

    get canAddAccounts() {
        return this.selected.getValue().length > 0;
    }

    private findAccounts(query: string) {
        return combineLatest(this.findAdvertisers(query), this.findMediaGroups(query)).pipe(
            map(([advertisers, mediaGroups]) => [].concat(advertisers, mediaGroups)),
            map(accounts => accounts.sort((a, b) => a.name > b.name ? 1 : b.name > a.name ? -1 : 0)));
    }

    private findAdvertisers(query: string) {

        let conditions;

        if (query) {
            conditions = [
                {
                    field: 'name',
                    value: query,
                    operator: 'like'
                },
                {
                    mode: 'or',
                    field: 'agencyName',
                    value: query,
                    operator: 'like'
                },
                {
                    mode: 'or',
                    field: 'managerFirstName',
                    value: query,
                    operator: 'like'
                },
                {
                    mode: 'or',
                    field: 'managerLastName',
                    value: query,
                    operator: 'like'
                },
                {
                    mode: 'or',
                    field: 'executiveFirstName',
                    value: query,
                    operator: 'like'
                },
                {
                    mode: 'or',
                    field: 'executiveLastName',
                    value: query,
                    operator: 'like'
                }
            ];

            if (/^\d+$/.test(query)) {
                conditions.push({
                    mode: 'or',
                    field: 'refId',
                    value: query,
                    operator: 'like'
                });
            }
        }

        return this.advertiserRepository.search({ number: 50, conditions }).pipe(
            mergeMap(advertisers => getManagersExecutivesForAdvertisers(advertisers, this.userRepository)),
            mergeMap(decorateAdvertisersWithNames)
        );
    }

    private findMediaGroups(query: string) {

        let conditions;

        if (query) {
            conditions = [
                {
                    field: 'name',
                    value: query,
                    operator: 'like',
                    mode: 'or'
                },
                {
                    field: 'managerName',
                    value: query,
                    operator: 'like',
                    mode: 'or'
                }, {
                    field: 'executiveName',
                    value: query,
                    operator: 'like',
                    mode: 'or'
                }
            ];

            if (/^\d+$/.test(query)) {
                conditions.push({
                    field: 'refId',
                    value: query,
                    operator: 'like',
                    mode: 'or'
                });
            }
        }

        return this.mediaGroupRepository.search({ number: 50, conditions }).pipe(
            mergeMap(mgs => getManagersExecutivesForMediaGroups(mgs, this.userRepository)),
            mergeMap(decorateMediaGroupsWithNames)
        );
    }
}
