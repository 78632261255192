import { EventEmitter, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Action, AuthorizationService, Entity, Role } from 'app/core/authorization.service';
import { AdBuilderNativeComponent } from './ad-builder-native';
import { AdBuilderNativeExchangeComponent } from './ad-builder-native-exchange';
import { BulkTagUploaderComponent } from './bulk-tag-uploader';
import { Campaign, Creative, LineItem } from 'app/shared/models';
import { CreativeRepository } from 'app/core/repositories';
import { PaginatorComponent } from 'app/shared/elements/paginator';
import { TableComponent } from 'app/shared/elements/table';
import { TableHelper } from 'app/shared/helpers/table-helper';
import { TabsComponent } from 'app/shared/elements/tabs';
import { IdService } from 'app/core/id.service';
import { ValidationService } from 'app/core/validation-service';
import validationErrors from 'app/core/errors/validation-errors.json';
import { isOverMaxFileSize, getMaxFileSizeByType } from 'app/shared/helpers/ad-builder-helper';
import { lodashDeepClone, truncateString } from 'app/core/utils';
import { Flag, LaunchDarklyService } from 'app/core/launch-darkly.service';
import { creativeStatusColors } from 'app/shared/models/creative';
var AdBuilderComponent = /** @class */ (function () {
    function AdBuilderComponent(auth, creativeRepository, id, launchDarklyService) {
        var _this = this;
        this.auth = auth;
        this.creativeRepository = creativeRepository;
        this.id = id;
        this.launchDarklyService = launchDarklyService;
        this.validationService = null;
        this.validationErrors = validationErrors;
        this.search = '';
        this.creativeRows = [];
        this.tags = [];
        this.state = 'closed';
        this.allChecked = false;
        this.anyChecked = false;
        this.checkedRows = 0;
        this.isCreativeSizeValid = false;
        this.helper = new TableHelper(function (params) { return _this.adapter(params); });
        this.truncateString = truncateString;
        this.rolloutNativeCreativeTrafficking$ = new BehaviorSubject(false);
        this.action = Action;
        this.role = Role;
        this.entity = Entity;
        this.creatives = [];
        this.ads = [];
        this.adsChange = new EventEmitter();
        this.onClosed = new EventEmitter();
        this._placementId$ = new BehaviorSubject('');
    }
    Object.defineProperty(AdBuilderComponent.prototype, "placementId", {
        set: function (value) {
            if (value) {
                this._placementId$.next(value);
            }
            else {
                this._placementId$.next(null);
            }
        },
        enumerable: true,
        configurable: true
    });
    AdBuilderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.validationService = new ValidationService();
        this.addThirdPartyTag();
        this.helper.table = this.table;
        this.helper.paginator = this.paginator;
        combineLatest(this.table.query, this.paginator.query).subscribe(function (args) { return _this.helper.search(args); });
        this.launchDarklyService
            .getVariation(Flag.RolloutNativeCreativeTrafficking)
            .pipe(distinctUntilChanged())
            .subscribe(function (rolloutNativeCreativeTrafficking) {
            _this.rolloutNativeCreativeTrafficking$.next(rolloutNativeCreativeTrafficking);
        });
        this.rolloutNativeCreativeTrafficking$.subscribe(function (value) {
            _this.rolloutNativeCreativeTrafficking = value;
        });
    };
    AdBuilderComponent.prototype.sourceUrlBlur = function (tag) {
        var img = new Image();
        img.src = tag.mediaUrl;
        img.onload = function () {
            tag.height = img.height;
            tag.width = img.width;
        };
    };
    AdBuilderComponent.prototype.addThirdPartyTag = function () {
        this.tags.push(new Creative({
            name: '',
            type: 'image',
            mediaUrl: '',
            clickUrl: '',
            urlTracking1: null,
            thirdPartyTag: true,
            _secondary: false,
            isModified: true
        }));
    };
    AdBuilderComponent.prototype.remove = function (index) {
        this.tags.splice(index, 1);
    };
    AdBuilderComponent.prototype.displayUploadError = function () {
        this.fileError = 'The file type you uploaded is not supported. ' +
            'Please upload a JPG, PNG, or GIF file.';
    };
    AdBuilderComponent.prototype.upload = function (files) {
        var component = this;
        var ads = [];
        for (var _i = 0, files_1 = files; _i < files_1.length; _i++) {
            var file = files_1[_i];
            var fileType = file.type;
            if (isOverMaxFileSize(file.file.size, fileType, !this.auth.canUseIncreasedCreativeFileSizes)) {
                return this.fileError = 'The uploaded file exceeds the maximum file size of '
                    + getMaxFileSizeByType(fileType, !this.auth.canUseIncreasedCreativeFileSizes) + 'kb. Please try uploading another file.';
            }
            load(file);
        }
        function load(file) {
            var image = new Image();
            image.src = file.dataURL;
            image.onload = function () {
                ads.push(new Creative({
                    type: 'image',
                    name: file.name,
                    mediaUrl: file.dataURL,
                    clickUrl: '',
                    width: image.width,
                    height: image.height,
                    thirdPartyTag: false,
                    isModified: true,
                    created: Date.now()
                }));
                if (ads.length === files.length) {
                    component.addToLineItem(ads);
                }
            };
        }
    };
    AdBuilderComponent.prototype.addToLineItem = function (advertisements) {
        var _this = this;
        var ads = advertisements || [];
        if (this.tabs.activeTab.header === 'Add Third Party Tags') {
            ads = this.tags;
        }
        if (this.tabs.activeTab.header === 'Use Existing Ads') {
            ads = this.table.selectedItems;
        }
        if (this.tabs.activeTab.header === 'Native Ads') {
            if (this.nativeAdForm) {
                ads = [this.nativeAdForm.creative.clone()];
            }
            else if (this.nativeExchangeAdForm) {
                ads = [this.nativeExchangeAdForm.creative.clone()];
            }
        }
        if (this.tabs.activeTab.header === 'Bulk Tag Uploader') {
            ads = this.bulkTagUploader.selectedCreatives;
        }
        if (ads.length > 0) {
            this.ads = this.ads.concat(ads);
            this.ads.forEach(function (ad) {
                ad.advertiser = _this.campaign.advertiser;
            });
            this.adsChange.emit(this.ads);
        }
        this.files = null;
        this.state = 'closed';
        this.table.clearSelections();
    };
    AdBuilderComponent.prototype.open = function () {
        this.state = 'open';
        this.fileError = null;
        if (this.nativeAdForm) {
            this.nativeAdForm.reset();
        }
        if (this.nativeExchangeAdForm) {
            this.nativeExchangeAdForm.reset();
        }
        this.paginator.reset();
    };
    AdBuilderComponent.prototype.closeModal = function () {
        this.files = null;
        this.onClosed.emit();
        this.bulkTagUploader.reset();
        this.state = 'closed';
    };
    AdBuilderComponent.prototype.isValid = function () {
        if (this.tabs && this.tabs.activeTab && this.tabs.activeTab.header === 'Native Ads') {
            return this.areAllRequiredFieldsValid && this.isCreativeSizeValid;
        }
        else if (this.tabs && this.tabs.activeTab && this.tabs.activeTab.header === 'Bulk Tag Uploader') {
            return this.bulkTagUploader.selectedCreatives.length > 0;
        }
        return true;
    };
    AdBuilderComponent.prototype.sortCreatives = function (params, creatives) {
        // Sorting by string
        if (params.orderBy === 'name') {
            if (params.sort.toLowerCase() === 'asc') {
                return creatives.sort(function (a, b) { return a[params.orderBy].localeCompare(b[params.orderBy]); });
            }
            return creatives.sort(function (a, b) { return b[params.orderBy].localeCompare(a[params.orderBy]); });
        }
        if (params.sort.toLowerCase() === 'asc') {
            return creatives.sort(function (a, b) { return (a[params.orderBy] > b[params.orderBy]) ? 1 : ((b[params.orderBy] > a[params.orderBy]) ? -1 : 0); });
        }
        return creatives.sort(function (a, b) { return (a[params.orderBy] < b[params.orderBy]) ? 1 : ((b[params.orderBy] < a[params.orderBy]) ? -1 : 0); });
    };
    Object.defineProperty(AdBuilderComponent.prototype, "areAllRequiredFieldsValid", {
        get: function () {
            if (this.nativeAdForm) {
                return this.nativeAdForm.form.valid && !this.nativeAdForm.isBlueprintNotSelected;
            }
            else if (this.nativeExchangeAdForm) {
                return this.nativeExchangeAdForm.nativeForm.valid && this.nativeExchangeAdForm.hasAssets() && !this.nativeExchangeAdForm.hasAssetErrors();
            }
        },
        enumerable: true,
        configurable: true
    });
    AdBuilderComponent.prototype.setIsCreativeSizeValid = function (_a) {
        var isValid = _a.isValid;
        // The `id` field is used only in ad-details and can be ignored here.
        this.isCreativeSizeValid = isValid;
    };
    AdBuilderComponent.prototype.adapter = function (params) {
        var _a;
        var _this = this;
        var conditions = [];
        if (this.ads.length > 0) {
            conditions.push({
                field: 'id',
                value: this.ads
                    .filter(function (ad) { return !!ad.id; })
                    .map(function (ad) { return ad.id; }),
                operator: 'neq'
            });
        }
        if (this.campaign) {
            conditions.push({
                field: 'advertiser',
                value: this.campaign.advertiser,
                operator: 'eq'
            });
            if (this.campaign.demandType !== 'direct') {
                conditions.push({
                    field: 'type',
                    value: Creative.nativeTypes,
                    operator: 'neq'
                });
            }
            if (this.campaign.demandType !== 'exchange') {
                conditions.push({
                    field: 'isNativeLike',
                    value: ['0'],
                    operator: 'eq'
                });
            }
        }
        (_a = params.conditions).push.apply(_a, conditions);
        if (this.campaign && this.campaign.demandType === 'exchange') {
            return this.creativeRepository.asyncSearch(params)
                .pipe(map(function (response) { return ({
                items: response.items,
                pages: response.pages,
                total: response.total,
                page: params.page
            }); }));
        }
        // Build native conditions off creative conditions w/ caveats
        var nativeParams = JSON.parse(JSON.stringify(params));
        if (this.campaign && this.campaign.demandType === 'direct') {
            nativeParams.conditions.push({
                field: 'blueprintId',
                value: null,
                operator: 'neq'
            });
        }
        nativeParams.conditions = nativeParams.conditions.filter(function (condition) { return !(condition.field == 'isNativeLike'); });
        nativeParams.conditions.push({ field: 'externalId', value: null });
        if (params.conditions.length > 0 && nativeParams.conditions.length > 0) {
            if (this.rolloutNativeCreativeTrafficking === true && this.campaign.demandType !== 'exchange') {
                var nativeCreativeTraffickingParams = lodashDeepClone(nativeParams);
                nativeCreativeTraffickingParams.conditions = nativeCreativeTraffickingParams.conditions.filter(function (condition) { return condition.field !== 'blueprintId'; });
                nativeCreativeTraffickingParams.conditions.push({
                    field: 'isDirectSoldTrafficking',
                    value: true,
                    operator: 'eq'
                });
                return this.creativeRepository.searchAllCreatives(params, nativeParams, nativeCreativeTraffickingParams).pipe(map(function (creatives) {
                    var items = [];
                    var creativeTotal = creatives.length;
                    creatives = _this.sortCreatives(params, creatives);
                    // Creating pages
                    while (creatives.length > 0)
                        items.push(creatives.splice(0, params.n));
                    return {
                        items: items.length > 0 ? items[params.page - 1] : items,
                        page: params.page,
                        pages: Math.ceil(creativeTotal / params.n),
                        total: creativeTotal
                    };
                }));
            }
            else {
                return forkJoin(this.creativeRepository.asyncSearch(params), this.creativeRepository.asyncSearch(nativeParams, '/search/creative/native')).pipe(map(function (_a) {
                    var nonNative = _a[0], native = _a[1];
                    return {
                        items: nonNative.items.concat(native.items),
                        page: params.page,
                        pages: nonNative.pages + native.pages,
                        total: nonNative.total + native.total
                    };
                }));
            }
        }
        return of({
            items: [],
            page: 1,
            pages: 1,
            total: 0
        });
    };
    AdBuilderComponent.prototype.handleOnShowSecondaryTracker = function (index, isExpanded) {
        if (isExpanded === false) {
            this.tags[index].urlTracking2 = null;
        }
    };
    AdBuilderComponent.prototype.getCreativeStatusColor = function (status) {
        return creativeStatusColors[status.toLowerCase()];
    };
    return AdBuilderComponent;
}());
export { AdBuilderComponent };
