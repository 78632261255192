import { Component, Input } from '@angular/core';

import { AuthorizationService } from 'app/core';
import { IdService } from 'app/core/id.service';
import { getLinkFromEntity, formatIndex } from 'app/shared/helpers/search-helper';

@Component({
    selector: 'global-search-display',
    templateUrl: './global-search-display.html',
    styleUrls: ['./global-search-display.styl']
})
export class GlobalSearchDisplayComponent {
    @Input() header: string;
    @Input() results: any[];
    @Input() total: number;

    constructor(
        public auth: AuthorizationService,
        public id: IdService
    ) { }

    link(entity: any) {
        return getLinkFromEntity(entity);
    }

    trackById(index: number, entity) {
        return entity.id;
    }

    formatIndex(index: string): string {
        return formatIndex(index);
    }
}
