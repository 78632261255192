import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { BuyerSeat } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { map } from 'rxjs/operators';
var BuyerSeatRepository = /** @class */ (function (_super) {
    tslib_1.__extends(BuyerSeatRepository, _super);
    function BuyerSeatRepository(http) {
        return _super.call(this, http, '/buyer', BuyerSeat) || this;
    }
    BuyerSeatRepository.prototype.save = function (buyerSeat) {
        var _this = this;
        var params = [this.path];
        if (buyerSeat.id) {
            params.push(buyerSeat.id.toString());
        }
        return this.http.post(this.url.apply(this, params), buyerSeat.serialize())
            .pipe(map(function (response) { return _this.build(response['output']); }));
    };
    BuyerSeatRepository.prototype.searchIfaas = function (params) {
        var _this = this;
        var path = "/search/buyer";
        return this.http.post(this.url(path), this.sanitizeQuery(params))
            .pipe(map(function (data) { return ({
            items: data['output'].map(function (item) { return _this.build(item); }),
            page: 1,
            pages: data['pages'],
            total: data['total']
        }); }));
    };
    return BuyerSeatRepository;
}(BackendRepository));
export { BuyerSeatRepository };
