import { TemplateRef, EventEmitter, OnInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import { fromEvent } from 'rxjs';
import { DropdownComponent } from 'app/shared/elements/dropdown';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { IdService } from 'app/core/id.service';
var SearchInputComponent = /** @class */ (function () {
    function SearchInputComponent(cdr, id, placeholder) {
        this.cdr = cdr;
        this.id = id;
        this.placeholder = placeholder;
        this.disabled = false;
        this.inputChange = new EventEmitter();
        this.itemSelect = new EventEmitter();
        this.items = [];
    }
    SearchInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        fromEvent(this.inputRef.nativeElement, 'input').pipe(tap(function () { return _this.cdr.markForCheck(); }), distinctUntilChanged()).subscribe(function (value) { return _this.inputChange.emit(_this.value); });
    };
    Object.defineProperty(SearchInputComponent.prototype, "value", {
        get: function () {
            return this.inputRef.nativeElement.value;
        },
        set: function (value) {
            this.inputRef.nativeElement.value = value;
            this.inputChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchInputComponent.prototype, "setItems", {
        set: function (items) {
            this.items = items;
        },
        enumerable: true,
        configurable: true
    });
    SearchInputComponent.prototype.select = function (index) {
        this.itemSelect.emit(this.items[index]);
        this.hover = -1;
        this.value = '';
    };
    SearchInputComponent.prototype.focus = function () {
        this.hover = -1;
    };
    SearchInputComponent.prototype.blur = function (event) {
        this.value = '';
        this.dropdown.hide();
        delete this.hover;
    };
    SearchInputComponent.prototype.enter = function () {
        if (typeof this.hover === 'number' && this.hover > -1) {
            this.select(this.hover);
        }
    };
    SearchInputComponent.prototype.arrowup = function (event) {
        event.preventDefault();
        this.hover--;
        if (this.hover < 0) {
            this.hover = this.items.length - 1;
        }
    };
    SearchInputComponent.prototype.arrowdown = function (event) {
        event.preventDefault();
        this.hover++;
        if (this.hover === this.items.length) {
            this.hover = 0;
        }
    };
    SearchInputComponent.prototype.mouseenter = function (index) {
        this.hover = index;
    };
    SearchInputComponent.prototype.mousedown = function (event) {
        // Allow for `select` to be called.
        event.preventDefault();
    };
    return SearchInputComponent;
}());
export { SearchInputComponent };
