/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./row-actions.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../directives/id-append.directive";
import * as i3 from "@angular/common";
import * as i4 from "../../components/portal/portal.component.ngfactory";
import * as i5 from "../../components/portal/portal.component";
import * as i6 from "../dropdown/dropdown.component.ngfactory";
import * as i7 from "../dropdown/dropdown.component";
import * as i8 from "./row-actions.component";
import * as i9 from "../../../core/id.service";
var styles_RowActionsComponent = [i0.styles];
var RenderType_RowActionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RowActionsComponent, data: {} });
export { RenderType_RowActionsComponent as RenderType_RowActionsComponent };
function View_RowActionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [], [[2, "no-border", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [["class", "small"]], [[2, "disabled", null], [1, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickAction($event, _v.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i2.IdAppendDirective, [i1.ElementRef], { id: [0, "id"] }, null), i1.ɵpad(4, 1), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n        "]))], function (_ck, _v) { var currVal_3 = _ck(_v, 4, 0, _v.context.$implicit.label); _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.options.length <= _co.maxRowActions) && (_v.context.index === (_co.options.length - 1))); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.disabled; var currVal_2 = i1.ɵnov(_v, 3).attrId; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_4 = _v.context.$implicit.label; _ck(_v, 5, 0, currVal_4); }); }
function View_RowActionsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "li", [["class", "align-left"]], [[2, "dropdown--item---index", null]], [[null, "mouseenter"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.mouseenter($event, _v.context.$implicit.key) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.clickDropdownAction($event, _v.context.$implicit.value) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                    "])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "a", [], [[1, "id", 0], [2, "disabled", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                        "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], [[1, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n                    "])), (_l()(), i1.ɵted(-1, null, ["\n                "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.key === _v.context.$implicit.key); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.id.control("row-actions", "more-options", _v.context.index); var currVal_2 = _v.context.$implicit.disabled; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _co.id.control("row-actions", _v.context.$implicit.label, _v.context.index); _ck(_v, 4, 0, currVal_3); var currVal_4 = _v.context.$implicit.label; _ck(_v, 5, 0, currVal_4); }); }
export function View_RowActionsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { dropdown: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 31, "div", [["class", "row-actions"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(3, 0, null, null, 14, "ul", [["class", "links"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RowActionsComponent_1)), i1.ɵdid(6, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(8, 0, null, null, 8, "li", [], [[2, "hidden", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(10, 0, [["trigger", 1]], null, 5, "a", [["class", "small"]], [[1, "id", 0]], [[null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (_co.keydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.clickedMore($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["More "])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "i", [["class", "fas fa-chevron-down"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(19, 0, null, null, 12, "app-portal", [], null, null, null, i4.View_PortalComponent_0, i4.RenderType_PortalComponent)), i1.ɵdid(20, 4898816, null, 0, i5.PortalComponent, [i1.ComponentFactoryResolver, i1.ApplicationRef, i1.Injector], { top: [0, "top"], left: [1, "left"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n        "])), (_l()(), i1.ɵeld(22, 0, null, 0, 8, "dropdown", [["class", "dropdown align-right"]], null, [[null, "visibility"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibility" === en)) {
        var pd_0 = (_co.dropdownVisChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_DropdownComponent_0, i6.RenderType_DropdownComponent)), i1.ɵdid(23, 245760, [[1, 4]], 0, i7.DropdownComponent, [i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { trigger: [0, "trigger"] }, { visibility: "visibility" }), (_l()(), i1.ɵted(-1, 0, ["\n            "])), (_l()(), i1.ɵeld(25, 0, null, 0, 4, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RowActionsComponent_2)), i1.ɵdid(28, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵted(-1, 0, ["\n        "])), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.linkOptions; _ck(_v, 6, 0, currVal_0); var currVal_3 = _co.dropdownPos.top; var currVal_4 = _co.dropdownPos.left; _ck(_v, 20, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵnov(_v, 10); _ck(_v, 23, 0, currVal_5); var currVal_6 = _co.dropdownOptions; _ck(_v, 28, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.options.length <= _co.maxRowActions); _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.id.control("row-actions", "links", "more-link"); _ck(_v, 10, 0, currVal_2); }); }
export function View_RowActionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "row-actions", [], null, null, null, View_RowActionsComponent_0, RenderType_RowActionsComponent)), i1.ɵdid(1, 1163264, null, 1, i8.RowActionsComponent, [i1.ElementRef, i1.ChangeDetectorRef, i9.IdService, [8, null]], null, null), i1.ɵqud(603979776, 1, { contentOptions: 1 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RowActionsComponentNgFactory = i1.ɵccf("row-actions", i8.RowActionsComponent, View_RowActionsComponent_Host_0, { maxRowActions: "maxRowActions", options: "options" }, { clickedAction: "clickedAction" }, []);
export { RowActionsComponentNgFactory as RowActionsComponentNgFactory };
