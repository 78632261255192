import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LiveConnectSetupMail } from 'app/shared/models';
import { environment } from 'src/environments/environment';

@Injectable()
export class MailRepository {
    constructor(private http: HttpClient) { }

    sendLiveConnectSetup(mail: LiveConnectSetupMail) {
        return this.http.post(this.url('/mail/live-connect-setup'), JSON.stringify(mail));
    }

    /**
     * Builds and returns a URL for the backend API given an array of strings
     * `suffix` which are joined using a forward-slash.
     * @param suffix
     */
    url(...suffix: string[]): string {
        return environment.backend.hostname + suffix.join('/');
    }
}
