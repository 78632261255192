import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { of } from 'rxjs';

import { AuthenticationService } from 'app/core/authentication.service';
import { NavigationService } from 'app/core/navigation.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private navigationService: NavigationService,
        private authentication: AuthenticationService,
    ) { }

    canActivate() {
        return this.authentication.refreshUser().pipe(
            map(() => true),
            catchError(() => {
                this.navigationService.redirectToLogin();
                return of(false);
            }));
    }
}
