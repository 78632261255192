/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./draggable-list.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/cdk/drag-drop";
import * as i3 from "@angular/common";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "@angular/forms";
import * as i6 from "./draggable-list.component";
var styles_DraggableListComponent = [i0.styles];
var RenderType_DraggableListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DraggableListComponent, data: {} });
export { RenderType_DraggableListComponent as RenderType_DraggableListComponent };
function View_DraggableListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-bars fa-fw"]], null, null, null, null, null))], null, null); }
function View_DraggableListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 14, "div", [["cdkDrag", ""], ["class", "draggable-list draggable-list-box cdk-drag"]], [[2, "lock", null], [2, "cdk-drag-disabled", null], [2, "cdk-drag-dragging", null]], null, null, null, null)), i1.ɵprd(6144, null, i2.ɵb, null, [i2.CdkDrag]), i1.ɵdid(2, 4866048, [[1, 4]], 3, i2.CdkDrag, [i1.ElementRef, [3, i2.CDK_DROP_LIST], i3.DOCUMENT, i1.NgZone, i1.ViewContainerRef, i2.CDK_DRAG_CONFIG, [2, i4.Directionality], i2.DragDrop, i1.ChangeDetectorRef], { disabled: [0, "disabled"] }, null), i1.ɵqud(603979776, 2, { _handles: 1 }), i1.ɵqud(603979776, 3, { _previewTemplate: 0 }), i1.ɵqud(603979776, 4, { _placeholderTemplate: 0 }), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DraggableListComponent_2)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(11, null, ["\n            ", "\n        "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["aria-hidden", "true"]], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.isLocked; _ck(_v, 2, 0, currVal_3); var currVal_5 = !_co.isLocked; _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLocked; var currVal_1 = i1.ɵnov(_v, 2).disabled; var currVal_2 = i1.ɵnov(_v, 2)._dragRef.isDragging(); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = i1.ɵinlineInterpolate(1, "", _co.drag, ""); _ck(_v, 7, 0, currVal_4); var currVal_6 = _v.context.$implicit.displayName; _ck(_v, 11, 0, currVal_6); var currVal_7 = i1.ɵinlineInterpolate(1, "", _co.icon, ""); _ck(_v, 13, 0, currVal_7); }); }
export function View_DraggableListComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵted(-1, null, [" "])), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["cdkDropList", ""], ["class", "cdk-drop-list"]], [[8, "id", 0], [2, "cdk-drop-list-disabled", null], [2, "cdk-drop-list-dragging", null], [2, "cdk-drop-list-receiving", null]], [[null, "cdkDropListDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cdkDropListDropped" === en)) {
        var pd_0 = (_co.drop($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(6144, null, i2.CDK_DROP_LIST, null, [i2.CdkDropList]), i1.ɵdid(3, 1196032, [["activeList", 4]], 1, i2.CdkDropList, [i1.ElementRef, i2.DragDrop, i1.ChangeDetectorRef, [2, i4.Directionality], [3, i2.CdkDropListGroup]], { connectedTo: [0, "connectedTo"], data: [1, "data"] }, { dropped: "cdkDropListDropped" }), i1.ɵqud(603979776, 1, { _draggables: 1 }), i1.ɵpad(5, 1), i1.ɵprd(256, null, i2.CdkDropListGroup, undefined, []), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DraggableListComponent_1)), i1.ɵdid(9, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = _ck(_v, 5, 0, i1.ɵnov(_v, 3)); var currVal_5 = _co.items; _ck(_v, 3, 0, currVal_4, currVal_5); var currVal_6 = _co.items; _ck(_v, 9, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).id; var currVal_1 = i1.ɵnov(_v, 3).disabled; var currVal_2 = i1.ɵnov(_v, 3)._dropListRef.isDragging(); var currVal_3 = i1.ɵnov(_v, 3)._dropListRef.isReceiving(); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_DraggableListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-draggable-list", [], null, null, null, View_DraggableListComponent_0, RenderType_DraggableListComponent)), i1.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.DraggableListComponent]), i1.ɵdid(2, 49152, null, 1, i6.DraggableListComponent, [i1.ChangeDetectorRef], null, null), i1.ɵqud(335544320, 1, { itemtpl: 0 })], null, null); }
var DraggableListComponentNgFactory = i1.ɵccf("app-draggable-list", i6.DraggableListComponent, View_DraggableListComponent_Host_0, { section: "section", icon: "icon", drag: "drag" }, { onRemove: "onRemove" }, []);
export { DraggableListComponentNgFactory as DraggableListComponentNgFactory };
