export abstract class Closable {

    public onHide;
    private button: HTMLElement;
    abstract hide(): void;

    private clickedOutsideComponent(target) {
        return !this.nativeElement.contains(target);
    }

    private buttonClicked (target) {
        return this.button && this.button.contains(target);
    }

    ngOnInit() {
        this.onHide.subscribe(() => {
            document.body.removeEventListener('mousedown', this.mousedownHandler);
            this.button = null;
        });
    }

    private mousedownHandler(e) {
        if (this.buttonClicked(e.target)) {
            return;
        }

        if (this.clickedOutsideComponent(e.target)) {
            this.hide();
        }
    }

    constructor(private nativeElement) {
        this.mousedownHandler = this.mousedownHandler.bind(this);
    }

    show (e): void {
        document.body.addEventListener('mousedown', this.mousedownHandler);
        this.button = e.currentTarget;
    }
}
