import {
    Component,
    Attribute,
    Input,
    TemplateRef,
    ContentChild,
    EventEmitter,
    Output,
    OnInit,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    ViewChild,
    ElementRef
} from '@angular/core';

import { Observable, fromEvent } from 'rxjs';
import { DropdownComponent } from 'app/shared/elements/dropdown';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { IdService } from 'app/core/id.service';

@Component({
    selector: 'search-input',
    templateUrl: './search-input.html',
    styleUrls: ['./search-input.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchInputComponent implements OnInit {
    @ContentChild(TemplateRef, { static: true }) template: TemplateRef<{ item: any, index: number }>;
    @Input() disabled: Boolean = false;
    @Output('input-change') inputChange = new EventEmitter<string>();
    @Output('item-select') itemSelect = new EventEmitter<any>();
    @ViewChild('input', { static: true }) inputRef: ElementRef;
    @ViewChild(DropdownComponent, { static: true }) dropdown: DropdownComponent;
    items = [];
    hover: number;

    constructor(
        private cdr: ChangeDetectorRef,
        public id: IdService,
        @Attribute('placeholder') public placeholder: string
    ) { }

    ngOnInit() {
        fromEvent(this.inputRef.nativeElement, 'input').pipe(
            tap(() => this.cdr.markForCheck()),
            distinctUntilChanged()
        ).subscribe(value => this.inputChange.emit(this.value));
    }

    get value() {
        return this.inputRef.nativeElement.value;
    }

    set value(value: string) {
        this.inputRef.nativeElement.value = value;
        this.inputChange.emit(value);
    }

    @Input('items') set setItems(items: any[]) {
        this.items = items;
    }

    select(index: number) {
        this.itemSelect.emit(this.items[index]);
        this.hover = -1;
        this.value = '';
    }

    focus() {
        this.hover = -1;
    }

    blur(event: FocusEvent) {
        this.value = '';
        this.dropdown.hide();
        delete this.hover;
    }

    enter() {
        if (typeof this.hover === 'number' && this.hover > -1) {
            this.select(this.hover);
        }
    }

    arrowup(event: KeyboardEvent) {
        event.preventDefault();

        this.hover--;

        if (this.hover < 0) {
            this.hover = this.items.length - 1;
        }
    }

    arrowdown(event: KeyboardEvent) {
        event.preventDefault();

        this.hover++;

        if (this.hover === this.items.length) {
            this.hover = 0;
        }
    }

    mouseenter(index: number) {
        this.hover = index;
    }

    mousedown(event: MouseEvent) {
        // Allow for `select` to be called.
        event.preventDefault();
    }
}
