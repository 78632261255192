import { TemplateRef, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { of, forkJoin, combineLatest } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthorizationService } from 'app/core/authorization.service';
import { AvailableActionsService } from 'app/shared/helpers/available-actions.service';
import { ConfirmDialogComponent } from 'app/shared/elements/confirm-dialog';
import { ErrorHelper } from 'app/core/errors/error-helper';
import { IdService } from 'app/core/id.service';
import { InsertionOrderRepository } from 'app/core/repositories';
import { NotificationsService } from 'app/core';
import { PaginatorComponent } from 'app/shared/elements/paginator';
import { TableComponent } from 'app/shared/elements/table';
import { TableHelper } from 'app/shared/helpers/table-helper';
var InsertionOrdersTableComponent = /** @class */ (function () {
    function InsertionOrdersTableComponent(insertionOrderRepository, availActions, router, notifications, auth, id) {
        var _this = this;
        this.insertionOrderRepository = insertionOrderRepository;
        this.availActions = availActions;
        this.router = router;
        this.notifications = notifications;
        this.auth = auth;
        this.id = id;
        this.helper = new TableHelper(function (params) { return _this.adapter(params); });
        this._ids = null;
        this._failedInsertionOrders = null;
        this.errorHelper = null;
        this.advertiser = null;
        this.deleted = new EventEmitter();
    }
    InsertionOrdersTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.helper.table = this.table;
        this.helper.paginator = this.paginator;
        combineLatest(this.table.query, this.paginator.query).subscribe(function (args) { return _this.helper.search(args); });
    };
    Object.defineProperty(InsertionOrdersTableComponent.prototype, "advertisers", {
        set: function (value) {
            this._ids = value;
            this.table.reset();
            this.paginator.reset();
        },
        enumerable: true,
        configurable: true
    });
    InsertionOrdersTableComponent.prototype.adapter = function (params) {
        if (Array.isArray(this._ids)) {
            params.conditions.push({
                field: 'advertiser',
                value: this._ids
            });
        }
        if (typeof this.advertiser === 'string') {
            params.conditions.push({
                field: 'advertiser',
                value: this.advertiser
            });
        }
        return this.insertionOrderRepository.asyncSearch(params);
    };
    InsertionOrdersTableComponent.prototype.availableActionsHandler = function (action, insertionOrder) {
        if (action === 'edit') {
            this.router.navigate(['/campaign-manager/insertion-orders', insertionOrder.id, 'edit',
                { redirect: btoa(this.router.url) }]);
        }
    };
    InsertionOrdersTableComponent.prototype.deleteIOs = function (insertionOrders) {
        var _this = this;
        this._failedInsertionOrders = [];
        this.errorHelper = new ErrorHelper();
        forkJoin(insertionOrders.map(function (insertionOrder) {
            return _this.insertionOrderRepository.delete(insertionOrder.id).pipe(catchError(function (err) {
                _this.errorHelper.loadBackEndErrors(err.error.errors);
                var errorList = _this.errorHelper.errors;
                _this._failedInsertionOrders.push({
                    key: insertionOrder.name,
                    value: errorList
                });
                return of(null);
            }));
        })).subscribe(function (res) {
            if (_this._failedInsertionOrders.length > 0) {
                _this.displayErrorMessage(_this._failedInsertionOrders);
            }
            else {
                _this.notifications.success("Selected insertion orders have been successfully deleted.");
            }
            _this.paginator.reset();
        });
    };
    InsertionOrdersTableComponent.prototype.displayErrorMessage = function (failedInsertionOrders) {
        var entityName = 'insertion order';
        if (failedInsertionOrders.length !== 1) {
            entityName = entityName.concat('s');
        }
        var error = '<strong>Uh Oh!</strong>  The following ' + entityName + ' could not be deleted:';
        failedInsertionOrders.forEach(function (io) { return error += '<li>' + io.key + '</li>'; });
        this.notifications.error(error, '', 0);
    };
    return InsertionOrdersTableComponent;
}());
export { InsertionOrdersTableComponent };
