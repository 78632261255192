import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Browser } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { mergeMap, shareReplay } from 'rxjs/operators';
var BrowserRepository = /** @class */ (function (_super) {
    tslib_1.__extends(BrowserRepository, _super);
    function BrowserRepository(http) {
        return _super.call(this, http, '/browser', Browser) || this;
    }
    BrowserRepository.prototype.get = function (id) {
        if (!this.cache) {
            this.cache = _super.prototype.search.call(this).pipe(shareReplay());
        }
        return this.cache.pipe(mergeMap(function (browsers) { return browsers.filter(function (browser) {
            return browser.id === id;
        }); }));
    };
    BrowserRepository.prototype.getFromCache = function (id) {
        return this.get(id);
    };
    return BrowserRepository;
}(BackendRepository));
export { BrowserRepository };
