import { UserRepository } from 'app/core/repositories';
import { forkJoin, of } from "rxjs";
import { Advertiser, Publisher, User } from "../models";
import { TableData } from './table-helper';

export const getManagersExecutivesForAdvertisers = (advertisers: Advertiser[], userRepo: UserRepository) => {
    const managerIds = advertisers.map(advertiser => advertiser.admin);
    const executiveIds = advertisers.map(advertiser => advertiser.executiveId);
    let userIds = managerIds.concat(executiveIds).filter(userId => userId);
    // Get rid of duplicates
    userIds = Array.from(new Set(userIds));
    if (userIds && userIds.length) {
        return forkJoin([of(advertisers), userRepo.search({
            conditions: [{ field: 'hash_id', value: userIds }]
        })]);
    }
    return forkJoin([of(advertisers), of(new Array<User>())]);
}

export const decorateAdvertisersWithNames = ([advertisers, users]: [Advertiser[], User[]]) => {
    const idToName = new Map<string, string[]>();
    users.forEach(user => idToName.set(user.hashId, [user.firstName, user.lastName]));
    advertisers.forEach(advertiser => {
        if (idToName.has(advertiser.admin)) {
            advertiser.managerFirstName = idToName.get(advertiser.admin)[0];
            advertiser.managerLastName = idToName.get(advertiser.admin)[1];
        }
        if (idToName.has(advertiser.executiveId)) {
            advertiser.executiveFirstName = idToName.get(advertiser.executiveId)[0];
            advertiser.executiveLastName = idToName.get(advertiser.executiveId)[1];
        }
    });
    return of(advertisers);
};

export const getManagersExecutivesForTableData = (data: TableData<Advertiser>, userRepo: UserRepository) => {
    const managerIds = data.items.map(advertiser => advertiser.admin);
    const executiveIds = data.items.map(advertiser => advertiser.executiveId);
    let userIds = managerIds.concat(executiveIds).filter(userId => userId);
    // Get rid of duplicates
    userIds = Array.from(new Set(userIds));
    if (userIds && userIds.length) {
        return forkJoin([of(data), userRepo.search({
            conditions: [{ field: 'hash_id', value: userIds }]
        })]);
    }
    return forkJoin([of(data), of(new Array<User>())]);
}

export const decorateTableDataWithNames = ([data, users]: [TableData<Advertiser>, User[]]) => {
    const idToName = new Map<string, string[]>();
    users.forEach(user => idToName.set(user.hashId, [user.firstName, user.lastName]));
    data.items.forEach(advertiser => {
        if (idToName.has(advertiser.admin)) {
            advertiser.managerFirstName = idToName.get(advertiser.admin)[0];
            advertiser.managerLastName = idToName.get(advertiser.admin)[1];
        }
        if (idToName.has(advertiser.executiveId)) {
            advertiser.executiveFirstName = idToName.get(advertiser.executiveId)[0];
            advertiser.executiveLastName = idToName.get(advertiser.executiveId)[1];
        }
    });
    return of(data);
};

export const isDomainOnAllPublishers = (domain: string, publishers: Publisher[]) : boolean => {
    return publishers.every((publisher) =>
        publisher.targetedDomains.includes(domain)
    );
}
