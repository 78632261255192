import { AfterViewInit, Component, ViewContainerRef, ElementRef, ChangeDetectorRef } from '@angular/core';
import { isEqual } from 'app/core/utils';
var BulkEditDirective = /** @class */ (function () {
    function BulkEditDirective(label, field, viewContainer, cdr, el) {
        this.viewContainer = viewContainer;
        this.cdr = cdr;
        this.el = el;
        this.label = label;
        this.field = field;
    }
    BulkEditDirective.prototype.ngAfterViewInit = function () {
        this.component = this.viewContainer['_data'].componentView.component;
        this.component['field'] = this.field;
    };
    BulkEditDirective.prototype.activate = function (entities, entityName) {
        if (entityName === void 0) { entityName = ''; }
        if (!this.originals) {
            this.originals = entities.map(function (entity) { return entity.clone(); });
        }
        this.component['entities'] = entities;
        this.component['entityName'] = entityName;
        this.el.nativeElement.hidden = false;
        this.cdr.detectChanges();
    };
    BulkEditDirective.prototype.resetOriginals = function (entities) {
        if (!this.originals) {
            this.originals = entities.map(function (entity) { return entity.clone(); });
        }
        this.component['entities'] = entities;
    };
    BulkEditDirective.prototype.deactivate = function () {
        this.el.nativeElement.hidden = true;
    };
    Object.defineProperty(BulkEditDirective.prototype, "isActive", {
        get: function () {
            return this.el.nativeElement.hidden === false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BulkEditDirective.prototype, "isDirty", {
        get: function () {
            var _this = this;
            if (!this.component || !Array.isArray(this.component['entities']) || !Array.isArray(this.originals)) {
                return false;
            }
            if (typeof this.component['isDirty'] === 'function') {
                return this.component['isDirty']();
            }
            return this.component['entities'].some(function (entity, index) {
                return !isEqual(entity[_this.field], _this.originals[index][_this.field]);
            });
        },
        enumerable: true,
        configurable: true
    });
    BulkEditDirective.prototype.isPreventingSave = function () {
        return this.component && typeof this.component['isPreventingSave'] === 'function'
            ? this.component['isPreventingSave']()
            : false;
    };
    BulkEditDirective.prototype.reset = function () {
        delete this.originals;
        if (typeof this.component['reset'] === 'function') {
            this.component['reset']();
        }
    };
    return BulkEditDirective;
}());
export { BulkEditDirective };
