import * as tslib_1 from "tslib";
import { Model } from './model';
var PublisherIdentifier = /** @class */ (function (_super) {
    tslib_1.__extends(PublisherIdentifier, _super);
    function PublisherIdentifier() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PublisherIdentifier.prototype.serialize = function () {
        return JSON.stringify(this);
    };
    Object.defineProperty(PublisherIdentifier.prototype, "entity", {
        get: function () {
            return 'Publisher Identifier';
        },
        enumerable: true,
        configurable: true
    });
    return PublisherIdentifier;
}(Model));
export { PublisherIdentifier };
var ConsentControl = /** @class */ (function () {
    function ConsentControl(publisherIdentifier) {
        this.id = parseInt(publisherIdentifier.id, 10);
        this.allow = publisherIdentifier.allow;
    }
    ConsentControl.prototype.serialize = function () {
        return JSON.parse(JSON.stringify(this));
    };
    Object.defineProperty(ConsentControl.prototype, "entity", {
        get: function () {
            return 'Consent Control';
        },
        enumerable: true,
        configurable: true
    });
    return ConsentControl;
}());
export { ConsentControl };
