export enum GAEventCategory {
    MODAL_INTERACTION = 'Modal Interaction',
    USER_ACCOUNT_INTERACTION = 'User Account Interaction',
    ACCOUNTS_INTERACTION = 'Accounts Interaction',
    ADS_MANAGEMENT = 'AD Management',
    ADVERTISER_MANAGEMENT = 'Advertiser Management',
    MEASUREMENT_MANAGEMENT = 'Measurement Management',
    CONVERSION_TRACKER_MANAGMENT = 'Conversion Tracker Management',
    AUDIENCES_MANAGEMENT = 'Audiences Management',
    AGENCY_MANAGEMENT = 'Agency Management',
    TABS_INTERACTION = 'Tabs Interaction',
    GLOBAL_SEARCH_INTERACTION = 'Global Search Interaction'
}
export class GAHelper { 
    public static trackEvent(eventCategory: GAEventCategory, eventAction: string, eventLabel: string) {
        if (window['ga'] && eventCategory && eventAction && eventLabel) {
            window['ga']('send', {
                hitType: 'event',
                eventCategory,
                eventAction,
                eventLabel
            });
        }
    }
}