import { Injectable } from '@angular/core';
import moment from 'moment';

import { DataSourceRegistry } from './datasource-registry';

const druidDataSources = {
    'advertiser': {
        'exact': {
            'type': 'union',
            'dataSources': [
                'custom_exact_dsp',
                'custom_aggregates_exact'
            ]
        },
        'inexact': {
            'type': 'union',
            'dataSources': [
                'custom_inexact_dsp_v4',
                'custom_aggregates_inexact_v2'
            ]
        }
    },
    'publisher': {
        'exact': 'custom_aggregates_exact',
        'inexact': 'custom_aggregates_inexact_v2'
    },
    'native': 'custom_aggregates_taboola',
    'auction': 'auction_agg'
};
const exchangeDuplicatesFilter = {
    'type': 'not',
    'field': {
        'type': 'selector',
        'dimension': 'campaign_id',
        'value': '1118'
    }
};

/**
* Query Builder Interface
* interval: time period for query. Format { type: absolute, start: startDate, end: endDate}
*                                      OR { type: dynamic, value: {{dynamicIntervalOption}} }
* granularity: frequency of split (see this.granularites for options)
* aggs: metrics for query (see this.aggregations for options)
* type: 'groupBy' or 'timeseries' - defaults to groupBy
* dimensions: splits for query (see this.dimensions for options)
* poastAggs: calculated fields for query (see this.postAggregations for options)
* filters: filters for query, format [ { dimension: {{dimensionName}}, value: {{value to filter}} }];
* lookups: flag for requiring lookups for dimensions
**/
export interface QueryBuilder {
    dataSource: string;
    granularity: string;
    interval: any;
    aggs: string[];
    type?: string;
    dimensions?: string[];
    postAggs?: string[];
    filters?: any[];
    lookups?: boolean;
}

@Injectable()
export class DruidQueryHelper {
    // Supported options for druid granularities
    private granularities: string[] = ['hour', 'day', 'week', 'month', 'year', 'all'];
    // Options for dynamic intervals
    // private dynamicIntervalOptions: string[] = ['WTD', 'MTD', 'QTD', 'YTD', '1', '7', '30', '90', 'LM'];

    /**
    * A Druid Query Builder
    * Params: queryBuilder: an instance of the QueryBuilder interface (defined above)
    **/
    public buildQuery(originalQueryBuilder: QueryBuilder): any {
        // Cloning is necessary to avoid pass by reference side effects
        const queryBuilder = JSON.parse(JSON.stringify(originalQueryBuilder));
        const query: any = {
            dataSource: queryBuilder.dataSource,
            timeZone: 'America/New_York'
        };
        query.queryType = queryBuilder.type ? queryBuilder.type : 'groupBy';
        query.intervals = [queryBuilder.interval];
        query.granularity =
            this.granularities.indexOf(queryBuilder.granularity) > -1 ? queryBuilder.granularity : 'day';

        if (Array.isArray(queryBuilder.postAggs)) {
            query.postAggregations = queryBuilder.postAggs.map((metric) => {
                if (DataSourceRegistry.postAggregations[metric]) {
                    if (DataSourceRegistry.postAggregations[metric].fieldNames) {
                        DataSourceRegistry.postAggregations[metric].fieldNames.forEach((field) => {
                            if (queryBuilder.aggs.indexOf(field) === -1
                                && field !== 'Demand Type'
                                && field !== 'Advertiser Spend'
                            ) {
                                queryBuilder.aggs.push(field);
                            }
                        });
                    }
                    return DataSourceRegistry.postAggregations[metric];
                }
            });
        }

        query.aggregations = queryBuilder.aggs.map((metric) => {
            if (DataSourceRegistry.aggregations[metric]) {
                return DataSourceRegistry.aggregations[metric];
            }
        });

        if (Array.isArray(queryBuilder.filters)) {
            query.filter = this.createFilters(queryBuilder.filters);
        }

        if (Array.isArray(queryBuilder.dimensions)) {
            query.dimensions = this.getDimensions(queryBuilder.dimensions, queryBuilder.lookups);
        }
        return query;
    }

    public createFilters(filters): any {
        const druidFilters = [];
        for (let filter of filters) {
            for (let value of filter.values) {
                const selectorFilter = DruidQueryHelper.createSelectorFilter(filter.dimension, value);
                druidFilters.push(selectorFilter);
            }
        }
        if (druidFilters.length === 1) {
            return druidFilters[0];
        } else {
            return DruidQueryHelper.createORFilter(druidFilters);
        }
    }

    public getDimensions(dimensions: Array<string>, lookups: boolean): any {
        if (lookups) {
            return dimensions.map((dimension) => {
                if (DataSourceRegistry.lookups[dimension]) {
                    return DataSourceRegistry.lookups[dimension];
                }
                else if (DataSourceRegistry.dimensions[dimension]) {
                    return DataSourceRegistry.dimensions[dimension];
                }
            });
        } else {
            return dimensions.map((dimension) => {
                if (DataSourceRegistry.dimensions[dimension]) {
                    return DataSourceRegistry.dimensions[dimension];
                }
            });
        }
    }

    /**
     * Helpers to create Druid Filters
     */
    public static createSelectorFilter(dimension: string, value: any): any {
        return {
            type: 'selector',
            dimension: DataSourceRegistry.dimensions[dimension].dimension,
            value: value
        };
    }

    public static createORFilter(filters): any {
        return {
            type: 'or',
            fields: filters
        };
    }

    public static createANDFilter(filters): any {
        if (filters.length === 0) {
            return null;
        }

        return {
            type: 'and',
            fields: filters
        };
    }

    /**
     * Helpers for datasource
     */

    public static getDSPExactDataSource(): any {
        return druidDataSources.advertiser.exact;
    }

    public static getSSPExactDataSource(): any {
        return druidDataSources.publisher.exact;
    }

    public static getDSPInexactDataSource(): any {
        return druidDataSources.advertiser.inexact;
    }

    public static getSSPInexactDataSource(): any {
        return druidDataSources.publisher.inexact;
    }

    public static getNativeDataSource(): string {
        return druidDataSources.native;
    }

    public static getAuctionDataSource(): string {
        return druidDataSources.auction;
    }

    public static getDSPFilterForExchangeDuplicates(): any {
        return exchangeDuplicatesFilter;
    }

    private formatDate(date): string {
        return moment(date).utc().format('YYYY-MM-DD');
    }

    public formatResults(
        results: any,
        keys: Array<string>,
        labels: Array<string>,
        appendDates: boolean = false): Array<Array<string | number>> {
        let formatted: Array<Array<string | number>> = [];
        // start each set of data with its label
        let dates = ['dates'];
        for (let label of labels) {
            formatted.push([label]);
        }
        for (let row of results) {
            // extracts data from query results
            dates.push(this.formatDate(row.timestamp));
            for (let key of keys) {
                let i = keys.indexOf(key);
                formatted[i].push(row.event[key]);
            }
        }
        if (appendDates) {
            formatted.push(dates);
        }
        return formatted;
    }
}
