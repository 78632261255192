import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { RTBPartner } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { map } from 'rxjs/operators';
var RTBPartnerRepository = /** @class */ (function (_super) {
    tslib_1.__extends(RTBPartnerRepository, _super);
    function RTBPartnerRepository(http) {
        return _super.call(this, http, '/dsp', RTBPartner) || this;
    }
    RTBPartnerRepository.prototype.searchByMediaGroup = function (id) {
        var _this = this;
        return this.http.get(this.url('/search/dsp/media-group', id))
            .pipe(map(function (response) { return response['output'].map(function (data) { return _this.build(data); }); }));
    };
    RTBPartnerRepository.prototype.searchByPublisher = function (id) {
        var _this = this;
        return this.http.get(this.url('/search/dsp/publisher', id))
            .pipe(map(function (response) { return response['output'].map(function (data) { return _this.build(data); }); }));
    };
    RTBPartnerRepository.prototype.searchByNewsletter = function (id) {
        var _this = this;
        return this.http.get(this.url('/search/dsp/newsletter', id))
            .pipe(map(function (response) { return response['output'].map(function (data) { return _this.build(data); }); }));
    };
    RTBPartnerRepository.prototype.searchByAdSlot = function (id) {
        var _this = this;
        return this.http.get(this.url('/search/dsp/ad-slot', id))
            .pipe(map(function (response) { return response['output'].map(function (data) { return _this.build(data); }); }));
    };
    return RTBPartnerRepository;
}(BackendRepository));
export { RTBPartnerRepository };
