// snake-to-camel-case.pipe.ts

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'camelToSnakeCase' })
export class CamelToSnakeCasePipe implements PipeTransform {
    transform(value: string): string {
        if (!value) return value;
        return value.replace(/[A-Z]/g, (letter, index) => {
            return (index !== 0 ? '_' : '') + letter.toLowerCase();
        });
    }
}
