import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { BehaviorSubject, ReplaySubject, Subject, combineLatest } from 'rxjs';
import { map, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { DropdownComponent } from 'app/shared/elements/dropdown';
import { dedupe } from 'app/core/array-utils';
import { IdService } from 'app/core/id.service';
var TagsInputTextComponent = /** @class */ (function () {
    function TagsInputTextComponent(id, cdr, splitOn) {
        this.id = id;
        this.cdr = cdr;
        this.placeholder = '';
        this.multiple = true;
        this.onblur = new EventEmitter();
        this.model = '';
        this.focused = false;
        this.onchange = null;
        this.selected$ = new BehaviorSubject([]);
        this.suggestions$ = new BehaviorSubject([]);
        this.searchableOptions$ = new ReplaySubject(1);
        this.input$ = new ReplaySubject(1);
        this.onDestroy$ = new Subject();
        var defaultSplitOn = ['\n', ','];
        this.splitOn = splitOn !== null ? splitOn.split(',') : defaultSplitOn;
    }
    Object.defineProperty(TagsInputTextComponent.prototype, "suggestions", {
        set: function (suggestions) {
            this.suggestions$.next(suggestions);
        },
        enumerable: true,
        configurable: true
    });
    TagsInputTextComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.suggestions$.pipe(startWith([])), this.selected$.pipe(startWith([])), this.input$.pipe(startWith(''))).pipe(tap(function () { return _this.cdr.markForCheck(); }), takeUntil(this.onDestroy$), map(function (_a) {
            var suggestions = _a[0], selected = _a[1], input = _a[2];
            if (!Array.isArray(suggestions)) {
                return;
            }
            var pattern = new RegExp(input, 'ig');
            return suggestions
                // exclude already selected items
                .filter(function (option) { return selected.findIndex(function (s) { return s === option.value; }) < 0; })
                // filter based on user input
                .filter(function (option) { return input.length < 1 || pattern.test(option.label); });
        })).subscribe(this.searchableOptions$);
    };
    TagsInputTextComponent.prototype.ngOnDestroy = function () {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    };
    TagsInputTextComponent.prototype.writeValue = function (value) {
        this.reset();
        if (Array.isArray(value)) {
            this.selected$.next(value);
        }
        this.cdr.detectChanges();
    };
    TagsInputTextComponent.prototype.registerOnChange = function (fn) {
        this.onchange = fn;
    };
    TagsInputTextComponent.prototype.registerOnTouched = function (_) { };
    TagsInputTextComponent.prototype.change = function (input) {
        if (!this.canSelect) {
            this.resetModel();
            return;
        }
        this.input$.next(input);
        var allJoinedAsString = this.selected$.getValue().join(this.splitOn[0]).concat(this.splitOn[0], input);
        // Split on any one of the `splitOn` values and turn the results
        // into selections. The last term is special, because that is
        // the one the user is still typing
        var splitters = this.splitOn.map(function (splitter) { return splitter + "+"; }).join('|');
        var terms = allJoinedAsString.split(new RegExp(splitters));
        var lastTerm = terms.splice(-1, 1)[0];
        this.selected$.next(dedupe(terms).filter(function (n) { return n.trim() !== ''; }));
        this.model = lastTerm;
        this.input$.next(lastTerm);
        this.propagate();
        this.onblur.emit();
    };
    TagsInputTextComponent.prototype.focus = function () {
        this.focused = true;
        this.hover = -1;
    };
    TagsInputTextComponent.prototype.blur = function (event, value) {
        this.focused = false;
        if (this.dropdown) {
            this.dropdown.hide();
        }
        event.target.value = null;
        this.push(value);
        this.onblur.emit();
    };
    TagsInputTextComponent.prototype.mouseenter = function (index) {
        this.hover = index;
    };
    TagsInputTextComponent.prototype.mousedown = function (event) {
        // Allow for `select` to be called.
        event.preventDefault();
    };
    TagsInputTextComponent.prototype.arrowup = function (event) {
        event.preventDefault();
        this.hover--;
        if (this.hover < 0 && Array.isArray(this.suggestions$.getValue())) {
            this.hover = this.suggestions$.getValue().length - 1;
        }
    };
    TagsInputTextComponent.prototype.trapInput = function (event) {
        if (!this.canSelect) {
            event.preventDefault();
            return;
        }
    };
    Object.defineProperty(TagsInputTextComponent.prototype, "canSelect", {
        get: function () {
            return this.multiple || this.selected$.getValue().length === 0;
        },
        enumerable: true,
        configurable: true
    });
    TagsInputTextComponent.prototype.arrowdown = function (event) {
        event.preventDefault();
        this.hover++;
        if (Array.isArray(this.suggestions$.getValue()) && this.hover === this.suggestions$.getValue().length) {
            this.hover = 0;
        }
    };
    TagsInputTextComponent.prototype.enter = function (event) {
        // allow user to press enter in case we are splitting on newlines
        event.preventDefault();
        if (typeof this.hover === 'number' && this.hover > -1) {
            this.select(this.hover);
        }
        this.model = this.model ? this.model + '\n' : '';
        this.change(this.model);
    };
    TagsInputTextComponent.prototype.select = function (index) {
        var _this = this;
        this.searchableOptions$.pipe(take(1), map(function (suggestions) { return suggestions.length > index ? suggestions[index].value : ''; })).subscribe(function (option) { return _this.push(option); });
        this.hover = -1;
    };
    TagsInputTextComponent.prototype.deselect = function (index) {
        this.selected$.getValue().splice(index, 1);
        this.selected$.next(this.selected$.getValue());
        this.resetModel();
        this.cdr.detectChanges();
        this.propagate();
        this.onblur.emit();
    };
    TagsInputTextComponent.prototype.propagate = function () {
        if (this.onchange !== null) {
            this.onchange(this.selected$.getValue());
        }
    };
    TagsInputTextComponent.prototype.push = function (value) {
        if (value !== '' && this.canSelect) {
            this.change(value + this.splitOn[0]);
        }
    };
    TagsInputTextComponent.prototype.reset = function () {
        this.selected$.next([]);
        this.resetModel();
    };
    TagsInputTextComponent.prototype.resetModel = function () {
        this.model = null;
    };
    return TagsInputTextComponent;
}());
export { TagsInputTextComponent };
