export enum OperationMethod {
    Post = 'POST',
    Get = 'GET',
    Put = 'PUT'
}

export class Operation {

    constructor(
        private method: OperationMethod,
        private uri: string,
        private payload: any,
        private operation_map_id: string = undefined,
        private name: string = undefined,
        private description: string = undefined,
    ) {}

    public serialize(): string {
        return JSON.stringify(this);
    }
}
