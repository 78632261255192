import { OnInit, ChangeDetectorRef } from '@angular/core';
import { DatePickerComponent } from 'app/shared/elements/date-picker';
import { DruidQueryHelper } from 'app/core/query-builder-helper';
import { ErrorHelper } from 'app/core/errors/error-helper';
import { ReportingQueryRepository } from 'app/core/repositories';
import moment from 'moment';
import { clone } from 'app/core/utils';
export var IntervalRange;
(function (IntervalRange) {
    IntervalRange[IntervalRange["last7Days"] = 1] = "last7Days";
    IntervalRange[IntervalRange["last14Days"] = 2] = "last14Days";
    IntervalRange[IntervalRange["last30Days"] = 3] = "last30Days";
    IntervalRange[IntervalRange["flightDates"] = 4] = "flightDates";
})(IntervalRange || (IntervalRange = {}));
var INITIAL_DATA = [
    ['Impressions', 0],
    ['Clicks', 0],
    ['PCC', 0],
    ['Advertiser Spend', 0.0],
    ['CTR', 0.0],
    ['eCPA', 0.0],
    ['eCPC', 0.0],
    ['Impressions (a)', 0],
    ['CTR (a)', 0.0]
];
var MetricsComparisonCardComponent = /** @class */ (function () {
    function MetricsComparisonCardComponent(reportingQueryRepository, druidQueryHelper, cdr) {
        this.reportingQueryRepository = reportingQueryRepository;
        this.druidQueryHelper = druidQueryHelper;
        this.cdr = cdr;
        this.isDirectSold = false;
        this.noData = false;
        this.IntervalRange = IntervalRange;
        // Most recent date that a user can select from the picker
        this.yesterday = moment().subtract(1, 'days').startOf('day').toDate();
        this.firstDate = new Date(2010, 0, 1);
        this.showFilters = false;
        this.leftIndex = 7;
        this.rightIndex = 8;
        this.leftColorPattern = ['#14abf9'];
        this.rightColorPattern = ['#9013fe'];
        this.dates = ['dates'];
        this.filteredDates = ['dates'];
        this.formats = ['Number', 'Number', 'Number', 'Currency', 'Percent', 'Currency', 'Currency', 'Number', 'Percent'];
        this.operations = ['sum', 'sum', 'sum', 'sum', 'mean', 'mean', 'mean', 'sum', 'mean'];
        this.data = clone(INITIAL_DATA);
        this.filteredData = clone(INITIAL_DATA);
        this.errorHelper = new ErrorHelper();
    }
    MetricsComparisonCardComponent.prototype.ngOnInit = function () {
        // 1. Init Intervals
        this.initInterval();
        // 2. Initialize Data
        this.initData(this.dates, this.data, moment(this._intervalStart).format('YYYY-MM-DD'), moment(this._intervalEnd).format('YYYY-MM-DD'));
        this.initData(this.filteredDates, this.filteredData, moment(this._intervalStart).format('YYYY-MM-DD'), moment(this._intervalEnd).format('YYYY-MM-DD'));
        // 3. Download aggregates
        this.downloadData();
    };
    Object.defineProperty(MetricsComparisonCardComponent.prototype, "intervalStart", {
        get: function () {
            return this._intervalStart;
        },
        set: function (value) {
            this._intervalStart = value;
            this.filterData();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MetricsComparisonCardComponent.prototype, "intervalEnd", {
        get: function () {
            return this._intervalEnd;
        },
        set: function (value) {
            this._intervalEnd = value;
            this.filterData();
        },
        enumerable: true,
        configurable: true
    });
    MetricsComparisonCardComponent.prototype.initInterval = function () {
        // local cloned copies.
        var flightStartDate = moment(this.flightStartDate).toDate();
        var flightEndDate = moment(this.flightEndDate).toDate();
        var yesterday = moment(this.yesterday).toDate();
        var minDate = this.firstDate;
        if (this.isDateBefore(flightStartDate, minDate)) {
            flightStartDate = minDate;
        }
        if (this.isDateBefore(flightEndDate, minDate)) {
            flightEndDate = minDate;
        }
        /**
         * If campaign/line-item has not yet begun, then display flight dates
         * If campaign/line-item is in progress but not yet completed then show flight start date, last date
         * If campaign/line-item is completed then display flight dates
         */
        this._intervalStart = flightStartDate;
        if (this.isDateBefore(yesterday, flightStartDate)) {
            this._intervalEnd = flightEndDate;
        }
        else if (this.isDateBefore(flightEndDate, yesterday)) {
            this._intervalEnd = flightEndDate;
        }
        else {
            this._intervalEnd = yesterday;
        }
    };
    MetricsComparisonCardComponent.prototype.initData = function (dates, data, startDateString, endDateString) {
        var startDate = moment(startDateString, 'YYYY-MM-DD');
        var endDate = moment(endDateString, 'YYYY-MM-DD');
        var diff = endDate.diff(startDate, 'days');
        // Local cloned copy
        var counter = moment(startDate);
        dates[1] = counter.format('YYYY-MM-DD');
        for (var i = 1; i <= diff; i += 1) {
            counter.add(1, 'days');
            dates.push(counter.format('YYYY-MM-DD'));
            // Impressions
            data[0].push(0);
            // Clicks
            data[1].push(0);
            // Conversions
            data[2].push(0);
            // Advertiser Spend
            data[3].push(0.0);
            // CTR
            data[4].push(0.0);
            // eCPA
            data[5].push(0.0);
            // eCPC
            data[6].push(0.0);
            // Impressions (a)
            data[7].push(0);
            // CTR (a)
            data[8].push(0.0);
        }
    };
    MetricsComparisonCardComponent.prototype.filterData = function () {
        var _this = this;
        var filteredDates = ['dates', '2017-01-01'];
        var filteredData = clone(INITIAL_DATA);
        var startDate = moment(this._intervalStart);
        if (startDate.isBefore(this.flightStartDate, 'day')) {
            startDate = moment(this.flightStartDate);
        }
        var endDate = moment(this._intervalEnd);
        if (endDate.isAfter(this.flightEndDate, 'day')) {
            endDate = moment(this.flightEndDate);
        }
        this.initData(filteredDates, filteredData, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
        filteredDates.map(function (filteredDate, index) {
            var dataIndex = _this.dates.indexOf(filteredDate);
            if (dataIndex > -1) {
                for (var i = 0; i < filteredData.length; i += 1) {
                    filteredData[i][index] = _this.data[i][dataIndex];
                }
            }
        });
        this.filteredData = filteredData;
        this.filteredDates = filteredDates;
        this.cdr.markForCheck();
    };
    MetricsComparisonCardComponent.prototype.buildDSPAggregateQueryPayload = function () {
        var queryBuilder = {};
        queryBuilder.dataSource = DruidQueryHelper.getDSPExactDataSource();
        queryBuilder.type = 'timeseries';
        queryBuilder.granularity = 'day';
        queryBuilder.interval = {
            type: 'absolute',
            start: moment(this._intervalStart).format('YYYY-MM-DD'),
            end: moment(this._intervalEnd).format('YYYY-MM-DD'),
        };
        queryBuilder.aggs = ['Impressions', 'Clicks', 'PCC', 'Advertiser Spent', 'Impressions (a)'];
        queryBuilder.postAggs = ['CTR', 'GrossECPC', 'eCPA', 'CTR (a)'];
        if (this.dimension === 'Campaign') {
            queryBuilder.filters = [
                {
                    dimension: 'Campaign ID',
                    values: [JSON.stringify(this.dimensionId)]
                }
            ];
        }
        else {
            queryBuilder.filters = [
                {
                    dimension: 'Line Item ID',
                    values: [JSON.stringify(this.dimensionId)]
                }
            ];
        }
        // build Druid Query
        var druidQuery = this.druidQueryHelper.buildQuery(queryBuilder);
        this.updateIntervalDateRange(druidQuery);
        this.addFilterForDSPDuplicates(druidQuery);
        return {
            query: druidQuery,
            type: 'advertiser'
        };
    };
    MetricsComparisonCardComponent.prototype.updateIntervalDateRange = function (druidQuery) {
        var interval = JSON.parse(JSON.stringify(druidQuery.intervals[0]));
        // Druid appends UTC offset 0 timestamp to the event dates
        druidQuery.intervals[0].start = moment.utc(interval.start).startOf('day').toISOString();
        // Adding one day to end to make the user entered end date inclusive in the report.
        druidQuery.intervals[0].end = moment.utc(interval.end).add(1, 'days').endOf('day').toISOString();
    };
    MetricsComparisonCardComponent.prototype.addFilterForDSPDuplicates = function (druidQuery) {
        /**
         * For DSP reporting, datasource is union of DSP & SSP aggregates.
         * To prevent duplication of DSP data, we need a filter campaign_id != 1118
         * for data in SSP aggregates. Because post bv4 migration the campaign_id for
         * all DSP impressions data in SSP will be marked as 1118 representing LiveIntent DSP.
         */
        var filters = [];
        var duplicateDSPFilter = DruidQueryHelper.getDSPFilterForExchangeDuplicates();
        // Deep cloning druidQuery.filter object using JSON parse & JSON stringify.
        var druidQueryFilter = JSON.parse(JSON.stringify(druidQuery.filter));
        filters.push(duplicateDSPFilter);
        filters.push(druidQueryFilter);
        druidQuery.filter = DruidQueryHelper.createANDFilter(filters);
    };
    MetricsComparisonCardComponent.prototype.downloadData = function () {
        var _this = this;
        var _a = this.buildDSPAggregateQueryPayload(), query = _a.query, type = _a.type;
        this.reportingQueryRepository.executeAdHocJSON(query, type).toPromise()
            .then(function (aggregates) {
            // Load Aggregates Data in data[]
            if (Array.isArray(aggregates)) {
                _this.noData = aggregates.every(function (aggr) {
                    return aggr.result['Advertiser Spend'] === 0 &&
                        aggr.result['CTR'] === 0 &&
                        aggr.result['Clicks'] === 0 &&
                        aggr.result['PCC'] === 0 &&
                        aggr.result['GrossECPA'] === 0 &&
                        aggr.result['eCPA'] === 0 &&
                        aggr.result['Impressions'] === 0 &&
                        aggr.result['Impressions (a)'] === 0 &&
                        aggr.result['CTR (a)'] === 0;
                });
                aggregates.forEach(function (druidData) {
                    var date = moment.utc(druidData.timestamp).format('YYYY-MM-DD');
                    var dataIndex = _this.dates.indexOf(date);
                    if (dataIndex > -1) {
                        _this.data[0][dataIndex] = druidData.result.Impressions;
                        _this.data[1][dataIndex] = druidData.result.Clicks;
                        _this.data[2][dataIndex] = druidData.result.PCC;
                        _this.data[3][dataIndex] = druidData.result['Advertiser Spend'];
                        _this.data[4][dataIndex] = druidData.result.CTR;
                        _this.data[5][dataIndex] = druidData.result.eCPA;
                        _this.data[6][dataIndex] = druidData.result.GrossECPC;
                        _this.data[7][dataIndex] = druidData.result['Impressions (a)'];
                        _this.data[8][dataIndex] = druidData.result['CTR (a)'];
                    }
                });
            }
            _this.filterData();
            _this.cdr.markForCheck();
        }, function (aggregatesError) {
            _this.errorHelper.resetAllErrors();
            _this.errorHelper.loadReportingError(aggregatesError.data, aggregatesError.status);
        });
    };
    MetricsComparisonCardComponent.prototype.updateInterval = function (range) {
        // local cloned copies.
        var flightStartDate = moment(this.flightStartDate).toDate();
        var flightEndDate = moment(this.flightEndDate).toDate();
        var yesterday = moment(this.yesterday).toDate();
        this.currentInterval = range;
        this._intervalEnd = yesterday;
        switch (range) {
            case IntervalRange.last7Days: {
                this._intervalStart = moment(yesterday).subtract(7, 'days').startOf('day').toDate();
                this.filterData();
                break;
            }
            case IntervalRange.last14Days: {
                this._intervalStart = moment(yesterday).subtract(14, 'days').startOf('day').toDate();
                this.filterData();
                break;
            }
            case IntervalRange.last30Days: {
                this._intervalStart = moment(yesterday).subtract(30, 'days').startOf('day').toDate();
                this.filterData();
                break;
            }
            case IntervalRange.flightDates: {
                /**
                 * If campaign/line-item has not yet begun, then display flight dates
                 * If campaign/line-item is in progress but not yet completed then show flight start date, last date
                 * If campaign/line-item is completed then display flight dates
                 */
                this._intervalStart = flightStartDate;
                if (this.isDateBefore(yesterday, flightStartDate)) {
                    this._intervalEnd = flightEndDate;
                }
                else if (this.isDateBefore(flightEndDate, yesterday)) {
                    this._intervalEnd = flightEndDate;
                }
                this.filterData();
                break;
            }
        }
    };
    MetricsComparisonCardComponent.prototype.isDateBefore = function (firstDate, secondDate) {
        return moment(firstDate).isBefore(secondDate);
    };
    Object.defineProperty(MetricsComparisonCardComponent.prototype, "leftSelectedData", {
        get: function () {
            return this.filteredData[this.leftIndex];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MetricsComparisonCardComponent.prototype, "rightSelectedData", {
        get: function () {
            return this.filteredData[this.rightIndex];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MetricsComparisonCardComponent.prototype, "leftSelectedName", {
        get: function () {
            return this.filteredData[this.leftIndex][0];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MetricsComparisonCardComponent.prototype, "rightSelectedName", {
        get: function () {
            return this.filteredData[this.rightIndex][0];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MetricsComparisonCardComponent.prototype, "leftSelectedFormat", {
        get: function () {
            return this.formats[this.leftIndex];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MetricsComparisonCardComponent.prototype, "rightSelectedFormat", {
        get: function () {
            return this.formats[this.rightIndex];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MetricsComparisonCardComponent.prototype, "leftSelectedOperation", {
        get: function () {
            return this.operations[this.leftIndex];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MetricsComparisonCardComponent.prototype, "rightSelectedOperation", {
        get: function () {
            return this.operations[this.rightIndex];
        },
        enumerable: true,
        configurable: true
    });
    MetricsComparisonCardComponent.prototype.leftDropDownIndex = function (value) {
        this.leftIndex = value;
    };
    MetricsComparisonCardComponent.prototype.rightDropDownIndex = function (value) {
        this.rightIndex = value;
    };
    MetricsComparisonCardComponent.prototype.setStartDate = function (date) {
        this.intervalStart = date;
        this.endDatePicker.calendar.setMonth(moment(date));
        this.endDatePicker.inputElement.nativeElement.focus();
        this.currentInterval = null;
    };
    Object.defineProperty(MetricsComparisonCardComponent.prototype, "ios15KnowledgeBaseLink", {
        get: function () {
            return this.isDirectSold
                ? 'https://support.liveintent.com/hc/en-us/articles/4407063919252'
                : 'https://support.liveintent.com/hc/en-us/articles/4406913619476';
        },
        enumerable: true,
        configurable: true
    });
    return MetricsComparisonCardComponent;
}());
export { MetricsComparisonCardComponent };
