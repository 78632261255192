import {
    ChangeDetectorRef,
    Component,
    ContentChild,
    EventEmitter,
    forwardRef,
    Output,
    TemplateRef,
    ChangeDetectionStrategy,
    Input
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IdService } from 'app/core/id.service';

@Component({
    selector: 'list',
    templateUrl: './list.html',
    styleUrls: ['./list.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ListComponent),
            multi: true
        }
    ]
})
export class ListComponent implements ControlValueAccessor {
    items = [];
    readonly LIST_LIMIT = 1000;
    @Input() disabled: boolean = false;
    @ContentChild('template', { static: true }) itemtpl: TemplateRef<any> = null;
    @Output() onRemove: EventEmitter<any> = new EventEmitter();

    private onchange = (_: any[]) => { };

    constructor(
        private cdr: ChangeDetectorRef,
        public id: IdService
    ) { }

    writeValue(value: any[]) {
        if (Array.isArray(value)) {
            this.items = value;
            this.cdr.markForCheck();
        }
    }

    registerOnChange(fn: (_: any[]) => {}) {
        this.onchange = fn;
    }

    registerOnTouched() { }

    remove(i: number) {
        if (!this.disabled) {
            const items = this.items.slice();
            const removedItem = items.splice(i, 1);
            this.items = items;
            this.onRemove.emit(removedItem);
            this.onchange(this.items);
        }
    }

    reset() {
        if (!this.disabled) {
            const removedItems = this.items.splice(0, this.items.length);
            this.onRemove.emit(removedItems);
        }
    }

    get shownItems() {
        return this.items.slice(0, this.LIST_LIMIT);
    }
}
