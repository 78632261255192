import { Component, Output, EventEmitter, ViewChild } from '@angular/core';

import { ConfirmDialogComponent } from 'app/shared/elements/confirm-dialog';
import { AdSlotRepository, LineItemRepository } from 'app/core/repositories';
import { IdService } from 'app/core';
import { mergeMap } from 'rxjs/operators';
import { of, Observable, ReplaySubject } from 'rxjs';
import { LineItem, Newsletter} from 'app/shared/models';

@Component({
    selector: 'app-ad-slot-coordinated-line-items-dialog',
    templateUrl: './ad-slot-coordinated-line-items-dialog.html'
})
export class AdSlotCoordinatedLineItemsDialogComponent {
    public constructor(
        public adSlotRepository: AdSlotRepository,
        public lineItemRepository: LineItemRepository,
        public id: IdService
    ) { }

    @Output() public handleCoordinatedSponsorship: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild(ConfirmDialogComponent, { static: true }) dialog: ConfirmDialogComponent;

    public newsletter: Newsletter = null;
    lineItems$ = new ReplaySubject<LineItem[]>(1);

    public showCoordination(newsletter): Observable<boolean> {
        if (newsletter.allowCoordination || !newsletter.id) {
            return of(false);
        }

        this.newsletter = newsletter;

        return this.adSlotRepository
            .search({
                conditions: [
                    { field: 'newsletter', value: this.newsletter.id }
                ]
            })
            .pipe(
                mergeMap(adSlots => this.lineItemRepository.searchByAdSlots(adSlots.map(slot => slot.id))),
                mergeMap(lineItems => of(lineItems)),
                mergeMap(lineItems => {
                    const ids = lineItems.map(lineItem => lineItem.refId);
                    const filterDuplicates = () => lineItems.filter((lineItem, index) => !ids.includes(lineItem.refId, index + 1));
                    const filteredLineItems = filterDuplicates();
                    const coordinatedLineItems = filteredLineItems.filter(lineItem => lineItem.isCoordinated);
                    this.lineItems$.next(coordinatedLineItems);

                    if (coordinatedLineItems.length > 0) {
                        return of(true);
                    }

                    return of(false);
                })
            );
    }

    public open(): void {
        this.dialog.open();
    }

    public handleConfirm() {
        this.handleCoordinatedSponsorship.emit(true);
    }

    public handleCancel() {
        this.handleCoordinatedSponsorship.emit(false);
    }
}
