import { ChangeDetectorRef, ElementRef, OnInit, TemplateRef } from '@angular/core';
import { generate } from 'c3';
import { timeFormat } from 'd3';
var BarChartComponent = /** @class */ (function () {
    function BarChartComponent(changeDetection) {
        this.changeDetection = changeDetection;
        /**
         * Whether or not to include the chart legend.
         */
        this.showLegend = true;
        /**
         * Current definition of date formatter.
         */
        this.formatter = timeFormat('%Y-%m-%d');
    }
    Object.defineProperty(BarChartComponent.prototype, "timeFormatter", {
        /**
         * A custom date format, because the `load` function does not support it.
         */
        set: function (format) {
            this.formatter = timeFormat(format);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Generate Chart
     */
    BarChartComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.chart = generate({
            bindto: this.container.nativeElement,
            data: {
                x: 'Date',
                type: 'bar',
                xFormat: '%Y-%m-%dT%H:%M:%S.%LZ',
                xLocaltime: false,
                columns: this.columns,
                groups: this.groups
            },
            color: {
                pattern: ['#13DDC9', '#ffa710', '#235fac', '#14abf9', '#fc7709', '#ba358a', '#bf7eaa', '#c03247', '#ef3849']
            },
            legend: {
                show: this.showLegend
            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: {
                        format: this.formatter ? function (x) {
                            return _this.formatter(x);
                        } : undefined
                    }
                },
                y: {
                    label: this.yLabel ? {
                        text: this.yLabel,
                        position: 'outer-middle'
                    } : undefined,
                    tick: {
                        format: function (x) {
                            // only show integers, but don't wrong cuz that's not accurate
                            return (x % 1 === 0 ? x : '');
                        }
                    }
                },
            },
            grid: {
                y: {
                    show: true
                }
            },
            tooltip: {
                format: {
                    title: (this.formatter ? function (x) {
                        return _this.formatter(x);
                    } : undefined)
                }
            }
        });
    };
    /**
     * Redraw the chart with new data.
     */
    BarChartComponent.prototype.redraw = function (columns) {
        this.changeDetection.markForCheck();
        if (this.chart) {
            this.chart.flush();
            this.chart.load({ columns: columns });
        }
    };
    Object.defineProperty(BarChartComponent.prototype, "hasData", {
        /**
         * Check if the chart has non-zero data.
         */
        get: function () {
            // if the chart has not yet rendered, or we have not even
            // supplied columns yet, we should show an empty chart
            if (!this.chart || this.chart.data().length === 0) {
                return false;
            }
            // once we have columns, if they are all empty, we'll show
            // a zero state instead of the graph
            return this.chart.data().some(function (column) {
                return column.values.some(function (value) { return value.value > 0; });
            });
        },
        enumerable: true,
        configurable: true
    });
    return BarChartComponent;
}());
export { BarChartComponent };
