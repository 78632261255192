import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Agency } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { publishLast, refCount, map } from 'rxjs/operators';
var AgencyRepository = /** @class */ (function (_super) {
    tslib_1.__extends(AgencyRepository, _super);
    function AgencyRepository(http) {
        var _this = _super.call(this, http, '/agency', Agency) || this;
        _this._allResult = null;
        return _this;
    }
    AgencyRepository.prototype.activate = function (agency) {
        var _this = this;
        return this.http.post(this.url(this.path, 'activate', agency.id), agency)
            .pipe(map(function (response) { return _this.build(response['output']); }));
    };
    AgencyRepository.prototype.deactivate = function (agency) {
        var _this = this;
        return this.http.post(this.url(this.path, 'deactivate', agency.id), agency)
            .pipe(map(function (response) { return _this.build(response['output']); }));
    };
    AgencyRepository.prototype.all = function (query) {
        if (query === void 0) { query = {}; }
        if (this._allResult) {
            return this._allResult;
        }
        this._allResult = _super.prototype.all.call(this, query)
            .pipe(publishLast(), refCount());
        return this._allResult;
    };
    AgencyRepository.prototype.searchAsOptions = function (query) {
        if (query === void 0) { query = {}; }
        return this.all(query).pipe(map(function (agencies) {
            return agencies.map(function (option) {
                return {
                    key: option.refId,
                    value: option.refId,
                    label: "<span class='supporting-color'>(" + option.refId + ") </span><span class='bold'>" + option.name + "</span>",
                    taglabel: "(" + option.refId + ") " + option.name,
                    data: option
                };
            });
        }));
    };
    AgencyRepository.prototype.convertAgencyToOptions = function (agencies) {
        return agencies.pipe(map(function (agency) {
            return agency.map(function (option) {
                return {
                    key: option.refId,
                    value: option.refId,
                    label: option.name,
                    data: option
                };
            });
        }));
    };
    return AgencyRepository;
}(BackendRepository));
export { AgencyRepository };
