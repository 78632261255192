import * as tslib_1 from "tslib";
import { Model } from './model';
var Buyer = /** @class */ (function (_super) {
    tslib_1.__extends(Buyer, _super);
    function Buyer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Buyer.prototype.serialize = function () {
        return JSON.stringify(this);
    };
    Object.defineProperty(Buyer.prototype, "entity", {
        get: function () {
            return 'Buyer';
        },
        enumerable: true,
        configurable: true
    });
    return Buyer;
}(Model));
export { Buyer };
