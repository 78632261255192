import { BehaviorSubject } from 'rxjs';
import { LightboxnextComponent } from 'app/shared/elements/lightboxnext';
import { HistoryChange } from 'app/shared/models';
import { DownloadHelper } from 'app/shared/helpers/download-helper';
import { LIESRepository, HistoryEntity } from 'app/core/repositories';
import { Formatter } from './formatter';
import { map } from 'rxjs/operators';
import { IdService } from 'app/core/id.service';
import FORM_OPTIONS from '../../../platform/campaign-manager/line-items/options.json';
var groupsPattern = / (?:AND|OR) (?![a-z0-9])/g;
var audiencePattern = /[a-z0-9.]+/g;
var HistoryViewComponent = /** @class */ (function () {
    function HistoryViewComponent(liesRepository, formatter, id) {
        this.liesRepository = liesRepository;
        this.formatter = formatter;
        this.id = id;
        this.isLoading = false;
        this._history$ = new BehaviorSubject([]);
        this.history$ = this._history$.pipe(map(function (items) { return items.filter(function (item) { return item.hasChanges; }); }));
        this.ageSegmentPattern = null;
        this.genderSegmentPattern = null;
        var ageSegmentOptions = FORM_OPTIONS.age.map(function (ele) { return ele.value; });
        var genderSegmentOptions = FORM_OPTIONS.gender.map(function (ele) { return ele.value; });
        this.ageSegmentPattern = new RegExp(ageSegmentOptions.join('|'), 'g');
        this.genderSegmentPattern = new RegExp(genderSegmentOptions.join('|'), 'g');
    }
    HistoryViewComponent.prototype.view = function (historyResponse, model) {
        var _a;
        if (this._history$.value.length) {
            this._history$.next([]);
        }
        this.model = model;
        this._history$.next((_a = this._history$.getValue()).concat.apply(_a, historyResponse.items));
        this.nextPage$ = historyResponse.next;
        this.isLoading = false;
        this.lightbox.show();
    };
    HistoryViewComponent.prototype.next = function () {
        var _this = this;
        this.isLoading = true;
        this.nextPage$.subscribe(function (response) { return _this.view(response, _this.model); });
    };
    /**
     * Here we need to go through each history item in the history response.
     * If any history item has an `audienceTargeting` change (either in the new value or old value),
     * remove age group and gender group from that change.
     * If the new age group is different from the old age group, add a new change to the history
     * item to represent it. Same for gender.
     * Why we do this? Age and gender are considered first party segments in our lfx db
     * but they are not first party to the clients. We need to make sure to not show them
     * in the "Audience Targeting" section but show them in the "Demographics" section.
     */
    HistoryViewComponent.prototype.viewLineItemHistory = function (lineItem) {
        var _this = this;
        this.liesRepository.history(lineItem.id, HistoryEntity.LineItem).subscribe(function (historyResponse) {
            if (historyResponse && historyResponse.items && Array.isArray(historyResponse.items)) {
                historyResponse.items.forEach(function (historyItem) {
                    if (historyItem && historyItem.changes && Array.isArray(historyItem.changes)) {
                        var newAgeSegmentGroup_1;
                        var newGenderSegmentGroup_1;
                        var oldAgeSegmentGroup_1;
                        var oldGenderSegmentGroup_1;
                        var changes_1 = [];
                        historyItem.changes.forEach(function (change) {
                            if (change.field === 'audienceTargeting') {
                                if (change.value && typeof change.value === 'string') {
                                    var liveSegmentGroups_1 = [];
                                    change.value.split(groupsPattern).forEach(function (group) {
                                        if (_this.ageSegmentPattern.test(group)) {
                                            newAgeSegmentGroup_1 = group;
                                        }
                                        else if (_this.genderSegmentPattern.test(group)) {
                                            newGenderSegmentGroup_1 = group;
                                        }
                                        else {
                                            liveSegmentGroups_1.push(group);
                                        }
                                    });
                                    // `change.value` should only contain live audience targeting (no age or gender)
                                    // it should not contain any prefix either
                                    change.value = liveSegmentGroups_1.join(' AND ').replace(/[a-z]/g, '').replace(/\./g, '');
                                }
                                if (change.old && typeof change.old === 'string') {
                                    var liveSegmentGroups_2 = [];
                                    change.old.split(groupsPattern).forEach(function (group) {
                                        if (_this.ageSegmentPattern.test(group)) {
                                            oldAgeSegmentGroup_1 = group;
                                        }
                                        else if (_this.genderSegmentPattern.test(group)) {
                                            oldGenderSegmentGroup_1 = group;
                                        }
                                        else {
                                            liveSegmentGroups_2.push(group);
                                        }
                                    });
                                    // `change.old` should only contain live audience targeting (no age or gender)
                                    // it should not contain any prefix either
                                    change.old = liveSegmentGroups_2.join(' AND ').replace(/[a-z]/g, '').replace(/\./g, '');
                                }
                                if (change.value !== change.old) {
                                    changes_1.push(change);
                                }
                            }
                            else {
                                // For any change that is not `audienceTargeting`, just take it
                                changes_1.push(change);
                            }
                        });
                        if (newAgeSegmentGroup_1 !== oldAgeSegmentGroup_1 || newGenderSegmentGroup_1 !== oldGenderSegmentGroup_1) {
                            var newAgeValues = _this.mapSegmentGroupToHistoryValue(newAgeSegmentGroup_1);
                            var newGenderValues = _this.mapSegmentGroupToHistoryValue(newGenderSegmentGroup_1);
                            var oldAgeValues = _this.mapSegmentGroupToHistoryValue(oldAgeSegmentGroup_1);
                            var oldGenderValues = _this.mapSegmentGroupToHistoryValue(oldGenderSegmentGroup_1);
                            changes_1.push(new HistoryChange({
                                field: 'dataProviderSegments',
                                value: newAgeValues.concat(newGenderValues),
                                old: oldAgeValues.concat(oldGenderValues)
                            }));
                        }
                        historyItem.changes = changes_1;
                    }
                });
            }
            _this.view(historyResponse, lineItem);
        });
    };
    HistoryViewComponent.prototype.mapSegmentGroupToHistoryValue = function (segmentGroup) {
        if (!segmentGroup) {
            return [];
        }
        return segmentGroup.match(audiencePattern).map(function (segment) {
            var type = segmentGroup.includes('NOT') ? 'exclude' : 'include';
            if (segment && segment.includes('.')) {
                return { id: parseInt(segment.split('.')[1]), type: type };
            }
            return { id: parseInt(segment), type: type };
        });
    };
    HistoryViewComponent.prototype.viewCreativeHistory = function (creative) {
        var _this = this;
        this.liesRepository.history(creative.id, HistoryEntity.Creative).subscribe(function (historyResponse) {
            _this.view(historyResponse, creative);
        });
    };
    HistoryViewComponent.prototype.viewCampaignHistory = function (campaign) {
        var _this = this;
        this.liesRepository.history(campaign.id, HistoryEntity.Campaign).subscribe(function (historyResponse) {
            _this.view(historyResponse, campaign);
        });
    };
    HistoryViewComponent.prototype.viewUserHistory = function (user) {
        var _this = this;
        this.liesRepository.history(user.hashId, HistoryEntity.User).subscribe(function (historyResponse) {
            _this.view(historyResponse, user);
        });
    };
    HistoryViewComponent.prototype.viewRTBPartnerHistory = function (partner) {
        var _this = this;
        this.liesRepository.history(partner.id, HistoryEntity.RTBPartner).subscribe(function (historyResponse) {
            _this.view(historyResponse, partner);
        });
    };
    HistoryViewComponent.prototype.viewPublisherHistory = function (publisher) {
        var _this = this;
        this.liesRepository.history(publisher.id, HistoryEntity.Publisher).subscribe(function (historyResponse) {
            _this.view(historyResponse, publisher);
        });
    };
    HistoryViewComponent.prototype.viewAdvertiserHistory = function (advertiser) {
        var _this = this;
        this.liesRepository.history(advertiser.id, HistoryEntity.Advertiser).subscribe(function (historyResponse) {
            _this.view(historyResponse, advertiser);
        });
    };
    HistoryViewComponent.prototype.viewAdSlotHistory = function (adSlot) {
        var _this = this;
        this.liesRepository.history(adSlot.id, HistoryEntity.AdSlot).subscribe(function (historyResponse) {
            _this.view(historyResponse, adSlot);
        });
    };
    HistoryViewComponent.prototype.viewNewsletterHistory = function (newsletter) {
        var _this = this;
        this.liesRepository.history(newsletter.id, HistoryEntity.Newsletter).subscribe(function (historyResponse) {
            _this.view(historyResponse, newsletter);
        });
    };
    HistoryViewComponent.prototype.formattedChanges = function (item) {
        return this.formatter.formatChanges(item);
    };
    HistoryViewComponent.prototype.download = function (fieldName, timestamp, csv) {
        csv = csv.filter(function (value) { return !!value; });
        DownloadHelper.downloadAsCSV(csv, this.model.entity + "-" + (this.model.refId || this.model.id) + "-" + fieldName + "-" + (timestamp || ''));
    };
    return HistoryViewComponent;
}());
export { HistoryViewComponent };
