import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberToPercentage'
})
export class NumberToPercentagePipe implements PipeTransform {
    transform(value: number) {
        return (value * 100).toFixed(0) + '%'
    }
}
