import { Component, HostListener, ElementRef } from '@angular/core';

@Component({
    selector: 'carousel',
    templateUrl: './carousel.html',
    styleUrls: ['./carousel.styl']
})
export class CarouselComponent {
    static carWidth = 180;

    constructor(private el: ElementRef) { }

    private wheel: any = null;

    ngAfterViewInit() {
        this.wheel = this.el.nativeElement.querySelector('.carousel---wheel');
        this.offset = 0;
    }

    ngDoCheck () {
        // If the `wheel` reference is not available yet we do not wish to trigger
        // the offset calculation. In the case where there is a reference, this
        // serves recalculate the `start` and `end` flags without setting a new
        // offset. This lets us set those variables according to any changes to the
        // number of cars that the carousel is displaying.
        if (this.wheel !== null) {
            this.offset = this._offset;
        }
    }

    showleft: boolean = false;
    showright: boolean = false;

    @HostListener('mousemove', ['$event.clientX']) move(x): void {
        const percentage = (100 / this.width) * (x - this.left);
        this.showleft = percentage <= 40;
        this.showright = percentage >= 60;
    }

    @HostListener('mouseleave') leave(): void {
        this.showleft = false;
        this.showright = false;
    }

    start: boolean = true;
    end: boolean = true;
    private _offset: number = 0;

    set offset(value: number) {
        this.start = value <= 0;
        this.end = value + this.remainder >= this.max && this.max !== 0;
        if (this.start) {
            value = 0;
        }
        this._offset = value;
        this.wheel.style.transform = 'matrix(1, 0, 0, 1, -' + this._offset.toString() + ', 0)';
    }

    get offset(): number {
        return this._offset;
    }

    get width(): number {
        return this.el.nativeElement.getBoundingClientRect().width;
    }

    get left(): number {
        return this.el.nativeElement.getBoundingClientRect().left;
    }

    get remainder(): number {
        return this.width - (this.width % CarouselComponent.carWidth);
    }

    get max(): number {
        return this.el.nativeElement.getElementsByClassName('carousel---car').length * CarouselComponent.carWidth;
    }

    next(): void {
        this.offset = this.offset + this.remainder;
    }

    previous(): void {
        this.offset = this.offset - this.remainder;
    }
}
