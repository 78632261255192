import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, zip, of } from 'rxjs';
import { Advertiser, InsertionOrder, Campaign } from 'app/shared/models';
import {
    AdvertiserRepository,
    InsertionOrderRepository,
    CampaignRepository,
    LineItemRepository
} from 'app/core/repositories';
import { mergeMap } from 'rxjs/operators';
import { Flag, LaunchDarklyService } from '../launch-darkly.service';

type CampaignDetails = {
    advertiser: Advertiser,
    insertionOrder: InsertionOrder,
    campaign: Campaign
};

@Injectable()
export class CampaignDetailsResolver implements Resolve<CampaignDetails> {
    constructor(
        private advertiserRepository: AdvertiserRepository,
        private insertionOrderRepository: InsertionOrderRepository,
        private campaignRepository: CampaignRepository,
        private lineItemRepository: LineItemRepository,
        private launchDarklyService: LaunchDarklyService,
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.campaignRepository
            .get(route.params['id'])
            .pipe(mergeMap(campaign => zip(
                this.advertiserRepository.get(campaign.advertiser),
                this.insertionOrderRepository.get(campaign.insertionOrder),
                of(campaign),
                this.campaignRepository.search({
                    conditions: [{
                        field: 'refId',
                        value: route.params['id']
                    }]
                }),
                this.lineItemRepository.search({
                    conditions: [{
                        field: 'campaign',
                        value: campaign.id
                    }]}),
                this.hasAudienceExtensionFlag(),
                (advertiser, insertionOrder, campaign, campaignSearch, lineItems, hasAudienceExtensionFlag) =>
                {
                    campaign.effectiveStartDate = campaignSearch[0].effectiveStartDate;
                    campaign.effectiveEndDate = campaignSearch[0].effectiveEndDate;
                    campaign['lineItemsObj'] = lineItems;
                    campaign.canAccessAudienceExtension = hasAudienceExtensionFlag;
                    return {advertiser, insertionOrder, campaign};
                })
            ));
    }

    private hasAudienceExtensionFlag() {
        return this.launchDarklyService
            .getVariation(Flag.RolloutAudienceExtension);
    }
}
