import * as tslib_1 from "tslib";
import { Model } from './model';
var AudienceSearchResponse = /** @class */ (function (_super) {
    tslib_1.__extends(AudienceSearchResponse, _super);
    function AudienceSearchResponse() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AudienceSearchResponse.prototype.serialize = function () {
        return JSON.stringify(this);
    };
    Object.defineProperty(AudienceSearchResponse.prototype, "entity", {
        get: function () {
            return 'Audience Search Response';
        },
        enumerable: true,
        configurable: true
    });
    return AudienceSearchResponse;
}(Model));
export { AudienceSearchResponse };
