import { Model } from './model';

export class CookieSyncPartner extends Model {

    public name: string;
    public cookieSyncType: string;

    public serialize(): string {
        return JSON.stringify(this);
    }

    get entity() {
        return 'Cookie Sync Partner';
    }
}
