/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./select-dropdown.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../directives/id-append.directive";
import * as i4 from "@angular/forms";
import * as i5 from "../button/button.component.ngfactory";
import * as i6 from "../button/button.component";
import * as i7 from "../dropdown/dropdown.component.ngfactory";
import * as i8 from "../dropdown/dropdown.component";
import * as i9 from "./select-dropdown.component";
import * as i10 from "../../../core/id.service";
var styles_SelectDropdownComponent = [i0.styles];
var RenderType_SelectDropdownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectDropdownComponent, data: {} });
export { RenderType_SelectDropdownComponent as RenderType_SelectDropdownComponent };
function View_SelectDropdownComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [], [[2, "dropdown--item---index", null]], [[null, "mouseenter"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.mouseenter($event, _v.context.$implicit.key) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co._select(_v.context.$implicit.key) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                            "])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                                "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "spacer--large-left"]], [[1, "id", 0], [8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                            "])), (_l()(), i1.ɵted(-1, null, ["\n                        "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.key === _v.context.$implicit.key); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.id.control("option", _v.context.$implicit.label); var currVal_2 = _v.context.$implicit.label; _ck(_v, 4, 0, currVal_1, currVal_2); }); }
function View_SelectDropdownComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "hr", [["class", "no-margin"]], null, null, null, null, null))], null, null); }
function View_SelectDropdownComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                    "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "supporting padding"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n                    "])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectDropdownComponent_4)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n                    "])), (_l()(), i1.ɵted(-1, null, ["\n                    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectDropdownComponent_5)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n                "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.parent.context.$implicit.options; _ck(_v, 8, 0, currVal_1); var currVal_2 = (_v.parent.context.index !== (_co.groups.length - 1)); _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.label; _ck(_v, 3, 0, currVal_0); }); }
function View_SelectDropdownComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectDropdownComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n            "]))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.options.length > 0); _ck(_v, 3, 0, currVal_0); }, null); }
function View_SelectDropdownComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectDropdownComponent_2)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.groups; _ck(_v, 3, 0, currVal_0); }, null); }
function View_SelectDropdownComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "li", [], [[2, "dropdown--item---index", null], [1, "id", 0]], [[null, "mouseenter"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.mouseenter($event, _v.context.$implicit.key) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co._select(_v.context.$implicit.key) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i3.IdAppendDirective, [i1.ElementRef], { id: [0, "id"] }, null), i1.ɵpad(2, 1), (_l()(), i1.ɵted(-1, null, ["\n                    "])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "a", [], [[1, "id", 0]], null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i3.IdAppendDirective, [i1.ElementRef], { id: [0, "id"] }, null), i1.ɵpad(6, 1), (_l()(), i1.ɵted(-1, null, ["\n                        "])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [], [[1, "id", 0], [8, "textContent", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                    "])), (_l()(), i1.ɵted(-1, null, ["\n                "]))], function (_ck, _v) { var currVal_2 = _ck(_v, 2, 0, _v.context.$implicit.label); _ck(_v, 1, 0, currVal_2); var currVal_4 = _ck(_v, 6, 0, "hyperlink"); _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.key === _v.context.$implicit.key); var currVal_1 = i1.ɵnov(_v, 1).attrId; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 5).attrId; _ck(_v, 4, 0, currVal_3); var currVal_5 = _co.id.control("option", _v.context.$implicit.label); var currVal_6 = _v.context.$implicit.label; _ck(_v, 8, 0, currVal_5, currVal_6); }); }
function View_SelectDropdownComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "li", [["class", "dropdown--item custom-option"]], [[2, "dropdown--item---index", null], [1, "id", 0]], [[null, "mouseenter"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.mouseenter($event, _co.customOption.key) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co._select(_co.customOption.key) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i3.IdAppendDirective, [i1.ElementRef], { id: [0, "id"] }, null), i1.ɵpad(2, 1), (_l()(), i1.ɵted(-1, null, ["\n                        "])), (_l()(), i1.ɵeld(4, 0, null, null, 11, "div", [["class", "custom-option-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                            "])), (_l()(), i1.ɵeld(6, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                                "])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "a", [["class", "padding-xsmall"]], [[1, "id", 0]], null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i3.IdAppendDirective, [i1.ElementRef], { id: [0, "id"] }, null), i1.ɵpad(10, 1), (_l()(), i1.ɵted(11, null, ["\n                                    ", "\n                                "])), (_l()(), i1.ɵted(-1, null, ["\n                            "])), (_l()(), i1.ɵted(-1, null, ["\n                            "])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "i", [["class", "fas fa-pen"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.editCustomOption();
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                        "])), (_l()(), i1.ɵted(-1, null, ["\n                    "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 2, 0, _co.customOption.label); _ck(_v, 1, 0, currVal_2); var currVal_4 = _ck(_v, 10, 0, "hyperlink"); _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.key === _co.customOption.key); var currVal_1 = i1.ɵnov(_v, 1).attrId; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 9).attrId; _ck(_v, 8, 0, currVal_3); var currVal_5 = _co.customOption.label; _ck(_v, 11, 0, currVal_5); }); }
function View_SelectDropdownComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n                        "])), (_l()(), i1.ɵeld(1, 0, null, null, 15, "li", [["class", "dropdown--item custom-option"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                            "])), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [["class", "custom-option-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                                "])), (_l()(), i1.ɵeld(5, 0, null, null, 7, "input", [["placeholder", "Add custom option"], ["type", "text"]], [[1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "keydown"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 6)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.newOptionLabel = $event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown" === en)) {
        var pd_5 = (_co.handleKeydown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i4.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(7, 540672, null, 0, i4.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i1.ɵprd(1024, null, i4.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i4.MaxLengthValidator]), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i1.ɵdid(10, 671744, null, 0, i4.NgModel, [[8, null], [6, i4.NG_VALIDATORS], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(12, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵted(-1, null, ["\n                                "])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "i", [["class", "fas fa-check"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.addCustomOption(_co.newOptionLabel);
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                            "])), (_l()(), i1.ɵted(-1, null, ["\n                        "])), (_l()(), i1.ɵted(-1, null, ["\n                    "]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.maxLengthCustomOption; _ck(_v, 7, 0, currVal_8); var currVal_9 = _co.newOptionLabel; _ck(_v, 10, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 7).maxlength ? i1.ɵnov(_v, 7).maxlength : null); var currVal_1 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 12).ngClassValid; var currVal_6 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_SelectDropdownComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectDropdownComponent_9)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n                    "])), (_l()(), i1.ɵand(0, [["inputField", 2]], null, 0, null, View_SelectDropdownComponent_10)), (_l()(), i1.ɵted(-1, null, ["\n                "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customOption; var currVal_1 = i1.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_SelectDropdownComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(1, 0, null, null, 7, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectDropdownComponent_7)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n                "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectDropdownComponent_8)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n            "])), (_l()(), i1.ɵted(-1, null, ["\n        "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.allowCustom; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_SelectDropdownComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { dropdown: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 23, "div", [["class", "select--dropdown"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(3, 0, null, null, 11, "button", [["class", "dropdown"], ["type", "button"]], [[2, "regular", null], [2, "button--create", null], [2, "dropdown--no-label", null], [2, "full-width", null], [8, "disabled", 0], [1, "id", 0]], [[null, "click"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co._outsideClickTarget.activate() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (_co.keydown($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i1.ɵdid(4, 16384, null, 0, i3.IdAppendDirective, [i1.ElementRef], { id: [0, "id"] }, null), i1.ɵpad(5, 1), i1.ɵdid(6, 49152, [["trigger", 4]], 0, i6.ButtonComponent, [[8, null], i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["\n        "])), (_l()(), i1.ɵeld(8, 0, null, 0, 3, "span", [], [[2, "pull-left", null], [2, "button--placeholder-label", null], [1, "id", 0]], null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i3.IdAppendDirective, [i1.ElementRef], { id: [0, "id"] }, null), i1.ɵpad(10, 2), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵted(-1, 0, ["\n        "])), (_l()(), i1.ɵeld(13, 0, null, 0, 0, "i", [["class", "fas fa-angle-down"]], [[2, "pull-right", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(16, 0, null, null, 7, "dropdown", [], [[2, "left-align", null], [2, "full-width", null]], [[null, "visibility"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibility" === en)) {
        var pd_0 = (_co.dropdownVisChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_DropdownComponent_0, i7.RenderType_DropdownComponent)), i1.ɵdid(17, 245760, [[1, 4]], 0, i8.DropdownComponent, [i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { trigger: [0, "trigger"] }, { visibility: "visibility" }), (_l()(), i1.ɵted(-1, 0, ["\n        "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SelectDropdownComponent_1)), i1.ɵdid(20, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n        "])), (_l()(), i1.ɵand(0, [["singleGroup", 2]], 0, 0, null, View_SelectDropdownComponent_6)), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_6 = _ck(_v, 5, 0, "button"); _ck(_v, 4, 0, currVal_6); var currVal_10 = _ck(_v, 10, 0, "span", _co.label); _ck(_v, 9, 0, currVal_10); var currVal_15 = i1.ɵnov(_v, 6); _ck(_v, 17, 0, currVal_15); var currVal_16 = (_co.groups.length > 0); var currVal_17 = i1.ɵnov(_v, 22); _ck(_v, 20, 0, currVal_16, currVal_17); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hasCreateClass; var currVal_1 = _co.hasCreateClass; var currVal_2 = (_co.isString(_co.placeholder) && (_co.placeholder.length === 0)); var currVal_3 = _co.fullWidth; var currVal_4 = _co.disabled; var currVal_5 = i1.ɵnov(_v, 4).attrId; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_7 = _co.fullWidth; var currVal_8 = (_co.selected === undefined); var currVal_9 = i1.ɵnov(_v, 9).attrId; _ck(_v, 8, 0, currVal_7, currVal_8, currVal_9); var currVal_11 = _co.label; _ck(_v, 11, 0, currVal_11); var currVal_12 = _co.fullWidth; _ck(_v, 13, 0, currVal_12); var currVal_13 = _co.dropdownLeftAlign; var currVal_14 = _co.fullWidth; _ck(_v, 16, 0, currVal_13, currVal_14); }); }
export function View_SelectDropdownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "select-dropdown", [], null, null, null, View_SelectDropdownComponent_0, RenderType_SelectDropdownComponent)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i9.SelectDropdownComponent]), i1.ɵdid(2, 114688, null, 2, i9.SelectDropdownComponent, [i1.ElementRef, i1.ChangeDetectorRef, i10.IdService, [8, null], [8, null]], null, null), i1.ɵqud(603979776, 1, { tplOptions: 1 }), i1.ɵqud(603979776, 2, { groups: 1 })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var SelectDropdownComponentNgFactory = i1.ɵccf("select-dropdown", i9.SelectDropdownComponent, View_SelectDropdownComponent_Host_0, { disabled: "disabled", dropdownLeftAlign: "dropdownLeftAlign", fullWidth: "fullWidth", allowCustom: "allowCustom", maxLengthCustomOption: "maxLengthCustomOption", customOptionValue: "customOptionValue", options: "options" }, {}, []);
export { SelectDropdownComponentNgFactory as SelectDropdownComponentNgFactory };
