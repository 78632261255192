import { Attribute, ChangeDetectionStrategy, Component } from '@angular/core';
import { AdSlot, AdSlotSize } from 'app/shared/models';
import { HasDirtyCheck } from 'app/shared/components/bulk-edit-lightbox';
import { isEqual } from 'app/core/utils';
import adSlotSettings from './ad-slot-setting.json';

interface Size {
    width: number;
    height: number;
}

@Component({
    selector: 'bulk-edit-ad-slot-size',
    templateUrl: './bulk-edit-ad-slot-size.component.html',
    styleUrls: ['./bulk-edit-ad-slot-size.component.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkEditAdSlotSizeComponent implements HasDirtyCheck {
    adSlots: AdSlot[] = [];
    private originals = new Map<number, AdSlot>();
    defaultSizes = adSlotSettings.defaultSizes;
    label: string;
    field: string;

    constructor(
        @Attribute('label') label: string
    ) {
        this.label = label;
    }

    original(refId: number) {
        return this.originals.get(refId);
    }

    set entities(entities: AdSlot[]) {
        this.adSlots = entities;

        this.originals.clear();
        entities.forEach(adSlot => {
            this.originals.set(adSlot.refId, adSlot.clone());
        });
    }

    get entities() {
        return this.adSlots;
    }

    revert(adSlot: AdSlot) {
        adSlot[this.field] = JSON.parse(JSON.stringify(this.original(adSlot.refId)[this.field]));
    }

    revertAll() {
        this.entities.forEach(adSlot => this.revert(adSlot));
    }

    toggleSizeSelection(selected: boolean, selectedAdSlot: AdSlot, size: Size) {
        const ad = this.adSlots.find(adSlot => adSlot.refId === selectedAdSlot.refId);
        const index = ad.sizes.findIndex(adSize => adSize.width === size.width && adSize.height === size.height);

        if (selected && index < 0) {
            ad.sizes.push(this.getSizeSettings(size));
        } else {
            ad.sizes.splice(index, 1);
        }
    }

    toggleMasterSelection(selected: boolean, size: Size) {
        this.adSlots.forEach(adSlot => {
            if (adSlot.isUniversalTag) {
                const index = adSlot.sizes.findIndex(
                    adSize => adSize.width === size.width && adSize.height === size.height
                );

                if (selected && index < 0) {
                    adSlot.sizes.push(this.getSizeSettings(size));
                } else if (!selected && index > -1) {
                    adSlot.sizes.splice(index, 1);
                }
            }
        });
    }

    isSizeSelected(adSlot: AdSlot, size: Size) {
        return adSlot.sizes.some(
            adSlotSize => adSlotSize.width === size.width && adSlotSize.height === size.height
        );
    }

    isMasterSelected(size: Size) {
        let selected = true;
        const universalAdSlots = this.adSlots.filter(ad => ad.isUniversalTag);

        for (const adSlot of universalAdSlots) {
            const index = adSlot.sizes.findIndex(
                adSize => adSize.height === size.height && adSize.width === size.width
            );
            if (index < 0) {
                selected = false;
                break;
            }
        }

        return selected;
    }

    hasCustomSizes(adSlot: AdSlot) {
        for (const size of adSlot.sizes) {
            const index = this.defaultSizes.findIndex(
                defaultSize => defaultSize.width === size.width && defaultSize.height === size.height
            );
            if (index < 0) {
                return true;
            }
        }

        return false;
    }

    getSizeSettings(size: Size) {
        const index = this.defaultSizes.findIndex(
            defaultSize => defaultSize.width === size.width && defaultSize.height === size.height
        );

        return this.defaultSizes[index];
    }

    /**
     * Check if an ad slot has been changed from its original state.
     *
     * We use a custom dirty checker to account for extra 'isEnabled' property
     * that gets added but should not affect dirty status as it is not used.
     * Instead toggling a size is handled by presence in the sizes array.
     *
     * Another reason for the custom check is because during this component's
     * lifecycle we do not preserve the order of the sizes array, so we need to
     * normalize it before we can accurately compare.
     */
    isAdSlotDirty(adSlot: AdSlot) {
        if (Array.isArray(adSlot[this.field])) {
            adSlot[this.field].forEach((size: AdSlotSize) => delete size.isEnabled);
        }

        if (Array.isArray(this.original(adSlot.refId)[this.field])) {
            this.original(adSlot.refId)[this.field].forEach((size: AdSlotSize) => delete size.isEnabled);
        }

        const current = adSlot[this.field];
        const original = this.original(adSlot.refId)[this.field];

        if (Array.isArray(current)) {
            current.sort(this.compareBySize);
        }

        if (Array.isArray(original)) {
            original.sort(this.compareBySize);
        }

        return !isEqual(current, original);
    }

    isDirty() {
        return this.entities.some(adSlot => this.isAdSlotDirty(adSlot));
    }

    /**
     * Compare two ad slot sizes for the purpose of sorting them.
     * Use both height and width in comparison.
     */
    private compareBySize(a: AdSlotSize, b: AdSlotSize) {
        const aSize = `${a.height}x${a.width}`;
        const bSize = `${b.height}x${b.width}`;

        if (aSize > bSize) {
            return 1;
        }

        if (aSize < bSize) {
            return -1;
        }

        return 0;
    }
}
