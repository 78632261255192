/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./list.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../directives/id-append.directive";
import * as i3 from "@angular/common";
import * as i4 from "@angular/forms";
import * as i5 from "./list.component";
import * as i6 from "../../../core/id.service";
var styles_ListComponent = [i0.styles];
var RenderType_ListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ListComponent, data: {} });
export { RenderType_ListComponent as RenderType_ListComponent };
function View_ListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                    "])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "column--3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "span", [["class", "list--value overflow"]], [[1, "id", 0]], null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.IdAppendDirective, [i1.ElementRef], { id: [0, "id"] }, null), i1.ɵpad(5, 1), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n                    "])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "column--8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "span", [["class", "overflow"]], [[1, "id", 0]], null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i2.IdAppendDirective, [i1.ElementRef], { id: [0, "id"] }, null), i1.ɵpad(11, 1), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n                    "])), (_l()(), i1.ɵeld(14, 0, null, null, 3, "div", [["class", "column--1 align-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                        "])), (_l()(), i1.ɵeld(16, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                    "])), (_l()(), i1.ɵted(-1, null, ["\n                "]))], function (_ck, _v) { var currVal_1 = _ck(_v, 5, 0, _v.parent.context.$implicit.value); _ck(_v, 4, 0, currVal_1); var currVal_4 = _ck(_v, 11, 0, _v.parent.context.$implicit.label); _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).attrId; _ck(_v, 3, 0, currVal_0); var currVal_2 = _v.parent.context.$implicit.value; _ck(_v, 6, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 10).attrId; _ck(_v, 9, 0, currVal_3); var currVal_5 = _v.parent.context.$implicit.label; _ck(_v, 12, 0, currVal_5); }); }
function View_ListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_ListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_ListComponent_4)), i1.ɵdid(1, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(2, { $implicit: 0, index: 1 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _v.parent.context.$implicit, _v.parent.context.index); var currVal_1 = _co.itemtpl; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "li", [], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i3.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_2)), i1.ɵdid(8, 278528, null, 0, i3.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i3.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_3)), i1.ɵdid(11, 16384, null, 0, i3.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i3.NgSwitch], null, null), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵted(-1, null, ["\n        "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.disabled ? "disabled" : "none"); _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.itemtpl; _ck(_v, 5, 0, currVal_2); var currVal_3 = null; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(2, "", _v.context.$implicit.value, " ", _v.context.$implicit.label, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_ListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Only showing first ", " items"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.LIST_LIMIT; _ck(_v, 1, 0, currVal_0); }); }
export function View_ListComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "list"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 7, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_1)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_5)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shownItems; _ck(_v, 5, 0, currVal_0); var currVal_1 = (_co.items.length > _co.LIST_LIMIT); _ck(_v, 8, 0, currVal_1); }, null); }
export function View_ListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "list", [], null, null, null, View_ListComponent_0, RenderType_ListComponent)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.ListComponent]), i1.ɵdid(2, 49152, null, 1, i5.ListComponent, [i1.ChangeDetectorRef, i6.IdService], null, null), i1.ɵqud(335544320, 1, { itemtpl: 0 })], null, null); }
var ListComponentNgFactory = i1.ɵccf("list", i5.ListComponent, View_ListComponent_Host_0, { disabled: "disabled" }, { onRemove: "onRemove" }, []);
export { ListComponentNgFactory as ListComponentNgFactory };
