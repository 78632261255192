import * as tslib_1 from "tslib";
import { BackendRepository } from './backend-repository';
import { map } from 'rxjs/operators';
import { dedupe } from 'app/core/array-utils';
// like is also supported, but we want to force ilike
var laravelResourceSearchSupportedOperators = [
    '<', '<=', '>', '>=', '=', '!=', 'not like', 'ilike', 'not ilike', 'in', 'not in', 'all in', 'any in'
];
var operatorMap = {
    like: 'ilike',
    co: 'ilike',
    nco: 'not ilike',
    eq: '=',
    ne: '!=',
    gt: '>',
    lt: '<',
    lteq: '<=',
    gteq: '>=',
};
var BackendResourceRepository = /** @class */ (function (_super) {
    tslib_1.__extends(BackendResourceRepository, _super);
    function BackendResourceRepository() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BackendResourceRepository.prototype.search = function (query) {
        var _this = this;
        return this.rawSearch(query)
            .pipe(map(function (response) { return response['data'].map(function (data) { return _this.build(data); }); }));
    };
    BackendResourceRepository.prototype.asyncSearch = function (params) {
        var _this = this;
        return this.rawSearch(params)
            .pipe(map(function (response) { return ({
            items: response['data'].map(function (item) { return _this.build(item); }),
            page: 1,
            pages: Math.ceil(response['meta']['total'] / response['meta']['per_page']),
            total: response['meta']['total']
        }); }));
    };
    BackendResourceRepository.prototype.rawSearch = function (query, uri) {
        var path = uri || this.path + '/search';
        var page = query['page'] || 1;
        var size = query['n'] || 500;
        var paginationQueryString = "page[number]=" + page + "&page[size]=" + size;
        return this.http.post(this.url(path + "?" + paginationQueryString), this.sanitizeQuery(query));
    };
    BackendResourceRepository.prototype.sanitizeQuery = function (query) {
        var _this = this;
        return {
            filters: (query.conditions || []).map(function (c) { return _this.convertConditionToFilter(c); }),
            sort: query.orderBy && [
                { field: query.orderBy, direction: query.sort }
            ],
            scopes: query.scope && [
                { name: query.scope }
            ]
        };
    };
    BackendResourceRepository.prototype.convertConditionToFilter = function (condition) {
        var _this = this;
        if (Array.isArray(condition.conditions)) {
            return {
                type: condition.mode || 'and',
                nested: condition.conditions.map(function (c) { return _this.convertConditionToFilter(c); })
            };
        }
        return {
            type: condition.mode,
            field: condition.field,
            value: this.getValue(condition),
            operator: this.getOperator(condition),
        };
    };
    BackendResourceRepository.prototype.getValue = function (condition) {
        var _this = this;
        var maybeLikify = function (value) {
            if (value && _this.getOperator(condition).includes('like') && !value.includes('%')) {
                return '%' + value + '%';
            }
            return value;
        };
        if (Array.isArray(condition.value)) {
            return dedupe(condition.value).map(function (v) { return maybeLikify(v); });
        }
        return maybeLikify(condition.value);
    };
    BackendResourceRepository.prototype.getOperator = function (condition) {
        if (laravelResourceSearchSupportedOperators.includes(condition.operator)) {
            return condition.operator;
        }
        if (Array.isArray(condition.value)) {
            return condition.operator === 'neq' ? 'not in' : 'in';
        }
        return operatorMap[condition.operator] || '=';
    };
    return BackendResourceRepository;
}(BackendRepository));
export { BackendResourceRepository };
