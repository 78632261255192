import { HttpClient } from '@angular/common/http';
import { Observable, Subject, ReplaySubject, concat, forkJoin, of, throwError } from 'rxjs';
import { bufferWhen, filter, mergeMap, debounceTime, map, reduce } from 'rxjs/operators';
import { Model } from 'app/shared/models/model';
import { environment } from 'src/environments/environment';

export interface Query extends Condition {
    n?: number;
    page?: number;
    returnMode?: string;
    return?: string[];
    orderBy?: string;
    sort?: 'ASC' | 'DESC';
}

export interface Condition {
    field?: string;
    value?: any;
    mode?: string;
    operator?: string;
    conditions?: Condition[];
}

export type PaginatedResponse = {
    items: any[],
    page: number,
    pages: number,
    total: number
};

export type Id = string | number;

/**
 * The `BackendRepository` is an abstract class which when extended takes a
 * generic model type and allows for the creation of instances of the model as
 * promises typically using data fetched from the backend API through the
 * `BackendHttp` service.
 */
export abstract class BackendRepository<T extends Model> {
    protected _cache = new Map<Id, Subject<T>>();
    private toFetch$ = new Subject<Id>();
    static readonly FLUSH_INTERVAL = 1000;
    protected _searchReturnFields: string[];

    constructor(
        public http: HttpClient,
        public path: string,
        public model: { new (from?: {}): T; }
    ) {
        this.toFetch$.pipe(
            bufferWhen(() => this.toFetch$.pipe(debounceTime(BackendRepository.FLUSH_INTERVAL))),
            filter(ids => ids.length > 0),
            mergeMap(ids => this.searchByIds(ids))
        ).subscribe(models => this.populateCache(models));
    }

    private populateCache(models: T[]) {
        models.forEach(model => {
            const subject = this._cache.get(this.getCacheKey(model));

            if (subject) {
                subject.next(model);
            }
        });
    }

    protected getCacheKey(model: T): Id {
        return model.id;
    }

    /**
     * Returns an observable that fetches `T` with `id` from the backend.
     * @param id
     */
    get(id: Id): Observable<T> {
        if (typeof id === 'number') {
            id = id.toString();
        }

        if (typeof id !== 'string') {
            return throwError(new Error('Invalid parameter. Parameter must be a string or a number.'));
        }

        if (id.length < 1) {
            return throwError(new Error('Invalid parameter. Parameter must have length at least 1.'));
        }

        return this.http.get(this.url(this.path, id)).pipe(
            map(response => this.build(response['output']))
        );
    }

    /**
     * Returns an observable that fetches `T` with `id` from the backend,
     * or the cache if the entity is already present.
     * @param id
     */
    getFromCache(id: Id): Observable<T> {
        if (!this._cache.has(id)) {
            this.toFetch$.next(id);
            this._cache.set(id, new ReplaySubject());
        }

        return this._cache.get(id);
    }

    /**
     * Returns an observable that searches for `T` in the backend.
     * @param query
     */
    search(query?: {}): Observable<T[]> {
        return this.rawSearch(query)
            .pipe(map(response => response['output'].map(data => this.build(data)) as T[]));
    }

    /**
     * Returns an observable that searches for `T`s by ids in the backend.
     * @param ids
     */
    searchByIds(ids: Id[]) {
        if (Array.isArray(ids) && ids.length === 0) {
            return of([]);
        }

        const query: {[k: string]: any} = {};

        query.conditions = [
            { field: 'id', value: ids }
        ];

        if (this._searchReturnFields) {
            query.return = this._searchReturnFields;
        }

        return this.search(query);
    }

    /**
     * Performs a search against the backend and returns the response.
     * @param query?
     */
    protected rawSearch(query?: {}, uri?: string) {
        const path = uri || '/search' + this.path;
        return this.http.post(this.url(path), this.sanitizeQuery(query));
    }

    /**
     * Sanitize the query params before sending it off.
     * @param query?
     */
    protected sanitizeQuery(query?: any) {
        if (!query) {
            return;
        }

        if (Array.isArray(query.conditions) && query.conditions.length === 0) {
            // LSD does not support empty conditions
            delete query.conditions;
        }

        return query;
    }

    /**
     * Performs a search against the backend and builds models of `T` for
     * each item returned.
     * @param params
     * @param uri?
     */
    asyncSearch(params: any, uri?: string): Observable<PaginatedResponse> {
        return this.rawSearch(params, uri)
            .pipe(map(data => ({
                items: data['output'].map(item => this.build(item) as T),
                page: 1,
                pages: data['pages'],
                total: data['total']
            })));
    }

    /**
     * Returns all results where field equals value.
     */
    allWhere(field: string, value: any) {
        return this.all({
            conditions: [
                { field, value }
            ]
        });
    }

    /**
     * Returns an observable of items of `T` in all pages in the result of the search
     * with `query` parameters.
     * @param query
     */
    all(query: Query = {}): Observable<T[]> {
        query.n = 1000;

        return this.consume(page => {
            // clone the query so when we update the page
            // we are not mutating the original query
            // since it will be passed by reference
            const q = JSON.parse(JSON.stringify(query));
            q.page = page;

            return this.rawSearch(q);
        });
    }

    /**
     * Returns an observable that saves `T` in the backend.
     * @param instance
     */
    save(instance: T): Observable<T> {
        const params = [this.path];
        if (instance.id) {
            params.push(instance.id);
        }
        return this.http.post(this.url(...params), instance.serialize())
            .pipe(map(response => this.build(response['output'])));
    }

    /**
     * Returns an observable that saves all `instances` of T.
     * @param instances
     */
    saveAll(instances: T[]): Observable<T[]> {
        return concat(...instances.map(instance => this.save(instance)))
            .pipe(reduce((all, instance) => all.concat(instance), []));
    }

    /**
     * Returns an observable that deletes `T` with `id` in the backend.
     * @param id
     */
     delete(id: string) {
        return this.http.delete(this.url(this.path, id));
    }

    /**
     * This function returns a new instance of the model of type `T`
     * instantiated with `from`.
     * @param from
     */
    build(from?: {}): T {
        return new this.model(from);
    }

    /**
     * This function will return an observable which consumes all the pages
     * returned by the endpoint for `method`.
     * @param method
     * @param query
     */
    consume(method: (page: number) => Observable<Object>): Observable<T[]> {
        return method(1).pipe(mergeMap(response => {
            const data = response;
            const observables = [ of(response) ];

            // Fetch all remaining pages.
            for (let i = 2; i <= data['pages']; i++) {
                observables.push(method(i));
            }

            return forkJoin(observables);
        })).pipe(map(responses => this.buildItems(responses)));
    }

    /**
     * Converts each item in the responses to type `T`.
     * @param responses
     */
    protected buildItems(responses): T[] {
        return responses.reduce((all, response) => {
            const data = response['output'];

            const items = data.map(item => this.build(item));
            all.push(...items);

            return all;
        }, [] as T[]);
    }

    /**
     * Builds and returns a URL for the backend API given an array of strings
     * `suffix` which are joined using a forward-slash.
     * @param suffix
     */
    url(...suffix: string[]): string {
        return environment.backend.hostname + suffix.join('/');
    }

    /**
     * Returns an observable that searches for `T`s by refIds in the backend.
     * @param refIds
     */
    searchByRefIds(refIds: Id[]) {
        if (Array.isArray(refIds) && refIds.length === 0) {
            return of([]);
        }

        const query: {[k: string]: any} = {};

        query.conditions = [
            { field: 'refId', value: refIds }
        ];

        if (this._searchReturnFields) {
            query.return = this._searchReturnFields;
        }

        return this.search(query);
    }
}
