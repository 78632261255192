import { Attribute, Component, ContentChildren, QueryList } from '@angular/core';
import { BulkEditDirective } from '../bulk-edit.directive';

@Component({
    selector: 'bulk-edit-group',
    template: '<ng-content></ng-content>'
})
export class BulkEditGroupComponent {
    @ContentChildren(BulkEditDirective) children: QueryList<BulkEditDirective>;
    label: string;

    constructor(@Attribute('label') label: string) {
        this.label = label;
    }
}
