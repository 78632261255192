import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ElementRef
} from '@angular/core';

import { SlicePipe } from '@angular/common';
import { FilterPipe } from '../pipes/filter.pipe';
import { DropdownComponent, Navigable, Option } from '../dropdown';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IdService } from 'app/core/id.service';

@Component({
    selector: 'search',
    templateUrl: './search.html',
    styleUrls: ['./search.styl']
})
export class SearchComponent extends Navigable implements OnDestroy, OnInit, AfterViewInit {
    @ViewChild('trigger', { static: true }) trigger: ElementRef;

    @ViewChild(DropdownComponent, { static: true })
    dropdown: DropdownComponent;

    @Input()
    isLoading: boolean = false;

    @Input()
    placeholder: string = null;

    @Input('debounce')
    DELAY = 0;

    @Output('onSelect')
    selectChange = new EventEmitter<Option>();

    @Output()
    remove = new EventEmitter<Option>();

    @Output()
    inputChange = new EventEmitter<String>();

    inputValue: string;
    itemList: Array<Option> = [];

    input = '';
    filter = new FilterPipe();
    slice = new SlicePipe();

    subscription: Subscription;

    constructor(
        private cdr: ChangeDetectorRef,
        public id: IdService
    ) {
        super(cdr);
    }

    ngOnInit(): void {
        this.refresh(this.input);
        this.subscription = fromEvent(this.trigger.nativeElement, 'input').pipe(
            map(() => this.inputValue),
            debounceTime(this.DELAY),
            distinctUntilChanged()
        ).subscribe(input => this.inputChange.emit(input));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public ngAfterViewInit(): void {
        this.dropdown.trigger.addEventListener('blur', _ => this.index = -1);
    }

    keydown(event: KeyboardEvent) {
        if (event.keyCode === 8 && this.input.length < 1) {
            this.remove.emit();
            return;
        }
        super.keydown(event);
        this.cdr.detectChanges();
    }

    mouseenter(event: MouseEvent, key: number): number {
        super.mouseenter(event, key);
        this.cdr.detectChanges();
        return null;
    }

    refresh(input) {
        this.input = input;
        this.items = this.input === ''
            ? this.itemList
            : this.slice.transform(this.filter.transform(this.itemList, this.input), 0);
        this.reset();
        this.cdr.detectChanges();
    }

    search(input) {
        this.refresh(input);
    }

    select(event: Event, index: number) {
        if (index > -1) {
            this.selectChange.emit(this.items[index]);
        }
        this.refresh('');
        this.inputValue = this.input;
    }

    blur() {
        window.setTimeout(() => {
            this.trigger.nativeElement.blur();
            this.dropdown.hide();
            this.cdr.detectChanges();
        }, 125);
    }

    inputBlur() {
        window.setTimeout(() => {
            this.dropdown.hide();
        }, 125);
    }

    @Input('items')
    set _items(newItems) {
        if (newItems &&
            this.itemList &&
            newItems.length === this.itemList.length) {
            return;
        }
        this.itemList = newItems;
        this.refresh(this.input);
    }

    get _items() {
        return this.itemList;
    }
}
