import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InsertionOrder } from 'app/shared/models';
import { BackendRepository } from './backend-repository';

@Injectable()
export class InsertionOrderRepository extends BackendRepository<InsertionOrder> {
    public constructor(http: HttpClient) {
        super(http, '/insertion-order', InsertionOrder);
    }
}
