/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/portal";
import * as i2 from "./portal.component";
var styles_PortalComponent = [];
var RenderType_PortalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PortalComponent, data: {} });
export { RenderType_PortalComponent as RenderType_PortalComponent };
function View_PortalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n      "])), i0.ɵncd(null, 0), (_l()(), i0.ɵted(-1, null, ["\n    "]))], null, null); }
export function View_PortalComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { portal: 0 }), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PortalComponent_1)), i0.ɵdid(3, 16384, [[1, 4]], 0, i1.CdkPortal, [i0.TemplateRef, i0.ViewContainerRef], null, null), (_l()(), i0.ɵted(-1, null, ["\n  "]))], null, null); }
export function View_PortalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-portal", [], null, null, null, View_PortalComponent_0, RenderType_PortalComponent)), i0.ɵdid(1, 4898816, null, 0, i2.PortalComponent, [i0.ComponentFactoryResolver, i0.ApplicationRef, i0.Injector], null, null)], null, null); }
var PortalComponentNgFactory = i0.ɵccf("app-portal", i2.PortalComponent, View_PortalComponent_Host_0, { top: "top", left: "left" }, {}, ["*"]);
export { PortalComponentNgFactory as PortalComponentNgFactory };
