import { EventEmitter, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { BehaviorSubject, of, ReplaySubject } from 'rxjs';
import { AuthorizationService, IdService } from 'app/core';
import { BlueprintRepository } from 'app/core/repositories';
import { TableHelper } from 'app/shared/helpers/table-helper';
import { LightboxComponent } from 'app/shared/elements/lightbox';
import { AsyncTableComponent } from 'app/shared/elements/async-table';
import { Router } from '@angular/router';
import { StatusId } from 'app/shared/models/status';
var MAX_LINKABLE_BLUEPRINTS = 10;
var LinkBlueprintsComponent = /** @class */ (function () {
    function LinkBlueprintsComponent(blueprintRepository, router, authorizationService, id, location) {
        var _this = this;
        this.blueprintRepository = blueprintRepository;
        this.router = router;
        this.authorizationService = authorizationService;
        this.id = id;
        this.location = location;
        this.blueprintsLinked = new EventEmitter();
        this.helper = new TableHelper(function (params) { return _this.adapter(params); });
        this.nativeBlueprints$ = new ReplaySubject(1);
        this.blueprintOptions$ = new ReplaySubject(1);
        this.currentParams$ = new BehaviorSubject({});
        this.allAvailableBlueprints = [];
    }
    LinkBlueprintsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.nativeBlueprints$.next(this.adSlot.nativeBlueprints);
        this.table.query.subscribe(function (args) { return _this.helper.search(args); });
    };
    LinkBlueprintsComponent.prototype.adapter = function (params) {
        if (!this.adSlot.isNative || (!this.adSlot.nativeBlueprints
            && !this.mediaGroup && !this.adSlot.mediaGroupRefId)) {
            return of({
                items: [],
                page: 1,
                pages: 0,
                total: 0
            });
        }
        var mediaGroupRefId = this.adSlot.mediaGroupRefId || this.mediaGroup.refId;
        if (this.adSlot.nativeBlueprints) {
            params.conditions.push({
                field: 'id',
                value: this.adSlot.nativeBlueprints.map(function (blueprint) { return blueprint.blueprintId; }),
                operator: 'neq'
            });
        }
        if (mediaGroupRefId) {
            params.conditions.push({
                field: 'mediaGroupId',
                value: mediaGroupRefId
            });
        }
        params.conditions.push({
            field: 'status',
            value: StatusId.ACTIVE_ID
        });
        return this.blueprintRepository.asyncSearch(params);
    };
    LinkBlueprintsComponent.prototype.query = function (tableQuery) {
        this.currentParams$.next(tableQuery);
    };
    Object.defineProperty(LinkBlueprintsComponent.prototype, "availableBlueprintsToLink", {
        get: function () {
            var existingNativeBlueprints = this.adSlot.nativeBlueprints ? this.adSlot.nativeBlueprints.length : 0;
            var maxLinkableItems = Math.min(MAX_LINKABLE_BLUEPRINTS, this.table.rows.length + existingNativeBlueprints);
            return Math.max(0, maxLinkableItems - existingNativeBlueprints - this.selectedBlueprints.length);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LinkBlueprintsComponent.prototype, "selectedBlueprints", {
        get: function () {
            if (this.allAvailableBlueprints.length > 0) {
                return this.allAvailableBlueprints;
            }
            else if (this.table) {
                return this.table.selectedItems;
            }
            return [];
        },
        enumerable: true,
        configurable: true
    });
    LinkBlueprintsComponent.prototype.confirmLinkBlueprints = function () {
        var nativeBlueprints = [];
        this.selectedBlueprints.map(function (blueprint) {
            nativeBlueprints.push({
                blueprintId: blueprint.id,
                floor: 0.0,
                allowExchange: false,
                allowRtb: false
            });
        });
        this.blueprintsLinked.emit(nativeBlueprints);
        this.close();
    };
    LinkBlueprintsComponent.prototype.open = function () {
        this.table.filters = [];
        this.table.clearSelections();
        this.lightbox.open();
    };
    LinkBlueprintsComponent.prototype.close = function () {
        this.lightbox.close();
    };
    LinkBlueprintsComponent.prototype.redirectToCreateBlueprint = function () {
        this.close();
        var url = this.router.serializeUrl(this.router.createUrlTree(['/inventory-manager/native/native-templates/new']));
        window.open(url, '_blank');
    };
    return LinkBlueprintsComponent;
}());
export { LinkBlueprintsComponent };
