import {
    Component,
    OnInit,
    forwardRef,
    Attribute,
    HostListener,
    ChangeDetectionStrategy,
    ViewChild,
    ElementRef,
    Input
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { XDropdownComponent } from 'app/shared/elements/x-dropdown';
import { CalendarComponent } from 'app/shared/elements/calendar';
import { Subject } from 'rxjs';
import moment from 'moment';
import { filter, map } from 'rxjs/operators';
import { IdService } from 'app/core/id.service';

@Component({
    selector: 'date-picker',
    templateUrl: './date-picker.html',
    styleUrls: ['./date-picker.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatePickerComponent),
            multi: true
        }
    ]
})
export class DatePickerComponent implements OnInit, ControlValueAccessor {
    @ViewChild(XDropdownComponent, { static: true }) dropdown: XDropdownComponent;
    @ViewChild('input', { static: true }) inputElement: ElementRef;
    @ViewChild(CalendarComponent, { static: true }) calendar: CalendarComponent;
    @Input() from;
    @Input() disabled: boolean = false;
    @Input('max-date') maxDate: moment.Moment;
    @Input('min-date') minDate: moment.Moment;

    date$ = new Subject<moment.Moment>();

    private onTouched = () => {};

    constructor(
        public id: IdService,
        @Attribute('placeholder') public placeholder
    ) { }

    ngOnInit() {
        this.date$.pipe(filter(date => date !== null)).subscribe(date => this.updateDate(date));
    }

    writeValue(value) {
        this.updateDate(moment(value || null));
    }

    registerOnChange(onChange) {
        this.date$
            .pipe(map(date => date ? date.format('YYYY-MM-DD') : null))
            .subscribe(date => onChange(date));
    }

    registerOnTouched(onTouched) {
        this.onTouched = onTouched;
    }

    onDateSelected(date: moment.Moment) {
        this.date$.next(date);
        this.dropdown.hide();
        this.inputElement.nativeElement.blur();
    }

    inputChange(value: string) {
        const date = moment(value);
        this.date$.next(date.isValid() ? date : null);
        this.inputBlur();
    }

    inputFocus() {
        this.dropdown.show();
    }

    @HostListener('window:keydown.esc') inputBlur() {
        this.calendar.clearHover();
        this.dropdown.hide();
        this.onTouched();
    }

    private updateDate(date: moment.Moment) {
        if (date.isValid()) {
            this.inputElement.nativeElement.value = date.format('M/D/Y');
            this.calendar.setMonth(date.clone().startOf('month'));
        } else {
            delete this.inputElement.nativeElement.value;
        }
    }

    reset() {
        this.inputElement.nativeElement.value = null;
    }
}
