<section>
    <div class="row padding-large">
        <div class="column--10">
            <h1 [attr.id]="id.header('bulk-operation-status', 'title')">Bulk Operation Status</h1>
        </div>
    </div>
    <div class="padding padding-large">
        <div class="row">
            <div class="column--12 deal-bottom-padding">
                <app-table [data]="helper.data | async">
                    <div class="zero">
                        <div>You have not created any bulk operations.</div>
                    </div>
                    <ng-container appTableColumn="operation_batch_name" appTableColumnName="Name" appTableColumnLocked>
                        <th *appTableHeader class="name">Name</th>
                        <td *appTableCell="let bulkOperation" class="name" (click)="showModal(bulkOperation)">
                            <span class="overflow">{{ bulkOperation.operation_batch_name | dashToSpace | titlecase }} Update</span>
                            <span class="copy--supporting">ID: {{ bulkOperation.id }}</span>
                        </td>
                    </ng-container>
                    <ng-container appTableColumn="status" appTableColumnName="Status">
                        <th *appTableHeader class="small">Status</th>
                        <td *appTableCell="let bulkOperation" class="small" (click)="showModal(bulkOperation)">
                            <span>{{ bulkOperation.status | capitalizeFirstLetter }}</span>
                        </td>
                    </ng-container>
                    <ng-container appTableColumn="total" appTableColumnName="Total">
                        <th *appTableHeader class="tiny">Total</th>
                        <td *appTableCell="let bulkOperation" class="tiny" (click)="showModal(bulkOperation)">
                            <span class="blue-chip">{{ (bulkOperation.total_jobs-bulkOperation.pending_jobs)/bulkOperation.total_jobs | numberToPercentage }}</span>
                        </td>
                    </ng-container>
                    <ng-container appTableColumn="pending" appTableColumnName="Pending">
                        <th *appTableHeader class="tiny">Pending</th>
                        <td *appTableCell="let bulkOperation" class="tiny" (click)="showModal(bulkOperation)">
                            <span class="blue-chip">{{ bulkOperation.pending_jobs/bulkOperation.total_jobs | numberToPercentage }}</span>
                        </td>
                    </ng-container>
                    <ng-container appTableColumn="success" appTableColumnName="Success">
                        <th *appTableHeader class="tiny">Success</th>
                        <td *appTableCell="let bulkOperation" class="tiny" (click)="showModal(bulkOperation)">
                            <span class="blue-chip">{{ bulkOperation.completed_successfully/bulkOperation.total_jobs | numberToPercentage }}</span>
                        </td>
                    </ng-container>
                    <ng-container appTableColumn="failed" appTableColumnName="Failed">
                        <th *appTableHeader class="tiny">Failed</th>
                        <td *appTableCell="let bulkOperation" class="tiny" (click)="showModal(bulkOperation)">
                            <span class="blue-chip">{{ bulkOperation.completed_with_failures/bulkOperation.total_jobs | numberToPercentage }}</span>
                        </td>
                    </ng-container>
                    <ng-container appTableColumn="created_date" appTableColumnName="Date Created">
                        <th *appTableHeader appTableSort appTableNoDefaultSortColumn class="date remove-click">Date Created</th>
                        <td *appTableCell="let bulkOperation" (click)="showModal(bulkOperation)">
                            <span>{{ bulkOperation.created_at | date: 'mediumDate' || '&mdash;' }}</span>
                            <div class="copy--supporting">{{ bulkOperation.created_at | date: 'shortTime' }}</div>
                        </td>
                    </ng-container>
                </app-table>
                <paginator [total]="(helper.data | async)?.total"></paginator>
            </div>
        </div>
    </div>
</section>
<bulk-operation-status-modal></bulk-operation-status-modal>
