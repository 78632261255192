import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReportingQueryRepository } from 'app/core/repositories';
import { AdSlot, AdSlotSize, Publisher } from 'app/shared/models';
import { map, publishLast, refCount } from 'rxjs/operators';

const deviceTypes = [
    "PC",
    "Phone",
    "Tablet",
    "Google Proxy",
    "GoogleProxy",
    "Apple MPP Proxy",
    "AppleMPPProxy",
    "Apple Mail",
    "AppleMail",
    "Yahoo Proxy",
    "YahooProxy"
] as const;

type DeviceType = typeof deviceTypes[number];

@Injectable()
export class YieldManagementAdSlotService {
    private adSlotHistory = new Map<string, Observable<any>>();

    constructor(private reporting: ReportingQueryRepository) { }

    /**
     * Get the percentage of impressions that were delivered to an ad slot for
     * a specific size, on a specific device type, since a certain date. If
     * the ad slot is newer than the date provided, we use the publisher.
     */
    adSlotImpressionVolume(
        adSlot: AdSlot, publisher: Publisher, since: Date, size: AdSlotSize, deviceType: DeviceType
    ) {
        deviceType = deviceType.replace(/\s/g, '') as DeviceType;

        return this.fetchAdSlotImpressionData(
            adSlot, publisher, since, 'getAdSlotImpressionsForDeviceAndSize'
        ).pipe(map(data => {
            const totalImpressions = data.reduce((sum, cur) => sum + cur.event.impressions, 0);

            const hit = data.find(p => {
                return p.event.device_type === deviceType && p.event.size === `${size.width}x${size.height}`;
            });

            return hit ? hit.event.impressions / totalImpressions : null;
        }));
    }

    /**
     * Get the percentage of impressions that were served to an ad slot under
     * a specific floor, since a certain date. If
     * the ad slot is newer than the date provided, we use the publisher.
     */
    adSlotImpressionVolumeBeneathFloor(adSlot: AdSlot, publisher: Publisher, since: Date, size: AdSlotSize) {
        return this.fetchAdSlotImpressionData(
            adSlot, publisher, since, 'getAdSlotImpressionsWithBidBucketsBySize'
        ).pipe(map(data => {
            const totalImpressions = data.reduce((sum, cur) => sum + cur.event.impressions, 0);

            const hits = data.filter(p => {
                return p.event.size === `${size.width}x${size.height}`
                    && parseFloat(p.event.bid_price_bucket) < size.floor;
            });

            const beneathImpressions = hits.reduce((sum, cur) => sum + cur.event.impressions, 0);

            return beneathImpressions ? beneathImpressions / totalImpressions : null;
        }));
    }

    /**
     * Fetch historical data for an ad slot. If the ad slot is newer than the date
     * provided, use the publisher instead.
     */
    private fetchAdSlotImpressionData(adSlot: AdSlot, publisher: Publisher, since: Date, queryFn: string) {
        if (adSlot.isNew() || new Date(adSlot.created) > since) {
            return this.fetchData(publisher.refId, 'Publisher', since, queryFn);
        }
        return this.fetchData(adSlot.refId, 'AdSlot', since, queryFn);
    }

    /**
     * Fetch historical data for an ad slot, grouped by device type and size.
     */
    private fetchData(refId: number, idType: 'AdSlot' | 'Publisher', since: Date, queryFn: string) {
        const cacheKey = `${refId}.${queryFn}`;

        if (!this.adSlotHistory.has(cacheKey)) {
            const request = this.reporting[queryFn](refId, idType, since).pipe(publishLast(), refCount());
            this.adSlotHistory.set(cacheKey, request);
        }

        return this.adSlotHistory.get(cacheKey);
    }
}
