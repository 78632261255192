import { AfterContentInit, AfterContentChecked, Component, ElementRef, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'expandable-text',
    templateUrl: './expandable-text.component.html'
})
export class ExpandableTextComponent implements AfterContentInit, AfterContentChecked {
    @ViewChild('container', { static: true }) container: ElementRef;

    overflows = false;
    expanded = false;

    private content: string;
    private readonly contentChanged = new Subject<void>();

    constructor() {
        this.contentChanged.subscribe(() => this.check());
    }

    ngAfterContentInit() {
        this.content = this.container.nativeElement.innerHTML;
        this.contentChanged.next();
    }

    ngAfterContentChecked() {
        const content = this.container.nativeElement.innerHTML;

        if (content !== this.content) {
            this.content = content;
            this.contentChanged.next();
        }
    }

    check() {
        setTimeout(() => {
            this.overflows = this.container.nativeElement.offsetWidth < this.container.nativeElement.scrollWidth;
        }, 0);
    }
}
