import * as tslib_1 from "tslib";
import { EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PageSizeOptions } from './page-size-options';
import { TableURLService } from 'app/shared/elements/table';
var PaginatorComponent = /** @class */ (function () {
    /**
     * Create a new paginator.
     */
    function PaginatorComponent(route, urlService, routeKey, noFirstAndLast, hideTotal) {
        this.route = route;
        this.urlService = urlService;
        this.routeKey = routeKey;
        /**
         * Emits when the pagination has been changed.
         */
        this.query = new EventEmitter();
        /**
         * The current number of rows visible at a time.
         */
        this.pageSize = 10;
        /**
         * Whether to show the first and last buttons.
         */
        this.showFirstAndLast = true;
        /**
         * The current page.
         */
        this.page = 1;
        /**
         * A stack which holds the current cursor, as well as all previously used cursors.
         */
        this.cursorStack = [];
        /**
         * We set a debounce so we don't fire off too many queries at once. For example if
         * a user clicks the `Next` button repeatedly, we'll wait until the clicks stop
         * coming before finally emitting the desired page of the user.
         */
        this.debounceTime = 200;
        /**
         * A stream of queries.
         */
        this.query$ = new Subject();
        /**
         * A stream used to signal the destruction of this component.
         */
        this.onDestroy$ = new Subject();
        /**
         * The available page size options.
         */
        this.pageSizeOptions = PageSizeOptions;
        this.showFirstAndLast = noFirstAndLast === null;
        this._showTotal = hideTotal === null;
    }
    Object.defineProperty(PaginatorComponent.prototype, "total", {
        /**
         * The total number of items.
         */
        get: function () {
            return this._total;
        },
        /**
         * Set the total number of available items to paginate through.
         */
        set: function (total) {
            this._total = total;
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(PaginatorComponent.prototype, "cursor", {
        /**
         * Set the current cursor, which should point to the next page of data. When using
         * this method of pagination, the first and last buttons will not be available.
         */
        set: function (cursor) {
            var previousCursor = this.cursorStack.slice(-1).length > 0 ? this.cursorStack.slice(-1)[0] : {};
            var newCursor = tslib_1.__assign({}, previousCursor, cursor);
            this.cursorStack.push(newCursor);
            this.showFirstAndLast = false;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Initialize subscriptions.
     */
    PaginatorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.query$.pipe(takeUntil(this.onDestroy$)).subscribe(function (query) { return _this.query.emit(query); });
        this.query$.next({
            page: this.page,
            pageSize: this.pageSize
        });
        this.route.queryParamMap
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(function (paramMap) { return _this.reset(_this.urlService.parsePageFromParamMap(_this.routeKey, paramMap)); });
    };
    /**
     * Destroy the component.
     */
    PaginatorComponent.prototype.ngOnDestroy = function () {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    };
    /**
     * Jump to the first page.
     */
    PaginatorComponent.prototype.first = function () {
        this.reset();
    };
    /**
     * Jump to the last page.
     */
    PaginatorComponent.prototype.last = function () {
        this.page = Math.ceil(this.total / this.pageSize);
        this.emit();
    };
    /**
     * Go to the next page.
     */
    PaginatorComponent.prototype.next = function () {
        this.emit(++this.page, this.pageSize, this.cursorStack[this.cursorStack.length - 1]);
    };
    /**
     * Go to the previous page.
     */
    PaginatorComponent.prototype.previous = function () {
        this.cursorStack.pop(); // the current cursor points to the next page of data, we don't want that
        this.cursorStack.pop(); // the previous cursor points the current page of data, we don't want that either
        var cursor = this.cursorStack[this.cursorStack.length - 1]; // this one points to the previous page, that's what we want
        this.emit(--this.page, this.pageSize, cursor);
    };
    /**
     * Reset the paginator.
     */
    PaginatorComponent.prototype.reset = function (page) {
        if (page === void 0) { page = 1; }
        this.page = page;
        this.cursorStack.length = 0;
        this.emit();
    };
    /**
     * Emit a new pagination query to the stream.
     */
    PaginatorComponent.prototype.emit = function (page, pageSize, cursor) {
        if (page === void 0) { page = this.page; }
        if (pageSize === void 0) { pageSize = this.pageSize; }
        this.query$.next({ page: page, pageSize: pageSize, cursor: cursor });
        this.urlService.pushPage(this.routeKey, page);
    };
    Object.defineProperty(PaginatorComponent.prototype, "firstVisible", {
        /**
         * Get the index of the first visible item on the page. For example when there are
         * twenty items split onto two pages with a page size of 10, the `firstVisible`
         * of the second page would be 11.
         */
        get: function () {
            if (!this.total)
                return 0;
            return (this.page - 1) * this.pageSize + 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatorComponent.prototype, "lastVisible", {
        /**
         * Get the index of the last visible item on the page. For example when there are
         * twenty items split onto two pages with a page size of 10, the `lastVisible`
         * of the first page would be 10.
         */
        get: function () {
            return Math.min(this.total, this.page * this.pageSize);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatorComponent.prototype, "hasTotal", {
        /**
         * Check if we know the total number of items. When using cursor based pagination,
         * this is often not available. In this case, 0 is not treated as falsey.
         */
        get: function () {
            return this.total !== null && this.total !== undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatorComponent.prototype, "showTotal", {
        /**
         * Check if we should display the total number of items.
         */
        get: function () {
            return this._showTotal && this.hasTotal;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatorComponent.prototype, "hasPrevious", {
        /**
         * Check whether there exists a previous page that we can go to.
         */
        get: function () {
            return this.page > 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatorComponent.prototype, "hasNext", {
        /**
         * Check whether there exists a next page that we can go to.
         */
        get: function () {
            if (this.hasTotal) {
                return this.lastVisible < this.total;
            }
            return this.cursorStack.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    return PaginatorComponent;
}());
export { PaginatorComponent };
