<h5>
    <span [attr.id]="id.header('global-search-display', 'result-title')">{{header}}</span>
    <span [attr.id]="id.text('global-search-display', 'result-total')" class="_badge">{{total}}</span>
</h5>
<div class="results">
    <div *ngFor="let result of results; trackBy: trackById" class="result-item">
        <a [attr.id]="id.control('global-search-display', 'result-name')" class="overflow" [routerLink]="link(result)">{{result.name}}</a>
        <span [attr.id]="id.text('global-search-display', 'result-ref-id')" class="supporting">ID: {{result.refId}}</span>
        <br>
        <span [attr.id]="id.text('global-search-display', 'result-index')" *ngIf="result.index" class="supporting">Type: {{formatIndex(result.index)}}</span>
        <br *ngIf="result.index" >
        <span [attr.id]="id.text('global-search-display', 'external-id')" *ngIf="result.externalId" class="supporting">External ID: {{result.externalId}}<br></span>
        <span [attr.id]="id.text('global-search-display', 'agency-type')" *ngIf="result.agencyName && auth.isInternalUser" class="supporting">{{result.agencyType}}: {{result.agencyName}}</span>
    </div>
</div>
<hr class="space space--small">
