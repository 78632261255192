import { Component, Input } from '@angular/core';
import { InsertionOrder } from './insertion-order';
import { IdService } from 'app/core/id.service';

@Component({
    selector: 'insertion-order-section-card',
    templateUrl: './insertion-order-section-card.html',
    styleUrls: ['./insertion-order-section-card.styl']
})
export class InsertionOrderSectionCardComponent {
    @Input() insertionOrder: InsertionOrder;
    @Input() advertiser: any;

    constructor(
        public id: IdService
    ) { }
}
