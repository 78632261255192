export enum Operation {
    Contains = 'co',
    DoesNotContain = 'nco',
    Equals = 'eq',
    DoesNotEqual = 'ne',
    GreaterThan = 'gt',
    LessThan = 'lt',
    GreaterThanOrEqualTo = 'gteq',
    LessThanOrEqualTo = 'lteq',
    Exists = 'ex',
    DoesNotExist = 'nex',

    And = 'and',
    Or = 'or'
}

export const OperationLabel = {
    'co': 'contains',
    'nco': 'does not contain',
    'eq': 'is',
    'ne': 'is not',
    'gt': 'is greater than',
    'lt': 'is less than',
    'lteq': 'is less than or equal to',
    'gteq': 'is greater than or equal to',
    'ex': 'exists',
    'nex': 'does not exist'
};
