import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class HostInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const parser = document.createElement('a');
        parser.href = req.url;

        const list = [
            environment.backend.hostname,
            environment.backend.heimdall.hostname,
            environment.backend.auth.hostname,
            environment.backend.privacyPortal.hostname
        ];

        // Do not execute request if hostname is not in the allowlist.
        if (list.filter(host => host === (parser.protocol + '//' + parser.host))
            .length === 0) {
            return throwError(new Error('Hostname not allowed'));
        }

        return next.handle(req);
    }

}
