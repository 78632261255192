import * as tslib_1 from "tslib";
import { OnInit, AfterViewInit } from '@angular/core';
import { Chart } from '../chart';
var TimeseriesChartComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TimeseriesChartComponent, _super);
    function TimeseriesChartComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.aggregate = null;
        _this.height = 280;
        _this.c3Config = {};
        _this.called = false;
        _this.metricMapping = {
            Clicks: 'Integer',
            Impressions: 'Integer',
            'Impressions (a)': 'Integer',
            PCC: 'Integer',
            Spend: 'Currency',
            'Advertiser Spend': 'Currency',
            CTR: 'Percent',
            'CTR (a)': 'Percent',
            CPC: 'Currency',
            CPA: 'Currency',
            eCPA: 'Currency',
            eCPC: 'Currency',
            'Win Rate': 'Percent'
        };
        _this.formatMapping = {
            Currency: '$,.2f',
            Number: ',.2r',
            Percent: ',.2%',
            Integer: ','
        };
        return _this;
    }
    TimeseriesChartComponent.prototype.ngOnInit = function () {
        this.drawGraph();
    };
    TimeseriesChartComponent.prototype.drawGraph = function () {
        var _a, _b;
        var _this = this;
        if (this.data && this.data[0].length > 1) {
            this.called = true;
            var y = this.data[0][0];
            var y2 = this.data[1][0];
            this.data.unshift(this.dates);
            this.chart = this.generate(Object.assign({
                size: {
                    height: this.height
                },
                point: {
                    show: true,
                    r: 0,
                    focus: {
                        expand: {
                            enabled: true,
                            r: 4
                        }
                    }
                },
                axis: {
                    x: {
                        type: 'timeseries',
                        tick: {
                            format: '%b %e'
                        }
                    },
                    y: {
                        show: true,
                        tick: {
                            format: function (value) {
                                var yMetric = _this.data[0][0].toString().trim();
                                return _this.d3.format(_this.formatMapping[_this.metricMapping[yMetric]])(value);
                            },
                            outer: false
                        }
                    },
                    y2: {
                        show: true,
                        tick: {
                            format: function (value) {
                                var y2Metric = _this.data[1][0].toString().trim();
                                return _this.d3.format(_this.formatMapping[_this.metricMapping[y2Metric]])(value);
                            },
                            outer: false
                        }
                    }
                },
                grid: {
                    y: {
                        show: true
                    }
                },
                legend: {
                    show: false
                },
                tooltip: {
                    format: {
                        title: this.d3.timeFormat('%b %e, %Y'),
                        value: function (value, ratio, id, index) {
                            var metric = id.toString().trim();
                            if (_this.metricMapping[metric]) {
                                return _this.d3.format(_this.formatMapping[_this.metricMapping[metric]])(value);
                            }
                            else {
                                return _this.d3.format(_this.formatMapping.Number)(value);
                            }
                        }
                    }
                }
            }, this.c3Config), {
                x: 'dates',
                axes: (_a = {
                        dates: 'x'
                    },
                    _a[y] = 'y',
                    _a[y2] = 'y2',
                    _a),
                colors: (_b = {},
                    _b[y] = '#14abf9',
                    _b[y2] = '#9013fe',
                    _b)
            });
        }
    };
    TimeseriesChartComponent.prototype.ngAfterViewInit = function () {
        _super.prototype.ngAfterViewInit.call(this);
        this.buildLegend();
    };
    TimeseriesChartComponent.prototype.buildLegend = function () {
        var select = this.d3.select;
        var color = this.chart.color;
        var chart = this.chart;
        var items = select(this.element.nativeElement)
            .selectAll('.chart-legend-item')
            .data(this.chart.data());
        items.insert('div', '.chart-legend-select').attr('class', 'chart-legend-circle')
            .each(function (id) { select(this).style('border-color', color(id)); })
            .on('mouseover', function (data) {
            chart.focus(data.id);
            select(this).style('box-shadow', '0 0 0 1px ' + color(data.id));
        })
            .on('mouseout', function () {
            chart.revert();
            select(this).style('box-shadow', null);
        });
    };
    TimeseriesChartComponent.prototype.rebuildLegend = function () {
        this.d3.select(this.element.nativeElement)
            .selectAll('.chart-legend-circle').remove();
        this.buildLegend();
    };
    return TimeseriesChartComponent;
}(Chart));
export { TimeseriesChartComponent };
