import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Metro } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
var MetroRepository = /** @class */ (function (_super) {
    tslib_1.__extends(MetroRepository, _super);
    function MetroRepository(http) {
        return _super.call(this, http, '/metro', Metro) || this;
    }
    return MetroRepository;
}(BackendRepository));
export { MetroRepository };
