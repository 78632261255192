import { Attribute, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PaginatorQuery } from './paginator-query';
import { PageSizeOptions } from './page-size-options';
import { TableURLService } from 'app/shared/elements/table';

@Component({
    selector: 'paginator',
    templateUrl: './paginator.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginatorComponent implements OnInit, OnDestroy {
    /**
     * Emits when the pagination has been changed.
     */
    @Output() query = new EventEmitter<PaginatorQuery>();

    /**
     * Set the total number of available items to paginate through.
     */
    @Input() set total(total: number) {
        this._total = total;
    };

    /**
     * Set the current cursor, which should point to the next page of data. When using
     * this method of pagination, the first and last buttons will not be available.
     */
    @Input() set cursor(cursor: any) {
        const previousCursor = this.cursorStack.slice(-1).length > 0 ? this.cursorStack.slice(-1)[0] : {};
        const newCursor = {...previousCursor, ...cursor};
        this.cursorStack.push(newCursor);
        this.showFirstAndLast = false;
    }

    /**
     * The current number of rows visible at a time.
     */
    pageSize = 10;

    /**
     * Whether to show the first and last buttons.
     */
    showFirstAndLast = true;

    /**
     * The current page.
     */
    private page = 1;

    /**
     * The total number of items.
     */
    private _total: number;

    /**
     * Whether the total should be displayed.
     */
    private _showTotal: boolean;

    /**
     * A stack which holds the current cursor, as well as all previously used cursors.
     */
    private readonly cursorStack = [];

    /**
     * We set a debounce so we don't fire off too many queries at once. For example if
     * a user clicks the `Next` button repeatedly, we'll wait until the clicks stop
     * coming before finally emitting the desired page of the user.
     */
    private readonly debounceTime = 200;

    /**
     * A stream of queries.
     */
    private readonly query$ = new Subject<PaginatorQuery>();

    /**
     * A stream used to signal the destruction of this component.
     */
    private readonly onDestroy$ = new Subject<void>();

    /**
     * The available page size options.
     */
    readonly pageSizeOptions = PageSizeOptions;

    /**
     * Create a new paginator.
     */
    constructor(
        private route: ActivatedRoute,
        private urlService: TableURLService,
        @Attribute('route-key') private routeKey: string,
        @Attribute('noFirstAndLast') noFirstAndLast: boolean,
        @Attribute('hideTotal') hideTotal: boolean
    ) {
        this.showFirstAndLast = noFirstAndLast === null;
        this._showTotal = hideTotal === null;
    }

    /**
     * Initialize subscriptions.
     */
    ngOnInit() {
        this.query$.pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(query => this.query.emit(query));

        this.query$.next({
            page: this.page,
            pageSize: this.pageSize
        });

        this.route.queryParamMap
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(paramMap => this.reset(
                this.urlService.parsePageFromParamMap(this.routeKey, paramMap)
            ));
    }

    /**
     * Destroy the component.
     */
    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Jump to the first page.
     */
    first() {
        this.reset();
    }

    /**
     * Jump to the last page.
     */
    last() {
        this.page = Math.ceil(this.total / this.pageSize);

        this.emit();
    }

    /**
     * Go to the next page.
     */
    next() {
        this.emit(
            ++this.page, this.pageSize, this.cursorStack[this.cursorStack.length - 1]
        );
    }

    /**
     * Go to the previous page.
     */
    previous() {
        this.cursorStack.pop(); // the current cursor points to the next page of data, we don't want that
        this.cursorStack.pop(); // the previous cursor points the current page of data, we don't want that either
        const cursor = this.cursorStack[this.cursorStack.length - 1]; // this one points to the previous page, that's what we want

        this.emit(--this.page, this.pageSize, cursor);
    }

    /**
     * Reset the paginator.
     */
    reset(page = 1) {
        this.page = page;
        this.cursorStack.length = 0;

        this.emit();
    }

    /**
     * Emit a new pagination query to the stream.
     */
    private emit(page = this.page, pageSize = this.pageSize, cursor?: any) {
        this.query$.next({ page, pageSize, cursor });

        this.urlService.pushPage(this.routeKey, page);
    }

    /**
     * Get the index of the first visible item on the page. For example when there are
     * twenty items split onto two pages with a page size of 10, the `firstVisible`
     * of the second page would be 11.
     */
    get firstVisible() {
        if (!this.total) return 0;

        return (this.page - 1) * this.pageSize + 1;
    }

    /**
     * Get the index of the last visible item on the page. For example when there are
     * twenty items split onto two pages with a page size of 10, the `lastVisible`
     * of the first page would be 10.
     */
    get lastVisible() {
        return Math.min(this.total, this.page * this.pageSize);
    }

    /**
     * The total number of items.
     */
    get total() {
        return this._total;
    }

    /**
     * Check if we know the total number of items. When using cursor based pagination,
     * this is often not available. In this case, 0 is not treated as falsey.
     */
    get hasTotal() {
        return this.total !== null && this.total !== undefined;
    }

    /**
     * Check if we should display the total number of items.
     */
    get showTotal() {
        return this._showTotal && this.hasTotal;
    }

    /**
     * Check whether there exists a previous page that we can go to.
     */
    get hasPrevious() {
        return this.page > 1;
    }

    /**
     * Check whether there exists a next page that we can go to.
     */
    get hasNext() {
        if (this.hasTotal) {
            return this.lastVisible < this.total;
        }

        return this.cursorStack.length > 0;
    }
}
