import { Component } from '@angular/core';

@Component({
    selector: 'global-navigation',
    templateUrl: './global-navigation.html',
    styleUrls: ['./global-navigation.styl']
})
export class GlobalNavigationComponent {

}
