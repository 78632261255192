import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalBlocklist } from 'app/shared/models';
import { BackendRepository, Query } from './backend-repository';
import { map } from 'rxjs/operators';
import { Option } from 'app/shared/elements/dropdown';

@Injectable()
export class GlobalBlocklistRepository extends BackendRepository<GlobalBlocklist> {
    public constructor(http: HttpClient) {
        super(http, '/flexible-list', GlobalBlocklist);
    }

    public searchAsOptions(query: Query = {}): Observable<Option[]> {
        return this.all(query).pipe(map(blocklists => {
            return blocklists.map(
                option => {
                    return {
                        key: option.id,
                        value: option.id,
                        label: ['(' + option.id + ')', option.name].join(' '),
                        data: option
                    };
                });
        }));
    }

    public convertGlobalBlocklistToOptions(blocklists: Observable<GlobalBlocklist[]>): Observable<Option[]> {
        return blocklists.pipe(map(blockList => {
            return blockList.map(option => {
                return {
                    key: option.id,
                    value: option.id,
                    label: ['(' + option.id + ')', option.name].join(' '),
                    data: option
                };
            });
        }));
    }
}
