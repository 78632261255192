import { ElementRef } from '@angular/core';
var OptionComponent = /** @class */ (function () {
    function OptionComponent(element) {
        this.element = element;
        this.value = null;
    }
    Object.defineProperty(OptionComponent.prototype, "html", {
        get: function () {
            return this.element.nativeElement.innerHTML;
        },
        enumerable: true,
        configurable: true
    });
    return OptionComponent;
}());
export { OptionComponent };
