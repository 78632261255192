import * as tslib_1 from "tslib";
import { Model } from '../model';
export var SupplyImageAssetType;
(function (SupplyImageAssetType) {
    SupplyImageAssetType[SupplyImageAssetType["Icon"] = 1] = "Icon";
    SupplyImageAssetType[SupplyImageAssetType["Main"] = 3] = "Main";
})(SupplyImageAssetType || (SupplyImageAssetType = {}));
var ɵ0 = SupplyImageAssetType.Icon, ɵ1 = SupplyImageAssetType.Main;
export var SupplyImageAssets = [{
        id: ɵ0,
        key: 'logoImage'
    }, {
        id: ɵ1,
        key: 'mainImage'
    }];
var SupplyImageAsset = /** @class */ (function (_super) {
    tslib_1.__extends(SupplyImageAsset, _super);
    function SupplyImageAsset(data) {
        if (data === void 0) { data = {}; }
        return _super.call(this, data) || this;
    }
    SupplyImageAsset.prototype.serialize = function () {
        var imageAsset = this.clone(SupplyImageAsset);
        delete imageAsset._isClone;
        if (!imageAsset.version) {
            delete imageAsset.version;
        }
        if (!imageAsset.refId) {
            delete imageAsset.refId;
        }
        if (!imageAsset.height) {
            delete imageAsset.height;
        }
        if (!imageAsset.width) {
            delete imageAsset.width;
        }
        if (!imageAsset.minWidth) {
            delete imageAsset.minWidth;
        }
        if (!imageAsset.minHeight) {
            delete imageAsset.minHeight;
        }
        return JSON.parse(JSON.stringify(imageAsset));
    };
    Object.defineProperty(SupplyImageAsset.prototype, "entity", {
        get: function () {
            return 'Native Supply Image Asset';
        },
        enumerable: true,
        configurable: true
    });
    return SupplyImageAsset;
}(Model));
export { SupplyImageAsset };
export { ɵ0, ɵ1 };
