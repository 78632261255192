import { TableService } from './table.service';
import { TableColumnDirective } from './table-column.directive';
var TableSortDirective = /** @class */ (function () {
    function TableSortDirective(tableService, column, defaultColumn, sortAscending, sortDescending, noDefaultSortColumn) {
        this.tableService = tableService;
        this.column = column;
        if (sortDescending !== null) {
            this.tableService.setSortDirection('desc');
        }
        if (sortAscending !== null) {
            this.tableService.setSortDirection('asc');
        }
        if (defaultColumn !== null) {
            this.tableService.setColumn(this.column.name);
        }
        else if (noDefaultSortColumn != null) {
            this.tableService.setColumn(undefined);
            this.tableService.setSortDirection(undefined);
        }
    }
    TableSortDirective.prototype.toggleSort = function () {
        this.tableService.sortByColumn(this.column.name);
    };
    Object.defineProperty(TableSortDirective.prototype, "classDesc", {
        get: function () {
            return this.tableService.getColumn() === this.column.name && this.tableService.getSortDirection() === 'desc';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableSortDirective.prototype, "classAsc", {
        get: function () {
            return this.tableService.getColumn() === this.column.name && this.tableService.getSortDirection() === 'asc';
        },
        enumerable: true,
        configurable: true
    });
    return TableSortDirective;
}());
export { TableSortDirective };
