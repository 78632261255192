import { ChangeDetectorRef, EventEmitter, TemplateRef } from '@angular/core';
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ControlValueAccessor } from '@angular/forms';
/**
 * @title Drag&Drop sorting
 */
var DraggableListComponent = /** @class */ (function () {
    function DraggableListComponent(cdr) {
        this.cdr = cdr;
        this.itemtpl = null;
        this.onRemove = new EventEmitter();
        this.onchange = function (_) { };
    }
    DraggableListComponent.prototype.writeValue = function (value) {
        if (Array.isArray(value)) {
            this.items = value;
            this.cdr.markForCheck();
        }
    };
    Object.defineProperty(DraggableListComponent.prototype, "isLocked", {
        get: function () {
            return this.section === 'lock';
        },
        enumerable: true,
        configurable: true
    });
    DraggableListComponent.prototype.registerOnChange = function (fn) {
        this.onchange = fn;
    };
    DraggableListComponent.prototype.registerOnTouched = function () { };
    DraggableListComponent.prototype.remove = function (i) {
        var removed = this.items.splice(i, 1);
        this.onchange(this.items);
        this.onRemove.emit(removed);
    };
    DraggableListComponent.prototype.drop = function (event) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        }
        else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
    };
    return DraggableListComponent;
}());
export { DraggableListComponent };
