import * as tslib_1 from "tslib";
import { Model } from './model';
import { DisplayUtils } from 'app/shared/helpers/display-utils';
export var AudiencePriceTier;
(function (AudiencePriceTier) {
    AudiencePriceTier[AudiencePriceTier["Tier_1"] = 1] = "Tier_1";
    AudiencePriceTier[AudiencePriceTier["Tier_2"] = 2] = "Tier_2";
    AudiencePriceTier[AudiencePriceTier["Tier_3"] = 3] = "Tier_3";
    AudiencePriceTier[AudiencePriceTier["Tier_4"] = 4] = "Tier_4";
})(AudiencePriceTier || (AudiencePriceTier = {}));
export var AdvertiserTier;
(function (AdvertiserTier) {
    AdvertiserTier["Major"] = "major_account";
    AdvertiserTier["Mid_Market"] = "mid_market_account";
    AdvertiserTier["Other"] = "other_account";
})(AdvertiserTier || (AdvertiserTier = {}));
var Advertiser = /** @class */ (function (_super) {
    tslib_1.__extends(Advertiser, _super);
    function Advertiser(attributes) {
        if (attributes === void 0) { attributes = {}; }
        var _this = _super.call(this) || this;
        Object.assign(_this, {
            owner: {}
        }, attributes);
        return _this;
    }
    Advertiser.prototype.serialize = function () {
        var advertiser = this.clone(Advertiser);
        delete advertiser.owner.refId;
        // LSD requires a description but doesn't use it.
        advertiser.description = 'Default description, courtesy of Maverick.';
        if (typeof advertiser.externalId === 'string') {
            if (advertiser.externalId.length < 1) {
                advertiser.externalId = null;
            }
        }
        if (!advertiser.salesforceId) {
            advertiser.salesforceId = null;
        }
        return JSON.stringify(advertiser);
    };
    Object.defineProperty(Advertiser.prototype, "managedBy", {
        get: function () {
            if (typeof this.agencyName === 'string') {
                return this.agencyName;
            }
            return this.mediaGroupName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Advertiser.prototype, "managerName", {
        get: function () {
            return DisplayUtils.displayName(this.managerFirstName, this.managerLastName);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Advertiser.prototype, "executiveName", {
        get: function () {
            return DisplayUtils.displayName(this.executiveFirstName, this.executiveLastName);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Advertiser.prototype, "entity", {
        get: function () {
            return 'Advertiser';
        },
        enumerable: true,
        configurable: true
    });
    return Advertiser;
}(Model));
export { Advertiser };
