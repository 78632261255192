import { EventEmitter, OnInit } from '@angular/core';
import { TimeseriesChartComponent } from 'app/shared/elements/charts/timeseries-chart/timeseries-chart.component';
var MetricComparisonComponent = /** @class */ (function () {
    function MetricComparisonComponent() {
        this._dataCache = [];
        this.rightIndex = new EventEmitter();
        this.leftIndex = new EventEmitter();
        this._left = 7;
        this._right = 8;
        this.filterBy = 1;
    }
    Object.defineProperty(MetricComparisonComponent.prototype, "data", {
        get: function () {
            this._dataCache.length = 0;
            this._dataCache.unshift(this._data[this._left], this._data[this._right]);
            return this._dataCache;
        },
        set: function (value) {
            this._data = value;
            this.reloadGraph();
        },
        enumerable: true,
        configurable: true
    });
    MetricComparisonComponent.prototype.ngOnInit = function () {
        this.createOptions();
    };
    MetricComparisonComponent.prototype.createOptions = function () {
        if (this.data && this.data[0].length > 1) {
            this.options = this._data.map(function (data, index) {
                return { key: index, value: index, label: data[0] };
            });
        }
    };
    Object.defineProperty(MetricComparisonComponent.prototype, "left", {
        get: function () {
            return this._left;
        },
        set: function (data) {
            this._left = data;
            this.leftIndex.emit(data);
            this.reloadGraph();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MetricComparisonComponent.prototype, "right", {
        get: function () {
            return this._right;
        },
        set: function (data) {
            this._right = data;
            this.rightIndex.emit(data);
            this.reloadGraph();
        },
        enumerable: true,
        configurable: true
    });
    MetricComparisonComponent.prototype.setData = function (data, suffix) {
        var label = data[0];
        return [label + suffix].concat(data.slice(1, data.length));
    };
    MetricComparisonComponent.prototype.reloadGraph = function () {
        var _a, _b;
        var _this = this;
        if (this.timeseries.chart === null) {
            return;
        }
        if (this.timeseries.called) {
            var leftData = this.setData(this.data[0], '');
            var rightData = this.setData(this.data[1], ' ');
            this.timeseries.chart.load({
                columns: [this.dates, leftData, rightData],
                x: 'dates',
                axes: (_a = {},
                    _a[leftData[0]] = 'y',
                    _a[rightData[0]] = 'y2',
                    _a),
                colors: (_b = {},
                    _b[leftData[0]] = '#14abf9',
                    _b[rightData[0]] = '#9013fe',
                    _b),
                unload: true,
                done: function () { return _this.timeseries.rebuildLegend(); }
            });
        }
    };
    return MetricComparisonComponent;
}());
export { MetricComparisonComponent };
