import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Deal } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { map } from 'rxjs/operators';

@Injectable()
export class DealRepository extends BackendRepository<Deal> {
    public constructor(http: HttpClient) {
        super(http, '/deal', Deal);
    }

    public save(deal: Deal): Observable<Deal> {
        const params = [this.path];
        if (deal.id) {
            params.push(deal.id.toString());
        }
        return this.http.post(this.url(...params), deal.serialize())
            .pipe(map(response => this.build(response['output'])));
    }
}
