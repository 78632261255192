import {
    Component,
    forwardRef,
    ContentChildren,
    QueryList
} from '@angular/core';

import {
    NG_VALUE_ACCESSOR,
    ControlValueAccessor
} from '@angular/forms';

import { OptionComponent } from '../filter-select/option.component';
import { IdService } from 'app/core/id.service';
@Component({
    selector: 'select-control',
    templateUrl: './select-control.html',
    styleUrls: ['./select-control.styl'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectControlComponent),
            multi: true
        }
    ]
})
export class SelectControlComponent implements ControlValueAccessor {
    @ContentChildren(OptionComponent) options: QueryList<OptionComponent> = null;

    private onchange = (_: any[]) => { };

    private _active: number = null;

    public writeValue(value: any): void {
        if (value !== null) {
            this._active = this.options.toArray().findIndex(element => element.value === value);
        }
    }

    constructor(
        public id: IdService
    ) { }

    registerOnChange(fn: (_: any[]) => {}) {
        this.onchange = fn;
    }

    select(value) {
        this.onchange(value);
    }

    public registerOnTouched(fn: any): void {
    }

    active(index: number): boolean {
        return this._active === index;
    }
}
