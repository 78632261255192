import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { publishLast, refCount, map } from 'rxjs/operators';
import { ISP } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
var ISPRepository = /** @class */ (function (_super) {
    tslib_1.__extends(ISPRepository, _super);
    function ISPRepository(http) {
        var _this = _super.call(this, http, '/isp', ISP) || this;
        // TODO implement based on the query params
        _this._allResult = null;
        return _this;
    }
    ISPRepository.prototype.all = function (query) {
        if (query === void 0) { query = {}; }
        if (this._allResult) {
            return this._allResult;
        }
        this._allResult = _super.prototype.all.call(this, query).pipe(publishLast(), refCount());
        return this._allResult;
    };
    ISPRepository.prototype.searchAsOptions = function (query) {
        if (query === void 0) { query = {}; }
        return this.all(query).pipe(map(function (isps) {
            return isps.map(function (option) {
                return {
                    key: option.id,
                    value: option.id,
                    label: option.name
                };
            });
        }));
    };
    return ISPRepository;
}(BackendRepository));
export { ISPRepository };
