import * as tslib_1 from "tslib";
import { Model } from './model';
var ReportingQuery = /** @class */ (function (_super) {
    tslib_1.__extends(ReportingQuery, _super);
    function ReportingQuery(from) {
        return _super.call(this, from) || this;
    }
    ReportingQuery.prototype.serialize = function () {
        var query = this.clone(ReportingQuery);
        Object.keys(query.data.attributes).forEach(function (attribute) {
            var allowed = ['query', 'meta', 'type', 'queryName', 'storage', 'columnOrder'];
            if (allowed.indexOf(attribute) === -1 || !query.data.attributes[attribute]) {
                delete query.data.attributes[attribute];
            }
        });
        return JSON.stringify(query.data.attributes);
    };
    Object.defineProperty(ReportingQuery.prototype, "entity", {
        get: function () {
            return 'Reporting Query';
        },
        enumerable: true,
        configurable: true
    });
    return ReportingQuery;
}(Model));
export { ReportingQuery };
