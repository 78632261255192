import { Injectable, InjectionToken, Optional, Inject } from '@angular/core';

export const ID_PREFIX = new InjectionToken<string>('IdPrefix');

@Injectable({
    providedIn: 'root'
})
export class IdService {

    constructor(@Optional() @Inject(ID_PREFIX) private prefix: string = null) { }

    container(...path: string[]) {
        return this.generate(...path.concat('container'));
    }

    control(...path: string[]) {
        return this.generate(...path.concat('control'));
    }

    message(...path: string[]) {
        return this.generate(...path.concat('message'));
    }

    header(...path: string[]) {
        return this.generate(...path.concat('header'));
    }

    form(...path: string[]) {
        return this.generate(...path.concat('form'));   
    }

    text(...path: string[]) {
        return this.generate(...path.concat('text'));
    }

    private generate(...path: string[]) {
        if (this.prefix) {
            path.unshift(this.prefix);
        }

        return path.join('.');
    }

}
