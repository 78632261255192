import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'yieldrank'
})
export class YieldManagementRankPipe implements PipeTransform {
    transform(rank: number) {
        switch (rank) {
            case 1:
                return 'Small';
            case 2:
                return 'Medium';
            case 3:
                return 'Large';
        }
    }
}
