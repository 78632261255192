import { Component, ComponentFactoryResolver, ApplicationRef, Injector, AfterViewInit, OnDestroy, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CdkPortal, DomPortalOutlet } from '@angular/cdk/portal';

@Component({
  selector: 'app-portal',
  template: `
    <ng-container *cdkPortal>
      <ng-content></ng-content>
    </ng-container>
  `
})
export class PortalComponent implements AfterViewInit, OnDestroy, OnChanges {
  @ViewChild(CdkPortal, { static: false })
  private portal: CdkPortal;

  private host: DomPortalOutlet;

  private outletElement: HTMLElement;

  @Input() top = 0;
  @Input() left = 0;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector
  ) { }

  ngAfterViewInit() {
    this.outletElement = document.createElement('div');
    this.outletElement.style.position = 'absolute';

    document.body.appendChild(this.outletElement);

    this.host = new DomPortalOutlet(
      this.outletElement,
      this.componentFactoryResolver,
      this.applicationRef,
      this.injector
    );

    this.host.attach(this.portal);
  }

  ngOnDestroy() {
    this.host.detach();
    this.outletElement.remove();
  }

  ngOnChanges({ left, top }: SimpleChanges) {
    if (this.outletElement == null) {
      return;
    }

    this.outletElement.style.top = `${top.currentValue}px`;
    this.outletElement.style.left = `${left.currentValue}px`;
  }
}
