import { Component, OnInit } from '@angular/core';

import { ThemeService, BackgroundStyle } from 'app/shared/helpers/theme.service';
import { AuthorizationService, AuthenticationService } from 'app/core';

@Component({
    templateUrl: './rate-limit-exceeded.component.html'
})
export class RateLimitExceededComponent implements OnInit {
    constructor(
        private themeService: ThemeService,
        public authorization: AuthorizationService,
        public authentication: AuthenticationService
    ) {  }

    ngOnInit() {
        this.themeService.setBackgroundStyle(BackgroundStyle.GreyStyle);
    }
}
