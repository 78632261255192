import {Component, Input, ViewChild, ContentChild, TemplateRef, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import moment from 'moment';
import { AuthorizationService } from 'app/core';
import { DeleteNewsletterDialogComponent } from 'app/shared/components/delete-newsletter-dialog';
import { IdService } from 'app/core/id.service';
import { InventoryDataService } from 'app/shared/services/inventory-data.service';
import { Newsletter } from 'app/shared/models/newsletter';
import { NewsletterFormComponent } from 'app/shared/components/newsletter-form';
import { NewsletterRepository } from 'app/core/repositories/newsletter-repository.service';
import { NewsletterTagsComponent } from 'app/shared/components/newsletter-tags';
import { PaginatorComponent } from 'app/shared/elements/paginator';
import { SearchParams } from 'app/shared/helpers/query-builder';
import { TableHelper } from 'app/shared/helpers/table-helper';
import { TableComponent } from 'app/shared/elements/table';
import { CategoryRepository } from 'app/core/repositories';
import {NewsletterBulkEditComponent} from "app/shared/components/newsletter-bulk-edit";
import {Flag, LaunchDarklyService} from "app/core/launch-darkly.service";

enum NewslettersTableRowAction {
    Edit,
    ViewCode,
    Delete,
    BulkEdit,
    BulkEditName,
    BulkEditCategory,
    BulkEditESP,
    BulkEditMisc,
    BulkEditPrivateDeals,
    BulkEditAllowCoordination,
    BulkEditRequireUniqueAds,
}

const newslettersTableRowActions = [
    {
        key: 0,
        value: NewslettersTableRowAction.Edit,
        label: 'Edit'
    },
    {
        key: 1,
        value: NewslettersTableRowAction.ViewCode,
        label: 'View Code'
    },
    {
        key: 2,
        value: NewslettersTableRowAction.Delete,
        label: 'Delete'
    }
];

@Component({
    selector: 'newsletters-table',
    templateUrl: './newsletters-table.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewslettersTableComponent implements OnInit {
    @ViewChild(TableComponent, { static: true }) table: TableComponent;
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent;

    @ViewChild(DeleteNewsletterDialogComponent, { static: true }) deleteNewsletterDialog: DeleteNewsletterDialogComponent;
    @ViewChild(NewsletterFormComponent, { static: true }) newsletterForm: NewsletterFormComponent;
    @ViewChild(NewsletterTagsComponent, { static: true }) viewCode: NewsletterTagsComponent;
    @ViewChild(NewsletterBulkEditComponent, { static: true }) bulkEditor: NewsletterBulkEditComponent;

    @ContentChild('actions', { static: true }) actionsTemplate: TemplateRef<any>;

    mediaGroup: string;
    helper = new TableHelper<Newsletter>(params => this.adapter(params));
    NewsletterAction = NewslettersTableRowAction;
    rowActions = newslettersTableRowActions;
    primaryIABCategories$: Observable<{string: string}>;
    maxDate: moment.Moment = moment();

    protected canSetCoordination$ = new BehaviorSubject(false);
    public canSetCoordination: boolean = false;

    constructor(
        private newsletterRepository: NewsletterRepository,
        public inventoryData: InventoryDataService,
        public id: IdService,
        private categoryRepository: CategoryRepository,
        private launchDarklyService: LaunchDarklyService,
        public authorizationService: AuthorizationService
    ) { }

    ngOnInit() {
        this.helper.table = this.table;
        this.helper.paginator = this.paginator;
        combineLatest(this.table.query, this.paginator.query).subscribe(args => this.helper.search(args));

        this.primaryIABCategories$ = this.primaryCategories();

        this.launchDarklyService
            .getVariation(Flag.AllowCoordination)
            .pipe(distinctUntilChanged())
            .subscribe(canSetCoordination => {
                this.canSetCoordination$.next(canSetCoordination);
            });

        this.canSetCoordination$.subscribe(value => {
            this.canSetCoordination = value;
        });
    }

    private adapter(params: SearchParams) {
        if (typeof this.mediaGroup === 'string') {
            params.conditions.push({ field: 'mediaGroup', value: this.mediaGroup });
        }

        params.return = ['publisherRefId', 'lastSeen', 'categoryName'];
        params.returnMode = 'appended';
        return this.newsletterRepository.asyncSearch(params);
    }

    @Input('media-group') set setMediaGroup(id: string) {
        this.mediaGroup = id;
        this.paginator.reset();
    }

    actionHandler(action: NewslettersTableRowAction, newsletters: Newsletter[]) {
        switch (action) {
            case NewslettersTableRowAction.Delete:
                this.deleteNewsletterDialog.open(newsletters[0]);
                break;
            case NewslettersTableRowAction.ViewCode:
                this.viewCode.openModal(newsletters[0]);
                break;
            case NewslettersTableRowAction.Edit:
                this.newsletterForm.openForEdit(newsletters[0]);
                break;
            case NewslettersTableRowAction.BulkEdit:
                this.bulkEditor.open(newsletters);
                break;
            case NewslettersTableRowAction.BulkEditName:
                this.bulkEditor.open(newsletters, 'name');
                break;
            case NewslettersTableRowAction.BulkEditCategory:
                this.bulkEditor.open(newsletters, 'category');
                break;
            case NewslettersTableRowAction.BulkEditESP:
                this.bulkEditor.open(newsletters, 'esp');
                break;
            case NewslettersTableRowAction.BulkEditPrivateDeals:
                this.bulkEditor.open(newsletters, 'privateDeals');
                break;
            case NewslettersTableRowAction.BulkEditAllowCoordination:
                this.bulkEditor.open(newsletters, 'allowCoordination');
                break;
            case NewslettersTableRowAction.BulkEditRequireUniqueAds:
                this.bulkEditor.open(newsletters, 'sspControl.uniqueAds');
                break;
        }
    }

    private buildPrimaryCategories(categories) {
        return categories.reduce((primaryCategory, currentCategory) => {
            primaryCategory[currentCategory] = currentCategory;
            return primaryCategory;
        }, {});
    }

    private primaryCategories(): Observable<{string: string}> {
        return this.categoryRepository.search().pipe(
            map(categories => this.categoryRepository.filterOutSecondary(categories).map(category => category.name)),
            map(categories => this.buildPrimaryCategories(categories))
        );
    }


    isGreaterThan(date: Date, days: number) {
        return moment().diff(date, 'days') > days;
    }

    refresh() {
        this.paginator.reset();
        this.table.clearSelections();
    }

    canModify(newsletters: Newsletter[]): boolean {
        return newsletters.every(newsletter => this.authorizationService.canModifyMonetizationPublisher(newsletter.publisherClientTypes));
    }

    getRowActions(newsletter: Newsletter): {}[] {
        if (this.authorizationService.canModifyMonetizationPublisher(newsletter.publisherClientTypes)) {
            return this.rowActions
        }

        return this.rowActions.filter(action => action.value === NewslettersTableRowAction.ViewCode);
    }

    protected readonly NewslettersTableRowAction = NewslettersTableRowAction;
}
