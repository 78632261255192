import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, ReplaySubject, BehaviorSubject, combineLatest, of } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { NotificationsService } from 'app/core';
import { Formatter } from 'app/shared/components/history-view/formatter';
import { AdSlotRepository, CampaignRepository, HistoryEntity, LIESRepository, LineItemRepository, NewsletterRepository } from 'app/core/repositories';
var HistoryComponent = /** @class */ (function () {
    function HistoryComponent(router, notifications, liesRepository, campaignRepository, lineItemRepository, newsletterRepository, adSlotRepository, route, formatter) {
        this.router = router;
        this.notifications = notifications;
        this.liesRepository = liesRepository;
        this.campaignRepository = campaignRepository;
        this.lineItemRepository = lineItemRepository;
        this.newsletterRepository = newsletterRepository;
        this.adSlotRepository = adSlotRepository;
        this.route = route;
        this.formatter = formatter;
        this.entityOptions = [
            {
                key: 0,
                value: HistoryEntity.Campaign,
                label: 'Campaign ID'
            },
            {
                key: 1,
                value: HistoryEntity.LineItem,
                label: 'Line Item ID'
            },
            {
                key: 2,
                value: HistoryEntity.Newsletter,
                label: 'Newsletter ID'
            },
            {
                key: 3,
                value: HistoryEntity.AdSlot,
                label: 'Ad Slot ID'
            }
        ];
        this.activeSearchEntity = HistoryEntity.Campaign;
        this.loading$ = new BehaviorSubject(false);
        this.history$ = new ReplaySubject(1);
    }
    HistoryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.routeUpdate = combineLatest(this.route.paramMap, this.route.queryParamMap).pipe(switchMap(function (_a) {
            var params = _a[0], query = _a[1];
            if (!(params.has('type') && params.has('id'))) {
                return of(null);
            }
            _this.activeSearchEntity = params.get('type');
            _this.loading$.next(true);
            var page = parseInt(query.get('page')) || 1;
            var modified = query.get('modified') || undefined;
            return new Observable(function (observer) {
                switch (_this.activeSearchEntity) {
                    case HistoryEntity.Campaign:
                        _this.campaignRepository.get(params.get('id')).pipe(mergeMap(function (campaign) { return _this.liesRepository.history(campaign.id, _this.activeSearchEntity, page, modified); })).subscribe(observer);
                        break;
                    case HistoryEntity.LineItem:
                        _this.lineItemRepository.get(params.get('id')).pipe(mergeMap(function (lineItem) { return _this.liesRepository.history(lineItem.id, _this.activeSearchEntity, page, modified); })).subscribe(observer);
                        break;
                    case HistoryEntity.Newsletter:
                        _this.newsletterRepository.get(params.get('id')).pipe(mergeMap(function (newsletter) { return _this.liesRepository.history(newsletter.id, _this.activeSearchEntity, page, modified); })).subscribe(observer);
                        break;
                    case HistoryEntity.AdSlot:
                        _this.adSlotRepository.get(params.get('id')).pipe(mergeMap(function (adSlot) { return _this.liesRepository.history(adSlot.id, _this.activeSearchEntity, page, modified); })).subscribe(observer);
                        break;
                    default:
                        observer.error(new Error('unsupported entity type'));
                        observer.complete();
                }
            }).pipe(catchError(function () {
                _this.notifications.error('An error occurred when searching for history.');
                return of(null);
            }));
        })).subscribe(function (response) {
            _this.loading$.next(false);
            _this.history$.next(response ? response.items : null);
        });
    };
    HistoryComponent.prototype.formattedChanges = function (item) {
        return this.formatter.formatChanges(item);
    };
    HistoryComponent.prototype.ngOnDestroy = function () {
        this.routeUpdate.unsubscribe();
    };
    HistoryComponent.prototype.search = function (data) {
        this.router.navigate(['/history', { type: data.searchEntity, id: data.refId }]);
    };
    HistoryComponent.prototype.returnToSearch = function () {
        this.router.navigate(['/history']);
    };
    HistoryComponent.prototype.next = function () {
        var page = parseInt(this.route.snapshot.queryParams.page) || 1;
        if (isNaN(page)) {
            page = 1;
        }
        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: {
                page: page + 1
            },
            queryParamsHandling: 'merge'
        });
    };
    HistoryComponent.prototype.previous = function () {
        var page = parseInt(this.route.snapshot.queryParams.page) || 1;
        if (isNaN(page)) {
            page = 1;
        }
        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: {
                page: Math.max(page - 1, 1)
            },
            queryParamsHandling: 'merge'
        });
    };
    HistoryComponent.prototype.setModifiedSince = function (modified) {
        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: {
                modified: modified
            },
            queryParamsHandling: 'merge'
        });
    };
    HistoryComponent.prototype.clearModifiedSince = function () {
        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: {
                modified: null
            },
            queryParamsHandling: 'merge'
        });
    };
    return HistoryComponent;
}());
export { HistoryComponent };
