import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { SharedModule } from 'app/shared';
import { PlatformRouteReuseStrategy } from 'app/core/platform-route-reuse-strategy';
import { AppErrorHandler } from 'app/core/errors';
import { GlobalSearchComponent } from './global-search';
import { GlobalSearchDisplayComponent, GlobalSearchInputComponent } from './global-search-input';
import { MyAccountsComponent } from './my-accounts';
import { NotificationsComponent } from './notifications';
import { PlatformComponent } from './platform.component';
import { ProfileComponent } from './profile';
import { PlatformRoutingModule } from './platform-routing.module';

// Platform
const platformComponents = [
    GlobalSearchComponent,
    GlobalSearchDisplayComponent,
    GlobalSearchInputComponent,
    MyAccountsComponent,
    NotificationsComponent,
    PlatformComponent,
    ProfileComponent
];

@NgModule({
    declarations: [
        ...platformComponents,
    ],
    imports: [
        CommonModule,
        SharedModule,
        PlatformRoutingModule,
        HttpClientModule
    ],
    providers: [
        {
            provide: RouteReuseStrategy,
            useClass: PlatformRouteReuseStrategy
        },
        {
            provide: ErrorHandler,
            useClass: AppErrorHandler
        }
    ]
})
export class PlatformModule { }
