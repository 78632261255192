import { Component, Input} from '@angular/core';

@Component({
    selector: 'description-display',
    templateUrl: './description-display.html',
    styleUrls: ['./description-display.styl']
})
export class DescriptionDisplayComponent{
    @Input('title') title;
    @Input('description') description;
}
