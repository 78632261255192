import { Router } from '@angular/router';
import { IdService } from 'app/core/id.service';
import { ReplaySubject } from 'rxjs';
import { FrontendTableHelper } from 'app/shared/helpers/frontend-table-helper';
import { LightboxnextComponent } from 'app/shared/elements/lightboxnext';
import colors from 'src/data/colors/user-interface.json';
import { Creative, Status, LineItem } from "app/shared/models";
import warnings from 'app/core/warnings.json';
var MappedToDetailsTableComponent = /** @class */ (function () {
    function MappedToDetailsTableComponent(id, router) {
        var _this = this;
        this.id = id;
        this.router = router;
        this.isAdSlotTargetingComponent = false;
        this.isCreativeTargetingComponent = false;
        this.tableHelper = new FrontendTableHelper(function () { return _this.adapter(); });
        this.items$ = new ReplaySubject(1);
        this.colors = colors;
        this.warnings = warnings;
    }
    Object.defineProperty(MappedToDetailsTableComponent.prototype, "items", {
        set: function (value) {
            this.items$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    ;
    MappedToDetailsTableComponent.prototype.adapter = function () {
        return this.items$.pipe();
    };
    MappedToDetailsTableComponent.prototype.query = function (tableQuery) {
        this.tableHelper.search([tableQuery]);
    };
    MappedToDetailsTableComponent.prototype.getStatusColor = function (status) {
        switch (status.toLowerCase()) {
            case Status.ACTIVE.toLowerCase():
                return this.colors['brand-green'];
            default:
                return '#dde3ea';
        }
    };
    MappedToDetailsTableComponent.prototype.showWarnings = function (adSlot) {
        return this.checkAspectRatioMismatchStatus(adSlot) || this.checkIncompatibleBlueprintStatus(adSlot);
    };
    MappedToDetailsTableComponent.prototype.checkAspectRatioMismatchStatus = function (adSlot) {
        if (this.creative && this.creative.isNative && this.creativeAspectRatioMismatchMap && this.creativeAspectRatioMismatchMap[this.creative.refId] && adSlot.isNative) {
            return this.creativeAspectRatioMismatchMap[this.creative.refId][adSlot.refId];
        }
    };
    MappedToDetailsTableComponent.prototype.checkIncompatibleBlueprintStatus = function (adSlot) {
        var _this = this;
        if (adSlot && adSlot.isNative === true && adSlot.nativeBlueprints && this.creative.isNative) {
            return adSlot.nativeBlueprints.filter(function (blueprint) { return !_this.creative.eligibleBlueprints.includes(blueprint.blueprintId); }).length > 0;
        }
        return false;
    };
    MappedToDetailsTableComponent.prototype.navigateToCreativeMappingPage = function (creative) {
        var route = ['/campaign-manager/ads', creative.refId, 'details'];
        var queryParams = {
            'line-item': this.lineItem.refId,
            'tab': 'Creative Mapping',
            'line-item-creative-status': this.creative.status,
        };
        this.router.navigate(route, { queryParams: queryParams });
        // Navigation will not be scrollable unless the modal is closed.
        this.modal.hide();
    };
    return MappedToDetailsTableComponent;
}());
export { MappedToDetailsTableComponent };
