import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Email } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { ProwlerNewsletter } from 'app/shared/models/prowler';


@Injectable()
export class ProwlerRepository extends BackendRepository<Email> {
    constructor(http: HttpClient) {
        super(http, '/prowler/api', Email);
    }

    buildItems(responses): Email[] {
        return responses.reduce((all, response) => {
            const data = response['data'];

            const items = data.map(item => this.build(item));
            all.push(...items);

            return all;
        }, [] as Email[]);
    }

    filter(params: any, resource: string): Observable<any> {
        return this.http.get(
            this.url(this.path, resource, this.stringifyParams(params))
        ).pipe(map(result => {
            return result;
        }));
    }

    private stringifyParams(params: any) {
        const p = Object.assign({}, { page: {} }, params);
        let str = '?' + params.conditions.map(condition => `filter[${condition.field}]=${condition.value}`).join('&');
        p.page.number ? str += `&page[number]=${params.page.number}` : '';
        p.page.size ? str += `&page[size]=${params.page.size}` : '';
        p.sort ? str += `&sort=${params.sort}` : '';
        return encodeURI(str);
    }

    findKeywords(params: any): Observable<any> {
        return this.http.get(
            this.url(this.path, 'keywords', this.stringifyParams(params))
        ).pipe(map(result => {
            return result['data'];
        }));
    }

    findMatchingKeywords(wildcardKeywords: string[]): Observable<string[]> {
        if (wildcardKeywords.length === 0) {
            return of([]);
        }
        return this.findKeywords({conditions: [{ field: 'value', value: wildcardKeywords}]})
            .pipe(map(result => {
                return result.map(res => res.value);
            }));
    }

    generateKeywordReport(email: string, keywords: string[], targetingType: string) {
        const filter = targetingType === 'include' ? 'keywords' : 'lackingKeywords';

        return this.http.post(this.url(this.path, 'keyword-reports'), {
            [filter]: keywords,
            email
        });
    }

    newsletters(refIds: number[]): Observable<ProwlerNewsletter[]> {
        const filter = encodeURI(`filter[id]=${refIds.join(',')}`);
        return this.http.get(this.url(this.path, `newsletters?${filter}`))
            .pipe(map(result => result['data']));
    }
}
