import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { Pixel } from 'app/shared/models';
import { PixelRepository } from 'app/core/repositories';

@Injectable()
export class ConversionTrackerResolver implements Resolve<Pixel> {
    constructor(private pixelRepository: PixelRepository) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.pixelRepository.get(route.params['id']);
    }
}
