import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IdService } from 'app/core/id.service';
import { ReplaySubject } from 'rxjs';
import { TableQuery } from 'app/shared/elements/async-table';
import { FrontendTableHelper } from 'app/shared/helpers/frontend-table-helper';
import { LightboxnextComponent } from 'app/shared/elements/lightboxnext';
import colors from 'src/data/colors/user-interface.json';
import { AdSlot, Creative, NativeBlueprint, Status, LineItem } from "app/shared/models";
import warnings from 'app/core/warnings.json';

@Component({
    selector: 'mapped-to-details-table',
    templateUrl: './mapped-to-details-table.html',
    styleUrls: ['./mapped-to-details-table.styl']
})
export class MappedToDetailsTableComponent {
    @Input() set items(value: any[]) {
        this.items$.next(value);
    }
    @Input() isAdSlotTargetingComponent: boolean = false;
    @Input() isCreativeTargetingComponent: boolean = false;
    @Input() creative: Creative;
    @Input('creativeAspectRatioMismatchMap') creativeAspectRatioMismatchMap;
    @Input() lineItem: LineItem;
    @Input() modal: LightboxnextComponent;

    tableHelper = new FrontendTableHelper<any>(() => this.adapter());
    items$ = new ReplaySubject<any[]>(1);
    colors = colors;
    warnings = warnings;

    constructor(
        public id: IdService,
        private router: Router
    ) { };

    adapter() {
        return this.items$.pipe();
    }

    query(tableQuery: TableQuery) {
        this.tableHelper.search([tableQuery]);
    }

    getStatusColor(status: string) {
        switch (status.toLowerCase()) {
            case Status.ACTIVE.toLowerCase():
                return this.colors['brand-green'];
            default:
                return '#dde3ea';
        }
    }

    showWarnings(adSlot: AdSlot): boolean {
        return this.checkAspectRatioMismatchStatus(adSlot) || this.checkIncompatibleBlueprintStatus(adSlot);
    }

    checkAspectRatioMismatchStatus(adSlot: AdSlot) {
        if (this.creative && this.creative.isNative && this.creativeAspectRatioMismatchMap && this.creativeAspectRatioMismatchMap[this.creative.refId] && adSlot.isNative) {
            return this.creativeAspectRatioMismatchMap[this.creative.refId][adSlot.refId];
        }
    }

    checkIncompatibleBlueprintStatus(adSlot: AdSlot) {
        if (adSlot && adSlot.isNative === true && adSlot.nativeBlueprints && this.creative.isNative) {
            return adSlot.nativeBlueprints.filter((blueprint: NativeBlueprint) => !this.creative.eligibleBlueprints.includes(blueprint.blueprintId)).length > 0;
        }

        return false;
    }

    navigateToCreativeMappingPage(creative: Creative): void {
        const route = ['/campaign-manager/ads', creative.refId, 'details'];
        const queryParams = {
            'line-item': this.lineItem.refId,
            'tab': 'Creative Mapping',
            'line-item-creative-status': this.creative.status,
        };

        this.router.navigate(route, { queryParams });

        // Navigation will not be scrollable unless the modal is closed.
        this.modal.hide();
    }
}
