import { Subject, ReplaySubject, concat, forkJoin, of, throwError } from 'rxjs';
import { bufferWhen, filter, mergeMap, debounceTime, map, reduce } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
/**
 * The `BackendRepository` is an abstract class which when extended takes a
 * generic model type and allows for the creation of instances of the model as
 * promises typically using data fetched from the backend API through the
 * `BackendHttp` service.
 */
var BackendRepository = /** @class */ (function () {
    function BackendRepository(http, path, model) {
        var _this = this;
        this.http = http;
        this.path = path;
        this.model = model;
        this._cache = new Map();
        this.toFetch$ = new Subject();
        this.toFetch$.pipe(bufferWhen(function () { return _this.toFetch$.pipe(debounceTime(BackendRepository.FLUSH_INTERVAL)); }), filter(function (ids) { return ids.length > 0; }), mergeMap(function (ids) { return _this.searchByIds(ids); })).subscribe(function (models) { return _this.populateCache(models); });
    }
    BackendRepository.prototype.populateCache = function (models) {
        var _this = this;
        models.forEach(function (model) {
            var subject = _this._cache.get(_this.getCacheKey(model));
            if (subject) {
                subject.next(model);
            }
        });
    };
    BackendRepository.prototype.getCacheKey = function (model) {
        return model.id;
    };
    /**
     * Returns an observable that fetches `T` with `id` from the backend.
     * @param id
     */
    BackendRepository.prototype.get = function (id) {
        var _this = this;
        if (typeof id === 'number') {
            id = id.toString();
        }
        if (typeof id !== 'string') {
            return throwError(new Error('Invalid parameter. Parameter must be a string or a number.'));
        }
        if (id.length < 1) {
            return throwError(new Error('Invalid parameter. Parameter must have length at least 1.'));
        }
        return this.http.get(this.url(this.path, id)).pipe(map(function (response) { return _this.build(response['output']); }));
    };
    /**
     * Returns an observable that fetches `T` with `id` from the backend,
     * or the cache if the entity is already present.
     * @param id
     */
    BackendRepository.prototype.getFromCache = function (id) {
        if (!this._cache.has(id)) {
            this.toFetch$.next(id);
            this._cache.set(id, new ReplaySubject());
        }
        return this._cache.get(id);
    };
    /**
     * Returns an observable that searches for `T` in the backend.
     * @param query
     */
    BackendRepository.prototype.search = function (query) {
        var _this = this;
        return this.rawSearch(query)
            .pipe(map(function (response) { return response['output'].map(function (data) { return _this.build(data); }); }));
    };
    /**
     * Returns an observable that searches for `T`s by ids in the backend.
     * @param ids
     */
    BackendRepository.prototype.searchByIds = function (ids) {
        if (Array.isArray(ids) && ids.length === 0) {
            return of([]);
        }
        var query = {};
        query.conditions = [
            { field: 'id', value: ids }
        ];
        if (this._searchReturnFields) {
            query.return = this._searchReturnFields;
        }
        return this.search(query);
    };
    /**
     * Performs a search against the backend and returns the response.
     * @param query?
     */
    BackendRepository.prototype.rawSearch = function (query, uri) {
        var path = uri || '/search' + this.path;
        return this.http.post(this.url(path), this.sanitizeQuery(query));
    };
    /**
     * Sanitize the query params before sending it off.
     * @param query?
     */
    BackendRepository.prototype.sanitizeQuery = function (query) {
        if (!query) {
            return;
        }
        if (Array.isArray(query.conditions) && query.conditions.length === 0) {
            // LSD does not support empty conditions
            delete query.conditions;
        }
        return query;
    };
    /**
     * Performs a search against the backend and builds models of `T` for
     * each item returned.
     * @param params
     * @param uri?
     */
    BackendRepository.prototype.asyncSearch = function (params, uri) {
        var _this = this;
        return this.rawSearch(params, uri)
            .pipe(map(function (data) { return ({
            items: data['output'].map(function (item) { return _this.build(item); }),
            page: 1,
            pages: data['pages'],
            total: data['total']
        }); }));
    };
    /**
     * Returns all results where field equals value.
     */
    BackendRepository.prototype.allWhere = function (field, value) {
        return this.all({
            conditions: [
                { field: field, value: value }
            ]
        });
    };
    /**
     * Returns an observable of items of `T` in all pages in the result of the search
     * with `query` parameters.
     * @param query
     */
    BackendRepository.prototype.all = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        query.n = 1000;
        return this.consume(function (page) {
            // clone the query so when we update the page
            // we are not mutating the original query
            // since it will be passed by reference
            var q = JSON.parse(JSON.stringify(query));
            q.page = page;
            return _this.rawSearch(q);
        });
    };
    /**
     * Returns an observable that saves `T` in the backend.
     * @param instance
     */
    BackendRepository.prototype.save = function (instance) {
        var _this = this;
        var params = [this.path];
        if (instance.id) {
            params.push(instance.id);
        }
        return this.http.post(this.url.apply(this, params), instance.serialize())
            .pipe(map(function (response) { return _this.build(response['output']); }));
    };
    /**
     * Returns an observable that saves all `instances` of T.
     * @param instances
     */
    BackendRepository.prototype.saveAll = function (instances) {
        var _this = this;
        return concat.apply(void 0, instances.map(function (instance) { return _this.save(instance); })).pipe(reduce(function (all, instance) { return all.concat(instance); }, []));
    };
    /**
     * Returns an observable that deletes `T` with `id` in the backend.
     * @param id
     */
    BackendRepository.prototype.delete = function (id) {
        return this.http.delete(this.url(this.path, id));
    };
    /**
     * This function returns a new instance of the model of type `T`
     * instantiated with `from`.
     * @param from
     */
    BackendRepository.prototype.build = function (from) {
        return new this.model(from);
    };
    /**
     * This function will return an observable which consumes all the pages
     * returned by the endpoint for `method`.
     * @param method
     * @param query
     */
    BackendRepository.prototype.consume = function (method) {
        var _this = this;
        return method(1).pipe(mergeMap(function (response) {
            var data = response;
            var observables = [of(response)];
            // Fetch all remaining pages.
            for (var i = 2; i <= data['pages']; i++) {
                observables.push(method(i));
            }
            return forkJoin(observables);
        })).pipe(map(function (responses) { return _this.buildItems(responses); }));
    };
    /**
     * Converts each item in the responses to type `T`.
     * @param responses
     */
    BackendRepository.prototype.buildItems = function (responses) {
        var _this = this;
        return responses.reduce(function (all, response) {
            var data = response['output'];
            var items = data.map(function (item) { return _this.build(item); });
            all.push.apply(all, items);
            return all;
        }, []);
    };
    /**
     * Builds and returns a URL for the backend API given an array of strings
     * `suffix` which are joined using a forward-slash.
     * @param suffix
     */
    BackendRepository.prototype.url = function () {
        var suffix = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            suffix[_i] = arguments[_i];
        }
        return environment.backend.hostname + suffix.join('/');
    };
    /**
     * Returns an observable that searches for `T`s by refIds in the backend.
     * @param refIds
     */
    BackendRepository.prototype.searchByRefIds = function (refIds) {
        if (Array.isArray(refIds) && refIds.length === 0) {
            return of([]);
        }
        var query = {};
        query.conditions = [
            { field: 'refId', value: refIds }
        ];
        if (this._searchReturnFields) {
            query.return = this._searchReturnFields;
        }
        return this.search(query);
    };
    BackendRepository.FLUSH_INTERVAL = 1000;
    return BackendRepository;
}());
export { BackendRepository };
