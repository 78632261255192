import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LineItemRepository } from '../repositories';
import { EligibleAdSlots } from 'app/shared/models/line-item';

@Injectable()
export class EligibleAdSlotsResolver implements Resolve<EligibleAdSlots> {

  constructor(
    private lineItemRepository: LineItemRepository
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<EligibleAdSlots> {
    if (route.queryParamMap.has('line-item')) {
      // Ad Details preview
      const lineItemId = route.queryParamMap.get('line-item');
      const creativeId = route.params['id'];
      return this.lineItemRepository.getEligibleAdSlots(lineItemId, creativeId);
    }
    // Line item details page ad preview
    const lineItemId = route.params['id'];
    return this.lineItemRepository.getEligibleAdSlots(lineItemId);
  }
}
