import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { Pixel, LiveConnectStatus } from 'app/shared/models';
import { ReportingQueryRepository, PixelRepository } from './repositories';

@Injectable()
export class PixelService {
    private statusCache = new Map<number, Observable<LiveConnectStatus>>();
    private pixelCache = new Map<string, Observable<Pixel>>();

    constructor(
        private reporting: ReportingQueryRepository,
        private pixelRepository: PixelRepository
    ) {}

    /**
     * Get recent activity for a pixel.
     */
    activity(pixel: Pixel) {
        return this.reporting.getMostRecentPixelActivity(pixel.refId);
    }

    /**
     * Compute and cache the status of the pixel.
     */
    status(pixel: Pixel) {
        if (!this.statusCache.has(pixel.refId)) {
            this.statusCache.set(
                pixel.refId,
                this.computeStatus(pixel).pipe(shareReplay(1))
            );
        }

        return this.statusCache.get(pixel.refId);
    }

    /**
     * Compute the status of a pixel.
     */
    private computeStatus(pixel: Pixel) {
        return this.activity(pixel).pipe(
            map((activity) => new LiveConnectStatus(activity))
        )
    }

    getPixel(pixelId: string) {
        if (!this.pixelCache.has(pixelId)) {
            this.pixelCache.set(
                pixelId,
                this.pixelRepository.get(pixelId).pipe(shareReplay(1))
            );
        }

        return this.pixelCache.get(pixelId);
    }
}
