import { Component, EventEmitter, Input, Output, ChangeDetectorRef } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';

import {
    AdSlotRepository,
    BundleRepository,
    NewsletterRepository,
    PublisherRepository
} from 'app/core/repositories';

import { InventoryHelperService } from 'app/core/inventory-helper.service';
import { InventoryTargetingOption } from './inventory-targeting-option.class';
import { DownloadHelper } from 'app/shared/helpers/download-helper';

type Mode = 'Publisher' | 'Newsletter' | 'Ad Slot' | 'Bundle';

const Publisher: Mode = 'Publisher';
const Newsletter: Mode = 'Newsletter';
const AdSlot: Mode = 'Ad Slot';
const Bundle: Mode = 'Bundle';

import options from './options.json';
import { InventoryTargetingToOptions } from './inventory-targeting-to-options';
import { IdService } from 'app/core/id.service';

/**
 * This file is a work in progress. The 'edit' functionality is not back yet,
 * and there is also some refactoring that is needed to make this more
 * coherent, so feel free.
 */

@Component({
    selector: 'inventory-targeting',
    templateUrl: './inventory-targeting.html',
    styleUrls: ['./inventory-targeting.styl'],
})
export class InventoryTargetingComponent {
    @Input() fileName: string = 'targeted_inventory';
    @Input() inventory: any[] = [];
    @Input() whiteListOnly: boolean = false;
    @Input() placeholder = 'Search Publishers and Templates';
    @Input('targeting') _targeting: string = 'include';

    @Output() inventoryChange = new EventEmitter<any[]>();
    @Output() targetingChange: EventEmitter<string> = new EventEmitter<string>();

    _bulkAddOptions = [Newsletter, Publisher, AdSlot, Bundle];

    items: InventoryTargetingOption[] = [];
    mode: Mode;
    modes: any[] = [Publisher, Newsletter, AdSlot, Bundle];
    isLoading = false;
    private _options: any[] = [];

    text: string = '';
    idsError: boolean = false;

    private modeOptions: any = options['modeOptions'];
    targetingOptions: any[] = options['targetingOptions'];

    constructor(
        private adSlotRepository: AdSlotRepository,
        private bundleRepository: BundleRepository,
        private cdr: ChangeDetectorRef,
        private inventoryHelper: InventoryHelperService,
        private newsletterRepository: NewsletterRepository,
        private publisherRepository: PublisherRepository,
        public id: IdService
    ) { }

    ngOnInit() {
        if (Array.isArray(this.inventory)) {
            this.updateItems();
        } else {
            this.inventory = [];
        }
        this.setTargeting(this.whiteListOnly);
    }

    @Input() targetingDropDownVisibile = true;


    @Input()
    set bulkAddOptions(value: any[]) {
        this._bulkAddOptions = value;
        this.modes = [];

        this._bulkAddOptions.forEach(mode => {
            this.modes.push(this.modeOptions[mode]);
        });
        this.mode = this.modes[0] ? this.modes[0].value : 'Mode not set';
    }

    get bulkAddOptions() {
        return this._bulkAddOptions;
    }

    updateItems() {
        this.inventoryHelper
            .lookupInventory(this.inventory, 'id')
            .then((inventory) => {
                this.items = inventory[0].map(InventoryTargetingToOptions.preparePublisherOption)
                    .concat(inventory[1].map(InventoryTargetingToOptions.prepareNewsletterOption),
                            inventory[2].map(InventoryTargetingToOptions.prepareAdSlotOption),
                            inventory[3].map(InventoryTargetingToOptions.prepareBundleOption));
                this.isLoading = false;
                this.inventoryChange.emit(this.inventory);
                this.cdr.detectChanges();
            }).catch (() => {});
    }

    setTargeting(whitelistonly) {
        if (whitelistonly) {
            this.targetingOptions = [{
                key: 0,
                value: 'include',
                label: 'Allow List'
            }];
        }
    }

    get searchItems(): any[] {
        return this._options.filter(option => {
            for (let item of this.inventory) {
                if (item.key === option.value) {
                    return false;
                }
            }
            return true;
        });
    }

    set targeting(value: string) {
        this._targeting = value;
        this.targetingChange.emit(this._targeting);
    }

    get targeting(): string {
        return this._targeting;
    }

    private parseIds(mode: Mode): string[] {
        this.idsError = false;
        let invalid = [];

        // split on newline and commas
        const ids = this.text.replace(/\n/g, ',')
            .split(',')
            .map(token => token.trim())
            .filter(refId => !this.isRefIdAdded(parseInt(refId), mode))
            .filter(refId => {
                if (!this.isIdValid(refId)) {
                    invalid.push(refId);
                    return false;
                }

                return true;
            });

        if (invalid.length > 0) {
            this.idsError = true;
        }
        this.text = invalid.join('\n');

        return ids;
    }

    addIDs(): void {
        const ids = this.parseIds(this.mode);

        const query = {
            conditions: [
                {
                    field: 'refId',
                    value: ids
                }
            ],
            return: [
                'id',
                'refId',
                'name'
            ],
            returnMode: 'only'
        };

        let repository;
        switch (this.mode) {
            default:
            case 'Publisher':
                repository = this.publisherRepository;
                break;
            case 'Newsletter':
                repository = this.newsletterRepository;
                break;
            case 'Ad Slot':
                repository = this.adSlotRepository;
                break;
            case 'Bundle':
                repository = this.bundleRepository;
                break;
        }
        repository.all(query).subscribe(results => {
            const returnedIds = results.map(res => res.refId);
            const missingIds = ids.filter(id => !returnedIds.includes(Number(id)));
            if (missingIds.length > 0) {
                this.idsError = true;
                this.text += this.text.length > 0 ? '\n' + missingIds.join('\n') : missingIds.join('\n');
            }
            this.inventory.push(...results.map(item => {
                return {
                    key: item.id,
                    value: item.id,
                    id: item.id,
                    refId: item.refId,
                    label: item.refId + ': ' + item.name,
                    type: this.mode
                };
            }));

            this.updateItems();

            if (results.length === 0) {
                this.idsError = true;
            }
        });
    }

    /**
     * Check if a refId has already been added for a mode.
     * @param {number} refId
     * @param {string} mode
     */
    private isRefIdAdded(refId: number, mode: string) {
        return this.inventory.findIndex(added => {
            return added.refId === refId && added.type === mode;
        }) > -1;
    }

    private isIdAdded(id: string) {
        return this.inventory.findIndex(added => {
            return added.id === id;
        }) > -1;
    }

    addTarget(option): void {

        if (this.isIdAdded(option.key)) {
            return;
        }

        this.inventory.push({
            key: option.key,
            id: option.value,
            refId: option.refId,
            type: option.type
        });
        this.updateItems();
    }

    removeAll() {
        this.inventory.length = 0;
        this.updateItems();
    }

    inputChange(searchTerm) {
        if (searchTerm.length < 1) {
            return;
        }

        const query = {
            conditions: [
                {
                    field: 'name',
                    value: searchTerm,
                    operator: 'like'
                }
            ],
            return: [
                'id',
                'refId',
                'name'
            ],
            returnMode: 'only'
        };

        this.isLoading = true;
        const requests = [];

        if (this.modes.findIndex(mode => mode.value === Publisher) > -1) {
            requests.push(this.publisherRepository.search(query));
        } else {
            requests.push(of([]));
        }

        if (this.modes.findIndex(mode => mode.value === Newsletter) > -1) {
            requests.push(this.newsletterRepository.search(query));
        } else {
            requests.push(of([]));
        }

        if (this.modes.findIndex(mode => mode.value === AdSlot) > -1) {
            requests.push(this.adSlotRepository.search(query));
        } else {
            requests.push(of([]));
        }

        if (this.modes.findIndex(mode => mode.value === Bundle) > -1) {
            requests.push(this.bundleRepository.search(query));
        } else {
            requests.push(of([]));
        }

        forkJoin(...requests).subscribe(results => {
            const flattend = [].concat.apply([], results);
            this._options = flattend.map((item: any) => {
                return {
                    key: item.id,
                    value: item.id,
                    refId: item.refId,
                    label: item.refId + ': ' + item.name,
                    type: item.entity
                };
            });

            this.updateItems();
        });
    }

    isIdValid(value: string): boolean {
        const regex = new RegExp('[0-9]+');

        return regex.test(value);
    }

    updateInventory(newInventory: InventoryTargetingOption[]) {
        this.inventory = newInventory.map(option => ({ type: option.type, id: option.data.id}));
        this.updateItems();
    }

    download() {
        const targetingType = this.targetingOptions.find(current => this.targeting === current.value).label;
        const data = this.items.map(item => ({
            'targeting type': targetingType,
            'targeting entity type': item.type,
            id: item.data.refId,
            name: item.data.name
        }));

        DownloadHelper.downloadAsCSV(data, this.fileName);
    }
}
