import { Model } from './model';
import campaignTypesMeta from './campaign-types-meta.json';
import { Advertiser } from './advertiser';
import { Status } from './status';
import { StrategyCard } from 'app/shared/models/strategy-card';
import moment from 'moment';

const GOAL_BRANDING = 'branding';
const GOAL_PERFORMANCE = 'performance';
const REACH_CPA = 'cpa';
const REACH_IMPRESSIONS = 'impressions';
const REACH_CLICKS = 'clicks';
const REACH_REACH = 'reach';
const START_TIME = '00:00:00';
const END_TIME = '23:59:59';
const IMPRESSIONS: BudgetType = 'impressions';
const ADJUSTED_IMPRESSIONS: BudgetType = 'adjusted_impressions';
const CURRENCY: BudgetType = 'currency';
const DAILY = 'daily';
const DEMAND_HOUSE = 'house';
const DEMAND_DIRECT = 'direct';
const DEMAND_EXCHANGE = 'exchange';
const FIRST_PRICE: ClearingMethod = '1stPrice';
const SECOND_PRICE: ClearingMethod = '2ndPrice';
const SSP: System = 'ssp';
const DSP: System = 'dsp';
const MAX_CTR = 'MaxCTR';
const MAX_CR = 'MaxCR';
const GOAL_CPM = 'CPM';
const GOAL_CPC = 'CPC';
const GOAL_CPA = 'CPA';
const SPEND_TYPE_DAILY = 'daily';
const SPEND_TYPE_LIFETIME = 'lifetime';
const TARGET_ECPM = 'Target eCPM';
const TARGET_ECPC = 'Target eCPC';
const TARGET_ECPA = 'Target eCPA';

export const CAMPAIGN_CONST = {
    GOAL_PERFORMANCE: GOAL_PERFORMANCE,
    GOAL_BRANDING: GOAL_BRANDING,
    REACH_CPA: REACH_CPA,
    REACH_IMPRESSIONS: REACH_IMPRESSIONS,
    REACH_REACH: REACH_REACH,
    REACH_CLICKS: REACH_CLICKS,
    START_TIME: START_TIME,
    END_TIME: END_TIME,
    IMPRESSIONS: IMPRESSIONS,
    ADJUSTED_IMPRESSIONS: ADJUSTED_IMPRESSIONS,
    CURRENCY: CURRENCY,
    DAILY: DAILY,
    HOUSE: DEMAND_HOUSE,
    DIRECT: DEMAND_DIRECT,
    EXCHANGE: DEMAND_EXCHANGE,
    FIRST_PRICE: FIRST_PRICE,
    SECOND_PRICE: SECOND_PRICE,
    DSP: DSP,
    SSP: SSP,
    MAX_CTR: MAX_CTR,
    MAX_CR: MAX_CR,
    GOAL_CPM: GOAL_CPM,
    GOAL_CPC: GOAL_CPC,
    GOAL_CPA: GOAL_CPA,
    BUDGET_SPEND_DAILY: SPEND_TYPE_DAILY,
    BUDGET_SPEND_LIFETIME: SPEND_TYPE_LIFETIME,
    TARGET_ECPM: TARGET_ECPM
};

export enum CampaignType {
    BrandingClicks,
    BrandingConversions,
    BrandingImpressions,
    PerformanceClicks,
    PerformanceConversions,
    PerformanceCPA,
    PerformanceCPC,
    PerformanceReach,
    Guaranteed,
    PerformanceCTR,
}

export type ClearingMethod = '1stPrice' | '2ndPrice';
export type PricingModel = 'CPM' | 'CPC' | 'CPA';
export type BudgetType = 'currency' | 'impressions' | 'adjusted_impressions';
export type Goal = 'MaxCTR' | 'MaxCR' | 'CPM' | 'CPC' | 'CPA';
export type System = 'dsp' | 'ssp';
export type BudgetSpendType = 'daily' | 'lifetime';

type CampaignMeta = {
    title: string;
    content: string;
    iconClass: string;
    reach: string;
    goal: string;
    goalDescription: string;
    bidLabel: string;
};

export class Campaign extends Model {
    static EvenPacingTypes = [
        CampaignType.BrandingConversions,
        CampaignType.BrandingClicks,
        CampaignType.PerformanceConversions,
        CampaignType.PerformanceClicks,
        CampaignType.PerformanceReach,
        CampaignType.PerformanceCTR,
    ];

    static BrandingTypes = [
        CampaignType.BrandingImpressions,
        CampaignType.BrandingClicks,
        CampaignType.BrandingConversions,
        CampaignType.Guaranteed
    ];

    advertiser: string;
    bidAmount: number;
    budget: number;
    unlimitedBudget: boolean;
    budgetAllocated: number;
    budgetType: BudgetType;
    category: number;
    clearingMethod: ClearingMethod;
    clicks: number;
    comment: any;
    conversionPixel: string;
    conversions: number;
    currencyType: string;
    demandType: string;
    description: string;
    dspFee: number;
    effectiveEndDate: string;
    effectiveStartDate: string;
    endDate: string;
    externalId: string;
    frequencyCapCount: number;
    frequencyCapPeriod: string;
    goal: Goal;
    guaranteed: boolean;
    impressions: number;
    insertionOrder: string;
    insertionOrderEndDate: string;
    insertionOrderStartDate: string;
    isDynamicBudget: boolean;
    maxCpm: number;
    modified: string;
    buyingStrategyId: number;
    name: string;
    pricingModel: PricingModel;
    refId: number;
    secondPrice: boolean;
    shareOfVoice: number;
    spend: number;
    sspFee: number;
    startDate: string;
    status: 'active' | 'inactive' | 'pending';
    system: System;
    type: string;
    startTime: string;
    endTime: string;
    parentCampaign: string;
    isAudienceExtension: boolean;
    canAccessAudienceExtension?: boolean;
    creativeAudienceExtensionErrors?: any;
    strategyAudienceExtensionErrors?: any;

    // non-lsd property
    advertiserObj: Advertiser;
    inheritDates: boolean;

    constructor(from?: any) {
        super(from);

        if (this.comment) {
            // Parse the comment as JSON.
            try {
                this.comment = JSON.parse(this.comment);
            } catch (err) {
                this.comment = null;
                // TODO: Add error logging when such a framework is available.
            }
        }

        this.startTime = this.getTime(this.startDate || this.insertionOrderStartDate);
        this.endTime = this.getTime(this.endDate || this.insertionOrderEndDate);
    }

    serialize() {
        const campaign = this.clone(Campaign);

        campaign.startDate = this.createDatetime(this.startDate, this.startTime);
        campaign.endDate = this.createDatetime(this.endDate, this.endTime);

        campaign.endDate = moment(campaign.endDate).endOf('minute').format('YYYY-MM-DD HH:mm:ss');

        delete campaign.startTime;
        delete campaign.endTime;

        if (campaign.inheritDates) {
            campaign.startDate = null;
            campaign.endDate = null;
        }

        delete campaign.inheritDates;

        if (typeof campaign.externalId === 'string' && campaign.externalId.trim().length === 0) {
            campaign.externalId = null;
        }
        delete campaign.advertiserObj;
        delete campaign.canAccessAudienceExtension;

        return JSON.stringify(campaign);
    }

    get entity() {
        return 'Campaign';
    }

    get campaignType() {
        if (this.pricingModel) {
            const guaranteed = this.guaranteed ? true : false;
            const campaignTypes = this.campaignTypes().filter(type =>
                type.pricingModel === this.pricingModel
                && type.goal === this.goal
                && type.guaranteed === guaranteed
                && (type.clearingMethod === this.clearingMethod
                    || type.secondPrice === this.isSecondPrice)
                && (!this.buyingStrategyId || (this.buyingStrategyId && type.buyingStrategyId === this.buyingStrategyId))
            );

            if (campaignTypes.length > 0) {
                return campaignTypes[0].type;
            }

            throw new Error('Could not find campaign type.');
        }
    }

    get meta(): CampaignMeta {
        switch (this.campaignType) {
            case CampaignType.BrandingClicks:
                if (this.buyingStrategyId === StrategyCard.FIRST_PRICE_CPM_MAX_CLICKTHRU) {
                    return campaignTypesMeta.performanceCPMMaxCTR;
                }
                return campaignTypesMeta.brandingClicks;
            case CampaignType.BrandingConversions:
                return campaignTypesMeta.brandingConversions;
            case CampaignType.BrandingImpressions:
                return campaignTypesMeta.brandingImpressions;
            case CampaignType.PerformanceClicks:
                return campaignTypesMeta.performanceClicks;
            case CampaignType.PerformanceConversions:
                if (this.buyingStrategyId === StrategyCard.PERFORMANCE_MAXIMIZE_CONVERSIONS_DAILY_CAP) {
                    return campaignTypesMeta.performanceConversionsWithDailyCap;
                }
                return campaignTypesMeta.performanceConversions;
            case CampaignType.PerformanceCPA:
                return campaignTypesMeta.performanceCPA;
            case CampaignType.PerformanceCPC:
                return campaignTypesMeta.performanceCPC;
            case CampaignType.PerformanceReach:
                return campaignTypesMeta.performanceReach;
            case CampaignType.Guaranteed:
                return campaignTypesMeta.guaranteed;
            case CampaignType.PerformanceCTR:
                return campaignTypesMeta.performanceCPMMaxCTR;
            default:
                throw new Error('Could not find campaign meta of type ' + this.campaignType);
        }
    }

    get allowsImpressionBudget() {
        return this.system === 'ssp' &&
            (this.campaignType === CampaignType.BrandingImpressions || this.campaignType === CampaignType.Guaranteed);
    }

    get allowsAdjustedImpressionBudget() {
        return this.system === 'ssp' && (
            (this.campaignType === CampaignType.Guaranteed) ||
            (this.campaignType === CampaignType.BrandingImpressions)
        )
    }

    get isActive() {
        return this.status === 'active';
    }

    get isSecondPrice() {
        return this.secondPrice || this.clearingMethod === SECOND_PRICE;
    }

    get isBranding() {
        return Campaign.BrandingTypes.indexOf(this.campaignType) > -1;
    }

    get isEvenPacing() {
        return Campaign.EvenPacingTypes.indexOf(this.campaignType) > -1;
    }

    get isExchange() {
        return this.demandType === 'exchange';
    }

    get isDirectSold() {
        return this.demandType === DEMAND_DIRECT;
    }

    get isUnlimitedBudget() {
        return this.unlimitedBudget || this.isExchange;
    }

    get isHouse() {
        return this.demandType === DEMAND_HOUSE;
    }

    get isSsp() {
        return this.system === SSP;
    }

    get isDsp() {
        return this.system === DSP;
    }

    get goalThreshold() {
        return this.campaignType === CampaignType.PerformanceCPC ||
            this.campaignType === CampaignType.PerformanceCPA ? 90 : 95;
    }

    get title() {
        return this.meta.title;
    }

    get goalDescription() {
        return this.meta.goalDescription;
    }

    get bidAmountLabel() {
        if (this.pricingModel === GOAL_CPM && !this.isSecondPrice) {
            return GOAL_CPM;
        } else if (this.pricingModel === GOAL_CPC && !this.isSecondPrice) {
            return GOAL_CPC;
        } else if (this.pricingModel === GOAL_CPA && !this.isSecondPrice) {
            return GOAL_CPA;
        } else if (this.pricingModel === GOAL_CPM && this.isSecondPrice) {
            if (this.goal === GOAL_CPM) {
                return TARGET_ECPM;
            } else if (this.goal === GOAL_CPC) {
                return TARGET_ECPC;
            } else if (this.goal === GOAL_CPA) {
                return TARGET_ECPA;
            }
        }
    }

    get formattedPlatform() {
        if (this.system) {
            return this.system.toUpperCase();
        }

        return null;
    }

    getFormattedDemandType() {
        switch (this.demandType) {
            case 'exchange': return 'Exchange';
            case 'direct': return 'Direct Sold';
            case 'house': return 'House Campaign';
        }
    }

    hasConversionTracker() {
        return typeof this.conversionPixel === 'string';
    }

    needsActivation() {
        return this.optimizesTowardsConversions && this.status === 'pending';
    }

    getBidLabel() {
        return this.meta.bidLabel;
    }

    private optimizesTowardsConversions() {
        return [
            CampaignType.PerformanceConversions,
            CampaignType.BrandingConversions,
            CampaignType.PerformanceCPA
        ].includes(this.campaignType);
    }

    get supportsDailyCap() {
        return ( this.system === 'ssp' ||
            (this.system === 'dsp'
                && (this.campaignType === CampaignType.PerformanceConversions || this.campaignType === CampaignType.PerformanceCTR)
                && this.buyingStrategyId !== StrategyCard.PERFORMANCE_MAXIMIZE_CONVERSIONS
            )
        );
    }

    get platformStatus() {
        return this.needsActivation ? Status.NEEDS_ACTIVATION : null;
    }

    get isGuaranteed() {
        return this.campaignType === CampaignType.Guaranteed;
    }

    get isImpressionBased() {
        return this.campaignType === CampaignType.BrandingImpressions
    }

    get isPerformanceCTR() {
        return this.campaignType === CampaignType.PerformanceCTR;
    }

    private campaignTypes() {
        return [{
            pricingModel: GOAL_CPM,
            clearingMethod: SECOND_PRICE,
            secondPrice: true,
            goal: GOAL_CPM,
            type: CampaignType.PerformanceReach,
            guaranteed: false
        }, {
            pricingModel: GOAL_CPM,
            clearingMethod: SECOND_PRICE,
            secondPrice: true,
            goal: null,
            type: CampaignType.PerformanceReach,
            guaranteed: false
        }, {
            pricingModel: GOAL_CPM,
            clearingMethod: SECOND_PRICE,
            secondPrice: true,
            goal: GOAL_CPC,
            type: CampaignType.PerformanceClicks,
            guaranteed: false
        }, {
            pricingModel: GOAL_CPM,
            clearingMethod: SECOND_PRICE,
            secondPrice: true,
            goal: GOAL_CPA,
            type: CampaignType.PerformanceConversions,
            guaranteed: false,
            buyingStrategyId: StrategyCard.PERFORMANCE_MAXIMIZE_CONVERSIONS
        }, {
            pricingModel: GOAL_CPM,
            clearingMethod: SECOND_PRICE,
            secondPrice: true,
            goal: GOAL_CPA,
            type: CampaignType.PerformanceConversions,
            guaranteed: false,
            buyingStrategyId: StrategyCard.PERFORMANCE_MAXIMIZE_CONVERSIONS_DAILY_CAP
        },
        {
            pricingModel: GOAL_CPM,
            clearingMethod: FIRST_PRICE,
            secondPrice: false,
            goal: MAX_CTR,
            type: CampaignType.BrandingClicks,
            guaranteed: false
        },{
            pricingModel: GOAL_CPM,
            clearingMethod: FIRST_PRICE,
            secondPrice: false,
            goal: MAX_CTR,
            type: CampaignType.PerformanceCTR,
            guaranteed: false,
            buyingStrategyId: StrategyCard.FIRST_PRICE_CPM_MAX_CLICKTHRU
        }, {
            pricingModel: GOAL_CPM,
            clearingMethod: FIRST_PRICE,
            secondPrice: false,
            goal: MAX_CR,
            type: CampaignType.BrandingConversions,
            guaranteed: false
        }, {
            pricingModel: GOAL_CPM,
            clearingMethod: FIRST_PRICE,
            secondPrice: false,
            goal: null,
            type: CampaignType.BrandingImpressions,
            guaranteed: false
        }, {
            pricingModel: GOAL_CPC,
            clearingMethod: FIRST_PRICE,
            secondPrice: false,
            goal: null,
            type: CampaignType.PerformanceCPC,
            guaranteed: false
        }, {
            pricingModel: GOAL_CPA,
            clearingMethod: SECOND_PRICE,
            secondPrice: true,
            goal: GOAL_CPA,
            type: CampaignType.PerformanceCPA,
            guaranteed: false
        }, {
            pricingModel: GOAL_CPA,
            clearingMethod: SECOND_PRICE,
            secondPrice: true,
            goal: null,
            type: CampaignType.PerformanceCPA,
            guaranteed: false
        }, {
            pricingModel: GOAL_CPA,
            clearingMethod: FIRST_PRICE,
            secondPrice: false,
            goal: null,
            type: CampaignType.PerformanceCPA,
            guaranteed: false
        }, {
            pricingModel: GOAL_CPM,
            clearingMethod: FIRST_PRICE,
            secondPrice: false,
            goal: null,
            type: CampaignType.Guaranteed,
            guaranteed: true
        }
    ];

    }

    get allowsDailyBudget() {
        return this.system === 'dsp'
            && (this.campaignType === CampaignType.PerformanceConversions || this.campaignType === CampaignType.PerformanceClicks);
    }
}
