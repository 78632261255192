import * as tslib_1 from "tslib";
import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnDestroy, OnInit, ElementRef } from '@angular/core';
import { SlicePipe } from '@angular/common';
import { FilterPipe } from '../pipes/filter.pipe';
import { DropdownComponent, Navigable } from '../dropdown';
import { Subscription, fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IdService } from 'app/core/id.service';
var SearchComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SearchComponent, _super);
    function SearchComponent(cdr, id) {
        var _this = _super.call(this, cdr) || this;
        _this.cdr = cdr;
        _this.id = id;
        _this.isLoading = false;
        _this.placeholder = null;
        _this.DELAY = 0;
        _this.selectChange = new EventEmitter();
        _this.remove = new EventEmitter();
        _this.inputChange = new EventEmitter();
        _this.itemList = [];
        _this.input = '';
        _this.filter = new FilterPipe();
        _this.slice = new SlicePipe();
        return _this;
    }
    SearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.refresh(this.input);
        this.subscription = fromEvent(this.trigger.nativeElement, 'input').pipe(map(function () { return _this.inputValue; }), debounceTime(this.DELAY), distinctUntilChanged()).subscribe(function (input) { return _this.inputChange.emit(input); });
    };
    SearchComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    SearchComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.dropdown.trigger.addEventListener('blur', function (_) { return _this.index = -1; });
    };
    SearchComponent.prototype.keydown = function (event) {
        if (event.keyCode === 8 && this.input.length < 1) {
            this.remove.emit();
            return;
        }
        _super.prototype.keydown.call(this, event);
        this.cdr.detectChanges();
    };
    SearchComponent.prototype.mouseenter = function (event, key) {
        _super.prototype.mouseenter.call(this, event, key);
        this.cdr.detectChanges();
        return null;
    };
    SearchComponent.prototype.refresh = function (input) {
        this.input = input;
        this.items = this.input === ''
            ? this.itemList
            : this.slice.transform(this.filter.transform(this.itemList, this.input), 0);
        this.reset();
        this.cdr.detectChanges();
    };
    SearchComponent.prototype.search = function (input) {
        this.refresh(input);
    };
    SearchComponent.prototype.select = function (event, index) {
        if (index > -1) {
            this.selectChange.emit(this.items[index]);
        }
        this.refresh('');
        this.inputValue = this.input;
    };
    SearchComponent.prototype.blur = function () {
        var _this = this;
        window.setTimeout(function () {
            _this.trigger.nativeElement.blur();
            _this.dropdown.hide();
            _this.cdr.detectChanges();
        }, 125);
    };
    SearchComponent.prototype.inputBlur = function () {
        var _this = this;
        window.setTimeout(function () {
            _this.dropdown.hide();
        }, 125);
    };
    Object.defineProperty(SearchComponent.prototype, "_items", {
        get: function () {
            return this.itemList;
        },
        set: function (newItems) {
            if (newItems &&
                this.itemList &&
                newItems.length === this.itemList.length) {
                return;
            }
            this.itemList = newItems;
            this.refresh(this.input);
        },
        enumerable: true,
        configurable: true
    });
    return SearchComponent;
}(Navigable));
export { SearchComponent };
