import { Model } from '../model';
import { ProwlerRule } from './prowler-rule';

export class ProwlerError extends Model {
    message: string;
    rule: ProwlerRule;
    createdAt: string;

    get entity(): string {
        return 'Error';
    }
}
