import { EventEmitter, OnDestroy } from '@angular/core';
import { interval } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import { IdService } from 'app/core/id.service';
var AlertnextComponent = /** @class */ (function () {
    function AlertnextComponent(id) {
        this.id = id;
        this.dismissed = new EventEmitter();
        this.timer$ = null;
    }
    AlertnextComponent.prototype.ngOnDestroy = function () {
        if (this.dismissing) {
            this.dismissing.unsubscribe();
        }
    };
    AlertnextComponent.prototype.timeout = function (seconds) {
        var _this = this;
        this.timer$ = interval(1000).pipe(map(function (n) { return seconds - n; }), takeWhile(function (n) { return n > 0; }), map(function (n) { return n.toString() + 's'; }));
        this.dismissing = this.timer$.subscribe(null, null, function () {
            _this.dismiss();
        });
    };
    AlertnextComponent.prototype.dismiss = function () {
        this.dismissed.emit();
    };
    Object.defineProperty(AlertnextComponent.prototype, "hasTitle", {
        get: function () {
            return typeof this.title === 'string';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlertnextComponent.prototype, "hasMessage", {
        get: function () {
            return typeof this.message === 'string';
        },
        enumerable: true,
        configurable: true
    });
    return AlertnextComponent;
}());
export { AlertnextComponent };
