import { ChangeDetectorRef, EventEmitter } from '@angular/core';
import { PublisherRepository } from 'app/core/repositories';
var NewsletterUploaderComponent = /** @class */ (function () {
    function NewsletterUploaderComponent(cdr, publisherRepository) {
        this.cdr = cdr;
        this.publisherRepository = publisherRepository;
        this.onUploaded = new EventEmitter();
        this.excelTemplate = {};
        this.errors = { parseErrors: [], others: [] };
        this._currentProgress = 0;
        this.progressInterval = null;
        this.isLoading = false;
        this.FILE_EXCEED_LIMIT_CODE = 'E1287';
    }
    NewsletterUploaderComponent.prototype.init = function (publisher) {
        this.publisher = publisher;
    };
    NewsletterUploaderComponent.prototype.upload = function (files) {
        this.resetErrors();
        this.excelTemplate.file = files[0];
    };
    NewsletterUploaderComponent.prototype.uploadError = function () {
        this.errors.others.push({
            title: 'Oh no! Upload failed',
            error: 'The selected file type is not supported. Please upload a XLSX file.'
        });
    };
    NewsletterUploaderComponent.prototype.resetErrors = function () {
        this.errors.parseErrors.length = 0;
        this.errors.others.length = 0;
    };
    NewsletterUploaderComponent.prototype.cancelFile = function () {
        if (this.errors.others.length > 0 || this.errors.parseErrors.length > 0) {
            this.resetErrors();
        }
        this.excelTemplate.file = false;
        this.excelTemplate.uploadedFileId = null;
        this.currentProgress = 0;
    };
    NewsletterUploaderComponent.prototype.uploadFile = function () {
        var _this = this;
        this.publisherRepository.inventoryUpload({
            id: this.publisher.id,
            file: this.excelTemplate.file.dataURL.split(',')[1]
        }).toPromise()
            .then(function () { return _this.onUploaded.emit(true); })
            .catch(function (err) {
            var resp = err.error;
            if (!(resp && resp.errors)) {
                _this.errors.others.push({ title: 'Oh no! Upload Failed', error: 'Please try again' });
            }
            else {
                if (resp.errors[0].id === _this.FILE_EXCEED_LIMIT_CODE) {
                    _this.errors.others.push({
                        title: 'Oh no! Your file is too big.',
                        error: 'We can only accept up to 200 rows in a single file.'
                    });
                }
                else {
                    _this.errors.parseErrors = resp.errors.map(function (error) { return _this.errorForDisplay(error); });
                }
            }
        })
            .then(function () {
            clearInterval(_this.progressInterval);
            _this.currentProgress = 100;
        });
        this.moveProgressBar();
    };
    NewsletterUploaderComponent.prototype.errorForDisplay = function (error) {
        var errorDetails = error.details.split(', ');
        var entityErrors = errorDetails[0].split(': ');
        return "Invalid " + entityErrors[0] + ": '" + entityErrors[1] + "', " + errorDetails[1] + ", " + errorDetails[2];
    };
    NewsletterUploaderComponent.prototype.downloadTemplate = function () {
        window.location.href = '../assets/templates/publisher_questionnaire_template_version3.0.xlsx';
    };
    NewsletterUploaderComponent.prototype.moveProgressBar = function () {
        var _this = this;
        this.progressInterval = setInterval(function (_) {
            _this.currentProgress = Math.random() * (99 - _this.currentProgress) + _this.currentProgress;
        }, 100);
    };
    Object.defineProperty(NewsletterUploaderComponent.prototype, "currentProgress", {
        get: function () {
            return this._currentProgress;
        },
        set: function (value) {
            this._currentProgress = value;
        },
        enumerable: true,
        configurable: true
    });
    NewsletterUploaderComponent.prototype.reset = function () {
        this.cancelFile();
        this.resetErrors();
    };
    return NewsletterUploaderComponent;
}());
export { NewsletterUploaderComponent };
