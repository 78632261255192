<div class="mav-container">
    <div class="mav-container-centered">
        <img src="/assets/images/sad-face.svg" alt="sad-face" height="320" class="spacer--large-bottom">
        <h1 class="spacer--xlarge-bottom">Fatal Error</h1>
        <h4 class="mav-text spacer--large-bottom bold">Whoops! The application has encountered a fatal error.</h4>
        <h4 class="mav-text ">Click the button below to clear the application cache and restart.</h4>
        <h4 class="mav-text mav-spacer--bottom">If the problem persists, please <a href="mailto:product@liveintent.zendesk.com">report a bug</a>.</h4>
        <button type="button" (click)="reload()" class="button--create">Reload</button>
    </div>
</div>
