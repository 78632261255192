import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'local-navigation',
    templateUrl: './local-navigation.html',
    styleUrls: ['./local-navigation.styl']
})
export class LocalNavigationComponent {
    @Input() collapsed: boolean = null;
    @Output() collapsedChange = new EventEmitter<boolean>();

    toggle () {
        this.collapsedChange.emit(this.collapsed === false);
    }
}
