import { Model } from './model';

export class Email extends Model {
    id: string;
    refId: number;
    newsletterId: number;
    publisherId: number;
    placementId: string;
    listId: string;
    keywords: Array<String>;
    fileURL: string;
    seenAt: Date;
    updatedAt: Date;

    serialize(): string {
        return JSON.stringify(this);
    }

    get entity(): string {
        return 'Email';
    }
}