import { Model } from './model';
import { Preferences } from './preferences';

export class User extends Model {
    confirmPassword?: string;
    email: string;
    firstName: string;
    lastName: string;
    name: string;
    password?: string;
    hashId: string;
    meta?: Preferences;
    two_factor_confirmed_at?: string;
    requires_mfa?: boolean;

    constructor(attributes: any = {}) {
        const hashId = typeof attributes.id === 'string' && attributes.id.length === 32
            ? attributes.id
            : (attributes.hash_id || attributes.hashId);

        const refId = typeof attributes.refId === 'number' ? attributes.refId : attributes.id;
        const id = refId;

        super(Object.assign(
            {
                firstName: (attributes.name || '').split(' ').slice(0, -1).join(' '),
                lastName: (attributes.name || '').split(' ').slice(-1).join(' ')
            },
            attributes,
            {
                hashId,
                refId,
                id
            }
        ));
    }

    get displayInitials(): string {
        if (this.firstName && this.lastName) {

            const fNameInitial = this.firstName.charAt(0).toUpperCase();
            const lNameInitial = this.lastName.charAt(0).toUpperCase();
            return fNameInitial + lNameInitial;
        }
    }

    serialize(): string {
        return JSON.stringify(this);
    }

    get entity() {
        return 'User';
    }
}
