import { EventEmitter } from '@angular/core';
import { ThemeService } from 'app/shared/helpers/theme.service';
var LightboxnextComponent = /** @class */ (function () {
    function LightboxnextComponent(theme) {
        this.theme = theme;
        this.shown = new EventEmitter();
        this.hidden = new EventEmitter();
        this.visibility = 'hidden';
        this.isLoading = false;
    }
    LightboxnextComponent.prototype.show = function () {
        this.visible = true;
        this.theme.lockPageScroll();
    };
    LightboxnextComponent.prototype.load = function (action) {
        if (action === void 0) { action = 'loading'; }
        this.action = action;
        this.isLoading = true;
    };
    LightboxnextComponent.prototype.stop = function () {
        this.isLoading = false;
    };
    LightboxnextComponent.prototype.hide = function () {
        if (this.isLoading) {
            return;
        }
        this.visible = false;
        this.hidden.emit();
        this.theme.unlockPageScroll();
    };
    Object.defineProperty(LightboxnextComponent.prototype, "visible", {
        set: function (visible) {
            this.visibility = visible ? 'visible' : 'hidden';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LightboxnextComponent.prototype, "isVisible", {
        get: function () {
            return this.visibility === 'visible';
        },
        enumerable: true,
        configurable: true
    });
    LightboxnextComponent.prototype.done = function (event) {
        var _this = this;
        var emitter = (function () {
            switch (event.toState) {
                case 'visible':
                    return _this.shown;
                case 'void':
                    return _this.hidden;
            }
            return null;
        })();
        if (emitter === null) {
            return;
        }
        emitter.emit(event);
    };
    return LightboxnextComponent;
}());
export { LightboxnextComponent };
