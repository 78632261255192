import { Directive, forwardRef, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import moment from 'moment';

@Directive({
    selector: '[min-date],[max-date]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DateValidator),
            multi: true
        }
    ]
})
export class DateValidator implements Validator {
    @Input('min-date') minDate: string;
    @Input('max-date') maxDate: string;

    validate(control: AbstractControl) {
        if (control.value) {
            const validation = {};

            if (this.minDate && moment(this.minDate).isAfter(control.value, 'day')) {
                validation['min-date'] = true;
            }

            if (this.maxDate && moment(this.maxDate).isBefore(control.value, 'day')) {
                validation['max-date'] = true;
            }

            return validation;
        }
    }
}
