import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BuyerSeat } from 'app/shared/models';
import { BackendRepository, PaginatedResponse } from './backend-repository';
import { map } from 'rxjs/operators';

@Injectable()
export class BuyerSeatRepository extends BackendRepository<BuyerSeat> {
    public constructor(http: HttpClient) {
        super(http, '/buyer', BuyerSeat);
    }

    public save(buyerSeat: BuyerSeat): Observable<BuyerSeat> {
        const params = [this.path];
        if (buyerSeat.id) {
            params.push(buyerSeat.id.toString());
        }
        return this.http.post(this.url(...params), buyerSeat.serialize())
            .pipe(map(response => this.build(response['output'])));
    }

    public searchIfaas(params: any): Observable<PaginatedResponse> {
        const path = `/search/buyer`;
        return this.http.post(this.url(path), this.sanitizeQuery(params))
            .pipe(map(data => ({
                items: data['output'].map(item => this.build(item)),
                page: 1,
                pages: data['pages'],
                total: data['total']
            })));
    }
}
