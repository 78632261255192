import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Seller } from 'app/shared/models';
import { BackendRepository } from './backend-repository';

@Injectable()
export class SellerRepository extends BackendRepository<Seller> {
    public constructor(http: HttpClient) {
        super(http, '/seller', Seller);
    }
}
