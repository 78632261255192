import { EventEmitter, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { IdService } from 'app/core/id.service';
import { CategoryRepository, SamovarAudienceRepository, SensitiveCategoryRepository } from 'app/core/repositories';
import { MediaGroup } from 'app/shared/models';
import { YieldManagement } from 'app/shared/services/yield-management.service';
import { AuthorizationService } from 'app/core';
var AUDIENCE_ID_TO_PREFIX = new Map();
var DemandBlockingComponent = /** @class */ (function () {
    function DemandBlockingComponent(yieldManagement, categoryRepository, samovarAudienceRepository, sensitiveCategoryRepository, auth, id) {
        this.yieldManagement = yieldManagement;
        this.categoryRepository = categoryRepository;
        this.samovarAudienceRepository = samovarAudienceRepository;
        this.sensitiveCategoryRepository = sensitiveCategoryRepository;
        this.auth = auth;
        this.id = id;
        this.allowIabCategoryBlocking = false;
        this.allowSensitiveCategoryBlocking = false;
        this.allowDomainTargeting = false;
        this.demandBlockingSettingsChangedEvent = new EventEmitter();
        this.audienceOptions$ = new ReplaySubject(1);
        this.showAdvertiserDomains = false;
        this.showCategoryBlacklist = false;
        this.showSensitiveCategoryBlocklist = false;
        this.showGlobalBlocklist = true;
        this.iabCategoriesOptions = [];
        this.sensitiveCategoriesOptions$ = new ReplaySubject(1);
        this.sensitiveCategoryTargetingOptions = [
            {
                "key": 0,
                "value": "block",
                "label": "Block"
            },
            {
                "key": 1,
                "value": "inherit",
                "label": "Inherit"
            }
        ];
    }
    Object.defineProperty(DemandBlockingComponent.prototype, "entity", {
        set: function (value) {
            if (value) {
                this._data = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DemandBlockingComponent.prototype, "mediaGroup", {
        set: function (value) {
            if (value) {
                this._mediaGroup = value;
                this.loadAudiences();
            }
        },
        enumerable: true,
        configurable: true
    });
    DemandBlockingComponent.prototype.ngOnInit = function () {
        this.showFields();
        this.loadSensitiveCategories();
        this.loadIABCategories();
        this.yieldManagement.loadDataForDomains('ymrtbd');
    };
    DemandBlockingComponent.prototype.ngOnDestroy = function () {
        this.yieldManagement.destroy();
    };
    /**
     * Show all fields that are not null on the entity
     */
    DemandBlockingComponent.prototype.showFields = function () {
        if (this.isTypePublisher()) {
            this.showAdvertiserDomains = this._data.targetedDomains.length > 0;
            this.showCategoryBlacklist = this._data.blocklistCategories.length > 0;
            this.showSensitiveCategoryBlocklist = this._data.sensitiveCategories.length > 0;
        }
        else {
            this.showAdvertiserDomains = this._data.domainTargeting && this._data.domainTargeting.type !== 'inherit';
            this.showSensitiveCategoryBlocklist = this._data.sensitiveCategoryTargeting && this._data.sensitiveCategoryTargeting.type !== 'inherit';
        }
    };
    /**
     * Respond to a progressive link being expanded or collapsed.
     * @param {string}  inputName
     * @param {boolean} expanded
     */
    DemandBlockingComponent.prototype.handleExpandedChange = function (inputName, expanded) {
        if (this.isTypePublisher()) {
            if (expanded === false) {
                this._data[inputName] = [];
            }
        }
        else {
            if (expanded === false) {
                switch (inputName) {
                    case 'targetedDomains':
                        this._data.domainTargeting.values = [];
                        this._data.domainTargeting.type = 'inherit';
                        break;
                    case 'blocklistCategories':
                        this._data.blocklistCategories = [];
                        break;
                    case 'sensitiveCategories':
                        this._data.sensitiveCategoryTargeting.values = [];
                        this._data.sensitiveCategoryTargeting.type = 'inherit';
                        break;
                }
            }
        }
    };
    Object.defineProperty(DemandBlockingComponent.prototype, "audienceId", {
        // Audience
        /**
         * We are only returning the first item because
         * the api returns an array of audiences. We want
         * to limit users to only select 1 audience through
         * the frontend.
         */
        get: function () {
            if (Array.isArray(this._data.audiences) && this._data.audiences[0]) {
                // This will be prefixed, so don't return the prefix, only return the audience id
                var audienceIdStr = this._data.audiences[0].split('.')[1];
                // And convert to number to match one of the dropdown options
                return Number(audienceIdStr);
            }
            return null;
        },
        set: function (audienceId) {
            this.addPrefixToThisPublishersAudience(audienceId);
            this.demandBlockingSettingsChangedEvent.emit(this._data);
        },
        enumerable: true,
        configurable: true
    });
    DemandBlockingComponent.prototype.addPrefixToThisPublishersAudience = function (audienceId) {
        if (audienceId) {
            var prefix = AUDIENCE_ID_TO_PREFIX.get(audienceId);
            this._data.audiences = [prefix + "." + audienceId];
        }
        else {
            this._data.audiences = null;
        }
    };
    DemandBlockingComponent.prototype.loadAudiences = function () {
        var _this = this;
        var mediaGroupId = this._mediaGroup ? this._mediaGroup.refId : null;
        var accountId = this._mediaGroup ? this._mediaGroup.accountId : null;
        this._accountId = accountId;
        if (mediaGroupId) {
            this.samovarAudienceRepository.searchByAccountId(accountId).subscribe(function (audiences) {
                var filteredAudiences = audiences.filter(function (audience) { return audience.matchCountTotal > 0; });
                filteredAudiences.forEach(function (audience) { return AUDIENCE_ID_TO_PREFIX.set(audience.uniqueSegmentId, audience.dataProvider.prefix); });
                _this.audienceOptions$.next(filteredAudiences.map(function (audience, i) {
                    var label = "(ID: " + audience.uniqueSegmentId + ") " + audience.name;
                    return {
                        label: label,
                        key: i,
                        value: audience.uniqueSegmentId,
                        data: audience
                    };
                }));
            });
        }
        else {
            this.audienceId = null;
            this.audienceOptions$.next(null);
        }
    };
    Object.defineProperty(DemandBlockingComponent.prototype, "targetedIabCategories", {
        // IAB Categories
        get: function () {
            if (this.isTypePublisher()) {
                return this._data.blocklistCategories;
            }
            return null;
        },
        set: function (iabCategories) {
            if (this.isTypePublisher()) {
                this._data.blocklistCategories = iabCategories;
            }
            this.demandBlockingSettingsChangedEvent.emit(this._data);
        },
        enumerable: true,
        configurable: true
    });
    DemandBlockingComponent.prototype.loadIABCategories = function () {
        var _this = this;
        this.categoryRepository.getPrimaryCategories()
            .pipe(function (categories) { return _this.categoryRepository.convertCategoriesToOptions(categories); })
            .subscribe(function (categories) { return _this.iabCategoriesOptions = categories; });
    };
    Object.defineProperty(DemandBlockingComponent.prototype, "targetedSensitiveCategories", {
        // Sensitive Categories
        get: function () {
            if (this.isTypePublisher()) {
                return this._data.sensitiveCategories;
            }
            else if (this._data.sensitiveCategoryTargeting) {
                return this._data.sensitiveCategoryTargeting.values;
            }
            return null;
        },
        set: function (sensitiveCategories) {
            if (this.isTypePublisher()) {
                this._data.sensitiveCategories = sensitiveCategories;
            }
            else {
                if (!this._data.sensitiveCategoryTargeting) {
                    this._data.sensitiveCategoryTargeting = {};
                }
                this._data.sensitiveCategoryTargeting.values = sensitiveCategories;
            }
            this.demandBlockingSettingsChangedEvent.emit(this._data);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DemandBlockingComponent.prototype, "sensitiveCategoryTargetingType", {
        get: function () {
            if (this._data.sensitiveCategoryTargeting) {
                return this._data.sensitiveCategoryTargeting.type;
            }
            return 'inherit';
        },
        set: function (type) {
            if (!this.isTypePublisher()) {
                if (!this._data.sensitiveCategoryTargeting) {
                    this._data.sensitiveCategoryTargeting = {};
                }
                this._data.sensitiveCategoryTargeting.type = type;
                this.demandBlockingSettingsChangedEvent.emit(this._data);
            }
        },
        enumerable: true,
        configurable: true
    });
    DemandBlockingComponent.prototype.loadSensitiveCategories = function () {
        var _this = this;
        this.sensitiveCategoryRepository.all()
            .pipe(function (sensitiveCategories) { return _this.sensitiveCategoryRepository.convertCategoriesToOptions(sensitiveCategories); })
            .subscribe(function (sensitiveCategories) { return _this.sensitiveCategoriesOptions$.next(sensitiveCategories); });
    };
    Object.defineProperty(DemandBlockingComponent.prototype, "targetedDomains", {
        // Domains
        get: function () {
            if (this.isTypePublisher()) {
                return this._data.targetedDomains;
            }
            else if (this._data.domainTargeting) {
                return this._data.domainTargeting.values;
            }
        },
        set: function (domains) {
            if (this.isTypePublisher()) {
                this._data.targetedDomains = domains;
            }
            else {
                if (!this._data.domainTargeting) {
                    this._data.domainTargeting = {};
                }
                this._data.domainTargeting.values = domains;
            }
            this.demandBlockingSettingsChangedEvent.emit(this._data);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DemandBlockingComponent.prototype, "domainTargetingType", {
        // Domain Targeting Type
        get: function () {
            if (this.isTypePublisher()) {
                return this._data.domainTargetingType;
            }
            else if (this._data.domainTargeting) {
                return this.transformTargetingType(this._data.domainTargeting.type);
            }
            return 'inherit';
        },
        set: function (value) {
            if (this.isTypePublisher()) {
                this._data.domainTargetingType = value;
            }
            else {
                if (!this._data.domainTargeting) {
                    this._data.domainTargeting = {};
                }
                this._data.domainTargeting.type = value;
            }
            this.demandBlockingSettingsChangedEvent.emit(this._data);
        },
        enumerable: true,
        configurable: true
    });
    DemandBlockingComponent.prototype.getFileName = function (postFix) {
        return this.type[0].toUpperCase() + this.type.slice(1) + ("" + this._data.refId) + (postFix ? "-" + postFix : '');
    };
    DemandBlockingComponent.prototype.isTypePublisher = function () {
        return this.type === 'publisher';
    };
    DemandBlockingComponent.prototype.transformTargetingType = function (type) {
        switch (type) {
            case 'block':
                return 'exclude';
            case 'allow':
                return 'include';
        }
        return type;
    };
    DemandBlockingComponent.prototype.isDomainTargetingTypeInherit = function () {
        return this.domainTargetingType && this.domainTargetingType === 'inherit';
    };
    DemandBlockingComponent.prototype.isSensitiveCategoryTargetingTypeInherit = function () {
        return this.sensitiveCategoryTargetingType && this.sensitiveCategoryTargetingType === 'inherit';
    };
    return DemandBlockingComponent;
}());
export { DemandBlockingComponent };
