import { Model } from './model';

export class GlobalBlocklist extends Model {
    public name: string;
    public domains: string[];
    public agencies: number[];

    public serialize(): string {
        delete this._isClone;
        return JSON.stringify(this);
    }

    get entity() {
        return 'Global Blocklist';
    }
}
