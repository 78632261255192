import * as tslib_1 from "tslib";
import { Model } from './model';
var native3PDsps;
(function (native3PDsps) {
    native3PDsps["BIDSWITCH_NATIVE_ID"] = "d1faedca409211e786aa22000a974651";
    native3PDsps["APPNEXUS_NATIVE_ID"] = "ed987af49ef211e789f2126374df4a7a";
    native3PDsps["TRADE_DESK_NATIVE_ID"] = "c41b2a04992011ea9377129c48bca6c4";
})(native3PDsps || (native3PDsps = {}));
var LIVEINTENT_NATIVE_DSP_ID = '4363607d53e411ea8716129c48bca6c4';
var RTBPartner = /** @class */ (function (_super) {
    tslib_1.__extends(RTBPartner, _super);
    function RTBPartner() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RTBPartner.prototype.serialize = function () {
        var rtbPartner = this.clone(RTBPartner);
        if (typeof rtbPartner.budget !== 'number' && rtbPartner.budget !== null) {
            delete rtbPartner.budget;
        }
        if (typeof rtbPartner.programmaticFee !== 'number') {
            delete rtbPartner.programmaticFee;
        }
        if (typeof rtbPartner.matchUrl !== 'string') {
            delete rtbPartner.matchUrl;
        }
        if (Array.isArray(rtbPartner.datacenters)) {
            rtbPartner.datacenters.forEach(function (datacenter) {
                if (!datacenter['geoTargets'] || datacenter['geoTargets'].length === 0) {
                    delete datacenter['geoTargets'];
                    datacenter['geoTargetingType'] = '';
                }
            });
        }
        if (Array.isArray(rtbPartner.creativeSizeTargets) &&
            rtbPartner.creativeSizeTargets.length < 1) {
            delete rtbPartner.creativeSizeTargets;
        }
        if (typeof rtbPartner.lossNotificationUrl === 'string'
            && rtbPartner.lossNotificationUrl.trim().length === 0) {
            rtbPartner.lossNotificationUrl = null;
        }
        if (!rtbPartner.ifaasUrlOne) {
            rtbPartner.ifaasUrlOne = null;
        }
        if (!rtbPartner.ifaasUrlTwo) {
            rtbPartner.ifaasUrlTwo = null;
        }
        if (!rtbPartner.requestTimeout) {
            delete rtbPartner.requestTimeout;
        }
        return JSON.stringify(rtbPartner);
    };
    Object.defineProperty(RTBPartner.prototype, "entity", {
        get: function () {
            return 'RTB Partner';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RTBPartner.prototype, "isNative3PDSP", {
        get: function () {
            return Object.values(native3PDsps).includes(this.id);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RTBPartner.prototype, "isLiNativeDSP", {
        get: function () {
            return LIVEINTENT_NATIVE_DSP_ID === this.id;
        },
        enumerable: true,
        configurable: true
    });
    RTBPartner.native3PDsps = native3PDsps;
    return RTBPartner;
}(Model));
export { RTBPartner };
