import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'adSlotSize'
})
export class AdSlotSizePipe implements PipeTransform {
    transform(sizes: any) {
        if (!sizes) {
            return 'Dynamic';
        }
        
        if (sizes === 'N/A') {
            return 'N/A';
        }

        let size: string = null;

        if (Array.isArray(sizes)) {
            size = sizes.filter(size => size.width !== null && size.height !== null)
                        .map(size => size.width + 'x' + size.height)
                        .sort((a, b) => {
                            const [widthA, heightA] = a.split('x').map(Number);
                            const [widthB, heightB] = b.split('x').map(Number);
                            return widthA - widthB || heightA - heightB;
                        })
                        .join(', ');
        }
        return size;
    }
}
