<ng-container *ngIf="auth.currentUser | async as user">
    <popover class="global-nav no-padding large shadow" event="click" [target]="target" [margin]="false">
        <div class="grid profile-popover">
            <div class="row">
                <div class="column--9">
                    <div class="user-info">
                        <h3 [attr.id]="id.header('profile', 'user-name')" class="ellipsis">{{user.name}}</h3>
                        <div [attr.id]="id.text('profile', 'user-email')" class="supporting">{{user.email}}</div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row profile-accounts">
                <div class="column--4">
                    <h4 [attr.id]="id.text('profile', 'user-accounts')" *ngIf="preferences.accounts | async as accounts">{{accounts.length}} {{'Account' | pluralize : accounts.length}}</h4>
                </div>
                <div class="column--8 align-right">
                    <button [attr.id]="id.control('profile', 'view-selected-accounts')" type="button" class="button--secondary button--small" (click)="viewSelectedAccounts()">View Selected Accounts</button>
                </div>
            </div>
            <div class="row">
                <div class="column--12">
                    <span [attr.id]="id.text('profile', 'need-help')">Need Help? Check out the </span>
                    <a [attr.id]="id.control('profile', 'knowledge-base')" href="https://support.liveintent.com/hc/en-us" target="_blank">Knowledge Base</a>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="column--12">
                    <a [attr.id]="id.control('profile', 'logout')" class="logout" (click)="logout()">
                        <span [attr.id]="id.text('profile', 'logout')">Log Out</span>
                        <span [attr.id]="id.text('profile', 'logout-user')" *ngIf="auth.isLoggedInAs">of {{user.name}}</span>
                    </a>
                </div>
            </div>
        </div>
    </popover>
</ng-container>
