import { Component, Input, ViewChild, ContentChild, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, of, combineLatest } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AdSlot } from 'app/shared/models/ad-slot';
import { AdSlotBulkEditComponent } from 'app/shared/components/ad-slot-bulk-edit';
import { AdSlotRepository, LineItemRepository } from 'app/core/repositories';
import { AuthorizationService, BulkResponseProcessor, NotificationsService } from 'app/core';
import { CampaignManagerDataService } from 'app/platform/campaign-manager/campaign-manager-data.service';
import { ConfirmDialogComponent } from 'app/shared/elements/confirm-dialog';
import { HistoryViewComponent } from 'app/shared/components/history-view';
import { IdService } from 'app/core/id.service';
import { InventoryDataService } from 'app/shared/services/inventory-data.service';
import { Newsletter } from 'app/shared/models/newsletter';
import { NewsletterFormComponent } from 'app/shared/components/newsletter-form';
import { NewsletterTagsComponent } from 'app/shared/components/newsletter-tags';
import { PaginatorComponent } from 'app/shared/elements/paginator';
import { Publisher } from 'app/shared/models';
import { SearchParams } from 'app/shared/helpers/query-builder';
import { TableComponent } from 'app/shared/elements/table';
import { TableHelper } from 'app/shared/helpers/table-helper';
import warnings from 'app/core/warnings.json';

enum AdSlotAction {
    Edit,
    ViewCode,
    EditName ,
    EditSize ,
    Archive ,
    Unarchive,
    ViewHistory,
    EditDemandSetting,
    EditExchangeFloor
}

@Component({
    selector: 'ad-slots-table',
    templateUrl: './ad-slots-table.html',
    styleUrls: ['./ad-slots-table.styl'],
    providers: [CampaignManagerDataService]
})
export class AdSlotsTableComponent implements OnInit {
    @ContentChild('actions', { static: true }) actionsTemplate: TemplateRef<any>;
    @ViewChild(AdSlotBulkEditComponent, { static: true }) bulkEditor: AdSlotBulkEditComponent;
    @ViewChild(TableComponent, { static: true }) table: TableComponent;
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent;
    @ViewChild('archiveDialog', { static: true }) archiveDialog: ConfirmDialogComponent;
    @ViewChild('unarchiveDialog', { static: true }) unarchiveDialog: ConfirmDialogComponent;
    @ViewChild(NewsletterFormComponent, { static: true }) newsletterForm: NewsletterFormComponent;
    @ViewChild(NewsletterTagsComponent, { static: true }) newsletterTags: NewsletterTagsComponent;
    @ViewChild(HistoryViewComponent, { static: true }) historyView: HistoryViewComponent;
    AdSlotAction = AdSlotAction;

    mediaGroupId: string;
    newsletterId: string;
    lineItemId: string;
    helper = new TableHelper<AdSlot>(params => this.adapter(params));
    warnings = warnings;

    defaultColumns: string[] = [
        'name',
        'status',
        'type',
        'linkedLineItems',
        'linkedBlueprints',
        'size',
        'collapsible',
        'displayStatus',
        'nativeStatus',
        'exchange',
        'exchangeFloorEffective',
        'rtb',
        'impressions',
        'ecpm',
        'revenue'
    ];

    constructor(
        private adSlotRepository: AdSlotRepository,
        public inventoryData: InventoryDataService,
        private notifications: NotificationsService,
        private lineItemRepository: LineItemRepository,
        private router: Router,
        public id: IdService,
        public authorizationService: AuthorizationService
    ) { }

    ngOnInit() {
        this.helper.table = this.table;
        this.helper.paginator = this.paginator;
        combineLatest(this.table.query, this.paginator.query).subscribe(args => this.helper.search(args));
    }

    @Input('media-group') set setMediaGroup(id: string) {
        this.mediaGroupId = id;
        this.refresh();
    }

    @Input('newsletter') set setNewsletter(id: string) {
        this.newsletterId = id;
        this.refresh();
    }

    @Input('default-columns') set setDefaultColumns(columns: string[]) {
        this.defaultColumns = columns;
        this.refresh();
    }

    @Input('line-item') set setLineItem(id: string) {
        this.lineItemId = id;
        this.refresh();
    }

    refresh() {
        this.paginator.reset();
        this.table.clearSelections();
        this.lineItemRepository.clearCache();
    }

    private adapter(params: SearchParams) {
        if (typeof this.mediaGroupId === 'string') {
            params.conditions.push({ field: 'mediaGroup', value: this.mediaGroupId });
        }

        if (typeof this.newsletterId === 'string') {
            params.conditions.push({ field: 'newsletter', value: this.newsletterId });
        }

        if (typeof this.lineItemId === 'string') {
            return this.adSlotRepository.searchByLineItem(this.lineItemId, params);
        } else {
            params.return = ['newsletterName', 'newsletterRefId', 'publisherRefId', 'sizes',
                'isDisplay', 'isNative', 'isHybrid', 'currentTagVersion', 'nativeBlueprints', 'status'];
            params.returnMode = 'appended';

            return this.adSlotRepository.asyncSearch(params);
        }
    }

    handle(action: AdSlotAction, adSlots: AdSlot[]) {
        switch (action) {
            case AdSlotAction.Edit:
                this.edit(adSlots);
                break;
            case AdSlotAction.ViewCode:
                this.viewCode(adSlots);
                break;
            case AdSlotAction.EditName:
                this.bulkEditor.open(adSlots, 'name');
                break;
            case AdSlotAction.EditSize:
                this.bulkEditor.open(adSlots, 'sizes');
                break;
            case AdSlotAction.ViewHistory:
                this.viewHistory(adSlots);
                break;
            case AdSlotAction.Archive:
                this.confirmArchive();
                break;
            case AdSlotAction.Unarchive:
                this.confirmUnarchive();
                break;
            case AdSlotAction.EditDemandSetting:
                this.bulkEditor.open(adSlots, 'sspControl.exchangeAllow');
                break;
            case AdSlotAction.EditExchangeFloor:
                this.bulkEditor.open(adSlots, 'sspControl.exchangeFloor');
                break;
        }
    }

    archive(adSlots: AdSlot[]) {
        this.archiveDialog.initiate(adSlots.length);

        const requests = adSlots.map(adSlot => {
            return this.adSlotRepository.archive(adSlot.id)
                .pipe(
                    tap(() => this.archiveDialog.postProgress()),
                    catchError(e => of(e))
                );
        });

        forkJoin(requests).subscribe(
            responses => this.handleArchived(adSlots, responses),
            () => this.handleGenericError()
        );
    }

    unarchive(adSlots: AdSlot[]) {
        this.unarchiveDialog.initiate(adSlots.length);

        const requests = adSlots.map(adSlot => {
            return this.adSlotRepository.unarchive(adSlot)
                .pipe(
                    tap(() => this.unarchiveDialog.postProgress()),
                    catchError(e => of(e))
                );
        });

        forkJoin(requests).subscribe(
            responses => this.handleUnarchived(adSlots, responses),
            () => this.handleGenericError()
        );
    }

    private edit(adSlots: AdSlot[]) {
        if (adSlots.length > 1) {
            this.notifications.error('Please select a single ad slot to edit at a time.');
            return;
        }

        this.router.navigate(['inventory-manager/ad-slots/' + adSlots[0].refId + '/edit', {
            redirect: btoa(this.router.url)
        }]);
    }

    private viewCode(adSlots: AdSlot[]) {
        if (adSlots.length > 1) {
            this.notifications.error('Please select a single ad slot to view code for at a time.');
            return;
        }

        const newsletter = new Newsletter({ id: adSlots[0].newsletter });
        this.newsletterTags.openModal(newsletter, adSlots[0].id);
    }

    private viewHistory(adSlots: AdSlot[]) {
        if (adSlots.length > 1) {
            this.notifications.error('Please select a single ad slot to view history for at a time.');
            return;
        }

        this.historyView.viewAdSlotHistory(adSlots[0]);
    }

    private confirmArchive() {
        this.archiveDialog.open('Archive');
    }

    private confirmUnarchive() {
        this.unarchiveDialog.open('Unarchive');
    }

    handleArchived(attempted: AdSlot[], responses: any[]) {
        const { status, failureItems, successItems } = (new BulkResponseProcessor()).processDeletes(attempted, responses);

        if (status === BulkResponseProcessor.AllOK) {
            this.notifications.success(
                `${successItems.length} ad slot${successItems.length === 1 ? ' was' : 's were'} successfully archived.`
            );
            this.archiveDialog.close();
            this.refresh();
            return;
        }

        if (status === BulkResponseProcessor.AllFailed) {
            this.notifications.error(
                `${failureItems.length} ad slot${failureItems.length === 1 ? ' was' : 's were'} not archived due to the following errors:
                <ul>${failureItems.join('')}</ul>`, '', 0
            );
            this.archiveDialog.close();
            return;
        }

        this.notifications.warning(
            `The following ad slots were successfully archived:
             <ul>${successItems.join('')}</ul>
             <br>
             However, the following ad slots were not archived:
            <ul>${failureItems.join('')}</ul>`, '', 0
        );
        this.archiveDialog.close();
        this.refresh();
    }

    handleUnarchived(attempted: AdSlot[], responses: any[]) {
        const { status, failureItems, successItems } = (new BulkResponseProcessor()).processDeletes(attempted, responses);

        if (status === BulkResponseProcessor.AllOK) {
            this.notifications.success(
                `${successItems.length} ad slot${successItems.length === 1 ? ' was' : 's were'} successfully unarchived.`
            );
            this.unarchiveDialog.close();
            this.refresh();
            return;
        }

        if (status === BulkResponseProcessor.AllFailed) {
            this.notifications.error(
                `${failureItems.length} ad slot${failureItems.length === 1 ? ' was' : 's were'} not unarchived due to the following errors:
                <ul>${failureItems.join('')}</ul>`, '', 0
            );
            this.unarchiveDialog.close();
            return;
        }

        this.notifications.warning(
            `The following ad slots were successfully unarchived:
             <ul>${successItems.join('')}</ul>
             <br>
             However, the following ad slots were not unarchived:
            <ul>${failureItems.join('')}</ul>`, '', 0
        );
        this.unarchiveDialog.close();
        this.refresh();
    }

    private handleGenericError() {
        this.notifications.error('An error has occurred.');
    }

    getLinkedLineItems(id: string) {
        return this.lineItemRepository.getByAdSlot(id);
    }

    canModify(adSlots: AdSlot[]): boolean {
        return adSlots.every(adSlot => this.authorizationService.canModifyMonetizationPublisher(adSlot.publisherClientTypes));
    }
}
