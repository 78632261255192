import { Component, Input } from '@angular/core';

@Component({
    selector: 'zebra-list',
    templateUrl: './zebra-list.html',
    styleUrls: ['./zebra-list.styl']
})
export class ZebraListComponent {
    @Input() columns: string[];
    @Input() rows: any[];
}
