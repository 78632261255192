import { Model } from './model';

export class PublisherIdentifier extends Model {
    name: string;
    label: string;
    isDefault: boolean;
    status: string;
    allow?: boolean;

    public serialize(): string {
        return JSON.stringify(this);
    }

    get entity() {
        return 'Publisher Identifier';
    }
}

export class ConsentControl {
    id: number;
    allow: boolean;

    constructor(publisherIdentifier: PublisherIdentifier) {
        this.id = parseInt(publisherIdentifier.id, 10);
        this.allow = publisherIdentifier.allow;
    }

    serialize(): string {
        return JSON.parse(JSON.stringify(this));
    }

    get entity(): string {
        return 'Consent Control';
    }
}
