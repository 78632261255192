import * as tslib_1 from "tslib";
import { Model } from './model';
var Preferences = /** @class */ (function (_super) {
    tslib_1.__extends(Preferences, _super);
    function Preferences(data) {
        if (data === void 0) { data = {}; }
        var _this = _super.call(this) || this;
        _this.advertiserIds = [];
        _this.mediaGroupIds = [];
        _this.watchedLineItemIds = [];
        Object.assign(_this, data);
        return _this;
    }
    Preferences.prototype.serialize = function () {
        return JSON.stringify(this);
    };
    Object.defineProperty(Preferences.prototype, "entity", {
        get: function () {
            return 'Preferences';
        },
        enumerable: true,
        configurable: true
    });
    return Preferences;
}(Model));
export { Preferences };
