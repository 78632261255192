import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { ReportingEmail } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
var ReportingEmailRepository = /** @class */ (function (_super) {
    tslib_1.__extends(ReportingEmailRepository, _super);
    function ReportingEmailRepository(http) {
        return _super.call(this, http, '/reporting/email', ReportingEmail) || this;
    }
    ReportingEmailRepository.prototype.emailReport = function (queryId, recipients) {
        return this.http.post(this.url(this.path), JSON.stringify({ queryId: queryId, recipients: recipients }), { responseType: 'text' });
    };
    return ReportingEmailRepository;
}(BackendRepository));
export { ReportingEmailRepository };
