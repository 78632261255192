<div class="search-container" #container>
    <div class="placeholder-icon">
        <input [attr.id]="id.control('global-search-input', 'search-input')" type="text" #search placeholder="Search..."
            (focus)="focus()" (blur)="blur()" (keyup.enter)="enter()" />
        <i class="fas fa-search fa-lg"></i>
    </div>
</div>
<popover #popover class="large shadow no-padding pull-left" [event]="null" [target]="container">
    <div class="popover--content" (mousedown)="mousedown($event)">
        <ng-container *ngIf="search.value.length > 1; else feedback">
            <ng-container *ngIf="results$ | async as results; else loading">
                <ng-container *ngIf="getESTotal(results) > 0">
                    
                        <div class="search-results--display">
                            <global-search-display [attr.id]="id.text('global-search-input', 'agencies')"
                                *ngIf="results.agencies" header="Agencies" [results]="results.agencies.entities"
                                [total]="getESTotal(results.agencies)">
                            </global-search-display>
                            <global-search-display [attr.id]="id.text('global-search-input', 'advertisers')"
                                *ngIf="results.advertisers" header="Advertisers"
                                [results]="results.advertisers.entities" [total]="getESTotal(results.advertisers)">
                            </global-search-display>
                            <global-search-display [attr.id]="id.text('global-search-input', 'insertion-orders')"
                                *ngIf="canViewInsertionOrders() && results.insertionOrders" header="Insertion Orders"
                                [results]="results.insertionOrders.entities"
                                [total]="getESTotal(results.insertionOrders)">
                            </global-search-display>
                            <global-search-display [attr.id]="id.text('global-search-input', 'campaigns')"
                                *ngIf="results.campaigns" header="Campaigns" [results]="results.campaigns.entities"
                                [total]="getESTotal(results.campaigns)">
                            </global-search-display>
                            <global-search-display [attr.id]="id.text('global-search-input', 'line-items')"
                                *ngIf="results.lineItems" header="Line Items" [results]="results.lineItems.entities"
                                [total]="getESTotal(results.lineItems)">
                            </global-search-display>
                            <global-search-display [attr.id]="id.text('global-search-input', 'creatives')"
                                *ngIf="results.creatives" header="Creatives" [results]="results.creatives.entities"
                                [total]="getESTotal(results.creatives)">
                            </global-search-display>
                            <global-search-display [attr.id]="id.text('global-search-input', 'media-groups')"
                                *ngIf="results.mediaGroups" header="Media Groups"
                                [results]="results.mediaGroups.entities" [total]="getESTotal(results.mediaGroups)">
                            </global-search-display>
                            <global-search-display [attr.id]="id.text('global-search-input', 'publishers')"
                                *ngIf="results.publishers" header="Publishers" [results]="results.publishers.entities"
                                [total]="getESTotal(results.publishers)">
                            </global-search-display>
                            <global-search-display [attr.id]="id.text('global-search-input', 'newsletters')"
                                *ngIf="results.newsletters" header="Newsletters"
                                [results]="results.newsletters.entities" [total]="getESTotal(results.newsletters)">
                            </global-search-display>
                            <global-search-display [attr.id]="id.text('global-search-input', 'ad-slots')"
                                *ngIf="results.adSlots" header="Ad Slots" [results]="results.adSlots.entities"
                                [total]="getESTotal(results.adSlots)">
                            </global-search-display>
                        </div>
                </ng-container>
                <ng-container *ngIf="!getESTotal(results)">
                    <div class="search-results--empty align-center">
                        <h4 [attr.id]="id.header('global-search-input', 'no-results')">
                            Sorry, there are no results to show.
                        </h4>
                    </div>
                </ng-container>
                <div class="align-center search-results--display-footer">
                    <a [attr.id]="id.control('global-search-input', 'view-all-results')" routerLink="/search"
                        [queryParams]="generateQueryParams()">View All Search Results</a>
                </div>
            </ng-container>
        </ng-container>
    </div>
</popover>
<ng-template #feedback>
    <div class="search-results--empty align-center">
        <h4 [attr.id]="id.header('global-search-input', 'start-typing')">
            Start typing to see results.
        </h4>
    </div>
</ng-template>
<ng-template #loading>
    <div class="search-results--loading align-center">
        <div class="spinner--small">
            <div class="grayDot1"></div>
            <div class="grayDot2"></div>
        </div>
        <h4 [attr.id]="id.header('global-search-input', 'searching')">
            Searching...
        </h4>
    </div>
</ng-template>
