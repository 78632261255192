import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Advertiser } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { map } from 'rxjs/operators';

@Injectable()
export class AdvertiserRepository extends BackendRepository<Advertiser> {
    public constructor(http: HttpClient) {
        super(http, '/advertiser', Advertiser);
    }

    public confirm(body: any) {
        const params = [this.path, 'confirm'];

        if (body.id) {
            params.push(body.id);
        }

        return this.http.post(this.url(...params), body.serialize());
    }

    public exchangeAdvertiserSearch(query: {}): Observable<Advertiser[]> {
        return this.http.post(this.url('/search/exchange-advertiser'), query)
            .pipe(map(response => response['output']
                .map(data => this.build(data)) as Advertiser[]));
    }
}
