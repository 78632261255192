import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { OperatingSystem } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
var OperatingSystemRepository = /** @class */ (function (_super) {
    tslib_1.__extends(OperatingSystemRepository, _super);
    function OperatingSystemRepository(http) {
        return _super.call(this, http, '/operating-system', OperatingSystem) || this;
    }
    return OperatingSystemRepository;
}(BackendRepository));
export { OperatingSystemRepository };
