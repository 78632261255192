import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ChangeDetectionStrategy, ElementRef } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { IdService } from 'app/core/id.service';
import { CategoryRepository, SamovarAudienceRepository, SensitiveCategoryRepository } from 'app/core/repositories';
import { Option } from 'app/shared/elements/dropdown';
import { MediaGroup } from 'app/shared/models';
import { YieldManagement } from 'app/shared/services/yield-management.service';
import { AuthorizationService } from 'app/core';

const AUDIENCE_ID_TO_PREFIX = new Map();

@Component({
    selector: 'demand-blocking',
    templateUrl: './demand-blocking.html',
    styleUrls: ['./demand-blocking.styl'],
    providers: [YieldManagement],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DemandBlockingComponent implements OnInit {
    @Input() type: string;
    @Input() set entity(value: any) {
        if (value) {
            this._data = value;
        }
    }
    @Input() allowIabCategoryBlocking: boolean = false;
    @Input() allowSensitiveCategoryBlocking: boolean = false;
    @Input() allowDomainTargeting: boolean = false;

    @Input() set mediaGroup(value: MediaGroup) {
        if (value) {
            this._mediaGroup = value;
            this.loadAudiences();
        }
    }
    @Output() demandBlockingSettingsChangedEvent = new EventEmitter<any>();

    audienceOptions$: ReplaySubject<Option[]> = new ReplaySubject<Option[]>(1);

    public showAdvertiserDomains: boolean = false;
    public showCategoryBlacklist: boolean = false;
    public showSensitiveCategoryBlocklist: boolean = false;
    public showGlobalBlocklist: boolean = true;

    public iabCategoriesOptions: Option[] = [];
    sensitiveCategoriesOptions$: ReplaySubject<Option[]> = new ReplaySubject<Option[]>(1);

    public sensitiveCategoryTargetingOptions: Option[] = [
        {
            "key": 0,
            "value": "block",
            "label": "Block"
        },
        {
            "key": 1,
            "value": "inherit",
            "label": "Inherit"
        }
    ];

    _mediaGroup: MediaGroup;
    _accountId: number;
    _data: any;

    constructor(
        private yieldManagement: YieldManagement,
        public categoryRepository: CategoryRepository,
        private samovarAudienceRepository: SamovarAudienceRepository,
        public sensitiveCategoryRepository: SensitiveCategoryRepository,
        public auth: AuthorizationService,
        public id: IdService
    ) {}

    ngOnInit(): void {
        this.showFields();
        this.loadSensitiveCategories();
        this.loadIABCategories();
        this.yieldManagement.loadDataForDomains('ymrtbd');
    }

    ngOnDestroy() {
        this.yieldManagement.destroy();
    }

    /**
     * Show all fields that are not null on the entity
     */
    public showFields(): void {
        if (this.isTypePublisher()) {
            this.showAdvertiserDomains = this._data.targetedDomains.length > 0;
            this.showCategoryBlacklist = this._data.blocklistCategories.length > 0;
            this.showSensitiveCategoryBlocklist = this._data.sensitiveCategories.length > 0;
        } else {
            this.showAdvertiserDomains = this._data.domainTargeting && this._data.domainTargeting.type !== 'inherit';
            this.showSensitiveCategoryBlocklist = this._data.sensitiveCategoryTargeting && this._data.sensitiveCategoryTargeting.type !== 'inherit';
        }
    }

    /**
     * Respond to a progressive link being expanded or collapsed.
     * @param {string}  inputName
     * @param {boolean} expanded
     */
    public handleExpandedChange(inputName: string, expanded: boolean): void {
        if (this.isTypePublisher()) {
            if (expanded === false) {
                this._data[inputName] = [];
            }
        } else {
            if (expanded === false) {
                switch (inputName) {
                    case 'targetedDomains':
                        this._data.domainTargeting.values = [];
                        this._data.domainTargeting.type = 'inherit';
                        break;
                    case 'blocklistCategories':
                        this._data.blocklistCategories = [];
                        break;
                    case 'sensitiveCategories':
                        this._data.sensitiveCategoryTargeting.values = [];
                        this._data.sensitiveCategoryTargeting.type = 'inherit';
                        break;
                }
            }
        }
    }

    // Audience
    /**
     * We are only returning the first item because
     * the api returns an array of audiences. We want
     * to limit users to only select 1 audience through
     * the frontend.
     */
    get audienceId(): number|null {
        if (Array.isArray(this._data.audiences) && this._data.audiences[0]) {
            // This will be prefixed, so don't return the prefix, only return the audience id
            const audienceIdStr = this._data.audiences[0].split('.')[1];
            // And convert to number to match one of the dropdown options
            return Number(audienceIdStr);
        }
        return null;
    }

    set audienceId(audienceId: number|null) {
        this.addPrefixToThisPublishersAudience(audienceId);
        this.demandBlockingSettingsChangedEvent.emit(this._data);
    }

    public addPrefixToThisPublishersAudience(audienceId: number) {
        if (audienceId) {
            const prefix = AUDIENCE_ID_TO_PREFIX.get(audienceId);
            this._data.audiences = [`${prefix}.${audienceId}`];
        } else {
            this._data.audiences = null;
        }
    }

    public loadAudiences() {
        let mediaGroupId = this._mediaGroup ? this._mediaGroup.refId : null;
        let accountId = this._mediaGroup? this._mediaGroup.accountId : null;
        this._accountId = accountId;

        if (mediaGroupId) {
            this.samovarAudienceRepository.searchByAccountId(accountId).subscribe(audiences => {
                const filteredAudiences = audiences.filter(audience => audience.matchCountTotal > 0);
                filteredAudiences.forEach(audience => AUDIENCE_ID_TO_PREFIX.set(audience.uniqueSegmentId, audience.dataProvider.prefix));
                this.audienceOptions$.next(filteredAudiences.map((audience, i) => {
                    let label = `(ID: ${audience.uniqueSegmentId}) ${audience.name}`;

                    return {
                        label,
                        key: i,
                        value: audience.uniqueSegmentId,
                        data: audience
                    };
                }));
            });
        } else {
            this.audienceId = null;
            this.audienceOptions$.next(null);
        }
    }

    // IAB Categories
    get targetedIabCategories(): number[]|null {
        if (this.isTypePublisher()) {
            return this._data.blocklistCategories;
        }
        return null;
    }

    set targetedIabCategories(iabCategories: number[]|null) {
        if (this.isTypePublisher()) {
            this._data.blocklistCategories = iabCategories;
        }
        this.demandBlockingSettingsChangedEvent.emit(this._data);
    }

    public loadIABCategories(): void {
        this.categoryRepository.getPrimaryCategories()
            .pipe(categories => this.categoryRepository.convertCategoriesToOptions(categories))
            .subscribe(categories => this.iabCategoriesOptions = categories);
    }

    // Sensitive Categories
    get targetedSensitiveCategories(): number[]|null {
        if (this.isTypePublisher()) {
            return this._data.sensitiveCategories;
        } else if (this._data.sensitiveCategoryTargeting) {
            return this._data.sensitiveCategoryTargeting.values;
        }
        return null;
    }

    set targetedSensitiveCategories(sensitiveCategories: number[]|null) {
        if (this.isTypePublisher()) {
            this._data.sensitiveCategories = sensitiveCategories;
        } else {
            if (!this._data.sensitiveCategoryTargeting) {
                this._data.sensitiveCategoryTargeting = {};
            }
            this._data.sensitiveCategoryTargeting.values = sensitiveCategories;
        }
        this.demandBlockingSettingsChangedEvent.emit(this._data);
    }

    get sensitiveCategoryTargetingType(): string {
        if (this._data.sensitiveCategoryTargeting) {
            return this._data.sensitiveCategoryTargeting.type;
        }
        return 'inherit';
    }

    set sensitiveCategoryTargetingType(type: string) {
        if (!this.isTypePublisher()) {
            if (!this._data.sensitiveCategoryTargeting) {
                this._data.sensitiveCategoryTargeting = {};
            }
            this._data.sensitiveCategoryTargeting.type = type;
            this.demandBlockingSettingsChangedEvent.emit(this._data);
        }
    }

    public loadSensitiveCategories(): void {
        this.sensitiveCategoryRepository.all()
            .pipe(sensitiveCategories => this.sensitiveCategoryRepository.convertCategoriesToOptions(sensitiveCategories))
            .subscribe(sensitiveCategories => this.sensitiveCategoriesOptions$.next(sensitiveCategories));
    }

    // Domains
    get targetedDomains(): Array<string> {
        if (this.isTypePublisher()) {
            return this._data.targetedDomains;
        } else if (this._data.domainTargeting) {
            return this._data.domainTargeting.values;
        }
    }

    set targetedDomains(domains: Array<string>) {
        if (this.isTypePublisher()) {
            this._data.targetedDomains = domains;
        } else {
            if (!this._data.domainTargeting) {
                this._data.domainTargeting = {};
            }
            this._data.domainTargeting.values = domains;
        }
        this.demandBlockingSettingsChangedEvent.emit(this._data);
    }

    // Domain Targeting Type
    get domainTargetingType(): string {
        if (this.isTypePublisher()) {
            return this._data.domainTargetingType;
        } else if (this._data.domainTargeting) {
            return this.transformTargetingType(this._data.domainTargeting.type);
        }
        return 'inherit';
    }

    set domainTargetingType(value: string) {
        if (this.isTypePublisher()) {
            this._data.domainTargetingType = value;
        } else {
            if (!this._data.domainTargeting) {
                this._data.domainTargeting = {};
            }
            this._data.domainTargeting.type = value;
        }
        this.demandBlockingSettingsChangedEvent.emit(this._data);
    }

    getFileName(postFix: string|null): string {
        return this.type[0].toUpperCase() + this.type.slice(1) + `${this._data.refId}` + (postFix ? `-${postFix}` : '');
    }

    isTypePublisher(): boolean {
        return this.type === 'publisher';
    }

    transformTargetingType(type: string): string {
        switch(type) {
            case 'block':
                return 'exclude';
            case 'allow':
                return 'include';
        }
        return type;
    }

    isDomainTargetingTypeInherit(): boolean {
        return this.domainTargetingType && this.domainTargetingType === 'inherit';
    }

    isSensitiveCategoryTargetingTypeInherit(): boolean {
        return this.sensitiveCategoryTargetingType && this.sensitiveCategoryTargetingType === 'inherit';
    }
}
