import { QueryList, AfterContentChecked } from '@angular/core';
import { TaskComponent } from './task.component';
var TaskProgressComponent = /** @class */ (function () {
    function TaskProgressComponent() {
    }
    Object.defineProperty(TaskProgressComponent.prototype, "complete", {
        get: function () {
            var complete = 0;
            this.tasks.forEach(function (task) {
                if (task.complete) {
                    complete++;
                }
            });
            return this.tasks.length > 1 ? (complete - 1) / (this.tasks.length - 1) * 100 : 0;
        },
        enumerable: true,
        configurable: true
    });
    TaskProgressComponent.prototype.ngAfterContentChecked = function () {
        var currentTask = false;
        this.tasks.forEach(function (task) {
            if (!task.complete && !currentTask) {
                task._currentTask = currentTask = true;
            }
            else {
                task._currentTask = false;
            }
        });
    };
    return TaskProgressComponent;
}());
export { TaskProgressComponent };
