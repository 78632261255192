import { Injectable } from '@angular/core';
import { Subject, ReplaySubject, Subscription } from 'rxjs';
import { AdSlotRepository, ReportingQueryRepository } from 'app/core/repositories';
import { debounceTime, bufferWhen, filter, map } from 'rxjs/operators';

type YieldData = {
    volume: number;
    domain: string;
};

type ReportingDataPoint = {
    event: YieldData;
};

@Injectable()
export class YieldManagement {
    cache = new Map<string, Subject<YieldData>>();
    domains$ = new ReplaySubject<string[]>(1);
    load$ = new Subject<string>();
    data: Subscription;

    constructor(
        public adSlotRepository: AdSlotRepository,
        private reporting: ReportingQueryRepository
    ) {
        this.data = this.load$.pipe(
            bufferWhen(() => this.load$.pipe(debounceTime(1000))),
            filter(domains => domains.length > 0)
        ).subscribe(this.domains$);
    }

    destroy() {
        if (this.data) {
            this.data.unsubscribe();
            this.data = null;
        }
    }

    loadDataForDomains(report: string) {
        return this.domains$.subscribe(domains => {
            const data = {
                report: report,
                filters: [ { type: 'in', dimension: 'rtb_domain', values: domains } ]
            };
            return this.reporting.executeCannedReport(data).subscribe((data: ReportingDataPoint[]) => this.processYieldData(data));
        });
    }

    getHistoricVolume(domain: string) {
        return this.lookup(domain).pipe(map(data => data.volume));
    }

    private processYieldData(data: ReportingDataPoint[]) {
        for (let point of data) {
            const domain$ = this.lookup(point.event.domain);
            domain$.next(point.event);
        }
    }

    private lookup(domain: string) {
        let domain$ = this.cache.get(domain);

        if (!domain$) {
            this.load$.next(domain);
            domain$ = new ReplaySubject<YieldData>(1);
        }

        this.cache.set(domain, domain$);
        return domain$;
    }
}
