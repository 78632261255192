import * as tslib_1 from "tslib";
import { Model } from './model';
var StrategyCard = /** @class */ (function (_super) {
    tslib_1.__extends(StrategyCard, _super);
    function StrategyCard() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StrategyCard.prototype.serialize = function () {
        return JSON.stringify(this);
    };
    Object.defineProperty(StrategyCard.prototype, "entity", {
        get: function () {
            return 'Strategy Card';
        },
        enumerable: true,
        configurable: true
    });
    StrategyCard.FIRST_PRICE_CPM_MAX_CLICKTHRU = 1;
    StrategyCard.PERFORMANCE_MAXIMIZE_CONVERSIONS_DAILY_CAP = 105;
    StrategyCard.PERFORMANCE_MAXIMIZE_CONVERSIONS = 110;
    return StrategyCard;
}(Model));
export { StrategyCard };
