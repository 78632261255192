import { Directive, ElementRef, Renderer2, HostListener, OnInit, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';

@Directive({
    selector: '[currency-input]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CurrencyInputDirective),
            multi: true
        }
    ]
})
export class CurrencyInputDirective implements OnInit, ControlValueAccessor {
    private static CURRENCY: string = 'USD';
    private static CURRENCY_SYMBOL: string = '$';

    @Input('currencyFormat')
    numberFormat: string = '1.2-2';

    private usdPipe: CurrencyPipe = null;
    private value: number = null;
    private _placeholder: string;

    constructor (private el: ElementRef, private renderer: Renderer2) {
        this.usdPipe = new CurrencyPipe('en-US');
    }

    ngOnInit () {
        this.el.nativeElement.placeholder = this.placeholder;
    }

    private propagate = null;
    private onTouchedCallback: () => void = null;

    writeValue (value) {
        if (value === null || value === undefined) {
            this.renderer.setStyle(this.el.nativeElement, 'text-align', 'left');
            this.reset();
            return;
        }
        this.renderer.setStyle(this.el.nativeElement, 'text-align', 'right');
        let input = this.el.nativeElement;
        this.value = value;
        input.value = this.transformInput(this.value.toString());
    }

    registerOnChange (fn) {
        this.propagate = fn;
    }

    registerOnTouched (fn) {
        this.onTouchedCallback = fn;
    }

    @HostListener('focus')
    activate() {
        let input = this.el.nativeElement;
        input.value = this.value;
        input.placeholder = '';
        this.renderer.setStyle(this.el.nativeElement, 'text-align', 'right');
    }

    @HostListener('blur')
    deactivate(): void {

        let input = this.el.nativeElement;

        if (input.value.length > 0) {
            let numericValueAsString = input.value.replace(/[^0-9\.]+/g, '');
            if (numericValueAsString.length < 1) {
                this.reset();
            } else {
                input.value = this.transformInput(numericValueAsString);
                this.value = parseFloat(input.value.replace(/[,$]/g, ''));
            }
        } else {
            this.reset();
        }
        this.propagate(this.value);
        this.onTouchedCallback();
    }

    reset(): void {
        this.el.nativeElement.value = null;
        this.el.nativeElement.placeholder = this.placeholder;
        this.renderer.setStyle(this.el.nativeElement, 'text-align', 'left');
        this.value = null;
    }

    transformInput(value: string): string {
        if (value.length < 1) {
            return '';
        }
        return this.usdPipe.transform(value, CurrencyInputDirective.CURRENCY, 'symbol', this.numberFormat);
    }

    @Input('placeholder')
    set placeholder(placeholder) {
        this._placeholder = placeholder;
        this.el.nativeElement.placeholder = this.placeholder;
    }

    get placeholder() {
        return this._placeholder ? this._placeholder : CurrencyInputDirective.CURRENCY_SYMBOL;
    }

    @Input('disabled')
    set disabled(isDisabled: boolean) {
        this.el.nativeElement.disabled = isDisabled;
    }
}
