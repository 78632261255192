import { Filter } from 'app/shared/elements/filters';
import { PaginatorQuery } from 'app/shared/elements/paginator';
import { TableQuery } from 'app/shared/elements/async-table';
import { ESParams, ESQueryBuilder } from './es-query-builder';

export class ESSimpleQueryBuilder extends ESQueryBuilder {
    /**
     * Convert a `TableQuery` into `SearchParams`, depending on
     * backend of the search.
     */
    build<T extends TableQuery & PaginatorQuery>(tableQuery: T): ESParams {
        return {
            page: tableQuery.page,
            n: tableQuery.pageSize,
            orderBy: tableQuery.orderBy,
            sort: tableQuery.direction,
            query: this.buildSimpleQueryString(tableQuery.filters || [])
        };
    }

    /**
     * Convert `filters` into an elasticsearch `query_string`.
     */
    private buildSimpleQueryString(filters: Filter[]) {
        // validate filters
        const validFilters = filters.filter(filter => filter.isValid());

        // recursively build any nested filters
        const expressions = validFilters.map(filter => {
            if (Array.isArray(filter.filters)) {
                return this.buildSimpleQueryString(filter.filters);
            }

            return this.buildExpression(filter);
        });

        // apply AND between different expressions
        return this.and(expressions);
    }

    /**
     * Flatten a list of expressions into a single expression with `AND` operators
     * between each of the original expressions.
     */
    and(expressions: string[]) {
        return expressions.map(expression => this.parenthesize(expression)).join(' + ');
    }

    /**
     * Build an elasticsearch expression based on a `Filter`.
     */
    protected buildExpression(filter: Filter) {
        return this.sanitize(filter.query);
    }
}
