import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OperatingSystem } from 'app/shared/models';
import { BackendRepository } from './backend-repository';

@Injectable()
export class OperatingSystemRepository extends BackendRepository<OperatingSystem> {
    public constructor(http: HttpClient) {
        super(http, '/operating-system', OperatingSystem);
    }
}
