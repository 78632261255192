import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { BudgetType } from 'app/shared/models/campaign';

@Pipe({
    name: 'budget'
})
export class BudgetPipe implements PipeTransform {
    private static locale = 'en-US';
    private static currencyDigits = '1.2-2';
    private static currencyCode = 'USD';
    private static defaultDigits = '1.0-0';
    private static symbolDisplay: 'code' | 'symbol' | 'symbol-narrow' = 'symbol';

    currency = new CurrencyPipe(BudgetPipe.locale);
    decimal = new DecimalPipe(BudgetPipe.locale);

    transform(value: any, type: BudgetType = 'currency') {
        if (isNaN(value)) {
            return '—';
        }

        if (type === 'currency') {
            return this.currency.transform(
                value,
                BudgetPipe.currencyCode,
                BudgetPipe.symbolDisplay,
                BudgetPipe.currencyDigits
            );
        }

        return this.decimal.transform(value, BudgetPipe.defaultDigits);
    }
}
