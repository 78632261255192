import { Injectable } from '@angular/core';
import store from 'store';
import expirePlugin from 'store/plugins/expire';

store.addPlugin(expirePlugin);

@Injectable()
export class StoreService {
    set(key: string, value: any, expiration?: number) {
        store.set(key, value, expiration);
    }

    get(key: string) {
        return store.get(key);
    }

    remove(key: string) {
        store.remove(key);
    }
}
