import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitalizeFirstLetter'
})
export class CapitalizeFirstLetterPipe implements PipeTransform {
    transform(word: string|null): string {
        if (!word) {
            return '';
        }

        if (word.length === 1) {
            return word.toUpperCase();
        }

        return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
    }
}
