import { Filter } from '../filter';
import { Operation } from '../operation';

export class FilterId extends Filter {
    private _query: number;

    readonly operations: Operation[] = [
        Operation.Equals,
        Operation.DoesNotEqual
    ];

    isValid() {
        return super.isValid() && this.query > 0;
    }

    set query(value: any) {
        const parsed = parseInt(value);

        if (!isNaN(parsed)) {
            this._query = parsed;
        }
    }

    get query() {
        return this._query;
    }
}
