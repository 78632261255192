import { ElementRef, EventEmitter } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { DropdownComponent, OutsideClickHelper } from '../dropdown';
import moment from 'moment';
import { IdService } from 'app/core';
var Type;
(function (Type) {
    Type[Type["Simple"] = 0] = "Simple";
    Type[Type["Range"] = 1] = "Range";
    Type[Type["Hours"] = 2] = "Hours";
    Type[Type["Days"] = 3] = "Days";
})(Type || (Type = {}));
var MultiSelectComponent = /** @class */ (function () {
    function MultiSelectComponent(placeholder, type, el, id) {
        var _this = this;
        this.placeholder = placeholder;
        this.el = el;
        this.id = id;
        this.disabled = false;
        this.dismissedEvent = new EventEmitter();
        this._options = [];
        this.model = { values: {} };
        this.type = Type.Simple;
        if (type === 'range') {
            this.type = Type.Range;
        }
        else if (type === 'hours') {
            this.type = Type.Hours;
        }
        else if (type === 'days') {
            this.type = Type.Days;
        }
        this._outsideClickTarget = new OutsideClickHelper(this.el.nativeElement, function () {
            _this.dismissedEvent.emit(true);
        });
    }
    Object.defineProperty(MultiSelectComponent.prototype, "options", {
        get: function () {
            return this._options;
        },
        set: function (options) {
            var _this = this;
            this._options = options;
            this._outsideClickTarget.target = this.dropdown;
            this.model = { values: {} };
            options.forEach(function (opt) {
                var property = opt.value.toString();
                if (typeof opt.disabled === 'undefined') {
                    opt.disabled = false;
                }
                Object.defineProperty(_this.model, property, {
                    configurable: true,
                    set: function (value) {
                        _this.model.values[property] = value;
                        _this.change(_this.selected);
                    },
                    get: function () {
                        return _this.model.values[property];
                    }
                });
            });
        },
        enumerable: true,
        configurable: true
    });
    MultiSelectComponent.prototype.writeValue = function (value) {
        if (!Array.isArray(value)) {
            value = [];
        }
        for (var i = 0; i < this._options.length; i++) {
            var property = this._options[i].value;
            this.model.values[property.toString()] = value.indexOf(property) > -1;
        }
    };
    MultiSelectComponent.prototype.registerOnChange = function (fn) {
        this.change = fn;
    };
    MultiSelectComponent.prototype.registerOnTouched = function (fn) { };
    Object.defineProperty(MultiSelectComponent.prototype, "label", {
        get: function () {
            if (this.selected.length < 1) {
                return this.placeholder;
            }
            if (this.type === Type.Days) {
                return this.days();
            }
            if (this.type === Type.Hours) {
                return this.hours();
            }
            if (this.type === Type.Range) {
                return this.range();
            }
            return this.getValueLabel();
        },
        enumerable: true,
        configurable: true
    });
    MultiSelectComponent.prototype.optionLabel = function (label) {
        if (this.type === Type.Range) {
            if (label.length > 1) {
                return label.join('-');
            }
            else {
                return label + '+';
            }
        }
        if (this.type === Type.Hours) {
            return moment.unix(label[0]).utc().format('hA');
        }
        return label;
    };
    MultiSelectComponent.prototype.getValueLabel = function () {
        var _this = this;
        return this.selected.map(function (value) {
            return _this.option(value).label;
        }).join(', ');
    };
    MultiSelectComponent.prototype.days = function () {
        if (this.selected.length === 7) {
            return 'Everyday';
        }
        else {
            switch (this.selected.toString()) {
                case MultiSelectComponent.weekdays.toString():
                    return 'Weekdays';
                case MultiSelectComponent.weekends.toString():
                    return 'Weekends';
                default: return this.getValueLabel();
            }
        }
    };
    MultiSelectComponent.prototype.hours = function () {
        var _this = this;
        var ranges = this.selected.map(function (key) { return _this.option(key).label; });
        var options = this.options.map(function (option) { return option.label; });
        var groups = this.findRangeGroups(options, ranges);
        return groups.map(function (group) {
            return group.reduce(function (previous, current, i, group) {
                var a = current[0];
                var b = current[1];
                if (b === undefined) {
                    a += '+';
                }
                if (i === 0) {
                    previous.push(a);
                }
                if (i === group.length - 1) {
                    if (b !== undefined) {
                        previous.push(b);
                    }
                    else if (group.length > 1) {
                        previous.push(a);
                    }
                }
                return previous;
            }, []).map(function (seconds) { return moment.unix(seconds).utc().format('hA'); }).join('-');
        }).join(', ');
    };
    MultiSelectComponent.prototype.range = function () {
        var _this = this;
        var ranges = this.selected.map(function (key) { return _this.option(key).label; });
        if (ranges && ranges[0] && Array.isArray(ranges[0]) && ranges[0][0]) {
            // Example: [[65], [18,20]]
            // After sorting: [[18,20], [65]];
            ranges.sort(function (a, b) { return a[0] - b[0]; });
        }
        var options = this.options.map(function (option) { return option.label; });
        var groups = this.findRangeGroups(options, ranges);
        return groups.map(function (group) {
            return group.reduce(function (previous, current, i, group) {
                var a = current[0];
                var b = current[1];
                if (b === undefined) {
                    a += '+';
                }
                if (i === 0) {
                    previous.push(a);
                }
                if (i === group.length - 1) {
                    if (b !== undefined) {
                        previous.push(b);
                    }
                    else if (group.length > 1) {
                        previous.push(a);
                    }
                }
                return previous;
            }, []).join('-');
        }).join(', ');
    };
    MultiSelectComponent.prototype.findRangeGroups = function (options, ranges) {
        var local;
        var groups = [];
        for (var i = 0; i < ranges.length; i++) {
            var range = ranges[i];
            if (local === undefined) {
                local = [range];
                continue;
            }
            var tail = local[local.length - 1];
            var j = options.indexOf(tail);
            var k = options.indexOf(range);
            if (j + 1 === k) {
                local.push(range);
            }
            else {
                groups.push(local.slice());
                local = [range];
            }
        }
        if (local !== undefined) {
            groups.push(local);
        }
        return groups;
    };
    Object.defineProperty(MultiSelectComponent.prototype, "selected", {
        get: function () {
            var _this = this;
            return Object.keys(this.model.values)
                .filter(function (item) { return _this.model.values[item] === true; }).map(function (item) {
                var val = item;
                if (item.length === 32 || !_this.isNumeric(val)) {
                    return item;
                }
                return parseInt(val);
            });
        },
        enumerable: true,
        configurable: true
    });
    MultiSelectComponent.prototype.option = function (value) {
        return this.options.find(function (opt) { return opt.value === value; }) || null;
    };
    // from https://github.com/jquery/jquery/blob/master/src/core.js
    MultiSelectComponent.prototype.isNumeric = function (value) {
        return !isNaN(value - parseFloat(value));
    };
    MultiSelectComponent.weekends = [0, 6];
    MultiSelectComponent.weekdays = [1, 2, 3, 4, 5];
    return MultiSelectComponent;
}());
export { MultiSelectComponent };
