import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ElementRef, EventEmitter, OnInit, QueryList, AfterContentInit } from '@angular/core';
import { DropdownComponent, Navigable, OutsideClickHelper } from '../dropdown';
import { OptionComponent } from '../filter-select';
import { IdService } from 'app/core/id.service';
var RowActionsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RowActionsComponent, _super);
    function RowActionsComponent(el, cdr, id, maxRowActions) {
        var _this = _super.call(this, cdr) || this;
        _this.el = el;
        _this.cdr = cdr;
        _this.id = id;
        _this.contentOptions = null;
        _this._options = null;
        _this._maxRowActions = 2;
        _this.clickedAction = new EventEmitter();
        _this._outsideClickTarget = null;
        _this.dropdownPos = { top: 0, left: 0 };
        if (typeof maxRowActions === 'string') {
            _this.maxRowActions = parseInt(maxRowActions);
        }
        _this._outsideClickTarget = new OutsideClickHelper(el.nativeElement);
        return _this;
    }
    Object.defineProperty(RowActionsComponent.prototype, "maxRowActions", {
        get: function () {
            return this._maxRowActions;
        },
        set: function (num) {
            if (num) {
                this._maxRowActions = num;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RowActionsComponent.prototype, "options", {
        get: function () {
            return this._options;
        },
        set: function (opts) {
            opts.forEach(function (option) { return option.disabled = option.disabled || false; });
            if (JSON.stringify(opts) !== JSON.stringify(this._options)) {
                this._options = opts;
                if (this.options.length <= this.maxRowActions) {
                    this.linkOptions = this.options;
                }
                if (this.options.length > this.maxRowActions) {
                    this.linkOptions = this.options.slice(0, this.maxRowActions);
                    this.dropdownOptions = this.options.slice(this.maxRowActions);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    RowActionsComponent.prototype.findOptionByAction = function (action) {
        return this._options.find(function (opt) { return opt.action === action; });
    };
    RowActionsComponent.prototype.ngOnInit = function () {
        this._outsideClickTarget.target = this.dropdown;
    };
    RowActionsComponent.prototype.ngAfterContentInit = function () {
        if (this.contentOptions.length > 0) {
            this.options = this.contentOptions.map(function (option, key) { return ({
                key: key,
                value: option.value,
                label: option.html
            }); });
        }
    };
    RowActionsComponent.prototype.dropdownVisChange = function (opened) {
        this.el.nativeElement.classList[opened ? 'add' : 'remove']('opened');
    };
    RowActionsComponent.prototype.clickAction = function (event, action) {
        if (this.options.find(function (opt) { return opt.value === action; }).disabled) {
            return;
        }
        this.clickedAction.emit(action);
        event.stopPropagation();
        this._outsideClickTarget.deactivate();
        this.cdr.detectChanges();
    };
    RowActionsComponent.prototype.clickDropdownAction = function (event, action) {
        this.clickedAction.emit(action);
        event.stopPropagation();
        this.dropdown.toggle();
        this._outsideClickTarget.deactivate();
        this.cdr.detectChanges();
    };
    RowActionsComponent.prototype.clickedMore = function (_a) {
        var pageY = _a.pageY, pageX = _a.pageX;
        this.dropdownPos = { top: pageY, left: pageX };
        this.dropdown.toggle();
        this._outsideClickTarget.activate();
    };
    RowActionsComponent.prototype.setOptionLabel = function (action, label) {
        this.findOptionByAction(action).label = label;
    };
    RowActionsComponent.prototype.disableOption = function (action) {
        this.findOptionByAction(action).disabled = true;
    };
    RowActionsComponent.prototype.enableOption = function (action) {
        this.findOptionByAction(action).disabled = false;
    };
    return RowActionsComponent;
}(Navigable));
export { RowActionsComponent };
