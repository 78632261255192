<div class="row-actions">
    <ul class="links">
        <li *ngFor="let option of linkOptions; let i = index" [class.no-border]="options.length <= maxRowActions && i === options.length - 1">
            <a [id-append]="[option.label]" class="small"
               [class.disabled]="option.disabled"
               (click)="clickAction($event, option.value)">{{option.label}}</a>
        </li>
        <li [class.hidden]="options.length <= maxRowActions">
            <a [attr.id]="id.control('row-actions', 'links', 'more-link')" class="small" #trigger (keydown)="keydown($event)" (click)="clickedMore($event)">
                <span>More <i class="fas fa-chevron-down"></i></span>
            </a>
        </li>
    </ul>
    <app-portal [top]="dropdownPos.top" [left]="dropdownPos.left">
        <dropdown (visibility)="dropdownVisChange($event)" class="dropdown align-right" [trigger]="trigger">
            <ul>
                <li *ngFor="let option of dropdownOptions; let i = index"
                    [class.dropdown--item---index]="key === option.key"
                    class="align-left"
                    (mouseenter)="mouseenter($event, option.key)"
                    (click)="clickDropdownAction($event, option.value)">
                    <a [attr.id]="id.control('row-actions', 'more-options', i)" [class.disabled]="option.disabled">
                        <span [attr.id]="id.control('row-actions', option.label, i)">{{option.label}}</span>
                    </a>
                </li>
            </ul>
        </dropdown>
    </app-portal>
</div>
