import { Operation } from "./operation";

export class BulkOperationRequest {

    constructor(private operations: Operation[]) {}

    public serialize(): string {
        return JSON.stringify(this);
    }
}
