import { EventEmitter, ChangeDetectorRef } from '@angular/core';
import { LightboxComponent } from 'app/shared/elements/lightbox';
import { LineItemsTableComponent } from 'app/shared/components/line-items-table';
import { IdService } from 'app/core/id.service';
var LinkedLineItemsLightboxComponent = /** @class */ (function () {
    function LinkedLineItemsLightboxComponent(cdr, id) {
        this.cdr = cdr;
        this.id = id;
        this.submit = new EventEmitter();
        this.entities = [];
        this.lineItemIds = [];
        this.action = null;
        this.progress = null;
    }
    LinkedLineItemsLightboxComponent.prototype.open = function (entities, lineItems, action) {
        if (action === void 0) { action = null; }
        this.action = action;
        this.entities = Array.isArray(entities) ? entities : [entities];
        this.lineItemIds = lineItems.map(function (lineItem) { return lineItem.id; });
        this.lightbox.open();
    };
    LinkedLineItemsLightboxComponent.prototype.close = function () {
        this.lightbox.close();
        this.progress = null;
    };
    Object.defineProperty(LinkedLineItemsLightboxComponent.prototype, "hasAction", {
        get: function () {
            return this.action !== null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LinkedLineItemsLightboxComponent.prototype, "selected", {
        get: function () {
            return this.table.table.selectedItems;
        },
        enumerable: true,
        configurable: true
    });
    LinkedLineItemsLightboxComponent.prototype.emit = function () {
        this.submit.emit({
            entities: this.entities,
            selected: this.selected
        });
    };
    LinkedLineItemsLightboxComponent.prototype.initiate = function () {
        this.progress = 0;
    };
    LinkedLineItemsLightboxComponent.prototype.postProgress = function () {
        this.progress++;
        this.cdr.detectChanges();
    };
    Object.defineProperty(LinkedLineItemsLightboxComponent.prototype, "isLoading", {
        get: function () {
            return Array.isArray(this.selected) && this.progress !== null
                && this.selected.length !== this.progress;
        },
        enumerable: true,
        configurable: true
    });
    return LinkedLineItemsLightboxComponent;
}());
export { LinkedLineItemsLightboxComponent };
