import { NgModel } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { IdService } from 'app/core/index.js';
import { RTBPartnerRepository } from 'app/core/repositories';
import { AdSlotType } from 'app/shared/models/ad-slot';
import options from './options.json';
export var RtbOptions;
(function (RtbOptions) {
    RtbOptions[RtbOptions["DEFAULT"] = 0] = "DEFAULT";
    RtbOptions[RtbOptions["ALLOW"] = 1] = "ALLOW";
    RtbOptions[RtbOptions["BLOCK"] = 2] = "BLOCK";
})(RtbOptions || (RtbOptions = {}));
var RTBSettingsComponent = /** @class */ (function () {
    function RTBSettingsComponent(rtbPartnerRepository, id) {
        this.rtbPartnerRepository = rtbPartnerRepository;
        this.id = id;
        this.onlyNativeDSP = false;
        this.isExchange = true;
        this.rtbPartners = [];
        this.entityType = 'Publisher';
        this.rtbAllowOptions = [];
        this.rtbPartnerOptions = {};
        this.rtbPartnersWithFloor = new BehaviorSubject({});
        this.effectiveExchangeFloor = null;
        this._adSlotType = null;
        this.rtbFirstTrigger = false;
        // Remove 2 criteoIDs that have floors from searches
        // because we do not use their floors (2.5) anymore per MT-4951
        var params = {
            conditions: [
                {
                    field: 'floor',
                    value: 0,
                    operator: 'gt'
                },
                {
                    field: 'id',
                    value: ['43800dd0785411e789f2126374df4a7a', '7268c40e859411e68c1522000a974651'],
                    operator: 'neq'
                }
            ]
        };
        rtbPartnerRepository.search(params).pipe(map(function (partners) {
            return partners.reduce(function (map, partner) {
                map[partner.id] = partner;
                return map;
            }, {});
        })).subscribe(this.rtbPartnersWithFloor);
    }
    Object.defineProperty(RTBSettingsComponent.prototype, "adSlotType", {
        get: function () {
            return this._adSlotType;
        },
        set: function (value) {
            this._adSlotType = value;
            this.overrideRtbOptions();
            this.resetSectionRtbSetting();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RTBSettingsComponent.prototype, "inheritedExchangeFloor", {
        get: function () {
            return this._inheritiedExchangeFloor;
        },
        set: function (value) {
            this._inheritiedExchangeFloor = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RTBSettingsComponent.prototype, "exchangeFloor", {
        get: function () {
            return this.effectiveExchangeFloor;
        },
        set: function (value) {
            switch (this.entityType) {
                case 'Media Group': {
                    this.mediaGroup.sspControl.exchangeFloor = value;
                    break;
                }
                case 'Publisher': {
                    this.publisher.sspControl.exchangeFloor = value;
                    break;
                }
                case 'Newsletter': {
                    this.newsletter.sspControl.exchangeFloor = value;
                    break;
                }
                default: {
                    this.adSlot.sspControl.exchangeFloor = value;
                    break;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RTBSettingsComponent.prototype, "rtbAllow", {
        get: function () {
            if ((this.sspControlHelper.rtbAllow
                || this.sspControlHelper.rtbAllowNative
                || this.sspControlHelper.rtbAllowDisplay) && !this.isLT2Native()) {
                return RtbOptions.ALLOW;
            }
            else if (this.sspControlHelper.rtbAllow === false && !this.isLT2Native()) {
                return RtbOptions.BLOCK;
            }
            else if (this.isLT2Native() && !this.atleastOneBlueprintHasRtbEnabled()) {
                this.rtbFirstTrigger = false;
                return RtbOptions.BLOCK;
            }
            else if (this.isLT2Native() && this.atleastOneBlueprintHasRtbEnabled() && !this.rtbFirstTrigger) {
                this.rtbFirstTrigger = true;
                this.rtbPartners = this.getAllowKeys(this.rtbPartners);
                return RtbOptions.ALLOW;
            }
            return this.setRtbAllowDefault();
        },
        set: function (value) {
            if (this.sspControlHelper) {
                this.setRtbAllows(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Initalize
     */
    RTBSettingsComponent.prototype.initSettings = function (sspControl, linkedRtbs, type, mediaGroup, publisher, newsletter, adSlot) {
        this.sspControlHelper = sspControl;
        this.entityType = type;
        this.mediaGroup = mediaGroup;
        this.publisher = publisher;
        this.newsletter = newsletter;
        this.adSlot = adSlot;
        this.initEffectiveSetting('Allow');
        this.initEffectiveSetting('Floor');
        this.rtbPartners = this.getAllowKeys(linkedRtbs);
        this.setRtbAllows(this.rtbAllow);
        this.initEffectiveExchangeFloor();
    };
    /**
     * Covert the booleans `allow`, `allowNative`, and `allowDisplay` values into integers
     */
    RTBSettingsComponent.prototype.getAllowKeys = function (rtbs) {
        var _this = this;
        rtbs.forEach(function (rtb) {
            rtb.allowKey = _this.setRtbAllowDefault();
            if (rtb.allow === false || (rtb.allowNative === false && rtb.allowDisplay === false)) {
                rtb.allowKey = RtbOptions.BLOCK;
            }
            else if (rtb.allow || rtb.allowNative || rtb.allowDisplay) {
                rtb.allowKey = RtbOptions.ALLOW;
            }
            if (_this.isLT2Native() && !_this.atleastOneBlueprintHasRtbEnabled()) {
                rtb.allowKey = RtbOptions.BLOCK;
            }
            if (_this.isLT2Native() && _this.atleastOneBlueprintHasRtbEnabled() && rtb.allowKey === RtbOptions.DEFAULT) {
                rtb.allowKey = RtbOptions.ALLOW;
            }
        });
        return rtbs;
    };
    RTBSettingsComponent.prototype.setRtbAllowDefault = function () {
        // If being inherited from the database then take the effective value
        if (this.isAdSlotComponent()) {
            return this.effectiveAllow ? RtbOptions.ALLOW : RtbOptions.BLOCK;
        }
        return RtbOptions.DEFAULT;
    };
    RTBSettingsComponent.prototype.setRtbAllows = function (rtbAllow) {
        switch (rtbAllow) {
            case RtbOptions.DEFAULT: {
                /**
                 * Set rtbAllow, rtbAllowNative, and rtbAllowDisplay for AdSlots.
                 * Only set rtbAllow for Media Groups, Publishers, and Newsletters.
                 */
                if (this.adSlot) {
                    this.setRtbAllowsHelper(null, null, null);
                }
                else {
                    this.setRtbAllowHelper(null);
                }
                break;
            }
            case RtbOptions.ALLOW: {
                /**
                 * Set rtbAllow, rtbAllowNative, and rtbAllowDisplay for AdSlots.
                 * Only set rtbAllow for Media Groups, Publishers, and Newsletters.
                 */
                if (this.adSlot) {
                    // Allow Native and Block Display for LT2 Native AdSlots
                    // Block Native and Allow Display for LT2 Fixed Ratio and Taboola AdSlots
                    this.setRtbAllowsHelper(true, this.adSlot.isNative, !this.adSlot.isNative);
                }
                else {
                    this.setRtbAllowHelper(true);
                }
                break;
            }
            case RtbOptions.BLOCK: {
                /**
                 * Set rtbAllow, rtbAllowNative, and rtbAllowDisplay for AdSlots.
                 * Only set rtbAllow for Media Groups, Publishers, and Newsletters.
                 */
                if (this.adSlot) {
                    // Block Native and Display
                    this.setRtbAllowsHelper(false, false, false);
                }
                else {
                    this.setRtbAllowHelper(false);
                }
                break;
            }
        }
        this.overrideRtbOptions();
        var _loop_1 = function (rtb) {
            if (rtb.isNative3PDSP) {
                var found = this_1.rtbPartnerOptions[rtb.id].find(function (option) { return (option.value === rtb.allowKey || option.value === RtbOptions.DEFAULT); });
                if (found === undefined) {
                    if (rtbAllow === RtbOptions.DEFAULT) {
                        var option = this_1.rtbPartnerOptions[rtb.id].slice(-1);
                        rtb.allowKey = option.key;
                    }
                    else {
                        rtb.allowKey = rtbAllow;
                    }
                }
            }
        };
        var this_1 = this;
        for (var _i = 0, _a = this.rtbPartners; _i < _a.length; _i++) {
            var rtb = _a[_i];
            _loop_1(rtb);
        }
    };
    /**
     * Set rtbAllow.
     */
    RTBSettingsComponent.prototype.setRtbAllowHelper = function (rtbAllow) {
        this.sspControlHelper.rtbAllow = rtbAllow;
    };
    /**
     * Set rtbAllow, rtbAllowNative, and rtbAllowDisplay
     */
    RTBSettingsComponent.prototype.setRtbAllowsHelper = function (rtbAllow, rtbAllowNative, rtbAllowDisplay) {
        this.sspControlHelper.rtbAllow = rtbAllow;
        this.sspControlHelper.rtbAllowNative = rtbAllowNative;
        this.sspControlHelper.rtbAllowDisplay = rtbAllowDisplay;
    };
    RTBSettingsComponent.prototype.updateMediaGroup = function (mediaGroup) {
        this.mediaGroup = mediaGroup;
        this.rtbPartners = this.remapRtbPartners(mediaGroup);
        this.initEffectiveSetting('Allow');
        this.initEffectiveSetting('Floor');
        this.overrideRtbOptions();
    };
    RTBSettingsComponent.prototype.initEffectiveExchangeFloor = function () {
        var values;
        if (this.entityType === 'Media Group') {
            this.effectiveExchangeFloor = this.mediaGroup.sspControl.exchangeFloor === null ? 0.5
                : this.mediaGroup.sspControl.exchangeFloor;
            return;
        }
        else if (this.entityType === 'Publisher') {
            values = [this.publisher.sspControl.exchangeFloor, this.mediaGroup.sspControl.exchangeFloor];
        }
        else if (this.entityType === 'Newsletter') {
            values = [this.newsletter.sspControl.exchangeFloor,
                this.publisher.sspControl.exchangeFloor,
                this.mediaGroup.sspControl.exchangeFloor];
        }
        else {
            values = [this.adSlot.sspControl.exchangeFloor,
                this.newsletter.sspControl.exchangeFloor,
                this.publisher.sspControl.exchangeFloor,
                this.mediaGroup.sspControl.exchangeFloor];
        }
        if (values[0] !== null && values[0] !== undefined) {
            this.effectiveExchangeFloor = values[0];
        }
        for (var i = 1; i < values.length; i++) {
            if (values[i] !== null && values[i] !== undefined) {
                this.inheritedExchangeFloor = values[i];
                break;
            }
        }
    };
    RTBSettingsComponent.prototype.initEffectiveSetting = function (field) {
        var effectiveSetting;
        var sspField = field === 'Floor' ? 'exchange' + field : 'rtb' + field;
        if (this.mediaGroup.sspControl.hasOwnProperty(sspField) && this.mediaGroup.sspControl[sspField] !== null) {
            effectiveSetting = this.mediaGroup.sspControl[sspField];
        }
        if (this.publisher &&
            this.publisher.sspControl.hasOwnProperty(sspField) &&
            this.publisher.sspControl[sspField] !== null) {
            effectiveSetting = this.publisher.sspControl[sspField];
        }
        if (this.entityType === 'AdSlot' &&
            this.newsletter.sspControl.hasOwnProperty(sspField) &&
            this.newsletter.sspControl[sspField] !== null) {
            effectiveSetting = this.newsletter.sspControl[sspField];
        }
        this['effective' + field] = effectiveSetting;
    };
    RTBSettingsComponent.prototype.getRtbAllowOptionsWithoutDefault = function () {
        var optionsCopy = JSON.parse(JSON.stringify(options.rtbAllow));
        optionsCopy.shift();
        return optionsCopy;
    };
    RTBSettingsComponent.prototype.getRtbAllowOptions = function () {
        // If component is ad slot form - don't do any inheritance
        if (this.isAdSlotComponent()) {
            return this.getRtbAllowOptionsWithoutDefault();
        }
        // Media Group, Publisher, and Newsletter should still have inheritance
        return this.getRtbAllowOptionsInheritance();
    };
    RTBSettingsComponent.prototype.getRtbAllowOptionsInheritance = function () {
        var label = this.effectiveAllow ? 'Allow' : 'Block';
        var optionsCopy = JSON.parse(JSON.stringify(options.rtbAllow));
        var allowDefault = optionsCopy[0];
        allowDefault.label = 'Default (' + label + ')';
        return optionsCopy;
    };
    RTBSettingsComponent.prototype.getRtbPartnerOptions = function (override, overrideInheritAllow) {
        if (override === void 0) { override = false; }
        if (overrideInheritAllow === void 0) { overrideInheritAllow = null; }
        // If component is ad slot form - don't do any inheritance
        if (this.isAdSlotComponent()) {
            return this.getRtbAllowOptionsWithoutDefault();
        }
        // Media Group, Publisher, and Newsletter should still have inheritance
        return this.getRtbPartnerOptionsInheritance(override, overrideInheritAllow);
    };
    /**
     * Changes the default label for allow options.
     * If override is true, we check the inherited allow for that
     * rtbPartner and if that is not set then the allow value
     * for the current entity of the form. For example, if this is the
     * newsletter form we check the newsletter for its allow
     * value to change the default label for the individual rtb partners.
     */
    RTBSettingsComponent.prototype.getRtbPartnerOptionsInheritance = function (override, overrideInheritAllow) {
        var _this = this;
        if (override === void 0) { override = false; }
        if (overrideInheritAllow === void 0) { overrideInheritAllow = null; }
        var labelCheck = function () {
            if (_this.onlyNativeDSP) {
                return true;
            }
            if (override && _this.sspControlHelper.rtbAllow !== null) {
                return _this.sspControlHelper.rtbAllow;
            }
            if (override && overrideInheritAllow !== null) {
                return overrideInheritAllow;
            }
            return _this.effectiveAllow;
        };
        var optionsCopy = JSON.parse(JSON.stringify(options.rtbAllow));
        var label = labelCheck() ? 'Allow' : 'Block';
        var allowDefault = optionsCopy[0];
        allowDefault.label = 'Default (' + label + ')';
        return optionsCopy;
    };
    RTBSettingsComponent.prototype.overrideRtbOptions = function () {
        // rtbAllow
        this.rtbAllowOptions = this.getRtbAllowOptions();
        // Rtb Partners
        for (var _i = 0, _a = this.rtbPartners; _i < _a.length; _i++) {
            var rtb = _a[_i];
            this.rtbPartnerOptions[rtb.id] = this.getRtbPartnerOptions(true, rtb.inheritedAllow);
        }
    };
    /**
     * Returns an array of all Rtb
     * Partners that have overrides
     */
    RTBSettingsComponent.prototype.getLinkedRtbs = function () {
        var _this = this;
        if (this.isRtbOff) {
            return [];
        }
        else {
            return this.rtbPartners.filter(function (rtb) { return (rtb.allowKey !== 0 ||
                rtb.floor !== null); }).map(function (rtb) {
                var rtbSettings = {
                    id: rtb.id,
                    floor: rtb.floor,
                    allow: rtb.allow,
                    allowNative: rtb.allowNative === undefined ? null : rtb.allowNative,
                    allowDisplay: rtb.allowDisplay === undefined ? null : rtb.allowDisplay
                };
                switch (rtb.allowKey) {
                    case RtbOptions.DEFAULT: {
                        // Reset the allow and floor to NULL when we want to inherit
                        // This will ensure we remove the DSP from zf_section_rtb_partners
                        rtbSettings.allow = null;
                        rtbSettings.floor = null;
                        break;
                    }
                    case RtbOptions.ALLOW: {
                        if (_this.adSlot) {
                            // Allow Native and Block Display for LT2 Native AdSlots
                            // Block Native and Allow Display for LT2 Fixed Ratio and Taboola AdSlots
                            rtbSettings.allowNative = _this.adSlot.isNative;
                            rtbSettings.allowDisplay = !_this.adSlot.isNative;
                        }
                        else {
                            delete rtbSettings.allowNative;
                            delete rtbSettings.allowDisplay;
                        }
                        rtbSettings.allow = true;
                        break;
                    }
                    case RtbOptions.BLOCK: {
                        if (_this.adSlot) {
                            // Block Native and Display
                            rtbSettings.allowNative = false;
                            rtbSettings.allowDisplay = false;
                        }
                        else {
                            delete rtbSettings.allowNative;
                            delete rtbSettings.allowDisplay;
                        }
                        rtbSettings.allow = false;
                        break;
                    }
                }
                return rtbSettings;
            });
        }
    };
    /**
     * Clear section Rtb setting to default
     */
    RTBSettingsComponent.prototype.resetSectionRtbSetting = function () {
        this.rtbAllow = RtbOptions.DEFAULT;
    };
    RTBSettingsComponent.prototype.resetAllowKeys = function () {
        this.rtbPartners.forEach(function (rtb) {
            rtb.allowKey = RtbOptions.BLOCK;
        });
    };
    Object.defineProperty(RTBSettingsComponent.prototype, "sspControlsDirty", {
        get: function () {
            if (this.inheritFloor) {
                return this.demand.dirty ||
                    this.floor.dirty ||
                    this.inheritFloor.dirty;
            }
            return this.demand.dirty || (this.floor && this.floor.dirty);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RTBSettingsComponent.prototype, "isRtbOff", {
        get: function () {
            return this.sspControlHelper.rtbAllow === false
                || (this.sspControlHelper.rtbAllow === null && this.effectiveAllow === false);
        },
        enumerable: true,
        configurable: true
    });
    RTBSettingsComponent.prototype.remapRtbPartners = function (mediaGroup) {
        var mgPartners = mediaGroup.rtbPartners || [];
        return this.rtbPartners.map(function (partner) {
            partner.inheritedAllow = mediaGroup.sspControl.rtbAllow;
            partner.inheritedFloor = mediaGroup.sspControl.rtbFloor;
            var mgPartner = mgPartners.find(function (aMgPartner) { return aMgPartner.id === partner.id; });
            if (mgPartner && mgPartner.allow !== null) {
                partner.inheritedAllow = mgPartner.allow;
            }
            if (mgPartner && mgPartner.floor !== null) {
                partner.inheritedFloor = mgPartner.floor;
            }
            return partner;
        });
    };
    RTBSettingsComponent.prototype.validFloors = function () {
        var partners = this.rtbPartnersWithFloor.getValue();
        return this.rtbPartners.every(function (partner) {
            return !(partners[partner.id] &&
                (partner.floor || partner.floor === 0) && partner.floor < partners[partner.id].floor);
        });
    };
    RTBSettingsComponent.prototype.resetFloors = function () {
        this.effectiveExchangeFloor = null;
        this.inheritedExchangeFloor = null;
    };
    // this will filter out our internal native dsp when we show all dsps
    RTBSettingsComponent.prototype.isExchangeAdSlotAndNativeDSP = function (partner) {
        return !this.onlyNativeDSP && this.adSlot && this.isExchange && partner.isLiNativeDSP;
    };
    RTBSettingsComponent.prototype.isAdSlotNativeDSP = function (partner) {
        return this.onlyNativeDSP && !partner.isNative3PDSP;
    };
    RTBSettingsComponent.prototype.isPartnerDisabled = function (partner) {
        return this.isAdSlotNativeDSP(partner) || this.isExchangeAdSlotAndNativeDSP(partner);
    };
    RTBSettingsComponent.prototype.isLT2Native = function () {
        return this.adSlot && this.adSlot.isNative || (this.adSlotType === AdSlotType.ImageNative);
    };
    RTBSettingsComponent.prototype.atleastOneBlueprintHasRtbEnabled = function () {
        return this.adSlot && this.adSlot.nativeBlueprints
            && this.adSlot.nativeBlueprints.some(function (b) { return b.allowRtb === true; });
    };
    RTBSettingsComponent.prototype.isAdSlotComponent = function () {
        return this.adSlot !== undefined;
    };
    return RTBSettingsComponent;
}());
export { RTBSettingsComponent };
