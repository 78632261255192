import {
    Component,
    EventEmitter,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { BehaviorSubject, Observable, Subscription, forkJoin } from 'rxjs';
import { NgForm, NgModel } from '@angular/forms';
import {
    CategoryRepository,
    NewsletterRepository,
    PublisherRepository,
    MediaGroupRepository
} from 'app/core/repositories';
import { Newsletter } from 'app/shared/models';
import { HttpErrorResponse } from '@angular/common/http';
import { IdService } from 'app/core/id.service';
import {Option} from 'app/shared/elements/dropdown';
import { AuthorizationService } from 'app/core';
import { AdSlotCoordinatedLineItemsDialogComponent } from 'app/shared/components/ad-slot-coordinated-line-items-dialog';
import { Flag, LaunchDarklyService } from 'app/core/launch-darkly.service';
import { distinctUntilChanged } from 'rxjs/operators';

import NEWSLETTER_TYPES from "app/shared/models/newsletter-types.json"

@Component({
    selector: 'newsletter-single',
    templateUrl: './newsletter-single.html',
    styleUrls: ['./newsletter-single.styl']
})

export class NewsletterSingleComponent {

    @Output() onSaved: EventEmitter<any> = new EventEmitter<any>();
    @Output() onCreated: EventEmitter<any> = new EventEmitter<any>();
    @Output() onError: EventEmitter<any> = new EventEmitter<any>();
    @Output() onLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onOpenPopUp: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('form', { static: true }) public form: NgForm;
    @ViewChild(AdSlotCoordinatedLineItemsDialogComponent, { static: true })
        coordinatedLineItemsDialogComponent: AdSlotCoordinatedLineItemsDialogComponent;
    @ViewChildren(NgModel) controls: QueryList<NgModel>;

    public isEdit: boolean;
    public errors: any[] = [];
    public publisher: any;
    public newsletter: any;
    public primaryCategories: Option[];

    public showAdvanced: boolean = false;
    public _useDifferentSources: boolean = false;
    public _useDifferentSourcesAdvertisers: boolean = false;
    public _useDifferentSourcesExternalAdvertisers: boolean = false;
    private categoryInitValue: number;
    private canSetCoordination$ = new BehaviorSubject(false);
    public canSetCoordination;
    public newsletter$ = new BehaviorSubject<Newsletter>(null);
    private launchDarklySubscription$: Subscription;
    public rolloutUamEnhancements = false;
    public rolloutAdBlocking = false;

    protected readonly Newsletter = Newsletter;
    protected readonly NEWSLETTER_TYPES = NEWSLETTER_TYPES;
    readonly ADVERTISER_UNIQUE_MODES = [
        NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER,
        NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER_DISPLAY,
        NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER_NATIVE
    ];

    readonly EXTERNAL_ADVERTISER_UNIQUE_MODES = [
        NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.EXTERNAL_ADVERTISERS,
        NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.EXTERNAL_ADVERTISERS_DISPLAY,
        NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.EXTERNAL_ADVERTISERS_NATIVE
    ];

    constructor(
        public auth: AuthorizationService,
        public categoryRepository: CategoryRepository,
        public newsletterRepository: NewsletterRepository,
        public publisherRepository: PublisherRepository,
        public mediaGroupRepository: MediaGroupRepository,
        public id: IdService,
        private launchDarklyService: LaunchDarklyService
    ) {
        this.newsletter = this.newNewsletter();
        this.initCategories();
    }

    get privateDeal(): boolean {
        return this.newsletter.privateDeals;
    }

    set privateDeal(value: boolean) {
        this.newsletter.privateDeals = value;
        if (value) { this.newsletter.sspControl.exchangeAllow = false; }
    }

    public initForCreate(publisher): void {
        this.isEdit = false;
        this.categoryInitValue = null;
        this.errors = [];

        this.newsletter = new Newsletter({
            publisher: publisher.id,
            sspControl: {
                exchangeAllow: true
            }
        });


        this.publisher = publisher;

        this.newsletter.publisherObj = this.publisher;

        this.newsletter.domainTargeting = {
            type: 'inherit',
            values: []
        }

        this.newsletter.sensitiveCategoryTargeting = {
            type: 'inherit',
            values: []
        }

        this.newsletter$.next(this.newsletter);

        this.initLaunchDarklyFlags();
    }

    public initForEdit(newsletter): void {
        this.isEdit = true;
        this.errors = [];

        forkJoin([
            this.newsletterRepository.get(newsletter.id),
            this.publisherRepository.get(newsletter.publisher)
        ]).subscribe(([newsletter, publisher]) => {
            this.newsletter = newsletter;
            this.publisher = publisher;

            this.newsletter.publisherObj = this.publisher;

            this.newsletter$.next(this.newsletter);

            this.categoryInitValue = newsletter.category;

            if (this.newsletter.sspControl.uniqueAds !== NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.INHERIT &&
                this.newsletter.sspControl.uniqueAds !== NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.OFF) {
                this._useDifferentSources = true;
            }

            this.onLoaded.emit(true);
        }, (rejection => this.showErrors(rejection)));

        this.initLaunchDarklyFlags();
    }

    public initCategories(): void {
        this.categoryRepository.getPrimaryCategories()
            .pipe(categories => this.categoryRepository.convertCategoriesToOptions(categories))
            .subscribe(categories => this.primaryCategories = categories);
    }

    public newNewsletter(): any {
        return {
            sspControl: {}
        };
    }

    public resetForm(): void {
        this.newsletter = this.newNewsletter();
        this.categoryInitValue = null;
        window.setTimeout(() => this.form.reset(), 10);
    }

    public get useDifferentSources(): boolean {
        return this._useDifferentSources;
    }

    public set useDifferentSources(value: boolean) {
        this.newsletter.sspControl.uniqueAds = value ? null : NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.OFF;
        this._useDifferentSources = value;


        if (this.newsletter.sspControl.uniqueAds === NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.INHERIT) {
            this._useDifferentSources = false;
        }

        if (!this.newsletter.sspControl.uniqueAds) {
            this.newsletter.sspControl.uniqueAds = NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER;; // Default if no previous selection
        }
    }

    public get useDifferentSourcesAdvertisers(): boolean {
        return this.ADVERTISER_UNIQUE_MODES.includes(this.newsletter.sspControl.uniqueAds);
    }
    
    public get useDifferentSourcesExternalAdvertisers(): boolean {
        return this.EXTERNAL_ADVERTISER_UNIQUE_MODES.includes(this.newsletter.sspControl.uniqueAds);
    }

    public set useDifferentSourcesAdvertisers(value: boolean) {
        this._useDifferentSourcesAdvertisers = value;
        this._useDifferentSourcesExternalAdvertisers = null;
        
       if (value) {
            // Preserve the user's choice if it's already set
            if (!this.newsletter.sspControl.uniqueAds || 
                !this.ADVERTISER_UNIQUE_MODES.includes(this.newsletter.sspControl.uniqueAds)) {
                this.newsletter.sspControl.uniqueAds = NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER; // Default if no previous selection
            }
        } else {
            this.newsletter.sspControl.uniqueAds = NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.OFF;
        }
    }
    
    public set useDifferentSourcesExternalAdvertisers(value: boolean) {
        this._useDifferentSourcesExternalAdvertisers = value;
        this._useDifferentSourcesAdvertisers = null;
        
        if (value) {
            // Preserve the user's choice if it's already set
            if (!this.newsletter.sspControl.uniqueAds || 
                !this.EXTERNAL_ADVERTISER_UNIQUE_MODES.includes(this.newsletter.sspControl.uniqueAds)) {
                this.newsletter.sspControl.uniqueAds = NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.EXTERNAL_ADVERTISERS; // Default if no previous selection
            }
        } else {
            this.newsletter.sspControl.uniqueAds = NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.OFF;
        }
    }

    inheritUniqueAdMode() {
        this.newsletter.sspControl.uniqueAds = NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.INHERIT;
        this._useDifferentSources = false;
        this._useDifferentSourcesAdvertisers = false;
        this._useDifferentSourcesExternalAdvertisers = false;
    }

    public checkCoordinatedSponsorship(): Observable<boolean> {
        return this.coordinatedLineItemsDialogComponent.showCoordination(this.newsletter);
    }

    public turnOffCoordinatedSponsorship($event): void {
        this.onOpenPopUp.emit($event);
    }

    public submit(): void {
        let newsletterPromise = Promise.resolve(this.newsletter);
        if (this.dirty) {
            newsletterPromise = this.newsletterRepository.save(this.newsletter).toPromise();
        }

        newsletterPromise
            .then((saveNewsletter) => {
                this.newsletter = saveNewsletter;
                this.isEdit ? this.onSaved.emit(this.newsletter) : this.onCreated.emit(this.newsletter);
            })
            .catch(rejection => this.showErrors(rejection));
    }

    public showErrors(err: HttpErrorResponse): void {
        this.onError.emit();
        this.errors = [];
        const payload = err.error;

        if (payload.errors) {
            payload.errors.forEach(error => {
                this.errors.push({
                    error: `There was an error with the ${error.details} field.`
                });
            });
        } else if (!err.ok) {
            this.errors.push({
                error: `Please, let us know! (${err.toString()}).`
            });

        }
    }

    private initLaunchDarklyFlags() {
        if (this.launchDarklySubscription$) {
            this.launchDarklySubscription$.unsubscribe();
        }

        this.launchDarklySubscription$ = this.launchDarklyService
            .getVariation(Flag.AllowCoordination)
            .pipe(distinctUntilChanged())
            .subscribe(canSetCoordination => {
                this.canSetCoordination$.next(canSetCoordination);
            });

        this.canSetCoordination$.subscribe(value => {
            this.canSetCoordination = value;
        });

        this.launchDarklyService
            .getVariation(Flag.RolloutUAMEnhancements)
            .pipe(distinctUntilChanged())
            .subscribe(rolloutUamEnhancements => this.rolloutUamEnhancements = rolloutUamEnhancements);

        this.launchDarklyService
            .getVariation(Flag.RolloutAdBlocking)
            .pipe(distinctUntilChanged())
            .subscribe(rolloutAdBlocking => this.rolloutAdBlocking = rolloutAdBlocking);
    }

    private get dirty(): boolean {
        return this.controls.some(control => {
            if (control.name === 'newsletterCategory') {
                return this.categoryInitValue !== control.value;
            }
            return control.dirty;
        }) || this.validEspSetting();
    }

    getParentEsp() {
        if (this.publisher.esp === 'other') {
            return 'Other ESP';
        }

        return this.publisher.espName;
    }

    validEspSetting() {
        return (this.newsletter.esp !== null);
    }

    updateNewsletterEspSettings(newsletter) {
        this.newsletter.esp = newsletter.esp;
        this.newsletter.emailTagReplacement = newsletter.emailTagReplacement;
        this.newsletter.emailTagType = newsletter.emailTagType;
        this.newsletter.placementTagReplacement = newsletter.placementTagReplacement;
        this.newsletter.listTagReplacement = newsletter.listTagReplacement;
        this.newsletter.lceeKeyReplacement = newsletter.lceeKeyReplacement;
        this.newsletter.lceeTagReplacement = newsletter.lceeTagReplacement;
    }

    updateDemandBlockingSettings(newsletter) {
        if (!this.newsletter.domainTargeting) {
            this.newsletter.domainTargeting = {};
        }
        this.newsletter.domainTargeting = newsletter.domainTargeting;

        this.transformTargetingType(this.newsletter.domainTargeting.type)

        if (!this.newsletter.sensitiveCategoryTargeting) {
            this.newsletter.sensitiveCategoryTargeting = {};
        }
        this.newsletter.sensitiveCategoryTargeting = newsletter.sensitiveCategoryTargeting;
    }

    transformTargetingType(type) {
        switch(type) {
            case 'exclude':
                this.newsletter.domainTargeting.type = 'block';
                break;
            case 'include':
                this.newsletter.domainTargeting.type = 'allow';
                break;
        }
    }
}
