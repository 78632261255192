/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./progress-bar.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./progress-bar.component";
var styles_ProgressBarComponent = [i0.styles];
var RenderType_ProgressBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProgressBarComponent, data: {} });
export { RenderType_ProgressBarComponent as RenderType_ProgressBarComponent };
function View_ProgressBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "flex flex-align-center spacer--large-left supporting"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", "%"])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.percentDone, "1.0-0")); _ck(_v, 1, 0, currVal_0); }); }
export function View_ProgressBarComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "flex"]], [[2, "indeterminate", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(3, 0, null, null, 11, "div", [["class", "progress-bar"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "total-bar line"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "current-bar subline inc"]], [[4, "width", "%"]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "subline dec"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgressBarComponent_1)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.indeterminate; _ck(_v, 13, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.indeterminate; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.percentDone; _ck(_v, 7, 0, currVal_1); }); }
export function View_ProgressBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "progress-bar", [], null, null, null, View_ProgressBarComponent_0, RenderType_ProgressBarComponent)), i1.ɵdid(1, 49152, null, 0, i3.ProgressBarComponent, [[8, null]], null, null)], null, null); }
var ProgressBarComponentNgFactory = i1.ɵccf("progress-bar", i3.ProgressBarComponent, View_ProgressBarComponent_Host_0, { current: "current", total: "total" }, {}, []);
export { ProgressBarComponentNgFactory as ProgressBarComponentNgFactory };
