import * as tslib_1 from "tslib";
import { Model } from './model';
var AudienceFile = /** @class */ (function (_super) {
    tslib_1.__extends(AudienceFile, _super);
    function AudienceFile() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AudienceFile.prototype.serialize = function () {
        return JSON.stringify(this);
    };
    Object.defineProperty(AudienceFile.prototype, "entity", {
        get: function () {
            return 'Audience File';
        },
        enumerable: true,
        configurable: true
    });
    return AudienceFile;
}(Model));
export { AudienceFile };
