import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReportingEmail } from 'app/shared/models';
import { BackendRepository } from './backend-repository';

@Injectable()
export class ReportingEmailRepository extends BackendRepository<ReportingEmail> {
    public constructor(http: HttpClient) {
        super(http, '/reporting/email', ReportingEmail);
    }

    public emailReport(queryId, recipients) {
        return this.http.post(this.url(this.path), JSON.stringify({ queryId, recipients }), { responseType: 'text' });
    }
}
