import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LineItem } from 'app/shared/models';
import { BackendRepository } from './backend-repository';

@Injectable()
export class ExchangeStrategyRepository extends BackendRepository<LineItem> {
    public constructor(http: HttpClient) {
        super(http, '/exchange-strategy', LineItem);
    }
}
