import { EventEmitter } from '@angular/core';
import { ThemeService } from 'app/shared/helpers/theme.service';
var DialogComponent = /** @class */ (function () {
    function DialogComponent(theme) {
        this.theme = theme;
        this.opened = new EventEmitter();
        this.closed = new EventEmitter();
        this.visibility = 'hidden';
        this.signal = null;
    }
    Object.defineProperty(DialogComponent.prototype, "visible", {
        set: function (visible) {
            this.visibility = visible ? 'visible' : 'hidden';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogComponent.prototype, "isVisible", {
        get: function () {
            return this.visibility === 'visible';
        },
        enumerable: true,
        configurable: true
    });
    DialogComponent.prototype.done = function (event) {
        var _this = this;
        var emitter = (function () {
            switch (event.toState) {
                case 'visible':
                    return _this.opened;
                case 'void':
                    return _this.closed;
            }
            return null;
        })();
        if (emitter === null) {
            return;
        }
        emitter.emit(this.signal);
        this.signal = null;
    };
    DialogComponent.prototype.open = function () {
        this.visible = true;
        this.theme.lockPageScroll();
    };
    DialogComponent.prototype.close = function (signal) {
        // Disallow overwriting an already set signal. The signal will be
        // once it is emitted in an event, additionally prevent signaling
        // 'undefined'.
        if (this.signal === null && signal !== undefined) {
            this.signal = signal;
        }
        this.visible = false;
        this.theme.unlockPageScroll();
    };
    return DialogComponent;
}());
export { DialogComponent };
