import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import { FilterComponent } from '../filter.component';
import { FilterEnum } from './filter-enum';

@Component({
    selector: 'filter-enum',
    templateUrl: './filter-enum.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterEnumComponent implements AfterViewInit, FilterComponent {
    @ViewChild('content', { static: true }) content: ElementRef;
    @Output() emitter = new EventEmitter<FilterEnum>();
    filter: FilterEnum = new FilterEnum();
    valueOptions: { value: any, label: string}[] = [];
    private groupMap = new Map<any, any[]>();

    @Input() set values(values: { string: string }) {
        if (values) {
            this.valueOptions = Object.keys(values).map(key => {
                let value = values[key];

                // Multi-select can't properly accept an array of values,
                // but sometimes one checkbox should relate to more than
                // one enum. Here's our hacky solution.
                if (Array.isArray(value)) {
                    this.groupMap.set(value[0], value);
                    value = value[0];
                }

                return {
                    label: key,
                    value
                };
            });

            this.filter.values = Object.values(values);
        }
    }

    ngAfterViewInit() {
        this.filter.label = this.content.nativeElement.innerHTML;

        if (!this.filter.operation) {
            this.filter.operation = this.filter.operations[0];
        }
    }

    onchange(value: any[]) {
        this.filter.query = [].concat(...value.map(selected => this.groupMap.has(selected) ? this.groupMap.get(selected) : selected));
        this.filter.simpleLabel = this.valueOptions.filter(
            option => this.filter.query.includes(option.value)
        ).map(option => option.label).join(', ');
        this.emit();
    }

    emit() {
        this.emitter.emit(this.filter);
    }
}
