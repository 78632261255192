import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component, ElementRef,
    EventEmitter, Input,
    Output, ViewChild,
} from '@angular/core';
import { FilterDate } from './filter-date';
import { FilterComponent } from 'app/shared/elements/filters';
import * as moment from 'moment';

@Component({
    selector: 'filter-date',
    templateUrl: './filter-date.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterDateComponent implements AfterViewInit, FilterComponent {
    @ViewChild('content', { static: true }) content: ElementRef;
    @Output() emitter = new EventEmitter<FilterDate>();
    filter: FilterDate = new FilterDate();
    @Input('max-date') maxDate: moment.Moment;

    ngAfterViewInit() {
        this.filter.label = this.content.nativeElement.innerHTML;

        if (!this.filter.operation) {
            this.filter.operation = this.filter.operations[0];
        }
    }

    emit() {
        this.emitter.emit(this.filter);
    }
}
