import moment from 'moment';
import { Status } from 'app/shared/models';
var byStartDate = function (a, b) {
    if (moment(a.startDate).isAfter(b.startDate)) {
        return -1;
    }
    if (moment(a.startDate).isBefore(b.startDate)) {
        return 1;
    }
    return 0;
};
var ɵ0 = byStartDate;
var CampaignsSortService = /** @class */ (function () {
    function CampaignsSortService() {
    }
    Object.defineProperty(CampaignsSortService.prototype, "campaigns", {
        get: function () {
            return this._campaigns || [];
        },
        set: function (val) {
            this._campaigns = val;
        },
        enumerable: true,
        configurable: true
    });
    CampaignsSortService.prototype.push = function (campaigns) {
        this.campaigns = this.campaigns.concat(campaigns);
        return this;
    };
    CampaignsSortService.prototype.pull = function () {
        var campaignsClone = this.campaigns.slice(0); // This creates clone of campaigns collection
        this.campaigns = null;
        return campaignsClone;
    };
    CampaignsSortService.prototype.sortByDeliveringLineItems = function () {
        var today = moment().format('YYYY-MM-DD');
        var delivering;
        var notDelivering;
        var current;
        var past;
        notDelivering = this.campaigns // Doesn't contain delivering LineItems
            .filter(function (campaign) { return campaign['lineItems']
            .every(function (lItem) { return lItem.platformStatus !== Status.DELIVERING; }); });
        delivering = this.campaigns // Contains 1+ delivering LineItem
            .filter(function (campaign) { return campaign['lineItems']
            .some(function (lItem) { return lItem.platformStatus === Status.DELIVERING; }); })
            .sort(byStartDate);
        current = notDelivering
            .filter(function (campaign) { return moment(campaign.effectiveEndDate).isSameOrAfter(today); })
            .sort(byStartDate);
        past = notDelivering
            .filter(function (campaign) { return moment(campaign.effectiveEndDate).isBefore(today); })
            .sort(byStartDate);
        this.campaigns = delivering.concat(current).concat(past);
        return this;
    };
    return CampaignsSortService;
}());
export { CampaignsSortService };
export { ɵ0 };
