import { IdService } from 'app/core';
var ThirdPartyDemandSettingsComponent = /** @class */ (function () {
    function ThirdPartyDemandSettingsComponent(id) {
        this.id = id;
        this.thirdPartyDemandPartners = [];
        this._allowDisplayThirdPartyDemand = true;
    }
    Object.defineProperty(ThirdPartyDemandSettingsComponent.prototype, "allowDisplayThirdPartyDemand", {
        get: function () {
            return this._allowDisplayThirdPartyDemand;
        },
        set: function (value) {
            if (!this._allowDisplayThirdPartyDemand && value) {
                this.thirdPartyDemandPartners.map(function (partner) {
                    partner.allowDisplay = true;
                });
            }
            else if (!value) {
                this.thirdPartyDemandPartners.map(function (partner) {
                    partner.allowDisplay = false;
                });
            }
            this._allowDisplayThirdPartyDemand = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThirdPartyDemandSettingsComponent.prototype, "isDisplay", {
        get: function () {
            return this._isDisplay;
        },
        set: function (value) {
            this._isDisplay = value;
            if (!value) {
                this.thirdPartyDemandPartners.map(function (partner) {
                    partner.allowDisplay = false;
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThirdPartyDemandSettingsComponent.prototype, "isNative", {
        get: function () {
            return this._isNative;
        },
        set: function (value) {
            this._isNative = value;
            if (!value) {
                this.thirdPartyDemandPartners.map(function (partner) {
                    partner.allowNative = false;
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThirdPartyDemandSettingsComponent.prototype, "nativeBlueprints", {
        get: function () {
            return this._nativeBlueprints;
        },
        set: function (nativeBlueprints) {
            this._nativeBlueprints = nativeBlueprints;
            this.nativeAdSlotRtbAllow = this.isNativeRtbAllow();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThirdPartyDemandSettingsComponent.prototype, "nativeAdSlotRtbAllow", {
        get: function () {
            return this._nativeAdSlotRtbAllow;
        },
        set: function (value) {
            if (this._nativeAdSlotRtbAllow !== value) {
                if (this._nativeAdSlotRtbAllow != null) {
                    this.thirdPartyDemandPartners = this.thirdPartyDemandPartners.map(function (rtb) {
                        if (rtb.isNative3PDSP) {
                            rtb.allowNative = value;
                        }
                        return rtb;
                    });
                }
                this._nativeAdSlotRtbAllow = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Initalize
     */
    ThirdPartyDemandSettingsComponent.prototype.initSettings = function (linkedRtbs) {
        if (this.thirdPartyDemandPartners.length === 0) {
            this.thirdPartyDemandPartners = linkedRtbs;
            this.thirdPartyDemandPartners.sort(function (x) { return x.isNative3PDSP ? -1 : 0; });
        }
    };
    ThirdPartyDemandSettingsComponent.prototype.getLinkedRtbs = function () {
        var _this = this;
        return this.thirdPartyDemandPartners
            .filter(function (partner) { return (partner.allowDisplay ||
            partner.allowNative ||
            partner.floor !== null); }).map(function (partner) {
            // We should not have null values for allow, allowDisplay, and allowNative unless all of them are null.
            var thirdPartyDemandSettings = {
                id: partner.id,
                floor: partner.floor,
                allow: partner.allowDisplay || partner.allowNative,
                allowDisplay: _this.isValidValue(partner.allowDisplay),
                allowNative: _this.isValidValue(partner.allowNative)
            };
            return thirdPartyDemandSettings;
        });
    };
    ThirdPartyDemandSettingsComponent.prototype.isNativeRtbAllow = function () {
        this.nativeAdSlotRtbAllow = this.nativeBlueprints != null && this.nativeBlueprints.some(function (bp) { return bp.allowRtb === true; });
        return this.nativeAdSlotRtbAllow;
    };
    ThirdPartyDemandSettingsComponent.prototype.removeWhiteSpace = function (string, replace) {
        return string.replace(/\s/g, replace);
    };
    ThirdPartyDemandSettingsComponent.prototype.isValidValue = function (value) {
        if (value === undefined || value === null) {
            return false;
        }
        return value;
    };
    return ThirdPartyDemandSettingsComponent;
}());
export { ThirdPartyDemandSettingsComponent };
