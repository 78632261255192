import {
    Component, ViewChild
} from '@angular/core';

import { LightboxComponent } from 'app/shared/elements/lightbox';
import { AuthorizationService } from 'app/core';
import macros from 'app/core/valid-macros.json';

@Component({
    selector: 'ad-details-macro',
    templateUrl: './ad-details-macro.html',
    styleUrls: ['./ad-details-macro.styl']
})
export class AdDetailsMacroComponent {
    @ViewChild(LightboxComponent, { static: true }) macroInfoBox: LightboxComponent;

    constructor(private auth: AuthorizationService) {}

    public open() {
        this.macroInfoBox.open();
    }

    get validMacros(): Array<string>{
        if (this.auth.isInternalUser) {
            return macros.allowedMacros
        } else {
            return macros.externalUserMacros;
        }
    }
}
