import { Location } from '@angular/common';
import { ErrorHelper } from 'app/core/errors';
import { environment } from 'src/environments/environment';
import { Router, NavigationStart, NavigationEnd, NavigationError, ActivationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { StoreService } from 'app/core/store.service';
import { ThemeService } from 'app/shared/helpers/theme.service';
var NavigationService = /** @class */ (function () {
    function NavigationService(location, router, store, themeService) {
        var _this = this;
        this.location = location;
        this.router = router;
        this.store = store;
        this.themeService = themeService;
        this.isLoading = new BehaviorSubject(false);
        this.timer = null;
        this.router.events.subscribe(function (event) { return _this.handleRouterEvent(event); });
    }
    /**
     * Respond to a NavigationStart event.
     */
    NavigationService.prototype.onNavigationStarted = function (event) {
        var _this = this;
        if (!this.isLoading.getValue()) {
            this.timer = window.setTimeout(function () { return _this.isLoading.next(true); }, 100);
        }
        this.themeService.resetBackgroundStyle();
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden';
    };
    /**
     * Respond to a NavigationEnd event.
     */
    NavigationService.prototype.onNavigationCompleted = function (event) {
        if (this.timer !== null) {
            clearTimeout(this.timer);
        }
        this.isLoading.next(false);
        document.body.style.overflow = null;
    };
    /**
     * Respond to a NavigationError event.
     */
    NavigationService.prototype.onNavigationFailed = function (event) {
        this.sendErrorPage(event.error, event.url);
    };
    /**
     * Send the user to the appropriate error page.
     */
    NavigationService.prototype.sendErrorPage = function (error, url) {
        var errorPage = this.errorPage(error, url);
        if (errorPage) {
            this.router.navigate([errorPage], { skipLocationChange: true })
                // for better UX, pretend like navigation succeeded
                .then(function () { return window.history.replaceState(null, 'Error', url); });
        }
    };
    /**
     * Find the appropriate error page to display.
     */
    NavigationService.prototype.errorPage = function (error, url) {
        var path = url || this.router.url;
        if (ErrorHelper.isRateLimitError(error)) {
            return "/" + this.findModule(path) + "/too-many-requests";
        }
        return "/" + this.findModule(path) + "/404";
    };
    /**
     * Respond to an ActivationEnd event.
     */
    NavigationService.prototype.onActivationEnd = function (event) {
        if (event.snapshot.firstChild === null && event.snapshot.outlet === 'primary') {
            if (event.snapshot.data.style) {
                if (event.snapshot.data.style.background) {
                    this.themeService.setBackgroundStyle(event.snapshot.data.style.background);
                }
            }
        }
    };
    /**
     * Find the module of a url.
     */
    NavigationService.prototype.findModule = function (url) {
        if (url.includes('inventory-manager')) {
            return 'inventory-manager';
        }
        else if (url.includes('campaign-manager')) {
            return 'campaign-manager';
        }
        else if (url.includes('internal-tools')) {
            return 'internal-tools';
        }
    };
    /**
     * Handle a Router event.
     */
    NavigationService.prototype.handleRouterEvent = function (event) {
        if (event instanceof NavigationStart) {
            this.onNavigationStarted(event);
        }
        else if (event instanceof NavigationEnd) {
            this.onNavigationCompleted(event);
        }
        else if (event instanceof NavigationError) {
            this.onNavigationFailed(event);
        }
        else if (event instanceof ActivationEnd) {
            this.onActivationEnd(event);
        }
    };
    /**
     * Redirect to the login page.
     */
    NavigationService.prototype.redirectToLogin = function (includeRedirectParam) {
        if (includeRedirectParam === void 0) { includeRedirectParam = true; }
        var url = '/login';
        if (includeRedirectParam) {
            url += "?redirect=" + this.redirectBackParam();
        }
        window.location.href = this.authAppURL(url);
    };
    /**
     * Redirect to the logout page.
     */
    NavigationService.prototype.redirectToLogout = function () {
        window.location.href = this.authAppURL("/logout?redirect=" + this.redirectBackParam());
    };
    /**
     * Redirect to the login page.
     */
    NavigationService.prototype.redirectToFatalError = function () {
        window.location.href = '/fatal-error';
    };
    /**
     * Replace the current URL with a URL containing `options.queryParams` and/or `options.matrixParams`, but do not
     * cause the Router to nagivate again. This function attempts to be the missing `router` function that acts exactly
     * like `router.navigate`, expect that it only replaces state, rather than triggerring a full navigation.
     */
    NavigationService.prototype.replaceState = function (route, options) {
        this.location.replaceState(this.router
            .createUrlTree([this.handleParams(options, route)], {
            relativeTo: route,
            queryParams: options.queryParams
        })
            .toString());
    };
    NavigationService.prototype.authAppURL = function () {
        var suffix = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            suffix[_i] = arguments[_i];
        }
        return (environment.backend.auth.hostname || 'https://auth.liveintent.com') + suffix.join('/');
    };
    NavigationService.prototype.redirectBackParam = function () {
        var currentAppBaseURL = new URL(window.location.href).origin;
        var currentAppURI = this.router.url;
        return encodeURIComponent(currentAppBaseURL + currentAppURI);
    };
    /**
     * Handle the matrix params based on the paramsHandling option.
     */
    NavigationService.prototype.handleParams = function (options, route) {
        if (options.paramsHandling === 'merge') {
            // clone the original params into a plain object so we can use `Object.assign`
            var originalParams = JSON.parse(JSON.stringify(route.snapshot.params));
            return Object.assign(originalParams, options.params);
        }
        return options.params;
    };
    return NavigationService;
}());
export { NavigationService };
