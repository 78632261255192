import { Injectable } from '@angular/core';
import { ErrorHelper } from 'app/core/errors';
import { NativeSupplyImageAssetRepository, NativeSupplyTextAssetRepository } from 'app/core/repositories/native-asset-repository.service';
import { NotificationsService } from 'app/core/notifications.service';
import {
    SupplyAssetProperties,
    SupplyAssetTypeId,
    SupplyImageAsset,
    SupplyImageAssets,
    SupplyTextAsset,
    SupplyTextAssets
} from 'app/shared/models';
import { IABAssets } from 'app/shared/models/native-supply';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs/index';


@Injectable()
export class SupplyAssetService {
    errorHelper: ErrorHelper = new ErrorHelper();

    constructor(
        private imageAssetRepository: NativeSupplyImageAssetRepository,
        private textAssetRepository: NativeSupplyTextAssetRepository,
        private notifications: NotificationsService
    ) { }

    /**
     * Generate an empty supplyAssets object to be used
     * @param supplyAssets
     */
    initSupplyAssets(supplyAssets) {
        for (let prop of SupplyAssetProperties) {
            supplyAssets[prop.modelKey] = {
                required: prop.required || false,
                model: prop.len ? prop.len : { width: prop.width, height: prop.height },
                typeId: prop.type,
                typeDescription: prop.assetKey,
                id: null
            };
        }
    }

    /**
     * Generate asset models based on model
     * @param asset
     */
    assetFactory(asset) {
        let version = null;
        if (asset.ext) {
            version = asset.ext.version;
        } else if (asset.version) {
            version = asset.version;
        } else {
            version = null;
        }

        if (asset[IABAssets.title]) {
            return new SupplyTextAsset({
                len: asset[IABAssets.title].len,
                refId: asset.id || null,
                version
            });
        } else if (asset[IABAssets.data]) {
            return new SupplyTextAsset({
                len: asset[IABAssets.data].len,
                type: asset[IABAssets.data].type,
                refId: asset.id || null,
                version
            });
        } else if (asset[IABAssets.img]) {
            const newAsset = new SupplyImageAsset({
                type: asset[IABAssets.img].type,
                minWidth: asset[IABAssets.img].minWidth || null,
                minHeight: asset[IABAssets.img].minheight || null,
                width: asset[IABAssets.img].width || null,
                height: asset[IABAssets.img].height || null,
                refId: asset.id || null,
                version
            });
            if (newAsset.minHeight) {
                delete newAsset.height;
                delete newAsset.width;
            } else {
                delete newAsset.minHeight;
                delete newAsset.minWidth;
            }
            return newAsset;
        } else {
            return null;
        }
    }

    saveAssets(blueprint) {
        return of(blueprint)
            .pipe(
                switchMap(blueprint => this.saveAsset(blueprint)),
                switchMap(([blueprint, blueprintAssetDetails]) => this.linkAsset(blueprint, blueprintAssetDetails))
            );
    }

    /**
     * Save asset
     * @param blueprint
     */
    saveAsset(blueprint) {
        const supplyAssetDetails = [];
        blueprint.assets.forEach(asset => {
            const updateAsset = this.assetFactory(asset);
            if (asset[IABAssets.img]) {
                supplyAssetDetails.push(this.saveImageAsset(updateAsset));
            } else {
                supplyAssetDetails.push(this.saveTextAsset(updateAsset));
            }
        });
        return forkJoin(of(blueprint), forkJoin(supplyAssetDetails));
    }

    /**
     * Link Asset
     * @param blueprint
     * @param {any} supplyAssetDetails
     * @returns {Observable<Blueprint>}
     */
    linkAsset(blueprint, supplyAssetDetails = null) {
        blueprint.assets = blueprint.assets.map(asset => {
            if (this.getAssetId(asset, supplyAssetDetails)) {
                asset.id = this.getAssetId(asset, supplyAssetDetails);
            }

            return asset;
        });

        // Link assets to creative
        blueprint.assets = blueprint.assets.map(asset => ({
                id: asset.id,
                required: asset.required
            })
        );

        return of(blueprint);
    }


    /**
     * Get AssetId from Asset Details
     * @param asset
     * @param supplyAssetDetails
     * @returns {number | null}
     */
    getAssetId(asset, supplyAssetDetails) {
        if (asset.id) {
            return asset.id;
        }

        let id = null;
        supplyAssetDetails.forEach(supplyAssetDetail => {
            if (asset[IABAssets.title]) {
                if (supplyAssetDetail.assetTypeId === SupplyAssetTypeId.Title) {
                    id = supplyAssetDetail.id;
                }
            } else if (asset[IABAssets.data] && supplyAssetDetail instanceof SupplyTextAsset) {
                if (supplyAssetDetail.type === asset[IABAssets.data].type) {
                    id = supplyAssetDetail.id;
                }
            } else if (asset[IABAssets.img] && supplyAssetDetail instanceof SupplyImageAsset) {
                if (supplyAssetDetail.type === asset[IABAssets.img].type) {
                    id = supplyAssetDetail.id;
                }
            }
        });

        return id;
    }

    saveImageAsset(asset) {
        if (asset.minWidth && asset.minHeight || asset.width && asset.height) {
            return this.imageAssetRepository.save(asset)
                .pipe(catchError(err => this.handleError(err)));
        }
    }

    saveTextAsset(asset) {
        if (asset.len) {
            return this.textAssetRepository.save(asset)
                .pipe(catchError(err => this.handleError(err)));
        }
    }

    /**
     * Handle Error
     * @param {HttpErrorResponse} err
     */
    handleError(err: HttpErrorResponse) {
        if (!(err instanceof HttpErrorResponse)) {
            this.notifications.error('There was a problem saving your asset.', 'Oh no!');
        } else {
            this.errorHelper.loadBackEndErrors(err);
            if (this.errorHelper.hasErrors) {
                this.notifications.error(this.errorHelper.errors[0], 'Oh no!');
            } else {
                this.notifications.error('There was a problem saving your asset.', 'Oh no!');
            }
        }

        return throwError(err);
    }

    /**
     * Set assets if they currently exist
     * @param obj
     * @param supplyAssets
     */
    setAssets(obj, supplyAssets) {
        if ((obj && obj.assets && obj.assets.length) || (obj && obj.native && obj.native.assets && obj.native.assets.length)) {
            const assets = obj.assets ? obj.assets : obj.native.assets;
            for (const asset of assets) {
                if (asset[IABAssets.title]) {
                    this.setSupplyTitleAsset(asset, supplyAssets);
                } else if (asset[IABAssets.data]) {
                    this.setSupplyDataAsset(asset, supplyAssets);
                } else {
                    this.setSupplyImageAsset(asset, supplyAssets);
                }
            }
        }
    }

    /**
     * Set Title Asset
     * @param asset
     * @param supplyAssets
     */
    setSupplyTitleAsset(asset, supplyAssets) {
        supplyAssets['headline'].id = asset.id || null;
        supplyAssets['headline'].model = asset[IABAssets.title].len;
        supplyAssets['headline'].required = asset.required;
        supplyAssets['headline'].typeDescription = IABAssets.title;
        supplyAssets['headline'].version = asset.ext ? asset.ext.version : null;
    }

    /**
     * Set Data Asset
     * @param asset
     * @param supplyAssets
     */
    setSupplyDataAsset(asset, supplyAssets) {
        for (let supplyTextAsset of SupplyTextAssets) {
            if (asset[IABAssets.data].type === supplyTextAsset.id) {
                supplyAssets[supplyTextAsset.key].id = asset.id || null;
                supplyAssets[supplyTextAsset.key].model = asset[IABAssets.data].len;
                supplyAssets[supplyTextAsset.key].required = asset.required;
                supplyAssets[supplyTextAsset.key].typeDescription = IABAssets.data;
                supplyAssets[supplyTextAsset.key].typeId = supplyTextAsset.id;
                supplyAssets[supplyTextAsset.key].version = asset.ext ? asset.ext.version : null;
            }
        }
    }

    /**
     * Set Image Asset
     * @param asset
     * @param supplyAssets
     */
    setSupplyImageAsset(asset, supplyAssets) {
        if (!asset[IABAssets.img]) {
            return;
        }
        const assetImageValues = asset[IABAssets.img];
        for (let supplyImageAsset of SupplyImageAssets) {
            if (asset[IABAssets.img].type === supplyImageAsset.id) {
                supplyAssets[supplyImageAsset.key].id = asset.id || null;
                supplyAssets[supplyImageAsset.key].model = { width: assetImageValues.wmin || assetImageValues.w, height: assetImageValues.hmin || assetImageValues.h};
                supplyAssets[supplyImageAsset.key].required = asset.required;
                supplyAssets[supplyImageAsset.key].typeDescription = IABAssets.img;
                supplyAssets[supplyImageAsset.key].typeId = supplyImageAsset.id;
                supplyAssets[supplyImageAsset.key].version = asset.ext ? asset.ext.version : null;
                supplyAssets[supplyImageAsset.key].width = asset[IABAssets.img].width;
                supplyAssets[supplyImageAsset.key].height = asset[IABAssets.img].height;
                supplyAssets[supplyImageAsset.key].isExact = assetImageValues.wmin ? false : true;
            }
        }
    }
}

