import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StrategyCard } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { map } from 'rxjs/operators';

@Injectable()
export class StrategyCardRepository extends BackendRepository<StrategyCard> {
    public constructor(http: HttpClient) {
        super(http, '/strategyCard', StrategyCard);
    }

    public cards(): Observable<StrategyCard[]> {
        return this.http.get(this.url('/strategy-card/cards')).pipe(map(response => response['output']));
    }
}
