import { Component, ChangeDetectionStrategy, EventEmitter, Output, ViewChild } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { BulkEditLightboxComponent } from 'app/shared/components/bulk-edit-lightbox';
import { AdSlot } from 'app/shared/models';
import { AdSlotRepository, NewsletterRepository } from 'app/core/repositories';
import { BulkResponseProcessor, NotificationsService } from 'app/core';
import { sortBy } from 'app/core/array-utils';

@Component({
    selector: 'ad-slot-bulk-edit',
    templateUrl: './ad-slot-bulk-edit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdSlotBulkEditComponent {
    @ViewChild(BulkEditLightboxComponent, { static: true }) lightbox: BulkEditLightboxComponent;
    @Output() completed = new EventEmitter();

    constructor(
        private adSlotRepository: AdSlotRepository,
        private notifications: NotificationsService,
        private newsletterRepository: NewsletterRepository
    ) {}

    open(adSlots: AdSlot[], field: string = null) {
        this.lightbox.initiate();
        this.lightbox.open(adSlots, field, 'load');

        forkJoin(
            adSlots.map(adSlot => this.adSlotRepository.get(adSlot.id).pipe(
                mergeMap(detail => {
                    return forkJoin(of(detail), this.newsletterRepository.get(detail.newsletter));
                }),
                map( ([detail, newsletter]) => {
                    detail.newsletterObj = newsletter;
                    return detail;
                }),
                tap(() => this.lightbox.postProgress()),
            ))
        ).subscribe(
            details => this.lightbox.open(sortBy(details, 'isUniversalTag').reverse(), field),
            () => this.handleError()
        );
    }

    close() {
        this.lightbox.close();
    }

    submit({ changed, originals }: { changed: AdSlot[], originals: AdSlot[], label: string }) {
        this.lightbox.initiate();

        const requests = changed.map(adSlot => {
            adSlot.sizes.forEach(size => {
                delete size.isEnabled;
            });

            return this.adSlotRepository.save(adSlot)
                .pipe(
                    tap(() => this.lightbox.postProgress()),
                    catchError(e => {
                        this.lightbox.postProgress();
                        return of(e);
                    })
                );
        });

        forkJoin(requests).subscribe(
            responses => this.handleAction(originals, responses),
            () => this.handleError()
        );
    }

    private handleAction(attempted: AdSlot[], responses: any[]) {
        const { status, failureItems, successItems } = (new BulkResponseProcessor()).processSaves(attempted, responses);

        if (status === BulkResponseProcessor.AllOK) {
            // All OK
            this.notifications.success(
                `Successfully updated ${successItems.length} ad slot${successItems.length === 1 ? '' : 's'}.`
            );
            this.completed.emit();
            this.close();
            return;
        }

        if (status === BulkResponseProcessor.AllFailed) {
            // All failed
            return this.notifications.error(
                `No ad slots were updated because the following errors have occurred:
                <ul>${failureItems.join('')}</ul>`, '', 0
            );
        }

        // Some succeeded, some failed
        this.notifications.warning(
            `The following ad slots were successfully updated:
             <ul>${successItems.join('')}</ul>
             <br>
             However, the following ad slots were not updated:
             <ul>${failureItems.join('')}</ul>`, '', 0);
        this.completed.emit();
        this.close();
    }

    private handleError() {
        this.notifications.error('An error has occurred.');
    }
}
