import { Observable } from 'rxjs';
import { BackendRepository, PaginatedResponse, Condition } from './backend-repository';
import { map } from 'rxjs/operators';
import { Model } from 'app/shared/models/model';
import { dedupe } from 'app/core/array-utils';

// like is also supported, but we want to force ilike
const laravelResourceSearchSupportedOperators = [
    '<', '<=', '>', '>=', '=', '!=', 'not like', 'ilike', 'not ilike', 'in', 'not in', 'all in', 'any in'
];

const operatorMap = {
    like: 'ilike', // for consistency, bc lsd is always case insensitive anyway
    co: 'ilike',
    nco: 'not ilike',
    eq: '=',
    ne: '!=',
    gt: '>',
    lt: '<',
    lteq: '<=',
    gteq: '>=',
};

export abstract class BackendResourceRepository<T extends Model> extends BackendRepository<T> {
    search(query?: {}): Observable<T[]> {
        return this.rawSearch(query)
            .pipe(map(response => response['data'].map(data => this.build(data)) as T[]));
    }

    asyncSearch(params: any): Observable<PaginatedResponse> {
        return this.rawSearch(params)
            .pipe(map(response => ({
                items: response['data'].map(item => this.build(item) as T),
                page: 1,
                pages: Math.ceil(response['meta']['total'] / response['meta']['per_page']),
                total: response['meta']['total']
            })));
    }

    protected rawSearch(query?: {}, uri?: string) {
        const path = uri || this.path + '/search';
        const page = query['page'] || 1
        const size = query['n'] || 500

        const paginationQueryString = `page[number]=${page}&page[size]=${size}`;

        return this.http.post(this.url(`${path}?${paginationQueryString}`), this.sanitizeQuery(query));
    }

    protected sanitizeQuery(query?: any) {
        return {
            filters: (query.conditions || []).map(c => this.convertConditionToFilter(c)),
            sort: query.orderBy && [
                { field: query.orderBy, direction: query.sort }
            ],
            scopes: query.scope && [
                { name: query.scope }
            ]
        };
    }

    private convertConditionToFilter(condition: Condition) {
        if (Array.isArray(condition.conditions)) {
            return {
                type: condition.mode || 'and',
                nested: condition.conditions.map(c => this.convertConditionToFilter(c))
            };
        }

        return {
            type: condition.mode,
            field: condition.field,
            value: this.getValue(condition),
            operator: this.getOperator(condition),
        };
    }

    private getValue(condition: Condition) {
        const maybeLikify = (value: string) => {
            if (value && this.getOperator(condition).includes('like') && !value.includes('%')) {
                return '%' + value + '%';
            }

            return value;
        };

        if (Array.isArray(condition.value)) {
            return dedupe(condition.value).map(v => maybeLikify(v));
        }

        return maybeLikify(condition.value);
    }

    private getOperator(condition: Condition) {
        if (laravelResourceSearchSupportedOperators.includes(condition.operator)) {
            return condition.operator;
        }

        if (Array.isArray(condition.value)) {
            return condition.operator === 'neq' ? 'not in' : 'in';
        }

        return operatorMap[condition.operator] || '=';
    }
}
