import * as tslib_1 from "tslib";
import { Filter } from '../filter';
import { Operation } from '../operation';
var FilterNumber = /** @class */ (function (_super) {
    tslib_1.__extends(FilterNumber, _super);
    function FilterNumber() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.operations = [
            Operation.GreaterThan,
            Operation.LessThan,
            Operation.Equals,
            Operation.DoesNotEqual
        ];
        return _this;
    }
    FilterNumber.prototype.isValid = function () {
        return _super.prototype.isValid.call(this) && !isNaN(Number(this.query));
    };
    return FilterNumber;
}(Filter));
export { FilterNumber };
