import {ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild} from "@angular/core";
import {BulkEditLightboxComponent} from "app/shared/components/bulk-edit-lightbox";
import {BulkOperationRepository, NewsletterRepository, PublisherRepository} from 'app/core/repositories';
import {BulkOperationStatusToastService, NotificationsService} from "app/core";
import {Newsletter} from "app/shared/models";
import {BehaviorSubject, forkJoin} from "rxjs";
import {distinctUntilChanged, map, switchMap, tap} from "rxjs/operators";
import {BulkOperationRequest, Operation, OperationMethod} from "app/shared/models/bulk-request";
import {Flag, LaunchDarklyService} from "app/core/launch-darkly.service";


@Component({
    selector: 'newsletter-bulk-edit',
    templateUrl: './newsletter-bulk-edit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsletterBulkEditComponent {
    @ViewChild(BulkEditLightboxComponent, { static: true }) lightbox: BulkEditLightboxComponent;
    @Output() completed = new EventEmitter();

    private canSetCoordination$ = new BehaviorSubject(false);
    public canSetCoordination: boolean = false;

    constructor(
        protected newsletterRepository: NewsletterRepository,
        protected publisherRepository: PublisherRepository,
        protected bulkOperationRepository: BulkOperationRepository,
        protected bulkOperationStatusToast: BulkOperationStatusToastService,
        protected notifications: NotificationsService,
        protected launchDarklyService: LaunchDarklyService,
    ) {}

    public ngOnInit() {
        this.launchDarklyService
            .getVariation(Flag.AllowCoordination)
            .pipe(distinctUntilChanged())
            .subscribe(canSetCoordination => {
                this.canSetCoordination$.next(canSetCoordination);
            });

        this.canSetCoordination$.subscribe(value => {
            this.canSetCoordination = value;
        });
    }

    open(newsletters: Newsletter[], field: string = null) {
        this.lightbox.initiate();
        this.lightbox.open(newsletters, field, 'load');

        const publisherIds: string[] = newsletters.map(n => n.publisher);

        forkJoin(
            publisherIds.map(p => this.publisherRepository.get(p))
        ).pipe(
            switchMap(publishers => {
                const publisherMap = new Map(publishers.map(p => [p.id, p]));

                return forkJoin(
                    newsletters.map(newsletter =>
                        this.newsletterRepository.get(newsletter.id).pipe(
                            tap(() => this.lightbox.postProgress()),
                            map(detail => {
                                detail.publisherObj = publisherMap.get(newsletter.publisher);
                                return detail;
                            })
                        )
                    )
                );
            })
        ).subscribe(
            details => this.lightbox.open(details, field),
            () => this.handleError()
        );
    }

    close() {
        this.lightbox.close();
    }

    submit({ changed }: { changed: Newsletter[] }): void {
        this.lightbox.initiate();
        let asJson = true;

        // TODO:CS -- Debugging
        console.log("Will be sending....", changed.map(newsletter => newsletter.serialize(asJson)));

        const operations = changed.map(newsletter => new Operation(
            OperationMethod.Post,
            '/newsletter/' + newsletter.refId,
            newsletter.serialize(asJson),
            newsletter.refId.toString(),
        ));

        this.bulkOperationRepository.sendRequest(new BulkOperationRequest(operations)).subscribe(
            () => this.createBulkOperationStatusToast(),
            () => this.handleSaveError()
        );
    }

    protected createBulkOperationStatusToast(): void {
        this.bulkOperationStatusToast.fetchStatusData();
        this.completed.emit();
        this.close();

        return;
    }

    protected handleError() {
        this.notifications.error('An error has occurred.');
    }

    protected handleSaveError(): void {
        this.lightbox.resetForm();
        this.handleError();
    }
}
