import { Component, Input, OnInit } from '@angular/core';
import { IdService } from 'app/core/id.service';

@Component({
    selector: 'selection-summary',
    templateUrl: './selection-summary.html',
    styleUrls: ['./selection-summary.styl']
})
export class SelectionSummaryComponent implements OnInit {
    @Input() title: string;
    @Input() selections: any[];
    @Input() hideThreshold: number = Infinity;
    @Input('hide-targeting-type') hideTargetingType: string = null;
    @Input() isExcluding: boolean = false;
    @Input() printData: Function;
    @Input() showCount: boolean = true;
    @Input() displayAsTable: boolean = false;
    @Input() columns: string[];
    @Input() wide: boolean = false;

    count: number = 0;

    dataVisible: boolean;

    constructor (
        public id: IdService
    ) { }

    ngOnInit (): void {
        if (Array.isArray(this.selections)){
            this.count = this.selections.length;
        }
        this.dataVisible = this.count < this.hideThreshold;
    }

    toggleVisibility (event): void {
        event.preventDefault();
        this.dataVisible = !this.dataVisible;
    }

    get _count() {
        return this.selections.length;
    }
}
