import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { BackendRepository } from './backend-repository';
import { Model } from 'app/shared/models';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SnakeToCamelCasePipe } from 'app/shared/elements/pipes';
import { forkJoin } from 'rxjs';
var SOROBAN = /** @class */ (function (_super) {
    tslib_1.__extends(SOROBAN, _super);
    function SOROBAN() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SOROBAN.prototype.serialize = function () {
        return null;
    };
    Object.defineProperty(SOROBAN.prototype, "entity", {
        get: function () {
            return 'SOROBAN';
        },
        enumerable: true,
        configurable: true
    });
    return SOROBAN;
}(Model));
var SorobanRepository = /** @class */ (function (_super) {
    tslib_1.__extends(SorobanRepository, _super);
    function SorobanRepository(http, snakeToCamelCasePipe) {
        var _this = _super.call(this, http, environment.backend.hostname + "/search", SOROBAN) || this;
        _this.snakeToCamelCasePipe = snakeToCamelCasePipe;
        _this.indices = [
            'agencies',
            'advertisers',
            'insertion_orders',
            'campaigns',
            'line_items',
            'creatives',
            'media_groups',
            'publishers',
            'newsletters',
            'ad_slots',
        ];
        return _this;
    }
    SorobanRepository.prototype.mapSorobanMultipleEntitiesWithCount = function (response) {
        var _this = this;
        response['total'] = 0;
        return Object.keys(response).map(function (key) {
            if (key != 'total') {
                var entity = response[key];
                response['total'] += entity['total'];
                var output = entity.output;
                entity['entities'] = output.map(function (indexElement) {
                    return Object.keys(indexElement).reduce(function (acc, key) {
                        acc[_this.snakeToCamelCasePipe.transform(key)] =
                            indexElement[key];
                        return acc;
                    }, {});
                });
                delete entity.output;
            }
        });
    };
    SorobanRepository.prototype.mapFlatOutput = function (response) {
        var _this = this;
        response['output'] = response['output'].map(function (output) {
            return Object.keys(output).reduce(function (acc, key) {
                acc[_this.snakeToCamelCasePipe.transform(key)] = output[key];
                return acc;
            }, {});
        });
    };
    SorobanRepository.prototype.sorobanSearch = function (query) {
        var _this = this;
        var params = { params: { query: query ? "" + query : '' } };
        var limit = '5';
        var indicesToSearch = {};
        this.indices.forEach(function (index) {
            var key = _this.snakeToCamelCasePipe.transform(index);
            indicesToSearch[key] = _this.http.get(_this.path + "/simple", {
                params: tslib_1.__assign({}, params.params, { indices: index, page: '1', pageSize: limit, sort: 'name:asc' }),
            });
        });
        return forkJoin(indicesToSearch).pipe(map(function (response) {
            _this.mapSorobanMultipleEntitiesWithCount(response);
            return response;
        }));
    };
    SorobanRepository.prototype.eSorobanSearch = function (params) {
        var _this = this;
        var paramToPass = {
            params: {
                query: params.query ? "" + params.query : '',
            },
        };
        if (params.indices && params.indices.length) {
            paramToPass.params['indices'] = params.indices.join(',');
        }
        if (params.n) {
            paramToPass.params['n'] = params.n;
        }
        if (params.page) {
            paramToPass.params['page'] = params.page;
        }
        if (params.sort) {
            paramToPass.params['sort'] = params.sort;
        }
        if (params.orderBy) {
            paramToPass.params['orderBy'] = params.orderBy;
        }
        return this.http
            .get(environment.backend.hostname + "/search/legacy", paramToPass)
            .pipe(map(function (data) {
            _this.mapFlatOutput(data);
            return {
                items: data['output'],
                page: params.page,
                pages: data['pages'],
                total: data['total'],
            };
        }));
    };
    return SorobanRepository;
}(BackendRepository));
export { SorobanRepository };
