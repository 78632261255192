import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { TableMode } from './table-mode';
import { Filter } from 'app/shared/elements/filters';
import { groupBy } from 'app/core/array-utils';

const URL_PARAM_DELIMETER = '|';
const URL_PARAM_GROUP_DELIMETER = '+';

type TableURLParams = {
    filters: Filter[],
    mode: TableMode,
    page: number
};

@Injectable()
export class TableURLService {
    private map = new Map<string, TableURLParams>();

    constructor(
        private router: Router,
        private location: Location,
        private route: ActivatedRoute
    ) { }

    /**
     * Generate query parameters for the table defined with a given `routeKey`.
     */
    generate(routeKey: string) {
        if (!this.map.has(routeKey)) {
            return {};
        }

        const params = this.map.get(routeKey);
        const validFilters = params.filters.filter(filter => filter.isValid());
        const groups = Object.values(groupBy(validFilters, 'groupId'));

        let generated = groups.map((group: Filter[]) => {
            return group.map(filter => this.convertFilterToParams(filter)).join(URL_PARAM_DELIMETER);
        }).join(URL_PARAM_GROUP_DELIMETER);

        generated += `${URL_PARAM_GROUP_DELIMETER}mode:${params.mode || TableMode.Basic}`;
        generated += `${URL_PARAM_GROUP_DELIMETER}page:${params.page || 1}`;

        return {
            [routeKey]: generated
        };
    }

    /**
     * Push filters onto the URL for a given `routeKey`.
     */
    pushFilters(routeKey: string, filters: Filter[]) {
        if (!routeKey) {
            return;
        }

        if (!this.map.has(routeKey)) {
            this.map.set(routeKey, { filters: [] } as TableURLParams);
        }

        this.map.get(routeKey).filters = filters;

        this.replaceState(this.generate(routeKey));
    }

    /**
     * Push mode onto the URL for a given `routeKey`.
     */
    pushMode(routeKey: string, mode: TableMode) {
        if (!routeKey) {
            return;
        }

        if (!this.map.has(routeKey)) {
            this.map.set(routeKey, { filters: [] } as TableURLParams);
        }

        this.map.get(routeKey).mode = mode;

        this.replaceState(this.generate(routeKey));
    }

    /**
     * Push the current page onto the URL for a given `routeKey`.
     */
    pushPage(routeKey: string, page: number) {
        if (!routeKey) {
            return;
        }

        if (!this.map.has(routeKey)) {
            this.map.set(routeKey, { filters: [] } as TableURLParams);
        }

        this.map.get(routeKey).page = page;

        this.replaceState(this.generate(routeKey));
    }

    /**
     * Replace the current URL with a URL containing `queryParams`.
     */
    private replaceState(queryParams: any) {
        this.location.replaceState(
            this.router
                .createUrlTree([], {
                    relativeTo: this.route,
                    queryParams
                })
                .toString()
        );
    }

    /**
     * Parse the mode from a param map for a given `routeKey`.
     */
    parseModeFromParamMap(routeKey: string, paramMap: ParamMap) {
        if (!paramMap.has(routeKey)) {
            return TableMode.Basic;
        }

        // parse the mode
        const key = 'mode:';
        const param = (
            paramMap.get(routeKey)
                .split(URL_PARAM_GROUP_DELIMETER)
                .find(group => group.indexOf(key) > -1) || ''
        ).slice(key.length);

        return param;
    }

    /**
     * Parse the page from a param map for a given `routeKey`.
     */
    parsePageFromParamMap(routeKey: string, paramMap: ParamMap) {
        if (!paramMap.has(routeKey)) {
            return 1;
        }

        // parse the page
        const key = 'page:'
        const param = (
            paramMap.get(routeKey)
                .split(URL_PARAM_GROUP_DELIMETER)
                .find(group => group.indexOf(key) > -1) || ''
        ).slice(key.length);

        return parseInt(param);
    }

    /**
     * Parse the filters from a param map for a given `routeKey`.
     */
    parseFiltersFromParamMap(routeKey: string, paramMap: ParamMap, availableFilters: Filter[]) {
        if (!paramMap.has(routeKey) || !Array.isArray(availableFilters)) {
            return [];
        }

        // parse the filters
        const filters = [];
        paramMap.get(routeKey)
            .split(URL_PARAM_GROUP_DELIMETER)
            .forEach((group, index) => {
                group.split(URL_PARAM_DELIMETER)
                    .map(params => {
                        const parsed = this.parseFilterFromParams(params);

                        const filter = availableFilters.find(filter => filter.field === parsed.field);
                        if (filter) {
                            return new (filter as any).constructor(Object.assign(filter, {
                                groupId: index,
                                query: parsed.query,
                                label: filter.label,
                                operation: parsed.operation,
                            }));
                        }
                    })
                    .filter(filter => filter && filter.isValid())
                    .forEach(filter => filters.push(filter));
            });

        return filters;
    }

    /**
     * Parse a filter from a param group.
     * For example: '?tableParams=advertiserRefId:co:test,advertiserName:ne:tester'
     */
    private parseFilterFromParams(params: string) {
        // some magic to only split by the first two `:`s
        const split = params.split(':');
        const parsed = split.splice(0, 2);
        parsed.push(split.join(':'));

        return {
            field: parsed[0],
            operation: parsed[1],
            query: parsed[2]
        };
    }

    /**
     * Convert a filter to a URL param string.
     * For example: '?tableParams=advertiserRefId:co:test,advertiserName:ne:tester'
     */
    private convertFilterToParams(filter: Filter) {
        return [filter.field, filter.operation, filter.query].join(':');
    }
}
