import { Injectable } from '@angular/core';
import { Subscription, EMPTY, Subject, of, concat, Observable } from 'rxjs';
import { pairwise, delay, concatMap, map, repeat, distinctUntilChanged, startWith } from 'rxjs/operators';

import { AuthenticationService } from 'app/core/authentication.service';
import { BulkOperationRepository } from 'app/core/repositories/bulk-operation-repository.service';
import { Status } from 'app/shared/models/bulk-request';

@Injectable()
export class BulkOperationStatusToastService {
    show$ = new Subject<Status>();
    remove$ = new Subject<null>();
    data$ = new Subject<any[]>();
    fetchData: Subscription;
    private user = null;
    delayed$ = EMPTY.pipe(delay(5000));

    constructor (
        private authentication: AuthenticationService,
        private bulkOperationRepo: BulkOperationRepository
    ) {
        this.authentication.currentUser.subscribe(user => this.user = user);
        this.getStatusData().subscribe(status => this.createStatusToast(status));
        this.fetchStatusData();
    }

    private getStatus([prevData, currData]): string {
        if (prevData.length <= 0 || currData.length <= 0) {
            return Status.Pending;
        }

        const previousDataCount = prevData.reduce((acc, batch) =>{
            acc.failedOperationCount += batch.completed_with_failures;
            acc.failedBatchCount += batch.status === Status.Failed ? 1 : 0;
            acc.successBatchCount += batch.status === Status.Success ? 1 : 0;

            return acc;
        }, {
            failedOperationCount: 0,
            failedBatchCount: 0,
            successBatchCount: 0,
        });

        const currentDataCount = currData.reduce((acc, batch) => {
            acc.failedOperationCount += batch.completed_with_failures;
            acc.failedBatchCount += batch.status === Status.Failed ? 1 : 0;
            acc.successBatchCount += batch.status === Status.Success ? 1 : 0;
            acc.pendingBatchCount += batch.status === Status.Pending ? 1 : 0;

            return acc;
        }, {
            failedOperationCount: 0,
            failedBatchCount: 0,
            successBatchCount: 0,
            pendingBatchCount: 0,
        });

        if ((currentDataCount.failedOperationCount > previousDataCount.failedOperationCount) || (currentDataCount.failedBatchCount > previousDataCount.failedBatchCount)) {
            return Status.Failed;
        }

        if (!currentDataCount.pendingBatchCount || (currentDataCount.successBatchCount > previousDataCount.successBatchCount)) {
            return Status.Success;
        }

        return Status.Pending;
    }

    createStatusToast(data: Status): void {
        this.show$.next(data);
    }

    removeStatusToast(): void {
        this.remove$.next();
    }

    fetchStatusData(): void {
        if (this.fetchData === undefined || this.fetchData.closed) {
            const twoHoursAgo = new Date(Date.now() - 1000 * 60 * 60 * 2).toISOString();

            const params = {
                conditions: [{
                    field: 'user',
                    value: this.user.id
                }]
            }

            this.fetchData = this.bulkOperationRepo.getStatusByUser(params).pipe(
                map(response => response.items.filter(bulkOperation => bulkOperation.created_at > twoHoursAgo)),
                map(bulkOperations => bulkOperations.map(bulkOperation => ({
                        status: bulkOperation.status,
                        completed_successfully: bulkOperation.completed_successfully,
                        completed_with_failures: bulkOperation.completed_with_failures
                }))),
                concatMap(v => concat(of(v), this.delayed$)),
                repeat(),
                startWith([]),
                pairwise(),
                distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
            ).subscribe(([prevData, currData]) => {
                const shouldContinue = currData.some(bulkOperation => bulkOperation.status === Status.Pending);

                if (!shouldContinue) {
                    this.stop();

                    if (prevData.length > 0) {
                        this.data$.next([prevData, currData]);
                    }
                } else {
                    this.data$.next([prevData, currData]);
                }
            });
        }
    }

    private getStatusData(): Observable<any> {
        return this.data$.pipe(
            map(data => this.getStatus([data[0], data[1]])),
            distinctUntilChanged((prev, curr) => prev === curr && curr === Status.Pending)
        );
    }

    stop(): void {
        this.fetchData.unsubscribe();
    }
}
