import { Component, ViewChild } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { IdService } from 'app/core';
import { AuthenticationService } from 'app/core/authentication.service';
import { PaginatedResponse } from 'app/core/repositories/backend-repository';
import { BulkOperationRepository } from 'app/core/repositories/bulk-operation-repository.service';
import { BulkOperationStatusModalComponent } from 'app/shared/components/bulk-operation-status-modal';
import { PaginatorComponent } from 'app/shared/elements/paginator';
import { TableComponent } from 'app/shared/elements/table';
import { TableHelper } from 'app/shared/helpers/table-helper';
import { SearchParams } from 'app/shared/helpers/query-builder';
import { BulkOperationStatusResponse, Status } from 'app/shared/models/bulk-request';

const DEFAULT_PAGE_SIZE = 25;

@Component({
    templateUrl: './bulk-operation-status.html',
    styleUrls: ['bulk-operation-status.styl']
})
export class BulkOperationStatusComponent {
    @ViewChild(BulkOperationStatusModalComponent, { static: false }) statusModalComponent: BulkOperationStatusModalComponent;
    @ViewChild(TableComponent, { static: true }) table: TableComponent;
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent;

    private user = null;
    helper = new TableHelper<BulkOperationStatusResponse>(params => this.getTableData(params));
    status = Status;

    constructor(
        private authentication: AuthenticationService,
        private bulkOperationRepo: BulkOperationRepository,
        public id: IdService
    ) {
        this.authentication.currentUser.subscribe(user => this.user = user);
    }

    ngOnInit() {
        this.paginator.pageSize = DEFAULT_PAGE_SIZE;
        this.helper.table = this.table;
        this.helper.paginator = this.paginator;

        combineLatest(this.table.query, this.paginator.query).subscribe(args => this.helper.search(args));
    }

    private getTableData(params: SearchParams): Observable<PaginatedResponse> {
        params.conditions.push({
            field: 'user',
            value: this.user.id,
            operator: 'eq'
        });

        return this.bulkOperationRepo.getStatusByUser(params)
            .pipe(map(response => {
                response.items.map(bulkOperation => this.processApiResponse(bulkOperation));

                return response;
            }));
    }

    private processApiResponse(bulkOperationStatusResponse: BulkOperationStatusResponse): BulkOperationStatusResponse {
        if (bulkOperationStatusResponse.status === Status.Success) {
            if (bulkOperationStatusResponse.completed_successfully && bulkOperationStatusResponse.completed_with_failures) {
                bulkOperationStatusResponse.status = Status.CompletedWithFailures;
            } else if (bulkOperationStatusResponse.completed_with_failures) {
                bulkOperationStatusResponse.status = Status.Failed;
            } else if (bulkOperationStatusResponse.completed_successfully) {
                bulkOperationStatusResponse.status = Status.Completed;
            }
        }

        return bulkOperationStatusResponse;
    }

    showModal(bulkOperationStatusResponse: BulkOperationStatusResponse): void {
        this.statusModalComponent.open(bulkOperationStatusResponse);
    }
}
