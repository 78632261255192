/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./zero-state.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./zero-state.component";
var styles_ZeroStateComponent = [i0.styles];
var RenderType_ZeroStateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ZeroStateComponent, data: {} });
export { RenderType_ZeroStateComponent as RenderType_ZeroStateComponent };
export function View_ZeroStateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "container background"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
export function View_ZeroStateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "zero-state", [], null, null, null, View_ZeroStateComponent_0, RenderType_ZeroStateComponent)), i1.ɵdid(1, 49152, null, 0, i2.ZeroStateComponent, [], null, null)], null, null); }
var ZeroStateComponentNgFactory = i1.ɵccf("zero-state", i2.ZeroStateComponent, View_ZeroStateComponent_Host_0, {}, {}, ["*"]);
export { ZeroStateComponentNgFactory as ZeroStateComponentNgFactory };
