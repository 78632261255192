import { NgModel } from '@angular/forms';
var BulkEditAdSlotExchangeFloorComponent = /** @class */ (function () {
    function BulkEditAdSlotExchangeFloorComponent(label) {
        this._adSlots = [];
        this._originals = [];
        this.entityName = '';
        this.label = label;
    }
    BulkEditAdSlotExchangeFloorComponent.prototype.master = function (value) {
        this.entities.forEach(function (adSlot) {
            if (adSlot.hasOwnProperty('sspControl')) {
                adSlot.sspControl.exchangeFloor = value;
            }
        });
    };
    BulkEditAdSlotExchangeFloorComponent.prototype.original = function (index) {
        return this._originals[index];
    };
    BulkEditAdSlotExchangeFloorComponent.prototype.reset = function () {
        var _this = this;
        this.input.reset();
        this.entities.forEach(function (adSlot, index) {
            if (adSlot.hasOwnProperty('sspControl')) {
                adSlot.sspControl.exchangeFloor = _this.original(index).sspControl.exchangeFloor;
            }
        });
    };
    BulkEditAdSlotExchangeFloorComponent.prototype.isDirty = function () {
        var _this = this;
        return this.entities.some(function (adSlot, index) {
            return adSlot.sspControl && adSlot.sspControl.exchangeFloor !== _this.original(index).sspControl.exchangeFloor;
        });
    };
    Object.defineProperty(BulkEditAdSlotExchangeFloorComponent.prototype, "entities", {
        get: function () {
            return this._adSlots;
        },
        set: function (adSlots) {
            this._adSlots = adSlots;
            this._originals = adSlots.map(function (adSlot) { return adSlot.clone(); });
        },
        enumerable: true,
        configurable: true
    });
    return BulkEditAdSlotExchangeFloorComponent;
}());
export { BulkEditAdSlotExchangeFloorComponent };
