import {
    Component,
    ElementRef,
    Input
} from '@angular/core';

@Component({
    selector: 'option',
    template: `<ng-content></ng-content>`
})
export class OptionComponent {
    @Input() value: any = null;
    @Input() disabled;

    constructor(private element: ElementRef) { }

    get html() {
        return this.element.nativeElement.innerHTML;
    }
}
