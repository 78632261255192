import * as tslib_1 from "tslib";
import { Model } from '../model';
export var DemandTextAssetType;
(function (DemandTextAssetType) {
    DemandTextAssetType[DemandTextAssetType["SponsoredBy"] = 1] = "SponsoredBy";
    DemandTextAssetType[DemandTextAssetType["Description"] = 2] = "Description";
    DemandTextAssetType[DemandTextAssetType["CallToAction"] = 12] = "CallToAction";
})(DemandTextAssetType || (DemandTextAssetType = {}));
var ɵ0 = DemandTextAssetType.SponsoredBy, ɵ1 = DemandTextAssetType.CallToAction, ɵ2 = DemandTextAssetType.Description;
export var DemandTextAssets = [{
        id: ɵ0,
        key: 'sponsored'
    }, {
        id: ɵ1,
        key: 'callToAction'
    }, {
        id: ɵ2,
        key: 'description'
    }];
var DemandTextAsset = /** @class */ (function (_super) {
    tslib_1.__extends(DemandTextAsset, _super);
    function DemandTextAsset(data) {
        if (data === void 0) { data = {}; }
        return _super.call(this, data) || this;
    }
    Object.defineProperty(DemandTextAsset.prototype, "entity", {
        get: function () {
            return 'Native Demand Text Asset';
        },
        enumerable: true,
        configurable: true
    });
    return DemandTextAsset;
}(Model));
export { DemandTextAsset };
export { ɵ0, ɵ1, ɵ2 };
