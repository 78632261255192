import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';
import { ValidationService } from 'app/core/validation-service';

@Directive({
    selector: '[validate-secure-url][formControlName],[validate-secure-url][formControl],[validate-secure-url][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => SecureUrlValidator), multi: true }
    ]
})
export class SecureUrlValidator implements Validator {
    private validationService: ValidationService = new ValidationService();

    validate(control: AbstractControl): ValidationErrors | null {
        const url = control.value;

        if (!url || url === '') {
            return null;
        }

        return this.validationService.isSecureUrl(url) ? null : { 'secure' : { valid: false }};
    }
}
