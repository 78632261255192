import * as tslib_1 from "tslib";
import { OnInit, AfterViewInit } from '@angular/core';
import { Chart } from '../chart';
export var ValueFormat;
(function (ValueFormat) {
    ValueFormat[ValueFormat["Currency"] = 0] = "Currency";
    ValueFormat[ValueFormat["None"] = 1] = "None";
})(ValueFormat || (ValueFormat = {}));
var SparkChartComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SparkChartComponent, _super);
    function SparkChartComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.aggregate = null;
        _this.format = null;
        _this.height = 150;
        _this.width = 50;
        _this.showLegend = false;
        _this.showTooltip = false;
        _this.showPoints = false;
        _this.c3Config = {};
        _this.called = false;
        return _this;
    }
    SparkChartComponent.prototype.ngOnInit = function () {
        this.drawGraph();
    };
    SparkChartComponent.prototype.drawGraph = function () {
        this.called = true;
        this.chart = this.generate(Object.assign({
            size: {
                height: this.height,
                width: this.width
            },
            axis: {
                x: {
                    type: 'timeseries',
                    show: false
                },
                y: {
                    show: false
                }
            },
            point: {
                show: this.showPoints,
                r: 0,
                focus: {
                    expand: {
                        enabled: true,
                        r: 4
                    }
                }
            },
            legend: {
                show: false
            },
            tooltip: {
                show: this.showTooltip,
                format: {
                    title: this.d3.timeFormat('%b %e, %Y'),
                    value: this.valueFormat
                }
            }
        }, this.c3Config), {
            x: 'dates'
        });
    };
    SparkChartComponent.prototype.ngAfterViewInit = function () {
        _super.prototype.ngAfterViewInit.call(this);
        if (this.showLegend) {
            this.buildLegend();
        }
    };
    Object.defineProperty(SparkChartComponent.prototype, "valueFormat", {
        get: function () {
            switch (this.format) {
                case ValueFormat.Currency: return this.d3.format('$,.2r');
                default: return this.d3.format(',.2r');
            }
        },
        enumerable: true,
        configurable: true
    });
    SparkChartComponent.prototype.buildLegend = function () {
        var _this = this;
        var select = this.d3.select;
        var color = this.chart.color;
        var chart = this.chart;
        var items = select(this.element.nativeElement).append('div').attr('class', 'legend')
            .selectAll('div')
            .data(this.chart.data()).enter()
            .append('div').attr('class', 'chart-legend-item')
            .on('mouseover', function (data) {
            chart.focus(data.id);
            select(this).select('.chart-legend-circle').style('box-shadow', '0 0 0 1px ' + color(data.id));
        })
            .on('mouseout', function () {
            chart.revert();
            select(this).select('.chart-legend-circle').style('box-shadow', null);
        });
        items.append('div').attr('class', 'chart-legend-circle')
            .each(function (id) { select(this).style('border-color', color(id)); });
        items.append('div').attr('class', 'chart-legend-id')
            .html(function (data) { return data.id; });
        if (this.aggregate !== null) {
            items.append('div').attr('class', 'chart-legend-value')
                .html(function (data) { return _this.valueFormat(_this.aggregate(data.values.map(function (point) { return point.value; }))); });
        }
    };
    SparkChartComponent.prototype.rebuildLegend = function () {
        this.element.nativeElement.removeChild(this.element.nativeElement.lastChild);
        if (this.showLegend) {
            this.buildLegend();
        }
    };
    return SparkChartComponent;
}(Chart));
export { SparkChartComponent };
