import backendErrors from './back-end-errors.json';
import reportingErrors from './reporting-errors.json';
import { HttpErrorResponse } from '@angular/common/http';
var GENERIC_MESSAGE = 'An error has occurred.';
var ErrorHelper = /** @class */ (function () {
    function ErrorHelper() {
        //key value pair
        this.validations = [];
        this.apiErrors = [];
    }
    ErrorHelper.getMessage = function (code) {
        return backendErrors[code] || GENERIC_MESSAGE;
    };
    Object.defineProperty(ErrorHelper.prototype, "errors", {
        get: function () {
            var errorList = [];
            var _loop_1 = function (key) {
                if (Array.isArray(this_1.validations[key]) === true) {
                    var fields_1 = this_1.getError(key);
                    fields_1 = fields_1.filter(function (item, pos) {
                        return fields_1.indexOf(item) === pos;
                    });
                    var the = key == 'incompatible' ? '' : 'the ';
                    if (fields_1.length === 1) {
                        // Special rule for 'incompatible' error since each error always contains two entities in one field
                        var predicate = key == 'incompatible' ? ' are ' : ' is ';
                        errorList.push(the + fields_1.join() + predicate + key + '.');
                    }
                    else {
                        var x = fields_1.join(', ');
                        var pos = x.lastIndexOf(', ');
                        x = x.substring(0, pos) + ' and ' + x.substring(pos + 1);
                        errorList.push(the + x + ' are ' + key + '.');
                    }
                }
                else {
                    errorList.push(this_1.validations[key]);
                }
            };
            var this_1 = this;
            for (var key in this.validations) {
                _loop_1(key);
            }
            return errorList;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ErrorHelper.prototype, "size", {
        get: function () {
            return Object.keys(this.validations).length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ErrorHelper.prototype, "hasErrors", {
        get: function () {
            return this.size > 0;
        },
        enumerable: true,
        configurable: true
    });
    ErrorHelper.prototype.setError = function (key, value) {
        this.validations[key] = value;
    };
    ErrorHelper.prototype.getError = function (key) {
        if (this.validations[key] !== undefined) {
            return this.validations[key];
        }
    };
    ErrorHelper.prototype.resetError = function (key) {
        if (this.validations[key] !== undefined) {
            delete this.validations[key];
        }
    };
    ErrorHelper.prototype.resetAllErrors = function () {
        for (var key in this.validations) {
            delete this.validations[key];
        }
    };
    ErrorHelper.prototype.didError = function (key) {
        return this.validations[key] !== undefined;
    };
    Object.defineProperty(ErrorHelper.prototype, "exclamation", {
        get: function () {
            return this._exclamation || 'Uh oh!';
        },
        set: function (str) {
            this._exclamation = str;
        },
        enumerable: true,
        configurable: true
    });
    ErrorHelper.prototype.loadBackEndErrors = function (errors) {
        var _this = this;
        if (errors instanceof HttpErrorResponse) {
            errors = errors.error.errors;
        }
        if (!Array.isArray(errors)) {
            this.setError('genericError', GENERIC_MESSAGE);
            return;
        }
        var invalidFields = this.getInvalidFields(errors);
        if (invalidFields.length > 0) {
            var error = this.getError('invalid') ? this.getError('invalid') : [];
            this.setError('invalid', invalidFields.concat(error));
        }
        var requiredFields = this.getRequiredFields(errors);
        if (requiredFields.length > 0) {
            var error = this.getError('required') ? this.getError('required') : [];
            this.setError('required', requiredFields.concat(error));
        }
        var incompatibleFields = this.getIncompatibleFields(errors);
        if (incompatibleFields.length > 0) {
            var error = this.getError('incompatible') ? this.getError('incompatible') : [];
            this.setError('incompatible', incompatibleFields.concat(error));
        }
        var customErrors = this.getCustomErrors(errors);
        customErrors.forEach(function (error) {
            _this.setError(error.id, backendErrors[error.id]);
        });
        var missingErrors = errors.filter(function (error) { return backendErrors[error.id] === undefined; });
        if (missingErrors.length > 0 && this.size === 0) {
            this.setError('genericError', GENERIC_MESSAGE);
        }
    };
    /**
    * Some LSD error responses do not fit the pattern that is expected by the methods in this helper
    * Therefore, it is sometimes necessary to set a generic error to not break the template
    **/
    ErrorHelper.prototype.setGenericError = function () {
        this.setError('genericError', GENERIC_MESSAGE);
    };
    /**
     * Expected Reporting Error Object Format
     * This is Wolfman's JOI Error Format
     * {
            "statusCode": ,
            "error": "",
            "message": "",
            "validation": {
                "source": "",
                "keys": [
                ""
                ]
            }
        }
     */
    ErrorHelper.prototype.loadReportingError = function (error, status) {
        if (status === -1) {
            /**
             * In case if wolfman service is down or it takes more than 60 seconds to process a query
             * The ELB for merlin times out request after 60 seconds. In that case there is no response from the server
             * But ELB returns a 504 Gateway error and angular in this case returns status -1
             */
            this.setError('Generic Error', reportingErrors['Generic Error']);
        }
        /**
        * TODO: Once Wolfman starts sending standard error response object for 500K rows error
        * This code needs to be changed to:
        * remove this else if block and replace as follows
        * if reportingErrors[error.error] exists then setError(error.error, reportingErrors[error.error])
        */
        else if (error && error.responsePayload !== undefined) {
            if (error.responsePayload.error === 'Resource limit exceeded') {
                this.setError('Resource Limit Exceeded', reportingErrors['Resource Limit Exceeded']);
            }
            else {
                this.setError('Generic Error', reportingErrors['Generic Error']);
            }
        }
        else if (error && error.message) {
            if (error.message.includes('destination')) {
                this.setError('Destination error', reportingErrors['Destination Error']);
            }
            else {
                this.setError('Generic Error', reportingErrors['Generic Error']);
            }
        }
        else {
            this.setError('Generic Error', reportingErrors['Generic Error']);
        }
    };
    ErrorHelper.prototype.getInvalidFields = function (errors) {
        return errors.filter(function (error) { return error.code === 'INV' && error.id === 'E1000'; })
            .map(function (error) { return error.details.split(/(?=[A-Z])/).join(' ').toLowerCase(); });
    };
    ErrorHelper.prototype.getRequiredFields = function (errors) {
        return errors.filter(function (error) { return error.code === 'REQ' && error.id === 'E1001'; })
            .map(function (error) { return error.details.split(/(?=[A-Z])/).join(' ').toLowerCase(); });
    };
    ErrorHelper.prototype.getIncompatibleFields = function (errors) {
        return errors.filter(function (error) { return error.code === 'INC'; })
            .map(function (error) { return error.details.split(/(?=[A-Z])/).join(' ').toLowerCase(); });
    };
    ErrorHelper.prototype.getCustomErrors = function (errors) {
        return errors.filter(function (error) {
            // If LSD sent extra context for the error we take that as the value of the error instead of what's in
            // backendErrors for that error id. When trouble shooting confirm the error id exists in backendErrors
            // and then check LSD to see if for that error id it's also setting the extra context property for the error
            if (backendErrors[error.id] && error.extra) {
                backendErrors[error.id] = error.extra;
                return true;
            }
            return backendErrors[error.id] !== undefined;
        });
    };
    ErrorHelper.isRateLimitError = function (err) {
        return err.status === 403 && Object.keys(err.error).length === 0;
    };
    return ErrorHelper;
}());
export { ErrorHelper };
