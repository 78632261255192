import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dashToSpace'
})
export class DashToSpacePipe implements PipeTransform {
    transform(word: string): string {
        return word.replace('-', ' ');
    }
}
