import * as tslib_1 from "tslib";
import { Model } from './model';
import campaignTypesMeta from './campaign-types-meta.json';
import { Status } from './status';
import { StrategyCard } from 'app/shared/models/strategy-card';
import moment from 'moment';
var GOAL_BRANDING = 'branding';
var GOAL_PERFORMANCE = 'performance';
var REACH_CPA = 'cpa';
var REACH_IMPRESSIONS = 'impressions';
var REACH_CLICKS = 'clicks';
var REACH_REACH = 'reach';
var START_TIME = '00:00:00';
var END_TIME = '23:59:59';
var IMPRESSIONS = 'impressions';
var ADJUSTED_IMPRESSIONS = 'adjusted_impressions';
var CURRENCY = 'currency';
var DAILY = 'daily';
var DEMAND_HOUSE = 'house';
var DEMAND_DIRECT = 'direct';
var DEMAND_EXCHANGE = 'exchange';
var FIRST_PRICE = '1stPrice';
var SECOND_PRICE = '2ndPrice';
var SSP = 'ssp';
var DSP = 'dsp';
var MAX_CTR = 'MaxCTR';
var MAX_CR = 'MaxCR';
var GOAL_CPM = 'CPM';
var GOAL_CPC = 'CPC';
var GOAL_CPA = 'CPA';
var SPEND_TYPE_DAILY = 'daily';
var SPEND_TYPE_LIFETIME = 'lifetime';
var TARGET_ECPM = 'Target eCPM';
var TARGET_ECPC = 'Target eCPC';
var TARGET_ECPA = 'Target eCPA';
export var CAMPAIGN_CONST = {
    GOAL_PERFORMANCE: GOAL_PERFORMANCE,
    GOAL_BRANDING: GOAL_BRANDING,
    REACH_CPA: REACH_CPA,
    REACH_IMPRESSIONS: REACH_IMPRESSIONS,
    REACH_REACH: REACH_REACH,
    REACH_CLICKS: REACH_CLICKS,
    START_TIME: START_TIME,
    END_TIME: END_TIME,
    IMPRESSIONS: IMPRESSIONS,
    ADJUSTED_IMPRESSIONS: ADJUSTED_IMPRESSIONS,
    CURRENCY: CURRENCY,
    DAILY: DAILY,
    HOUSE: DEMAND_HOUSE,
    DIRECT: DEMAND_DIRECT,
    EXCHANGE: DEMAND_EXCHANGE,
    FIRST_PRICE: FIRST_PRICE,
    SECOND_PRICE: SECOND_PRICE,
    DSP: DSP,
    SSP: SSP,
    MAX_CTR: MAX_CTR,
    MAX_CR: MAX_CR,
    GOAL_CPM: GOAL_CPM,
    GOAL_CPC: GOAL_CPC,
    GOAL_CPA: GOAL_CPA,
    BUDGET_SPEND_DAILY: SPEND_TYPE_DAILY,
    BUDGET_SPEND_LIFETIME: SPEND_TYPE_LIFETIME,
    TARGET_ECPM: TARGET_ECPM
};
export var CampaignType;
(function (CampaignType) {
    CampaignType[CampaignType["BrandingClicks"] = 0] = "BrandingClicks";
    CampaignType[CampaignType["BrandingConversions"] = 1] = "BrandingConversions";
    CampaignType[CampaignType["BrandingImpressions"] = 2] = "BrandingImpressions";
    CampaignType[CampaignType["PerformanceClicks"] = 3] = "PerformanceClicks";
    CampaignType[CampaignType["PerformanceConversions"] = 4] = "PerformanceConversions";
    CampaignType[CampaignType["PerformanceCPA"] = 5] = "PerformanceCPA";
    CampaignType[CampaignType["PerformanceCPC"] = 6] = "PerformanceCPC";
    CampaignType[CampaignType["PerformanceReach"] = 7] = "PerformanceReach";
    CampaignType[CampaignType["Guaranteed"] = 8] = "Guaranteed";
    CampaignType[CampaignType["PerformanceCTR"] = 9] = "PerformanceCTR";
})(CampaignType || (CampaignType = {}));
var Campaign = /** @class */ (function (_super) {
    tslib_1.__extends(Campaign, _super);
    function Campaign(from) {
        var _this = _super.call(this, from) || this;
        if (_this.comment) {
            // Parse the comment as JSON.
            try {
                _this.comment = JSON.parse(_this.comment);
            }
            catch (err) {
                _this.comment = null;
                // TODO: Add error logging when such a framework is available.
            }
        }
        _this.startTime = _this.getTime(_this.startDate || _this.insertionOrderStartDate);
        _this.endTime = _this.getTime(_this.endDate || _this.insertionOrderEndDate);
        return _this;
    }
    Campaign.prototype.serialize = function () {
        var campaign = this.clone(Campaign);
        campaign.startDate = this.createDatetime(this.startDate, this.startTime);
        campaign.endDate = this.createDatetime(this.endDate, this.endTime);
        campaign.endDate = moment(campaign.endDate).endOf('minute').format('YYYY-MM-DD HH:mm:ss');
        delete campaign.startTime;
        delete campaign.endTime;
        if (campaign.inheritDates) {
            campaign.startDate = null;
            campaign.endDate = null;
        }
        delete campaign.inheritDates;
        if (typeof campaign.externalId === 'string' && campaign.externalId.trim().length === 0) {
            campaign.externalId = null;
        }
        delete campaign.advertiserObj;
        delete campaign.canAccessAudienceExtension;
        return JSON.stringify(campaign);
    };
    Object.defineProperty(Campaign.prototype, "entity", {
        get: function () {
            return 'Campaign';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Campaign.prototype, "campaignType", {
        get: function () {
            var _this = this;
            if (this.pricingModel) {
                var guaranteed_1 = this.guaranteed ? true : false;
                var campaignTypes = this.campaignTypes().filter(function (type) {
                    return type.pricingModel === _this.pricingModel
                        && type.goal === _this.goal
                        && type.guaranteed === guaranteed_1
                        && (type.clearingMethod === _this.clearingMethod
                            || type.secondPrice === _this.isSecondPrice)
                        && (!_this.buyingStrategyId || (_this.buyingStrategyId && type.buyingStrategyId === _this.buyingStrategyId));
                });
                if (campaignTypes.length > 0) {
                    return campaignTypes[0].type;
                }
                throw new Error('Could not find campaign type.');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Campaign.prototype, "meta", {
        get: function () {
            switch (this.campaignType) {
                case CampaignType.BrandingClicks:
                    if (this.buyingStrategyId === StrategyCard.FIRST_PRICE_CPM_MAX_CLICKTHRU) {
                        return campaignTypesMeta.performanceCPMMaxCTR;
                    }
                    return campaignTypesMeta.brandingClicks;
                case CampaignType.BrandingConversions:
                    return campaignTypesMeta.brandingConversions;
                case CampaignType.BrandingImpressions:
                    return campaignTypesMeta.brandingImpressions;
                case CampaignType.PerformanceClicks:
                    return campaignTypesMeta.performanceClicks;
                case CampaignType.PerformanceConversions:
                    if (this.buyingStrategyId === StrategyCard.PERFORMANCE_MAXIMIZE_CONVERSIONS_DAILY_CAP) {
                        return campaignTypesMeta.performanceConversionsWithDailyCap;
                    }
                    return campaignTypesMeta.performanceConversions;
                case CampaignType.PerformanceCPA:
                    return campaignTypesMeta.performanceCPA;
                case CampaignType.PerformanceCPC:
                    return campaignTypesMeta.performanceCPC;
                case CampaignType.PerformanceReach:
                    return campaignTypesMeta.performanceReach;
                case CampaignType.Guaranteed:
                    return campaignTypesMeta.guaranteed;
                case CampaignType.PerformanceCTR:
                    return campaignTypesMeta.performanceCPMMaxCTR;
                default:
                    throw new Error('Could not find campaign meta of type ' + this.campaignType);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Campaign.prototype, "allowsImpressionBudget", {
        get: function () {
            return this.system === 'ssp' &&
                (this.campaignType === CampaignType.BrandingImpressions || this.campaignType === CampaignType.Guaranteed);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Campaign.prototype, "allowsAdjustedImpressionBudget", {
        get: function () {
            return this.system === 'ssp' && ((this.campaignType === CampaignType.Guaranteed) ||
                (this.campaignType === CampaignType.BrandingImpressions));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Campaign.prototype, "isActive", {
        get: function () {
            return this.status === 'active';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Campaign.prototype, "isSecondPrice", {
        get: function () {
            return this.secondPrice || this.clearingMethod === SECOND_PRICE;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Campaign.prototype, "isBranding", {
        get: function () {
            return Campaign.BrandingTypes.indexOf(this.campaignType) > -1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Campaign.prototype, "isEvenPacing", {
        get: function () {
            return Campaign.EvenPacingTypes.indexOf(this.campaignType) > -1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Campaign.prototype, "isExchange", {
        get: function () {
            return this.demandType === 'exchange';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Campaign.prototype, "isDirectSold", {
        get: function () {
            return this.demandType === DEMAND_DIRECT;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Campaign.prototype, "isUnlimitedBudget", {
        get: function () {
            return this.unlimitedBudget || this.isExchange;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Campaign.prototype, "isHouse", {
        get: function () {
            return this.demandType === DEMAND_HOUSE;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Campaign.prototype, "isSsp", {
        get: function () {
            return this.system === SSP;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Campaign.prototype, "isDsp", {
        get: function () {
            return this.system === DSP;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Campaign.prototype, "goalThreshold", {
        get: function () {
            return this.campaignType === CampaignType.PerformanceCPC ||
                this.campaignType === CampaignType.PerformanceCPA ? 90 : 95;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Campaign.prototype, "title", {
        get: function () {
            return this.meta.title;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Campaign.prototype, "goalDescription", {
        get: function () {
            return this.meta.goalDescription;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Campaign.prototype, "bidAmountLabel", {
        get: function () {
            if (this.pricingModel === GOAL_CPM && !this.isSecondPrice) {
                return GOAL_CPM;
            }
            else if (this.pricingModel === GOAL_CPC && !this.isSecondPrice) {
                return GOAL_CPC;
            }
            else if (this.pricingModel === GOAL_CPA && !this.isSecondPrice) {
                return GOAL_CPA;
            }
            else if (this.pricingModel === GOAL_CPM && this.isSecondPrice) {
                if (this.goal === GOAL_CPM) {
                    return TARGET_ECPM;
                }
                else if (this.goal === GOAL_CPC) {
                    return TARGET_ECPC;
                }
                else if (this.goal === GOAL_CPA) {
                    return TARGET_ECPA;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Campaign.prototype, "formattedPlatform", {
        get: function () {
            if (this.system) {
                return this.system.toUpperCase();
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Campaign.prototype.getFormattedDemandType = function () {
        switch (this.demandType) {
            case 'exchange': return 'Exchange';
            case 'direct': return 'Direct Sold';
            case 'house': return 'House Campaign';
        }
    };
    Campaign.prototype.hasConversionTracker = function () {
        return typeof this.conversionPixel === 'string';
    };
    Campaign.prototype.needsActivation = function () {
        return this.optimizesTowardsConversions && this.status === 'pending';
    };
    Campaign.prototype.getBidLabel = function () {
        return this.meta.bidLabel;
    };
    Campaign.prototype.optimizesTowardsConversions = function () {
        return [
            CampaignType.PerformanceConversions,
            CampaignType.BrandingConversions,
            CampaignType.PerformanceCPA
        ].includes(this.campaignType);
    };
    Object.defineProperty(Campaign.prototype, "supportsDailyCap", {
        get: function () {
            return (this.system === 'ssp' ||
                (this.system === 'dsp'
                    && (this.campaignType === CampaignType.PerformanceConversions || this.campaignType === CampaignType.PerformanceCTR)
                    && this.buyingStrategyId !== StrategyCard.PERFORMANCE_MAXIMIZE_CONVERSIONS));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Campaign.prototype, "platformStatus", {
        get: function () {
            return this.needsActivation ? Status.NEEDS_ACTIVATION : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Campaign.prototype, "isGuaranteed", {
        get: function () {
            return this.campaignType === CampaignType.Guaranteed;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Campaign.prototype, "isImpressionBased", {
        get: function () {
            return this.campaignType === CampaignType.BrandingImpressions;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Campaign.prototype, "isPerformanceCTR", {
        get: function () {
            return this.campaignType === CampaignType.PerformanceCTR;
        },
        enumerable: true,
        configurable: true
    });
    Campaign.prototype.campaignTypes = function () {
        return [{
                pricingModel: GOAL_CPM,
                clearingMethod: SECOND_PRICE,
                secondPrice: true,
                goal: GOAL_CPM,
                type: CampaignType.PerformanceReach,
                guaranteed: false
            }, {
                pricingModel: GOAL_CPM,
                clearingMethod: SECOND_PRICE,
                secondPrice: true,
                goal: null,
                type: CampaignType.PerformanceReach,
                guaranteed: false
            }, {
                pricingModel: GOAL_CPM,
                clearingMethod: SECOND_PRICE,
                secondPrice: true,
                goal: GOAL_CPC,
                type: CampaignType.PerformanceClicks,
                guaranteed: false
            }, {
                pricingModel: GOAL_CPM,
                clearingMethod: SECOND_PRICE,
                secondPrice: true,
                goal: GOAL_CPA,
                type: CampaignType.PerformanceConversions,
                guaranteed: false,
                buyingStrategyId: StrategyCard.PERFORMANCE_MAXIMIZE_CONVERSIONS
            }, {
                pricingModel: GOAL_CPM,
                clearingMethod: SECOND_PRICE,
                secondPrice: true,
                goal: GOAL_CPA,
                type: CampaignType.PerformanceConversions,
                guaranteed: false,
                buyingStrategyId: StrategyCard.PERFORMANCE_MAXIMIZE_CONVERSIONS_DAILY_CAP
            },
            {
                pricingModel: GOAL_CPM,
                clearingMethod: FIRST_PRICE,
                secondPrice: false,
                goal: MAX_CTR,
                type: CampaignType.BrandingClicks,
                guaranteed: false
            }, {
                pricingModel: GOAL_CPM,
                clearingMethod: FIRST_PRICE,
                secondPrice: false,
                goal: MAX_CTR,
                type: CampaignType.PerformanceCTR,
                guaranteed: false,
                buyingStrategyId: StrategyCard.FIRST_PRICE_CPM_MAX_CLICKTHRU
            }, {
                pricingModel: GOAL_CPM,
                clearingMethod: FIRST_PRICE,
                secondPrice: false,
                goal: MAX_CR,
                type: CampaignType.BrandingConversions,
                guaranteed: false
            }, {
                pricingModel: GOAL_CPM,
                clearingMethod: FIRST_PRICE,
                secondPrice: false,
                goal: null,
                type: CampaignType.BrandingImpressions,
                guaranteed: false
            }, {
                pricingModel: GOAL_CPC,
                clearingMethod: FIRST_PRICE,
                secondPrice: false,
                goal: null,
                type: CampaignType.PerformanceCPC,
                guaranteed: false
            }, {
                pricingModel: GOAL_CPA,
                clearingMethod: SECOND_PRICE,
                secondPrice: true,
                goal: GOAL_CPA,
                type: CampaignType.PerformanceCPA,
                guaranteed: false
            }, {
                pricingModel: GOAL_CPA,
                clearingMethod: SECOND_PRICE,
                secondPrice: true,
                goal: null,
                type: CampaignType.PerformanceCPA,
                guaranteed: false
            }, {
                pricingModel: GOAL_CPA,
                clearingMethod: FIRST_PRICE,
                secondPrice: false,
                goal: null,
                type: CampaignType.PerformanceCPA,
                guaranteed: false
            }, {
                pricingModel: GOAL_CPM,
                clearingMethod: FIRST_PRICE,
                secondPrice: false,
                goal: null,
                type: CampaignType.Guaranteed,
                guaranteed: true
            }
        ];
    };
    Object.defineProperty(Campaign.prototype, "allowsDailyBudget", {
        get: function () {
            return this.system === 'dsp'
                && (this.campaignType === CampaignType.PerformanceConversions || this.campaignType === CampaignType.PerformanceClicks);
        },
        enumerable: true,
        configurable: true
    });
    Campaign.EvenPacingTypes = [
        CampaignType.BrandingConversions,
        CampaignType.BrandingClicks,
        CampaignType.PerformanceConversions,
        CampaignType.PerformanceClicks,
        CampaignType.PerformanceReach,
        CampaignType.PerformanceCTR,
    ];
    Campaign.BrandingTypes = [
        CampaignType.BrandingImpressions,
        CampaignType.BrandingClicks,
        CampaignType.BrandingConversions,
        CampaignType.Guaranteed
    ];
    return Campaign;
}(Model));
export { Campaign };
