import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Agency } from 'app/shared/models';
import { AgencyRepository } from 'app/core/repositories';

@Injectable()
export class AgencyResolver implements Resolve<Agency> {
    constructor(private agencyRepository: AgencyRepository) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.agencyRepository.get(route.params.id);
    }
}
