import * as tslib_1 from "tslib";
import { Model } from './model';
var Agency = /** @class */ (function (_super) {
    tslib_1.__extends(Agency, _super);
    function Agency() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Agency.prototype.serialize = function () {
        this.isApiPartner = false;
        return JSON.stringify(this);
    };
    Object.defineProperty(Agency.prototype, "entity", {
        get: function () {
            return 'Agency';
        },
        enumerable: true,
        configurable: true
    });
    return Agency;
}(Model));
export { Agency };
