import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';

import { AdBuilderComponent, AdBuilderNativeComponent } from 'app/shared/components/ad-builder';
import { Campaign, Creative, DemandAsset, LineItem, SupplyAssetProperties } from 'app/shared/models';
import { AuthorizationService } from 'app/core';
import { ValidationService } from 'app/core/validation-service';
import { CreativeSizeValidation } from 'app/shared/models/creative';
import validationErrors from 'app/core/errors/validation-errors.json';
import { AdDetailsMacroComponent } from './ad-details-macro';
import { IdService } from 'app/core/id.service';
import { BehaviorSubject } from 'rxjs';
import { AdBuilderNativeExchangeComponent } from 'app/shared/components/ad-builder/ad-builder-native-exchange';
import { DemandAssetService } from 'app/core/demand-asset.service';
import { NativeDemandImageAssetRepository, NativeDemandTextAssetRepository } from 'app/core/repositories';
import { LineItemService } from 'app/platform/campaign-manager/line-items/line-item.service';
import { lodashDeepClone } from 'app/core/utils';

@Component({
    selector: 'ad-details',
    templateUrl: './ad-details.html',
    styleUrls: ['./ad-details.styl'],
    providers: [ DemandAssetService, NativeDemandImageAssetRepository, NativeDemandTextAssetRepository ]
})
export class AdDetailsComponent implements OnChanges {
    @Input() set ads(ads: Array<Creative>) {
        this._ads = ads;
        if (this._ads && this._ads.length > 0) {
            this.selectAd(0);
        }
    }

    get ads(): Array<Creative> {
        if (!this._ads) {
            return [];
        }
        return this._ads;
    }

    @Input() set placementId(value) {
        if (value) {
            this._placementId$.next(value);
        } else {
            this._placementId$.next(null);
        }
    }

    @Input() creatives: Array<Creative> = [];
    @Input() campaign: Campaign;
    @Input() lineItem: LineItem;

    @Output() adsChange = new EventEmitter<Array<Creative>>();
    @Output() creativesHaveErrors = new EventEmitter<boolean>();

    @ViewChild(AdBuilderComponent, { static: false }) adBuilder: AdBuilderComponent;
    @ViewChild(AdBuilderNativeComponent, { static: false }) adBuilderNativeComponent: AdBuilderNativeComponent;
    @ViewChild(AdBuilderNativeExchangeComponent, { static: false }) adBuilderNativeExchangeComponent: AdBuilderNativeExchangeComponent;
    @ViewChild('macroInfoBox', { static: true }) macroInfoBox: AdDetailsMacroComponent;

    _ads: Array<Creative> = [];
    creativeErrorMap: Map<string, boolean> = new Map<string, boolean>();
    creativeSizeValidMap: Map<string, boolean> = new Map<string, boolean>();

    _placementId$ = new BehaviorSubject<any>('');
    public ad: Creative = null;
    private _showThirdPartyTracker: boolean = false;
    private _showStaqId: boolean = false;
    private _showExternalId: boolean = false;
    public selectedIndex: number = 0;
    validationService: ValidationService = null;
    validationErrors = validationErrors;

    constructor(
        private auth: AuthorizationService,
        public id: IdService,
        public lineItemService: LineItemService
    ) {
        this.validationService = new ValidationService();
    }

    selectAd(index: number) {
        this.selectedIndex = index;
        this.ad = this.ads[index];

        if (this.ad.isNative) {
            if (this.adBuilderNativeComponent) {
                this.adBuilderNativeComponent.refresh();
            } else if (this.adBuilderNativeExchangeComponent) {
                this.adBuilderNativeExchangeComponent.refresh(this.ad);
            }
        }

        this._showThirdPartyTracker = typeof this.ad.urlTracking2 === 'string';
        this.showStaqId = !!this.ad.staqId;
        this.showExternalId = !!this.ad.externalId;
    }

    removeAd(index: number) {
        // When you remove the ad, remove any errors associated with it
        let adHash = this.ads[index].id ? this.ads[index].id : this.ads[index].created;
        delete this.creativeErrorMap[adHash];
        delete this.creativeSizeValidMap[adHash];

        this.ads.splice(index, 1);

        if (this.selectedIndex > index || (this.selectedIndex === index && index !== 0)) {
            this.selectAd(this.selectedIndex - 1);
        }
        else if (index === 0 && this.ads.length > 0) {
            this.selectAd(index);
        }
        else if (this.ads.length === 0) {
            this.ad = null;
        }
    }

    openAdBuilder() {
        this.adBuilder.open();
    }

    backgroundImage(url) {
        return 'url(' + url + ')';
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['ads'] && changes['ads'].currentValue && changes['ads'].currentValue.length > 0) {
            this.selectAd(this.ads.length - 1);
        }
    }

    creativeHasErrors(ad): boolean {
        return !ad.name || !this.hasValidMediaAndClickUrls(ad) || !this.hasValidTrackingUrls(ad) || this.nativeCreativeHasErrors(ad);
    }

    hasValidMediaAndClickUrls(ad): boolean {
        return ad.mediaUrl
            && ad.clickUrl
            && ValidationService.isValidUrl(ad.clickUrl)
            && this.validationService.hasAcceptableMacros(ad.clickUrl)
            && (this.validationService.isAcceptableUrl(ad.mediaUrl) || ad.mediaUrl.match('data:image'));
    }

    hasValidTrackingUrls(ad): boolean {
        if (ad.urlTracking1 && !this.validationService.isAcceptableUrl(ad.urlTracking1)) {
            return false;
        }

        if (ad.urlTracking2 && !this.validationService.isAcceptableUrl(ad.urlTracking2)) {
            return false
        }

        return true;
    }

    nativeCreativeHasErrors(ad): boolean {
        if (ad.assetRestrictions) {
            return this.creativeHasAssetRestrictionsErrors(ad);
        } else if (this.lineItem.nativeLike && this.adBuilderNativeComponent) {
            return !this.adBuilderNativeComponent.form.valid;
        } else if (ad.isNative && this.adBuilderNativeExchangeComponent && !this.adBuilderNativeExchangeComponent.isNativeDirectSoldCreative) {
            return !this.adBuilderNativeExchangeComponent.nativeForm.valid;
        }

        return false;
    }

    hasCreativeErrors(ad){
        if (ad.id) {
            return this.creativeErrorMap[ad.id];
        } else if (ad.name) {
            return this.creativeErrorMap[ad.name];
        }
        return true;
    }

    isAdNative(ad) {
        return ad.isNative;
    }

    // Store whether each creative has an error associated with it
    // Check each creative to determine if any have errors
    checkCreativeErrors(ad){
        const key = ad.id ? ad.id : ad.created;

        if (this.creativeHasErrors(ad) || (ad.isNativeCreative && this.creativeSizeValidMap[key] === false)) {
            this.creativeErrorMap[key] = true;
        } else {
            this.creativeErrorMap[key] = false;
        }

        for (let key in this.creativeErrorMap) {
            if (this.creativeErrorMap[key]) {
                return this.creativesHaveErrors.emit(true);
            }
        }

        return this.creativesHaveErrors.emit(false);
    }

    setIsCreativeSizeValid({ id, isValid }: CreativeSizeValidation): void {
        this.creativeSizeValidMap[id] = isValid;
        this.checkCreativeErrors(this.ad);
    }

    creativeHasAssetRestrictionsErrors(ad): boolean {
        const assets = {
            headline: {},
            callToAction: {},
            description: {},
            sponsored: {},
            logoImage: {},
            mainImage: {},
        };

        if (ad.native && ad.native.assets) {
            // Asset restriction check
            ad.assets = assets;
            ad.native.assets.forEach(asset => {
                if (asset.title) {
                    ad.assets['headline'] = asset;
                } else if (asset.data) {
                    switch (asset.data.type) {
                        case 12:
                            ad.assets['callToAction'] = asset;
                            break;
                        case 2:
                            ad.assets['description'] = asset;
                            break;
                        case 1:
                            ad.assets['sponsored'] = asset;
                            break;
                    }
                } else if (asset.img) {
                    switch (asset.img.type) {
                        case 1:
                            ad.assets['logoImage'] = asset;
                            break;
                        case 3:
                            ad.assets['mainImage'] = asset;
                            break;
                    }
                }
            })

            for (let prop of SupplyAssetProperties) {
                if (ad.assetRestrictions[prop.modelKey]) {
                    if (this.hasRequiredRestrictionError(ad.assetRestrictions[prop.modelKey], ad.assets[prop.modelKey]) || this.hasSizeRestrictionError(ad.assetRestrictions[prop.modelKey], ad.assets[prop.modelKey])) {
                        return true;
                    };
                }
            }

            // If all assets are good
            return false;
        }
    }

    hasRequiredRestrictionError(assetRestrictions, assetSettings){
        return assetRestrictions.required == true && !("id" in assetSettings);
    }

    hasSizeRestrictionError(assetRestrictions, assetSettings){
        if ("id" in assetSettings) {
            if ("img" in assetSettings) {
                if (assetRestrictions.img.wmin && assetRestrictions.img.hmin) {
                    if (assetSettings.img.width < assetRestrictions.img.wmin || assetSettings.img.height < assetRestrictions.img.hmin) {
                        return true;
                    }
                }
                if (assetRestrictions.img.w && assetRestrictions.img.h) {
                    if (assetSettings.img.width !== assetRestrictions['img'].w || assetSettings.img.height !== assetRestrictions['img'].h) {
                        return true;
                    }
                }
            } else if ("data" in assetSettings) {
                if (assetSettings.data.len > assetRestrictions.data.len) {
                    return true;
                }
            } else if ("title" in assetSettings) {
                if (assetSettings.title.len > assetRestrictions.title.len) {
                    return true;
                }
            }
        }
        return false;
    }

    adsSelected($event) {
        this.ads = $event;
        this.adsChange.emit(this.ads);
    }

    cloneCreative() {
        const clonedCreative = lodashDeepClone(this.ads[this.selectedIndex]);

        // Delete and modify properties in cloned creative to ensure LSD creates new unique creative
        clonedCreative.name += ' - COPY';
        delete clonedCreative.id;
        delete clonedCreative.refId;

        // Same demand assets cannot be linked again, unlink id and version
        if (clonedCreative.isNative && clonedCreative.native.assets) {
            clonedCreative.native.assets.forEach((asset: DemandAsset) => {
                asset.id = null;
                asset.version = null;
            });
        }

        clonedCreative.created = Date.now();

        this.ads.push(clonedCreative);
        this.selectedIndex = this.ads.length - 1;

        this.selectAd(this.selectedIndex);
    }

    copyInput(key, value) {
        for (let ad of this.ads) {
            ad.isModified = true;
            ad[key] = value;
        }
    }

    get showStaqId() {
        return this._showStaqId && this.auth.isInternalUser;
    }

    set showStaqId(value) {
        if (value === false) {
            this.ad.staqId = null;
            this.ad.isModified = true;
        }
        this._showStaqId = value;
    }

    get showExternalId() {
        return this._showExternalId;
    }

    set showExternalId(value) {
        if (value === false) {
            this.ad.externalId = null;
            this.ad.isModified = true;
        }
        this._showExternalId = value;
    }

    get showThirdPartyTracker(): boolean {
        return this._showThirdPartyTracker;
    }

    set showThirdPartyTracker(val: boolean) {
        this._showThirdPartyTracker = val;

        if (val === false) {
            this.ad.urlTracking2 = null;
            this.ad.isModified = true;
        }
    }

    setIsModified() {
        this.ad.isModified = true;
    }

    openValidMacro() {
        this.macroInfoBox.open();
    }

    handleOnShowSecondaryTracker(isExpanded: boolean) {
        if (isExpanded === false) {
            this.ad.urlTracking2 = null;
        }
    }
}
