import { Operation, OperationLabel } from './operation';
import { Option } from 'app/shared/elements/dropdown';

export class Filter {
    groupId: number | string;
    field: string;
    label: string;
    query: string | string[] | number;
    operation: Operation;
    isAdvancedOnly: boolean;
    filters: Filter[];
    values?: string[];
    readonly operations = [];
    private _operationOptions: Option[];
    private _simpleLabel: string;

    constructor(data = {}) {
        Object.assign(this, data);
    }

    get operationOptions() {
        if (!this._operationOptions) {
            this._operationOptions = this.operations.map((operation, index) => ({
                key: index,
                value: operation,
                label: OperationLabel[operation]
            }));
        }

        return this._operationOptions;
    }

    get simpleLabel() {
        if (!this._simpleLabel) {
            return (
                Array.isArray(this.filters)
                    ? this.filters.filter(f => f.isValid())[0].query
                    : this.query
            ).toString();
        }

        return this._simpleLabel;
    }

    set simpleLabel(label: string) {
        this._simpleLabel = label;
    }

    isValid() {
        // or and and must contain child filters
        if ([Operation.And, Operation.Or].includes(this.operation) && (!Array.isArray(this.filters) || this.filters.length < 1)) {
            return false;
        }

        // we'll defer this to the children, because we will exclude the
        // invalid filters, rather than throw an error
        if (Array.isArray(this.filters)) {
            return true;
        }

        return this.field
            && (this.query || this.query === 0)
            && (typeof this.query === 'string' ? this.query.trim().length > 0 : true)
            && this.operations.includes(this.operation);
    }
}
