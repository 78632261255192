import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate } from '@angular/router';

@Injectable()
export class ResetPasswordGuard implements CanActivate {
    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot) {
        const hasRequiredParameters = route.queryParams['email'].length > 0 && route.queryParams['code'].length > 0;

        if (!hasRequiredParameters) {
            this.router.navigate(['/login']);
        }

        return hasRequiredParameters;
    }
}
