import { Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

import { BaseTableHelper } from './base-table-helper';
import { Condition } from 'app/core/repositories';
import { LSDQueryBuilder } from './lsd-query-builder';
import { Query } from 'app/shared/elements/types';
import { QueryBuilder, SearchParams } from './query-builder';

export class FrontendTableHelper<T> extends BaseTableHelper<T> {
    /**
     * Create a new table helper.
     */
    constructor(
        private adapter: () => Observable<T[]>,
        private queryBuilder: QueryBuilder = new LSDQueryBuilder()
    ) {
        super();

        this.query.pipe(switchMap(rawParams => this.adapt(rawParams)))
            .subscribe(this.data);
    }

    /**
     * Adapt a query into table data.
     */
    adapt(rawParams: Query) {
        const params = this.queryBuilder.build(rawParams);

        return this.adapter().pipe(map(all => ({
            items: this.itemsAtCurrentPage(all, params),
            page: params.page,
            pages: this.pages(all, params),
            total: this.filter(all, params).length
        })));
    }

    /**
     * Fetch the sorted, filtered, and paginated items to display at the current page.
     */
    private itemsAtCurrentPage(items: T[], params: SearchParams) {
        return this.paginate(this.sort(this.filter(items, params), params), params);
    }

    /**
     * Paginate the results according to the supplied params.
     */
    private paginate(items: T[], params: SearchParams) {
        if (!params.n) {
            return items;
        }

        const offset = (params.page - 1) * params.n;
        return items.slice(offset, Math.min(offset + params.n, items.length));
    }

    /**
     * The total number of available pages.
     */
    private pages(items: T[], params: SearchParams) {
        return Math.ceil(items.length / params.n);
    }

    /**
     * Sorts an array of items in place by a given field name `params.orderBy` and direction `params.sort`.
     */
    private sort(data: any[], params: SearchParams) {
        const orderBy = params.orderBy;
        const direction = params.sort;

        const compare = (a: any, b: any) => {
            if (a === undefined || a === null) {
                return 1;
            }

            if (b === undefined || b === null) {
                return -1;
            }

            if (typeof a === 'string') {
                a = a.toLowerCase();
                b = b.toLowerCase();
            }

            if (a > b) {
                return -1;
            }

            if (b > a) {
                return 1;
            }

            if (a === b) {
                return 0;
            }
        };

        data.sort((a, b) => compare(a[orderBy], b[orderBy]));

        if (direction.toUpperCase() === 'ASC') {
            data.reverse();
        }

        return data;
    }

    /**
     * Filters an array of items.
     * This is meant to be used for async-tables that filter client side.
     */
    private filter(items: T[], params: SearchParams) {
        if (!Array.isArray(params.conditions) || params.conditions.length < 1) {
            return items;
        }

        return items.filter(item => this.testAgainstConditions(item, params.conditions));
    }

    /**
     * Test whether an item meets a set of conditions.
     */
    private testAgainstConditions(item: T, conditions: Condition[]) {
        if (!Array.isArray(conditions) || conditions.length < 1) {
            return true;
        }

        // for simplicity, let's assume the conditions in a group all share the same
        // operator until we have to support anything further
        if (conditions[0].mode === 'or') {
            return conditions.some(condition => this.testAgainstCondition(item, condition));
        } else {
            return conditions.every(condition => this.testAgainstCondition(item, condition));
        }
    }

    /**
     * Test whether an item meet a of condition.
     */
    private testAgainstCondition(item: T, condition: Condition) {
        if (Array.isArray(condition.conditions)) {
            return this.testAgainstConditions(item, condition.conditions);
        }

        if (condition.operator === 'like') {
            return (new RegExp(condition.value, 'i')).test(item[condition.field]);
        }

        return condition.value === item[condition.field];
    }
}
