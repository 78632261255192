export const getLinkFromEntity = (entity: any) => {
    const type = entity.type ? entity.type : entity.index;
    switch (type) {
        case 'Agency':
        case 'agencies':
            return '/campaign-manager/agencies/' + entity.refId;
        case 'Advertiser':
        case 'advertisers':
            return '/campaign-manager/advertisers/' + entity.refId;
        case 'Insertion Order':
        case 'insertion_orders':
            return '/campaign-manager/insertion-orders/' + entity.refId;
        case 'Campaign':
        case 'campaigns':
            return '/campaign-manager/campaigns/' + entity.refId;
        case 'Line Item':
        case 'line_items':
            return '/campaign-manager/line-items/' + entity.refId;
        case 'Creative':
        case 'creatives':
            return [
                '/campaign-manager/ads/',
                entity.refId,
                { advertiser: entity.advertiserRefId },
            ];
        case 'Media Group':
        case 'media_groups':
            return '/inventory-manager/media-groups/' + entity.refId;
        case 'Publisher':
        case 'publishers':
            return '/inventory-manager/publishers/' + entity.refId;
        case 'Newsletter':
        case 'newsletters':
            return '/inventory-manager/newsletters/' + entity.refId;
        case 'Ad Slot':
        case 'ad_slots':
            return '/inventory-manager/ad-slots/' + entity.refId;
    }
};

export const formatIndex = (index: string): string => {
    switch (index) {
        case 'agencies':
            return 'Agency';
        case 'advertisers':
            return 'Advertisers';
        case 'insertion_orders':
            return 'Insertion Order';
        case 'campaigns':
            return 'Campaign';
        case 'line_items':
            return 'Line Item';
        case 'creatives':
            return 'Creative';
        case 'media_groups':
            return 'Media Group';
        case 'publishers':
            return 'Publisher';
        case 'newsletters':
            return 'Newsletter';
        case 'ad_slots':
            return 'Ad Slot';
        default:
            return index;
    }
};
