import * as tslib_1 from "tslib";
import { ApplicationRef, ErrorHandler, Injector } from '@angular/core';
import { Router } from '@angular/router';
var AppErrorHandler = /** @class */ (function (_super) {
    tslib_1.__extends(AppErrorHandler, _super);
    function AppErrorHandler(injector) {
        var _this = _super.call(this) || this;
        _this.injector = injector;
        return _this;
    }
    /**
     * Intercept all uncaught application errors, and
     * handle them appropriately.
     */
    AppErrorHandler.prototype.handleError = function (error) {
        if (document.location.host.includes('liveintent.com')) {
            this.recover(error);
        }
        _super.prototype.handleError.call(this, error);
    };
    /**
     * Recover from the error.
     *
     * If the application is still stable, the next `tick`
     * will succeed and nothing further will be required.
     * Otherwise, the application is in an unrecoverable
     * state and must be completely rebooted.
     *
     * @param {error} any
     */
    AppErrorHandler.prototype.recover = function (error) {
        var app = this.injector.get(ApplicationRef);
        var url = this.injector.get(Router).url;
        try {
            app.tick();
        }
        catch (e) {
            this.log(error, url).then(function () { return window.location.href = '/fatal-error'; });
        }
    };
    /**
     * Log the error to Google Analytics.
     *
     * @param {error} any
     */
    AppErrorHandler.prototype.log = function (error, url) {
        var ga = window['ga'];
        return new Promise(function (resolve) {
            ga('send', {
                hitType: 'event',
                eventCategory: 'errors',
                eventAction: 'Page: ' + url,
                eventLabel: 'crash',
                hitCallback: resolve
            });
        });
    };
    return AppErrorHandler;
}(ErrorHandler));
export { AppErrorHandler };
