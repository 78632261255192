import { Model } from '../model';
import { DemandImageAssetType } from 'app/shared/models/native-demand/demand-image-asset';
import { DemandTextAssetType } from 'app/shared/models/native-demand/demand-text-asset';

export interface DemandAssetModel {
    requirement: boolean;
    model: string;
    typeId: number;
    typeDescription: string;
    id?: number;
    version?: number;
    width?: number;
    height?: number;
}

export enum DemandAssetTypeId {
    Title = 20,
    SponsoredBy = 21,
    Description = 22,
    CTA = 32,
    ImageIcon = 33,
    ImageMain = 34
}

export type DemandAssetType = {
    assets: any[],
    link: string
}

export type DemandAssetLinkType = {
    id: number,
    required: boolean
}

export enum IABAssets {
    'title' = 'title',
    'data' = 'data',
    'img' = 'img'
}

export class DemandAsset extends Model {
    headline: DemandAssetModel;
    sponsored: DemandAssetModel;
    description: DemandAssetModel;
    callToAction: DemandAssetModel;
    mainImage: DemandAssetModel;
    logoImage: DemandAssetModel;

    public serialize(): string {
        delete this._isClone;

        return JSON.stringify(this);
    }

    get entity() {
        return 'DemandAsset';
    }
}

export const DemandAssetProperties = [{
        'label': 'Headline',
        'assetLabel': 'Headline',
        'modelKey': 'headline',
        'type': null,
        'assetKey': 'title',
        'multiLine': false
    }, {
        'label': 'Image Url',
        'assetLabel': 'Image',
        'modelKey': 'mainImage',
        'type': DemandImageAssetType.Main,
        'assetKey': 'img',
        'multiLine': false
    }, {
        'label': 'Body',
        'assetLabel': 'Body',
        'modelKey': 'description',
        'type': DemandTextAssetType.Description,
        'assetKey': 'data',
        'multiLine': true
    }, {
        'label': 'Call to Action',
        'assetLabel': 'CTA',
        'modelKey': 'callToAction',
        'type': DemandTextAssetType.CallToAction,
        'assetKey': 'data',
        'multiLine': false
    }, {
        'label': 'Sponsored By',
        'assetLabel': 'Sponsored',
        'modelKey': 'sponsored',
        'type': DemandTextAssetType.SponsoredBy,
        'assetKey': 'data',
        'multiLine': false
    }, {
        'label': 'Logo Url',
        'assetLabel': 'Logo',
        'modelKey': 'logoImage',
        'type': DemandImageAssetType.Icon,
        'assetKey': 'img',
        'multiLine': false
}];
