import { NgModel } from '@angular/forms';
import { IdService } from 'app/core/id.service';
import { getDotNotationField, setDotNotationField } from "app/core/utils";
var BulkEditCheckboxComponent = /** @class */ (function () {
    function BulkEditCheckboxComponent(setToLabelOverride, label, description, id) {
        if (setToLabelOverride === void 0) { setToLabelOverride = null; }
        this.id = id;
        this._entities = [];
        this._originals = [];
        this.entityName = '';
        this.description = '';
        this.label = label;
        this.setToLabelOverride = setToLabelOverride;
        this.description = description;
    }
    BulkEditCheckboxComponent.prototype.master = function (value) {
        var _this = this;
        this.entities.forEach(function (entity) {
            setDotNotationField(entity, _this.field, value);
        });
    };
    BulkEditCheckboxComponent.prototype.original = function (index) {
        return this._originals[index];
    };
    BulkEditCheckboxComponent.prototype.resetEntity = function (entity) {
        var originalValue = getDotNotationField(this.original(this.entities.indexOf(entity)), this.field);
        setDotNotationField(entity, this.field, originalValue);
    };
    BulkEditCheckboxComponent.prototype.reset = function () {
        var _this = this;
        this.input.reset();
        this.entities.forEach(function (entity, index) {
            var originalValue = getDotNotationField(_this.original(index), _this.field);
            setDotNotationField(entity, _this.field, originalValue);
        });
    };
    Object.defineProperty(BulkEditCheckboxComponent.prototype, "entities", {
        get: function () {
            return this._entities;
        },
        set: function (entities) {
            this._entities = entities;
            this._originals = entities.map(function (entity) { return entity.clone(); });
        },
        enumerable: true,
        configurable: true
    });
    BulkEditCheckboxComponent.prototype.getInputField = function (entity) {
        if (!entity) {
            return null;
        }
        return getDotNotationField(entity, this.field);
    };
    BulkEditCheckboxComponent.prototype.inputFieldChanged = function (event, entity) {
        setDotNotationField(entity, this.field, event);
    };
    BulkEditCheckboxComponent.prototype.isDirty = function () {
        var _this = this;
        return this.entities.some(function (entity, index) {
            var originalValue = getDotNotationField(_this.original(index), _this.field);
            return _this.getInputField(entity) !== originalValue;
        });
    };
    return BulkEditCheckboxComponent;
}());
export { BulkEditCheckboxComponent };
