import { EventEmitter, TemplateRef } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { IdService } from 'app/core/id.service';
var State;
(function (State) {
    State[State["Drag"] = 0] = "Drag";
    State[State["DragError"] = 1] = "DragError";
    State[State["Loaded"] = 2] = "Loaded";
    State[State["Empty"] = 3] = "Empty";
})(State || (State = {}));
var FileInputComponent = /** @class */ (function () {
    function FileInputComponent(accept, multiple, id) {
        this.accept = accept;
        this.multiple = multiple;
        this.id = id;
        this.error = new EventEmitter();
        this.showName = true;
        this.State = State;
        this.state = new BehaviorSubject(State.Empty);
        this.onChange = function (files) { };
    }
    FileInputComponent.prototype.writeValue = function (files) {
        this.files = files || [];
        var state = Array.isArray(files) && files.length > 0 ? State.Loaded : State.Empty;
        this.state.next(state);
    };
    FileInputComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    FileInputComponent.prototype.registerOnTouched = function (fn) {
        //
    };
    FileInputComponent.prototype.dragenter = function (event) {
        event.stopPropagation();
        event.preventDefault();
        this.state.next(State.Drag);
        event.dataTransfer.dropEffect = 'copy';
        if (!this.acceptable(Array.from(event.dataTransfer.items))) {
            this.state.next(State.DragError);
        }
    };
    FileInputComponent.prototype.dragleave = function (event) {
        event.stopPropagation();
        event.preventDefault();
        this.state.next(State.Empty);
    };
    FileInputComponent.prototype.drop = function (event) {
        event.stopPropagation();
        event.preventDefault();
        this.upload(Array.from(event.dataTransfer.files));
    };
    FileInputComponent.prototype.change = function (event) {
        event.stopPropagation();
        event.preventDefault();
        this.upload(Array.from(event.target.files));
    };
    FileInputComponent.prototype.upload = function (files) {
        var _this = this;
        if (!this.acceptable(files)) {
            return this.error.emit();
        }
        var read$ = files.map(function (file) { return _this.readFile(file); });
        forkJoin(read$).subscribe(function (files) {
            _this.state.next(State.Loaded);
            _this.files = files;
            _this.onChange(files);
        });
    };
    FileInputComponent.prototype.removeFile = function (index) {
        this.files.splice(index, 1);
        this.files = this.files.slice();
        this.onChange(this.files);
        this.state.next(State.Empty);
    };
    FileInputComponent.prototype.readFile = function (file) {
        var _this = this;
        return new Observable(function (obs) {
            var reader = new FileReader();
            reader.onerror = function (err) { return obs.error(err); };
            reader.onabort = function (err) { return obs.error(err); };
            reader.onload = function (err) { return obs.next(_this.formatFile(file, reader.result.toString())); };
            reader.onloadend = function (err) { return obs.complete(); };
            return reader.readAsDataURL(file);
        });
    };
    FileInputComponent.prototype.formatFile = function (file, dataUrl) {
        return {
            name: file.name,
            type: file.type,
            dataURL: dataUrl,
            body: this.convertToBlob(dataUrl),
            file: file
        };
    };
    FileInputComponent.prototype.convertToBlob = function (dataURL) {
        if (dataURL.length < 6) {
            return '';
        }
        var parts = dataURL.split(',');
        if (parts.length < 2) {
            return '';
        }
        var base = parts[1];
        return window.atob(base);
    };
    FileInputComponent.prototype.acceptable = function (files) {
        var _this = this;
        if (!this.accept) {
            return true;
        }
        return files.every(function (file) { return _this.accept.includes(file.type); });
    };
    Object.defineProperty(FileInputComponent.prototype, "getStateClass", {
        get: function () {
            return this.state.pipe(map(function (state) {
                switch (state) {
                    case State.Loaded:
                        return 'loaded';
                    case State.Drag:
                        return 'drag';
                    case State.DragError:
                        return 'error';
                    default:
                        return 'empty';
                }
            }));
        },
        enumerable: true,
        configurable: true
    });
    return FileInputComponent;
}());
export { FileInputComponent };
