<div class="flex flex-end flex-align-center padding-large--left padding-large--right padding--top">
    <div class="spacer--xlarge-right">
        <span class="spacer--large-right">Rows per page:</span>
        <select-dropdown class="inline-block" [(ngModel)]="pageSize" (ngModelChange)="reset()">
            <option *ngFor="let option of pageSizeOptions" [value]="option.value">{{option.label}}</option>
        </select-dropdown>
    </div>
    <div *ngIf="showTotal" class="spacer--xlarge-right spacer--xlarge-left">{{firstVisible}} - {{lastVisible}} of {{total | number}}</div>
    <div class="spacer--xlarge-left">
        <button *ngIf="showFirstAndLast" type="button" class="link link-grey spacer--large-right" (click)="first()" [disabled]="!hasPrevious">
            <i class="fas fa-angle-double-left fa-lg"></i>
        </button>
        <button type="button" class="link link-grey spacer--xlarge-right" (click)="previous()" [disabled]="!hasPrevious">
            <i class="fas fa-angle-left fa-lg"></i>
        </button>
        <button type="button" class="link link-grey spacer--xlarge-left" (click)="next()" [disabled]="!hasNext">
            <i class="fas fa-angle-right fa-lg"></i>
        </button>
        <button *ngIf="showFirstAndLast" type="button" class="link link-grey spacer--large-left" (click)="last()" [disabled]="!hasNext">
            <i class="fas fa-angle-double-right fa-lg"></i>
        </button>
    </div>
</div>
