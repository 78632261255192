import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { Chart } from '../chart';

export enum ValueFormat {
    Currency,
    None
}

@Component({
    selector: 'spark-chart',
    template: '<div #chart class="spark-chart"></div>'
})
export class SparkChartComponent extends Chart implements OnInit, AfterViewInit {
    @Input() aggregate: (set: number[]) => number = null;
    @Input() format: ValueFormat = null;
    @Input() height: Number = 150;
    @Input() width: Number = 50;
    @Input() showLegend: Boolean = false;
    @Input() showTooltip: Boolean = false;
    @Input() showPoints: Boolean = false;
    @Input() c3Config = {};

    public called = false;

    public ngOnInit(): void {
        this.drawGraph();
    }

    public drawGraph() {
        this.called = true;
        this.chart = this.generate(Object.assign({
            size: {
                height: this.height,
                width: this.width
            },
            axis: {
                x: {
                    type: 'timeseries',
                    show: false
                },
                y: {
                    show: false
                }
            },
            point: {
                show: this.showPoints,
                r: 0,
                focus: {
                    expand: {
                        enabled: true,
                        r: 4
                    }
                }
            },
            legend: {
                show: false
            },
            tooltip: {
                show: this.showTooltip,
                format: {
                    title: this.d3.timeFormat('%b %e, %Y'),
                    value: this.valueFormat
                }
            }
        }, this.c3Config), {
            x: 'dates'
        });
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
        if (this.showLegend) {
            this.buildLegend();
        }
    }

    private get valueFormat(): any {
        switch (this.format) {
            case ValueFormat.Currency: return this.d3.format('$,.2r');
            default: return this.d3.format(',.2r');
        }
    }

    private buildLegend(): void {
        const select = this.d3.select;
        const color = this.chart.color;
        const chart = this.chart;

        const items = select(this.element.nativeElement).append('div').attr('class', 'legend')
            .selectAll('div')
            .data(this.chart.data()).enter()
            .append('div').attr('class', 'chart-legend-item')
            .on('mouseover', function (data) {
                chart.focus(data.id);
                select(this).select('.chart-legend-circle').style('box-shadow', '0 0 0 1px ' + color(data.id));
            })
            .on('mouseout', function () {
                chart.revert();
                select(this).select('.chart-legend-circle').style('box-shadow', null);
            });
        items.append('div').attr('class', 'chart-legend-circle')
            .each(function (id) { select(this).style('border-color', color(id)); });
        items.append('div').attr('class', 'chart-legend-id')
            .html(data => data.id);
        if (this.aggregate !== null) {
            items.append('div').attr('class', 'chart-legend-value')
                .html(data => this.valueFormat(this.aggregate(data.values.map(point => point.value))));
        }
    }

    public rebuildLegend(): void {
        this.element.nativeElement.removeChild(this.element.nativeElement.lastChild);
        if (this.showLegend) {
            this.buildLegend();
        }
    }
}
