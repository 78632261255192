import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';

import { Creative } from 'app/shared/models';
import { CreativeRepository } from 'app/core/repositories';
import { map } from 'rxjs/operators';

@Injectable()
export class AdsResolver implements Resolve<Creative[]> {
    constructor(
        private creativeRepository: CreativeRepository,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        const creativeId = route.params['id'];

        return this.creativeRepository.get(creativeId).pipe(
            map((creative: Creative) => {
                if (creative.externalId) {
                    this.router.navigate(['/campaign-manager/404']);
                }
                return [creative];
            })
        );
    }
}
