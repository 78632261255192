import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { GlobalSearchComponent } from './global-search';
import { MyAccountsComponent } from './my-accounts';
import { NotificationsComponent } from './notifications';
import { PlatformComponent } from './platform.component';
import { AuthGuard, InternalUserGuard, CanLoadIsInternalUsers } from 'app/core/guards';
import { BulkOperationStatusComponent } from 'app/shared/components';

export const routes: Routes = [
    // Match the root path.
    { path: '', pathMatch: 'full', redirectTo: '/campaign-manager' },
    {
        path: '',
        component: PlatformComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: NotificationsComponent,
                outlet: 'notifications'
            },
            {
                path: 'accounts',
                component: MyAccountsComponent
            },
            {
                path: 'bulk-operation-status',
                component: BulkOperationStatusComponent,
            },
            {
                path: 'internal-tools',
                canLoad: [CanLoadIsInternalUsers],
                loadChildren: () => import('./internal-tools/internal-tools.module').then(mod => mod.InternalToolsModule)
            },
            {
                path: 'inventory-manager',
                loadChildren: () => import('./inventory-manager/inventory-manager.module').then(mod => mod.InventoryModule)
            },
            {
                path: 'campaign-manager',
                loadChildren: () => import('./campaign-manager/campaign-manager.module').then(mod => mod.CampaignManagerModule)
            },
            {
                path: 'reporting',
                loadChildren: () => import('./reporting/reporting.module').then(mod => mod.ReportingModule)
            },
            {
                path: 'measurement',
                loadChildren: () => import('./measurement/measurement.module').then(mod => mod.MeasurementModule)
            },
            {
                path: 'history',
                canActivate: [InternalUserGuard],
                loadChildren: () => import('./history/history.module').then(mod => mod.HistoryModule)
            },
            {
                path: 'search',
                component: GlobalSearchComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class PlatformRoutingModule { }
