import {Injectable} from '@angular/core';
import {Observable, of, ReplaySubject, Subject} from 'rxjs';
import {Campaign, Pixel, StrategyCard} from 'app/shared/models';
import {CAMPAIGN_CONST, CampaignType, System} from 'app/shared/models/campaign';
import {LineItemRepository, StrategyCardRepository} from 'app/core/repositories';
import {switchMap} from 'rxjs/operators';

@Injectable()
export class CampaignFlowService {

    campaign = new ReplaySubject<Campaign>(1);
    validations = new ReplaySubject<any[]>(1);
    pixel = new Subject<Pixel>();
    $pauseLineItems = new ReplaySubject<any>(1);
    strategyCards = new ReplaySubject<StrategyCard[]>(1);

    campaignUpdated = this.campaign.asObservable();
    campaignLoaded = this.campaign.asObservable();
    validationsUpdated = this.validations.asObservable();
    pixelUpdated = this.pixel.asObservable();

    constructor(
        private lineItemRepository: LineItemRepository,
        private strategyCardRepository: StrategyCardRepository
    ){
        this.strategyCardRepository.cards().subscribe(strategyCards => this.strategyCards.next(strategyCards));
    }

    updateValidations(validations: any[]) {
        this.validations.next(validations);
    }

    loadPixel(pixel: Pixel) {
        this.pixel.next(pixel);
    }

    updatePixel(pixel: Pixel) {
        this.pixel.next(pixel);
    }

    setUpCampaign(goal: string, reach: string, buyingStrategyId?: number): Observable<any> {
        return this.strategyCards
            .pipe(
                switchMap(cards => {
                    let strategyCards = cards.filter(sc =>
                        sc.goal === goal
                        && sc.reach === reach
                        && sc.system === CAMPAIGN_CONST.DSP);

                    if (buyingStrategyId) {
                        strategyCards = strategyCards.filter(sc => sc.strategyCardId === buyingStrategyId);
                    }

                    return of(strategyCards);
                }),
                switchMap(strategyCards => {
                    const bidLabel = this.getBidLabel(goal, reach, strategyCards);
                    const pricingModel = this.getPricingModel(bidLabel, strategyCards);

                    return of({
                        bidLabel: bidLabel,
                        targetBidLabel: this.getTargetBidLabel(goal, reach),
                        pricingModel: pricingModel,
                        clearingMethod: this.getBidType(bidLabel, strategyCards),
                        goal: this.getGoal(bidLabel, goal, reach, pricingModel, strategyCards)
                    });
                })
            );
    }

    private getBidLabel(goal: string, reach: string, strategyCards: StrategyCard[]) {
        if (strategyCards.length > 0) {
            return strategyCards[0].bidLabel;
        } else if (goal === CAMPAIGN_CONST.GOAL_PERFORMANCE && reach === CAMPAIGN_CONST.REACH_REACH) {
            return CAMPAIGN_CONST.TARGET_ECPM;
        } else if (goal === CAMPAIGN_CONST.GOAL_BRANDING && reach === CAMPAIGN_CONST.REACH_IMPRESSIONS) {
            return CAMPAIGN_CONST.GOAL_CPM;
        }

        return null;
    }

    private getTargetBidLabel(goal: string, reach: string) {
        if (goal === CAMPAIGN_CONST.GOAL_PERFORMANCE) {
            switch (reach) {
                case 'conversions':
                case 'clicks':
                case 'reach':
                    return 'Max CPM Bid';
            }
        }

        return undefined;
    }

    private getPricingModel(bidLabel, strategyCards) {
        if (strategyCards.length > 0) {
            return strategyCards[0].pricingModel;
        } else if (bidLabel === CAMPAIGN_CONST.TARGET_ECPM || bidLabel === CAMPAIGN_CONST.GOAL_CPM) {
            return CAMPAIGN_CONST.GOAL_CPM;
        }

        return null;
    }

    private getBidType(bidLabel, strategyCards) {
        if (strategyCards.length > 0) {
            return strategyCards[0].clearingMethod;
        } else if (bidLabel === CAMPAIGN_CONST.TARGET_ECPM) {
            return CAMPAIGN_CONST.SECOND_PRICE;
        } else if (bidLabel === CAMPAIGN_CONST.GOAL_CPM
            || bidLabel === CAMPAIGN_CONST.MAX_CTR) {
            return CAMPAIGN_CONST.FIRST_PRICE;
        } else {
            return undefined;
        }
    }

    private getGoal(bidLabel, goal, reach, pricingModel, strategyCards) {
        const isGoalNull = () => {
            return goal === CAMPAIGN_CONST.GOAL_BRANDING && reach === CAMPAIGN_CONST.REACH_IMPRESSIONS
                || (goal === CAMPAIGN_CONST.GOAL_PERFORMANCE && bidLabel === CAMPAIGN_CONST.GOAL_CPA
                    && pricingModel === CAMPAIGN_CONST.GOAL_CPA);
        };

        if (strategyCards.length > 0) {
            return strategyCards[0].campaignGoal;
        } else if (bidLabel === CAMPAIGN_CONST.TARGET_ECPM) {
            return CAMPAIGN_CONST.GOAL_CPM;
        } else if (isGoalNull()) {
            return null;
        } else {
            return undefined;
        }
    }

    getSystem(campaignType: CampaignType, demandType: string): System {
        // demandType can be undefined if house/direct is chosen
        if ((campaignType === CampaignType.BrandingClicks
            || campaignType === CampaignType.BrandingConversions
            || campaignType === CampaignType.PerformanceClicks
            || campaignType === CampaignType.PerformanceConversions
            || campaignType === CampaignType.PerformanceCTR)
            && (demandType && demandType === CAMPAIGN_CONST.EXCHANGE)
        ) {
            return CAMPAIGN_CONST.DSP;
        } else {
            return CAMPAIGN_CONST.SSP;
        }
    }

    isNewGoal(goal, reach, campaign: Campaign): Observable<boolean> {
        if (!campaign.isSsp) {
            return this.setUpCampaign(goal, reach, campaign.buyingStrategyId)
                .pipe(
                    switchMap(campaignData => {
                        return this.lineItemRepository
                            .search({
                                conditions: [{
                                    field: 'campaign',
                                    value: campaign.id,
                                    returnMode: 'only',
                                    return: ['id']
                                }]
                            }).pipe(switchMap(lineItems => {
                                return of({
                                    campaignData: campaignData,
                                    lineItems: lineItems
                                });
                            }));
                    }),
                    switchMap(data => {
                        if (data.lineItems.length > 0 && (data.campaignData.bidLabel !== campaign.bidAmountLabel
                            || data.campaignData.goal !== campaign.goal)) {

                            let pause = true;
                            const lineItems = data.lineItems.filter(lineItem => lineItem.status === 'active');
                            if (lineItems.length === 0) {
                                pause = false;
                            }

                            return of(pause);
                        }

                        return of(false);
                    })
                );
        }

        return of(false);
    }

}
