import { Injectable } from '@angular/core';

import {
    AdSlotRepository,
    BundleRepository,
    NewsletterRepository,
    PublisherRepository
} from 'app/core/repositories';

const RETURN_FIELDS = ['id', 'refId', 'name'];
const RETURN_MODE = 'only';
const TYPES = ['cookie_jar', 'tracking', 'powered_by', 'ad_choices', 'ad_choices_icon'];
const REMOTE_SEARCHABLE = ['Publisher', 'Newsletter', 'Ad Slot', 'Bundle'];

@Injectable()
export class InventoryHelperService {
    publishers = [];
    newsletters = [];
    adSlots = [];
    bundles = [];

    constructor(
        private adSlotRepository: AdSlotRepository,
        private bundleRepository: BundleRepository,
        private newsletterRepository: NewsletterRepository,
        private publisherRepository: PublisherRepository
    ) { }

    initPublishers (advertiserId): Promise<any> {
        this.reset();

        return this.publisherRepository.getBlockableByAdvertiser(advertiserId)
            .toPromise()
            .then(results => {
                this.addElementsToSet(this.publishers, results);
            });
    }

    reset () {
        this.publishers = [];
        this.newsletters = [];
        this.adSlots = [];
        this.bundles = [];
    }

    lookup (id, type) {

        let options = [];
        if (type === 'Publisher') {
            options = this.publishers;
        }
        if (type === 'Newsletter') {
            options = this.newsletters;
        }
        if (type === 'Ad Slot') {
            options = this.adSlots;
        }
        if (type === 'Bundle') {
            options = this.bundles;
        }

        for (let i = 0; i < options.length; i++) {
            let option = options[i];
            if (id === option.id) {
                return option;
            }
        }
    }

    lookupBySearchTerm (term) {
        const requestParams: any = {
            conditions: [{
                field: 'name',
                value: term,
                operator: 'like'
            }],
            return: RETURN_FIELDS,
            returnMode: RETURN_MODE
        };

        const adSlotRequestParams: any = {
            conditions: [
                {
                    field: 'type',
                    value: TYPES,
                    operator: 'neq'
                },
                {
                    mode: 'and',
                    conditions: [
                        {
                            field: 'name',
                            value: term,
                            operator: 'like'
                        }
                    ]
                }
            ],
            return: RETURN_FIELDS,
            returnMode: RETURN_MODE
        };

        const isNum = /^\d+$/.test(term);
        if (isNum) {
            requestParams.conditions.push({
                mode: 'or',
                field: 'refId',
                value: term,
                operator: 'like'
            });
            adSlotRequestParams
                .conditions[1]
                .conditions
                .push({mode: 'or', field: 'refId', value: term, operator: 'like'});
        }

        return Promise.all([
            REMOTE_SEARCHABLE
                .indexOf('Publisher') > -1 ? this.publisherRepository.search(requestParams).toPromise() : [],
            REMOTE_SEARCHABLE
                .indexOf('Newsletter') > -1 ? this.newsletterRepository.search(requestParams).toPromise() : [],
            REMOTE_SEARCHABLE
                .indexOf('Ad-Slot') > -1 ? this.adSlotRepository.search(adSlotRequestParams).toPromise() : [],
            REMOTE_SEARCHABLE
                .indexOf('Bundle') > -1 ? this.bundleRepository.search(requestParams).toPromise() : []
        ]);
    }

    lookupInventory (inventories: any[], useField: string): Promise<any> {
        let needBackendLookup = [];
        let inventoriesFound = [[], [], [], []];
        if (!inventories) {
            return Promise.resolve(inventoriesFound);
        }
        for (let i = 0; i < inventories.length; i++) {
            let currInventory = inventories[i];
            let cached = this.lookup(currInventory[useField], currInventory.type);
            if (!cached) {
                needBackendLookup.push(currInventory);
            } else {
                let inventoriesFoundIdx;
                if (currInventory.type === 'Publisher') {
                    inventoriesFoundIdx = 0;
                } else if (currInventory.type === 'Newsletter') {
                    inventoriesFoundIdx = 1;
                } else if (currInventory.type === 'Ad-Slot' || currInventory.type === 'Ad Slot') {
                    inventoriesFoundIdx = 2;
                } else {
                    inventoriesFoundIdx = 3;
                }

                inventoriesFound[inventoriesFoundIdx].push(cached);
            }
        }
        if (needBackendLookup.length < 1) {
            return Promise.resolve(inventoriesFound);
        }

        return this.queryForInventory(needBackendLookup, useField)
            .then(inventoriesLoaded => {
                inventoriesFound[0] = inventoriesFound[0].concat(inventoriesLoaded[0]);
                inventoriesFound[1] = inventoriesFound[1].concat(inventoriesLoaded[1]);
                inventoriesFound[2] = inventoriesFound[2].concat(inventoriesLoaded[2]);
                inventoriesFound[3] = inventoriesFound[3].concat(inventoriesLoaded[3]);

                this.addElementsToSet(this.publishers, inventoriesLoaded[0]);
                this.addElementsToSet(this.newsletters, inventoriesLoaded[1]);
                this.addElementsToSet(this.adSlots, inventoriesLoaded[2]);
                this.addElementsToSet(this.bundles, inventoriesLoaded[3]);
                return inventoriesFound;
            });
    }

    queryForInventory (inventories, useField) {

        function defaultQuery () {
            return {
                conditions: [],
                return: RETURN_FIELDS,
                returnMode: RETURN_MODE
            };
        }

        let publisherParams = defaultQuery();
        let newsletterParams = defaultQuery();
        let adSlotParams = defaultQuery();
        let bundleParams = defaultQuery();

        for (let i = 0; i < inventories.length; i++) {
            let currInventory = inventories[i];
            let type = currInventory.type;
            let params;

            if (type === 'Publisher') {
                params = publisherParams;
            } else if (type === 'Newsletter') {
                params = newsletterParams;
            } else if (type === 'Ad Slot') {
                params = adSlotParams;
            } else {
                params = bundleParams;
            }

            if (params.conditions.length === 0) {
                params.conditions.push({field: useField, value: []});
            }
            params.conditions[0].value.push(currInventory[useField]);
        }

        let asyncQueries = [];
        if (publisherParams.conditions.length > 0) {
            asyncQueries.push(this.publisherRepository.all(publisherParams).toPromise());
        } else {
            asyncQueries.push([]);
        }
        if (newsletterParams.conditions.length > 0) {
            asyncQueries.push(this.newsletterRepository.all(newsletterParams).toPromise());
        } else {
            asyncQueries.push([]);
        }
        if (adSlotParams.conditions.length > 0) {
            asyncQueries.push(this.adSlotRepository.all(adSlotParams).toPromise());
        } else {
            asyncQueries.push([]);
        }
        if (bundleParams.conditions.length > 0) {
            asyncQueries.push(this.bundleRepository.all(bundleParams).toPromise());
        } else {
            asyncQueries.push([]);
        }
        return Promise.all(asyncQueries);
    }

    private addElementsToSet(list, elements) {
        for (let i = 0; i < elements.length; i++) {
            let currElement = elements[i];
            if (!this.setContains(list, currElement)){
                list.push(currElement);
            }
        }
    }

    private setContains (list, element) {
        for (let i = 0; i < list.length; i++) {
            if (list[i].id === element.id) {
                return true;
            }
        }
        return false;
    }

}
