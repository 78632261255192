import { Model } from './model';

export class LiveConnectSetupMail extends Model {
    advertiser: string;
    conversionPixel: string;
    appId: string;
    liveConnectTag: string;
    attachment: string;
    to: string[];
    cc?: string[];
    bcc?: string[];
    isJavaScriptTag: boolean;
    tagType: 'javascript' | 'image';

    serialize(): string {
        return JSON.stringify(this);
    }

    get entity() {
        return 'LiveConnect Setup Mail';
    }
}
