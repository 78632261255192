import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { LineItem } from 'app/shared/models';
import { LineItemRepository } from 'app/core/repositories';

import moment from 'moment';

@Injectable()
export class PublisherCreativeApprovalResolver implements Resolve<LineItem[]> {
    constructor(private lineItemRepository: LineItemRepository) { }

    resolve(route: ActivatedRouteSnapshot) {
        const startOfNextWeek = moment().add(1, 'weeks').startOf('week');
        const endOfNextWeek = moment().add(1, 'weeks').endOf('week');

        const query = {
            conditions: [
                { field: 'startDate', value: startOfNextWeek, operator: 'gteq'},
                { field: 'startDate', value: endOfNextWeek, operator: 'lteq'}
            ]
        };
        return this.lineItemRepository.exchangeStrategySearch(query);
    }
}
