import { EventEmitter, TemplateRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { of, forkJoin, combineLatest } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AvailableActionsService } from 'app/shared/helpers/available-actions.service';
import { CampaignRepository } from 'app/core/repositories';
import { ErrorHelper } from 'app/core/errors/error-helper';
import { HistoryViewComponent } from 'app/shared/components/history-view';
import { IdService } from 'app/core/id.service';
import { NotificationsService } from 'app/core';
import { PaginatorComponent } from 'app/shared/elements/paginator';
import { TableComponent } from 'app/shared/elements/table';
import { TableHelper } from 'app/shared/helpers/table-helper';
var CampaignsTableComponent = /** @class */ (function () {
    function CampaignsTableComponent(campaignRepository, availActions, notifications, router, id) {
        var _this = this;
        this.campaignRepository = campaignRepository;
        this.availActions = availActions;
        this.notifications = notifications;
        this.router = router;
        this.id = id;
        this.paused = new EventEmitter();
        this.deleted = new EventEmitter();
        this.helper = new TableHelper(function (params) { return _this.adapter(params); });
        this._ids = null;
        this._conversionPixelId = null;
        this._failedCampaigns = null;
        this.errorHelper = null;
        this.advertiser = null;
    }
    CampaignsTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.helper.table = this.table;
        this.helper.paginator = this.paginator;
        combineLatest(this.table.query, this.paginator.query).subscribe(function (args) { return _this.helper.search(args); });
    };
    Object.defineProperty(CampaignsTableComponent.prototype, "insertionOrders", {
        set: function (value) {
            this._ids = value;
            this.paginator.reset();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CampaignsTableComponent.prototype, "conversionPixelId", {
        set: function (id) {
            this._conversionPixelId = id;
            this.paginator.reset();
        },
        enumerable: true,
        configurable: true
    });
    CampaignsTableComponent.prototype.adapter = function (params) {
        if (Array.isArray(this._ids)) {
            params.conditions.push({
                field: 'insertionOrder',
                value: this._ids
            });
        }
        if (typeof this.advertiser === 'string') {
            params.conditions.push({
                field: 'advertiser',
                value: this.advertiser
            });
        }
        if (typeof this._conversionPixelId === 'string') {
            params.conditions.push({
                field: 'conversionPixel',
                value: this._conversionPixelId
            });
        }
        return this.campaignRepository.asyncSearch(params);
    };
    CampaignsTableComponent.prototype.actionHandler = function (action, campaign) {
        if (action === 'edit') {
            this.router.navigate(['/campaign-manager/campaigns', campaign.refId, 'edit',
                { redirect: btoa(this.router.url) }]);
        }
        else if (action === 'pause') {
            this.campaignPauseModal.open(campaign);
        }
        else if (action === 'history') {
            this.historyView.viewCampaignHistory(campaign);
        }
    };
    CampaignsTableComponent.prototype.handlePaused = function () {
        this.paused.emit();
        this.notifications.success("The campaign has successfully been paused.");
    };
    CampaignsTableComponent.prototype.deleteCampaigns = function (campaigns) {
        var _this = this;
        this._failedCampaigns = [];
        this.errorHelper = new ErrorHelper();
        forkJoin(campaigns.map(function (campaign) {
            return _this.campaignRepository.delete(campaign.id).pipe(catchError(function (err) {
                _this.errorHelper.loadBackEndErrors(err.error.errors);
                var errorList = _this.errorHelper.errors;
                _this._failedCampaigns.push({
                    key: campaign.name,
                    value: errorList
                });
                return of(null);
            }));
        })).subscribe(function (res) {
            if (_this._failedCampaigns.length > 0) {
                _this.displayErrorMessage(_this._failedCampaigns);
            }
            else {
                _this.notifications.success("Selected campaigns have been successfully deleted.");
                _this.deleted.emit();
                _this.table.clearSelections();
            }
            _this.paginator.reset();
        });
    };
    CampaignsTableComponent.prototype.displayErrorMessage = function (campaigns) {
        var entityName = 'campaign';
        if (campaigns.length !== 1) {
            entityName = entityName.concat('s');
        }
        var error = '<strong>Uh Oh!</strong>  The following ' + entityName + ' could not be deleted:';
        campaigns.forEach(function (c) { return error += '<li>' + c.key + ' ' + c.value + '</li>'; });
        this.notifications.error(error, '', 0);
    };
    return CampaignsTableComponent;
}());
export { CampaignsTableComponent };
