import { AfterViewInit, EventEmitter, QueryList } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil, map, distinctUntilChanged } from 'rxjs/operators';
import { TabComponent } from './tab.component';
import { IdService } from 'app/core/id.service';
import { GAEventCategory, GAHelper } from 'app/shared/helpers/ga-helper';
var TabsComponent = /** @class */ (function () {
    /**
     * Create a new tabs component.
     */
    function TabsComponent(routeKey, router, location, route, id) {
        this.router = router;
        this.location = location;
        this.route = route;
        this.id = id;
        /**
         * Emit when a tab is selected.
         */
        this.onTabSelected = new EventEmitter();
        /**
         * Emit a value of our choice upon click
         */
        this.clicked = new EventEmitter();
        /**
         * On destroy subject.
         */
        this.onDestroy$ = new Subject();
        this.routeKey = routeKey;
    }
    /**
     * Initialize.
     */
    TabsComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.route.queryParamMap.pipe(takeUntil(this.onDestroy$), map(function (map) { return map.get(_this.routeKey); }), distinctUntilChanged()).subscribe(function (header) {
            var activeTab = _this.tabs.toArray().find(function (tab) { return tab.header === header; });
            // Activate the tab identified in the route if present
            if (_this.routeKey && activeTab) {
                setTimeout(function () { return _this.select(activeTab, true); });
                return;
            }
        });
        if (!this.routeKey) {
            // fallback to the default active tab
            for (var _i = 0, _a = this.tabs.toArray(); _i < _a.length; _i++) {
                var t = _a[_i];
                if (t.active) {
                    this.select(t);
                    break;
                }
            }
        }
    };
    /**
     * Select a tab and make it active.
     */
    TabsComponent.prototype.select = function (tab, replaceUrl) {
        var _this = this;
        if (replaceUrl === void 0) { replaceUrl = false; }
        if (!tab.empty) {
            this.activeTab = tab;
            this.tabs.forEach(function (_tab) { return _tab.active = tab === _tab; });
            window.setTimeout(function () {
                _this.onTabSelected.emit(tab);
            }, 0);
            if (this.routeKey) {
                GAHelper.trackEvent(GAEventCategory.TABS_INTERACTION, "Tab Selected : " + tab.header + ", Query: " + this.routeKey, "Tab Navigation Page : " + this.router.url);
                this.pushToURL(tab, replaceUrl);
            }
            else {
                GAHelper.trackEvent(GAEventCategory.TABS_INTERACTION, "Tab Selected : " + tab.header, "Tab Navigation : Tab Selection");
            }
            this.clicked.emit(this.activeTab.value);
        }
        else {
            this.clicked.emit();
        }
    };
    /**
     * Select a tab by its title.
     */
    TabsComponent.prototype.selectTabByTitle = function (title) {
        for (var _i = 0, _a = this.tabs.toArray(); _i < _a.length; _i++) {
            var tab = _a[_i];
            if (tab.header === title) {
                this.select(tab);
                break;
            }
        }
    };
    /**
     * Push the currently selected tab to the URL.
     */
    TabsComponent.prototype.pushToURL = function (tab, replaceUrl) {
        var _a;
        if (replaceUrl === void 0) { replaceUrl = false; }
        this.router.navigate([], {
            queryParams: (_a = {},
                _a[this.routeKey] = tab.header,
                _a),
            queryParamsHandling: 'merge',
            replaceUrl: replaceUrl
        });
    };
    return TabsComponent;
}());
export { TabsComponent };
