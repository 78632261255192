import * as tslib_1 from "tslib";
import moment from 'moment';
import { Model } from './model';
var InsertionOrder = /** @class */ (function (_super) {
    tslib_1.__extends(InsertionOrder, _super);
    function InsertionOrder(from) {
        var _this = _super.call(this, from) || this;
        if (typeof _this.isUnlimitedBudget !== 'boolean') {
            _this.isUnlimitedBudget = false;
        }
        return _this;
    }
    InsertionOrder.prototype.serialize = function () {
        var insertionOrder = this.clone(InsertionOrder);
        if (insertionOrder.admin && insertionOrder.admin['hash']) {
            insertionOrder.admin = insertionOrder.admin['hash'];
        }
        if (insertionOrder.executive && insertionOrder.executive['hash']) {
            insertionOrder.executive = insertionOrder.executive['hash'];
        }
        if (insertionOrder.trafficker && insertionOrder.trafficker['hash']) {
            insertionOrder.trafficker = insertionOrder.trafficker['hash'];
        }
        insertionOrder.startDate = moment(insertionOrder.startDate).format('YYYY-MM-DD') + ' 00:00:00';
        insertionOrder.endDate = moment(insertionOrder.endDate).format('YYYY-MM-DD') + ' 23:59:59';
        return JSON.stringify(insertionOrder);
    };
    Object.defineProperty(InsertionOrder.prototype, "entity", {
        get: function () {
            return 'Insertion Order';
        },
        enumerable: true,
        configurable: true
    });
    return InsertionOrder;
}(Model));
export { InsertionOrder };
