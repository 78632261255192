import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SensitiveCategory } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { map } from 'rxjs/operators';
import { Option } from 'app/shared/elements/dropdown';

@Injectable()
export class SensitiveCategoryRepository extends BackendRepository<SensitiveCategory> {

    public constructor(http: HttpClient) {
        super(http, '/mc/sensitive-category-labels', SensitiveCategory);
    }

    public all():  Observable<SensitiveCategory[]> {
        return this.http.get(this.url(this.path)).pipe(
            map(response => response['data'].map(data => this.build(data)) as SensitiveCategory[])
        );
    }

    public linkSensitiveCategories(creativeId: number, sensitiveCategories: number[]): Observable<SensitiveCategory> {
        const params = {
            attributes: sensitiveCategories
        };

        const url = this.url('/mc/creatives/li-' + creativeId + '/sensitive-category-labels');

        return this.http.put(url, params).pipe(map(response => this.build(response['output'])));
    }

    public searchByCreativeId(id: number): Observable<SensitiveCategory[]> {
        const params = {
            filters: [
                {field: 'creatives.id', value: 'li-' + id}
            ]
        };

        const url = this.url(this.path + '/search');

        return this.http.post(url, params)
        .pipe(map(response => response['data'].map(data => this.build(data)) as SensitiveCategory[]));
    }

    /**
     * Converts observable array of sensitive categories
     * into an observable array of options
     *
     * @param {Observable<SensitiveCategory[]>} sensitiveCategories
     * @return {Observable<Option[]>} options
     */
    public convertCategoriesToOptions(sensitiveCategories: Observable<SensitiveCategory[]>): Observable<Option[]> {
        return sensitiveCategories.pipe(map(sensitiveCategoryList => {
            return sensitiveCategoryList.map(option => {
                return {
                    key: option.id,
                    value: option.id,
                    label: option.name,
                    data: option
                };
            });
        }));
    }
}

