import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

import { AdSlot, AdSlotSize } from 'app/shared/models';
import {
    AdSlotRepository,
    NewsletterRepository
} from 'app/core/repositories';
import { mergeMap, switchMap} from 'rxjs/operators';

@Injectable()
export class AdSlotResolver implements Resolve<AdSlot> {
    constructor(
        private adSlotRepository: AdSlotRepository,
        private newsletterRepository: NewsletterRepository
    ) { }

    resolve(route: ActivatedRouteSnapshot): Observable<AdSlot> {
        const newsletterId = route.paramMap.get('newsletter');
        const adSlotId = route.paramMap.get('id');
        const publisherId = route.paramMap.get('publisher');

        if (adSlotId) {
            return this.adSlotRepository.get(adSlotId).pipe(
                switchMap((adSlot: AdSlot) => {                    
                    if (adSlot.isHybrid) {
                        // Remove display sizes if ad slot is not display
                        if (!adSlot.isDisplay) {
                            adSlot.sizes = [] as AdSlotSize[];
                        }
                    }
                    
                    return of(adSlot);
                })
            );
        } else if (newsletterId) {
            return this.newAdSlot(newsletterId);
        } else if (publisherId) {
            return this.newAdSlotFromPublisher(publisherId);
        } else {
            const adSlot = new AdSlot();
            return of(adSlot);
        }
    }

    newAdSlot(newsletterId): Observable<AdSlot> {
        return this.newsletterRepository.get(newsletterId)
            .pipe(mergeMap(newsletter => {

                const newAdSlot = new AdSlot();
                newAdSlot.newsletter = newsletter.id;
                newAdSlot.newsletterObj = newsletter;

                return of(newAdSlot);
            }));
    }

    newAdSlotFromPublisher(publisherId): Observable<AdSlot> {
        return this.newsletterRepository.searchByPublisherId(publisherId)
            .pipe(mergeMap(publisher => {
                const newAdSlot = new AdSlot();
                newAdSlot.publisherRefId = publisher.refId;
                newAdSlot.publisherName = publisher.name;
                newAdSlot.publisher = publisher;

                return of(newAdSlot);
            }));
    }
}
