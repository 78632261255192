import { Component, EventEmitter, Output, ViewChild, ChangeDetectorRef } from '@angular/core';

import { Model, LineItem } from 'app/shared/models';
import { LightboxComponent } from 'app/shared/elements/lightbox';
import { LineItemsTableComponent } from 'app/shared/components/line-items-table';
import { IdService } from 'app/core/id.service';

@Component({
    selector: 'linked-line-items-lightbox',
    templateUrl: './linked-line-items-lightbox.html'
})
export class LinkedLineItemsLightboxComponent {
    @ViewChild(LineItemsTableComponent, { static: true }) table: LineItemsTableComponent;
    @ViewChild(LightboxComponent, { static: true }) lightbox: LightboxComponent;
    @Output() submit = new EventEmitter<{ entities: Model[], selected: LineItem[] }>();
    entities: Model[] = [];
    lineItemIds: string[] = [];
    action = null;
    progress: number = null;

    constructor(
        private cdr: ChangeDetectorRef,
        public id: IdService
    ) {}

    open(entities: Model | Model[], lineItems: LineItem[], action = null) {
        this.action = action;
        this.entities = Array.isArray(entities) ? entities : [entities];
        this.lineItemIds = lineItems.map(lineItem => lineItem.id);
        this.lightbox.open();
    }

    close() {
        this.lightbox.close();
        this.progress = null;
    }

    get hasAction() {
        return this.action !== null;
    }

    get selected() {
        return this.table.table.selectedItems;
    }

    emit() {
        this.submit.emit({
            entities: this.entities,
            selected: this.selected
        });
    }

    initiate() {
        this.progress = 0;
    }

    postProgress() {
        this.progress++;
        this.cdr.detectChanges();
    }

    get isLoading() {
        return Array.isArray(this.selected) && this.progress !== null
            && this.selected.length !== this.progress;
    }
}
