import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { MediaGroup } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { map } from 'rxjs/operators';
var MediaGroupRepository = /** @class */ (function (_super) {
    tslib_1.__extends(MediaGroupRepository, _super);
    function MediaGroupRepository(http) {
        return _super.call(this, http, '/media-group', MediaGroup) || this;
    }
    MediaGroupRepository.prototype.confirm = function (body) {
        var params = [this.path, 'confirm'];
        if (body.id) {
            params.push(body.id);
        }
        return this.http.post(this.url.apply(this, params), body.serialize());
    };
    MediaGroupRepository.prototype.activate = function (mediaGroup) {
        var _this = this;
        return this.http.post(this.url(this.path, 'activate', mediaGroup.id), {
            version: mediaGroup.version
        }).pipe(map(function (response) { return _this.build(response['output']); }));
    };
    MediaGroupRepository.prototype.deactivate = function (mediaGroup) {
        var _this = this;
        return this.http.post(this.url(this.path, 'deactivate', mediaGroup.id), {
            version: mediaGroup.version
        }).pipe(map(function (response) { return _this.build(response['output']); }));
    };
    return MediaGroupRepository;
}(BackendRepository));
export { MediaGroupRepository };
