import * as tslib_1 from "tslib";
import { SupplyImageAssetType } from 'app/shared/models/native-supply/supply-image-asset';
import { SupplyTextAssetType } from 'app/shared/models/native-supply/supply-text-asset';
import { Model } from '../model';
export var SupplyAssetTypeId;
(function (SupplyAssetTypeId) {
    SupplyAssetTypeId[SupplyAssetTypeId["Title"] = 20] = "Title";
    SupplyAssetTypeId[SupplyAssetTypeId["SponsoredBy"] = 21] = "SponsoredBy";
    SupplyAssetTypeId[SupplyAssetTypeId["Description"] = 22] = "Description";
    SupplyAssetTypeId[SupplyAssetTypeId["CTA"] = 32] = "CTA";
    SupplyAssetTypeId[SupplyAssetTypeId["ImageIcon"] = 33] = "ImageIcon";
    SupplyAssetTypeId[SupplyAssetTypeId["ImageMain"] = 34] = "ImageMain";
})(SupplyAssetTypeId || (SupplyAssetTypeId = {}));
export var IABAssets;
(function (IABAssets) {
    IABAssets["title"] = "title";
    IABAssets["data"] = "data";
    IABAssets["img"] = "img";
})(IABAssets || (IABAssets = {}));
var SupplyAsset = /** @class */ (function (_super) {
    tslib_1.__extends(SupplyAsset, _super);
    function SupplyAsset() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(SupplyAsset.prototype, "entity", {
        get: function () {
            return 'SupplyAsset';
        },
        enumerable: true,
        configurable: true
    });
    SupplyAsset.prototype.serialize = function () {
        delete this._isClone;
        return JSON.stringify(this);
    };
    return SupplyAsset;
}(Model));
export { SupplyAsset };
export var SupplyAssetProperties = [
    {
        'label': 'Logo',
        'assetLabel': 'Logo',
        'modelKey': 'logoImage',
        'type': SupplyImageAssetType.Icon,
        'assetKey': 'img',
        'width': 1,
        'height': 1,
        'macro': 'l',
    },
    {
        'label': 'Headline',
        'assetLabel': 'Headline',
        'modelKey': 'headline',
        'type': null,
        'assetKey': 'title',
        'len': 90,
        'max': 255,
        'macro': 't',
        'required': true
    }, {
        'label': 'Body',
        'assetLabel': 'Body',
        'modelKey': 'description',
        'type': SupplyTextAssetType.Description,
        'assetKey': 'data',
        'len': 250,
        'max': 2000,
        'macro': 'd'
    }, {
        'label': 'CTA',
        'assetLabel': 'CTA',
        'modelKey': 'callToAction',
        'type': SupplyTextAssetType.CallToAction,
        'assetKey': 'data',
        'len': 15,
        'max': 255,
        'macro': 'cta'
    }, {
        'label': 'Sponsored',
        'assetLabel': 'Sponsored',
        'modelKey': 'sponsored',
        'type': SupplyTextAssetType.SponsoredBy,
        'assetKey': 'data',
        'len': 25,
        'max': 255,
        'macro': 'sb'
    }, {
        'label': 'Image',
        'assetLabel': 'Image',
        'modelKey': 'mainImage',
        'type': SupplyImageAssetType.Main,
        'assetKey': 'img',
        'width': 600,
        'height': 314,
        'macro': 'i'
    }
];
