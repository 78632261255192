import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'onOff'
})
export class OnOffPipe implements PipeTransform {
    transform(value: boolean) {
        if (typeof value === 'boolean') {
            return value ? 'On' : 'Off';
        }

        return null;
    }
}
