import { Model } from './model';

export class SensitiveCategory extends Model {
    id: any;
    name: string;
    description: string;
    updatedAt: Date;

    public serialize(): string {
        return JSON.stringify(this);
    }

    get entity(): string {
        return 'Sensitive Category';
    }
}
