import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { GlobalBlocklist } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { map } from 'rxjs/operators';
var GlobalBlocklistRepository = /** @class */ (function (_super) {
    tslib_1.__extends(GlobalBlocklistRepository, _super);
    function GlobalBlocklistRepository(http) {
        return _super.call(this, http, '/flexible-list', GlobalBlocklist) || this;
    }
    GlobalBlocklistRepository.prototype.searchAsOptions = function (query) {
        if (query === void 0) { query = {}; }
        return this.all(query).pipe(map(function (blocklists) {
            return blocklists.map(function (option) {
                return {
                    key: option.id,
                    value: option.id,
                    label: ['(' + option.id + ')', option.name].join(' '),
                    data: option
                };
            });
        }));
    };
    GlobalBlocklistRepository.prototype.convertGlobalBlocklistToOptions = function (blocklists) {
        return blocklists.pipe(map(function (blockList) {
            return blockList.map(function (option) {
                return {
                    key: option.id,
                    value: option.id,
                    label: ['(' + option.id + ')', option.name].join(' '),
                    data: option
                };
            });
        }));
    };
    return GlobalBlocklistRepository;
}(BackendRepository));
export { GlobalBlocklistRepository };
