import * as tslib_1 from "tslib";
import { QueryList } from "@angular/core";
import { Model } from "app/shared/models";
import { IdService } from "app/core";
import { EmailServiceProviderCardComponent } from "app/shared/components/email-service-provider-card";
import { BehaviorSubject } from "rxjs";
var BulkEditEspComponent = /** @class */ (function () {
    function BulkEditEspComponent(context, label, id) {
        this.id = id;
        this._entities = [];
        this._originals = [];
        this.entityName = '';
        this.espCards = [];
        this.master = new MasterEspFieldOverride();
        this.dirtyEntitiesByIndexMap$ = new BehaviorSubject([]);
        this.processingUpdate = false;
        this.context = context;
        this.label = label;
    }
    BulkEditEspComponent.prototype.updateEsp = function (value, index) {
        this.entities[index].esp = value;
    };
    BulkEditEspComponent.prototype.updateEmailTagReplacement = function (value, index) {
        this.entities[index].emailTagReplacement = value;
    };
    BulkEditEspComponent.prototype.updateEmailTagType = function (value, index) {
        this.entities[index].emailTagType = value;
    };
    BulkEditEspComponent.prototype.updatePlacementTagReplacement = function (value, index) {
        this.entities[index].placementTagReplacement = value;
    };
    BulkEditEspComponent.prototype.updateListTagReplacement = function (value, index) {
        this.entities[index].listTagReplacement = value;
    };
    BulkEditEspComponent.prototype.updateLceeKeyReplacement = function (value, index) {
        this.entities[index].lceeKeyReplacement = value;
    };
    BulkEditEspComponent.prototype.updateLceeTagReplacement = function (value, index) {
        this.entities[index].lceeTagReplacement = value;
    };
    BulkEditEspComponent.prototype.validEspSetting = function (index) {
        return (this.entities[index].esp !== null);
    };
    BulkEditEspComponent.prototype.updateAllEspSettings = function (master) {
        var _this = this;
        this.entities.forEach(function (entity, index) {
            _this.updateEsp(master.esp, index);
            _this.updateEmailTagReplacement(master.emailTagReplacement, index);
            _this.updateEmailTagType(master.emailTagType, index);
            _this.updatePlacementTagReplacement(master.placementTagReplacement, index);
            _this.updateListTagReplacement(master.listTagReplacement, index);
            _this.updateLceeKeyReplacement(master.lceeKeyReplacement, index);
            _this.updateLceeTagReplacement(master.lceeTagReplacement, index);
            var card = _this.cards.toArray()[index];
            var updatedEntity = _this.entities[index];
            card.entity = updatedEntity;
            _this.updateDirtyCheck(index, _this.checkDirty(index));
        });
    };
    BulkEditEspComponent.prototype.updateEspSettings = function (entity, index) {
        this.updateEsp(entity.esp, index);
        this.updateEmailTagReplacement(entity.emailTagReplacement, index);
        this.updateEmailTagType(entity.emailTagType, index);
        this.updatePlacementTagReplacement(entity.placementTagReplacement, index);
        this.updateListTagReplacement(entity.listTagReplacement, index);
        this.updateLceeKeyReplacement(entity.lceeKeyReplacement, index);
        this.updateLceeTagReplacement(entity.lceeTagReplacement, index);
        this.updateDirtyCheck(index, this.checkDirty(index));
    };
    BulkEditEspComponent.prototype.isDirty = function () {
        var _this = this;
        var hasDirty = false;
        this.entities.forEach(function (entity, index) {
            var checkDirty = _this.checkDirty(index);
            if (checkDirty) {
                hasDirty = true;
            }
            _this.updateDirtyCheck(index, checkDirty);
        });
        return hasDirty || this.master.isDirty();
    };
    BulkEditEspComponent.prototype.updateDirtyCheck = function (index, value) {
        var currentMap = tslib_1.__assign({}, this.dirtyEntitiesByIndexMap$.value);
        currentMap[index] = value ? value : false;
        this.dirtyEntitiesByIndexMap$.next(currentMap);
    };
    BulkEditEspComponent.prototype.checkDirty = function (index) {
        if (!index && index !== 0) {
            return false;
        }
        var original = this.original(index);
        var entity = this.entities[index];
        return original.esp !== entity.esp
            || original.espName !== entity.espName
            || original.emailTagReplacement !== entity.emailTagReplacement
            || original.emailTagType !== entity.emailTagType
            || original.placementTagReplacement !== entity.placementTagReplacement
            || original.listTagReplacement !== entity.listTagReplacement
            || original.lceeKeyReplacement !== entity.lceeKeyReplacement
            || original.lceeTagReplacement !== entity.lceeTagReplacement;
    };
    BulkEditEspComponent.prototype.resetEntity = function (index) {
        // If the card isn't dirty, then nothing to reset
        if (!this.dirtyEntitiesByIndexMap$.getValue()[index]) {
            return;
        }
        var original = this.original(index);
        var entity = this.entities[index];
        entity.esp = original.esp;
        entity.espName = original.espName;
        entity.emailTagReplacement = original.emailTagReplacement;
        entity.emailTagType = original.emailTagType;
        entity.placementTagReplacement = original.placementTagReplacement;
        entity.listTagReplacement = original.listTagReplacement;
        entity.lceeKeyReplacement = original.lceeKeyReplacement;
        entity.lceeTagReplacement = original.lceeTagReplacement;
        var card = this.cards.toArray()[index];
        card.entity = entity;
        card.loadEspSettings(entity, true);
        this.updateDirtyCheck(index, false);
    };
    BulkEditEspComponent.prototype.reset = function () {
        var _this = this;
        this.master.reset();
        this.masterCard.entity = this.master;
        this.masterCard.loadEspSettings(this.master, true);
        this.entities.forEach(function (entity, index) { return _this.resetEntity(index); });
    };
    BulkEditEspComponent.prototype.applyPublisherSettingsToAll = function () {
        var _this = this;
        this.master.reset();
        this.masterCard.entity = this.master;
        this.masterCard.loadEspSettings(this.master, true);
        this.entities.forEach(function (entity, index) { return _this.applyPublisherSetting(index); });
    };
    BulkEditEspComponent.prototype.applyPublisherSetting = function (index) {
        var entity = this.entities[index];
        var card = this.cards.toArray()[index];
        card.entity = entity;
        card.loadEspSettings(entity.publisherObj, true);
        this.updateDirtyCheck(index, true);
    };
    BulkEditEspComponent.prototype.original = function (index) {
        return this._originals[index];
    };
    Object.defineProperty(BulkEditEspComponent.prototype, "entities", {
        get: function () {
            return this._entities;
        },
        set: function (entities) {
            this._entities = entities;
            this._originals = entities.map(function (entity) { return entity.clone(); });
        },
        enumerable: true,
        configurable: true
    });
    return BulkEditEspComponent;
}());
export { BulkEditEspComponent };
var MasterEspFieldOverride = /** @class */ (function (_super) {
    tslib_1.__extends(MasterEspFieldOverride, _super);
    function MasterEspFieldOverride() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MasterEspFieldOverride.prototype.reset = function () {
        this.esp = null;
        this.espName = null;
        this.emailTagReplacement = null;
        this.emailTagType = null;
        this.placementTagReplacement = null;
        this.listTagReplacement = null;
        this.lceeKeyReplacement = null;
        this.lceeTagReplacement = null;
    };
    MasterEspFieldOverride.prototype.isDirty = function () {
        return this.esp !== null
            || this.emailTagReplacement !== null
            || this.emailTagType !== null
            || this.placementTagReplacement !== null
            || this.listTagReplacement !== null
            || this.lceeKeyReplacement !== null
            || this.lceeTagReplacement !== null;
    };
    MasterEspFieldOverride.prototype.serialize = function () {
        return _super.prototype.serialize.call(this);
    };
    return MasterEspFieldOverride;
}(Model));
