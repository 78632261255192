import * as tslib_1 from "tslib";
import { Model } from './model';
var ESP = /** @class */ (function (_super) {
    tslib_1.__extends(ESP, _super);
    function ESP() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ESP.prototype.serialize = function () {
        return JSON.stringify(this);
    };
    Object.defineProperty(ESP.prototype, "entity", {
        get: function () {
            return 'ESP';
        },
        enumerable: true,
        configurable: true
    });
    return ESP;
}(Model));
export { ESP };
export var EspProperties = {
    userEmail: {
        label: 'e',
        key: 'email',
        placeholder: '{INSERT_EMAIL_ID}',
        info: "A parameter that will populate a user's email address. LiveIntent always hashes raw emails before it reaches our servers."
    },
    userMd5: {
        label: 'md5',
        key: 'md5',
        placeholder: '{INSERT_MD5_ID}',
        info: "A parameter that will populate the MD5 hash of a user's email address."
    },
    userSha1: {
        label: 'sha1',
        key: 'sha1',
        placeholder: '{INSERT_SHA1_ID}',
        info: "A parameter that will populate the SHA-1 hash of a user's email address."
    },
    userSha2: {
        label: 'sha2',
        key: 'sha2',
        placeholder: '{INSERT_SHA2_ID}',
        info: "A parameter that will populate the SHA-2 hash of a user's email address."
    },
    userUniqueId: {
        label: 'u',
        key: 'uniqueId',
        placeholder: '{INSERT_U_ID}',
        info: "A parameter that will populate an ESP defined UniqueID for each user."
    },
    placement: {
        label: 'p',
        placeholder: '{INSERT_PLACEMENT_ID}',
        info: "A parameter that will populate a unique value for that specific email send. Date stamps or campaign IDs are commonly used."
    },
    list: {
        label: 'li',
        placeholder: '{INSERT_LIST_ID}',
        info: "An optional parameter that populates an identifier for an email list."
    },
    lcee: {
        label: 'lctg',
        placeholder: '{INSERT_LCEE_ID}',
        info: "A parameter that will act as a stable user identifier between email and the web-based LiveConnect tag."
    },
};
export var EspUserMacroTagTypeMap = {
    email: 'userEmail',
    md5: 'userMd5',
    sha1: 'userSha1',
    sha2: 'userSha2',
    uniqueId: 'userUniqueId',
    unique: 'userUniqueId'
};
export var Tag;
(function (Tag) {
    Tag["USER"] = "user";
    Tag["PLACEMENT"] = "placement";
    Tag["LIST"] = "list";
    Tag["LCEE_KEY"] = "lceeKey";
    Tag["LCEE"] = "lcee";
})(Tag || (Tag = {}));
