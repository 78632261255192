import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Blueprint, Creative, LineItem, NativeBlueprint } from "app/shared/models";
import { IdService } from 'app/core/id.service';
import { AvailableActionsService } from "app/shared/helpers/available-actions.service";
import { BlueprintRepository, CreativeRepository, LineItemRepository } from "app/core/repositories";
import { ReplaySubject } from "rxjs";
import { mergeMap, tap } from "rxjs/operators";
import { TableQuery } from "app/shared/elements/async-table";
import { FrontendTableHelper } from "app/shared/helpers/frontend-table-helper";
import { ConfirmDialogComponent } from "app/shared/elements/confirm-dialog";
import { DownloadHelper } from "app/shared/helpers/download-helper";

@Component({
    selector: 'linked-blueprints',
    templateUrl: './linked-blueprints.html',
    styleUrls: ['./linked-blueprints.styl']
})
export class LinkedBlueprintsComponent implements OnInit {
    @Input() adSlot;
    @Input() detailsOnly: boolean = false;
    @Input() set nativeBlueprints(value: NativeBlueprint[]) {
        if (value) {
            this.blueprintIds = value.map(bp => bp.blueprintId);
        }

        this.nativeBlueprints$.next(value);
    }
    @Output() unlinkBlueprint = new EventEmitter<number>();
    @Output() blueprintUpdated = new EventEmitter<any>();
    @ViewChild('unlinkedBlueprintDialog', { static: true }) private unlinkedBlueprintDialog: ConfirmDialogComponent

    tableHelper = new FrontendTableHelper<any>(() => this.adapter());
    nativeBlueprints$ = new ReplaySubject<NativeBlueprint[]>(1);
    lineItems = [];
    blueprintIds = [];
    mismatchingLineItems = [];

    constructor(
        public blueprintRepository: BlueprintRepository,
        public lineItemRepository: LineItemRepository,
        public creativeRepository: CreativeRepository,
        public id: IdService,
        public availActions: AvailableActionsService
    ){};

    ngOnInit(): void {
        this.lineItemRepository.asyncSearchByAdSlot({
            "conditions": [
                {
                    "field": "adSlot",
                    "value": this.adSlot.id,
                    "operator": "eq"
                }
            ]
        }).pipe(
            tap(res => {
                this.lineItems = res.items.map(lineItem => {
                    return { lineItem: lineItem, creatives: [] }
                })
            }),
            // Flatten array to observable array
            mergeMap(res => res.items),
            // Fetch creatives
            mergeMap((lineItem: LineItem) => this.creativeRepository.searchByLineItem(lineItem.id)))
            .subscribe((creatives: Creative[]) => {
                if (creatives.length) {
                    const lineItem = this.lineItems.find(lineItemObject => lineItemObject.lineItem.id === creatives[0].strategyId);
                    const lineItemIndex = this.lineItems.indexOf(lineItem);
                    lineItem['creatives'] = creatives;
                    this.lineItems[lineItemIndex] = { ...lineItem };
                }
            });
    }

    private adapter() {
        return this.nativeBlueprints$.pipe(
            mergeMap(nativeBlueprints => {
                const blueprintIds = [];
                if (nativeBlueprints != null) {
                    nativeBlueprints.forEach(nb => blueprintIds.push(nb.blueprintId));
                }

                const payload = {
                    conditions: [{
                        field: 'id',
                        value: blueprintIds,
                        operator: 'eq'
                    }]
                };

                return this.blueprintRepository.search(payload);
            })
        );
    }

    attachBlueprintAssets(nativeBlueprints: any[], blueprints: Blueprint[]): Blueprint[] {
        const attachedBlueprintAssets = [];
        const nativeBlueprintMap = (nativeBlueprints || []).reduce((obj, val) => {obj[val.blueprintId] = val; return obj;}, {});
        blueprints.map(blueprint => {
            blueprint.assets = nativeBlueprintMap[blueprint.id].native.assets || [];
            attachedBlueprintAssets.push(blueprint);
        });

        return attachedBlueprintAssets;
    }

    getBlueprintAllowExchange(id: Number): boolean {
        if (this.adSlot && this.adSlot.nativeBlueprints) {
            const blueprint = this.adSlot.nativeBlueprints.find((blueprint) => blueprint.blueprintId === id)
            return blueprint? this.booleanize(blueprint.allowExchange) : false;
        }
        return false;
    }

    getBlueprintAllowRtb(id: Number): boolean {
        if (this.adSlot && this.adSlot.nativeBlueprints) {
            const blueprint = this.adSlot.nativeBlueprints.find((blueprint) => blueprint.blueprintId === id)
            return blueprint? this.booleanize(blueprint.allowRtb) : false;
        }
        return false;
    }

    updateBlueprint(blueprintId: number, property: string, value) {
        const blueprintData = {
            blueprintId: blueprintId
        };

        blueprintData[property] = value;
        this.blueprintUpdated.next(blueprintData)
    }

    disconnect(blueprintId) {
        this.mismatchingLineItems = [];

        this.lineItems.filter(lineItemObj => !lineItemObj.creatives.some(
            // Does the creative match any of the remaining blueprints?
            creative => this.blueprintIds.filter(bp => bp !== blueprintId).indexOf(creative.blueprintId) !== -1
        )).map(lineItemObject => lineItemObject.creatives.map((creative: Creative) => {
            // Did creative match the removed blueprint id? - Was the creative serving?
            if (creative.blueprintId == blueprintId) {
                this.mismatchingLineItems.push({
                    "Line Item name": lineItemObject.lineItem.name,
                    "Line Item id": lineItemObject.lineItem.refId,
                    "Start date": lineItemObject.lineItem.startDate,
                    "End date": lineItemObject.lineItem.endDate,
                    "Creative id": creative.refId,
                    "Creative name": creative.name,
                });
            }
        }));

        if (this.mismatchingLineItems.length) {
            this.unlinkedBlueprintDialog.confirmed.subscribe(() => {
                this.unlinkBlueprint.next(blueprintId);
                this.unlinkedBlueprintDialog.close();
            });
            this.unlinkedBlueprintDialog.open('disconnect-blueprint');
        } else {
            this.unlinkBlueprint.next(blueprintId);
        }
    }

    query(tableQuery: TableQuery) {
        this.tableHelper.search([tableQuery]);
    }

    downloadFile() {
        DownloadHelper.downloadAsCSV(this.mismatchingLineItems, "report");
    }

    booleanize(value: any) {
        if (value === 'true') {
            return true;
        }

        return value;
    }
}
