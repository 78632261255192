import moment from 'moment';

import { Status } from './status';
import { MidasActivity } from './midas-activity';

export class LiveConnectStatus {
    /**
     * Recent activity.
     */
    private activity: MidasActivity;

    /**
     * Create a new instance.
     */
    constructor(activity: MidasActivity) {
        this.activity = activity;
    }

    /**
     * Get the last seen time.
     */
    get lastSeen() {
        if (!this.hasActivity) {
            return null;
        }

        return moment.utc(this.activity.events[0].__time);
    }

    /**
     * Get the last time it was seen as an estimated interval.
     */
    get lastSeenInterval() {
        if (!this.lastSeen) {
            return null;
        }

        return this.lastSeen.fromNow();
    }

    /**
     * A textual representation of the status.
     */
    get title() {
        switch (this.status) {
            case Status.ACTIVE:
            case Status.WARNING:
                return 'Active';
            case Status.INACTIVE:
                return 'Inactive';
            default:
                return 'Unknown';
        }
    }

    /**
     * The css class used for showing the status as a circle icon.
     */
    get circleClass() {
        switch (this.status) {
            case Status.ACTIVE:
                return 'circle-success';
            case Status.WARNING:
                return 'circle-warning';
            default:
                return 'circle-inactive';
        }
    }

    /**
     * The current status.
     */
    get status() {
        if (!this.lastSeen) {
            return Status.UNKNOWN;
        }

        const dayDiff = moment().diff(this.lastSeen, 'days');

        if (dayDiff <= 1) {
            return Status.ACTIVE;
        } else if (dayDiff <= 7) {
            return Status.WARNING;
        } else {
            return Status.INACTIVE;
        }
    }

    /**
     * Check if the pixel has activity.
     */
    private get hasActivity() {
        return this.activity && Array.isArray(this.activity.events) && this.activity.events.length > 0;
    }
}
