/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../filter-select/option.component.ngfactory";
import * as i2 from "@angular/forms";
import * as i3 from "../filter-select/option.component";
import * as i4 from "../button/button.component.ngfactory";
import * as i5 from "../button/button.component";
import * as i6 from "@angular/common";
import * as i7 from "../select-dropdown/select-dropdown.component.ngfactory";
import * as i8 from "../select-dropdown/select-dropdown.component";
import * as i9 from "../../../core/id.service";
import * as i10 from "./paginator.component";
import * as i11 from "@angular/router";
import * as i12 from "../table/table-url.service";
var styles_PaginatorComponent = [];
var RenderType_PaginatorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PaginatorComponent, data: {} });
export { RenderType_PaginatorComponent as RenderType_PaginatorComponent };
function View_PaginatorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "option", [], null, null, null, i1.View_OptionComponent_0, i1.RenderType_OptionComponent)), i0.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i0.ElementRef, i0.Renderer2, [8, null]], { value: [0, "value"] }, null), i0.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i0.ElementRef, i0.Renderer2, [8, null]], { value: [0, "value"] }, null), i0.ɵdid(3, 49152, [[1, 4]], 0, i3.OptionComponent, [i0.ElementRef], { value: [0, "value"] }, null), (_l()(), i0.ɵted(4, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.value; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.value; _ck(_v, 2, 0, currVal_1); var currVal_2 = _v.context.$implicit.value; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_3 = _v.context.$implicit.label; _ck(_v, 4, 0, currVal_3); }); }
function View_PaginatorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "spacer--xlarge-right spacer--xlarge-left"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " - ", " of ", ""])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.firstVisible; var currVal_1 = _co.lastVisible; var currVal_2 = i0.ɵunv(_v, 1, 2, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), _co.total)); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_PaginatorComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "button", [["class", "link link-grey spacer--large-right"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.first() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ButtonComponent_0, i4.RenderType_ButtonComponent)), i0.ɵdid(1, 49152, null, 0, i5.ButtonComponent, [[8, null], i0.ElementRef], null, null), (_l()(), i0.ɵted(-1, 0, ["\n            "])), (_l()(), i0.ɵeld(3, 0, null, 0, 0, "i", [["class", "fas fa-angle-double-left fa-lg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, 0, ["\n        "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hasPrevious; _ck(_v, 0, 0, currVal_0); }); }
function View_PaginatorComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "button", [["class", "link link-grey spacer--large-left"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.last() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ButtonComponent_0, i4.RenderType_ButtonComponent)), i0.ɵdid(1, 49152, null, 0, i5.ButtonComponent, [[8, null], i0.ElementRef], null, null), (_l()(), i0.ɵted(-1, 0, ["\n            "])), (_l()(), i0.ɵeld(3, 0, null, 0, 0, "i", [["class", "fas fa-angle-double-right fa-lg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, 0, ["\n        "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hasNext; _ck(_v, 0, 0, currVal_0); }); }
export function View_PaginatorComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i6.DecimalPipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 44, "div", [["class", "flex flex-end flex-align-center padding-large--left padding-large--right padding--top"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(3, 0, null, null, 17, "div", [["class", "spacer--xlarge-right"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "spacer--large-right"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Rows per page:"])), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(8, 0, null, null, 11, "select-dropdown", [["class", "inline-block"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.pageSize = $event) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = (_co.reset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_SelectDropdownComponent_0, i7.RenderType_SelectDropdownComponent)), i0.ɵdid(9, 114688, null, 2, i8.SelectDropdownComponent, [i0.ElementRef, i0.ChangeDetectorRef, i9.IdService, [8, null], [8, null]], null, null), i0.ɵqud(603979776, 1, { tplOptions: 1 }), i0.ɵqud(603979776, 2, { groups: 1 }), i0.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i8.SelectDropdownComponent]), i0.ɵdid(13, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i0.ɵdid(15, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i0.ɵted(-1, null, ["\n            "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginatorComponent_1)), i0.ɵdid(18, 278528, null, 0, i6.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginatorComponent_2)), i0.ɵdid(23, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(25, 0, null, null, 19, "div", [["class", "spacer--xlarge-left"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginatorComponent_3)), i0.ɵdid(28, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(30, 0, null, null, 4, "button", [["class", "link link-grey spacer--xlarge-right"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.previous() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ButtonComponent_0, i4.RenderType_ButtonComponent)), i0.ɵdid(31, 49152, null, 0, i5.ButtonComponent, [[8, null], i0.ElementRef], null, null), (_l()(), i0.ɵted(-1, 0, ["\n            "])), (_l()(), i0.ɵeld(33, 0, null, 0, 0, "i", [["class", "fas fa-angle-left fa-lg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, 0, ["\n        "])), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(36, 0, null, null, 4, "button", [["class", "link link-grey spacer--xlarge-left"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ButtonComponent_0, i4.RenderType_ButtonComponent)), i0.ɵdid(37, 49152, null, 0, i5.ButtonComponent, [[8, null], i0.ElementRef], null, null), (_l()(), i0.ɵted(-1, 0, ["\n            "])), (_l()(), i0.ɵeld(39, 0, null, 0, 0, "i", [["class", "fas fa-angle-right fa-lg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, 0, ["\n        "])), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginatorComponent_4)), i0.ɵdid(43, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 9, 0); var currVal_7 = _co.pageSize; _ck(_v, 13, 0, currVal_7); var currVal_8 = _co.pageSizeOptions; _ck(_v, 18, 0, currVal_8); var currVal_9 = _co.showTotal; _ck(_v, 23, 0, currVal_9); var currVal_10 = _co.showFirstAndLast; _ck(_v, 28, 0, currVal_10); var currVal_13 = _co.showFirstAndLast; _ck(_v, 43, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 15).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 15).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 15).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 15).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 15).ngClassValid; var currVal_5 = i0.ɵnov(_v, 15).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 15).ngClassPending; _ck(_v, 8, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_11 = !_co.hasPrevious; _ck(_v, 30, 0, currVal_11); var currVal_12 = !_co.hasNext; _ck(_v, 36, 0, currVal_12); }); }
export function View_PaginatorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "paginator", [], null, null, null, View_PaginatorComponent_0, RenderType_PaginatorComponent)), i0.ɵdid(1, 245760, null, 0, i10.PaginatorComponent, [i11.ActivatedRoute, i12.TableURLService, [8, null], [8, null], [8, null]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaginatorComponentNgFactory = i0.ɵccf("paginator", i10.PaginatorComponent, View_PaginatorComponent_Host_0, { total: "total", cursor: "cursor" }, { query: "query" }, []);
export { PaginatorComponentNgFactory as PaginatorComponentNgFactory };
