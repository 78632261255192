import { Model } from './model';

export enum IdPrefix {
    LIN = 'lin',
    LIN_DSP = 'lin_dsp'
}

enum DeviceType {
    DESKTOP = 'Desktop',
    MOBILE = 'Mobile'
}

export class Blueprint extends Model {
    id: string;
    refId: number;
    blueprintId: number;
    name: string;
    width: number;
    maxHeight: number;
    minHeight: number;
    isDesktopDynamic: boolean;
    mobileWidth: number;
    mobileMinHeight: number;
    mobileMaxHeight: number;
    isMobileDynamic: boolean;
    desktopMainImageWidth?: number;
    desktopMainImageHeight?: number;
    mobileMainImageWidth?: number;
    mobileMainImageHeight?: number;
    desktopAspectRatio?: string;
    mobileAspectRatio?: string;
    mediaGroupId: number;
    ifaasId: string;
    ifaasUrl: string;
    desktopHtml: string;
    mobileHtml: string;
    displayName: string;
    adSlots: number[];
    creatives: number[];
    prefix?: IdPrefix;
    modifiedByUser: any;
    isCustom: boolean;
    assets: any[];
    modified: string;
    adSlotId?: string;


    private static smallThumbnailFactor = 0.08;
    private static largeThumbnailFactor = 0.6;
    private static previewFactor = 0.5;
    public static readonly nativeTypes = ['text_ad', 'in_feed', 'product_listing', 'recommendation_widget'];

    thumbnailFactor(large: boolean): number {
        return large ? Blueprint.largeThumbnailFactor : Blueprint.smallThumbnailFactor;
    }

    thumbnailWidth(large: boolean): number {
        return Math.floor(this.width * this.thumbnailFactor(large));
    }

    thumbnailHeight(large: boolean): number {
        return Math.floor(this.maxHeight * this.thumbnailFactor(large));
    }

    previewWidth(display?: string) : number {
      if(display === 'mobile') return Math.floor(this.mobileWidth * Blueprint.previewFactor);
      return Math.floor(this.width * Blueprint.previewFactor);
    }

    previewHeight(display?: string) : number {
      if(display === 'mobile') return Math.floor(this.mobileMaxHeight * Blueprint.previewFactor);
      return Math.floor(this.maxHeight * Blueprint.previewFactor);
    }

    appendPrefix(ifaasId: string) {
        const re = /^lin_/gi;
        const replaceRe = /\s/g;
        if (!re.test(ifaasId)) {
            return ''.concat('lin_', ifaasId).toLowerCase().replace(replaceRe, '_');
        }

        return ifaasId.toLowerCase().replace(replaceRe, '_');
    }

    public serialize(): string {

        delete this._isClone;

        return JSON.stringify(this);
    }

    get entity() {
        return 'Blueprint';
    }

    get aspectRatio() {
        if (this.desktopAspectRatio && this.mobileAspectRatio && this.desktopAspectRatio !== this.mobileAspectRatio) {
            return `${this.desktopAspectRatio} (${DeviceType.DESKTOP})\n${this.mobileAspectRatio} (${DeviceType.MOBILE})`;
        }

        if (this.desktopAspectRatio) {
            return this.desktopAspectRatio;
        }

        if (this.mobileAspectRatio) {
            return `${this.mobileAspectRatio} (${DeviceType.MOBILE} Only)`;
        }

        return 'N/A';
    }
}

export class BlueprintSettings extends Model {
    desktop: BlueprintAssetsModel;
    mobile: BlueprintAssetsModel;
    ifaasHtml: string;
    ifaasMobileHtml: string;
    desktopLayout: string;
    mobileLayout: string;
    ifaasDesktopId: string;
    ifaasMobileId: string;

    constructor() {
        super();
        for (const device of BlueprintCoords) {
            if (!this[device.modelKey]) {
                this[device.modelKey] = {
                    sponsoredBy: null,
                    description: null,
                    headline: null,
                    callToAction: null,
                    wrapper: null,
                    image: null,
                    isFixed: null,
                    isSynced: true,
                    hasBorder: false,
                };
            }

            for (const prop of Object.keys(BlueprintProperties)) {
                const defaultHtml = BlueprintProperties[prop].html;
                const defaultIfaasHTML = BlueprintProperties[prop].macro;
                if (!this[device.modelKey][prop]) {
                    this[device.modelKey][prop] = {
                        css: new BlueprintCssOptions(),
                        defaultCss: new BlueprintCssOptions(),
                        internalHtml: null,
                        ifaasHtml: null,
                        internalStyle: null,
                        ifaasStyle: null,
                    };
                }

                this[device.modelKey][prop].internalHtml = this[device.modelKey][prop].internalHtml || defaultHtml;
                this[device.modelKey][prop].ifaasHtml = this[device.modelKey][prop].ifaasHtml || defaultIfaasHTML;
            }
        }
    }

    public serialize(): string {

        delete this._isClone;

        return JSON.stringify(this);
    }

    get entity() {
        return 'BlueprintSettings';
    }
}

interface BlueprintAssetsModel {
    sponsoredBy: BlueprintModel;
    headline: BlueprintModel;
    description: BlueprintModel;
    callToAction: BlueprintModel;
    wrapper: BlueprintModel;
    image: BlueprintModel;
    isFixed: boolean;
    isSynced: boolean;
    hasBorder: boolean;
}
interface BlueprintModel {
    css: BlueprintCssOptions;
    defaultCss: BlueprintCssOptions;
    internalHtml: string;
    internalStyle?: string;
    ifaasHtml: string;
    ifaasStyle?: string;
}

export const BlueprintCoords = [{
    label: 'Desktop',
    modelKey: 'desktop'
}, {
    label: 'Mobile',
    modelKey: 'mobile'
}];

export const BlueprintProperties = {
    sponsoredBy: {
        html: 'Sponsored By: Advertisers Name Is Here',
        label: 'Sponsored By',
        modelKey: 'sponsoredBy',
        class: 'sponsoredby',
        macro: ' <#if sb??> Sponsored by ${sb!} <#else> Sponsored Content </#if>'
    },
    headline: {
        html: 'An Advertisers Headline Will Go Here To Promote A Product When You Open To Exchange Demand',
        label: 'Headline',
        modelKey: 'headline',
        class: 'title',
        macro: '${t!}'
    },
    description: {
        html: 'Description For An Ad Will Go Here. Be Mindful Of Advertisers Abiding By IAB Recommended Character Counts When Accepting Exchange Demand, Typically 200 Characters Including Spaces For The Body Of Ad.',
        label: 'Description',
        modelKey: 'description',
        class: 'description',
        macro: '${d!}'
    },
    callToAction: {
        html: 'Call To Action Shown',
        label: 'Call to action',
        modelKey: 'callToAction',
        class: 'cta',
        macro: '${cta!}'
    },
    image: {
        html: null,
        label: 'Image',
        modelKey: 'image',
        class: 'image',
        macro: '<div class="image" style="background-image: url(${i!});" />'
    },
    wrapper: {
        html: null,
        label: 'Wrapper',
        modelKey: 'wrapper',
        class: 'wrapper',
        macro: null
    },
    global: {
        html: null,
        label: 'Global',
        modelKey: 'global',
        class: 'global',
        macro: null
    }
};

export type BlueprintLayout = {
    ifaasDesktopId: string,
    ifaasMobileId: string,
    direction: string,
    image: string
}

export const BlueprintLayouts = {
    left: {
        ifaasDesktopId: 'lin_master_wysiwyg_d1',
        ifaasMobileId: 'lin_master_wysiwyg_m1',
        direction: 'left',
        class: 'layout-left',
        image: '/assets/images/img_left.svg',
        imageSelected: '/assets/images/img_left_selected.svg'
    },
    right: {
        ifaasDesktopId: 'lin_master_wysiwyg_d2',
        ifaasMobileId: 'lin_master_wysiwyg_m2',
        direction: 'right',
        class: 'layout-right',
        image: '/assets/images/img_right.svg',
        imageSelected: '/assets/images/img_right_selected.svg'
    },
    top: {
        ifaasDesktopId: 'lin_master_wysiwyg_d3',
        ifaasMobileId: 'lin_master_wysiwyg_m3',
        direction: 'top',
        class: 'layout-top',
        image: '/assets/images/img_top.svg',
        imageSelected: '/assets/images/img_top_selected.svg'
    },
    text: {
        ifaasDesktopId: 'lin_master_wysiwyg_d4',
        ifaasMobileId: 'lin_master_wysiwyg_m4',
        direction: 'text',
        class: 'layout-text',
        image: '/assets/images/img_none.svg',
        imageSelected: '/assets/images/img_none_selected.svg'
    }
};

export class BlueprintCssOptions {
    'font-family': string;
    'font-weight': string;
    'font-size': number;
    'color': string;

    'text-transform': string;
    'text-decoration': string;

    'line-height': number;
    'text-align': string;

    'margin': string;
    'margin-left': number;
    'margin-right': number;
    'margin-top': number;
    'margin-bottom': number;

    'padding': string;
    'padding-left': number;
    'padding-right': number;
    'padding-top': number;
    'padding-bottom': number;

    'border-style': string;
    'border-width': number;
    'border-radius': number;
    'border-color': string;
    'background-color': string;

    'width': number;
    'height': number;
    'max-height': number;
    'min-height': number;

    strike: boolean;
    underline: boolean;
    bold: boolean;
    italic: boolean;

    cssState: {
        font: {
            capitalize: boolean,
            uppercase: boolean,
            lowercase: boolean,
        },
        alignment: {
            left: boolean,
            center: boolean,
            right: boolean,
            justify: boolean
        }
    };

    constructor() {

        this['font-family'] = null;
        this['font-weight'] = null; // bold | normal
        this['font-style'] = null; // italic | normal
        this['font-size'] = null;
        this['color'] = null;

        this['text-transform'] = null; // capitalize | uppercase | lowercase
        this['text-decoration'] = null; // underline | line-through | underline line-through

        this['line-height'] = null;
        this['text-align'] = null; // left, center, right, justify

        this['margin'] = null; // top right bottom left | top right,left bottom | (top bottom), (right left)
        this['margin-left'] = null;
        this['margin-right'] = null;
        this['margin-top'] = null;
        this['margin-bottom'] = null;

        this['padding'] = null; // top right bottom left | top right,left bottom | (top bottom), (right left)
        this['padding-left'] = null;
        this['padding-right'] = null;
        this['padding-top'] = null;
        this['padding-bottom'] = null;

        this['border'] = null; // border-width border-style border-color
        this['border-style'] = null;
        this['border-width'] = null;
        this['border-radius'] = null;
        this['border-color'] = null;
        this['background-color'] = null;

        this['height'] = null;
        this['width'] = null;
        this['max-height'] = null;
        this['min-height'] = null;

        this.strike = false;
        this.underline = false;
        this.bold = false;
        this.italic = false;

        this.cssState = {
            font: {
                capitalize: false,
                uppercase: false,
                lowercase: false,
            },
            alignment: {
                left: false,
                center: false,
                right: false,
                justify: false
            }
        };

    }
}
