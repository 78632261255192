import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { EMPTY, Observable, forkJoin, of } from 'rxjs';
import { delay, expand, map, mergeMap, tap } from 'rxjs/operators';

import { BulkOperationStatusToastService, BulkResponseProcessor, IdService, NotificationsService } from 'app/core';
import { ErrorHelper } from 'app/core/errors';
import { CreativeRepository } from 'app/core/repositories';
import { BulkOperationRepository } from 'app/core/repositories/bulk-operation-repository.service';
import { SensitiveCategoryRepository } from 'app/core/repositories/sensitive-category-repository.service';
import { BulkEditLightboxComponent } from 'app/shared/components/bulk-edit-lightbox';
import { Creative } from 'app/shared/models';
import { BulkOperationRequest, BulkOperationStatusResponse, Operation, OperationMethod } from 'app/shared/models/bulk-request';

@Component({
    selector: 'creative-bulk-edit',
    templateUrl: './creative-bulk-edit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreativeBulkEditComponent {
    @ViewChild(BulkEditLightboxComponent, { static: true }) lightbox: BulkEditLightboxComponent;
    @Output() completed = new EventEmitter();

    constructor(
        private creativeRepository: CreativeRepository,
        private notifications: NotificationsService,
        public id: IdService,
        private sensitiveCategoryRepo: SensitiveCategoryRepository,
        private bulkOperationRepo: BulkOperationRepository,
        private bulkOperationStatusToast: BulkOperationStatusToastService,

    ) { }

    successItems = [];
    failureItems = [];

    open(creatives: Creative[], field: string = null) {
        this.lightbox.initiate();
        this.lightbox.open(creatives, field, 'load');

        forkJoin(
            creatives.map(creative => this.creativeRepository.get(creative.id).pipe(
                mergeMap(detail => {
                    return forkJoin(of(detail), this.sensitiveCategoryRepo.searchByCreativeId(creative.refId));
                }),
                map(([detail, sensitiveCategories]) => {
                    detail.sensitiveCategories = sensitiveCategories.map(category => category.id)

                    return detail;
                }),
                tap(() => this.lightbox.postProgress())
            ))
        ).subscribe(
            details => this.lightbox.open(details, field),
            () => this.handleError()
        );
    }

    close() {
        this.lightbox.close();
    }

    public submit({ changed }: { changed: Creative[] }): void {
        this.lightbox.initiate();

        const LSDRequests = changed.map(creative => new Operation(
            OperationMethod.Post,
            '/creative/' + creative.refId,
            JSON.parse(creative.serialize()),
            'lsd:' + creative.refId.toString()
        ));

        const MCSRequests = changed.map(creative => new Operation(
            OperationMethod.Put,
            '/mc/creatives/li-' + creative.refId + '/sensitive-category-labels',
            { attributes: creative.sensitiveCategories || [] },
            'mcs:' + creative.refId.toString()
        ));

        forkJoin([
            this.bulkOperationRepo.sendRequest(new BulkOperationRequest([...LSDRequests, ...MCSRequests]))
        ]).subscribe(
            () => this.createBulkOperationStatusToast(),
            () => this.handleSaveError()
        );
    }

    private createBulkOperationStatusToast(): void {
        this.bulkOperationStatusToast.fetchStatusData();
        this.completed.emit();
        this.close();

        return;
    }

    private handleError() {
        this.notifications.error('An error has occurred.');
    }

    private handleSaveError(): void {
        this.lightbox.resetForm();
        this.handleError();
    }
}
