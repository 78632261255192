import { EventEmitter, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
export var CHECKBOX_CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return CheckboxComponent; }),
    multi: true
};
var CheckboxChange = /** @class */ (function () {
    function CheckboxChange() {
    }
    return CheckboxChange;
}());
export { CheckboxChange };
var CheckboxComponent = /** @class */ (function () {
    function CheckboxComponent() {
        this.change = new EventEmitter();
        this.id = '';
        this.disabled = false;
        this.tabindex = 0;
        this.name = null;
        this.onTouched = function () { };
        this._checked = false;
        this._indeterminate = false;
        this._controlValueAccessorChangeFn = function (value) { };
        this.hasFocus = false;
    }
    Object.defineProperty(CheckboxComponent.prototype, "checked", {
        get: function () {
            return this._checked;
        },
        set: function (checked) {
            if (checked !== this.checked) {
                this._checked = checked;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CheckboxComponent.prototype, "indeterminate", {
        get: function () {
            return this._indeterminate;
        },
        set: function (indeterminate) {
            this._indeterminate = indeterminate;
        },
        enumerable: true,
        configurable: true
    });
    CheckboxComponent.prototype.writeValue = function (value) {
        this.checked = !!value;
    };
    CheckboxComponent.prototype.registerOnChange = function (fn) {
        this._controlValueAccessorChangeFn = fn;
    };
    CheckboxComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    CheckboxComponent.prototype._emitChangeEvent = function () {
        var event = new CheckboxChange();
        event.source = this;
        event.checked = this.checked;
        this._controlValueAccessorChangeFn(this.checked);
        this.change.emit(event);
    };
    CheckboxComponent.prototype._onFocus = function () {
        this.hasFocus = true;
    };
    CheckboxComponent.prototype._onBlur = function () {
        this.hasFocus = false;
        this.onTouched();
    };
    CheckboxComponent.prototype._onChange = function (event) {
        event.stopPropagation();
        if (!this.disabled) {
            this.toggle();
            this._emitChangeEvent();
        }
    };
    CheckboxComponent.prototype._onClick = function (event) {
        event.stopPropagation();
    };
    CheckboxComponent.prototype.toggle = function () {
        this.checked = !this.checked;
    };
    return CheckboxComponent;
}());
export { CheckboxComponent };
