import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { BackendRepository } from './backend-repository';
import { Blueprint } from 'app/shared/models/blueprint';
import { map } from 'rxjs/operators';
import { StatusId } from 'app/shared/models/status';
var BlueprintRepository = /** @class */ (function (_super) {
    tslib_1.__extends(BlueprintRepository, _super);
    function BlueprintRepository(http) {
        return _super.call(this, http, '/blueprint', Blueprint) || this;
    }
    BlueprintRepository.prototype.basicSearch = function (mediaGroupId) {
        var _this = this;
        return this.consume(function (page) {
            return _this.http.post(_this.url("/search/blueprint"), { 'mediaGroupId': mediaGroupId, 'status': StatusId.ACTIVE_ID, 'page': page });
        });
    };
    BlueprintRepository.prototype.confirm = function (body) {
        var params = [this.path, 'confirm'];
        if (body.id) {
            params.push(body.id);
        }
        return this.http.post(this.url.apply(this, params), body.serialize());
    };
    BlueprintRepository.prototype.linkAdSlot = function (id, body) {
        return this.http
            .post(this.url('/blueprint/link-ad-slots', id), body)
            .pipe(map(function (response) { return response['output']; }));
    };
    BlueprintRepository.prototype.unlinkAdSlot = function (id, body) {
        return this.http
            .post(this.url('/blueprint/unlink-ad-slots', id), body)
            .pipe(map(function (response) { return response['output']; }));
    };
    BlueprintRepository.prototype.confirmAssets = function (id, body, creatives) {
        var payload = { "assets": body.map(function (_a) {
                var id = _a.id, version = _a.version, rest = tslib_1.__rest(_a, ["id", "version"]);
                return rest;
            }) };
        if (creatives) {
            payload['creatives'] = creatives;
        }
        return this.http.post(this.url('/blueprint/confirm-assets', id), payload);
    };
    return BlueprintRepository;
}(BackendRepository));
export { BlueprintRepository };
