/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./button.component";
var styles_ButtonComponent = [i0.styles];
var RenderType_ButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonComponent, data: {} });
export { RenderType_ButtonComponent as RenderType_ButtonComponent };
export function View_ButtonComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
export function View_ButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [], null, null, null, View_ButtonComponent_0, RenderType_ButtonComponent)), i1.ɵdid(1, 49152, null, 0, i2.ButtonComponent, [[8, null], i1.ElementRef], null, null)], null, null); }
var ButtonComponentNgFactory = i1.ɵccf("button", i2.ButtonComponent, View_ButtonComponent_Host_0, {}, {}, ["*"]);
export { ButtonComponentNgFactory as ButtonComponentNgFactory };
