import {Attribute, ChangeDetectionStrategy, Component} from "@angular/core";
import {HasDirtyCheck} from "app/shared/components/bulk-edit-lightbox";
import {Newsletter} from "app/shared/models";
import {IdService} from "app/core";
import {clone} from "app/core/utils";

import NEWSLETTER_TYPES from "app/shared/models/newsletter-types.json"
import { Flag, LaunchDarklyService } from "app/core/launch-darkly.service";
import { distinctUntilChanged } from "rxjs/operators";

@Component({
    selector: 'bulk-edit-newsletter-unique-ads',
    templateUrl: './bulk-edit-newsletter-unique-ads.component.html',
    styleUrls: ['./bulk-edit-newsletter-unique-ads.component.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkEditNewsletterUniqueAdsComponent implements HasDirtyCheck {
    _newsletters: Newsletter[] = [];
    private originals = new Map<number, Newsletter>();
    label: string;
    field: string;
    public rolloutUamEnhancements = false;

    _useDifferentSourcesMain: boolean;
    _adSourceMain: string;

    _useDifferentSourcesAdvertisersMain: boolean = false;
    _useDifferentSourcesExternalAdvertisersMain: boolean = false;

    protected readonly Newsletter = Newsletter;
    protected readonly NEWSLETTER_TYPES = NEWSLETTER_TYPES;

    readonly ADVERTISER_NEWSLETTER_TYPES = [
        NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER,
        NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER_DISPLAY,
        NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER_NATIVE
    ];

    readonly EXTERNAL_ADVERTISER_NEWSLETTER_TYPES = [
        NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.EXTERNAL_ADVERTISERS,
        NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.EXTERNAL_ADVERTISERS_DISPLAY,
        NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.EXTERNAL_ADVERTISERS_NATIVE
    ];

    constructor(
        @Attribute('label') label: string,
        public id: IdService,
        private launchDarklyService: LaunchDarklyService
    ) {
        this.label = label;
    }

    ngOnInit() {
        this.launchDarklyService
            .getVariation(Flag.RolloutUAMEnhancements)
            .pipe(distinctUntilChanged())
            .subscribe(rolloutUamEnhancements => this.rolloutUamEnhancements = rolloutUamEnhancements);
    }

    updateAllNewsletters(value: any) {
        this.entities.forEach(entity => {
            this.updateUniqueAds(entity, value);
        });
    }

    isNewsletterDirty(newsletter: Newsletter): boolean {
        let originalNewsletter = this.original(newsletter.refId);

        if ((!! originalNewsletter.sspControl) !== (!! newsletter.sspControl)) {
            return true;
        }

        let originalUniqueAds = originalNewsletter.sspControl ? originalNewsletter.sspControl.uniqueAds : "not_set";
        let newsletterUniqueAds = newsletter.sspControl ? newsletter.sspControl.uniqueAds : "not_set";

        return originalUniqueAds !== newsletterUniqueAds;
    }

    isDirty(): boolean {
        return this.entities.some(newsletter => this.isNewsletterDirty(newsletter));
    }

    isUniqueAdsEnabled(newsletter: Newsletter): boolean {
        let id = newsletter.refId;
        if (! newsletter.sspControl
            || ! newsletter.sspControl.uniqueAds
            || newsletter.sspControl.uniqueAds === NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.OFF
            || newsletter.sspControl.uniqueAds === NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.INHERIT) {
                return false;
        }

        return Object.values(NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS).includes(newsletter.sspControl.uniqueAds);
    }

    updateUniqueAds(newsletter: Newsletter, value: boolean|string|null): void {
        let currentUniqueAdsValue = newsletter.sspControl.uniqueAds || null;

        if (! newsletter.sspControl.uniqueAds) {
            newsletter.sspControl['uniqueAds'] = null;
        }
        if (typeof value === 'string') {
            newsletter.sspControl.uniqueAds = value;
        } else {
            newsletter.sspControl.uniqueAds = value
                ? NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER
                : NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.OFF;
        }
    }

    original(refId: number) {
        return this.originals.get(refId);
    }

    set entities(entities: Newsletter[]) {
        this._newsletters = entities;

        this.originals.clear();
        entities.forEach(newsletter => {
            this.originals.set(newsletter.refId, newsletter.clone());
        });
    }

    get entities() {
        return this._newsletters;
    }

    set useDifferentSourcesMain(value) {
        this._useDifferentSourcesMain = value;

        this._adSourceMain = value
            ? NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER
            : null;
        this.updateAllNewsletters(this._adSourceMain);
    }

    get useDifferentSourcesMain() {
        return this._useDifferentSourcesMain;
    }

    set adSourceMain(value) {
        this._adSourceMain = value;
        this.updateAllNewsletters(this._adSourceMain);
    }

    get adSourceMain() {
        return this._adSourceMain;
    }

    public get useDifferentSourcesAdvertisersMain(): boolean {
        return this.ADVERTISER_NEWSLETTER_TYPES.includes(this._adSourceMain);
    }

    public get useDifferentSourcesExternalAdvertisersMain(): boolean {
        return this.EXTERNAL_ADVERTISER_NEWSLETTER_TYPES.includes(this._adSourceMain);
    }

    public set useDifferentSourcesAdvertisersMain(value: boolean) {
        this._useDifferentSourcesAdvertisersMain = value;
        this._useDifferentSourcesExternalAdvertisersMain = null;

        this._adSourceMain = value
            ? NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.ADVERTISER
            : null;

        this.updateAllNewsletters(this._adSourceMain);
    }

    public set useDifferentSourcesExternalAdvertisersMain(value: boolean) {
        this._useDifferentSourcesExternalAdvertisersMain = value;
        this._useDifferentSourcesAdvertisersMain = null;

        this._adSourceMain = value
            ? NEWSLETTER_TYPES.SSP_CONTROL.UNIQUE_ADS.EXTERNAL_ADVERTISERS
            : null;

        this.updateAllNewsletters(this._adSourceMain);
    }

    revert(newsletter: Newsletter) {
        newsletter.sspControl.uniqueAds = clone(this.original(newsletter.refId).sspControl.uniqueAds);
    }

    revertAll() {
        this._useDifferentSourcesMain = false;
        this._adSourceMain = null;
        this.entities.forEach(newsletter => this.revert(newsletter));
    }

    isNewsletterRequiringUniqueAdvertiserSources(newsletter: Newsletter): boolean {
        return this.ADVERTISER_NEWSLETTER_TYPES.includes(newsletter.sspControl.uniqueAds);
    }

    isNewsletterRequiringUniqueExternalAdvertiserSources(newsletter: Newsletter): boolean {
        return this.EXTERNAL_ADVERTISER_NEWSLETTER_TYPES.includes(newsletter.sspControl.uniqueAds);
    }
}
