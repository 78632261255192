import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Audience, Pixel, WhitelistingRule } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { Observable } from 'rxjs';
import * as configs from 'app/platform/measurement/live-connect-manager/live-connect-setup/event-configs';
import { AudienceSearchResponse } from 'app/shared/models/audience-search-response';
import { map } from 'rxjs/operators';

@Injectable()
export class AudiencesApiRepository extends BackendRepository<AudienceSearchResponse> {
    constructor(http: HttpClient) {
        super(http, "/audiences-api", AudienceSearchResponse);
    }

    build(from?: {}): AudienceSearchResponse {
        const model = super.build(from);
        if (model.entries && Array.isArray(model.entries)) {
            // Have to convert each entry into an `Audience` object in this way
            // otherwise calling functions or getting properties of the 
            // `Audience` object will not work in runtime
            model.entries = model.entries.map(entry => new Audience(entry));
        }
        return model;
    }

    getAudienceByRuleId(ruleId: number, accountId: number): Observable<AudienceSearchResponse> {
        return this.http.post(
            this.url(this.path, `search/audiences?accountId=${String(accountId)}`),
            { 
                'matches': { 'ruleIdMatches': {'eq': ruleId} }
            }
        ).pipe(map(res => this.build(res)));
    }

    updateTracker(ruleId: number, audienceId: number, accountId: number, pixel: Pixel) {
        const payload = {
            ruleId,
            audienceId,
            accountId,
            name: pixel.name,
            pcc: pixel.expiration,
            pvc: pixel.pvcExpiration
        };
        return this.http.patch(
            this.url(this.path, 'trackers'),
            payload
        );
    }

    createEventTracker(
        pixel: Pixel,
        accountId: number,
        audience: Audience,
        appId: string,
        rule: WhitelistingRule
    ) {
        const body: any = {};
        if (rule.eventNameRegex === configs.addToCart.eventType) {
            body.Matches = {
                path: '$.event',
                value: rule.eventNameRegex
            };
        } else {
            body.And = {
                conditions: [
                    {
                        Matches: {
                            path: '$.event',
                            value: rule.eventNameRegex
                        }
                    },
                    {
                        Matches: {
                            path: '$.name',
                            value: rule.termRegex
                        }
                    }
                ]
            }
        }
        return this.http.post(
            this.url(this.path, 'eventtrackers'),
            this.getPayload(pixel, accountId, audience, appId, body)
        );
    }

    createUrlTracker(
        pixel: Pixel,
        accountId: number,
        audience: Audience,
        appId: string,
        rule: WhitelistingRule
    ) {
        const body: any = {};
        body.Matches = {
            path: '$.url',
            value: rule.pageRegex
        }
        return this.http.post(
            this.url(this.path, 'urltrackers'),
            this.getPayload(pixel, accountId, audience, appId, body)
        );
    }

    private getPayload(
        pixel: Pixel,
        accountId: number,
        audience: Audience,
        appId: string,
        body: any
    ) {
        const pcc = pixel.expirationInDays * 24 * 60 * 60;
        const pvc = pixel.pvcExpirationInHours * 60 * 60;
        return {
            conversionId: pixel.refId,
            pixelId: pixel.id,
            accountId,
            appIds: [appId],
            name: audience.name || pixel.name,
            attributionWindow: {
                pcc, 
                pvc
            },
            audienceTtl: audience.ttl,
            body
        };
    }
}
