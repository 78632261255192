import { Component, Output, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { PublisherRepository } from 'app/core/repositories';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'newsletter-uploader',
    templateUrl: './newsletter-uploader.html',
    styleUrls: ['./newsletter-uploader.styl']
})

export class NewsletterUploaderComponent {
    @Output() onUploaded: EventEmitter<boolean> = new EventEmitter<boolean>();

    public publisher;
    public excelTemplate: any = {};

    public errors: any = { parseErrors: [], others: [] };
    public _currentProgress = 0;
    public progressInterval = null;
    public isLoading: boolean = false;
    public FILE_EXCEED_LIMIT_CODE = 'E1287';

    constructor (
        public cdr: ChangeDetectorRef,
        public publisherRepository: PublisherRepository
    ) {}

    public init(publisher): void {
        this.publisher = publisher;
    }

    upload (files) {
        this.resetErrors();
        this.excelTemplate.file = files[0];
    }

    uploadError () {
        this.errors.others.push({
            title: 'Oh no! Upload failed',
            error: 'The selected file type is not supported. Please upload a XLSX file.'
        });
    }

    resetErrors () {
        this.errors.parseErrors.length = 0;
        this.errors.others.length = 0;
    }

    cancelFile () {
        if (this.errors.others.length > 0 || this.errors.parseErrors.length > 0) {
            this.resetErrors();
        }
        this.excelTemplate.file = false;
        this.excelTemplate.uploadedFileId = null;
        this.currentProgress = 0;
    }

    uploadFile () {
        this.publisherRepository.inventoryUpload({
            id: this.publisher.id,
            file: this.excelTemplate.file.dataURL.split(',')[1]
        }).toPromise()
        .then(() => this.onUploaded.emit(true))
        .catch((err: HttpErrorResponse) => {
            let resp = err.error;

            if (!(resp && resp.errors)) {
                this.errors.others.push(
                    {title: 'Oh no! Upload Failed', error: 'Please try again'}
                );
            } else {
                if (resp.errors[0].id === this.FILE_EXCEED_LIMIT_CODE) {
                    this.errors.others.push({
                        title: 'Oh no! Your file is too big.',
                        error: 'We can only accept up to 200 rows in a single file.'
                    });
                } else {
                    this.errors.parseErrors = resp.errors.map(error => this.errorForDisplay(error));
                }
            }
        })
        .then(() => {
            clearInterval(this.progressInterval);
            this.currentProgress = 100;
        });

        this.moveProgressBar();
    }

    errorForDisplay (error) {
        const errorDetails = error.details.split(', ');
        const entityErrors = errorDetails[0].split(': ');
        return `Invalid ${entityErrors[0]}: '${entityErrors[1]}', ${errorDetails[1]}, ${errorDetails[2]}`;
    }

    downloadTemplate () {
        window.location.href = '../assets/templates/publisher_questionnaire_template_version3.0.xlsx';
    }

    moveProgressBar () {
        this.progressInterval = setInterval( _ => {
            this.currentProgress = Math.random() * (99 - this.currentProgress) + this.currentProgress;
        }, 100);
    }

    set currentProgress (value: number) {
        this._currentProgress = value;
    }

    get currentProgress (): number {
        return this._currentProgress;
    }

    reset() {
        this.cancelFile();
        this.resetErrors();
    }
}
