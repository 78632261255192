import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { CookieSyncPartner } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { shareReplay, mergeMap } from 'rxjs/operators';
var CookieSyncPartnerRepository = /** @class */ (function (_super) {
    tslib_1.__extends(CookieSyncPartnerRepository, _super);
    function CookieSyncPartnerRepository(http) {
        return _super.call(this, http, '/cookie-sync-partner', CookieSyncPartner) || this;
    }
    CookieSyncPartnerRepository.prototype.getFromCache = function (id) {
        if (!this.cache) {
            this.cache = _super.prototype.all.call(this).pipe(shareReplay());
        }
        return this.cache.pipe(mergeMap(function (partner) { return partner.filter(function (partner) { return partner.id === id; }); }));
    };
    return CookieSyncPartnerRepository;
}(BackendRepository));
export { CookieSyncPartnerRepository };
