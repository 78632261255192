import { ChangeDetectorRef, EventEmitter, OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { LocationHelperService, IdService } from 'app/core';
import { DownloadHelper } from 'app/shared/helpers/download-helper';
var ZipTargetingComponent = /** @class */ (function () {
    function ZipTargetingComponent(cdr, locationHelper, id) {
        this.cdr = cdr;
        this.locationHelper = locationHelper;
        this.id = id;
        this.data = '';
        this.countryOptions = [];
        this.country = null;
        this.items = [];
        this.targetingOptions = [
            { key: 0, value: 'include', label: 'Target' },
            { key: 1, value: 'exclude', label: 'Exclude' }
        ];
        this.fileName = 'targeted_zip_codes';
        this._targeting = 'include';
        this._targetingChange = new EventEmitter();
        this.locationPromise = null;
        this.onchange = null;
    }
    ZipTargetingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.locationPromise = this.locationHelper.promise.then(function () { return _this.prepareCountries(); });
    };
    ZipTargetingComponent.prototype.writeValue = function (obj) {
        if (Array.isArray(obj)) {
            this.update(obj);
        }
    };
    ZipTargetingComponent.prototype.registerOnChange = function (fn) {
        this.onchange = fn;
    };
    ZipTargetingComponent.prototype.registerOnTouched = function (fn) { };
    ZipTargetingComponent.prototype.update = function (obj) {
        var _this = this;
        this.locationPromise.then(function () {
            _this.items = obj.reduce(function (items, data) {
                var country = _this.countryOptions.find(function (country) { return country.value.toString() === data.countryId; });
                return items.concat(data.postalCodes.map(function (pc) { return ({
                    countryId: data.countryId,
                    country: country.label,
                    key: pc,
                    value: 'Postal Code',
                    label: pc.toString() + ", " + country.label
                }); }));
            }, []);
        });
    };
    Object.defineProperty(ZipTargetingComponent.prototype, "targeting", {
        get: function () {
            return this._targeting;
        },
        set: function (value) {
            this._targeting = value;
            this._targetingChange.emit(this._targeting);
        },
        enumerable: true,
        configurable: true
    });
    ZipTargetingComponent.prototype.emitChanges = function () {
        if (this.onchange !== null) {
            var buckets_1 = this.items.reduce(function (buckets, item) {
                if (!Array.isArray(buckets[item.countryId])) {
                    buckets[item.countryId] = [];
                }
                buckets[item.countryId].push(item.key);
                return buckets;
            }, {});
            var selected = Object.keys(buckets_1).map(function (countryId) { return ({
                countryId: countryId, postalCodes: buckets_1[countryId]
            }); });
            this.onchange(selected);
        }
    };
    ZipTargetingComponent.prototype.addPostalCodes = function (data) {
        var _this = this;
        if (typeof data !== 'string') {
            return;
        }
        var pcs = data.replace(/\n/g, ',').split(',').map(function (str) { return str.trim(); }).filter(function (str) { return str.length > 0; });
        if (pcs.length < 1) {
            return;
        }
        var country = this.countryOptions.find(function (country) { return country.value === _this.country; });
        // Filter list so that it is unique.
        pcs = pcs.filter(function (pc, i, arr) { return arr.indexOf(pc) === i; });
        // Filter list so that it does not contain any postal codes already added.
        pcs = pcs.filter(function (pc) {
            return _this.items.findIndex(function (item) { return item.key === pc && _this.country.toString() === item.countryId; }) < 0;
        });
        var items = this.items.slice();
        pcs.forEach(function (pc) {
            items.push({
                countryId: _this.country.toString(),
                country: country.label,
                key: pc,
                value: 'Postal Code',
                label: pc.toString() + ", " + country.label
            });
        });
        this.items = items;
        this.emitChanges();
        // Reset.
        this.data = '';
    };
    Object.defineProperty(ZipTargetingComponent.prototype, "usonly", {
        get: function () {
            return this._usonly;
        },
        set: function (value) {
            this._usonly = value;
            if (this._usonly) {
                this.country = 235;
            }
            else {
                this.country = null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ZipTargetingComponent.prototype, "placeholder", {
        get: function () {
            return this.usonly
                ? 'Enter U.S. postal codes in the following formats: 12345 and 12345-6789.'
                : 'Select a country and then add postal codes you want to target.';
        },
        enumerable: true,
        configurable: true
    });
    ZipTargetingComponent.prototype.prepareCountries = function () {
        this.countryOptions = this.locationHelper.database
            .filter(function (location) { return location.type === 'country'; })
            .map(function (location) { return ({
            key: location.id,
            value: location.id,
            label: location.display
        }); });
        this.cdr.markForCheck();
    };
    ZipTargetingComponent.prototype.itemsUpdate = function (items) {
        this.items = items;
        this.emitChanges();
    };
    Object.defineProperty(ZipTargetingComponent.prototype, "hasItems", {
        get: function () {
            return this.items.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    ZipTargetingComponent.prototype.clearItems = function () {
        this.items = [];
        this.emitChanges();
    };
    ZipTargetingComponent.prototype.download = function () {
        var _this = this;
        var targetingType = this.targetingOptions.find(function (current) { return _this.targeting === current.value; }).label;
        var data = this.items.map(function (zip) { return ({
            'targeting type': targetingType,
            'geo type': 'Postal Code',
            'postal code': zip.key,
            country: zip.country
        }); });
        DownloadHelper.downloadAsCSV(data, this.fileName);
    };
    return ZipTargetingComponent;
}());
export { ZipTargetingComponent };
