import { Injectable } from '@angular/core';
import { ReplaySubject, combineLatest } from 'rxjs';
import { mergeMap, shareReplay, map } from 'rxjs/operators';
import { Logger } from 'app/services/logger';
import { Store } from 'app/services/store.service';
import { AdvertiserRepository } from 'app/core/repositories/advertiser-repository.service';
import { MediaGroupRepository } from 'app/core/repositories';

const AccountsPreferences = 'preferences:accounts';

@Injectable({
    providedIn: 'root'
})
export class Preferences {

    private accountIds$ = new ReplaySubject<string[]>(1);

    advertisers$ = this.accountIds$.pipe(mergeMap(accountIds => this.advertisers.searchByIds(accountIds)), shareReplay(1));
    mediaGroups$ = this.accountIds$.pipe(mergeMap(accountIds => this.mediaGroups.searchByIds(accountIds)), shareReplay(1));
    accounts$ = combineLatest(this.advertisers$, this.mediaGroups$).pipe(map(([advertisers, mediaGroups]) => [...advertisers, ...mediaGroups]));

    constructor(
        private logger: Logger,
        private store: Store,
        private advertisers: AdvertiserRepository,
        private mediaGroups: MediaGroupRepository
    ) {
        // Initialize the `accountIds` subject.
        const accountIds = this.store.get(AccountsPreferences) || [];
        this.accountIds$.next(accountIds);

        this.accountIds$.subscribe(accountIds => this.store.set(AccountsPreferences, accountIds));
    }

    setAccountIds(accountIds: string[]) {
        this.accountIds$.next(accountIds);
        this.logger.notice(`Updated account preferences (${accountIds.length} accounts)`);
    }

}
