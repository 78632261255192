import { AfterViewInit, Attribute, Directive, Input, ViewContainerRef } from '@angular/core';
import { FilterComponent } from './filter.component';
import { Filter } from './filter';

@Directive({
    selector: '[field]'
})
export class FilterFieldDirective implements AfterViewInit {
    @Input() field: string;
    component: FilterComponent;
    private advancedOnly: boolean;

    constructor(
        private viewContainer: ViewContainerRef,
        @Attribute('advanced-only') advancedOnly: boolean
    ) {
        this.advancedOnly = advancedOnly !== null;
    }

    ngAfterViewInit() {
        this.component = this.viewContainer['_data'].componentView.component;

        if (this.component.filter instanceof Filter) {
            this.component.filter.field = this.field;
            this.component.filter.isAdvancedOnly = this.advancedOnly;
        }
    }
}
