import { Model } from './model';

export class ReportingQuery extends Model {
    public data: {
        attributes: {
            lastUpdatedBy?: string,
            meta?: {
                reportType: string
            },
            query?: Object,
            queryName?: string,
            storage: 'druid' | 'athena',
            type?: string,
            userId?: string,
            columnOrder?: string[]
        },
        id?: number
    };

    constructor(from?: Object) {
        super(from);
    }

    public serialize(): string {
        const query = this.clone(ReportingQuery);

        Object.keys(query.data.attributes).forEach(attribute => {
            const allowed = ['query', 'meta', 'type', 'queryName', 'storage', 'columnOrder'];

            if (allowed.indexOf(attribute) === -1 || !query.data.attributes[attribute]) {
                delete query.data.attributes[attribute];
            }
        });

        return JSON.stringify(query.data.attributes);
    }

    get entity() {
        return 'Reporting Query';
    }
}
