import { Component, OnInit } from '@angular/core';

import { ThemeService, BackgroundStyle } from 'app/shared/helpers/theme.service';

@Component({
    templateUrl: './page-not-found.html',
    styleUrls: ['./page-not-found.styl']
})
export class PageNotFoundComponent implements OnInit {
    constructor(private themeService: ThemeService) {  }

    ngOnInit() {
        this.themeService.setBackgroundStyle(BackgroundStyle.GreyStyle);
    }
}
