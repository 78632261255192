import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { InsertionOrder } from 'app/shared/models';
import { InsertionOrderRepository } from 'app/core/repositories';

@Injectable()
export class InsertionOrderResolver implements Resolve<InsertionOrder> {
    constructor(private insertionOrderRepository: InsertionOrderRepository, ) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.insertionOrderRepository.get(route.params['id']);
    }
}
