import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { BackendRepository } from './backend-repository';
import { Rule } from 'app/shared/models/rule';
import { map, mergeMap } from 'rxjs/operators';

@Injectable()
export class RuleRepository extends BackendRepository<Rule> {
    constructor(http: HttpClient) {
        super(http, "/ruth/rules", Rule);
    }

    getRulesByAppId(appId: string, accountId: number): Observable<Rule[]> {
        const params = {
            accountId: accountId.toString(),
            filter: `appId:${appId}`,
        };
        return this.http.get(this.url(this.path), { params }).pipe(
            map((response) => response["results"]),
            map((results) => results.map((result) => this.build(result)))
        );
    }

    getRuleByAppIdAndPixelId(
        appId: string,
        pixelId: string,
        accountId: number
    ): Observable<Rule> {
        return this.getRulesByAppId(appId, accountId).pipe(
            mergeMap((rules) => {
                if (!rules) {
                    throw throwError(
                        `There is no rule associated with app id ${appId} and pixel id ${pixelId}`
                    );
                }
                const rule = rules.find((rule) => rule.pixelId === pixelId);
                if (!rule) {
                    throw throwError(
                        `There is no rule associated with app id ${appId} and pixel id ${pixelId}`
                    );
                }
                return of(rule);
            })
        );
    }
}
