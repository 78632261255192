import { Component, ViewChild } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { LightboxnextComponent } from 'app/shared/elements/lightboxnext';
import {
    AdSlot,
    Advertiser,
    Campaign,
    Creative,
    HistoryChange,
    HistoryItem,
    HistoryResponse,
    LineItem,
    Model,
    Newsletter,
    Publisher,
    RTBPartner,
    User
} from 'app/shared/models';
import { DownloadHelper } from 'app/shared/helpers/download-helper';
import { LIESRepository, HistoryEntity } from 'app/core/repositories';

import { Formatter } from './formatter';
import { map } from 'rxjs/operators';
import { IdService } from 'app/core/id.service';
import FORM_OPTIONS from '../../../platform/campaign-manager/line-items/options.json';

const groupsPattern = / (?:AND|OR) (?![a-z0-9])/g;
const audiencePattern = /[a-z0-9.]+/g;

@Component({
    selector: 'history-view',
    templateUrl: './history-view.html',
    styleUrls: ['./history-view.styl'],
    providers: [Formatter]
})
export class HistoryViewComponent {
    @ViewChild(LightboxnextComponent, { static: true }) private lightbox: LightboxnextComponent;
    isLoading = false;
    nextPage$: Observable<HistoryResponse>;
    private model: Model;
    private readonly _history$ = new BehaviorSubject<HistoryItem[]>([]);
    history$ = this._history$.pipe(map(items => items.filter(item => item.hasChanges)));
    private ageSegmentPattern: RegExp = null;
    private genderSegmentPattern: RegExp = null;

    constructor(
        private liesRepository: LIESRepository,
        private formatter: Formatter,
        public id: IdService
    ) {
        const ageSegmentOptions = FORM_OPTIONS.age.map(ele => ele.value);
        const genderSegmentOptions = FORM_OPTIONS.gender.map(ele => ele.value);
        this.ageSegmentPattern = new RegExp(ageSegmentOptions.join('|'), 'g');
        this.genderSegmentPattern = new RegExp(genderSegmentOptions.join('|'), 'g');
    }

    private view(historyResponse: HistoryResponse, model: Model) {
        if(this._history$.value.length) {
            this._history$.next([]);
        }
        this.model = model;
        this._history$.next(this._history$.getValue().concat(...historyResponse.items));
        this.nextPage$ = historyResponse.next;
        this.isLoading = false;
        this.lightbox.show();
    }

    next() {
        this.isLoading = true;
        this.nextPage$.subscribe(response => this.view(response, this.model));
    }

    /**
     * Here we need to go through each history item in the history response.
     * If any history item has an `audienceTargeting` change (either in the new value or old value),
     * remove age group and gender group from that change.
     * If the new age group is different from the old age group, add a new change to the history
     * item to represent it. Same for gender.
     * Why we do this? Age and gender are considered first party segments in our lfx db
     * but they are not first party to the clients. We need to make sure to not show them
     * in the "Audience Targeting" section but show them in the "Demographics" section.
     */
    viewLineItemHistory(lineItem: LineItem) {
        this.liesRepository.history(lineItem.id, HistoryEntity.LineItem).subscribe(historyResponse => {
            if (historyResponse && historyResponse.items && Array.isArray(historyResponse.items)) {
                historyResponse.items.forEach(historyItem => {
                    if (historyItem && historyItem.changes && Array.isArray(historyItem.changes)) {
                        let newAgeSegmentGroup: string;
                        let newGenderSegmentGroup: string;
                        let oldAgeSegmentGroup: string;
                        let oldGenderSegmentGroup: string;
                        let changes = [];

                        historyItem.changes.forEach(change => {
                            if (change.field === 'audienceTargeting') {
                                if (change.value && typeof change.value === 'string') {
                                    const liveSegmentGroups = [];
                                    change.value.split(groupsPattern).forEach((group: string) => {
                                        if (this.ageSegmentPattern.test(group)) {
                                            newAgeSegmentGroup = group;
                                        } else if (this.genderSegmentPattern.test(group)) {
                                            newGenderSegmentGroup = group;
                                        } else {
                                            liveSegmentGroups.push(group);
                                        }
                                    });
                                    // `change.value` should only contain live audience targeting (no age or gender)
                                    // it should not contain any prefix either
                                    change.value = liveSegmentGroups.join(' AND ').replace(/[a-z]/g, '').replace(/\./g, '');
                                }

                                if (change.old && typeof change.old === 'string') {
                                    const liveSegmentGroups = [];
                                    change.old.split(groupsPattern).forEach((group: string) => {
                                        if (this.ageSegmentPattern.test(group)) {
                                            oldAgeSegmentGroup = group;
                                        } else if (this.genderSegmentPattern.test(group)) {
                                            oldGenderSegmentGroup = group;
                                        } else {
                                            liveSegmentGroups.push(group);
                                        }
                                    });
                                    // `change.old` should only contain live audience targeting (no age or gender)
                                    // it should not contain any prefix either
                                    change.old = liveSegmentGroups.join(' AND ').replace(/[a-z]/g, '').replace(/\./g, '');
                                }

                                if (change.value !== change.old) {
                                    changes.push(change);
                                }
                            } else {
                                // For any change that is not `audienceTargeting`, just take it
                                changes.push(change);
                            }
                        });

                        if (newAgeSegmentGroup !== oldAgeSegmentGroup || newGenderSegmentGroup !== oldGenderSegmentGroup) {
                            const newAgeValues = this.mapSegmentGroupToHistoryValue(newAgeSegmentGroup);
                            const newGenderValues = this.mapSegmentGroupToHistoryValue(newGenderSegmentGroup);
                            const oldAgeValues = this.mapSegmentGroupToHistoryValue(oldAgeSegmentGroup);
                            const oldGenderValues = this.mapSegmentGroupToHistoryValue(oldGenderSegmentGroup);
                            changes.push(
                                new HistoryChange({
                                    field: 'dataProviderSegments',
                                    value: [...newAgeValues, ...newGenderValues],
                                    old: [...oldAgeValues, ...oldGenderValues]
                                })
                            );
                        }

                        historyItem.changes = changes;
                    }
                });
            }
            this.view(historyResponse, lineItem);
        });
    }

    private mapSegmentGroupToHistoryValue(segmentGroup: string) {
        if (!segmentGroup) {
            return [];
        }
        return segmentGroup.match(audiencePattern).map(segment => {
            const type = segmentGroup.includes('NOT') ? 'exclude' : 'include';
            if (segment && segment.includes('.')) {
                return { id: parseInt(segment.split('.')[1]), type };
            }
            return { id: parseInt(segment), type };
        });
    }

    viewCreativeHistory(creative: Creative) {
        this.liesRepository.history(creative.id, HistoryEntity.Creative).subscribe(historyResponse => {
            this.view(historyResponse, creative);
        });
    }

    viewCampaignHistory(campaign: Campaign) {
        this.liesRepository.history(campaign.id, HistoryEntity.Campaign).subscribe(historyResponse => {
            this.view(historyResponse, campaign);
        });
    }

    viewUserHistory(user: User) {
        this.liesRepository.history(user.hashId, HistoryEntity.User).subscribe(historyResponse => {
            this.view(historyResponse, user);
        });
    }

    viewRTBPartnerHistory(partner: RTBPartner) {
        this.liesRepository.history(partner.id, HistoryEntity.RTBPartner).subscribe(historyResponse => {
            this.view(historyResponse, partner);
        });
    }

    viewPublisherHistory(publisher: Publisher) {
        this.liesRepository.history(publisher.id, HistoryEntity.Publisher).subscribe(historyResponse => {
            this.view(historyResponse, publisher);
        });
    }

    viewAdvertiserHistory(advertiser: Advertiser) {
        this.liesRepository.history(advertiser.id, HistoryEntity.Advertiser).subscribe(historyResponse => {
            this.view(historyResponse, advertiser);
        });
    }

    viewAdSlotHistory(adSlot: AdSlot) {
        this.liesRepository.history(adSlot.id, HistoryEntity.AdSlot).subscribe(historyResponse => {
            this.view(historyResponse, adSlot);
        });
    }

    viewNewsletterHistory(newsletter: Newsletter) {
        this.liesRepository.history(newsletter.id, HistoryEntity.Newsletter).subscribe(historyResponse => {
            this.view(historyResponse, newsletter);
        });
    }

    formattedChanges(item: HistoryItem) {
        return this.formatter.formatChanges(item);
    }

    download(fieldName: string, timestamp: string, csv: any[]) {
        csv = csv.filter(value => !!value);

        DownloadHelper.downloadAsCSV(
            csv, `${this.model.entity}-${this.model.refId || this.model.id}-${fieldName}-${timestamp || ''}`
        );
    }
}
