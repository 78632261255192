import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { DateTimeInterval } from 'app/shared/elements/date-interval-picker';
import { LiveConnectStatus } from 'app/shared/models';
import { DruidFilter, LiveConnectTagRepository, ReportingQueryRepository } from './repositories';
import { EventType, Granularity } from './repositories/reporting-query-repository.service';

@Injectable()
export class LiveConnectService {
    private tagStatusCache = new Map<number, Observable<LiveConnectStatus>>();

    constructor(
        private reporting: ReportingQueryRepository,
        private liveConnectRepository: LiveConnectTagRepository,
    ) { }

    /**
     * Get an advertiser's LiveConnect Tag.
     */
    getTagByAdvertiserId(advertiserRefId: number, accountId: number) {
        return this.liveConnectRepository.getAppInfoFromCache(advertiserRefId, accountId);
    }

    /**
     * Get recent activity for a LiveConnect tag.
     */
    getRecentActivity(advertiserRefId: number) {
        return this.reporting.getMostRecentTagActivity(advertiserRefId);
    }

    /**
     * Get the status of the tag.
     */
    tagStatus(advertiserRefId: number) {
        if (!this.tagStatusCache.has(advertiserRefId)) {
            this.tagStatusCache.set(advertiserRefId, this.computeStatus(advertiserRefId).pipe(shareReplay(1)));
        }

        return this.tagStatusCache.get(advertiserRefId);
    }

    /**
     * Get the count of all LiveConnect events for an advertiser over an interval, split by granularity.
     */
    getEventsCount(advertiserRefId: number, interval: DateTimeInterval, granularity: Granularity, filters: DruidFilter[] = []) {
        return this.reporting.getLiveConnectEvents(advertiserRefId, interval.start.toDate(), interval.end.toDate(), granularity, filters);
    }

    /**
     * Get LiveConnect overview metrics for an advertiser over an interval.
     */
    getOverviewMetrics(advertiserRefId: number, interval: DateTimeInterval, eventType?: EventType) {
        return this.reporting.liveConnectOverviewMetrics(advertiserRefId, interval.start.toDate(), interval.end.toDate(), eventType).pipe(
            map(data => ([
                {
                    title: eventType === 'event' ? 'Total Events' : 'Total Page Views',
                    value: data.event.site_visits || 0
                },
                {
                    title: 'Unique Visitors',
                    value: data.event.unique_visitors || 0
                },
                {
                    title: 'Addressable Activation Audience',
                    value: data.event.known_visitors || 0
                }
            ]))
        );
    }

    /**
     * Compute the status of a tag.
     */
    private computeStatus(advertiserRefId: number) {
        return this.getRecentActivity(advertiserRefId).pipe(
            map(activity => new LiveConnectStatus(activity))
        );
    }


    /**
     * Get aggregates for all advertisers for given days
     */
    getAllAdvertiserAggregates(interval: DateTimeInterval, source: string) {
        const query = {
            queryType: 'groupBy',
            dataSource: source,
            granularity: 'all',
            intervals: [
                {
                    type: 'absolute',
                    start: interval.start.toISOString(),
                    end: interval.end.toISOString()
                }
            ],
            dimensions: [
                'advertiser_id',
                'app_id',
                'publisher_id',
                'event_platform',
                {
                    type: 'lookup',
                    dimension: 'advertiser_id',
                    outputName: 'advertiser_name',
                    name: 'advertiser_name_v2'
                },
                {
                    type: 'lookup',
                    dimension: 'publisher_id',
                    outputName: 'publisher_name',
                    name: 'publisher_name_v2'
                }
            ],
            aggregations: [
                {
                    type: 'HLLSketchMerge',
                    name: 'site_visits',
                    fieldName: 'event_id_sketch',
                },
                {
                    type: 'HLLSketchMerge',
                    name: 'known_visitors',
                    fieldName: 'all_hashes_sketch',
                }
            ]
        };

        return this.reporting.executeAdHocJSON(query, 'advertiser');
    }
}
