import {	
    Component,	
    OnInit,	
    OnDestroy,	
    AfterViewInit,	
    ViewChild	
} from '@angular/core';	

import { Router } from '@angular/router';	
import { combineLatest, forkJoin } from 'rxjs';	
import { map } from 'rxjs/operators';	

import { ConfirmDialogComponent } from 'app/shared/elements/confirm-dialog';	
import { PackageRepository, PublisherRepository,  ReportingQueryRepository, NewsletterRepository, AdSlotRepository } from 'app/core/repositories';	
import { NotificationsService } from 'app/core/notifications.service';	
import { Package } from 'app/shared/models';	
import { PackageFormComponent } from '../package-form/package-form.component';	
import { SearchParams } from 'app/shared/helpers/query-builder';	
import { TableHelper } from 'app/shared/helpers/table-helper';	
import { TableComponent } from 'app/shared/elements/table';	
import { PaginatorComponent } from 'app/shared/elements/paginator';	
import { ThemeService, BackgroundStyle } from 'app/shared/helpers/theme.service';	
import { IdService } from 'app/core';
import { DruidQueryHelper } from 'app/core/query-builder-helper';
import { AuthorizationService } from 'app/core/authorization.service';	

@Component({	
    selector: 'package-manager',	
    templateUrl: './package-manager.html',	
    styleUrls: ['./package-manager.styl']	
})	
export class PackageManagerComponent implements OnInit, OnDestroy, AfterViewInit {	
    @ViewChild(TableComponent, { static: true }) table: TableComponent;	
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent;	
    @ViewChild(PackageFormComponent, { static: true }) packageForm: PackageFormComponent;	
    @ViewChild(ConfirmDialogComponent, { static: true }) deleteDialog: ConfirmDialogComponent;	

    helper = new TableHelper<Package>(params => this.adapter(params));	

    selectedPackage: any;

    private static optionsAll = [	
        {	
            key: 0, value: 'edit', label: 'Edit'	
        },	
        {	
            key: 1, value: 'delete', label: 'Delete'	
        }	
    ];

    constructor(	
        private router: Router,	
        private theme: ThemeService,
        private notifications: NotificationsService,	
        private packageRepository: PackageRepository,	
        private publisherRepository: PublisherRepository,
        private newsletterRepository: NewsletterRepository,
        private adSlotRepository: AdSlotRepository,
        private reportingQueryRepository: ReportingQueryRepository,
        private queryBuilder: DruidQueryHelper,        
        public id: IdService,
        public auth: AuthorizationService
    ) { }	

    ngOnInit() {	
        this.theme.setBackgroundStyle(BackgroundStyle.GreyStyle);	
        this.publisherRepository.search({ n: 5, page: 1 }).pipe(map(publishers => publishers.length > 0))	
            .subscribe(hasPublishers => {	
                if (!hasPublishers) {	
                    this.notifications.warning(	
                        'At least one publisher is required to use the Package Manager.',	
                        'Notice!', 0);	
                }	
            });	

        this.helper.table = this.table;	
        this.helper.paginator = this.paginator;	
        combineLatest(this.table.query, this.paginator.query).subscribe(args => this.helper.search(args));
    }	

    ngAfterViewInit() {	
        this.paginator.reset();	
    }	

    ngOnDestroy() {	
        this.theme.resetBackgroundStyle();
    }	

    private getPublishers(pubIds) {
        return this.publisherRepository.searchByIds(pubIds || []);
    }

    private getNewsletters(newsletterIds) {
        return this.newsletterRepository.searchByIds(newsletterIds || []);
    }

    private getAdSlots(adSlotIds) {
        return this.adSlotRepository.searchByIds(adSlotIds || []);
    }

    private adapter(params: SearchParams) {
        params.conditions.push({
            'field': 'demandType',
            'value': 'direct'
        });
        const query = {
            type: 'groupBy',
            dataSource: 'custom_aggregates_exact',
            granularity: 'all',
            dimensions: [],
            aggs: ['Adjusted Impressions'],
            postAggs: [],
            interval: { type: 'dynamic', value: '30' }
        };

         // Fetch all packages
        return this.packageRepository.asyncSearch(params).pipe(map(response => {
            response.items.forEach(pkg => {
                // For each package fetch the inventory
                this.packageRepository.get(pkg.id).subscribe(pkgDetails => {
                    forkJoin([this.getPublishers(pkgDetails.publishers),
                        this.getAdSlots(pkgDetails.adSlots),
                        this.getNewsletters(pkgDetails.newsletters)
                    ]).subscribe(([ publishers, adSlots, newsletters ]) => {
                        const filters = [];
                        if (publishers.length) {
                            filters.push(	
                            { dimension: 'Publisher', values: publishers.map(pub => pub.refId.toString())});
                        }
                        if (adSlots.length) {
                            filters.push(	
                            { dimension: 'Section', values: adSlots.map(adSlot => adSlot.refId.toString())});
                        }
                        if (newsletters.length) {
                            filters.push(	
                            { dimension: 'Template', values: newsletters.map(newsletter => newsletter.refId.toString())});
                        }
                        // For each package make a reporting call to fetch impressions using inventory above for filters
                        this.reportingQueryRepository
                            .executeAdHocJSON(this.queryBuilder.buildQuery(Object.assign({}, query, { filters: filters })), 'publisher')
                            .subscribe(rows => {
                                let adjustedImpressions = 0;
                                if (Array.isArray(rows) && rows.length > 0) {	
                                    adjustedImpressions = rows[0].event['Adjusted Impressions'];
                                }
                                pkg['adjusted_impressions'] = adjustedImpressions;
                        });
                    });   
                });
            });
            return {	
                items: response.items,	
                pages: response.pages,
                total: response.total,	
                page: params.page
            };
        }));
            
    };

    openPackageModal() {	
        this.packageForm.createPackage();	
    }	

    rowOptions(pkg: Package) {	
        	
            return PackageManagerComponent.optionsAll;	

    }	

    rowAction(value: string, pkg: Package) {	
        switch (value) {	
            case 'edit':	
                if (this.isInventoryManager()){	
                    this.router.navigate(['/inventory-manager/packages', pkg.id]);	
                }	
                else {	
                    this.router.navigate(['/internal-tools/packages', pkg.id]);	
                }	
                break;	
            case 'delete':	
                this.selectedPackage = pkg;	
                this.deleteDialog.open();	
                break;	
        }	
    }	

    deletePackage() {	
        this.packageRepository.delete(this.selectedPackage.id).subscribe(() => {	
            this.notifications.success(`You have successfully deleted ${this.selectedPackage.name}.`, 'Woo hoo!');	
            this.paginator.reset();	
        });	
    }

    saved(pkg) {	
        this.paginator.reset();	

        this.notifications.success(`You have successfully created <strong>${pkg.name}</strong>.`, 'Woo Hoo!');	
    }

    private isInventoryManager() {	
        return this.router.url.indexOf('inventory-manager') > -1;	
    }	
}	
