export class KVP {
    public key: string;
    public value: string;

    constructor(key: string, value: string) {
        this.key = key;
        this.value = value;
    }

    public toString(): string {
        return `${this.key || ''}=${this.value || ''}`;
    }
}
