import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'gerund'
})
export class GerundPipe implements PipeTransform {
    transform(verb: string) {
        const lastChar = verb[verb.length - 1];

        if (lastChar === 'e') {
            verb = verb.slice(0, -1);
        }

        return verb + 'ing';
    }
}
