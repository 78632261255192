import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ErrorHelper } from 'app/core/errors';
import { environment } from 'src/environments/environment';

import {
    Router,
    NavigationStart,
    NavigationEnd,
    NavigationError,
    Event as RouterEvent,
    ActivationEnd
} from '@angular/router';

import { BehaviorSubject } from 'rxjs';

import { StoreService } from 'app/core/store.service';
import { ThemeService } from 'app/shared/helpers/theme.service';
import { HttpErrorResponse } from '@angular/common/http';

type NavigationOptions = {
    params?: any,
    queryParams?: any,
    paramsHandling?: 'merge' | ''
};

@Injectable()
export class NavigationService {
    isLoading = new BehaviorSubject<boolean>(false);

    private timer: number = null;

    constructor(
        private location: Location,
        private router: Router,
        private store: StoreService,
        private themeService: ThemeService
    ) {
        this.router.events.subscribe(event => this.handleRouterEvent(event));
    }

    /**
     * Respond to a NavigationStart event.
     */
    private onNavigationStarted(event: NavigationStart) {
        if (!this.isLoading.getValue()) {
            this.timer = window.setTimeout(() => this.isLoading.next(true), 100);
        }

        this.themeService.resetBackgroundStyle();
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden';
    }

    /**
     * Respond to a NavigationEnd event.
     */
    private onNavigationCompleted(event: NavigationEnd) {
        if (this.timer !== null) {
            clearTimeout(this.timer);
        }
        this.isLoading.next(false);

        document.body.style.overflow = null;
    }

    /**
     * Respond to a NavigationError event.
     */
    private onNavigationFailed(event: NavigationError) {
        this.sendErrorPage(event.error, event.url);
    }

    /**
     * Send the user to the appropriate error page.
     */
    sendErrorPage(error: HttpErrorResponse, url?: string) {
        const errorPage = this.errorPage(error, url);

        if (errorPage) {
            this.router.navigate([errorPage], { skipLocationChange: true })
                // for better UX, pretend like navigation succeeded
                .then(() => window.history.replaceState(null, 'Error', url));
        }
    }

    /**
     * Find the appropriate error page to display.
     */
    private errorPage(error: HttpErrorResponse, url?: string) {
        const path = url || this.router.url;

        if (ErrorHelper.isRateLimitError(error)) {
            return `/${this.findModule(path)}/too-many-requests`;
        }

        return `/${this.findModule(path)}/404`;
    }

    /**
     * Respond to an ActivationEnd event.
     */
    private onActivationEnd(event: ActivationEnd) {
        if (event.snapshot.firstChild === null && event.snapshot.outlet === 'primary') {
            if (event.snapshot.data.style) {
                if (event.snapshot.data.style.background) {
                    this.themeService.setBackgroundStyle(event.snapshot.data.style.background);
                }
            }
        }
    }

    /**
     * Find the module of a url.
     */
    findModule(url: string) {
        if (url.includes('inventory-manager')) {
            return 'inventory-manager';
        } else if (url.includes('campaign-manager')) {
            return 'campaign-manager';
        } else if (url.includes('internal-tools')) {
            return 'internal-tools';
        }
    }

    /**
     * Handle a Router event.
     */
    private handleRouterEvent(event: RouterEvent) {
        if (event instanceof NavigationStart) {
            this.onNavigationStarted(event);
        } else if (event instanceof NavigationEnd) {
            this.onNavigationCompleted(event);
        } else if (event instanceof NavigationError) {
            this.onNavigationFailed(event);
        } else if (event instanceof ActivationEnd) {
            this.onActivationEnd(event);
        }
    }

    /**
     * Redirect to the login page.
     */
    redirectToLogin(includeRedirectParam: boolean = true) {
        let url = '/login';
        if (includeRedirectParam) {
            url += `?redirect=${this.redirectBackParam()}`;
        }
        window.location.href = this.authAppURL(url);
    }

    /**
     * Redirect to the logout page.
     */
    redirectToLogout() {
        window.location.href = this.authAppURL(
            `/logout?redirect=${this.redirectBackParam()}`
        )
    }

    /**
     * Redirect to the login page.
     */
    redirectToFatalError() {
        window.location.href = '/fatal-error';
    }

    /**
     * Replace the current URL with a URL containing `options.queryParams` and/or `options.matrixParams`, but do not
     * cause the Router to nagivate again. This function attempts to be the missing `router` function that acts exactly
     * like `router.navigate`, expect that it only replaces state, rather than triggerring a full navigation.
     */
    replaceState(route: ActivatedRoute, options?: NavigationOptions) {
        this.location.replaceState(
            this.router
                .createUrlTree([this.handleParams(options, route)], {
                    relativeTo: route,
                    queryParams: options.queryParams
                })
                .toString()
        );
    }

    private authAppURL(...suffix: string[]): string {
        return (environment.backend.auth.hostname || 'https://auth.liveintent.com') + suffix.join('/');
    }

    private redirectBackParam() {
        const currentAppBaseURL = new URL(window.location.href).origin;
        const currentAppURI = this.router.url;

        return encodeURIComponent(currentAppBaseURL + currentAppURI);
    }

    /**
     * Handle the matrix params based on the paramsHandling option.
     */
    private handleParams(options: NavigationOptions, route: ActivatedRoute) {
        if (options.paramsHandling === 'merge') {
            // clone the original params into a plain object so we can use `Object.assign`
            const originalParams = JSON.parse(JSON.stringify(route.snapshot.params));

            return Object.assign(originalParams, options.params);
        }

        return options.params;
    }
}
