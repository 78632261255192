import { Model } from './model';

export class Bundle extends Model {

    public name: string;

    public serialize(): string {
        return JSON.stringify(this);
    }

    get entity(): string {
        return 'Bundle';
    }
}
