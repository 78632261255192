import * as tslib_1 from "tslib";
import { ElementRef, OnInit, ChangeDetectorRef, QueryList } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ControlValueAccessor } from '@angular/forms';
import { DropdownComponent, Navigable, OutsideClickHelper } from '../dropdown';
import { OptionComponent } from '../filter-select';
import { OptionGroupComponent } from './option-group.component';
import { IdService } from 'app/core/id.service';
var SelectDropdownComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SelectDropdownComponent, _super);
    function SelectDropdownComponent(el, cdr, id, placeholder, button) {
        var _this = _super.call(this, cdr) || this;
        _this.el = el;
        _this.cdr = cdr;
        _this.id = id;
        _this.placeholder = placeholder;
        _this.button = button;
        _this.disabled = false;
        _this.dropdownLeftAlign = false;
        _this.fullWidth = false;
        _this.allowCustom = false;
        _this.maxLengthCustomOption = 128;
        _this.isButtonOnly = false;
        _this.onchange = null;
        _this._outsideClickTarget = null;
        _this.isEditing = false;
        _this.newOptionLabel = '';
        _this.customOption = null;
        _this.tplOptionsCache = null;
        _this._options = [];
        /**
         * Used to offer a different way to bind with the component.
         */
        _this.selectedSubject = new BehaviorSubject(null);
        _this._outsideClickTarget = new OutsideClickHelper(el.nativeElement);
        _this.isButtonOnly = button !== null;
        return _this;
    }
    Object.defineProperty(SelectDropdownComponent.prototype, "customOptionValue", {
        set: function (value) {
            if (value) {
                this.newOptionLabel = value;
                this.addCustomOption(this.newOptionLabel);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectDropdownComponent.prototype, "options", {
        get: function () {
            if (this.groups && this.groups.length > 0) {
                if (!this.tplOptionsCache) {
                    var groupOptions = this.groups.reduce(function (acc, cur) {
                        acc = acc.concat(cur.options);
                        return acc;
                    }, []);
                    this.tplOptionsCache = groupOptions.map(function (option, key) {
                        option.key = key;
                        var value = option.value;
                        var label = option.html;
                        return { key: key, value: value, label: label };
                    });
                }
                return this.tplOptionsCache;
            }
            if (this.tplOptions && this.tplOptions.length > 0) {
                if (!this.tplOptionsCache) {
                    this.tplOptionsCache = this.tplOptions.map(function (option, key) {
                        var value = option.value;
                        var label = option.html;
                        return { key: key, value: value, label: label };
                    });
                }
                return this.tplOptionsCache;
            }
            return this._options;
        },
        set: function (value) {
            this._options = value;
            if (this.selected) {
                this._resetValue(this.selectedValue);
            }
        },
        enumerable: true,
        configurable: true
    });
    SelectDropdownComponent.prototype.ngOnInit = function () {
        this._outsideClickTarget.target = this.dropdown;
    };
    SelectDropdownComponent.prototype.disable = function () {
        this.disabled = true;
        return this;
    };
    SelectDropdownComponent.prototype.enable = function () {
        this.disabled = false;
        return this;
    };
    SelectDropdownComponent.prototype.writeValue = function (value) {
        this._resetValue(value);
        this.cdr.markForCheck();
    };
    SelectDropdownComponent.prototype._resetValue = function (value) {
        delete this.selected;
        if (!this.options) {
            return;
        }
        if (this.customOption && this.customOption.value === value) {
            this.selected = this.customOption.key;
        }
        else {
            var option = this.options.find(function (option) { return option.value === value; });
            if (option !== undefined) {
                this.selected = option.key;
            }
        }
    };
    SelectDropdownComponent.prototype.dropdownVisChange = function (opened) {
        this.el.nativeElement.classList[opened ? 'add' : 'remove']('opened');
    };
    SelectDropdownComponent.prototype.registerOnChange = function (fn) {
        this.onchange = fn;
    };
    SelectDropdownComponent.prototype.registerOnTouched = function (fn) { };
    SelectDropdownComponent.prototype.propagate = function () {
        if (this.onchange !== null) {
            this.onchange(this.selectedValue);
            this.selectedSubject.next(this.selectedValue);
        }
    };
    Object.defineProperty(SelectDropdownComponent.prototype, "selectedOption", {
        get: function () {
            var _this = this;
            if (this.customOption && this.customOption.key === this.selected) {
                return this.customOption;
            }
            return this.options.find(function (option) { return option.key === _this.selected; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectDropdownComponent.prototype, "selectedValue", {
        get: function () {
            return this.selectedOption ? this.selectedOption.value : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectDropdownComponent.prototype, "label", {
        get: function () {
            if (this.selected !== undefined && this.selectedOption && !this.isButtonOnly) {
                return this.selectedOption.label;
            }
            if (typeof this.placeholder === 'string') {
                return this.placeholder;
            }
            return 'Select';
        },
        enumerable: true,
        configurable: true
    });
    SelectDropdownComponent.prototype.reset = function () {
        this._select(undefined);
    };
    SelectDropdownComponent.prototype._select = function (key) {
        this.selected = key;
        this.propagate();
        this.dropdown.hide();
        this._outsideClickTarget.deactivate();
        this.cdr.markForCheck();
    };
    SelectDropdownComponent.prototype.isString = function (value) {
        return typeof value === 'string';
    };
    Object.defineProperty(SelectDropdownComponent.prototype, "hasCreateClass", {
        get: function () {
            return this.el.nativeElement.classList.contains('create');
        },
        enumerable: true,
        configurable: true
    });
    SelectDropdownComponent.prototype.toggleEditing = function () {
        if (this.isEditing && this.newOptionLabel.trim()) {
            this.addCustomOption(this.newOptionLabel);
        }
        this.isEditing = !this.isEditing;
    };
    SelectDropdownComponent.prototype.addCustomOption = function (value) {
        if (value.trim()) {
            this.customOption = {
                key: Number.MAX_SAFE_INTEGER,
                label: value,
                value: value,
            };
            this.isEditing = false;
        }
        else {
            this.customOption = null;
            this.isEditing = true;
        }
        this.newOptionLabel = '';
    };
    SelectDropdownComponent.prototype.editCustomOption = function () {
        if (this.customOption) {
            this.newOptionLabel = this.customOption.label;
            this.customOption = null;
            this.isEditing = true;
        }
    };
    SelectDropdownComponent.prototype.handleKeydown = function (event) {
        if (event.code === 'Enter') {
            event.preventDefault();
            this.addCustomOption(this.newOptionLabel);
            if (this.customOption) {
                this._select(this.customOption.key);
            }
        }
    };
    return SelectDropdownComponent;
}(Navigable));
export { SelectDropdownComponent };
