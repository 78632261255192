import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    EventEmitter,
    forwardRef,
    Output,
    TemplateRef
} from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Input } from '@angular/core';
import { TableColumnDirective } from 'app/shared/elements/table/table-column.directive';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * @title Drag&Drop sorting
 */
@Component({
    selector: 'app-draggable-list',
    templateUrl: 'draggable-list.component.html',
    styleUrls: ['draggable-list.component.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DraggableListComponent),
            multi: true
        }
    ]
})
export class DraggableListComponent implements ControlValueAccessor {
    @Input('section') section: string;
    @Input('icon') icon: string;
    @Input('drag') drag: string;

    @ContentChild('template', { static: true }) itemtpl: TemplateRef<any> = null;
    @Output() onRemove = new EventEmitter<TableColumnDirective[]>();
    items: TableColumnDirective[];

    private onchange = (_: any[]) => { };

    constructor(private cdr: ChangeDetectorRef) { }

    writeValue(value: TableColumnDirective[]) {
        if (Array.isArray(value)) {
            this.items = value;
            this.cdr.markForCheck();
        }
    }

    get isLocked() {
        return this.section === 'lock';
    }

    registerOnChange(fn: (_: any[]) => {}) {
        this.onchange = fn;
    }

    registerOnTouched() { }

    remove(i: number) {
        const removed = this.items.splice(i, 1);
        this.onchange(this.items);
        this.onRemove.emit(removed);
    }

    drop(event: CdkDragDrop<TableColumnDirective[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
    }
}
