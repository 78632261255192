import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarChartComponent } from './bar-chart';
import { DonutChartComponent } from './donut-chart';
import { LineChartComponent } from './line-chart';
import { OverlapBarChartComponent } from './overlap-bar-chart';
import { PieChartComponent } from './pie-chart';
import { SparkChartComponent } from './spark-chart';
import { StackedBarChartComponent } from './stacked-bar-chart';
import { TimeseriesChartComponent } from './timeseries-chart';

const components = [
    BarChartComponent,
    DonutChartComponent,
    LineChartComponent,
    OverlapBarChartComponent,
    PieChartComponent,
    SparkChartComponent,
    StackedBarChartComponent,
    TimeseriesChartComponent
];

@NgModule({
    imports: [CommonModule],
    declarations: [...components],
    exports: [...components]
})
export class ChartsModule { }
