import { ChangeDetectorRef } from '@angular/core';
import { CampaignManagerDataService } from 'app/platform/campaign-manager/campaign-manager-data.service';
import { AuthorizationService, MetricsDataService } from 'app/core';
import { sum } from 'app/core/array-utils';
import { isEqual } from 'app/core/utils';
import moment from 'moment';
import { take } from 'rxjs/operators';
var BulkEditLineItemBudgetComponent = /** @class */ (function () {
    function BulkEditLineItemBudgetComponent(label, auth, cdr, dataService, metricsDataService) {
        this.auth = auth;
        this.cdr = cdr;
        this.dataService = dataService;
        this.metricsDataService = metricsDataService;
        this._lineItems = [];
        this.originals = new Map();
        this.campaignToLineItemsMap = new Map();
        this.campaignMap = new Map();
        this.budgetIncrement = 'lifetime';
        this.spendAsOfDate = moment().subtract(1, 'days').endOf('day');
        this.label = label;
    }
    BulkEditLineItemBudgetComponent.prototype.original = function (refId) {
        return this.originals.get(refId);
    };
    Object.defineProperty(BulkEditLineItemBudgetComponent.prototype, "lineItems", {
        get: function () {
            return this._lineItems;
        },
        set: function (lineItems) {
            var _this = this;
            this._lineItems = lineItems;
            this.originals.clear();
            this.campaignMap.clear();
            this.campaignToLineItemsMap.clear();
            lineItems.forEach(function (lineItem) {
                var clone = lineItem.clone();
                delete clone.campaignObj;
                _this.originals.set(lineItem.refId, clone);
                if (!_this.campaignToLineItemsMap.has(lineItem.campaign)) {
                    _this.campaignToLineItemsMap.set(lineItem.campaign, []);
                }
                _this.campaignToLineItemsMap.get(lineItem.campaign).push(lineItem);
                _this.dataService.fetchCampaign(lineItem.campaign)
                    .pipe(take(1))
                    .subscribe(function (campaign) {
                    _this.campaignMap.set(campaign.id, campaign);
                    _this.cdr.markForCheck();
                });
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BulkEditLineItemBudgetComponent.prototype, "campaigns", {
        get: function () {
            return Array.from(this.campaignMap.values());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BulkEditLineItemBudgetComponent.prototype, "entities", {
        get: function () {
            return this.lineItems;
        },
        set: function (entities) {
            this.lineItems = entities;
        },
        enumerable: true,
        configurable: true
    });
    BulkEditLineItemBudgetComponent.prototype.getCampaignLineItems = function (campaign) {
        return this.campaignToLineItemsMap.get(campaign.id);
    };
    BulkEditLineItemBudgetComponent.prototype.getSpendToDate = function (lineItem, date) {
        var system = this.campaignMap.get(lineItem.campaign).system;
        return this.metricsDataService.getLineItemSpendToDate(lineItem, system, date);
    };
    BulkEditLineItemBudgetComponent.prototype.getImpressionsToDate = function (lineItem, date) {
        var system = this.campaignMap.get(lineItem.campaign).system;
        return this.metricsDataService.getLineItemImpressionsToDate(lineItem, system, date);
    };
    BulkEditLineItemBudgetComponent.prototype.budgetIncrementDisabled = function (lineItem) {
        // TODO: disable this toggle and force user to use line item form
        // until we have this logic worked out
        return true;
    };
    BulkEditLineItemBudgetComponent.prototype.availableBudget = function (lineItem) {
        var _this = this;
        var campaign = this.campaignMap.get(lineItem.campaign);
        // sum of budgets of all line items under the campaign, calculated by LSD
        var budgetOfAllLineItemsUnderCampaign = campaign.budgetAllocated;
        // list of line items currently visible in the form
        var selectedLineItemsUnderCampaign = this.getCampaignLineItems(campaign);
        // sum of budgets of currently visible line items, BEFORE they were modified
        var originalSumOfBudgetsOfSelectedLineItems = sum(selectedLineItemsUnderCampaign.map(function (lineItem) { return _this.original(lineItem.refId); }), 'budget');
        // sum of budgets of currently visible line items, AFTER they were modified
        var currentSumOfBudgetsOfSelectedLineItems = selectedLineItemsUnderCampaign.reduce(function (acc, cur) {
            acc += cur.budgetIncrement === 'lifetime' ? cur.budget : cur.dailyBudget * cur.remainingNumberOfDaysInFlight;
            return acc;
        }, 0);
        return campaign.budget - budgetOfAllLineItemsUnderCampaign + originalSumOfBudgetsOfSelectedLineItems - currentSumOfBudgetsOfSelectedLineItems;
    };
    BulkEditLineItemBudgetComponent.prototype.useRemainingBudget = function (lineItem) {
        if (lineItem.budgetIncrement === 'daily') {
            lineItem.dailyBudget += this.availableBudget(lineItem) / lineItem.remainingNumberOfDaysInFlight;
        }
        else {
            lineItem.budget += this.availableBudget(lineItem);
        }
    };
    BulkEditLineItemBudgetComponent.prototype.inheritDates = function (lineItem) {
        lineItem.startDate = null;
        lineItem.endDate = null;
    };
    BulkEditLineItemBudgetComponent.prototype.disinheritDates = function (lineItem) {
        var campaign = this.campaignMap.get(lineItem.campaign);
        lineItem.startDate = campaign.effectiveStartDate;
        lineItem.endDate = campaign.effectiveEndDate;
    };
    BulkEditLineItemBudgetComponent.prototype.isLineItemDirty = function (lineItem) {
        var clone = lineItem.clone();
        delete clone.campaignObj;
        return !isEqual(clone, this.original(lineItem.refId));
    };
    BulkEditLineItemBudgetComponent.prototype.isDirty = function () {
        var _this = this;
        return this.lineItems.some(function (lineItem) { return _this.isLineItemDirty(lineItem); });
    };
    BulkEditLineItemBudgetComponent.prototype.revert = function (lineItem) {
        Object.assign(lineItem, this.original(lineItem.refId));
    };
    BulkEditLineItemBudgetComponent.prototype.reset = function () {
        var _this = this;
        this.dataService.reset();
        this.lineItems.forEach(function (entity) {
            entity['budget'] = _this.original(entity.refId)['budget'];
            entity['dailyBudget'] = _this.original(entity.refId)['dailyBudget'];
        });
    };
    return BulkEditLineItemBudgetComponent;
}());
export { BulkEditLineItemBudgetComponent };
