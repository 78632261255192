import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { TableMode } from './table-mode';
import { groupBy } from 'app/core/array-utils';
var URL_PARAM_DELIMETER = '|';
var URL_PARAM_GROUP_DELIMETER = '+';
var TableURLService = /** @class */ (function () {
    function TableURLService(router, location, route) {
        this.router = router;
        this.location = location;
        this.route = route;
        this.map = new Map();
    }
    /**
     * Generate query parameters for the table defined with a given `routeKey`.
     */
    TableURLService.prototype.generate = function (routeKey) {
        var _a;
        var _this = this;
        if (!this.map.has(routeKey)) {
            return {};
        }
        var params = this.map.get(routeKey);
        var validFilters = params.filters.filter(function (filter) { return filter.isValid(); });
        var groups = Object.values(groupBy(validFilters, 'groupId'));
        var generated = groups.map(function (group) {
            return group.map(function (filter) { return _this.convertFilterToParams(filter); }).join(URL_PARAM_DELIMETER);
        }).join(URL_PARAM_GROUP_DELIMETER);
        generated += URL_PARAM_GROUP_DELIMETER + "mode:" + (params.mode || TableMode.Basic);
        generated += URL_PARAM_GROUP_DELIMETER + "page:" + (params.page || 1);
        return _a = {},
            _a[routeKey] = generated,
            _a;
    };
    /**
     * Push filters onto the URL for a given `routeKey`.
     */
    TableURLService.prototype.pushFilters = function (routeKey, filters) {
        if (!routeKey) {
            return;
        }
        if (!this.map.has(routeKey)) {
            this.map.set(routeKey, { filters: [] });
        }
        this.map.get(routeKey).filters = filters;
        this.replaceState(this.generate(routeKey));
    };
    /**
     * Push mode onto the URL for a given `routeKey`.
     */
    TableURLService.prototype.pushMode = function (routeKey, mode) {
        if (!routeKey) {
            return;
        }
        if (!this.map.has(routeKey)) {
            this.map.set(routeKey, { filters: [] });
        }
        this.map.get(routeKey).mode = mode;
        this.replaceState(this.generate(routeKey));
    };
    /**
     * Push the current page onto the URL for a given `routeKey`.
     */
    TableURLService.prototype.pushPage = function (routeKey, page) {
        if (!routeKey) {
            return;
        }
        if (!this.map.has(routeKey)) {
            this.map.set(routeKey, { filters: [] });
        }
        this.map.get(routeKey).page = page;
        this.replaceState(this.generate(routeKey));
    };
    /**
     * Replace the current URL with a URL containing `queryParams`.
     */
    TableURLService.prototype.replaceState = function (queryParams) {
        this.location.replaceState(this.router
            .createUrlTree([], {
            relativeTo: this.route,
            queryParams: queryParams
        })
            .toString());
    };
    /**
     * Parse the mode from a param map for a given `routeKey`.
     */
    TableURLService.prototype.parseModeFromParamMap = function (routeKey, paramMap) {
        if (!paramMap.has(routeKey)) {
            return TableMode.Basic;
        }
        // parse the mode
        var key = 'mode:';
        var param = (paramMap.get(routeKey)
            .split(URL_PARAM_GROUP_DELIMETER)
            .find(function (group) { return group.indexOf(key) > -1; }) || '').slice(key.length);
        return param;
    };
    /**
     * Parse the page from a param map for a given `routeKey`.
     */
    TableURLService.prototype.parsePageFromParamMap = function (routeKey, paramMap) {
        if (!paramMap.has(routeKey)) {
            return 1;
        }
        // parse the page
        var key = 'page:';
        var param = (paramMap.get(routeKey)
            .split(URL_PARAM_GROUP_DELIMETER)
            .find(function (group) { return group.indexOf(key) > -1; }) || '').slice(key.length);
        return parseInt(param);
    };
    /**
     * Parse the filters from a param map for a given `routeKey`.
     */
    TableURLService.prototype.parseFiltersFromParamMap = function (routeKey, paramMap, availableFilters) {
        var _this = this;
        if (!paramMap.has(routeKey) || !Array.isArray(availableFilters)) {
            return [];
        }
        // parse the filters
        var filters = [];
        paramMap.get(routeKey)
            .split(URL_PARAM_GROUP_DELIMETER)
            .forEach(function (group, index) {
            group.split(URL_PARAM_DELIMETER)
                .map(function (params) {
                var parsed = _this.parseFilterFromParams(params);
                var filter = availableFilters.find(function (filter) { return filter.field === parsed.field; });
                if (filter) {
                    return new filter.constructor(Object.assign(filter, {
                        groupId: index,
                        query: parsed.query,
                        label: filter.label,
                        operation: parsed.operation,
                    }));
                }
            })
                .filter(function (filter) { return filter && filter.isValid(); })
                .forEach(function (filter) { return filters.push(filter); });
        });
        return filters;
    };
    /**
     * Parse a filter from a param group.
     * For example: '?tableParams=advertiserRefId:co:test,advertiserName:ne:tester'
     */
    TableURLService.prototype.parseFilterFromParams = function (params) {
        // some magic to only split by the first two `:`s
        var split = params.split(':');
        var parsed = split.splice(0, 2);
        parsed.push(split.join(':'));
        return {
            field: parsed[0],
            operation: parsed[1],
            query: parsed[2]
        };
    };
    /**
     * Convert a filter to a URL param string.
     * For example: '?tableParams=advertiserRefId:co:test,advertiserName:ne:tester'
     */
    TableURLService.prototype.convertFilterToParams = function (filter) {
        return [filter.field, filter.operation, filter.query].join(':');
    };
    return TableURLService;
}());
export { TableURLService };
