import { NgModule } from '@angular/core';
import { SafeHtml } from './safe-html.pipe';
import { SafeStyle } from './safe-style.pipe';
import { BlueprintDimensions } from 'app/shared/pipes/blueprint-dimensions.pipe';

const pipes = [
    SafeHtml,
    SafeStyle,
    BlueprintDimensions
];

@NgModule({
    declarations: [...pipes],
    exports: [...pipes]

})
export class PipesModules {}
