import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BackendRepository } from './backend-repository';
import { SupplyImageAsset, SupplyTextAsset } from 'app/shared/models';
import { DemandImageAsset, DemandTextAsset } from 'app/shared/models/native-demand';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/index';

@Injectable()
export class NativeSupplyImageAssetRepository extends BackendRepository<SupplyImageAsset> {
    public constructor(http: HttpClient) {
        super(http, '/native-supply-image', SupplyImageAsset);
    }

    public save(supplyImageAsset: SupplyImageAsset): Observable<SupplyImageAsset> {
        const params = [this.path];
        if (supplyImageAsset.refId) {
            params.push(supplyImageAsset.refId.toString());
        }
        return this.http.post(this.url(...params), supplyImageAsset.serialize())
            .pipe(map(response => this.build(response['output'])));
    }
}

@Injectable()
export class NativeSupplyTextAssetRepository extends BackendRepository<SupplyTextAsset> {
    public constructor(http: HttpClient) {
        super(http, '/native-supply-text', SupplyTextAsset);
    }

    public save(supplyTextAsset: SupplyTextAsset): Observable<SupplyTextAsset> {
        const params = [this.path];
        if (supplyTextAsset.refId) {
            params.push(supplyTextAsset.refId.toString());
        }
        return this.http.post(this.url(...params), supplyTextAsset.serialize())
            .pipe(map(response => this.build(response['output'])));
    }
}

@Injectable()
export class NativeDemandImageAssetRepository extends BackendRepository<DemandImageAsset> {
    public constructor(http: HttpClient) {
        super(http, '/native-demand-image', DemandImageAsset);
    }

    public save(demandImageAsset: DemandImageAsset): Observable<DemandImageAsset> {
        const params = [this.path];
        if (demandImageAsset.refId) {
            params.push(demandImageAsset.refId.toString());
        }
        return this.http.post(this.url(...params), demandImageAsset.serialize())
            .pipe(map(response => this.build(response['output'])));
    }
}

@Injectable()
export class NativeDemandTextAssetRepository extends BackendRepository<DemandTextAsset> {
    public constructor(http: HttpClient) {
        super(http, '/native-demand-text', DemandTextAsset);
    }

    public save(demandTextAsset: DemandTextAsset): Observable<DemandTextAsset> {
        const params = [this.path];
        if (demandTextAsset.refId) {
            params.push(demandTextAsset.refId.toString());
        }
        return this.http.post(this.url(...params), demandTextAsset.serialize())
            .pipe(map(response => this.build(response['output'])));
    }
}
