import { Condition } from 'app/core/repositories';
import { Filter } from 'app/shared/elements/filters';
import { Operation } from 'app/shared/elements/filters';
import { SearchParams, QueryBuilder } from './query-builder';
import { PaginatorQuery } from 'app/shared/elements/paginator';
import { TableQuery } from 'app/shared/elements/async-table';

export class LSDQueryBuilder implements QueryBuilder {
    /**
     * Convert a `TableQuery` into `SearchParams`, depending on
     * backend of the search.
     */
    build<T extends TableQuery & PaginatorQuery>(tableQuery: T): SearchParams {
        return {
            page: tableQuery.page,
            n: tableQuery.pageSize,
            orderBy: tableQuery.orderBy,
            sort: tableQuery.direction,
            conditions: this.buildConditions(tableQuery.filters || [])
        };
    }

    /**
     * Convert `filters` into `conditions` that LSD will understand.
     */
    private buildConditions(filters: Filter[]) {
        const conditions = this.buildConditionsHelper(filters);

        // just some specific LSD logic
        if (conditions.conditions.length > 0) {
            return [conditions];
        }

        // if all else fails, return an empty set of conditions
        return [];
    }

    private buildConditionsHelper(filters: Filter[]) {
        // strip any filters that are invalid
        const validFilters = filters.filter(filter => filter.isValid());

        // recursively build any nested filters
        const conditionsByGroup = validFilters.map(filter => {
            if (Array.isArray(filter.filters)) {
                return this[filter.operation](this.buildConditionsHelper(filter.filters));
            }

            return this.buildCondition(filter);
        });

        // apply AND between each top level group
        return this.and(conditionsByGroup);
    }

    /**
     * Build an lsd condition based on a `Filter`.
     */
    private buildCondition(filter: Filter) {
        return {
            field: filter.field,
            value: filter.query,
            operator: this.getOperator(filter.operation)
        };
    }

    /**
     * Flatten a list of conditions into a single condition with `OR` operators
     * between each of the original conditions.
     */
    private or(condition: Condition) {
        const conditions = condition.conditions;

        return {
            conditions: conditions.map(condition => {
                condition.mode = 'or';
                return condition;
            })
        };
    }

    /**
     * Flatten a list of conditions into a single condition with `AND` operators
     * between each of the original conditions.
     */
    private and(conditions: Condition[]) {
        return {
            conditions
        };
    }

    /**
     * Get the LSD condition operator for a given `operation`.
     */
    private getOperator(operation: Operation) {
        switch (operation) {
            case Operation.Equals:
                return 'eq';
            case Operation.DoesNotEqual:
                return 'neq';
            case Operation.Contains:
                return 'like';
            case Operation.DoesNotContain:
                return 'nlike';
            case Operation.GreaterThan:
                return 'gt';
            case Operation.LessThan:
                return 'lt';
            case Operation.GreaterThanOrEqualTo:
                return 'gteq';
            case Operation.LessThanOrEqualTo:
                return 'lteq';
            default:
                return undefined;
        }
    }
}
