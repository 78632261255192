import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { SasNewsletter } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
var SasNewsletterRepository = /** @class */ (function (_super) {
    tslib_1.__extends(SasNewsletterRepository, _super);
    function SasNewsletterRepository(http) {
        return _super.call(this, http, '/sas-newsletter', SasNewsletter) || this;
    }
    return SasNewsletterRepository;
}(BackendRepository));
export { SasNewsletterRepository };
