import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReportingSchedule } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { map } from 'rxjs/operators';

@Injectable()
export class ReportingScheduleRepository extends BackendRepository<ReportingSchedule> {
    public constructor(http: HttpClient) {
        super(http, '/reporting/schedule', ReportingSchedule);
    }

    public getUserScheduledReports() {
        return this.http.get(this.url(this.path));
    }

    public getAllScheduledReports() {
        return this.http.get(this.url(this.path, 'allUsers'));
    }

    public update(params: {id: string}, model: ReportingSchedule): Observable<ReportingSchedule> {
        return this.http.put(this.url(this.path, params.id), model.serialize()) as Observable<ReportingSchedule>;
    }

    public immediateSend(id: number): Observable<ReportingSchedule> {
        return this.http.post(this.url(this.path, id.toString(), 'send'), {}) as Observable<ReportingSchedule>;
    }

    public immediateBulkSend(ids: number[]): Observable<ReportingSchedule[]> {
        return this.http.post(this.url(this.path, 'send'), { 'ids': ids }) as Observable<ReportingSchedule[]>;
    }

    public save(instance: ReportingSchedule): Observable<ReportingSchedule> {
        // Overriding BackendRepository's method because unlike LSD 'output' field doesn't exist in Wolfman response
        const params = [this.path];
        let id = instance.id as string | number;
        if (typeof id === 'number') {
            id = id.toString();
        }
        if (typeof id === 'string') {
            params.push(id);
        }
        return this.http.post(this.url(...params), instance.serialize())
            .pipe(map(response => this.build(response)));
    }
}
