export class AdSlotSize {
    public height: number;
    public width: number;
    public floor: number;
    public deviceTypes: any[];
    public isEnabled?: boolean = true;
    public allowOutlook?: boolean = false;
    public hasDuplicateSize?: boolean = false;
    public isDeviceTypeEmpty?: boolean = false;
    public isWidthAndHeightValid?: boolean = true;
    public hasErrors?: boolean = false;
    public errors?: any[] = [];
    public sizeType?: string;
    public nameLabel?: string;
    public isProwlerCustomSize?: boolean = false;

    constructor(from?: Object) {
        // If `from` is given then initialize `this` model with `from`.
        if (typeof from === 'object') {
            Object.assign(this, from);
        }
    }
}
