import * as tslib_1 from "tslib";
import { Model } from './model';
var DataProvider = /** @class */ (function (_super) {
    tslib_1.__extends(DataProvider, _super);
    function DataProvider() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DataProvider.prototype.serialize = function () {
        return JSON.stringify(this);
    };
    Object.defineProperty(DataProvider.prototype, "entity", {
        get: function () {
            return 'Data Provider';
        },
        enumerable: true,
        configurable: true
    });
    DataProvider.ADOBE_LIVE_AUDIENCE = 69;
    DataProvider.BLUEKAI_LIVE_AUDIENCE = 70;
    DataProvider.NEUSTAR_LIVE_AUDIENCE = 73;
    DataProvider.WEBSITE_AUDIENCE_SEGMENT_PROVIDER = 75;
    DataProvider.LOTAME_LIVE_AUDIENCE = 78;
    DataProvider.DET_EXPANDED_SEGMENT_PROVIDER = 81;
    DataProvider.PRO_EXPANDED_SEGMENT_PROVIDER = 82;
    DataProvider.REPLACEABLE_AUDIENCE_SEGMENT_PROVIDER = 83;
    DataProvider.LIVE_AUDIENCE_SEGMENT_PROVIDER = 84;
    DataProvider.MERKLE_LIVE_AUDIENCE = 85;
    DataProvider.LIVERAMP_LIVE_AUDIENCE = 86;
    DataProvider.MEDIAMATH_LIVE_AUDIENCE = 87;
    DataProvider.DYNAMIC_SEGMENT_ALOGIRTHMIC_RETARGETING = 88;
    DataProvider.WEBSITE_SEGMENT_URL_PROVIDER = 89;
    DataProvider.WEBSITE_SEGMENT_EVENT_PROVIDER = 90;
    return DataProvider;
}(Model));
export { DataProvider };
