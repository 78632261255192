import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnDestroy
} from '@angular/core';

import { Observable, Subscription, interval } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import { IdService } from 'app/core/id.service';

@Component({
    selector: 'alertnext',
    templateUrl: './alert.html',
    styleUrls: ['./alert.styl']
})
export class AlertnextComponent implements OnDestroy {
    @Input() title: string;
    @Input() message: string;
    @Output() dismissed = new EventEmitter();

    private timer$: Observable<string> = null;
    private dismissing: Subscription;

    constructor(
        public id: IdService
    ){}

    ngOnDestroy() {
        if (this.dismissing) {
            this.dismissing.unsubscribe();
        }
    }

    timeout(seconds: number) {
        this.timer$ = interval(1000).pipe(
            map(n => seconds - n),
            takeWhile(n => n > 0),
            map(n => n.toString() + 's')
        );

        this.dismissing = this.timer$.subscribe(null, null, () => {
            this.dismiss();
        });
    }

    dismiss() {
        this.dismissed.emit();
    }

    get hasTitle() {
        return typeof this.title === 'string';
    }

    get hasMessage() {
        return typeof this.message === 'string';
    }
}
