import * as tslib_1 from "tslib";
import { Model } from './model';
var User = /** @class */ (function (_super) {
    tslib_1.__extends(User, _super);
    function User(attributes) {
        if (attributes === void 0) { attributes = {}; }
        var _this = this;
        var hashId = typeof attributes.id === 'string' && attributes.id.length === 32
            ? attributes.id
            : (attributes.hash_id || attributes.hashId);
        var refId = typeof attributes.refId === 'number' ? attributes.refId : attributes.id;
        var id = refId;
        _this = _super.call(this, Object.assign({
            firstName: (attributes.name || '').split(' ').slice(0, -1).join(' '),
            lastName: (attributes.name || '').split(' ').slice(-1).join(' ')
        }, attributes, {
            hashId: hashId,
            refId: refId,
            id: id
        })) || this;
        return _this;
    }
    Object.defineProperty(User.prototype, "displayInitials", {
        get: function () {
            if (this.firstName && this.lastName) {
                var fNameInitial = this.firstName.charAt(0).toUpperCase();
                var lNameInitial = this.lastName.charAt(0).toUpperCase();
                return fNameInitial + lNameInitial;
            }
        },
        enumerable: true,
        configurable: true
    });
    User.prototype.serialize = function () {
        return JSON.stringify(this);
    };
    Object.defineProperty(User.prototype, "entity", {
        get: function () {
            return 'User';
        },
        enumerable: true,
        configurable: true
    });
    return User;
}(Model));
export { User };
