import { Model } from './model';
import { daysToSeconds, secondsToDays, hoursToSeconds, secondsToHours } from 'app/core/utils';

export class Pixel extends Model {
    static readonly DEFAULT_EXPIRATION_SECONDS = daysToSeconds(30);
    static readonly DEFAULT_PVC_EXPIRATION_IN_HOURS = 72;
    static readonly DEFAULT_EXPIRATION_IN_DAYS = 30;

    advertiser: {
        id: string;
        name: string;
    };
    advertiserRefId?: number;
    created: string;
    expiration: number;
    modified: string;
    pvcExpiration: number;
    sharedWith: string[];
    status: string;
    tagType: string;
    type: string;
    url: string;

    constructor(from?: {}) {
        super(Object.assign({ expiration: Pixel.DEFAULT_EXPIRATION_SECONDS }, from));
    }

    get isLiveConnect() {
        return this.tagType === 'LiveConnect';
    }

    get formattedType() {
        return this.isLiveConnect ? 'LiveConnect' : 'Image Pixel';
    }

    get expirationInDays() {
        return secondsToDays(this.expiration);
    }

    set expirationInDays(days: number) {
        this.expiration = daysToSeconds(days);
    }

    get pvcExpirationInHours() {
        return secondsToHours(this.pvcExpiration);
    }

    set pvcExpirationInHours(days: number) {
        this.pvcExpiration = hoursToSeconds(days);
    }

    serialize(): string {
        const clone = this.clone();

        if (typeof clone.advertiser === 'object') {
            clone.advertiser = clone.advertiser.id;
        }

        return JSON.stringify(clone);
    }

    get entity() {
        return 'Pixel';
    }
}
