import { Model } from './model';

export class PublisherKeyValue extends Model {
    key: string;
    value: string[];

    serialize(): string {
        return JSON.stringify(this.clone(PublisherKeyValue));
    }

    get entity() {
        return 'Publisher Key Value';
    }
}
