import { ChangeDetectorRef, OnInit, ElementRef, } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { ReplaySubject, of, fromEvent, combineLatest } from 'rxjs';
import { AuthorizationService } from 'app/core';
import { FilterString, Operation } from 'app/shared/elements/filters';
import { LIESRepository, SorobanRepository } from 'app/core/repositories';
import { PopoverComponent } from 'app/shared/elements/popover';
import { TableMode, TableURLService } from 'app/shared/elements/table';
import { debounceTime, switchMap, tap, map, distinctUntilChanged, } from 'rxjs/operators';
import { IdService } from 'app/core/id.service';
import { GAEventCategory, GAHelper } from 'app/shared/helpers/ga-helper';
import { getESTotal } from 'app/shared/helpers/es-helper';
import { Flag, LaunchDarklyService } from 'app/core/launch-darkly.service';
var GlobalSearchInputComponent = /** @class */ (function () {
    function GlobalSearchInputComponent(tableURLService, auth, router, cdr, liesRepository, launchDarklyService, id, sorobanRepository) {
        var _this = this;
        this.tableURLService = tableURLService;
        this.auth = auth;
        this.router = router;
        this.cdr = cdr;
        this.liesRepository = liesRepository;
        this.launchDarklyService = launchDarklyService;
        this.id = id;
        this.sorobanRepository = sorobanRepository;
        this.useNewSearchExperience = false;
        this.query$ = new ReplaySubject();
        this.results$ = combineLatest([
            this.query$.pipe(debounceTime(100)),
            this.launchDarklyService
                .getVariation(Flag.NewSearchExperienceInMaverick)
                .pipe(distinctUntilChanged()),
        ]).pipe(switchMap(function (_a) {
            var query = _a[0], useNewSearchExperience = _a[1];
            _this.useNewSearchExperience = useNewSearchExperience;
            if (query.length > 1) {
                if (_this.useNewSearchExperience) {
                    return _this.sorobanRepository.sorobanSearch(query);
                }
                else {
                    return _this.liesRepository.globalsearch(query);
                }
            }
            return of(null);
        }));
        this.getESTotal = getESTotal;
    }
    GlobalSearchInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationStart) {
                _this.searchElement.nativeElement.blur();
            }
        });
        fromEvent(this.searchElement.nativeElement, 'input')
            .pipe(tap(function () { return _this.cdr.markForCheck(); }), map(function () { return _this.searchElement.nativeElement.value; }), distinctUntilChanged())
            .subscribe(function (query) { return _this.query$.next(query); });
    };
    GlobalSearchInputComponent.prototype.enter = function () {
        GAHelper.trackEvent(GAEventCategory.GLOBAL_SEARCH_INTERACTION, "Global Search", "Global Search Interaction : Enter");
        this.router.navigate(['/search'], {
            queryParams: this.generateQueryParams(),
        });
    };
    GlobalSearchInputComponent.prototype.focus = function () {
        this.popover.show({
            currentTarget: this.searchElement.nativeElement,
            stopPropagation: function () { },
        });
    };
    GlobalSearchInputComponent.prototype.blur = function () {
        this.searchElement.nativeElement.value = '';
        this.popover.hide();
    };
    GlobalSearchInputComponent.prototype.mousedown = function (event) {
        // Allow for `select` to be called.
        event.preventDefault();
    };
    GlobalSearchInputComponent.prototype.canViewInsertionOrders = function () {
        return this.auth.isInternalUser;
    };
    GlobalSearchInputComponent.prototype.generateQueryParams = function () {
        var filter = new FilterString({
            field: 'name',
            operation: Operation.Contains,
            query: this.searchElement.nativeElement.value,
        });
        this.tableURLService.pushFilters('q', [filter]);
        this.tableURLService.pushMode('q', TableMode.Basic);
        return this.tableURLService.generate('q');
    };
    return GlobalSearchInputComponent;
}());
export { GlobalSearchInputComponent };
