import { Model } from '../model';

export enum DemandImageAssetType {
    Icon = 1,
    Main = 3
}

export const DemandImageAssets = [{
    id: DemandImageAssetType.Icon,
    key: 'logoImage'
}, {
    id: DemandImageAssetType.Main,
    key: 'mainImage'
}];

export class DemandImageAsset extends Model {
    type: DemandImageAssetType;
    width: number;
    height: number;
    imageUrl: string;

    constructor(data: any = {}) {
        super(data);
    }

    get entity(): string {
        return 'Native Demand Image Asset';
    }
}