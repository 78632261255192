import { UserRepository } from "app/core/repositories";
import { forkJoin, of } from "rxjs";
import { MediaGroup, User } from "../models";

export const getManagersExecutivesForMediaGroups = (mediaGroups: MediaGroup[], userRepo: UserRepository) => {
    const managerIds = mediaGroups.map(mediaGroup => mediaGroup.manager);
    const executiveIds = mediaGroups.map(mediaGroup => mediaGroup.executive);
    let userIds = managerIds.concat(executiveIds).filter(userId => userId);
    // Get rid of duplicates
    userIds = Array.from(new Set(userIds));
    if (userIds && userIds.length) {
        return forkJoin([of(mediaGroups), userRepo.search({
            conditions: [{ field: 'hash_id', value: userIds }]
        })]);
    }
    return forkJoin([of(mediaGroups), of(new Array<User>())]);
}

export const decorateMediaGroupsWithNames = ([mediaGroups, users]: [MediaGroup[], User[]]) => {
    const idToName = new Map<string, string>();
    users.forEach(user => idToName.set(user.hashId, user.name));
    mediaGroups.forEach(mediaGroup => {
        if (idToName.has(mediaGroup.manager)) {
            mediaGroup.managerName = idToName.get(mediaGroup.manager);
        }
        if (idToName.has(mediaGroup.executive)) {
            mediaGroup.executiveName = idToName.get(mediaGroup.executive);
        }
    });
    return of(mediaGroups);
};
