import * as tslib_1 from "tslib";
import { Model } from './model';
import { DataProvider } from './data-provider';
import { SharingControl } from './sharing-control';
import { daysToSeconds, secondsToDays } from 'app/core/utils';
var Audience = /** @class */ (function (_super) {
    tslib_1.__extends(Audience, _super);
    function Audience(data) {
        if (data === void 0) { data = {}; }
        var _this = _super.call(this) || this;
        _this.sharedWith = [];
        Object.assign(_this, { sharedWith: [] }, data);
        _this.applyDefaultTTL();
        return _this;
    }
    Object.defineProperty(Audience.prototype, "isLiveAudience", {
        get: function () {
            return this.dataProviderId === DataProvider.LIVE_AUDIENCE_SEGMENT_PROVIDER;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Audience.prototype, "isDynamicRetargeting", {
        get: function () {
            return this.dataProviderId === DataProvider.DYNAMIC_SEGMENT_ALOGIRTHMIC_RETARGETING;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Audience.prototype, "isDynamicURL", {
        get: function () {
            return this.dataProviderId === DataProvider.WEBSITE_SEGMENT_URL_PROVIDER
                || (!this.isDynamicEvent && this.dataProviderId === DataProvider.WEBSITE_AUDIENCE_SEGMENT_PROVIDER);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Audience.prototype, "isDynamicEvent", {
        get: function () {
            return this.dataProviderId === DataProvider.WEBSITE_SEGMENT_EVENT_PROVIDER
                || (this.metadata
                    && (this.metadata.eventName || this.metadata.eventNameRegex)
                    && this.dataProviderId === DataProvider.WEBSITE_AUDIENCE_SEGMENT_PROVIDER);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Audience.prototype, "isDynamicSegment", {
        get: function () {
            return this.isDynamicURL || this.isDynamicEvent || this.isDynamicRetargeting;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Audience.prototype, "isExpandedSegment", {
        get: function () {
            return this.dataProviderId === DataProvider.DET_EXPANDED_SEGMENT_PROVIDER
                || this.dataProviderId === DataProvider.PRO_EXPANDED_SEGMENT_PROVIDER;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Audience.prototype, "isReplaceableAudience", {
        get: function () {
            return this.dataProviderId === DataProvider.REPLACEABLE_AUDIENCE_SEGMENT_PROVIDER;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Audience.prototype, "typeLabel", {
        get: function () {
            switch (this.dataProviderId) {
                case DataProvider.WEBSITE_AUDIENCE_SEGMENT_PROVIDER:
                    return { main: 'Website Audience', sub: '' };
                case DataProvider.REPLACEABLE_AUDIENCE_SEGMENT_PROVIDER:
                    return { main: 'Custom Audience', sub: '' };
                case DataProvider.LIVE_AUDIENCE_SEGMENT_PROVIDER:
                    return { main: 'Custom Audience', sub: '' };
                case DataProvider.DET_EXPANDED_SEGMENT_PROVIDER:
                    return { main: 'Expanded', sub: 'Deterministic' };
                case DataProvider.PRO_EXPANDED_SEGMENT_PROVIDER:
                    return { main: 'Expanded', sub: 'Probabilistic' };
                case DataProvider.ADOBE_LIVE_AUDIENCE:
                    return { main: 'Custom Audience', sub: 'Adobe' };
                case DataProvider.BLUEKAI_LIVE_AUDIENCE:
                    return { main: 'Custom Audience', sub: 'Bluekai' };
                case DataProvider.DYNAMIC_SEGMENT_ALOGIRTHMIC_RETARGETING:
                    return { main: 'Dynamic', sub: 'Product Remarketing' };
                case DataProvider.LOTAME_LIVE_AUDIENCE:
                    return { main: 'Custom Audience', sub: 'Lotame' };
                case DataProvider.MERKLE_LIVE_AUDIENCE:
                    return { main: 'Custom Audience', sub: 'Merkle' };
                case DataProvider.LIVERAMP_LIVE_AUDIENCE:
                    return { main: 'Custom Audience', sub: 'LiveRamp' };
                case DataProvider.MEDIAMATH_LIVE_AUDIENCE:
                    return { main: 'Custom Audience', sub: 'MediaMath' };
                case DataProvider.WEBSITE_SEGMENT_URL_PROVIDER:
                    return { main: 'Website Audience', sub: 'URL' };
                case DataProvider.WEBSITE_SEGMENT_EVENT_PROVIDER:
                    return { main: 'Website Audience', sub: 'Event' };
                default:
                    return { main: '—', sub: '' };
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Audience.prototype, "eventType", {
        get: function () {
            if (this.isDynamicURL) {
                return 'url';
            }
            else if (this.isDynamicEvent) {
                return 'event';
            }
            return '—';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Audience.prototype, "matchRate", {
        get: function () {
            if (this.records < 1) {
                return null;
            }
            return this.matchedRecords / this.records;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Audience.prototype, "ttlInDays", {
        get: function () {
            return secondsToDays(this.ttl);
        },
        set: function (days) {
            this.ttl = daysToSeconds(days);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Audience.prototype, "sharingControl", {
        get: function () {
            if (this.isShared) {
                return SharingControl.AgencyWide;
            }
            else if (Array.isArray(this.sharedWith) && this.sharedWith.length > 0) {
                return SharingControl.AdvertiserSpecific;
            }
            else {
                return SharingControl.Private;
            }
        },
        enumerable: true,
        configurable: true
    });
    Audience.prototype.serialize = function () {
        var audience = this.clone(Audience);
        delete audience._isClone;
        if (audience.conversionId === null) {
            delete audience.conversionId;
        }
        if (audience.isShared === null) {
            delete audience.isShared;
        }
        this.applyDefaultTTL();
        return JSON.stringify(audience);
    };
    Audience.prototype.applyDefaultTTL = function () {
        if (typeof this.ttl === 'number') {
            return;
        }
        if (this.isDynamicSegment) {
            this.ttl = daysToSeconds(30);
        }
        else {
            this.ttl = null;
        }
    };
    Object.defineProperty(Audience.prototype, "entity", {
        get: function () {
            return 'Audience';
        },
        enumerable: true,
        configurable: true
    });
    Audience.expansionTypes = [
        'det', 'prob'
    ];
    Audience.RETARGETING_DEFAULT_TTL_IN_DAYS = 14;
    Audience.DYNAMIC_AUDIENCE_DEFAULT_TTL_IN_DAYS = 30;
    return Audience;
}(Model));
export { Audience };
