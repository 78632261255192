import * as tslib_1 from "tslib";
import { Model } from './model';
var Deal = /** @class */ (function (_super) {
    tslib_1.__extends(Deal, _super);
    function Deal(from) {
        var _this = _super.call(this, from) || this;
        if (_this.preferred === null) {
            _this.preferred = false;
        }
        return _this;
    }
    Deal.prototype.serialize = function () {
        var deal = JSON.parse(JSON.stringify(this));
        if (this.isEmptyArray(deal.domains)) {
            deal.domains = null;
            delete deal.domainTargetingType;
        }
        if (this.isEmptyArray(deal.keywords)) {
            deal.keywords = null;
            delete deal.keywordTargetingType;
        }
        if (deal.preferred === null) {
            deal.preferred = false;
        }
        return deal;
    };
    Deal.prototype.isEmptyArray = function (prop) {
        return Array.isArray(prop) && prop.length === 0;
    };
    Object.defineProperty(Deal.prototype, "entity", {
        get: function () {
            return 'Deal';
        },
        enumerable: true,
        configurable: true
    });
    return Deal;
}(Model));
export { Deal };
