import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { map, switchMap, take } from 'rxjs/operators';

import { BaseTableHelper } from 'app/shared/helpers/base-table-helper';
import { DownloadHelper } from 'app/shared/helpers/download-helper';
import { LightboxnextComponent } from 'app/shared/elements/lightboxnext';
import { TableColumnDirective } from '../table-column.directive';
import { TableComponent } from '../table.component';
import { ValidationService } from 'app/core/validation-service';

@Component({
    selector: 'app-table-download-button',
    templateUrl: './table-download-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableDownloadButtonComponent<T> implements OnInit {
    @ViewChild(LightboxnextComponent, { static: true }) lightbox: LightboxnextComponent;
    @ViewChild(NgForm, { static: true }) form: NgForm;
    @Input('helper') helper: BaseTableHelper<T>;
    @Input('table') table: TableComponent;
    validator = new ValidationService();
    tableColumn = new TableColumnDirective();

    readonly sizes = [
        500, 1000, 2500, 5000, 10000
    ];

    filename: string;
    rows = 500;
    includeHeaders = true;

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.lightbox.hidden.subscribe(() => {
            this.form.reset();
            this.cdr.detectChanges();
            this.rows = 500;
            this.includeHeaders = true;
            this.lightbox.stop();
        });
        this.tableColumn.displayName = 'ID';
        this.tableColumn.name = 'refId';
    }

    download() {
        this.lightbox.load('Preparing your download');

        this.helper.query.pipe(
            switchMap(query => {
                query.pageSize = this.rows;
                return this.helper.adapt(query);
            }),
            take(1)
        ).subscribe(data => {
            const rows = data.items.map(item => {
                if (item['entity'] === 'Ad Slot') {
                    this.table.activeColumns.splice(1, 0, this.tableColumn);
                }
                return this.table.activeColumns.reduce((obj, column) => {
                    obj[column.displayName] = item[column.name];

                    return obj;
                }, {});
            });
            DownloadHelper.downloadAsCSV(rows, this.filename, false, this.includeHeaders);

            this.lightbox.stop();
            this.lightbox.hide();
        });
    }
}
