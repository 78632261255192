export enum Status {
    ACTIVE = 'Active',
    READY = 'Ready to Deliver',
    NOT_READY = 'Not Ready',
    DELIVERING = 'Delivering',
    NOT_DELIVERING = 'Not Delivering',
    PAUSED = 'Paused',
    PAUSED_BLACKOUT = 'Paused',
    COMPLETED = 'Completed',
    INACTIVE = 'Inactive',
    NEEDS_ACTIVATION = 'Needs Activation',
    WARNING = 'Warning',
    DELETED = 'Deleted',
    READY_LIMITED = 'Ready, limited',
    INELIGIBLE = 'Ineligible',
    EXPIRED = 'Expired',
    EXPIRING_SOON = 'Expiring Soon',
    UNKNOWN = 'Unknown',
    REJECTED = 'Rejected',
}

export enum StatusId {
    ACTIVE_ID = 1,
}
