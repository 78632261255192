import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import moment from 'moment';

import {
    GroupByDataPoint,
    MidasActivity,
    ReportingQuery,
    Rule,
    TimeSeriesDataPoint,
    TopNDataPoint,
    WhitelistingRule
} from 'app/shared/models';
import { BackendRepository } from 'app/core/repositories/backend-repository';

export enum Granularity {
    All = 'all',
    Day = 'day',
    Hour = 'hour'
}

export type DruidFilter = {
    type: string,
    dimension?: string,
    value?: string,
    field?: DruidFilter,
    fields?: DruidFilter[]
};

export type EventType = 'event' | 'url';

const LGK = 8;

@Injectable()
export class ReportingQueryRepository extends BackendRepository<ReportingQuery> {
    public constructor(http: HttpClient) {
        super(http, '/reporting/query', ReportingQuery);
    }

    public executeSavedQueryCSV(params: { id: string }) {
        return this.http.request('post', this.url(this.path, params.id, 'execute'), {
            headers: new HttpHeaders({ Accept: 'text/csv' })
        }).pipe(
            catchError((err: HttpErrorResponse) => {
                if (!(err.error instanceof ErrorEvent)) {
                    if (typeof err.error === 'object') {
                        return throwError(err.error);
                    }
                    return of(JSON.parse(err.error));
                }

                return throwError('An error occurred executing the query.');
            })
        );

    }

    public executeAdHocQueryCSV(params: ReportingQuery) {
        return this.http.request('post', this.url(this.path, 'execute'), {
            headers: new HttpHeaders({ Accept: 'text/csv' }),
            body: params.serialize()
        }).pipe(
            catchError((err: HttpErrorResponse) => {
                if (!(err.error instanceof ErrorEvent)) {
                    if (typeof err.error === 'object') {
                        return throwError(err.error);
                    }
                    return of(JSON.parse(err.error));
                }

                return throwError('An error occurred executing the query.');
            })
        );
    }

    public executeAdHocJSON(query, type?: string) {
        return this.http.post(this.url(this.path, 'execute'), { query, type }) as Observable<any>;
    }

    public getUserQueries(): Observable<ReportingQuery[]> {
        return this.http.get(this.url(this.path)) as Observable<ReportingQuery[]>;
    }

    public getAllQueries(): Observable<ReportingQuery[]> {
        return this.http.get(this.url(this.path, 'allUsers')) as Observable<ReportingQuery[]>
    }

    public fetchRtbDomains(params: { lookback: string, publishers?: Array<number|string> }): Observable<any> {
        // @ts-ignore
        params.type = 'rtb';
        return this.http.post(this.url(this.path, 'fetch-domains'), params) as Observable<any>;
    }

    public fetchExchangeDomains(params: { lookback: string, publishers?: Array<number|string> }): Observable<any> {
        // @ts-ignore
        params.type = 'exchange';
        return this.http.post(this.url(this.path, 'fetch-domains'), params) as Observable<any>;
    }
    public fetchRtbCreatives(params: { lookback: string, domains: Array<string> , publishers?: Array<number|string> }): Observable<any> {
        // @ts-ignore
        params.type = 'rtb';
        return this.http.post(this.url(this.path, 'fetch-creatives'), params) as Observable<any>;
    }

    public fetchExchangeCreatives(params: { lookback: string, advertisers: Array<number|string> , publishers?: Array<number|string> }): Observable<any> {
        // @ts-ignore
        params.type = 'exchange';
        return this.http.post(this.url(this.path, 'fetch-creatives'), params) as Observable<any>;
    }


    public update(params: { id: string }, model: ReportingQuery): Observable<ReportingQuery> {
        return this.http.put(this.url(this.path, params.id), model.serialize()) as Observable<ReportingQuery>;
    }

    public save(instance: ReportingQuery): Observable<ReportingQuery> {
        // Overriding BackendRepository's method because unlike LSD 'output' field doesn't exist in Wolfman response
        const params = [this.path];
        let id = instance.id as string | number;
        if (typeof id === 'number') {
            id = id.toString();
        }
        if (typeof id === 'string') {
            params.push(id);
        }
        return this.http.post(this.url(...params), instance.serialize()) as Observable<ReportingQuery>;
    }

    public executeCannedReport(data) {
        return this.http.post(this.url(this.path, 'execute-canned'), data);
    }

    /**
     * Determine midas aggregated data source based on interval's start date.
     * midas_events_intermediate has hourly aggregates only for last 7 days
     * midas_events_intermediate_filtered has daily aggregates for 30 days
     * midas_events_intermediate_filtered doesn't include today's data
     */
    getMidasDataSource(start: Date): string {
        return moment(start).isBefore(moment().subtract(7, 'days').startOf('day'))
            ? 'midas_events_intermediate_filtered' : 'midas_events_intermediate';
    }

    getInventoryStats(publisherIds: number[]) {
        const query: any = {
            dataSource: 'custom_aggregates_exact',
            timeZone: 'America/New_York',
            queryType: 'groupBy',
            intervals: [{
                type: 'dynamic',
                value: '30'
            }],
            granularity: 'all',
            postAggregations: [{
                type: 'javascript',
                name: 'effectiveCPM',
                fieldNames: [
                    'revenue',
                    'adjusted_impressions'
                ],
                function: 'function(r, i) { return i ? (r / i) * 1000 : 0; };'
            }],
            aggregations: [{
                fieldName: 'publisher_revenue',
                type: 'doubleSum',
                name: 'revenue'
            }, {
                fieldName: 'adjusted_impressions',
                type: 'longSum',
                name: 'adjusted_impressions'
            }],
            dimensions: [{
                type: 'default',
                dimension: 'publisher_id',
                outputName: 'publisherId'
            }, {
                type: 'default',
                dimension: 'template_id',
                outputName: 'newsletterId'
            }, {
                type: 'default',
                dimension: 'section_id',
                outputName: 'adSlotId'
            }],
            filter: {
                type: 'and',
                fields: [{
                    type: 'in',
                    dimension: 'publisher_id',
                    values: publisherIds.map(id => id.toString())
                }, {
                    type: 'selector',
                    dimension: 'demand_type',
                    value: 'default'
                }]
            }
        };
        return this.executeAdHocJSON(query, 'publisher');
    }

    getMostRecentTagActivity(advertiserRefId: number): Observable<MidasActivity> {
        return this.mostRecentActivity([
            { dimension: 'advertiser_id' , value: advertiserRefId.toString() }
        ]);
    }

    getMostRecentPixelActivity(pixelId: number): Observable<MidasActivity> {
        return this.mostRecentActivity([
            { dimension: 'conversion_tracker_id' , value: pixelId.toString() }
        ]);
    }

    getMostRecentRuleActivity(rule: Rule, advertiserRefId: number): Observable<MidasActivity> {
        return this.mostRecentActivity([
            { dimension: 'soft_validation_result_event' , value: rule.eventNameRegex },
            { dimension: 'soft_validation_result_name' , value: rule.termRegex.toLowerCase() },
            { dimension: 'advertiser_id' , value: advertiserRefId.toString() },
        ]);
    }

    getMostRecentEventActivity(advertiserId: number, eventName: string): Observable<MidasActivity> {
        // empty event name is considered as pageView
        const event = eventName === 'pageView' ? '' : eventName;

        return this.mostRecentActivity([
            { dimension: 'soft_validation_result_event' , value: event },
            { dimension: 'advertiser_id' , value: advertiserId.toString() },
        ]);
    }

    liveConnectOverviewMetrics(advertiserRefId: number, start: Date, end: Date, eventType?: EventType): Observable<TimeSeriesDataPoint> {
        let filters = [];

        if (eventType === 'url') {
            filters = [{
                type: 'not',
                field: {
                    type: 'selector',
                    dimension: 'web_page_url_path',
                    value: null
                }
            }];
        }

        return this.fetchLiveConnectOverviewMetrics(advertiserRefId, start, end, filters);
    }

    private fetchLiveConnectOverviewMetrics(advertiserRefId: number, start: Date, end: Date, filters: DruidFilter[] = [])
        : Observable<TimeSeriesDataPoint> {
        const dataSource = this.getMidasDataSource(start);
        const query = {
            dataSource,
            timeZone: 'America/New_York',
            queryType: 'groupBy',
            granularity: 'all',
            intervals: [
                {
                    type: 'absolute',
                    start: start.toISOString(),
                    end: end.toISOString()
                }
            ],
            aggregations: [
                {
                    type: 'HLLSketchMerge',
                    name: 'site_visits',
                    fieldName: 'event_id_sketch',
                },
                {
                    type: 'HLLSketchMerge',
                    name: 'known_visitors',
                    fieldName: 'all_hashes_sketch',
                },
                {
                    type: 'HLLSketchMerge',
                    name: 'matched_visitors',
                    fieldName: 'auction_hashes_sketch',
                },
                {
                    type: 'HLLSketchMerge',
                    name: 'unique_visitors',
                    fieldName: 'domain_user_id_sketch',
                }
            ],
            filter: {
                type: 'and',
                fields: ([
                    {
                        type: 'selector',
                        dimension: 'advertiser_id',
                        value: advertiserRefId.toString()
                    }
                ] as any[]).concat(filters)
            }
        };

        return this.executeAdHocJSON(query, 'advertiser').pipe(
            // pluck the first since we use granularity all
            map(dataPoints => Array.isArray(dataPoints) && dataPoints.length !== 0 ? dataPoints[0] : {event: {}})
        );
    }

    topUrls(advertiserRefId: number, days = 7, limit = 100): Observable<TopNDataPoint[]> {
        const query = {
            dataSource: 'midas_events_intermediate_filtered',
            queryType: 'topN',
            granularity: 'all',
            dimension: 'web_page_url_path',
            metric: 'matched_visitors',
            timeZone: 'America/New_York',
            threshold: limit,
            intervals: [{
                type: 'dynamic',
                value: days.toString()
            }],
            aggregations: [
                {
                    type: 'HLLSketchMerge',
                    name: 'matched_visitors',
                    fieldName: 'auction_hashes_sketch',
                    lgK: LGK
                }
            ],
            filter: {
                type: 'and',
                fields: [
                    {
                        type: 'selector',
                        dimension: 'advertiser_id',
                        value: advertiserRefId.toString()
                    }
                ]
            }
        };

        return this.executeAdHocJSON(query, 'advertiser');
    }

    topEventTypes(advertiserRefId: number, days = 7, limit = 100): Observable<TopNDataPoint[]> {
        const query = {
            dataSource: 'midas_events_intermediate_filtered',
            queryType: 'topN',
            granularity: 'all',
            dimension: 'soft_validation_result_event',
            metric: 'matched_visitors',
            timeZone: 'America/New_York',
            threshold: limit,
            intervals: [{
                type: 'dynamic',
                value: days.toString()
            }],
            aggregations: [
                {
                    type: 'HLLSketchMerge',
                    name: 'matched_visitors',
                    fieldName: 'auction_hashes_sketch',
                    lgK: LGK
                }
            ],
            filter: {
                type: 'and',
                fields: [
                    {
                        type: 'selector',
                        dimension: 'advertiser_id',
                        value: advertiserRefId.toString()
                    }
                ]
            }
        };

        return this.executeAdHocJSON(query, 'advertiser');
    }

    topEventNames(eventTypes: string[], advertiserRefId: number, days = 7, limit = 100): Observable<TopNDataPoint[]> {
        const query = {
            dataSource: 'midas_events_intermediate_filtered',
            queryType: 'topN',
            granularity: 'all',
            dimension: 'soft_validation_result_name',
            metric: 'matched_visitors',
            timeZone: 'America/New_York',
            threshold: limit,
            intervals: [{
                type: 'dynamic',
                value: days.toString()
            }],
            aggregations: [
                {
                    type: 'HLLSketchMerge',
                    name: 'matched_visitors',
                    fieldName: 'auction_hashes_sketch',
                    lgK: LGK
                }
            ],
            filter: {
                type: 'and',
                fields: [
                    {
                        type: 'selector',
                        dimension: 'advertiser_id',
                        value: advertiserRefId.toString()
                    }
                ]
            }
        };

        if (eventTypes.length > 0) {
            (query.filter.fields as any[]).push({
                type: 'or',
                fields: eventTypes.map(eventType => ({
                    type: 'selector',
                    dimension: 'soft_validation_result_event',
                    value: eventType
                }))
            });
        }

        return this.executeAdHocJSON(query, 'advertiser');
    }

    getPotentialCountsByAdvertiser(advertiserRefId: number, filters: DruidFilter[] = [], days = 7): Observable<TopNDataPoint[]> {
        const query = {
            dataSource: 'midas_events_intermediate_filtered',
            queryType: 'topN',
            granularity: 'all',
            dimension: 'advertiser_id',
            metric: 'unique_visitors',
            timeZone: 'America/New_York',
            threshold: '10',
            intervals: [{
                type: 'dynamic',
                value: days.toString()
            }],
            aggregations: [
                {
                    type: 'HLLSketchMerge',
                    name: 'unique_visitors',
                    fieldName: 'all_hashes_sketch',
                    lgK: LGK
                },
                {
                    type: 'HLLSketchMerge',
                    name: 'matched_visitors',
                    fieldName: 'auction_hashes_sketch',
                    lgK: LGK
                }
            ],
            filter: {
                type: 'and',
                fields: ([
                    {
                        type: 'selector',
                        dimension: 'advertiser_id',
                        value: advertiserRefId.toString()
                    }
                ] as any[]).concat(filters)
            }
        };

        return this.executeAdHocJSON(query, 'advertiser');
    }

    getLiveConnectEvents(advertiserRefId: number, start: Date, end: Date, granularity: Granularity, filters: DruidFilter[] = []):
        Observable<TimeSeriesDataPoint[]> {
        const period = granularity === Granularity.Day ? 'P1D' : 'PT1H';

        const query = {
            queryType: 'timeseries',
            granularity: {
                type: 'period',
                period,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            timeZone: 'America/New_York',
            dataSource: 'midas',
            intervals: [{
                type: 'absolute',
                start: start.toISOString(),
                end: end.toISOString(),
            }],
            aggregations: [
                {
                    type: 'count',
                    name: 'count'
                }
            ],
            filter: {
                type: 'and',
                fields: filters.concat({
                    type: 'selector',
                    dimension: 'advertiser_id',
                    value: advertiserRefId.toString()
                })
            }
        };

        return this.executeAdHocJSON(query, 'advertiser').pipe(
            map(dataPoints => {
                const unitOfTime = granularity === Granularity.Day ? 'day' : 'hour';
                return dataPoints.map(dataPoint => {
                    dataPoint.timestamp = moment(dataPoint.timestamp).startOf(unitOfTime).toISOString();
                    return dataPoint;
                });
            })
        );
    }

    getEventData(advertiserRefId: number, start: Date, end: Date): Observable<GroupByDataPoint[]> {
        const dataSource = this.getMidasDataSource(start);
        const query = {
            dataSource,
            timeZone: 'America/New_York',
            queryType: 'groupBy',
            filter: {
                type: 'and',
                fields: [
                    {
                        type: 'selector',
                        dimension: 'advertiser_id',
                        value: advertiserRefId.toString()
                    }
                ]
            },
            intervals: [
                {
                    type: 'absolute',
                    start: start.toISOString(),
                    end: end.toISOString()
                }
            ],
            granularity: {
                type: 'period',
                period: 'P1D',
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            aggregations: [
                {
                    type: 'HLLSketchMerge',
                    name: 'page_visits',
                    fieldName: 'event_id_sketch'
                },
                {
                    type: 'HLLSketchMerge',
                    name: 'known_visitors',
                    fieldName: 'domain_user_id_sketch'
                }
            ],
            // Druid group by query remove null aggregation from response.
            // We are replacing null (empty) with 'pageView' event.
            // Empty events get generated from base LC tag
            // Empty event name is considered as 'pageView'
            dimensions: [
                {
                    type: 'lookup',
                    dimension: 'soft_validation_result_event',
                    outputName: 'soft_validation_result_event',
                    lookup: {
                        type: 'map',
                        map: {'': 'pageView'}
                    },
                    retainMissingValue: true,
                    injective: false
                }
            ]
        };

        return this.executeAdHocJSON(query, 'advertiser').pipe(
            map(dataPoints => {
                return dataPoints.map(dataPoint => {
                    dataPoint.timestamp = moment(dataPoint.timestamp).startOf('day').toISOString();
                    return dataPoint;
                });
            })
        );
    }

    getUrlData(advertiserRefId: number, start: Date, end: Date): Observable<GroupByDataPoint[]> {
        const dataSource = this.getMidasDataSource(start);
        const query = {
            dataSource,
            timeZone: 'America/New_York',
            queryType: 'groupBy',
            granularity: {
                type: 'period',
                period: 'P1D',
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            intervals: [
                {
                    type: 'absolute',
                    start: start.toISOString(),
                    end: end.toISOString()
                }
            ],
            aggregations: [
                {
                    type: 'HLLSketchMerge',
                    name: 'count',
                    fieldName: 'event_id_sketch'
                }
            ],
            filter: {
                type: 'and',
                fields: [
                    {
                        type: 'selector',
                        dimension: 'advertiser_id',
                        value: advertiserRefId.toString()
                    },
                    {
                        type: 'not',
                        field: {
                            type: 'selector',
                            dimension: 'web_page_url_path',
                            value: null
                        }
                    }
                ]
            }
        };

        return this.executeAdHocJSON(query, 'advertiser').pipe(
            map(dataPoints => {
                return dataPoints.map(dataPoint => {
                    dataPoint.timestamp = moment(dataPoint.timestamp).startOf('day').toISOString();
                    return dataPoint;
                });
            })
        );
    }

    getLiveConnectAggregatedEvents(advertiserRefId: number, start: Date, end: Date): Observable<GroupByDataPoint[]> {
        const dataSource = this.getMidasDataSource(start);
        const query = {
            dataSource,
            timeZone: 'America/New_York',
            queryType: 'groupBy',


            filter: {
                type: 'and',
                fields: [
                    {
                        type: 'selector',
                        dimension: 'advertiser_id',
                        value: advertiserRefId.toString()
                    }
                ]
            },
            intervals: [
                {
                    type: 'absolute',
                    start: start.toISOString(),
                    end: end.toISOString()
                }
            ],
            granularity: 'all',
            aggregations: [
                {
                    type: 'HLLSketchMerge',
                    name: 'page_visits',
                    fieldName: 'event_id_sketch'
                }
            ],
            dimensions: [
                'event_platform',
                {
                    type: 'lookup',
                    dimension: 'soft_validation_result_event',
                    outputName: 'soft_validation_result_event',
                    lookup: {
                        type: 'map',
                        map: {'': 'pageView'}
                    },
                    retainMissingValue: true,
                    injective: false
                }
            ]
        };

        return this.executeAdHocJSON(query, 'advertiser');
    }

    getLiveConnectUrls(advertiserRefId: number, start: Date, end: Date): Observable<TopNDataPoint> {
        const dataSource = this.getMidasDataSource(start);
        const query = {
            dataSource,
            timeZone: 'America/New_York',
            queryType: 'topN',
            metric: 'page_visits',
            filter: {
                type: 'and',
                fields: [
                    {
                        type: 'selector',
                        dimension: 'advertiser_id',
                        value: advertiserRefId.toString()
                    },
                    {
                        type: 'not',
                        field: {
                            type: 'selector',
                            dimension: 'web_page_url_path',
                            value: null
                        }
                    }
                ]
            },
            intervals: [
                {
                    type: 'absolute',
                    start: start.toISOString(),
                    end: end.toISOString()
                }
            ],
            granularity: 'all',
            aggregations: [
                {
                    type: 'HLLSketchMerge',
                    name: 'page_visits',
                    fieldName: 'event_id_sketch'
                },
                {
                    type: 'HLLSketchMerge',
                    name: 'known_visitors',
                    fieldName: 'domain_user_id_sketch'
                }
            ],
            threshold: 1000,
            dimension: 'web_page_url_path'
        };

        return this.executeAdHocJSON(query, 'advertiser').pipe(
            // pluck the first since we use granularity all
            map(dataPoints => Array.isArray(dataPoints) && dataPoints.length !== 0 ? dataPoints[0] : {result: []})
        );
    }

    private mostRecentActivity(filters: Array<{ dimension: string, value: string }>, maxLookBack = 30): Observable<MidasActivity> {
        const start = moment().subtract(maxLookBack, 'days').format('YYYY-MM-DD');
        const end = moment().add(1, 'day').format('YYYY-MM-DD');

        const query: any = {
            queryType: 'scan',
            dataSource: 'midas',
            limit: 1,
            order: 'descending',
            columns: [
                'app_id',
                'advertiser_id',
                'conversion_tracker_id',
                '__time'
            ],
            timeZone: 'America/New_York',
            intervals: [{
                type: 'absolute',
                start,
                end
            }],
            filter: {
                type: 'and',
                fields: filters.map(filter => ({
                    type: 'selector',
                    dimension: filter.dimension,
                    value: filter.value
                }))
            }
        };

        return this.executeAdHocJSON(query, 'advertiser').pipe(
            map(dataPoints => Array.isArray(dataPoints) && dataPoints.length !== 0 ? dataPoints[0] : {}),
            catchError(e => {
                console.log('Got error', e)
                return of({});
            })
        );
    }

    getInsightMetrics(advertiserId: number) {
        const query: any = {
            dataSource: 'advertiser_insights',
            timeZone: 'America/New_York',
            queryType: 'groupBy',
            intervals: [{
                type: 'dynamic',
                value: '30'
            }],
            granularity: 'day',
            aggregations: [
            ],
            dimensions: [{
                type: 'default',
                dimension: 'date',
                outputName: 'date'
            }, {
                type: 'default',
                dimension: 'openers_count',
                outputName: 'openersCount'
            }, {
                type: 'default',
                dimension: 'website_visitors_openers_count',
                outputName: 'websiteVisitorsOpenersCount'
            }, {
                type: 'default',
                dimension: 'website_prospects_count',
                outputName: 'websiteProspectsCount'
            }, {
                type: 'default',
                dimension: 'website_visitors_count',
                outputName: 'websiteVisitorsCount'
            }],
            filter: {
                type: 'selector',
                dimension: 'advertiser_id',
                value: advertiserId.toString()
            }
        };

        return this.executeAdHocJSON(query, 'advertiser');
    }

    getAdSlotImpressionsForDeviceAndSize(refId: number, idType: 'AdSlot' | 'Publisher', since: Date) {
        const query = {
            timeZone: 'America/New_York',
            queryType: 'groupBy',
            dataSource: 'custom_aggregates_inexact_v2',
            granularity: 'all',
            dimensions: [
                'size',
                'device_type'
            ],
            filter: {
                type: 'and',
                fields: []
            },
            aggregations: [
                {
                    type: 'longSum',
                    name: 'impressions',
                    fieldName: 'impressions'
                }
            ],
            intervals: [
                {
                    type: 'absolute',
                    start: since.toISOString(),
                    end: new Date().toISOString()
                }
            ]
        };

        query.filter.fields.push({
            type: 'selector',
            dimension: idType === 'AdSlot' ? 'section_id' : 'publisher_id',
            value: refId.toString()
        });

        return this.executeAdHocJSON(query, 'publisher');
    }

    getAdSlotImpressionsWithBidBucketsBySize(refId: number, idType: 'AdSlot' | 'Publisher', since: Date) {
        const query = {
            timeZone: 'America/New_York',
            queryType: 'groupBy',
            dataSource: 'imp_bid_buckets',
            granularity: 'all',
            dimensions: [
                'size',
                'bid_price_bucket'
            ],
            filter: {
                type: 'and',
                fields: []
            },
            aggregations: [
                {
                    type: 'longSum',
                    name: 'impressions',
                    fieldName: 'impressions'
                }
            ],
            intervals: [
                {
                    type: 'absolute',
                    start: since.toISOString(),
                    end: new Date().toISOString()
                }
            ]
        };

        query.filter.fields.push({
            type: 'selector',
            dimension: idType === 'AdSlot' ? 'section_id' : 'publisher_id',
            value: refId.toString()
        });

        return this.executeAdHocJSON(query, 'publisher');
    }

    getIndexes(advertiserId: number) {
        const query: any = {
            dataSource: 'indexes_insights',
            timeZone: 'America/New_York',
            queryType: 'groupBy',
            granularity: 'day',
            intervals: [{
                type: 'dynamic',
                value: '1'
            }],
            dimensions: [
                'date',
                'days_behind',
                'goal',
                'campaign_id',
                'lc_ctr_rank',
                'non_lc_ctr_rank',
                'avg_ctr',
                'lc_ccr_rank',
                'non_lc_ccr_rank',
                'avg_ccr',
                'lc_ecpm_rank',
                'non_lc_ecpm_rank',
                'avg_ecpm',
                'clicks',
                'conversions',
                'category',
                'demand_type',
                'advertiser_spent'
            ],
            aggregations: [
            ],
            filter: {
                type: 'selector',
                dimension: 'advertiser_id',
                value: advertiserId
            }
        };
        return this.executeAdHocJSON(query, 'advertiser');
    }

    getAllAdvertisersByIndex(index: string) {
        const query = {
            dataSource: 'indexes_insights',
            timeZone: 'America/New_York',
            queryType: 'groupBy',
            granularity: 'day',
            intervals: [
                {
                    type: 'dynamic',
                    value: '1'
                }
            ],
            dimensions: [
                index
            ],
            aggregations: []
        };
        return this.executeAdHocJSON(query, 'advertiser');
    }

    getDemandMetrics(refId: number, entityType: 'LineItem', system: 'ssp' | 'dsp', from: Date, until: Date) {
        const dataSource = system === 'dsp' ? 'custom_exact_dsp' : 'custom_aggregates_exact';
        let dimension;

        if (entityType === 'LineItem') {
            dimension = 'line_item_id';
        }

        const query = {
            timeZone: 'America/New_York',
            queryType: 'groupBy',
            dataSource,
            granularity: 'all',
            dimensions: [
                'line_item_id'
            ],
            filter: {
                type: 'and',
                fields: []
            },
            aggregations: [
                {
                    type: 'longSum',
                    name: 'impressions',
                    fieldName: 'impressions'
                },
                {
                    type: 'longSum',
                    name: 'spend',
                    fieldName: 'advertiser_spent'
                }
            ],
            intervals: [
                {
                    type: 'absolute',
                    start: from.toISOString(),
                    end: until.toISOString()
                }
            ]
        };

        query.filter.fields.push({
            type: 'selector',
            dimension,
            value: refId.toString()
        });

        return this.executeAdHocJSON(query, 'advertiser');
    }
}
