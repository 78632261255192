import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Account } from 'app/shared/models/account';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AccountRepository {
    private path = '/accounts';

    constructor(private http: HttpClient) {}

    all() {
        return this.http.get(this.url(this.path));
    }

    save(instance: Account): Observable<Account> {
        const params = [this.path];
        if (instance.id) {
            params.push(instance.id);
        }
        return this.http.post(this.url(...params), instance.serialize())
            .pipe(map(response => new Account(response)));
    }

    addProvision(accountId, provisionId) {
        return this.http.put(this.url('/accounts', accountId, 'provisions', provisionId), null, {
            responseType: 'text'
        });
    }

    allProvisions(): Observable<any> {
        return this.http.get(this.url('/provisions'));
    }

    /**
     * Builds and returns a URL for the backend API given an array of strings
     * `suffix` which are joined using a forward-slash.
     */
    url(...suffix: string[]): string {
        return environment.backend.heimdall.hostname + suffix.join('/');
    }
}
