import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { Package } from 'app/shared/models';
import { PackageRepository } from 'app/core/repositories';

@Injectable()
export class PackageResolver implements Resolve<Package> {
    constructor(private packageRepository: PackageRepository) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.packageRepository.get(route.params['id']);
    }
}
