import * as tslib_1 from "tslib";
import { Model } from '../model';
export var DemandImageAssetType;
(function (DemandImageAssetType) {
    DemandImageAssetType[DemandImageAssetType["Icon"] = 1] = "Icon";
    DemandImageAssetType[DemandImageAssetType["Main"] = 3] = "Main";
})(DemandImageAssetType || (DemandImageAssetType = {}));
var ɵ0 = DemandImageAssetType.Icon, ɵ1 = DemandImageAssetType.Main;
export var DemandImageAssets = [{
        id: ɵ0,
        key: 'logoImage'
    }, {
        id: ɵ1,
        key: 'mainImage'
    }];
var DemandImageAsset = /** @class */ (function (_super) {
    tslib_1.__extends(DemandImageAsset, _super);
    function DemandImageAsset(data) {
        if (data === void 0) { data = {}; }
        return _super.call(this, data) || this;
    }
    Object.defineProperty(DemandImageAsset.prototype, "entity", {
        get: function () {
            return 'Native Demand Image Asset';
        },
        enumerable: true,
        configurable: true
    });
    return DemandImageAsset;
}(Model));
export { DemandImageAsset };
export { ɵ0, ɵ1 };
