import { Injectable } from '@angular/core';
import { PreferencesService } from 'app/core/preferences.service';
import { AuthorizationService } from 'app/core/authorization.service';
import { Advertiser, Audience, Campaign } from 'app/shared/models';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Flag, LaunchDarklyService } from 'app/core/launch-darkly.service';
import { distinctUntilChanged } from 'rxjs/operators';

export enum Action {
    Create,
    Edit,
    UploadFile,
    ViewDetails,
    Delete,
    ViewHistory,
    ViewTags,
    CreateConversionTracker,
    CreateDynamicAudience,
    ViewActivity,
    AudienceExtension
}

export enum AudienceAction {
    Create,
    CreateUrlBasedAudience,
    Modify,
    AudienceExpansion,
    Add,
    Remove,
    Edit,
    Replace,
    Delete,
    AudienceExtension
}

@Injectable()
export class AvailableActionsService {
    private rolloutAudienceExtension$: BehaviorSubject<boolean> = new BehaviorSubject(null);
    rolloutAudienceExtension: boolean = false;

    constructor(
        public preferencesService: PreferencesService,
        public authorization: AuthorizationService,
        private launchDarklyService: LaunchDarklyService
    ) {
        this.launchDarklyService
            .getVariation(Flag.RolloutAudienceExtension)
            .pipe(distinctUntilChanged())
            .subscribe(rolloutAudienceExtension => {
                this.rolloutAudienceExtension$.next(rolloutAudienceExtension);
            });
    }

    private options = {
        edit: {label: 'Edit', key: 'edit', value: 'edit'},
        pause: {label: 'Pause Campaign', key: 'pause', value: 'pause'},
        copy: {label: 'Copy', key: 'copy', value: 'copy'},
        clone: {label: 'Clone', key: 'clone', value: 'clone'},
        delete: {label: 'Delete', key: 'delete', value: 'delete'},
        watch: {label: 'Watch', key: 'watch', value: 'watch'},
        unwatch: {label: 'Stop Watching', key: 'unwatch', value: 'unwatch'},
        notify: {label: 'Send Notification', key: 'notify', value: 'notify'},
        view: { label: 'View Details', value: 'view', key: 'view'},
        viewHistory: {label: 'View History', value: 'history', key: 'history'},
        link: {label: 'Connect', value: 'connect', key: 'connect', action: 'connect'},
        unlink: {label: 'Disconnect', value: 'disconnect', key: 'disconnect', action: 'disconnect'},
        activate: {label: 'Activate', value: 'activate', key: 'activate', action: 'activate'},
        audienceExtension: {label: 'Audience Extension', value: 'audienceExtension', key: 'audienceExtension', action: 'audienceExtension'}
    };

    private readonly noActions = [];

    private readonly liveAudienceActions = [
        { label: 'Edit', value: AudienceAction.Edit, key: 0 },
        { label: 'Add to Audience', value: AudienceAction.Add, key: 1 },
        { label: 'Remove from Audience', value: AudienceAction.Remove, key: 2 },
    ];

    private readonly dynamicSegmentInternalUserActions = [];

    // Now that we can edit DMP partner audience names,
    // we default to edit only rather than no actions
    private readonly defaultEditOnlyActions = [
        { label: 'Edit Audience', value: AudienceAction.Edit, key: 0 }
    ];

    private readonly dynamicSegmentExternalUserActions = [];
    private readonly expandedSegmentActions = [
        { label: 'Edit', value: AudienceAction.Edit, key: 0 },
        { label: 'Add to Audience', value: AudienceAction.Add, key: 1 },
        { label: 'Remove from Audience', value: AudienceAction.Remove, key: 2 },
        { label: 'Replace Audience', value: AudienceAction.Replace, key: 3 }
    ];

    private readonly defaultRuleDynamicSegmentActions = [];

    audienceActions(audience: Audience) {
        if (!audience.advertiser) {
            return [];
        }

        if (audience.isLiveAudience || audience.isReplaceableAudience) {
            return this.liveAudienceActions.filter(option => {
                return option.value !== AudienceAction.AudienceExpansion || this.authorization.isAdmin || this.authorization.isAdOps;
            });
        }

        if (audience.isExpandedSegment) {
            return this.expandedSegmentActions;
        }

        if (audience.isDynamicSegment) {
            if (!this.authorization.isInternalUser && !this.authorization.isMediaGroupAudience) {
                return this.dynamicSegmentExternalUserActions;
            }

            if (audience.externalId !== null) {
                return this.dynamicSegmentInternalUserActions;
            }

            return this.defaultRuleDynamicSegmentActions;
        }

        return this.defaultEditOnlyActions;
    }

    public genAdvertiserActions(advertiser: any) {
        const availableActions = [this.options.edit, this.options.viewHistory];

        if (advertiser.lineItemsObj && this.canDeleteEntity(advertiser)) {
            availableActions.push(this.options.delete);
        }

        return availableActions;
    }

    public genInsertionOrderActions(insertionOrder: any) {
        const availableActions = [this.options.edit];

        if (this.canDeleteEntity(insertionOrder)) {
            availableActions.push(this.options.delete);
        }

        return this.clone(availableActions);
    }

    public genCampaignActions(campaign: any) {
        const availableActions = [this.options.edit];

        if (this.authorization.isAdmin || this.authorization.isAdOps) {
            availableActions.push(this.options.pause);
        }

        if (this.canAudienceExtend(campaign)) {
            availableActions.push(this.options.audienceExtension);
        }

        availableActions.push(this.options.viewHistory);

        if (this.canDeleteEntity(campaign)) {
            availableActions.push(this.options.delete);
        }

        return  availableActions.slice(0);
    }

    public genLineItemActions(lineItem: any): Observable<any[]> {
        return of([this.options.edit]);
    }

    public genLiveConnectAdvertiserActions(status: string) {
        switch (status) {
            case 'active':
                return [
                    { label: 'View Tags', value: Action.ViewTags, key: 0 }
                ];
            case 'inactive':
                return [
                    { label: 'Setup Tags', value: Action.ViewTags, key: 0 }
                ];
        }
    }

    public genAdActions(ad: any) {
        const availableActions = [this.options.edit, this.options.copy];

        return this.clone(availableActions);
    }

    public genConversionTrackerActions(pixel: any) {
        return [
            { label: 'View Tags', value: Action.ViewTags, key: 0 }
        ];
    }

    public getConversionTrackerDetailsActions(advertiser: Advertiser) {
        const options = [{ label: 'Edit', value: Action.Edit, key: 0 }];
        if (advertiser) {
            options.push({ label: 'View Tags', value: Action.ViewTags, key: 2 });
            options.push({ label: 'View Activity', value: Action.ViewActivity, key: 3 });
        }
        return options;
    }

    public genBlueprintActions(blueprint: any, linkedBlueprintId?:any) {
        const availableActions = [];

        if (linkedBlueprintId && blueprint.id === linkedBlueprintId) {
            availableActions.push(this.options.unlink);
        } else {
            availableActions.push(this.options.link);
        }

        return  availableActions;
    }

    public genNativeAdSlotActions(adSlot: any) {
        const availableActions = [this.options.edit];

        if (adSlot.status !== 'active' && adSlot.status !== 'archived' && this.authorization.isAdmin) {
            availableActions.push(this.options.activate);
        }

        return  availableActions;
    }

    // helper functions

    private hasZeroSpend(entityObj: any): boolean {
        return !entityObj.spend || Number(entityObj.spend) === 0;
    }

    private hasNonActiveStatus(entityObj: any): boolean {
        return entityObj.status !== 'active';
    }

    private clone(arr) {
        return JSON.parse(JSON.stringify(arr));
    }

    private instanceOfStrategy(entity): boolean {
        return entity.hasOwnProperty('campaign') &&
            entity.hasOwnProperty('creatives') &&
            entity.hasOwnProperty('domainTargetingType') &&
            entity.hasOwnProperty('domains') &&
            entity.hasOwnProperty('version');
    }

    private instanceOfCreative(entity): boolean {
        return entity.hasOwnProperty('clickUrl') &&
            entity.hasOwnProperty('width') &&
            entity.hasOwnProperty('height') &&
            entity.hasOwnProperty('version');
    }

    private instanceOfCampaign(entity): boolean {
        return entity.hasOwnProperty('demandType')
            && entity.hasOwnProperty('secondPrice')
            && entity.hasOwnProperty('pricingModel');
    }

    // Can accept Advertisers, Insertion Orders, Campaigns, Line Items, Ads as a parameter
    public canDeleteEntity(entity: any): boolean {
        if (this.instanceOfStrategy(entity)) {
            return this.hasZeroSpend(entity) && this.hasNonActiveStatus(entity);
        }

        if (this.instanceOfCreative(entity)) {
            return false; // No creatives can be deleted. This is temporary.
        }

        if (this.instanceOfCampaign(entity)) {
            if (entity.spend > 0) {
                return false;
            }
        }

        // Others
        if (!entity.lineItemsObj) {
            // throw new Error('lineItemsObj should exist as a property of entity');
            return false;
        }

        return (entity.lineItemsObj).every(lineItem => {
            return this.hasZeroSpend(lineItem) && this.hasNonActiveStatus(lineItem);
        });
    }

    public genBuyerSeatActions() {
        const availableActions = [this.options.edit];
        if (this.authorization.isAdmin) {
            availableActions.push(this.options.delete);
        }
        return this.clone(availableActions);
    }

    public canAudienceExtend(campaign: Campaign): boolean {
        return (campaign.isDirectSold && campaign.canAccessAudienceExtension);
    }
}
