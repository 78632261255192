import { EventEmitter, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { ESPRepository } from 'app/core/repositories';
import { ReplaySubject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/index';
import options from './email-service-provider.options.json';
import { EspProperties, EspUserMacroTagTypeMap, Tag } from 'app/shared/models/esp';
import { SelectDropdownComponent } from 'app/shared/elements/select-dropdown';
var TYPE_NEWSLETTER = 'newsletter';
var EmailServiceProviderCardComponent = /** @class */ (function () {
    function EmailServiceProviderCardComponent(espRepository) {
        this.espRepository = espRepository;
        this.showHeader = true;
        this.showSupportBlurb = true;
        this.showPublisherCopySetting = true;
        this.isBulkEdit = false;
        this.espSettingsChangedEvent = new EventEmitter();
        this.options = options;
        this.espOptions = new ReplaySubject(1);
        this.userTagReplacementOptions = new ReplaySubject(1);
        this.placementTagReplacementOptions = new ReplaySubject(1);
        this.listTagReplacementOptions = new ReplaySubject(1);
        this.lceeTagReplacementOptions = new ReplaySubject(1);
        this.lceeKeyReplacementOptions = new ReplaySubject(1);
        this.espLabel = new ReplaySubject(1);
        this.userTagAllowCustom = false;
        this.placementTagAllowCustom = false;
        this.listTagAllowCustom = false;
        this.lceeKeyAllowCustom = false;
        this.lceeTagAllowCustom = false;
        this.Tag = Tag;
        this.EspUserMacroTagTypeMap = EspUserMacroTagTypeMap;
        this.showAdvanced = false;
        this._includeListParam = false;
        this.allEsps = {};
        this.esp$ = new BehaviorSubject('');
        this.userTagReplacement$ = new BehaviorSubject('');
        this.userTagType$ = new BehaviorSubject('');
        this.placementTagReplacement$ = new BehaviorSubject('');
        this.listTagReplacement$ = new BehaviorSubject('');
        this.lceeKeyReplacement$ = new BehaviorSubject('');
        this.lceeTagReplacement$ = new BehaviorSubject('');
        this.placementKey = EspProperties[Tag.PLACEMENT].label;
        this.listKey = EspProperties[Tag.LIST].label;
        this.EspProperties = EspProperties;
        this._userTagReplacementOptions = [];
        this._placementTagReplacementOptions = [];
        this._listTagReplacementOptions = [];
        this._lceeTagReplacementOptions = [];
        this._lceeKeyReplacementOptions = [];
    }
    Object.defineProperty(EmailServiceProviderCardComponent.prototype, "entity", {
        set: function (value) {
            if (value) {
                this._data = value;
                this.loadEspSettings(value, this.isBulkEdit);
            }
        },
        enumerable: true,
        configurable: true
    });
    EmailServiceProviderCardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadEsps();
        if (this.isNewsletter() && this.isNew()) {
            this.loadEspSettings(this._data.publisherObj);
        }
        this.espOptions.subscribe(function (esps) {
            if (esps.length > 0 && _this.esp) {
                // ESP options are now loaded, set the selected ESP
                _this.esp$.next(_this.esp);
            }
        });
        combineLatest([this.userTagType$, this.esp$])
            .subscribe(function (_a) {
            var userTagType = _a[0], esp = _a[1];
            if (_this.esp && _this.userTagType) {
                _this.buildUserTagReplacementOptions(userTagType);
            }
        });
        this.esp$.subscribe(function () {
            if (_this.esp && _this.allEsps) {
                _this.buildReplacementOptions();
            }
        });
        this.userTagReplacementOptions.subscribe(function (options) {
            if (options) {
                _this._userTagReplacementOptions = options;
            }
        });
        this.placementTagReplacementOptions.subscribe(function (options) { return _this._placementTagReplacementOptions = options; });
        this.listTagReplacementOptions.subscribe(function (options) { return _this._listTagReplacementOptions = options; });
        this.lceeKeyReplacementOptions.subscribe(function (options) { _this._lceeKeyReplacementOptions = options; });
        this.lceeTagReplacementOptions.subscribe(function (options) { return _this._lceeTagReplacementOptions = options; });
    };
    EmailServiceProviderCardComponent.prototype.buildReplacementOptions = function () {
        this.buildUserTagReplacementOptions(this.userTagType);
        this.buildPlacementTagReplacementOptions();
        this.buildListTagReplacementOptions();
        this.buildLceeKeyReplacementOptions();
        this.buildLceeTagReplacementOptions();
    };
    Object.defineProperty(EmailServiceProviderCardComponent.prototype, "esp", {
        get: function () {
            return this._esp;
        },
        set: function (value) {
            if (!value) {
                this.clearSettings();
            }
            this.changeEsp(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmailServiceProviderCardComponent.prototype, "userTagReplacement", {
        get: function () {
            return this._userTagReplacement;
        },
        set: function (value) {
            this.changeUserTagReplacement(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmailServiceProviderCardComponent.prototype, "userTagType", {
        get: function () {
            return this._userTagType;
        },
        set: function (value) {
            this.changeUserTagReplacement(null);
            this.changeUserTagType(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmailServiceProviderCardComponent.prototype, "placementTagReplacement", {
        get: function () {
            return this._placementTagReplacement;
        },
        set: function (value) {
            this.changePlacementTagReplacement(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmailServiceProviderCardComponent.prototype, "listTagReplacement", {
        get: function () {
            return this._listTagReplacement;
        },
        set: function (value) {
            this.changeListTagReplacement(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmailServiceProviderCardComponent.prototype, "lceeKeyReplacement", {
        get: function () {
            return this._lceeKeyReplacement;
        },
        set: function (value) {
            this.changeLceeKeyReplacement(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmailServiceProviderCardComponent.prototype, "lceeTagReplacement", {
        get: function () {
            return this._lceeTagReplacement;
        },
        set: function (value) {
            this.changeLceeTagReplacement(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmailServiceProviderCardComponent.prototype, "includeListParam", {
        get: function () {
            return this._includeListParam;
        },
        set: function (value) {
            this._includeListParam = value;
            if (!value) {
                this.changeListTagReplacement(null);
            }
            else {
                this.buildListTagReplacementOptions();
            }
        },
        enumerable: true,
        configurable: true
    });
    EmailServiceProviderCardComponent.prototype.loadEspSettings = function (data, force) {
        if (force === void 0) { force = false; }
        if (!data || !this._data) {
            return;
        }
        if (data.emailTagType || force) {
            this.changeUserTagType(data.emailTagType);
        }
        if (data.emailTagReplacement || force) {
            this.changeUserTagReplacement(data.emailTagReplacement);
        }
        if (data.placementTagReplacement || force) {
            this.changePlacementTagReplacement(data.placementTagReplacement);
        }
        if (data.listTagReplacement || force) {
            this.changeListTagReplacement(data.listTagReplacement);
            this._includeListParam = !!data.listTagReplacement;
        }
        if (data.lceeKeyReplacement || force) {
            this.changeLceeKeyReplacement(data.lceeKeyReplacement);
            if (data.lceeKeyReplacement) {
                this.lceeKeySelectDropdown.writeValue(data.lceeKeyReplacement);
            }
        }
        if (data.lceeTagReplacement || force) {
            this.changeLceeTagReplacement(data.lceeTagReplacement);
        }
        if (data.esp || force) {
            this._esp = data.esp;
            this._data.esp = data.esp;
            if (data.esp === 'other') {
                // Internally the component gives the Other ESP an id value of 0
                this._esp = '0';
                this._data.esp = 'other';
            }
            this.changeEsp(this._esp);
        }
    };
    EmailServiceProviderCardComponent.prototype.clearSettings = function () {
        this.resetAllowCustom();
        this.changeUserTagReplacement(null);
        this.changeUserTagType(null);
        this.changePlacementTagReplacement(null);
        this.changeListTagReplacement(null);
        this.changeLceeKeyReplacement(null);
        this.changeLceeTagReplacement(null);
        this._includeListParam = false;
        this.showAdvanced = false;
    };
    EmailServiceProviderCardComponent.prototype.reset = function () {
        this.changeEsp(null);
        this.clearSettings();
    };
    EmailServiceProviderCardComponent.prototype.resetAllowCustom = function () {
        this.userTagAllowCustom = false;
        this.listTagAllowCustom = false;
        this.placementTagAllowCustom = false;
        this.lceeKeyAllowCustom = false;
        this.lceeTagAllowCustom = false;
    };
    EmailServiceProviderCardComponent.prototype.getPlaceholder = function (param) {
        switch (param) {
            case Tag.USER:
                if (this.userTagType && EspProperties[EspUserMacroTagTypeMap[this.userTagType]]) {
                    return EspProperties[EspUserMacroTagTypeMap[this.userTagType]].placeholder;
                }
                return EspProperties.userEmail.placeholder;
            case Tag.PLACEMENT:
            case Tag.LIST:
            case Tag.LCEE:
                return EspProperties[param].placeholder;
            default:
                return '{INSERT_MACRO_HERE}';
        }
    };
    EmailServiceProviderCardComponent.prototype.buildUserTagReplacementOptions = function (userTagType) {
        var options = this.getUserMacros(userTagType);
        if (options.length > 0) {
            this.userTagAllowCustom = true;
            if (!this.userTagReplacement) {
                this.changeUserTagReplacement(options[0].value);
            }
        }
        this.userTagReplacementOptions.next(options);
    };
    EmailServiceProviderCardComponent.prototype.buildPlacementTagReplacementOptions = function () {
        var options = this.getPlacementMacros();
        if (options.length > 0) {
            this.placementTagAllowCustom = true;
            if (!this.placementTagReplacement) {
                this.changePlacementTagReplacement(options[0].value);
            }
        }
        this.placementTagReplacementOptions.next(options);
    };
    EmailServiceProviderCardComponent.prototype.buildListTagReplacementOptions = function () {
        var options = this.getListMacros();
        if (options.length > 0) {
            this.listTagAllowCustom = true;
            if (!this.listTagReplacement && this.includeListParam) {
                this.changeListTagReplacement(options[0].value);
            }
        }
        this.listTagReplacementOptions.next(options);
    };
    EmailServiceProviderCardComponent.prototype.buildLceeKeyReplacementOptions = function () {
        var esp_options = this.getLceeKeyMacros();
        var default_options = this.options.lceeKey;
        var first_option = default_options[0].value;
        if (esp_options.length > 0) {
            first_option = esp_options[0].value;
            esp_options.forEach(function (option) {
                if (!default_options.find(function (o) { return o.value === option.value; })) {
                    var newOption = {
                        key: default_options.length,
                        value: option.value,
                        label: option.label
                    };
                    default_options.push(newOption);
                }
            });
        }
        this.lceeKeyAllowCustom = true;
        this.lceeKeyReplacementOptions.next(default_options);
        if (!this.lceeKeyReplacement) {
            this.changeLceeKeyReplacement(first_option);
        }
        this.lceeKeySelectDropdown.writeValue(this.lceeKeyReplacement);
    };
    EmailServiceProviderCardComponent.prototype.buildLceeTagReplacementOptions = function () {
        var options = this.getLceeTagMacros();
        if (options.length > 0) {
            this.lceeTagAllowCustom = true;
            if (!this.lceeTagReplacement) {
                this.changeLceeTagReplacement(options[0].value);
            }
        }
        this.lceeTagReplacementOptions.next(options);
    };
    /**
     * Load all supported Esp's for the dropdown.
     */
    EmailServiceProviderCardComponent.prototype.loadEsps = function () {
        var _this = this;
        this.espSubscription = this.espRepository.all()
            .pipe(map(function (esps) {
            esps.forEach(function (el) { return _this.allEsps[el.id] = el; });
            return esps.sort(function (a, b) {
                var aname = a.name.toLowerCase();
                var bname = b.name.toLowerCase();
                if (aname < bname) {
                    return -1;
                }
                else if (aname > bname) {
                    return 1;
                }
                else {
                    return parseInt(a.id) - parseInt(b.id);
                }
            })
                .map(function (esp) { return ({
                key: esp.id,
                value: esp.id,
                label: esp.name
            }); });
        })).subscribe(function (esps) {
            esps.push({ key: 'other', value: '0', label: 'Other ESP' });
            _this.espOptions.next(esps);
        });
    };
    EmailServiceProviderCardComponent.prototype.getUserMacros = function (key) {
        return this.getMacros(EspUserMacroTagTypeMap[key]);
    };
    EmailServiceProviderCardComponent.prototype.getPlacementMacros = function () {
        return this.getMacros('placement');
    };
    EmailServiceProviderCardComponent.prototype.getListMacros = function () {
        return this.getMacros('list');
    };
    EmailServiceProviderCardComponent.prototype.getLceeKeyMacros = function () {
        return this.getMacros('lceeKey');
    };
    EmailServiceProviderCardComponent.prototype.getLceeTagMacros = function () {
        return this.getMacros('lceeValue');
    };
    EmailServiceProviderCardComponent.prototype.getMacros = function (key) {
        if (this.allEsps && this.allEsps[this.esp] && this.allEsps[this.esp][key] && this.allEsps[this.esp][key].length > 0) {
            var esp = this.allEsps[this.esp];
            var options_1 = this.buildOptions(esp[key]);
            return options_1;
        }
        return [];
    };
    EmailServiceProviderCardComponent.prototype.buildOptions = function (arr) {
        return arr.map(function (value, index) { return ({
            key: index,
            value: value,
            label: value,
        }); });
    };
    EmailServiceProviderCardComponent.prototype.changeEsp = function (value) {
        if (value) {
            this._userTagType = this._data.emailTagType ? this._data.emailTagType : 'email';
            this.userTagType$.next(this._userTagType);
            this._data.emailTagType = this._userTagType;
        }
        this._esp = value;
        this.esp$.next(value);
        this._data.esp = this._esp;
        if (this._esp === '0') {
            this._data.esp = 'other';
        }
        this.espSettingsChangedEvent.emit(this._data);
    };
    EmailServiceProviderCardComponent.prototype.changeLceeTagReplacement = function (value) {
        this._lceeTagReplacement = value;
        this.lceeTagReplacement$.next(value);
        this._data.lceeTagReplacement = this._lceeTagReplacement;
        this.espSettingsChangedEvent.emit(this._data);
    };
    EmailServiceProviderCardComponent.prototype.changeLceeKeyReplacement = function (value) {
        this._lceeKeyReplacement = value;
        this.lceeKeyReplacement$.next(value);
        this._data.lceeKeyReplacement = this._lceeKeyReplacement;
        this.espSettingsChangedEvent.emit(this._data);
    };
    EmailServiceProviderCardComponent.prototype.changeUserTagReplacement = function (value) {
        this._userTagReplacement = value;
        this.userTagReplacement$.next(value);
        this._data.emailTagReplacement = this._userTagReplacement;
        this.espSettingsChangedEvent.emit(this._data);
    };
    EmailServiceProviderCardComponent.prototype.changeUserTagType = function (value) {
        this._userTagType = value;
        this.userTagType$.next(value);
        this._data.emailTagType = this._userTagType;
        this.espSettingsChangedEvent.emit(this._data);
    };
    EmailServiceProviderCardComponent.prototype.changePlacementTagReplacement = function (value) {
        this._placementTagReplacement = value;
        this.placementTagReplacement$.next(value);
        this._data.placementTagReplacement = this._placementTagReplacement;
        this.espSettingsChangedEvent.emit(this._data);
    };
    EmailServiceProviderCardComponent.prototype.changeListTagReplacement = function (value) {
        this._listTagReplacement = value;
        this.listTagReplacement$.next(value);
        this._data.listTagReplacement = this._listTagReplacement;
        this.espSettingsChangedEvent.emit(this._data);
    };
    EmailServiceProviderCardComponent.prototype.useOtherEsp = function () {
        this.userTagType$.next('email');
        this.changeEsp('0');
    };
    EmailServiceProviderCardComponent.prototype.getCustomValue = function (param) {
        var _this = this;
        switch (param) {
            case Tag.USER:
                var userTagReplacementHasChanged = !this._userTagReplacementOptions.some(function (option) { return option.value === _this._userTagReplacement; });
                return this._userTagReplacement && userTagReplacementHasChanged ? this._userTagReplacement : null;
            case Tag.PLACEMENT:
                var placementTagReplacementHasChanged = !this._placementTagReplacementOptions.some(function (option) { return option.value === _this._placementTagReplacement; });
                return this._placementTagReplacement && placementTagReplacementHasChanged ? this._placementTagReplacement : null;
            case Tag.LIST:
                var listTagReplacementHasChanged = !this._listTagReplacementOptions.some(function (option) { return option.value === _this._listTagReplacement; });
                return this._listTagReplacement && listTagReplacementHasChanged ? this._listTagReplacement : null;
            case Tag.LCEE:
                var lceeTagReplacementHasChanged = !this._lceeTagReplacementOptions.some(function (option) { return option.value === _this._lceeTagReplacement; });
                return this._lceeTagReplacement && lceeTagReplacementHasChanged ? this._lceeTagReplacement : null;
            case Tag.LCEE_KEY:
                var lceeKeyReplacementHasChanged = !this._lceeKeyReplacementOptions.some(function (option) { return option.value === _this._lceeKeyReplacement; });
                return this._lceeKeyReplacement && lceeKeyReplacementHasChanged ? this._lceeKeyReplacement : null;
            default:
                return null;
        }
    };
    EmailServiceProviderCardComponent.prototype.copyFromPublisher = function () {
        this.clearSettings();
        this.loadEspSettings(this._data.publisherObj, this.isBulkEdit);
    };
    EmailServiceProviderCardComponent.prototype.isNewsletter = function () {
        return this.type === TYPE_NEWSLETTER;
    };
    EmailServiceProviderCardComponent.prototype.isNew = function () {
        return !this._data.refId;
    };
    return EmailServiceProviderCardComponent;
}());
export { EmailServiceProviderCardComponent };
