import {
    Directive,
    ElementRef,
    forwardRef,
    HostListener,
    Input,
    OnInit,
    Renderer2,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Directive({
    selector: "[number-input]",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NumberInputDirective),
            multi: true,
        },
    ],
})
export class NumberInputDirective implements OnInit, ControlValueAccessor {
    private value: number = null;
    private _placeholder: string;

    constructor(private el: ElementRef, private renderer: Renderer2) {}

    ngOnInit() {
        this.el.nativeElement.placeholder = this.placeholder;
    }

    private propagate = null;
    private onTouchedCallback: () => void = null;

    writeValue(value) {
        if (value === null || value === undefined) {
            this.renderer.setStyle(this.el.nativeElement, "text-align", "left");
            this.reset();
            return;
        }
        this.renderer.setStyle(this.el.nativeElement, "text-align", "right");
        let input = this.el.nativeElement;
        this.value = value;
        input.value = this.formatNumber(this.value.toString());
    }

    reset(): void {
        this.el.nativeElement.value = null;
        this.el.nativeElement.placeholder = this.placeholder;
        this.renderer.setStyle(this.el.nativeElement, "text-align", "left");
        this.value = null;
    }

    @Input("placeholder")
    set placeholder(placeholder) {
        this._placeholder = placeholder;
        this.el.nativeElement.placeholder = this.placeholder;
    }

    get placeholder() {
        return this._placeholder ? this._placeholder : "";
    }

    registerOnChange(fn) {
        this.propagate = fn;
    }

    registerOnTouched(fn) {
        this.onTouchedCallback = fn;
    }

    @HostListener("focus")
    onFocus() {
        let input = this.el.nativeElement;
        input.value = this.value;
        input.placeholder = "";
        this.renderer.setStyle(this.el.nativeElement, "text-align", "right");
    }

    @HostListener("blur") onBlur() {
        let input = this.el.nativeElement;
        if (input.value.length > 0) {
            let numericValueAsString = input.value.replace(/[^0-9\.]+/g, "");
            if (numericValueAsString.length < 1) {
                this.reset();
            } else {
                input.value = this.formatNumber(numericValueAsString);
                this.value = parseFloat(input.value.replace(/[,]/g, ""));
            }
        } else {
            this.reset();
        }
        this.propagate(this.value);
        this.onTouchedCallback();
    }

    private formatNumber(value: string): string {
        if (!value) return "0";
        const parts = parseFloat(value.replace(/,/g, "")).toString().split(".");

        // Add commas to integer part
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }

    @Input("disabled")
    set disabled(isDisabled: boolean) {
        this.el.nativeElement.disabled = isDisabled;
    }
}
