import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { AdSlotRepository } from 'app/core/repositories';

@Component({
    selector: 'delete-ad-slot-dialog',
    templateUrl: './delete-ad-slot-dialog.html'
})

export class DeleteAdSlotDialogComponent {
    @Output()
    public deleted: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('dialog', { static: true })
    public dialog;

    public adSlot: any = null;

    public constructor(public adSlotRepository: AdSlotRepository) { }

    public open(adSlot): void {
        this.adSlot = adSlot;
        this.dialog.open();
    }

    public delete(): void {
        this.adSlotRepository.delete(this.adSlot.id)
            .subscribe(() => this.deleted.emit(this.adSlot));
    }
}
