import {
    OnInit,
    OnDestroy,
    Component,
    ViewChild,
    ChangeDetectionStrategy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, BehaviorSubject, Observable, combineLatest, of } from 'rxjs';

import { LIESRepository, SorobanRepository } from 'app/core/repositories';
import { ESParams } from 'app/shared/helpers/es-query-builder';
import { ESSimpleQueryBuilder } from 'app/shared/helpers/es-simple-query-builder';
import { PaginatorComponent } from 'app/shared/elements/paginator';
import { TableComponent } from 'app/shared/elements/table';
import { TableHelper } from 'app/shared/helpers/table-helper';

import { AuthorizationService } from 'app/core';
import {
    mergeMap,
    debounceTime,
    distinctUntilChanged,
    takeUntil,
    switchMap,
} from 'rxjs/operators';
import { getESTotal } from 'app/shared/helpers/es-helper';
import { CamelToSnakeCasePipe, SnakeToCamelCasePipe } from 'app/shared/elements/pipes';
import {
    getLinkFromEntity,
    formatIndex,
} from 'app/shared/helpers/search-helper';
import { Flag, LaunchDarklyService } from 'app/core/launch-darkly.service';

type SearchFilter = {
    label: string;
    index: string;
    internalOnly?: boolean;
};

@Component({
    selector: 'global-search',
    templateUrl: './global-search.html',
    styleUrls: ['./global-search.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSearchComponent implements OnInit, OnDestroy {
    private queryBuilder = new ESSimpleQueryBuilder();
    @ViewChild(TableComponent, { static: true }) table: TableComponent;
    @ViewChild(PaginatorComponent, { static: true })
    paginator: PaginatorComponent;
    tableHelper = new TableHelper<any>(
        (params) => this.adapter(params),
        this.queryBuilder
    );
    getESTotal = getESTotal;

    aggregatedResults$: Observable<any>;
    private filters = new BehaviorSubject<any>({});
    private onDestroy = new Subject<void>();
    private demandFilters: SearchFilter[] = [
        { label: 'Agencies', index: 'agencies' },
        { label: 'Advertisers', index: 'advertisers' },
        {
            label: 'Insertion Orders',
            index: 'insertionOrders',
            internalOnly: true,
        },
        { label: 'Campaigns', index: 'campaigns' },
        { label: 'Line Items', index: 'lineItems' },
        { label: 'Creatives', index: 'creatives' },
    ];
    private supplyFilters: SearchFilter[] = [
        { label: 'Media Groups', index: 'mediaGroups' },
        { label: 'Publishers', index: 'publishers' },
        { label: 'Newsletters', index: 'newsletters' },
        { label: 'Ad Slots', index: 'adSlots' },
    ];

    public useNewSearchExperience = false;

    constructor(
        public route: ActivatedRoute,
        private auth: AuthorizationService,
        private liesRepository: LIESRepository,
        private camelToSnakeCasePipe: CamelToSnakeCasePipe,
        private snakeToCamelCasePipe: SnakeToCamelCasePipe,
        private sorobanRepository: SorobanRepository,
        private launchDarklyService: LaunchDarklyService
    ) {}

    ngOnInit() {
        this.aggregatedResults$ = combineLatest([
            this.table.query.pipe(debounceTime(300), takeUntil(this.onDestroy)),
            this.launchDarklyService
                .getVariation(Flag.NewSearchExperienceInMaverick)
                .pipe(distinctUntilChanged()),
        ]).pipe(
            switchMap(([rawParams, useNewSearchExperience]) => {
                this.useNewSearchExperience = useNewSearchExperience;
                const params = this.queryBuilder.build(rawParams);
                
                if (params.query.length > 1) {
                    if (this.useNewSearchExperience) {
                        return this.sorobanRepository.sorobanSearch(params.query);
                    } else {
                        return this.liesRepository.globalsearch(params.query);
                    }
                }
    
                return of(null);
            })
        );

        this.tableHelper.table = this.table;
        this.tableHelper.paginator = this.paginator;

        combineLatest(this.table.query, this.paginator.query).subscribe(args => this.tableHelper.search(args));
    }
    
    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    adapter(params: ESParams) {
        return combineLatest([
            this.filters,
            this.launchDarklyService
                .getVariation(Flag.NewSearchExperienceInMaverick)
                .pipe(distinctUntilChanged()),
        ]).pipe(
            switchMap(([filters, useNewSearchExperience]) => {
                this.useNewSearchExperience = useNewSearchExperience;
                params.indices = this.filterIndices(Object.keys(filters));

                const _params = this.route.queryParams['value'].q;
                const splitQueryParams = _params.split(/[+:]/);

                if (splitQueryParams.find((arr) => arr[0] !== '')) {
                    const parsed = splitQueryParams.slice(2, 3);
                    const searchQuery = parsed[0];
                    params.query = this.table._searchOnInput
                        ? params.query
                        : '(' + searchQuery + ')';
                }

                if (this.useNewSearchExperience) {
                    return this.sorobanRepository.eSorobanSearch(params);
                } else {
                    return this.liesRepository.eSearch(params);
                }
            })
        );
    }

    filterIndices(filters: string[]) {
        if (filters.length > 0) {
            return filters;
        }
        return this.demandFilters
            .concat(this.supplyFilters)
            .filter(
                (filter) => this.auth.isInternalUser || !filter.internalOnly
            )
            .map((filter) => {
                if (this.useNewSearchExperience) {
                    filter.index = this.camelToSnakeCasePipe.transform(
                        filter.index
                    );
                    return filter.index;
                } else {
                    return filter.index;
                }
            });
    }

    filterBy(shouldFilter: boolean, filter: SearchFilter) {
        const filters = this.filters.getValue();
        const key = filter.index;
        filters[key] = shouldFilter;
        if (!filters[key]) {
            delete filters[key];
        }
        this.filters.next(filters);
    }

    count(results, key: string) {
        if(this.useNewSearchExperience){
            key = this.snakeToCamelCasePipe.transform(key);
        }

        const type = results[key];
        return getESTotal(type);
    }

    link(entity: any) {
        return getLinkFromEntity(entity);
    }

    formatIndex(index: string): string {
        return formatIndex(index);
    }
}
