import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { publishLast, refCount, map } from 'rxjs/operators';
import { ISP } from 'app/shared/models';
import { BackendRepository, Query } from './backend-repository';

@Injectable()
export class ISPRepository extends BackendRepository<ISP> {

    public constructor(http: HttpClient) {
        super(http, '/isp', ISP);
    }

    // TODO implement based on the query params
    private _allResult: Observable<ISP[]> = null;
    public all(query: Query = {}) {

        if (this._allResult) {
            return this._allResult;
        }

        this._allResult = super.all(query).pipe(publishLast(), refCount());

        return this._allResult;
    }

    public searchAsOptions(query: Query = {}) {
        return this.all(query).pipe(map(isps => {
            return isps.map(
                option => {
                    return {
                        key: option.id,
                        value: option.id,
                        label: option.name
                    };
                });
        }));
    }

}
