import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { DeviceManufacturer } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
var DeviceManufacturerRepository = /** @class */ (function (_super) {
    tslib_1.__extends(DeviceManufacturerRepository, _super);
    function DeviceManufacturerRepository(http) {
        return _super.call(this, http, '/device-manufacturer', DeviceManufacturer) || this;
    }
    return DeviceManufacturerRepository;
}(BackendRepository));
export { DeviceManufacturerRepository };
