import { Component, ViewChild, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';

import { Advertiser } from 'app/shared/models';
import { AdvertiserRepository, UserRepository } from 'app/core/repositories';
import { AvailableActionsService } from 'app/shared/helpers/available-actions.service';
import { AuthorizationService } from 'app/core/';
import { HistoryViewComponent } from 'app/shared/components/history-view';
import { PaginatorComponent } from 'app/shared/elements/paginator';
import { IdService } from 'app/core/id.service';
import { TableComponent } from 'app/shared/elements/table';
import { TableHelper } from 'app/shared/helpers/table-helper';
import { mergeMap } from 'rxjs/operators';
import { getManagersExecutivesForTableData, decorateTableDataWithNames } from 'app/shared/helpers/advertiser-helper';

@Component({
    selector: 'advertisers-table',
    templateUrl: './advertisers-table.html',
    styleUrls: ['./advertisers-table.styl']
})
export class AdvertisersTableComponent implements OnInit {
    @ViewChild(TableComponent, { static: true }) table: TableComponent;
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent;
    @ViewChild(HistoryViewComponent, { static: true }) historyView: HistoryViewComponent;
    mediaGroup: string;
    helper = new TableHelper<Advertiser>(params => this.adapter(params));

    private _ids: string[];

    constructor(
        public availActions: AvailableActionsService,
        private advertiserRepository: AdvertiserRepository,
        private userRepository: UserRepository,
        private authorization: AuthorizationService,
        private router: Router,
        public id: IdService
    ) { }

    ngOnInit() {
        this.helper.table = this.table;
        this.helper.paginator = this.paginator;
        combineLatest(this.table.query, this.paginator.query).subscribe(args => this.helper.search(args));
    }

    @Input() set advertisers(value: string[]) {
        this._ids = value;
        this.paginator.reset();
    }

    @Input('media-group') set setMediaGroup(id: string) {
        this.mediaGroup = id;
        this.paginator.reset();
    }

    private adapter(params) {
        if (typeof this.mediaGroup === 'string') {
            params.conditions.push({ field: 'mediaGroup', value: this.mediaGroup });
        }

        if (Array.isArray(this._ids)) {
            params.conditions.push({
                field: 'id',
                value: this._ids
            });
        }

        return this.advertiserRepository.asyncSearch(params).pipe(
            mergeMap(data => getManagersExecutivesForTableData(data, this.userRepository)),
            mergeMap(decorateTableDataWithNames)
        );;
    }

    availableActionHandler(action: string, advertiser: Advertiser) {
        if (action === 'edit') {
            this.router.navigate(['/campaign-manager/advertisers', advertiser.refId, 'edit',
                { redirect: btoa(this.router.url)}]);
        } else if (action === 'history') {
            this.historyView.viewAdvertiserHistory(advertiser);
        }
    }

    get canCreateAdvertiser() {
        return this.authorization.isInternalUser
            || this.authorization.isMediaGroupDemand
            || this.authorization.isMediaGroupManager
            || this.authorization.isPublisherDemand
            || this.authorization.isPublisherManager
            || this.authorization.isAgencyDemand
            || this.authorization.isAgencyManager;
    }
}
