import { Component, Input } from '@angular/core';
import { IdService } from 'app/core';
import { NativeBlueprint, RTBPartner as ThirdPartyDemandPartner } from 'app/shared/models';

interface ThirdPartyDemandSettings {
    id: string,
    floor: number,
    allow: boolean,
    allowDisplay: boolean,
    allowNative: boolean
}

@Component({
    selector: 'third-party-demand-settings',
    templateUrl: './third-party-demand-settings.html',
    styleUrls: ['./third-party-demand-settings.styl']
})
export class ThirdPartyDemandSettingsComponent {
    @Input() set allowDisplayThirdPartyDemand(value: boolean) {
        if (!this._allowDisplayThirdPartyDemand && value) {
            this.thirdPartyDemandPartners.map(partner => {
                partner.allowDisplay = true;
            });
        } else if (!value) {
            this.thirdPartyDemandPartners.map(partner => {
                partner.allowDisplay = false;
            });
        }

        this._allowDisplayThirdPartyDemand = value;
    }

    thirdPartyDemandPartners: ThirdPartyDemandPartner[] = [];
    _allowDisplayThirdPartyDemand: boolean = true;
    _nativeBlueprints: NativeBlueprint[];
    _nativeAdSlotRtbAllow: boolean;
    
    _isDisplay: boolean;
    @Input() set isDisplay(value: boolean) {
        this._isDisplay = value;
        if (!value) {
            this.thirdPartyDemandPartners.map(partner => {
                partner.allowDisplay = false;
            });
        }
    }
    get isDisplay() {
        return this._isDisplay;
    }

    _isNative: boolean;
    @Input() set isNative(value: boolean) {
        this._isNative = value;
        if (!value) {
            this.thirdPartyDemandPartners.map(partner => {
                partner.allowNative = false;
            });
        }
    }
    get isNative() {
        return this._isNative;
    }

    @Input() set nativeBlueprints(nativeBlueprints: NativeBlueprint[]) {
        this._nativeBlueprints = nativeBlueprints;
        this.nativeAdSlotRtbAllow = this.isNativeRtbAllow();
    }

    get nativeBlueprints(): NativeBlueprint[] {
        return this._nativeBlueprints;
    }

    @Input() set nativeAdSlotRtbAllow(value: boolean) {
        if (this._nativeAdSlotRtbAllow !== value) {
            if (this._nativeAdSlotRtbAllow != null) {
                this.thirdPartyDemandPartners = this.thirdPartyDemandPartners.map(rtb => {
                    if (rtb.isNative3PDSP) {
                        rtb.allowNative = value;
                    }

                    return rtb as ThirdPartyDemandPartner;
                });
            }

            this._nativeAdSlotRtbAllow = value;
        }
    }
      
    get nativeAdSlotRtbAllow(): boolean {
        return this._nativeAdSlotRtbAllow;
    }

    constructor(
        public id: IdService
    ) { }

    /**
     * Initalize
     */
    initSettings(linkedRtbs: Array<ThirdPartyDemandPartner>): void {
        if (this.thirdPartyDemandPartners.length === 0) {
            this.thirdPartyDemandPartners = linkedRtbs;
            this.thirdPartyDemandPartners.sort(x => x.isNative3PDSP ? -1 : 0);
        }
    }

    getLinkedRtbs(): ThirdPartyDemandSettings[] {
        return this.thirdPartyDemandPartners
            .filter(partner => (
                partner.allowDisplay ||
                partner.allowNative ||
                partner.floor !== null
            )).map(partner => {
                // We should not have null values for allow, allowDisplay, and allowNative unless all of them are null.
                const thirdPartyDemandSettings: ThirdPartyDemandSettings = {
                    id: partner.id,
                    floor: partner.floor,
                    allow: partner.allowDisplay || partner.allowNative,
                    allowDisplay: this.isValidValue(partner.allowDisplay),
                    allowNative: this.isValidValue(partner.allowNative)
                };

                return thirdPartyDemandSettings;
            });
    }

    get allowDisplayThirdPartyDemand(): boolean {
        return this._allowDisplayThirdPartyDemand;
    }

    isNativeRtbAllow(): boolean {
        this.nativeAdSlotRtbAllow = this.nativeBlueprints != null && this.nativeBlueprints.some(bp => bp.allowRtb === true);
        return this.nativeAdSlotRtbAllow;
    }

    removeWhiteSpace(string: string, replace: string): string {
        return string.replace(/\s/g, replace);
    }

    isValidValue(value: boolean): boolean {
        if (value === undefined || value === null) {
            return false;
        }

        return value;
    }
}
