import { AfterViewInit, ElementRef, EventEmitter } from '@angular/core';
import { FilterEnum } from './filter-enum';
var FilterEnumComponent = /** @class */ (function () {
    function FilterEnumComponent() {
        this.emitter = new EventEmitter();
        this.filter = new FilterEnum();
        this.valueOptions = [];
        this.groupMap = new Map();
    }
    Object.defineProperty(FilterEnumComponent.prototype, "values", {
        set: function (values) {
            var _this = this;
            if (values) {
                this.valueOptions = Object.keys(values).map(function (key) {
                    var value = values[key];
                    // Multi-select can't properly accept an array of values,
                    // but sometimes one checkbox should relate to more than
                    // one enum. Here's our hacky solution.
                    if (Array.isArray(value)) {
                        _this.groupMap.set(value[0], value);
                        value = value[0];
                    }
                    return {
                        label: key,
                        value: value
                    };
                });
                this.filter.values = Object.values(values);
            }
        },
        enumerable: true,
        configurable: true
    });
    FilterEnumComponent.prototype.ngAfterViewInit = function () {
        this.filter.label = this.content.nativeElement.innerHTML;
        if (!this.filter.operation) {
            this.filter.operation = this.filter.operations[0];
        }
    };
    FilterEnumComponent.prototype.onchange = function (value) {
        var _this = this;
        this.filter.query = [].concat.apply([], value.map(function (selected) { return _this.groupMap.has(selected) ? _this.groupMap.get(selected) : selected; }));
        this.filter.simpleLabel = this.valueOptions.filter(function (option) { return _this.filter.query.includes(option.value); }).map(function (option) { return option.label; }).join(', ');
        this.emit();
    };
    FilterEnumComponent.prototype.emit = function () {
        this.emitter.emit(this.filter);
    };
    return FilterEnumComponent;
}());
export { FilterEnumComponent };
