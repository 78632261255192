import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { City } from 'app/shared/models';
import { BackendRepository } from './backend-repository';

@Injectable()
export class CityRepository extends BackendRepository<City> {
    public constructor(http: HttpClient) {
        super(http, '/city', City);
    }
}
