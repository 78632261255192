import { ElementRef } from '@angular/core';
var CarouselComponent = /** @class */ (function () {
    function CarouselComponent(el) {
        this.el = el;
        this.wheel = null;
        this.showleft = false;
        this.showright = false;
        this.start = true;
        this.end = true;
        this._offset = 0;
    }
    CarouselComponent.prototype.ngAfterViewInit = function () {
        this.wheel = this.el.nativeElement.querySelector('.carousel---wheel');
        this.offset = 0;
    };
    CarouselComponent.prototype.ngDoCheck = function () {
        // If the `wheel` reference is not available yet we do not wish to trigger
        // the offset calculation. In the case where there is a reference, this
        // serves recalculate the `start` and `end` flags without setting a new
        // offset. This lets us set those variables according to any changes to the
        // number of cars that the carousel is displaying.
        if (this.wheel !== null) {
            this.offset = this._offset;
        }
    };
    CarouselComponent.prototype.move = function (x) {
        var percentage = (100 / this.width) * (x - this.left);
        this.showleft = percentage <= 40;
        this.showright = percentage >= 60;
    };
    CarouselComponent.prototype.leave = function () {
        this.showleft = false;
        this.showright = false;
    };
    Object.defineProperty(CarouselComponent.prototype, "offset", {
        get: function () {
            return this._offset;
        },
        set: function (value) {
            this.start = value <= 0;
            this.end = value + this.remainder >= this.max && this.max !== 0;
            if (this.start) {
                value = 0;
            }
            this._offset = value;
            this.wheel.style.transform = 'matrix(1, 0, 0, 1, -' + this._offset.toString() + ', 0)';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CarouselComponent.prototype, "width", {
        get: function () {
            return this.el.nativeElement.getBoundingClientRect().width;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CarouselComponent.prototype, "left", {
        get: function () {
            return this.el.nativeElement.getBoundingClientRect().left;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CarouselComponent.prototype, "remainder", {
        get: function () {
            return this.width - (this.width % CarouselComponent.carWidth);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CarouselComponent.prototype, "max", {
        get: function () {
            return this.el.nativeElement.getElementsByClassName('carousel---car').length * CarouselComponent.carWidth;
        },
        enumerable: true,
        configurable: true
    });
    CarouselComponent.prototype.next = function () {
        this.offset = this.offset + this.remainder;
    };
    CarouselComponent.prototype.previous = function () {
        this.offset = this.offset - this.remainder;
    };
    CarouselComponent.carWidth = 180;
    return CarouselComponent;
}());
export { CarouselComponent };
