const MAX_FILE_SIZE_GIF = 4096; // in KB
const MAX_FILE_SIZE_JPG_PNG = 1200; // in KB
const MAX_FILE_SIZE_250 = 250; //in KB

const IMAGE_TYPE_JPG = 'image/jpg';
const IMAGE_TYPE_JPEG = 'image/jpeg';
const IMAGE_TYPE_PNG = 'image/png';
export const IMAGE_TYPE_GIF = 'image/gif';

export const isOverMaxFileSize = (fileSize, fileType, restrictFileSizeTo250) => {
    const size = fileSize / 1024; // in KB
    const maxAllowedSize = getMaxFileSizeByType(fileType, restrictFileSizeTo250);

    return maxAllowedSize ? (size > maxAllowedSize) : true;
}

export const getMaxFileSizeByType = (fileType, restrictFileSizeTo250) => {
    if (restrictFileSizeTo250) {
        if (isAcceptedImageType(fileType)) {
            return MAX_FILE_SIZE_250;
        }
        return null;
    }

    if (isAcceptedDynamicImageType(fileType)) {
        return MAX_FILE_SIZE_GIF;
    } else if (isAcceptedStaticImageType(fileType)) {
        return MAX_FILE_SIZE_JPG_PNG;
    }

    return null;
}

export const isAcceptedStaticImageType = (type) => {
    return type === IMAGE_TYPE_JPG || type === IMAGE_TYPE_PNG || type === IMAGE_TYPE_JPEG;
}

export const isAcceptedDynamicImageType = (type) => {
    return type === IMAGE_TYPE_GIF;
}

export const isAcceptedImageType = (type) => {
    return isAcceptedStaticImageType(type) || isAcceptedDynamicImageType(type);
}