import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, ReplaySubject, zip, of } from 'rxjs';

import { groupBy } from 'app/core/array-utils';
import { Campaign } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { map, bufferWhen, debounceTime, filter, mergeMap } from 'rxjs/operators';

@Injectable()
export class CampaignRepository extends BackendRepository<Campaign> {
    private pixelIdToCampaigns = new Map<string, Subject<Campaign[]>>();
    private pixelIdToSearch$ = new Subject<string>();

    constructor(http: HttpClient) {
        super(http, '/campaign', Campaign);

        this.pixelIdToSearch$.pipe(
            bufferWhen(() => this.pixelIdToSearch$.pipe(debounceTime(BackendRepository.FLUSH_INTERVAL))),
            filter(ids => ids.length > 0),
            mergeMap(ids => zip(of(ids), this.searchByConversionId(ids)))
        ).subscribe(([pixelIds, campaigns]) => {
            const mapped = groupBy(campaigns, 'conversionPixel');

            pixelIds.forEach(pixelId => {
                this.pixelIdToCampaigns.get(pixelId).next(mapped[pixelId] || []);
            });
        });
    }

    pendingSearch(params: any = {}): Observable<{ items: Campaign[], page: number, pages: number, total: number}> {
        return this.http.post(this.url('/search/campaign/pending'), this.sanitizeQuery(params))
            .pipe(map(data => ({
                items: data['output'].map(item => this.build(item) as Campaign),
                page: 1,
                pages: data['pages'],
                total: data['total']
            })));
    }

    activate(campaign: Campaign): Observable<Campaign> {
        return this.http.post(this.url(this.path, 'activate', campaign.id), campaign)
            .pipe(map(response => response['output']));
    }

    pause(campaignId: string): Observable<Response> {
        return this.http.post(this.url(this.path, 'pause', campaignId), null)
            .pipe(map(response => response['output']));
    }

    maxQuality(campaign: Campaign): Observable<Campaign> {
        return this.http.post(this.url(this.path, 'max-quality', campaign.id), campaign)
            .pipe(map(response => response['output']));
    }

    private searchByConversionId(conversionIds: string[]) {
        return this.search({
            conditions: [
                { field: 'conversionPixel', value: conversionIds }
            ]
        });
    }

    getByConversionId(conversionId: string) {
        if (!this.pixelIdToCampaigns.has(conversionId)) {
            this.pixelIdToSearch$.next(conversionId);
            this.pixelIdToCampaigns.set(conversionId, new ReplaySubject<Campaign[]>());
        }

        return this.pixelIdToCampaigns.get(conversionId);
    }
}
