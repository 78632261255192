import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { BlueprintProperties } from 'app/shared/models/blueprint';
import { Observable } from 'rxjs';

const BASE_URL = environment.ifaas;
const DEFAULT_IMAGE_URL = 'https://via.placeholder.com/250x250.jpg';
const DEFAULT_LOGO_URL = 'https://via.placeholder.com/50x50.jpg';

@Injectable()
export class IfaasPreviewService {
    private http: HttpClient;

    constructor(
        httpBackend: HttpBackend
    ) {
        this.http = new HttpClient(httpBackend);
    }

    getPreview(ifaasHtml: string, params:{} = null): Observable<Blob> {
        return this.http.request('post', this.getImageUrl(params), {
            headers: new HttpHeaders({ Accept: 'image/jpeg, image/png, image/gif' }),
            responseType: 'blob',
            body: ifaasHtml
        });
    }

    getImageUrl(params) {
        const url = new URL(BASE_URL);
        url.pathname = 'preview';
        url.searchParams.set('iUrl', params && params.iUrl ? params.iUrl : DEFAULT_IMAGE_URL);
        url.searchParams.set('t', params && params.t ? params.t : BlueprintProperties.headline.html);
        url.searchParams.set('cta', params && params.cta ? params.cta : BlueprintProperties.callToAction.html);
        url.searchParams.set('d', params && params.d ? params.d : BlueprintProperties.description.html);
        url.searchParams.set('sb', params && params.sb ? params.sb : BlueprintProperties.sponsoredBy.html);
        url.searchParams.set('lUrl', params && params.lUrl ? params.lUrl : DEFAULT_LOGO_URL);
        return url.toJSON().toString();
    }

}
