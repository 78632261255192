import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { DeviceType } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
var DeviceTypeRepository = /** @class */ (function (_super) {
    tslib_1.__extends(DeviceTypeRepository, _super);
    function DeviceTypeRepository(http) {
        return _super.call(this, http, '/device-type', DeviceType) || this;
    }
    return DeviceTypeRepository;
}(BackendRepository));
export { DeviceTypeRepository };
