import { Model } from './model';

export enum AccountProvisions {
    SELF_SERVICE = 'Self Service (Platform)',
    AUDIENCE_MANAGER = 'Audience Manager',
}

export class Account extends Model {
    name: string;
    salesforceId?: string;

    serialize() {
        return JSON.stringify(this);
    }

    get entity() {
        return 'Account';
    }
}
