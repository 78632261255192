import moment from 'moment';

export class DownloadHelper {
    /**
     * Download an array of objects as a CSV.
     */
    static downloadAsCSV(data: Object[], fileName: string, timestamp: boolean = true, includeHeaders = true) {
        if (timestamp) {
            fileName += moment().format('-YYYYMMDDHHmm');
        }

        let prepared = data;
        if (typeof data[0] === 'string') {
            prepared = data.map(d => [d]);
        }

        DownloadHelper.downloadFile(DownloadHelper.convertToCSV(prepared, includeHeaders), fileName + '.csv');
    }

    /**
     * Download an array of objects as a CSV.
     */
    static downloadPlain(data: string, fileName: string, timestamp: boolean = true) {
        if (timestamp) {
            fileName += moment().format('-YYYYMMDDHHmm');
        }
        const blob = new Blob([data], { type: 'text/plain' });
        DownloadHelper.downloadFile(blob, fileName + '.txt');
    }

    /**
     * Download a Blob as a file.
     */
    static downloadFile(data: Blob, fileName: string) {
        const dataBlob = URL.createObjectURL(data);
        DownloadHelper.downloadFileFromURL(dataBlob, fileName);
    }

    /**
     * Download a file from a URL.
     */
    static downloadFileFromURL(url: string, fileName: string) {
        const downloadLink = document.createElement('a');
        downloadLink.setAttribute('href', url);
        downloadLink.setAttribute('download', fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    /**
     * Convert an array of objects into a comma separated string Blob.
     */
    static convertToCSV(data: Object[], includeHeaders = true): Blob {
        return new Blob([DownloadHelper.convertToString(data, includeHeaders)], { type: 'text/csv' });
    }

    /**
     * Convert an array of objects into a comma separated string.
     */
    private static convertToString(data: Object[], includeHeaders = true) {
        let str = '';

        // If the objects are true Objects (not just arrays)
        // we'll add headers based on the object keys.
        if (includeHeaders && !Array.isArray(data[0])) {
            str += Object.keys(data[0])
                .map(key => key.toUpperCase())
                .join(',') + '\r\n';
        }

        // Convert the data to a csv string
        str += data.map(record => {
            return Object.keys(record)
                .map(key => DownloadHelper.normalize(record[key]))
                .join(',');
        }).join('\r\n');

        return str;
    }

    /**
     * Normalize the data for file download.
     *
     * Replace (") with ("") to render double quotes properly in CSV file.
     * Replace `undefined` and `null` values with an emtpy cell.
     */
    private static normalize(item: any) {
        if (item === undefined || item === null) {
            return '';
        }

        return '"' + item.toString().replace(/"/g, '""') + '"';
    }
}
