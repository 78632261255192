import { OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { LineItem } from 'app/shared/models';
import { BudgetHelper } from 'app/shared/helpers';
import { AuthorizationService, IdService } from 'app/core';
import { AdSlotRepository } from 'app/core/repositories';
import FORM_OPTIONS from '../../../platform/campaign-manager/line-items/options.json';
// Age and Gender segments as defined by LSD
// These do not change and can be hardcoded here
// TODO: have central repo for these. See also: data-segment-targeting component
var AGE_SEGMENT_IDS = [1, 2, 3, 4, 5, 6, 7];
var GENDER_SEGMENT_IDS = [8, 9];
var groupsPattern = / (?:AND|OR) (?![a-z0-9])/g;
var audiencePattern = /[a-z0-9.]+/g;
var LineItemSectionCardComponent = /** @class */ (function () {
    function LineItemSectionCardComponent(adSlotRepository, auth, id) {
        this.adSlotRepository = adSlotRepository;
        this.auth = auth;
        this.id = id;
    }
    LineItemSectionCardComponent.prototype.ngOnInit = function () {
        this.noOp = function () { };
        this.budgetHelper = new BudgetHelper(this.lineItem, this.lineItem.campaignObj);
        var ageSegmentOptions = FORM_OPTIONS.age.map(function (ele) { return ele.value; });
        var genderSegmentOptions = FORM_OPTIONS.gender.map(function (ele) { return ele.value; });
        this.ageSegmentPattern = new RegExp(ageSegmentOptions.join('|'), 'g');
        this.genderSegmentPattern = new RegExp(genderSegmentOptions.join('|'), 'g');
    };
    LineItemSectionCardComponent.prototype.isExcluding = function (targetType) {
        return targetType === 'exclude';
    };
    LineItemSectionCardComponent.prototype.commaSeparate = function (data) {
        return data.join(', ');
    };
    LineItemSectionCardComponent.prototype.dayTimeDisplay = function (dayTimeTargeting) {
        var days = dayTimeTargeting.days.join(', ');
        var hours = dayTimeTargeting.hours.join(', ');
        var article = (days.length && hours.length) ? ' from ' : '';
        return days + article + hours;
    };
    Object.defineProperty(LineItemSectionCardComponent.prototype, "genderTargets", {
        get: function () {
            var _this = this;
            var genderTargets = [];
            if (this.lineItem.audienceTargeting) {
                var segmentGroups = this.lineItem.audienceTargeting.split(groupsPattern);
                var genderSegments = segmentGroups.filter(function (segmentGroup) { return segmentGroup.match(_this.genderSegmentPattern); });
                if (genderSegments && genderSegments.length) {
                    var type_1 = 'include';
                    if (genderSegments[0].includes('NOT')) {
                        type_1 = 'exclude';
                    }
                    var genders = genderSegments[0].match(audiencePattern).map(function (segment) { return parseInt(segment); });
                    genderTargets = genders.map(function (gender) { return ({
                        type: type_1,
                        id: gender
                    }); });
                }
            }
            return genderTargets;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LineItemSectionCardComponent.prototype, "genderTargetingType", {
        get: function () {
            var targets = this.genderTargets;
            if (targets.length > 0) {
                return targets[0].type;
            }
            return 'include';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LineItemSectionCardComponent.prototype, "ageTargets", {
        get: function () {
            var _this = this;
            var ageTargets = [];
            if (this.lineItem.audienceTargeting) {
                var segmentGroups = this.lineItem.audienceTargeting.split(groupsPattern);
                var ageSegments = segmentGroups.filter(function (segmentGroup) { return segmentGroup.match(_this.ageSegmentPattern); });
                if (ageSegments && ageSegments.length) {
                    var type_2 = 'include';
                    if (ageSegments[0].includes('NOT')) {
                        type_2 = 'exclude';
                    }
                    var ages = ageSegments[0].match(audiencePattern).map(function (segment) { return parseInt(segment); });
                    ageTargets = ages.map(function (age) { return ({
                        type: type_2,
                        id: age
                    }); });
                }
            }
            return ageTargets;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LineItemSectionCardComponent.prototype, "ageTargetingType", {
        get: function () {
            var targets = this.ageTargets;
            if (targets.length > 0) {
                return targets[0].type;
            }
            return 'include';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LineItemSectionCardComponent.prototype, "dayTargets", {
        get: function () {
            if (!this.lineItem.days) {
                return null;
            }
            return Object.keys(this.lineItem.days);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LineItemSectionCardComponent.prototype, "timeTargets", {
        get: function () {
            if (!this.lineItem.hours) {
                return null;
            }
            return Object.keys(this.lineItem.hours);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LineItemSectionCardComponent.prototype, "liveAudienceIncludes", {
        get: function () {
            if (!this.lineItem.audiences) {
                return null;
            }
            return this.lineItem.audiences.filter(function (audience) { return audience.type === 'include'; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LineItemSectionCardComponent.prototype, "liveAudienceExcludes", {
        get: function () {
            if (!this.lineItem.audiences) {
                return null;
            }
            return this.lineItem.audiences.filter(function (audience) { return audience.type === 'exclude'; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LineItemSectionCardComponent.prototype, "isExchangeDSPCampaign", {
        get: function () {
            return !this.lineItem.campaignObj.isSsp && this.lineItem.campaignObj.isExchange;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LineItemSectionCardComponent.prototype, "calculateDailyBudget", {
        get: function () {
            return this.budgetHelper.getDailyBudget();
        },
        enumerable: true,
        configurable: true
    });
    return LineItemSectionCardComponent;
}());
export { LineItemSectionCardComponent };
