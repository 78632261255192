import { Model } from './model';

export enum RegexGroupOperator {
    And = 'and',
    Or = 'or'
}

export enum RegexRuleOperator {
    Contains = 'contains',
    DoesNotContain = 'doesNotContain',
    Equals = 'Equals'
}

export class RegexRule extends Model {
    operator: RegexRuleOperator | RegexGroupOperator;
    rules?: RegexRule[];
    term?: string;

    constructor(from?: Object) {
        super(Object.assign({ operator: RegexRuleOperator.Contains }, from));
    }

    isValid() {
        if (Array.isArray(this.rules)) {
            return Object.values(RegexGroupOperator).includes(this.operator)
                && this.rules.length > 0
                && this.rules.every(rule => rule.isValid());
        }

        return Object.values(RegexRuleOperator).includes(this.operator)
            && typeof this.term === 'string' && this.term.length > 0;
    }

    serialize(): string {
        return null;
    }

    get entity() {
        return 'Regex Rule';
    }
}
