import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'wordspace'
})
export class WordspacePipe implements PipeTransform {
    transform(value: string) {
        return value[0] + value.slice(1).replace(/([A-Z]{1})/g, ' $&');
    }
}
