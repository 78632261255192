import { SupplyImageAssetType } from 'app/shared/models/native-supply/supply-image-asset';
import { SupplyTextAssetType } from 'app/shared/models/native-supply/supply-text-asset';
import { Model } from '../model';

export interface SupplyAssetModel {
    required: boolean;
    model: number | { minWidth: number, minHeight: number };
    typeId: number;
    typeDescription: string;
    id?: number;
    version?: number;
    width?: number;
    height?: number;
    minWidth?: number;
    minHeight?: number;
    isExact?: boolean;
}

export enum SupplyAssetTypeId {
    Title = 20,
    SponsoredBy = 21,
    Description = 22,
    CTA = 32,
    ImageIcon = 33,
    ImageMain = 34
}

export interface SupplyAssetLinkType {
    id: number;
    required: boolean;
}

export enum IABAssets {
    'title' = 'title',
    'data' = 'data',
    'img' = 'img'
}

export class SupplyAsset extends Model {
    headline: SupplyAssetModel;
    sponsored: SupplyAssetModel;
    description: SupplyAssetModel;
    callToAction: SupplyAssetModel;
    mainImage: SupplyAssetModel;
    logoImage: SupplyAssetModel;

    get entity() {
        return 'SupplyAsset';
    }

    public serialize(): string {
        delete this._isClone;

        return JSON.stringify(this);
    }
}

export const SupplyAssetProperties = [
    {
        'label': 'Logo',
        'assetLabel': 'Logo',
        'modelKey': 'logoImage',
        'type': SupplyImageAssetType.Icon,
        'assetKey': 'img',
        'width': 1,
        'height': 1,
        'macro': 'l',
    },
    {
        'label': 'Headline',
        'assetLabel': 'Headline',
        'modelKey': 'headline',
        'type': null,
        'assetKey': 'title',
        'len': 90,
        'max': 255,
        'macro': 't',
        'required': true
    }, {
        'label': 'Body',
        'assetLabel': 'Body',
        'modelKey': 'description',
        'type': SupplyTextAssetType.Description,
        'assetKey': 'data',
        'len': 250,
        'max': 2000,
        'macro': 'd'
    }, {
        'label': 'CTA',
        'assetLabel': 'CTA',
        'modelKey': 'callToAction',
        'type': SupplyTextAssetType.CallToAction,
        'assetKey': 'data',
        'len': 15,
        'max': 255,
        'macro': 'cta'
    }, {
        'label': 'Sponsored',
        'assetLabel': 'Sponsored',
        'modelKey': 'sponsored',
        'type': SupplyTextAssetType.SponsoredBy,
        'assetKey': 'data',
        'len': 25,
        'max': 255,
        'macro': 'sb'
    }, {
        'label': 'Image',
        'assetLabel': 'Image',
        'modelKey': 'mainImage',
        'type': SupplyImageAssetType.Main,
        'assetKey': 'img',
        'width': 600,
        'height': 314,
        'macro': 'i'
    }
];

interface TitleAsset {
    len: string;
}

interface DataAsset {
    len: string;
    type: string;
}

interface ImgAsset {
    type: string;
    width?: number;
    height?: number;
    minWidth?: number;
    minHeight?: number;
}

export type SupplyAssetPayload = {
    id: string | null;
    required: boolean;
    version: string | null;
} & (TitleAsset | DataAsset | ImgAsset | {});