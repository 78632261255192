import { Directive, TemplateRef } from '@angular/core';

@Directive({
    exportAs: 'row',
    selector: '[row]'
})
export class RowDirective {
    private expansions = new Map<number, boolean>();
    constructor(public templateRef: TemplateRef<any>) { }

    toggleExpansion(index: number) {
        this.expansions.set(index, !this.expansions.get(index));
    }

    isExpanded(index: number) {
        return this.expansions.get(index);
    }

    getExpansions() {
        return this.expansions;
    }
}
