import * as tslib_1 from "tslib";
import { BackendRepository } from 'app/core/repositories/backend-repository';
import { PublisherIdentifier } from 'app/shared/models/publisher-identifier';
import { HttpClient } from '@angular/common/http';
var PublisherIdentifierRepository = /** @class */ (function (_super) {
    tslib_1.__extends(PublisherIdentifierRepository, _super);
    function PublisherIdentifierRepository(http) {
        return _super.call(this, http, '/publisher-identifier', PublisherIdentifier) || this;
    }
    return PublisherIdentifierRepository;
}(BackendRepository));
export { PublisherIdentifierRepository };
