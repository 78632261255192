import {
    AfterViewInit,
    Attribute,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ChangeDetectionStrategy,
    Injector,
    ChangeDetectorRef
} from '@angular/core';

import { ValidationService } from 'app/core/validation-service';
import { Option } from 'app/shared/elements/dropdown';
import { SearchComponent } from 'app/shared/elements/search';
import { DownloadHelper } from 'app/shared/helpers/download-helper';
import { YieldManagement } from 'app/shared/services/yield-management.service';
import { debounceTime, distinctUntilChanged, switchMap, retry } from 'rxjs/operators';
import { IdService } from 'app/core/id.service';

@Component({
    selector: 'domain-targeting',
    templateUrl: './domain-targeting.html',
    styleUrls: ['./domain-targeting.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DomainTargetingComponent implements OnInit, AfterViewInit {
    @Input() searchFn: (term: string) => Promise<string[]>;
    @Input() searchPlaceholder: string;
    @Input() targetingType: string = 'exclude';
    @Input() excludeOnly: boolean = false;
    @Input() fileName: string = 'targeted_domains';
    @Input() showInheritType: boolean = false;
    @Input() refId: number;
    @Input() allowDownload: boolean = true;
    @Input() disabled: boolean = false;
    @Input('disable-type-selection') disableTypeSelection: boolean = false;

    @Input('domains')
    set domains(val: string[]) {
        if (!Array.isArray(val)) {
            this.selected = [];
        } else {
            this.selected = val;
        }
    }
    get domains(): string[] {
        return this.selected;
    }

    @Output('domainsChange') selectedChange: EventEmitter<string[]> = new EventEmitter<string[]>();
    @Output() targetingTypeChange: EventEmitter<any[]> = new EventEmitter<any[]>();

    @ViewChild('searchInput', { static: false }) searchInput: SearchComponent;

    inputText: string;
    selected: string[] = [];

    targetingTypes: Option[] = [
        { key: 0, value: 'exclude', label: 'Block List' },
        // { key: 1, value: 'include', label: 'Allow List' }
    ];

    _searchResults = [];
    isLoading: boolean = false;
    isSearchable: boolean = false;
    invalidText = 'The domain URLs above were not added because they are invalid.';
    placeholder = 'Enter advertiser domains one per each line or separated by commas.\n\n' +
        'ie.\n' +
        'advertiser.com\n' +
        'anotherone.com\n' +
        'athird.com';

    yieldManagement: YieldManagement;

    constructor(
        private injector: Injector,
        private cdr: ChangeDetectorRef,
        public id: IdService
    ) {}

    /**
     * Perform set up after the `Input` properties have been resolved.
     */
    ngOnInit(): void {
        this.isSearchable = typeof this.searchFn === 'function';

         // HOTFOX --- REMOVE WHEN READY
        if (this.excludeOnly === false) {
            this.targetingTypes.push({ key: 1, value: 'include', label: 'Allow List' });
        }

        if (this.showInheritType) {
            this.targetingTypes.push({ key: 2, value: 'inherit', label: 'Inherit' });
        }

        try {
            this.yieldManagement = this.injector.get(YieldManagement);
        } catch (err) {
            this.yieldManagement = null;
        }
    }

    /**
     * Register subscribers after the component's child views have been created.
     */
    ngAfterViewInit(): void {
        if (this.isSearchable) {
            this.registerSearch();
        }
    }

    /**
     * Register and set up the search.
     */
    private registerSearch(): void {
        this.searchInput.inputChange.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            switchMap(this.search.bind(this)),
            retry()
        ).subscribe(results => {
            this.isLoading = false;
            const domains = Array.from(new Set((results as any[]).map(result => result.domain)));
            this.searchResults = domains.map(domain => this.mapToOption(domain));
            this.cdr.detectChanges();
        });
    }

    /**
     * Proxy the search function so we can enable the loading spinner.
     * @param  {string}            searchTerm
     * @return {Promise<string[]>}
     */
    private search(searchTerm: string): Promise<any[]> {
        this.isLoading = true;
        return this.searchFn(searchTerm);
    }

    /**
     * Get the list of search results, excluding currently selected items.
     * @return {Option[]}
     */
    private get searchResults(): Option[] {
        return this._searchResults.filter(result => {
            return !this.selected.some(selectedItem => result.value === selectedItem);
        });
    }

    /**
     * Set the list of search results.
     * @param {Option[]} values
     */
    private set searchResults(values: Option[]) {
        this._searchResults = values;
    }

    /**
     * Map an array of strings to an array of Options.
     * @param  {string[]} items
     * @return {Option}
     */
    private mapToOption(item: string): Option {
        return { key: item, value: item, label: item };
    }

    /**
     * Add an option's value to the list.
     * @param {Option} option
     */
    selectOption(option: Option): void {
        this.update(this.selected.concat(option.value));
    }

    /**
     * Check if a string resembles a valid URL.
     * @param  {string}  domain
     * @return {boolean}
     */
    isDomainValid(domain: string): boolean {
        return ValidationService.isValidDomain(domain);
    }

    /**
     * Prevent clicking on the domain search results
     * from closing the results so a user can
     * select as many values as needed.
     * @param {Event} $event
     */
    keepResultsOpen($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.searchInput.trigger.nativeElement.focus();
    }

    /**
     * Parse raw text into an array of lowercase domains.
     * @param  {string}  rawList
     * @return {KVP[]}
     */
    parse(rawList: string) {
        return rawList
            .replace(/\n/g, ',')
            .split(',')
            .map(token => token.trim())
            .filter(token => token !== '')
            .map(domain => domain.toLowerCase());
    }

    /**
     * Clear out the componet. This is needed where a form
     * is to be reused rather than freshly instantiated.
     */
    reset() {
        this.inputText = null;
        this.clear();
    }

    clear() {
        this.update([]);
    }

    /**
     * Update the list and propagate the changes.
     */
    update(values: string[]) {
        this.selected = values.slice();
        this.selectedChange.emit(this.selected);
    }

    download() {
        const targetingType = this.targetingTypes.find(current => this.targetingType === current.value).label;
        const data = this.selected.map(domain => ({
            'targeting type': targetingType,
            domain
        }));
        DownloadHelper.downloadAsCSV(data, this.fileName);
    }

    get hasRefId() {
        return typeof this.refId === 'number';
    }

    isDomainTargetingTypeInherit(): boolean {
        return this.targetingType && this.targetingType === 'inherit';
    }
}
