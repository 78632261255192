import { ChangeDetectorRef } from '@angular/core';
var XDropdownComponent = /** @class */ (function () {
    function XDropdownComponent(changeDetector) {
        this.changeDetector = changeDetector;
        this.visibility = 'hidden';
    }
    XDropdownComponent.prototype.show = function () {
        this.visible = true;
    };
    XDropdownComponent.prototype.hide = function () {
        this.visible = false;
    };
    Object.defineProperty(XDropdownComponent.prototype, "visible", {
        set: function (visible) {
            this.visibility = visible ? 'visible' : 'hidden';
            this.changeDetector.markForCheck();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(XDropdownComponent.prototype, "isVisible", {
        get: function () {
            return this.visibility === 'visible';
        },
        enumerable: true,
        configurable: true
    });
    return XDropdownComponent;
}());
export { XDropdownComponent };
