import { Publisher } from 'app/shared/models';
import { MediaGroupRepository, PublisherRepository } from 'app/core/repositories';
import { of, zip } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';
var PublisherResolver = /** @class */ (function () {
    function PublisherResolver(mediaGroupRepository, publisherRepository) {
        this.mediaGroupRepository = mediaGroupRepository;
        this.publisherRepository = publisherRepository;
    }
    PublisherResolver.prototype.resolve = function (route) {
        var _this = this;
        var publisherId = route.params['id'];
        var mediaGroupId = route.params['mediaGroupId'];
        if (publisherId) {
            return this.publisherRepository.get(publisherId).pipe(switchMap(function (publisher) { return zip(_this.mediaGroupRepository.get(publisher.mediaGroup), of(publisher)); }), switchMap(function (_a) {
                var mediaGroup = _a[0], publisher = _a[1];
                publisher.mediaGroupObj = mediaGroup;
                return of(publisher);
            }));
        }
        if (mediaGroupId) {
            return this.newPublisher(mediaGroupId);
        }
        var publisher = new Publisher();
        return of(publisher);
    };
    PublisherResolver.prototype.newPublisher = function (mediaGroupId) {
        return this.mediaGroupRepository.get(mediaGroupId)
            .pipe(mergeMap(function (mediaGroup) {
            var publisher = new Publisher();
            publisher.mediaGroup = mediaGroup.id;
            publisher.mediaGroupObj = mediaGroup;
            return of(publisher);
        }));
    };
    return PublisherResolver;
}());
export { PublisherResolver };
