import { Model } from './model';

import { Newsletter } from 'app/shared/models/newsletter';
import { AdSlotSize } from 'app/shared/models/ad-slot-size';
import { NativeBlueprint } from 'app/shared/models/native-blueprint';
import { RTBPartner } from 'app/shared/models/rtb-partner';
import { Blueprint } from './blueprint';
import { LineItem } from './line-item';

import colors from 'src/data/colors/palette.json';
import { Creative } from './creative';
import { DemandImageAssetType } from './native-demand';

export enum AdSlotTagType {
    Universal = 'universal',
    Legacy = 'legacy'
}

export enum AdSlotTagVersion {
    Hybrid = '3.0.0'
}

export enum AdSlotType {
    FixedRatio = 'image',
    TaboolaNative = 'native',
    ImageNative = 'imageNative',
    Hybrid = 'hybrid'
}

export enum AdSlotTypeLabel {
    FixedRatio = 'Display',
    TaboolaNative = 'Taboola',
    ImageNative = 'Native',
    Hybrid = 'Display & Native'
}

export enum AdIndicatorType {
    Advertisement = 1,
    Advertising = 2,
    AdvertiserContent = 3,
    Sponsored = 4,
    SponsoredAd = 5,
    SponsoredBy = 6,
    Blank = 7
}

export enum AdSlotStatus {
    Active = 'active',
    Pending = 'pending'
}

// This was used for the old CM table but was updated to fix a type error. It should be removed when the old CM table is removed.
export interface AdSlotPackageData {
    adSlotId: string,
    packages: {
        id: number|string,
        name: string
    }[]
}

export interface PackageInfo {
    packageId: string,
    packageName: string,
    entity: string,
    entityId: string
}

export class AdSlot extends Model {
    refId: number;
    publisher: string;
    publisherName: string;
    publisherRefId: number;
    newsletterName: string;
    newsletterRefId: number;
    coordinationType: string;
    created: string;
    createdBy: string;
    createdByUser: string;
    modifiedBy: string;
    modifiedByUser: string;
    newsletter: string;
    type: string;
    tagType: string;
    taboola: {};
    sspControl: {
        exchangeAllow: boolean,
        exchangeAllowDisplay: boolean,
        exchangeAllowNative: boolean,
        exchangeFloor: number,
        nativeFloor: number,
        rtbFloor: number,
        rtbAllow: boolean,
        rtbAllowDisplay: boolean,
        rtbAllowNative: boolean
    };
    effectiveSspControl: {
        exchangeFloor: number,
        exchangeAllow: boolean,
        exchangeAllowDisplay: boolean,
        exchangeAllowNative: boolean,
        rtbAllow: boolean,
        rtbAllowDisplay: boolean,
        rtbAllowNative: boolean
    };
    name: string;
    sizes: AdSlotSize[];
    nativeBlueprints: NativeBlueprint[];
    isUniversalTag: boolean;
    collapse: boolean;
    isNative: boolean;
    isDisplay: boolean;
    isHybrid: boolean;
    externalUrl: string;
    externalUrlEnabled: boolean;
    privateDeals: boolean;
    deviceVisibility?: string;
    adIndicatorId: number;
    adIndicatorIdDisplay: number;
    adIndicatorIdNative: number;
    allowAdChoicesDisplay: boolean;
    allowAdChoicesNative: boolean;
    allowPoweredByDisplay: boolean;
    allowPoweredByNative: boolean;
    native: { assets: any[] };
    dsps: RTBPartner[];
    nativeBlueprintObjects: Blueprint[];
    currentTagVersion: string;
    previousTagVersion: string;
    allPackageInfo?: PackageInfo[];
    primaryPackageInfo?: PackageInfo[];
    creativeMappingStrategies?: string[];
    updateCreativeMapping?: boolean;
    publisherClientTypes: string[];
    enforceCoordinationType: boolean;
    activateCoordination?: boolean;
    coordinationStatus?: string;

    domainTargeting?: {
        type: string,
        values?: string[]
    };

    sensitiveCategoryTargeting?: {
        type: string,
        values?: number[]
    }

    private _status: string;

    lastSeen: string;

    newsletterObj?: Newsletter;
    eligibleCreatives?: any;

    constructor(data: any = {}) {
        super(data);
        this._status = data.status;
    }

    get status() {
        if (this._status === 'deleted') {
            return 'archived';
        }

        return this._status;
    }

    set status(value: string) {
        this._status = value;
    }

    isPending(): boolean {
        return this.status === AdSlotStatus.Pending;
    }

    getAdType(): string {
        let type: string = this.type;

        if (this.isHybrid) {
            type = AdSlotTypeLabel.Hybrid;
        } else if (this.isNative) {
            type = AdSlotTypeLabel.ImageNative;
        } else if (type === AdSlotType.TaboolaNative) {
            type = AdSlotTypeLabel.TaboolaNative;
        } else if (type === AdSlotType.FixedRatio) {
            type = AdSlotTypeLabel.FixedRatio;
        }

        return type;
    }

    getAdIndicatorType() {
        if (this.adIndicatorId) {
            return AdIndicatorType[this.adIndicatorId];
        }

        return 'Blank';
    }

    getCoordinationLabel(): string {
        if (this.tagType === AdSlotTagType.Universal || this.getTagType() === AdSlotTagType.Universal) {
            if (this.coordinationType) {
                return 'Enabled';
            }
            return 'Disabled';
        }

        return 'N/A';
    }

    getCoordinationLabelForLineItem(lineItem: LineItem): string {
        if (lineItem.isCoordinated) {
            return this.coordinationStatus;
        }

        return this.getCoordinationLabel();
    }

    /*
    * Tag Type is universal if adslot has display enabled with multiple sizes or single size and collapse enabled
    * If adslot is native only then it is considered a Universal Tag by default
    */
    getTagType(): string {
        if ((this.isUniversalTag && this.sizes && this.sizes.length > 1) ||
            (this.isUniversalTag && this.sizes && this.sizes.length === 1 && this.collapse) ||
            (this.isUniversalTag && this.isNative && this.isDisplay != true)) {
            return AdSlotTagType.Universal;
        }
        return AdSlotTagType.Legacy;
    }

    // [TODO] Use collapse field instead of tagtype https://liveintent.atlassian.net/browse/MT-5222
    getCollapsibleStatus(): string {
        if (this.tagType === AdSlotTagType.Universal || this.getTagType() === AdSlotTagType.Universal) {
            return 'Yes';
        }
        return 'No';
    }

    serialize(): string {
        const adSlot = JSON.parse(JSON.stringify(this));
        delete adSlot.newsletterObj;
        delete adSlot.eligibleCreatives;

        if (adSlot.type !== 'native' && adSlot.taboola) {
            delete adSlot.taboola;
        }

        if ('sspControl' in adSlot) {
            delete adSlot.sspControl.uniqueAds;
        }

        if ('nativeBlueprints' in adSlot && adSlot.nativeBlueprints) {
            adSlot.nativeBlueprints.forEach(nativeBlueprint => {
                delete nativeBlueprint.blueprintName;
                delete nativeBlueprint.native;
                delete nativeBlueprint.maxHeight;
                delete nativeBlueprint.minHeight;
                delete nativeBlueprint.width;
                delete nativeBlueprint.mobileMaxHeight;
                delete nativeBlueprint.mobileMinHeight;
                delete nativeBlueprint.mobileWidth;
            });
        }

        delete adSlot.native;

        if (!adSlot.externalUrl && adSlot.externalUrlEnabled) {
            delete adSlot.externalUrl;
        }

        if (!adSlot.sizes) {
            delete adSlot.sizes;
        }

        return adSlot;
    }

    get entity(): string {
        return 'Ad Slot';
    }

    isPrimary(): boolean {
        return this.type == 'image';
    }

    isTaboola(): boolean {
        return this.type === AdSlotType.TaboolaNative;
    }

    nativeEnabledStatus(): string {
        if (this.isTaboola()) {
            return 'N/A';
        }

        return this.isNative ? 'Yes' : 'No';
    }

    displayEnabledStatus(): string {
        if (this.isTaboola()) {
            return 'N/A';
        }
        if (this.isDisplay) {
            return 'Yes';
        }
        return 'No';
    }

    /**
     * Check if the hybrid adslot is using an LT3 tag to support serving native.
     * Newly created adslots will have currentTagVersion set as null so the new adslots need to be excluded.
     */
    get isNativeUnsupported(): boolean {
        return this.isHybrid && this.currentTagVersion !== AdSlotTagVersion.Hybrid && this.currentTagVersion !== null;
    }

    /**
     * Display the warning only in some locations when native is enabled.
     */
    get showNativeUnsupportedWarning(): boolean {
        return this.isNativeUnsupported && this.isNative;
    }

    showNoBlueprintsInfo(): boolean {
        return this.isNative && this.nativeBlueprints && this.nativeBlueprints.length < 1;
    }

    get adSlotStatusColor(): string {
        return this.status === AdSlotStatus.Active ? colors['sushi'] : colors['storm-gray'];
    }

    get displaySizes(): string | AdSlotSize[] {
        if (!this.isDisplay && this.isNative) {
            return 'N/A';
        }

        return this.sizes;
    }

    getBlueprintAspectRatio(blueprint: NativeBlueprint): string {
        if (blueprint.desktopAspectRatio && blueprint.mobileAspectRatio && blueprint.desktopAspectRatio !== blueprint.mobileAspectRatio) {
            return `${blueprint.desktopAspectRatio} (Desktop)\n${blueprint.mobileAspectRatio} (Mobile)`;
        }

        if (blueprint.desktopAspectRatio) {
            return blueprint.desktopAspectRatio;
        }

        if (blueprint.mobileAspectRatio) {
            return `${blueprint.mobileAspectRatio} (Mobile)`;
        }

        return 'N/A';
    }

    getEligibleBlueprintAspectRatios(eligibleBlueprintIds: number[]): string {
        if (this.nativeBlueprints.length === 0 || !eligibleBlueprintIds) {
            return 'N/A';
        }

        const allAspectRatios = new Set<string>();
        const desktopOnlyAspectRatios = new Set<string>();
        const mobileOnlyAspectRatios = new Set<string>();
        let hasNAAspectRatio = false;

        // Populate aspect ratios into respective sets
        this.nativeBlueprints
            .filter(blueprint => eligibleBlueprintIds.includes(blueprint.blueprintId))
            .forEach(blueprint => {
                const { desktopAspectRatio, mobileAspectRatio } = blueprint;

                if (desktopAspectRatio && mobileAspectRatio && desktopAspectRatio === mobileAspectRatio) {
                    // Add to combined set if both ratios are the same
                    allAspectRatios.add(blueprint.desktopAspectRatio);
                } else {
                    if (desktopAspectRatio && !allAspectRatios.has(desktopAspectRatio)) {
                        desktopOnlyAspectRatios.add(desktopAspectRatio + ' (Desktop)');
                    }
                    if (mobileAspectRatio && !allAspectRatios.has(mobileAspectRatio)) {
                        mobileOnlyAspectRatios.add(mobileAspectRatio + ' (Mobile)');
                    }
                    if (!desktopAspectRatio && !mobileAspectRatio) {
                        hasNAAspectRatio = true;
                    }
                }
            }
        );

        // Aspect ratios for both desktop and mobile will be listed first, followed by desktop-only and mobile-only ratios
        const combinedAspectRatios = [
            ...Array.from(allAspectRatios),
            ...Array.from(desktopOnlyAspectRatios).filter(ratio => !allAspectRatios.has(ratio.replace(' (Desktop)', ''))),
            ...Array.from(mobileOnlyAspectRatios).filter(ratio => !allAspectRatios.has(ratio.replace(' (Mobile)', '')))
        ];

        // Append "N/A" at the end if necessary
        if (hasNAAspectRatio || !combinedAspectRatios.length) {
            combinedAspectRatios.push('N/A');
        }

        return combinedAspectRatios.join(', ');
    }

    get primaryPackageNames(): string[] {
        if (!this.primaryPackageInfo) {
            return [];
        }
        return this.primaryPackageInfo.map(packageInfo => packageInfo.packageName);
    }

    displayAspectRatioMismatchWarning(blueprint: NativeBlueprint, creative: Creative): boolean {
        if (!this.shouldCheckAspectRatio(blueprint, creative)) {
            return false;
        }

        const creativeAspectRatio = this.getCreativeMainImageAspectRatio(creative);
        return creativeAspectRatio !== null && creativeAspectRatio !== this.getBlueprintAspectRatio(blueprint);
    }

    private shouldCheckAspectRatio(blueprint: NativeBlueprint, creative: Creative): boolean {
        const blueprintAspectRatio = this.getBlueprintAspectRatio(blueprint);
        return creative.isNative && blueprintAspectRatio !== 'N/A' && blueprintAspectRatio !== undefined;
    }

    private getCreativeMainImageAspectRatio(creative: Creative): string {
        const creativeMainImageAsset = creative.native.assets.find(asset =>
            this.isDemandImageAsset(asset) && asset.hasOwnProperty('aspectRatio')
        );

        return creativeMainImageAsset ? creativeMainImageAsset.aspectRatio : null;
    }

    private isDemandImageAsset(asset): boolean {
        return asset && asset.hasOwnProperty('img') && asset.img.hasOwnProperty('type') && asset.img.type === DemandImageAssetType.Main
    }

    newsletterHasCoordinatedAdSlots() {
        if (this.newsletterObj && this.newsletterObj.allowCoordination && this.newsletterObj.adSlots) {
            return this.newsletterObj.adSlots.filter(adSlot => adSlot.coordinationType === 'companion').length > 0;
        }

        return false;
    }
}
