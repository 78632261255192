import { Component, ViewChild } from '@angular/core';

import { IdService } from 'app/core';
import { ErrorHelper } from 'app/core/errors';
import { BulkOperationRepository } from 'app/core/repositories/bulk-operation-repository.service';
import { LightboxnextComponent } from 'app/shared/elements/lightboxnext';
import { BulkOperationStatusResponse, OperationResponse, OperationStatus } from 'app/shared/models/bulk-request';

interface OperationStatusDetails {
    id: string,
    name: string,
    errorMessage?: string
}

@Component({
    selector: 'bulk-operation-status-modal',
    templateUrl: './bulk-operation-status-modal.html',
    styleUrls: ['./bulk-operation-status-modal.styl']
})
export class BulkOperationStatusModalComponent {
    @ViewChild(LightboxnextComponent, { static: true }) lightbox: LightboxnextComponent;

    cancelledOperations: OperationStatusDetails[] = [];
    successfulOperations: OperationStatusDetails[] = [];
    failedOperations: OperationStatusDetails[] = [];
    pendingOperations: OperationStatusDetails[] = [];
    loading: boolean = true;

    constructor(
        private bulkOperationRepo: BulkOperationRepository,
        public id: IdService
    ) { }

    private getOperationIdAndName(uri: string[]): string[] {
        if (uri.length > 2) {
            switch (uri[1]) {
                case 'creatives': return [uri[2].replace('li-', ''), 'creative'];
                default: return [uri[2], uri[1]];
            }
        }

        return [uri[1], uri[0]];
    }

    private getErrorMessage(operation: OperationResponse): string {
        if (!operation || !operation.response) {
            return 'An error has occurred';
        }

        const responseBody = operation.response.body;

        // MCS/Laravel error message format
        if (responseBody && responseBody.message) {
            return responseBody.message;
        }

        // Standard error message format (LSD)
        if (responseBody && responseBody.errors && !responseBody.message) {
            const helper = new ErrorHelper();

            helper.loadBackEndErrors(responseBody.errors);

            return helper.errors[0].slice(0, -1);
        }

        return 'An error has occurred.';
    }

    private parseUri(operation: OperationResponse): string[] {
        const uri = operation.uri.split('/');

        if (uri[0] === '') {
            uri.shift();
        }

        return uri;
    }

    open(BulkOperationStatusResponse: BulkOperationStatusResponse): void {
        this.loading = true;
        this.lightbox.show();

        this.bulkOperationRepo.getStatus(BulkOperationStatusResponse.id).subscribe(bulkOperationStatus => {
            this.cancelledOperations = [];
            this.successfulOperations = [];
            this.failedOperations = [];
            this.pendingOperations = [];

            bulkOperationStatus.operations.map(operation => {
                const uri = this.parseUri(operation);
                const [id, name] = this.getOperationIdAndName(uri);

                const operationStatusDetails: OperationStatusDetails = {
                    id: id,
                    name: name
                }

                if (operation.status_code >= 400) {
                    operationStatusDetails.errorMessage = this.getErrorMessage(operation);
                    this.failedOperations.push(operationStatusDetails);
                } else if (operation.status_code >= 200 && operation.status_code <= 300) {
                    // Succeeded operations
                    this.successfulOperations.push(operationStatusDetails);
                } else if (operation.status === OperationStatus.Cancelled) {
                    // Cancelled Operations
                    this.cancelledOperations.push(operationStatusDetails);
                } else if (operation.status_code === -1) {
                    // Pending operations
                    this.pendingOperations.push(operationStatusDetails);
                }
            });

            this.loading = false;
        });
    }

    close(): void {
        this.lightbox.hide();
    }
}
