import { QueryList } from '@angular/core';
import { StepComponent } from './step';
var WizardComponent = /** @class */ (function () {
    function WizardComponent(headerColumns, bodyColumns) {
        this.headerColumns = headerColumns || 6;
        this.bodyColumns = bodyColumns || 8;
    }
    Object.defineProperty(WizardComponent.prototype, "headerColumnClassList", {
        get: function () {
            return "column--" + this.headerColumns + " offset--" + (12 - this.headerColumns) / 2;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WizardComponent.prototype, "bodyColumnClassList", {
        get: function () {
            return "column--" + this.bodyColumns + " offset--" + (12 - this.bodyColumns) / 2;
        },
        enumerable: true,
        configurable: true
    });
    WizardComponent.prototype.nextStep = function () {
        var currentStep = this.activeStepIndex;
        var steps = this.steps.toArray();
        if (currentStep >= steps.length) {
            return;
        }
        this.setActiveStep(currentStep + 1);
    };
    WizardComponent.prototype.previousStep = function () {
        var currentStep = this.activeStepIndex;
        if (currentStep < 1) {
            return;
        }
        this.setActiveStep(currentStep - 1);
    };
    WizardComponent.prototype.setActiveStep = function (stepIndex) {
        this.steps.forEach(function (step, i) {
            step.isActive = stepIndex === i;
        });
    };
    Object.defineProperty(WizardComponent.prototype, "activeStep", {
        get: function () {
            return this.steps.find(function (step) { return step.isActive; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WizardComponent.prototype, "activeStepIndex", {
        get: function () {
            return this.steps.toArray().findIndex(function (step) { return step.isActive; });
        },
        enumerable: true,
        configurable: true
    });
    return WizardComponent;
}());
export { WizardComponent };
