/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popover.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./popover.component";
var styles_PopoverComponent = [i0.styles];
var RenderType_PopoverComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopoverComponent, data: { "animation": [{ type: 7, name: "visibility", definitions: [{ type: 0, name: "visible", styles: { type: 6, styles: { opacity: 1, transform: "scale(1)" }, offset: null }, options: undefined }, { type: 0, name: "hidden", styles: { type: 6, styles: { opacity: 0.5, transform: "scale(.95)" }, offset: null }, options: undefined }, { type: 1, expr: "hidden => visible", animation: { type: 4, styles: null, timings: "50ms ease-in" }, options: null }], options: {} }] } });
export { RenderType_PopoverComponent as RenderType_PopoverComponent };
export function View_PopoverComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { popover: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["popover", 1]], null, 8, "div", [["class", "_popover"]], [[24, "@visibility", 0], [4, "maxWidth", "px"], [4, "left", "px"], [4, "top", "px"], [4, "width", "px"], [2, "_popover--top", null], [2, "_popover--bottom", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "_popover--nubbin"]], [[4, "left", "px"], [2, "centered", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "_popover--container"]], [[4, "left", "px"], [2, "no-margin", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visibility; var currVal_1 = _co.maxWidth; var currVal_2 = _co.left; var currVal_3 = _co.top; var currVal_4 = _co.width; var currVal_5 = (_co.position == "top"); var currVal_6 = (_co.position == "bottom"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.nubbinLeft; var currVal_8 = _co.centered; _ck(_v, 3, 0, currVal_7, currVal_8); var currVal_9 = _co.containerLeft; var currVal_10 = !_co.margin; _ck(_v, 5, 0, currVal_9, currVal_10); }); }
export function View_PopoverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "popover", [], null, null, null, View_PopoverComponent_0, RenderType_PopoverComponent)), i1.ɵdid(1, 114688, null, 0, i2.PopoverComponent, [i1.ChangeDetectorRef, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PopoverComponentNgFactory = i1.ɵccf("popover", i2.PopoverComponent, View_PopoverComponent_Host_0, { target: "target", event: "event", maxWidth: "maxWidth", margin: "margin" }, { onHide: "onHide" }, ["*"]);
export { PopoverComponentNgFactory as PopoverComponentNgFactory };
