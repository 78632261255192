import { ChangeDetectorRef } from '@angular/core';
import { Option } from './option.interface';

export class Navigable {
    public items: Array<Option>;
    public index = -1;
    public key: string | number = -1;

    constructor(private changeDetectorRef: ChangeDetectorRef) { }

    keydown(event: KeyboardEvent) {
        switch (event.which) {
            case 38: case 40: this.update(this.index + (event.which === 40 ? 1 : -1)); break;
            case 13: this.select(event, this.index); break;
            default: return;
        }
        event.preventDefault();
    }

    private update(index: number) {
        if (this.items.length < 1) {
            return;
        }
        this.index = index < 0 ? this.items.length - 1 : index % this.items.length;
        this.key = this.items[this.index].key;
        this.changeDetectorRef.markForCheck();
    }

    mouseenter(event: MouseEvent, key: number): number {
        this.key = key;
        for (let i in this.items) {
            if (this.items[i].key === key) {
                return this.index = parseInt(i);
            }
        }
        this.changeDetectorRef.markForCheck();
    }

    public reset() {
        this.key = -1;
        this.index = -1;
    }

    select(event, key) {
        throw new Error('Select not implemented.');
    }
}
