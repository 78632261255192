<global-navigation class="fixed">
    <a [attr.id]="id.control('platform-component', 'logo')" class="logo" routerLink="/accounts">
        <img [attr.id]="id.container('platform-component', 'logo')" [src]="getLogoSrc()" alt="LiveIntent">
    </a>
    <ul class="links">
        <li>
            <a [attr.id]="id.control('platform-component', 'campaign-manager')" routerLink="/campaign-manager" routerLinkActive="active">
                <span [attr.id]="id.text('platform-component', 'campaign-manager')">Campaign Manager</span>
            </a>
        </li>
        <li>
            <a [attr.id]="id.control('platform-component', 'inventory-manager')" routerLink="/inventory-manager" routerLinkActive="active">
                <span [attr.id]="id.text('platform-component', 'inventory-manager')">Inventory Manager</span>
            </a>
        </li>
        <li>
            <a [attr.id]="id.control('platform-component', 'reporting')" *ngIf="auth.hasReportingAccess" routerLink="/reporting" routerLinkActive="active">
                <span [attr.id]="id.text('platform-component', 'reporting')">Reporting</span>
            </a>
        </li>
        <li *ngIf="auth.isInternalUser">
            <a routerLink="/measurement" routerLinkActive="active">
                <span>Measurement</span>
            </a>
        </li>
    </ul>
    <global-search-input></global-search-input>
    <ul>
        <li>
            <a [attr.id]="id.control('platform-component', 'bulk-operation-status')" routerLink="/bulk-operation-status" routerLinkActive="active" title="Bulk Operation Status">
                <span [attr.id]="id.text('platform-component', 'bulk-operation-status')"><i class="fas fa-layer-group"></i></span>
            </a>
        </li>
        <li>
            <a [attr.id]="id.control('platform-component', 'history')" *ngIf="auth.isInternalUser" routerLink="/history" routerLinkActive="active" title="History">
                <span [attr.id]="id.text('platform-component', 'history')"><i class="fas fa-history"></i></span>
            </a>
        </li>
        <li>
            <a [attr.id]="id.control('platform-component', 'internal-tools')" routerLink="/internal-tools" routerLinkActive="active" *ngIf="auth.isAdmin || auth.isAccountManager || auth.isAdOps" title="Internal Tools">
                <span [attr.id]="id.text('platform-component', 'internal-tools')"><i class="fas fa-wrench"></i></span>
            </a>
        </li>
        <li>
            <a [attr.id]="id.control('platform-component', 'knowledge-link')" href="https://support.liveintent.com/hc/en-us" target="_blank" rel="noopener" class="knowledge-link" title="Knowledge Base">
                <span [attr.id]="id.text('platform-component', 'knowledge-link')"><i class="fas fa-question"></i></span>
            </a>
        </li>
        <li #profile>
            <button [attr.id]="id.control('platform-component', 'current.user')" type="button" class="plain">
                {{ (authentication.currentUser | async)?.displayInitials }}
                <i class="fas fa-angle-down"></i>
            </button>
        </li>
    </ul>
    <profile [target]="profile"></profile>
</global-navigation>
<div class="grid">
    <router-outlet></router-outlet>
</div>
<div [attr.id]="id.container('notifications')" class="notifications">
    <router-outlet name="notifications"></router-outlet>
</div>
