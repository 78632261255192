import { ElementRef } from '@angular/core';
var TaskComponent = /** @class */ (function () {
    function TaskComponent() {
        this.complete = true;
        this._currentTask = false;
    }
    Object.defineProperty(TaskComponent.prototype, "right", {
        get: function () {
            var el = this.content.nativeElement;
            return (el.scrollWidth / 2) - 15;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaskComponent.prototype, "currentTask", {
        get: function () {
            return this._currentTask;
        },
        enumerable: true,
        configurable: true
    });
    return TaskComponent;
}());
export { TaskComponent };
