import {Injectable} from '@angular/core';
import * as moment from 'moment';
import { LineItem } from 'app/shared/models';
import stat from './line-item-statuses.json';

const STATUS_SORT_ORDER = [
    stat.DELIVERING,
    stat.NOT_DELIVERING,
    stat.PAUSED,
    stat.READY,
    stat.NOT_READY,
    stat.INACTIVE,
    stat.COMPLETE
];
const byStartDate = (a, b) => { // most recent start date at the top
    if (moment(a.effectiveStartDate).isAfter(b.effectiveStartDate)) {
        return -1;
    }

    if (moment(a.effectiveStartDate).isBefore(b.effectiveStartDate)) {
        return 1;
    }

    return 0;
};

@Injectable()
export class LineItemsSortService {
    private _lineItems: Array<LineItem>;

    private set lineItems(val: Array<LineItem>) {
        this._lineItems = val;
    }

    private get lineItems(): Array<LineItem> {
        return this._lineItems || [];
    }

    public push(lineItems: Array<LineItem>): LineItemsSortService {
        this.lineItems = this.lineItems.concat(lineItems);
        return this;
    }

    public pull(): Array<LineItem> {
        const toReturn = this.lineItems.slice(0); // This creates clone of lineItems collection
        this.lineItems = null;
        return toReturn;
    }

    public excludeStatuses(exclude: Array<string> = []): LineItemsSortService {
        this.lineItems = this.lineItems.filter(lItem => {
            return exclude.indexOf(lItem['platformStatus']) === -1;
        });

        return this;
    }

    public sortByStatusAndDate(): LineItemsSortService {
        let sorted = [];

        STATUS_SORT_ORDER.forEach(status => {
            const sortedSubset = this.lineItems
                .filter(lItem => {
                    return lItem['platformStatus'].toLowerCase() === status;
                })
                .sort(byStartDate);
            sorted = sorted.concat(sortedSubset);
        });
        this.lineItems = sorted.map((lItem, index) => {
            lItem.index = index;
            return lItem;
        });

        return this;
    }
}
