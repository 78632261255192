import { Component, Input, Output, EventEmitter, ContentChildren, QueryList } from '@angular/core';
import { OptionComponent } from '../filter-select';
import { IdService } from 'app/core/id.service';

@Component({
    selector: 'bubble-card',
    templateUrl: './bubble-card.html',
    styleUrls: ['./bubble-card.styl']
})
export class BubbleCardComponent {
    @Input() title: string;
    @Input('options') inputOptions: any[];
    @Input('iconClass') iconName: string;
    @ContentChildren(OptionComponent) tplOptions: QueryList<OptionComponent>;
    @Output('onSelect') onSelect = new EventEmitter();

    constructor(
        public id: IdService
    ) {}

    get options() {
        if (this.tplOptions && this.tplOptions.length > 0) {
            return this.tplOptions.map((option, key) => {
                const param = option.value;
                const text = option.html;
                return { key, param, text };
            });
        }
        return this.inputOptions;
    }

    select(option) {
        this.onSelect.emit(option);
    }

    get iconClass() {
        return `fa-${this.iconName}`;
    }
}
