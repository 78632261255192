import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AuthorizationService } from 'app/core/authorization.service';

@Injectable()
export class ReportingGuard implements CanActivate {
    constructor(private router: Router, private authorization: AuthorizationService) { }

    canActivate(route: ActivatedRouteSnapshot) {
        if ((route.url[1].path === 'publisher' && !this.authorization.hasInventoryReportingAccess)
            || (route.url[1].path === 'advertiser' && !this.authorization.hasCampaignReportingAccess)) {
            this.router.navigate(['reporting']);
            return false;
        }

        return true;
    }
}
