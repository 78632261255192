/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lightbox.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../blink/blink.component.ngfactory";
import * as i3 from "../blink/blink.component";
import * as i4 from "../progress-bar/progress-bar.component.ngfactory";
import * as i5 from "../progress-bar/progress-bar.component";
import * as i6 from "../overlay-loading-indicator/overlay-loading-indicator.component.ngfactory";
import * as i7 from "../overlay-loading-indicator/overlay-loading-indicator.component";
import * as i8 from "../../../core/id.service";
import * as i9 from "@angular/common";
import * as i10 from "../button/button.component.ngfactory";
import * as i11 from "../button/button.component";
import * as i12 from "../pipes/empty.pipe";
import * as i13 from "./lightbox.component";
import * as i14 from "../../helpers/theme.service";
var styles_LightboxnextComponent = [i0.styles];
var RenderType_LightboxnextComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LightboxnextComponent, data: { "animation": [{ type: 7, name: "backdrop", definitions: [{ type: 0, name: "visible", styles: { type: 6, styles: { opacity: 0.8 }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: { type: 4, styles: null, timings: "175ms ease-in" }, options: null }, { type: 1, expr: ":leave", animation: [{ type: 4, styles: null, timings: "175ms ease-out" }, { type: 6, styles: { opacity: 0.8 }, offset: null }], options: null }], options: {} }, { type: 7, name: "lightbox", definitions: [{ type: 0, name: "visible", styles: { type: 6, styles: { transform: "scale(1)", opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: { type: 4, styles: null, timings: "175ms ease-in" }, options: null }, { type: 1, expr: ":leave", animation: { type: 4, styles: null, timings: "175ms ease-out" }, options: null }], options: {} }] } });
export { RenderType_LightboxnextComponent as RenderType_LightboxnextComponent };
function View_LightboxnextComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "backdrop"]], [[24, "@backdrop", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visibility; _ck(_v, 0, 0, currVal_0); }); }
function View_LightboxnextComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵeld(1, 0, null, null, 8, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                    "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 2, "blink", [], null, null, null, i2.View_BlinkComponent_0, i2.RenderType_BlinkComponent)), i1.ɵdid(7, 1097728, null, 0, i3.BlinkComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, ["..."])), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "relative progress-bar-container spacer--large-top spacer--small-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                    "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "progress-bar", [["indeterminate", ""]], null, null, null, i4.View_ProgressBarComponent_0, i4.RenderType_ProgressBarComponent)), i1.ɵdid(14, 49152, null, 0, i5.ProgressBarComponent, [[8, ""]], null, null), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵted(-1, null, ["\n            "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent.parent, 0), _co.action)); _ck(_v, 4, 0, currVal_0); }); }
function View_LightboxnextComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "overlay-loading-indicator", [["class", "no-spinner"], ["opacity", ".9"]], [[40, "@overlayLoadingIndicatorAnimation", 0]], null, null, i6.View_OverlayLoadingIndicatorComponent_0, i6.RenderType_OverlayLoadingIndicatorComponent)), i1.ɵdid(1, 49152, null, 1, i7.OverlayLoadingIndicatorComponent, [i1.Renderer2, i1.ElementRef, i8.IdService], { opacity: [0, "opacity"] }, null), i1.ɵqud(335544320, 1, { template: 0 }), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵand(0, [[1, 2]], null, 0, null, View_LightboxnextComponent_4)), (_l()(), i1.ɵted(-1, null, ["\n        "]))], function (_ck, _v) { var currVal_1 = ".9"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).overlayLoadingIndicatorAnimation; _ck(_v, 0, 0, currVal_0); }); }
function View_LightboxnextComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 33, "div", [["class", "lightbox"]], [[24, "@lightbox", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 30, "div", [["class", "container relative"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LightboxnextComponent_3)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(7, 0, null, null, 20, "header", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(9, 0, null, null, 17, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [["class", "column--8"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                    "])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), i1.ɵppd(15, 2), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵeld(18, 0, null, null, 7, "div", [["class", "column--4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                    "])), (_l()(), i1.ɵeld(20, 0, null, null, 4, "button", [["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_ButtonComponent_0, i10.RenderType_ButtonComponent)), i1.ɵdid(21, 49152, null, 0, i11.ButtonComponent, [[8, null], i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["\n                        "])), (_l()(), i1.ɵeld(23, 0, null, 0, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, 0, ["\n                    "])), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n        "])), i1.ɵncd(null, 1), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isLoading; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visibility; _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i1.ɵnov(_v.parent, 1), _co.header, "\u00A0")); _ck(_v, 14, 0, currVal_2); }); }
export function View_LightboxnextComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i9.TitleCasePipe, []), i1.ɵpid(0, i12.EmptyPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LightboxnextComponent_1)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LightboxnextComponent_2)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isVisible; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.isVisible; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_LightboxnextComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lightboxnext", [], null, [["window", "keydown.esc"]], function (_v, en, $event) { var ad = true; if (("window:keydown.esc" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_LightboxnextComponent_0, RenderType_LightboxnextComponent)), i1.ɵdid(1, 49152, null, 0, i13.LightboxnextComponent, [i14.ThemeService], null, null)], null, null); }
var LightboxnextComponentNgFactory = i1.ɵccf("lightboxnext", i13.LightboxnextComponent, View_LightboxnextComponent_Host_0, { header: "header" }, { shown: "shown", hidden: "hidden" }, [".body", "footer"]);
export { LightboxnextComponentNgFactory as LightboxnextComponentNgFactory };
