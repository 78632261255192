import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Bundle } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { publishLast, refCount, map } from 'rxjs/operators';
var BundleRepository = /** @class */ (function (_super) {
    tslib_1.__extends(BundleRepository, _super);
    function BundleRepository(http) {
        var _this = _super.call(this, http, '/bundle', Bundle) || this;
        // TODO implement based on the query params
        _this._allResult = null;
        return _this;
    }
    BundleRepository.prototype.all = function (query) {
        if (query === void 0) { query = {}; }
        if (this._allResult) {
            return this._allResult;
        }
        this._allResult = _super.prototype.all.call(this, query)
            .pipe(publishLast(), refCount());
        return this._allResult;
    };
    BundleRepository.prototype.searchAsOptions = function (query) {
        if (query === void 0) { query = {}; }
        return this.all(query).pipe(map(function (bundles) {
            return bundles.map(function (option) {
                return {
                    key: option.id,
                    value: option.id,
                    label: option.name
                };
            });
        }));
    };
    BundleRepository.prototype.searchByLineItem = function (strategy, params) {
        var _this = this;
        var url = this.url('/search/bundle/strategy');
        return this.http.post(url, Object.assign({ strategy: strategy }, params))
            .pipe(map(function (data) { return data['output'].map(function (item) { return _this.build(item); }); }));
    };
    return BundleRepository;
}(BackendRepository));
export { BundleRepository };
