import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sort'
})
export class SortPipe implements PipeTransform {
    private predicate: string = null;
    private reverse: boolean = null;
    private data: Array<any> = null;

    private static compare(key: string): ((a, b: any) => number) {
        return function (a, b: any) {
            let aVal = a[key];
            let bVal = b[key];
            if (typeof aVal === 'string') {
                aVal = aVal.toLowerCase();
            }
            if (typeof bVal === 'string') {
                bVal = bVal.toLowerCase();
            }
            if (aVal > bVal) {
                return 1;
            }
            if (bVal > aVal) {
                return -1;
            }
            return 0;
        };
    }

    transform(data: Array<any>, predicate: string, reverse: boolean = false): Array<any> {
        if (predicate === null) {
            return data;
        }
        if (this.data === data && this.predicate === predicate && this.reverse === reverse) {
            return data;
        }
        this.data = data;
        this.predicate = predicate;
        this.reverse = reverse;
        data.sort(SortPipe.compare(predicate));
        if (this.reverse) {
            data.reverse();
        }
        return data;
    }
}
