import { Attribute, Component, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Creative } from 'app/shared/models';
import { HasDirtyCheck } from 'app/shared/components/bulk-edit-lightbox';
import {AdDetailsMacroComponent} from "app/shared/components/ad-details/ad-details-macro";
import { IdService } from 'app/core/id.service';


@Component({
    selector: 'bulk-edit-creative-urls',
    templateUrl: './bulk-edit-creative-urls.component.html',
    styleUrls: ['./bulk-edit-creative-urls.component.styl']
})
export class BulkEditCreativeUrlsComponent implements HasDirtyCheck {
    @ViewChild('clickUrl', { static: true }) clickUrlInput: NgModel;
    @ViewChild('urlTracking1', { static: true }) trackingUrl1Input: NgModel;
    @ViewChild('urlTracking2', { static: true }) trackingUrl2Input: NgModel;
    @ViewChild(AdDetailsMacroComponent, { static: true }) macroInfoBox: AdDetailsMacroComponent;

    creatives: Creative[] = [];
    private originals = new Map<number, Creative>();
    label: string;
    field: string;
    fields = ['clickUrl', 'urlTracking1', 'urlTracking2'];

    constructor(
        @Attribute('label') label: string,
        public id: IdService
    ) {
        this.label = label;
    }

    original(refId: number) {
        return this.originals.get(refId);
    }

    set entities(entities: Creative[]) {
        this.creatives = entities;

        this.originals.clear();
        entities.forEach(creative => {
            this.originals.set(creative.refId, creative.clone());
        });
    }

    get entities() {
        return this.creatives;
    }

    revert(creative: Creative, field: string) {
        creative[field] = this.original(creative.refId)[field];
    }

    resetClickUrl() {
        this.clickUrlInput.reset();
        this.creatives.forEach(creative => this.revert(creative, 'clickUrl'));
    }

    resetUrlTracking1() {
        this.trackingUrl1Input.reset();
        this.creatives.forEach(creative => this.revert(creative, 'urlTracking1'));
    }

    resetUrlTracking2() {
        this.trackingUrl2Input.reset();
        this.creatives.forEach(creative => this.revert(creative, 'urlTracking2'));
    }

    revertFields(creative: Creative) {
        this.fields.forEach(field => this.revert(creative, field));
    }

    isDirty() {
        return this.entities.some(creative => this.isCreativeDirty(creative));
    }

    isCreativeDirty(creative: Creative) {
        const original = this.originals.get(creative.refId);

        return original.clickUrl !== creative.clickUrl
            || original.urlTracking1 !== creative.urlTracking1
            || original.urlTracking2 !== creative.urlTracking2;
    }

    updateCreatives(value: string, field: string) {
        this.creatives.forEach(creative => creative[field] = value);
    }

    openValidMacro() {
        this.macroInfoBox.open();
    }

    reset(){
        this.resetClickUrl();
        this.resetUrlTracking1();
        this.resetUrlTracking2();
    }
}
