import * as tslib_1 from "tslib";
import { MaxLengthValidator } from '@angular/forms';
var CharacterMaxLengthValidator = /** @class */ (function (_super) {
    tslib_1.__extends(CharacterMaxLengthValidator, _super);
    function CharacterMaxLengthValidator() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return CharacterMaxLengthValidator;
}(MaxLengthValidator));
export { CharacterMaxLengthValidator };
