import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { ESPRepository } from 'app/core/repositories';
import { ReplaySubject, Subscription } from 'rxjs';
import { Option } from 'app/shared/elements/dropdown';
import { BehaviorSubject } from 'rxjs/index';
import options from './email-service-provider.options.json';
import { ESP, Model } from 'app/shared/models';
import { EspProperties, EspUserMacroTagTypeMap, Tag } from 'app/shared/models/esp';
import { EspFieldOverride } from "app/shared/models/mixin/esp-field-override";
import { SelectDropdownComponent } from 'app/shared/elements/select-dropdown';

const TYPE_NEWSLETTER = 'newsletter';

@Component({
    selector: 'email-service-provider-card',
    templateUrl: './email-service-provider-card.html',
    styleUrls: ['./email-service-provider-card.styl'],
})
export class EmailServiceProviderCardComponent <T extends Model & EspFieldOverride> implements OnInit {
    @ViewChild('lceeKeySelectDropdown', { static: true }) lceeKeySelectDropdown: SelectDropdownComponent;
    @Input() showHeader: boolean = true;
    @Input() showSupportBlurb: boolean = true;
    @Input() showPublisherCopySetting: boolean = true;
    @Input() type: string;
    @Input() isBulkEdit: boolean = false;
    @Input() set entity(value: T) {
        if (value) {
            this._data = value;
            this.loadEspSettings(value, this.isBulkEdit);
        }
    }

    @Output() espSettingsChangedEvent = new EventEmitter<T>();

    options = options;

    espOptions = new ReplaySubject<Option[]>(1);
    userTagReplacementOptions = new ReplaySubject<Option[]>(1);
    placementTagReplacementOptions = new ReplaySubject<Option[]>(1);
    listTagReplacementOptions = new ReplaySubject<Option[]>(1);
    lceeTagReplacementOptions = new ReplaySubject<Option[]>(1);
    lceeKeyReplacementOptions = new ReplaySubject<Option[]>(1);
    espLabel = new ReplaySubject<string>(1);
    espSubscription: Subscription;

    userTagAllowCustom = false;
    placementTagAllowCustom = false;
    listTagAllowCustom = false;
    lceeKeyAllowCustom = false;
    lceeTagAllowCustom = false;
    Tag = Tag;
    EspUserMacroTagTypeMap = EspUserMacroTagTypeMap;

    public showAdvanced: boolean = false;
    public _includeListParam: boolean = false;

    allEsps = {};

    esp$ = new BehaviorSubject('');
    userTagReplacement$ = new BehaviorSubject('');
    userTagType$ = new BehaviorSubject('');
    placementTagReplacement$ = new BehaviorSubject('');
    listTagReplacement$ = new BehaviorSubject('');
    lceeKeyReplacement$ = new BehaviorSubject('');
    lceeTagReplacement$ = new BehaviorSubject('');

    placementKey = EspProperties[Tag.PLACEMENT].label;
    listKey = EspProperties[Tag.LIST].label;
    EspProperties = EspProperties;

    _data: T;
    _esp;
    _userTagReplacement;
    _userTagType;
    _placementTagReplacement;
    _listTagReplacement;
    _lceeKeyReplacement;
    _lceeTagReplacement;

    _userTagReplacementOptions: Option[] = [];
    _placementTagReplacementOptions: Option[] = [];
    _listTagReplacementOptions: Option[] = [];
    _lceeTagReplacementOptions: Option[] = [];
    _lceeKeyReplacementOptions: Option[] = [];

    constructor(
        public espRepository: ESPRepository
    ) {}

    ngOnInit(): void {
        this.loadEsps();

        if (this.isNewsletter() && this.isNew()) {
            this.loadEspSettings(this._data.publisherObj);
        }

        this.espOptions.subscribe((esps) => {
            if (esps.length > 0 && this.esp) {
                // ESP options are now loaded, set the selected ESP
                this.esp$.next(this.esp);
            }
        });

        combineLatest([this.userTagType$, this.esp$])
            .subscribe(([userTagType, esp]) => {
                if (this.esp && this.userTagType) {
                    this.buildUserTagReplacementOptions(userTagType);
                }

        });

        this.esp$.subscribe(() => {
            if (this.esp && this.allEsps) {
                this.buildReplacementOptions();
            }
        });

        this.userTagReplacementOptions.subscribe((options) => {
            if (options) {
                this._userTagReplacementOptions = options;
            }
        });
        this.placementTagReplacementOptions.subscribe((options) => this._placementTagReplacementOptions = options);
        this.listTagReplacementOptions.subscribe((options) => this._listTagReplacementOptions = options);
        this.lceeKeyReplacementOptions.subscribe((options) => {this._lceeKeyReplacementOptions = options});
        this.lceeTagReplacementOptions.subscribe((options) => this._lceeTagReplacementOptions = options);
    }

    public buildReplacementOptions() {
        this.buildUserTagReplacementOptions(this.userTagType);
        this.buildPlacementTagReplacementOptions();
        this.buildListTagReplacementOptions();
        this.buildLceeKeyReplacementOptions();
        this.buildLceeTagReplacementOptions();
    }

    public get esp() {
        return this._esp;
    }

    public set esp(value) {
        if (!value) {
            this.clearSettings();
        }
        this.changeEsp(value);
    }

    public get userTagReplacement() {
        return this._userTagReplacement;
    }

    public set userTagReplacement(value) {
        this.changeUserTagReplacement(value);
    }

    public get userTagType() {
        return this._userTagType;
    }

    public set userTagType(value) {
        this.changeUserTagReplacement(null);
        this.changeUserTagType(value);
    }

    public get placementTagReplacement() {
        return this._placementTagReplacement;
    }

    public set placementTagReplacement(value) {
        this.changePlacementTagReplacement(value);
    }

    public get listTagReplacement() {
        return this._listTagReplacement;
    }

    public set listTagReplacement(value) {
        this.changeListTagReplacement(value);
    }

    public get lceeKeyReplacement() {
        return this._lceeKeyReplacement;
    }

    public set lceeKeyReplacement(value) {
        this.changeLceeKeyReplacement(value);
    }

    public get lceeTagReplacement() {
        return this._lceeTagReplacement;
    }

    public set lceeTagReplacement(value) {
        this.changeLceeTagReplacement(value);
    }

    public get includeListParam(): boolean {
        return this._includeListParam;
    }

    public set includeListParam(value: boolean) {
        this._includeListParam = value;
        if (!value) {
            this.changeListTagReplacement(null);
        } else {
            this.buildListTagReplacementOptions();
        }
    }

    loadEspSettings(data: T, force: boolean = false) {
        if (!data || !this._data) {
            return
        }

        if (data.emailTagType || force) {
            this.changeUserTagType(data.emailTagType);
        }

        if (data.emailTagReplacement || force) {
            this.changeUserTagReplacement(data.emailTagReplacement);
        }

        if (data.placementTagReplacement || force) {
            this.changePlacementTagReplacement(data.placementTagReplacement);
        }

        if (data.listTagReplacement || force) {
            this.changeListTagReplacement(data.listTagReplacement);

            this._includeListParam = !! data.listTagReplacement;
        }

        if (data.lceeKeyReplacement || force) {
            this.changeLceeKeyReplacement(data.lceeKeyReplacement);
            if (data.lceeKeyReplacement) {
                this.lceeKeySelectDropdown.writeValue(data.lceeKeyReplacement);
            }
        }

        if (data.lceeTagReplacement || force) {
            this.changeLceeTagReplacement(data.lceeTagReplacement);
        }

        if (data.esp || force) {
            this._esp = data.esp;
            this._data.esp = data.esp;

            if (data.esp === 'other') {
                // Internally the component gives the Other ESP an id value of 0
                this._esp = '0';
                this._data.esp = 'other';
            }

            this.changeEsp(this._esp);
        }
    }

    clearSettings() {
        this.resetAllowCustom();
        this.changeUserTagReplacement(null);
        this.changeUserTagType(null);
        this.changePlacementTagReplacement(null);
        this.changeListTagReplacement(null);
        this.changeLceeKeyReplacement(null);
        this.changeLceeTagReplacement(null);
        this._includeListParam = false;
        this.showAdvanced = false;
    }

    reset() {
        this.changeEsp(null);
        this.clearSettings();
    }

    resetAllowCustom() {
        this.userTagAllowCustom = false;
        this.listTagAllowCustom = false;
        this.placementTagAllowCustom = false;
        this.lceeKeyAllowCustom = false;
        this.lceeTagAllowCustom = false;
    }

    getPlaceholder(param: Tag): string {
        switch (param) {
            case Tag.USER:
                if (this.userTagType && EspProperties[EspUserMacroTagTypeMap[this.userTagType]]) {
                    return EspProperties[EspUserMacroTagTypeMap[this.userTagType]].placeholder;
                }
                return EspProperties.userEmail.placeholder;
            case Tag.PLACEMENT:
            case Tag.LIST:
            case Tag.LCEE:
                return EspProperties[param].placeholder;
            default:
                return '{INSERT_MACRO_HERE}';
        }
    }

    buildUserTagReplacementOptions(userTagType: string): void {
        const options = this.getUserMacros(userTagType);
        if (options.length > 0) {
            this.userTagAllowCustom = true;
            if (!this.userTagReplacement) {
                this.changeUserTagReplacement(options[0].value);
            }
        }
        this.userTagReplacementOptions.next(options);
    }

    buildPlacementTagReplacementOptions(): void {
        const options = this.getPlacementMacros();
        if (options.length > 0) {
            this.placementTagAllowCustom = true;
            if (!this.placementTagReplacement) {
                this.changePlacementTagReplacement(options[0].value);
            }
        }
        this.placementTagReplacementOptions.next(options);
    }

    buildListTagReplacementOptions(): void {
        const options = this.getListMacros();
        if (options.length > 0) {
            this.listTagAllowCustom = true;
            if (!this.listTagReplacement && this.includeListParam) {
                this.changeListTagReplacement(options[0].value);
            }
        }
        this.listTagReplacementOptions.next(options);
    }

    buildLceeKeyReplacementOptions(): void {
        const esp_options = this.getLceeKeyMacros();
        const default_options = this.options.lceeKey;

        var first_option = default_options[0].value;
        if (esp_options.length > 0) {
            first_option = esp_options[0].value;
            esp_options.forEach(option => {
                if (!default_options.find(o => o.value === option.value)) {
                    const newOption = {
                        key: default_options.length,
                        value: option.value,
                        label: option.label
                    }
                    default_options.push(newOption);
                }
            })
        }

        this.lceeKeyAllowCustom = true;
        this.lceeKeyReplacementOptions.next(default_options);

        if (!this.lceeKeyReplacement) {
            this.changeLceeKeyReplacement(first_option);
        }

        this.lceeKeySelectDropdown.writeValue(this.lceeKeyReplacement);
    }

    buildLceeTagReplacementOptions(): void {
        const options = this.getLceeTagMacros();
        if (options.length > 0) {
            this.lceeTagAllowCustom = true;
            if (!this.lceeTagReplacement) {
                this.changeLceeTagReplacement(options[0].value);
            }
        }
        this.lceeTagReplacementOptions.next(options);
    }

    /**
     * Load all supported Esp's for the dropdown.
     */
    loadEsps(): void {
        this.espSubscription = this.espRepository.all()
            .pipe(
                map(esps => {
                    esps.forEach((el) => this.allEsps[el.id] = el);
                    return esps.sort((a, b) => {
                        const aname = a.name.toLowerCase();
                        const bname = b.name.toLowerCase();

                        if (aname < bname) {
                            return -1;
                        } else if (aname > bname) {
                            return 1;
                        } else {
                            return parseInt(a.id) - parseInt(b.id);
                        }
                    })
                    .map(esp => ({
                        key: esp.id,
                        value: esp.id,
                        label: esp.name
                }))
            })
            ).subscribe(esps => {
                esps.push({ key: 'other', value: '0', label: 'Other ESP' });
                this.espOptions.next(esps);
            });
    }

    getUserMacros(key: string): Option[] {
        return this.getMacros(EspUserMacroTagTypeMap[key]);
    }

    getPlacementMacros(): Option[] {
        return this.getMacros('placement');
    }

    getListMacros(): Option[] {
        return this.getMacros('list');
    }

    getLceeKeyMacros(): Option[] {
        return this.getMacros('lceeKey');
    }

    getLceeTagMacros(): Option[] {
        return this.getMacros('lceeValue');
    }

    getMacros(key: string): Option[] {
        if (this.allEsps && this.allEsps[this.esp] && this.allEsps[this.esp][key] && this.allEsps[this.esp][key].length > 0) {
            const esp: ESP = this.allEsps[this.esp];
            const options = this.buildOptions(esp[key]);
            return options;
        }
        return [];
    }

    buildOptions(arr): Option[] {
        return arr.map((value, index) => ({
            key: index,
            value: value,
            label: value,
        }));
    }

    changeEsp(value) {
        if (value) {
            this._userTagType = this._data.emailTagType ? this._data.emailTagType : 'email';
            this.userTagType$.next(this._userTagType);
            this._data.emailTagType = this._userTagType;
        }
        this._esp = value;
        this.esp$.next(value);
        this._data.esp = this._esp;

        if (this._esp === '0') {
            this._data.esp = 'other';
        }

        this.espSettingsChangedEvent.emit(this._data);
    }

    changeLceeTagReplacement(value) {
        this._lceeTagReplacement = value ;
        this.lceeTagReplacement$.next(value);
        this._data.lceeTagReplacement = this._lceeTagReplacement;
        this.espSettingsChangedEvent.emit(this._data);
    }

    changeLceeKeyReplacement(value) {
        this._lceeKeyReplacement = value ;
        this.lceeKeyReplacement$.next(value);
        this._data.lceeKeyReplacement = this._lceeKeyReplacement;
        this.espSettingsChangedEvent.emit(this._data);
    }

    changeUserTagReplacement(value) {
        this._userTagReplacement = value ;
        this.userTagReplacement$.next(value);
        this._data.emailTagReplacement = this._userTagReplacement;
        this.espSettingsChangedEvent.emit(this._data);
    }

    changeUserTagType(value) {
        this._userTagType = value ;
        this.userTagType$.next(value);
        this._data.emailTagType = this._userTagType;
        this.espSettingsChangedEvent.emit(this._data);
    }

    changePlacementTagReplacement(value) {
        this._placementTagReplacement = value ;
        this.placementTagReplacement$.next(value);
        this._data.placementTagReplacement = this._placementTagReplacement;
        this.espSettingsChangedEvent.emit(this._data);
    }

    changeListTagReplacement(value) {
        this._listTagReplacement = value ;
        this.listTagReplacement$.next(value);
        this._data.listTagReplacement = this._listTagReplacement;
        this.espSettingsChangedEvent.emit(this._data);
    }

    useOtherEsp() {
        this.userTagType$.next('email');
        this.changeEsp('0');
    }

    getCustomValue(param: Tag): string {
        switch (param) {
            case Tag.USER:
                let userTagReplacementHasChanged = !this._userTagReplacementOptions.some(option => option.value === this._userTagReplacement)
                return this._userTagReplacement && userTagReplacementHasChanged ? this._userTagReplacement : null;
            case Tag.PLACEMENT:
                let placementTagReplacementHasChanged = !this._placementTagReplacementOptions.some(option => option.value === this._placementTagReplacement)
                return this._placementTagReplacement && placementTagReplacementHasChanged ? this._placementTagReplacement : null;
            case Tag.LIST:
                let listTagReplacementHasChanged = !this._listTagReplacementOptions.some(option => option.value === this._listTagReplacement)
                return this._listTagReplacement && listTagReplacementHasChanged ? this._listTagReplacement : null;
            case Tag.LCEE:
                let lceeTagReplacementHasChanged = !this._lceeTagReplacementOptions.some(option => option.value === this._lceeTagReplacement)
                return this._lceeTagReplacement && lceeTagReplacementHasChanged ? this._lceeTagReplacement : null;
            case Tag.LCEE_KEY:
                let lceeKeyReplacementHasChanged = !this._lceeKeyReplacementOptions.some(option => option.value === this._lceeKeyReplacement)
                return this._lceeKeyReplacement && lceeKeyReplacementHasChanged ? this._lceeKeyReplacement : null;
            default:
                return null;
        }
    }

    copyFromPublisher(): void {
        this.clearSettings();
        this.loadEspSettings(this._data.publisherObj, this.isBulkEdit);
    }

    isNewsletter(): boolean {
        return this.type === TYPE_NEWSLETTER;
    }

    isNew(): boolean {
        return !this._data.refId;
    }
}
