import { Model } from './model';

export class SasNewsletter extends Model {
    public publisher: string;
    public newsletter: string;
    public url: string;

    public serialize(): string {
        return JSON.stringify(this);
    }

    get entity() {
        return 'Sas Newsletter';
    }
}
