import { enableProdMode } from '@angular/core';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (window['_limavrun'] === true) {
    if (document.location.host.includes('liveintent.com')) {
        enableProdMode();
    }
    __NgCli_bootstrap_2.platformBrowser()
        .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory, { preserveWhitespaces: true })
        .catch(function (err) { return console.error(err); });
}
