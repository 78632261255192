import * as tslib_1 from "tslib";
import { Filter } from '../filter';
import { Operation } from '../operation';
var FilterString = /** @class */ (function (_super) {
    tslib_1.__extends(FilterString, _super);
    function FilterString(data) {
        if (data === void 0) { data = {}; }
        var _this = _super.call(this, data) || this;
        _this.operations = [
            Operation.Contains,
            Operation.DoesNotContain,
            Operation.Equals,
            Operation.DoesNotEqual
        ];
        if (_this.field === 'newsletter' && typeof _this.query === 'string') {
            _this.query = _this.query.slice(0, 48);
        }
        return _this;
    }
    return FilterString;
}(Filter));
export { FilterString };
