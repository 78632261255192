/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./overlay-loading-indicator.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./overlay-loading-indicator.component";
import * as i4 from "../../../core/id.service";
var styles_OverlayLoadingIndicatorComponent = [i0.styles];
var RenderType_OverlayLoadingIndicatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OverlayLoadingIndicatorComponent, data: { "animation": [{ type: 7, name: "overlayLoadingIndicatorAnimation", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 4, styles: null, timings: "200ms ease-in" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "200ms ease-out" }], options: null }], options: {} }] } });
export { RenderType_OverlayLoadingIndicatorComponent as RenderType_OverlayLoadingIndicatorComponent };
function View_OverlayLoadingIndicatorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_OverlayLoadingIndicatorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverlayLoadingIndicatorComponent_2)), i1.ɵdid(1, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.template; _ck(_v, 1, 0, currVal_0); }, null); }
function View_OverlayLoadingIndicatorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [["class", "copy--supporting"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading..."])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], null, null); }
export function View_OverlayLoadingIndicatorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "loading-indicator flex flex-column flex-align-center flex-justify-center fill-vertical"]], [[1, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "spinner-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "spinner--small"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "grayDot1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "grayDot2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverlayLoadingIndicatorComponent_1)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(0, [["default", 2]], null, 0, null, View_OverlayLoadingIndicatorComponent_3)), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.template; var currVal_2 = i1.ɵnov(_v, 15); _ck(_v, 13, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.id.container("overlay-loading-indicator"); _ck(_v, 0, 0, currVal_0); }); }
export function View_OverlayLoadingIndicatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "overlay-loading-indicator", [], [[40, "@overlayLoadingIndicatorAnimation", 0]], null, null, View_OverlayLoadingIndicatorComponent_0, RenderType_OverlayLoadingIndicatorComponent)), i1.ɵdid(1, 49152, null, 1, i3.OverlayLoadingIndicatorComponent, [i1.Renderer2, i1.ElementRef, i4.IdService], null, null), i1.ɵqud(335544320, 1, { template: 0 })], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).overlayLoadingIndicatorAnimation; _ck(_v, 0, 0, currVal_0); }); }
var OverlayLoadingIndicatorComponentNgFactory = i1.ɵccf("overlay-loading-indicator", i3.OverlayLoadingIndicatorComponent, View_OverlayLoadingIndicatorComponent_Host_0, { opacity: "opacity", layer: "layer" }, {}, []);
export { OverlayLoadingIndicatorComponentNgFactory as OverlayLoadingIndicatorComponentNgFactory };
