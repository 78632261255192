import { Model } from './model';
import { Url } from 'app/core/url';
import { KVP } from 'app/shared/components/kvp-input/KVP';
import { MediaGroup } from './media-group';
import { EspFieldOverride } from "./mixin/esp-field-override";

export enum PublisherClientTypes {
    Monetization = 'monetization',
    Hiro = 'hiro',
    Identity = 'identity',
    EmailIntelligence = 'email_intelligence'
}

export class Publisher extends Model implements EspFieldOverride {
    public admin: string;
    public keyValues: KVP[];
    public mediaGroup: string;
    public executive: string;
    public externalId: string;
    public tagsUrlPrefix: string;
    public targetedAdvertisers: string[];
    public targetedDomains: string[];
    public domainTargetingType: string;
    public blocklistCategories: number[];
    public refId: number;
    public accountId: number;
    public name: string;
    public category: string;
    public domain: string;
    public gdprCompliance: string;
    public inheritConsentControls: boolean;
    public consentControls: any[];
    public blocklists: number[];
    public salesforceId: string;
    public publisher: string;
    public clientTypes: string[];

    public sspControl: {
        exchangeAllow: boolean,
        exchangeFloor: number,
        rtbFloor: number,
        rtbAllow: boolean
    };

    public sensitiveCategories: number[];

    // ESPFieldOverride
    public esp: number | string;
    public espName: string;
    public emailTagReplacement: string;
    public emailTagType: string;
    public placementTagReplacement: string;
    public listTagReplacement: string;
    public lceeKeyReplacement: string;
    public lceeTagReplacement: string;


    mediaGroupObj?: MediaGroup;

    public constructor(publisher: any = {}) {
        super();

        Object.assign(this, publisher, {
            esp: publisher.esp !== null ? publisher.esp : 'other',
            targetedDomains: publisher.targetedDomains || [],
            blocklistCategories: publisher.blocklistCategories || [],
            sensitiveCategories: publisher.sensitiveCategories || [],
            blocklists: publisher.blocklists || [],
            keyValues: publisher.keyValues ? this.transformIncomingKvps(publisher.keyValues) : [],
            sspControl: publisher.sspControl || {}
        });
    }

    /**
     * Transform JSON kvp's into an array of KVP objects.
     * The frontend always limits one value per key,
     * so we can just pluck the first value.
     */
    private transformIncomingKvps(json): KVP[] {
        if (Array.isArray(json)) {
            return json;
        }

        return Object.keys(json).map(key => new KVP(key, json[key][0]));
    }

    /**
     * Get the protocol from the tags URL.
     */
    public getTagsProtocol(): string {
        return new Url(this.tagsUrlPrefix).protocol;
    }

    /**
     * Get the CNAME from the tags URL.
     */
    public getCname(): string {
        return new Url(this.tagsUrlPrefix).hostname;
    }

    public serialize(): string {
        const publisher = JSON.parse(JSON.stringify(this));
        delete publisher.mediaGroupObj;

        publisher.keyValues = publisher.keyValues.reduce((json, kvp) => {
            // the api accepts an array of values, but on the
            // frontend we always limit one value per key
            json[kvp.key] = [kvp.value];
            return json;
        }, {});

        if (publisher.esp === 'other') {
            publisher.esp = null;
        }

        if (this.isEmptyArray(publisher.targetedDomains)) {
            publisher.targetedDomains = null;
            delete publisher.domainTargetingType;
        }

        if (publisher.externalId === '') {
            publisher.externalId = null;
        }

        if (!publisher.salesforceId) {
            publisher.salesforceId = null;
        }

        if (publisher.emailTagReplacement === '') {
            publisher.emailTagReplacement = null;
        }
        if (publisher.placementTagReplacement === '') {
            publisher.placementTagReplacement = null;
        }

        if (publisher.listTagReplacement === '') {
            publisher.listTagReplacement = null;
        }

        if (publisher.placementTagReplacement === '') {
            publisher.placementTagReplacement = null;
        }

        if (publisher.lceeKeyReplacement === '') {
            publisher.lceeKeyReplacement = null;
        }

        if (publisher.lceeTagReplacement === '') {
            publisher.lceeTagReplacement = null;
        }

        this.setDefaultsForLSD(publisher);

        return publisher;
    }

    private setDefaultsForLSD(publisher): void {
        // LSD requires a description but doesn't use it.
        publisher.description = 'Default description, courtesy of Maverick.';

    }

    private isEmptyArray(prop) {
        return Array.isArray(prop) && prop.length === 0;
    }

    get entity(): string {
        return 'Publisher';
    }
}
