import { TemplateRef, OnInit } from '@angular/core';
import { of, forkJoin, combineLatest } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DeletePublisherDialogComponent } from 'app/shared/components/delete-publisher-dialog';
import { DownloadHelper } from 'app/shared/helpers/download-helper';
import { HistoryViewComponent } from 'app/shared/components/history-view';
import { IdService } from 'app/core/id.service';
import { InventoryDataService } from 'app/shared/services/inventory-data.service';
import { LiveConnectTagRepository } from 'app/core/repositories';
import { AuthorizationService, NotificationsService } from 'app/core';
import { PaginatorComponent } from 'app/shared/elements/paginator';
import { PublisherBulkEditComponent } from 'app/shared/components/publisher-bulk-edit';
import { PublisherRepository } from 'app/core/repositories/publisher-repository.service';
import { TableComponent } from 'app/shared/elements/table';
import { TableHelper } from 'app/shared/helpers/table-helper';
var PublisherAction;
(function (PublisherAction) {
    PublisherAction[PublisherAction["Delete"] = 0] = "Delete";
    PublisherAction[PublisherAction["DownloadLiveConnect"] = 1] = "DownloadLiveConnect";
    PublisherAction[PublisherAction["Edit"] = 2] = "Edit";
    PublisherAction[PublisherAction["EditAdvertiserDomains"] = 3] = "EditAdvertiserDomains";
    PublisherAction[PublisherAction["EditCategory"] = 4] = "EditCategory";
    PublisherAction[PublisherAction["EditCategoryBlacklist"] = 5] = "EditCategoryBlacklist";
    PublisherAction[PublisherAction["EditSensitveCategoryBlocklist"] = 6] = "EditSensitveCategoryBlocklist";
    PublisherAction[PublisherAction["EditName"] = 7] = "EditName";
    PublisherAction[PublisherAction["ViewHistory"] = 8] = "ViewHistory";
    PublisherAction[PublisherAction["EditEsp"] = 9] = "EditEsp";
})(PublisherAction || (PublisherAction = {}));
var PublishersTableComponent = /** @class */ (function () {
    function PublishersTableComponent(notifications, publisherRepository, liveConnectTagRepository, inventoryData, id, authorizationService) {
        var _this = this;
        this.notifications = notifications;
        this.publisherRepository = publisherRepository;
        this.liveConnectTagRepository = liveConnectTagRepository;
        this.inventoryData = inventoryData;
        this.id = id;
        this.authorizationService = authorizationService;
        this.helper = new TableHelper(function (params) { return _this.adapter(params); });
        this.PublisherAction = PublisherAction;
    }
    Object.defineProperty(PublishersTableComponent.prototype, "setMediaGroup", {
        set: function (id) {
            this.mediaGroupId = id;
            this.paginator.reset();
        },
        enumerable: true,
        configurable: true
    });
    PublishersTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.helper.table = this.table;
        this.helper.paginator = this.paginator;
        combineLatest(this.table.query, this.paginator.query).subscribe(function (args) { return _this.helper.search(args); });
    };
    PublishersTableComponent.prototype.adapter = function (params) {
        if (typeof this.mediaGroupId === 'string') {
            params.conditions.push({ field: 'mediaGroup', value: this.mediaGroupId });
        }
        return this.publisherRepository.asyncSearch(params);
    };
    PublishersTableComponent.prototype.refresh = function () {
        this.paginator.reset();
        this.table.clearSelections();
    };
    PublishersTableComponent.prototype.handle = function (action, publishers) {
        switch (action) {
            case PublisherAction.Edit:
                this.edit(publishers);
                break;
            case PublisherAction.EditName:
                this.bulkEditor.open(publishers, 'name');
                break;
            case PublisherAction.EditCategory:
                this.bulkEditor.open(publishers, 'category');
                break;
            case PublisherAction.EditEsp:
                this.bulkEditor.open(publishers, 'esp');
                break;
            case PublisherAction.EditCategoryBlacklist:
                this.bulkEditor.open(publishers, 'blocklistCategories');
                break;
            case PublisherAction.EditSensitveCategoryBlocklist:
                this.bulkEditor.open(publishers, 'sensitiveCategories');
                break;
            case PublisherAction.EditAdvertiserDomains:
                this.bulkEditor.open(publishers, 'targetedDomains');
                break;
            case PublisherAction.ViewHistory:
                this.viewHistory(publishers);
                break;
            case PublisherAction.DownloadLiveConnect:
                this.downloadLiveConnectTags(publishers);
                break;
            case PublisherAction.Delete:
                this.deletePublishers(publishers);
                break;
        }
    };
    PublishersTableComponent.prototype.canModify = function (publishers) {
        var _this = this;
        return publishers.every(function (publisher) { return _this.authorizationService.canModifyMonetizationPublisher(publisher.clientTypes); });
    };
    PublishersTableComponent.prototype.edit = function (publishers) {
        this.bulkEditor.open(publishers);
    };
    PublishersTableComponent.prototype.viewHistory = function (publishers) {
        if (publishers.length > 1) {
            this.notifications.error('Please select a single publisher to view history for at a time.');
            return;
        }
        this.historyView.viewPublisherHistory(publishers[0]);
    };
    PublishersTableComponent.prototype.deletePublishers = function (publishers) {
        if (publishers.length > 1) {
            this.notifications.error('Please select a single publisher to delete at a time.');
            return;
        }
        this.deletePublisherDialog.open(publishers[0]);
    };
    PublishersTableComponent.prototype.downloadLiveConnectTags = function (publishers) {
        var _this = this;
        forkJoin(publishers.map(function (publisher) {
            return _this.liveConnectTagRepository.getOrCreateByAdvertiserIdAndAccountId(publisher.refId, publisher.accountId);
        })).pipe(catchError(function () {
            _this.notifications.error('There was an error downloading the LiveConnect tags.', 'Oh no!');
            return of([]);
        })).subscribe(function (tags) {
            if (tags) {
                var csv = tags.map(function (tag) {
                    return {
                        'Publisher ID': tag.publisherId,
                        'Publisher Name': tag.name,
                        'LiveConnect Tag': tag.liveConnectTagJs
                    };
                });
                DownloadHelper.downloadAsCSV(csv, 'liveconnect-tags');
            }
        });
    };
    return PublishersTableComponent;
}());
export { PublishersTableComponent };
