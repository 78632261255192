import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HistoryItem, HistoryResponse } from 'app/shared/models';
import { Observable, ReplaySubject, Subscription, BehaviorSubject, Observer, combineLatest, of } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { NotificationsService } from 'app/core';
import { Formatter } from 'app/shared/components/history-view/formatter';

import {
    AdSlotRepository,
    CampaignRepository,
    HistoryEntity,
    LIESRepository,
    LineItemRepository,
    NewsletterRepository
} from 'app/core/repositories';

@Component({
    templateUrl: './history.html',
    styleUrls: ['./history.styl'],
    providers: [Formatter]
})
export class HistoryComponent implements OnInit {

    entityOptions = [
        {
            key: 0,
            value: HistoryEntity.Campaign,
            label: 'Campaign ID'
        },
        {
            key: 1,
            value: HistoryEntity.LineItem,
            label: 'Line Item ID'
        },
        {
            key: 2,
            value: HistoryEntity.Newsletter,
            label: 'Newsletter ID'
        },
        {
            key: 3,
            value: HistoryEntity.AdSlot,
            label: 'Ad Slot ID'
        }
    ];

    activeSearchEntity = HistoryEntity.Campaign;

    routeUpdate: Subscription;
    loading$ = new BehaviorSubject(false);
    history$ = new ReplaySubject<HistoryItem[]>(1);

    constructor(
        private router: Router,
        private notifications: NotificationsService,
        private liesRepository: LIESRepository,
        private campaignRepository: CampaignRepository,
        private lineItemRepository: LineItemRepository,
        private newsletterRepository: NewsletterRepository,
        private adSlotRepository: AdSlotRepository,
        public route: ActivatedRoute,
        public formatter: Formatter
    ) { }

    ngOnInit() {
        this.routeUpdate = combineLatest(this.route.paramMap, this.route.queryParamMap).pipe(
            switchMap(([params, query]) => {
                if (!(params.has('type') && params.has('id'))) {
                    return of(null);
                }

                this.activeSearchEntity = params.get('type') as HistoryEntity;
                this.loading$.next(true);

                const page = parseInt(query.get('page')) || 1;
                const modified = query.get('modified') || undefined;

                return new Observable((observer: Observer<HistoryResponse>) => {
                    switch (this.activeSearchEntity) {
                        case HistoryEntity.Campaign:
                            this.campaignRepository.get(params.get('id')).pipe(
                                mergeMap(campaign => this.liesRepository.history(campaign.id, this.activeSearchEntity, page, modified))
                            ).subscribe(observer);
                            break;
                        case HistoryEntity.LineItem:
                            this.lineItemRepository.get(params.get('id')).pipe(
                                mergeMap(lineItem => this.liesRepository.history(lineItem.id, this.activeSearchEntity, page, modified))
                            ).subscribe(observer);
                            break;
                        case HistoryEntity.Newsletter:
                            this.newsletterRepository.get(params.get('id')).pipe(
                                mergeMap(newsletter => this.liesRepository.history(newsletter.id, this.activeSearchEntity, page, modified))
                            ).subscribe(observer);
                            break;
                        case HistoryEntity.AdSlot:
                            this.adSlotRepository.get(params.get('id')).pipe(
                                mergeMap(adSlot => this.liesRepository.history(adSlot.id, this.activeSearchEntity, page, modified))
                            ).subscribe(observer);
                            break;
                        default:
                            observer.error(new Error('unsupported entity type'));
                            observer.complete();
                    }
                }).pipe(
                    catchError(() => {
                        this.notifications.error('An error occurred when searching for history.');
                        return of(null);
                    })
                );
            })
        ).subscribe((response: HistoryResponse) => {
            this.loading$.next(false);
            this.history$.next(response ? response.items : null);
        });
    }

    formattedChanges(item: HistoryItem) {
        return this.formatter.formatChanges(item);
    }

    ngOnDestroy() {
        this.routeUpdate.unsubscribe();
    }

    search(data: { searchEntity: HistoryEntity, refId: number }) {
        this.router.navigate(['/history', { type: data.searchEntity, id: data.refId }]);
    }

    returnToSearch() {
        this.router.navigate(['/history']);
    }

    next() {
        let page = parseInt(this.route.snapshot.queryParams.page) || 1;

        if (isNaN(page)) {
            page = 1;
        }

        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: {
                page: page + 1
            },
            queryParamsHandling: 'merge'
        });
    }

    previous() {
        let page = parseInt(this.route.snapshot.queryParams.page) || 1;

        if (isNaN(page)) {
            page = 1;
        }

        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: {
                page: Math.max(page - 1, 1)
            },
            queryParamsHandling: 'merge'
        });
    }

    setModifiedSince(modified) {
        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: {
                modified
            },
            queryParamsHandling: 'merge'
        });
    }

    clearModifiedSince() {
        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: {
                modified: null
            },
            queryParamsHandling: 'merge'
        });
    }

}
