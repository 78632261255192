import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { throwError, forkJoin, of } from 'rxjs';
import { Creative } from 'app/shared/models/creative';
import { BackendRepository } from './backend-repository';
import { map, mergeMap } from 'rxjs/operators';
var CreativeRepository = /** @class */ (function (_super) {
    tslib_1.__extends(CreativeRepository, _super);
    function CreativeRepository(http) {
        return _super.call(this, http, '/creative', Creative) || this;
    }
    CreativeRepository.prototype.setSearchReturnFields = function (params) {
        this._searchReturnFields = params;
    };
    CreativeRepository.prototype.asyncLineItemSearch = function (query) {
        var _this = this;
        return this.rawLineItemSearch(query)
            .pipe(map(function (data) { return ({
            items: data['output'].map(function (item) { return _this.build(item); }),
            page: 1,
            pages: data['pages'],
            total: data['total']
        }); }));
    };
    CreativeRepository.prototype.asyncCreativeSearch = function (query) {
        var _this = this;
        return this.http.post(this.url('/mc/creatives', 'search'), query)
            .pipe(map(function (data) { return ({
            items: data['data'].map(function (item) { return _this.build(item); }),
            page: 1,
            total: data['meta']['total']
        }); }));
    };
    CreativeRepository.prototype.confirm = function (creative) {
        var params = [this.path, 'confirm'];
        if (creative.id) {
            params.push(creative.id);
        }
        return this.http.post(this.url.apply(this, params), creative.serialize());
    };
    CreativeRepository.prototype.lineItemSearch = function (query) {
        var _this = this;
        return this.http.post(this.url('/search' + this.path, 'strategy'), query)
            .pipe(map(function (response) { return response['output']
            .map(function (data) { return _this.build(data); }); }));
    };
    CreativeRepository.prototype.searchNativeCreatives = function (query) {
        var _this = this;
        return this.http.post(this.url('/search' + this.path, 'native'), query)
            .pipe(map(function (response) { return response['output']
            .map(function (data) { return _this.build(data); }); }));
    };
    // Takes in 3 different creative searches and combines them all
    // - display creatives
    // - native creatives (native endpoint where blueprints attached to creatives)
    // - native creative trafficking creatives (native endpoint where there are no blueprints attached to the creatives)
    CreativeRepository.prototype.searchAllCreatives = function (params, nativeParams, nativeCreativeTraffickingParams) {
        var _this = this;
        return this.consume(function (page) {
            // Clone both params and try to send
            // requests as few as possible
            var q = JSON.parse(JSON.stringify(params));
            q.page = page;
            q.n = 500;
            var nq = JSON.parse(JSON.stringify(nativeParams));
            nq.page = page;
            nq.n = 500;
            var nctq = JSON.parse(JSON.stringify(nativeCreativeTraffickingParams));
            nctq.page = page;
            nctq.n = 500;
            return forkJoin(_this.asyncSearch(q), _this.asyncSearch(nq, '/search/creative/native'), _this.asyncSearch(nctq, '/search/creative/native')).pipe(map(function (_a) {
                var nonNative = _a[0], native = _a[1], nativeCreativeTrafficking = _a[2];
                return {
                    output: [].concat.apply([], nonNative['items'].concat(native['items'], nativeCreativeTrafficking['items'])),
                    page: params.page,
                    pages: nonNative['pages'] + native['pages'] + nativeCreativeTrafficking['pages'],
                    total: nonNative['total'] + native['total'] + nativeCreativeTrafficking['total']
                };
            }));
        });
    };
    CreativeRepository.prototype.rawLineItemSearch = function (query) {
        return this.http.post(this.url('/search' + this.path, 'strategy'), query);
    };
    CreativeRepository.prototype.searchByLineItem = function (id) {
        return this.lineItemSearch({
            conditions: [
                {
                    field: 'strategyId',
                    value: id
                }
            ]
        });
    };
    CreativeRepository.prototype.searchByLineItemAll = function (id) {
        var _this = this;
        if (typeof id !== 'string' && !Array.isArray(id)) {
            return throwError(new Error('Invalid parameter. Parameter must be string or array of strings.'));
        }
        var query = {
            conditions: [
                {
                    field: 'strategyId',
                    value: id
                }
            ]
        };
        return this.consume(function (page) {
            var q = JSON.parse(JSON.stringify(query));
            q.page = page;
            return _this.rawLineItemSearch(q);
        });
    };
    CreativeRepository.prototype.searchExchangeCreativeByStrategy = function (query) {
        var _this = this;
        return this.http.post(this.url('/search/exchange-creative/strategy'), query)
            .pipe(map(function (response) { return response['output']
            .map(function (data) { return _this.build(data); }); }));
    };
    CreativeRepository.prototype.approve = function (id, key, version) {
        var _this = this;
        return this.http.post(this.url(this.path, 'approve', id, key), { version: version })
            .pipe(map(function (response) { return _this.build(response['output']); }));
    };
    CreativeRepository.prototype.reject = function (id, key, version) {
        var _this = this;
        return this.http.post(this.url(this.path, 'reject', id, key), { version: version })
            .pipe(map(function (response) { return _this.build(response['output']); }));
    };
    CreativeRepository.prototype.clone = function (creative) {
        creative.name += ' - COPY';
        delete creative.id;
        delete creative.refId;
        return creative;
    };
    CreativeRepository.prototype.eSearch = function (params) {
        var _this = this;
        return this.asyncSearch(params, '/lies/search/creatives').pipe(mergeMap(function (creatives) {
            if (!creatives.items || creatives.items.length === 0) {
                return forkJoin([of(creatives), of({ items: [] })]);
            }
            return forkJoin([of(creatives), _this.asyncCreativeSearch({
                    filters: [{
                            field: 'id',
                            value: creatives.items.map(function (creative) { return String('li-' + creative.refId); }),
                            type: 'or',
                            operator: 'in'
                        }]
                })]);
        }), map(function (_a) {
            var creatives = _a[0], attributes = _a[1];
            creatives.items.map(function (creative) {
                if (attributes.items.find(function (label) { return String(label.creative_external_id) === String(creative.refId); })) {
                    creative.categoryLabel = attributes.items
                        .find(function (label) { return String(label.creative_external_id) === String(creative.refId); })
                        .attributes;
                    var result = creative.categoryLabel.map(function (label) { return label.name; });
                    creative.categoryLabel = result.join('; ');
                }
                else {
                    creative.categoryLabel = '—';
                }
            });
            return creatives;
        }));
    };
    return CreativeRepository;
}(BackendRepository));
export { CreativeRepository };
