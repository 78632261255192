import { Filter } from '../filter';
import { Operation } from '../operation';

export class FilterNumber extends Filter {
    readonly operations: Operation[] = [
        Operation.GreaterThan,
        Operation.LessThan,
        Operation.Equals,
        Operation.DoesNotEqual
    ];

    isValid() {
        return super.isValid() && !isNaN(Number(this.query));
    }
}
