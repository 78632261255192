import { AfterViewInit, EventEmitter, OnInit, Injector, ChangeDetectorRef } from '@angular/core';
import { ValidationService } from 'app/core/validation-service';
import { SearchComponent } from 'app/shared/elements/search';
import { DownloadHelper } from 'app/shared/helpers/download-helper';
import { YieldManagement } from 'app/shared/services/yield-management.service';
import { debounceTime, distinctUntilChanged, switchMap, retry } from 'rxjs/operators';
import { IdService } from 'app/core/id.service';
var DomainTargetingComponent = /** @class */ (function () {
    function DomainTargetingComponent(injector, cdr, id) {
        this.injector = injector;
        this.cdr = cdr;
        this.id = id;
        this.targetingType = 'exclude';
        this.excludeOnly = false;
        this.fileName = 'targeted_domains';
        this.showInheritType = false;
        this.allowDownload = true;
        this.disabled = false;
        this.disableTypeSelection = false;
        this.selectedChange = new EventEmitter();
        this.targetingTypeChange = new EventEmitter();
        this.selected = [];
        this.targetingTypes = [
            { key: 0, value: 'exclude', label: 'Block List' },
        ];
        this._searchResults = [];
        this.isLoading = false;
        this.isSearchable = false;
        this.invalidText = 'The domain URLs above were not added because they are invalid.';
        this.placeholder = 'Enter advertiser domains one per each line or separated by commas.\n\n' +
            'ie.\n' +
            'advertiser.com\n' +
            'anotherone.com\n' +
            'athird.com';
    }
    Object.defineProperty(DomainTargetingComponent.prototype, "domains", {
        get: function () {
            return this.selected;
        },
        set: function (val) {
            if (!Array.isArray(val)) {
                this.selected = [];
            }
            else {
                this.selected = val;
            }
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Perform set up after the `Input` properties have been resolved.
     */
    DomainTargetingComponent.prototype.ngOnInit = function () {
        this.isSearchable = typeof this.searchFn === 'function';
        // HOTFOX --- REMOVE WHEN READY
        if (this.excludeOnly === false) {
            this.targetingTypes.push({ key: 1, value: 'include', label: 'Allow List' });
        }
        if (this.showInheritType) {
            this.targetingTypes.push({ key: 2, value: 'inherit', label: 'Inherit' });
        }
        try {
            this.yieldManagement = this.injector.get(YieldManagement);
        }
        catch (err) {
            this.yieldManagement = null;
        }
    };
    /**
     * Register subscribers after the component's child views have been created.
     */
    DomainTargetingComponent.prototype.ngAfterViewInit = function () {
        if (this.isSearchable) {
            this.registerSearch();
        }
    };
    /**
     * Register and set up the search.
     */
    DomainTargetingComponent.prototype.registerSearch = function () {
        var _this = this;
        this.searchInput.inputChange.pipe(debounceTime(300), distinctUntilChanged(), switchMap(this.search.bind(this)), retry()).subscribe(function (results) {
            _this.isLoading = false;
            var domains = Array.from(new Set(results.map(function (result) { return result.domain; })));
            _this.searchResults = domains.map(function (domain) { return _this.mapToOption(domain); });
            _this.cdr.detectChanges();
        });
    };
    /**
     * Proxy the search function so we can enable the loading spinner.
     * @param  {string}            searchTerm
     * @return {Promise<string[]>}
     */
    DomainTargetingComponent.prototype.search = function (searchTerm) {
        this.isLoading = true;
        return this.searchFn(searchTerm);
    };
    Object.defineProperty(DomainTargetingComponent.prototype, "searchResults", {
        /**
         * Get the list of search results, excluding currently selected items.
         * @return {Option[]}
         */
        get: function () {
            var _this = this;
            return this._searchResults.filter(function (result) {
                return !_this.selected.some(function (selectedItem) { return result.value === selectedItem; });
            });
        },
        /**
         * Set the list of search results.
         * @param {Option[]} values
         */
        set: function (values) {
            this._searchResults = values;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Map an array of strings to an array of Options.
     * @param  {string[]} items
     * @return {Option}
     */
    DomainTargetingComponent.prototype.mapToOption = function (item) {
        return { key: item, value: item, label: item };
    };
    /**
     * Add an option's value to the list.
     * @param {Option} option
     */
    DomainTargetingComponent.prototype.selectOption = function (option) {
        this.update(this.selected.concat(option.value));
    };
    /**
     * Check if a string resembles a valid URL.
     * @param  {string}  domain
     * @return {boolean}
     */
    DomainTargetingComponent.prototype.isDomainValid = function (domain) {
        return ValidationService.isValidDomain(domain);
    };
    /**
     * Prevent clicking on the domain search results
     * from closing the results so a user can
     * select as many values as needed.
     * @param {Event} $event
     */
    DomainTargetingComponent.prototype.keepResultsOpen = function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.searchInput.trigger.nativeElement.focus();
    };
    /**
     * Parse raw text into an array of lowercase domains.
     * @param  {string}  rawList
     * @return {KVP[]}
     */
    DomainTargetingComponent.prototype.parse = function (rawList) {
        return rawList
            .replace(/\n/g, ',')
            .split(',')
            .map(function (token) { return token.trim(); })
            .filter(function (token) { return token !== ''; })
            .map(function (domain) { return domain.toLowerCase(); });
    };
    /**
     * Clear out the componet. This is needed where a form
     * is to be reused rather than freshly instantiated.
     */
    DomainTargetingComponent.prototype.reset = function () {
        this.inputText = null;
        this.clear();
    };
    DomainTargetingComponent.prototype.clear = function () {
        this.update([]);
    };
    /**
     * Update the list and propagate the changes.
     */
    DomainTargetingComponent.prototype.update = function (values) {
        this.selected = values.slice();
        this.selectedChange.emit(this.selected);
    };
    DomainTargetingComponent.prototype.download = function () {
        var _this = this;
        var targetingType = this.targetingTypes.find(function (current) { return _this.targetingType === current.value; }).label;
        var data = this.selected.map(function (domain) { return ({
            'targeting type': targetingType,
            domain: domain
        }); });
        DownloadHelper.downloadAsCSV(data, this.fileName);
    };
    Object.defineProperty(DomainTargetingComponent.prototype, "hasRefId", {
        get: function () {
            return typeof this.refId === 'number';
        },
        enumerable: true,
        configurable: true
    });
    DomainTargetingComponent.prototype.isDomainTargetingTypeInherit = function () {
        return this.targetingType && this.targetingType === 'inherit';
    };
    return DomainTargetingComponent;
}());
export { DomainTargetingComponent };
