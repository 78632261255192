import { ErrorHelper } from './error-helper';
import { HttpErrorResponse } from '@angular/common/http';

type Error = {
    id: string,
    code: string,
    details: string
};

export class ErrorMessageBuilder {
    static unknownMessage = ' an unexpected error has occurred.';

    static build(prefix: string, response: any): string {
        let errors: Error[];

        if (response instanceof HttpErrorResponse && response.error) {
            errors = response.error.errors;
        } else {
            return prefix + ErrorMessageBuilder.unknownMessage;
        }

        const helper = new ErrorHelper();
        helper.loadBackEndErrors(errors);

        return prefix + ErrorMessageBuilder.buildBody(helper.errors);
    }

    private static buildBody(messages: string[]): string {
        return messages.length === 1
            ? ` because ${messages[0].charAt(0).toLowerCase() + messages[0].slice(1)}`
            : ` for the following reasons: <ul>` + messages.map(message => `<li>${message}</li>`).join(``) + `</ul>`;
    }
}
