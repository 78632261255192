import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocationHelperService, IdService } from 'app/core';
import { DownloadHelper } from 'app/shared/helpers/download-helper';

@Component({
    selector: 'zip-targeting',
    templateUrl: './zip-targeting.html',
    styleUrls: ['./zip-targeting.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ZipTargetingComponent),
        multi: true
    }]
})
export class ZipTargetingComponent implements OnInit, ControlValueAccessor {
    data = '';
    countryOptions = [];
    country = null;
    items = [];

    targetingOptions = [
        { key: 0, value: 'include', label: 'Target' },
        { key: 1, value: 'exclude', label: 'Exclude' }
    ];

    @Input() fileName: string = 'targeted_zip_codes';
    @Input('targeting') _targeting = 'include';
    @Output('targetingChange') _targetingChange = new EventEmitter();

    private locationPromise: Promise<any> = null;
    private _usonly: boolean;
    private onchange = null;

    constructor(
        private cdr: ChangeDetectorRef,
        private locationHelper: LocationHelperService,
        public id: IdService
    ) { }

    ngOnInit() {
        this.locationPromise = this.locationHelper.promise.then(() => this.prepareCountries());
    }

    writeValue(obj: any[]) {
        if (Array.isArray(obj)) {
            this.update(obj);
        }
    }

    registerOnChange(fn: any) {
        this.onchange = fn;
    }

    registerOnTouched(fn: any) { }

    update(obj) {
        this.locationPromise.then(() => {
            this.items = obj.reduce((items, data) => {
                const country = this.countryOptions.find(country => country.value.toString() === data.countryId);
                return items.concat(data.postalCodes.map(pc => ({
                    countryId: data.countryId,
                    country: country.label,
                    key: pc,
                    value: 'Postal Code',
                    label: `${pc.toString()}, ${country.label}`
                })));
            }, []);
        });
    }

    set targeting(value: string) {
        this._targeting = value;
        this._targetingChange.emit(this._targeting);
    }

    get targeting() {
        return this._targeting;
    }

    private emitChanges() {
        if (this.onchange !== null) {
            const buckets = this.items.reduce((buckets, item) => {
                if (!Array.isArray(buckets[item.countryId])) {
                    buckets[item.countryId] = [];
                }

                buckets[item.countryId].push(item.key);

                return buckets;
            }, {});

            const selected = Object.keys(buckets).map(countryId => ({
                countryId, postalCodes: buckets[countryId]
            }));

            this.onchange(selected);
        }
    }

    addPostalCodes(data: string) {
        if (typeof data !== 'string') {
            return;
        }

        let pcs = data.replace(/\n/g, ',').split(',').map(str => str.trim()).filter(str => str.length > 0);

        if (pcs.length < 1) {
            return;
        }

        const country = this.countryOptions.find(country => country.value === this.country);

        // Filter list so that it is unique.
        pcs = pcs.filter((pc, i, arr) => arr.indexOf(pc) === i);

        // Filter list so that it does not contain any postal codes already added.
        pcs = pcs.filter(pc =>
            this.items.findIndex(item => item.key === pc && this.country.toString() === item.countryId) < 0
        );

        const items = this.items.slice();

        pcs.forEach(pc => {
            items.push({
                countryId: this.country.toString(),
                country: country.label,
                key: pc,
                value: 'Postal Code',
                label: `${pc.toString()}, ${country.label}`
            });
        });

        this.items = items;

        this.emitChanges();

        // Reset.
        this.data = '';
    }

    @Input()
    set usonly(value: boolean) {
        this._usonly = value;
        if (this._usonly) {
            this.country = 235;
        } else {
            this.country = null;
        }
    }

    get usonly() {
        return this._usonly;
    }

    get placeholder(): string {
        return this.usonly
            ? 'Enter U.S. postal codes in the following formats: 12345 and 12345-6789.'
            : 'Select a country and then add postal codes you want to target.';
    }

    private prepareCountries() {
        this.countryOptions = this.locationHelper.database
            .filter(location => location.type === 'country')
            .map(location => ({
                key: location.id,
                value: location.id,
                label: location.display
            }));
        this.cdr.markForCheck();
    }

    itemsUpdate(items) {
        this.items = items;
        this.emitChanges();
    }

    get hasItems() {
        return this.items.length > 0;
    }

    clearItems() {
        this.items = [];
        this.emitChanges();
    }

    download() {
        const targetingType = this.targetingOptions.find(current => this.targeting === current.value).label;
        const data = this.items.map(zip => ({
            'targeting type': targetingType,
            'geo type': 'Postal Code',
            'postal code': zip.key,
            country: zip.country
        }));

        DownloadHelper.downloadAsCSV(data, this.fileName);
    }
}
