import * as moment from 'moment';
import stat from './line-item-statuses.json';
var STATUS_SORT_ORDER = [
    stat.DELIVERING,
    stat.NOT_DELIVERING,
    stat.PAUSED,
    stat.READY,
    stat.NOT_READY,
    stat.INACTIVE,
    stat.COMPLETE
];
var byStartDate = function (a, b) {
    if (moment(a.effectiveStartDate).isAfter(b.effectiveStartDate)) {
        return -1;
    }
    if (moment(a.effectiveStartDate).isBefore(b.effectiveStartDate)) {
        return 1;
    }
    return 0;
};
var ɵ0 = byStartDate;
var LineItemsSortService = /** @class */ (function () {
    function LineItemsSortService() {
    }
    Object.defineProperty(LineItemsSortService.prototype, "lineItems", {
        get: function () {
            return this._lineItems || [];
        },
        set: function (val) {
            this._lineItems = val;
        },
        enumerable: true,
        configurable: true
    });
    LineItemsSortService.prototype.push = function (lineItems) {
        this.lineItems = this.lineItems.concat(lineItems);
        return this;
    };
    LineItemsSortService.prototype.pull = function () {
        var toReturn = this.lineItems.slice(0); // This creates clone of lineItems collection
        this.lineItems = null;
        return toReturn;
    };
    LineItemsSortService.prototype.excludeStatuses = function (exclude) {
        if (exclude === void 0) { exclude = []; }
        this.lineItems = this.lineItems.filter(function (lItem) {
            return exclude.indexOf(lItem['platformStatus']) === -1;
        });
        return this;
    };
    LineItemsSortService.prototype.sortByStatusAndDate = function () {
        var _this = this;
        var sorted = [];
        STATUS_SORT_ORDER.forEach(function (status) {
            var sortedSubset = _this.lineItems
                .filter(function (lItem) {
                return lItem['platformStatus'].toLowerCase() === status;
            })
                .sort(byStartDate);
            sorted = sorted.concat(sortedSubset);
        });
        this.lineItems = sorted.map(function (lItem, index) {
            lItem.index = index;
            return lItem;
        });
        return this;
    };
    return LineItemsSortService;
}());
export { LineItemsSortService };
export { ɵ0 };
