import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BackendRepository, PaginatedResponse } from './backend-repository';
import { Model } from 'app/shared/models';
import { ESParams } from 'app/shared/helpers/es-query-builder';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SnakeToCamelCasePipe } from 'app/shared/elements/pipes';
import { Observable, forkJoin } from 'rxjs';

class SOROBAN extends Model {
    serialize() {
        return null;
    }

    get entity() {
        return 'SOROBAN';
    }
}

@Injectable()
export class SorobanRepository extends BackendRepository<SOROBAN> {
    constructor(
        http: HttpClient,
        private snakeToCamelCasePipe: SnakeToCamelCasePipe
    ) {
        super(http, `${environment.backend.hostname}/search`, SOROBAN);
    }

    private indices = [
        'agencies',
        'advertisers',
        'insertion_orders',
        'campaigns',
        'line_items',
        'creatives',
        'media_groups',
        'publishers',
        'newsletters',
        'ad_slots',
    ];

    private mapSorobanMultipleEntitiesWithCount(response: Object) {
        response['total'] = 0;
        return Object.keys(response).map((key) => {
            if (key != 'total') {
                const entity = response[key];
                response['total'] += entity['total'];
                const output = entity.output;
                entity['entities'] = output.map((indexElement) => {
                    return Object.keys(indexElement).reduce((acc, key) => {
                        acc[this.snakeToCamelCasePipe.transform(key)] =
                            indexElement[key];
                        return acc;
                    }, {});
                });
                delete entity.output;
            }
        });
    }

    private mapFlatOutput(response: Object) {
        response['output'] = response['output'].map((output) => {
            return Object.keys(output).reduce((acc, key) => {
                acc[this.snakeToCamelCasePipe.transform(key)] = output[key];
                return acc;
            }, {});
        });
    }

    sorobanSearch(query: string) {
        const params = { params: { query: query ? `${query}` : '' } };
        const limit = '5';

        const indicesToSearch = {};
        this.indices.forEach((index) => {
            const key = this.snakeToCamelCasePipe.transform(index);
            indicesToSearch[key] = this.http.get(`${this.path}/simple`, {
                params: {
                    ...params.params,
                    indices: index,
                    page: '1',
                    pageSize: limit,
                    sort: 'name:asc'
                },
            });
        });

        return forkJoin(indicesToSearch).pipe(
            map((response) => {
                this.mapSorobanMultipleEntitiesWithCount(response);
                return response;
            })
        );
    }

    eSorobanSearch(params: ESParams): Observable<PaginatedResponse> {
        const paramToPass = {
            params: {
                query: params.query ? `${params.query}` : '',
            },
        };

        if (params.indices && params.indices.length) {
            paramToPass.params['indices'] = params.indices.join(',');
        }

        if (params.n) {
            paramToPass.params['n'] = params.n;
        }

        if (params.page) {
            paramToPass.params['page'] = params.page;
        }

        if (params.sort) {
            paramToPass.params['sort'] = params.sort;
        }

        if (params.orderBy) {
            paramToPass.params['orderBy'] = params.orderBy;
        }

        return this.http
            .get(`${environment.backend.hostname}/search/legacy`, paramToPass)
            .pipe(
                map((data) => {
                    this.mapFlatOutput(data);
                    return {
                        items: data['output'],
                        page: params.page,
                        pages: data['pages'],
                        total: data['total'],
                    };
                })
            );
    }
}
