import { Injectable } from '@angular/core';
import store from 'store';
import expirePlugin from 'store/plugins/expire';

store.addPlugin(expirePlugin);

@Injectable({
    providedIn: 'root'
})
export class Store {

    set(key: string, value: any, expiration?: number) {
        store.set(key, btoa(JSON.stringify(value)), expiration);
    }

    get(key: string) {
        try {
            return JSON.parse(atob(store.get(key)));
        } catch {
            return;
        }
    }

    remove(key: string) {
        store.remove(key);
    }

}
