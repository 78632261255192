import { Model } from 'app/shared/models/model';
import { HistoryChange } from 'app/shared/models/history-change';

export class HistoryItem extends Model {
    changes: HistoryChange[];
    initial: boolean;
    snapshot: any;
    previous: any;
    private created: Date;
    private modified: Date;

    constructor(from) {
        super(from);

        if (from['changes']) {
            this.changes = from['changes'].map(change => new HistoryChange(change));
        }
    }

    get hasChanges() {
        return Array.isArray(this.changes) && this.changes.length > 0;
    }

    get action () {
        return this.created === this.modified ? 'created' : 'updated';
    }

    get previousModified() {
        if (this.previous) {
            return this.previous.modified;
        }
    }

    findChange(field: string) {
        return this.changes.find(change => change.field === field);
    }

    serialize() {
        return null;
    }

    get entity() {
        return 'History Item';
    }
}
