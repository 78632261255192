import { Injectable } from '@angular/core';
import { CityRepository, CountryRepository, MetroRepository, RegionRepository } from 'app/core/repositories';

@Injectable()
export class  LocationHelperService {

    promise: Promise<any> = null;
    database: any;
    country: any;
    region: any;
    metro: any;
    city: any;

    constructor(
        cityRepository: CityRepository,
        countryRepository: CountryRepository,
        metroRepository: MetroRepository,
        regionRepository: RegionRepository
    ) {
        this.promise = Promise.all([
            countryRepository.all().toPromise(),
            regionRepository.all().toPromise(),
            metroRepository.all().toPromise(),
            cityRepository.all().toPromise()
        ]).then(res => {
            this.country = res[0].reduce(this.maptable, {length: 0});
            this.region = res[1].reduce(this.maptable, {length: 0});
            this.metro = res[2].reduce(this.maptable, {length: 0});
            this.city = res[3].reduce(this.maptable, {length: 0});

            this.database = [];

            res[0].forEach(countrySet => this.database = this.database.concat(this.searchable(countrySet)));
            res[1].forEach(regionSet => this.database = this.database.concat(this.searchable(regionSet)));
            res[2].forEach(metroSet => this.database = this.database.concat(this.searchable(metroSet)));
            res[3].forEach(citySet => this.database = this.database.concat(this.searchable(citySet)));
        });
    }

    maptable(table, data) {
        table[parseInt(data.id)] = data;
        table.length = + 1;
        return table;
    }

    searchable(data) {
        data.display = data.name;
        data.search = [data.name].join('');
        switch (true) {
            case (typeof (data.country) === 'string'):
                data.type = 'city';
                data.region = this.region[data.region];
                data.country = this.country[data.country];
                data.display += ', ' + data.region.name + ', ' + data.country.name;
                break;
            case (typeof (data.iso2) === 'string'):
                data.type = 'country';
                break;
            case (typeof (data.code) === 'string'):
                data.type = 'region';
                data.country = this.country[data.country];
                data.display += ', ' + data.country.name;
                break;
            case (typeof (data.code) === 'number'):
                data.type = 'metro';
                data.country = this.country[data.country];
                data.display += ', ' + data.country.name;
                break;
        }
        return data;
    }

    search(term, usonly) {
        const pattern = new RegExp(term, 'ig');
        if (this.database === undefined) {
            return [];
        }
        return this.database.reduce(function (res, data) {
            if (res.length > 20) {
                return res;
            }
            if (usonly && ((data.country !== undefined && data.country.id !== 235)
                || (data.country === undefined && data.id !== 235))) {
                return res;
            }
            if (pattern.test(data.search)) {
                res.push(data);
            }
            return res;
        }, []);
    }

    lookup(id, type) {
        return this[type][id];
    }
}
