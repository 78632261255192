import { Model } from './model';
import { DataProvider } from './data-provider';
import { SharingControl } from './sharing-control';
import { daysToSeconds, secondsToDays } from 'app/core/utils';

interface Metadata {
    eventName?: string;
    source: string;
    origin: string;
    term: string;
    eventNameRegex?: string;
    termRegex?: string;
}

export class Audience extends Model {
    static readonly expansionTypes = [
        'det', 'prob'
    ];
    static readonly RETARGETING_DEFAULT_TTL_IN_DAYS = 14;
    static readonly DYNAMIC_AUDIENCE_DEFAULT_TTL_IN_DAYS = 30;

    // Fields from audiences API
    accountId: number;
    dataProviderId: number;
    createdAt: string;
    updatedAt: string;
    status: string;
    metadata?: Metadata;

    advertiser: string;
    advertiserRefId: number;
    category: string;
    conversion: string;
    conversionId: number;
    countsUpdatedOn: string;
    created: string;
    dataProvider: DataProvider;
    uniqueSegmentId: number;
    expires: string;
    externalId?: string;
    isShared: boolean;
    matchedRecords: number;
    modified: string;
    name: string;
    parentId?: number;
    parentUniqueSegmentId?: number;
    records: number;
    refId: number;
    sharedWith: string[] = [];
    matchCountTotal: number;
    ttl: number;
    value: string;

    constructor(data: any = {}) {
        super();
        Object.assign(this, { sharedWith: [] }, data);
        this.applyDefaultTTL();
    }

    get isLiveAudience() {
        return this.dataProviderId === DataProvider.LIVE_AUDIENCE_SEGMENT_PROVIDER;
    }

    get isDynamicRetargeting() {
        return this.dataProviderId  === DataProvider.DYNAMIC_SEGMENT_ALOGIRTHMIC_RETARGETING;
    }

    get isDynamicURL() {
        return this.dataProviderId === DataProvider.WEBSITE_SEGMENT_URL_PROVIDER
            || (!this.isDynamicEvent && this.dataProviderId === DataProvider.WEBSITE_AUDIENCE_SEGMENT_PROVIDER);
    }

    get isDynamicEvent() {
        return this.dataProviderId === DataProvider.WEBSITE_SEGMENT_EVENT_PROVIDER
            || (this.metadata
                && (this.metadata.eventName || this.metadata.eventNameRegex)
                && this.dataProviderId === DataProvider.WEBSITE_AUDIENCE_SEGMENT_PROVIDER);
    }

    get isDynamicSegment() {
        return this.isDynamicURL || this.isDynamicEvent || this.isDynamicRetargeting;
    }

    get isExpandedSegment() {
        return this.dataProviderId === DataProvider.DET_EXPANDED_SEGMENT_PROVIDER
            || this.dataProviderId === DataProvider.PRO_EXPANDED_SEGMENT_PROVIDER;
    }

    get isReplaceableAudience() {
        return this.dataProviderId === DataProvider.REPLACEABLE_AUDIENCE_SEGMENT_PROVIDER;
    }

    get typeLabel() {
        switch (this.dataProviderId) {
            case DataProvider.WEBSITE_AUDIENCE_SEGMENT_PROVIDER:
                return {main: 'Website Audience', sub: ''};
            case DataProvider.REPLACEABLE_AUDIENCE_SEGMENT_PROVIDER:
                return {main: 'Custom Audience', sub: ''};
            case DataProvider.LIVE_AUDIENCE_SEGMENT_PROVIDER:
                return {main: 'Custom Audience', sub: ''};
            case DataProvider.DET_EXPANDED_SEGMENT_PROVIDER:
                return {main: 'Expanded', sub: 'Deterministic'};
            case DataProvider.PRO_EXPANDED_SEGMENT_PROVIDER:
                return {main: 'Expanded', sub: 'Probabilistic'};
            case DataProvider.ADOBE_LIVE_AUDIENCE:
                return {main: 'Custom Audience', sub: 'Adobe'};
            case DataProvider.BLUEKAI_LIVE_AUDIENCE:
                return {main: 'Custom Audience', sub: 'Bluekai'};
            case DataProvider.DYNAMIC_SEGMENT_ALOGIRTHMIC_RETARGETING:
                return {main: 'Dynamic', sub: 'Product Remarketing'};
            case DataProvider.LOTAME_LIVE_AUDIENCE:
                return {main: 'Custom Audience', sub: 'Lotame'};
            case DataProvider.MERKLE_LIVE_AUDIENCE :
                return {main: 'Custom Audience', sub: 'Merkle'};
            case DataProvider.LIVERAMP_LIVE_AUDIENCE :
                return {main: 'Custom Audience', sub: 'LiveRamp'};
            case DataProvider.MEDIAMATH_LIVE_AUDIENCE:
                return {main: 'Custom Audience', sub: 'MediaMath'};
            case DataProvider.WEBSITE_SEGMENT_URL_PROVIDER:
                return {main: 'Website Audience', sub: 'URL'};
            case DataProvider.WEBSITE_SEGMENT_EVENT_PROVIDER:
                return {main: 'Website Audience', sub: 'Event'};
            default:
                return {main: '—', sub: ''};
        }
    }

    get eventType() {
        if (this.isDynamicURL) {
            return 'url';
        } else if (this.isDynamicEvent) {
            return 'event';
        }

        return '—';
    }

    get matchRate() {
        if (this.records < 1) {
            return null;
        }
        return this.matchedRecords / this.records;
    }

    get ttlInDays() {
        return secondsToDays(this.ttl);
    }

    set ttlInDays(days: number) {
        this.ttl = daysToSeconds(days);
    }

    get sharingControl() {
        if (this.isShared) {
            return SharingControl.AgencyWide;
        } else if (Array.isArray(this.sharedWith) && this.sharedWith.length > 0) {
            return SharingControl.AdvertiserSpecific;
        } else {
            return SharingControl.Private;
        }
    }

    serialize(): string {
        const audience = this.clone(Audience);
        delete audience._isClone;

        if (audience.conversionId === null) {
            delete audience.conversionId;
        }
        if (audience.isShared === null) {
            delete audience.isShared;
        }

        this.applyDefaultTTL();

        return JSON.stringify(audience);
    }

    private applyDefaultTTL() {
        if (typeof this.ttl === 'number') {
            return;
        }

        if (this.isDynamicSegment) {
            this.ttl = daysToSeconds(30);
        } else {
            this.ttl = null;
        }
    }

    get entity() {
        return 'Audience';
    }
}
