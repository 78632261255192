import { Model } from '../model';

export enum SupplyTextAssetType {
    SponsoredBy = 1,
    Description = 2,
    CallToAction = 12
}

export const SupplyTextAssets = [{
    id: SupplyTextAssetType.SponsoredBy,
    key: 'sponsored'
}, {
    id: SupplyTextAssetType.CallToAction,
    key: 'callToAction'
}, {
    id: SupplyTextAssetType.Description,
    key: 'description'
}];

export class SupplyTextAsset extends Model {
    type: SupplyTextAssetType;
    len: number;

    constructor(data: any = {}) {
        super(data);
    }

    serialize(): string {
        const textAsset = this.clone(SupplyTextAsset);
        delete textAsset._isClone;
        if (!textAsset.version) {
            delete textAsset.version;
        }
        if (!textAsset.refId) {
            delete textAsset.refId;
        }
        return JSON.parse(JSON.stringify(textAsset));
    }

    get entity(): string {
        return 'Native Supply Text Asset';
    }
}
