import { Attribute, Component, OnInit, ViewChild } from '@angular/core';

import { Model } from 'app/shared/models';
import { Resettable } from 'app/shared/components/bulk-edit-lightbox';
import { SensitiveCategoryRepository } from 'app/core/repositories/sensitive-category-repository.service';
import { SearchableSelectSingleComponent } from "app/shared/elements/searchable-select-single";
import { TagsInputComponent } from "app/shared/elements/tags-input";

@Component({
    selector: 'bulk-edit-sensitive-categories',
    templateUrl: './bulk-edit-sensitive-categories.component.html'
})
export class BulkEditSensitiveCategoriesComponent implements OnInit, Resettable {
    private _entities: Model[] = [];
    private _originals: Model[] = [];
    label: string;
    multiple: boolean;
    entityName: string = '';
    field: string;
    sensitiveCategories = [];
    @ViewChild("singleSelect", { static: false }) singleSelect: SearchableSelectSingleComponent;
    @ViewChild("multiSelect", { static: false }) multiSelect: TagsInputComponent;

    constructor(
        @Attribute('label') label: string,
        @Attribute('multiple') multiple: boolean,
        private sensitiveCategoryRepo: SensitiveCategoryRepository
    ) {
        this.multiple = multiple !== null;
        this.label = label;
    }

    ngOnInit() {
        this.sensitiveCategoryRepo.all()
            .pipe(sensitiveCategories => this.sensitiveCategoryRepo.convertCategoriesToOptions(sensitiveCategories))
            .subscribe(sensitiveCategories => this.sensitiveCategories = sensitiveCategories);
    }

    master(value: string) {
        this.entities.forEach(entity => entity[this.field] = value);
    }

    original(index: number) {
        return this._originals[index];
    }

    set entities(entities: Model[]) {
        this._entities = entities;
        this._originals = entities.map(entity => entity.clone());
    }

    get entities() {
        return this._entities;
    }

    reset() {
        if (this.singleSelect !== undefined && !this.multiple) {
            this.singleSelect.resetDropDown();
        }
        if (this.multiSelect !== undefined && this.multiple) {
            this.multiSelect.removeAll();
        }
        this.entities.forEach(
            (entity, index) => entity[this.field] = this.original(index)[this.field]
        );
    }
}
