import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Subject, ReplaySubject, zip, of } from 'rxjs';
import { groupBy } from 'app/core/array-utils';
import { Campaign } from 'app/shared/models';
import { BackendRepository } from './backend-repository';
import { map, bufferWhen, debounceTime, filter, mergeMap } from 'rxjs/operators';
var CampaignRepository = /** @class */ (function (_super) {
    tslib_1.__extends(CampaignRepository, _super);
    function CampaignRepository(http) {
        var _this = _super.call(this, http, '/campaign', Campaign) || this;
        _this.pixelIdToCampaigns = new Map();
        _this.pixelIdToSearch$ = new Subject();
        _this.pixelIdToSearch$.pipe(bufferWhen(function () { return _this.pixelIdToSearch$.pipe(debounceTime(BackendRepository.FLUSH_INTERVAL)); }), filter(function (ids) { return ids.length > 0; }), mergeMap(function (ids) { return zip(of(ids), _this.searchByConversionId(ids)); })).subscribe(function (_a) {
            var pixelIds = _a[0], campaigns = _a[1];
            var mapped = groupBy(campaigns, 'conversionPixel');
            pixelIds.forEach(function (pixelId) {
                _this.pixelIdToCampaigns.get(pixelId).next(mapped[pixelId] || []);
            });
        });
        return _this;
    }
    CampaignRepository.prototype.pendingSearch = function (params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.http.post(this.url('/search/campaign/pending'), this.sanitizeQuery(params))
            .pipe(map(function (data) { return ({
            items: data['output'].map(function (item) { return _this.build(item); }),
            page: 1,
            pages: data['pages'],
            total: data['total']
        }); }));
    };
    CampaignRepository.prototype.activate = function (campaign) {
        return this.http.post(this.url(this.path, 'activate', campaign.id), campaign)
            .pipe(map(function (response) { return response['output']; }));
    };
    CampaignRepository.prototype.pause = function (campaignId) {
        return this.http.post(this.url(this.path, 'pause', campaignId), null)
            .pipe(map(function (response) { return response['output']; }));
    };
    CampaignRepository.prototype.maxQuality = function (campaign) {
        return this.http.post(this.url(this.path, 'max-quality', campaign.id), campaign)
            .pipe(map(function (response) { return response['output']; }));
    };
    CampaignRepository.prototype.searchByConversionId = function (conversionIds) {
        return this.search({
            conditions: [
                { field: 'conversionPixel', value: conversionIds }
            ]
        });
    };
    CampaignRepository.prototype.getByConversionId = function (conversionId) {
        if (!this.pixelIdToCampaigns.has(conversionId)) {
            this.pixelIdToSearch$.next(conversionId);
            this.pixelIdToCampaigns.set(conversionId, new ReplaySubject());
        }
        return this.pixelIdToCampaigns.get(conversionId);
    };
    return CampaignRepository;
}(BackendRepository));
export { CampaignRepository };
