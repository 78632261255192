import { isEqual } from 'app/core/utils';
import adSlotSettings from './ad-slot-setting.json';
var BulkEditAdSlotSizeComponent = /** @class */ (function () {
    function BulkEditAdSlotSizeComponent(label) {
        this.adSlots = [];
        this.originals = new Map();
        this.defaultSizes = adSlotSettings.defaultSizes;
        this.label = label;
    }
    BulkEditAdSlotSizeComponent.prototype.original = function (refId) {
        return this.originals.get(refId);
    };
    Object.defineProperty(BulkEditAdSlotSizeComponent.prototype, "entities", {
        get: function () {
            return this.adSlots;
        },
        set: function (entities) {
            var _this = this;
            this.adSlots = entities;
            this.originals.clear();
            entities.forEach(function (adSlot) {
                _this.originals.set(adSlot.refId, adSlot.clone());
            });
        },
        enumerable: true,
        configurable: true
    });
    BulkEditAdSlotSizeComponent.prototype.revert = function (adSlot) {
        adSlot[this.field] = JSON.parse(JSON.stringify(this.original(adSlot.refId)[this.field]));
    };
    BulkEditAdSlotSizeComponent.prototype.revertAll = function () {
        var _this = this;
        this.entities.forEach(function (adSlot) { return _this.revert(adSlot); });
    };
    BulkEditAdSlotSizeComponent.prototype.toggleSizeSelection = function (selected, selectedAdSlot, size) {
        var ad = this.adSlots.find(function (adSlot) { return adSlot.refId === selectedAdSlot.refId; });
        var index = ad.sizes.findIndex(function (adSize) { return adSize.width === size.width && adSize.height === size.height; });
        if (selected && index < 0) {
            ad.sizes.push(this.getSizeSettings(size));
        }
        else {
            ad.sizes.splice(index, 1);
        }
    };
    BulkEditAdSlotSizeComponent.prototype.toggleMasterSelection = function (selected, size) {
        var _this = this;
        this.adSlots.forEach(function (adSlot) {
            if (adSlot.isUniversalTag) {
                var index = adSlot.sizes.findIndex(function (adSize) { return adSize.width === size.width && adSize.height === size.height; });
                if (selected && index < 0) {
                    adSlot.sizes.push(_this.getSizeSettings(size));
                }
                else if (!selected && index > -1) {
                    adSlot.sizes.splice(index, 1);
                }
            }
        });
    };
    BulkEditAdSlotSizeComponent.prototype.isSizeSelected = function (adSlot, size) {
        return adSlot.sizes.some(function (adSlotSize) { return adSlotSize.width === size.width && adSlotSize.height === size.height; });
    };
    BulkEditAdSlotSizeComponent.prototype.isMasterSelected = function (size) {
        var selected = true;
        var universalAdSlots = this.adSlots.filter(function (ad) { return ad.isUniversalTag; });
        for (var _i = 0, universalAdSlots_1 = universalAdSlots; _i < universalAdSlots_1.length; _i++) {
            var adSlot = universalAdSlots_1[_i];
            var index = adSlot.sizes.findIndex(function (adSize) { return adSize.height === size.height && adSize.width === size.width; });
            if (index < 0) {
                selected = false;
                break;
            }
        }
        return selected;
    };
    BulkEditAdSlotSizeComponent.prototype.hasCustomSizes = function (adSlot) {
        var _loop_1 = function (size) {
            var index = this_1.defaultSizes.findIndex(function (defaultSize) { return defaultSize.width === size.width && defaultSize.height === size.height; });
            if (index < 0) {
                return { value: true };
            }
        };
        var this_1 = this;
        for (var _i = 0, _a = adSlot.sizes; _i < _a.length; _i++) {
            var size = _a[_i];
            var state_1 = _loop_1(size);
            if (typeof state_1 === "object")
                return state_1.value;
        }
        return false;
    };
    BulkEditAdSlotSizeComponent.prototype.getSizeSettings = function (size) {
        var index = this.defaultSizes.findIndex(function (defaultSize) { return defaultSize.width === size.width && defaultSize.height === size.height; });
        return this.defaultSizes[index];
    };
    /**
     * Check if an ad slot has been changed from its original state.
     *
     * We use a custom dirty checker to account for extra 'isEnabled' property
     * that gets added but should not affect dirty status as it is not used.
     * Instead toggling a size is handled by presence in the sizes array.
     *
     * Another reason for the custom check is because during this component's
     * lifecycle we do not preserve the order of the sizes array, so we need to
     * normalize it before we can accurately compare.
     */
    BulkEditAdSlotSizeComponent.prototype.isAdSlotDirty = function (adSlot) {
        if (Array.isArray(adSlot[this.field])) {
            adSlot[this.field].forEach(function (size) { return delete size.isEnabled; });
        }
        if (Array.isArray(this.original(adSlot.refId)[this.field])) {
            this.original(adSlot.refId)[this.field].forEach(function (size) { return delete size.isEnabled; });
        }
        var current = adSlot[this.field];
        var original = this.original(adSlot.refId)[this.field];
        if (Array.isArray(current)) {
            current.sort(this.compareBySize);
        }
        if (Array.isArray(original)) {
            original.sort(this.compareBySize);
        }
        return !isEqual(current, original);
    };
    BulkEditAdSlotSizeComponent.prototype.isDirty = function () {
        var _this = this;
        return this.entities.some(function (adSlot) { return _this.isAdSlotDirty(adSlot); });
    };
    /**
     * Compare two ad slot sizes for the purpose of sorting them.
     * Use both height and width in comparison.
     */
    BulkEditAdSlotSizeComponent.prototype.compareBySize = function (a, b) {
        var aSize = a.height + "x" + a.width;
        var bSize = b.height + "x" + b.width;
        if (aSize > bSize) {
            return 1;
        }
        if (aSize < bSize) {
            return -1;
        }
        return 0;
    };
    return BulkEditAdSlotSizeComponent;
}());
export { BulkEditAdSlotSizeComponent };
