import { Filter } from '../filter';
import { Operation } from '../operation';

export class FilterEnum extends Filter {
    private _query: string[];

    readonly operations: Operation[] = [
        Operation.Equals,
        Operation.DoesNotEqual
    ];

    constructor(data = {}) {
        super(data);

        this.values = this.values || [];
    }

    isValid() {
        return super.isValid()
            && this.query.length > 0
            && Array.isArray(this.query)
                ? this.query.every(query => this.values.flat().includes(query))
            : this.values.includes(this.query as string);
    }

    set query(value: string | string[]) {
        if (typeof value === 'string') {
            value = value.split(',');
        }

        this._query = value;
    }

    get query() {
        return this._query;
    }
}
