import { Model } from './model';

export class Country extends Model {
    iso2: string;

    serialize(): string {
        return JSON.stringify(this);
    }

    get countryId() {
        if (typeof this.id === 'number') {
            return this.id;
        }

        return parseInt(this.id.toString());
    }

    get entity() {
        return 'Country';
    }
}
