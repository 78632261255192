import { Component, Output, EventEmitter, Input } from '@angular/core';
import { backdropAnimation, dialogAnimation } from 'app/shared/animations';
import { ThemeService } from 'app/shared/helpers/theme.service';

@Component({
    selector: 'dialogbox',
    templateUrl: './dialog.html',
    styleUrls: ['./dialog.styl'],
    animations: [backdropAnimation, dialogAnimation]
})
export class DialogComponent {
    @Input() header: string;
    @Output() opened = new EventEmitter();
    @Output() closed = new EventEmitter();
    visibility = 'hidden';

    private signal = null;

    constructor(private theme: ThemeService) { }

    set visible(visible: boolean) {
        this.visibility = visible ? 'visible' : 'hidden';
    }

    get isVisible() {
        return this.visibility === 'visible';
    }

    done(event) {
        const emitter = (() => {
            switch (event.toState) {
                case 'visible':
                    return this.opened;
                case 'void':
                    return this.closed;
            }
            return null;
        })();
        if (emitter === null) {
            return;
        }
        emitter.emit(this.signal);
        this.signal = null;
    }

    open() {
        this.visible = true;
        this.theme.lockPageScroll();
    }

    close(signal?) {
        // Disallow overwriting an already set signal. The signal will be
        // once it is emitted in an event, additionally prevent signaling
        // 'undefined'.
        if (this.signal === null && signal !== undefined) {
            this.signal = signal;
        }
        this.visible = false;
        this.theme.unlockPageScroll();
    }
}
