import { Model } from 'app/shared/models';
import { ErrorHelper } from 'app/core/errors/error-helper';
import { HttpErrorResponse } from '@angular/common/http';
import { BulkOperationStatusResponse } from 'app/shared/models/bulk-request';

enum BulkResponseStatus {
    AllOk,
    AllFailed,
    SomeFailed
}

export class BulkResponseProcessor {
    static readonly AllOK = BulkResponseStatus.AllOk;
    static readonly AllFailed = BulkResponseStatus.AllFailed;
    static readonly SomeFailed = BulkResponseStatus.SomeFailed;

    /**
     * Process responses from an attempted bulk save by determining
     * which entities succeeded and which entities failed.
     */
    processSaves<T extends Model>(attempted: T[], responses: any[]) {
        return this.process(attempted, responses);
    }



    /**
     * Process status response from an attempted bulk action by determining
     * which entities succeeded and which entities failed.
     */
    processBulkAction<T extends Model>(
        attempted: T[],
        response: BulkOperationStatusResponse,
        getMessage: (responseBody: any) => string | object,
    ) {
        const failureItems = [];
        const successItems = [];

        const idToResponseMap = new Map();
        response.operations.forEach(operation => {
            let msg = getMessage(operation.response.body);
            if (!msg) {
                msg = 'An error has occurred.';
            }
            idToResponseMap.set(operation.operation_map_id, {
                statusCode: operation.status_code,
                msg: msg,
            });
        });

        attempted.forEach((attempt) => {
            const name = attempt.name || '';
            const id = attempt.refId || attempt.id;
            const response = idToResponseMap.get(id.toString());
            let msg = response.msg;
            let extra = '';

            if(typeof response.msg === 'object' && response.msg.message) {
                msg = response.msg.message;
            }

            if(typeof response.msg === 'object' && response.msg.extra) {
                extra = `<strong>${response.msg.extra}</strong>: `;
            }

            if (response.statusCode >= 300) {
                failureItems.push(`<li>${extra}<strong>${name}</strong> (ID: ${id})&nbsp;&nbsp;&mdash;&nbsp;&nbsp;${msg}</li>`);
            } else {
                successItems.push(`<li>${extra}<strong>${name}</strong> (ID: ${id})</li>`);
            }
        });

        return { successItems, failureItems };
    }



    /**
     * Process responses from an attempted bulk delete by determining
     * which entities succeeded and which entities failed.
     */
    processDeletes<T extends Model>(attempted: T[], responses: any[]) {
        return this.process(attempted, responses);
    }

    /**
     * Process responses from an attempted bulk action by determining
     * which entities succeeded and which entities failed.
     */
    private process<T extends Model>(attempted: T[], responses: any[]) {
        const failureItems = [];
        const successItems = [];

        attempted.forEach((attempt, index) => {
            const name = attempt.name || '';
            const id = attempt.refId || attempt.id;

            const response = responses[index];

            if (response instanceof HttpErrorResponse) {
                const helper = new ErrorHelper();

                helper.loadBackEndErrors(response.error ? response.error.errors : null);

                failureItems.push(`
                    <li>
                        <strong>${name}</strong> (ID: ${id})&nbsp;&nbsp;&mdash;&nbsp;&nbsp;${helper.errors[0].slice(0, -1)}
                    </li>`
                );
            } else {
                successItems.push(`<li><strong>${name}</strong> (ID: ${id})</li>`);
            }
        });

        let status: BulkResponseStatus;
        if (failureItems.length === 0) {
            status = BulkResponseProcessor.AllOK;
        } else if (successItems.length === 0) {
            status = BulkResponseProcessor.AllFailed;
        } else {
            status = BulkResponseProcessor.SomeFailed;
        }

        return { status, successItems, failureItems };
    }
}
