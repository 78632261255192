import * as tslib_1 from "tslib";
import { Model } from './model';
var Category = /** @class */ (function (_super) {
    tslib_1.__extends(Category, _super);
    function Category() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Category.prototype.serialize = function () {
        return JSON.stringify(this);
    };
    Object.defineProperty(Category.prototype, "entity", {
        get: function () {
            return 'Category';
        },
        enumerable: true,
        configurable: true
    });
    return Category;
}(Model));
export { Category };
