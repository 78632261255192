var DisplayUtils = /** @class */ (function () {
    function DisplayUtils() {
    }
    DisplayUtils.displayName = function () {
        var components = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            components[_i] = arguments[_i];
        }
        return components
            .filter(function (component) { return typeof component === 'string'; })
            .filter(function (component) { return component.length > 0; })
            .join(' ');
    };
    return DisplayUtils;
}());
export { DisplayUtils };
