import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ListInputDirective } from 'app/shared/elements/list-input';
import { KVP } from './KVP';
import reservedKeys from './reserved-keys.json';
import { IdService } from 'app/core/id.service';

@Component({
    selector: 'kvp-input',
    templateUrl: './kvp-input.html',
    styleUrls: ['./kvp-input.styl']
})
export class KVPInputComponent {
    @Input('kvps') selected: KVP[] = [];
    @Input() allowMultipleKeyValues: boolean = false;

    @Output('kvpsChange') selectedChange: EventEmitter<KVP[]> = new EventEmitter<KVP[]>();

    @ViewChild(ListInputDirective, { static: true }) listInput;

    constructor(
        public id: IdService
    ) { }

    inputText: string;
    private errors: string[] = [];

    placeholder: string = 'Enter the KVP parameters each on a separate line with no spaces. '
    + 'Include "=" between the key and value.\n\n'
    + 'ie.\n'
    + 'key=value\n'
    + 'key2=value2\n'
    + 'key3=value3\n';

    private reservedKeys = reservedKeys;

    validate = (kvp: KVP): boolean => {
        let isValid = true;

        if (kvp.key === undefined || kvp.value === undefined || kvp.value.length === 0) {
            this.errors.push(
                'A key value pair must contain "=" between the key and value, with no spaces (i.e. key=value).'
            );
            isValid = false;
        }

        if (!/[a-zA-Z]/.test(kvp.key.charAt(0))) {
            this.errors.push('Keys must start with a letter.');
            isValid = false;
        }

        if (kvp.key && kvp.value && (kvp.key.includes(' ') || kvp.value.includes(' '))) {
            this.errors.push('Key value pairs cannot contain spaces.');
            isValid = false;
        }

        const supportedCharactersRegex = new RegExp(/^[a-z0-9_\{\}\[\]\-\s]*$/, 'i');
        if (!supportedCharactersRegex.test(kvp.key) || !supportedCharactersRegex.test(kvp.value)) {
            this.errors.push(
                'Letters, numbers, hyphens, brackets and underscores are the only' +
                ' supported characters for key and value names.'
            );
            isValid = false;
        }
        if (this.selected.some(selectedKvp => kvp.key === selectedKvp.key)
            && !this.allowMultipleKeyValues) {
            this.errors.push('Keys must be unique.');
            isValid = false;
        }

        if (this.reservedKeys.indexOf(kvp.key) > -1) {
            this.errors.push('One or more keys is unavailable.');
            isValid = false;
        }

        return isValid;
    }

    /**
     * Parse raw text into an array of KVPs.
     * @param  {string}  rawList
     * @return {KVP[]}
     */
    parse(rawList: string): KVP[] {
        return rawList
            .split(/\n/)
            .map(token => token.trim())
            .filter(token => token !== '')
            .map(token => {
                let key, value;
                [key, value] = token.split('=');
                return new KVP(key, value);
            });
    }

    addParemeters(): void {
        this.errors = [];
        this.listInput.parse();
    }

    /**
     * Clear out the componet. This is needed where a form
     * is to be reused rather than freshly instantiated.
     */
    public reset() {
        this.inputText = null;
        this.clearAll();
    }

    private clearAll(): void {
        this.update([]);
    }

    update(values: KVP[]) {
        this.selected = values;
        this.selectedChange.emit(this.selected);
    }
}
