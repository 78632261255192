import { Injectable } from '@angular/core';
import { Session } from 'app/shared/models';
import { StoreService } from './store.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class TokenService {
    private jwtHelper = new JwtHelperService();
    private static readonly Tokens = 'mtokens';
    private static readonly LoggedInAsTokens = 'mlitokens';
    private readonly expiration = new Date().getTime() + 1000 * 60 * 60 * 24 * 365;

    constructor(
        private store: StoreService
    ) { }

    /**
     * Check if the user has a currently valid "logged in as" token stored.
     */
    get isLoggedInAs(): boolean {
        return !! this.store.get(TokenService.LoggedInAsTokens);
    }

    /**
     * Get the current access token.
     */
    public get accessToken(): string {
        const token = this.tokens ? this.tokens.token : null;

        return !this.isTokenExpired(token) ? token : null;
    }

    /**
     * Get the currently effective tokens.
     */
    private get tokens(): Session {
        return this.isLoggedInAs
            ? this.store.get(TokenService.LoggedInAsTokens)
            : this.store.get(TokenService.Tokens);
    }

    /**
     * Check if an access token is expired. We
     * add a buffer time of 60 seconds.
     * @param {string} token
     */
    private isTokenExpired(token: string) {
        return this.jwtHelper.isTokenExpired(token, 600);
    }

    /**
     * Set tokens for a "logged in as" user.
     * @param {Session} session
     */
    setLoggedInAsTokens(session: Session) {
        this.store.set(TokenService.LoggedInAsTokens, session, this.expiration);
    }

    /**
     * Clear the tokens for a "logged in as" user and restore
     * the original user.
     */
    clearLoggedInAsTokens() {
        this.store.remove(TokenService.LoggedInAsTokens);
    }

    /**
     * Clear all the tokens from the store.
     */
    clear() {
        this.store.remove(TokenService.Tokens);
        this.store.remove(TokenService.LoggedInAsTokens);
    }
}
