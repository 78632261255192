import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { IdService } from 'app/core/id.service';
import { Filter, FilterFactory, Operation } from 'app/shared/elements/filters';
import { RowDirective } from '../table/row.directive';
import { TableMode } from './table-mode';
import { TableURLService } from '../table/table-url.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
var AsyncTableComponent = /** @class */ (function () {
    function AsyncTableComponent(element, route, cdr, urlService, id, routeKey, searchOnType) {
        this.element = element;
        this.route = route;
        this.cdr = cdr;
        this.urlService = urlService;
        this.id = id;
        this.showSelectedCount = true;
        this.pageInfoElement = null;
        this.tableElement = null;
        this.query = new EventEmitter();
        this._reset = new EventEmitter();
        this.selection = new EventEmitter();
        this.funnel = null;
        this._data = { items: [] };
        this._initializing = true;
        this._loading = true;
        this._direction = 'ASC';
        this._availableFilters = [];
        this._query$ = new Subject();
        this._onDestroy = new Subject();
        this._selectionMap = new Map();
        this.filter = new Filter({
            filters: [],
            operation: Operation.And
        });
        this._searchOnType = searchOnType !== null;
        this._debounceTime = this._searchOnType ? 0 : 300;
        this._routeKey = routeKey;
    }
    AsyncTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._query$.pipe(debounceTime(this._debounceTime), takeUntil(this._onDestroy)).subscribe(function (query) { return _this.query.emit(query); });
    };
    AsyncTableComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.route.queryParamMap
            .pipe(takeUntil(this._onDestroy))
            .subscribe(function (paramMap) {
            var useAdvanced = _this.urlService
                .parseModeFromParamMap(_this._routeKey, paramMap) === TableMode.Advanced;
            _this._mode = useAdvanced ? TableMode.Advanced : TableMode.Basic;
            _this.filter.filters = _this.urlService
                .parseFiltersFromParamMap(_this._routeKey, paramMap, _this.availableFilters);
        });
    };
    AsyncTableComponent.prototype.ngOnDestroy = function () {
        this._onDestroy.next();
        this._onDestroy.complete();
    };
    Object.defineProperty(AsyncTableComponent.prototype, "data", {
        set: function (value) {
            if (value) {
                this._initializing = false;
                this.stopLoading();
                this._data = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    AsyncTableComponent.prototype.load = function () {
        this._loading = true;
        this.cdr.markForCheck();
    };
    AsyncTableComponent.prototype.stopLoading = function () {
        this._loading = false;
    };
    Object.defineProperty(AsyncTableComponent.prototype, "filterBy", {
        set: function (value) {
            // filter-by can be a commma separated string for simple filtering
            if (typeof value === 'string') {
                this._availableFilters = value.split(',')
                    .map(function (field) { return FilterFactory.create(field.trim()); });
            }
            // filter-by can be passed as a Filter[] for more advanced filtering
            if (Array.isArray(value)) {
                this._availableFilters = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AsyncTableComponent.prototype, "availableFilters", {
        get: function () {
            return this._availableFilters;
        },
        enumerable: true,
        configurable: true
    });
    AsyncTableComponent.prototype.select = function (value, item, emit) {
        if (emit === void 0) { emit = true; }
        if (value) {
            this._selectionMap.set(this.itemKey(item), item);
        }
        else {
            this._selectionMap.delete(this.itemKey(item));
        }
        if (emit) {
            this.emitSelection();
        }
    };
    AsyncTableComponent.prototype.isSelected = function (item) {
        return this._selectionMap.has(this.itemKey(item));
    };
    AsyncTableComponent.prototype.itemKey = function (item) {
        return item.id || item;
    };
    Object.defineProperty(AsyncTableComponent.prototype, "selectedOutOfView", {
        get: function () {
            var _this = this;
            return this.selectedItems.filter(function (selected) { return !_this.rows.some(function (item) { return _this.itemKey(item) === _this.itemKey(selected); }); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AsyncTableComponent.prototype, "master", {
        get: function () {
            var _this = this;
            return this.rows.every(function (item) { return _this.isSelected(item); });
        },
        set: function (value) {
            var _this = this;
            if (this.master) {
                this._selectionMap.clear();
            }
            else {
                this.rows.forEach(function (item) { return _this.select(value, item, false); });
            }
            this.cdr.markForCheck();
            this.emitSelection();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AsyncTableComponent.prototype, "selectedCount", {
        get: function () {
            return this._selectionMap.size;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AsyncTableComponent.prototype, "selectedItems", {
        get: function () {
            return Array.from(this._selectionMap.values());
        },
        enumerable: true,
        configurable: true
    });
    AsyncTableComponent.prototype.emitSelection = function () {
        // We emit those rows which are selected.
        this.selection.emit(this.selectedItems);
    };
    AsyncTableComponent.prototype.clearSelections = function () {
        this.master = false;
        this._selectionMap.clear();
    };
    AsyncTableComponent.prototype.handleInput = function (term) {
        if (this._searchOnType) {
            this.filterSimple(term);
        }
    };
    AsyncTableComponent.prototype.submitInput = function (term) {
        this.filterSimple(term);
        this.filters.forEach(function (filter) { return filter['isBuilding'] = false; });
    };
    AsyncTableComponent.prototype.filterSimple = function (term) {
        // try to use the search `term` with every filter available to the table
        if (term) {
            var filters = this.availableFilters
                .filter(function (filter) { return !filter.isAdvancedOnly; })
                .map(function (filter) {
                return new filter.constructor({
                    field: filter.field,
                    query: term,
                    label: term,
                    operation: filter.operations[0],
                    values: filter.values,
                    isBuilding: true
                });
            });
            this.filters = this.filters.filter(function (filter) { return !filter['isBuilding']; })
                .concat(new Filter({ filters: filters, operation: Operation.Or, isBuilding: true }))
                .filter(function (filter) { return filter.isValid(); });
        }
    };
    AsyncTableComponent.prototype.emit = function () {
        this.load();
        this._query$.next({
            filters: this.filters,
            orderBy: this.orderBy,
            direction: this.direction
        });
    };
    AsyncTableComponent.prototype.reset = function () {
        this.emit();
        this._reset.emit();
    };
    Object.defineProperty(AsyncTableComponent.prototype, "orderBy", {
        get: function () {
            return this._orderBy;
        },
        set: function (value) {
            this._orderBy = value;
            this.reset();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AsyncTableComponent.prototype, "direction", {
        get: function () {
            return this._direction;
        },
        set: function (value) {
            this._direction = value;
            this.reset();
        },
        enumerable: true,
        configurable: true
    });
    AsyncTableComponent.prototype.addFilter = function (filter) {
        this.filters = this.filters.concat(filter);
    };
    AsyncTableComponent.prototype.removeFilter = function (toRemove) {
        this.filters = this.filters.filter(function (filter) { return filter !== toRemove; });
    };
    Object.defineProperty(AsyncTableComponent.prototype, "filters", {
        get: function () {
            return this.filter.filters;
        },
        set: function (filters) {
            this.filter.filters = filters;
            this.urlService.pushFilters(this._routeKey, this.filter.filters);
            this.reset();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AsyncTableComponent.prototype, "filterable", {
        get: function () {
            return Array.isArray(this.availableFilters) && this.availableFilters.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AsyncTableComponent.prototype, "rows", {
        get: function () {
            return this._data.items;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AsyncTableComponent.prototype, "initializing", {
        get: function () {
            return this._initializing;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AsyncTableComponent.prototype, "zerostate", {
        get: function () {
            return this.filters.length < 1 && !this.loading && this.rows.length < 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AsyncTableComponent.prototype, "isZeroStateMinimal", {
        get: function () {
            return this.element.nativeElement.classList.contains('light');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AsyncTableComponent.prototype, "loading", {
        get: function () {
            return this._loading;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AsyncTableComponent.prototype, "overlayOffset", {
        get: function () {
            // Adjust for the borders?
            var offset = 2;
            if (this.pageInfoElement instanceof ElementRef) {
                offset += this.pageInfoElement.nativeElement.clientHeight;
            }
            if (this.tableElement instanceof ElementRef) {
                offset += this.tableElement.nativeElement.tHead.clientHeight;
            }
            return offset;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AsyncTableComponent.prototype, "mode", {
        get: function () {
            return this._mode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AsyncTableComponent.prototype, "showAdvanced", {
        get: function () {
            return this.mode === 'advanced';
        },
        enumerable: true,
        configurable: true
    });
    AsyncTableComponent.prototype.toggleAdvanced = function (useAdvanced) {
        this._mode = useAdvanced ? TableMode.Advanced : TableMode.Basic;
        this.urlService.pushMode(this._routeKey, this._mode);
        this.filters = useAdvanced
            ? []
            : this.filters.filter(function (filter) { return filter.isValid(); });
    };
    return AsyncTableComponent;
}());
export { AsyncTableComponent };
