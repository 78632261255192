import { HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
/**
 * The `HeimdallRepository` is an abstract class which when extended takes a
 * generic model type and allows for the creation of instances of the model as
 * promises typically using data fetched from the backend API through the
 * `BackendHttp` service.
 */
var HeimdallRepository = /** @class */ (function () {
    function HeimdallRepository(http, path, model) {
        this.http = http;
        this.path = path;
        this.model = model;
    }
    /**
     * Returns an observable that fetches `T` with `id` from the backend.
     * @param id
     */
    HeimdallRepository.prototype.get = function (id) {
        if (typeof id === 'number') {
            id = id.toString();
        }
        if (typeof id !== 'string') {
            return throwError(new Error('Invalid parameter. Parameter must be a string or a number.'));
        }
        if (id.length < 1) {
            return throwError(new Error('Invalid parameter. Parameter must have length at least 1.'));
        }
        return this.http.get(this.url(this.path, id), {
            headers: new HttpHeaders({
                'Accept': 'text/plain',
                'Content-Type': 'text/plain'
            }),
            responseType: 'text'
        }).pipe(map(function (response) { return response; }));
    };
    /**
     * Returns an observable that saves `T` in the backend.
     * @param model
     */
    HeimdallRepository.prototype.save = function (instance) {
        var _this = this;
        var params = [this.path];
        if (instance.id) {
            params.push(instance.id);
        }
        return this.http.post(this.url.apply(this, params), instance.serialize())
            .pipe(map(function (response) { return _this.build(response['output']); }));
    };
    /**
     * This function returns a new instance of the model of type `T`
     * instantiated with `from`.
     * @param from
     */
    HeimdallRepository.prototype.build = function (from) {
        return new this.model(from);
    };
    /**
     * Builds and returns a URL for the backend API given an array of strings
     * `suffix` which are joined using a forward-slash.
     * @param suffix
     */
    HeimdallRepository.prototype.url = function () {
        var suffix = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            suffix[_i] = arguments[_i];
        }
        return environment.backend.heimdall.hostname + suffix.join('/');
    };
    return HeimdallRepository;
}());
export { HeimdallRepository };
