import { Component, ViewChild } from '@angular/core';
import { of, forkJoin, ReplaySubject } from 'rxjs';

import { DownloadHelper } from 'app/shared/helpers/download-helper';
import { Newsletter } from 'app/shared/models';
import { AdSlotRepository, NewsletterRepository } from 'app/core/repositories';
import { LightboxComponent } from 'app/shared/elements/lightbox';
import { mergeMap } from 'rxjs/operators';
import { IdService } from 'app/core/id.service';
import { NgForm } from '@angular/forms';

const WIDTH_MACRO = '{INSERT TEMPLATE MAX WIDTH HERE AS INTEGER}';
const MOBILE_WIDTH_MACRO = '{INSERT TEMPLATE MAX MOBILE WIDTH HERE AS INTEGER}';
const RESPONSIVE_WIDTH_MACRO = '{INSERT TEMPLATE MOBILE RESPONSIVE WIDTH HERE AS INTEGER}';

const DEFAULT_TAG_OPTIONS: Array<any> = [
    {value: 'all',  label: 'All',     key: 0},
    {value: 'safertb', label: 'SafeRTB', key: 1},
];

@Component({
    selector: 'newsletter-tags',
    templateUrl: './newsletter-tags.html',
    styleUrls: ['./newsletter-tags.styl']
})
export class NewsletterTagsComponent {

    public newsletter;
    public adSlots;
    public tagOptions = DEFAULT_TAG_OPTIONS.slice();
    public state = 'closed';
    private _width: number;
    private _mobileWidth: number;

    @ViewChild('form', { static: true }) public form: NgForm;
    @ViewChild(LightboxComponent, { static: true }) public dialog: LightboxComponent;
    @ViewChild('newsletterTagsTable', {static: true}) public newsletterTagsTable;
    newsletter$ = new ReplaySubject<Newsletter>(1);
    adSlotId$ = new ReplaySubject<string>(1);

    constructor(
        public adSlotRepository: AdSlotRepository,
        public newsletterRepository: NewsletterRepository,
        public id: IdService
    ) {
    }

    /**
     * If adSlotId is not specified, show the code for all of the ad slots on the newsletter
     * If the adSlotId is passed in, focus on displaying that ad slot, while others should be available as options.
     * Depending on where the method is invoked, newsletter might only have an id, in which case we would need to
     * do a get request
     * @param newsletter
     * @param adSlotId
     */
    openModal(newsletter: Newsletter, adSlotId?: string) {
        this.newsletterRepository.get(newsletter.id)
            .pipe(
                mergeMap(n => forkJoin(this.newsletterRepository.getSafeRTB(newsletter.id), of(n))),
            )
            .subscribe(([newsletterSafeRTB, n]) => {
                this.newsletter = n;
                this.newsletter.safeRTB = newsletterSafeRTB;
                this.newsletter$.next(this.newsletter);

                if (adSlotId) {
                    this.adSlotId$.next(adSlotId);
                } else {
                    this.adSlotId$.next(null);
                }
            });

        this.dialog.open();
    }

    closeModal(): void {
        this.form.reset();
        this.newsletter = null;
        this.adSlots = [];
        this.tagOptions = DEFAULT_TAG_OPTIONS.slice();
        this.newsletterTagsTable.reset();
    }

    downloadTags(): void {
        const args = [this.newsletter.id];

        if (this.width !== null && this.width !== undefined) {
            args.push(this.width.toString());
        }
        if (this.mobileWidth !== null && this.mobileWidth !== undefined) {
            args.push(this.mobileWidth.toString());
        }

        this.newsletterRepository
            .downloadTags(args)
            .subscribe(data => {
                DownloadHelper.downloadFile(data, `NewsletterTags-${this.newsletter.refId}.xls`);
            });
    }

    get width(): number {
        return this._width;
    }

    set width(width: number) {
        this._width = width;

        for (const slot of this.tagOptions) {
            if (!slot.adSlot) {
                continue;
            }

            slot.fullTag = this.replaceWidths(slot)
        }
    }

    get mobileWidth(): number {
        return this._mobileWidth;
    }

    set mobileWidth(mobileWidth: number) {
        this._mobileWidth = mobileWidth;

        for (const slot of this.tagOptions) {
            if (!slot.adSlot) {
                continue;
            }

            slot.fullTag = this.replaceWidths(slot);
        }
    }

    private replaceWidths(slot): string {
        let fullTag = slot.adSlot.fullTag;

        const desktopRegex = new RegExp(WIDTH_MACRO, 'g');
        const mobileRegex = new RegExp(MOBILE_WIDTH_MACRO, 'g');
        const responsiveRegex = new RegExp(RESPONSIVE_WIDTH_MACRO, 'g');

        if (this._width !== null && this._width !== undefined) {
            fullTag = fullTag.replace(desktopRegex, this._width);
        }

        if (this._mobileWidth !== null && this._mobileWidth !== undefined) {
            fullTag = fullTag.replace(mobileRegex, this._mobileWidth);
            fullTag = fullTag.replace(responsiveRegex, this._mobileWidth);
        }

        return fullTag;
    }

    disabled() {
        return !this.form.valid;
    }
}
