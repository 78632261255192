import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment';
import { PopoverComponent } from 'app/shared/elements/popover';
var URL_PARAM_DELIMETER = '|';
var Preset;
(function (Preset) {
    Preset[Preset["Today"] = 0] = "Today";
    Preset[Preset["Yesterday"] = 1] = "Yesterday";
    Preset[Preset["LastSevenDays"] = 2] = "LastSevenDays";
    Preset[Preset["LastThirtyDays"] = 3] = "LastThirtyDays";
})(Preset || (Preset = {}));
var DateIntervalPickerComponent = /** @class */ (function () {
    function DateIntervalPickerComponent(routeKey, cdr, router, location, route) {
        this.cdr = cdr;
        this.router = router;
        this.location = location;
        this.route = route;
        this.confirmed = new EventEmitter();
        this.error$ = new Subject();
        this.Preset = Preset;
        this.readableFormat = 'MM/DD/YYYY';
        this.onDestroy$ = new Subject();
        this.routeKey = routeKey;
    }
    DateIntervalPickerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParamMap.pipe(takeUntil(this.onDestroy$)).subscribe(function (paramMap) {
            if (_this.routeKey && paramMap.has(_this.routeKey)) {
                var _a = paramMap
                    .get(_this.routeKey)
                    .split(URL_PARAM_DELIMETER)
                    .map(function (date) { return moment(decodeURIComponent(date)); }), start = _a[0], end = _a[1];
                if (start.isValid() && end.isValid()) {
                    _this.start = start;
                    _this.end = end;
                    _this.apply();
                }
            }
        });
    };
    DateIntervalPickerComponent.prototype.ngOnDestroy = function () {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    };
    Object.defineProperty(DateIntervalPickerComponent.prototype, "summary", {
        get: function () {
            var format = 'MMM Do, YYYY';
            if (this.initialStart.isSame(moment().startOf('day')) && this.initialEnd.isSame(moment().endOf('day'))) {
                return 'Today';
            }
            if (this.initialStart.isSame(moment().subtract(1, 'day').startOf('day')) && this.initialEnd.isSame(moment().subtract(1, 'day').endOf('day'))) {
                return 'Yesterday';
            }
            if (this.initialStart.isSame(moment().subtract(7, 'day').startOf('day')) && this.initialEnd.isSame(moment().endOf('day'))) {
                return 'Last 7 Days';
            }
            if (this.initialStart.isSame(moment().subtract(30, 'day').startOf('day')) && this.initialEnd.isSame(moment().endOf('day'))) {
                return 'Last 30 Days';
            }
            if (this.initialStart.isSame(this.initialEnd, 'day')) {
                return this.initialStart.format(format);
            }
            return ''.concat(this.initialStart.format(format), ' - ', this.initialEnd.format(format));
        },
        enumerable: true,
        configurable: true
    });
    DateIntervalPickerComponent.prototype.setStart = function (str) {
        var date = this.toMoment(str);
        this.start = date ? date.startOf('day') : null;
    };
    DateIntervalPickerComponent.prototype.setEnd = function (str) {
        var date = this.toMoment(str);
        this.end = date ? date.endOf('day') : null;
    };
    DateIntervalPickerComponent.prototype.format = function (date) {
        if (date) {
            return date.format(this.readableFormat);
        }
    };
    DateIntervalPickerComponent.prototype.snapTo = function (preset) {
        var date = this.getDateRange(preset);
        this.start = date.start;
        this.end = date.end;
    };
    DateIntervalPickerComponent.prototype.display = function (preset) {
        var date = this.getDateRange(preset);
        return this.minDate.isSameOrBefore(date.start)
            && this.maxDate.isSameOrAfter(date.end);
    };
    DateIntervalPickerComponent.prototype.getDateRange = function (preset) {
        var start;
        var end;
        switch (preset) {
            case Preset.Today:
                start = moment().startOf('day');
                end = moment().endOf('day');
                break;
            case Preset.Yesterday:
                start = moment().subtract(1, 'day').startOf('day');
                end = moment().subtract(1, 'day').endOf('day');
                break;
            case Preset.LastSevenDays:
                start = moment().subtract(7, 'days').startOf('day');
                end = moment().endOf('day');
                break;
            case Preset.LastThirtyDays:
                start = moment().subtract(30, 'days').startOf('day');
                end = moment().endOf('day');
                break;
        }
        return { start: start, end: end };
    };
    DateIntervalPickerComponent.prototype.cancel = function () {
        this.start = this.initialStart;
        this.end = this.initialEnd;
        this.reset();
    };
    DateIntervalPickerComponent.prototype.apply = function () {
        if (!this.validate()) {
            return;
        }
        this.initialStart = this.start;
        this.initialEnd = this.end;
        this.confirmed.emit({ start: this.start, end: this.end });
        this.pushToURL(this.initialStart, this.initialEnd);
        this.reset();
    };
    DateIntervalPickerComponent.prototype.pushToURL = function (start, end) {
        var _a;
        if (!this.routeKey) {
            return;
        }
        var state = this.router.createUrlTree([], {
            relativeTo: this.route,
            queryParams: (_a = {},
                _a[this.routeKey] = encodeURIComponent(start.toISOString()) + URL_PARAM_DELIMETER + encodeURIComponent(end.toISOString()),
                _a),
            queryParamsHandling: 'merge'
        }).toString();
        this.location.replaceState(state);
    };
    DateIntervalPickerComponent.prototype.reset = function () {
        this.error$.next(null);
        this.popover.hide();
    };
    DateIntervalPickerComponent.prototype.validate = function () {
        if (!this.start || !this.end) {
            this.error$.next('Please include both start and end dates.');
            return false;
        }
        if (this.start.isAfter(this.end)) {
            this.error$.next('The start date must be before the end date.');
            return false;
        }
        if (this.minDate && this.start.isBefore(this.minDate)) {
            this.error$.next("The start date must not be before " + this.minDate.format(this.readableFormat) + ".");
            return false;
        }
        if (this.maxDate && this.end.isAfter(this.maxDate)) {
            this.error$.next("The end date must not be after " + this.maxDate.format(this.readableFormat) + ".");
            return false;
        }
        this.error$.next(null);
        return true;
    };
    DateIntervalPickerComponent.prototype.toMoment = function (dateString) {
        var date = moment(dateString, this.readableFormat);
        return date.isValid() ? date : null;
    };
    return DateIntervalPickerComponent;
}());
export { DateIntervalPickerComponent };
