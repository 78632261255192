import { Component, ChangeDetectionStrategy, EventEmitter, Output, ViewChild } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { BulkEditLightboxComponent } from 'app/shared/components/bulk-edit-lightbox';
import { LineItem } from 'app/shared/models';
import { LineItemRepository } from 'app/core/repositories';
import { BulkResponseProcessor, NotificationsService, IdService } from 'app/core';

@Component({
    selector: 'line-item-bulk-edit',
    templateUrl: './line-item-bulk-edit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineItemBulkEditComponent {
    @ViewChild(BulkEditLightboxComponent, { static: true }) lightbox: BulkEditLightboxComponent;
    @Output() completed = new EventEmitter();

    constructor(
        private lineItemRepository: LineItemRepository, 
        private notifications: NotificationsService,
        public id: IdService
    ) {}

    open(lineItems: LineItem[], field: string = null) {
        this.lightbox.initiate();
        this.lightbox.open(lineItems, field, 'load');

        forkJoin(
            lineItems.map(lineItem => this.lineItemRepository.get(lineItem.id).pipe(
                tap(() => this.lightbox.postProgress())
            ))
        ).subscribe(
            details => this.lightbox.open(details, field),
            () => this.handleError()
        );
    }

    close() {
        this.lightbox.close();
    }

    submit({ changed, originals }: { changed: LineItem[], originals: LineItem[], label: string }) {
        this.lightbox.initiate();

        const requests = changed.map(lineItem => {
            return this.lineItemRepository.save(lineItem)
                .pipe(
                    tap(() => this.lightbox.postProgress()),
                    catchError(e => {
                        this.lightbox.postProgress();
                        return of(e);
                    })
                );
        });

        forkJoin(requests).subscribe(
            responses => this.handleAction(originals, responses),
            () => this.handleError()
        );
    }

    private handleAction(attempted: LineItem[], responses: any[]) {
        const { status, failureItems, successItems } = (new BulkResponseProcessor()).processSaves(attempted, responses);

        if (status === BulkResponseProcessor.AllOK) {
            // All OK
            this.notifications.success(
                `Successfully updated ${successItems.length} line item${successItems.length === 1 ? '' : 's'}.`
            );
            this.completed.emit();
            this.close();
            return;
        }

        if (status === BulkResponseProcessor.AllFailed) {
            // All failed
            return this.notifications.error(
                `No line items were updated because the following errors have occurred:
                <ul>${failureItems.join('')}</ul>`, '', 0
            );
        }

        // Some succeeded, some failed
        this.notifications.warning(
            `The following line items were successfully updated:
             <ul>${successItems.join('')}</ul>
             <br>
             However, the following line items were not updated:
             <ul>${failureItems.join('')}</ul>`, '', 0);
        this.completed.emit();
        this.close();
    }

    private handleError() {
        this.notifications.error('An error has occurred.');
    }
}
