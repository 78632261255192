import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BackendRepository, PaginatedResponse } from './backend-repository';
import { BulkOperationCreateResponse, BulkOperationStatusResponse } from 'app/shared/models/bulk-request';
import { BulkOperationRequest } from 'app/shared/models/bulk-request/bulk-operation-request';

@Injectable()
export class BulkOperationRepository extends BackendRepository<BulkOperationStatusResponse> {
    constructor(http: HttpClient) {
        super(http, '/bulk/operation-batches', BulkOperationStatusResponse);
    }

    sendRequest(request: BulkOperationRequest): Observable<BulkOperationCreateResponse> {
        return this.http
            .post(this.url(this.path), request.serialize())
            .pipe(map(result => result as BulkOperationCreateResponse));
    }

    getStatus(batchId: string): Observable<BulkOperationStatusResponse> {
        const includesPath = '?response_output=all';
        return this.http.get(this.url(this.path, batchId) + includesPath, {
            headers: new HttpHeaders({ 'Content-Type': 'text/html' })
        }).pipe(map(result => result as BulkOperationStatusResponse));
    }

    getStatusByUser(params: any): Observable<PaginatedResponse> {
        return this.http.get(this.url(this.path) + this.stringifyParams(params))
            .pipe(map(response => ({
                items: response['data'].map(item => this.build(item)),
                page: 1,
                pages: response['meta']['last_page'],
                total: response['meta']['total']
            })));
    }

    private stringifyParams(params: any): string {
        let str = '?' + params.conditions.map(condition => `${condition.field}=${condition.value}`).join('&');

        params.page ? str += `&page=${params.page}` : '';
        params.n ? str += `&pageSize=${params.n}` : '';

        return str;
    }
}
