import { ComponentFactoryResolver, ApplicationRef, Injector, AfterViewInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { CdkPortal, DomPortalOutlet } from '@angular/cdk/portal';
var PortalComponent = /** @class */ (function () {
    function PortalComponent(componentFactoryResolver, applicationRef, injector) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.applicationRef = applicationRef;
        this.injector = injector;
        this.top = 0;
        this.left = 0;
    }
    PortalComponent.prototype.ngAfterViewInit = function () {
        this.outletElement = document.createElement('div');
        this.outletElement.style.position = 'absolute';
        document.body.appendChild(this.outletElement);
        this.host = new DomPortalOutlet(this.outletElement, this.componentFactoryResolver, this.applicationRef, this.injector);
        this.host.attach(this.portal);
    };
    PortalComponent.prototype.ngOnDestroy = function () {
        this.host.detach();
        this.outletElement.remove();
    };
    PortalComponent.prototype.ngOnChanges = function (_a) {
        var left = _a.left, top = _a.top;
        if (this.outletElement == null) {
            return;
        }
        this.outletElement.style.top = top.currentValue + "px";
        this.outletElement.style.left = left.currentValue + "px";
    };
    return PortalComponent;
}());
export { PortalComponent };
