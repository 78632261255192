import {
    ViewChild,
    ElementRef,
    Input,
    AfterViewInit
} from '@angular/core';

import * as d3 from 'd3';
import * as c3 from 'c3';

export class Chart implements AfterViewInit {
    public chart: any = null;
    protected d3: any = null;
    private c3: any = null;

    @Input() data: Array<Array<string | number>> = null;
    @Input() dataColorPattern: Array<string> = ['#14abf9', '#9013fe', '#1FD58E'];
    @ViewChild('chart', { static: false }) element: ElementRef;

    constructor() {
        this.d3 = d3;
        this.c3 = c3;
    }

    public ngAfterViewInit(): void {
        (this.element.nativeElement as HTMLDivElement).appendChild(this.chart.element);
    }

    protected generate(config?: any, data?: any): any {
        return this.c3.generate(Object.assign({
            data: Object.assign({
                columns: this.data
            }, data),
            color: {
                pattern: this.dataColorPattern
            }
        }, config));
    }
}
