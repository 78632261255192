import { AfterViewInit } from '@angular/core';
import { SparkChartComponent } from 'app/shared/elements/charts/spark-chart/spark-chart.component';
import { ChartHelper } from 'app/shared/helpers/chart-helper';
var MetricCardComponent = /** @class */ (function () {
    function MetricCardComponent() {
        this.metric = '';
        this.operation = '';
        this.height = 150;
        this.width = 50;
        this.format = '';
        this.colorPattern = [];
        this.undefinedTrend = false;
        this.trendValue = 0;
        this.trendNegative = false;
        this._sparkYAxis = [];
        this._sparkXAxis = [];
        this.chartHelper = new ChartHelper();
    }
    Object.defineProperty(MetricCardComponent.prototype, "sparkYAxis", {
        get: function () {
            return this._sparkYAxis;
        },
        set: function (value) {
            this._sparkYAxis = value;
            this.reloadGraph();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MetricCardComponent.prototype, "sparkXAxis", {
        get: function () {
            return this._sparkXAxis;
        },
        set: function (value) {
            this._sparkXAxis = value;
            this.reloadGraph();
        },
        enumerable: true,
        configurable: true
    });
    MetricCardComponent.prototype.ngAfterViewInit = function () {
        this.reloadGraph();
    };
    Object.defineProperty(MetricCardComponent.prototype, "cardMetric", {
        get: function () {
            if (this._sparkYAxis[0] === 'eCPA' || this._sparkYAxis[0] === 'eCPC' || this._sparkYAxis[0] === 'CTR') {
                return null;
            }
            var value = this.chartHelper.evaluateMetricReduction(this._sparkYAxis.slice(1, this._sparkYAxis.length), this.operation);
            switch (this.format) {
                case 'Percent':
                    return value.toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 1 });
                case 'Number':
                    return value.toLocaleString('en-US');
                case 'Currency':
                    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                default: return value.toLocaleString('en-US');
            }
        },
        enumerable: true,
        configurable: true
    });
    MetricCardComponent.prototype.dataTrend = function (YData) {
        var data = YData.slice(1, YData.length);
        var index = -1;
        for (var i = 0; i < data.length; i++) {
            if (data[i] > 0) {
                index = i;
                break;
            }
        }
        if (index > -1) {
            this.trendValue = (data[data.length - 1] - data[index]) / data[index];
            this.undefinedTrend = false;
        }
        else {
            this.undefinedTrend = true;
        }
        this.trendNegative = (this.trendValue < 0);
    };
    Object.defineProperty(MetricCardComponent.prototype, "cardTrend", {
        get: function () {
            return Math.abs(this.trendValue).toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 1 });
        },
        enumerable: true,
        configurable: true
    });
    MetricCardComponent.prototype.reloadGraph = function () {
        this.dataTrend(this.sparkYAxis);
        if (this.sparkChart === undefined) {
            return;
        }
        if (this.sparkChart.called && this.sparkChart.chart) {
            this.sparkChart.chart.load({
                columns: [this.sparkYAxis, this.sparkXAxis],
                unload: true,
            });
        }
    };
    return MetricCardComponent;
}());
export { MetricCardComponent };
