import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, ReplaySubject, zip, of } from 'rxjs';
import { LineItem } from 'app/shared/models';
import { groupBy } from 'app/core/array-utils';
import { BackendRepository } from './backend-repository';
import { bufferWhen, debounceTime, filter, mergeMap, map } from 'rxjs/operators';
import { EligibleAdSlots } from 'app/shared/models/line-item';

@Injectable()
export class LineItemRepository extends BackendRepository<LineItem> {
    private creativeIdToLineItems = new Map<string, Subject<LineItem[]>>();
    private adSlotIdToLineItems = new Map<string, Subject<LineItem[]>>();
    private creativeIdToSearch$ = new Subject<string>();
    private adSlotIdToSearch$ = new Subject<string>();

    constructor(http: HttpClient) {
        super(http, '/strategy', LineItem);

        this.creativeIdToSearch$.pipe(
            bufferWhen(() => this.creativeIdToSearch$.pipe(debounceTime(BackendRepository.FLUSH_INTERVAL))),
            filter(ids => ids.length > 0),
            mergeMap(ids => zip(of(ids), this.searchByCreativeId(ids))),
        ).subscribe(([ids, lineItems]) => {

            const mapped = groupBy(lineItems, 'creative');

            ids.forEach(id => {
                this.creativeIdToLineItems.get(id).next(mapped[id] || []);
            });
        });

        this.adSlotIdToSearch$.pipe(
            bufferWhen(() => this.adSlotIdToSearch$.pipe(debounceTime(BackendRepository.FLUSH_INTERVAL))),
            filter(ids => ids.length > 0),
            mergeMap(ids => zip(of(ids), this.searchByAdSlotId(ids)))
        ).subscribe(([ids, lineItems]) => {
            const mapped = groupBy(lineItems, 'adSlot');

            ids.forEach(id => {
                this.adSlotIdToLineItems.get(id).next(mapped[id] || []);
            });
        });
    }

    clearCache() {
        this.creativeIdToLineItems = new Map<string, Subject<LineItem[]>>();
    }

    searchByAdSlots(id: string | number | string[] | number[]): Observable<LineItem[]> {
        const query = {
            conditions: [
                { field: 'adSlot', value: id }
            ]
        };

        return this.http.post(this.url('/search' + this.path, 'ad-slot'), query)
            .pipe(map(response => response['output']
                .map(data => this.build(data)) as LineItem[]));
    }

    linkCreatives(lineItemId: string, version: number, creativeIds: string[]): Observable<LineItem> {
        return this.http.post(this.url(this.path, 'link-creatives', lineItemId), {
            version,
            creatives: creativeIds
        }).pipe(map(response => this.build(response['output'])));
    }

    confirmLinkAdSlots(lineItemId: string, version: number, adSlotIds: string[]): Observable<Response> {
        return this.http.post(this.url(this.path, 'link-ad-slots-confirm', lineItemId), {
            version,
            adSlots: adSlotIds
        }) as Observable<Response>;
    }

    linkAdSlots(lineItemId: string, version: number, adSlotIds: string[]): Observable<Response> {
        return this.http.post(this.url(this.path, 'link-ad-slots', lineItemId), {
            version,
            adSlots: adSlotIds
        }) as Observable<Response>;
    }

    private searchByCreativeId(creativeIds: string[]) {
        const params = {
            conditions: [
                { field: 'creative', value: creativeIds }
            ]
        };

        return this.http.post(this.url('/search/strategy/creative'), params)
            .pipe(map(response => response['output']
                .map(data => this.build(data)) as LineItem[]));
    }

    private searchByAdSlotId(adSlotIds: string[]) {
        const params = {
            conditions: [
                { field: 'adSlot', value: adSlotIds }
            ]
        };

        return this.http.post(this.url('/search/strategy/ad-slot'), params)
            .pipe(map(response => response['output'].map(data => this.build(data)) as LineItem[]));
    }

    getByCreative(creativeId: string): Observable<LineItem[]> {
        if (!this.creativeIdToLineItems.has(creativeId)) {
            this.creativeIdToSearch$.next(creativeId);
            this.creativeIdToLineItems.set(creativeId, new ReplaySubject<LineItem[]>());
        }

        return this.creativeIdToLineItems.get(creativeId);

    }

    getByAdSlot(adSlotId: string): Observable<LineItem[]> {
        if (!this.adSlotIdToLineItems.has(adSlotId)) {
            this.adSlotIdToSearch$.next(adSlotId);
            this.adSlotIdToLineItems.set(adSlotId, new ReplaySubject<LineItem[]>());
        }

        return this.adSlotIdToLineItems.get(adSlotId);

    }

    searchByAudience(audienceRefId: number): Observable<LineItem[]> {
        return this.http.post(this.url('/search/strategy/audience', audienceRefId.toString()), null)
            .pipe(map(response => response['output']
                .map(data => this.build(data)) as LineItem[]));

    }

    asyncSearchByCreative(params: any): Observable<{ items: LineItem[], page: number, pages: number, total: number}> {
        return this.http.post(this.url('/search' + this.path, 'creative'), params)
            .pipe(map(data => ({
                items: data['output'].map(item => this.build(item) as LineItem),
                page: 1,
                pages: data['pages'],
                total: data['total']
            })));
    }

    exchangeStrategySearch(params: any): Observable<LineItem[]> {
        return this.http.post(this.url('/search/exchange-strategy'), params)
            .pipe(map(response => response['output']
                .map(data => this.build(data)) as LineItem[]));
    }

    asyncSearchByAdSlot(params: any): Observable<{ items: LineItem[], page: number, pages: number, total: number}> {
        return this.http.post(this.url('/search' + this.path, 'ad-slot'), params)
            .pipe(map(data => ({
                items: data['output'].map(item => this.build(item) as LineItem),
                page: 1,
                pages: data['pages'],
                total: data['total']
            })));
    }

    getEligibleAdSlots(lineItemId: string, creativeId?: string): Observable<EligibleAdSlots> {
        return this.http.get(this.url('/strategy-creative/eligible-ad-slots', lineItemId, creativeId))
          .pipe(
            map(response => response['output'])
          );
      }
}
