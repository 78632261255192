import { Component, Output, EventEmitter, ViewChild } from '@angular/core';

import { ConfirmDialogComponent } from 'app/shared/elements/confirm-dialog';
import { CampaignRepository } from 'app/core/repositories';
import { Campaign } from 'app/shared/models';

@Component({
    selector: 'campaign-pause-dialog',
    templateUrl: './campaign-pause-dialog.html'
})
export class CampaignPauseDialogComponent {
    @Output() paused = new EventEmitter();
    @ViewChild(ConfirmDialogComponent, { static: true }) dialog: ConfirmDialogComponent;

    campaign: Campaign;

    constructor(
        private campaignRepository: CampaignRepository
    ) { }

    open(campaign: Campaign) {
        this.campaign = campaign;
        this.dialog.open();
    }

    pause() {
        this.campaignRepository.pause(this.campaign.id)
            .subscribe(() => this.paused.emit());
    }
}
